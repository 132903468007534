import {
  validEmail,
  validateAtleastOneNumber,
  validateAtleastOneSpecialChar,
} from "../../../../setup/utils/validations";

export const initialValues = {
  property_name: "",
  address_line1: "",
  address_line2: "",
  latitude: "",
  longitude: "",
  description: "",
  property_title_deed: "",
  property_permit: "",
  plot_number: "",
  affection_plan: "",
  electricity_id: "",
  sewerage_id: "",
  water_id: "",
  gas_id: "",
  colling_id: "",
  heating_id: "",
};

export const loadInitialValue = (property) => {
  return {
    property_name: property?.property_name || "",
    address_line1: property?.address_line1 || "",
    address_line2: property?.address_line2 || "",
    latitude: property?.latitude || "",
    longitude: property?.longitude || "",
    description: property?.description || "",
    property_title_deed: property?.property_title_deed?.url || "",
    property_permit: property?.property_permit || "",
    plot_number: property?.plot_number || "",
    affection_plan: property?.affection_plan?.url || "",
    electricity_id: property?.electricity_id || "",
    sewerage_id: property?.sewerage_id || "",
    water_id: property?.water_id || "",
    gas_id: property?.gas_id || "",
    colling_id: property?.colling_id || "",
    heating_id: property?.heating_id || "",
  };
};

export const validations = (values) => {
  const error = {};

  if (values.property_name === "") {
    error.property_name = "Property Name is required";
  }

  if (values.address_line1 === "") {
    error.address_line1 = "Address is required";
  }

  if (isNaN(parseFloat(values.latitude))) {
    error.latitude = "Provide valid latitude";
  }

  if (isNaN(parseFloat(values.longitude))) {
    error.longitude = "Provide valid longitude";
  }

  return error;
};
