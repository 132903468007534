/**
 *
 */

import React, { useCallback, useEffect, useRef } from "react";

/* Import configuration starts */
/* Import configuration ends */

import "../style.scss";
import { getUserTheme } from "../../../../../setup/store/slices/authSlice";
import { useSelector } from "react-redux";
/* Component starts */
const V1 = (props) => {
  /* Props destructuring starts */
  const {
    name,
    label,
    handleBlur,
    handleChange,
    valueExtractor,
    optionValueExtractor = null,
    options = [],
    customOnchange = null, //
    value, // useState value or formik value

    /* If value captured in useRef */
    refValue = null, // useRef

    /* If the value captured in useState */
    setValue = null, // useState set function definition

    /* For null or empty value enabled */
    emptyOption = true,
    emptyOptionText = "",

    /* If value captured in formik state/property */
    setFieldValue = null, // Formik setFieldValue function
    formikState = true,

    /* For validations */
    required = true,
    errors,
    touched,
    disabled = false,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const refValueLoaded = useRef(false);
  const formikValueLoaded = useRef(false);
  const userTheme = useSelector(getUserTheme);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const renderOptions = useCallback(valueExtractor, [options]);
  const renderOptionValues = useCallback(optionValueExtractor, [options]);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* On select handler if formikState is false */
  const onSelect = (e) => {
    if (optionValueExtractor !== null) {
      setValue &&
        setValue(
          options.filter(
            (item) => optionValueExtractor(item) === e.target.value
          )[0]
        );
    } else {
      setValue && setValue(options[e.target.value]);
    }

    customOnchange && customOnchange(options[e.target.value]);

    if (refValue) {
      if (e.target.value) {
        refValue.current = options[e.target.value];
      } else {
        refValue.current = null;
      }
    }

    if (setFieldValue) {
      if (options[e.target.value]) {
        setFieldValue(name, valueExtractor(options[e.target.value]));
      } else {
        setFieldValue(name, "");
      }
    }
  };

  /* On select handler if formikState is true */
  const onSelect2 = (e) => {
    if (customOnchange !== null) {
      let extractedValue = null;
      if (optionValueExtractor !== null) {
        extractedValue = options.filter(
          (item) => optionValueExtractor(item) === e.target.value
        )[0];
      } else {
        extractedValue = options.filter(
          (item) => valueExtractor(item) === e.target.value
        )[0];
      }
      customOnchange(extractedValue);
    }

    if (setValue !== null) {
      let extractedValue = null;
      if (optionValueExtractor !== null) {
        extractedValue = options.filter(
          (item) => optionValueExtractor(item) === e.target.value
        )[0];
      } else {
        extractedValue = options.filter(
          (item) => valueExtractor(item) === e.target.value
        )[0];
      }
      setValue(extractedValue);
    }

    handleChange(e);
  };

  // Extracting values locally
  const extractor = () => {
    if (optionValueExtractor !== null) {
      const currentvalue = options?.filter(
        (option) => optionValueExtractor(option) === optionValueExtractor(value)
      )[0];
      return optionValueExtractor(currentvalue);
    } else {
      return options?.findIndex(
        (option) => valueExtractor(option) === valueExtractor(value)
      );
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    if (value && setFieldValue && !formikValueLoaded.current) {
      setFieldValue(name, valueExtractor(value));
      formikValueLoaded.current = true;
    }

    if (refValue && !refValueLoaded.current) {
      if (value) {
        refValue.current = value;
      } else {
        refValue.current = null;
      }
      // refValueLoaded.current = true;
    }
  }, [value]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="custom-form-control">
      <div className="form-input-wrapper">
        <label className="font_s font_grey">
          {label}
          {required && <span className="font_m font_red"> *</span>}
        </label>

        {/* Capturing values on parent state or ref */}
        {!formikState && (
          <select
            onChange={onSelect}
            onBlur={handleBlur}
            name={name}
            value={extractor()}
            // className="bg_black font_xs"
            //|| userTheme === "automatic"
            className={`font_xs
            ${
              userTheme === "light" || userTheme === "automatic"
                ? "bg_lightThemeInput font_lightTheme"
                : "bg_black"
            }
            `}
          >
            {emptyOption && (
              <option
                value=""
                className={`
            ${
              userTheme === "light" || userTheme === "automatic"
                ? "bg_lightThemeInput font_lightTheme"
                : "bg_black"
            }
            `}
              >
                {emptyOptionText}
              </option>
            )}
            {options.map((item, index) => (
              <option
                key={index}
                value={
                  renderOptionValues !== null ? renderOptionValues(item) : index
                }
                disabled={disabled}
                className={`
            ${
              userTheme === "light" || userTheme === "automatic"
                ? "bg_lightThemeInput font_lightTheme"
                : "bg_black"
            }
            `}
              >
                {renderOptions(item)}
              </option>
            ))}
          </select>
        )}

        {/* Capturing value on formik state */}
        {formikState && (
          <select
            onChange={onSelect2}
            onBlur={handleBlur}
            name={name}
            value={value}
            // className="bg_black font_xs"
            className={`font_xs
            ${
              userTheme === "light" || userTheme === "automatic"
                ? "bg_lightThemeInput font_lightTheme"
                : "bg_black"
            }
            `}
          >
            {emptyOption && (
              <option
                value=""
                disabled={disabled}
                className={`
            ${
              userTheme === "light" || userTheme === "automatic"
                ? "bg_lightThemeInput font_lightTheme"
                : "bg_black"
            }
            `}
              >
                {emptyOptionText}
              </option>
            )}
            {options.map((item, index) => (
              <option
                key={index}
                disabled={disabled}
                value={
                  renderOptionValues !== null
                    ? renderOptionValues(item)
                    : renderOptions(item)
                }
                className={`
            ${
              userTheme === "light" || userTheme === "automatic"
                ? "bg_lightThemeInput font_lightTheme"
                : "bg_black"
            }
            `}
              >
                {renderOptions(item)}
              </option>
            ))}
          </select>
        )}
        <img
          src="https://icons.veryicon.com/png/o/miscellaneous/simple-and-round-line-mark/down-arrow-56.png"
          alt="down-arrow"
          className="select-opt"
        />
      </div>

      {touched && errors && <p className="error font_xxs font_red">{errors}</p>}
    </div>
  );
};
/* Component ends */

export default V1;
