/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useSelector } from "react-redux";
import { IoIosChatboxes } from "react-icons/io";
/* Import configuration ends */

/* Import redux slices component starts */
import { getIsTENANT } from "../../setup/store/slices/authSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { HelpAndSupport } from "../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import BreadCrumb from "../../components/ui/other/BreadCrumb/BreadCrumb";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const HelpAndSupportPage = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */

  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const isTenant = useSelector(getIsTENANT);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  /* Component function definition ends */

  /* Component useEffect starts */

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      {!isTenant && <BreadCrumb type="settings_help_page" />}
      <div className="helpAndSupport-page-wrapper container_md">
        <div className="main-wrapper">
          <div className="main_details">
            <div className="img_wrapper">
              <img src={HelpAndSupport} alt="Support" />
            </div>

            <div className="details">
              <p className="font_xxl fw_6">Are you facing any problem?</p>
              <p className="font_m fw_4 font_grey">
                If you need instant support then use live chat option to reach
                us quickly. <br /> Our support will reoly as soon as possible
                after you send us a message.
              </p>
            </div>
          </div>

          <div className="main_contacts">
            <div className="btn">
              <p>
                <IoIosChatboxes size={30} />
                <span className="fw_5 font_m">Start Live Chat</span>
              </p>
            </div>
            <div className="or-message">
              <p className="font_m fw_4 font_grey">Or you can contact at</p>
            </div>
            <div className="contacts">
              <p className="font_m fw_4">
                Email :{" "}
                <span className="font_m fw_4 font_blue">
                  support@dgrnte.com
                </span>
              </p>

              <p className="font_m fw_4">
                Phone :{" "}
                <span className="font_m fw_4 font_blue">+88 123 456 789</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
/* Component ends */

export default HelpAndSupportPage;
