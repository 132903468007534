/**
 *
 */

import React, { useState, useRef } from "react";

/* Import configuration starts */
import GLOBAL from "../../../../../../setup/constants/global";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment/moment";
import { Formik } from "formik";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  getIsOwner,
  getIsPM,
  getUserTheme,
} from "../../../../../../setup/store/slices/authSlice";
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../../../../setup/store/slices/globalAlertSlice";
import { uploadNotarizedContract } from "../../../../../../setup/store/slices/tenantInfoSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Modal } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import {
  EyeFilledBlue,
  UploadDoc,
  XGreen,
} from "../../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../../../../ui/button/IconButton/IconButton";
import FileViewerModal from "../../../../../ui/modal/FileViewerModal/FileViewerModal";
import CustomButton from "../../../../../ui/button/CustomButton/CustomButton";
import CustomFileInput from "../../../../../ui/input/CustomFileInput/CustomFileInput";
/* Import local pages and component ends */

import "./style.scss";
import { fetchTenantContractsDetails } from "../../../../../../setup/store/slices/tenatDetailSlice";

/* Component starts */
const DetailViewModal = (props) => {
  /* Props destructuring starts */
  const {
    showModal,
    detail,
    size = "md",
    onClose,
    type,
    property,
    fromContract = false,
  } = props;
  /* Props destructuring ends */

  console.log(detail, "ppp", property);

  /* Component states and useRef declaration starts */
  const [file, setFile] = useState(null);
  const [showFileViewer, setShowFileViewer] = useState(false);

  const [uploadDoc, setUploadDoc] = useState(false);

  const notar_contract = useRef(null);

  const initialFormValues = {
    notar_contract: "",
  };
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const userTheme = useSelector(getUserTheme);
  const dispatch = useDispatch();
  const isPM = useSelector(getIsPM);
  const isOwner = useSelector(getIsOwner);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const contractRenewStatus = detail?.contract_renewal_status;
  const contractStartDate = detail?.start_date;
  const contractEndDate = detail?.end_date;
  const contractCopyImg = detail?.contract_pdf;
  const installmentNumber = detail?.no_of_installments;
  const totalRent = detail?.total_rent;
  const unitName = detail?.unit_name;
  const unitType = detail?.unit_type;
  const contractStatus = detail?.status;
  const initialAgreement = detail?.initial_agreement_pdf;
  const initialAgreementAudit = detail?.initial_agreement_audit_logs;
  const finalAgreement = detail?.final_agreement_pdf;
  const finalAgreementAudit = detail?.final_agreement_audit_logs;
  const notarizedContract = detail?.notarized_contract_pdf;
  const chequeDoc =
    detail?.cheque_details?.document || detail?.cheque?.document;

  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // On viewing file
  const viewFile = (file) => {
    setFile(file);
    setShowFileViewer(true);
  };

  const onSubmit = async (values) => {
    const payload = {
      tenant_application_id: detail?.tenant_application,
      unit_id: detail?.unit_id,
      tenant_id: detail?.tenant_id,
    };

    payload["contract"] = notar_contract.current;

    console.log(payload);

    // Backend response. Try, Catch
    try {
      const result = await dispatch(uploadNotarizedContract(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Notarized Contract Uploaded",
              description: result.payload.message,
            })
          );

          dispatch(
            fetchTenantContractsDetails({
              tenantId: detail?.tenant_id,
              unitId: detail?.unit_id,
            })
          );
          onClose();
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  if (type === "Contract" && !showFileViewer)
    return (
      <Modal
        size={size}
        centered
        show={showModal}
        className="detail-view-modal-wrapper"
      >
        <Modal.Body
          // className="custom-modal-body"
          className={`custom-modal-body ${
            userTheme === "dark" ? "bg_darkTheme" : "bg_lightTheme"
          }`}
        >
          {/* Header */}
          <div className="close-button-wrapper">
            <IconButton onClick={onClose} className="close-button">
              <img className="h_100" src={XGreen} alt="Close" />
            </IconButton>

            <p
              className={`fw_5 font_m mb-2 ${
                userTheme !== "dark" && "font_blue"
              } font_white text-center`}
            >
              {type} Details
            </p>
          </div>

          {/* Body */}
          <div className="details-content">
            <div className="right">
              <div
                //className="wrapper bg_grey"
                className={`wrapper ${
                  userTheme === "dark" ? "bg_grey" : "bg_lightThemeDetail"
                }`}
              >
                {/* Contract Renewel Status */}
                {contractRenewStatus && (
                  <div className="custom_row">
                    <p className="key font_grey font_s">
                      Contract Renewel Status
                    </p>
                    <p
                      // className="val font_s"
                      className={`val font_s ${
                        (userTheme === "light" || userTheme === "automatic") &&
                        "font_lightTheme"
                      } `}
                    >
                      {contractRenewStatus}
                    </p>
                  </div>
                )}

                {/* Contract Start Date */}
                {contractStartDate && (
                  <div className="custom_row">
                    <p className="key font_grey font_s">Contract Start Date</p>
                    <p
                      //className="val font_s"
                      className={`val font_s ${
                        (userTheme === "light" || userTheme === "automatic") &&
                        "font_lightTheme"
                      } `}
                    >
                      {moment(contractStartDate).format(DATE_FORMAT)}
                    </p>
                  </div>
                )}

                {/* Contract End Date */}
                {contractEndDate && (
                  <div className="custom_row">
                    <p className="key font_grey font_s">Contract End Date</p>
                    <p
                      //className="val font_s"
                      className={`val font_s ${
                        (userTheme === "light" || userTheme === "automatic") &&
                        "font_lightTheme"
                      } `}
                    >
                      {moment(contractEndDate).format(DATE_FORMAT)}
                    </p>
                  </div>
                )}

                {/* Contract Copy Image */}
                {contractCopyImg && (
                  <div className="custom_row">
                    <p className="key font_grey font_s">Contract PDF</p>
                    <p className="val font_s">
                      <IconButton
                        onClick={() => viewFile(contractCopyImg)}
                        className="view"
                      >
                        <img className="h_100" src={EyeFilledBlue} alt="" />
                      </IconButton>
                    </p>
                  </div>
                )}

                {/* Installments Number */}
                {installmentNumber && (
                  <div className="custom_row">
                    <p className="key font_grey font_s">Installments Number</p>
                    <p
                      //className="val font_s"
                      className={`val font_s ${
                        (userTheme === "light" || userTheme === "automatic") &&
                        "font_lightTheme"
                      } `}
                    >
                      {installmentNumber}
                    </p>
                  </div>
                )}

                {/* Total Rent */}
                {totalRent && (
                  <div className="custom_row">
                    <p className="key font_grey font_s">Total Rent</p>
                    <p className="val font_s font_blue">
                      {totalRent?.toLocaleString("en-US")} {detail?.currency}
                    </p>
                  </div>
                )}

                {/* Unit Name */}
                {unitName && (
                  <div className="custom_row">
                    <p className="key font_grey font_s">Unit Name</p>
                    <p
                      //className="val font_s"
                      className={`val font_s ${
                        (userTheme === "light" || userTheme === "automatic") &&
                        "font_lightTheme"
                      } `}
                    >
                      {unitName}
                    </p>
                  </div>
                )}

                {/* Unit Number */}
                {/* {unitNumber && (
                  <div className="custom_row">
                    <p className="key font_grey font_s">Unit Number</p>
                    <p
                      //className="val font_s"
                      className={`val font_s ${
                        (userTheme === "light" || userTheme === "automatic") &&
                        "font_lightTheme"
                      } `}
                    >
                      {unitNumber}
                    </p>
                  </div>
                )} */}

                {/* Unit Type */}
                {unitType && (
                  <div className="custom_row">
                    <p className="key font_grey font_s">Unit Type</p>
                    <p
                      //className="val font_s"
                      className={`val font_s ${
                        (userTheme === "light" || userTheme === "automatic") &&
                        "font_lightTheme"
                      } `}
                    >
                      {unitType}
                    </p>
                  </div>
                )}

                {/* Status */}
                {contractStatus && (
                  <div className="custom_row">
                    <p className="key font_grey font_s">Status</p>
                    <p className="val font_s font_blue">
                      {contractStatus.toUpperCase()}
                    </p>
                  </div>
                )}

                {/* Initial Agreement PDF */}
                {initialAgreement && (
                  <div className="custom_row">
                    <p className="key font_grey font_s">Initial Agreement</p>
                    <p className="val font_s">
                      <IconButton
                        onClick={() => viewFile(initialAgreement)}
                        className="view"
                      >
                        <img className="h_100" src={EyeFilledBlue} alt="" />
                      </IconButton>
                    </p>
                  </div>
                )}

                {/* Initial Agreement Audit Log PDF */}
                {initialAgreementAudit && (
                  <div className="custom_row">
                    <p className="key font_grey font_s">
                      Initial Agreement Audit
                    </p>
                    <p className="val font_s">
                      <IconButton
                        onClick={() => viewFile(initialAgreementAudit)}
                        className="view"
                      >
                        <img className="h_100" src={EyeFilledBlue} alt="" />
                      </IconButton>
                    </p>
                  </div>
                )}

                {/* Final Agreement PDF */}
                {finalAgreement && (
                  <div className="custom_row">
                    <p className="key font_grey font_s">Final Agreement</p>
                    <p className="val font_s">
                      <IconButton
                        onClick={() => viewFile(finalAgreement)}
                        className="view"
                      >
                        <img className="h_100" src={EyeFilledBlue} alt="" />
                      </IconButton>
                    </p>
                  </div>
                )}

                {/* Final Agreement Audit Log PDF */}
                {finalAgreementAudit && (
                  <div className="custom_row">
                    <p className="key font_grey font_s">
                      Final Agreement Audit
                    </p>
                    <p className="val font_s">
                      <IconButton
                        onClick={() => viewFile(finalAgreementAudit)}
                        className="view"
                      >
                        <img className="h_100" src={EyeFilledBlue} alt="" />
                      </IconButton>
                    </p>
                  </div>
                )}

                {/* Notarized PDF */}

                {detail?.notarized_contract && (
                  <div className="custom_row">
                    <p className="key font_grey font_s">Notarized Contract</p>
                    <p className="val font_s">
                      {detail?.notarized_contract_pdf && (
                        <IconButton
                          onClick={() => viewFile(notarizedContract)}
                          className="view"
                        >
                          <img className="h_100" src={EyeFilledBlue} alt="" />
                        </IconButton>
                      )}

                      {/* T3 Property */}
                      {
                        property?.mgmt_model === 2 &&
                          isPM &&
                          !detail?.notarized_contract_pdf &&
                          !uploadDoc && (
                            <IconButton
                              onClick={() => setUploadDoc(!uploadDoc)}
                              className="view"
                            >
                              <img
                                className="h_100"
                                src={UploadDoc}
                                alt="Upload"
                              />
                            </IconButton>
                          )
                        // : 'Not added yet!'
                      }

                      {/* T1 Property */}
                      {property?.mgmt_model === 0 &&
                        isOwner &&
                        !detail?.notarized_contract_pdf &&
                        !uploadDoc && (
                          <IconButton
                            onClick={() => setUploadDoc(!uploadDoc)}
                            className="view"
                          >
                            <img
                              className="h_100"
                              src={UploadDoc}
                              alt="Upload"
                            />
                          </IconButton>
                        )}

                      {uploadDoc && (
                        <div className="form-main-wrapper">
                          <Formik
                            onSubmit={onSubmit}
                            initialValues={initialFormValues}
                            validateOnBlur={true}
                            enableReinitialize={true}
                          >
                            {({
                              values,
                              errors,
                              touched,
                              handleBlur,
                              handleChange,
                              handleSubmit,
                            }) => (
                              <form onSubmit={handleSubmit}>
                                <div className="form-wrapper">
                                  <div
                                    className={`form-group form-group-full ${
                                      userTheme !== "dark" && "otherTheme"
                                    } `}
                                  >
                                    <CustomFileInput
                                      label="Notarized Contract"
                                      name="notar_contract"
                                      files={notar_contract}
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                      errors={errors.notar_contract}
                                      touched={touched.notar_contract}
                                      values={values.notar_contract}
                                      required={false}
                                      formikState={false}
                                    />
                                  </div>
                                </div>

                                <div className="button-wrapper">
                                  <CustomButton type="submit" size="l">
                                    Submit
                                  </CustomButton>
                                  <CustomButton
                                    size="l"
                                    onClick={() => setUploadDoc(!uploadDoc)}
                                    variant="outline-red"
                                  >
                                    Close
                                  </CustomButton>
                                </div>
                              </form>
                            )}
                          </Formik>
                        </div>
                      )}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );

  if (type === "Rent" && !showFileViewer)
    return (
      <Modal
        size={size}
        centered
        show={showModal}
        className="detail-view-modal-wrapper container_md"
      >
        <Modal.Body
          //className="custom-modal-body"
          className={`custom-modal-body ${
            userTheme === "dark" ? "bg_darkTheme" : "bg_lightTheme"
          }`}
        >
          {/* Header */}
          <div className="close-button-wrapper">
            <IconButton onClick={onClose} className="close-button">
              <img className="h_100" src={XGreen} alt="Close" />
            </IconButton>

            <p
              className={`fw_5 font_m mb-2 ${
                userTheme !== "dark" && "font_blue"
              } font_white text-center`}
            >
              {type} Details
            </p>
          </div>

          {/* Body */}
          <div className="details-content">
            <div className="right">
              <div
                // className="wrapper bg_grey"
                className={`wrapper ${
                  userTheme === "dark" ? "bg_grey" : "bg_lightThemeDetail"
                }`}
              >
                {/* Invoice Number */}

                <div className="custom_row">
                  <p className="key font_grey font_s">Installment Name</p>
                  <p className="val font_s font_blue">{detail?.name}</p>
                </div>

                {/* Invoice Due Date */}

                <div className="custom_row">
                  <p className="key font_grey font_s">Installment Due Date</p>
                  <p
                    //className="val font_s"
                    className={`val font_s ${
                      (userTheme === "light" || userTheme === "automatic") &&
                      "font_lightTheme"
                    } `}
                  >
                    {moment(detail?.due_date).format(DATE_FORMAT)}
                  </p>
                </div>

                {/* Invoice Amount */}

                <div className="custom_row">
                  <p className="key font_grey font_s">Installment Amount</p>
                  <p className="val font_s font_blue">
                    {detail?.amount?.toLocaleString("en-US")} {detail?.currency}
                  </p>
                </div>

                {detail?.is_payable_by_cheque &&
                  (detail?.cheque_details || detail?.cheque) && (
                    <>
                      <p className="fw_5 font_m mb-2 font_blue">
                        Cheque Details
                      </p>

                      <div className="custom_row">
                        <p className="key font_grey font_s">Cheque Number</p>
                        <p
                          className={`val font_s ${
                            (userTheme === "light" ||
                              userTheme === "automatic") &&
                            "font_lightTheme"
                          } `}
                        >
                          {detail?.cheque_details?.cheque_number ||
                            detail?.cheque?.cheque_number}
                        </p>
                      </div>

                      <div className="custom_row">
                        <p className="key font_grey font_s">Cheque Amount</p>
                        <p className="val font_s font_blue">
                          {detail?.cheque_details?.cheque_amount?.toLocaleString(
                            "en-US"
                          ) ||
                            detail?.cheque?.cheque_amount?.toLocaleString(
                              "en-US"
                            )}{" "}
                          {detail?.currency || property?.currency}
                        </p>
                      </div>

                      <div className="custom_row">
                        <p className="key font_grey font_s">Account Number</p>
                        <p
                          className={`val font_s ${
                            (userTheme === "light" ||
                              userTheme === "automatic") &&
                            "font_lightTheme"
                          } `}
                        >
                          {detail?.cheque_details?.account_number ||
                            detail?.cheque?.account_number}
                        </p>
                      </div>

                      <div className="custom_row">
                        <p className="key font_grey font_s">Bank Name</p>
                        <p
                          className={`val font_s ${
                            (userTheme === "light" ||
                              userTheme === "automatic") &&
                            "font_lightTheme"
                          } `}
                        >
                          {detail?.cheque_details?.bank_name ||
                            detail?.cheque?.bank_name}
                        </p>
                      </div>

                      <div className="custom_row">
                        <p className="key font_grey font_s">Issuer Name</p>
                        <p
                          className={`val font_s ${
                            (userTheme === "light" ||
                              userTheme === "automatic") &&
                            "font_lightTheme"
                          } `}
                        >
                          {detail?.cheque_details?.issuer_name ||
                            detail?.cheque?.issuer_name}
                        </p>
                      </div>

                      {/* Cheque Copy Image */}
                      {(detail?.cheque_details?.document ||
                        detail?.cheque?.document) &&
                        !fromContract && (
                          <div className="custom_row">
                            <p className="key font_grey font_s">
                              Cheque Document
                            </p>
                            <p className="val font_s">
                              {/* <img
                            src={chequeDoc?.url}
                            alt="Cheque"
                            className="cheque_img"
                            onClick={() => viewFile(chequeDoc)}
                          /> */}
                              <IconButton
                                onClick={() => viewFile(chequeDoc)}
                                className="view"
                              >
                                <img
                                  className="h_100"
                                  src={EyeFilledBlue}
                                  alt=""
                                />
                              </IconButton>
                            </p>
                          </div>
                        )}
                    </>
                  )}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );

  if (type === "Deposit")
    return (
      <Modal
        size={size}
        centered
        show={showModal}
        className="detail-view-modal-wrapper"
      >
        <Modal.Body
          // className="custom-modal-body"
          className={`custom-modal-body ${
            userTheme === "dark" ? "bg_darkTheme" : "bg_lightTheme"
          }`}
        >
          {/* Header */}
          <div className="close-button-wrapper">
            <IconButton onClick={onClose} className="close-button">
              <img className="h_100" src={XGreen} alt="Close" />
            </IconButton>

            <p
              className={`fw_5 font_m mb-2 ${
                userTheme !== "dark" && "font_blue"
              } font_white text-center`}
            >
              {type} Details
            </p>
          </div>

          {/* Body */}
          <div className="details-content">
            <div className="right">
              <div
                //className="wrapper bg_grey"
                className={`wrapper ${
                  userTheme === "dark" ? "bg_grey" : "bg_lightThemeDetail"
                }`}
              >
                {/* Invoice Number */}

                <div className="custom_row">
                  <p className="key font_grey font_s">Invoice Number</p>
                  <p className="val font_s font_blue">{detail?.invoice_no}</p>
                </div>

                {/* Invoice Date */}

                <div className="custom_row">
                  <p className="key font_grey font_s">Invoice Date</p>
                  <p
                    //className="val font_s"
                    className={`val font_s ${
                      (userTheme === "light" || userTheme === "automatic") &&
                      "font_lightTheme"
                    } `}
                  >
                    {moment(detail?.invoice_date).format(DATE_FORMAT)}
                  </p>
                </div>

                {/* Invoice Due Date */}

                <div className="custom_row">
                  <p className="key font_grey font_s">Invoice Due Date</p>
                  <p
                    //className="val font_s"
                    className={`val font_s ${
                      (userTheme === "light" || userTheme === "automatic") &&
                      "font_lightTheme"
                    } `}
                  >
                    {moment(detail?.due_date).format(DATE_FORMAT)}
                  </p>
                </div>

                {/* Contract Copy Image */}
                {/* {contractCopyImg && (
                  <div className="custom_row">
                    <p className="key font_grey font_s">Contract Copy Image</p>
                    <p className="val font_s">
                      <IconButton
                        //   onClick={() => viewFile(contractCopyImg)}
                        className="view"
                      >
                        <img className="h_100" src={EyeFilledBlue} alt="" />
                      </IconButton>
                    </p>
                  </div>
                )} */}

                {/* Invoice Amount */}

                <div className="custom_row">
                  <p className="key font_grey font_s">Invoice Amount</p>
                  <p className="val font_s font_blue">
                    {detail?.total_amount?.toLocaleString("en-US")}{" "}
                    {detail?.currency}
                  </p>
                </div>

                {/* Type */}
                <div className="custom_row">
                  <p className="key font_grey font_s">Type</p>
                  <p
                    //className="val font_s"
                    className={`val font_s ${
                      (userTheme === "light" || userTheme === "automatic") &&
                      "font_lightTheme"
                    } `}
                  >
                    {detail?.type?.charAt(0).toUpperCase() +
                      detail?.type?.slice(1)}
                  </p>
                </div>

                {/* Status */}

                <div className="custom_row">
                  <p className="key font_grey font_s">Status</p>
                  <p className="val font_s font_blue">
                    {detail?.status?.toUpperCase()}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );

  if (type === "invoice" && !showFileViewer)
    return (
      <Modal
        size={size}
        centered
        show={showModal}
        className="detail-view-modal-wrapper"
      >
        <Modal.Body
          //className="custom-modal-body"
          className={`custom-modal-body ${
            userTheme === "dark" ? "bg_darkTheme" : "bg_lightTheme"
          }`}
        >
          {/* Header */}
          <div className="close-button-wrapper">
            <IconButton onClick={onClose} className="close-button">
              <img className="h_100" src={XGreen} alt="Close" />
            </IconButton>

            <p
              className={`fw_5 font_m mb-2 ${
                userTheme !== "dark" && "font_blue"
              } font_white text-center`}
            >
              {type} Details
            </p>
          </div>

          {/* Body */}
          <div className="details-content">
            <div className="right">
              <div
                // className="wrapper bg_grey"
                className={`wrapper ${
                  userTheme === "dark" ? "bg_grey" : "bg_lightThemeDetail"
                }`}
              >
                {/* Invoice Number */}

                <div className="custom_row">
                  <p className="key font_grey font_s">Invoice Number</p>
                  <p className="val font_s font_blue">{detail?.invoice_no}</p>
                </div>

                {/* Invoice Date */}

                <div className="custom_row">
                  <p className="key font_grey font_s">Invoice Date</p>
                  <p
                    //className="val font_s"
                    className={`val font_s ${
                      (userTheme === "light" || userTheme === "automatic") &&
                      "font_lightTheme"
                    } `}
                  >
                    {moment(detail?.invoice_date).format(DATE_FORMAT)}
                  </p>
                </div>

                {/* Invoice Due Date */}

                <div className="custom_row">
                  <p className="key font_grey font_s">Invoice Due Date</p>
                  <p
                    //className="val font_s"
                    className={`val font_s ${
                      (userTheme === "light" || userTheme === "automatic") &&
                      "font_lightTheme"
                    } `}
                  >
                    {moment(detail?.due_date).format(GLOBAL.DATE_FORMAT)}
                  </p>
                </div>

                {/* Invoice Amount */}

                <div className="custom_row">
                  <p className="key font_grey font_s">Invoice Amount</p>
                  <p className="val font_s font_blue">
                    {detail?.total_amount?.toLocaleString("en-US")}{" "}
                    {detail?.currency}
                  </p>
                </div>

                {/* Description */}

                {detail?.description && (
                  <div className="custom_row">
                    <p className="key font_grey font_s">Description</p>
                    <p
                      //className="val font_xs"
                      className={`val font_xs ${
                        (userTheme === "light" || userTheme === "automatic") &&
                        "font_lightTheme"
                      } `}
                    >
                      {detail?.description}
                    </p>
                  </div>
                )}

                {/* Type */}
                <div className="custom_row">
                  <p className="key font_grey font_s">Type</p>
                  <p
                    //className="val font_s"
                    className={`val font_s ${
                      (userTheme === "light" || userTheme === "automatic") &&
                      "font_lightTheme"
                    } `}
                  >
                    {detail?.type?.charAt(0).toUpperCase() +
                      detail?.type?.slice(1)}
                  </p>
                </div>

                {/* utility_name */}

                {detail?.utility_name && (
                  <div className="custom_row">
                    <p className="key font_grey font_s">Utility Name</p>
                    <p
                      //className="val font_s"
                      className={`val font_s ${
                        (userTheme === "light" || userTheme === "automatic") &&
                        "font_lightTheme"
                      } `}
                    >
                      {detail?.utility_name}
                    </p>
                  </div>
                )}

                {/* Status */}

                <div className="custom_row">
                  <p className="key font_grey font_s">Status</p>
                  <p className="val font_s font_blue">
                    {detail?.status?.toUpperCase()}
                  </p>
                </div>

                {/* Invoice PDF */}
                {detail?.invoice_pdf && (
                  <div className="custom_row">
                    <p className="key font_grey font_s">Invoice PDF</p>
                    <p className="val font_s">
                      <IconButton
                        onClick={() => viewFile(detail?.invoice_pdf)}
                        className="view"
                      >
                        <img className="h_100" src={EyeFilledBlue} alt="" />
                      </IconButton>
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );

  if (type === "Contract" && showFileViewer)
    return (
      <FileViewerModal
        show={showFileViewer}
        file={file?.url}
        type={file?.type}
        onClose={() => {
          setShowFileViewer(false);
          setFile(null);
        }}
      />
    );

  if (showFileViewer)
    return (
      <FileViewerModal
        show={showFileViewer}
        file={file?.url}
        type={file?.type}
        onClose={() => {
          setShowFileViewer(false);
          setFile(null);
        }}
      />
    );

  if (type === "invoice" && showFileViewer)
    return (
      <FileViewerModal
        show={showFileViewer}
        file={file?.url}
        type={file?.type}
        onClose={() => {
          setShowFileViewer(false);
          setFile(null);
        }}
      />
    );
};
/* Component ends */

export default DetailViewModal;
