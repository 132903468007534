export const formFields = {
  oldPassword: "",
  newPassword: "",
  newPasswordConfirm: "",
};

export const validations = (values) => {
  const error = {};

  // Validations for Old Password Starts
  if (values.oldPassword === "") {
    error.oldPassword = "Old Password is required";
  }
  // Validations for Old Password Ends

  // Validations for New Password Starts
  if (values.newPassword === "") {
    error.newPassword = "New Password is required";
  } else if (values.newPassword.length < 8) {
    error.newPassword = "Minimum 8 characters are needed";
  }
  // Validations for New Password Ends

  // Validations for New Password Confirm Starts
  if (values.newPasswordConfirm === "") {
    error.newPasswordConfirm = "New Password Confirm is required";
  } else if (values.newPasswordConfirm !== values.newPassword) {
    error.newPasswordConfirm = "Password not matching";
  }
  // Validations for New Password Confirm Ends

  return error;
};
