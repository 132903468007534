/*
 * Slice to handle RATINGS DASHBOARD Module
 */

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_URL } from "../../config/devKeys";
import { setLoading } from "./unpersistedSlice";

const initialState = {
  ratingsList: [],
  propertyRatingsList: [],
  communityRatingsList: [],
  searchedRatingsList: [],
  // annoucement: null,
};

// FETCH RATINGS DASHBOARD LIST - Client Side (HOME)
export const fetchRatingsList = createAsyncThunk(
  "dashboard_home/dashboard_ratingAndReview_listData",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(
        `${API_URL}/dashboard/dashboard_ratingAndReview_listData`,
        {
          method: "POST",
          headers: headers,
        }
      );

      const data = await response.json();

      dispatch(setLoading(false));

      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// FETCH RATINGS DASHBOARD LIST - Client Side  (Property_ID)
export const fetchRatingsListBasedProperty = createAsyncThunk(
  "dashboard_property/dashboard_ratingAndReview_listData",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(
        `${API_URL}/dashboard/dashboard_ratingAndReview_listData`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            property_id: payload?.property_id,
          }),
        }
      );

      const data = await response.json();
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// FETCH RATINGS DASHBOARD LIST - Client Side  (Community_ID)
export const fetchRatingsListBasedCommunity = createAsyncThunk(
  "dashboard_community/dashboard_ratingAndReview_listData",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(
        API_URL + `/dashboard/dashboard_ratingAndReview_listData`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            community_id: payload?.community_id,
          }),
        }
      );

      const data = await response.json();

      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// FETCH SEARCHED RATINGS DASHBOARD LIST - Client Side (HOME)
export const fetchSearchedRatingsList = createAsyncThunk(
  "dashboard_search/dashboard_ratingAndReview_listData",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(
        `${API_URL}/dashboard/dashboard_ratingAndReview_listData?searchKey=${payload.searchKey}&start_date=${payload.start_date}&end_date=${payload.end_date}`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            property_id: payload?.property_id,
            community_id: payload?.community_id,
          }),
        }
      );

      const data = await response.json();

      dispatch(setLoading(false));

      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// FETCH ANNOUCEMENT DATA BASED ON ID  - (ANNOUCEMENT_ID)
// export const fetchAnnoucementById = createAsyncThunk(
//   "announcement/get_annoucement_by_id",
//   async (payload, { rejectWithValue, getState, dispatch }) => {
//     try {
//       dispatch(setLoading(true));
//       const headers = {
//         Authorization: `Bearer ${localStorage.getItem("user_token")}`,
//         "Content-Type": "application/json",
//       };

//       const response = await fetch(
//         `${API_URL}/announcement/get_annoucement_by_id?id=${payload}`,
//         {
//           method: "GET",
//           headers: headers,
//         }
//       );

//       const data = await response.json();

//       dispatch(setLoading(false));

//       if (data.success) {
//         return data.data;
//       } else {
//         return rejectWithValue(data.error.message);
//       }
//     } catch (error) {
//       dispatch(setLoading(false));
//       return rejectWithValue(error?.response);
//     }
//   }
// );

export const ratingsDashboardSlice = createSlice({
  name: "ratingDash",
  initialState,
  reducers: {
    clearSearchRatings: (state, action) => {
      state.ratingsList = [];
    },
  },
  extraReducers: {
    [fetchRatingsList.rejected]: (state, action) => {
      state.ratingsList = [];
    },
    [fetchRatingsList.fulfilled]: (state, action) => {
      state.ratingsList = action.payload;
    },
    [fetchRatingsListBasedProperty.rejected]: (state, action) => {
      state.propertyRatingsList = [];
    },
    [fetchRatingsListBasedProperty.fulfilled]: (state, action) => {
      state.propertyRatingsList = action.payload;
    },
    [fetchRatingsListBasedCommunity.rejected]: (state, action) => {
      state.communityRatingsList = [];
    },
    [fetchRatingsListBasedCommunity.fulfilled]: (state, action) => {
      state.communityRatingsList = action.payload;
    },
    [fetchSearchedRatingsList.rejected]: (state, action) => {
      state.searchedRatingsList = [];
    },
    [fetchSearchedRatingsList.fulfilled]: (state, action) => {
      state.searchedRatingsList = action.payload;
    },
  },
});

// To Get The RATINGS List For Home
export const getRatingsList = (state) => state.ratingDash.ratingsList;

// To Get The RATINGS List For Property
export const getPropertyRatingsList = (state) =>
  state.ratingDash.propertyRatingsList;

// To Get The RATINGS List For Community
export const getCommunityRatingsList = (state) =>
  state.ratingDash.communityRatingsList;

// To Get The SEARCH RATINGS List For Community
export const getSeacrhedRatingsList = (state) =>
  state.ratingDash.searchedRatingsList;

export const { clearSearchRatings } = ratingsDashboardSlice.actions;

// // To Get The Annoucement Based On ID
// export const getAnnoucementById = (state) => state.annoucement.annoucement;

export default ratingsDashboardSlice.reducer;
