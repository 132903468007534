/*
 * Slice to handle INFO TILES Module
 */

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../axios";
import { API_URL } from "../../config/devKeys";
import { setLoading } from "./unpersistedSlice";
import { PLATFORM } from "../../platformUtil";
// import { setLoading } from "./unpersistedSlice";
// import { PLATFORM } from "../../platformUtil";

const initialState = {
  propertyInfoTiles: [],
  communityInfoTiles: [],
  allInfoTiles: [],

  // ********************

  securityInfo: null,
  propertySecurityInfo: null,
  communitySecurityInfo: null,
  unitInfo: null,
  propertyUnitInfo: null,
  communityUnitInfo: null,
  accessPassInfo: null,
  propertyAccessPassInfo: null,
  communityAccessPassInfo: null,
  moveInfo: null,
  propertyMoveInfo: null,
  communityMoveInfo: null,
};

// ******************************************************************

// START - FETCH INFO TILES LIST - CLIENT

// USER / HOME PAGE INFO
export const fetchInfoTilesList = createAsyncThunk(
  "info_tiles/all_info_tiles_list",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));

      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(
        `${API_URL}/info_tiles/all_info_tiles_list`,
        // 'http://localhost:3000/info_tiles/all_info_tiles_list',
        {
          method: "POST",
          headers: headers,
        }
      );
      const data = await response.json();

      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return error?.response;
    }
  }
);

// PROPERTY BASED INFO
export const fetchInfoTilesListProperty = createAsyncThunk(
  "info_tiles_property/all_info_tiles_list",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(
        API_URL + "/info_tiles/all_info_tiles_list",
        // 'http://localhost:3000/info_tiles/all_info_tiles_list',
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            property_id: payload?.property_id,
          }),
        }
      );

      const data = await response.json();
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// COMMUNITY BASED INFO
export const fetchInfoTilesListCommunity = createAsyncThunk(
  "info_tiles_community/all_info_tiles_list",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(
        API_URL + "/info_tiles/all_info_tiles_list",
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            community_id: payload?.community_id,
          }),
        }
      );

      const data = await response.json();
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// END - FETCH SECURITY INFO TILES LIST - CLIENT

// ******************************************************************

// START - FETCH SECURITY INFO TILES LIST - CLIENT

// USER / HOME PAGE INFO
export const fetchSecurityInfoTilesList = createAsyncThunk(
  "info_tiles/security_info_tiles_list",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));

      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(
        `${API_URL}/info_tiles/security_info_tiles_list`,
        {
          method: "POST",
          headers: headers,
        }
      );
      const data = await response.json();

      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return error?.response;
    }
  }
);

// PROPERTY BASED INFO
export const fetchSecurityInfoTilesListProperty = createAsyncThunk(
  "info_tiles_property/security_info_tiles_list",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(
        API_URL + "/info_tiles/security_info_tiles_list",
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            property_id: payload?.property_id,
          }),
        }
      );

      const data = await response.json();
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// COMMUNITY BASED INFO
export const fetchSecurityInfoTilesListCommunity = createAsyncThunk(
  "info_tiles_community/security_info_tiles_list",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(
        API_URL + "/info_tiles/security_info_tiles_list",
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            community_id: payload?.community_id,
          }),
        }
      );

      const data = await response.json();
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// END - FETCH SECURITY INFO TILES LIST - CLIENT

// START - FETCH UNIT INFO TILES LIST - CLIENT

// USER / HOME PAGE INFO
export const fetchUnitInfoTilesList = createAsyncThunk(
  "info_tiles/units_info_tiles_list",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));

      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(
        `${API_URL}/info_tiles/units_info_tiles_list`,
        {
          method: "POST",
          headers: headers,
        }
      );
      const data = await response.json();

      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return error?.response;
    }
  }
);

// PROPERTY BASED INFO
export const fetchUnitInfoTilesListProperty = createAsyncThunk(
  "info_tiles_property/units_info_tiles_list",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(
        API_URL + "/info_tiles/units_info_tiles_list",
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            property_id: payload?.property_id,
          }),
        }
      );

      const data = await response.json();
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// COMMUNITY BASED INFO
export const fetchUnitInfoTilesListCommunity = createAsyncThunk(
  "info_tiles_community/units_info_tiles_list",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    console.log(payload, "[PAY]");
    try {
      dispatch(setLoading(true));
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(
        API_URL + "/info_tiles/units_info_tiles_list",
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            community_id: payload?.community_id,
          }),
        }
      );

      const data = await response.json();
      dispatch(setLoading(false));
      console.log(data.data, "14785");
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// END - FETCH UNIT INFO TILES LIST - CLIENT

// START - FETCH ACCESS PASS INFO TILES LIST - CLIENT

// USER / HOME PAGE INFO
export const fetchAccessPassInfoTilesList = createAsyncThunk(
  "info_tiles/accessPass_info_tiles_list",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));

      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(
        `${API_URL}/info_tiles/accessPass_info_tiles_list`,
        {
          method: "POST",
          headers: headers,
        }
      );
      const data = await response.json();

      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return error?.response;
    }
  }
);

// PROPERTY BASED INFO
export const fetchAccessPassInfoTilesListProperty = createAsyncThunk(
  "info_tiles_property/accessPass_info_tiles_list",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(
        API_URL + "/info_tiles/accessPass_info_tiles_list",
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            property_id: payload?.property_id,
          }),
        }
      );

      const data = await response.json();
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// COMMUNITY BASED INFO
export const fetchAccessPassInfoTilesListCommunity = createAsyncThunk(
  "info_tiles_community/accessPass_info_tiles_list",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    console.log(payload, "[PAY]");
    try {
      dispatch(setLoading(true));
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(
        API_URL + "/info_tiles/accessPass_info_tiles_list",
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            community_id: payload?.community_id,
          }),
        }
      );

      const data = await response.json();
      dispatch(setLoading(false));
      console.log(data.data, "14785");
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// END - FETCH UNIT INFO TILES LIST - CLIENT

// START - FETCH MOVE INFO TILES LIST - CLIENT

// USER / HOME PAGE INFO
export const fetchMoveInfoTilesList = createAsyncThunk(
  "info_tiles/move_info_tiles_list",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));

      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(
        `${API_URL}/info_tiles/move_info_tiles_list`,
        {
          method: "POST",
          headers: headers,
        }
      );
      const data = await response.json();
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return error?.response;
    }
  }
);

// PROPERTY BASED INFO
export const fetchMoveInfoTilesListProperty = createAsyncThunk(
  "info_tiles_property/move_info_tiles_list",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(
        API_URL + "/info_tiles/move_info_tiles_list",
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            property_id: payload?.property_id,
          }),
        }
      );

      const data = await response.json();
      dispatch(setLoading(false));

      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// COMMUNITY BASED INFO
export const fetchMoveInfoTilesListCommunity = createAsyncThunk(
  "info_tiles_community/move_info_tiles_list",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(
        API_URL + "/info_tiles/move_info_tiles_list",
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            community_id: payload?.community_id,
          }),
        }
      );

      const data = await response.json();
      dispatch(setLoading(false));

      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// END - FETCH MOVE INFO TILES LIST - CLIENT

export const infoTiles = createSlice({
  name: "infoTiles",
  initialState,
  extraReducers: {
    [fetchInfoTilesList.rejected]: (state, action) => {
      state.allInfoTiles = [];
    },
    [fetchInfoTilesList.fulfilled]: (state, action) => {
      state.allInfoTiles = action.payload;
    },

    [fetchInfoTilesListProperty.rejected]: (state, action) => {
      state.propertyInfoTiles = [];
    },
    [fetchInfoTilesListProperty.fulfilled]: (state, action) => {
      state.propertyInfoTiles = action.payload;
    },

    [fetchInfoTilesListCommunity.rejected]: (state, action) => {
      state.communityInfoTiles = [];
    },
    [fetchInfoTilesListCommunity.fulfilled]: (state, action) => {
      state.communityInfoTiles = action.payload;
    },

    // **********************************************************

    [fetchSecurityInfoTilesList.rejected]: (state, action) => {
      state.securityInfo = null;
    },
    [fetchSecurityInfoTilesList.fulfilled]: (state, action) => {
      state.securityInfo = action.payload;
    },
    [fetchSecurityInfoTilesListProperty.rejected]: (state, action) => {
      state.propertySecurityInfo = null;
    },
    [fetchSecurityInfoTilesListProperty.fulfilled]: (state, action) => {
      state.propertySecurityInfo = action.payload;
    },
    [fetchSecurityInfoTilesListCommunity.rejected]: (state, action) => {
      state.communitySecurityInfo = null;
    },
    [fetchSecurityInfoTilesListCommunity.fulfilled]: (state, action) => {
      state.communitySecurityInfo = action.payload;
    },
    [fetchUnitInfoTilesList.rejected]: (state, action) => {
      state.unitInfo = null;
    },
    [fetchUnitInfoTilesList.fulfilled]: (state, action) => {
      state.unitInfo = action.payload;
    },
    [fetchUnitInfoTilesListProperty.rejected]: (state, action) => {
      state.propertyUnitInfo = null;
    },
    [fetchUnitInfoTilesListProperty.fulfilled]: (state, action) => {
      state.propertyUnitInfo = action.payload;
    },
    [fetchUnitInfoTilesListCommunity.rejected]: (state, action) => {
      state.communityUnitInfo = null;
    },
    [fetchUnitInfoTilesListCommunity.fulfilled]: (state, action) => {
      state.communityUnitInfo = action.payload;
    },
    [fetchAccessPassInfoTilesList.rejected]: (state, action) => {
      state.accessPassInfo = null;
    },
    [fetchAccessPassInfoTilesList.fulfilled]: (state, action) => {
      state.accessPassInfo = action.payload;
    },
    [fetchAccessPassInfoTilesListProperty.rejected]: (state, action) => {
      state.propertyAccessPassInfo = null;
    },
    [fetchAccessPassInfoTilesListProperty.fulfilled]: (state, action) => {
      state.propertyAccessPassInfo = action.payload;
    },
    [fetchAccessPassInfoTilesListCommunity.rejected]: (state, action) => {
      state.communityAccessPassInfo = null;
    },
    [fetchAccessPassInfoTilesListCommunity.fulfilled]: (state, action) => {
      state.communityAccessPassInfo = action.payload;
    },
    [fetchMoveInfoTilesList.rejected]: (state, action) => {
      state.moveInfo = null;
    },
    [fetchMoveInfoTilesList.fulfilled]: (state, action) => {
      state.moveInfo = action.payload;
    },
    [fetchMoveInfoTilesListProperty.rejected]: (state, action) => {
      state.propertyMoveInfo = null;
    },
    [fetchMoveInfoTilesListProperty.fulfilled]: (state, action) => {
      state.propertyMoveInfo = action.payload;
    },
    [fetchMoveInfoTilesListCommunity.rejected]: (state, action) => {
      state.communityMoveInfo = null;
    },
    [fetchMoveInfoTilesListCommunity.fulfilled]: (state, action) => {
      state.communityMoveInfo = action.payload;
    },
  },
});

// To Get The SECURITY INFO TILES BASED ON PROPERTY
export const getInfoTilesList = (state) => state.infoTiles.allInfoTiles;

export const getPropertyInfoTilesList = (state) =>
  state.infoTiles.propertyInfoTiles;

export const getCommunityInfoTilesList = (state) =>
  state.infoTiles.communityInfoTiles;

// *********************************************

// To Get The SECURITY INFO TILES BASED ON PROPERTY
export const getSecurityInfoTilesList = (state) => state.infoTiles.securityInfo;

export const getPropertySecurityInfoTilesList = (state) =>
  state.infoTiles.propertySecurityInfo;

export const getCommunitySecurityInfoTilesList = (state) =>
  state.infoTiles.communitySecurityInfo;

// To Get The UNIT INFO TILES BASED ON PROPERTY
export const getUnitInfoTilesList = (state) => state.infoTiles.unitInfo;

export const getPropertyUnitInfoTilesList = (state) =>
  state.infoTiles.propertyUnitInfo;

export const getCommunityUnitInfoTilesList = (state) =>
  state.infoTiles.communityUnitInfo;

// To Get The ACCESS PASS INFO TILES BASED ON PROPERTY
export const getAccessPassInfoTilesList = (state) =>
  state.infoTiles.accessPassInfo;

export const getPropertyAccessPassInfoTilesList = (state) =>
  state.infoTiles.propertyAccessPassInfo;

export const getCommunityAccessPassInfoTilesList = (state) =>
  state.infoTiles.communityAccessPassInfo;

// To Get The MOVE INFO TILES BASED ON PROPERTY
export const getMoveInfoTilesList = (state) => state.infoTiles.moveInfo;

export const getPropertyMoveInfoTilesList = (state) =>
  state.infoTiles.propertyMoveInfo;

export const getCommunityMoveInfoTilesList = (state) =>
  state.infoTiles.communityMoveInfo;

export default infoTiles.reducer;
