/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { renewalStatus } from "./helper";
import { useDispatch } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import { setLoading } from "../../../../setup/store/slices/unpersistedSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { EyeFilledBlue } from "../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../../ui/button/IconButton/IconButton";
import ContractsTable from "./local-component/ContractsTable";
import ESignDocModal from "../../Tenant/ESignDocModal/ESignDocModal";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const ContractList = (props) => {
  /* Props destructuring starts */
  const { contracts, renewal, handleFetchContractList } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [contractModal, setContractModal] = useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const { pathname } = useLocation();
  const { unitId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const handleRenewalAction = () => {
    if (renewal.status === "pending_tenant_signature") {
      setContractModal(true);
    } else {
      navigate(
        renewal._id + "/application/" + renewal.tenant_application + "/renew"
      );
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="contract-wrapper">
      <div className="text-center">
        <h2 className="font_xxl fw_5">Contracts</h2>
      </div>

      {renewal && (
        <div className="renew-wrapper bg_grey">
          <div className="font_l font_blue fw_4">Renew</div>

          <div className="other-detail">
            <div className={`font_m fw_5 font_yellow`}>
              {renewalStatus(renewal).text}
            </div>

            {renewal?.status !== "pending_contract_dispatch" &&
              renewal?.status !== "pending-contract-review" && (
                <IconButton
                  onClick={handleRenewalAction}
                  className="action-button"
                >
                  <img
                    src={EyeFilledBlue}
                    className="h_100"
                    alt="View Details"
                  />
                </IconButton>
              )}
          </div>
          {renewal?.tenant_application && (
            <ESignDocModal
              onCompleted={async () => {
                dispatch(setLoading(true));
                setTimeout(async () => {
                  console.log("Signed Successfully");
                  await handleFetchContractList();
                }, 2000);
                setContractModal(false);
              }}
              application_id={renewal?.tenant_application}
              type="final_agreement"
              showModal={contractModal}
              onClose={() => setContractModal(false)}
            />
          )}
        </div>
      )}

      <ContractsTable contracts={contracts} />
    </div>
  );
};
/* Component ends */

export default ContractList;
