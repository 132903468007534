/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import moment from "moment";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
/* Import local pages and component ends */

/* Component starts */
const DisplayMoveoutDate = (props) => {
  /* Props destructuring starts */
  const { unit_name, move_out_date } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="display-move-out container_sm">
      <h3 className="fw_6 font_m">
        <span className="font_white">Unit Name : </span>
        <span className="font_blue">{unit_name}</span>
      </h3>
      <h3 className="fw_6 font_m">
        <span className="font_white">Selected Moveout Date : </span>
        <span className="font_blue">
          {moment(move_out_date).format(DATE_FORMAT)}
        </span>
      </h3>
    </div>
  );
};
/* Component ends */

export default DisplayMoveoutDate;
