const home = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1599 },
    items: 3.2,
    slidesToSlide: 3,
  },
  desktop: {
    breakpoint: { max: 1600, min: 1199 },
    items: 2.5,
    slidesToSlide: 2,
  },
  tablet: {
    breakpoint: { max: 1200, min: 599 },
    items: 1.2,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 600, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

const license = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1599 },
    items: 2,
    slidesToSlide: 2,
  },
  desktop: {
    breakpoint: { max: 1600, min: 1199 },
    items: 2,
    slidesToSlide: 2,
  },
  tablet: {
    breakpoint: { max: 1200, min: 599 },
    items: 1,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 600, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

const tenant = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1599 },
    items: 1,
    slidesToSlide: 1,
  },
  desktop: {
    breakpoint: { max: 1600, min: 1199 },
    items: 1,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 1200, min: 599 },
    items: 1,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 600, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

const overview = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1599 },
    items: 5,
    slidesToSlide: 5,
  },
  desktop: {
    breakpoint: { max: 1600, min: 1199 },
    items: 4,
    slidesToSlide: 4,
  },
  tablet: {
    breakpoint: { max: 1200, min: 599 },
    items: 3,
    slidesToSlide: 3,
  },
  mobile: {
    breakpoint: { max: 600, min: 0 },
    items: 2,
    slidesToSlide: 2,
  },
};

export const sliderResponsive = (type) => {
  switch (type) {
    case "license":
      return license;
      break;

    case "tenantHome":
      return tenant;
      break;

    case "overview":
      return overview;
      break;

    default:
      return home;
      break;
  }
};
