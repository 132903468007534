/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { Toastify } from "../../../setup/utils/toast";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { CopyToClipboardWhite } from "../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../../components/ui/button/IconButton/IconButton";
/* Import local pages and component ends */

/* Component starts */
const Stripe = (props) => {
  /* Props destructuring starts */
  const { data, settings } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const stripePublicKey = data?.stripe_public_key;
  const stripeSecretKey = data?.stripe_secret_key;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // On clicking copy to clipboard
  const onCopy = (text) => {
    navigator.clipboard.writeText(text);
    Toastify("info", "Copied to clipboard!");
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="stripe-wrapper">
      <h3 className="gateway font_l font_blue fw_6">Stripe</h3>
      <div className="flex-card">
        {/* Public Key */}
        <div className="custom_row row1">
          <p className="key font_s font_grey fw_6">Public Key</p>
          <p className="value font_s">
            <input
              type="text"
              readOnly
              className="transparent-input d_block font_s"
              value={stripePublicKey}
            />
          </p>
        </div>

        {/* Secret Key */}
        <div className="custom_row row2">
          <p className="key font_s font_grey fw_6">Secret Key</p>
          <p className="value font_s">
            <input
              type="text"
              readOnly
              className="transparent-input d_block font_s"
              value={stripeSecretKey}
            />
          </p>
        </div>

        {/* Webhook URL */}
        <div className="custom_row row3">
          <p className="key font_s font_grey fw_6">Webhook URL</p>
          <div className="value font_s">
            <div className="url">
              <input type="text" readOnly value={settings.stripeCallbackURL} />
            </div>

            <div className="icon-wrapper">
              <IconButton
                onClick={() => onCopy(settings.stripeCallbackURL)}
                className="copy-icon v_middle"
              >
                <img
                  src={CopyToClipboardWhite}
                  className="w_100"
                  alt="Copy to clipboard"
                />
              </IconButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
/* Component ends */

export default Stripe;
