/**
 *
 */

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */

/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import Searchbar from "../../components/ui/other/Searchbar/Searchbar";
import TabButton from "../../components/ui/other/TabButton/TabButton";

import OpenCommunication from "../../components/module/InternalCommunication/InternalCommunicationTable/OpenCommunicationTable/OpenCommunication";
import ClosedCommunication from "../../components/module/InternalCommunication/InternalCommunicationTable/ClosedCommunicationTable/ClosedCommunication";
/* Import local pages and component ends */

// styles sheet
import "./style.scss";

const InternalCommunication = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [currentKey, setCurrentKey] = useState("open");

  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const buttonList = [
    { key: "open", label: "Open" },
    { key: "closed", label: "Closed" },
  ];

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const onTabSelect = (key) => {
    setCurrentKey(key);
  };

  /* Component function definition ends */

  /* Component useEffect starts */
  //   useEffect(() => {
  //     dispatch(fetchFoundItemList());
  //     dispatch(fetchLostItemList());

  //     dispatch(fetchClaimedItemList());
  //   }, [dispatch]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  /* Component starts */
  return (
    <div className="internal-communication-wrapper">
      <div className="internal-communication-heading">
        <Link
          to="/property/internal-communication/add"
          className="font_m fw_6 d_block link"
        >
          + Create Internal Communication
        </Link>

        <h2 className="font_xxl fw_5">Internal Communications</h2>
      </div>

      <Searchbar />

      {/* TAB BUTTONS */}
      <TabButton
        buttonList={buttonList}
        currentKey={currentKey}
        onSelect={onTabSelect}
      />

      {/* Display Table Data Based On The Current Tab */}

      {currentKey === "open" ? <OpenCommunication /> : <ClosedCommunication />}
    </div>
  );
};
/* Component End */

export default InternalCommunication;
