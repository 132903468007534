/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import moment from "moment";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
/* Import configuration ends */

/* Import redux slices component starts */
import { fetchVisitorPassBasedOnId } from "../../../../setup/store/slices/visitorLogBook";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Table } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import {
  EyeFilledBlue,
  LogoutFilledBlue,
} from "../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import GLOBAL from "../../../../setup/constants/global";
import IconButton from "../../../ui/button/IconButton/IconButton";

import Pagination from "../../../ui/other/Pagination/Pagination";
/* Import local pages and component ends */

// Styles
import "./style.scss";

/* Component starts */
const VisitorLogTable = (props) => {
  /* Props destructuring starts */
  const { visitorList, totalCount, currentPage, setCurrentPage } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [highlightedItems, setHighlightedItems] = useState([]);
  const [cookies, setCookie] = useCookies(["openedItems"]);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  const dispatch = useDispatch();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  // To View The Item Report
  const handleViewReport = async (id, type) => {
    // Add the clicked item to cookies
    const openedItems = cookies?.openedItems || [];
    if (!openedItems?.includes(id)) {
      openedItems?.push(id);
      setCookie("openedItems", openedItems);
    }

    if (type === undefined) {
      const itemData = await dispatch(fetchVisitorPassBasedOnId(id));

      navigate(`sign-out/${id}`, {
        state: itemData.payload,
      });
    } else {
      const itemData = await dispatch(fetchVisitorPassBasedOnId(id));

      navigate(`${id}`, {
        state: itemData.payload,
      });
    }
  };

  /* Component function definition ends */

  /* Component useEffect starts */

  useEffect(() => {
    // Update highlighted visitorList based on cookies
    const openedItems = cookies?.openedItems || [];
    const newItems = visitorList?.filter(
      (item) => !openedItems?.includes(item._id)
    );

    setHighlightedItems(newItems);
  }, [visitorList, cookies?.openedItems]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */

  return (
    <>
      <div className="table-wrapper">
        {visitorList?.length > 0 && (
          <div className="custom-table-wrapper">
            <Table className="custom-table font_s" borderless responsive>
              {/* Table Heading */}
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Unit </th>
                  <th>Name</th>
                  <th>Company</th>
                  <th>Car Plate</th>
                  <th>Reason For Access</th>
                  <th>Time In</th>
                  <th>Time Out</th>
                  <th>Action</th>
                </tr>
              </thead>
              {/* Table Body */}
              <tbody>
                {visitorList?.map((item, index) => (
                  <>
                    {highlightedItems?.includes(item) ? (
                      <>
                        <span className="font_xxl new-label"></span>
                        <tr key={item._id}>
                          {/* ID */}
                          {/* <td>{item?._id}</td> */}
                          <td>{item?.logbook_id}</td>

                          {/* Unit */}
                          <td>{item?.unit_no?.unit_name}</td>

                          {/* Name */}
                          <td>{item?.visitor_name}</td>

                          {/* Company */}
                          <td>{item?.company || "-"}</td>

                          {/* Car Number */}
                          <td className="font_blue">
                            {item?.car_data === null
                              ? "-"
                              : item?.car_data?.car_plate}
                          </td>

                          {/* Reason For Access */}
                          <td>{item?.reason}</td>

                          {/* Time In */}
                          <td>
                            {item?.time_in === undefined
                              ? "-"
                              : moment(item?.time_in).format(DATE_TIME_FORMAT)}
                          </td>
                          {/* Time Out */}
                          <td>
                            {item?.time_out === undefined
                              ? "-"
                              : moment(item?.time_out).format(DATE_TIME_FORMAT)}
                          </td>

                          {/* Actions */}
                          <td className="text-start txt-nowrap">
                            {item?.time_out === undefined ? (
                              <IconButton
                                onClick={() =>
                                  handleViewReport(item?._id, item?.time_out)
                                }
                                className="action-button"
                              >
                                <img
                                  src={LogoutFilledBlue}
                                  className="h_100"
                                  alt="View Details"
                                />
                              </IconButton>
                            ) : (
                              <IconButton
                                onClick={() =>
                                  handleViewReport(item?._id, item?.time_out)
                                }
                                className="action-button"
                              >
                                <img
                                  src={EyeFilledBlue}
                                  className="h_100"
                                  alt="View Details"
                                />
                              </IconButton>
                            )}
                          </td>
                        </tr>
                      </>
                    ) : (
                      <>
                        <tr key={item._id}>
                          {/* ID */}
                          {/* <td>{item?._id}</td> */}
                          <td>{`# ${index + 1}`}</td>

                          {/* Unit */}
                          <td>{item?.unit_no?.unit_name}</td>

                          {/* Name */}
                          <td>{item?.visitor_name}</td>

                          {/* Company */}
                          <td>{item?.company || "-"}</td>

                          {/* Car Number */}
                          <td className="font_blue">
                            {item?.car_data === null
                              ? "-"
                              : item?.car_data?.car_plate}
                          </td>

                          {/* Reason For Access */}
                          <td>{item?.reason}</td>

                          {/* Time In */}
                          <td>
                            {item?.time_in === undefined
                              ? "-"
                              : moment(item?.time_in).format(DATE_TIME_FORMAT)}
                          </td>
                          {/* Time Out */}
                          <td>
                            {item?.time_out === undefined
                              ? "-"
                              : moment(item?.time_out).format(DATE_TIME_FORMAT)}
                          </td>

                          {/* Actions */}
                          <td className="text-start txt-nowrap">
                            {item?.time_out === undefined ? (
                              <IconButton
                                onClick={() =>
                                  handleViewReport(item?._id, item?.time_out)
                                }
                                className="action-button"
                              >
                                <img
                                  src={LogoutFilledBlue}
                                  className="h_100"
                                  alt="View Details"
                                />
                              </IconButton>
                            ) : (
                              <IconButton
                                onClick={() =>
                                  handleViewReport(item?._id, item?.time_out)
                                }
                                className="action-button"
                              >
                                <img
                                  src={EyeFilledBlue}
                                  className="h_100"
                                  alt="View Details"
                                />
                              </IconButton>
                            )}
                          </td>
                        </tr>
                      </>
                    )}
                  </>
                ))}
              </tbody>
            </Table>
          </div>
        )}

        {visitorList?.length === 0 && (
          <div className=" text-center">
            <h3 className=" font_m font_yellow fw_6">No Data</h3>
          </div>
        )}
      </div>

      {/* PAGINATION */}
      {visitorList?.length > 0 && (
        <Pagination
          itemsPerPage="10"
          totalItems={totalCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
    </>
  );
};
/* Component Ends */
export default VisitorLogTable;
