import axios from "axios";
import { emitCustomEvent } from "react-custom-events";
import store from "./index";
import { logOutUser } from "./slices/authSlice";
import { setTokenExpiredModal } from "./slices/unpersistedSlice";
import { API_URL } from "../config/devKeys";
import moment from "moment";

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    // if(response) {

    // }
    if (response?.status === 413) {
      return {
        meta: { requestStatus: "rejected" },
        payload:
          "File size is too large. Please upload a file of maximum 100 MB.",
      };
    }
    if (response?.data?.error?.message === "Token expired.") {
      store.dispatch(setTokenExpiredModal(true));
      console.log("Axios call after token expiry");
      store.dispatch(logOutUser());
      localStorage.clear();
      // window.location.href = "/";
      // emitCustomEvent("session_expired", {});
    }
    return response;
  },
  function (error) {
    // Do something with response error
    return Promise.reject(error);
  }
);

/* axios.interceptors.request.use(function (request) {
  console.log(
    "Below is the request data from axios interceptor, before sending the request..."
  );

  if (request?.data && Object.keys(request?.data).length > 0) {
    Object.keys(request?.data).forEach((item) => {
      if (
        typeof request?.data[item] === "object" &&
        request?.data[item] instanceof Date
      ) {
        request.data[item] = moment(request?.data[item]).format("MM/DD/YYYY");
      }
    });
  }
  console.log(request?.data);
  return request;
}); */

export const apiAdapter = () => {};

export const axiosInstance = axios.create({
  baseURL: API_URL,
});

export default axios;
