export const initialValues = {
  first_name: "",
  last_name: "",
  mobile_number: "",
  license_plate_number: "",
  image: "",
};

export const validations = (values) => {
  const error = {};
  /* Validation for First Name starts */
  if (values.first_name === "") {
    error.first_name = "First Name is required";
  }
  /* Validation for First Name ends */
  /* ******************************************************************************* */

  /* Validation for Last Name starts */
  if (values.last_name === "") {
    error.last_name = "Last Name is required";
  }
  /* Validation for Last Name ends */
  /* ******************************************************************************* */

  /* Validation for Mobile Number starts */
  if (values.mobile_number === " ") {
    error.mobile_number = "Mobile Number is required";
  }
  /* Validation for Mobile Number ends */
  /* ******************************************************************************* */

  /* Validation for License Plate Number starts */
  if (values.license_plate_number === " ") {
    error.license_plate_number = "License Plate Number is required";
  }
  /* Validation for License Plate Number ends */
  /* ******************************************************************************* */
};

export const loadInitialValue = (
  passData,
  setInitialFormValues,
  setStartDate,
  setEndDate
) => {
  setInitialFormValues({
    first_name: passData?.visitor_firstname || "",
    last_name: passData?.visitor_lastname || "",
    mobile_number: passData?.mobile_number || "",
    license_plate_number: passData?.car_number || "",
    image: passData?.image || "",
  });
  setStartDate(
    passData?.start_date ? new Date(passData?.start_date) : new Date()
  );
  setEndDate(passData?.end_date ? new Date(passData?.end_date) : new Date());
};
