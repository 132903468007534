/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
/* Import local pages and component ends */

/* Component starts */
const MaintenanceDetails = (props) => {
  /* Props destructuring starts */
  const { details, index } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="basic-wrapper">
      <div className="heading-wrapper">
        <p className="font_l label my-2 mx-4">
          {" "}
          {index && `${index} -`} Maintenances Details
        </p>
      </div>

      <div className="detail-box">
        <div className="basic">
          <div className="detail">
            <p className="font_m fw_3">
              Maintenance Count <br />{" "}
              <span className="font_xl fw_6 font_blue">{details}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
/* Component ends */

export default MaintenanceDetails;
