/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import MUI component starts */
/* Import MUI component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import Searchbar from "../../components/ui/other/Searchbar/Searchbar";
import TabButton from "../../components/ui/other/TabButton/TabButton";
import ActiveFacilities from "../../components/module/TenantSharedFacility/ActiveFacilitiesTable/ActiveFacilities";
import Facilities from "../../components/module/TenantSharedFacility/FacilitiesTable/Facilities";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const TenantShareFacility = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [currentKey, setCurrentKey] = useState("facilities");

  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */

  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */

  const buttonList = [
    { key: "facilities", label: "Facilities" },
    { key: "avtive", label: "Active" },
  ];

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const onTabSelect = (key) => {
    setCurrentKey(key);
  };

  /* Component function definition ends */

  /* Component useEffect starts */

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="facility-wrapper">
      <div className="heading-wrapper">
        <div className="heading">
          <h2 className="font_xxl fw_5">Facilities List</h2>
        </div>
      </div>

      <Searchbar />
      {/* TABS BUTTON START */}
      <TabButton
        buttonList={buttonList}
        currentKey={currentKey}
        onSelect={onTabSelect}
      />
      {/* TABS BUTTON END*/}

      {currentKey === "facilities" ? <Facilities /> : <ActiveFacilities />}
    </div>
  );
};
/* Component ends */

export default TenantShareFacility;
