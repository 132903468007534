// Intital Form Values
export const initialValues = (unit) => {
  if (unit?.is_active) {
    return {
      unit_name: unit?.unit_name,
      unit_floor: unit?.unit_floor,
      unit_size: unit?.unit_size?.toString(),
      toilet: unit?.toilet?.toString(),
      store: unit?.store?.toString(),
      target_rent_price: unit?.target_rent_price?.toString(),
      unit_description: unit?.unit_description || "",
      note: unit?.note || "",
      floor_plan: unit?.floor_plan?.url,
      // residential
      kitchen: unit?.kitchen?.toString() || "",
      bathroom: unit?.bathroom?.toString() || "",
      living_room: unit?.living_room?.toString() || "",
      bedroom: unit?.bedroom?.toString() || "",
      maids_room: unit?.maids_room?.toString() || "",
      balcony: unit?.balcony?.toString() || "",
      laundry: unit?.laundry?.toString() || "",
      // commercial
      pantry: unit?.pantry?.toString() || "",
      showroom: unit?.showroom?.toString() || "",
      terrace: unit?.terrace?.toString() || "",
      offices: unit?.offices?.toString() || "",
    };
  } else {
    return {
      unit_name: "",
      unit_floor: "",
      unit_size: "",
      toilet: "",
      store: "",
      target_rent_price: "",
      unit_description: "",
      note: "",
      floor_plan: "",
      // residential
      kitchen: "",
      bathroom: "",
      living_room: "",
      bedroom: "",
      maids_room: "",
      balcony: "",
      laundry: "",
      // commercial
      pantry: "",
      showroom: "",
      terrace: "",
      offices: "",
    };
  }
};

export const sizeMeasurements = [
  { name: "Square Feet (Sq. Ft.)", type: "Sq. Ft." },
  { name: "Square Meter (Sq. M.)", type: "Sq. M." },
];

// Form Validations
export const unitValidations = (
  values,
  isResidential,
  unitType,
  unitBedroom,
  propertyModal
) => {
  const error = {};

  /* Validations for Unit Name starts */
  if (values.unit_name === "") {
    error.unit_name = "Unit Name is required";
  }
  /* Validations for Unit Name ends */

  /* Validations for Unit Floor ends */
  if (values.unit_floor === "") {
    error.unit_floor = "Unit Floor is required";
  }
  /* Validations for Unit Floor starts */

  /* Validations for Unit Size (Sq.Ft.) starts */
  if (values.unit_size === "") {
    error.unit_size = "Unit Size is required";
  }
  /* Validations for Unit Size (Sq.Ft.) ends */

  /* Validations for Toilet starts */
  if (values.toilet === "") {
    error.toilet = "Toilet Count is required";
  }
  /* Validations for Toilet ends */

  /* Validations for Store starts */
  if (values.store === "") {
    error.store = "Store Count is required";
  }
  /* Validations for Store ends */

  /* Validations for Target Rent Price starts */
  if (values.target_rent_price === "" && propertyModal !== 1) {
    error.target_rent_price = "Target Rent Price is required";
  }
  /* Validations for Target Rent Price ends */

  // If Unit Type is Residential
  if (isResidential) {
    /* Validations for Kitchen starts */
    if (values.kitchen === "") {
      error.kitchen = "Kitchen Count is required";
    }
    /* Validations for Kitchen ends */

    /* Validations for Bedroom starts */
    if (!unitBedroom?.includes(unitType?.name)) {
      if (values.bedroom === "") {
        error.bedroom = "Bedroom Count is required";
      }
    }
    /* Validations for Bedroom ends */

    /* Validations for Bathroom starts */
    if (values.bathroom === "") {
      error.bathroom = "Bathroom Count is required";
    }
    /* Validations for Bathroom ends */

    /* Validations for Living Room starts */
    if (values.living_room === "") {
      error.living_room = "Living Room Count is required";
    }
    /* Validations for Living Room ends */

    /* Validations for Maid's Room starts */
    if (values.maids_room === "") {
      error.maids_room = "Maid's Room Count is required";
    }
    /* Validations for Maid's Room ends */

    /* Validations for Balcony starts */
    if (values.balcony === "") {
      error.balcony = "Balcony Count is required";
    }
    /* Validations for Balcony ends */

    /* Validations for Laundry starts */
    if (values.laundry === "") {
      error.laundry = "Laundry Count is required";
    }
    /* Validations for Laundry ends */
  }

  // If Unit Type is Commercial
  if (!isResidential) {
    /* Validations for Pantry starts */
    if (values.pantry === "") {
      error.pantry = "Pantry Count is required";
    }
    /* Validations for Pantry ends */

    /* Validations for Showroom starts */
    if (values.showroom === "") {
      error.showroom = "Pantry Count is required";
    }
    /* Validations for Showroom ends */

    /* Validations for Terrace starts */
    if (values.terrace === "") {
      error.terrace = "Terrace Count is required";
    }
    /* Validations for Terrace ends */

    /* Validations for Offices starts */
    if (values.offices === "") {
      error.offices = "Office Count is required";
    }
    /* Validations for Offices ends */
  }

  return error;
};
