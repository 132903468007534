/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  fetchUnitDetailByID,
  getUnitDetail,
} from "../../setup/store/slices/unitSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import MUI component starts */
/* Import MUI component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import EditUnitForm from "./local-component/EditUnitForm";
import Header from "./local-component/Header";
import BreadCrumb from "../../components/ui/other/BreadCrumb/BreadCrumb";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const EditUnit = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const { unitId } = useParams();
  const dispatch = useDispatch();
  const unit = useSelector(getUnitDetail);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */

  useEffect(() => {
    if (unitId) {
      dispatch(fetchUnitDetailByID({ unit_id: unitId }));
    }
  }, [unitId]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      <BreadCrumb type="edit_unit" />
      <div className="edit-unit-page-wrapper">
        <div className="container_lg">
          <Header unit={unit} />
        </div>

        <div className="container_sm">
          <EditUnitForm />
        </div>
      </div>
    </>
  );
};
/* Component ends */

export default EditUnit;
