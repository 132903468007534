/**
 * This component is a Modal that is shown on success
 */

import React, { useRef, useState } from "react";

import { Formik } from "formik";
/* Import react bootstrap component starts */
import { Modal } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomTextArea from "../../../../ui/input/CustomTextArea/CustomTextArea";
import CustomButton from "../../../../ui/button/CustomButton/CustomButton";
import TextButton from "../../../../ui/button/TextButton/TextButton";
/* Import local pages and component ends */

import "./style.scss";
import CustomSelectInput from "../../../../ui/input/CustomSelectInput/CustomSelectInput";
import { getUserTheme } from "../../../../../setup/store/slices/authSlice";
import { useSelector } from "react-redux";

/* Component starts */
const AssignSoModal = (props) => {
  /* Props destructuring starts */
  const { showModal, size = "lg", onClose, data, onSelected } = props;
  /* Props destructuring ends */

  const [selectedOfficer, setSelectedOfficer] = useState();
  const userTheme = useSelector(getUserTheme);

  const initialFormValues = {
    so: "",
  };

  /* ************* Component rendering. JSX code ************* */
  return (
    <Modal
      size={size}
      centered
      show={showModal}
      className="so-assign-modal-wrapper"
    >
      <Modal.Body
        className={`${userTheme === "dark" ? "bg_darkTheme" : "bg_lightTheme"}`}
      >
        <div className="modal-header-custom">
          <p className="font_l fw_6 font_grey text-start">SO Manual Assign</p>
        </div>
        <div className="modal-body-custom">
          <Formik
            onSubmit={(values) => onSelected(selectedOfficer)}
            initialValues={initialFormValues}
            validateOnBlur={true}
            enableReinitialize={true}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                {/* Form Fields */}
                <div className="form-wrapper form-wrapper-flex">
                  {/* Select SO */}
                  <div className="form-group">
                    <CustomSelectInput
                      name="officer"
                      label="Officers"
                      handleBlur={handleBlur}
                      onChange={handleChange}
                      valueExtractor={(item) =>
                        `${item?.first_name} ${item?.last_name}`
                      }
                      setValue={setSelectedOfficer}
                      options={data}
                      value={selectedOfficer}
                      formikState={false}
                      emptyOption={false}
                    />
                  </div>
                </div>

                {/* Submit and Cancel */}
                <div className="button-wrapper text-center">
                  <>
                    <CustomButton type="submit" size="l">
                      Submit
                    </CustomButton>
                    <CustomButton
                      size="l"
                      variant="red"
                      onClick={onClose}
                      className="custom-text-btn"
                    >
                      Cancel
                    </CustomButton>
                  </>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </Modal.Body>
    </Modal>
  );
};
/* Component ends */

export default AssignSoModal;
