/**
 *
 */

import React from "react";

/* Import configuration starts */
import { useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import MUI component starts */
/* Import MUI component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import CommunitySlider from "../../../components/module/Community/CommunitySlider/CommunitySlider";
/* Import local pages and component ends */

/* Component starts */
const Communities = (props) => {
  /* Props destructuring starts */
  const { communityList } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isClient = localStorage.getItem("user_role") === "Client";
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const onCommunityItemClicked = async (community) => {
    navigate(`/communities/${community._id}`);
  };
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="communities">
      <div className="heading">
        <h2 className="font_xl fw_6">Communitites ({communityList?.length})</h2>
        {isClient && (
          <Link to="/communities/add" className="font_m fw_6 d_block">
            + Add Community
          </Link>
        )}
      </div>

      {communityList?.length > 0 ? (
        <CommunitySlider
          heading=""
          onClickCommunity={onCommunityItemClicked}
          communityList={communityList}
        />
      ) : (
        <div className="no-comm-prop-added">
          <h3 className="text-center fw_6 font_m font_yellow">
            No Community Added!
          </h3>
        </div>
      )}
    </div>
  );
};
/* Component ends */

export default Communities;
