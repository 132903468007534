import React from "react";
/* Import configuration starts */
/* Import configuration ends */

/* Import MUI component starts */
/* Import MUI component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import Modules from "./local-component/Modules";
import BreadCrumb from "../../components/ui/other/BreadCrumb/BreadCrumb";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const Dashboard = () => {
  /* Component states declaration starts */
  /* Component states declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      <BreadCrumb type="dashboards" />
      <div className="dashboard-page-wrapper">
        <div className="container_lg">
          <h2 className="font_xxl fw_6 w-50">Dashboards Details</h2>
          <Modules />
        </div>
      </div>
    </>
  );
};
/* Component ends */

export default Dashboard;
