/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import { SuccessIconOutlinedBlue } from "../../../../../setup/constants/images";
import CancelApplicationBlock from "../../../Unit/UnitSliderTenantHome/sub-component/CancelApplicationBlock";
/* Import local pages and component ends */

/* Component starts */
const ApplicationPendingReview = (props) => {
  /* Props destructuring starts */
  const { unit, fetchTenantUnitsNew } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div>
      <div className="status-icon-wrapper text-center">
        <img src={SuccessIconOutlinedBlue} alt="Warning" />
      </div>

      <h3 className="text-center fw_6 font_xxl font_blue mt-4">Thank You!</h3>
      <p className="font_l fw_5 text-center mt-4">
        Your application was successfully submitted
      </p>

      <p className="font_l fw_5 text-center mt-4 font_yellow">Pending Review</p>

      <CancelApplicationBlock
        unit={unit}
        fetchTenantUnitsNew={fetchTenantUnitsNew}
      />
    </div>
  );
};
/* Component ends */

export default ApplicationPendingReview;
