/*
 * Slice to handle TENANT Guest Pass Module
 */

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { axiosInstance } from "../axios";
import { API_URL } from "../../config/devKeys";
import {
  setLoading,
  setShowUploadProgress,
  setUploadProgress,
} from "./unpersistedSlice";
import { PLATFORM } from "../../platformUtil";
// import { setLoading } from "./unpersistedSlice";
// import { PLATFORM } from "../../platformUtil";

const initialState = {
  guestAccessType: [],
  contractorAccessType: [],
  guestPassList: [],
};

// FETCH Guest Access Type -> Select Options For Tenant's Guest Access Pass
export const fetchGuestAccessType = createAsyncThunk(
  "guest_access_type/list",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await axios.get(
        API_URL + "/guest_access_pass_type/list",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        return data.data;
      } else {
        rejectWithValue(data.error);
      }
    } catch (error) {
      return rejectWithValue(error?.response);
    }
  }
);

// FETCH Contractor Access Type -> Select Options For Tenant's Guest Access Pass
export const fetchContractorAccessType = createAsyncThunk(
  "contractor_access_pass_type/list",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await axios.get(
        API_URL + "/contractor_access_pass_type/list",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        return data.data;
      } else {
        rejectWithValue(data.error);
      }
    } catch (error) {
      return rejectWithValue(error?.response);
    }
  }
);

// ADD Visitor Guest/Contractor Pass -> Tenant Side
export const addVisitorsPass = createAsyncThunk(
  "visitor-pass/add",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const formData = new FormData();
      Object.keys(payload).map((key) => {
        if (key === "image") {
          if (PLATFORM !== "web") {
            formData.append("image", {
              name: payload.image.name,
              type: payload.image.type,
              uri:
                PLATFORM === "android"
                  ? payload.image.uri
                  : payload.image.uri.replace("file://", ""),
            });
          } else {
            formData.append("image", payload[key], payload[key].name);
          }
        } else {
          formData.append(key, payload[key]);
        }
      });
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }
      // const response = await fetch(API_URL + "/visitor-pass/add", {
      //   method: "POST",
      //   headers: headers,
      //   body: formData,
      // });
      // const data = await response.json();

      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "POST",
        url: "/visitor-pass/add",
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      // const data = await response.json();

      const { data } = await axiosInstance.request(config);

      dispatch(setLoading(false));
      if (data.success) {
        dispatch(
          fetchVisitorGuestPassList({
            property_id: payload?.property_id,
            type: payload?.type,
          })
        );
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// FETCH Visitors GUEST PASS LIST - Tenant Side --> Unit_ID
export const fetchVisitorGuestPassList = createAsyncThunk(
  "tenant-visitor-pass/list",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(
        API_URL + "/visitor-pass/list",
        // 'http://localhost:3000/visitor-pass/list',
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            ...payload,
          }),
        }
      );

      const data = await response.json();
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// EDIT Visitor Guest/Contractor Pass -> Tenant Side
export const editVisitorsPass = createAsyncThunk(
  "visitor-pass/update",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const formData = new FormData();
      Object.keys(payload).map((key) => {
        if (key === "image") {
          if (PLATFORM !== "web") {
            formData.append("image", {
              name: payload.image.name,
              type: payload.image.type,
              uri:
                PLATFORM === "android"
                  ? payload.image.uri
                  : payload.image.uri.replace("file://", ""),
            });
          } else {
            formData.append("image", payload[key], payload[key].name);
          }
        } else {
          formData.append(key, payload[key]);
        }
      });
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }
      // const response = await fetch(API_URL + `/visitor-pass/update`, {
      //   method: "POST",
      //   headers: headers,
      //   body: formData,
      // });
      // const data = await response.json();

      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "POST",
        url: "/visitor-pass/update",
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      // const data = await response.json();

      const { data } = await axiosInstance.request(config);

      dispatch(setLoading(false));
      if (data.success) {
        dispatch(fetchVisitorGuestPassList());
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Revoked the Guest Pass --> Tenant ( Tenant )
export const revokeGuestPass = createAsyncThunk(
  "visitor-pass/revoked",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));

      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };

      const response = await fetch(
        `${API_URL}/visitor-pass/revoked?id=${payload.id}`,
        {
          method: "PATCH",
          headers: headers,
        }
      );
      const data = await response.json();

      dispatch(setLoading(false));
      console.log(data, "Rejected");
      if (data.success) {
        await dispatch(fetchVisitorGuestPassList());
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return error?.response;
    }
  }
);

// Visitor Pass Detail Based on ID
export const fetchVisitorPassDetail = createAsyncThunk(
  "visitorpass/get",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.get(
        `${API_URL}/visitor-pass/get?id=${payload.id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const guestPassSlice = createSlice({
  name: "guestPass",
  initialState,
  extraReducers: {
    [fetchGuestAccessType.rejected]: (state, action) => {
      state.guestAccessType = [];
    },
    [fetchGuestAccessType.fulfilled]: (state, action) => {
      state.guestAccessType = action.payload;
    },
    [fetchContractorAccessType.rejected]: (state, action) => {
      state.contractorAccessType = [];
    },
    [fetchContractorAccessType.fulfilled]: (state, action) => {
      state.contractorAccessType = action.payload;
    },
    [fetchVisitorGuestPassList.rejected]: (state, action) => {
      state.guestPassList = [];
    },
    [fetchVisitorGuestPassList.fulfilled]: (state, action) => {
      state.guestPassList = action.payload;
    },
  },
});

// To Get The Guest Acces Types For Tenants
export const getGuestAccessType = (state) => state.guestPass.guestAccessType;

// To Get The Contractor Acces Types For Tenants
export const getContractorAccessType = (state) =>
  state.guestPass.contractorAccessType;

// To Get The Visitor Guest List For Tenants
export const getGuestPassList = (state) => state.guestPass.guestPassList;

export default guestPassSlice.reducer;
