export const fieldValue = {};

// Validations
export const validation = (values, descList, imgList, isT1) => {
  const error = {};

  descList.forEach((descItm, descIndx) => {
    const description = `description_${descItm}`;
    const estimate = `estimate_${descItm}`;

    // ********************** Creating Object Keys **********************
    if (values[description] === "") {
      error[description] = "Description is required";
    }

    if (isT1 && values[estimate] === "") {
      error[estimate] = "Estimation is required";
    }

    imgList[descIndx].forEach((docItem, docIndex) => {
      const document = `document_${descItm}_${docItem}`;

      if (values[document] === null) {
        error[document] = "Attachment is required";
      }
    });
  });

  return error;
};

// Adding Description field
export const addMoreDesc = (
  formRef,
  setFormValue,
  desc,
  setDesc,
  setImgList
) => {
  const lastIndx = desc.length === 0 ? 0 : desc[desc.length - 1] + 1;
  const currentFormState = { ...formRef.current.values };

  setFormValue(() => {
    // Generating new field name according to array indexes
    currentFormState[`description_${lastIndx}`] = "";
    currentFormState[`estimate_${lastIndx}`] = "";
    return currentFormState;
  });

  setDesc((p) => [...p, lastIndx]);
  setImgList((p) => [...p, []]);
};

// Removing Description field
export const removeDesc = (
  formRef,
  setFormValue,
  desc,
  setDesc,
  imgList,
  setImgList,
  index
) => {
  const removeVal = desc[index];
  const currentFormState = { ...formRef.current.values };

  imgList[index].forEach((item, index) => {
    delete currentFormState[`document_${removeVal}_${item}`];
  });

  setFormValue(() => {
    delete currentFormState[`description_${removeVal}`];
    delete currentFormState[`estimate_${removeVal}`];
    return currentFormState;
  });

  setDesc((prevState) => {
    const prevFields = [...prevState];
    prevFields.splice(index, 1);
    return prevFields;
  });

  setImgList((prevState) => {
    const prevFields = [...prevState];
    prevFields.splice(index, 1);
    return prevFields;
  });
};

// Adding more img as per task description
export const addMoreImg = (
  formRef,
  setFormValue,
  imgList,
  setImgList,
  descKey,
  index
) => {
  const lastIndx = imgList.length === 0 ? 0 : imgList[imgList.length - 1] + 1;
  const currentFormState = { ...formRef.current.values };

  setFormValue(() => {
    // Generating new field name according to array indexes
    currentFormState[`document_${descKey}_${lastIndx}`] = null;
    return currentFormState;
  });

  setImgList((p) => {
    const cloneArray = [...p];
    cloneArray[index].push(lastIndx);
    return cloneArray;
  });
};

export const removeImg = (
  formRef,
  setFormValue,
  imgList,
  setImgList,
  descList,
  descIndex,
  docIndex
) => {
  const descKey = descList[descIndex];
  const removeDocKey = imgList[descIndex][docIndex];
  const currentFormState = { ...formRef.current.values };

  setFormValue(() => {
    delete currentFormState[`document_${descKey}_${removeDocKey}`];
    return currentFormState;
  });

  setImgList((p) => {
    const cloneArray = [...p];
    cloneArray[descIndex].splice(docIndex, 1);
    return cloneArray;
  });
};
