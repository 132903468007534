import GLOBAL from "../../../../setup/constants/global";

export const logTitle = (item) => {
  let title = "";
  let role = item?.requester_role?.name;
  let currentStatus = item?.current_status;
  let logType = item?.log_type;

  //   Generating title according to action string
  switch (item?.action) {
    // Ticket Assigned
    case GLOBAL.MAINTENANCE_LOG_STATUS.ASSIGNED:
      title = `Ticket assigned to ${role}`;
      break;

    // Maintenance-Accepted
    case GLOBAL.MAINTENANCE_LOG_STATUS.MAINTENANCE_ACCEPTED:
      title = `Maintenance job completed by ${role}`;
      break;

    // Technician Assigned
    case "Technician Assigned":
      if (logType === "URIC Initial") {
        title = `Task assigned to technician. Waiting to get approved for UIRC Phase 1 Initial`;
      } else if (logType === "URIC Phase 2") {
        title = `Task assigned to technician`;
      } else if (logType === "Additional") {
        title = `Additional Task assigned to technician.`;
      } else {
        title = `Task assigned to technician. Waiting to get approved for UIRC Phase 1 Final`;
      }
      break;

    // Readiness Ticket false
    case "Readiness Ticket false":
      title = `Rejected UIRC request by ${role}`;
      break;

    // Readiness Ticket Accepted
    case "Readiness Ticket Accepted":
      if (logType === "URIC Initial") {
        title = `Approved URIC Phase 1 Initial request by ${role}`;
      } else {
        title = `Approved URIC Phase 1 Final request by ${role}`;
      }
      break;

    // MT submitted Readiness card Initial P-1
    case "MT submitted Readiness card Initial P-1":
      title = `${role} submitted URIC Phase 1 Intial form`;
      break;

    // FM pending initial p1 report review
    case "FM pending initial p1 report review":
      title = `Review of URIC Phase 1 Intial by FM is pending`;
      break;

    // Readiness Ticket Rejected
    case "Readiness Ticket Rejected":
      if (logType === "URIC Initial") {
        title = `UIRC Phase 1 Initial report rejected by ${role}`;
      } else {
        title = `UIRC Phase 1 Final report rejected by ${role}`;
      }
      break;

    // Readiness Ticket Reassigned To FM
    case "Readiness Ticket Reassigned To FM":
      title = `Readiness ticket reassigned to FM`;
      break;

    // Readiness Ticket Reassigned
    case "Readiness Ticket Reassigned":
      if (currentStatus === "Reassigned To FM") {
        title = `Readiness ticket reassigned to FM`;
      } else {
        if (logType === "URIC Final") {
          title = `UIRC Phase 1 Final reassigned and assigned Technician to it`;
        } else {
          title = `UIRC Phase 1 Initial reassigned and assigned Technician to it`;
        }
      }
      break;

    // Readiness Ticket Approved
    case "Readiness Ticket Approved":
      if (logType === "URIC Initial") {
        title = `UIRC Phase 1 Initial approved by ${role}`;
      } else {
        title = `UIRC Phase 1 Final approved by ${role}`;
      }
      break;

    // UIRC final ticket created
    case "UIRC final ticket created":
      title = `UIRC Phase 1 Final created`;
      break;

    // MT submitted Readiness card Final P-1
    case "MT submitted Readiness card Final P-1":
      title = `${role} submitted URIC Phase 1 Final form`;
      break;

    // FM pending final p1 report review
    case "FM pending final p1 report review":
      title = `Review of URIC Phase 1 Final by FM is pending`;
      break;

    //
    case "FM skipped final card":
      title = `UIRC Phase 1 Final Assessment skipped by FM`;
      break;

    // UIRC initial assigned to PM
    case "UIRC initial assigned to PM":
      title = `UIRC Phase 1 Initial sent to PM for review`;
      break;

    // UIRC final assigned to PM
    case "UIRC Final assigned to PM":
      title = `UIRC Phase 1 Final sent to PM for review`;
      break;

    // UIRC phase 2 assigned to FM
    case "UIRC phase 2 assigned to FM":
      title = `UIRC Phase 2 created`;
      break;

    // Quoatation updated succesfully
    case "Quoatation updated succesfully":
      title = `Quoatation submitted succesfully`;
      break;

    // Quoatation submitted succesfully
    case "Quoatation submitted succesfully":
      title = `Quoatation submitted succesfully`;
      break;

    // Quoatation assigned To PM
    case "Quoatation assigned To PM":
      title = `Quotation assigned To PM`;
      break;

    // Quoatation Approved by PM
    case "Quoatation Approved by PM":
      title = `Spare Parts/Service Approved. Assigned to Facility Manager`;
      break;

    // Quoatation Approved by PM
    case "FM received approval":
      title = `Waiting for Facility Manager to place order`;
      break;

    // Order status updated by FM
    case "Order status updated by FM":
      if (currentStatus === "Order Placed") {
        title = `Order has been placed`;
      } else {
        title = `Spare parts has arrived`;
      }
      break;

    // Quoatation Rejected by PM
    case "Quoatation Rejected by PM":
      title = `Quoatation Rejected by PM`;
      break;

    // Quoatation reassigned to FM
    case "Quoatation reassigned to FM":
      title = `Quoatation reassigned to FM`;
      break;

    // Keys are ready to collect
    case "Keys are ready to collect":
      title = `Keys are ready to collect`;
      break;

    //
    case "Keys Rejected By Tenant":
      title = `Keys Rejected By Tenant`;
      break;

    //
    case "Keys collected by Tenant":
      title = `Keys collected by Tenant`;
      break;

    //
    case "Closed":
      title = `Ticket Closed`;
      break;

    //
    case "Final Inspection is assigned to Owner":
      title = `Final Inspection is assigned to Owner`;
      break;

    //
    case "Initial Inspection submitted by Owner":
      title = `Initial Inspection submitted by Owner`;
      break;

    //
    case "Owner skipped final card":
      title = `Owner skipped final card`;
      break;

    //
    case "UIRC phase 2 assigned to Owner":
      title = `UIRC phase 2 assigned to Owner`;
      break;

    //
    case "Quoatation approved To Owner":
      title = `Quoatation approved To Owner`;
      break;

    //
    case "Owner submitted Readiness card Final P-1":
      title = `Owner submitted Readiness card Final P-1`;
      break;

    //
    case "Owner uploaded report":
      title = `Owner uploaded report`;
      break;

    //
    case "":
      title = ``;
      break;

    // Default Case...
    default:
      title = item?.action;
  }

  return title;
};

export const statusColorGenerate = (status) => {
  switch (status) {
    case "New":
      return "font_blue";

    case "Closed":
      return "font_green";

    case "Rejected":
      return "font_red";

    case "Completed":
      return "font_green";

    default:
      return "";
  }
};

export const orderStatusGenerate = (status) => {
  if (status === "Approved") {
    return {
      status: "Order Placed",
      button: "Place Order",
    };
  }
  if (status === "Order Placed") {
    return {
      status: "Parts Recieved",
      button: "Recieve Parts",
    };
  }
};

export const taskStatusGenerate = (status) => {
  switch (status) {
    case "technician_acceptance_pending":
    case "Pending":
      return { color: "font_yellow", text: "Pending Technician approval" };

    case "accepted_by_technician":
      return { color: "font_green", text: "Technician Accepted" };

    case "technician_on_the_way":
      return { color: "font_yellow", text: "Technician On The Way" };

    case "technician_on_the_location":
      return { color: "font_yellow", text: "Technician On The Location" };

    case "rejected_by_technician":
      return { color: "font_red", text: "Technician Rejected" };

    case "Completed":
      return { color: "font_blue", text: "Task Completed" };

    case "pm_approval_pending":
      return { color: "font_yellow", text: "PM Approval Pending" };

    case "Approved":
      return { color: "font_green", text: "Approved" };

    case "rejected_by_pm":
      return { color: "font_red", text: "Rejected By PM" };

    default:
      return {};
  }
};

const userActionPermission = (item, user) => {
  const logRequester = item?.requester?._id;
  const logRequesterRole = item?.requester_role?._id;
  const currentUser = user?._id;
  const currentUserRole = user.role_id;
  if (logRequesterRole === currentUserRole && logRequester === currentUser) {
    return true;
  }
  return false;
};

export const ownerAddQuote = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  const requesterRole = item?.requester_role?.name;
  if (!userActionPermission(item, user)) {
    return false;
  }
  if (
    action === "UIRC phase 2 assigned to Owner" ||
    action === "Owner uploaded report"
  ) {
    return true;
  }
};

export const ownerCompletesTask = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  const requesterRole = item?.requester_role?.name;
  if (!userActionPermission(item, user)) {
    return false;
  }
  if (
    action === "UIRC phase 2 assigned to Owner" ||
    action === "Quoatation approved To Owner" ||
    action === "Owner uploaded report" ||
    action === "Owner received approval"
  ) {
    return true;
  }
};

// FM to assign technician
export const canAssignTechnician = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  const requesterRole = item?.requester_role?.name;
  if (!userActionPermission(item, user)) {
    return false;
  }
  if (
    requesterRole !== "Owner" &&
    (action === "Ticket Assigned" ||
      action === "Readiness Ticket false" ||
      action === "Readiness Ticket Reassigned To FM" ||
      action === "Unit Readiness card created" ||
      // (action === "Readiness Ticket Reassigned" &&
      //   currentStatus === "Reassigned To FM") ||
      action === "UIRC final ticket created")
  ) {
    return true;
  }

  return false;
};

export const canFillURICP1_t1 = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  const requesterRole = item?.requester_role?.name;
  if (!userActionPermission(item, user)) {
    return false;
  }
  if (requesterRole === "Owner" && action === "Unit Readiness card created") {
    return true;
  }
};

export const canFillURICFinl_t1 = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  const requesterRole = item?.requester_role?.name;
  if (!userActionPermission(item, user)) {
    return false;
  }
  if (
    action === "Final Inspection is assigned to Owner" ||
    action === "UIRC final ticket created"
  ) {
    return true;
  }
};

export const canFmRespond2PMrejectUIRC = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  if (!userActionPermission(item, user)) {
    return false;
  }
  if (
    action === "Readiness Ticket Reassigned" &&
    currentStatus !== "Reassigned To Technician"
  ) {
    return true;
  }
  return false;
};

// MT to take action for UIRC Phase 1 Intial request
export const mtRespondUIRCP1Init = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  if (!userActionPermission(item, user)) {
    return false;
  }
  if (
    (action === "Technician Assigned" ||
      (action === "Readiness Ticket Reassigned" &&
        currentStatus !== "Reassigned To FM")) &&
    logType === "URIC Initial"
  ) {
    return true;
  }

  return false;
};

// MT to take action for UIRC Phase 1 Final request
export const mtRespondUIRCP1Finl = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  if (!userActionPermission(item, user)) {
    return false;
  }
  if (
    (action === "Technician Assigned" ||
      action === "Readiness Ticket Reassigned") &&
    logType === "URIC Final"
  ) {
    return true;
  }

  return false;
};

// MT to fill URIC Phase 1
export const canFillURICP1 = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  if (!userActionPermission(item, user)) {
    return false;
  }
  if (action === "Readiness Ticket Accepted") {
    return true;
  }
};

// FM to approve URIC Phase 1 Initial form details
export const fmCanApproveURICP1Init = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  if (!userActionPermission(item, user)) {
    return false;
  }
  if (
    action === "FM pending initial p1 report review" &&
    logType === "URIC Initial"
  ) {
    return true;
  }
};

// FM to approve URIC Phase 1 Final form details
export const fmCanApproveURICP1Finl = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  if (!userActionPermission(item, user)) {
    return false;
  }
  if (
    action === "FM pending final p1 report review" &&
    logType === "URIC Final"
  ) {
    return true;
  }
};

// Checking if all estimations have been put on UIRC P1
export const putAllEstimations = (items) => {
  return items.every((item, index) => item && !isNaN(item));
};

// FM to review URIC Phase 1 Initial form
export const canReviewUIRCP1Init = (items) => {
  return putAllEstimations(items);
};

// FM to review URIC Phase 1 Final form
export const canReviewUIRCP1Finl = (items) => {
  return putAllEstimations(items);
};

// FM to skip URIC Phase 1 Final form
export const canSkipFinalAsessment = (item, user, finalURICStatus) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  if (!userActionPermission(item, user)) {
    return false;
  }
  if (
    (finalURICStatus === "Pending" &&
      currentStatus === "Ticket Assigned to FM") ||
    action === "Final Inspection is assigned to Owner" ||
    action === "UIRC final ticket created"
  ) {
    return true;
  }
};

// PM to approve UIRC Phase 1 Initial Card
export const pmCanApproveUIRCP1Init = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  if (!userActionPermission(item, user)) {
    return false;
  }
  if (action === "UIRC initial assigned to PM") {
    return true;
  }
};

// PM to approve UIRC Phase 1 Final Card
export const pmCanApproveUIRCP1Finl = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  if (!userActionPermission(item, user)) {
    return false;
  }
  if (action === "UIRC Final assigned to PM") {
    return true;
  }
};

// FM to start UIRC Phase 2
export const canStartP2 = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  if (!userActionPermission(item, user)) {
    return false;
  }
  if (
    // action === "UIRC phase 2 assigned to FM" &&
    // logType === "URIC Phase 2" ||
    // logType === "Additional"
    false
  ) {
    return true;
  }
};

// FM to add quote
export const canAddQuote = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  if (!userActionPermission(item, user)) {
    return false;
  }
  if (action === "UIRC phase 2 assigned to FM") {
    return true;
  }
};

// FM to update quatation
export const fmCanEditQuote = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  if (!userActionPermission(item, user)) {
    return false;
  }
  if (action === "Quoatation reassigned to Manager") {
    return true;
  }
};

// PM to approve quote
export const pmCanApproveQuote = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  if (!userActionPermission(item, user)) {
    return false;
  }
  if (action === "Quoatation assigned To PM") {
    return true;
  }
};

// FM to order spare part
export const fmCanChangeOrderStatus = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  const isPartExist = item?.data?.is_part_exist;
  if (!userActionPermission(item, user)) {
    return false;
  }
  if (
    (isPartExist && action === "Manager received approval") ||
    (action === "Order status updated by Manager" &&
      currentStatus === "Order Placed")
  ) {
    return true;
  }
};

// FM to assign multiple technician to multiple UIRC inspections items
export const fmCanAssignPhase2MT = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  const isPartExist = item?.data?.is_part_exist;
  if (!userActionPermission(item, user)) {
    return false;
  }
  if (
    (!isPartExist && action === "Manager received approval") ||
    (action === "Order status updated by Manager" &&
      currentStatus === "Parts Recieved")
  ) {
    return true;
  }
};

const checkTaskAssignedTo = (taskItem, user) => {
  const logRequester = taskItem?.assigned?._id;
  const logRequesterRole = taskItem?.assigned_role?._id;
  const currentUser = user?._id;
  const currentUserRole = user.role_id;
  if (logRequesterRole === currentUserRole && logRequester === currentUser) {
    return true;
  }
  return false;
};

// MT to respont phase 2 task
export const mtCanRespondP2Task = (taskItem, user) => {
  const status = taskItem?.status;
  if (!checkTaskAssignedTo(taskItem, user)) {
    return false;
  }
  if (status === "technician_acceptance_pending" || status === "Pending") {
    return true;
  }
};

// FM to re-assign technician to multiple UIRC inspections items
export const fmCanReAssignPhase2MT = (taskItem, user) => {
  const status = taskItem?.status;
  if (localStorage.getItem("user_role") !== "Facility Manager") {
    return false;
  }
  if (status === "rejected_by_technician" || status === "rejected_by_pm") {
    return true;
  }
};

// MT to change arrival status
export const mtCanChangeArrivalStatus = (taskItem, user) => {
  const status = taskItem?.status;
  if (!checkTaskAssignedTo(taskItem, user)) {
    return false;
  }
  if (
    status === "accepted_by_technician" ||
    status === "technician_on_the_way"
  ) {
    return true;
  }
};

// MT to fill UIRC Phase 2 form
export const mtCanFillUIRCP2 = (taskItem, user) => {
  const status = taskItem?.status;
  if (!checkTaskAssignedTo(taskItem, user)) {
    return false;
  }
  if (status === "technician_on_the_location") {
    return true;
  }
};

// Checking if all items are completed
export const allTaskComplete = (UIRCp2) => {
  const items = UIRCp2.items;
  if (UIRCp2) {
    return (
      items.every((item) => item.status === "Completed") ||
      UIRCp2?.status === "pm_approval_pending" ||
      UIRCp2?.status === "fm_approval_pending"
    );
  }
};

// Checking if all items are approved by FM
export const allItemsApprovedByFM = (UIRCp2) => {
  const items = UIRCp2.items;
  if (UIRCp2) {
    return (
      items.every((item) => item.status === "pm_approval_pending") ||
      UIRCp2?.status === "pm_approval_pending"
    );
  }
};

// FM to respond to completed task
export const fmCanRespondToTask = (taskItem, user) => {
  const status = taskItem?.status;
  if (localStorage.getItem("user_role") !== "Facility Manager") {
    return false;
  }
  if (status === "Completed" || status === "fm_approval_pending") {
    return true;
  }
};

// PM to respond to approved task by FM
export const pmCanRespondToTask = (taskItem, user) => {
  const status = taskItem?.status;
  if (localStorage.getItem("user_role") !== "Property Manager") {
    return false;
  }
  if (status === "pm_approval_pending") {
    return true;
  }
};

export const showActionGroup = (taskItem, user, UIRCp2) => {
  return (
    mtCanRespondP2Task(taskItem, user) ||
    fmCanReAssignPhase2MT(taskItem, user) ||
    mtCanFillUIRCP2(taskItem, user) ||
    mtCanChangeArrivalStatus(taskItem, user) ||
    (allTaskComplete(UIRCp2) && fmCanRespondToTask(taskItem, user)) ||
    (allItemsApprovedByFM(UIRCp2) && pmCanRespondToTask(taskItem, user))
  );
};

// PM to respond to key rejection
export const pmCanRespondKeyReject = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  if (!userActionPermission(item, user)) {
    return false;
  }
  if (logType === "keys" && currentStatus === "Rejected") {
    return true;
  }
};
