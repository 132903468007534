/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import GLOBAL from "../../setup/constants/global";
import { useSelector, useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  getCommunities,
  fetchCommunities,
} from "../../setup/store/slices/communitySlice";
import {
  fetchProperties,
  getProperties,
} from "../../setup/store/slices/propertySlice";
import { fetchUser } from "../../setup/store/slices/authSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import Overview from "./local-components/Overview";
import Communities from "./local-components/Communities";
import Properties from "./local-components/Properties";
import HeadingAndNavigation from "./local-components/HeadingAndNavigation";
import NoCommunityAndProperty from "./local-components/NoCommunityAndProperty";
import BreadCrumb from "../../components/ui/other/BreadCrumb/BreadCrumb";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const Home = () => {
  /* Props destructuring starts */
  /* Props destructuring ends */

  /* Component states declaration starts */
  const [filteredCommunities, setFilteredCommunities] = useState([]);
  const [filteredProperties, setFilteredProperties] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [userLoaded, setUserLoaded] = useState(false);
  const [userData, setUserData] = useState(null);
  /* Component states declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();

  // Redux Selector
  const communities = useSelector(getCommunities);
  const properties = useSelector(getProperties);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  // Fetching all properties and communitites on initital load
  useEffect(() => {
    dispatch(fetchCommunities());
    dispatch(fetchProperties());
  }, []);

  // Setting all properties to "filteredProperties" state on loading of all properties
  useEffect(() => {
    if (properties) {
      setFilteredProperties(properties);
    }
  }, [properties]);

  // Setting all communitites to "filteredCommunities" state on loading of all communitites
  useEffect(() => {
    if (communities) {
      setFilteredCommunities(communities);
    }
  }, [communities]);

  const getUser = async () => {
    // Creating or collecting payload data to be sent
    const payload = {};
    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      const result = await dispatch(fetchUser());
      setUserLoaded(true);
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setUserData(null);
          break;

        case GLOBAL.FULFILLED:
          setUserData(result.payload);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  useEffect(() => {
    getUser();
  }, []);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  if (userLoaded && !userData)
    return (
      <>
        {console.log(userData, "User Data")}
        <Navigate to="/" replace />
      </>
    );

  if (
    userLoaded &&
    (properties === undefined || properties?.length === 0) &&
    (communities === undefined || communities?.length === 0)
  )
    return <NoCommunityAndProperty />;

  if (userLoaded)
    return (
      <div className="home-page-wrapper container_lg">
        <BreadCrumb type="home" />

        <HeadingAndNavigation />
        {/* <Overview /> */}

        <Overview />

        <Communities communityList={filteredCommunities} />

        <Properties propertyList={filteredProperties} />
      </div>
    );
};
/* Component ends */

export default Home;
