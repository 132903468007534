/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import GLOBAL from "../../../../../setup/constants/global";
import moment from "moment";
import { tenancyType } from "../helper";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Table } from "react-bootstrap";

/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { EyeFilledBlue } from "../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../../../ui/button/IconButton/IconButton";
import RentHistoryDetailModal from "../../NewTenantPMReview/RentHistoryDetailModal/RentHistoryDetailModal";
/* Import local pages and component ends */

/* Component starts */
const RentalHistory = (props) => {
  /* Props destructuring starts */
  const { data, index } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [showModal, setShowModal] = useState(false);
  const [detailData, setDetailData] = useState({});
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // To View Detail About the Rent Hisitory
  const handleViewReport = (item) => {
    setDetailData(item);
    setShowModal(!showModal);
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="rent-wrapper">
      <div className="rent-heading">
        <p className="font_l label">
          {" "}
          {index !== undefined ? `${index} ${"-"}` : ""} Rental History
        </p>
      </div>

      {/* ID Verfication Table */}
      <div className="custom-table-wrapper">
        {data?.length > 0 && (
          <Table className="custom-table font_s" borderless responsive>
            {/* Table Heading */}
            <thead>
              <tr>
                <th>Property</th>
                <th>Tenancy Type</th>
                {/* <th>Address</th>
                <th>Unit </th>
                <th>Unit Type</th>
                <th>Mobile</th>
                <th>Email</th> */}
                <th>From </th>
                <th>To</th>
                <th>Action</th>
              </tr>
            </thead>
            {/* Table Body */}
            <tbody>
              {data?.map((item, index) => (
                <tr key={index}>
                  {/* Property Name */}
                  <td>{item?.property_name}</td>
                  {/* Tenancy Type */}
                  <td>{tenancyType[item.tenancy_type]}</td>
                  {/* Property Address */}
                  {/* <td>{item?.address}</td> */}
                  {/* Tenancy Type */}
                  {/* <td>{tenancyType[item.tenancy_type]}</td> */}
                  {/* Unit */}
                  {/* <td>{item?.unit}</td> */}
                  {/* Unit Type */}
                  {/* <td>{item?.type}</td> */}
                  {/* Mobile */}
                  {/* <td className="txt-nowrap">
                    {item.contact_number !== undefined
                      ? `${item.countrycode} ${item.contact_number}`
                      : "-"}
                  </td> */}
                  {/* Email */}
                  {/* <td> {item.email !== undefined ? item.email : "-"}</td> */}
                  {/* From */}
                  <td> {moment(item.start_date).format(DATE_FORMAT)}</td>
                  {/* To */}
                  <td> {moment(item.end_date).format(DATE_FORMAT)}</td>
                  {/* Actions */}

                  <th className="action">
                    <IconButton
                      onClick={() => handleViewReport(item)}
                      className="action-button1 mt-2"
                    >
                      <img
                        src={EyeFilledBlue}
                        className="h_100"
                        alt="View Details"
                      />
                    </IconButton>
                  </th>
                </tr>
              ))}
            </tbody>
          </Table>
        )}

        {data?.length === 0 && (
          <div className=" text-center">
            <h3 className=" font_m font_yellow fw_6">No Data</h3>
          </div>
        )}
      </div>

      {/* Modals */}

      {/* Tenant Previous History Modal*/}
      <RentHistoryDetailModal
        showModal={showModal}
        detail={detailData}
        onClose={() => setShowModal(!showModal)}
      />
    </div>
  );
};
/* Component ends */

export default RentalHistory;
