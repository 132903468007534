/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useDispatch, useSelector } from "react-redux";
import GLOBAL from "../../../../setup/constants/global";
import { useNavigate } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
import { licenseAssignToProperty } from "../../../../setup/store/slices/licenseSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Modal } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import MUI component starts */
/* Import MUI component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
import CounterInput from "../../../ui/input/CounterInput/CounterInput";
/* Import local pages and component ends */

import "./style.scss";
import { getUserTheme } from "../../../../setup/store/slices/authSlice";

/* Component starts */
const AssignLicenseModal = (props) => {
  /* Props destructuring starts */
  const {
    showModal,
    size = "lg",
    onSuccess,
    onCancel,
    selectedProperty,
    maxLicense,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [count, setCount] = useState(1);
  const [showErrorMessage, setShowErrorMessage] = useState("");
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userTheme = useSelector(getUserTheme);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  // To Purchase Licence
  const purchaseLicense = () => {
    navigate("/settings/manageLicense/purchase");
  };

  // On changing counter
  const onNumChange = () => {
    if (showErrorMessage) {
      setShowErrorMessage("");
    }
  };

  // Validation for counter
  const validation = (num) => {
    if (num < 1) return "Please select atleast 1 license to assign";
    if (num > parseInt(maxLicense))
      return "You do not have enough licenses to assign";
  };

  // On assigning license to the property
  const onAssign = async () => {
    if (count < 1) return;

    if (count > parseInt(maxLicense)) return;

    // Collecting or payload data to be sent
    const payload = {
      property_id: selectedProperty?._id,
      number_of_license: count,
    };

    // Backend response. Try, Catch
    try {
      const result = await dispatch(licenseAssignToProperty(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setShowErrorMessage(result.payload);
          break;
        case GLOBAL.FULFILLED:
          await onSuccess();
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <Modal
      show={showModal}
      size={size}
      centered
      className="assign-license-modal-wrapper"
    >
      <Modal.Body
        className={`body ${
          userTheme === "dark" ? "bg_darkTheme" : "bg_lightTheme"
        }`}
      >
        <h3 className="font_xxl heading text-center font_blue fw_6">
          Assign License
        </h3>

        <p className="font_xl heading font_grey text-center fw_4">
          Number if License you want to remove
        </p>

        <div
          className={`
         ${(userTheme === "light" || userTheme === "automatic") && "otherTheme"}
        `}
        >
          <CounterInput
            label="No. Of Licenses"
            num={count}
            setNum={setCount}
            validation={validation}
            onNumChange={onNumChange}
          />
        </div>

        {showErrorMessage ? (
          <p className="font_s fw_5 font_red warning">{showErrorMessage}</p>
        ) : (
          <p className="font_s fw_5 font_yellow warning">
            Select the number of licenses you want to assign to this property.
            You currently have{" "}
            <span className="font_blue">{maxLicense?.toLocaleString()}</span>{" "}
            available in your account.
          </p>
        )}

        <hr />

        <div
          className={`insuff-wrapper  ${
            (userTheme === "light" || userTheme === "automatic") && "otherTheme"
          }`}
        >
          <p className="font_xl font_yellow fw_4">
            Do not have sufficient licence?
          </p>
          <button className="font_l fw_4 font_blue" onClick={purchaseLicense}>
            +ADD
          </button>
        </div>

        <hr />

        <div className="button-wrapper">
          <CustomButton onClick={onCancel} size="l" variant="red">
            Cancel
          </CustomButton>
          <CustomButton onClick={onAssign} size="l" variant="primary">
            + Assign
          </CustomButton>
        </div>
      </Modal.Body>
    </Modal>
  );
};
/* Component ends */

export default AssignLicenseModal;
