/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  formValueStripe,
  formPrefillStripe,
  validationStripe,
} from "../helper";
import GLOBAL from "../../../../../setup/constants/global";
/* Import configuration ends */

/* Import redux slices component starts */
import { updateUserBilling } from "../../../../../setup/store/slices/authSlice";
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../../../setup/store/slices/globalAlertSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomTextInput from "../../../../ui/input/CustomTextInput/CustomTextInput";
import CustomButton from "../../../../ui/button/CustomButton/CustomButton";
import TextButton from "../../../../ui/button/TextButton/TextButton";
/* Import local pages and component ends */

/* Component starts */
const Stripe = (props) => {
  /* Props destructuring starts */
  const { data } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [initialFormValues, setInitialFormValues] = useState(formValueStripe);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  const dispatch = useDispatch();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // Form validation
  const formValidation = (value) => {
    return validationStripe(value);
  };

  // On updating payment set up
  const handleSubmit = async (value) => {
    // Creating or collecting payload data to be sent
    const payload = {
      payment_processor_details: {
        gateway: "stripe",
        stripe_public_key: value.stripe_public_key,
        stripe_secret_key: value.stripe_secret_key,
      },
    };

    // Backend response. Try, Catch
    try {
      const result = await dispatch(updateUserBilling(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Updated!",
              description: "You payment setup has been successfully updated.",
            })
          );
          navigate(-1);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    if (data) {
      formPrefillStripe(data, setInitialFormValues);
    }
  }, [data]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div>
      <Formik
        initialValues={initialFormValues}
        validate={formValidation}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          isValid,
        }) => (
          <form onSubmit={handleSubmit}>
            {/* Form fields */}
            <div className="form-wrapper bg_grey payment-details">
              {/* Input for Public Key */}
              <div className="form-group form-group-full">
                <CustomTextInput
                  name="stripe_public_key"
                  label="Public Key"
                  value={values.stripe_public_key}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  touched={touched.stripe_public_key}
                  errors={errors.stripe_public_key}
                />
              </div>

              {/* Input for Secret Key */}
              <div className="form-group form-group-full">
                <CustomTextInput
                  name="stripe_secret_key"
                  label="Secret Key"
                  value={values.stripe_secret_key}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  touched={touched.stripe_secret_key}
                  errors={errors.stripe_secret_key}
                />
              </div>
            </div>

            {/* Submit and Candel Button */}
            <div className="button-wrapper text-center">
              <CustomButton type="submit" size="l">
                Update
              </CustomButton>
              <TextButton
                type="button"
                className="fw_6 font-m"
                onClick={() => navigate(-1)}
              >
                Cancel
              </TextButton>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};
/* Component ends */

export default Stripe;
