/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useSelector } from "react-redux";
import Lottie from "lottie-react";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  getShowUploadProgress,
  getUploadProgress,
} from "../../../../setup/store/slices/unpersistedSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { LogoPrimary } from "../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import LoaderAnimation from "../../../../assets/loader/loader_lottie.json";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const Loader = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const showUploadProgress = useSelector(getShowUploadProgress);
  const uploadProgress = useSelector(getUploadProgress);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="loader-component-wrapper">
      <div className="loader-content">
        <div className="logo-wrapper">
          <Lottie animationData={LoaderAnimation} loop={true} />
          {/* <img src={LogoPrimary} className="logo w_100" alt="Logo" /> */}
        </div>
        {/* {!showUploadProgress && (
          <h1 className="font_l fw_6 text-center font_blue">Loading</h1>
        )}
        {showUploadProgress && (
          <h1 className="font_l fw_6 text-center font_blue">Uploading</h1>
        )} */}

        {showUploadProgress && (
          <div className="upload-progress-wrapper">
            {/* <h1 className="font_l fw_6 text-center font_blue text-center">
              {parseInt(uploadProgress)}%
            </h1> */}

            <div className="progress-bar">
              <div
                style={{ width: `${parseInt(uploadProgress)}%` }}
                className="progress"
              ></div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
/* Component ends */

export default Loader;
