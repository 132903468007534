/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useParams } from "react-router-dom";
import GLOBAL from "../../setup/constants/global";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../setup/store/slices/globalAlertSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import VisitorAccessPassInfo from "../../components/module/VisitorAccessPass/VisitorAccessPassInfo/VisitorAccessPassInfo";
/* Import local pages and component ends */

/* Component starts */
const VisitorAccessPassInfoPage = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [passDetail, setPassDetail] = useState(null);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const { visitorPassID } = useParams();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="visitor-access-pass-info-page-wrapper">
      <VisitorAccessPassInfo />
    </div>
  );
};
/* Component ends */

export default VisitorAccessPassInfoPage;
