/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useDispatch, useSelector } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import { fetchUser, getUser } from "../../../../setup/store/slices/authSlice";
import {
  fetchCommunities,
  getCommunities,
} from "../../../../setup/store/slices/communitySlice";
import {
  fetchProperties,
  getProperties,
  getCommunityProperties,
} from "../../../../setup/store/slices/propertySlice";
import {
  fetchSetting,
  getSetting,
} from "../../../../setup/store/slices/settingSlice";
import { setLoading } from "../../../../setup/store/slices/unpersistedSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import MUI component starts */
/* Import MUI component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import LicenseOverViewDetail from "../LicenseOverViewDetail/LicenseOverViewDetail";
import PropertyLicenseSlider from "../PropertyLicenseSlider/PropertyLicenseSlider";
import CommunityLicenseSlider from "../CommunityLicenseSlider/CommunityLicenseSlider";
import CommunityPropertyLicenseSlider from "../CommunityPropertySlider/CommunityPropertyLicenseSlider";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const ManageLicenseModule = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [showCommunityProperties, setShowCommunityProperties] = useState(false);
  const [selectedCommunity, setSelectedCommunity] = useState(null);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();

  // Redux Selector
  const user = useSelector(getUser);
  const settings = useSelector(getSetting);
  const properties = useSelector(getProperties);
  const communities = useSelector(getCommunities);
  const communityProperties = useSelector(getCommunityProperties);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const update = async () => {
    try {
      await dispatch(setLoading(true));
      await dispatch(fetchCommunities());
      await dispatch(fetchProperties());
      await dispatch(setLoading(false));
    } catch (e) {
      console.log(e);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  // Fetching User, Properties, Settings, Communities
  useEffect(() => {
    (async () => {
      try {
        await dispatch(fetchUser());
        await update();
        await dispatch(fetchSetting());
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="manage-license-module-wrapper container_lg">
      <h2 className="heading font_xxl fw_6">Manage License</h2>

      {/* License Overview Details */}
      <LicenseOverViewDetail settings={settings} user={user} />

      {/* If community is selected */}
      {!showCommunityProperties && (
        <>
          {/* Community License Slider */}
          {communities?.length > 0 && (
            <CommunityLicenseSlider
              communities={communities}
              setShowCommunityProperties={setShowCommunityProperties}
              setSelectedCommunity={setSelectedCommunity}
            />
          )}

          {/* Property License Slider */}
          {properties?.length > 0 && (
            <PropertyLicenseSlider
              user={user}
              properties={properties}
              update={update}
            />
          )}
        </>
      )}

      {/* If community is selected */}
      {showCommunityProperties && (
        <CommunityPropertyLicenseSlider
          setShowCommunityProperties={setShowCommunityProperties}
          selectedCommunity={selectedCommunity}
          user={user}
          properties={communityProperties}
          update={update}
        />
      )}
    </div>
  );
};
/* Component ends */

export default ManageLicenseModule;
