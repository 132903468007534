export const skipLink = (key) => {
  switch (key) {
    case "home":
    case "manage":
    case "internal":
    case "moveout":
    case "tenant":
    case "password":
      return false;
    default:
      return true;
  }
};

export const skipLink2 = (key, index, array) => {
  if (beforeThis(index, "tenant", array)) {
    return false;
  }
  if (afterThis(index, "maintenance_requests", array)) {
    return false;
  }
  if (afterThis(index, "guest-passes", array)) {
    return false;
  }

  if (afterThis(index, "contracts", array)) {
    return false;
  }

  if (afterThis(index, "security_complains", array)) {
    return false;
  }

  if (
    //afterThis(index, "invoicing", array)
    array[index] === "invoicing"
  ) {
    return false;
  }

  if (afterThis(index, "annoucements", array)) {
    return false;
  }

  if (afterThis(index, "violations", array)) {
    return false;
  }

  if (beforeThis(index, "moveout", array)) {
    return false;
  }

  if (afterThis(index, "moveout", array)) {
    return false;
  }

  if (beforeThis(index, "unit", array)) {
    return false;
  }

  if (
    //beforeThis(index, "lost_and_found", array)
    array[index] === "lost_and_found"
  ) {
    return false;
  }

  if (
    beforeThis(index, "application", array) ||
    beforeTwo(index, "contracts", array)
  ) {
    return false;
  }
  return true;
};

export const renderKey = (key, index, array) => {
  if (
    beforeThis(index, "maintenance_requests", array) &&
    array[index] === "add"
  ) {
    return "Add Maintenance";
  }

  if (beforeThis(index, "maintenance_requests", array)) {
    return "Maintenance Request Details";
  }

  if (array[index] === "maintenance_requests") {
    return "Maintenance";
  }

  if (beforeThis(index, "maintenances", array)) {
    return "Maintenance Details";
  }
  if (beforeThis(index, "annoucements", array)) {
    return "Annoucement Detail";
  }

  if (beforeTwo(index, "moveout", array)) {
    return "Move Out";
  }

  if (
    beforeThis(index, "security_complains", array) &&
    array[index] === "add"
  ) {
    return "Register Complains";
  }

  if (array[index] === "security_complains") {
    return "Security Complain";
  }

  if (beforeThis(index, "security_complains", array)) {
    return "Security Complains Detail";
  }

  if (
    beforeThis(index, "invoicing", array) &&
    (array[index] === "unpaid" || array[index] === "paid")
  ) {
    return "Invoicing";
  }

  if (beforeThis(index, "paid", array) || beforeThis(index, "unpaid", array)) {
    return "Invoice Detail";
  }

  if (beforeThis(index, "annoucements", array)) {
    return "Annoucement Detail";
  }

  if (beforeThis(index, "violations", array)) {
    return "Violation Detail";
  }

  if (
    beforeThis(index, "lost_and_found", array) &&
    (array[index] === "found" || array[index] === "lost")
  ) {
    return "Lost & Found";
  }

  if (beforeThis(index, "lost", array) && array[index] === "add") {
    return "Add Lost";
  }

  if (beforeThis(index, "lost", array)) {
    return "Lost & Found Detail";
  }

  if (array[index] === "guest-passes") {
    return "My Guest Pass";
  }

  if (
    beforeThis(index, "guest-passes", array) &&
    // array[index] === "guest-passes"
    array[index] === "add"
  ) {
    return "Add Guest Pass";
  }

  if (
    beforeThis(index, "guest-passes", array)
    // array[index] === "guest-passes"
  ) {
    return "Guest Pass Detail";
  }

  if (beforeThis(index, "facilities", array) && array[index] === "list") {
    return "Facilities";
  }
  if (array[index] === "basic_detail") {
    return "Basic Detail";
  }

  if (array[index] === "rental_history") {
    return "Rental History";
  }

  if (array[index] === "termAndConditon") {
    return "Term And Conditon";
  }

  if (array[index] === "ground_rules") {
    return "Ground Rules";
  }

  if (array[index] === "profile") {
    return "My Profile";
  }

  if (array[index] === "auto_payment") {
    return "Auto Payment";
  }

  if (array[index] === "manageCard") {
    return "Manage Cards";
  }

  if (array[index] === "helpAndSupport") {
    return "Help And Support";
  }

  if (array[index] === "theme") {
    return "Change Theme";
  }

  if (array[index] === "change") {
    return "Change Password";
  }

  if (array[index] === "renew") {
    return "Contract Renew";
  }

  return changeKeyLetters(key);
};

export const changeKeyLetters = (key) => {
  return key?.charAt(0)?.toUpperCase() + key?.slice(1);
};

export const beforeThis = (currIndex, prevValue, array) => {
  const previndex = currIndex - 1;
  if (previndex >= 0) {
    if (array[previndex] === prevValue) {
      return true;
    }
    return false;
  }
};

export const beforeTwo = (currIndex, prevValue, array) => {
  const previndex = currIndex - 2;
  if (previndex >= 0) {
    if (array[previndex] === prevValue) {
      return true;
    }
    return false;
  }
};

export const afterThis = (currIndex, nextValue, array) => {
  const nextindex = currIndex + 1;
  if (nextindex < array?.length) {
    if (array[nextindex] === nextValue) {
      return true;
    }
    return false;
  }
};
