/**
 *
 */

import React, { useState, useEffect, useRef } from "react";

/* Import configuration starts */
import { connect } from "react-redux";
import { Formik, FieldArray } from "formik";
import { bindActionCreators } from "redux";
import {
  idVerificationInitVal,
  idVerificationValidation,
  idVerifyFormPrefill,
  countSet,
  isDataChanged,
  isApproved,
} from "./helper";
import GLOBAL from "../../../../setup/constants/global";
import { useDispatch } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  clearMasterData,
  fetchIdTypes,
} from "../../../../setup/store/slices/masterSlice";
import { updateTenantApplication } from "../../../../setup/store/slices/tenantSlice";
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../../setup/store/slices/globalAlertSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import TextButton from "../../../ui/button/TextButton/TextButton";
import CustomDateInput from "../../../ui/input/CustomDateInput/CustomDateInput";
import CustomTextInput from "../../../ui/input/CustomTextInput/CustomTextInput";
import CustomSelectInput from "../../../ui/input/CustomSelectInput/CustomSelectInput";
import CustomFileInput from "../../../ui/input/CustomFileInput/CustomFileInput";
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
import SuccessModal from "../../../ui/modal/SuccessModal/SuccessModal";
import ErrorModal from "../../../ui/modal/ErrorModal/ErrorModal";
/* Import local pages and component ends */

/* Component starts */
const IDVerificationForm = (props) => {
  /* Props destructuring starts */
  // states
  const {
    IdTypes,
    data,
    applicationId,
    numIds,
    fetchApplication = null,
    canCancelApplication = false,
    validateDataUpdated = true,
  } = props;

  // functions
  const {
    fetchIdTypes,
    onNext,
    update,
    clearMasterData,
    onCancelStep = null,
    onCancelApplication,
    onUpdatingInfo = null,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [initialFormValues, setInitialFormValues] = useState(
    idVerificationInitVal
  );
  const [message, setMessage] = useState("");
  const [showErrModal, setShowErrModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [keyList, setKeyList] = useState([]);
  const [initialised, setInitialised] = useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatchHook = useDispatch();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // Getting or Processing action error note from PM
  const getActionError = (d) => {
    if (d?.actions?.length > 0) {
      let lastAction = d["actions"][d["actions"].length - 1];
      if (
        lastAction.status === GLOBAL.TENANT_APPLICATION_STATUS.DATA_REJECTED
      ) {
        return true;
      }
    }
    return false;
  };

  // Form validation
  const formValidation = (values) => {
    return idVerificationValidation(values, keyList);
  };

  // On updating id verification details
  const onSubmit = async (values) => {
    if (
      validateDataUpdated &&
      data?.length > 0 &&
      !isDataChanged(values, data, keyList)
    ) {
      dispatchHook(
        showErrorAlert({
          title: "Error",
          description: "Please update the form",
        })
      );
      return;
    }

    // Creating or collecting payload data to be sent
    const payload = {
      applicationId,
      type: "ids",
      ids: [],
    };
    const ids = [];

    keyList.forEach((item, index) => {
      if (!isApproved(values[`status_${item}`])) {
        const id = {
          type: values[`type_${item}`],
          number: values[`number_${item}`],
          expiry_date: values[`expiry_date_${item}`],
        };
        if (data.length === numIds) {
          id["_id"] = data[index]._id;
        }
        ids.push(id);
        if (
          values[`image_${item}`] &&
          values[`image_${item}`] instanceof File
        ) {
          payload[`img_${index}`] = values[`image_${item}`];
        }
      }
    });

    payload[`ids`] = ids;

    console.log(payload);

    // Backend response. Try, Catch
    try {
      const result = await update(payload);

      console.log(result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setMessage(result.payload);
          setShowErrModal(true);
          break;

        case GLOBAL.FULFILLED:
          onUpdatingInfo && onUpdatingInfo();
          fetchApplication && fetchApplication();
          setMessage(result.payload.message);
          setShowSuccessModal(true);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  // Fetching necessary data on initial load
  useEffect(() => {
    fetchIdTypes();
  }, []);

  // On load of id verification details previous data
  useEffect(() => {
    if (data?.length > 0) {
      idVerifyFormPrefill(data, setInitialFormValues, setKeyList);
      setInitialised(true);
    } else {
      countSet(numIds, setInitialFormValues, setKeyList);
      setInitialised(true);
    }
  }, [data]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  if (initialised)
    return (
      <div className="id-verification container_sm">
        <h2 className="heading text-center font_xxl mb-5">ID Verification</h2>

        <Formik
          onSubmit={onSubmit}
          initialValues={initialFormValues}
          validate={formValidation}
          validateOnBlur={true}
          enableReinitialize={true}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="form-wrapper">
                {/* ID List Form */}
                {keyList.map((item, index) => (
                  <div key={index} className="form-section-group">
                    {/* ID Count Title */}
                    <h3 className="title-bubble font_blue font_m fw_6 w_100">
                      ID #{index + 1}{" "}
                      {isApproved(values[`status_${item}`]) && (
                        <p className="font_m fw_5 already-approved-warning">
                          <span className="font_yellow">
                            All fields are disabled, as this information is
                            already
                          </span>{" "}
                          <span className="font_green">Approved</span>
                        </p>
                      )}
                    </h3>

                    {/* Require action */}
                    {getActionError(data[index]) && (
                      <p className="w_100 font_red font_s fw_5">
                        {
                          data[index]?.actions[data[index]?.actions?.length - 1]
                            .note
                        }
                      </p>
                    )}

                    {/* Form Fields */}
                    {/* Input for ID Type */}
                    <div className="form-group">
                      <CustomSelectInput
                        label="ID Type"
                        name={`type_${item}`}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        options={IdTypes}
                        value={values[`type_${item}`]}
                        errors={errors[`type_${item}`]}
                        touched={touched[`type_${item}`]}
                        valueExtractor={(d) => d?.name}
                        disabled={isApproved(values[`status_${item}`])}
                      />
                    </div>

                    {/* Input for ID number */}
                    <div className="form-group">
                      <CustomTextInput
                        name={`number_${item}`}
                        label="ID Number"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        touched={touched[`number_${item}`]}
                        errors={errors[`number_${item}`]}
                        value={values[`number_${item}`]}
                        required={true}
                        readOnly={isApproved(values[`status_${item}`])}
                      />
                    </div>

                    {/* Input for ID Expiry */}
                    <div className="form-group">
                      <CustomDateInput
                        name={`expiry_date_${item}`}
                        label="Expiry Date"
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        touched={touched[`expiry_date_${item}`]}
                        errors={errors[`expiry_date_${item}`]}
                        value={values[`expiry_date_${item}`]}
                        required={true}
                        disabled={isApproved(values[`status_${item}`])}
                      />
                    </div>

                    {/* Input for ID Photo */}
                    <div className="form-group">
                      <CustomFileInput
                        name={`image_${item}`}
                        label="ID Photo"
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        formikState={true}
                        touched={touched[`image_${item}`]}
                        errors={errors[`image_${item}`]}
                        value={values[`image_${item}`]}
                        required={true}
                        disabled={isApproved(values[`status_${item}`])}
                      />
                    </div>
                  </div>
                ))}
              </div>

              <div className="button-wrapper text-center">
                <CustomButton type="submit" size="l">
                  Next
                </CustomButton>

                {onCancelStep && (
                  <TextButton
                    type="button"
                    onClick={onCancelStep}
                    className="font_m fw_6"
                  >
                    Back
                  </TextButton>
                )}
              </div>
            </form>
          )}
        </Formik>

        {canCancelApplication && (
          <div className="cancel-application-btn-wrapper mt-4">
            <CustomButton
              className="cancel-application-btn"
              variant="red"
              size="l"
              onClick={onCancelApplication}
            >
              Cancel Application
            </CustomButton>
          </div>

          // <div className="cancel-application-wrapper bg_grey">
          //   <p className="font_m font_yellow fw_5">
          //     I would like to cancel the onboarding application process. I am
          //     aware that after cancelling the application I won't be able to undo
          //     my action.
          //   </p>
          //   <div className="cancel-application-btn-wrapper">
          //     <CustomButton
          //       className="cancel-application-btn"
          //       variant="red"
          //       size="m"
          //       onClick={onCancelApplication}
          //     >
          //       Cancel Application
          //     </CustomButton>
          //   </div>
          // </div>
        )}

        {/* Modals */}
        {/* Success Modal */}
        <SuccessModal
          showModal={showSuccessModal}
          description={message}
          title="Success"
          onClose={() => onNext()}
        />

        {/* Error Modal */}
        <ErrorModal
          showModal={showErrModal}
          description={message}
          title="Error"
          onClose={() => setShowErrModal(false)}
        />
      </div>
    );
};
/* Component ends */

const mapStateToProps = (state) => {
  return {
    IdTypes: state.master.idTypes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchIdTypes: bindActionCreators(fetchIdTypes, dispatch),
    update: bindActionCreators(updateTenantApplication, dispatch),
    clearMasterData: bindActionCreators(clearMasterData, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IDVerificationForm);
