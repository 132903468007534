/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import moment from "moment";
import GLOBAL from "../../../../../setup/constants/global";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { EyeFilledBlue } from "../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import FileViewerModal from "../../../../ui/modal/FileViewerModal/FileViewerModal";
import IconButton from "../../../../ui/button/IconButton/IconButton";
import ApplicationDataRejectionModal from "../../ApplicationDataRejectionModal/ApplicationDataRejectionModal";
/* Import local pages and component ends */

/* Component starts */
const CommercialDetails = (props) => {
  /* Props destructuring starts */
  const { details, onAction, approval = false, index, isRenew } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [file, setFile] = useState({});
  const [showFile, setShowFile] = useState(false);
  const [rejectAction, setRejectAction] = useState({});
  const [rejectionModal, setRejectionModal] = useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";

  const companyName =
    details?.business_name !== undefined ? details?.business_name : "-";
  const companyRelation =
    details?.relation_to_business !== undefined
      ? details?.relation_to_business
      : "-";
  const status = details?.status !== undefined ? details?.status : "-";
  const taxIdDoc =
    details?.tax_id_document !== undefined ? details?.tax_id_document : "-";
  const taxIdNum =
    details?.tax_id_number !== undefined ? details?.tax_id_number : "-";
  const tradeLicenseDoc =
    details?.trade_license_document !== undefined
      ? details?.trade_license_document
      : "-";
  const tradeLicenseExp = moment(details?.trade_license_expiry_date).format(
    DATE_FORMAT
  );
  const tradeLicenseNum = details?.trade_license_number
    ? details?.trade_license_number
    : "-";

  const bankStatement =
    details?.bank_statement_document !== undefined
      ? details?.bank_statement_document
      : "-";
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // On approving details
  const onApprove = () => {
    onAction({
      type: "commercial_details",
      info_id: details._id,
      info_details: {
        status: GLOBAL.TENANT_APPLICATION_STATUS.DATA_APPROVED,
      },
    });
  };

  // On rejecting details
  const onReject = () => {
    setRejectAction({
      type: "commercial_details",
      info_id: details._id,
      info_details: {
        status: GLOBAL.TENANT_APPLICATION_STATUS.DATA_REJECTED,
      },
    });
    setRejectionModal(true);
  };

  // On rejecting with note
  const onRejectWithNote = (note) => {
    setRejectionModal(false);
    const action = { ...rejectAction };
    action.info_details.note = note;
    onAction(action);
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="commercial-wrapper">
      <div className="heading-wrapper ">
        <p className={`font_l label my-2 ${isRenew && "mx-4"} `}>
          {" "}
          {index && `${index} -`} Commercial Details
        </p>
      </div>
      {/* <p className="font_l heading">  </p> */}
      <div className="detail-box">
        {details ? (
          <div className="basic">
            <div className="detail">
              <p className="font_s fw_3">
                Company Name <br />{" "}
                <span className="font_m fw_4">{companyName}</span>
              </p>
            </div>
            <div className="detail">
              <p className="font_s fw_3">
                Company Relation <br />{" "}
                <span className="font_m fw_4">{companyRelation}</span>
              </p>
            </div>
            <div className="detail">
              <p className="font_s fw_3">
                Tax ID Number <br />{" "}
                <span className="font_m fw_4">{taxIdNum}</span>
              </p>
            </div>
            <div className="detail">
              <p className="font_s fw_3">
                Tax ID Document <br />{" "}
                <span className="font_m fw_4">
                  <IconButton
                    onClick={() => {
                      setFile(taxIdDoc);
                      setShowFile(true);
                    }}
                    className="view-icon"
                  >
                    <img className="w_100" src={EyeFilledBlue} alt="view" />
                  </IconButton>
                </span>
              </p>
            </div>
            <div className="detail">
              <p className="font_s fw_3">
                Trade License Number <br />{" "}
                <span className="font_m fw_4">{tradeLicenseNum}</span>
              </p>
            </div>
            <div className="detail">
              <p className="font_s fw_3">
                Trade License Expiry <br />{" "}
                <span className="font_m fw_4">{tradeLicenseExp}</span>
              </p>
            </div>

            <div className="detail">
              <p className="font_s fw_3">
                Trade License Document <br />{" "}
                <span className="font_m fw_4">
                  <IconButton
                    onClick={() => {
                      setFile(tradeLicenseDoc);
                      setShowFile(true);
                    }}
                    className="view-icon"
                  >
                    <img className="w_100" src={EyeFilledBlue} alt="view" />
                  </IconButton>
                </span>
              </p>
            </div>

            <div className="detail">
              <p className="font_s fw_3">
                Bank Statement <br />{" "}
                <span className="font_m fw_4">
                  <IconButton
                    onClick={() => {
                      setFile(bankStatement);
                      setShowFile(true);
                    }}
                    className="view-icon"
                  >
                    <img className="w_100" src={EyeFilledBlue} alt="view" />
                  </IconButton>
                </span>
              </p>
            </div>

            {/* <div className="detail">
              <p className="font_s fw_3">
                Bank Statement <br />
                <img
                  src={EyeFilledBlue}
                  onClick={() => {
                    setFile(bankStatement);
                    setShowFile(true);
                  }}
                  className="view-icon"
                  alt="Doc"
                />
              </p>
            </div> */}

            {approval && (
              <div className="detail">
                {/* If pending actions */}
                {status === GLOBAL.TENANT_APPLICATION_STATUS.DATA_PENDING && (
                  <div className="btn-wrapper">
                    <span
                      onClick={() => onApprove()}
                      className="approve font_s fw_3"
                    >
                      Approve
                    </span>
                    <span
                      onClick={() => onReject()}
                      className="reject font_s fw_3"
                    >
                      Reject
                    </span>
                  </div>
                )}

                {/* If data approved */}
                {status === GLOBAL.TENANT_APPLICATION_STATUS.DATA_APPROVED && (
                  <p className="fw_6 font_s font_green">Approved</p>
                )}

                {/* If data rejected */}
                {status === GLOBAL.TENANT_APPLICATION_STATUS.DATA_REJECTED && (
                  <p className="fw_6 font_s font_red">Rejected</p>
                )}
              </div>
            )}
          </div>
        ) : (
          <div className=" text-center">
            <h3 className=" font_m font_yellow fw_6">No Data</h3>
          </div>
        )}
      </div>

      {/* Modal */}
      {/* File Viewer Modal */}
      <FileViewerModal
        show={showFile}
        file={file?.url}
        type={file?.type}
        onClose={() => {
          setShowFile(false);
          setFile({});
        }}
      />

      {/* Tenant Application Rejection Modal*/}
      <ApplicationDataRejectionModal
        showModal={rejectionModal}
        title="Are you sure you want to reject this Commercial Detail?"
        onRejectWithNote={onRejectWithNote}
        onCancel={() => setRejectionModal(false)}
      />
    </div>
  );
};
/* Component ends */

export default CommercialDetails;
