/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import moment from "moment/moment";
import { useFormikContext } from "formik";
import GLOBAL from "../../../../../setup/constants/global";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import { getUserTheme } from "../../../../../setup/store/slices/authSlice";
import { useSelector } from "react-redux";
import { customMoment } from "../../../../../setup/utils/global-helper";
import { formatRenderer } from "../helper";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import MUI component starts */
/* Import MUI component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
/* Import local pages and component ends */

/* Component starts */
const Variant2 = (props) => {
  /* Props destructuring starts */
  const {
    label,
    value, // useState value / Formik State
    setValue = null,
    name,
    required = true,
    customOnchange = null,
    touched,
    errors,
    setFieldValue = null, // setFieldValue formik function
    setFieldTouched = null, // setFieldTouched formik function
    refValue = null, // If useRef used
    minDate = true,
    maxDate = false,
    maxDateValue = null,
    minDateValue = null,
    disabled = false,
    format = "dd/MM/y",
    canSetOffset = false,
    canSetTimezone = false,
    offset = 0,
    timeZone = "",
    floatingCalender = false,
    setIsCalenderOpen,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const userTheme = useSelector(getUserTheme);
  const userData = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // On selecting date
  const handleDateChange = (date) => {
    // setFieldValue && setFieldValue(name, moment(date).format("MM/DD/YYYY"));
    // setFieldValue && setFieldValue(name, moment(date).format("DD-MM-YYYY"));
    setFieldValue && setFieldValue(name, date.toString());

    customOnchange && customOnchange(date.toString());

    setValue && setValue(date.toString());
    // setValue && setValue(moment(date).format("MM/DD/YYYY"));

    if (refValue !== null) {
      refValue.current = date;
    }
  };

  const parseDate = (dateString) => {
    if (
      dateString &&
      typeof dateString === "string" &&
      dateString.length === 10
    ) {
      const digits = dateString.split("-");
      if (
        digits[0].length === 2 &&
        digits[1].length === 2 &&
        digits[2].length === 4
      ) {
        const firstDigit = digits[0];
        digits[0] = digits[1];
        digits[1] = firstDigit;
        return new Date(digits.join("-"));
      } else {
        return new Date();
      }
    } else {
      return new Date();
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    if (refValue !== null) {
      refValue.current = value;
    }
  }, [value]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className={`custom-date-input-main-wrapper-v2`}>
      {/* {console.log("I am inside DatePicker (actual date) -->", value)} */}
      {/* {console.log("I am inside DatePicker (parsed date) -->", new Date(value))} */}
      <div className="datepicker">
        <DatePicker
          format={formatRenderer(userData?.date_format)}
          disabled={disabled}
          name={name}
          // || userTheme === "automatic"
          className={`font_xs font_blue
          ${
            userTheme === "light" || userTheme === "automatic"
              ? "bg_lightThemeInput"
              : "bg_black"
          }
          `}
          onChange={handleDateChange}
          // value={new Date(parseDate(value).toString())}
          value={value ? new Date(value) : new Date()}
          minDate={minDateValue ? minDateValue : minDate ? new Date() : null}
          maxDate={maxDateValue ? maxDateValue : maxDate ? new Date() : null}
          onCalendarOpen={() => {
            console.log("Calender Opened");
            setFieldTouched && setFieldTouched(name, true);
            setIsCalenderOpen && setIsCalenderOpen(true);
          }}
          calendarClassName="custom-datepicker-calender"
          onCalendarClose={() => {
            console.log("Calender Closed");
            setIsCalenderOpen && setIsCalenderOpen(false);
          }}
        />
        <label className="font_s font_grey">
          {label} {required && <span className="font_m font_red"> *</span>}
        </label>
      </div>

      {touched && errors && <p className="error font_xxs font_red">{errors}</p>}
    </div>
  );
};
/* Component ends */

export default Variant2;
