/**
 *
 */

import React from "react";

/* Import configuration starts */
import { Link } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import MUI component starts */
/* Import MUI component ends */

/* Import image and SVG starts */
import {
  LogoWhiteNoLabel,
  LogoGreenNoLabel,
  BlankImagePlaceholder,
} from "../../../setup/constants/images";
import BreadCrumb from "../../../components/ui/other/BreadCrumb/BreadCrumb";
/* Import image and SVG ends */

/* Import local pages and component starts */
/* Import local pages and component ends */

/* Component starts */
const NoCommunityAndProperty = () => {
  /* Props destructuring starts */
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      <BreadCrumb type="no_property" />
      <div className="no-property-community mt-5">
        <div className="img-wrapper text-center">
          <img src={BlankImagePlaceholder} alt="Logo" />
        </div>
        <h1 className="font_xxl text-center mt-3">Welcome to DGRNTE</h1>
        <p className="font_l text-center mt-3 font_grey">
          You can add a property using the DGRNTE system. To begin, simply click
          on the "Add Property" button.
        </p>
        <div className="text-center mt-3">
          <Link to="/independent/properties/add" className="font_l fw_6">
            + Add Property
          </Link>
        </div>
      </div>
    </>
  );
};
/* Component ends */

export default NoCommunityAndProperty;
