/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import moment from "moment";
import GLOBAL from "../../../../../setup/constants/global";
import { useNavigate } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Table } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import {
  DocFilledWhite,
  EyeFilledBlue,
  RandomProperty,
  DocumentGreen,
} from "../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../../../ui/button/IconButton/IconButton";
import FileViewerModal from "../../../../ui/modal/FileViewerModal/FileViewerModal";
/* Import local pages and component ends */

// import "../style.scss"

/* Component starts */
const ContractPDF = (props) => {
  /* Props destructuring starts */
  const { contract, index } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [showFile, setShowFile] = useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="contract-pdf-wrapper">
      <div className="contract-heading">
        <p className="font_l label">{index && `${index} -`} Contract Details</p>
      </div>

      {/* ID Verfication Table */}
      <div className="custom-table-wrapper">
        <Table className="custom-table font_s" borderless responsive>
          {/* Table Heading */}
          <thead>
            <tr>
              <th></th>
              <th>File</th>
            </tr>
          </thead>
          {/* Table Body */}
          <tbody>
            <tr>
              <td>Contract PDF</td>
              <td>
                <IconButton className="" onClick={() => setShowFile(true)}>
                  <img src={DocumentGreen} className="icon-md" alt="View" />
                </IconButton>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
      {contract && (
        <FileViewerModal
          show={showFile}
          file={contract?.url}
          type={contract?.type}
          onClose={() => setShowFile(false)}
        />
      )}
    </div>
  );
};
/* Component ends */

export default ContractPDF;
