/**
 *
 */

import React, { useState, useEffect, useRef } from "react";

/* Import configuration starts */
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import GLOBAL from "../../../../setup/constants/global";
import { validEmail } from "../../../../setup/utils/validations";
import { scrollToTop } from "../../../../setup/utils/global-helper";
/* Import configuration ends */

/* Import redux slices component starts */
import { searchUser } from "../../../../setup/store/slices/authSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import MUI component starts */
/* Import MUI component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomPhoneInput from "../../input/CustomPhoneInput/CustomPhoneInput";
import CustomTextInput from "../../input/CustomTextInput/CustomTextInput";
import CustomButton from "../../button/CustomButton/CustomButton";
import ErrorModal from "../../modal/ErrorModal/ErrorModal";
import TextButton from "../../button/TextButton/TextButton";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const SearchForAccount = (props) => {
  /* Props destructuring starts */
  const { roleType, title, onInvite, onCancel = null } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  // useState
  const [role, setRole] = useState();
  const [searchResult, setSearchResult] = useState(null);
  const [message, setMessage] = useState("");
  const [showErrModal, setShowErrModal] = useState(false);

  // useRef
  const customPhoneNum = useRef({ code: "", num: "" });
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const initialFormValues = {
    email: "",
    mobile: "",
  };
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // Form validations
  const formValidation = (values) => {
    const error = {};

    if (values.email === "" && customPhoneNum.current.num === "") {
      if (values.email === "") {
        error.email = "Email or Phone is required";
      }
      if (customPhoneNum.current.num === "") {
        error.mobile = "Email or Phone is required";
      }
    }
    if (values.email && !validEmail(values.email)) {
      error.email = "Email is not valid";
    }
    if (
      customPhoneNum.current.num !== "" &&
      customPhoneNum.current.num.length < 8
    ) {
      error.mobile = "Phone number is not valid";
    }
    return error;
  };
  // Search for user
  const searchUserWithQuery = async (payload) => {
    setSearchResult(null);

    // Backend response. Try, Catch
    try {
      const result = await dispatch(searchUser(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setMessage(result.payload);
          setShowErrModal(true);
          break;
        case GLOBAL.FULFILLED:
          scrollToTop()
          setSearchResult(result.payload);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // On inviting user
  const invite = () => {};

  // Handling form submit
  const handleSubmit = (values) => {
    if (values.email) {
      // Creating or collecting payload data tobe sent
      const payload = `role_id=${roleType._id}&email=${encodeURIComponent(
        values.email.toLowerCase()
      )}`;

      searchUserWithQuery(payload);
    } else {
      // Creating or collecting payload data tobe sent
      const payload = `role_id=${roleType._id}&countrycode=${encodeURIComponent(
        customPhoneNum.current.code
      )}&mobile=${customPhoneNum.current.num}`;

      searchUserWithQuery(payload);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="search-account-wrapper-main w_100">
      {searchResult && (
        <div className="search-result bg_grey d_flex">
          <div className="left">
            <p className="m-0 font_s">
              Name : {searchResult.first_name} {searchResult.last_name}
            </p>
            <p className="m-0 font_s">Email : {searchResult.email}</p>
            <p className="m-0 font_s">
              Phone: {searchResult.countrycode}-{searchResult.mobile}
            </p>
            <p className="m-0 font_s">City : {searchResult.city}</p>
            <p className="m-0 font_s">State : {searchResult.state}</p>
            <p className="m-0 font_s">Country : {searchResult.country}</p>
          </div>
          <div className="right">
            <TextButton
              onClick={() => onInvite(searchResult)}
              className="font_l font_blue fw_6"
            >
              Select
            </TextButton>
          </div>
        </div>
      )}
      <Formik
        initialValues={initialFormValues}
        validate={formValidation}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          isValid,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="fleid w_100">
              <CustomTextInput
                type="text"
                label="Email Address"
                handleBlur={handleBlur}
                handleChange={handleChange}
                value={values.email}
                name="email"
                errors={errors.email}
                touched={touched.email}
              />
            </div>

            <p className="font_m my-3 text-center fw_6">OR</p>

            <div className="field w_100">
              <CustomPhoneInput
                name="mobile"
                label="Phone Number"
                customPhoneNum={customPhoneNum}
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.mobile}
                countryCodeEditable={true}
                errors={errors.mobile}
                touched={touched.mobile}
              />
            </div>
            <div className="button-wrapper text-center">
              <CustomButton type="submit" size="l">
                Search
              </CustomButton>
              {onCancel && (
                <TextButton
                  type="button"
                  onClick={onCancel}
                  className="font_m fw_6"
                >
                  Cancel
                </TextButton>
              )}
            </div>
          </form>
        )}
      </Formik>

      <ErrorModal
        showModal={showErrModal}
        description={message}
        title="Error"
        onClose={() => {
          setShowErrModal(false);
        }}
      />
    </div>
  );
};
/* Component ends */

export default SearchForAccount;
