/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
import { getIsTENANT, getUser } from "../../setup/store/slices/authSlice";
import { getSetting } from "../../setup/store/slices/settingSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import AddPaymentSetting from "../../components/module/Payment/AddPaymentSetting/AddPaymentSetting";
import TextButton from "../../components/ui/button/TextButton/TextButton";
import Stripe from "./local-components/Stripe";
import BreadCrumb from "../../components/ui/other/BreadCrumb/BreadCrumb";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const PaymentSettingPage = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  const isTenant = useSelector(getIsTENANT);

  // Redux Selector
  const user = useSelector(getUser);
  const settings = useSelector(getSetting);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const paymentProcessorDetails = user?.payment_processor_details;
  const gateway = user?.payment_processor_details?.gateway;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const onEdit = () => {
    navigate("edit", { state: paymentProcessorDetails });
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      {!isTenant && <BreadCrumb type="settings_payment_page" />}
      <div className="payment-setting-page-wrapper container_sm">
        <h2 className="font_xxl heading text-center">Payment Setting</h2>

        <div className="inner-wrapper">
          {/* Add Edit Button */}
          {paymentProcessorDetails ? (
            <div className="btn-wrapper text-end">
              <TextButton
                onClick={() => onEdit()}
                className="font_m edit-btn fw_6"
              >
                Edit
              </TextButton>
            </div>
          ) : (
            <div className="btn-wrapper text-end">
              <TextButton
                onClick={() => onEdit()}
                className="font_m font_blue fw_6"
              >
                + Add
              </TextButton>
            </div>
          )}

          {/* Payment Gateway Details */}
          {/* Stripe */}
          {paymentProcessorDetails && gateway === "stripe" && (
            <Stripe data={paymentProcessorDetails} settings={settings} />
          )}

          {/* If no payment processor detail added */}
          {!paymentProcessorDetails && (
            <h3 className="font_l font_yellow fw_6 text-center">
              No Payment Settings Added!
            </h3>
          )}
        </div>
      </div>
    </>
  );
};
/* Component ends */

export default PaymentSettingPage;
