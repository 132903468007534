/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import GLOBAL from "../../setup/constants/global";
/* Import configuration ends */

/* Import redux slices component starts */
import { showErrorAlert } from "../../setup/store/slices/globalAlertSlice";
import { fetchTenantMoveOutDetail } from "../../setup/store/slices/moveOutSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import TenantMoveOutDetail from "../../components/module/Moveout/TenantMoveOutDetail/TenantMoveOutDetail";
/* Import local pages and component ends */

/* Component starts */
const TenantMoveOutDetailPage = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [sequence, setSequence] = useState([]);
  const [data, setData] = useState(null);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const { unitId, contractId, moveOutId } = useParams();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const getTenantMoveOutDetail = async () => {
    // Creating or collecting payload data to be sent
    const payload = { contract_id: contractId };
    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      const result = await dispatch(fetchTenantMoveOutDetail(payload));
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description:
                typeof result?.payload === "string" ? result?.payload : "",
            })
          );
          setData(null);
          setSequence([]);
          break;

        case GLOBAL.FULFILLED:
          setData(result?.payload?.data);
          setSequence(result?.payload?.sequence);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    if (contractId) {
      getTenantMoveOutDetail();
    }
  }, []);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="">
      {data && sequence?.length > 0 && (
        <TenantMoveOutDetail
          sequence={sequence}
          data={data}
          contract_id={contractId}
          unit_id={unitId}
          move_out_id={moveOutId}
          getTenantMoveOutDetail={getTenantMoveOutDetail}
        />
      )}
    </div>
  );
};
/* Component ends */

export default TenantMoveOutDetailPage;
