import GLOBAL from "../../../../setup/constants/global";
import {
  validEmail,
  validateAtleastOneNumber,
  validateAtleastOneSpecialChar,
  containsSpecialChar,
} from "../../../../setup/utils/validations";

export const initialValues = {
  first_name: "",
  last_name: "",
  email: "",
  mobile: "",
  contract_number: "",
  start_date: new Date(),
  duration: "",
  contract_amount: "",
  installments: "",
  deposits: "",
  commision: "",
  contract_pdf: null,
  deposit_amount_0: "",
  desciption_0: "",
};

export const tenantTypes = [
  {
    name: "New",
    type: GLOBAL.TENANT_TYPE.NEW,
  },
  {
    name: "Transitioned",
    type: GLOBAL.TENANT_TYPE.TRANSITIONED,
  },
];

export const validations = (
  values,
  customPhoneNum,
  tenantType,
  deposit,
  commision,
  depositList,
  globalSettings
) => {
  const errorFields = {};

  /* Validation for first name starts */
  if (values.first_name === "") {
    errorFields.first_name = "First Name is required";
  }
  if (values.first_name && validateAtleastOneNumber(values.first_name)) {
    errorFields.first_name = "First Name Cannot Contains Numbers";
  }
  if (values.first_name && containsSpecialChar(values.first_name)) {
    errorFields.first_name = "First Name Cannot Contains Special Characters";
  }
  /* Validation for first name ends */

  /* Validation for last name starts */
  if (values.last_name === "") {
    errorFields.last_name = "Last Name is required";
  }
  if (values.last_name && validateAtleastOneNumber(values.last_name)) {
    errorFields.last_name = "Last Name Cannot Contains Numbers";
  }
  if (values.last_name && containsSpecialChar(values.last_name)) {
    errorFields.last_name = "Last Name Cannot Contains Special Characters";
  }
  /* Validation for last name ends */

  /* Validation for email starts */
  if (values.email === "") {
    errorFields.email = "Email is required";
  }
  if (values.email && !validEmail(values.email)) {
    errorFields.email = "Email is not valid";
  }
  /* Validation for email ends */

  /* Validation for phone number starts */
  if (values.mobile === "") {
    errorFields.mobile = "Phone Number is required";
  }
  if (customPhoneNum.current.num.length < 8) {
    errorFields.mobile = "Phone number is not valid";
  }
  /* Validation for phone number ends */

  //  If Tenant Type is Transistioned
  if (tenantType?.type === GLOBAL.TENANT_TYPE.TRANSITIONED) {
    /* Validation for Contract Number starts */
    if (values.contract_number === "") {
      errorFields.contract_number = "Contract Number is required";
    }
    /* Validation for Contract Number ends */

    /* Validation for Start Date starts */
    if (values.start_date) {
      const selectedDate = new Date(values.start_date);
      const currentDate = new Date();
      const minDate = new Date(currentDate);
      minDate.setMonth(currentDate.getMonth() - 1);

      if (selectedDate > minDate) {
        errorFields.start_date =
          "Start date must be at least 1 month before the current date.";
      }
    }
    /* Validation for Start Date ends */

    /* Validation for Duration starts */
    if (values.duration === "") {
      errorFields.duration = "Duration is required";
    } else if (
      parseInt(values.duration) > globalSettings?.max_contract_duration
    ) {
      errorFields.duration =
        "Contract duration should be less than " +
        globalSettings.max_contract_duration;
    }
    /* Validation for Duration ends */

    /* Validation for Contract Amount starts */
    if (values.contract_amount === "") {
      errorFields.contract_amount = "Contract Amount is required";
    }
    /* Validation for Contract Amount ends */

    /* Validation for Installments starts */
    if (values.installments === "") {
      errorFields.installments = "Number Of Installment is required";
    } else if (
      parseInt(values.installments) >
      parseInt(values.duration) *
        parseInt(globalSettings?.max_monthly_installments)
    ) {
      errorFields.installments =
        "Number of installments should be less than " +
        parseInt(values.duration) *
          parseInt(globalSettings?.max_monthly_installments);
    }
    /* Validation for Installments ends */

    /* Validation for Deposits starts */
    if (deposit) {
      depositList?.forEach((id, index) => {
        const desciption = `desciption_${id}`;
        const depositAmount = `deposit_amount_${id}`;

        if (values[desciption] === "") {
          errorFields[desciption] = "Deposit description is required";
        }
        if (values[depositAmount] === "") {
          errorFields[depositAmount] = "Deposit amount is required";
        }
      });
    }
    /* Validation for Deposits ends */

    /* Validation for Commission starts */
    if (commision && values.commision === "") {
      errorFields.commision = "Commission is required";
    }
    /* Validation for Commission ends */

    /* Validation for Contract PDF starts */
    if (!values.contract_pdf) {
      errorFields.contract_pdf = "Contract PDF is required";
    }
    /* Validation for Contract PDF ends */
  }

  return errorFields;
};

// On selecting searched tenant
export const onSelectSearched = (
  tenant,
  setInitialFormValues,
  setExistingTenant,
  customPhoneNum,
  setMemberSelected
) => {
  setInitialFormValues((prevState) => {
    return {
      ...prevState,
      first_name: tenant?.first_name,
      last_name: tenant?.last_name,
      email: tenant?.email,
      mobile: tenant?.countrycode + "" + tenant?.mobile,
    };
  });
  customPhoneNum.current = { code: tenant?.countrycode, num: tenant?.mobile };
  setExistingTenant(false);
  setMemberSelected(true);
};

export const formReset = (
  setInitialFormValues,
  setTenantType,
  setDeposit,
  setCheque,
  customPhoneNum,
  setExistingTenant,
  setMemberSelected,
  setCommision
) => {
  customPhoneNum.current = { code: "", num: "" };

  setInitialFormValues(initialValues);
  setTenantType(tenantTypes[0]);
  setDeposit(false);
  setCheque(false);
  setCommision(false);

  setExistingTenant(false);
  setMemberSelected(false);
};

export const addMore = (formRef, setFormValue, list, setList) => {
  const generetedID = list.length === 0 ? 0 : list[list.length - 1] + 1;
  const currentFormState = { ...formRef.current.values };

  setFormValue(() => {
    // Generating new field name according to array indexes
    currentFormState[`desciption_${generetedID}`] = "";
    currentFormState[`deposit_amount_${generetedID}`] = "";
    return currentFormState;
  });
  setList((p) => [...p, generetedID]);
};

export const remove = (formRef, setFormValue, list, setList, index) => {
  const removeID = list[index];
  const currentFormState = { ...formRef.current.values };

  setFormValue(() => {
    delete currentFormState[`desciption_${removeID}`];
    delete currentFormState[`deposit_amount_${removeID}`];
    return currentFormState;
  });

  setList((prevState) => {
    const prevFields = [...prevState];
    prevFields.splice(index, 1);
    return prevFields;
  });
};
