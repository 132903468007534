/**
 *
 */

import React, { useState, useEffect, useRef } from "react";

/* Import configuration starts */
import { Formik } from "formik";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import GLOBAL from "../../../../setup/constants/global";
import { useDispatch } from "react-redux";
import {
  petValidation,
  petFormPrefill,
  setFormList,
  isDataChanged,
  isApproved,
} from "./helper";
/* Import configuration ends */

/* Import redux slices component starts */
import { showErrorAlert } from "../../../../setup/store/slices/globalAlertSlice";
import {
  clearMasterData,
  fetchPetTypes,
} from "../../../../setup/store/slices/masterSlice";
import { updateTenantApplication } from "../../../../setup/store/slices/tenantSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomNumberInput from "../../../ui/input/CustomNumberInput/CustomNumberInput";
import TextButton from "../../../ui/button/TextButton/TextButton";
import CustomDateInput from "../../../ui/input/CustomDateInput/CustomDateInput";
import CustomTextInput from "../../../ui/input/CustomTextInput/CustomTextInput";
import CustomSelectInput from "../../../ui/input/CustomSelectInput/CustomSelectInput";
import CustomFileInput from "../../../ui/input/CustomFileInput/CustomFileInput";
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
import SuccessModal from "../../../ui/modal/SuccessModal/SuccessModal";
import ErrorModal from "../../../ui/modal/ErrorModal/ErrorModal";
/* Import local pages and component ends */

/* Component starts */
const PetsForm = (props) => {
  /* Props destructuring starts */
  // state
  const {
    onNext,
    applicationId,
    data,
    petTypes,
    maxAllowed,
    fetchApplication = null,
    canCancelApplication = false,
    validateDataUpdated = true,
  } = props;

  // functions
  const {
    fetchPetTypes,
    clearMasterData,
    update,
    onCancelStep = null,
    onCancelApplication,
    onUpdatingInfo = null,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [message, setMessage] = useState("");
  const [showErrModal, setShowErrModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [count, setCount] = useState("");
  const [initialFormValues, setInitialFormValues] = useState({});
  const [formCountList, setFormCountList] = useState([]);
  const [counterChanged, setCounterChanged] = useState(false);

  // useRef
  const formRef = useRef();
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatchHook = useDispatch();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // On change count
  const handleCountChange = (e) => {
    const value = e.target.value;
    const intValue = value !== "" ? parseInt(value) : 0;

    // Checking for maximum member allowed
    if (!isNaN(intValue) && intValue > maxAllowed) {
      setMessage(`Maximum member allowed is ${maxAllowed}`);
      setShowErrModal(true);
      return;
    }
    setCount(value);
    setCounterChanged(true);
    setFormList(
      formCountList,
      setFormCountList,
      intValue,
      formRef,
      setInitialFormValues
    );
  };

  // Getting or Processing action error note from PM
  const getActionError = (d) => {
    if (d?.actions?.length > 0) {
      let lastAction = d["actions"][d["actions"].length - 1];
      if (
        lastAction.status === GLOBAL.TENANT_APPLICATION_STATUS.DATA_REJECTED
      ) {
        return true;
      }
    }
    return false;
  };

  // Form validations
  const formValidation = (values) => {
    return petValidation(values, formCountList);
  };

  // On updating vehicles
  const onSubmit = async (values) => {
    if (formCountList.length === 0 && data.length === 0) {
      onNext();
      return;
    }

    if (
      validateDataUpdated &&
      !counterChanged &&
      !isDataChanged(values, data, formCountList)
    ) {
      dispatchHook(
        showErrorAlert({
          title: "Error",
          description: "Please update the form",
        })
      );
      return;
    }

    // Creating or collecting payload data to be sent
    const payload = {
      applicationId,
      type: "pets",
      pets: [],
    };

    formCountList.forEach((i, indx) => {
      if (!isApproved(values[`status_${i}`])) {
        const detail = {};
        detail.name = values[`name_${i}`];
        detail.type = values[`type_${i}`];
        detail.dob = values[`dob_${i}`];

        if (indx < data.length) {
          detail._id = data[indx]._id;
        }

        if (values[`image_${i}`] && values[`image_${i}`] instanceof File) {
          payload[`img_${indx}`] = values[`image_${i}`];
        }

        payload.pets.push(detail);
      }
    });

    // Backend response. Try, Catch
    try {
      const result = await update(payload);
      console.log(result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setMessage(result.payload);
          setShowErrModal(true);
          break;

        case GLOBAL.FULFILLED:
          onUpdatingInfo && onUpdatingInfo();
          fetchApplication && fetchApplication();
          setMessage(result.payload.message);
          setShowSuccessModal(true);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  // Fetching necessary data on initial load
  useEffect(() => {
    fetchPetTypes();
  }, []);

  // On load of pet details previous data
  useEffect(() => {
    if (data?.length > 0) {
      setCount(parseInt(data?.length));
      petFormPrefill(data, setInitialFormValues, setFormCountList);
    }
  }, [data]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="pets container_sm">
      <h2 className="heading text-center font_xxl mb-5">Pets</h2>

      <Formik
        onSubmit={onSubmit}
        initialValues={initialFormValues}
        validate={formValidation}
        validateOnBlur={true}
        enableReinitialize={true}
        innerRef={formRef}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="form-wrapper">
              {/* Pet List Counter */}
              <div className="list-counter">
                <CustomNumberInput
                  label="Number of Pet"
                  handleChange={(e) => handleCountChange(e)}
                  value={count}
                  required={false}
                />
              </div>

              {/* Maximum pet allowed */}
              <p className="fw_5 font_s font_yellow max-allowed">
                Maximum pet allowed is {maxAllowed}
              </p>

              {/* If no pets added */}
              {formCountList.length === 0 && (
                <p className="font_m fw_5 text-center">No pets added !</p>
              )}

              {/* Pets form List */}
              {formCountList.map((item, index) => (
                <div key={index} className="form-section-group">
                  {/* Member Count Title */}
                  <h3 className="title-bubble font_blue font_m fw_6 w_100">
                    Pet #{index + 1}
                    {isApproved(values[`status_${item}`]) && (
                      <p className="font_m fw_5 already-approved-warning">
                        <span className="font_yellow">
                          All fields are disabled, as this information is
                          already
                        </span>{" "}
                        <span className="font_green">Approved</span>
                      </p>
                    )}
                  </h3>

                  {/* Require action */}
                  {getActionError(data[index]) && (
                    <p className="w_100 font_red font_s fw_5">
                      {
                        data[index]?.actions[data[index]?.actions?.length - 1]
                          .note
                      }
                    </p>
                  )}

                  {/* Form Fields */}
                  {/* Input for Pet Name */}
                  <div className="form-group">
                    <CustomTextInput
                      name={`name_${item}`}
                      label="Name"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched[`name_${item}`]}
                      errors={errors[`name_${item}`]}
                      value={values[`name_${item}`]}
                      required={true}
                      readOnly={isApproved(values[`status_${item}`])}
                    />
                  </div>

                  {/* Input for Type of Pet */}
                  <div className="form-group">
                    <CustomSelectInput
                      label="Type"
                      name={`type_${item}`}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      options={petTypes}
                      value={values[`type_${item}`]}
                      errors={errors[`type_${item}`]}
                      touched={touched[`type_${item}`]}
                      valueExtractor={(d) => d?.name}
                      disabled={isApproved(values[`status_${item}`])}
                    />
                  </div>

                  {/* Input for Date of Birth */}
                  <div className="form-group">
                    <CustomDateInput
                      name={`dob_${item}`}
                      label="Date of birth"
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      touched={touched[`dob_${item}`]}
                      errors={errors[`dob_${item}`]}
                      value={values[`dob_${item}`]}
                      maxDate={new Date()}
                      minDate={false}
                      required={true}
                      disabled={isApproved(values[`status_${item}`])}
                    />
                  </div>

                  {/* Input for ID Photo */}
                  <div className="form-group">
                    <CustomFileInput
                      name={`image_${item}`}
                      label="Photo"
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      formikState={true}
                      touched={touched[`image_${item}`]}
                      errors={errors[`image_${item}`]}
                      value={values[`image_${item}`]}
                      required={true}
                      disabled={isApproved(values[`status_${item}`])}
                    />
                  </div>
                </div>
              ))}
            </div>

            <div className="button-wrapper text-center">
              <CustomButton type="submit" size="l">
                Next
              </CustomButton>

              {onCancelStep && (
                <TextButton onClick={onCancelStep} className="font_m fw_6">
                  Back
                </TextButton>
              )}
            </div>
          </form>
        )}
      </Formik>

      {canCancelApplication && (
        <div className="cancel-application-btn-wrapper mt-4">
          <CustomButton
            className="cancel-application-btn"
            variant="red"
            size="l"
            onClick={onCancelApplication}
          >
            Cancel Application
          </CustomButton>
        </div>

        // <div className="cancel-application-wrapper bg_grey">
        //   <p className="font_m font_yellow fw_5">
        //     I would like to cancel the onboarding application process. I am
        //     aware that after cancelling the application I won't be able to undo
        //     my action.
        //   </p>
        //   <div className="cancel-application-btn-wrapper">
        //     <CustomButton
        //       className="cancel-application-btn"
        //       variant="red"
        //       size="m"
        //       onClick={onCancelApplication}
        //     >
        //       Cancel Application
        //     </CustomButton>
        //   </div>
        // </div>
      )}

      {/* Modals */}
      {/* Success Modal */}
      <SuccessModal
        showModal={showSuccessModal}
        description={message}
        title="Success"
        onClose={() => onNext()}
      />

      {/* Error Modal */}
      <ErrorModal
        showModal={showErrModal}
        description={message}
        title="Error"
        onClose={() => setShowErrModal(false)}
      />
    </div>
  );
};
/* Component ends */

const mapStateToProps = (state) => {
  return {
    petTypes: state.master.petTypes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPetTypes: bindActionCreators(fetchPetTypes, dispatch),
    update: bindActionCreators(updateTenantApplication, dispatch),
    clearMasterData: bindActionCreators(clearMasterData, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PetsForm);
