/**
 *
 */

import React from "react";

/* Import configuration starts */
import Viewer from "react-viewer";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import MUI component starts */
/* Import MUI component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
/* Import local pages and component ends */

import "./style.scss"

/* Component starts */
const ImageViewerModal = (props) => {
  /* Props destructuring starts */
  const {
    images, // This is array of object. Format [{src: "", alt: ""}, {src: "", alt: ""}]
    visible,
    onClose,
    ...rest
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="custom-image-viewer-component-wrapper">
      <Viewer
        visible={visible}
        onClose={onClose}
        images={images}
        drag={true}
        scalable={false}
        {...rest}
      />
    </div>
  );
};
/* Component ends */

export default ImageViewerModal;
