/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import GLOBAL from "../../setup/constants/global";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  fetchVisitorGuestPassList,
  getGuestPassList,
} from "../../setup/store/slices/guestPass";
import {
  fetchUnitDetailByID,
  getUnitDetail,
} from "../../setup/store/slices/unitSlice";
import { showErrorAlert } from "../../setup/store/slices/globalAlertSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import GuestPassTable from "../../components/module/GuestPass/GuestPassTable/GuestPassTable";
/* Import local pages and component ends */

// styles sheet
import "./style.scss";

/* Component starts */
const TenantGuestPass = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [currentPage, setCurrentPage] = useState(1);
  const [guestsList, setGuestsList] = useState(null);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const { unitId } = useParams();
  const unit = useSelector(getUnitDetail);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const onloadGuestList = async () => {
    // Creating or collecting payload data to be sent
    const payload = { unit_id: unit?._id, page: currentPage };
    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      const result = await dispatch(fetchVisitorGuestPassList(payload));
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setGuestsList(null);
          dispatch(
            showErrorAlert({
              title: "Error!",
              description:
                typeof result?.payload === "string" ? result?.payload : "",
            })
          );
          break;

        case GLOBAL.FULFILLED:
          setGuestsList(result?.payload);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    onloadGuestList();
  }, [unit, currentPage]);

  useEffect(() => {
    dispatch(fetchUnitDetailByID({ unit_id: unitId }));
  }, [unitId]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */

  return (
    <div className="guestPass-wrapper">
      <div className="heading-wrapper">
        <div className="heading">
          <h2 className="font_xxl fw_5">My Guest Pass</h2>
        </div>
        <div className="register-wrapper">
          <Link to="add" className="font_m fw_4 d_block link-security">
            + Add Guest Pass
          </Link>
        </div>
      </div>

      {guestsList && (
        <GuestPassTable
          guestPassList={guestsList?.visitorpass}
          totalCount={guestsList?.totalCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
    </div>
  );
};
/* Component End */

export default TenantGuestPass;
