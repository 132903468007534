/*
 * Slice to handle Visitor Pass Module
 */

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../axios";
import { API_URL } from "../../config/devKeys";
import { setLoading } from "./unpersistedSlice";

const initialState = {
  visitorPassList: [],
  visitorPassData: null,
};

// FETCH Visitors PASS LIST - Client Based On Property
export const fetchVisitorAccessPassList = createAsyncThunk(
  "visitor-pass/list",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(
        API_URL +
          `/visitor-pass/property/list?property_id=${payload?.property_id}&type=${payload?.type}&page=${payload?.page}`,
        // `http://localhost:3000/visitor-pass/property/list?property_id=${payload?.property_id}&type=${payload?.type}&page=${payload?.page}`,
        {
          method: "GET",
          headers: headers,
          // body: JSON.stringify({
          //   property_id: payload,
          // }),
        }
      );

      const data = await response.json();
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// GET Access Pass Data Based on ID
export const fetchAccessPassBasedOnId = createAsyncThunk(
  "visitor-pass/get",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.get(
        `${API_URL}/visitor-pass/get?id=${payload}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        rejectWithValue(data.error);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// Update Contractor Pass By Approved --> Client ( PM - T3{Model} )
export const updateContractorPassByApproved = createAsyncThunk(
  "visitor-pass/approved",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));

      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };

      const response = await fetch(
        `${API_URL}/visitor-pass/approved?id=${payload}`,
        {
          method: "PATCH",
          headers: headers,
        }
      );
      const data = await response.json();

      dispatch(setLoading(false));
      console.log(data, "Accepted");
      if (data.success) {
        await dispatch(fetchVisitorAccessPassList());
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return error?.response;
    }
  }
);

// Update Contractor Pass By Rejected --> Client ( PM - T3{Model} )
export const updateContractorPassByRejected = createAsyncThunk(
  "visitor-pass/rejected",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { id } = payload;

      console.log(payload, "[TEST]", id);
      dispatch(setLoading(true));

      const { data } = await axios.patch(
        `${API_URL}/visitor-pass/rejected?id=${id}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );

      // const headers = {
      //   Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      // };

      // const response = await fetch(
      //   `${API_URL}/visitor-pass/rejected?id=${id}`,
      //   payload,
      //   {
      //     method: "PATCH",
      //     headers: headers,
      //   }
      // );
      // const data = await response.json();

      dispatch(setLoading(false));
      console.log(data, "Rejected");
      if (data.success) {
        // await dispatch(fetchVisitorAccessPassList());
        await dispatch(fetchAccessPassBasedOnId(id));
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return error?.response;
    }
  }
);

export const visitorAccessPass = createSlice({
  name: "visitorPass",
  initialState,
  extraReducers: {
    [fetchVisitorAccessPassList.rejected]: (state, action) => {
      state.visitorPassList = [];
    },
    [fetchVisitorAccessPassList.fulfilled]: (state, action) => {
      state.visitorPassList = action.payload;
    },
    [fetchAccessPassBasedOnId.rejected]: (state, action) => {
      state.visitorPassData = null;
    },
    [fetchAccessPassBasedOnId.fulfilled]: (state, action) => {
      state.visitorPassData = action.payload;
    },
  },
});

// To Get The Visitors Pass List
export const getVisitorAccessPassList = (state) =>
  state.visitorPass.visitorPassList;

// To Get The Visitors Pass Data
export const getVisitorPassData = (state) => state.visitorPass.visitorPassData;

export default visitorAccessPass.reducer;
