/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  updateComplaintAcceptedBySO,
  updateComplaintRejectedBySO,
} from "../../../setup/store/slices/securitySlice";
import { getUser } from "../../../setup/store/slices/authSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import GLOBAL from "../../../setup/constants/global";
import CustomButton from "../../../components/ui/button/CustomButton/CustomButton";
import FileViewerModal from "../../../components/ui/modal/FileViewerModal/FileViewerModal";

/* Import local pages and component ends */

/* Component starts */
const Report = (props) => {
  /* Props destructuring starts */
  const { incident, role } = props;
  console.log(incident, "DATA");
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */

  const [showFileViewer, setShowFileViewer] = useState(false);
  const [file, setFile] = useState({});

  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const user = useSelector(getUser);
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  //  Update Complaint By Accepted By SO
  const handleUpdateComplaintAcceptBySO = (id) => {
    dispatch(updateComplaintAcceptedBySO(id));

    navigate(-1);
  };

  // Update Complaint By Rejected By SO
  const handleUpdateComplaintRejectBySO = (id) => {
    dispatch(updateComplaintRejectedBySO(id));

    navigate(-1);
  };

  /* Component function definition ends */

  /* Component useEffect starts */

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */

  return (
    <div className="unit-detail-box">
      {incident?.type === "Complaint" ? (
        <h3 className="num fw_6 font_l ">
          Ticket No. :- {incident?.ticket_id}
        </h3>
      ) : (
        <h3 className="num fw_6 font_l">
          {" "}
          Ticket No. :-{" "}
          <span className="fw_6 font_l font_blue">
            {incident?.incident_id}
          </span>{" "}
        </h3>
      )}
      <p className="title font_m fw_3 font_grey">{incident?.description}</p>

      {incident?.type !== "Complaint" ? (
        <div className="date-wrapper-incident">
          <p className="font_m fw_3 font_grey">
            Date :{" "}
            <span className="font_m fw_3">
              {moment(incident?.date).format(DATE_FORMAT)}
            </span>
          </p>

          <p className="font_m fw_3 font_grey">
            Time :{" "}
            <span className="font_m fw_3">
              {moment(incident?.date).format(TIME_FORMAT)}
            </span>
          </p>
        </div>
      ) : (
        <div className="date-wrapper">
          <div>
            <span className="font_m fw_3 font_grey">Date & Time</span>
            <br />
            <span className="font_m fw_3">
              {moment(incident?.date).format(DATE_TIME_FORMAT)}
            </span>
          </div>

          <div>
            <span className="font_m fw_3 font_grey">Type Of Unit</span>
            <br />
            <span className="font_m fw_3">
              {incident?.unit_id?.unit_type?.name}
            </span>
          </div>
        </div>
      )}

      <hr />

      <div className="location-wrapper">
        {incident?.type !== "Complaint" && (
          <div>
            <span className="font_m fw_3 font_grey">Location</span>
            <br />
            <span className="font_m fw_4">{incident?.location}</span>
          </div>
        )}

        {incident?.type !== "Complaint" ? (
          <div>
            <span className="font_m fw_3 font_grey">Violator</span>
            <br />{" "}
            <span className="font_m fw_6">
              {incident?.violator === "Tenants"
                ? `${incident?.violator_id?.first_name} ${" "}
              ${incident?.violator_id?.last_name}`
                : incident?.violator === "Teams"
                ? `${incident?.team_member_id?.first_name}${" "}
              ${incident?.team_member_id?.last_name}`
                : `${incident?.violator_name}`}
              {`(${incident?.violator.toUpperCase()})`}
            </span>
          </div>
        ) : (
          <div>
            <span className="font_m fw_3 font_grey">Tenant Name</span>
            <br />{" "}
            <span className="font_m fw_6">
              {incident.tenant_id?.first_name} {incident?.tenant_id?.last_name}
            </span>
          </div>
        )}

        {incident?.type === "Complaint" ? (
          <div>
            <span className="font_m fw_3 font_grey">Unit</span>
            <br />{" "}
            <span className={`font_m fw_6 font_blue`}>
              {incident?.unit_id?.unit_name}
            </span>
          </div>
        ) : (
          <div>
            {incident?.violator === "Tenants" && (
              <>
                <span className="font_m fw_3 font_grey">Unit</span>
                <br />{" "}
                <span className={`font_m fw_6 font_blue`}>
                  {incident?.unit_id?.unit_number}
                </span>
              </>
            )}
          </div>
        )}

        <div>
          <span className="font_m fw_3 font_grey">Status</span>
          <br />{" "}
          <span
            className={`font_m fw_6 ${
              incident?.status === "Open" ? "open" : "closed"
            }`}
          >
            {incident?.status.toUpperCase()}
          </span>
        </div>
      </div>

      <hr />

      {incident?.documents.length > 0 && (
        <>
          <div className="image-wrapper">
            {incident?.documents.map((image, index) => (
              <div className="proof-img" key={index}>
                <img
                  src={image.url}
                  alt="ID-Proof"
                  onClick={() => {
                    setFile(image);
                    setShowFileViewer(true);
                  }}
                />
              </div>
            ))}
          </div>

          <hr />
        </>
      )}

      <div className="ticket-wrapper">
        <p className="font_m fw_4 font_grey">
          Ticket Assign{" "}
          <span className="font_m fw_3">
            {incident?.assigned_to !== null
              ? `${incident?.assigned_to?.first_name}${" "}
            ${incident?.assigned_to?.last_name} ${
                  incident?.assigned_to?.role_id?.name === "Owner"
                    ? incident?.assigned_to?.role_id?.name
                    : incident?.assigned_to?.role_id?.name
                        ?.split(" ")
                        .map((role) => role[0])
                        .join("")
                }`
              : "NA"}
          </span>
        </p>
      </div>

      <div className="last-update">
        <p className="font_m fw_3 font_grey">
          Request Date{" "}
          <span className="font_s fw_4">
            {moment(incident?.createdAt).format(DATE_TIME_FORMAT)}
          </span>
        </p>
      </div>

      {/* T3 - SO */}
      {incident?.level === "t3" &&
        incident?.status === "Open" &&
        role === "Security Officer" && (
          <>
            {user?._id === incident?.assigned_to?._id &&
              (incident?.action !== "accepted" ||
                incident?.action !== "rejected") &&
              (!incident?.manually_assigned || incident?.manually_assigned) && (
                <div className="btn">
                  <CustomButton
                    size="l"
                    onClick={() =>
                      handleUpdateComplaintAcceptBySO(incident?._id)
                    }
                  >
                    Approve
                  </CustomButton>

                  <CustomButton
                    size="l"
                    variant="red"
                    onClick={() =>
                      handleUpdateComplaintRejectBySO(incident?._id)
                    }
                  >
                    Reject
                  </CustomButton>
                </div>
              )}
          </>
        )}

      {/* Modal */}
      {/* File viewer modal */}
      <FileViewerModal
        show={showFileViewer}
        type={file.type}
        file={file.url}
        onClose={() => {
          setShowFileViewer(false);
          setFile({});
        }}
      />
    </div>
  );
};
/* Component Ends */
export default Report;
