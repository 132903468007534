/**
 * Global constants
 * @author Abdul Ahad <abdulahad.ss@smartdatainc.net>
 *
 * @example
 * import {GLOBAL} from './constants';
 * GLOBAL.FULFILLED
 */

const year = new Date().getFullYear();
const month = new Date().getMonth();
const day = new Date().getDate();

const GLOBAL = {
  DATE_FORMAT: "DD/MM/YYYY",
  DATE_TIME_FORMAT: "DD/MM/YYYY hh:mm A",
  DEFAULT_LICENSES: "1",
  FULFILLED: "fulfilled",
  REJECTED: "rejected",
  TIME_FORMAT: "hh:mm A",
  UNPAID: "unpaid",
  PAID: "paid",
  MIN_DOB: new Date(year - 100, month, day),
  MAX_DOB: new Date(year - 18, month, day),
  PROP_STEP: {
    LICENSE: "license",
    MGMT: "mgmt",
    OWNER: "owner",
    PMC: "PMC",
    FMC: "FMC",
    SMC: "SMC",
    DONE: "done",
  },
  USER_ROLE: {
    CLIENT: "Client",
    OWNER: "Owner",
    TENANT: "Tenant",
    PMC: "Property Management Company",
    SMC: "Security Management Company",
    FMC: "Facility Management Company",
    TPC: "Third-Party Company",
    PM: "Property Manager",
    ACCOUNTANT: "Accountant",
    SM: "Security Manager",
    SO: "Security Officer",
    FM: "Facility Manager",
    MT: "Maintenance Technician",
    TPM: "Third Party Manager",
    TPT: "Third Party Technician",
  },
  USER_STATUS: {
    PENDING_INVITATION: "pending_invitation",
    PENDING_APPROVAL: "pending_approval",
    SUSPENDED: "suspended",
    ACTIVE: "active",
    REJECTED: "rejected",
    DEACTIVATED: "deactivated",
    APPROVED: "approved",
    EXPIRED: "expired",
    CANCELLED: "canceled",
  },
  PAYMENT_STATUS: {
    REQUIRE_ACTION: "requires_action",
    SUCCESS: "Success",
    SUCCEEDED: "Succeeded",
    FAILED: "Failed",
  },
  PAYMENT_GATEWAY: {
    STRIPE: "stripe",
  },
  PROPERTY_TYPE: {
    COMMERCIAL: "Commercial",
    MIX_USE: "Mix Use",
    RESIDENTIAL: "Residential",
  },
  UNIT_STATUS: {
    VACANT: "vacant",
    RESERVED: "reserved",
    OCCUPIED: "occupied",
  },
  DOC_TYPE: {
    IMAGE: "image",
    PDF: "pdf",
    VIDEO: "video",
  },
  TENANT_TYPE: {
    NEW: "new",
    TRANSITIONED: "transitioned",
  },
  TENANCY_TYPE: {
    RENT: "rent",
    OWN: "own",
  },
  TENANT_APPLICATION_STATUS: {
    PENDING_APPLICATION: "reservor-pending-application",
    PENDING_CONFIRMATION: "reservor-pending-confirmation",
    PENDING_ACTION: "reservor-pending-action",
    PENDING_REVIEW: "pending-application-review",
    APPROVED: "reservor-approved",
    REJECTED: "reservor-rejected",
    DATA_APPROVED: "approved",
    DATA_REJECTED: "rejected",
    DATA_PENDING: "pending",
    PENDING_AGREEMENT_TERMS: "pending-initial-agreement-terms",
    PENDING_AGREEMENT_REVIEW: "pending-agreement-review",
    PENDING_CONTRACT_REVIEW: "pending-contract-review",
    PENDING_CONTRACT_SIGNATURE: "reservor-pending-contract-signature",
    UNIT_MAINTENANCE: "unit-maintenance",
    PENDING_KEY_COLLECTION: "reservor-pending-key-collection",
    REJECTED_KEY_COLLECTION: "reservor-rejected-key-collection",
    TENANT: "tenant",
    INVITATION_CANCELLED: "invitation_canceled",
    EXPIRED: "expired",
  },
  PAYMENT_TYPE: {
    LICENSE: "license",
    INVOICE: "invoice",
    TENANT_APPLICATION_FEE: "tenant_application_fee",
    TENANT_CONTRACT: "tenant_contract",
    TENANT_INVOICE: "tenant_invoice",
    APPLICATION_FEE: "application-fee",
    DEPOSIT: "deposit",
    RENT: "rent",
    COMMISSION: "commission",
    OTHER: "other",
  },
  MGMT_MODEL: {
    T1: 0,
    T2: 1,
    T3: 2,
  },
  VIOLATION_TO: {
    TENANT: "Tenants",
    TEAM: "Teams",
    OTHERS: "Others",
  },
  SECURITY_STATUS: {
    OPEN: "Open",
    CLOSED: "Closed",
    RESOLVED: "resolved",
    PENDING_SO_ASSIGNMENT: "pending_so_assignment",
    PENDING_DECISION: "pending_decision",
    ESCALATED: "escalated",
    ESCALATED_TO_SM: "escalated_to_sm",
    ESCALATED_TO_PM: "escalated_to_pm",
    SO_ASSIGNED: "so_assigned",
    SO_REJECTED: "so_rejected",
    SO_ACCEPTED: "so_accepted",
    INITIATED: "initiated",
    REPORT_SUBMITTED: "report_submitted",
    PENDING_APPROVAL: "pending_approval",
    NEED_MORE_INFO: "need_more_info",
  },
  MAINTENANCE: {
    NEW: "New",
    ONGOING: "Ongoing",
    SPARE_PARTS: "Spare Parts",
    COMPLETED: "Completed",
    REJECTED: "Rejected",
    ESCALATED: "Escalated",
    MOVE_IN: "Move In",
    MOVE_OUT: "Move Out",
    UNITS: "Units",
    COMMON_AREA: "Common Area",
    CLOSED: "Closed",
  },
  MAINTENANCE_LOG_STATUS: {
    NEW: "New",
    PENDING: "Pending",
    REJECTED: "Rejected",
    ACCEPTED: "Accepted",
    REASSIGNED: "Reassigned",
    REASSIGNED_TO_FM: "Reassigned To FM",
    FM_APPROVAL_PENDING: "fm_approval_pending",
    PM_APPROVAL_PENDING: "pm_approval_pending",
    APPROVED: "Approved",
    ASSIGNED_TO_FM: "Ticket Assigned to FM",
    REASSIGNED_TO_MT: "Reassigned To Technician",
    ASSIGNED_TO_PM: "Ticket Assigned to PM",
    P2_ASSIGNED_TO_FM: "UIRC phase 2 assigned to FM",
    SKIPPED: "Skipped",
    QUOTATION_ASSIGNED_TO_PM: "Quoatation assigned To PM",
  },
  FOR: {
    ALL: "all",
    COMMUNITY: "community",
    PROPERTY: "property",
  },
  INVOICING: {
    TENANTS: "Tenant",
    PROPERTIES_INVOICES: "Property Invoice",
    PARTIES_INVOICES: "Parties Invoice",
    ADVERTISMENT: "Advertisments",
  },
  DASHBOARD: {
    OCCUPANCY: "Occupancy",
    CONTRACTS: "Contracts",
    FINANCIAL: "Financial",
    TENANT_MAINTENANCE: "Tenant_Maintenance",
    INTERNAL_MAINTENANCE: "Internal_Maintenance",
    SECURITY: "Security",
    SPARE_PARTS: "Spare_Parts",
    RATINGS: "Ratings",
    TEAMS: "Teams",
    VIOLATIONS: "Violations",
    MOVE_IN: "Move_In",
    MOVE_OUT: "Move_Out",
  },
};

export default GLOBAL;
