/**
 * This component is a Modal that is shown on success
 */

import React from "react";

/* Import react bootstrap component starts */
import { Modal } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { SuccessIconOutlinedBlue } from "../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomButton from "../../button/CustomButton/CustomButton";
/* Import local pages and component ends */

import "./style.scss";
import { getUserTheme } from "../../../../setup/store/slices/authSlice";
import { useSelector } from "react-redux";

/* Component starts */
const SuccessModal = (props) => {
  /* Props destructuring starts */
  const {
    showModal,
    description,
    title,
    size = "md",
    onClose,
    btnText = "Close",
  } = props;
  /* Props destructuring ends */

  const userTheme = useSelector(getUserTheme);

  /* ************* Component rendering. JSX code ************* */
  return (
    <Modal
      size={size}
      centered
      show={showModal}
      className={`success-modal-wrapper `}
    >
      <Modal.Body
        className={`${userTheme === "dark" ? "bg_darkTheme" : "bg_lightTheme"}`}
      >
        <div className="modal-header-custom">
          <div className="icon-wrapper text-center">
            <img src={SuccessIconOutlinedBlue} alt="Success Icon" />
          </div>
          <h2 className="text-center font_blue">{title}</h2>
        </div>
        <div className="modal-body-custom">
          <p className="description text-center font_m font_blue">
            {description}
          </p>
        </div>
        <div className="modal-footer-custom">
          <CustomButton size="m" variant="primary" onClick={onClose}>
            {btnText}
          </CustomButton>
        </div>
      </Modal.Body>
    </Modal>
  );
};
/* Component ends */

export default SuccessModal;
