/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import ApplicationStatuses from "./sub-components/ApplicationStatuses";
import InitialAgreementStatuses from "./sub-components/InitialAgreementStatuses";
import ContractStatuses from "./sub-components/ContractStatuses";
import MoveInStatuses from "./sub-components/MoveInStatuses";

import ApplicationPendingConfirmation from "./sub-components/ApplicationPendingConfirmation";
import ApplicationPendingApplication from "./sub-components/ApplicationPendingApplication";
import ApplicationPendingReview from "./sub-components/ApplicationPendingReview";
import ApplicationPendingAction from "./sub-components/ApplicationPendingAction";

import InitialAgreementPendingTerms from "./sub-components/InitialAgreementPendingTerms";
import InitialAgreementPendingAction from "./sub-components/InitialAgreementPendingAction";
import InitialAgreementPendingReview from "./sub-components/InitialAgreementPendingReview";

import EarlyMoveOutStarted from "./sub-components/EarlyMoveOutStarted";

import ContractPendingAction from "./sub-components/ContractPendingAction";
import ContractPendingSignature from "./sub-components/ContractPendingSignature";
import ContractPendingReview from "./sub-components/ContractPendingReview";

import UtilitiesRejected from "./sub-components/UtilitiesRejected";
import Utilities from "./sub-components/Utilities";
import UtilitiesSubmitted from "./sub-components/UtilitiesSubmitted";
import PendingFinalAgreementSign from "./sub-components/PendingFinalAgreementSign";
import FinalAgreementSubmitted from "./sub-components/FinalAgreementSubmitted";
import MoveInMaintenance from "./sub-components/MoveInMaintenance";
import MoveInPendingKey from "./sub-components/MoveInPendingKey";
import KeyRejected from "./sub-components/KeyRejected";
import ChequeSubmissionPending from "./sub-components/ChequeSubmissionPending";
/* Import local pages and component ends */

import "./style.scss";
import ApplicationFeePendingAction from "./sub-components/ApplicationFeePendingAction";
import KeysPendingDispatch from "./sub-components/KeysPendingDispatch";

/* Component starts */
const TenantOnbadingStatuses = (props) => {
  /* Props destructuring starts */
  const { unit, fetchTenantUnitsNew } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  switch (unit?.status) {
    case "pending_tenant_inv_confirmation":
      return (
        <div className="tenant-unit-details-page-wrapper">
          <ApplicationPendingConfirmation
            unit={unit}
            fetchTenantUnitsNew={fetchTenantUnitsNew}
          />
        </div>
      );

    case "pending_application_fee":
      return (
        <div className="tenant-unit-details-page-wrapper">
          <ApplicationFeePendingAction
            unit={unit}
            fetchTenantUnitsNew={fetchTenantUnitsNew}
          />
        </div>
      );

    case "pending_tenant_application":
      return (
        <div className="tenant-unit-details-page-wrapper">
          <ApplicationPendingApplication
            unit={unit}
            fetchTenantUnitsNew={fetchTenantUnitsNew}
          />
        </div>
      );

    case "pending_review":
      return (
        <div className="tenant-unit-details-page-wrapper">
          <ApplicationPendingReview
            unit={unit}
            fetchTenantUnitsNew={fetchTenantUnitsNew}
          />
        </div>
      );

    case "pending_tenant_data_update": // TODO Change the link
      return (
        <div className="tenant-unit-details-page-wrapper">
          <ApplicationPendingAction
            unit={unit}
            fetchTenantUnitsNew={fetchTenantUnitsNew}
          />
        </div>
      );

    case "pending_initial_agreement_terms":
      return (
        <div className="tenant-unit-details-page-wrapper">
          <InitialAgreementPendingTerms
            unit={unit}
            fetchTenantUnitsNew={fetchTenantUnitsNew}
          />
        </div>
      );

    case "pending_tenant_sign_initial_agreement":
      return (
        <div className="tenant-unit-details-page-wrapper">
          <InitialAgreementPendingAction
            unit={unit}
            fetchTenantUnitsNew={fetchTenantUnitsNew}
          />
        </div>
      );

    case "pending_pm_sign_initial_agreement":
      return (
        <div className="tenant-unit-details-page-wrapper">
          <InitialAgreementPendingReview
            unit={unit}
            fetchTenantUnitsNew={fetchTenantUnitsNew}
          />
        </div>
      );

    case "early_moveout_started":
      return (
        <div className="tenant-unit-details-page-wrapper">
          <EarlyMoveOutStarted
            unit={unit}
            fetchTenantUnitsNew={fetchTenantUnitsNew}
          />
        </div>
      );

    case "tenant_invoice_payment":
      return (
        <div className="tenant-unit-details-page-wrapper">
          <ContractPendingAction
            unit={unit}
            fetchTenantUnitsNew={fetchTenantUnitsNew}
          />
        </div>
      );

    case "utility_rejected":
      return (
        <div className="tenant-unit-details-page-wrapper">
          <UtilitiesRejected
            unit={unit}
            fetchTenantUnitsNew={fetchTenantUnitsNew}
          />
        </div>
      );

    case "utilities":
      return (
        <div className="tenant-unit-details-page-wrapper">
          <Utilities unit={unit} fetchTenantUnitsNew={fetchTenantUnitsNew} />
        </div>
      );

    case "utilities_submitted":
      return (
        <div className="tenant-unit-details-page-wrapper">
          <UtilitiesSubmitted
            unit={unit}
            fetchTenantUnitsNew={fetchTenantUnitsNew}
          />
        </div>
      );

    case "pending_tenant_sign_final_agreement":
      return (
        <div className="tenant-unit-details-page-wrapper">
          <PendingFinalAgreementSign
            unit={unit}
            fetchTenantUnitsNew={fetchTenantUnitsNew}
          />
        </div>
      );

    case "pending_approver_sign_final_agreement":
      return (
        <div className="tenant-unit-details-page-wrapper">
          <FinalAgreementSubmitted
            unit={unit}
            fetchTenantUnitsNew={fetchTenantUnitsNew}
          />
        </div>
      );

    case "stay_tuned":
      return (
        <div className="tenant-unit-details-page-wrapper">
          <MoveInMaintenance />
        </div>
      );

    case "pending_key_collection":
      return (
        <div className="tenant-unit-details-page-wrapper">
          <MoveInPendingKey unit={unit} />
        </div>
      );

    case "key_rejected":
      return (
        <div className="tenant-unit-details-page-wrapper">
          <KeyRejected unit={unit} />
        </div>
      );

    case "pending_key_dispatch":
      return (
        <div className="tenant-unit-details-page-wrapper">
          <KeysPendingDispatch unit={unit} />
        </div>
      );

    case "pending_tenant_cheque_submission":
      return (
        <div className="tenant-unit-details-page-wrapper">
          <ChequeSubmissionPending
            unit={unit}
            fetchTenantUnitsNew={fetchTenantUnitsNew}
          />
        </div>
      );

    default:
      return null;
  }
};
/* Component ends */

export default TenantOnbadingStatuses;
