export const gatewayList = [
  // { name: "Checkout", gateway: "checkout" },
  { name: "Stripe", gateway: "stripe" },
];

/* Stripe form configuration starts */
// Initial form value
export const formValueStripe = {
  stripe_public_key: "",
  stripe_secret_key: "",
};

// Form prefill
export const formPrefillStripe = (data, setInitialFormValues) => {
  setInitialFormValues({
    stripe_public_key: data?.stripe_public_key || "",
    stripe_secret_key: data?.stripe_secret_key || "",
  });
};

// Form validation
export const validationStripe = (value) => {
  const error = {};

  // Public key validation starts
  if (value.stripe_public_key === "") {
    error.stripe_public_key = "Public Key is required";
  }
  // Public key validation ends

  // Secret key validation starts
  if (value.stripe_secret_key === "") {
    error.stripe_secret_key = "Secret Key is required";
  }
  // Secret key validation ends

  return error;
};
/* Stripe form configuration ends */
