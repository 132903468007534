/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { LazyLoadImage } from "react-lazy-load-image-component";
import VideoThumbnail from "react-video-thumbnail";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { PlayFilledBlue } from "../../../../setup/constants/images";
import ImageLoader from "../../../../assets/loader/image-loader.svg";
/* Import image and SVG ends */

/* Import local pages and component starts */
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const Image = (props) => {
  /* Props destructuring starts */
  const {
    src,
    alt,
    style,
    className = "",
    sizeByWidth = true,
    sizeByHeight = false,
    imgClass = "",
    objectFit = "cover", // fill / cover / contain
    objectPosition = "",
    overflow = "hidden",
    videoThumbnail = false,
    aspectRatioResponsiveness = false,
    aspectRatio = "1:1",
    imageFit = "",
    imagePosition = "top-left",
    backgroundImage = false,
    edgeRounded = "",
    videoThumbnailText = true,
    ...rest
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [thumbnail, setThumbnail] = useState("");
  const [loaded, setLoaded] = useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const dependentSize = sizeByHeight
    ? "height-scale"
    : sizeByWidth
    ? "width-scale"
    : "";

  const objectFitStyleClass = objectFit !== "" ? `objectFit-${objectFit}` : "";

  const objectPositionStyle =
    objectFit !== "" && objectPosition !== ""
      ? { objectPosition: objectPosition }
      : {};
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const styleAsPerAspectRatio = () => {
    if (aspectRatioResponsiveness) {
      switch (aspectRatio) {
        case "1:1":
          return { paddingTop: "100%" };

        case "4:3":
          return { paddingTop: "75%" };

        case "3:2":
          return { paddingTop: "66.67%" };

        case "16:9":
          return { paddingTop: "56.25%" };
      }
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    setLoaded(true);
  }, []);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  if (aspectRatioResponsiveness) {
    return (
      <div
        className={`custom-image-wrapper-main custom-image-wrapper p_relative ${className} overflow-${overflow} ${
          aspectRatioResponsiveness ? "aspectRatioResponsiveness" : ""
        }`}
        style={{ ...styleAsPerAspectRatio(), overflow: overflow }}
      >
        {loaded ? (
          <img
            src={src}
            alt={alt}
            className={`image p_absolute ${objectFitStyleClass} ${imagePosition}`}
          />
        ) : (
          <p className="font_xs font_blue fw_5 text-center loading-text p_absolute">
            Loading...
          </p>
        )}
      </div>
    );
  } else {
    return (
      <div
        className={`custom-image-wrapper-main custom-image-wrapper w_100 h_100 ${className} overflow-${overflow} ${
          aspectRatioResponsiveness ? "aspectRatioResponsiveness" : ""
        }`}
        style={{ ...style }}
        {...rest}
      >
        {/* Not a video thumbnail */}
        {!videoThumbnail && (
          <LazyLoadImage
            className={`d_block ${
              objectFit === "" ? dependentSize : "w_100 h_100"
            } ${imgClass} ${objectFitStyleClass}`}
            src={videoThumbnail ? thumbnail : src}
            alt={alt}
            style={{
              ...objectPositionStyle,
            }}
            placeholder={
              <img className="lazy-loader" src={ImageLoader} alt="" />
            }
          />
        )}

        {videoThumbnail && (
          <div className="custom-thumbnail-wrapper">
            <VideoThumbnail
              videoUrl={src}
              thumbnailHandler={(t) => setThumbnail(t)}
              height={0}
              width={0}
            />
            <div className="video-thumbnail-icon-wrapper">
              <div className="video-thumbnail">
                <img src={PlayFilledBlue} alt="Video" className="p_absolute" />
                {/* {videoThumbnailText && (
                <p className="font_blue fw_6 w_100 text-center p_absolute">
                  Click to play
                </p>
              )} */}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
};
/* Component ends */

export default Image;
