/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { keyStatusGenerate } from "../helper";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Table } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import moment from "moment";
import GLOBAL from "../../../../../setup/constants/global";
/* Import local pages and component ends */

/* Component starts */
const Keys = (props) => {
  /* Props destructuring starts */
  const { details, index } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const keyDetails = details?.status;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="keys-wrapper">
      <div className="keys-heading">
        <p className="font_l label">
          {" "}
          {index !== undefined ? `${index} ${"-"}` : ""} Keys
        </p>
        <p className="font_s time"></p>
      </div>

      {/* ID Verfication Table */}
      <div className="custom-table-wrapper">
        <Table className="custom-table font_s" borderless responsive>
          {/* Table Heading */}
          <thead>
            <tr>
              <th></th>
              <th>Status</th>
              {keyDetails === "reservor-rejected-key-collection" && (
                <th>Reason</th>
              )}
              <th>Date</th>
            </tr>
          </thead>
          {/* Table Body */}
          <tbody>
            <tr>
              {/* ID */}
              <td>Key Status</td>

              {/* Status */}
              <td>
                <p className={`${keyStatusGenerate(keyDetails).color}`}>
                  {keyStatusGenerate(keyDetails).text}
                </p>
              </td>

              {keyDetails === "reservor-rejected-key-collection" && (
                <td>{details?.reason}</td>
              )}

              {/* Date */}
              <td>
                {keyDetails === "reservor-key-collected"
                  ? moment(details?.updatedAt).format(GLOBAL.DATE_FORMAT)
                  : "-"}
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </div>
  );
};
/* Component ends */

export default Keys;
