import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../axios";
import { API_URL } from "../../config/devKeys";
import { setLoading } from "./unpersistedSlice";

const initialState = {
  property: null,
};

const getByIdSlice = createSlice({
  name: "getById",
  initialState: initialState,
  reducers: {},
  extraReducers: {},
});

export const getCurrentProperty = (state) => state.getById.property;

export default getByIdSlice.reducer;
