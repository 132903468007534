/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useDispatch, useSelector } from "react-redux";
import GLOBAL from "../../../../../setup/constants/global";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { newAction } from "../helper";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  cancelTenantInvitation,
  resendTenantInvitation,
} from "../../../../../setup/store/slices/tenantSlice";
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../../../setup/store/slices/globalAlertSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Table } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import {
  EyeFilledBlue,
  ProfileFilledGrey,
} from "../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../../../ui/button/IconButton/IconButton";
import Pagination from "../../../../ui/other/Pagination/Pagination";
import TextButton from "../../../../ui/button/TextButton/TextButton";
import ConfirmationModal from "../../../../ui/modal/ConfirmationModal/ConfirmationModal";
/* Import local pages and component ends */

/* Component starts */
const NewTenants = (props) => {
  /* Props destructuring starts */
  const { list, totalCount, currentPage, setCurrentPage, refreshNewTenant } =
    props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [action, setAction] = useState();
  const [selectedItem, setSelectedItem] = useState();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [modalText, setModalText] = useState({ title: "", description: "" });
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // Setting Application status and check if it can be reviewed
  const checkStatus = (item) => {
    let status = "";
    let rejected = false;
    let canReview = false;

    // switch (item?.status) {
    //   case "application":
    //     switch (item.sub_status) {
    //       case GLOBAL.TENANT_APPLICATION_STATUS.PENDING_CONFIRMATION:
    //         status = "Pending Confirmation";
    //         canReview = false;
    //         break;
    //       case GLOBAL.TENANT_APPLICATION_STATUS.PENDING_APPLICATION:
    //         status = "Pending Application";
    //         canReview = false;
    //         break;
    //       case GLOBAL.TENANT_APPLICATION_STATUS.PENDING_REVIEW:
    //         status = "Pending Review";
    //         canReview = true;
    //         break;
    //       case GLOBAL.TENANT_APPLICATION_STATUS.PENDING_ACTION:
    //         status = "Pending Tenant Action";
    //         canReview = false;
    //         break;
    //       case GLOBAL.TENANT_APPLICATION_STATUS.APPROVED:
    //         status = "Pending Action";
    //         canReview = false;
    //         break;
    //       case GLOBAL.TENANT_APPLICATION_STATUS.TENANT:
    //         status = "Occupied";
    //         canReview = false;
    //         break;
    //       case GLOBAL.TENANT_APPLICATION_STATUS.REJECTED:
    //         status = "Rejected";
    //         rejected = true;
    //         canReview = false;
    //         break;
    //     }
    //     break;

    //   case "initial_agreement":
    //     switch (item.sub_status) {
    //       case GLOBAL.TENANT_APPLICATION_STATUS.PENDING_AGREEMENT_TERMS:
    //         status = "Pending Initial Agreement Terms";
    //         canReview = true;
    //         break;
    //       case GLOBAL.TENANT_APPLICATION_STATUS.PENDING_AGREEMENT_REVIEW:
    //         status = "Pending Review";
    //         canReview = true;
    //         break;
    //       case GLOBAL.TENANT_APPLICATION_STATUS.PENDING_ACTION:
    //         status = "Pending Tenant Action";
    //         canReview = false;
    //         break;
    //     }
    //     break;

    //   case "tenantContract":
    //     switch (item.sub_status) {
    //       case GLOBAL.TENANT_APPLICATION_STATUS.PENDING_ACTION:
    //         status = "Pending Tenant Action";
    //         canReview = false;
    //         break;
    //       case GLOBAL.TENANT_APPLICATION_STATUS.PENDING_CONTRACT_REVIEW:
    //         status = "Pending Contract Review";
    //         canReview = true;
    //         break;
    //       case GLOBAL.TENANT_APPLICATION_STATUS.PENDING_CONTRACT_SIGNATURE:
    //         status = "Pending Contract Sign";
    //         canReview = false;
    //         break;
    //     }
    //     break;

    //   case "tenantKey":
    //     switch (item.sub_status) {
    //       case GLOBAL.TENANT_APPLICATION_STATUS.UNIT_MAINTENANCE:
    //         if (item?.contract?.contract_details?.move_in_date) {
    //           status = "Unit Under Maintenance";
    //         } else {
    //           status = "Pending Tenant Action";
    //         }
    //         canReview = true;
    //         break;
    //       case GLOBAL.TENANT_APPLICATION_STATUS.PENDING_KEY_COLLECTION:
    //         status = "Pending Tenant Key Collection";
    //         canReview = true;
    //         break;
    //     }
    //     break;
    // }

    switch (item.status) {
      case GLOBAL.TENANT_APPLICATION_STATUS.INVITATION_CANCELLED:
        status = "Invitaion Cancelled";
        canReview = false;
        rejected = true;
        break;

      case GLOBAL.TENANT_APPLICATION_STATUS.PENDING_CONFIRMATION:
        status = "Pending Confirmation";
        canReview = false;
        break;
      case GLOBAL.TENANT_APPLICATION_STATUS.PENDING_REVIEW:
        status = "Pending Review";
        canReview = true;
        break;
      case GLOBAL.TENANT_APPLICATION_STATUS.APPROVED:
        status = "Pending Action";
        canReview = true;
        break;
      case GLOBAL.TENANT_APPLICATION_STATUS.REJECTED:
        status = "Rejected";
        rejected = true;
        canReview = true;
        break;
      case GLOBAL.TENANT_APPLICATION_STATUS.PENDING_APPLICATION:
        status = "Pending Application";
        canReview = false;
        break;
      case GLOBAL.TENANT_APPLICATION_STATUS.PENDING_ACTION:
        status = "Pending Tenant Action";
        canReview = true;
        break;
      case GLOBAL.TENANT_APPLICATION_STATUS.TENANT:
        status = "Occupied";
        canReview = true;
        break;
      case GLOBAL.TENANT_APPLICATION_STATUS.PENDING_AGREEMENT_TERMS:
        status = "Pending Initial Agreement Terms";
        canReview = true;
        break;
      case GLOBAL.TENANT_APPLICATION_STATUS.PENDING_AGREEMENT_REVIEW:
        status = "Pending Review";
        canReview = true;
        break;
      case GLOBAL.TENANT_APPLICATION_STATUS.PENDING_CONTRACT_REVIEW:
        status = "Pending Contract Review";
        canReview = true;
        break;
      case GLOBAL.TENANT_APPLICATION_STATUS.PENDING_CONTRACT_SIGNATURE:
        status = "Pending Contract Sign";
        canReview = true;
        break;
      case GLOBAL.TENANT_APPLICATION_STATUS.UNIT_MAINTENANCE:
        if (item?.contract?.contract_details?.move_in_date) {
          status = "Unit Under Maintenance";
        } else {
          status = "Pending Tenant Action";
        }
        canReview = true;
        break;
      case GLOBAL.TENANT_APPLICATION_STATUS.PENDING_KEY_COLLECTION:
        status = "Pending Tenant Key Collection";
        canReview = true;
        break;

      case "reservor-data-rejected":
        status = "Application Data Rejected";
        canReview = true;
    }

    return {
      status,
      canReview,
      rejected,
    };
  };

  // Go to review tenant page
  const review = (canReview, application) => {
    // if (canReview) {
    navigate(
      // `${application._id}?application_status=${application.status}&unit_property_type=${application.unit.unit_type.property_type_id.name}&target_rent_price=${application.unit.target_rent_price}`,
      `${application._id}`
      // {
      //   state: {
      //     application_id: application._id,
      //     application_status: application.status,
      //     unit_property_type: application.unit.unit_type.property_type_id.name,
      //     target_rent_price: application.unit.target_rent_price,
      //   },
      // }
    );
    // } else {
    //   dispatch(
    //     showErrorAlert({
    //       title: "Error!",
    //       description: "Cannot be reviewed now!",
    //     })
    //   );
    // }
  };

  const onAction = async () => {
    setShowConfirmation(false);
    // Backend response. Try, Catch
    try {
      let result;
      if (action === "resend") {
        result = await dispatch(resendTenantInvitation(selectedItem));
      } else {
        result = await dispatch(cancelTenantInvitation(selectedItem));
      }
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description:
                typeof result?.payload === "string" ? result?.payload : "",
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description:
                typeof result?.payload?.message === "string"
                  ? result?.payload?.message
                  : "",
            })
          );
          refreshNewTenant();
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      <div className="custom-table-wrapper new-tenant-table">
        {list?.length > 0 && (
          <Table className="custom-table font_s" borderless responsive>
            {/* Table Heading */}
            <thead>
              <tr>
                <th>Tenant Name</th>
                <th>Unit Name</th>
                <th>Unit Type</th>
                <th>Application Date & Time</th>
                <th>Status</th>
                <th className="text-end">Action</th>
              </tr>
            </thead>

            {/* Table Body */}
            <tbody>
              {list?.map((item, index) => (
                <tr key={index}>
                  {/* Tenant Name */}
                  <td>{item?.tenant !== undefined ? item?.tenant : "-"}</td>

                  {/* Unit Name */}
                  <td>
                    {item?.unit_name !== undefined ? item?.unit_name : "-"}
                  </td>

                  {/* Unit Type */}
                  <td>
                    {item?.unit_type !== undefined ? item?.unit_type : "-"}
                  </td>

                  {/* Application Date & Time */}
                  <td>{moment(item?.date_time).format(DATE_TIME_FORMAT)}</td>

                  {/* Status */}
                  <td className="status">
                    <p
                      className={`${
                        checkStatus(item).rejected
                          ? "font_red"
                          : checkStatus(item).status === "Occupied"
                          ? "font_green"
                          : "font_yellow"
                      }`}
                    >
                      {checkStatus(item).status}
                    </p>
                  </td>

                  {/* Actions */}
                  <td className="text-end txt-nowrap">
                    {item?.status ===
                    GLOBAL.TENANT_APPLICATION_STATUS.PENDING_CONFIRMATION ? (
                      <>
                        {/* <div className="resend-cancel-btn">
                          <TextButton
                            onClick={() => {
                              setAction("resend");
                              setModalText({
                                title: "Confirm Resend",
                                description:
                                  "Are you sure you want to resend ?",
                              });
                              setSelectedItem(item.invitation_id);
                              setShowConfirmation(true);
                            }}
                            className="font_m fw_6"
                          >
                            Resend
                          </TextButton>
                          <TextButton
                            onClick={() => {
                              setAction("cancel");
                              setModalText({
                                title: "Confirm Cancel",
                                description:
                                  "Are you sure you want to cancel ?",
                              });
                              setSelectedItem(item.invitation_id);
                              setShowConfirmation(true);
                            }}
                            className="font_m fw_6"
                            variant="red"
                          >
                            Cancel
                          </TextButton>
                        </div> */}
                      </>
                    ) : (
                      <>
                        {/* <IconButton
                          onClick={() =>
                            review(checkStatus(item).canReview, item)
                          }
                          className="action-button"
                        >
                          <img
                            src={EyeFilledBlue}
                            className="h_100"
                            alt="View Details"
                          />
                        </IconButton> */}
                      </>
                    )}
                    <IconButton
                      onClick={() => review(checkStatus(item).canReview, item)}
                      className="action-button"
                    >
                      <img
                        src={EyeFilledBlue}
                        className="h_100"
                        alt="View Details"
                      />
                    </IconButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}

        {list?.length === 0 && (
          <div className=" text-center">
            <h3 className=" font_m font_yellow fw_6">No Data</h3>
          </div>
        )}

        <ConfirmationModal
          showModal={showConfirmation}
          description={modalText?.description}
          title={modalText.title}
          onClose={() => setShowConfirmation(false)}
          onConfirm={onAction}
        />
      </div>

      {/* Pagination */}
      {list?.length > 0 && (
        <Pagination
          itemsPerPage="10"
          totalItems={totalCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
    </>
  );
};
/* Component ends */

export default NewTenants;
