/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { Link } from "react-router-dom";

/* Import configuration ends */

/* Import redux slices component starts */

/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import Searchbar from "../../components/ui/other/Searchbar/Searchbar";
import AdvertismentsTable from "../../components/module/Advertising/AdvertismentsTable/AdvertismentsTable";
/* Import local pages and component ends */

// styles sheet
import "./style.scss";
import BreadCrumb from "../../components/ui/other/BreadCrumb/BreadCrumb";

/* Component starts */
const Advertising = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */

  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */

  /* Other hooks declaration ends */

  // Redux Selectors

  /* Component variable declaration / object destructure starts */

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  /* Component function definition ends */

  /* Component useEffect starts */

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */

  return (
    <>
      <BreadCrumb type="advertisment_page" />

      <div className="advertisment-wrapper">
        <div className="heading-wrapper">
          <div className="register-wrapper">
            <h2 className="font_xxl fw_5">Advertising</h2>
            <Link
              to="add"
              className="font_m fw_4 d_block link-security"
            >
              + Create Advertisments
            </Link>
          </div>
          {/* <div className="heading">
          
        </div> */}
        </div>

        <Searchbar />

        <AdvertismentsTable />
      </div>
    </>
  );
};
/* Component End */

export default Advertising;
