/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  fetchSearchedTeamsList,
  fetchTeamsList,
  fetchTeamsListBasedCommunity,
  fetchTeamsListBasedProperty,
  getCommunityTeamsList,
  getPropertyTeamsList,
  getTeamsList,
} from "../../setup/store/slices/teamsDashboardSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { EnvelopeOutLinedBlue, PrintIcon } from "../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import GLOBAL from "../../setup/constants/global";
import Header from "./local-component/Header";
import PieChartGraph from "./local-component/PieChartGraph";
import Searchbar from "../../components/ui/other/Searchbar/Searchbar";
import TeamsTable from "./local-component/TeamsTable";
import CustomDateInput from "../../components/ui/input/CustomDateInput/CustomDateInput";
// import CustomButton from "../../components/ui/button/CustomButton/CustomButton";
// import TextButton from "../../components/ui/button/TextButton/TextButton";
import StatusPieChartGraph from "./local-component/StatusPieChartGraph";
import Pagination from "../../components/module/RnD/Pagination/Pagination";
import Status3DPieChart from "../../components/ui/other/PieCharts/Status3DPieChart";
import Type3DPieChart from "../../components/ui/other/PieCharts/Type3DPieChart";
import { getUniqueValues } from "../../setup/utils/global-helper";
import EmailModal from "../../components/ui/modal/EmailModal/EmailModal";
/* Import local pages and component ends */

import "./style.scss";
import BreadCrumb from "../../components/ui/other/BreadCrumb/BreadCrumb";
import moment from "moment";

/* Component starts */
const TeamsDashboard = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const startDate = new Date();
  // const endDate = new Date();
  const [fromDate, setFromDate] = useState(
    new Date(startDate.getFullYear(), startDate.getMonth(), 1)
  );
  const [toDate, setToDate] = useState(
    // new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0)
    new Date()
  );

  const [search, setSearch] = useState("");
  const [teams, setTeams] = useState([]);
  const [filterTeams, setFilterTeams] = useState();
  const [typeData, setTypeData] = useState();
  const [statusData, setStatusData] = useState();
  const [cleared, setCleared] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [showModal, setShowModal] = useState(false);

  // const [isFilter, setIsFilter] = useState(false);

  // const [multiSelectData, setMultiSelectData] = useState({
  //   // a: false,
  //   // b: false,
  //   // c: false,
  //   woodland: false,
  //   oasis: false,
  //   palm: false,
  // });

  // FOR FILTERS

  const [communityData, setCommunityData] = useState();
  const [multiSelectCommunity, setMultiSelectCommunity] = useState();
  const [propertyData, setPropertyData] = useState();
  const [multiSelectProperty, setMultiSelectProperty] = useState();

  const [nameData, setNameData] = useState();
  const [multiSelectName, setMultiSelectName] = useState();

  const [roleData, setRoleData] = useState();
  const [multiSelectRole, setMultiSelectRole] = useState();

  const [statusData1, setStatusData1] = useState();
  const [multiSelectStatus, setMultiSelectStatus] = useState();
  // const [anyOneSelected, setAnyOneSelected] = useState(false);

  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const { pathname } = useLocation();
  const param = useParams();
  const dispatch = useDispatch();
  const teamsList = useSelector(getTeamsList);
  const propertyTeamsList = useSelector(getPropertyTeamsList);
  const communityTeamsList = useSelector(getCommunityTeamsList);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItemsList = filterTeams?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  const loadTeamsList = async () => {
    if (pathname.includes("communities")) {
      await dispatch(
        fetchTeamsListBasedCommunity({
          community_id: param.communityId,
        })
      );
    } else if (pathname.includes("independent")) {
      await dispatch(
        fetchTeamsListBasedProperty({ property_id: param.propertyId })
      );
    } else {
      await dispatch(fetchTeamsList());
    }
  };

  const filterTypeOfTeams = (occupancy) => {
    const arrayUniqueByKey = [
      ...new Map(
        occupancy.map((item) => [item["property_name"], item])
      ).values(),
    ];

    let obj = [];
    obj.push(["Property Team Size", "No Of Size"]);
    arrayUniqueByKey.forEach((item) => {
      if (item?._id) {
        let cont = occupancy?.filter(
          (d) =>
            d._id === item._id &&
            d.status !== "suspended" &&
            d.status !== "deactivated"
        );
        obj.push([item?.property_name, cont.length]);
      }
    });
    setTypeData(obj);
  };

  const filterStatusOfTeams = (occupancy) => {
    let owner = occupancy?.filter((d) => d.role === "Owner");
    let propertyManager = occupancy?.filter(
      (d) => d.role === "Property Manager"
    );
    let pMC = occupancy?.filter(
      (d) => d.role === "Property Management Company"
    );
    let accountant = occupancy?.filter((d) => d.role === "Accountant");
    let fMC = occupancy?.filter(
      (d) => d.role === "Facility Management Company"
    );
    let fM = occupancy?.filter((d) => d.role === "Facility Manager");
    let mT = occupancy?.filter((d) => d.role === "Maintenance Technician");
    let sMC = occupancy?.filter(
      (d) => d.role === "Security Management Company"
    );
    let sM = occupancy?.filter((d) => d.role === "Security Manager");
    let sO = occupancy?.filter((d) => d.role === "Security Officer");
    let thirdPC = occupancy?.filter((d) => d.role === "Third-Party Company");
    let thirdPM = occupancy?.filter((d) => d.role === "Third-Party Manager");
    let thirdPT = occupancy?.filter((d) => d.role === "Third Party Technician");

    const obj = [];
    obj.push(["Role Type", "No. Of Role?"]);
    if (owner?.length > 0) {
      obj.push(["Owner", owner?.length]);
    }
    if (propertyManager?.length > 0) {
      obj.push(["Property Manager", propertyManager?.length]);
    }
    if (pMC?.length > 0) {
      obj.push(["Property Management Company", pMC?.length]);
    }

    if (accountant?.length > 0) {
      obj.push(["Accountant", accountant?.length]);
    }
    if (fMC?.length > 0) {
      obj.push(["Facility Management Company", fMC?.length]);
    }
    if (fM?.length > 0) {
      obj.push(["Facility Manager", fM?.length]);
    }

    if (mT?.length > 0) {
      obj.push(["Maintenance Technician", mT?.length]);
    }
    if (sMC?.length > 0) {
      obj.push(["Security Management Company", sMC?.length]);
    }
    if (sM?.length > 0) {
      obj.push(["Security Manager", sM?.length]);
    }

    if (sO?.length > 0) {
      obj.push(["Security Officer", sO?.length]);
    }

    if (thirdPC?.length > 0) {
      obj.push(["Third-Party Company", mT?.length]);
    }
    if (thirdPM?.length > 0) {
      obj.push(["Third-Party Manager", thirdPM?.length]);
    }
    if (thirdPT?.length > 0) {
      obj.push(["Third Party Technician", thirdPT?.length]);
    }

    setStatusData(obj);
  };

  const handleSearch = async (isReset = false, resetValue = {}) => {
    let payload = {};
    if (!isReset) {
      if (search && fromDate && toDate) {
        payload = {
          searchKey: search,
          start_date: moment(fromDate)?.format("MM-DD-YYYY"),
          end_date: moment(toDate)?.format("MM-DD-YYYY"),
        };
        // Backend response. Try, Catch
        try {
          let result;
          if (pathname.includes("communities")) {
            payload = {
              ...payload,
              community_id: param.communityId,
            };
            result = await dispatch(fetchSearchedTeamsList(payload));
          } else if (pathname.includes("independent")) {
            payload = {
              ...payload,
              property_id: param.propertyId,
            };
            result = await dispatch(fetchSearchedTeamsList(payload));
          } else {
            result = await dispatch(fetchSearchedTeamsList(payload));
          }
          // Handling success response
          switch (result.meta.requestStatus) {
            case GLOBAL.REJECTED:
              // setModalDesc(result.payload);
              // setShowErrorModal(true);
              break;

            case GLOBAL.FULFILLED:
              // setModalDesc(result.payload.message);
              // setShowSuccessModal(true);
              setTeams(result.payload);
              setFilterTeams(result?.payload);
              break;
          }
        } catch (error) {
          // Handling error response
          console.error(error.message);
        }
      } else if (search) {
        payload = {
          searchKey: search,
        };

        await dispatch(fetchSearchedTeamsList(payload));
      } else if (!search && fromDate && toDate) {
        payload = {
          searchKey: "",
          start_date: moment(fromDate)?.format("MM-DD-YYYY"),
          end_date: moment(toDate)?.format("MM-DD-YYYY"),
        };
        // Backend response. Try, Catch
        try {
          let result;
          if (pathname.includes("communities")) {
            payload = {
              ...payload,
              community_id: param.communityId,
            };
            result = await dispatch(fetchSearchedTeamsList(payload));
          } else if (pathname.includes("independent")) {
            payload = {
              ...payload,
              property_id: param.propertyId,
            };
            result = await dispatch(fetchSearchedTeamsList(payload));
          } else {
            result = await dispatch(fetchSearchedTeamsList(payload));
          }
          // Handling success response
          switch (result.meta.requestStatus) {
            case GLOBAL.REJECTED:
              // setModalDesc(result.payload);
              // setShowErrorModal(true);
              break;

            case GLOBAL.FULFILLED:
              // setModalDesc(result.payload.message);
              // setShowSuccessModal(true);
              setTeams(result.payload);
              setFilterTeams(result?.payload);

              break;
          }
        } catch (error) {
          // Handling error response
          console.error(error.message);
        }
      }
    } else {
      payload = {
        ...resetValue,
      };
      setFilterTeams(teams);
    }
  };

  const handleClear = () => {
    let payload = {
      searchKey: "",
      start_date: new Date(startDate.getFullYear(), startDate.getMonth(), 1),
      end_date: new Date(),
    };
    setSearch(payload.searchKey);
    setFromDate(payload.start_date);
    setToDate(payload.end_date);

    handleSearch(true, payload);
    setCleared(!cleared);
    setFilterTeams(teams);
  };

  // To check the any checkbox is true or not
  const checkAnyFilterChecked = (filter) => {
    return Object?.keys(filter)?.every(function (k) {
      return !filter[k];
    });
  };

  // SEND EMAIL FUNCTION
  const handleSendEmail = () => {
    setShowModal(!showModal);
  };

  /* Component function definition ends */

  /* Component useEffect starts */

  useEffect(() => {
    loadTeamsList();
  }, [pathname, !cleared]);

  useEffect(() => {
    if (pathname.includes("communities") && communityTeamsList) {
      setTeams(communityTeamsList);
      setFilterTeams([...communityTeamsList]);
      filterTypeOfTeams(teams);
      filterStatusOfTeams(teams);
    } else if (pathname.includes("independent") && propertyTeamsList) {
      setTeams(propertyTeamsList);
      setFilterTeams([...propertyTeamsList]);
      filterTypeOfTeams(teams);
      filterStatusOfTeams(teams);
    } else {
      setTeams(teamsList);
      setFilterTeams([...teamsList]);
      filterTypeOfTeams(teams);
      filterStatusOfTeams(teams);
    }
  }, [pathname, teamsList, communityTeamsList, propertyTeamsList, !cleared]);

  useEffect(() => {
    if (pathname.includes("communities") && communityTeamsList) {
      filterTypeOfTeams(teams);
      filterStatusOfTeams(teams);
    } else if (pathname.includes("independent") && propertyTeamsList) {
      filterTypeOfTeams(teams);
      filterStatusOfTeams(teams);
    } else {
      filterTypeOfTeams(teams);
      filterStatusOfTeams(teams);
    }
  }, [!search, teams]);

  useEffect(() => {
    const community = getUniqueValues(teams, "community_name");
    const property = getUniqueValues(teams, "property_name");
    const status = getUniqueValues(teams, "status");

    const name = getUniqueValues(teams, "name");
    const role = getUniqueValues(teams, "role");

    const communityObject = {};
    community.forEach((prop) => (communityObject[prop] = false));
    setMultiSelectCommunity(communityObject);
    setCommunityData(community);

    const propertyObject = {};
    property.forEach((prop) => (propertyObject[prop] = false));
    setMultiSelectProperty(propertyObject);
    setPropertyData(property);

    const nameObject = {};
    name.forEach((prop) => (nameObject[prop] = false));
    setMultiSelectName(nameObject);
    setNameData(name);

    const roleObject = {};
    role.forEach((prop) => (roleObject[prop] = false));
    setMultiSelectRole(roleObject);
    setRoleData(role);

    const statusObject = {};
    status.forEach((prop) => (statusObject[prop] = false));
    setMultiSelectStatus(statusObject);
    setStatusData1(status);
  }, [teams]);

  useEffect(() => {
    if (
      multiSelectCommunity !== undefined ||
      multiSelectProperty !== undefined ||
      multiSelectName !== undefined ||
      multiSelectRole !== undefined ||
      multiSelectStatus !== undefined
    ) {
      let allUnCheckedCommunity = checkAnyFilterChecked(multiSelectCommunity);
      let allUnCheckedProperty = checkAnyFilterChecked(multiSelectProperty);

      let allUnCheckedName = checkAnyFilterChecked(multiSelectName);
      let allUnCheckedRole = checkAnyFilterChecked(multiSelectRole);

      let allUnCheckedStatus = checkAnyFilterChecked(multiSelectStatus);

      if (!allUnCheckedCommunity && multiSelectCommunity) {
        let selectedValue = Object.keys(multiSelectCommunity)?.filter(
          (key) => multiSelectCommunity[key]
        );

        const filteredCommunity = teams?.filter((d) =>
          selectedValue?.includes(d.community_name)
        );

        setFilterTeams(filteredCommunity);
      } else if (!allUnCheckedProperty && multiSelectProperty) {
        let selectedValue = Object.keys(multiSelectProperty)?.filter(
          (key) => multiSelectProperty[key]
        );

        const filteredProperty = teams?.filter((d) =>
          selectedValue?.includes(d.property_name)
        );

        setFilterTeams(filteredProperty);
      } else if (!allUnCheckedStatus && multiSelectStatus) {
        let selectedValue = Object.keys(multiSelectStatus)?.filter(
          (key) => multiSelectStatus[key]
        );

        const filteredStatus = teams?.filter((d) =>
          selectedValue?.includes(d.status)
        );

        setFilterTeams(filteredStatus);
      } else if (!allUnCheckedName && multiSelectName) {
        let selectedValue = Object.keys(multiSelectName)?.filter(
          (key) => multiSelectName[key]
        );

        const filteredName = teams?.filter((d) =>
          selectedValue?.includes(d.name)
        );

        console.log(filteredName, "[{}]");
        setFilterTeams(filteredName);
      } else if (!allUnCheckedRole && multiSelectRole) {
        let selectedValue = Object.keys(multiSelectRole)?.filter(
          (key) => multiSelectRole[key]
        );

        const filteredRole = teams?.filter((d) =>
          selectedValue?.includes(d.role)
        );

        setFilterTeams(filteredRole);
      }
    } else {
      setFilterTeams(teams);
    }
  }, [
    multiSelectCommunity,
    multiSelectProperty,
    multiSelectStatus,
    multiSelectName,
    multiSelectRole,
  ]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      <BreadCrumb type="dashboard_teams" />
      <div className="teams-dashboard-wrapper">
        <div className="action-wrapper">
          <div>
            <img src={PrintIcon} alt="Print-Icon" />
            <span className="font_m fw_3 font_blue">Print</span>
          </div>

          <div onClick={handleSendEmail}>
            <img src={EnvelopeOutLinedBlue} alt="Print-Icon" />
            <span className="font_m fw_3 font_blue">Email</span>
          </div>
        </div>
        <div className="header">
          <Header />
        </div>

        {teams?.length > 0 && (
          <div className="report-chart-wrapper">
            <div className="detail-box ">
              <div>
                <p className="font_l fw_4">Property Team Size</p>
              </div>
              {/* <div>
              <PieChartGraph />
            </div> */}
              <Type3DPieChart data={typeData} />
            </div>

            <div className="detail-box ">
              <div>
                <p className="font_l fw_4">Roles</p>
              </div>
              {/* <div>
              <StatusPieChartGraph statusData={statusData} />
            </div> */}
              <Status3DPieChart data={statusData} />
            </div>
          </div>
        )}

        <div className="search-box mt-4 mb-4">
          <div className="search">
            <Searchbar
              setQuery={setSearch}
              query={search}
              className="dash-search"
            />
          </div>

          <div className="date">
            <div>
              <CustomDateInput
                label="From"
                value={fromDate}
                minDate={false}
                customOnchange={(date) => setFromDate(date)}
                required={true}
              />
            </div>

            {/* </div> */}

            {/* <div className="date"> */}
            <div>
              <CustomDateInput
                label="To"
                value={toDate}
                minDate={false}
                customOnchange={(date) => setToDate(date)}
                required={true}
              />
            </div>
          </div>
          <div className="btn-wrapper">
            <button
              onClick={() => handleSearch()}
              // disabled={!search}
            >
              Apply
            </button>
            <button className="btn-clear" onClick={handleClear}>
              Clear
            </button>
          </div>
        </div>

        <div className="teams-table-wrapper">
          <TeamsTable
            teamsListData={currentItemsList}
            communityData={communityData}
            multiSelectCommunity={multiSelectCommunity}
            setMultiSelectCommunity={setMultiSelectCommunity}
            propertyData={propertyData}
            multiSelectProperty={multiSelectProperty}
            setMultiSelectProperty={setMultiSelectProperty}
            statusData1={statusData1}
            multiSelectStatus={multiSelectStatus}
            setMultiSelectStatus={setMultiSelectStatus}
            name={nameData}
            multiSelectName={multiSelectName}
            setMultiSelectName={setMultiSelectName}
            role={roleData}
            multiSelectRole={multiSelectRole}
            setMultiSelectRole={setMultiSelectRole}
          />

          {filterTeams?.length > 0 && (
            <Pagination
              itemsPerPage={itemsPerPage}
              totalItems={filterTeams?.length}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          )}
        </div>

        {/* EMAIL MODAL */}
        <EmailModal
          showModal={showModal}
          onSuccess={() => setShowModal(!showModal)}
          onClose={() => setShowModal(!showModal)}
        />
      </div>
    </>
  );
};
/* Component ends */

export default TeamsDashboard;
