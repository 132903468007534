/**
 *
 */

import React, { useState } from "react";

/* Import configuration starts */
import moment from "moment";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import GLOBAL from "../../../setup/constants/global";
import FileViewerModal from "../../../components/ui/modal/FileViewerModal/FileViewerModal";
/* Import local pages and component ends */

/* Component starts */
const Report = (props) => {
  /* Props destructuring starts */
  const { incident } = props;
  console.log(incident, "[]=[]=[]");
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */

  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const [showFileViewer, setShowFileViewer] = useState(false);
  const [file, setFile] = useState({});

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="facility-report-detail">
      <div className="facility-main-wrapper">
        <div className="facility-data">
          <p className="font_s fw_4">Facility Name : Parking</p>
          <p className="font_xxs font_grey fw_4">
            There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered alteration in some form, by injected
            humour, or randomised words which don't look even slightly
            believable.
          </p>
          <p className="font_xxs fw_4">Requested Date : 16/08/2023 11:45AM </p>
        </div>
        <div className="facility-img">
          <img
            src="https://da28rauy2a860.cloudfront.net/outdoordesign.com.au/contents/6654/20181212162025_785.jpg"
            alt="Facility-Picture"
          />
        </div>
      </div>

      <hr />

      <div className="tenant-data">
        <p className="font_s font_blue fw_4">
          Tenant : {incident?.item?.tenant}
        </p>
        <p className="font_s fw_4">Unit : {incident?.item?.unit} </p>
      </div>

      <hr />

      <div className="date-wrapper">
        <p className="font_xs font_grey fw_4">From Date</p>
        <p className="font_xs fw_4">01/01/2023 10:30 AM</p>
      </div>

      <div className="date-wrapper mt-2">
        <p className="font_xs font_grey fw_4">End Date</p>
        <p className="font_xs fw_4">01/01/2024 10:30 AM</p>
      </div>

      <div className="vehicle-wrapper">
        <p className="font_s font_grey fw_4">Vehicle Details</p>
        <div className="vehicle-data">
          <div className="data">
            <p className="font_s font_grey fw_4">
              Vehcile Name <br />
              <span className="font_s  fw_4">BMW</span>
            </p>
          </div>

          <div className="data">
            <p className="font_s font_grey fw_4">
              Vehcile Number <br />
              <span className="font_s  fw_4">USA-007</span>
            </p>
          </div>

          <div className="data">
            <p className="font_s font_grey fw_4">
              Vehcile Type <br />
              <span className="font_s  fw_4">350 d</span>
            </p>
          </div>

          <div className="data">
            <p className="font_s font_grey fw_4">
              Make <br />
              <span className="font_s  fw_4">BMW</span>
            </p>
          </div>

          <div className="data">
            <p className="font_s font_grey fw_4">
              Model <br />
              <span className="font_s  fw_4">350d</span>
            </p>
          </div>

          <div className="data">
            <p className="font_s font_grey fw_4">
              Color <br />
              <span className="font_s  fw_4">Red</span>
            </p>
          </div>
        </div>
      </div>

      <div className="date-wrapper mt-2">
        <p className="font_xs font_grey fw_4">Available Parking Spot</p>
        <p className="font_xs fw_4">Ground Floor - 101</p>
      </div>
    </div>
  );
};
/* Component Ends */
export default Report;
