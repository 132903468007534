/**
 *
 */

import React from "react";

/* Import configuration starts */
import { useLocation, useNavigate } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
/* Import local pages and component ends */

import "./style.scss";
import CustomButton from "../../components/ui/button/CustomButton/CustomButton";

/* Component starts */
const TermAndConditionPage = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  const { state } = useLocation();
  console.log(state, "111");
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */

  return (
    <div className="terms-page-wrapper container_sm">
      <h2 className="font_l fw_6">TERMS AND CONDITION PAGE</h2>

      <div className="detail-card document-wrapper mt-3">
        {state !== undefined && (
          <div
            className="description"
            dangerouslySetInnerHTML={{ __html: state }}
          />
        )}
      </div>

      <CustomButton
        type="submit"
        size="l"
        className="mt-4"
        onClick={() => navigate(-1)}
      >
        Back
      </CustomButton>
    </div>
  );
};
/* Component Ends */
export default TermAndConditionPage;
