import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../axios";
import { API_URL } from "../../config/devKeys";
import { setLoading } from "./unpersistedSlice";
import { fetchPropertySettings } from "./propertySlice";

const initialState = {
  contacts: [],
};

export const fetchEmergencyContacts = createAsyncThunk(
  "emergency_contacts/list",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { property_id } = payload;
      const { data } = await axios.post(
        API_URL + "/emergency-contact/list",
        { property_id },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        rejectWithValue(data.error);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const addEmergencyContact = createAsyncThunk(
  "emergency_contacts/add",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/emergency-contact/add",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );

      dispatch(setLoading(false));
      if (data.success) {
        // await dispatch(fetchEmergencyContacts());
        await dispatch(fetchPropertySettings(payload));
        return data.data;
      } else {
        dispatch(setLoading(false));
        rejectWithValue(data.error);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const updateEmergencyContact = createAsyncThunk(
  "emergency_contacts/update",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/emergency-contact/update?id=" + payload._id,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        await dispatch(fetchEmergencyContacts());
        return data.data;
      } else {
        dispatch(setLoading(false));
        rejectWithValue(data.error);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const deleteEmergencyContact = createAsyncThunk(
  "emergency_contacts/delete",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.delete(
        API_URL + "/emergency-contact/delete?id=" + payload._id,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        // await dispatch(fetchPropertySettings(payload));
        await dispatch(fetchEmergencyContacts());
        return data.data;
      } else {
        dispatch(setLoading(false));
        rejectWithValue(data.error);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const emergencyContactSlice = createSlice({
  name: "emergency",
  initialState,
  extraReducers: {
    [fetchEmergencyContacts.fulfilled]: (state, action) => {
      state.contacts = action.payload;
    },
  },
});

export const getContacts = (state) => state.emergency.contacts;

export default emergencyContactSlice.reducer;
