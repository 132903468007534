/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import moment from "moment";
import { getContractType, navigateToInvoiceByType } from "../helper";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  getIsACC,
  getIsOwner,
} from "../../../../../setup/store/slices/authSlice";
import { fetchTenantApplicationByIdNew } from "../../../../../setup/store/slices/tenantSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Table } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import {
  EditGreen,
  ExcalmatoryYellow,
  EyeFilledBlue,
} from "../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../../../ui/button/IconButton/IconButton";
import PaymentModal from "../../../Invoicing/ClientInvoicing/PaymentModal/PaymentModal";
/* Import local pages and component ends */

/* Component starts */
const ContractDetail = (props) => {
  /* Props destructuring starts */
  const { invoices, index, settings, property, getApplication } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [invoiceData, setInvoiceData] = useState();
  const [showModal, setShowModal] = useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  const isAccountant = useSelector(getIsACC);
  const isOwner = useSelector(getIsOwner);
  const { applicationId } = useParams();
  const dispatch = useDispatch();
  // const history = useHistory();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const handlePaymentModal = (item) => {
    setInvoiceData(item);
    setShowModal(!showModal);
  };

  const onClose = () => {
    setShowModal(!showModal);
  };

  const onSuccess = () => {
    setShowModal(!showModal);
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="contract-wrapper">
      <div className="contract-heading">
        <p className="font_l label">{index && `${index} -`} Contract Details</p>
      </div>

      {/* ID Verfication Table */}
      <div className="custom-table-wrapper">
        {invoices?.length > 0 && (
          <Table className="custom-table font_s" borderless responsive>
            {/* Table Heading */}
            <thead>
              <tr>
                <th>Invoice No.</th>
                <th>Type</th>
                <th>Amount</th>
                <th>Due Date</th>
                <th>Status</th>
                <th>View</th>
                <th>Action Date</th>
              </tr>
            </thead>
            {/* Table Body */}
            <tbody>
              {invoices?.map((item, index) => (
                <tr key={index}>
                  {/* Contract ID */}
                  <td>{item?.invoice_no}</td>

                  {/* Type */}
                  <td className="text-start txt-nowrap ">
                    {getContractType(item?.type)}
                  </td>

                  {/* Amount */}
                  <td>
                    {item?.total_amount?.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}{" "}
                    {item?.currency}
                  </td>

                  {/* Due Date */}
                  <td>{moment(item?.due_date).format(DATE_FORMAT)}</td>

                  {/* Status */}
                  {/* <td className="text-left txt-nowrap action">
                    {item?.status === "paid" ? (
                      <p className="font_s font_blue fw_6">Paid</p>
                    ) : (
                      <p className="font_s font_yellow fw_6">Unpaid</p>
                    )}
                  </td> */}
                  <td
                    className={`${
                      item?.status === "paid" || item?.status === "collected"
                        ? "font_green"
                        : item?.status === "adjusted" ||
                          item?.status === "settled"
                        ? "font_yellow"
                        : "font_red"
                    }`}
                  >
                    {item?.status === "paid" && "Paid"}
                    {item?.status === "collected" && "Collected"}
                    {item?.status === "not_collected" && "Not Collected"}
                    {item?.status === "partially_collected" &&
                      "Partially Collected"}
                    {item?.status === "partial paid" && "Partial Paid"}
                    {item?.status === "unpaid" && "Unpaid"}
                    {item?.status === "void" && "Void"}
                    {item?.status === "adjusted" && "Adjusted"}
                    {item?.status === "settled" && "Settled"}
                  </td>
                  <td>
                    <IconButton
                      onClick={() =>
                        navigateToInvoiceByType(navigate, item?.type, item)
                      }
                    >
                      <img src={EyeFilledBlue} alt="View" className="icon-m" />
                    </IconButton>

                    {((isOwner && property?.mgmt_model === 0) ||
                      (isAccountant && property?.mgmt_model === 2)) &&
                      (item?.status === "unpaid" ||
                        item?.status === "partial paid" ||
                        item?.status === "not_collected" ||
                        item?.status === "partially_collected") &&
                      !item?.is_payable_by_cheque && (
                        <IconButton
                          onClick={() => handlePaymentModal(item)}
                          className="action-button icon-m"
                        >
                          <img
                            // src={EditWhite}
                            src={EditGreen}
                            className="h_100 mx-2"
                            alt="View Details"
                          />
                        </IconButton>
                      )}

                    {((isOwner && property?.mgmt_model === 0) ||
                      (isAccountant && property?.mgmt_model === 2)) &&
                      item?.is_payable_by_cheque &&
                      (item?.status !== "paid" ||
                        item?.status === "bounced") && (
                        <IconButton className="action-button mx-2 icon-m">
                          <img src={ExcalmatoryYellow} className="h_100" />
                        </IconButton>
                      )}
                  </td>
                  <td>{moment(item?.updatedAt).format(DATE_TIME_FORMAT)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}

        {invoices?.length === 0 && (
          <div className=" text-center">
            <h3 className=" font_m font_yellow fw_6">No Data</h3>
          </div>
        )}
      </div>

      {/* MODALS */}
      <PaymentModal
        showModal={showModal}
        onClose={onClose}
        onSuccess={onSuccess}
        invoiceData={invoiceData}
        property_id={property?._id}
        afterPayment={() => {
          getApplication();
        }}
      />
    </div>
  );
};
/* Component ends */

export default ContractDetail;
