/**
 *
 */

import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
/* Import configuration starts */
import { Formik } from "formik";
import { initialValues, loadInitialValue, validations } from "./helper";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomTextInput from "../../../ui/input/CustomTextInput/CustomTextInput";
import CustomFileInput from "../../../ui/input/CustomFileInput/CustomFileInput";
import CustomDateInput from "../../../ui/input/CustomDateInput/CustomDateInput";
import CustomSelectInput from "../../../ui/input/CustomSelectInput/CustomSelectInput";
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
import TextButton from "../../../ui/button/TextButton/TextButton";
import SuccessModal from "../../../ui/modal/SuccessModal/SuccessModal";
import ErrorModal from "../../../ui/modal/ErrorModal/ErrorModal";
/* Import local pages and component ends */

// Styles
import "./style.scss";
import {
  editVisitorsPass,
  getContractorAccessType,
  getGuestAccessType,
} from "../../../../setup/store/slices/guestPass";
import { useParams } from "react-router-dom";
import {
  fetchAccessPassBasedOnId,
  getVisitorPassData,
} from "../../../../setup/store/slices/visitorPass";
import GLOBAL from "../../../../setup/constants/global";

/* Component starts */

const EditGuestPass = (props) => {
  /* Props destructuring starts */
  const { onSuccess, onCancel } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [initialFormValues, setInitialFormValues] = useState(initialValues);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [selectedAccessType, setSelectedAccessType] = useState();
  const [type, setType] = useState();
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [modalDesc, setModalDesc] = useState("");
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const photo = useRef(null);
  const { passId } = useParams();

  const dispatch = useDispatch();
  const contractorPass = useSelector(getContractorAccessType);
  const guestPass = useSelector(getGuestAccessType);
  const passData = useSelector(getVisitorPassData);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  // Form Validations
  const formValidation = (values) => {
    return validations(values);
  };

  const onSubmit = async (values) => {
    // Payload To Sent For Backend
    const payload = {
      id: passId,
      visitor_firstname: values.first_name,
      visitor_lastname: values.last_name,
      mobile_number: values.mobile_number,
      car_number: values.license_plate_number,
      start_date: startDate,
      end_date: endDate,
      reason: selectedAccessType?.name || "",
    };

    // payload["image"] = photo.current;
    if (values.image && values.image instanceof File) {
      payload[`image`] = values.image;
    }

    // Backend response. Try, Catch
    try {
      const result = await dispatch(editVisitorsPass(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setModalDesc(result.payload);
          setShowErrorModal(true);
          break;

        case GLOBAL.FULFILLED:
          setModalDesc(result.payload.message);
          setShowSuccessModal(true);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    dispatch(fetchAccessPassBasedOnId(passId));
  }, [dispatch]);

  // useEffect(() => {
  //   if (passData) {
  //     setInitialFormValues(loadInitialValue(passData));
  //   }
  // }, [passData]);

  // On load of basic details previous data
  useEffect(() => {
    if (passData) {
      loadInitialValue(
        passData,
        setInitialFormValues,
        setStartDate,
        setEndDate
      );
      setType(passData.type);
    }
  }, [passData]);

  // Setting selected Access Type after loading Access Type
  useEffect(() => {
    if (type === "Guest") {
      if (guestPass.length > 0 && passData) {
        let defaultGuestPass = guestPass.filter(
          (state) => state.name === passData?.guest_type
        )[0];

        setSelectedAccessType(defaultGuestPass || guestPass[0]);
      }
    } else {
      if (contractorPass.length > 0 && passData) {
        let defaultGuestPass = contractorPass.filter(
          (state) => state.name === passData?.guest_type
        )[0];

        setSelectedAccessType(defaultGuestPass || contractorPass[0]);
      }
    }
  }, [guestPass, passData, contractorPass]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */

  return (
    <div className="edit-guestPass-module-wrapper">
      <h2 className="heading text-center font_xxl mb-5">Edit Guest Pass</h2>

      <div className="edit-form">
        <Formik
          onSubmit={onSubmit}
          initialValues={initialFormValues}
          validate={formValidation}
          validateOnBlur={true}
          enableReinitialize={true}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="form-wrapper">
                {/* Creating Guest Pass */}
                <>
                  {/* Input for First Name */}
                  <div className="form-group">
                    <CustomTextInput
                      name="first_name"
                      label="Enter First Name"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched.first_name}
                      errors={errors.first_name}
                      value={values.first_name}
                    />
                  </div>

                  {/* Input for Last Name */}
                  <div className="form-group">
                    <CustomTextInput
                      name="last_name"
                      label="Enter Last Name"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched.last_name}
                      errors={errors.last_name}
                      value={values.last_name}
                    />
                  </div>

                  {/* Input for Mobile Number */}
                  <div className="form-group">
                    <CustomTextInput
                      name="mobile_number"
                      label="Enter Mobile Number"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched.mobile_number}
                      errors={errors.mobile_number}
                      value={values.mobile_number}
                    />
                  </div>
                  {/* Select Reason For Access */}
                  <div className="form-group">
                    <CustomSelectInput
                      name="reason"
                      label="Select Reason For Access"
                      handleBlur={handleBlur}
                      onChange={handleChange}
                      valueExtractor={(item) => item?.name}
                      setValue={setSelectedAccessType}
                      options={
                        type === "Contractor" ? contractorPass : guestPass
                      }
                      value={selectedAccessType}
                      formikState={false}
                      emptyOption={false}
                    />
                  </div>

                  {/* Input for License Plate Number */}
                  <div className="form-group">
                    <CustomTextInput
                      name="license_plate_number"
                      label="Enter License Plate Number"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched.license_plate_number}
                      errors={errors.license_plate_number}
                      value={values.license_plate_number}
                    />
                  </div>

                  {/* Input for Photo */}
                  <div className="form-group">
                    <CustomFileInput
                      label="Upload Photo"
                      name="photo"
                      files={photo}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      errors={errors.photo}
                      touched={touched.photo}
                      values={values.photo}
                      required={false}
                      formikState={false}
                    />
                  </div>

                  {/* Input for Start Date */}
                  <div className="form-group">
                    <CustomDateInput
                      label="Select Start Date"
                      value={startDate}
                      minDate={false}
                      customOnchange={(date) => setStartDate(date)}
                    />
                  </div>

                  {/* Input for End Date */}
                  <div className="form-group">
                    <CustomDateInput
                      label="Select End Date"
                      value={endDate}
                      minDate={false}
                      customOnchange={(date) => setEndDate(date)}
                    />
                  </div>
                </>
              </div>

              <div className="button-wrapper text-center">
                <>
                  <CustomButton type="submit" size="l">
                    Update
                  </CustomButton>
                  <TextButton
                    type="button"
                    onClick={onCancel}
                    className="font_m fw_6"
                  >
                    Cancel
                  </TextButton>
                </>
              </div>
            </form>
          )}
        </Formik>
      </div>

      {/* Success Modal */}
      <SuccessModal
        showModal={showSuccessModal}
        description={modalDesc}
        title="Successfully Updated"
        onClose={() => {
          onSuccess();
          setShowSuccessModal(false);
        }}
      />

      {/* Error Modal */}
      <ErrorModal
        showModal={showErrorModal}
        description={modalDesc}
        title="Error"
        onClose={() => setShowErrorModal(false)}
      />
    </div>
  );
};
/* Component ends */

export default EditGuestPass;
