import GLOBAL from "../../../../setup/constants/global";

export const logTitle = (item) => {
  let title = "";
  let role = item?.requester_role?.name;
  let currentStatus = item?.current_status;
  let logType = item?.log_type;

  //   Generating title according to action string
  switch (item?.action) {
    // Ticket Assigned
    case "Maintenance ticket assigned":
      title = "Maintenance ticket assigned";
      break;

    // Default Case...
    default:
      title = item?.action;
  }

  return title;
};

export const statusColorGenerate = (status) => {
  switch (status) {
    case "New":
      return "font_blue";

    case "Closed":
      return "font_red";

    case "Rejected":
      return "font_red";

    case "Canceled":
      return "font_red";

    case "Ongoing":
      return "font_yellow";

    default:
      return "";
  }
};

export const orderStatusGenerate = (status) => {
  if (status === "Approved") {
    return {
      status: "Order Placed",
      button: "Place Order",
    };
  }
  if (status === "Order Placed") {
    return {
      status: "Parts Recieved",
      button: "Recieve Parts",
    };
  }
};

export const taskStatusGenerate = (status) => {
  switch (status) {
    case "technician_acceptance_pending":
    case "Pending":
      return { color: "font_yellow", text: "Pending Technician approval" };

    case "accepted_by_technician":
      return { color: "font_green", text: "Technician Accepted" };

    case "rejected_by_technician":
      return { color: "font_red", text: "Technician Rejected" };

    case "Completed":
      return { color: "font_blue", text: "Task Completed" };

    case "pm_approval_pending":
      return { color: "font_yellow", text: "PM Approval Pending" };

    case "Approved":
      return { color: "font_green", text: "Approved" };

    case "rejected_by_pm":
      return { color: "font_red", text: "Rejected By PM" };

    default:
      return {};
  }
};

// If tenant can approve maintenance
export const canTenantReviewMaintenance = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  const requesterRole = item?.requester_role?.name;
  if (action === "Waiting for tenant approval") {
    return true;
  }
  return false;
};

// If tenant can rate service
export const canTenantRateService = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  const requesterRole = item?.requester_role?.name;
  if (
    action === "Maintenance is approved by tenant" ||
    currentStatus === "rating_pending"
  ) {
    return true;
  }
  return false;
};

// If tenant reschedule maintenance service
export const canTenantReschedule = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  const requesterRole = item?.requester_role?.name;
  if (currentStatus === "reschedule_maintenance") {
    return true;
  }
  return false;
};

// Template
export const functionTemplate = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  const requesterRole = item?.requester_role?.name;
  if (false) {
    return true;
  }
  return false;
};
