/**
 *
 */

import React, { useState, useEffect, useRef } from "react";

/* Import configuration starts */
import * as pdfjsLib from "pdfjs-dist/webpack";

// Set the worker path for PDF.js
// import { GlobalWorkerOptions } from "pdfjs-dist";
// // import "pdfjs-dist/build/pdf.css"; // Import the default PDF.js styles
// import { pdfjs } from "react-pdf";

// GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
/* Import local pages and component ends */

/* Component starts */
const PDFViewerNew = (props) => {
  /* Props destructuring starts */
  const { file } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [pdfDocument, setPdfDocument] = useState(null);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const pdfContainerRef = useRef(null);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  //   const nextPage = () => {
  //     if (pageNumber < numPages) {
  //       setPageNumber(pageNumber + 1);
  //     }
  //   };

  //   const prevPage = () => {
  //     if (pageNumber > 1) {
  //       setPageNumber(pageNumber - 1);
  //     }
  //   };
  /* Component function definition ends */

  /* Component useEffect starts */

  useEffect(() => {
    const loadPdf = async () => {
      try {
        // Load the PDF document from the provided URL
        const loadingTask = pdfjsLib.getDocument(file);
        const pdf = await loadingTask.promise;
        setPdfDocument(pdf);
      } catch (error) {
        console.error("Error loading PDF:", error);
      }
    };

    loadPdf();
  }, [file]);

  useEffect(() => {
    const renderPdfPages = async () => {
      if (!pdfDocument || !pdfContainerRef.current) return;

      const container = pdfContainerRef.current;
      container.innerHTML = ""; // Clear any existing pages

      for (let pageNum = 1; pageNum <= pdfDocument.numPages; pageNum++) {
        const page = await pdfDocument.getPage(pageNum);
        const viewport = page.getViewport({ scale: 1.5 });

        // Create a canvas element for each page
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        // Render the page into the canvas
        await page.render({ canvasContext: context, viewport }).promise;

        // Append the canvas to the container
        container.appendChild(canvas);
      }
    };

    renderPdfPages();
  }, [pdfDocument]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    // <>
    //   <canvas ref={canvasRef}></canvas>
    // </>
    <div>
      <div ref={pdfContainerRef}></div>
      {/* <div>
        <button onClick={prevPage} disabled={pageNumber <= 1}>
          Previous
        </button>
        <span>
          Page {pageNumber} of {numPages}
        </span>
        <button onClick={nextPage} disabled={pageNumber >= numPages}>
          Next
        </button>
      </div> */}
    </div>
  );
};
/* Component ends */

export default PDFViewerNew;
