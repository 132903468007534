/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";

import moment from "moment";
/* Import configuration ends */

/* Import redux slices component starts */
import { getIsTENANT } from "../../../setup/store/slices/authSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { EditGreen, EditWhite } from "../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import ConfirmationModal from "../../../components/ui/modal/ConfirmationModal/ConfirmationModal";
/* Import local pages and component ends */

/* Component starts */
const OtherDetailBox = (props) => {
  /* Props destructuring starts */
  const { user } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const isTenant = useSelector(getIsTENANT);
  const navigate = useNavigate();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const mobile = user?.countrycode + " " + user?.mobile;
  const companyName = user?.business_name;
  const address1 = user?.address_line1;
  const address2 = user?.address_line2;
  const dateOfBirth = user?.dob;
  const city = user?.city;
  const country = user?.country;
  const state = user?.state;
  const zipcode = user?.zipcode;

  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const handleUpdate = () => {
    // if (!showConfirmationModal) {
    //   setShowConfirmationModal(!showConfirmationModal);
    navigate("update-mobile");
    // }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */
  //update-mobile

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="other-detail">
      {/* Mobile */}
      <div className="detail-group mobile">
        <p className="key fw_5 font_grey font_m">Mobile</p>
        <p className="value font_m">
          {mobile}
          <img
            src={EditGreen}
            alt="Edit"
            className="h_100"
            onClick={() => setShowConfirmationModal(!showConfirmationModal)}
          />
        </p>
      </div>

      {/* Gender */}
      {isTenant && (
        <div className="detail-group business_name">
          <p className="key fw_5 font_grey font_m">Gender</p>
          <p className="value  font_m">{user?.gender}</p>
        </div>
      )}

      {/* DOB */}
      {isTenant && (
        <div className="detail-group business_name">
          <p className="key fw_5 font_grey font_m">Date Of Birth</p>
          <p className="value  font_m">
            {dateOfBirth !== undefined
              ? moment(dateOfBirth).format(DATE_FORMAT)
              : "-"}
          </p>
        </div>
      )}

      {/* Company Name */}
      <div className="detail-group business_name">
        <p className="key fw_5 font_grey font_m">Company Name</p>
        <p className="value  font_m">
          {companyName !== undefined ? companyName : "-"}
        </p>
      </div>

      {/* Profession */}
      {isTenant && (
        <div className="detail-group business_name">
          <p className="key fw_5 font_grey font_m">Profession</p>
          <p className="value  font_m">{user?.profession}</p>
        </div>
      )}

      {/* Nationality */}
      {isTenant && (
        <div className="detail-group business_name">
          <p className="key fw_5 font_grey font_m">Nationality</p>
          <p className="value  font_m">{user?.nationality}</p>
        </div>
      )}

      {/* Language */}
      {isTenant && (
        <div className="detail-group business_name">
          <p className="key fw_5 font_grey font_m">Language</p>
          <p className="value  font_m">{user?.language}</p>
        </div>
      )}

      {!isTenant && (
        <>
          {/* City */}
          <div className="detail-group city">
            <p className="key fw_5 font_grey font_m">City</p>
            <p className="value fw_5  font_m">{city}</p>
          </div>

          {/* State */}
          <div className="detail-group state">
            <p className="key fw_5 font_grey font_m">State</p>
            <p className="value fw_5  font_m">{state}</p>
          </div>

          {/* Country */}
          <div className="detail-group country">
            <p className="key fw_5 font_grey font_m">Country</p>
            <p className="value fw_5  font_m">{country}</p>
          </div>

          {/* Address 1 */}
          <div className="detail-group address_1">
            <p className="key fw_5 font_grey font_m">Address 1</p>
            <p className="value fw_5  font_m">{address1}</p>
          </div>

          {/* Address 2 */}
          {address2 && (
            <div className="detail-group address_2">
              <p className="key fw_5 font_grey font_m">Address 2</p>
              <p className="value fw_5  font_m">{address2}</p>
            </div>
          )}

          {/* Zip Code */}
          <div className="detail-group zip_code">
            <p className="key fw_5 font_grey font_m">Zip Code</p>
            <p className="value fw_5  font_m">{zipcode}</p>
          </div>
        </>
      )}

      {/* Modals */}
      {/* Confirmation Modal */}
      <ConfirmationModal
        showModal={showConfirmationModal}
        description="Are you sure to change / update the Mobile Number"
        title="Confirm Mobile Number Update"
        onClose={() => setShowConfirmationModal(false)}
        onConfirm={() => navigate("update-mobile")}
      />
    </div>
  );
};
/* Component ends */

export default OtherDetailBox;
