/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { formKeys, validations } from "./helper";
import { Formik } from "formik";
import { useSelector } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import { getUserTheme } from "../../../../setup/store/slices/authSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Modal } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomCurrencyInput from "../../../ui/input/CustomCurrencyInput/CustomCurrencyInput";
import CustomDateInput from "../../../ui/input/CustomDateInput/CustomDateInput";
import CustomTextInput from "../../../ui/input/CustomTextInput/CustomTextInput";
import CustomFileInput from "../../../ui/input/CustomFileInput/CustomFileInput";
import CustomCheckInput from "../../../ui/input/CustomCheckInput/CustomCheckInput";
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
import TextButton from "../../../ui/button/TextButton/TextButton";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const ChequeDetailModal = (props) => {
  /* Props destructuring starts */
  const {
    showModal,
    size = "lg",
    onClose,
    onSubmit,
    amt,
    date,
    lockDate = true,
    lockAmt = true,
    currency,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [initialFormValues, setInitialFormValues] = useState({
    ...formKeys,
    cheque_date: new Date(date),
    cheque_amount: amt,
  });
  const [formRefValues, setFormRefValue] = useState({});
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const userTheme = useSelector(getUserTheme);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const submitHandler = (values) => {
    onClose();
    onSubmit({
      ...values,
      cheque_date: new Date(date),
      cheque_amount: amt,
      payment_amount: amt,
    });
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <Modal
      size={size}
      centered
      show={showModal}
      className="cheque-detail-modal-wrapper"
    >
      <Modal.Body
        className={`${userTheme === "dark" ? "bg_darkTheme" : "bg_lightTheme"}`}
      >
        <div className="custom-mocal-header">
          <h3
            className={`text-center ${
              userTheme !== "dark" && "font_blue"
            } font_white font_xl fw_6`}
          >
            Enter Cheque Details
          </h3>
        </div>

        <div className="cheque-modal-body">
          <Formik
            onSubmit={submitHandler}
            initialValues={initialFormValues}
            validate={(values) => validations(values)}
            validateOnBlur={true}
            innerRef={(d) =>
              d ? setFormRefValue(d.values) : setFormRefValue({})
            }
            enableReinitialize={true}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              setFieldTouched,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="form-wrapper">
                  <div className="form-group">
                    <CustomTextInput
                      name="cheque_number"
                      label="Cheque Number"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched.cheque_number}
                      errors={errors.cheque_number}
                      value={values.cheque_number}
                      required={true}
                    />
                  </div>
                  {!lockAmt && (
                    <div className="form-group">
                      <CustomCurrencyInput
                        label={`Cheque Amount ${currency}`}
                        name="cheque_amount"
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        touched={touched.cheque_amount}
                        errors={errors.cheque_amount}
                        value={values.cheque_amount}
                        currency={currency}
                        required={true}
                      />
                    </div>
                  )}
                  <div className="form-group">
                    <CustomTextInput
                      name="bank_name"
                      label="Bank Name"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched.bank_name}
                      errors={errors.bank_name}
                      value={values.bank_name}
                      required={true}
                    />
                  </div>
                  <div className="form-group">
                    <CustomTextInput
                      name="account_no"
                      label="Account Number"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched.account_no}
                      errors={errors.account_no}
                      value={values.account_no}
                      required={true}
                    />
                  </div>
                  <div className="form-group">
                    <CustomTextInput
                      name="cheque_issuer"
                      label="Issuer"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched.cheque_issuer}
                      errors={errors.cheque_issuer}
                      value={values.cheque_issuer}
                      required={true}
                    />
                  </div>
                  <div className="form-group">
                    <CustomFileInput
                      name="cheque_doc"
                      label="Upload Cheque"
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      formikState={true}
                      touched={touched.cheque_doc}
                      errors={errors.cheque_doc}
                      value={values.cheque_doc}
                      required={true}
                    />
                  </div>
                  {!lockDate && (
                    <div className="form-group">
                      <CustomDateInput
                        name="cheque_date"
                        label="Cheque Date"
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        touched={touched.cheque_date}
                        errors={errors.cheque_date}
                        value={values.cheque_date}
                        required={true}
                      />
                    </div>
                  )}
                </div>
                <div className="button-wrapper">
                  <CustomButton onClick={onClose} size="m" variant="red">
                    Close
                  </CustomButton>
                  <CustomButton type="submit" size="m">
                    Save
                  </CustomButton>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </Modal.Body>
    </Modal>
  );
};
/* Component ends */

export default ChequeDetailModal;
