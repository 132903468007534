/**
 *
 */

import React, { useState } from "react";

/* Import configuration starts */

import { useSelector } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import { getUserTheme } from "../../../../setup/store/slices/authSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Modal } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { EyeFilledBlue, XGreen } from "../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../../ui/button/IconButton/IconButton";
import FileViewerModal from "../../../ui/modal/FileViewerModal/FileViewerModal";
import InstallmentPlanDetailModal from "../InstallmentPlanDetailModal/InstallmentPlanDetailModal";
import ShowChequeDetailModal from "../../Invoicing/ShowChequeDetailModal/ShowChequeDetailModal";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const ViewContractModal = (props) => {
  /* Props destructuring starts */
  const { showModal, detail, size = "md", onClose } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [file, setFile] = useState(null);
  const [showFileViewer, setShowFileViewer] = useState(false);
  const [showInstalmentPlanModal, setShowInstalmentPlanModal] = useState(false);

  const [showChequeDetail, setShowChequeDetail] = useState(false);
  const [selectedCheque, setSelectedCheque] = useState(null);

  const [showChequeFile, setShowChequeFile] = useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const userTheme = useSelector(getUserTheme);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const initialAgreement = detail?.initial_agreement_pdf;
  const initialAgreementAudit = detail?.initial_agreement_audit_logs;
  const finalAgreement = detail?.final_agreement_pdf;
  const finalAgreementAudit = detail?.final_agreement_audit_logs;
  const notarizedContract = detail?.notarized_contract_pdf;
  const contractPDF = detail?.contract_pdf;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // On viewing file
  const viewFile = (file) => {
    setFile(file);
    setShowInstalmentPlanModal(false);
    setShowChequeDetail(false);
    setShowChequeFile(false);
    setShowFileViewer(true);
  };

  const viewInstallmentPlan = () => {
    setShowFileViewer(false);
    setShowChequeFile(false);
    setShowChequeDetail(false);
    setShowInstalmentPlanModal(true);
  };

  const onViewChequeDetails = () => {
    setShowFileViewer(false);
    setShowInstalmentPlanModal(false);
    setShowChequeFile(false);
    setShowChequeDetail(true);
  };

  /* Component function definition ends */

  console.log(selectedCheque, "[SELECCC]");

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  if (
    !showFileViewer &&
    !showInstalmentPlanModal &&
    !showChequeDetail &&
    !showChequeFile
  )
    return (
      <Modal
        size={size}
        centered
        show={showModal}
        className="detail-view-modal-wrapper"
      >
        <Modal.Body
          // className="custom-modal-body"
          className={`custom-modal-body ${
            userTheme === "dark" ? "bg_darkTheme" : "bg_lightTheme"
          }`}
        >
          {/* Header */}
          <div className="close-button-wrapper">
            <IconButton onClick={onClose} className="close-button">
              <img className="h_100" src={XGreen} alt="Close" />
            </IconButton>

            <p className="fw_5 font_m mb-2 font_blue text-center">
              Contract Documents
            </p>
          </div>

          {/* Body */}
          <div className="details-content">
            <div className="right">
              <div
                //className="wrapper bg_grey"
                className={`wrapper ${
                  userTheme === "dark" ? "bg_grey" : "bg_lightThemeDetail"
                }`}
              >
                {/* Initial Agreement PDF */}
                {initialAgreement && (
                  <div className="custom_row">
                    <p className="key font_grey font_s">Initial Agreement</p>
                    <p className="val font_s">
                      <IconButton
                        onClick={() => viewFile(initialAgreement)}
                        className="view"
                      >
                        <img className="h_100" src={EyeFilledBlue} alt="" />
                      </IconButton>
                    </p>
                  </div>
                )}

                {/* Initial Agreement Audit Log PDF */}
                {initialAgreementAudit && (
                  <div className="custom_row">
                    <p className="key font_grey font_s">
                      Initial Agreement Audit
                    </p>
                    <p className="val font_s">
                      <IconButton
                        onClick={() => viewFile(initialAgreementAudit)}
                        className="view"
                      >
                        <img className="h_100" src={EyeFilledBlue} alt="" />
                      </IconButton>
                    </p>
                  </div>
                )}

                {/* Final Agreement PDF */}
                {finalAgreement && (
                  <div className="custom_row">
                    <p className="key font_grey font_s">Final Agreement</p>
                    <p className="val font_s">
                      <IconButton
                        onClick={() => viewFile(finalAgreement)}
                        className="view"
                      >
                        <img className="h_100" src={EyeFilledBlue} alt="" />
                      </IconButton>
                    </p>
                  </div>
                )}

                {/* Final Agreement Audit Log PDF */}
                {finalAgreementAudit && (
                  <div className="custom_row">
                    <p className="key font_grey font_s">
                      Final Agreement Audit
                    </p>
                    <p className="val font_s">
                      <IconButton
                        onClick={() => viewFile(finalAgreementAudit)}
                        className="view"
                      >
                        <img className="h_100" src={EyeFilledBlue} alt="" />
                      </IconButton>
                    </p>
                  </div>
                )}

                {/* Notarized PDF */}

                {detail?.notarized_contract_pdf && (
                  <div className="custom_row">
                    <p className="key font_grey font_s">Notarized Contract</p>
                    <p className="val font_s">
                      {detail?.notarized_contract_pdf && (
                        <IconButton
                          onClick={() => viewFile(notarizedContract)}
                          className="view"
                        >
                          <img className="h_100" src={EyeFilledBlue} alt="" />
                        </IconButton>
                      )}
                    </p>
                  </div>
                )}

                {detail?.contract_pdf && (
                  <div className="custom_row">
                    <p className="key font_grey font_s">Contract PDF</p>
                    <p className="val font_s">
                      {detail?.contract_pdf && (
                        <IconButton
                          onClick={() => viewFile(contractPDF)}
                          className="view"
                        >
                          <img className="h_100" src={EyeFilledBlue} alt="" />
                        </IconButton>
                      )}
                    </p>
                  </div>
                )}

                {detail?.installment_plan &&
                  detail?.installments?.length > 0 && (
                    <div className="custom_row">
                      <p className="key font_grey font_s">Installment Plan</p>
                      <p className="val font_s">
                        <IconButton
                          onClick={viewInstallmentPlan}
                          className="view"
                        >
                          <img className="h_100" src={EyeFilledBlue} alt="" />
                        </IconButton>
                      </p>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );

  if (
    showFileViewer &&
    !showInstalmentPlanModal &&
    !showChequeDetail &&
    !showChequeFile
  )
    return (
      <FileViewerModal
        show={showFileViewer}
        file={file?.url}
        type={file?.type}
        onClose={() => {
          setShowFileViewer(false);
          setFile(null);
        }}
      />
    );

  if (
    !showFileViewer &&
    showInstalmentPlanModal &&
    !showChequeDetail &&
    !showChequeFile
  )
    return (
      <InstallmentPlanDetailModal
        instalments={detail?.installments}
        instalmentPlan={detail?.installment_plan}
        data={detail}
        settings={detail?.property_setting}
        onClose={() => {
          setShowFileViewer(false);
          setShowInstalmentPlanModal(false);
          setShowChequeDetail(false);
          setShowChequeFile(false);
        }}
        setSelectedCheque={setSelectedCheque}
        onViewChequeDetails={onViewChequeDetails}
      />
    );

  if (
    !showFileViewer &&
    !showInstalmentPlanModal &&
    showChequeDetail &&
    !showChequeFile
  ) {
    return (
      <ShowChequeDetailModal
        data={selectedCheque?.cheque || selectedCheque}
        isCheque={selectedCheque?.cheque && true}
        showModal={showChequeDetail}
        onClose={() => {
          setShowChequeDetail(false);
          setShowInstalmentPlanModal(true);
          setShowFileViewer(false);
          setShowChequeFile(false);
        }}
        onViewChequeFile={() => {
          setShowChequeDetail(false);
          setShowInstalmentPlanModal(false);
          setShowFileViewer(false);
          setShowChequeFile(true);
        }}
      />
    );
  }

  if (
    !showFileViewer &&
    !showInstalmentPlanModal &&
    !showChequeDetail &&
    showChequeFile
  ) {
    return (
      <FileViewerModal
        show={showChequeFile}
        file={selectedCheque?.cheque?.document?.url}
        type={selectedCheque?.cheque?.document?.type}
        onClose={() => {
          setShowChequeFile(false);
          setShowInstalmentPlanModal(false);
          setShowFileViewer(false);
          setShowChequeDetail(true);
        }}
      />
    );
  }
};
/* Component ends */

export default ViewContractModal;
