/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import FileViewerModal from "../../../../ui/modal/FileViewerModal/FileViewerModal";
import { DefaultAdvertisment } from "../../../../../setup/constants/images";
/* Import local pages and component ends */

/* Component starts */
const SliderItem2 = (props) => {
  /* Props destructuring starts */
  // const { item, index } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  // const [showFileViewer, setShowFileViewer] = useState(false);
  // const [file, setFile] = useState({});

  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="slider2-wrapper">
      <div
        className="image-wrapper w_100"
        //key={index}
      >
        <img
          src={DefaultAdvertisment}
          alt="slider-image"
          // onClick={() => {
          //   setFile(item);
          //   setShowFileViewer(true);
          // }}
        />
      </div>
      {/* <div className="details-wrapper">
        <div className="detail">
          <div className="text-1">
            <p className="font_xl fw_5 font_darkblue text-center">
              Premium Luxury Sea Facing Apartments
            </p>
          </div>

          <div className="text-1">
            <p className="font_l fw_4 font_darkblue text-center">
              <span className="font_xl fw_6 font_darkblue">3</span> &{" "}
              <span className="font_xl fw_6 font_darkblue">4 BHK</span>{" "}
              apartments starting from{" "}
              <span className="font_l fw_6 font_darkblue">
                ₹ 5.58 Cr<sup className="font_l fw_6 font_darkblue">*</sup>
              </span>
            </p>
          </div>

          <div className="text-1">
            <div className="tag">
              <p className="font_xl fw_5">LAST FEW UNITS LEFT</p>
            </div>
          </div>
          <div className="text-1">
            <p className="font_xxs fw_3 font_darkblue text-center">
              In proximitily to the upcoming Coastal Road Indoor Games Area
            </p>
          </div>
          <div className="text-1">
            <p className="font_m fw_4 font_darkblue text-center">
              Rooftop Launge | Salon Spa | Yoga Area
            </p>
          </div>
          <div className="text-1">
            <p className="font_m fw_4 font_darkblue text-center">
              24/7 Multi-Tier Security and many more amenities
            </p>
          </div>
        </div>
      </div> */}

      {/* <FileViewerModal
        show={showFileViewer}
        type={file.type}
        file={file.url}
        onClose={() => {
          setShowFileViewer(false);
          setFile({});
        }}
      /> */}
    </div>
  );
};
/* Component ends */

export default SliderItem2;
