/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import {
  DocumentGreen,
  EyeFilledBlue,
} from "../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../../../ui/button/IconButton/IconButton";
import FileViewerModal from "../../../../ui/modal/FileViewerModal/FileViewerModal";
/* Import local pages and component ends */

/* Component starts */
const ClearenceDocGroupBlackCard = (props) => {
  /* Props destructuring starts */
  const { auditLog, doc } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [showDoc, setShowDoc] = useState(false);
  const [file, setFile] = useState({});
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="clearence-doc-group-wrapper bg_black">
      <div className="custom-row">
        <div className="label">
          <h3 className="font_m fw_5">Clearence Certificate Document View</h3>
        </div>
        <div className="value">
          <IconButton
            onClick={() => {
              setFile(doc);
              setShowDoc(true);
            }}
          >
            <img src={EyeFilledBlue} alt="View" className="icon" />
          </IconButton>
        </div>
      </div>
      <div className="custom-row">
        <div className="label">
          <h3 className="font_m fw_5">Clearence Certificate Audit Logs View</h3>
        </div>
        <div className="value">
          <IconButton
            onClick={() => {
              setFile(auditLog);
              setShowDoc(true);
            }}
          >
            <img src={EyeFilledBlue} alt="View" className="icon" />
          </IconButton>
        </div>
      </div>

      <FileViewerModal
        show={showDoc}
        file={file?.url}
        type={file?.type}
        onClose={() => setShowDoc(false)}
      />
    </div>
  );
};
/* Component ends */

export default ClearenceDocGroupBlackCard;
