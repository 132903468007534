/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import GLOBAL from "../../../../setup/constants/global";
import { useSelector } from "react-redux";
import { isUnpaid, getContractType } from "./helper";
/* Import configuration ends */

/* Import redux slices component starts */
import { getTenantContract } from "../../../../setup/store/slices/tenantSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import PaymentMethod from "../../Payment/PaymentMethod/PaymentMethod";
import TransactionStatus from "../../Payment/TransactionStatus/TransactionStatus";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const ContractPayment = (props) => {
  /* Props destructuring starts */
  const {
    data,
    onPaymentSuccess,
    acceptOnlinePayment,
    billing_invoices,
    contract,
    property_settings,
    attachCheque,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [transactionStatus, setTransactionStatus] = useState({});
  const [showTransactionStatus, setShowTransactionStatus] = useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const _getInvoicesToPay = () => {
    let invoice_ids = [];
    billing_invoices?.forEach((invoice) => {
      if (
        invoice?.status === GLOBAL.UNPAID ||
        invoice?.status === "not_collected"
      ) {
        invoice_ids?.push(invoice?._id);
      }
    });
    return invoice_ids;
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="contract-fee-payment-module-wrapper container_sm">
      <h2 className="heading text-center font_xxl mb-3">Contract</h2>

      {/* Showing transaction status after payment */}
      {showTransactionStatus && (
        <TransactionStatus
          onSuccessClose={() => {
            onPaymentSuccess();
            setShowTransactionStatus(false);
          }}
          onFailedClose={() => {
            setShowTransactionStatus(false);
          }}
          status={transactionStatus}
          currency={data?.currency}
        />
      )}

      {/* Payment interface */}
      {!showTransactionStatus && (
        <>
          <div className="amt-list">
            {billing_invoices?.map(
              (item, index) =>
                (isUnpaid(item) || item?.status === "not_collected") && (
                  <div className="amt-item">
                    <div className="name">
                      <p className="font_m fw_5 ">
                        {getContractType(item.type)}{" "}
                        {item?.description ? `(${item?.description})` : null}
                      </p>

                      {((property_settings?.collect_tax_enabled &&
                        item?.tax_amount > 0) ||
                        (property_settings?.collect_vat_enabled &&
                          item?.vat_amount > 0)) && (
                        <>
                          {property_settings?.collect_tax_enabled &&
                            item?.tax_amount > 0 && (
                              <p className="tax_label font_xs fw_6 font_grey">
                                TAX ({property_settings?.collect_tax_value}%)
                              </p>
                            )}

                          {property_settings?.collect_vat_enabled &&
                            item?.vat_amount > 0 && (
                              <p className="vat_label font_xs fw_6 font_grey">
                                VAT ({property_settings?.collect_vat_value}%)
                              </p>
                            )}

                          <p className="font_m fw_5 ">Total</p>
                        </>
                      )}
                    </div>
                    <div className="totalAmt text-end">
                      <p>
                        {(property_settings?.collect_tax_enabled &&
                          item?.tax_amount > 0) ||
                        (property_settings?.collect_vat_enabled &&
                          item?.vat_amount > 0) ? (
                          <span className="font_grey font_m fw_5">
                            {data?.currency}{" "}
                            {item?.amount?.toLocaleString("en-US")}
                          </span>
                        ) : (
                          <span className="name font_m fw_5 ">
                            {data?.currency}{" "}
                            {item?.total_amount?.toLocaleString("en-US")}
                          </span>
                        )}
                      </p>

                      {((property_settings?.collect_tax_enabled &&
                        item?.tax_amount > 0) ||
                        (property_settings?.collect_vat_enabled &&
                          item?.vat_amount > 0)) && (
                        <>
                          {property_settings?.collect_tax_enabled &&
                            item?.tax_amount > 0 && (
                              <p className="tax_amt font_xs fw_6 font_grey">
                                {data.currency}{" "}
                                {item?.tax_amount?.toLocaleString("en-US")}
                              </p>
                            )}

                          {property_settings?.collect_vat_enabled &&
                            item?.vat_amount > 0 && (
                              <p className="vat_amt font_xs fw_6 font_grey">
                                {data.currency}{" "}
                                {item?.vat_amount?.toLocaleString("en-US")}
                              </p>
                            )}

                          <p className="font_m fw_5 ">
                            {data.currency}{" "}
                            {item?.total_amount?.toLocaleString("en-US")}
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                )
            )}
            <div className="amt-item">
              <p className="name font_m fw_5">Total Payable Amount</p>
              <p className="totalAmt font_m fw_5 font_blue text-end">
                {data?.currency}{" "}
                {billing_invoices
                  ?.reduce(
                    (prev, next) =>
                      (prev =
                        prev +
                        (next.status === GLOBAL.UNPAID ||
                        next.status === "not_collected"
                          ? next.total_amount
                          : 0)),
                    0
                  )
                  ?.toLocaleString("en-US")}
              </p>
            </div>
          </div>
          {/* {attachCheque && (
                  // <p className="font_s fw_4 font_blue py-2">
                    'Payable By Cheque...'
                  // </p>
                )} */}
          <div className="form-wrapper">
            {data?.property_id?.mgmt_model === GLOBAL.MGMT_MODEL.T1 ? (
              <h3 className="font_m fw_5 font_yellow">
                The contract payment is due. Please visit the leasing office to
                make the payment.
                {/* //{attachCheque && "( This Invoice Is Payable By Cheque! )"} */}
              </h3>
            ) : (
              <h3 className="font_m fw_5 font_yellow">
                The contract payment is due. Please visit the leasing office to
                make the payment.
                {/* {attachCheque && "This Invoice Is Payable By Cheque!"} ) */}
              </h3>
            )}
          </div>
          {acceptOnlinePayment && !attachCheque && (
            <>
              <h3 className="pay-online font_l fw_6 text-center">
                Or Pay Online
              </h3>
              <PaymentMethod
                paymentType={GLOBAL.PAYMENT_TYPE.TENANT_CONTRACT}
                receiverStripeKey={
                  property_settings?.payment_processor?.stripe_public_key
                }
                isPaymentAllowed={true}
                payload={{
                  invoice_ids: _getInvoicesToPay(),
                  property_id: data?.property_id?._id,
                  contract_id: contract._id,
                }}
                setTransactionStatus={setTransactionStatus}
                setShowTransactionStatus={setShowTransactionStatus}
              />
            </>
          )}
        </>
      )}
    </div>
  );
};
/* Component ends */

export default ContractPayment;
