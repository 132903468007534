export const initialValues = {
  total_parking: "",
  paid_parking: "",
  period: "",
  fee: "",
};

export const validations = (values) => {
  const errorFields = {};

  /* Validations for Paid Parking Starts */
  if (values.paid_parking === "") {
    errorFields.paid_parking = "Enter Paid Parking is required";
  }

  /* Validations for Paid Parking Ends */

  /* Validations for Fee Starts */
  if (values.fee === "") {
    errorFields.fee = "Fee is required";
  }
  /* Validations for Fee Ends */

  return errorFields;
};
