/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { EyeFilledBlue } from "../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../../../ui/button/IconButton/IconButton";
import RejectedReportModal from "../../RejectedReportModal/RejectedReportModal";
import FileViewerModal from "../../../../ui/modal/FileViewerModal/FileViewerModal";
/* Import local pages and component ends */

/* Component starts */
const DetailSection7 = (props) => {
  /* Props destructuring starts */
  const { prev_final_URIC } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [showReport, setShowReport] = useState(false);
  const [showFile, setShowFile] = useState(false);
  const [file, setFile] = useState(null);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const handleViewFile = (file) => {
    setFile(file);
    setShowReport(false);
    setShowFile(true);
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="section pair-section section3 rejected-uirc-section font_s fw_6">
      {/* Status */}
      <p className="left font_grey">Rejected Phase 1 UIRC Final Reports</p>
      <p className={`value right`}>
        <IconButton onClick={() => setShowReport(true)}>
          <img src={EyeFilledBlue} className="icon-sm" alt="View" />
        </IconButton>
      </p>

      <RejectedReportModal
        showModal={showReport}
        onClose={() => setShowReport(false)}
        prev_final_URIC={prev_final_URIC}
        handleViewFile={handleViewFile}
        assesment_type="Final"
      />

      <FileViewerModal
        show={showFile}
        file={file?.url}
        type={file?.type}
        onClose={() => {
          setShowFile(false);
          setShowReport(true);
          setFile(null);
        }}
      />
    </div>
  );
};
/* Component ends */

export default DetailSection7;
