/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
// import { getUser } from "../../setup/store/slices/authSlice";
// import { getSetting } from "../../setup/store/slices/settingSlice";
import {
  getIsTENANT,
  getUserTheme,
  updateTenantTheme,
  updateUserTheme,
} from "../../setup/store/slices/authSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import SliderButton from "../../components/ui/button/SliderButton/SliderButton";
import BreadCrumb from "../../components/ui/other/BreadCrumb/BreadCrumb";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const ThemeSettingPage = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [theme, setTheme] = useState(null);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  //   const navigate = useNavigate();

  // Redux Selector
  //   const user = useSelector(getUser);
  //   const settings = useSelector(getSetting);
  const dispatch = useDispatch();
  const userTheme = useSelector(getUserTheme);
  const isTenant = useSelector(getIsTENANT);
  console.log(userTheme, "(0)");
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const toggleActiveTheme = (state, type) => {
    console.log(state, "1245", type);

    if (isTenant) {
      // FOR TENANTS
      if (state && type === "automatic") {
        dispatch(updateTenantTheme({ theme: "automatic" }));
      } else if (state) {
        dispatch(updateTenantTheme({ theme: "dark" }));
      } else {
        dispatch(updateTenantTheme({ theme: "light" }));
      }
    } else {
      // NORMAL USERS
      if (state && type === "automatic") {
        dispatch(updateUserTheme({ theme: "automatic" }));
      } else if (state) {
        dispatch(updateUserTheme({ theme: "dark" }));
      } else {
        dispatch(updateUserTheme({ theme: "light" }));
      }
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      {!isTenant && <BreadCrumb type="settings_changeTheme_page" />}
      <div className="theme-setting-page-wrapper container_sm">
        <h2 className="font_xxl heading text-center">Theme Setting</h2>

        <div className="inner-wrapper">
          <div className="option">
            <p>Dark / Light Theme</p>
            <SliderButton
              active={userTheme === "dark"}
              onChange={(state) => toggleActiveTheme(state, "dark")}
              disabled={userTheme === "automatic"}
            />
          </div>
          <div className="option">
            <p>Automatic</p>
            <SliderButton
              active={userTheme === "automatic"}
              onChange={(state) => toggleActiveTheme(state, "automatic")}
              // disabled={userTheme !== ("light " || "dark")}
            />
          </div>
        </div>
      </div>
    </>
  );
};
/* Component ends */

export default ThemeSettingPage;
