/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import GLOBAL from "../../setup/constants/global";
import { useParams } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  fetchContractorAccessType,
  fetchGuestAccessType,
} from "../../setup/store/slices/guestPass";
import { showErrorAlert } from "../../setup/store/slices/globalAlertSlice";
import { fetchUnitDetailByID } from "../../setup/store/slices/unitSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import AddGuestPass from "../../components/module/GuestPass/AddGuestPass/AddGuestPass";
/* Import local pages and component ends */

/* Component starts */
const AddTenantGuestPass = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [contractorPassReasons, setContractorPassReasons] = useState([]);
  const [guestPassReasons, setGuestPassReasons] = useState([]);
  const [unit, setUnit] = useState(null);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { unitId } = useParams();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const handleFetchContractorReasons = async () => {
    // Backend response. Try, Catch
    try {
      const result = await dispatch(fetchContractorAccessType());
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setContractorPassReasons([]);
          dispatch(
            showErrorAlert({
              title: "Error!",
              description:
                typeof result?.payload === "string" ? result?.payload : "",
            })
          );
          break;

        case GLOBAL.FULFILLED:
          setContractorPassReasons(result?.payload);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  const handleFetchGuestReasons = async () => {
    // Backend response. Try, Catch
    try {
      const result = await dispatch(fetchGuestAccessType());
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setGuestPassReasons([]);
          dispatch(
            showErrorAlert({
              title: "Error!",
              description:
                typeof result?.payload === "string" ? result?.payload : "",
            })
          );
          break;

        case GLOBAL.FULFILLED:
          setGuestPassReasons(result?.payload);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  const handleFetchUnitDetails = async () => {
    // Creating or collecting payload data to be sent
    const payload = { unit_id: unitId };
    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      const result = await dispatch(fetchUnitDetailByID(payload));
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setUnit(null);
          dispatch(
            showErrorAlert({
              title: "Error!",
              description:
                typeof result?.payload === "string" ? result?.payload : "",
            })
          );
          break;

        case GLOBAL.FULFILLED:
          setUnit(result?.payload);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    handleFetchContractorReasons();
    handleFetchGuestReasons();
    handleFetchUnitDetails();
  }, []);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="container_sm">
      <AddGuestPass
        onSuccess={() => navigate(-1)}
        onCancel={() => navigate(-1)}
        contractorPassReasons={contractorPassReasons}
        guestPassReasons={guestPassReasons}
        unit={unit}
      />
    </div>
  );
};
/* Component ends */

export default AddTenantGuestPass;
