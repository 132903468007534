/**
 *
 */

import React, { useState, useEffect, useRef } from "react";

/* Import configuration starts */
import { useDispatch, useSelector } from "react-redux";
import { Formik, FieldArray } from "formik";
import CustomDateInput from "../../../ui/input/CustomDateInput/CustomDateInput";
import CustomTimeInput from "../../../ui/input/CustomTimeInput/CustomTimeInput";
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
import TextButton from "../../../ui/button/TextButton/TextButton";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */

/* Import local pages and component ends */

import "./style.scss";
import CustomCheckInput from "../../../ui/input/CustomCheckInput/CustomCheckInput";
import {
  DeleteGrey,
  DeleteRed,
  EditGreen,
  EditWhite,
} from "../../../../setup/constants/images";
import CustomTextInput from "../../../ui/input/CustomTextInput/CustomTextInput";

/* Component starts */
const AddVacations = (props) => {
  /* Props destructuring starts */
  const { onCancel } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [initialFormValues, setInitialFormValues] = useState({
    title: "",
  });

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [monday, setMonday] = useState(false);
  const [tuesday, setTuesday] = useState(false);
  const [wednesday, setWednesday] = useState(false);
  const [thursday, setThursday] = useState(false);
  const [friday, setFriday] = useState(false);
  const [saturday, setSaturday] = useState(false);
  const [sunday, setSunday] = useState(false);

  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // Form validation
  //   const formValidation = (value) => {
  //     const err = {};

  //     if (value.time === "") {
  //       err.time = "asd";
  //     }

  //     return err;
  //   };

  // On updating commercial detail
  const onSubmit = (value) => {
    console.log(value);
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="vacations-module-wrapper">
      <h2 className="heading text-center font_xxl mb-5">Add Vacations</h2>

      <div className="add-new-form">
        <Formik
          onSubmit={onSubmit}
          initialValues={initialFormValues}
          //   validate={formValidation}
          validateOnBlur={true}
          enableReinitialize={true}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="form-wrapper">
                {/* Creating Schedule */}

                <>
                  <div className="form-group-full">
                    <p className="font_m fw_4">Staff Member: Emmett Motley</p>
                  </div>

                  <hr />

                  <div className="form-group-full mb-2 mt-2">
                    <p className="font_m fw_4 form-group-full">
                      Upcoming Vacations
                    </p>
                    <div className="form-group-full bg_black holiday mb-2">
                      <p className="font_m font_grey fw_4 p-2">
                        10/08/2022 to 10/08/2023{" "}
                      </p>
                      <div className="details">
                        <p className="font_m font_grey fw_4 p-2">Metharan</p>
                        <p>
                          <img src={EditGreen} alt="Edit-Icon" />
                          <img src={DeleteRed} alt="Delete-Icon" />
                        </p>
                      </div>
                    </div>

                    <div className="form-group-full bg_black holiday mb-2">
                      <p className="font_m font_grey fw_4 p-2">
                        10/08/2022 to 10/08/2023{" "}
                      </p>
                      <div className="details">
                        <p className="font_m font_grey fw_4 p-2">Metharan</p>
                        <p>
                          <img src={EditGreen} alt="Edit-Icon" />
                          <img src={DeleteRed} alt="Delete-Icon" />
                        </p>
                      </div>
                    </div>
                  </div>

                  <hr className="mb-2" />

                  <p className="font_l fw_4 form-group-full mb-2 mt-2">
                    Create Vacations
                  </p>

                  {/* Input for Start Date */}
                  <div className="form-group">
                    <CustomDateInput
                      label="Start Date"
                      value={startDate}
                      minDate={false}
                      customOnchange={(date) => setStartDate(date)}
                    />
                  </div>

                  {/* Input for End Date */}
                  <div className="form-group">
                    <CustomDateInput
                      label="End Date"
                      value={endDate}
                      minDate={false}
                      customOnchange={(date) => setEndDate(date)}
                    />
                  </div>

                  {/* Input for Title */}
                  <div className="form-group">
                    <CustomTextInput
                      name="title"
                      label="Enter Title"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched.title}
                      errors={errors.title}
                      value={values.title}
                    />
                  </div>
                </>
              </div>

              <div className="button-wrapper text-center">
                <>
                  <CustomButton type="submit" size="l">
                    Create Vacation
                  </CustomButton>
                  <TextButton
                    type="button"
                    onClick={onCancel}
                    className="font_m fw_6"
                  >
                    Cancel
                  </TextButton>
                </>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};
/* Component ends */

export default AddVacations;
