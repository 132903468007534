/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useParams } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import InitialAgreementTemplate from "./templates/InitialAgreementTemplate";
import FinalAgreementTemplate from "./templates/FinalAgreementTemplate";
import ClearenceCertificateTemplate from "./templates/ClearenceCertificateTemplate";
import TemplateDateFormat from "./TemplateDateFormat";
/* Import local pages and component ends */

/* Component starts */
const T3Template = (props) => {
  /* Props destructuring starts */
  const { user, settings } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const { propertyId } = useParams();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div>
      {/* **************** Settings ******************************** */}
      <h2 className="main_heading font_xxl fw_7 font_grey template-row-style">
        Templates
      </h2>

      <InitialAgreementTemplate
        property_id={propertyId}
        user={user}
        settings={settings}
      />

      <FinalAgreementTemplate
        property_id={propertyId}
        user={user}
        settings={settings}
      />

      <ClearenceCertificateTemplate
        property_id={propertyId}
        user={user}
        settings={settings}
      />

      <TemplateDateFormat
        property_id={propertyId}
        user={user}
        settings={settings}
      />
    </div>
  );
};
/* Component ends */

export default T3Template;
