/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useDispatch } from "react-redux";
import GLOBAL from "../../../../../setup/constants/global";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  resendTenantInvitation,
  cancelTenantInvitation,
} from "../../../../../setup/store/slices/tenantSlice";
import {
  showSuccessAlert,
  showErrorAlert,
} from "../../../../../setup/store/slices/globalAlertSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomButton from "../../../../ui/button/CustomButton/CustomButton";
import ConfirmationModal from "../../../../ui/modal/ConfirmationModal/ConfirmationModal";
/* Import local pages and component ends */

/* Component starts */
const CancelResendInvitation = (props) => {
  /* Props destructuring starts */
  const { getApplication, application } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [action, setAction] = useState();
  const [invitationConfirmModalText, setInvitationConfirmModalText] = useState({
    title: "",
    description: "",
  });
  const [showInvitationConfirmation, setShowInvitationConfirmation] =
    useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const onInvitaionAction = async () => {
    setShowInvitationConfirmation(false);
    // Backend response. Try, Catch
    try {
      let result;
      if (action === "resend") {
        result = await dispatch(
          resendTenantInvitation(application?.invitation_id)
        );
      } else {
        result = await dispatch(
          cancelTenantInvitation(application?.invitation_id)
        );
      }
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description:
                typeof result?.payload === "string" ? result?.payload : "",
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description:
                typeof result?.payload?.message === "string"
                  ? result?.payload?.message
                  : "",
            })
          );
          getApplication();
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      <div className="invitaion-action-btn-wrapper">
        <CustomButton
          onClick={() => {
            setAction("resend");
            setInvitationConfirmModalText({
              title: "Confirm Resend",
              description: "Are you sure you want to resend ?",
            });
            setShowInvitationConfirmation(true);
          }}
          size="l"
          className=""
        >
          Resend Invitation
        </CustomButton>

        <CustomButton
          onClick={() => {
            setAction("cancel");
            setInvitationConfirmModalText({
              title: "Confirm Cancel",
              description: "Are you sure you want to cancel ?",
            });
            setShowInvitationConfirmation(true);
          }}
          size="l"
          variant="red"
          className=""
        >
          Cancel Invitaion
        </CustomButton>
      </div>

      <ConfirmationModal
        showModal={showInvitationConfirmation}
        title={invitationConfirmModalText.title}
        description={invitationConfirmModalText.description}
        onClose={() => {
          setShowInvitationConfirmation(false);
        }}
        onConfirm={onInvitaionAction}
      />
    </>
  );
};
/* Component ends */

export default CancelResendInvitation;
