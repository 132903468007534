/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Table } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { EyeFilledBlue } from "../../../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../../../../../ui/button/IconButton/IconButton";
/* Import local pages and component ends */

/* Component starts */
const ExpiredInvoicing = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */

  // DUMMY DATA
  const ongoingList = [];

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="custom-table-wrapper advertisment-invoice-table">
      {ongoingList?.length > 0 && (
        <Table className="custom-table font_s" borderless responsive>
          {/* Table Heading */}
          <thead>
            <tr>
              <th>ID</th>
              <th>Date & Time</th>
              <th>Tenant</th>
              <th>Unit</th>
              <th>Category</th>
              <th>Sub Category</th>
              <th>Technician</th>
              <th>Rating</th>
              <th className="text-center">Action</th>
            </tr>
          </thead>
          {/* Table Body */}
          <tbody>
            {ongoingList?.map((item) => (
              <tr key={item.id}>
                {/* ID */}
                <td>{item?.id}</td>

                {/*  Date */}
                <td>{item?.date}</td>

                {/* Tenant Name */}
                <td>{item?.tenant_name}</td>

                {/* Unit NUmber */}
                <td>{item?.unit_no}</td>

                {/* Category */}
                <td>{item?.category}</td>

                {/* Sub Category */}
                <td>{item?.sub_cat}</td>

                {/* Technician */}
                <td>{item?.technician}</td>

                {/* Rating */}
                <td>{item?.rating}</td>

                {/* Actions */}
                <td className="text-center txt-nowrap">
                  <IconButton
                    // onClick={() =>
                    //   navigate("/tenant/security/details", {
                    //     state: { item },
                    //   })
                    // }
                    className="action-button"
                  >
                    <img
                      src={EyeFilledBlue}
                      className="h_100"
                      alt="View Details"
                    />
                  </IconButton>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      {ongoingList?.length === 0 && (
        <div className=" text-center">
          <h3 className=" font_m font_yellow fw_6">No Data</h3>
        </div>
      )}
    </div>
  );
};
/* Component ends */

export default ExpiredInvoicing;
