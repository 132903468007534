/**
 * This component is a Modal that is shown on success
 */

import React from "react";
import moment from "moment";

/* Import react bootstrap component starts */
import { Modal } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { DocumentGreen, XGreen } from "../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../../ui/button/IconButton/IconButton";
/* Import local pages and component ends */

import "./style.scss";
import { getUserTheme } from "../../../../setup/store/slices/authSlice";
import { useSelector } from "react-redux";

/* Component starts */
const ShowChequeDetailModal = (props) => {
  /* Props destructuring starts */
  const {
    showModal,
    size = "md",
    data,
    onClose,
    setShowFile = null,
    onViewChequeFile = null,
    isCheque = false,
    currency,
  } = props;
  /* Props destructuring ends */

  /* Component state definition starts */
  const userTheme = useSelector(getUserTheme);
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";

  /* Component state definition ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <Modal
      size={size}
      centered
      show={showModal}
      className="get-cheque-detail-modal-wrapper"
    >
      <Modal.Body
        className={`${userTheme === "dark" ? "bg_darkTheme" : "bg_lightTheme"}`}
      >
        <div className="modal-header-custom">
          <div className="cross-btn">
            <IconButton className="icon" onClick={onClose}>
              <img src={XGreen} alt="Close" className="h_100" />
            </IconButton>
          </div>
          <h3
            className={`text-center ${
              userTheme !== "dark" && "font_blue"
            } font_white font_xl fw_6`}
          >
            {isCheque ? "Cheque" : "Rent"} Details
          </h3>
        </div>
        <div className="custom-modal-body">
          <div
            className={`cheque-details-flex ${
              userTheme !== "dark" && "otherTheme"
            } `}
          >
            {isCheque ? (
              <>
                <div className="custm-row">
                  <p className="left font_xs font_grey fw_5">Cheque Number</p>
                  <p
                    // className=
                    className={`val font_s ${
                      userTheme === "light" || userTheme === "automatic"
                        ? "font_lightTheme"
                        : "font_white"
                    } `}
                    // "right font_xs fw_5"
                  >
                    {data?.cheque_number}
                  </p>
                </div>
                <div className="custm-row">
                  <p className="left font_xs font_grey fw_5">Account Number</p>
                  <p
                    //className="right font_xs fw_5"
                    className={`val font_s ${
                      userTheme === "light" || userTheme === "automatic"
                        ? "font_lightTheme"
                        : "font_white"
                    } `}
                  >
                    {data?.account_number}
                  </p>
                </div>
                <div className="custm-row">
                  <p className="left font_xs font_grey fw_5">Bank name</p>
                  <p
                    //className="right font_xs fw_5"
                    className={`val font_s ${
                      userTheme === "light" || userTheme === "automatic"
                        ? "font_lightTheme"
                        : "font_white"
                    } `}
                  >
                    {data?.bank_name}
                  </p>
                </div>
                <div className="custm-row">
                  <p className="left font_xs font_grey fw_5">Issuer</p>
                  <p
                    //className="right font_xs fw_5"
                    className={`val font_s ${
                      userTheme === "light" || userTheme === "automatic"
                        ? "font_lightTheme"
                        : "font_white"
                    } `}
                  >
                    {data?.issuer_name}
                  </p>
                </div>
                <div className="custm-row">
                  <p className="left font_xs font_grey fw_5">Cheque Amount</p>
                  <p
                    //className="right font_xs fw_5"
                    className={`val font_s font_blue`}
                  >
                    {data?.total_amount?.toLocaleString("en-US") ||
                      data?.cheque_amount?.toLocaleString("en-US")}{" "}
                    {currency}
                  </p>
                </div>
                <div className="custm-row">
                  <p className="left font_xs font_grey fw_5">Cheque Date</p>
                  <p
                    //className="right font_xs fw_5"
                    className={`val font_s ${
                      userTheme === "light" || userTheme === "automatic"
                        ? "font_lightTheme"
                        : "font_white"
                    } `}
                  >
                    {moment(data?.cheque_date).format(DATE_FORMAT)}
                  </p>
                </div>
                <div className="custm-row">
                  <p className="left font_xs font_grey fw_5">Cheque Doc</p>
                  <p className="right font_xs fw_5">
                    <IconButton
                      className="icon"
                      onClick={() => {
                        if (setShowFile) {
                          setShowFile(true);
                          onClose();
                        } else if (onViewChequeFile) {
                          onViewChequeFile();
                        }
                      }}
                    >
                      <img src={DocumentGreen} className="h_100" alt="" />
                    </IconButton>
                  </p>
                </div>
              </>
            ) : (
              <>
                <div className="custm-row">
                  <p className="left font_xs font_grey fw_5">
                    Installment Name
                  </p>
                  <p
                    // className=
                    className={`val font_s font_blue `}
                    // "right font_xs fw_5"
                  >
                    {data?.name}
                  </p>
                </div>

                <div className="custm-row">
                  <p className="left font_xs font_grey fw_5">
                    Installment Due Date
                  </p>
                  <p
                    // className=
                    className={`val font_s ${
                      userTheme === "light" || userTheme === "automatic"
                        ? "font_lightTheme"
                        : "font_white"
                    } `}
                    // "right font_xs fw_5"
                  >
                    {moment(data?.due_date).format(DATE_FORMAT)}
                  </p>
                </div>

                <div className="custm-row">
                  <p className="left font_xs font_grey fw_5">
                    Installment Amount
                  </p>
                  <p
                    // className=
                    className={`val font_s font_blue`}
                    // "right font_xs fw_5"
                  >
                    {data?.total_amount?.toLocaleString("en-US")}{" "}
                    {data?.currency}
                  </p>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="modal-footer-custom"></div>
      </Modal.Body>
    </Modal>
  );
};
/* Component ends */

export default ShowChequeDetailModal;
