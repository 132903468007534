/**
 *
 */

import React, { useEffect, useState } from "react";

/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Modal } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import MUI component starts */
/* Import MUI component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
import CustomTextArea from "../../../ui/input/CustomTextArea/CustomTextArea";
import CustomSelectInput from "../../../ui/input/CustomSelectInput/CustomSelectInput";
/* Import local pages and component ends */

import "./style.scss";
import { getUserTheme } from "../../../../setup/store/slices/authSlice";
import { useSelector } from "react-redux";
import { NoProfPicSet } from "../../../../setup/constants/images";

/* Component starts */
const AssignTechnicianModal = (props) => {
  /* Props destructuring starts */
  const {
    showModal,
    title = "Assign Technician",
    size = "lg",
    onCancel,
    action,
    technicians,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [selectedTechnician, setSelectedTechnician] = useState();
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const userTheme = useSelector(getUserTheme);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  // On Action
  const onAction = () => {
    action(selectedTechnician);
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    setSelectedTechnician(technicians?.length > 0 ? technicians[0] : {});
  }, [technicians]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <Modal
      size={size}
      centered
      show={showModal}
      className="reassign-manager-modal-wrapper"
    >
      <Modal.Body
        className={`${
          userTheme === "dark"
            ? "bg_darkTheme"
            : "bg_lightTheme font_lightTheme"
        }`}
      >
        <div className="modal-header-custom">
          <h2
            className={`text-center ${
              userTheme !== "dark" && "font_blue"
            } font_white font_l`}
          >
            {title}
          </h2>
        </div>
        <div className="modal-body-custom note-wrapper">
          <CustomSelectInput
            label="Managers"
            valueExtractor={(item) => `${item?.first_name} ${item?.last_name}`}
            setValue={setSelectedTechnician}
            options={technicians}
            value={selectedTechnician}
            formikState={false}
            emptyOption={false}
          />
          {technicians?.length > 0 && (
            <div className="userProfile">
              <div className="userImage">
                <img
                  src={selectedTechnician?.myphoto?.url || NoProfPicSet}
                  alt="Profile"
                />
              </div>
              <div className="userData">
                <div className="details">
                  <p
                    className={`key font_s ${
                      userTheme !== "dark" ? "font_grey" : "font_white"
                    } `}
                  >
                    Email:
                  </p>
                  <p
                    className={`value font_s ${
                      userTheme !== "dark" ? "font_grey" : "font_white"
                    } `}
                  >
                    {selectedTechnician?.email}
                  </p>
                </div>

                <div className="details">
                  <p
                    className={`key font_s ${
                      userTheme !== "dark" ? "font_grey" : "font_white"
                    } `}
                  >
                    Mobile:
                  </p>
                  <p
                    className={`value font_s ${
                      userTheme !== "dark" ? "font_grey" : "font_white"
                    } `}
                  >
                    {selectedTechnician?.mobile}
                  </p>
                </div>

                {selectedTechnician?.profession && (
                  <div className="details">
                    <p
                      className={`key font_s ${
                        userTheme !== "dark" ? "font_grey" : "font_white"
                      } `}
                    >
                      Profession:
                    </p>
                    <p
                      className={`value font_s ${
                        userTheme !== "dark" ? "font_grey" : "font_white"
                      } `}
                    >
                      {selectedTechnician?.profession}
                    </p>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="modal-footer-custom">
          <CustomButton size="m" variant="red" onClick={onCancel}>
            Close
          </CustomButton>
          <CustomButton size="m" onClick={onAction}>
            Assign
          </CustomButton>
        </div>
      </Modal.Body>
    </Modal>
  );
};
/* Component ends */

export default AssignTechnicianModal;
