/**
 *
 */

import React from "react";
import { useLocation } from "react-router-dom";
/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import Report from "../local-component/Report";
import Header from "../local-component/Header";
import BreadCrumb from "../../../components/ui/other/BreadCrumb/BreadCrumb";
/* Import local pages and component ends */

// Styles
import "./style.scss";

/* Component starts */
const VisitorsLogDetail = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */

  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const { state } = useLocation();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */

  return (
    <>
      <BreadCrumb type="visitor_log_detail" />

      <div className="visitor-log-book-wrapper">
        <div className="visitor-log-book-header">
          <Header />
        </div>

        <div className="visitor-report-wrapper container_sm">
          <div className="visitor-detail-box ">
            <Report incident={state} />
          </div>
        </div>
      </div>
    </>
  );
};
/* Component Ends */
export default VisitorsLogDetail;
