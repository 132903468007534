export const intialValues = {
  community_name: "",
  address_line1: "",
  address_line2: "",
  image: "",
};

export const validations = (values, image) => {
  const errors = {};

  /* Validations from Community Name Starts */
  if (values.community_name === "") {
    errors.community_name = "Community Name is required";
  }
  /* Validations from Community Name Ends */

  /* Validations from Address Line 1 Starts */
  if (values.address_line1 === "") {
    errors.address_line1 = "Address is required";
  }
  /* Validations from Address Line 1 Ends */

  /* Validations from Image Starts */
  if (image.current === null) {
    errors.image = "Image is required";
  }
  /* Validations from Image Ends */

  return errors;
};
