/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { joinAddress } from "../../../setup/utils/global-helper";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import {
  EyeFilledBlue,
  LocationPinFilledGrey,
} from "../../../setup/constants/images";
import FileViewerModal from "../../../components/ui/modal/FileViewerModal/FileViewerModal";
import IconButton from "../../../components/ui/button/IconButton/IconButton";
/* Import image and SVG ends */

/* Import local pages and component starts */
/* Import local pages and component ends */

/* Component starts */
const DetailBox = (props) => {
  /* Props destructuring starts */
  const { community } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [communityAddress, setCommunityAddress] = useState("");
  const [showFileViewer, setShowFileViewer] = useState(false);
  const [file, setFile] = useState({});
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  // Formatting address of seleted community
  useEffect(() => {
    if (community) {
      setCommunityAddress(
        joinAddress({
          address1: community?.address_line1,
          address2: community?.address_line2,
          city: community?.city,
          state: community?.state,
          country: community?.country,
        })
      );
    }
  }, [community]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="details">
      {/*  Community Name */}
      <p className="name font_l fw_5">{community?.community_name}</p>

      {/* Community Location */}
      <p className="address font_m font_grey fw_5">
        <img
          src={LocationPinFilledGrey}
          className="v_middle location"
          alt="Location Icon"
        />{" "}
        <span className="font_grey font_m v_middle">{communityAddress}</span>
      </p>

      {/* Total Units and Licenses  */}
      <div className="units-licenses">
        {/* Units */}
        <p className="units font_grey font_m">
          Total units :{" "}
          <span>{community?.unit_count || "No units assigned"}</span>
        </p>

        {/* Allocated Licences */}
        <p className="licenses font_grey font_m">
          Total Licences : <span>{community?.allotted_licenses}</span>
        </p>

        {/* Owner */}
        <p className="licenses font_grey font_m">
          Client :{" "}
          <span>
            {community?.client_user_id?.first_name}{" "}
            {community?.client_user_id?.last_name}
          </span>
        </p>
      </div>

      {/* Description */}
      <div className="description">
        <h3 className="font_m">Description</h3>
        <p className="font_grey font_m">
          {community?.description && community?.description}
          {!community?.description && "Not Added"}
        </p>
      </div>

      {/* Other details */}
      <div className="other-detail">
        <h3 className="font_m w_100 grid_full">Community Details</h3>

        {/* Property Title Deed */}
        {community?.community_title_deed?.url && (
          <p className="font_m font_grey">
            <span className="font_m font_grey v_middle">
              Community Title Deed :{" "}
            </span>
            {community?.community_title_deed && (
              <IconButton
                className="eye v_middle"
                onClick={() => {
                  setFile(community?.community_title_deed);
                  setShowFileViewer(true);
                }}
              >
                <img src={EyeFilledBlue} className="h_100 d_block" alt="" />
              </IconButton>
            )}
          </p>
        )}

        {/* Electricity ID */}
        {community?.electricity_id && (
          <p className="font_m font_grey">
            Electricity ID : <span>{community?.electricity_id}</span>
          </p>
        )}

        {/* Telecommunication ID */}
        {community?.telecommunication && (
          <p className="font_m font_grey">
            Telecommunication : <span>{community?.telecommunication}</span>
          </p>
        )}

        {/* Community Permit */}
        {community?.community_permit && (
          <p className="font_m font_grey">
            Community Permit : <span>{community?.community_permit}</span>
          </p>
        )}

        {/* Water ID */}
        {community?.water_id && (
          <p className="font_m font_grey">
            Water ID : <span>{community?.water_id}</span>
          </p>
        )}

        {/* Cooling ID */}
        {community?.colling_id && (
          <p className="font_m font_grey">
            Cooling ID : <span>{community?.colling_id}</span>
          </p>
        )}

        {/* Heating ID */}
        {community?.heating_id && (
          <p className="font_m font_grey">
            Heating ID : <span>{community?.heating_id}</span>
          </p>
        )}

        {/* Plot Number */}
        {community?.plot_number && (
          <p className="font_m font_grey">
            Plot Number : <span>{community?.plot_number}</span>
          </p>
        )}

        {/* Affection Plan */}
        {community?.affection_plan && (
          <p className="font_m font_grey">
            <span className="font_m font_grey v_middle">Affection Plan :</span>{" "}
            {community?.affection_plan && (
              <IconButton
                className="eye v_middle"
                onClick={() => {
                  setFile(community?.affection_plan);
                  setShowFileViewer(true);
                }}
              >
                <img src={EyeFilledBlue} className="h_100 d_block" alt="" />
              </IconButton>
            )}
          </p>
        )}

        {/* Sewerrage ID */}
        {community?.sewerage_id && (
          <p className="font_m font_grey">
            Sewerage ID : <span>{community?.sewerage_id}</span>
          </p>
        )}

        {/* Gas ID */}
        {community?.gas_id && (
          <p className="font_m font_grey">
            GAS ID : <span>{community?.gas_id}</span>
          </p>
        )}
      </div>

      {/* Modal */}
      {/* File viewer modal */}
      <FileViewerModal
        show={showFileViewer}
        type={file.type}
        file={file.url}
        onClose={() => {
          setShowFileViewer(false);
          setFile({});
        }}
      />
    </div>
  );
};
/* Component ends */

export default DetailBox;
