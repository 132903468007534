/**
 *
 */

import React, { useEffect, useState } from "react";

/* Import configuration starts */
import { usePaymentInputs } from "react-payment-inputs";
import { useDispatch, useSelector } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";
import GLOBAL from "../../../../setup/constants/global";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  fetchCards,
  fetchClientSecret,
  fetchTenantCards,
  fetchTenantSecret,
  generateStripeToken,
} from "../../../../setup/store/slices/paymentSlice";
import { setLoading } from "../../../../setup/store/slices/unpersistedSlice";
import {
  getUser,
  getLoggedinUserRole,
} from "../../../../setup/store/slices/authSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import MUI component starts */
/* Import MUI component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import SuccessModal from "../../../ui/modal/SuccessModal/SuccessModal";
import ErrorModal from "../../../ui/modal/ErrorModal/ErrorModal";
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
import TextButton from "../../../ui/button/TextButton/TextButton";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const AddNewCard = (props) => {
  /* Props destructuring starts */
  const { receiverStripeKey, onAddNewCard, onCancel, property_id } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [cardNumber, setCardNumber] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [cvc, setCvc] = useState("");
  const [nameOnCard, setNameOnCard] = useState("");
  const [nameOnCardTouched, setNameOnCardTouched] = useState(false);
  const [nameOnCardError, setNameOnCardError] = useState("Enter name on card");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState("");
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const user = useSelector(getUser);
  const userRole = useSelector(getLoggedinUserRole);
  const dispatch = useDispatch();
  const { meta, getCardNumberProps, getExpiryDateProps, getCVCProps } =
    usePaymentInputs();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // Handling touched for Name input
  const handleNameTouched = (event) => {
    setNameOnCardTouched(true);
    if (nameOnCard === "") {
      setNameOnCardError("Enter name on card");
    }
  };

  // Handling input change for Name
  const handleChangeName = (event) => {
    let value = event.target.value;
    setNameOnCardError("");
    setNameOnCard(value);
  };

  // Handling input change for Card number
  const handleChangeCardNumber = (event) => {
    setCardNumber(event.target.value);
  };

  // Handling input change for Expiry date
  const handleChangeExpiryDate = (event) => {
    setExpiryDate(event.target.value);
  };

  // Handling input change for Card CVC
  const handleChangeCVC = (event) => {
    setCvc(event.target.value);
  };

  // Handling on submitting card details
  const onSubmit = async () => {
    if (meta.error || nameOnCardError) {
      setIsSubmitting(true);
    } else {
      try {
        await dispatch(setLoading(true));
        let configureExpiryDate = expiryDate.split("/");
        const payload = {
          "card[number]": cardNumber.split(" ").join(""),
          "card[exp_month]": configureExpiryDate[0].trim(),
          "card[exp_year]": "20" + configureExpiryDate[1].trim(),
          "card[cvc]": cvc,
          stripePublicKey: receiverStripeKey,
        };

        const [stripeToken, clienSecret] = await Promise.all([
          dispatch(generateStripeToken(payload)),
          userRole === GLOBAL.USER_ROLE.CLIENT
            ? dispatch(fetchClientSecret())
            : dispatch(fetchTenantSecret({ property_id })),
        ]);

        console.log(stripeToken)
        console.log(clienSecret)

        if (stripeToken.meta.requestStatus === GLOBAL.REJECTED) {
          await dispatch(setLoading(false));
          setMessage(stripeToken.payload);
          setShowErrorModal(true);
        } else if (clienSecret.meta.requestStatus === GLOBAL.REJECTED) {
          await dispatch(setLoading(false));
          setMessage(clienSecret.payload);
          setShowErrorModal(true);
        } else {
          let token = stripeToken.payload.id;
          let client_secret =
            userRole === GLOBAL.USER_ROLE.CLIENT
              ? clienSecret.payload.client_secret
              : clienSecret.payload;

          const stripe = await loadStripe(receiverStripeKey);

          const { paymentMethod } = await stripe.createPaymentMethod({
            type: "card",
            card: {
              token: token,
            },
            billing_details: {
              //! user.email won't work for tenant, as we do not have tenant fetch api
              email: user.email,
              name: nameOnCard,
            },
          });

          console.log("payment method", paymentMethod)

          const { error } = await stripe.confirmCardSetup(client_secret, {
            payment_method: paymentMethod.id,
          });
          await dispatch(setLoading(false));
          if (error) {
            console.error("err", error);
            setMessage(error.localizedMessage);
            setTimeout(() => {
              setShowErrorModal(true);
            }, 300);
          } else {
            setMessage("Card successfully saved");
            setTimeout(() => {
              setShowSuccessModal(true);
            }, 300);
          }
        }
      } catch (e) {
        await dispatch(setLoading(false));
        console.log("error", e.message);
      }
    }
  };

  // On modal close
  const onModalClose = async () => {
    if (showErrorModal) {
      setShowErrorModal(false);
    } else {
      if (userRole === GLOBAL.USER_ROLE.CLIENT) {
        await dispatch(fetchCards());
      } else {
        await dispatch(fetchTenantCards({ property_id }));
      }
      onAddNewCard();
      setShowSuccessModal(false);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="add-new-card-wrapper-main">
      {/* Form */}
      <div className="fields">
        {/* Input for Name */}
        <div className="input-wrapper">
          <label className="font_s font_grey">Name on Card</label>
          <input
            className="bg_black"
            onChange={handleChangeName}
            value={nameOnCard}
            onBlur={handleNameTouched}
          />
          {nameOnCardTouched && nameOnCardError ? (
            <p className="error font_xxs font_red">{nameOnCardError}</p>
          ) : isSubmitting ? (
            <p className="error font_xxs font_red">{nameOnCardError}</p>
          ) : (
            ""
          )}
        </div>

        {/* Input for Card Number */}
        <div className="input-wrapper">
          <label className="font_s font_grey">Card Number</label>
          <input
            className="bg_black"
            {...getCardNumberProps({ onChange: handleChangeCardNumber })}
            value={cardNumber}
          />

          {meta.touchedInputs.cardNumber && meta.erroredInputs.cardNumber ? (
            <p className="error font_xxs font_red">
              {meta.erroredInputs.cardNumber}
            </p>
          ) : isSubmitting ? (
            <p className="error font_xxs font_red">
              {meta.erroredInputs.cardNumber}
            </p>
          ) : (
            ""
          )}
        </div>

        {/* Input for Expiry Date */}
        <div className="input-wrapper">
          <label className="font_s font_grey">Expiry Date</label>
          <input
            className="bg_black"
            placeholder="MM/YY"
            {...getExpiryDateProps({ onChange: handleChangeExpiryDate })}
            value={expiryDate}
          />

          {meta.touchedInputs.expiryDate && meta.erroredInputs.expiryDate ? (
            <p className="error font_xxs font_red">
              {meta.erroredInputs.expiryDate}
            </p>
          ) : isSubmitting ? (
            <p className="error font_xxs font_red">
              {meta.erroredInputs.expiryDate}
            </p>
          ) : (
            ""
          )}
        </div>

        {/* Input for CVC */}
        <div className="input-wrapper">
          <label className="font_s font_grey">CVC</label>
          <input
            className="bg_black"
            {...getCVCProps({ onChange: handleChangeCVC })}
            value={cvc}
          />

          {meta.touchedInputs.cvc && meta.erroredInputs.cvc ? (
            <p className="error font_xxs font_red">{meta.erroredInputs.cvc}</p>
          ) : isSubmitting ? (
            <p className="error font_xxs font_red">{meta.erroredInputs.cvc}</p>
          ) : (
            ""
          )}
        </div>
      </div>

      {/* Submit and cancel button */}
      <div className="button-wrapper">
        <CustomButton
          size="l"
          onClick={() => {
            onSubmit();
          }}
        >
          Save
        </CustomButton>
        <TextButton onClick={onCancel} size="l" className="fw_6 w_100 font_m">
          Cancel
        </TextButton>
      </div>

      {/* Modals */}
      {/* Error Modal */}
      <ErrorModal
        showModal={showErrorModal}
        description={message}
        title="Add Card Failed"
        onClose={() => {
          onModalClose();
        }}
      />

      {/* Success Modal */}
      <SuccessModal
        showModal={showSuccessModal}
        description={message}
        title="Card Added"
        onClose={() => {
          onModalClose();
        }}
      />
    </div>
  );
};
/* Component ends */

export default AddNewCard;
