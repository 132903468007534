/**
 * This component is a Modal that is shown on success
 */

import React from "react";
import { useState, useRef } from "react";
/* Import react bootstrap component starts */
import { Modal } from "react-bootstrap";
import { Formik } from "formik";
import { validations, addMore, remove } from "./helper";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomTextArea from "../../../ui/input/CustomTextArea/CustomTextArea";
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
import CustomFileInput from "../../../ui/input/CustomFileInput/CustomFileInput";
import TextButton from "../../../ui/button/TextButton/TextButton";
/* Import local pages and component ends */

import "./style.scss";
import { getUserTheme } from "../../../../setup/store/slices/authSlice";
import { useSelector } from "react-redux";

/* Component starts */
const OnboardKeyRejectionModal = (props) => {
  /* Props destructuring starts */
  const {
    showModal,
    size = "lg",
    onClose,
    onReject,
    closeOnSubmit = true,
    title = "Reject Key Collection",
    attachmentRequired = true,
  } = props;
  /* Props destructuring ends */
  const [initialFormValues, setInitialFormValues] = useState({
    reason: "",
    document_0: null,
  });
  const [docList, setDocList] = useState([0]);
  const formRef = useRef(null);

  const userTheme = useSelector(getUserTheme);

  const onSubmit = (values) => {
    if (attachmentRequired && docList.length === 0) return false;
    const document = [];

    docList.forEach((item, index) => {
      document.push(values[`document_${item}`]);
    });

    onReject({ reason: values?.reason, document });
    closeOnSubmit && onClose();
  };

  const handleSubmit_outsideFormikContext = () => {
    if (attachmentRequired && docList.length === 0) return false;
    if (formRef.current) {
      const { setTouched, values, handleSubmit } = formRef.current;
      const touchedFields = Object.keys(values).reduce((acc, key) => {
        acc[key] = true;
        return acc;
      }, {});
      setTouched(touchedFields);
      handleSubmit();
    }
  };
  /* ************* Component rendering. JSX code ************* */
  return (
    <Modal
      size={size}
      centered
      show={showModal}
      className={`onboard-key-rejection-modal-wrapper `}
    >
      <Modal.Body
        className={`${userTheme === "dark" ? "bg_darkTheme" : "bg_lightTheme"}`}
      >
        <div className="modal-header-custom">
          <h2 className="text-center font_blue">{title}</h2>
        </div>
        <div className="modal-body-custom">
          <div className="form-wrapper">
            <Formik
              onSubmit={onSubmit}
              initialValues={initialFormValues}
              validate={(values) => validations(values, docList)}
              validateOnBlur={true}
              innerRef={(f) => (formRef.current = f)}
              enableReinitialize={true}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                setFieldTouched,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="form-group form-group-full">
                    <CustomTextArea
                      name="reason"
                      label="Rejection Reason"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched[`reason`]}
                      errors={errors[`reason`]}
                      value={values[`reason`]}
                      required={true}
                    />
                  </div>

                  <h3 className="attachment-heading font_l font_white fw_6">
                    Photos or Videos
                  </h3>

                  {docList.length === 0 && (
                    <div className="no-attacthments-added">
                      <p className="font_s font_yellow fw_5 no-attacthments-added-text text-center">
                        {attachmentRequired
                          ? "Please add atleast one photo or video !"
                          : "No photos or videos added"}
                      </p>

                      <div className="action-btn text-center">
                        <TextButton
                          className="font_l fw_6"
                          onClick={() =>
                            addMore(
                              formRef,
                              setInitialFormValues,
                              docList,
                              setDocList
                            )
                          }
                        >
                          Add
                        </TextButton>
                      </div>
                    </div>
                  )}

                  {docList.map((item, index) => (
                    <div className="form-group file-input">
                      <div className="form-control-wrapper">
                        <CustomFileInput
                          name={`document_${item}`}
                          label="Photo/Video"
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldTouched}
                          formikState={true}
                          touched={touched[`document_${item}`]}
                          errors={errors[`document_${item}`]}
                          value={values[`document_${item}`]}
                          required={true}
                          acceptable={true}
                          acceptType="image/*,video/*"
                        />
                      </div>
                      <div className="action-btn">
                        {docList.length > 0 && (
                          <TextButton
                            className="font_m fw_6"
                            variant="red"
                            onClick={() =>
                              remove(
                                formRef,
                                setInitialFormValues,
                                docList,
                                setDocList,
                                index
                              )
                            }
                          >
                            Remove
                          </TextButton>
                        )}

                        {item === docList[docList.length - 1] && (
                          <TextButton
                            className="font_m fw_6"
                            onClick={() =>
                              addMore(
                                formRef,
                                setInitialFormValues,
                                docList,
                                setDocList
                              )
                            }
                          >
                            Add
                          </TextButton>
                        )}
                      </div>
                    </div>
                  ))}
                </form>
              )}
            </Formik>
          </div>
        </div>

        <div className="modal-footer-custom">
          <CustomButton onClick={handleSubmit_outsideFormikContext} size="m">
            Reject
          </CustomButton>
          <CustomButton onClick={onClose} size="m" variant="red">
            Close
          </CustomButton>
        </div>
      </Modal.Body>
    </Modal>
  );
};
/* Component ends */

export default OnboardKeyRejectionModal;
