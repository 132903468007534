/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import GLOBAL from "../../../setup/constants/global";
/* Import configuration ends */

/* Import redux slices component starts */
import { sendOtp } from "../../../setup/store/slices/otpSlice";
import { deleteUnit } from "../../../setup/store/slices/unitSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { DeleteRed } from "../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../../components/ui/button/IconButton/IconButton";
import SuccessModal from "../../../components/ui/modal/SuccessModal/SuccessModal";
import ErrorModal from "../../../components/ui/modal/ErrorModal/ErrorModal";
import ConfirmationModal from "../../../components/ui/modal/ConfirmationModal/ConfirmationModal";
import ConfirmationOTPModal from "../../../components/ui/modal/ConfirmationOTPModal/ConfirmationOTPModal";

/* Import local pages and component ends */

/* Component starts */
const Header = (props) => {
  /* Props destructuring starts */
  const { unit } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [otpId, setOtpId] = useState();
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [message, setMessage] = useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { propertyId } = useParams();
  // Redux Selector

  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  const onConfirmDelete = async () => {
    // Backend response. Try, catch
    try {
      const result = await dispatch(
        sendOtp({
          action: "delete_unit",
          resource_name: unit.unit_number,
        })
      );
      setShowDeleteConfirmationModal(false);
      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setMessage(result.payload);
          setShowErrorModal(true);
          break;

        case GLOBAL.FULFILLED:
          setShowOtpModal(true);
          setOtpId(result.payload.otp._id);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // On verifying otp for deleting unit
  const onVerifyOTP = async (otp) => {
    if (otp.toString().length !== 6) return;

    // Backend response. Try, catch
    try {
      const result = await dispatch(
        deleteUnit({
          id: unit._id,
          otp: otp,
          otpId: otpId,
          property_id: propertyId,
        })
      );

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setShowOtpModal(false);
          setMessage(result.payload);
          setShowErrorModal(true);
          break;
        case GLOBAL.FULFILLED:
          setShowOtpModal(false);
          setMessage("Unit has been successfully deleted");
          setShowSuccessModal(true);
          navigate(-2);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      <div className="header">
        <div className=""></div>
        {unit?.status === "vacant" && (
          <IconButton
            onClick={() => {
              if (unit?.status !== "vacant") {
                setMessage("Cannot delete reserved or occupied unit.");
                setShowErrorModal(true);
                return;
              }
              setShowDeleteConfirmationModal(true);
            }}
            className="option-button"
          >
            <img src={DeleteRed} className="w_100 d_block" alt="Delete Icon" />
            <span className="font_s d_block font_red fw_5">Delete</span>
          </IconButton>
        )}

        {/* Modals */}
        {/* Modals */}
        {/* Confirmation OTP Modal */}
        <ConfirmationOTPModal
          showModal={showOtpModal}
          title="Enter OTP"
          description="Enter six digits confirmation code sent to your registered email"
          onCancel={() => setShowOtpModal(false)}
          onVerify={onVerifyOTP}
        />

        {/* Success Modal */}
        <SuccessModal
          showModal={showSuccessModal}
          description={message}
          title="Success"
          onClose={() => navigate(-1)}
        />

        {/* Confirmation Modal */}
        <ConfirmationModal
          showModal={showDeleteConfirmationModal}
          title="Confirm Delete"
          description="Are you sure you want to delete this unit ?"
          onClose={() => setShowDeleteConfirmationModal(false)}
          onConfirm={onConfirmDelete}
        />

        {/* Error Modal */}
        <ErrorModal
          showModal={showErrorModal}
          description={message}
          title="Error"
          onClose={() => setShowErrorModal(false)}
        />
      </div>
    </>
  );
};
/* Component ends */

export default Header;
