/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import GLOBAL from "../../../../../setup/constants/global";
import { useDispatch } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import { rejectTenantApplicationData } from "../../../../../setup/store/slices/tenantSlice";
import { acceptContractRenewalApplication } from "../../../../../setup/store/slices/contractSlice";
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../../../setup/store/slices/globalAlertSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomButton from "../../../../ui/button/CustomButton/CustomButton";
/* Import local pages and component ends */

/* Component starts */
const RenewalFinalStep = (props) => {
  /* Props destructuring starts */
  const {
    contract_id,
    application_id,
    can_resend_for_review,
    fetchContractDetails,
    can_approve_application,
    error_message,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const acceptContractRenewal = async () => {
    // Creating or collecting payload data to be sent
    const payload = { contract_id };

    // Backend response. Try, Catch
    try {
      const result = await dispatch(acceptContractRenewalApplication(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description:
                typeof result?.payload === "string" ? result?.payload : "",
            })
          );
          break;

        case GLOBAL.FULFILLED:
          fetchContractDetails();
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description:
                typeof result?.payload?.message === "string"
                  ? result?.payload?.message
                  : "",
            })
          );
          break;
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const rejectApplicationData = async () => {
    // Creating or collecting payload data to be sent
    const payload = { application_id };

    // Backend response. Try, Catch
    try {
      const result = await dispatch(rejectTenantApplicationData(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description:
                typeof result?.payload === "string" ? result?.payload : "",
            })
          );
          break;

        case GLOBAL.FULFILLED:
          fetchContractDetails();
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description:
                typeof result?.payload?.message === "string"
                  ? result?.payload?.message
                  : "",
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  const handleContractRenew = () => {
    if (can_approve_application) {
      acceptContractRenewal();
    } else {
      dispatch(
        showErrorAlert({
          title: "Error!",
          description: typeof error_message === "string" ? error_message : "",
        })
      );
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="renewal-final-step-wrapper">
      <CustomButton
        className={`${can_resend_for_review ? "" : "btn_full"}`}
        onClick={handleContractRenew}
        size="l"
      >
        Renew Contract
      </CustomButton>

      {can_resend_for_review && (
        <CustomButton
          className={``}
          onClick={rejectApplicationData}
          size="l"
          variant="yellow"
        >
          {/* Send For Review */}
          Send for Revision
        </CustomButton>
      )}
    </div>
  );
};
/* Component ends */

export default RenewalFinalStep;
