/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useNavigate } from "react-router-dom";
import moment from "moment";
import GLOBAL from "../../../../../setup/constants/global";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Table } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import {
  EyeFilledBlue,
  ProfileFilledGrey,
} from "../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../../../ui/button/IconButton/IconButton";
import Pagination from "../../../../ui/other/Pagination/Pagination";
/* Import local pages and component ends */

/* Component starts */
const Renewals = (props) => {
  /* Props destructuring starts */
  const { list, totalCount, currentPage, setCurrentPage } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */
  console.log(list);
  /* Other hooks declaration starts */
  const navigate = useNavigate();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const renderStatus = (item) => {
    let status;
    let statusColor;
    switch (item.status) {
      case "renewal_pending_approval":
        status = "Pending Approval";
        statusColor = "font_yellow";
        break;
      case "renewed":
        status = "Renewed";
        statusColor = "font_yellow";
        break;
      case "pending_tenant_approval":
      case "renewal_pending_approval":
        status = "Pending Tenant Approval";
        statusColor = "font_yellow";
        break;
      case "pending_revision_approval":
        status = "Tenant Requested Revision";
        statusColor = "font_yellow";
        break;
      case "pending_contract_dispatch":
        status = "Pending Contract";
        statusColor = "font_yellow";
        break;
      case "pending_tenant_signature":
        status = "Pending Contract Signature";
        statusColor = "font_yellow";
        break;
      case "pending_tenant_info":
        status = "Pending Tenant Information";
        statusColor = "font_yellow";
        break;

      case "pending-contract-review":
        status = "Pending Contract Review";
        statusColor = "font_yellow";
        break;
    }

    return { status, statusColor };
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      <div className="custom-table-wrapper renewal-table">
        {list?.contracts?.length > 0 && (
          <Table className="custom-table font_s" borderless responsive>
            {/* Table Heading */}
            <thead>
              <tr>
                <th>Tenant Name</th>
                <th>Unit No.</th>
                <th>Type Of Unit</th>
                <th>Contract Expiry Date</th>
                <th>No. Day Expiry</th>
                <th>Status</th>
                <th className="text-center">Action</th>
              </tr>
            </thead>
            {/* Table Body */}
            <tbody>
              {list?.contracts?.map((item) => (
                <tr key={item._id}>
                  {/* Tenant Name */}
                  <td>{item?.tenant}</td>

                  {/* Unit NUmber */}
                  <td>{item?.unit_name}</td>

                  {/* Unit Type */}
                  <td>{item?.unit_type}</td>

                  {/* Contract Expiry Date */}
                  <td>{moment(item.end_date).format(DATE_FORMAT)}</td>

                  {/* No. Of Day Expiry */}
                  <td>
                    {item.status === "expired"
                      ? "-"
                      : moment(item.end_date).diff(moment(), "day")}
                  </td>

                  {/* Status */}
                  <td className={`${renderStatus(item)?.statusColor}`}>
                    {renderStatus(item)?.status}
                  </td>

                  {/* Actions */}
                  <td className="text-center txt-nowrap">
                    {/* <IconButton
                      // onClick={() =>
                      // navigate()
                      // }
                      className="action-button"
                    >
                      <img
                        src={ProfileFilledGrey}
                        className="h_100"
                        alt="View Details"
                      />
                    </IconButton> */}

                    <IconButton
                      onClick={() =>
                        navigate(
                          `${item?.tenant_application}?tenant_id=${item.tenant_id}`
                        )
                      }
                      className="action-button"
                    >
                      <img
                        src={EyeFilledBlue}
                        className="h_100"
                        alt="View Details"
                      />
                    </IconButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}

        {list?.contracts?.length === 0 && (
          <div className=" text-center">
            <h3 className=" font_m font_yellow fw_6">No Data</h3>
          </div>
        )}
      </div>

      {/* Pagination */}
      {list?.contracts?.length > 0 && (
        <Pagination
          itemsPerPage="10"
          totalItems={totalCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
    </>
  );
};
/* Component ends */

export default Renewals;
