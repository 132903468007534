/**
 *
 */

import React, { useState } from "react";

/* Import configuration starts */
import moment from "moment";
import { useDispatch } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */

/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import { logTitle } from "../helper";
/* Import local pages and component ends */

/* Component starts */
const TenantLog = (props) => {
  /* Props destructuring starts */
  const { complaint } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */

  const name = complaint?.user
    ? `${complaint?.user?.first_name} ${complaint?.user?.last_name}`
    : `${complaint.tenant.first_name} ${complaint.tenant.last_name}`;
  const role = complaint?.user?.role_id?.name;
  const createdAt = complaint?.createdAt;
  const comment = complaint?.comment;
  //   const reportData = complaint?.data;

  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="time-line simple-log">
      {/* Dot */}

      <div className="dot-wrapper">
        <i className="dot"></i>
        <div className="vl"></div>
      </div>

      <div className="detail-wrapper">
        {/* Title */}
        <h3 className="title font_m fw_6">{logTitle(complaint)}</h3>

        {/* Technician Name and Date */}
        <div className="black-container-sm fw_5 font_s bg_black flex-between">
          {/* Name */}
          <p className="left">
            <span className="font_grey">{role} </span>
            <span className="font_white"> {name}</span>
          </p>

          {/* Date and Time */}
          <p className="right font_grey">
            {moment(createdAt).format(DATE_TIME_FORMAT)}
          </p>
        </div>
        {/* Log Footer */}
        <div className="log-footer">
          {/* Comments */}
          {comment && (
            <p className="comments font_s fw-5">
              <span className="font_grey fw_6">Comments: </span>
              <span className="fw_5 font_red"> {comment}</span>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
/* Component ends */

export default TenantLog;
