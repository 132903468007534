/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import DetailSection1 from "./DetailSection1";
import DetailSection2 from "./DetailSection2";
import DetailSection3 from "./DetailSection3";
import DetailSection4 from "./DetailSection4";
import DetailSection5 from "./DetailSection5";
import DetailSection8 from "./DetailSection8";
/* Import local pages and component ends */

/* Component starts */
const DetailCard = (props) => {
  /* Props destructuring starts */
  const { type, maintenance, mgmtModel, isT1, isT2, isT3, status } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const uircInit = maintenance?.initial_URIC;
  const uircFinl = maintenance?.final_URIC;
  // const initAttachments = uircInit?.
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="detail-card bg_grey">
      {/* Section 1 */}
      <DetailSection1 type={type} maintenance={maintenance} />

      <hr className="section-divider" />

      {/* Section 2 */}
      {/* <DetailSection2 type={type} maintenance={maintenance} /> */}

      {/* <hr className="section-divider" /> */}

      {/* Section 3 (Status) */}
      <DetailSection3 status={status} />

      <hr className="section-divider" />

      {/* Section 4 (Documents) */}
      {/* {!isT1 && (
        <>
          <DetailSection4 />
          <hr className="section-divider" />
        </>
      )} */}

      {/* Section 5 (Ticket Assigned) */}
      {/* <DetailSection5 isT1={isT1} maintenance={maintenance} /> */}

      {/* <hr className="section-divider" /> */}

      {/* Section 6 */}
      {/* <div className="section pair-section section6 font_s fw_6"></div> */}

      {/* <hr className="section-divider" /> */}

      {/* Section 7 */}
      {/* <div className="section pair-section section7 font_s fw_6"></div> */}

      {/* <hr className="section-divider" /> */}

      {/* Section 8 */}
      <DetailSection8 maintenance={maintenance} />
    </div>
  );
};
/* Component ends */

export default DetailCard;
