/**
 *
 */

import React, { useRef } from "react";

/* Import configuration starts */
import { Formik } from "formik";
import { validEmail } from "../../../../setup/utils/validations";
import { useNavigate } from "react-router-dom";
/* Import configuration ends */

/* Import react bootstrap component starts */
import { Modal } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
import CustomTextInput from "../../../ui/input/CustomTextInput/CustomTextInput";
import TextButton from "../../../ui/button/TextButton/TextButton";
/* Import local pages and component ends */

import "./style.scss";
import { getUserTheme } from "../../../../setup/store/slices/authSlice";
import { useSelector } from "react-redux";

/* Component starts */
const ForgotPasswordModal = (props) => {
  /* Props destructuring starts */
  const {
    showModal,
    onResend = null,
    description = "Please enter registered mobile number or email address, to change password.",
    title = "Forgot Password?",
    size = "lg",
    onClose,
    callBack = null,
    closeOnSubmit = true,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  const userTheme = useSelector(getUserTheme);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const initialValues = {
    username: "",
  };
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const errorMsgForEmptyFields = (fieldName) => {
    switch (fieldName) {
      case "username":
        return "Email is required";
      default:
        return "Invalid Input";
    }
  };

  // Input fields that are required are validated
  const requiredFieldsValidation = (errorFields, values) => {
    Object.keys(values).map((key) => {
      if (values[key] === "") {
        errorFields[key] = errorMsgForEmptyFields(key);
      }
    });
    return errorFields;
  };

  // Other form validations logics
  const otherFieldValidations = (errorFields, values) => {
    /* Validation for username starts */
    if (isNaN(+values.username) && !validEmail(values.username)) {
      errorFields.username = "Email is not valid";
    }
    /* Validation for username ends */
  };

  const formValidation = (values) => {
    const errorFields = {};
    requiredFieldsValidation(errorFields, values);
    otherFieldValidations(errorFields, values);
    return errorFields;
  };

  // Handling Form submission
  const onSend = (values) => {
    if (callBack) {
      callBack(values.username);
    } else {
      navigate("/password/forgot", {
        state: values.username.toLowerCase(),
      });
    }
    closeOnSubmit && onClose();
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <Modal
      className="forgot-password-modal"
      size={size}
      centered
      show={showModal}
    >
      <Modal.Body
        className={`${userTheme === "dark" ? "bg_darkTheme" : "bg_lightTheme"}`}
      >
        <div className="modal-header-custom">
          <h2 className="text-center font_blue">{title}</h2>
        </div>
        <div className="modal-body-custom">
          <p className="description font_yellow text-center font_m">
            {description}
          </p>
          {/* Form */}
          <Formik
            onSubmit={onSend}
            initialValues={initialValues}
            validate={formValidation}
            validateOnBlur={true}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <div
                  //className="form-group"
                  // ${
                  //   (userTheme === "light" || userTheme === "automatic") &&
                  //   "otherTheme"
                  // }
                  className={`form-group otherTheme `}
                >
                  <CustomTextInput
                    name="username"
                    type="text"
                    label="Email / Phone Number"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    value={values.username}
                    touched={touched.username}
                    errors={errors.username}
                  />
                </div>

                <div className="button-wrapper">
                  {onResend && (
                    <div className="resend-wrapper text-end">
                      <TextButton onClick={onResend} className="font_m">
                        Resend
                      </TextButton>
                    </div>
                  )}

                  <CustomButton type="submit" size="m">
                    Send
                  </CustomButton>

                  <CustomButton
                    onClick={onClose}
                    type="button"
                    size="m"
                    variant="outline-red"
                  >
                    Close
                  </CustomButton>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </Modal.Body>
    </Modal>
  );
};
/* Component ends */

export default ForgotPasswordModal;
