export const initialValues = {
  community_name: "",
  address_line1: "",
  address_line2: "",
  description: "",
  community_title_deed: "",
  community_permit: "",
  plot_number: "",
  affection_plan: "",
  telecommunication: "",
  electricity_id: "",
  sewerage_id: "",
  water_id: "",
  gas_id: "",
  colling_id: "",
  heating_id: "",
};

export const loadInitialValue = (community) => {
  return {
    community_name: community?.community_name || "",
    address_line1: community?.address_line1 || "",
    address_line2: community?.address_line2 || "",
    telecommunication: community?.telecommunication || "",
    description: community?.description || "",
    community_title_deed: community?.community_title_deed?.url || "",
    community_permit: community?.community_permit || "",
    plot_number: community?.plot_number || "",
    affection_plan: community?.affection_plan?.url || "",
    electricity_id: community?.electricity_id || "",
    sewerage_id: community?.sewerage_id || "",
    water_id: community?.water_id || "",
    gas_id: community?.gas_id || "",
    colling_id: community?.colling_id || "",
    heating_id: community?.heating_id || "",
  };
};

export const validations = (
  values,
  customPhoneNum,
  contract_copy_img,
  profile_img
) => {
  const errorFields = {};

  return errorFields;
};
