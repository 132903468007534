/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import GLOBAL from "../../../../../setup/constants/global";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { transitionedAction } from "../helper";
import { useSelector, useDispatch } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import { showWarningAlert } from "../../../../../setup/store/slices/globalAlertSlice";
import { getIsACC } from "../../../../../setup/store/slices/authSlice";
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../../../setup/store/slices/globalAlertSlice";
import {
  cancelTenantInvitation,
  resendTenantInvitation,
} from "../../../../../setup/store/slices/tenantSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Table } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import {
  EyeFilledBlue,
  ProfileFilledGrey,
} from "../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../../../ui/button/IconButton/IconButton";
import Pagination from "../../../../ui/other/Pagination/Pagination";
import TextButton from "../../../../ui/button/TextButton/TextButton";
import ConfirmationModal from "../../../../ui/modal/ConfirmationModal/ConfirmationModal";
/* Import local pages and component ends */

/* Component starts */
const TransitionedTenants = (props) => {
  /* Props destructuring starts */
  const {
    list,
    totalCount,
    currentPage,
    setCurrentPage,
    refreshTransitionedTenant,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [action, setAction] = useState();
  const [selectedItem, setSelectedItem] = useState();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [modalText, setModalText] = useState({ title: "", description: "" });
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAcc = useSelector(getIsACC);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const onAction = async () => {
    setShowConfirmation(false);
    // Backend response. Try, Catch
    try {
      let result;
      if (action === "resend") {
        result = await dispatch(resendTenantInvitation(selectedItem));
      } else {
        result = await dispatch(cancelTenantInvitation(selectedItem));
      }
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description:
                typeof result?.payload === "string" ? result?.payload : "",
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description:
                typeof result?.payload?.message === "string"
                  ? result?.payload?.message
                  : "",
            })
          );
          refreshTransitionedTenant();
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      <div className="custom-table-wrapper transitioned-table">
        {list?.length > 0 && (
          <Table className="custom-table font_s" borderless responsive>
            {/* Table Heading */}
            <thead>
              <tr>
                <th>Tenant Name</th>
                <th>Unit Name</th>
                <th>Type Of Unit</th>
                <th>Application Date & Time</th>
                <th>Status</th>
                <th className="text-center">Action</th>
              </tr>
            </thead>
            {/* Table Body */}
            <tbody>
              {list?.map((item, index) => (
                <tr key={index}>
                  {/* Tenant Name */}
                  <td>{item?.tenant !== undefined ? item?.tenant : "-"}</td>

                  {/* Unit Name */}
                  <td>
                    {item?.unit_name !== undefined ? item?.unit_name : "-"}
                  </td>

                  {/* Unit Type */}
                  <td>
                    {item?.unit_type !== undefined ? item?.unit_type : "-"}
                  </td>

                  {/* Application Date & Time */}
                  <td>{moment(item?.date_time).format(DATE_TIME_FORMAT)}</td>

                  {/* Subscription */}
                  <td>
                    <p className={transitionedAction(item)?.color}>
                      {transitionedAction(item)?.status}
                    </p>
                  </td>

                  {/* Actions */}
                  <td className="text-center txt-nowrap">
                    <IconButton
                      onClick={() => {
                        if (transitionedAction(item)?.canReview) {
                          navigate(`${item?._id}`);
                        }
                      }}
                      className="action-button"
                    >
                      <img
                        src={EyeFilledBlue}
                        className="h_100"
                        alt="View Details"
                      />
                    </IconButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}

        {list?.length === 0 && (
          <div className=" text-center">
            <h3 className=" font_m font_yellow fw_6">No Data</h3>
          </div>
        )}

        <ConfirmationModal
          showModal={showConfirmation}
          description={modalText?.description}
          title={modalText.title}
          onClose={() => setShowConfirmation(false)}
          onConfirm={onAction}
        />
      </div>

      {/* Pagination */}
      {list?.length > 0 && (
        <Pagination
          itemsPerPage="10"
          totalItems={totalCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
    </>
  );
};
/* Component ends */

export default TransitionedTenants;
