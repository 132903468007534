/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import moment from "moment";
import { useNavigate } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomButton from "../../../../components/ui/button/CustomButton/CustomButton";
import ClaimedModal from "../../../../components/module/LostAndFound/ClientLostAndFoundModule/ClaimedModal/ClaimedModal";
import GLOBAL from "../../../../setup/constants/global";

import FileViewerModal from "../../../../components/ui/modal/FileViewerModal/FileViewerModal";

/* Import local pages and component ends */

/* Component starts */

const Report = (props) => {
  /* Props destructuring starts */
  const { incident } = props;
  console.log(incident, "INC");
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [showModal, setShowModal] = useState(false);

  const [showFileViewer, setShowFileViewer] = useState(false);
  const [file, setFile] = useState({});
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  const onClose = () => {
    setShowModal(!showModal);
  };

  const onSuccess = () => {
    setShowModal(!showModal);
    navigate(-1);
  };

  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="unit-detail-box">
      <p className="num fw_4 font_m font_grey">
        {" "}
        Ticket No. :-{" "}
        <span className="font_blue fw_6">{incident?.lostfound_id}</span>{" "}
      </p>
      <h3 className="num fw_6 font_l"> {incident?.item_name} </h3>
      <p className="title fw_4 font_m">{incident?.item_description}</p>

      <div className="report-date">
        <p className="font_grey">
          <span className="fw_4 font_m">{`Date : ${moment(
            incident?.createdAt
          ).format(DATE_FORMAT)}`}</span>
        </p>
        <p className=" font_grey">
          <span className="fw_4 font_m">{`Time : ${moment(
            incident?.createdAt
          ).format(TIME_FORMAT)}`}</span>
        </p>
      </div>

      <hr />

      <div className="date-wrapper">
        <div className="detail">
          <p className="fw_4">
            Found Date <br />{" "}
            <span>{moment(incident?.date_time).format(DATE_FORMAT)}</span>
          </p>
        </div>
        <div className="detail">
          <p className="fw_4">
            Found Time <br />{" "}
            <span>{moment(incident?.date_time).format(TIME_FORMAT)}</span>
          </p>
        </div>
        <div className="detail">
          <p className="fw_4">
            Location
            <br />
            <span>{incident?.location}</span>
          </p>
        </div>
      </div>

      <hr />

      {incident.files.length > 0 ? (
        <div className="image-wrapper">
          {incident?.files.map((image, index) => (
            <div className="proof-img" key={index}>
              <img
                src={image.url}
                alt="ID-Proof"
                onClick={() => {
                  setFile(image);
                  setShowFileViewer(true);
                }}
              />
            </div>
          ))}
        </div>
      ) : (
        <div className="image-wrapper">
          <div className="proof-img undefined-img">
            <img
              src={
                "https://icons.veryicon.com/png/o/transport/transportation-project/lost-and-found.png"
              }
              alt="ID-Proof"
            />
          </div>
        </div>
      )}

      <hr />

      <div className="foundBy-wrapper">
        <p className="fw_4 font_m">
          Found By <span className="fw_6 font_m">{incident?.found_by}</span>
        </p>
      </div>

      <hr />

      <div className="ticket-wrapper">
        <p className="fw_4 font_m">
          Created By{" "}
          <span className="fw_6 font_m">
            {incident?.created_by?.first_name} {incident?.created_by?.last_name}
          </span>
        </p>
      </div>

      <div>
        <CustomButton size="l" onClick={() => setShowModal(!showModal)}>
          Claim
        </CustomButton>
      </div>

      {/* MODALS */}

      <ClaimedModal
        showModal={showModal}
        onClose={onClose}
        onSuccess={onSuccess}
        id={incident?._id}
        itemId={incident?.lostfound_id}
        type={incident?.type}
      />

      {/* File viewer modal */}
      <FileViewerModal
        show={showFileViewer}
        type={file.type}
        file={file.url}
        onClose={() => {
          setShowFileViewer(false);
          setFile({});
        }}
      />
    </div>
  );
};
/* Component Ends */
export default Report;
