/**
 * Slice to fetch countries, states, and cities
 * @author Abdul Ahad <abdulahad.ss@smartdatainc.net>
 *
 */

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { axiosInstance } from "../axios";
import { API_URL } from "../../config/devKeys";
import { PLATFORM } from "../../platformUtil";
import {
  setLoading,
  setShowUploadProgress,
  setUploadProgress,
} from "./unpersistedSlice";
import { fetchTenantApplicationById } from "./tenantSlice";

const initialState = {};

export const updateChequeDetails = createAsyncThunk(
  "tenant/attach_cheque_to_installment_plan",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { application_id } = payload;
      dispatch(setLoading(true));
      const formData = new FormData();
      Object.keys(payload).map((key) => {
        if (key == "cheque_doc") {
          if (PLATFORM !== "web") {
            formData.append("image", {
              name: payload.image.name,
              type: payload.image.type,
              uri:
                PLATFORM === "android"
                  ? payload.image.uri
                  : payload.image.uri.replace("file://", ""),
            });
          } else {
            formData.append("cheque_doc", payload[key], payload[key].name);
          }
        } else {
          formData.append(key, payload[key]);
        }
      });
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }
      // const response = await fetch(
      //   API_URL + "/tenant/attach_cheque_to_installment_plan",
      //   {
      //     method: "POST",
      //     headers: headers,
      //     body: formData,
      //   }
      // );
      // const data = await response.json();
      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "POST",
        url: "/tenant/attach_cheque_to_installment_plan",
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      // const data = await response.json();

      const { data } = await axiosInstance.request(config);

      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      console.error(error.response);
      return rejectWithValue(error.message);
    }
  }
);

export const chequeSlice = createSlice({
  name: "cheque",
  initialState,
  reducers: {},
});

export default chequeSlice.reducer;
