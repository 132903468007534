/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import Variant2 from "./variants/Variant2";
import Variant1 from "./variants/Variant1";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const CustomDateTimeInput = (props) => {
  /* Props destructuring starts */
  const {
    className,
    label,
    value, // useState value / Formik State
    setValue = null,
    setFieldValue = null, // setFieldValue formik function
    customOnchange = null,
    name,
    refValue = null, // If useRef used
    minDate = true,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return <Variant2 {...props} />;
};
/* Component ends */

export default CustomDateTimeInput;
