/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import GLOBAL from "../../../setup/constants/global";
import { formatCurrency } from "../../../setup/utils/global-helper";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import MUI component starts */
/* Import MUI component ends */

/* Import image and SVG starts */
import { DocumentGreen } from "../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../../components/ui/button/IconButton/IconButton";
import FileViewerModal from "../../../components/ui/modal/FileViewerModal/FileViewerModal";
/* Import local pages and component ends */

/* Component starts */
const DetailBox = (props) => {
  /* Props destructuring starts */
  const { unit, property } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [isResidential, setIsResidential] = useState(false);
  const [showFileViwer, setShowFileViwer] = useState(false);
  const [file, setFile] = useState({});
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    if (unit?.property_type.length > 0) {
      setIsResidential(
        unit?.property_type[0]?.name === GLOBAL.PROPERTY_TYPE.RESIDENTIAL
      );
    }
  }, [unit]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="unit-detail-box">
      <h3 className="num fw_6 font_l">
        #{unit?.unit_number}{" "}
        {unit?.is_active ? (
          <span className="font_s font_blue">(Active)</span>
        ) : (
          <span className="font_s font_grey">(Inactive)</span>
        )}
      </h3>
      <h3 className="title fw_6 font_l">Unit Details</h3>
      <div className="details-content">
        {/* Unit Name */}
        {unit?.unit_name && (
          <p className="font_m fw_5">
            <span className="font_grey">Unit Name : </span>
            {unit?.unit_name}
          </p>
        )}

        {/* Unit Type */}
        {unit?.unit_type?.name && (
          <p className="font_m fw_5">
            <span className="font_grey">Unit Type : </span>
            {unit?.unit_type?.name}
          </p>
        )}

        {/* Unit Floor */}
        {unit?.unit_floor && (
          <p className="font_m fw_5">
            <span className="font_grey">Unit Floor : </span>
            {unit?.unit_floor}
          </p>
        )}

        {/* Unit Floor Plan */}
        {unit?.floor_plan?.url && (
          <p className="font_m fw_5">
            <span className="font_grey v_middle">Unit Floor Plan : </span>
            <IconButton
              className="doc-icon v_middle"
              onClick={() => {
                setFile(unit?.floor_plan);
                setShowFileViwer(true);
              }}
            >
              <img src={DocumentGreen} className="h_100" alt="Floor Plan" />
            </IconButton>
          </p>
        )}

        {/* Unit Status */}
        {unit?.status && (
          <p className="font_m fw_5">
            <span className="font_grey">Unit Status : </span>
            {unit?.status.toUpperCase()}
          </p>
        )}

        {/* Unit Size */}
        {unit?.unit_size && (
          <p className="font_m fw_5">
            <span className="font_grey">Unit Size : </span>
            {unit?.unit_size} {unit?.size_measurement}
          </p>
        )}

        {/* Unit Rent Price */}
        {unit?.target_rent_price && (
          <p className="font_m fw_5">
            <span className="font_grey">Target Rent Price : </span>
            {formatCurrency(
              unit?.target_rent_price.toString(),
              property?.currency
            )}
          </p>
        )}

        {/* Toilet */}
        {unit?.toilet > 0 && (
          <p className="font_m fw_5">
            <span className="font_grey">Toilet : </span>
            {unit?.toilet}
          </p>
        )}

        {/* Store */}
        {unit?.store > 0 && (
          <p className="font_m fw_5">
            <span className="font_grey">Store : </span>
            {unit?.store}
          </p>
        )}

        {/* If Unit Type is Residential */}
        {isResidential && (
          <>
            {/* Kitchen */}
            {unit?.kitchen > 0 && (
              <p className="font_m fw_5">
                <span className="font_grey">Kitchen : </span>
                {unit?.kitchen}
              </p>
            )}

            {/* Bedroom */}
            {unit?.bedroom > 0 && (
              <p className="font_m fw_5">
                <span className="font_grey">Bedroom : </span>
                {unit?.bedroom}
              </p>
            )}

            {/* Bathroom */}
            {unit?.bathroom > 0 && (
              <p className="font_m fw_5">
                <span className="font_grey">Bathroom : </span>
                {unit?.bathroom}
              </p>
            )}

            {/* Living Room */}
            {unit?.living_room > 0 && (
              <p className="font_m fw_5">
                <span className="font_grey">Living Room : </span>
                {unit?.living_room}
              </p>
            )}

            {/* Maid's Room */}
            {unit?.maids_room > 0 && (
              <p className="font_m fw_5">
                <span className="font_grey">Maid's Room : </span>
                {unit?.maids_room}
              </p>
            )}

            {/* Balcony Room */}
            {unit?.balcony > 0 && (
              <p className="font_m fw_5">
                <span className="font_grey">Balcony : </span>
                {unit?.balcony}
              </p>
            )}

            {/* Laundry Room */}
            {unit?.laundry > 0 && (
              <p className="font_m fw_5">
                <span className="font_grey">Laundry : </span>
                {unit?.laundry}
              </p>
            )}
          </>
        )}

        {/* If Unit Type is Residential */}
        {!isResidential && (
          <>
            {/* Pantry */}
            {unit?.pantry > 0 && (
              <p className="font_m fw_5">
                <span className="font_grey">Pantry : </span>
                {unit?.pantry}
              </p>
            )}

            {/* Showroom */}
            {unit?.showroom > 0 && (
              <p className="font_m fw_5">
                <span className="font_grey">Showroom : </span>
                {unit?.showroom}
              </p>
            )}

            {/* Terrace */}
            {unit?.terrace > 0 && (
              <p className="font_m fw_5">
                <span className="font_grey">Terrace : </span>
                {unit?.terrace}
              </p>
            )}

            {/* Offices */}
            {unit?.offices > 0 && (
              <p className="font_m fw_5">
                <span className="font_grey">Offices : </span>
                {unit?.offices}
              </p>
            )}
          </>
        )}

        {/* Description */}
        {unit?.unit_description && (
          <p className="font_m fw_5 desc grid-full">
            <span className="font_grey">Description : </span>
            {unit?.unit_description}
          </p>
        )}

        {/* Note */}
        {unit?.note && (
          <p className="font_m fw_5 grid-full">
            <span className="font_grey">Note : </span>
            {unit?.note}
          </p>
        )}
      </div>

      {/* Modals */}
      {/* File Viewer Modal */}
      <FileViewerModal
        show={showFileViwer}
        file={file.url}
        type={file.type}
        onClose={() => {
          setFile({});
          setShowFileViwer(false);
        }}
      />
    </div>
  );
};
/* Component ends */

export default DetailBox;
