/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { ACTION_REQ_SECTION } from "../helper";
import { useNavigate } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import {
  ExcalmatoryYellow,
  WarningRoundedOutlineYellow,
} from "../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomButton from "../../../../../components/ui/button/CustomButton/CustomButton";
import ESignDocModal from "../../ESignDocModal/ESignDocModal";
import CancelApplicationBlock from "../../../Unit/UnitSliderTenantHome/sub-component/CancelApplicationBlock";
/* Import local pages and component ends */

/* Component starts */
const PendingFinalAgreementSign = (props) => {
  /* Props destructuring starts */
  const { unit, fetchTenantUnitsNew } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [finalAgreementModal, setFinalAgreementModal] = useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const onSignatureSuccess = () => {
    fetchTenantUnitsNew();
    setFinalAgreementModal(false);
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div>
      <div className="status-icon-wrapper text-center">
        <img src={ExcalmatoryYellow} alt="Warning" />
      </div>

      <h3 className="text-center fw_6 font_xxl font_blue mt-4">
        Action Required
      </h3>
      <p className="font_l fw_5 text-center mt-4">
        Action required in Final Agreement
      </p>

      <p className="font_l fw_5 text-center mt-4 font_yellow">
        {/* Pending Final Agreement Contract Sign */}
        It's important to carefully review the accompanying Final agreement
        before signing. Signing signifies your acceptance of the relation and
        all its associated terms and conditions.
      </p>

      <div className="button-wrapper">
        <CustomButton
          size="l"
          className="mt-5"
          onClick={() => {
            // navigate(
            //   `/tenant/unit/${unit?._id}/application/${unit.application_id}`,
            //   {
            //     state: {
            //       unit: unit,
            //     },
            //   }
            // );
            setFinalAgreementModal(true);
          }}
        >
          Take Action
        </CustomButton>
      </div>

      <ESignDocModal
        onCompleted={onSignatureSuccess}
        application_id={unit?.application_id}
        type={"final_agreement"}
        showModal={finalAgreementModal}
        onClose={() => setFinalAgreementModal(false)}
      />

      <CancelApplicationBlock
        unit={unit}
        fetchTenantUnitsNew={fetchTenantUnitsNew}
      />
    </div>
  );
};
/* Component ends */

export default PendingFinalAgreementSign;
