/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useDispatch } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import { fetchCommunities } from "../../../../setup/store/slices/communitySlice";
import { fetchCommunityProperties } from "../../../../setup/store/slices/propertySlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import MUI component starts */
/* Import MUI component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import PropertyLicenseSlider from "../PropertyLicenseSlider/PropertyLicenseSlider";
import TextButton from "../../../ui/button/TextButton/TextButton";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const CommunityPropertyLicenseSlider = (props) => {
  /* Props destructuring starts */
  const {
    user,
    properties,
    selectedCommunity,
    setShowCommunityProperties,
    update,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const onLicenseActionSuccess = async () => {
    await dispatch(fetchCommunityProperties(selectedCommunity._id));
    await dispatch(fetchCommunities());
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="community-property-slider-license-module-wrapper">
      <div className="community-name-button">
        <p className="community-name fw_6 font_xl m-0">
          {selectedCommunity?.community_name}
        </p>

        <TextButton
          className="font_s fw_6"
          onClick={() => setShowCommunityProperties(false)}
        >
          Back
        </TextButton>
      </div>

      {properties?.length > 0 ? (
        <>
          <PropertyLicenseSlider
            onLicenseActionSuccess={onLicenseActionSuccess}
            user={user}
            properties={properties}
            update={update}
          />
        </>
      ) : (
        <div className="no-comm-prop">
          <div className="center">
            <h3 className="lg_text fw_6 font_l text-center">
              No Properties have been added to this Community yet
            </h3>
            <p className="sm_text fw_5 font_s font_grey text-center">
              Please add properties under this community to Assign or Unassign
              Licenses
            </p>
          </div>
        </div>
      )}
    </div>
  );
};
/* Component ends */

export default CommunityPropertyLicenseSlider;
