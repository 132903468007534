import {
  validateAtleastOneNumber,
  validateAtleastOneSpecialChar,
  containsSpecialChar,
} from "../../setup/utils/validations";

export const initialValues = (user = null) => {
  return {
    first_name: user?.first_name || "",
    last_name: user?.last_name || "",
    business_name: user?.business_name || "",
    address_line1: user?.address_line1 || "",
    address_line2: user?.address_line2 || "",
    zipcode: user?.zipcode || "",
    email: user?.email || "",
    mobile: user?.mobile || "",
    nationality: user?.nationality || "",
    profession: user?.profession || "",
    gender: user?.gender || "",
    language: user?.language || "",
    countrycode: user?.countrycode || ""
  };
};

export const validations = (values, role) => {
  const errorFields = {};

  /* Validations for First Name Starts */
  if (values.first_name === "") {
    errorFields.first_name = "First Name is required";
  }
  if (values.first_name && validateAtleastOneNumber(values.first_name)) {
    errorFields.first_name = "First Name Cannot Contains Numbers";
  }
  if (values.first_name && containsSpecialChar(values.first_name)) {
    errorFields.first_name = "First Name Cannot Contains Special Characters";
  }
  /* Validations for First Name Ends */

  /* Validations for Last Name Starts */
  if (values.last_name === "") {
    errorFields.last_name = "Last Name is required";
  }
  if (values.last_name && validateAtleastOneNumber(values.last_name)) {
    errorFields.last_name = "Last Name Cannot Contains Numbers";
  }
  if (values.last_name && containsSpecialChar(values.last_name)) {
    errorFields.last_name = "Last Name Cannot Contains Special Characters";
  }
  /* Validations for Last Name Ends */

  /* Validations for Company Name Starts */
  if (values.business_name === "") {
    errorFields.business_name = "Company Name is required";
  }
  /* Validations for Company Name Ends */

  /* Validations for Address 1 Starts */
  if (values.address_line1 === "" && role !== "Tenant") {
    errorFields.address_line1 = "Address is required";
  }
  /* Validations for Address 1 Ends */

  /* Validations for Zip Code Starts */
  if (values.zipcode === "" && role !== "Tenant") {
    errorFields.zipcode = "Zipcode is required";
  }
  /* Validations for Zip Code Ends */

  return errorFields;
};
