/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { Modal } from "react-bootstrap";
/* Import configuration ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import MUI component starts */
/* Import MUI component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomButton from "../../button/CustomButton/CustomButton";
import { InfoOutlineBlue } from "../../../../setup/constants/images";
/* Import local pages and component ends */

import "./style.scss";
import { getUserTheme } from "../../../../setup/store/slices/authSlice";
import { useSelector } from "react-redux";

/* Component starts */
const InfoMessageModal = (props) => {
  /* Props destructuring starts */
  const {
    showModal,
    description,
    title,
    size = "md",
    onClose,
    onSuccess,
    btnName,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const userTheme = useSelector(getUserTheme);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <Modal
      size={size}
      centered
      show={showModal}
      className="info-msg-modal-main-wrapper"
    >
      <Modal.Body
        className={`${userTheme === "dark" ? "bg_darkTheme" : "bg_lightTheme"}`}
      >
        <div className="modal-header-custom">
          <div className="icon-wrapper text-center">
            <img src={InfoOutlineBlue} alt="Info Icon" />
          </div>
          <h2 className="text-center font_yellow mt-3">{title}</h2>
        </div>
        <div className="modal-body-custom">
          <p className="description text-center font_yellow font_m">
            {description}
          </p>
        </div>
        <div className="modal-footer-custom">
          <CustomButton size="m" variant="red" onClick={onClose}>
            Close
          </CustomButton>

          {onSuccess && (
            <CustomButton size="m" type="submit" variant="outline">
              {btnName}
            </CustomButton>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};
/* Component ends */

export default InfoMessageModal;
