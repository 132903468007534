/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  clearInternalMaintenance,
  fetchAllClosedInternalMaintenance,
  fetchAllCompletedInternalMaintenance,
  fetchAllNewInternalMaintenance,
  fetchAllOngoingInternalMaintenance,
  fetchAllSparePartsInternalMaintenance,
  getInternalMaintenanceList,
} from "../../../../setup/store/slices/internalMaintenanceSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import Searchbar from "../../../ui/other/Searchbar/Searchbar";
import TabButton from "../../../ui/other/TabButton/TabButton";
import NewRequest from "./sub-component/NewRequest";
import OnGoingRequest from "./sub-component/OnGoingRequest";
import SparePartsRequest from "./sub-component/SparePartsRequest";
import CompletedRequest from "./sub-component/CompletedRequest";
import ClosedRequest from "./sub-component/ClosedRequest";
import RevisionRequest from "./sub-component/RevisionRequest";
import BreadCrumb from "../../../ui/other/BreadCrumb/BreadCrumb";
/* Import local pages and component ends */

// Styles
import "./style.scss";

/* Component starts */
const InternalMaintenanceRequestsList = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [currentKey, setCurrentKey] = useState("new");
  const [currentPage, setCurrentPage] = useState(1);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const { propertyId, tab } = useParams();
  const { pathname } = useLocation();
  const internalMaintenances = useSelector(getInternalMaintenanceList);
  const navigate = useNavigate();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const buttonList = [
    { key: "new", label: "New" },
    { key: "ongoing", label: "Ongoing" },
    { key: "spareParts", label: "Spare Parts" },
    { key: "completed", label: "Completed" },
    { key: "closed", label: "Closed" },
    { key: "revision", label: "Revision" },
  ];
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const onTabSelect = (key) => {
    // setCurrentKey(key);
    const tabPath = pathname.split("/");
    tabPath.pop();
    navigate(tabPath.join("/") + "/" + key);
    setCurrentPage(1);
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    switch (tab) {
      case "new":
        dispatch(
          fetchAllNewInternalMaintenance({
            property_id: propertyId,
            page: currentPage,
            limit: 20,
          })
        );
        break;

      case "ongoing":
        dispatch(
          fetchAllOngoingInternalMaintenance({
            property_id: propertyId,
            page: currentPage,
            limit: 20,
          })
        );
        break;

      case "spareParts":
        dispatch(
          fetchAllSparePartsInternalMaintenance({
            property_id: propertyId,
            page: currentPage,
            limit: 20,
          })
        );
        break;

      case "completed":
        dispatch(
          fetchAllCompletedInternalMaintenance({
            property_id: propertyId,
            page: currentPage,
            limit: 20,
          })
        );
        break;

      case "closed":
        dispatch(
          fetchAllClosedInternalMaintenance({
            property_id: propertyId,
            page: currentPage,
            limit: 20,
          })
        );
        break;

      case "revision":
        break;

      default:
        break;
    }
  }, [tab, propertyId, currentPage]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      <BreadCrumb type="internal_maintenance" />
      <div className="internal-maintenance-wrapper">
        <div className="heading">
          <h2 className="font_xxl fw_6">Internal Maintenances</h2>
          {tab === "new" && (
            <Link to="add" className="font_m fw_6 d_block link">
              + Internal Maintenance Request
            </Link>
          )}
        </div>

        {/* <Searchbar /> */}

        {/* TAB BUTTONS */}
        <TabButton
          buttonList={buttonList}
          currentKey={tab}
          onSelect={onTabSelect}
        />

        {/* Display Table Data Based On The Current Tab */}

        {tab === "new" ? (
          <NewRequest
            internalMaintenances={internalMaintenances?.maintenance}
            totalCount={internalMaintenances?.total}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        ) : tab === "ongoing" ? (
          <OnGoingRequest
            internalMaintenances={internalMaintenances?.maintenance}
            totalCount={internalMaintenances?.total}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        ) : tab === "spareParts" ? (
          <SparePartsRequest
            internalMaintenances={internalMaintenances?.maintenance}
            totalCount={internalMaintenances?.total}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        ) : tab === "completed" ? (
          <CompletedRequest
            internalMaintenances={internalMaintenances?.maintenance}
            totalCount={internalMaintenances?.total}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        ) : tab === "closed" ? (
          <ClosedRequest
            internalMaintenances={internalMaintenances?.maintenance}
            totalCount={internalMaintenances?.total}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        ) : (
          <RevisionRequest />
        )}
      </div>
    </>
  );
};
/* Component ends */

export default InternalMaintenanceRequestsList;
