/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";
/* Import configuration ends */

/* Import redux slices component starts */

/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Table } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { EyeFilledBlue } from "../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import GLOBAL from "../../../setup/constants/global";
import IconButton from "../../../components/ui/button/IconButton/IconButton";
/* Import local pages and component ends */

/* Component starts */
const SharedFacilityTable = (props) => {
  /* Props destructuring starts */
  const {} = props;

  /* Props destructuring ends */

  /* Component states and useRef declaration starts */

  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  //   const navigate = useNavigate();
  //   const dispatch = useDispatch();

  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */

  // DUMMY DATA
  const OccupationList = [];

  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  // To View The Item Report
  //   const handleViewReport = async (id) => {
  //     const itemData = await dispatch(fetchComplaintBasedOnId(id));
  //     navigate("/property/security/details-complaint", {
  //       state: itemData.payload,
  //     });
  //   };

  /* Component function definition ends */

  /* Component useEffect starts */

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */

  return (
    <div className="custom-table-wrapper shared-facility-table">
      {OccupationList?.length > 0 && (
        <Table className="custom-table font_s" borderless responsive>
          {/* Table Heading */}
          <thead>
            <tr>
              <th>Unit No.</th>
              <th>Community</th>
              <th>Property</th>
              <th>Floor</th>
              <th>Size</th>
              <th>Type</th>
              <th>Tenant Name</th>
              <th>Status</th>
              <th>Last Status Date</th>
              <th>Date Range</th>
              <th className="text-end">Action</th>
            </tr>
          </thead>
          {/* Table Body */}
          {OccupationList?.length > 0 ? (
            <tbody>
              {OccupationList?.map((item, index) => (
                <tr key={index}>
                  {/* UNIT NO */}
                  <td>{item?.unit_no}</td>

                  {/* Community */}
                  <td>{item?.community}</td>

                  {/* Property */}
                  <td>{item?.property}</td>

                  {/* Floor */}
                  <td>{item?.floor}</td>

                  {/* Size */}
                  <td>{item?.size}Sq.ft</td>

                  {/* type */}
                  <td>{item?.type}</td>

                  {/* Tenant Name */}
                  <td>{item?.tenant}</td>

                  {/* Status */}
                  <td
                    className={
                      item?.status === "Completed" ? "complete" : "in-complete"
                    }
                  >
                    {item?.status}
                  </td>

                  {/* Last Status Date */}
                  <td>{moment(item?.last_date).format(DATE_TIME_FORMAT)}</td>

                  {/* Range Date */}
                  <td>
                    {moment(item?.last_date).format(DATE_TIME_FORMAT)} to{" "}
                    {moment(item?.range).format(DATE_TIME_FORMAT)}{" "}
                  </td>

                  {/* Actions */}
                  <td className="text-start txt-nowrap">
                    <IconButton
                      // onClick={() => handleViewReport(item?._id)}
                      className="action-button"
                    >
                      <img
                        src={EyeFilledBlue}
                        className="h_100"
                        alt="View Details"
                      />
                    </IconButton>
                  </td>
                </tr>
              ))}
            </tbody>
          ) : (
            ""
          )}
        </Table>
      )}

      {OccupationList?.length === 0 && (
        <div className=" text-center">
          <h3 className=" font_m font_yellow fw_6">No Data</h3>
        </div>
      )}
    </div>
  );
};
/* Component ends */
export default SharedFacilityTable;
