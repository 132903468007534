export const initialValues = {
  subject: "",
  photos: [null],
};

export const validations = (values) => {
  const error = {};
  /* Validation for Subject starts */
  if (values.subject === "") {
    error.subject = "Subject is required";
  }
  /* Validation for Subject ends */
  /* ******************************************************************************* */

  return error;
};

// Field Prop option extractor
export const fieldPropExtractor = (option, index) => {
  if (option?.photos?.length > 0) return option?.photos[index];
  else return undefined;
};
