/**
 *
 */

import React, { useEffect, useState } from "react";

/* Import configuration starts */
import { useSelector } from "react-redux";
import Calendar from "react-calendar";
/* Import configuration ends */

/* Import redux slices component starts */
import { getUserTheme } from "../../../../../setup/store/slices/authSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Modal } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import MUI component starts */
/* Import MUI component ends */

/* Import image and SVG starts */
import { XRed } from "../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../../button/IconButton/IconButton";
/* Import local pages and component ends */

import "../style.scss";

/* Component starts */
const DatePickerModal = (props) => {
  /* Props destructuring starts */
  const {
    showModal,
    title = "Select Date",
    size = "md",
    onCancel,
    handleChange,

    date,
    setDate,

    // Date Input Props
    minDate,
    maxDate,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const userTheme = useSelector(getUserTheme);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const onSelectingDate = (d) => {
    handleChange && handleChange(d.toString());
    setDate && setDate(d.toString());
    onCancel()
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <Modal
      size={size}
      centered
      show={showModal}
      className="date-picker-modal-wrapper"
    >
      <Modal.Body
        className={`${
          userTheme === "dark"
            ? "bg_darkTheme"
            : "bg_lightTheme font_lightTheme"
        }`}
      >
        <div className="modal-header-custom">
          <IconButton className="close-btn" onClick={onCancel}>
            <img src={XRed} alt="Close" className="w-100" />
          </IconButton>
          <h2 className="text-center font_blue font_xl">{title}</h2>
        </div>

        <div className="modal-body-custom modal-date-value-wrapper">
          <div
            className={`${
              (userTheme === "light" || userTheme === "automatic") &&
              "otherTheme"
            } `}
          >
            <Calendar
              onChange={onSelectingDate}
              value={date}
              minDate={minDate}
              maxDate={maxDate}
            />
          </div>
        </div>

        <div className="modal-footer-custom"></div>
      </Modal.Body>
    </Modal>
  );
};
/* Component ends */

export default DatePickerModal;
