/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useDispatch } from "react-redux";
import GLOBAL from "../../../../../setup/constants/global";
import { Formik } from "formik";
import { notifyList, checkValidEmailList } from "../helper";
/* Import configuration ends */

/* Import redux slices component starts */
import { updatePropertySettings } from "../../../../../setup/store/slices/propertySlice";
import {
  showSuccessAlert,
  showErrorAlert,
} from "../../../../../setup/store/slices/globalAlertSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import {
  PlusBlue,
  DeleteGrey,
  DeleteRed,
} from "../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomNumberInput from "../../../../ui/input/CustomNumberInput/CustomNumberInput";
import CustomButton from "../../../../ui/button/CustomButton/CustomButton";
import CustomCheckInput from "../../../../ui/input/CustomCheckInput/CustomCheckInput";
import CustomSelectInput from "../../../../ui/input/CustomSelectInput/CustomSelectInput";
import CustomTextInput from "../../../../ui/input/CustomTextInput/CustomTextInput";
import CustomRadioInput from "../../../../ui/input/CustomRadioInput/CustomRadioInput";
import IconButton from "../../../../ui/button/IconButton/IconButton";
/* Import local pages and component ends */

/* Component starts */
const MaxTimeResolveIntMaintenance = (props) => {
  /* Props destructuring starts */
  const { user, settings, property_id } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [selectedDuration, setSelectedDuration] = useState();
  const [durationCount, setDurationCount] = useState();
  const [isEnabled, setIsEnabled] = useState();
  const [additionalEmails, setAdditionalEmails] = useState(true);
  const [emails, setEmails] = useState([""]);
  const [settingsChanged, setSettingsChanged] = useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const durations = [
    {
      name: "Days",
      type: "days",
    },
    {
      name: "Hours",
      type: "hours",
    },
  ];
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const handleAddEmail = () => {
    setSettingsChanged(true);
    setEmails((prevState) => {
      const arr = [...prevState];
      arr.push("");
      return arr;
    });
  };

  const handleRemoveEmail = (index) => {
    setSettingsChanged(true);
    setEmails((prevState) => {
      const arr = [...prevState];
      arr.splice(index, 1);
      return arr;
    });
  };

  const onUpdate = async () => {
    if (additionalEmails && checkValidEmailList(emails)) {
      dispatch(
        showErrorAlert({ title: "Error", description: "Invalid email" })
      );
      return;
    }

    // Creating or collecting payload data to be sent
    const payload = {
      max_time_resolve_internal_main_enabled: isEnabled,
      max_time_resolve_internal_main_duration: selectedDuration?.type,
      max_time_resolve_internal_main_value: durationCount,
      max_time_resolve_internal_main_additional_emails: additionalEmails,
      max_time_resolve_internal_main_emails: JSON.stringify(emails),
      property_id,
    };
    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      const result = await dispatch(updatePropertySettings(payload));
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          setSettingsChanged(false);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    setIsEnabled(settings?.max_time_resolve_internal_main_enabled);
    let selDura = durations.find(
      (d) => d.type === settings.max_time_resolve_internal_main_duration
    );
    setSelectedDuration(selDura ? selDura : durations[0]);
    setDurationCount(
      settings?.max_time_resolve_internal_main_value
        ? settings.max_time_resolve_internal_main_value.toString()
        : "15"
    );
    setAdditionalEmails(
      settings?.max_time_resolve_internal_main_additional_emails
    );
    setEmails(
      settings?.max_time_resolve_internal_main_emails
        ? JSON.parse(settings.max_time_resolve_internal_main_emails)
        : [""]
    );
  }, [settings]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="sla-common-option option">
      {/* Label */}
      <div className="label">
        <p className="font_m fw_5">
          Maximum Time to Resolve Internal Maintenance Ticket
        </p>
      </div>

      {/* Setting */}
      <div className="setting">
        <div className="row1 mb_2">
          <div className="enable-switch">
            <CustomCheckInput
              variant="2"
              display="block"
              value={isEnabled}
              setValue={(v) => {
                setSettingsChanged(true);
                setIsEnabled(v);
              }}
            />
            <p className="font_xs">Enabled</p>
          </div>
          <div className="duration">
            <CustomNumberInput
              variant="2"
              value={durationCount}
              setValue={(v) => {
                setSettingsChanged(true);
                setDurationCount(v);
              }}
              inputClass="setting-input font_xs sm text-center"
            />
            <CustomSelectInput
              inputClass="setting-select font_xs text-center"
              variant="2"
              valueExtractor={(item) => item?.name}
              optionValueExtractor={(item) => item?.type}
              options={durations}
              formikState={false}
              emptyOption={false}
              value={selectedDuration}
              setValue={setSelectedDuration}
              customOnchange={() => {
                setSettingsChanged(true);
              }}
            />
          </div>
        </div>

        <div className="row2 mb_2">
          <div className="radio-wrapper">
            <p className="additional-email-label font_s">Additional Emails</p>
            <div className="radio-container">
              <CustomRadioInput
                isSelected={additionalEmails}
                onSelecting={() => {
                  setSettingsChanged(true);
                  setAdditionalEmails(true);
                }}
              />
              <p className="font_s">Yes</p>
            </div>
            <div className="radio-container">
              <CustomRadioInput
                isSelected={!additionalEmails}
                onSelecting={() => {
                  setSettingsChanged(true);
                  setAdditionalEmails(false);
                }}
              />
              <p className="font_s">No</p>
            </div>
          </div>
        </div>

        {additionalEmails && (
          <div className="row3">
            {emails.map((item, index) => (
              <div className="emaileach mb_1" key={index}>
                <CustomTextInput
                  variant="2"
                  inputClass="setting-input"
                  setValue={(v) => {
                    setSettingsChanged(true);
                    setEmails((prevState) => {
                      const arr = [...prevState];
                      arr[index] = v;
                      return arr;
                    });
                  }}
                  value={item}
                />
                {emails?.length === index + 1 && (
                  <>
                    <IconButton className="icon" onClick={handleAddEmail}>
                      <img className="w_100" src={PlusBlue} alt="" />
                    </IconButton>
                  </>
                )}
                {emails.length > 1 && (
                  <IconButton
                    className="icon"
                    onClick={() => handleRemoveEmail(index)}
                  >
                    <img className="w_100" src={DeleteRed} alt="" />
                  </IconButton>
                )}
              </div>
            ))}
          </div>
        )}

        <div className="row4">
          {settingsChanged && (
            <CustomButton onClick={onUpdate} className="save-btn" size="s">
              Save
            </CustomButton>
          )}
        </div>
      </div>
    </div>
  );
};
/* Component ends */

export default MaxTimeResolveIntMaintenance;
