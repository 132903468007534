import moment from "moment";

export const isApproved = (status) => {
  return status && status === "approved" ? true : false;
};

// Pets form validation
export const petValidation = (values, formCountList) => {
  const error = {};

  formCountList.forEach((i, indx) => {
    const name = `name_${i}`;
    const type = `type_${i}`;
    const image = `image_${i}`;

    if (values[name] === "") {
      error[name] = "Name is required";
    }

    if (values[type] === "") {
      error[type] = "Type is required";
    }

    if (values[image] === null) {
      error[image] = "Image is required";
    }
  });

  return error;
};

// Pets form prefilling
export const petFormPrefill = (
  data,
  setInitialFormValues,
  setFormCountList
) => {
  const formValues = {};
  const currentFormCount = [];

  data.forEach((item, i) => {
    formValues[`name_${i}`] = item?.name;
    formValues[`type_${i}`] = item?.type;
    formValues[`dob_${i}`] = new Date(item?.dob);
    formValues[`image_${i}`] = item?.image;
    formValues[`status_${i}`] = item?.status;

    currentFormCount.push(i);
  });

  setInitialFormValues(formValues);
  setFormCountList(currentFormCount);
};

export const setFormList = (
  formCountList,
  setFormCountList,
  requiredFormCountNumber,
  formRef,
  setInitialFormValues
) => {
  const currentFormState = { ...formRef.current.values };
  const currentFormCount = [...formCountList];

  if (requiredFormCountNumber > formCountList.length) {
    // Adding forms
    for (let i = 0; i < requiredFormCountNumber; i++) {
      if (i >= formCountList.length) {
        currentFormState[`name_${i}`] = "";
        currentFormState[`type_${i}`] = "";
        currentFormState[`dob_${i}`] = new Date();
        currentFormState[`image_${i}`] = null;

        currentFormCount.push(i);
      }
    }
  } else if (requiredFormCountNumber < formCountList.length) {
    const lastIdx = formCountList.length - 1;

    for (let i = lastIdx; i >= requiredFormCountNumber; i--) {
      delete currentFormState[`name_${formCountList[i]}`];
      delete currentFormState[`type_${formCountList[i]}`];
      delete currentFormState[`dob_${formCountList[i]}`];
      delete currentFormState[`image_${formCountList[i]}`];

      currentFormCount.pop();
    }
  }

  setInitialFormValues(currentFormState);
  setFormCountList(currentFormCount);
};

export const isDataChanged = (newData, prevData, keyList) => {
  for (let i = 0; i < keyList.length; i++) {
    const name_old = prevData[i].name;
    const type_old = prevData[i].type;
    const dob_old = prevData[i].dob;

    if (newData[`name_${keyList[i]}`] !== name_old) {
      return true;
    }

    if (newData[`type_${keyList[i]}`] !== type_old) {
      return true;
    }

    if (!moment(newData[`dob_${keyList[i]}`]).isSame(dob_old)) {
      return true;
    }

    if (
      newData[`image_${keyList[i]}`] &&
      !newData[`image_${keyList[i]}`]?.url
    ) {
      return true;
    }
  }

  return false;
};
