/**
 *
 */

import React from "react";
import { useNavigate } from "react-router-dom";
/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Table } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { EditWhite, EyeFilledBlue } from "../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../../ui/button/IconButton/IconButton";
/* Import local pages and component ends */

// Styles
import "./style.scss";
import moment from "moment";
import GLOBAL from "../../../../setup/constants/global";
import { useDispatch } from "react-redux";
import { fetchAccessPassBasedOnId } from "../../../../setup/store/slices/visitorPass";
import Pagination from "../../../ui/other/Pagination/Pagination";

/* Component starts */
const GuestPassTable = (props) => {
  /* Props destructuring starts */
  const { guestPassList, totalCount, currentPage, setCurrentPage } = props;

  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  const dispatch = useDispatch();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  // To View The Item Report
  const handleViewReport = async (id) => {
    navigate(`${id}`);
  };

  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      <div className="custom-table-wrapper guestPass-table-wrapper">
        {guestPassList?.length > 0 && (
          <Table className="custom-table font_s" borderless responsive>
            {/* Table Heading */}
            <thead>
              <tr>
                <th>Guest Name</th>
                <th>Guest Type </th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Guest Status</th>
                {/* <th>Contract Status</th> */}
                <th>Action</th>
              </tr>
            </thead>
            {/* Table Body */}
            <tbody>
              {guestPassList?.map((item) => (
                <tr key={item._id}>
                  {/* Guest Name */}

                  <td>
                    {item?.guest_name !== undefined ? item?.guest_name : "-"}
                  </td>

                  {/* Guest Type */}
                  <td>{item?.guest_type}</td>

                  {/* Start Date */}
                  <td className="txt-nowrap">
                    {moment(item?.start_date).format(DATE_FORMAT)}{" "}
                    {moment(item?.start_date).format(TIME_FORMAT)}
                  </td>

                  {/* End Date */}
                  <td className="txt-nowrap">
                    {moment(item?.end_date).format(DATE_FORMAT)}{" "}
                    {moment(item?.end_date).format(TIME_FORMAT)}
                  </td>

                  {/* Guest Status */}
                  <td
                    className={
                      item?.guest_status === "active"
                        ? "font_green"
                        : item?.guest_status === "approved"
                        ? "font_blue"
                        : item?.guest_status === "revoked"
                        ? "font_yellow"
                        : "font_red"
                    }
                  >
                    {item?.guest_status?.charAt(0).toUpperCase() +
                      item?.guest_status?.slice(1)}
                  </td>

                  {/* Actions */}
                  <td className="text-start txt-nowrap">
                    <IconButton
                      onClick={() => handleViewReport(item?._id)}
                      className="action-button"
                    >
                      <img
                        src={EyeFilledBlue}
                        className="h_100"
                        alt="View Details"
                      />
                    </IconButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}

        {guestPassList?.length === 0 && (
          <div className=" text-center">
            <h3 className=" font_m font_yellow fw_6">No Data</h3>
          </div>
        )}
      </div>

      {/* PAGINATION */}
      {guestPassList?.length > 0 && (
        <Pagination
          itemsPerPage="10"
          totalItems={totalCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
    </>
  );
};
/* Component Ends */
export default GuestPassTable;
