/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import GLOBAL from "../../../../../setup/constants/global";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Table } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { DocumentGreen } from "../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../../../ui/button/IconButton/IconButton";
import FileViewerModal from "../../../../ui/modal/FileViewerModal/FileViewerModal";
/* Import local pages and component ends */

/* Component starts */
const FinalAgreement = (props) => {
  /* Props destructuring starts */
  const { application, index, agreement, auditLog } = props;

  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [file, setFile] = useState();
  const [showFileViewer, setShowFileViewer] = useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // On viewing signature file
  const onViewFile = (item) => {
    setFile(item);
    setShowFileViewer(true);
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="initial-agreement-wrapper">
      <div className="initial-agreement-heading">
        <p className="font_l label">
          {index !== undefined ? `${index} ${"-"}` : ""} Final Agreement
        </p>
      </div>

      {/* ID Verfication Table */}
      <div className="custom-table-wrapper">
        {agreement && auditLog && (
          <Table className="custom-table font_s" borderless responsive>
            {/* Table Heading */}
            <thead>
              <tr>
                <th></th>
                <th>Tenant Signature Date and Time</th>
                <th>PM or Owner Signature Date and Time</th>
                <th>File</th>
                {/* <th className="text-start">Actions</th> */}
              </tr>
            </thead>
            {/* Table Body */}
            <tbody>
              <tr>
                <td>Final Agreement</td>
                <td>
                  {application?.first_party_final_signature_timestamp
                    ? moment(
                        application?.first_party_final_signature_timestamp
                      ).format(DATE_TIME_FORMAT)
                    : "-"}
                </td>
                <td>
                  {application?.second_party_final_signature_timestamp
                    ? moment(
                        application?.second_party_final_signature_timestamp
                      ).format(DATE_TIME_FORMAT)
                    : "-"}
                </td>

                {/* Document */}
                <td className="text-start txt-nowrap ">
                  <IconButton onClick={() => onViewFile(agreement)}>
                    <img src={DocumentGreen} alt="View ID" />
                  </IconButton>
                </td>
              </tr>
              <tr>
                <td>Audit log</td>
                <td>-</td>
                <td>-</td>
                {/* Document */}
                <td className="text-start txt-nowrap ">
                  <IconButton onClick={() => onViewFile(auditLog)}>
                    <img src={DocumentGreen} alt="View ID" />
                  </IconButton>
                </td>
              </tr>
            </tbody>
          </Table>
        )}

        {agreement === undefined && (
          <div className=" text-center">
            <h3 className=" font_m font_yellow fw_6">No Data</h3>
          </div>
        )}
      </div>

      {/* Modals */}
      {/* File Viewer Modal */}
      <FileViewerModal
        show={showFileViewer}
        file={file?.url}
        type={file?.type}
        onClose={() => {
          setShowFileViewer(false);
          setFile({});
        }}
      />
    </div>
  );
};
/* Component ends */

export default FinalAgreement;
