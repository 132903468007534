export const initialValues = {
  incident_sub: "",
  location: "",
  description: "",
  companyName: "",
  fullName: "",
  email: "",
  mobile: "",
  company: "",
  photos: [null],
};

export const validations = (values, voilator) => {
  const errorFields = {};

  /* Validations for Incident Subject Starts */
  if (values.incident_sub === "") {
    errorFields.incident_sub = "Incident Subject is required";
  }

  /* Validations for Incident Subject Ends */

  /* Validations for Location Starts */
  if (values.location === "") {
    errorFields.location = "Location is required";
  }

  /* Validations for Location Ends */

  /* Validations for Description Starts */
  if (values.description === "") {
    errorFields.description = "Description is required";
  }
  /* Validations for Description Ends */

  /* Validations for Others Starts */
  if (voilator === "Others" && values.fullName === "") {
    errorFields.fullName = "Full Name is required";
  }

  /* Validations for Others Ends */

  return errorFields;
};

// Field Prop option extractor
export const fieldPropExtractor = (option, index) => {
  if (option?.photos?.length > 0) return option?.photos[index];
  else return undefined;
};
