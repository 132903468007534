/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { joinAddress } from "../../../../../setup/utils/global-helper";
import { getAmmenities, renewalStatus } from "../helper";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import {
  LocationPinFilledGrey,
  WarningRoundedOutlineYellow,
} from "../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import Modules from "./Modules";
import TenantOnbadingStatuses from "../../../Tenant/TenantOnbadingStatuses/TenantOnbadingStatuses";
import RejectionStatus from "./RejectionStatus";
import CancelApplicationBlock from "./CancelApplicationBlock";
/* Import local pages and component ends */

/* Component starts */
const SliderItem = (props) => {
  /* Props destructuring starts */
  const { item, onClick, fetchTenantUnitsNew } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const propertyName = item?.property_name;
  const unitName = item?.unit_name;
  const unitType = item?.unitType || "";
  const city = item?.property_city;
  const state = item?.property_state;
  const country = item?.property_country;
  const ammenties = item && getAmmenities(item);

  const propertyLogo = item?.property_logo?.url;
  const propertyEmail = item?.property_email;
  const propertyMobile = item?.property_contact_number;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="slider-item" onClick={onClick}>
      {/* Header */}
      <div className="title-header d-flex">
        <div className="property-detail">
          {propertyLogo && (
            <div className="property_logo">
              <img src={propertyLogo} alt="LOGO" />
            </div>
          )}
          <div className="property_data mx-4">
            <p className="propName left fw_5 font_l font_blue">
              {propertyName}
            </p>
            <div className="location-data">
              <img src={LocationPinFilledGrey} alt="Location-Pin" />
              <span className="font_s fw_4">
                {city}, {state}, {country}
              </span>
            </div>
            {propertyEmail && (
              <p className="propName left fw_3 font_l">
                Property Email :{" "}
                <span className="propName left fw_5 font_l font_blue">
                  {propertyEmail}
                </span>{" "}
              </p>
            )}
            {propertyMobile && (
              <p className="propName left fw_3 font_l">
                Property Mobile :{" "}
                <span className="propName left fw_5 font_l font_blue">
                  {propertyMobile}
                </span>{" "}
              </p>
            )}
          </div>
        </div>
        <div className="unit-detail">
          <p className="unitName font_l fw_4">
            Unit :{" "}
            <span className="unitName font_l fw_6 font_blue">{unitName}</span>{" "}
          </p>
          <p className="unitName font_m fw_4">
            Unit Type:{" "}
            <span className="unitName font_m fw_6 font_blue">{unitType}</span>{" "}
          </p>
          {ammenties && (
            <>
              <p className="ammenties font_xs font_grey fw_5">{ammenties}</p>
            </>
          )}
          {/* <p className="unitName font_m fw_4">
            Unit Type :{" "}
            <span className="unitName font_m fw_6 font_blue">{unitType}</span>{" "}
          </p> */}
        </div>
      </div>

      {/* Tenant Statuses/Options */}
      {/* Statuses */}

      <div className="unit-status-and-module-section-wrapper">
        <div className="unit-status-and-module-section">
          {!item?.show_cards && (
            <TenantOnbadingStatuses
              fetchTenantUnitsNew={fetchTenantUnitsNew}
              unit={item}
            />
          )}

          {/* Modules */}
          {item?.show_cards && (
            <Modules unit={item} fetchTenantUnitsNew={fetchTenantUnitsNew} />
          )}

          {/* Contract Renewal Status */}
          {item?.contract_renewal?.status && (
            <>
              <p className="contract_renewal_status">
                <img src={WarningRoundedOutlineYellow} alt="Notification" />
                <span className="font_yellow fw_6 font_l">
                  Contract Renewal Status :{" "}
                  {renewalStatus(item?.contract_renewal?.status)?.text}
                </span>
              </p>
              {/* {item?.contract_renewal?.status} */}
            </>
          )}

          {item?.tenant_application?.status === "application" &&
            item?.tenant_application?.sub_status === "reservor-rejected" && (
              <RejectionStatus item={item} />
            )}
        </div>
      </div>
    </div>
  );
};
/* Component ends */

export default SliderItem;
