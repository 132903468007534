import GLOBAL from "../../../../setup/constants/global";

export const getContractType = (contract_type) => {
  switch (contract_type) {
    case GLOBAL.PAYMENT_TYPE.APPLICATION_FEE:
      return "Application Fee";
    case GLOBAL.PAYMENT_TYPE.DEPOSIT:
      return "Deposit";
    case GLOBAL.PAYMENT_TYPE.RENT:
      return "Rent";
    case GLOBAL.PAYMENT_TYPE.COMMISSION:
      return "Commission";
  }
};

export const _getInvoicesToPay = (contract) => {
  let invoice_ids = [];
  contract.billing_invoices.forEach((invoice) => {
    if (invoice.status === GLOBAL.UNPAID) {
      invoice_ids.push(invoice._id);
    }
  });
  return invoice_ids;
};

export const isUnpaid = (item) => {
  return item.status === GLOBAL.UNPAID;
};
