/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { RightArrowOutlineGrey } from "../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomCheckInput from "../../../ui/input/CustomCheckInput/CustomCheckInput";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const CheckboxMultiSelectDropdown = (props) => {
  /* Props destructuring starts */
  const {
    multiSelectData,
    setMultiSelectData,
    optionLabel,
    label,
    labelClass,
    setAnyOneSelected,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // Lock body scroll
  const lockScroll = () => {
    document.body.style.overflow = "hidden";
  };

  // Unlock body scroll
  const unlockScroll = () => {
    document.body.style.overflow = "";
  };

  // Toggle show and hide dropdown menu
  const toggleDropdown = () => {
    if (isMenuVisible) {
      setIsMenuVisible(false);
      unlockScroll();
    } else {
      setIsMenuVisible(true);
      lockScroll();
    }
  };

  // Hide dropdown on clicking outside
  const hideOnClickOutside = () => {
    setIsMenuVisible(false);
    unlockScroll();
  };

  const handleChange = (item) => {
    setMultiSelectData(() => {
      const oldData = { ...multiSelectData };
      oldData[item] = !oldData[item];
      return oldData;
    });
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  // useEffect(() => {
  //   if (setAnyOneSelected) {
  //     let isAnyoneSelected = false;
  //     Object?.keys(multiSelectData)?.forEach((key, indx) => {
  //       if (multiSelectData[key]) {
  //         isAnyoneSelected = true;
  //       }
  //     });

  //     setAnyOneSelected(isAnyoneSelected);
  //   }
  // }, [multiSelectData]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="checkbox-multi-select-dropdown-wrapper">
      <p
        className={`main-label font_s fw_4 ${labelClass}`}
        onClick={toggleDropdown}
      >
        {label}
        <img
          className="down_icon"
          src={RightArrowOutlineGrey}
          alt="Dropdown Icon"
        />
      </p>
      {/* <div onClick={hideOnClickOutside} className="back-drop"></div> */}
      {isMenuVisible && (
        <div className="drop-menu">
          {Object.keys(multiSelectData).map((item, index) => (
            <div className="drop-item" key={index}>
              <input
                type="checkbox"
                className="v_middle"
                onChange={() => {
                  handleChange(item);
                }}
                checked={multiSelectData[item]}
              />
              <span className="v_middle font_m fw_6">{optionLabel[index]}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
/* Component ends */

export default CheckboxMultiSelectDropdown;
