/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import MUI component starts */
/* Import MUI component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomSlider from "../../../ui/other/CustomSlider/CustomSlider";
import PropertyLicenseSliderItem from "../PropertyLicenseSliderItem/PropertyLicenseSliderItem";
import AssignLicenseModal from "../AssignLicenseModal/AssignLicenseModal";
import SuccessModal from "../../../ui/modal/SuccessModal/SuccessModal";
import UnAssignLicenseModal from "../UnAssignLicenseModal/UnAssignLicenseModal";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const PropertyLicenseSlider = (props) => {
  /* Props destructuring starts */
  const { update, properties, user, onLicenseActionSuccess = () => {} } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [modalMessage, setModalMessage] = useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [showUnAssignModal, setShowUnAssignModal] = useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // On assigning license to the selected property
  const onAssign = (property) => {
    setSelectedProperty(property);
    setShowAssignModal(true);
  };

  // On un-assigning license from the selected property
  const onUnAssign = (property) => {
    setSelectedProperty(property);
    setShowUnAssignModal(true);
  };

  // On successfully assiging license to the property
  const onSuccessfullyAssign = async () => {
    try {
      await update();
      setSelectedProperty(null);
      setModalMessage("License successfully assigned to the property.");
      setShowAssignModal(false);
      setShowSuccessModal(true);
    } catch (e) {
      console.log(e);
    }
  };

  // On successfully unassiging license from the property
  const onSuccessfullyUnAssign = async () => {
    try {
      await update();
      setSelectedProperty(null);
      setModalMessage("License successfully unassigned from the property.");
      setShowUnAssignModal(false);
      setShowSuccessModal(true);
    } catch (e) {
      console.log(e);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="property-license-slider-component-wrapper">
      <h2 className="heading font_xl fw_6">
        Properties ({properties?.length})
      </h2>
      <CustomSlider type="license">
        {properties?.map((item, index) => (
          <PropertyLicenseSliderItem
            onAssign={onAssign}
            onUnAssign={onUnAssign}
            key={index}
            property={item}
          />
        ))}
      </CustomSlider>

      {/* Modals */}
      {/* Assign License Modal */}
      <AssignLicenseModal
        selectedProperty={selectedProperty}
        showModal={showAssignModal}
        maxLicense={user?.available_license}
        onSuccess={onSuccessfullyAssign}
        onCancel={() => {
          setShowAssignModal(false);
          setSelectedProperty(null);
        }}
      />

      {/* Un-assign License Modal */}
      <UnAssignLicenseModal
        selectedProperty={selectedProperty}
        showModal={showUnAssignModal}
        maxLicense={selectedProperty?.unused_license}
        onSuccess={onSuccessfullyUnAssign}
        onCancel={() => {
          setShowUnAssignModal(false);
          setSelectedProperty(null);
        }}
      />

      {/* Success Modal */}
      <SuccessModal
        showModal={showSuccessModal}
        title="Success"
        description={modalMessage}
        onClose={() => {
          onLicenseActionSuccess();
          setShowSuccessModal(false);
          setModalMessage("");
        }}
      />
    </div>
  );
};
/* Component ends */

export default PropertyLicenseSlider;
