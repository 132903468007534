/**
 *
 */

import React, { useState, useEffect } from "react";
import SimpleLog from "./SimpleLog";
import DocApproveLog from "./DocApproveLog";

/* Import configuration starts */
import { useSelector } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import { getUser, getIsPM } from "../../../../../setup/store/slices/authSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
/* Import local pages and component ends */

/* Component starts */
const TimeLineLists = (props) => {
  /* Props destructuring starts */
  const { logs, moveoutId, moveOut, getMoveoutDetail } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const user = useSelector(getUser);
  const isPM = useSelector(getIsPM);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const generateLogType = (item) => {
    if (item?.action === "Approval Pending") {
      return "approve_log";
    } else {
      return "simple_log";
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="moveOut-timeline-list-wrapper timeline-list-wrapper">
      {/* Time Line List */}
      {logs?.map((log, index) => (
        <div className="log-wrapper" key={index}>
          {/* Simple Log */}
          {generateLogType(log) === "simple_log" && (
            <SimpleLog
              moveoutId={moveoutId}
              takeAction={index === 0}
              user={user}
              item={log}
              isPM={isPM}
              getMoveoutDetail={getMoveoutDetail}
              moveOut={moveOut}
            />
          )}

          {/* Document Approval Log */}
          {generateLogType(log) === "approve_log" && (
            <DocApproveLog
              moveoutId={moveoutId}
              takeAction={index === 0}
              user={user}
              item={log}
              moveOut={moveOut}
              getMoveoutDetail={getMoveoutDetail}
            />
          )}
        </div>
      ))}
    </div>
  );
};
/* Component ends */

export default TimeLineLists;
