/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { WarningRoundedOutlineYellow } from "../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
/* Import local pages and component ends */

/* Component starts */
const Causes = (props) => {
  /* Props destructuring starts */
  const { causes } = props;

  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  if (causes) {
    return (
      <div className="causes_wrapper bg_grey">
        <h3 className="heading font_m font_5">
          Kindly ensure to comply with the conditions below to enable the system
          to activate the renewed contract
        </h3>

        <div className="causes_list">
          {causes?.map((item, index) => (
            <div className="causes_list_item" key={index}>
              <img
                className="warning"
                src={WarningRoundedOutlineYellow}
                alt="Warning Icon"
              />
              <p className="font_m fw_4 font_yellow">{item}</p>
            </div>
          ))}
        </div>

        {/* <ul className="causes_list">
          {causes?.map((item, i) => {
            <li className="causes_list_item" key={i}>
              <img
                src={WarningRoundedOutlineYellow}
                className="icon-sm"
                alt="Warning Icon"
              />
              <p className="font_yellow font_xs fw_4">{item}</p>
            </li>;
          })}
        </ul> */}
      </div>
    );
  }
};
/* Component ends */

export default Causes;
