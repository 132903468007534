import GLOBAL from "../../../../setup/constants/global";
import { pathNamePopped } from "../../../../setup/utils/global-helper";

export const tenancyType = {
  [GLOBAL.TENANCY_TYPE.RENT]: "Rent",
  [GLOBAL.TENANCY_TYPE.OWN]: "Own",
};

export const getContractType = (contract_type) => {
  switch (contract_type) {
    case GLOBAL.PAYMENT_TYPE.APPLICATION_FEE:
      return "Application Fees";
    case GLOBAL.PAYMENT_TYPE.DEPOSIT:
      return "Deposit";
    case GLOBAL.PAYMENT_TYPE.RENT:
      return "Rent";
    case GLOBAL.PAYMENT_TYPE.COMMISSION:
      return "Commission";
    case GLOBAL.PAYMENT_TYPE.OTHER:
      return "Other";
  }
};

export const blockInvalidCharNoDot = (e) =>
  [("e", "E", "+", "-", ".", " ")].includes(e.key) && e.preventDefault();

export const keyStatusGenerate = (status) => {
  switch (status) {
    case "reservor-pending-key-collection":
      return { color: "font_yellow", text: "Keys are ready to be collected" };
    case "reservor-rejected-key-collection":
      return { color: "font_red", text: "Keys are rejected" };

    case "reservor-key-collected":
      return { color: "font_blue", text: "Keys are collected" };

    case "pending_key_dispatch":
      return { color: "font_yellow", text: "Pending Key Dispatch" };

    default:
      return "";
  }
};

export const invoiceDetailsByType = (invoiceList, type) => {
  for (let i = 0; i < invoiceList.length; i++) {
    if (invoiceList[i].type === type) {
      return invoiceList[i];
    }
  }
  return null;
};

export const navigateToInvoiceByType = (
  navigate,
  type,
  invoice,
  currentLocation,
  pathname,
  fromExisiting
) => {
  if (currentLocation === "cancelled" && type === "application-fee") {
    const newPathname = pathNamePopped(pathname?.pathname, 3);
    navigate(
      `${newPathname}/invoicing/Tenant/application/${invoice?._id}?from=onboard_application`,
      {
        state: { pathname: pathname?.pathname },
      }
    );
  } else if (fromExisiting) {
    const newPathname = pathNamePopped(pathname?.pathname, 6);
    switch (type) {
      case "application-fee":
        navigate(
          `${newPathname}/invoicing/Tenant/application/${invoice?._id}?from=onboard_application`
          // {
          //   state: { pathname: pathname },
          // }
        );
        break;

      case "deposit":
        navigate(`Tenant/deposits/${invoice?._id}?from=onboard_application`);
        break;

      case "commission":
        navigate(`Tenant/commission/${invoice?._id}?from=onboard_application`);
        break;

      case "rent":
        navigate(`Tenant/rent/${invoice?._id}?from=onboard_application`);
        break;

      case "other":
        navigate(`Tenant/other/${invoice?._id}?from=onboard_application`);
        break;

      default:
        return;
    }
  } else {
    switch (type) {
      case "application-fee":
        navigate(`Tenant/application/${invoice?._id}?from=onboard_application`);
        break;

      case "deposit":
        navigate(`Tenant/deposits/${invoice?._id}?from=onboard_application`);
        break;

      case "commission":
        navigate(`Tenant/commission/${invoice?._id}?from=onboard_application`);
        break;

      case "rent":
        navigate(`Tenant/rent/${invoice?._id}?from=onboard_application`);
        break;

      case "other":
        navigate(`Tenant/other/${invoice?._id}?from=onboard_application`);
        break;

      default:
        return;
    }
  }
};
