/*
 * Slice to handle INTERNAL MAINTENANCE  DASHBOARD Module
 */

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_URL } from "../../config/devKeys";
import { setLoading } from "./unpersistedSlice";

const initialState = {
  internalMaintenanceList: [],
  propertyInternalMaintenanceList: [],
  communityInternalMaintenanceList: [],
  searchedInternalMaintenanceList: [],
  // annoucement: null,
};

// FETCH INTERNAL MAINTENANCE DASHBOARD LIST - Client Side (HOME)
export const fetchInternalMaintenanceList = createAsyncThunk(
  "dashboard_home/dashboard_internalMaintanance_listData",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(
        `${API_URL}/dashboard/dashboard_internalMaintanance_listData`,
        {
          method: "POST",
          headers: headers,
        }
      );

      const data = await response.json();

      dispatch(setLoading(false));

      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// FETCH INTERNAL INTERNAL MAINTENANCE DASHBOARD LIST - Client Side  (Property_ID)
export const fetchInternalMaintenanceListBasedProperty = createAsyncThunk(
  "dashboard_property/dashboard_internalMaintanance_listData",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(
        `${API_URL}/dashboard/dashboard_internalMaintanance_listData`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            property_id: payload?.property_id,
          }),
        }
      );

      const data = await response.json();
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// FETCH INTERNAL MAINTENANCE DASHBOARD LIST - Client Side  (Community_ID)
export const fetchInternalMaintenanceListBasedCommunity = createAsyncThunk(
  "dashboard_community/dashboard_internalMaintanance_listData",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(
        API_URL + `/dashboard/dashboard_internalMaintanance_listData`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            community_id: payload?.community_id,
          }),
        }
      );

      const data = await response.json();

      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// FETCH SEARCHED INTERNAL MAINTENANCE DASHBOARD LIST - Client Side (HOME)
export const fetchSearchedInternalMaintenanceList = createAsyncThunk(
  "dashboard_search/dashboard_internalMaintanance_listData",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(
        `${API_URL}/dashboard/dashboard_internalMaintanance_listData?searchKey=${payload.searchKey}&start_date=${payload.start_date}&end_date=${payload.end_date}`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            property_id: payload?.property_id,
            community_id: payload?.community_id,
          }),
        }
      );

      const data = await response.json();

      dispatch(setLoading(false));

      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// FETCH ANNOUCEMENT DATA BASED ON ID  - (ANNOUCEMENT_ID)
// export const fetchAnnoucementById = createAsyncThunk(
//   "announcement/get_annoucement_by_id",
//   async (payload, { rejectWithValue, getState, dispatch }) => {
//     try {
//       dispatch(setLoading(true));
//       const headers = {
//         Authorization: `Bearer ${localStorage.getItem("user_token")}`,
//         "Content-Type": "application/json",
//       };

//       const response = await fetch(
//         `${API_URL}/announcement/get_annoucement_by_id?id=${payload}`,
//         {
//           method: "GET",
//           headers: headers,
//         }
//       );

//       const data = await response.json();

//       dispatch(setLoading(false));

//       if (data.success) {
//         return data.data;
//       } else {
//         return rejectWithValue(data.error.message);
//       }
//     } catch (error) {
//       dispatch(setLoading(false));
//       return rejectWithValue(error?.response);
//     }
//   }
// );

export const internalMaintenanceInDashboardSlice = createSlice({
  name: "internalMaintenanceDash",
  initialState,
  reducers: {
    clearSearchInternalMaintenance: (state, action) => {
      state.internalMaintenanceList = [];
    },
  },
  extraReducers: {
    [fetchInternalMaintenanceList.rejected]: (state, action) => {
      state.internalMaintenanceList = [];
    },
    [fetchInternalMaintenanceList.fulfilled]: (state, action) => {
      state.internalMaintenanceList = action.payload;
    },
    [fetchInternalMaintenanceListBasedProperty.rejected]: (state, action) => {
      state.propertyInternalMaintenanceList = [];
    },
    [fetchInternalMaintenanceListBasedProperty.fulfilled]: (state, action) => {
      state.propertyInternalMaintenanceList = action.payload;
    },
    [fetchInternalMaintenanceListBasedCommunity.rejected]: (state, action) => {
      state.communityInternalMaintenanceList = [];
    },
    [fetchInternalMaintenanceListBasedCommunity.fulfilled]: (state, action) => {
      state.communityInternalMaintenanceList = action.payload;
    },
    [fetchSearchedInternalMaintenanceList.rejected]: (state, action) => {
      state.searchedInternalMaintenanceList = [];
    },
    [fetchSearchedInternalMaintenanceList.fulfilled]: (state, action) => {
      state.searchedInternalMaintenanceList = action.payload;
    },
  },
});

// To Get The INTERNAL MAINTENANCE List For Home
export const getInternalMaintenanceList = (state) =>
  state.internalMaintenanceDash.internalMaintenanceList;

// To Get The INTERNAL MAINTENANCE List For Property
export const getPropertyInternalMaintenanceList = (state) =>
  state.internalMaintenanceDash.propertyInternalMaintenanceList;

// To Get The INTERNAL MAINTENANCE List For Community
export const getCommunityInternalMaintenanceList = (state) =>
  state.internalMaintenanceDash.communityInternalMaintenanceList;

// To Get The SEARCH INTERNAL MAINTENANCE List For Community
export const getSeacrhedInternalMaintenanceList = (state) =>
  state.internalMaintenanceDash.searchedInternalMaintenanceList;

export const { clearSearchInternalMaintenance } =
  internalMaintenanceInDashboardSlice.actions;

// // To Get The Annoucement Based On ID
// export const getAnnoucementById = (state) => state.annoucement.annoucement;

export default internalMaintenanceInDashboardSlice.reducer;
