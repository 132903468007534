/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { Formik } from "formik";
import moment from "moment";
import { useDispatch } from "react-redux";
import GLOBAL from "../../../../setup/constants/global";
import { useNavigate } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
import { completeTransitionedTenantReview } from "../../../../setup/store/slices/tenantSlice";
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../../setup/store/slices/globalAlertSlice";
import {
  submitTenantApplicationWithoutFee,
  createApplicationInvoice,
  resubmitApplicationData,
} from "../../../../setup/store/slices/tenantSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { EyeFilledBlue } from "../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
import CustomCheckInput from "../../../ui/input/CustomCheckInput/CustomCheckInput";
import BasicDetails from "./sub-component/BasicDetails";
import IDVerification from "./sub-component/IDVerification";
import Disability from "./sub-component/Disability";
import HouseholdMembers from "./sub-component/HouseholdMembers";
import Pets from "./sub-component/Pets";
import Vehicles from "./sub-component/Vehicles";
import RentalHistory from "./sub-component/RentalHistory";
import CommercialDetails from "./sub-component/CommercialDetails";
import SuccessModal from "../../../ui/modal/SuccessModal/SuccessModal";
import ErrorModal from "../../../ui/modal/ErrorModal/ErrorModal";
import TextButton from "../../../ui/button/TextButton/TextButton";
import TermsConditionSignupModal from "../../Auth/TermsConditionSignupModal/TermsConditionSignupModal";
import IconButton from "../../../ui/button/IconButton/IconButton";
/* Import local pages and component ends */

import "./style.scss";
import UnitDetails from "../NewTenantPMReview/sub-component/UnitDetails";
// import Disability from "../NewTenantPMReview/sub-component/Disability";

/* Component starts */
const ReviewTenantApplicaion = (props) => {
  /* Props destructuring starts */
  const {
    application_id,
    application,
    onNext,
    isUnitCommercial,
    tenantType,
    data,
    acceptApplicationFee = true,
    canUpdate = false,
    isContractRenewal = false,
    onUpdate,
    onCancelStep,
    cancelBtn = false,
    propertySetting,
    reviewAgain = false,
    canCancelApplication = false,
    onCancelApplication,
    sequence,
    sequenceNumber = [],
  } = props;

  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [certified, setCertified] = useState(false);
  const [accepted, setAccepted] = useState(false);
  const [message, setMessage] = useState("");
  const [showErrModal, setShowErrModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showTerms, setShowTerms] = useState(false);

  const [showRules, setShowRules] = useState(false);
  const [acceptedRule, setAcceptedRule] = useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const {
    basic_details,
    commercial_details,
    ids,
    household_members,
    disability,
    vehicles,
    pets,
    rental_history,
  } = data;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // After reviewing and proceeding to next step
  const onSubmit = async () => {
    if (!certified || !accepted || !acceptedRule) {
      setMessage(
        !certified
          ? "Please accept the above information is correct."
          : !accepted
          ? "Please read and accept terms and conditions."
          : "Please read and accept property ground rules."
      );
      setShowErrModal(true);
      return;
    }

    // if (!acceptedRule) {
    //   setMessage("Please read and accept property ground rules.");
    //   setShowErrModal(true);
    //   return;
    // }

    if (!checkIfVerificationDocExist()) return;
    if (!checkIfIDsAvailable()) return;
    if (!checkAllDocsValid()) return;
    if (reviewAgain) {
      resubmitApplication();
    } else {
      if (tenantType === GLOBAL.TENANT_TYPE.TRANSITIONED) {
        onboardTransitionedTenant();
      } else {
        if (acceptApplicationFee) {
          // Creating or collecting payload data to be sent
          const payload = {
            application_id: application_id,
          };
          console.log("Payload", payload);

          // Backend response. Try, Catch
          try {
            const result = await dispatch(createApplicationInvoice(payload));
            console.log("Response", result);

            // Handling success response
            switch (result.meta.requestStatus) {
              case GLOBAL.REJECTED:
                dispatch(
                  showErrorAlert({
                    title: "Error!",
                    description: result?.error?.message || "",
                  })
                );
                break;

              case GLOBAL.FULFILLED:
                dispatch(
                  showSuccessAlert({
                    title: "Success!",
                    description: result?.payload?.message || "",
                  })
                );
                onNext();
                break;
            }
          } catch (error) {
            // Handling error response
            console.error(error.message);
          }
        } else {
          sendForReview();
        }
      }
    }
  };

  // On direct onboard transitioned tenant skipping all the next steps ahead
  const onboardTransitionedTenant = async () => {
    // Creating or collecting payload data to be sent
    const payload = { application_id };

    // Backend response. Try, Catch
    try {
      const result = await dispatch(completeTransitionedTenantReview(payload));
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setMessage(result.payload);
          setShowErrModal(true);
          break;
        case GLOBAL.FULFILLED:
          setMessage("Application successfully submitted.");
          setShowSuccessModal(true);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // On sending directly for review without paying application fees
  const sendForReview = async () => {
    const payload = { application_id, is_contract_renewal: isContractRenewal };
    console.log("Payload", payload);
    try {
      const result = await dispatch(submitTenantApplicationWithoutFee(payload));
      console.log("Result", result);
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setMessage(result.payload);
          setShowErrModal(true);
          break;
        case GLOBAL.FULFILLED:
          setMessage("Application submitted successfully.");
          setShowSuccessModal(true);
          break;
      }
    } catch (e) {
      console.error(e.message);
    }
  };

  const resubmitApplication = async () => {
    // Creating or collecting payload data to be sent

    // Backend response. Try, Catch
    try {
      const result = await dispatch(
        resubmitApplicationData({ application_id: application_id })
      );
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description:
                typeof result?.payload === "string" ? result?.payload : "",
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description:
                typeof result?.payload?.message === "string"
                  ? result?.payload?.message
                  : "",
            })
          );
          navigate(-1);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  const checkIfVerificationDocExist = () => {
    let msg = "";
    let exist = false;
    if (isUnitCommercial) {
      if (commercial_details || ids?.length > 0) {
        if (commercial_details) {
          exist = true;
        } else {
          msg = "Commercial Details are missing.";
          exist = false;
        }

        if (ids?.length > 0) {
          exist = true;
        } else {
          msg = "ID Details are missing.";
          exist = false;
        }
      } else {
        msg = "Commercial Details and ID Details are missing.";
        exist = false;
      }
    } else {
      if (ids?.length > 0) {
        exist = true;
      } else {
        msg = "ID Details are missing.";
        exist = false;
      }
    }

    if (!exist) {
      setMessage(msg);
      setShowErrModal(true);
    }

    return exist;
  };

  const checkAllDocsValid = () => {
    let msg = "";
    let expired = false;
    if (isUnitCommercial) {
      if (
        moment(commercial_details?.trade_license_expiry_date).isBefore(moment())
      ) {
        msg = "Commercial ID Expired";
        expired = true;
      }
    }
    for (let i = 0; i < ids.length; i++) {
      let id = ids[i];
      if (moment(id.expiry_date).isBefore(moment())) {
        msg = "ID expired";
        expired = true;
        break;
      }
    }
    if (!isUnitCommercial && household_members?.length > 0) {
      for (let i = 0; i < household_members.length; i++) {
        let h = household_members[i];
        if (moment(h.id_expiry_date).isBefore(moment())) {
          msg = "Household member id expired";
          expired = true;
          break;
        }
      }
    }
    if (expired) {
      setMessage(msg);
      setShowErrModal(true);
    }
    return !expired;
  };

  const checkIfIDsAvailable = () => {
    const residential = data?.is_unit_residential;
    let canSubmit;

    if (residential) {
      canSubmit = ids?.length > 0 ? true : false;
    } else {
      canSubmit =
        ids?.length > 0 &&
        commercial_details &&
        Object.keys(commercial_details).length > 0
          ? true
          : false;
    }

    if (!canSubmit) {
      if (residential) {
        setMessage("Please provide your updated IDs");
      } else {
        setMessage("Please provide your updated IDs and Commercial Details");
      }
      setShowErrModal(true);
    }
    return canSubmit;
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="review-tenant-application-wrapper container_sm">
      <div className="my-2">
        <UnitDetails
          details={application?.application?.unit}
          index="1"
          className="unitDetail-wrapper"
        />
      </div>

      {application &&
        sequence &&
        sequence.map((seq) => {
          switch (seq) {
            case "basic_details":
              return (
                <>
                  <BasicDetails
                    details={basic_details}
                    index={sequenceNumber.indexOf("basic_details") + 2}
                  />
                </>
              );

            case "commercial_details":
              return (
                <>
                  {isUnitCommercial && (
                    <CommercialDetails
                      details={commercial_details}
                      index={sequenceNumber.indexOf("commercial_details") + 2}
                    />
                  )}
                </>
              );

            case "ids":
              return (
                <>
                  <IDVerification
                    data={ids}
                    index={sequenceNumber.indexOf("ids") + 2}
                  />
                </>
              );

            case "household_members":
              return (
                <>
                  {!isUnitCommercial && household_members !== undefined && (
                    <HouseholdMembers
                      data={household_members}
                      index={sequenceNumber.indexOf("household_members") + 2}
                    />
                  )}
                </>
              );

            case "vehicles":
              return (
                <>
                  {vehicles !== undefined && (
                    <Vehicles
                      data={vehicles}
                      index={sequenceNumber.indexOf("vehicles") + 2}
                    />
                  )}
                </>
              );

            case "pets":
              return (
                <>
                  {pets !== undefined && (
                    <Pets
                      data={pets}
                      index={sequenceNumber.indexOf("pets") + 2}
                    />
                  )}
                </>
              );

            case "disability":
              return (
                <>
                  <Disability
                    data={disability}
                    index={sequenceNumber.indexOf("disability") + 2}
                  />
                </>
              );

            case "rental_history":
              return (
                <>
                  <RentalHistory
                    data={rental_history}
                    index={sequenceNumber.indexOf("rental_history") + 2}
                  />
                </>
              );
          }
        })}

      {application && sequence && sequence?.includes("review_again") && (
        <>
          {/* BASIC DETAILS */}
          <BasicDetails
            details={basic_details}
            index={sequenceNumber.indexOf("basic_details") + 2}
          />

          {/* COMMERCIAL DETAILS */}
          {isUnitCommercial && (
            <CommercialDetails
              details={commercial_details}
              index={sequenceNumber.indexOf("commercial_details") + 2}
            />
          )}

          {/* ID DETAILS */}
          <IDVerification
            data={ids}
            index={sequenceNumber.indexOf("ids") + 2}
          />

          {/*HOUSEHOLD DETAILS */}
          {!isUnitCommercial && household_members !== undefined && (
            <HouseholdMembers
              data={household_members}
              index={sequenceNumber.indexOf("household_members") + 2}
            />
          )}

          {/* DISABLITY DETAILS */}
          <Disability
            data={disability}
            index={sequenceNumber.indexOf("disability") + 2}
          />

          {/* VEHICLE DETAILS */}
          {vehicles !== undefined && (
            <Vehicles
              data={vehicles}
              index={sequenceNumber.indexOf("vehicles") + 2}
            />
          )}

          {/* PETS DETAILS */}
          {pets !== undefined && (
            <Pets data={pets} index={sequenceNumber.indexOf("pets") + 2} />
          )}

          {/* RENTAL DETAILS */}
          <RentalHistory
            data={rental_history}
            index={sequenceNumber.indexOf("rental_history") + 2}
          />
        </>
      )}

      <div className="accept-terms">
        <div className="check">
          <CustomCheckInput value={certified} setValue={setCertified} />
          <p className="font_s">
            I certify that the above information is correct.
          </p>
        </div>
        <div className="check">
          <CustomCheckInput
            value={accepted}
            //setValue={setAccepted}
          />
          <p className="font_s">
            I agree and accept{" "}
            <span
              className="termsText"
              onClick={() => {
                setShowTerms(!showTerms);
                setAccepted(!accepted);
              }}
            >
              Terms And Condition
            </span>
          </p>

          {/* <IconButton
            onClick={() => {
              setShowTerms(!showTerms);
              setAccepted(!accepted);
            }}
          >
            <img src={EyeFilledBlue} alt="View ID" />
          </IconButton> */}
        </div>

        <div className="check">
          <CustomCheckInput
            value={acceptedRule}
            //setValue={setAccepted}
          />
          <p className="font_s">
            I agree and accept{" "}
            <span
              className="termsText"
              onClick={() => {
                setShowRules(!showRules);
                setAcceptedRule(!acceptedRule);
              }}
            >
              Property Ground Rules
            </span>
          </p>
        </div>
      </div>

      <div className="button-wrapper">
        <CustomButton size="l" onClick={onSubmit}>
          Submit
        </CustomButton>
        {canUpdate && (
          <CustomButton variant="yellow" size="l" onClick={onUpdate}>
            Update
          </CustomButton>
        )}
      </div>

      {cancelBtn && (
        <div className="button-wrapper text-center">
          <TextButton className="font_m fw_6" onClick={onCancelStep}>
            Back
          </TextButton>
        </div>
      )}

      {canCancelApplication && (
        <div className="cancel-application-btn-wrapper mt-4">
          <CustomButton
            className="cancel-application-btn"
            variant="red"
            size="l"
            onClick={onCancelApplication}
          >
            Cancel Application
          </CustomButton>
        </div>

        // <div className="cancel-application-wrapper bg_grey">
        //   <p className="font_m font_yellow fw_5">
        //     I would like to cancel the onboarding application process. I am
        //     aware that after cancelling the application I won't be able to undo
        //     my action.
        //   </p>
        //   <div className="cancel-application-btn-wrapper">
        //     <CustomButton
        //       className="cancel-application-btn"
        //       variant="red"
        //       size="m"
        //       onClick={onCancelApplication}
        //     >
        //       Cancel Application
        //     </CustomButton>
        //   </div>
        // </div>
      )}

      {/* Modals */}
      {/* Terms & Contions */}
      <TermsConditionSignupModal
        title="Terms And Conditions"
        showModal={showTerms}
        onClose={() => setShowTerms(!showTerms)}
        data={propertySetting?.reservation_term_cond}
      />

      {/* Property Ground Rule */}
      <TermsConditionSignupModal
        title="Property Ground Rules"
        showModal={showRules}
        onClose={() => setShowRules(!showRules)}
        data={propertySetting?.ground_rules}
      />

      {/* Error Modal */}
      <ErrorModal
        showModal={showErrModal}
        title="Error"
        description={message}
        onClose={() => {
          setShowErrModal(false);
        }}
      />

      {/* Success Modal */}
      <SuccessModal
        showModal={showSuccessModal}
        title="Success"
        description={message}
        onClose={() => {
          navigate(-1);
          setShowSuccessModal(false);
        }}
      />
    </div>
  );
};
/* Component ends */

export default ReviewTenantApplicaion;
