/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { gatewayList } from "./helper";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import Stripe from "./sub-component/Stripe";
import CustomSelectInput from "../../../ui/input/CustomSelectInput/CustomSelectInput";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const AddPaymentSetting = (props) => {
  /* Props destructuring starts */
  const { data } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [paymentGateway, setPaymentGateway] = useState();
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    if (data) {
      const selectedGateway = gatewayList.filter(
        (item) => item.gateway === data?.gateway
      );
      setPaymentGateway(selectedGateway[0]);
    } else {
      setPaymentGateway(gatewayList[0]);
    }
  }, [data]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="add-payment-setting-module-wrapper container_sm">
      {/* Selecting payment type */}
      <div className="payment-type form-wrapper bg_grey">
        <div className="form-group">
          <CustomSelectInput
            label="Payment Gateway"
            valueExtractor={(item) => item?.name}
            value={paymentGateway}
            setValue={setPaymentGateway}
            options={gatewayList}
            formikState={false}
            emptyOption={false}
          />
        </div>
      </div>

      {/* Payment type info input */}
      <Stripe data={data} />
    </div>
  );
};
/* Component ends */

export default AddPaymentSetting;
