/**
 *
 */

import React, { useEffect, useState } from "react";

/* Import configuration starts */
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
import { getUser, fetchUser } from "../../../setup/store/slices/authSlice";
import { logout } from "../../../setup/store/slices/masterSlice";
import { logOutUser } from "../../../setup/store/slices/authSlice";
import { clearNotificationToken } from "../../../setup/store/slices/notificationSlice";
import { requestToken } from "../../../setup/utils/notification_config";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import MUI component starts */
/* Import MUI component ends */

/* Import image and SVG starts */
import {
  LogoWhite,
  BellFilledWhite,
  NoProfPicSet,
  SettingsFilledGrey,
  ProfileFilledGrey,
  LogoutFilledGrey,
} from "../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../ui/button/IconButton/IconButton";
import CustomDropDown from "../../ui/other/CustomDropDown/CustomDropDown";
/* Import local pages and component ends */

import "./style.scss";
import { MdOutlineDarkMode } from "react-icons/md";

/* Component starts */
const Header = () => {
  /* Props destructuring starts */
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */

  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Redux Selector
  const user = useSelector(getUser);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const profilePic = user?.myphoto?.url || NoProfPicSet;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // On logout
  const handleLogout = async () => {
    localStorage.clear();
    await dispatch(logOutUser());
    navigate("/signin");
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    dispatch(fetchUser());
    document.body.style.overflow = "auto";
    requestToken();
  }, []);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="header-wrapper">
      <div className="header container_lg">
        <div className="left h_100">
          <Link to="/" className="logo-wrapper h_100">
            <img src={LogoWhite} className="h_100" alt="Logo" />
          </Link>
        </div>
        <div className="right h_100">
          {/* <p className="font_m fw_4">
            Light Mode{" "}
            <span >
              <MdOutlineDarkMode size={25} />
            </span>
          </p> */}
          <IconButton
            onClick={() => navigate("/notification")}
            className="notification"
          >
            <img src={BellFilledWhite} className="h_100" alt="Notification" />
          </IconButton>
          <div className="profile-menu">
            <CustomDropDown
              triggerElement={
                <IconButton
                  style={{
                    backgroundImage: `url(${profilePic})`,
                  }}
                  className="profile-btn"
                />
              }
            >
              <ul className="font_m">
                <li onClick={() => navigate("/profile")}>
                  <img src={ProfileFilledGrey} className="v_middle" alt="" />{" "}
                  <span className="font_grey v_middle"> My Profile</span>
                </li>
                <li onClick={() => navigate("/settings")}>
                  <img src={SettingsFilledGrey} className="v_middle" alt="" />{" "}
                  <span className="font_grey v_middle"> Settings </span>
                </li>
                <li onClick={handleLogout}>
                  <img src={LogoutFilledGrey} className="v_middle" alt="" />{" "}
                  <span className="font_grey v_middle"> Logout </span>
                </li>
              </ul>
            </CustomDropDown>
          </div>
        </div>
      </div>
    </div>
  );
};
/* Component ends */

export default Header;
