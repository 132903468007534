export const generateNavigation = (notification) => {
  const reference = notification?.reference;
  const underCommunity = !!reference?.community_id;
  const communityId = reference?.community_id || "";
  const propertyId = reference?.property_id || "";
  const unitId = reference?.unit_id || "";
  const advertisementId = reference?.advertisement_id || "";
  const invoiceId = reference.invoice_id || "";
  const intMaintenanceId = reference.maintenance_id || "";
  const maintenanceId = reference.maintenance_id || "";
  const securityId = reference?.security_id || "";

  switch (notification?.type) {
    case "community":
      return `/communities/${communityId}/`;

    case "unit_list":
      if (underCommunity) {
        return `/communities/${communityId}/properties/${propertyId}/units`;
      } else {
        return `/independent/properties/${propertyId}/units`;
      }
      break;

    case "unit":
      if (underCommunity) {
        return `/communities/${communityId}/properties/${propertyId}/units/${unitId}`;
      } else {
        return `/independent/properties/${propertyId}/units/${unitId}`;
      }
      break;

    case "property":
      if (underCommunity) {
        return `/communities/${communityId}/properties/${propertyId}`;
      } else {
        return `/independent/properties/${propertyId}`;
      }
      break;

    case "property_setting":
      if (underCommunity) {
        return `/communities/${communityId}/properties/${propertyId}/settings`;
      } else {
        return `/independent/properties/${propertyId}/settings`;
      }
      break;

    case "property_invoice":
      if (underCommunity) {
        return `/communities/${communityId}/properties/${propertyId}/invoicing/?type=Properties/${invoiceId}`;
      } else {
        return `/independent/properties/${propertyId}/invoicing/?type=Properties/${invoiceId}`;
      }

    case "party_invoice":
      if (underCommunity) {
        return `/communities/${communityId}/properties/${propertyId}/invoicing/?type=Parties/${invoiceId}`;
      } else {
        return `/independent/properties/${propertyId}/invoicing/?type=Parties/${invoiceId}`;
      }
      break;

    case "tenant_invoice":
      if (underCommunity) {
        return `/communities/${communityId}/properties/${propertyId}/invoicing/?type=Tenants/${invoiceId}`;
      } else {
        return `/independent/properties/${propertyId}/invoicing/?type=Tenants/${invoiceId}`;
      }
      break;

    case "internal_rating":
    case "internal_quotation":
    case "internal_maintenace":
      if (underCommunity) {
        return `/communities/${communityId}/properties/${propertyId}/internal/maintenances/${intMaintenanceId}`;
      } else {
        return `/independent/properties/${propertyId}/internal/maintenances/${intMaintenanceId}`;
      }

    case "tenant_rating":
    case "move_in":
    case "maintenace":
    case "move_out":
    case "quotation":
      if (underCommunity) {
        return `/communities/${communityId}/properties/${propertyId}/maintenances/${maintenanceId}`;
      } else {
        return `/independent/properties/${propertyId}/maintenances/${maintenanceId}`;
      }

    /* case "security_incident":
        if (underCommunity) {
          return `/communities/${communityId}/properties/${propertyId}/`;
        } else {
          return `/independent/properties/${propertyId}/`;
        } */

    case "invoice":
      return `/license/manage/invoices/${invoiceId}`;

    case "team_onboarding":
      if (underCommunity) {
        return `/communities/${communityId}/properties/${propertyId}/teams`;
      } else {
        return `/independent/properties/${propertyId}/teams`;
      }
    case "":
      if (underCommunity) {
        return `/communities/${communityId}/properties/${propertyId}/`;
      } else {
        return `/independent/properties/${propertyId}/`;
      }

    default:
      break;
  }
};

export const generateNavigationTenant = (notification) => {
  const reference = notification?.reference;
  const underCommunity = !!reference?.community_id;
  const communityId = reference?.community_id || "";
  const propertyId = reference?.property_id || "";
  const unitId = reference?.unit_id || "";
  const advertisementId = reference?.advertisement_id || "";
  const invoiceId = reference.invoice_id || "";
  const intMaintenanceId = reference.maintenance_id || "";
  const maintenanceId = reference.maintenance_id || "";
  const securityId = reference?.security_id || "";
  const moveOutId = reference?.move_out_id || "";
  const contractId = reference?.contract_id || "";

  switch (notification?.type) {
    case "move_out":
      return `/tenant/unit/${unitId}/contracts/${contractId}/moveout/${moveOutId}/clearence`;
      break;

    case "tenant_invoice":
      return `/tenant/unit/${unitId}/invoicing/${invoiceId}`;
      break;

    default:
      break;
  }
};
