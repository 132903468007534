/**
 *
 */

import React from "react";

/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
/* Import local pages and component ends */

/* Component starts */
const UnitDetails = (props) => {
  /* Props destructuring starts */
  const { unitInfo } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="unit-detail-wrapper bg_grey">
      <div className="property_name">
        <div>
          <p className="font_m fw_3 font_grey">
            Property Name
            <br />
            <span className="font_m fw_6">{unitInfo?.property_name}</span>
          </p>
        </div>

        <div>
          <p className="font_m fw_3 font_grey">
            Owner Name
            <br />
            <span className="font_m fw_6">
              {unitInfo?.owner_first_name} {unitInfo?.owner_last_name}
            </span>
          </p>
        </div>
      </div>

      <hr />

      <div className="unit-detail">
        <p className="font_l fw_6">Unit Details</p>

        <div className="unit-num">
          <div>
            <p className="font_m fw_3 font_grey">
              Unit Type <br />
              <span className="font_m fw_4">{unitInfo?.unit_type}</span>
            </p>
          </div>

          <div>
            <p className="font_m fw_3 font_grey">
              Unit Number <br />
              <span className="font_m fw_4">{unitInfo?.unit_number}</span>
            </p>
          </div>

          <div>
            <p className="font_m fw_3 font_grey">
              Unit Description <br />
              <span className="font_m fw_4">
                {unitInfo?.unit_description !== ""
                  ? unitInfo?.unit_description
                  : "-"}
              </span>
            </p>
          </div>
        </div>

        <div className="unit-floor">
          <div>
            <p className="font_m fw_3 font_grey">
              Floor <br />
              <span className="font_m fw_5">
                {unitInfo?.unit_floor !== "" ? unitInfo?.unit_floor : "-"}
              </span>
            </p>
          </div>
          <div>
            <p className="font_m fw_3 font_grey">
              Kitchen
              <br />
              <span className="font_m fw_4">
                {unitInfo?.kitchen !== null ? unitInfo?.kitchen : "-"}
              </span>
            </p>
          </div>
          <div>
            <p className="font_m fw_3 font_grey">
              Bathroom
              <br />
              <span className="font_m fw_4">
                {unitInfo?.bathroom !== null ? unitInfo?.bathroom : "-"}
              </span>
            </p>
          </div>
          <div>
            <p className="font_m fw_3 font_grey">
              Living Room <br />
              <span className="font_m fw_4">
                {unitInfo?.living_room !== null ? unitInfo?.living_room : "-"}
              </span>
            </p>
          </div>

          <div>
            <p className="font_m fw_3 font_grey">
              Laundry
              <br />
              <span className="font_m fw_4">
                {unitInfo?.laundry !== null ? unitInfo?.laundry : "-"}
              </span>
            </p>
          </div>

          <div>
            <p className="font_m fw_3 font_grey">
              Size(Sq.ft.) <br />
              <span className="font_m fw_4">
                {unitInfo?.unit_size !== null ? unitInfo?.unit_size : "-"}
              </span>
            </p>
          </div>

          <div>
            <p className="font_m fw_3 font_grey">
              Toilet
              <br />
              <span className="font_m fw_4">
                {unitInfo?.toilet !== null ? unitInfo?.toilet : "-"}
              </span>
            </p>
          </div>
          <div>
            <p className="font_m fw_3 font_grey">
              Balcony/Patio <br />
              <span className="font_m fw_4">
                {unitInfo?.balcony !== null ? unitInfo?.kitchen : "-"}
              </span>
            </p>
          </div>
          <div>
            <p className="font_m fw_3 font_grey">
              Maid's Room <br />
              <span className="font_m fw_4">
                {unitInfo?.maids_room !== null ? unitInfo?.maids_room : "-"}
              </span>
            </p>
          </div>

          <div>
            <p className="font_m fw_3 font_grey">
              Store <br />
              <span className="font_m fw_4">
                {unitInfo?.store !== null ? unitInfo?.store : "-"}
              </span>
            </p>
          </div>
          <div>
            <p className="font_m fw_3 font_grey">
              Unit Type <br />
              <span className="font_m fw_4">
                {unitInfo?.unit_type !== null ? unitInfo?.unit_type : "-"}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
/* Component ends */

export default UnitDetails;
