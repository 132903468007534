/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";
/* Import configuration ends */

/* Import redux slices component starts */
import { fetchUserDetailByID } from "../../../setup/store/slices/propertySlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Table } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { EyeFilledBlue } from "../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import GLOBAL from "../../../setup/constants/global";
import IconButton from "../../../components/ui/button/IconButton/IconButton";
import CheckboxMultiSelectDropdown from "../../../components/ui/input/CheckboxMultiSelectDropdown/CheckboxMultiSelectDropdown";
import TeamDetailModal from "../../../components/ui/modal/TeamDetailModal/TeamDetailModal";

/* Import local pages and component ends */

/* Component starts */
const TeamsTable = (props) => {
  /* Props destructuring starts */
  const {
    teamsListData,
    multiSelectCommunity,
    setMultiSelectCommunity,
    communityData,
    propertyData,
    multiSelectProperty,
    setMultiSelectProperty,
    statusData1,
    multiSelectStatus,
    setMultiSelectStatus,
    name,
    multiSelectName,
    setMultiSelectName,

    role,
    multiSelectRole,
    setMultiSelectRole,
  } = props;

  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [showTeamDetailModal, setShowTeamDetailModal] = useState(false);
  const [detail, setDetail] = useState();
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  //   const dispatch = useDispatch();

  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  // To View The Item Report
  const handleViewReport = async (item) => {
    const itemData = await dispatch(fetchUserDetailByID(item?.user_id));
    setDetail(itemData?.payload);

    // To SHOW THE MODAL
    setShowTeamDetailModal(true);
  };

  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */

  return (
    <div className="custom-table-wrapper teams-table">
      {teamsListData?.length > 0 && (
        <Table className="custom-table font_s" borderless responsive>
          {/* Table Heading */}
          <thead>
            <tr>
              <th>
                <CheckboxMultiSelectDropdown
                  multiSelectData={multiSelectName}
                  setMultiSelectData={setMultiSelectName}
                  optionLabel={name}
                  label={"Name"}
                />
              </th>
              <th>
                {/* {Object.keys(multiSelectCommunity)?.length > 0 ? ( */}
                <CheckboxMultiSelectDropdown
                  multiSelectData={multiSelectCommunity}
                  setMultiSelectData={setMultiSelectCommunity}
                  // multiSelectData={multiSelectData}
                  // setMultiSelectData={setMultiSelectData}
                  // checkList={{ woodland: false, oasis: false, palm: false }}
                  optionLabel={
                    communityData
                    // getUniqueValues(teamsListData, "community_name")
                    // ["Woodland", "Oasis", "Palm"]
                  }
                  label={"Community"}
                  // setAnyOneSelected={setAnyOneSelected}
                />
                {/* // ) : (
              //   "Community"
              // )} */}
              </th>
              <th>
                <CheckboxMultiSelectDropdown
                  multiSelectData={multiSelectProperty}
                  setMultiSelectData={setMultiSelectProperty}
                  optionLabel={propertyData}
                  label={"property"}
                />
              </th>
              <th>Company</th>
              <th>
                <CheckboxMultiSelectDropdown
                  multiSelectData={multiSelectRole}
                  setMultiSelectData={setMultiSelectRole}
                  optionLabel={role}
                  label={"Role"}
                />
              </th>
              <th>Joing Date</th>
              <th>
                <CheckboxMultiSelectDropdown
                  multiSelectData={multiSelectStatus}
                  setMultiSelectData={setMultiSelectStatus}
                  optionLabel={statusData1}
                  label={"Status"}
                />
              </th>
              <th className="text-start">Action</th>
            </tr>
          </thead>
          {/* Table Body */}
          {teamsListData?.length > 0 && (
            <tbody>
              {teamsListData?.map((item, index) => (
                <tr key={index}>
                  {/* Name */}
                  <td>{item?.name !== "" ? item?.name : "-"}</td>

                  {/* Community */}
                  <td>
                    {item?.community_name !== undefined
                      ? item?.community_name
                      : "-"}
                  </td>

                  {/* Property */}
                  <td>{item?.property_name}</td>

                  {/* Company Name */}
                  <td>
                    {item?.company_name !== undefined
                      ? item?.company_name
                      : "-"}
                  </td>

                  {/* Role */}
                  <td>{item?.role}</td>

                  {/* Joing Date */}
                  <td>
                    {item?.joiningDate !== undefined
                      ? moment(item?.joingDate).format(DATE_FORMAT)
                      : "-"}
                  </td>

                  {/* Status */}
                  <td
                    className={
                      item?.status === "active" ? "font_blue" : "font_red"
                    }
                  >
                    {item?.status?.charAt(0).toUpperCase() +
                      item?.status?.slice(1)}
                  </td>

                  {/* Actions */}
                  <td className="text-start txt-nowrap">
                    <IconButton
                      onClick={() => handleViewReport(item)}
                      className="action-button"
                    >
                      <img
                        src={EyeFilledBlue}
                        className="h_100"
                        alt="View Details"
                      />
                    </IconButton>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </Table>
      )}

      {teamsListData?.length === 0 && (
        <div className=" text-center">
          <h3 className=" font_m font_yellow fw_6">No Data</h3>
        </div>
      )}

      {/* Modals */}
      {/* Team details Modal */}
      <TeamDetailModal
        showModal={showTeamDetailModal}
        detail={detail}
        showApprovalReq={false}
        // requestAction=''
        // user={user}
        onClose={() => {
          setShowTeamDetailModal(false);
        }}
      />
    </div>
  );
};
/* Component ends */
export default TeamsTable;
