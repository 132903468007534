export const typeList = [
  { name: "utility" },
  { name: "shared facility" },
  { name: "other" },
];

export const utilityList = [
  "Electricity",
  "Sewerage",
  "Water",
  "GAS",
  "District Cooling",
  "Telecommunication",
];

export const paymentTypeList = [
  { name: "Online" },
  { name: "Cash" },
  { name: "Cheque" },
  { name: "Other" },
];

export const validations = (values, showUtilityList, fromInvoice) => {
  const error = {};

  // if (values.invoice_num === "") {
  //   error.invoice_num = "Invoice number is required";
  // }

  if (values.unit_number === "") {
    error.unit_number = "Unit number is required";
  }

  if (values.type === "" && !fromInvoice) {
    error.type = "Type is required";
  }

  if (showUtilityList && values.utility === "") {
    error.utility = "Utility is required";
  }

  if (values.description === "") {
    error.description = "Description is required";
  }

  // if (values.quantity === "") {
  //   error.quantity = "Quantity is required";
  // }

  if (values.total === "") {
    error.total = "Total is required";
  }

  return error;
};

export const propertyInvoiceValidations = (values) => {
  const error = {};

  if (values.invoice_num === "") {
    error.invoice_num = "Invoice number is required";
  }

  if (values.type === "") {
    error.type = "Type is required";
  }

  if (values.description === "") {
    error.description = "Description is required";
  }

  if (values.quantity === "") {
    error.quantity = "Quantity is required";
  }

  if (values.total === "") {
    error.total = "Total is required";
  }

  return error;
};

export const partyInvoiceValidations = (values, serviceType) => {
  const error = {};

  // if (values.type === ""  ) {
  //   error.type = "Type is required";
  // }

  if (values.description === "" && serviceType === "Contracts") {
    error.description = "Description is required";
  }

  if (values.total === "" && serviceType === "Contracts") {
    error.total = "Total is required";
  }

  if (values.description === "" && serviceType === "Spare-Parts") {
    error.description = "Description is required";
  }

  return error;
};
