import {
  validEmail,
  validateAtleastOneNumber,
  validateAtleastOneSpecialChar,
} from "../../../../setup/utils/validations";

export const basicDetailInitVal = {
  first_name: "",
  last_name: "",
  email: "",
  mobile: "",
  gender: "",
  nationality: "",
  language: "",
  profession: "",
  business_name: "",
};

export const basicDetailValidation = (values, customPhoneNum) => {
  const errorFields = {};

  /* Validations for First Name Starts */
  if (values.first_name === "") {
    errorFields.first_name = "First Name is required";
  }
  if (values.first_name && validateAtleastOneNumber(values.first_name)) {
    errorFields.first_name = "First Name Cannot Contains Numbers";
  }
  if (values.first_name && validateAtleastOneSpecialChar(values.first_name)) {
    errorFields.first_name = "First Name Cannot Contains Special Characters";
  }
  /* Validations for First Name Ends */

  /* Validations for Last Name Starts */
  if (values.last_name === "") {
    errorFields.last_name = "Last Name is required";
  }
  if (values.last_name && validateAtleastOneNumber(values.last_name)) {
    errorFields.last_name = "Last Name Cannot Contains Numbers";
  }
  if (values.last_name && validateAtleastOneSpecialChar(values.last_name)) {
    errorFields.last_name = "Last Name Cannot Contains Special Characters";
  }
  /* Validations for Last Name Ends */

  /* Validations for Email Starts */
  if (values.email === "") {
    errorFields.email = "Email is required";
  }
  if (values.email && !validEmail(values.email)) {
    errorFields.email = "Email is not valid";
  }
  /* Validations for Email Ends */

  /* Validations for Gender Starts */
  if (values.gender === "") {
    errorFields.gender = "Gender is required";
  }
  /* Validations for Gender Ends */

  /* Validations for Phone Number Starts */
  if (values.mobile === "") {
    errorFields.mobile = "Phone Number is required";
  }
  if (values.mobile && customPhoneNum.current.num.length < 8) {
    errorFields.mobile = "Phone number is not valid";
  }
  /* Validations for Phone Number Ends */

  /* Validations for Nationality Starts */
  if (values.nationality === "") {
    errorFields.nationality = "Nationality is required";
  }
  /* Validations for Nationality Ends */

  /* Validations for Language Starts */
  if (values.language === "") {
    errorFields.language = "Language is required";
  }
  /* Validations for Language Ends */

  return errorFields;
};

export const basicDetailFormPrefill = (
  data,
  setInitialFormValues,
  setDob,
  customPhoneNum
) => {
  setInitialFormValues({
    first_name: data?.first_name || "",
    last_name: data?.last_name || "",
    email: data?.email || "",
    mobile: data?.countrycode + " " + data?.mobile,
    gender: data?.gender || "",
    nationality: data?.nationality || "",
    language: data?.language || "",
    profession: data?.profession || "",
    business_name: data?.business_name || "",
  });
  setDob(data?.dob ? new Date(data?.dob) : new Date());
  customPhoneNum.current = { code: data?.countrycode, num: data?.mobile };
};
