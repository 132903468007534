/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import moment from "moment";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import GLOBAL from "../../setup/constants/global";
import Header from "./local-component/Header";
import Report from "./local-component/Report";
/* Import local pages and component ends */

// Styles
import "./style.scss";
import { useLocation } from "react-router-dom";
import { Formik } from "formik";
import CustomTextArea from "../../components/ui/input/CustomTextArea/CustomTextArea";
import CustomButton from "../../components/ui/button/CustomButton/CustomButton";
import IconButton from "../../components/ui/button/IconButton/IconButton";
import { DocFilledWhite, DocumentGreen } from "../../setup/constants/images";

/* Component starts */
const RoutineTaskDetail = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const { state } = useLocation();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */

  const logs = [
    {
      id: "1",
      technician: "Janet Jhonson",
      action: "Assigned",
      updated: "10/08/2023 10:30 AM",
      role: "Technician",
    },
    {
      id: "2",
      technician: "Janet Jhonson",
      action: "Marked Completed",
      updated: "10/08/2023 11:30 AM",
      role: "Technician",
    },
    {
      id: "3",
      technician: "Janet Jhonson",
      action: "Reviewed By Technician",
      role: "Technician",
      updated: "10/08/2023 12:30 AM",
      nextDue: "10/08/2023 01:30 PM",
    },
  ];

  const initialFormValues = {
    remark: "",
  };

  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  const onSubmit = (values) => {
    console.log(values);
  };

  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="routine-task-detail-wrapper">
      <div className="header-security">
        <Header />
      </div>

      <div className="report-wrapper">
        <div className="detail-box bg_grey">
          <Report task={state} />
        </div>

        {/* TIME-LINE */}

        {logs?.reverse().map((timeLine, index) => (
          <div className={`detail-box-timeLine mt-3 bg_grey`} key={index}>
            <div className="main-timeline-wrapper">
              <div className="dot">
                <div className="dot-1">
                  <span className="dot-2"></span>
                </div>
                <div className="vl"></div>
              </div>
              <div className="detail">
                {timeLine?.nextDue === undefined ? (
                  <div className="data">
                    <div className="name">
                      <div>
                        <p className="font_s fw_4 font_grey">
                          {timeLine?.role}
                          <span className="font_s fw_4 ">
                            {timeLine?.technician}
                          </span>
                        </p>
                      </div>
                      <div>
                        <p className="font_s fw_4 font_grey">
                          {moment(timeLine.updated).format(DATE_TIME_FORMAT)}
                        </p>
                      </div>
                    </div>
                    <div className="current-status">
                      {timeLine?.action !== undefined && (
                        <p className="font_m fw_4 font_grey">
                          <span className="font_xs fw_4">
                            {timeLine?.action}
                          </span>
                        </p>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="data">
                    <div className="name">
                      <div>
                        <p className="font_s fw_4 font_grey">
                          {timeLine?.role}
                          <span className="font_s fw_4 ">
                            {timeLine?.technician}
                          </span>
                        </p>
                      </div>
                      <div>
                        <p className="font_s fw_4 font_grey">
                          {moment(timeLine.updated).format(DATE_TIME_FORMAT)}
                        </p>
                      </div>
                    </div>
                    <div className="current-status">
                      {timeLine?.action !== undefined && (
                        <p className="font_m fw_4 font_grey">
                          <span className="font_xs fw_4">
                            {timeLine?.action}
                          </span>
                        </p>
                      )}
                    </div>
                    <hr />
                    <div className="remark-wrapper">
                      <div className="task-descr">
                        <div>
                          <p className="font_xs fw_5">Task Description</p>
                        </div>
                        <div className="desc">
                          <p className="font_xs fw_4 font_grey">
                            Many desktop publishing packages and web page
                            editors now their default model many web sites still
                            in their infancy.
                          </p>
                        </div>
                      </div>
                      <div className="uploaded-doc">
                        <div>
                          <p className="font_xs fw_5">Uploaded Documents</p>
                        </div>
                        <div className="doc-wrapper">
                          <div className="doc">
                            <img src={DocumentGreen} alt="View ID" />
                            <p className="mt-2 font_xs">
                              Untitled 1 -{" "}
                              <span className="font_xxs font_blue text-decoration-underline">
                                Download
                              </span>
                            </p>
                          </div>

                          <div className="doc">
                            <img src={DocumentGreen} alt="View ID" />
                            <p className="mt-2 font_xs">
                              Untitled 2 -{" "}
                              <span className="font_xxs font_blue text-decoration-underline">
                                Download
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <p className="font_xs fw_3">I have reviewed the task.</p>
                      <div className="add-remark">
                        <Formik
                          onSubmit={onSubmit}
                          initialValues={initialFormValues}
                          validateOnBlur={true}
                          enableReinitialize={true}
                        >
                          {({
                            values,
                            errors,
                            touched,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                          }) => (
                            <form onSubmit={handleSubmit}>
                              {/* Submit and Cancel */}
                              <div className="button-wrapper text-center mb-3">
                                <CustomButton type="submit" size="l">
                                  Reviewed
                                </CustomButton>
                              </div>

                              {/* Form Fields */}
                              <div className="form-wrapper form-wrapper-flex">
                                <div className="form-group form-group-full">
                                  <CustomTextArea
                                    name="remark"
                                    label="Enter Remarks"
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    value={values.remark}
                                    errors={errors.remark}
                                    touched={touched.remark}
                                    required={false}
                                  />
                                </div>
                              </div>
                            </form>
                          )}
                        </Formik>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
/* Component ends */

export default RoutineTaskDetail;
