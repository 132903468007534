/**
 * This component is for custom text input field
 */

import React from "react";

// import "./style.scss";
import V1 from "./variants/V1";
import V2 from "./variants/V2";

/* Component starts */
const CustomTextInput = (props) => {
  /* Props destructuring starts */
  const {
    type = "text",
    label,
    handleChange,
    value,
    name,
    errors,
    handleBlur,
    touched,
    required = true,
    disabled = false,
    variant = "1",
    readOnly = false,
  } = props;
  /* Props destructuring ends */

  /* ************* Component rendering. JSX code ************* */
  // If variant 1 is selected
  if (variant === "1") return <V1 {...props} />;
  if (variant === "2") return <V2 {...props} />;

  // return (
  //   <div className="custom-form-control">
  //     <div className="form-input-wrapper">
  //       <label className="font_s font_grey">
  //         {label}
  //         {required && <span className="font_m font_red"> *</span>}
  //       </label>
  //       <input
  //         type={type}
  //         onBlur={handleBlur}
  //         onChange={handleChange}
  //         value={value}
  //         name={name}
  //         className="bg_black font_xs"
  //         disabled={disabled}
  //         readOnly={readOnly}
  //       />
  //     </div>

  //     {touched && errors && <p className="error font_xxs font_red">{errors}</p>}
  //   </div>
  // );
};
/* Component ends */

export default CustomTextInput;
