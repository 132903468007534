/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useDispatch, useSelector } from "react-redux";
import { FaChevronDown, FaChevronRight } from "react-icons/fa";
/* Import configuration ends */

/* Import redux slices component starts */
import { fetchFAQsList, getFAQsList } from "../../setup/store/slices/faqsSlice";
import { getIsTENANT } from "../../setup/store/slices/authSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import Searchbar from "../../components/ui/other/Searchbar/Searchbar";
import BreadCrumb from "../../components/ui/other/BreadCrumb/BreadCrumb";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const FAQsPage = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [activeIndex, setActiveIndex] = useState(null);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const faqs = useSelector(getFAQsList);
  const isTenant = useSelector(getIsTENANT);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const handleAccordionClick = (index) => {
    if (activeIndex === index) {
      return setActiveIndex(null);
    }

    setActiveIndex(index);
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    dispatch(fetchFAQsList());
  }, []);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      {!isTenant && <BreadCrumb type="settings_FAQs_page" />}
      <div className="faqs-page-wrapper container_sm">
        <h2 className="font_xxl heading text-center">
          Frequently Asked Questions
        </h2>

        <Searchbar
          // setQuery={setSearch}
          // query={search}
          className="dash-search my-4"
        />

        <div className="accordion">
          {faqs?.map((item, index) => (
            <div className="item" key={item?._id}>
              <div
                className="title"
                onClick={() => handleAccordionClick(index)}
              >
                <h2 className="font_m fw_6">{item?.title}</h2>
                <span>
                  {activeIndex === index ? (
                    <FaChevronDown size={22} className="icon" />
                  ) : (
                    <FaChevronRight size={22} className="icon" />
                  )}
                </span>
              </div>
              <div
                className={`${
                  activeIndex === index ? "content show" : "content"
                } font_s fw_4 font_grey`}
              >
                {item?.description}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
/* Component ends */

export default FAQsPage;
