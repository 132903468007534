/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useSelector, useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
import { getUser } from "../../setup/store/slices/authSlice";
import {
  fetchUserRoles,
  getUserRoles,
} from "../../setup/store/slices/masterSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
/* Import local pages and component ends */

/* Component starts */
const HomePageController = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [userRole, setUserRole] = useState(null);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();

  // Redux Selector
  const user = useSelector(getUser);
  const userRoles = useSelector(getUserRoles);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const loggedInUserData =
    localStorage.getItem("user_data") &&
    JSON.parse(localStorage.getItem("user_data"));
  const loggedInUserRole = localStorage.getItem("user_role");
  const loggedInUserToken = localStorage.getItem("user_token");
  const userLoggedIn =
    loggedInUserData && loggedInUserRole && loggedInUserToken;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  // Fetching all user roles on initial load
  useEffect(() => {
    // dispatch(fetchUserRoles());
  }, []);

  // Setting current user role
  // useEffect(() => {
  //   if (user && userRoles?.length > 0) {
  //     setUserRole(
  //       () => userRoles.filter((item) => item._id === user.role_id)[0]
  //     );
  //   }
  // }, [user, userRoles]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  if (userLoggedIn) {
    if (loggedInUserRole === "Tenant") {
      return <Navigate to="/tenant/home" replace />;
    } else {
      return <Navigate to="/provider/home" replace />;
    }
  }
};
/* Component ends */

export default HomePageController;
