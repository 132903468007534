/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  fetchUserRoles,
  getUserRoles,
} from "../../../../setup/store/slices/masterSlice";
import { fetchUser, getUser } from "../../../../setup/store/slices/authSlice";
import {
  fetchUsersAnnoucementsList,
  getUsersAnnoucementsList,
} from "../../../../setup/store/slices/annoucementSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import Annoucement from "./local-component/Annoucement";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const TenantAnnoucement = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [role, setRole] = useState();
  const [highlightedItems, setHighlightedItems] = useState([]);
  const [cookies, setCookie] = useCookies(["openedItems"]);
  const [bgColor, setBgColor] = useState("");

  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const { state } = useLocation();
  const propertyId = state?.property_id;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userRoles = useSelector(getUserRoles);
  const user = useSelector(getUser);
  const annoucementList = useSelector(getUsersAnnoucementsList);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const handleViewReport = async (id) => {
    // Add the clicked item to cookies
    const openedItems = cookies?.openedItems || [];
    if (!openedItems?.includes(id)) {
      openedItems?.push(id);
      setBgColor("bg_lightishgrey");
      setCookie("openedItems", openedItems);
    }

    navigate(`${id}`, { state: role });
  };
  /* Component function definition ends */

  /* Component useEffect starts */

  useEffect(() => {
    dispatch(fetchUserRoles());
    dispatch(fetchUser());
  }, []);

  // Setting role after all user roles loaded
  useEffect(() => {
    let userRole = userRoles.filter((d) => d?._id === user?.role_id)[0]?.name;
    setRole(userRole);
  }, [userRoles]);

  useEffect(() => {
    if (propertyId) {
      dispatch(fetchUsersAnnoucementsList({ property_id: propertyId }));
    }
  }, [propertyId]);

  useEffect(() => {
    // Update highlighted incidentList based on cookies
    const openedItems = cookies?.openedItems || [];
    const newItems = annoucementList?.filter(
      (item) => !openedItems?.includes(item?._id)
    );

    setHighlightedItems(newItems);
  }, [annoucementList, cookies?.openedItems]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="tenant-annoucement-wrapper">
      <div className="heading">
        <h2 className="font_xxl fw_5">Annoucement</h2>
      </div>

      <div className="container_sm bg_grey annoucemnt-list-wrapper">
        {annoucementList?.length > 0 &&
          annoucementList?.map((annoucement) => (
            <>
              {highlightedItems?.includes(annoucement) ? (
                <div
                  key={annoucement?._id}
                  onClick={() => handleViewReport(annoucement?._id)}
                >
                  <Annoucement annoucement={annoucement} bgColor={bgColor} />
                </div>
              ) : (
                <div
                  key={annoucement?._id}
                  onClick={() => handleViewReport(annoucement?._id)}
                >
                  <Annoucement annoucement={annoucement} />
                </div>
              )}
            </>
          ))}

        {annoucementList?.length === 0 && (
          <p className="font_l fw_4 font_yellow text-center">No Annoucement</p>
        )}
      </div>
    </div>
  );
};
/* Component ends */

export default TenantAnnoucement;
