/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import moment from "moment";

import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  deleteTenantLostItemById,
  fetchItemBasedOnId,
} from "../../../../../setup/store/slices/lostAndFoundSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Table } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import {
  DeleteRed,
  EyeFilledBlue,
} from "../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import GLOBAL from "../../../../../setup/constants/global";
import IconButton from "../../../../ui/button/IconButton/IconButton";
import ConfirmationModal from "../../../../ui/modal/ConfirmationModal/ConfirmationModal";
import ErrorModal from "../../../../ui/modal/ErrorModal/ErrorModal";
/* Import local pages and component ends */

// Styles
import "./style.scss";
import Pagination from "../../../../ui/other/Pagination/Pagination";

/* Component starts */
const TenantLostTable = (props) => {
  /* Props destructuring starts */
  const { lostList, totalCount, currentPage, setCurrentPage } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [reportDetail, setreportDetail] = useState({});
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [modalDesc, setModalDesc] = useState("");
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  const dispatch = useDispatch();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  // To View The Item Report
  const handleViewReport = async (id) => {
    const itemData = await dispatch(fetchItemBasedOnId(id));
    navigate(`${id}`, {
      state: itemData.payload,
    });
  };

  // To View The Item Report
  const handleDeleteReport = async (item) => {
    setreportDetail(item);
    setModalDesc("Are you sure you want to delete this lost item ?");
    setShowConfirmationModal(true);
  };

  // On confirming delete
  const confirmDelete = async () => {
    // Collecting or creating payload data to be sent

    const payload = {
      id: reportDetail?._id,
      unit_id: reportDetail?.unit?._id,
      tenant_id: reportDetail?.tenant_id?._id,
    };

    // Backend response. Try, catch
    try {
      const result = await dispatch(deleteTenantLostItemById(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setModalDesc(result.payload);
          setShowConfirmationModal(false);
          setShowErrorModal(true);
          break;
        case GLOBAL.FULFILLED:
          setModalDesc("Lost Item Successfully Deleted");
          setShowConfirmationModal(false);
          // setShowSuccessModal(true);
          break;
      }
    } catch (error) {
      // Handling error response
      console.log("error", error);
    }
  };

  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */

  return (
    <>
      <div className="custom-table-wrapper tenant-lost-table">
        {lostList?.length > 0 && (
          <Table className="custom-table font_s" borderless responsive>
            {/* Table Heading */}
            <thead>
              <tr>
                <th>Serial No</th>
                <th>ID</th>
                <th>Name </th>
                <th>Date </th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            {/* Table Body */}
            <tbody>
              {lostList?.map((item, index) => (
                <tr key={item?._id}>
                  {/* ID */}
                  <td>{index + 1}</td>
                  {/* ID */}
                  <td>{item?.lostfound_id}</td>

                  {/* Item Name */}
                  <td>{item?.item_name}</td>

                  {/* Date  */}
                  <td>{moment(item?.date_time).format(DATE_FORMAT)}</td>

                  {/* Status */}
                  <td
                    className={`${
                      item?.status === "Open" ? "font_blue" : "font_red"
                    }`}
                  >
                    {item?.status}
                  </td>

                  {/* Actions */}
                  <td className="txt-nowrap">
                    <IconButton
                      onClick={() => handleViewReport(item?._id)}
                      className="action-button"
                    >
                      <img
                        src={EyeFilledBlue}
                        className="h_100"
                        alt="View Details"
                      />
                    </IconButton>

                    <IconButton
                      onClick={() => handleDeleteReport(item)}
                      className="action-button"
                    >
                      <img src={DeleteRed} className="h_100" alt="Delete" />
                    </IconButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}

        {lostList?.length === 0 && (
          <div className=" text-center">
            <h3 className=" font_m font_yellow fw_6">No Data</h3>
          </div>
        )}

        {/* Confirmation Modal */}
        <ConfirmationModal
          showModal={showConfirmationModal}
          description={modalDesc}
          title="Confirm Delete"
          onClose={() => setShowConfirmationModal(false)}
          onConfirm={confirmDelete}
        />

        {/* Error Modal */}
        <ErrorModal
          showModal={showErrorModal}
          description={modalDesc}
          title="Error"
          onClose={() => setShowErrorModal(false)}
        />
      </div>

      {/* PAGINATION */}
      {lostList?.length > 0 && (
        <Pagination
          itemsPerPage="10"
          totalItems={totalCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
    </>
  );
};
/* Component Ends */
export default TenantLostTable;
