/**
 *
 */

import React from "react";
import { useNavigate } from "react-router-dom";
/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Table } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import {
  DeleteGrey,
  DeleteRed,
  EditGreen,
  EditWhite,
  EyeFilledBlue,
} from "../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../../../ui/button/IconButton/IconButton";
/* Import local pages and component ends */

// Styles
import "./style.scss";

/* Component starts */
const OpenTasks = (props) => {
  /* Props destructuring starts */
  const {} = props;

  /* Props destruc/turing ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */

  // Dummy Data
  const violationList = [
    {
      id: "TK011",
      start_date: "09/08/2023 10:30AM",
      end_date: "19/08/2023 10:30AM",
      subject: "Testing Subject",
      description:
        "Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy.",
      frequency: "Hourly",
      assigned_to: "Lewis Peter",
      next_due_date: "09/08/2023 10:30PM",
      status: "Open",
    },
    {
      id: "TK012",
      start_date: "09/08/2023 10:30AM",
      end_date: "19/08/2023 10:30AM",
      subject: "Testing Subject Week",
      description:
        "Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy.",

      frequency: "Weekly",
      assigned_to: "John Cena",
      status: "Pending",
      next_due_date: "14/08/2023 10:30PM",
    },
    {
      id: "TK013",
      start_date: "09/08/2023 10:30AM",
      end_date: "19/10/2023 10:30AM",
      subject: "Testing Subject Month",
      description:
        "Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy.",

      frequency: "Monthly",
      assigned_to: "Jovi Tenant",
      status: "Closed",

      next_due_date: "09/09/2023 10:30PM",
    },
  ];

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  // function getDate(date) {
  //   let theDate = new Date(Date.parse(date));

  //   return (
  //     theDate.toLocaleDateString() + " " + theDate.toLocaleTimeString("en-US")
  //   );
  // }

  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */

  return (
    <div className="custom-table-wrapper open-task-table-wrapper">
      <Table className="custom-table font_s" borderless responsive>
        {/* Table Heading */}
        <thead>
          <tr>
            <th>ID</th>
            <th>Start Date & Time</th>
            <th>End Date & Time</th>
            <th>Subject</th>
            <th>Frequency</th>
            <th>Assigned to</th>
            <th>Next Due Date & Time</th>
            <th>Actions</th>
          </tr>
        </thead>
        {/* Table Body */}
        <tbody>
          {violationList?.map((item) => (
            <tr key={item.id}>
              {/* ID */}

              <td>{item?.id}</td>

              {/* Start Date */}
              <td>{item?.start_date}</td>

              {/* End Date */}
              <td>{item?.end_date}</td>

              {/* Subject */}
              <td>{item?.subject}</td>

              {/* Frequency */}
              <td>{item?.frequency}</td>

              {/* Assigned To */}
              <td>{item?.assigned_to}</td>

              {/* Next Due Date & Time */}
              <td>{item?.next_due_date}</td>

              {/* Actions */}
              <td className="d-flex justify-content-between gap-1">
                <IconButton
                  onClick={() =>
                    navigate("/property/routine-request/detail", {
                      state: { item },
                    })
                  }
                  className="action-button"
                >
                  <img
                    src={EyeFilledBlue}
                    className="h_100"
                    alt="View Details"
                  />
                </IconButton>
                <IconButton
                  // onClick={() =>
                  //   navigate("/tenant/guest-pass/edit", {
                  //     state: { item },
                  //   })
                  // }
                  className="action-button"
                >
                  <img src={EditGreen} className="h_100" alt="View Details" />
                </IconButton>
                <IconButton
                  // onClick={() =>
                  //   navigate("/tenant/guest-pass/edit", {
                  //     state: { item },
                  //   })
                  // }
                  className="action-button"
                >
                  <img src={DeleteRed} className="h_100" alt="View Details" />
                </IconButton>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};
/* Component Ends */
export default OpenTasks;
