/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import moment from "moment";
import GLOBAL from "../../../../../setup/constants/global";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Table } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
/* Import local pages and component ends */

/* Component starts */
const InititalAgreementInstalmentPlan = (props) => {
  /* Props destructuring starts */
  const { settings, currency, installmentPlan, installments, isRenewal } =
    props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";

  const collectTax = settings?.collect_tax_enabled;
  const taxValue = settings?.collect_tax_value;
  const collectVat = settings?.collect_vat_enabled;
  const vatValue = settings?.collect_vat_value;

  const startDate = moment(installmentPlan?.start_date).format(DATE_FORMAT);
  const endDate = moment(installmentPlan?.end_date).format(DATE_FORMAT);
  const numInstallment = installmentPlan?.no_of_installments;
  const duration = installmentPlan?.duration;
  const collectCommission = installmentPlan?.commission;
  const commisionAmt = installmentPlan?.commission_amount;
  const collectCommissionVat =
    collectVat && installmentPlan?.collect_commission_vat;
  const collectCommissionTax =
    collectTax && installmentPlan?.collect_commission_tax;
  const collectDeposit = installmentPlan?.deposit;
  const depositAmt = installmentPlan?.deposit_amount;
  const collectDepositVat = collectVat && installmentPlan?.collect_deposit_vat;
  const collectDepositTax = collectTax && installmentPlan?.collect_deposit_tax;
  const collectCheque = installmentPlan?.cheque;
  const totalRent = installmentPlan?.total_rent;
  const collectInstallmentVat =
    collectVat && installmentPlan?.collect_instalment_vat;
  const collectInstallmentTax =
    collectTax && installmentPlan?.collect_instalment_tax;
  const depositData = installmentPlan?.deposit_data;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const getTotal = (amt, collectVat, collectTax) => {
    let amount = amt;
    if (collectVat && settings?.collect_vat_enabled) {
      amount += (amt * settings?.collect_vat_value) / 100;
    }
    if (collectTax && settings?.collect_tax_enabled) {
      amount += (amt * settings?.collect_tax_value) / 100;
    }
    // return `${amount?.toLocaleString("en-US")}`;
    return `${amount?.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`;
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  if (settings && currency && installmentPlan && installments) {
    return (
      <div className="installment-plan-detail-wrapper">
        <h3 className="font_xl fw_6 installment-plan-heading font_blue">
          Installment Plan Details
        </h3>
        <div className="summary">
          <h3 className="section-heading font_m fw_6">
            {isRenewal ? "Renewal Contract Terms" : "Current Contract Terms"}
          </h3>
          <div className="custom-row custom-row-flex custom-row-flex-2">
            <div className="detail-box start-date-detail custom-column">
              <p className="label fw_5 font_s font_grey">Contract Start Date</p>
              <p className="value fw_5 font_s ">{startDate}</p>
            </div>
            <div className="detail-box end-date-detail custom-column">
              <p className="label fw_5 font_s font_grey">Contract End Date</p>
              <p className="value fw_5 font_s ">{endDate}</p>
            </div>
          </div>
          <div className="custom-row custom-row-flex custom-row-flex-2">
            <div className="detail-box duration-detail custom-column">
              <p className="label fw_5 font_s font_grey">Contract Duration</p>
              <p className="value fw_5 font_s ">{duration} Months</p>
            </div>
            <div className="detail-box installment-num-detail custom-column">
              <p className="label fw_5 font_s font_grey">
                Number Of Installments
              </p>
              <p className="value fw_5 font_s ">{numInstallment}</p>
            </div>
          </div>
          <div className="custom-row custom-row-flex custom-row-flex-2">
            <div className="detail-box total-rent-detail custom-column">
              <p className="label fw_5 font_s font_grey">
                Total Rent ({currency}) <br />
                {(collectInstallmentTax || collectInstallmentVat) && (
                  <>
                    {collectInstallmentTax && (
                      <span className="font_xs font_grey">
                        TAX({taxValue}%)
                      </span>
                    )}
                    <br />
                    {collectInstallmentVat && (
                      <span className="font_xxs font_grey">
                        VAT({vatValue}%)
                      </span>
                    )}
                    <br />
                    <span className="font_xxs font_grey">
                      Total ({currency})
                    </span>
                  </>
                )}
              </p>
              <p className="value fw_5 font_s ">
                {totalRent.toLocaleString("en-US")} <br />
                {(collectInstallmentTax || collectInstallmentVat) && (
                  <>
                    {collectInstallmentTax && (
                      <span className="font_xxs font_grey">
                        {((totalRent * taxValue) / 100)?.toLocaleString(
                          undefined,
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}
                      </span>
                    )}
                    <br />
                    {collectInstallmentVat && (
                      <span className="font_xxs font_grey">
                        {((totalRent * vatValue) / 100)?.toLocaleString(
                          undefined,
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}
                      </span>
                    )}
                    <br />
                    <span className="font_xxs font_grey">
                      {getTotal(
                        totalRent,
                        collectInstallmentVat,
                        collectInstallmentTax
                      )}
                    </span>
                  </>
                )}
              </p>
            </div>
            {collectDeposit && (
              <div className="detail-box deposit-detail custom-column">
                <p className="label fw_5 font_s font_grey">
                  Total Deposit ({currency}) <br />
                  {(collectDepositTax || collectDepositVat) && (
                    <>
                      {collectDepositTax && (
                        <span className="font_xxs font_grey">
                          TAX({taxValue}%)
                        </span>
                      )}
                      <br />
                      {collectDepositVat && (
                        <span className="font_xxs font_grey">
                          VAT({vatValue}%)
                        </span>
                      )}
                      <br />
                      <span className="font_xxs font_grey">
                        Total ({currency})
                      </span>
                    </>
                  )}
                </p>
                <p className="value fw_5 font_s ">
                  {depositAmt.toLocaleString("en-US")} <br />
                  {(collectDepositTax || collectDepositVat) && (
                    <>
                      {collectDepositTax && (
                        <span className="font_xxs font_grey">
                          {((depositAmt * taxValue) / 100)?.toLocaleString(
                            undefined,
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )}
                        </span>
                      )}
                      <br />
                      {collectDepositVat && (
                        <span className="font_xxs font_grey">
                          {((depositAmt * vatValue) / 100)?.toLocaleString(
                            undefined,
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )}
                        </span>
                      )}
                      <br />
                      <span className="font_xxs font_grey">
                        {getTotal(
                          depositAmt,
                          collectDepositVat,
                          collectDepositTax
                        )}
                      </span>
                    </>
                  )}
                </p>
              </div>
            )}
          </div>
          {collectCommission && (
            <div className="custom-row">
              <div className="detail-box commission-detail custom-column">
                <p className="label fw_5 font_s font_grey">
                  Commission({currency}) <br />
                  {(collectCommissionTax || collectCommissionVat) && (
                    <>
                      {collectCommissionTax && (
                        <span className="font_xxs font_grey">
                          TAX({taxValue}%)
                        </span>
                      )}
                      <br />
                      {collectCommissionVat && (
                        <span className="font_xxs font_grey">
                          VAT({vatValue}%)
                        </span>
                      )}
                      <br />
                      Total({currency})
                    </>
                  )}
                </p>
                <p className="value fw_5 font_s ">
                  {commisionAmt.toLocaleString("en-US")} <br />
                  {(collectCommissionTax || collectCommissionVat) && (
                    <>
                      {collectCommissionTax && (
                        <span className="font_xxs font_grey">
                          {((commisionAmt * taxValue) / 100)?.toLocaleString(
                            undefined,
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )}
                        </span>
                      )}
                      <br />
                      {collectCommissionVat && (
                        <span className="font_xxs font_grey">
                          {((commisionAmt * vatValue) / 100)?.toLocaleString(
                            undefined,
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )}
                        </span>
                      )}
                      <br />
                      <span className="font_xxs font_grey">
                        {getTotal(
                          commisionAmt,
                          collectCommissionVat,
                          collectCommissionTax
                        )}
                      </span>
                    </>
                  )}
                </p>
              </div>
            </div>
          )}
          {collectCheque && (
            <div className="custom-row">
              <p className="font_s fw_6 font_yellow">Receive Rent By Cheques</p>
            </div>
          )}
        </div>

        {collectDeposit && (
          <div className="deposit-list">
            <h3 className="table-section-heading font_m fw_6">Deposits</h3>
            <div className="custom-table-wrapper">
              <Table className="custom-table font_s" borderless responsive>
                {/* Table Heading */}
                <thead>
                  <tr>
                    <th className="">
                      <p className="font_s fw_6 font_grey">Description</p>
                    </th>
                    <th className="">
                      <p className="font_s fw_6 font_grey">
                        Amount ({currency})
                      </p>
                    </th>
                    <th>
                      <p className="font_s fw_6 font_grey">Due Date</p>
                    </th>
                  </tr>
                </thead>
                {/* Table Body */}
                <tbody>
                  {depositData?.map((item, index) => (
                    <tr key={index} className="install">
                      {/* Name */}
                      <td>
                        <p className="font_s fw_5">{item?.description}</p>

                        {collectDepositVat && (
                          <p className="font_xxs fw_5">VAT({vatValue}%)</p>
                        )}

                        {collectDepositTax && (
                          <p className="font_xxs fw_5">Tax({taxValue}%)</p>
                        )}

                        {(collectDepositVat || collectDepositTax) && (
                          <p className="font_xxs fw_5">Total</p>
                        )}
                      </td>
                      {/* Amount */}
                      <td className="amount-column-wrapper">
                        <p className="font_s fw_5">
                          {parseInt(item?.deposit_amount)?.toLocaleString(
                            undefined,
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )}
                        </p>

                        {collectDepositVat && (
                          <p className="font_xxs fw_5">
                            {item?.deposit_amount
                              ? (
                                  (parseFloat(item?.deposit_amount) *
                                    vatValue) /
                                  100
                                )?.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : 0}
                          </p>
                        )}

                        {collectDepositTax && (
                          <p className="font_xxs fw_5">
                            {item?.deposit_amount
                              ? (
                                  (parseFloat(item?.deposit_amount) *
                                    taxValue) /
                                  100
                                )?.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : 0}
                          </p>
                        )}

                        {(collectDepositVat || collectDepositTax) && (
                          <p className="font_xxs fw_5">
                            {getTotal(
                              parseFloat(item?.deposit_amount),
                              collectDepositVat,
                              collectDepositTax
                            )}
                          </p>
                        )}
                      </td>

                      <td>
                        {moment(installmentPlan?.updatedAt).format(DATE_FORMAT)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        )}

        <div className="installment-list">
          <h3 className="table-section-heading font_m fw_6">Installments</h3>
          <div className="custom-table-wrapper">
            <Table className="custom-table font_s" borderless responsive>
              {/* Table Heading */}
              <thead>
                <tr>
                  <th>
                    <p className="font_s fw_6 font_grey">Name</p>
                  </th>
                  <th>
                    <p className="font_s fw_6 font_grey">Amount ({currency})</p>
                  </th>
                  <th>
                    <p className="font_s fw_6 font_grey">Due Date</p>
                  </th>
                </tr>
              </thead>
              {/* Table Body */}
              <tbody>
                {installments?.map((item, index) => (
                  <tr key={index} className="install">
                    {/* Name */}
                    <td>
                      <p className="font_s fw_5">{item?.name}</p>

                      {collectInstallmentVat && (
                        <p className="font_xxs fw_5">VAT({vatValue}%)</p>
                      )}

                      {collectInstallmentTax && (
                        <p className="font_xxs fw_5">Tax({taxValue}%)</p>
                      )}

                      {(collectInstallmentVat || collectInstallmentTax) && (
                        <p className="font_xxs fw_5">Total</p>
                      )}
                    </td>
                    {/* Amount */}
                    <td className="amount-column-wrapper">
                      <p className="font_s fw_5">
                        {item?.amount?.toLocaleString("en-US")}
                      </p>

                      {collectInstallmentVat && (
                        <p className="font_xxs fw_5">
                          {item?.amount
                            ? // ((parseFloat(item?.amount) * vatValue) / 100)
                              //     .toFixed(2)
                              //     .toLocaleString("en-US")
                              ((item?.amount * vatValue) / 100)?.toLocaleString(
                                undefined,
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )
                            : 0}
                        </p>
                      )}

                      {collectInstallmentTax && (
                        <p className="font_xxs fw_5">
                          {item?.amount
                            ? // ((parseFloat(item?.amount) * taxValue) / 100)
                              //     .toFixed(2)
                              //     .toLocaleString("en-US")
                              ((item?.amount * taxValue) / 100)?.toLocaleString(
                                undefined,
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )
                            : 0}
                        </p>
                      )}

                      {(collectInstallmentVat || collectInstallmentTax) && (
                        <p className="font_xxs fw_5">
                          {getTotal(
                            parseFloat(item?.amount),
                            collectInstallmentVat,
                            collectInstallmentTax
                          )}
                        </p>
                      )}
                    </td>
                    {/* Due Date */}
                    <td className="date-column-wrapper">
                      <p className="font_s fw_5">
                        {moment(item?.due_date).format(DATE_FORMAT)}
                      </p>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    );
  }
};
/* Component ends */

export default InititalAgreementInstalmentPlan;
