/**
 * ? In version "3" we have to send object in "keyRender" prop as : { actua_key : "Rendered Key" }
 * ? and in steps send the actual array of keys (string)
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import MUI component starts */
/* Import MUI component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const ProgressSteps = (props) => {
  /* Props destructuring starts */
  const { steps, currentStep, keyRender, version = "1" } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  // Version 1.0
  const [reversedArray, setReversedArray] = useState([]);
  const [customStyle, setCustomStyle] = useState();
  const [width, setWindowWidth] = useState(0);

  // Version 2.0
  const [transformValue, setTransformValue] = useState(1);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const moveStyle = {
    transform: `translateX(-${transformValue}%)`,
  };
  /* Component variable declaration / object destructure ends */
  // Version 1.0 starts
  const updateDimensions = () => {
    const width = window.innerWidth;
    setWindowWidth(width);
  };

  // Event Listner to track the Width of window for applying responsive style
  // window.addEventListener("resize", updateDimensions);
  // Version 1.0 ends

  const activeByKey = (item) => {
    return item && (item === currentStep ? "active" : "");
  };

  const activeByCount = (item) => {
    return item && (item === currentStep ? "active" : "");
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  // Version 1.0 starts
  useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  // useEffect to reverse the steps array whenever the array of steps is changed
  useEffect(() => {
    const array = [...steps];
    setReversedArray(array?.reverse());
  }, [steps]);
  // Version 1.0 ends

  // Version 2.0 starts
  useEffect(() => {
    const currentIndex =
      steps?.findIndex((item) => item?.key === currentStep) + 1;

    if (
      steps?.length > 0 &&
      currentIndex <= steps?.length &&
      currentIndex >= 1
    ) {
      const quotient = parseInt(currentIndex / 4);
      const reminder = currentIndex % 4;

      if (reminder === 0) {
        const t = quotient - 1;
        setTransformValue(t * 100);
      } else {
        setTransformValue(quotient * 100);
      }
    }
  }, [currentStep, steps]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  // If version 2 is selected
  if (version === "2") {
    return (
      <div className="progress-steps-component-wrapper-v2 w_100">
        <div className="progress-steps">
          <hr className="line" />
          <div className="step-list h_100" style={moveStyle}>
            {steps?.map((item, index) => (
              <div className="step text-center" key={index}>
                <span
                  className={`d_inlineblock step-num  ${activeByCount(
                    item
                  )} ${activeByKey(item)}`}
                >
                  <span className="font_m">
                    {item?.count ? item?.count : index + 1}
                  </span>
                </span>
                <p className="font_xxs font_grey step-title">{item?.title}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  } else if (version === "1") {
    return (
      <div className="progress-steps-component-wrapper-v1 w_100">
        <div className="progress-steps">
          <hr className="line" />
          <div className="step-list h_100">
            {reversedArray?.map((item, index) => (
              <div
                className="step text-center"
                key={index}
                style={{
                  right: `${31.5 * index}%`,
                }}
              >
                <span className="d_inlineblock step-num ">
                  <span className="font_m">
                    {item?.count ? item?.count : steps?.length - index}
                  </span>
                </span>
                <p className="font_xxs font_grey step-title">{item?.title}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  } else if (version === "3") {
    return (
      <div className="progress-steps-component-wrapper-v3 w_100">
        <div className="progress-steps">
          <hr className="line" />
          <div className="step-list h_100" style={moveStyle}>
            {steps?.map((item, index) => (
              <div className="step text-center" key={index}>
                <span
                  className={`d_inlineblock step-num   ${activeByCount(
                    item
                  )} ${activeByKey(item)}`}
                >
                  <span className="font_m">
                    {item?.count ? item?.count : index + 1}
                  </span>
                </span>
                <p className="font_xxs font_grey step-title">
                  {keyRender && keyRender[item]}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  } else if (version === "4") {
    return (
      <div className="progress-steps-component-wrapper-v4 w_100">
        <div className="progress-steps">
          <hr className="line" />
          <div className="step-list h_100" style={moveStyle}>
            {steps?.map((item, index) => (
              <div className="step text-center" key={index}>
                <span
                  className={`d_inlineblock step-num   ${activeByCount(
                    item
                  )} ${activeByKey(item)}`}
                >
                  <span className="font_m">
                    {item?.count ? item?.count : index + 1}
                  </span>
                </span>
                <p className="font_xxs font_grey step-title">
                  {keyRender && keyRender[item]}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
};
/* Component ends */

export default ProgressSteps;
