/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import { getIsCLIENT } from "../../../../../setup/store/slices/authSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import OnlinePaymentOption from "./OnlinePaymentOption";
import TenantApplicationFees from "./TenantApplicationFees";
import TenantIdCount from "./TenantIdCount";
import HouseholdMember from "./HouseholdMember";
import CollectRentHistory from "./CollectRentHistory";
import CollectVehicleDetails from "./CollectVehicleDetails";
import CollectPetDetails from "./CollectPetDetails";
import EmergencyContacts from "./EmergencyContacts";
import PendingReview from "./PendingReview";
import ReviewedWaitingAssignedTech from "./ReviewedWaitingAssignedTech";
import PropertyLogo from "./PropertyLogo";
import PropertyDefaults from "./PropertyDefaults";
import CollectTax from "./CollectTax";
import CollectVAT from "./CollectVAT";
import ContractRenewalFee from "./ContractRenewalFee";
import PropertyGroundRules from "./PropertyGroundRules";
import ContractRenewalRemainder from "./ContractRenewalRemainder";
import UnitReadinessGap from "./UnitReadinessGap";
import ReservationTerms from "./ReservationTerms";
import SecurityModule from "./SecurityModule";
import GuestLogBook from "./GuestLogBook";
import VisitorAccessPass from "./VisitorAccessPass";
import UtilityDocOnboarding from "./UtilityDocOnboarding";
import UtilityDocMoveOut from "./UtilityDocMoveOut";
import Electricity from "./Electricity";
import MaxMaintenanceReject from "./MaxMaintenanceReject";
import NotarizedContract from "./notarizedContract";
import MissingPropertySettings from "./MissingPropertySettings";
/* Import local pages and component ends */

/* Component starts */
const T3Settings = (props) => {
  /* Props destructuring starts */
  const { user, settings, getPropertySettingsDetail } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const isClient = useSelector(getIsCLIENT);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const { propertyId } = useParams();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div>
      {/* **************** Settings ******************************** */}
      <h2 className="main_heading font_xxl fw_7 font_grey">Settings</h2>

      {/* MISSING SETTINGS */}
      {settings?.missing?.length > 0 && (
        <MissingPropertySettings
          property_id={propertyId}
          user={user}
          settings={settings}
        />
      )}

      {/* Property Logo Option */}
      <PropertyLogo property_id={propertyId} user={user} settings={settings} />

      {/* Property Defaults */}
      <PropertyDefaults
        property_id={propertyId}
        user={user}
        settings={settings}
      />

      {/* Collect Tax */}
      <CollectTax property_id={propertyId} user={user} settings={settings} />

      {/* Collect VAT */}
      <CollectVAT property_id={propertyId} user={user} settings={settings} />

      {/* Collect VAT */}
      {/* <ContractRenewalFee
        property_id={propertyId}
        user={user}
        settings={settings}
      /> */}

      {/* Notarized Contract */}
      <NotarizedContract
        property_id={propertyId}
        user={user}
        settings={settings}
      />

      <PropertyGroundRules
        property_id={propertyId}
        user={user}
        settings={settings}
      />

      <ReservationTerms
        property_id={propertyId}
        user={user}
        settings={settings}
      />

      {/* Online Payment Option */}
      {isClient && (
        <OnlinePaymentOption
          property_id={propertyId}
          user={user}
          settings={settings}
        />
      )}

      {/* Tenant Application Fees */}
      <TenantApplicationFees
        property_id={propertyId}
        user={user}
        settings={settings}
      />

      {/* Tenant ID Count */}
      <TenantIdCount property_id={propertyId} user={user} settings={settings} />

      {/* Tenant Household Member */}
      <HouseholdMember
        property_id={propertyId}
        user={user}
        settings={settings}
      />

      {/* Rental History */}
      <CollectRentHistory
        property_id={propertyId}
        user={user}
        settings={settings}
      />

      {/* Vehicle Details */}
      <CollectVehicleDetails
        property_id={propertyId}
        user={user}
        settings={settings}
      />

      {/* Pet Details */}
      <CollectPetDetails
        property_id={propertyId}
        user={user}
        settings={settings}
      />

      {/* Emergency Contract */}
      <EmergencyContacts
        property_id={propertyId}
        user={user}
        settings={settings}
        getPropertySettingsDetail={getPropertySettingsDetail}
      />

      {/* Contract renewal remainder */}
      <ContractRenewalRemainder
        property_id={propertyId}
        user={user}
        settings={settings}
      />

      {/* Contract renewal remainder */}
      <UnitReadinessGap
        property_id={propertyId}
        user={user}
        settings={settings}
      />

      <UtilityDocOnboarding
        property_id={propertyId}
        user={user}
        settings={settings}
      />

      <UtilityDocMoveOut
        property_id={propertyId}
        user={user}
        settings={settings}
      />

      {/* <h3 className="sub_heading font_l fw_6 font_grey">Utilities and Bill</h3> */}
      {/* <Electricity property_id={propertyId} user={user} settings={settings} /> */}
    </div>
  );
};
/* Component ends */

export default T3Settings;
