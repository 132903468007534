/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  showErrorAlert,
  showSuccessAlert,
  showWarningAlert,
} from "../../setup/store/slices/globalAlertSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomButton from "../../components/ui/button/CustomButton/CustomButton";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const TestPage_1 = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { testParam } = useParams();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="test-page-1">
      <div className="akg">
        <h1 className="font_green font_xxl fw_6 text-center">
          This is test page One
        </h1>

        <div className="btn-wrapper">
          <CustomButton
            size="l"
            onClick={() => {
              dispatch(
                showSuccessAlert({
                  title: "BRUH !",
                  description: "What's up buddy ?",
                })
              );
              navigate(
                "/signin/tenant/invite/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bml0X2lkIjoiNjYzNWU5ODc3NDQ4ZGJiODAyMjE4ZDg3IiwidGVuYW50X2lkIjoiNjYzNWVhOTI3NDQ4ZGJiODAyMjE4ZjFjIiwidGVuYW50X2FwcGxpY2F0aW9uX2lkIjoiNjYzNWVhM2Y3NDQ4ZGJiODAyMjE4ZTkyIiwicmVxdWVzdGVyIjoiNjRjOWZkZjA3NWMwZWY0ZDg0NjdiM2EzIiwiZmlyc3RfbmFtZSI6IkV0aGFuIiwibGFzdF9uYW1lIjoiTGVlIiwiZW1haWwiOiJkZ3JudGV0ZXN0K3RlbmFudF9ldGhhbkBnbWFpbC5jb20iLCJjb3VudHJ5Y29kZSI6IisxIiwibW9iaWxlIjoiODU3NDQ0NDM5NyIsImludml0YXRpb25faWQiOiI2NjM1ZWE5Mjc0NDhkYmI4MDIyMThmMzEiLCJpYXQiOjE3MTQ4MDk0OTAsImV4cCI6MTcxNDgxNjY5MH0.ETHu4HBRcPkvs_Q_rKw_NeHpmzajl0t1YxaCrwT2u7Q"
              );
            }}
          >
            Navigate Me
          </CustomButton>
        </div>
      </div>
    </div>
  );
};
/* Component ends */

export default TestPage_1;
