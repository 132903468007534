/**
 *
 */

import React, { useState, useEffect, useRef } from "react";

/* Import configuration starts */
import { Formik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import GLOBAL from "../../../../setup/constants/global";
import {
  fieldValue,
  validation,
  addPartDetail,
  removePartDetail,
} from "./helper";
import { useParams } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Modal } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
import TextButton from "../../../ui/button/TextButton/TextButton";
import CustomTextArea from "../../../ui/input/CustomTextArea/CustomTextArea";
import CustomTextInput from "../../../ui/input/CustomTextInput/CustomTextInput";
import CustomNumberInput from "../../../ui/input/CustomNumberInput/CustomNumberInput";
/* Import local pages and component ends */

import "./style.scss";
import { getUserTheme } from "../../../../setup/store/slices/authSlice";

/* Component starts */
const SparePartRequestModal = (props) => {
  /* Props destructuring starts */
  const { showModal, size = "lg", onClose, callback } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [formValue, setFormValue] = useState(fieldValue);
  const [partDetails, setPartDetails] = useState([0]);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const formRef = useRef();
  const userTheme = useSelector(getUserTheme);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // On updating move in type spare parts quotations
  const onSubmit = async (values) => {
    // Creating or collecting payload data to be sent
    const payload = {
      part: [],
    };

    // Parts List
    partDetails.forEach((item, index) => {
      const partEach = {};
      partEach["part_name"] = values[`part_name_${item}`];
      partEach["description"] = values[`description_${item}`];
      partEach["quantity"] = values[`quantity_${item}`];
      payload.part.push(partEach);
    });
    callback(payload.part);
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <Modal
      size={size}
      centered
      show={showModal}
      className="spare-part-request-modal-wrapper"
    >
      <Modal.Body
        className={`${
          userTheme === "dark"
            ? "bg_darkTheme"
            : "bg_lightTheme font_lightTheme"
        }`}
      >
        <div className="modal-header-custom">
          <p
            className={`font_l fw_6 text-center ${
              userTheme !== "dark" && "font_blue"
            } font_white`}
          >
            Request Spare Part
          </p>
        </div>
        <div className="modal-body-custom">
          <Formik
            onSubmit={onSubmit}
            initialValues={formValue}
            validate={(values) => validation(values, partDetails)}
            validateOnBlur={true}
            enableReinitialize={true}
            innerRef={formRef}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                {/* Form Fields */}
                <div className="form-wrapper form-wrapper-flex ">
                  {/* Spare Part Form List */}
                  <div className="form-list-wrapper w_100">
                    {partDetails?.map((item, index) => (
                      <div key={index} className="form-each">
                        <h3 className="w_100 font_blue fw_6 font_m">
                          Part #{index + 1}
                        </h3>

                        {/* Input for Part Name */}
                        <div className="form-group">
                          <CustomTextInput
                            label="Part Name"
                            name={`part_name_${item}`}
                            value={values[`part_name_${item}`]}
                            errors={errors[`part_name_${item}`]}
                            touched={touched[`part_name_${item}`]}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                          />
                        </div>

                        {/* Input for Quantity */}
                        <div className="form-group">
                          <CustomNumberInput
                            label="Quantity"
                            name={`quantity_${item}`}
                            value={values[`quantity_${item}`]}
                            errors={errors[`quantity_${item}`]}
                            touched={touched[`quantity_${item}`]}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            disableDot={true}
                          />
                        </div>

                        {/* Input for Description */}
                        <div className="form-group form-group-full">
                          <CustomTextArea
                            label="Description"
                            name={`description_${item}`}
                            value={values[`description_${item}`]}
                            errors={errors[`description_${item}`]}
                            touched={touched[`description_${item}`]}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                          />
                        </div>
                      </div>
                    ))}
                  </div>

                  {/* Add or Remove Field Action */}
                  <div className="action-btn w_100 text-end">
                    <TextButton
                      onClick={() =>
                        addPartDetail(
                          formRef,
                          setFormValue,
                          partDetails,
                          setPartDetails
                        )
                      }
                      className="fw_6 font_m font_blue v_middle"
                    >
                      + Add More
                    </TextButton>

                    {partDetails.length > 1 && (
                      <TextButton
                        onClick={() =>
                          removePartDetail(
                            formRef,
                            setFormValue,
                            partDetails,
                            setPartDetails
                          )
                        }
                        className="fw_6 font_m font_red v_middle"
                      >
                        Remove
                      </TextButton>
                    )}
                  </div>
                </div>

                {/* Submit and Cancel */}
                <div className="button-wrapper">
                  <>
                    <CustomButton
                      variant="red"
                      size="m"
                      onClick={onClose}
                      className="btn1"
                    >
                      Cancel
                    </CustomButton>
                    <CustomButton type="submit" size="m">
                      Submit
                    </CustomButton>
                  </>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </Modal.Body>
    </Modal>
  );
};
/* Component ends */

export default SparePartRequestModal;
