/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
/* Import local pages and component ends */

/* Component starts */
const DetailSection1 = (props) => {
  /* Props destructuring starts */
  const { type, maintenance } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const requestId = maintenance?.request_id;
  const unitName = maintenance?.unit_card?.unit_name;
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="section section1">
      {/* Name or ID */}
      {type === "Move In" ? (
        <p className="name font_m fw_6 font_grey">
          Ticket No <span className="fw_6 font_blue">{requestId}</span>
        </p>
      ) : (
        <p className="name font_m fw_6">
          {maintenance?.requester?.first_name}{" "}
          {maintenance?.requester?.last_name}
        </p>
      )}
      {/* Summary */}
      {type !== "Move In" && (
        <p className="summary font_xs font_grey fw_5">
          {/* Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste, magni. */}
        </p>
      )}
      {/* Ticket and Unit Number */}
      <div className="ticket-unit">
        {/* Ticket Number */}
        {type !== "Move In" && (
          // <p className="ticket-no font_xs fw_5 w-50">
          //   <span className="font_grey">Ticket Number: </span>
          //   <span>#{requestId}</span>
          // </p>
          <p className="name font_m fw_6 font_grey">
            Ticket No <span className="fw_6 font_blue">{requestId}</span>
          </p>
        )}
        {/* Unit Name */}
        <p className="unit-name font_xs fw_5 w-50">
          <span className="font_grey">Unit: </span>
          <span className="fw_6 font_blue">{unitName}</span>
        </p>
      </div>
    </div>
  );
};
/* Component ends */

export default DetailSection1;
