/*
 * Slice to handle SECURITY DASHBOARD Module
 */

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_URL } from "../../config/devKeys";
import { setLoading } from "./unpersistedSlice";

const initialState = {
  securityList: [],
  propertySecurityList: [],
  communitySecurityList: [],
  searchedSecurityList: [],
  // annoucement: null,
};

// FETCH SECURITY DASHBOARD LIST - Client Side (HOME)
export const fetchSecurityList = createAsyncThunk(
  "dashboard_home/dashboard_security_listData",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(
        `${API_URL}/dashboard/dashboard_security_listData`,
        {
          method: "POST",
          headers: headers,
        }
      );

      const data = await response.json();

      dispatch(setLoading(false));

      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// FETCH SECURITY DASHBOARD LIST - Client Side  (Property_ID)
export const fetchSecurityListBasedProperty = createAsyncThunk(
  "dashboard_property/dashboard_security_listData",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(
        `${API_URL}/dashboard/dashboard_security_listData`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            property_id: payload?.property_id,
          }),
        }
      );

      const data = await response.json();
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// FETCH SECURITY DASHBOARD LIST - Client Side  (Community_ID)
export const fetchSecurityListBasedCommunity = createAsyncThunk(
  "dashboard_community/dashboard_security_listData",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(
        API_URL + `/dashboard/dashboard_security_listData`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            community_id: payload?.community_id,
          }),
        }
      );

      const data = await response.json();

      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// FETCH SEARCHED SECURITY DASHBOARD LIST - Client Side (HOME)
export const fetchSearchedSecurityList = createAsyncThunk(
  "dashboard_search/dashboard_security_listData",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(
        `${API_URL}/dashboard/dashboard_security_listData?searchKey=${payload.searchKey}&start_date=${payload.start_date}&end_date=${payload.end_date}`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            property_id: payload?.property_id,
            community_id: payload?.community_id,
          }),
        }
      );

      const data = await response.json();

      dispatch(setLoading(false));

      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// FETCH ANNOUCEMENT DATA BASED ON ID  - (ANNOUCEMENT_ID)
// export const fetchAnnoucementById = createAsyncThunk(
//   "announcement/get_annoucement_by_id",
//   async (payload, { rejectWithValue, getState, dispatch }) => {
//     try {
//       dispatch(setLoading(true));
//       const headers = {
//         Authorization: `Bearer ${localStorage.getItem("user_token")}`,
//         "Content-Type": "application/json",
//       };

//       const response = await fetch(
//         `${API_URL}/announcement/get_annoucement_by_id?id=${payload}`,
//         {
//           method: "GET",
//           headers: headers,
//         }
//       );

//       const data = await response.json();

//       dispatch(setLoading(false));

//       if (data.success) {
//         return data.data;
//       } else {
//         return rejectWithValue(data.error.message);
//       }
//     } catch (error) {
//       dispatch(setLoading(false));
//       return rejectWithValue(error?.response);
//     }
//   }
// );

export const securityDashboardSlice = createSlice({
  name: "securityDash",
  initialState,
  reducers: {
    clearSearchSecurity: (state, action) => {
      state.securityList = [];
    },
  },
  extraReducers: {
    [fetchSecurityList.rejected]: (state, action) => {
      state.securityList = [];
    },
    [fetchSecurityList.fulfilled]: (state, action) => {
      state.securityList = action.payload;
    },
    [fetchSecurityListBasedProperty.rejected]: (state, action) => {
      state.propertySecurityList = [];
    },
    [fetchSecurityListBasedProperty.fulfilled]: (state, action) => {
      state.propertySecurityList = action.payload;
    },
    [fetchSecurityListBasedCommunity.rejected]: (state, action) => {
      state.communitySecurityList = [];
    },
    [fetchSecurityListBasedCommunity.fulfilled]: (state, action) => {
      state.communitySecurityList = action.payload;
    },
    [fetchSearchedSecurityList.rejected]: (state, action) => {
      state.searchedSecurityList = [];
    },
    [fetchSearchedSecurityList.fulfilled]: (state, action) => {
      state.searchedSecurityList = action.payload;
    },
  },
});

// To Get The SECURITY List For Home
export const getSecurityList = (state) => state.securityDash.securityList;

// To Get The SECURITY List For Property
export const getPropertySecurityList = (state) =>
  state.securityDash.propertySecurityList;

// To Get The SECURITY List For Community
export const getCommunitySecurityList = (state) =>
  state.securityDash.communitySecurityList;

// To Get The SEARCH SECURITY List For Community
export const getSeacrhedSecurityList = (state) =>
  state.securityDash.searchedSecurityList;

export const { clearSearchSecurity } = securityDashboardSlice.actions;

// // To Get The Annoucement Based On ID
// export const getAnnoucementById = (state) => state.annoucement.annoucement;

export default securityDashboardSlice.reducer;
