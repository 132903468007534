import React, { useEffect, useState } from "react";

/* Import configuration starts */
import { useSelector, useDispatch } from "react-redux";
import GLOBAL from "../../../../setup/constants/global";
import { dateFormatList, timeFormatList, preFill, validations } from "./helper";
import { Formik } from "formik";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  getUserTheme,
  updateUserPreference,
} from "../../../../setup/store/slices/authSlice";
import {
  showSuccessAlert,
  showErrorAlert,
} from "../../../../setup/store/slices/globalAlertSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Modal } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
import CustomSelectInput from "../../../ui/input/CustomSelectInput/CustomSelectInput";
/* Import local pages and component ends */
import "./style.scss";

/* Component starts */
const DateTimeChangeFormatModal = (props) => {
  /* Props destructuring starts */
  const {
    showModal,
    size = "md",
    onClose,
    // onSubmit,
    closeOnSubmit = true,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [initialFormValues, setInitialFormValues] = useState();
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const userTheme = useSelector(getUserTheme);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const onSubmit = async (values) => {
    // Creating or collecting payload data to be sent
    const payload = { ...values };

    // Backend response. Try, Catch
    try {
      const result = await dispatch(updateUserPreference(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description:
                typeof result?.payload === "string" ? result?.payload : "",
            })
          );
          if (closeOnSubmit) {
            onClose();
          }
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description:
                typeof result?.payload?.message === "string"
                  ? result?.payload?.message
                  : "",
            })
          );
          setInitialFormValues(values);
          if (closeOnSubmit) {
            onClose();
          }
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    preFill(setInitialFormValues);
  }, []);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <Modal
      size={size}
      centered
      show={showModal}
      className={`date-time-change-format-modal-wrapper `}
    >
      <Modal.Body
        className={`${userTheme !== "dark" ? "bg_lightTheme" : "bg_darkTheme"}`}
      >
        <div className="modal-header-custom">
          <h2 className="text-center font_blue font_xl">
            Change Date and Time Format
          </h2>
        </div>
        <div className="modal-body-custom">
          {initialFormValues && (
            <Formik
              onSubmit={onSubmit}
              initialValues={initialFormValues}
              validate={validations}
              validateOnBlur={true}
              enableReinitialize={true}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                setFieldTouched,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="form-input-fields-wrapper">
                    <div className="form-group">
                      <CustomSelectInput
                        label="Select Date Format"
                        name={`date_format`}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        options={dateFormatList}
                        value={values[`date_format`]}
                        errors={errors[`date_format`]}
                        touched={touched[`date_format`]}
                        valueExtractor={(d) => d?.name}
                      />
                    </div>

                    <div className="form-group">
                      <CustomSelectInput
                        label="Select Time Format"
                        name={`time_format`}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        options={timeFormatList}
                        value={values[`time_format`]}
                        errors={errors[`time_format`]}
                        touched={touched[`time_format`]}
                        valueExtractor={(d) => d?.name}
                      />
                    </div>
                  </div>

                  <div className="form-action-btn-wrapper">
                    <CustomButton onClick={onClose} size="m" variant="red">
                      Cancel
                    </CustomButton>
                    <CustomButton type="submit" size="m">
                      Submit
                    </CustomButton>
                  </div>
                </form>
              )}
            </Formik>
          )}
        </div>
        <div className="modal-footer-custom"></div>
      </Modal.Body>
    </Modal>
  );
};
/* Component ends */

export default DateTimeChangeFormatModal;
