/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useNavigate } from "react-router-dom";
import GLOBAL from "../../setup/constants/global";
import { useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../setup/store/slices/globalAlertSlice";
import {
  fetchVacantUnits,
  canInviteTransitionedTenantinUnit,
} from "../../setup/store/slices/unitSlice";
import {
  fetchPropertyDetailByID,
  getPropertyDetail,
} from "../../setup/store/slices/propertySlice";
import {
  fetchUnitDetailByID,
  getUnitDetail,
} from "../../setup/store/slices/unitSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import InviteTenant from "../../components/module/Tenant/InviteTenant/InviteTenant";
import BreadCrumb from "../../components/ui/other/BreadCrumb/BreadCrumb";
/* Import local pages and component ends */

/* Component starts */
const AddTenantPage = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [vacantUnit, setVacantUnit] = useState();
  const [fromManageTeams, setFromManageTeams] = useState(false);
  const [unitsLoaded, setUnitsLoaded] = useState(false);
  const [unitList, setUnitList] = useState([]);
  const [allowTransitionedTenant, setAllowTransitionedTenant] = useState(false);
  const [allowTransitionedTenant_loaded, setAllowTransitionedTenant_loaded] =
    useState(false);
  const [unit, setUnit] = useState(null);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { unitId, propertyId } = useParams();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const getVacantUnitList = async () => {
    // Creating or collecting payload data to be sent
    const payload = { property_id: propertyId };

    // Backend response. Try, Catch
    try {
      const result = await dispatch(fetchVacantUnits(payload));
      setUnitsLoaded(true);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setUnitList([]);
          break;

        case GLOBAL.FULFILLED:
          setUnitList(result.payload);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  const handleAllowingTransitionedTenant = async (unitIDArgs = "") => {
    setAllowTransitionedTenant_loaded(false);
    // Backend response. Try, Catch
    try {
      const result = await dispatch(
        canInviteTransitionedTenantinUnit(unitIDArgs || unitId)
      );

      setAllowTransitionedTenant_loaded(true);
      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description:
                typeof result?.payload === "string" ? result?.payload : "",
            })
          );
          break;

        case GLOBAL.FULFILLED:
          setAllowTransitionedTenant(result?.payload?.is_transitioned_allowed);
          // dispatch(
          //   showSuccessAlert({
          //     title: "Success!",
          //     description:
          //       typeof result?.payload?.message === "string"
          //         ? result?.payload?.message
          //         : "",
          //   })
          // );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  const handleFetchUnits = async (unitIDArgs = "") => {
    // Backend response. Try, Catch
    try {
      const result = await dispatch(
        fetchUnitDetailByID({ unit_id: unitIDArgs || unitId })
      );

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description:
                typeof result?.payload === "string" ? result?.payload : "",
            })
          );
          setUnit(null);
          break;

        case GLOBAL.FULFILLED:
          setUnit(result?.payload);
          // dispatch(
          //   showSuccessAlert({
          //     title: "Success!",
          //     description:
          //       typeof result?.payload?.message === "string"
          //         ? result?.payload?.message
          //         : "",
          //   })
          // );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    if (fromManageTeams) {
      getVacantUnitList();
    } else if (unitId) {
      handleAllowingTransitionedTenant();
    }
  }, [fromManageTeams]);

  useEffect(() => {
    if (pathname) {
      setFromManageTeams(pathname.includes("tenants"));
    }
  }, [pathname]);

  useEffect(() => {
    if (unitList.length > 0) {
      setVacantUnit(unitList[0]);
    }
  }, [unitList]);

  useEffect(() => {
    if (unitList.length > 0 && vacantUnit) {
      handleAllowingTransitionedTenant(vacantUnit?._id);
    }
  }, [vacantUnit]);

  useEffect(() => {
    // if (!fromManageTeams) {
    //   handleFetchUnits();
    // }
  }, []);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      <BreadCrumb type="invite_tenant" />
      {allowTransitionedTenant_loaded && (
        <div className="container_md">
          <InviteTenant
            onSuccess={() => navigate(-1)}
            onCancel={() => navigate(-1)}
            fromManageTeams={fromManageTeams}
            vacantUnit={vacantUnit}
            setVacantUnit={setVacantUnit}
            unitList={unitList}
            allowTransitionedTenant={allowTransitionedTenant}
          />
        </div>
      )}
    </>
  );
};
/* Component ends */

export default AddTenantPage;
