export const intialFormState = {
  technician_0: "",
  note: "",
};

// Adding more fields
export const addMore = (
  formValues,
  setFormValues,
  technicianCount,
  setTechnicianCount
) => {
  const length = technicianCount.length;
  const currentFormState = { ...formValues.current.values };

  setFormValues(() => {
    // Generating new field name according to array indexes
    currentFormState[`technician_${length}`] = "";
    return currentFormState;
  });
  setTechnicianCount((prevState) => [...prevState, length]);
};

// Removing last fields
export const remove = (
  formValues,
  setFormValues,
  technicianCount,
  setTechnicianCount
) => {
  const lastIdx = technicianCount.length - 1;
  const currentFormState = { ...formValues.current.values };

  setFormValues(() => {
    delete currentFormState["technician_" + lastIdx];
    return currentFormState;
  });

  setTechnicianCount((prevState) => {
    const prevFields = [...prevState];
    prevFields.pop();
    return prevFields;
  });
};

export const formValidation = (
  values,
  technicianCount,
  noteValidation,
  noteLabel
) => {
  const error = {};

  // Validations for Note Starts
  if (noteValidation && values.note === "") {
    error.note = `${noteLabel} is required`;
  }
  if (noteValidation && values.note !== "" && values.note.length < 10) {
    error.note = `Minimum 10 characters are required`;
  }
  // Validations for Note Ends

  technicianCount.forEach((itm, idx) => {
    // ********************** Creating Object Keys **********************
    const technician = "technician_" + idx;

    // ********************** Validations starts from here **********************
    // Validations for Technician Starts
    if (values[technician] === "") {
      error[technician] = "Technician is required";
    }
    // Validations for Technician Ends
  });
  return error;
};

// export const prefill for multiple mt
export const moveInMultipleTechnician = (
  data,
  setTechnicianCount,
  setFormValues
) => {
  let mtCount = [];
  const formValues = {};

  data.forEach((item, index) => {
    mtCount.push(index);
    formValues[`technician_${index}`] = "";
  });

  setTechnicianCount(mtCount);
  setFormValues((prevState) => ({ ...prevState, ...formValues }));
};
