// Disability intial form values
export const disabilityInitVal = {
  disability: "",
};

// Disability form validation
export const disabilityValidation = (values) => {
  const error = {};

  if (values.disability === "") {
    error.disability = "Please choose an option";
  }

  return error;
};

// Disability form prefilling
export const disabilityFormPrefill = (data, setInitialFormValues) => {
  const boolToStr = data.disability === true ? "1" : "0";
  setInitialFormValues({
    disability: boolToStr,
  });
};
