/**
 *
 */

import React, { useState, useEffect } from "react";
import {
  DocFilledWhite,
  DocumentGreen,
} from "../../../../../setup/constants/images";

/* Import configuration starts */
import moment from "moment";
import GLOBAL from "../../../../../setup/constants/global";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../../../ui/button/IconButton/IconButton";
import FileViewerModal from "../../../../ui/modal/FileViewerModal/FileViewerModal";
import ApplicationDataRejectionModal from "../../ApplicationDataRejectionModal/ApplicationDataRejectionModal";
/* Import local pages and component ends */

/* Component starts */
const HouseholdMembers = (props) => {
  /* Props destructuring starts */
  const { data, approval = false, onAction, index } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [file, setFile] = useState();
  const [showFileViewer, setShowFileViewer] = useState(false);
  const [rejectAction, setRejectAction] = useState({});
  const [rejectionModal, setRejectionModal] = useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // On viewing id file
  const onViewID = (item) => {
    setFile(item);
    setShowFileViewer(true);
  };

  // On approving details
  const onApprove = (item) => {
    onAction({
      type: "household_members",
      info_id: item._id,
      info_details: {
        status: GLOBAL.TENANT_APPLICATION_STATUS.DATA_APPROVED,
      },
    });
  };

  // On rejecting details
  const onReject = (item) => {
    setRejectAction({
      type: "household_members",
      info_id: item._id,
      info_details: {
        status: GLOBAL.TENANT_APPLICATION_STATUS.DATA_REJECTED,
      },
    });
    setRejectionModal(true);
  };

  // On rejecting with note
  const onRejectWithNote = (note) => {
    setRejectionModal(false);
    const action = { ...rejectAction };
    action.info_details.note = note;
    onAction(action);
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="household-wrapper">
      <div className="heading-wrapper">
        <p className="font_l label">
          {" "}
          {index !== undefined ? `${index} ${"-"}` : ""} Household Members
        </p>
      </div>

      <div className="detail-box bg_grey">
        {data?.map((item, index) => (
          <>
            <p className="header font_m fw_3">Member #{index + 1}</p>
            <div className="household" key={index}>
              <div className="household-detail">
                <p className="font_s fw_3">
                  First Name <br />{" "}
                  <span className="font_m fw_4">{item.first_name}</span>
                </p>
              </div>
              <div className="household-detail">
                <p className="font_s fw_3">
                  Last Name <br />{" "}
                  <span className="font_m fw_4">{item.last_name}</span>
                </p>
              </div>

              <div className="household-detail">
                <p className="font_s fw_3">
                  Date Of Birth <br />{" "}
                  <span className="font_m fw_4">
                    {moment(item.dob).format(DATE_FORMAT)}
                  </span>
                </p>
              </div>

              <div className="household-detail">
                <p className="font_s fw_3">
                  Relation <br />{" "}
                  <span className="font_m fw_4">{item.relation}</span>
                </p>
              </div>

              <div className="household-detail">
                <p className="font_s fw_3">
                  Gender <br />{" "}
                  <span className="font_m fw_4">{item.gender}</span>
                </p>
              </div>

              <div className="household-detail">
                <p className="font_s fw_3">
                  Email <br /> <span className="font_m fw_4">{item.email}</span>
                </p>
              </div>
              <div className="household-detail">
                <p className="font_s fw_3">
                  Mobile Number <br />{" "}
                  <span className="font_m fw_4">{`${item.countrycode} ${item.mobile}`}</span>
                </p>
              </div>

              <div className="household-detail">
                <p className="font_s fw_3">
                  Language <br />{" "}
                  <span className="font_m fw_4">{item.language}</span>
                </p>
              </div>

              <div className="household-detail">
                <p className="font_s fw_3">
                  Nationality <br />{" "}
                  <span className="font_m fw_4">{item.nationality}</span>
                </p>
              </div>

              <div className="household-detail">
                <p className="font_s fw_3">
                  Id Type <br />{" "}
                  <span className="font_m fw_4">{item.id_type}</span>
                </p>
              </div>

              <div className="household-detail">
                <p className="font_s fw_3">
                  Id Number <br />{" "}
                  <span className="font_m fw_4">{item.id_number}</span>
                </p>
              </div>

              <div className="household-detail">
                <p className="font_s fw_3">
                  Id Expiry Date <br />{" "}
                  <span className="font_m fw_4">
                    {moment(item.id_expiry_date).format(DATE_FORMAT)}
                  </span>
                </p>
              </div>

              <div className="household-detail">
                <p className="font_s fw_3">
                  ID Picture <br />{" "}
                  <span
                    className="font_m fw_4"
                    onClick={() => onViewID(item.id_image)}
                  >
                    <img src={DocumentGreen} alt="document" />-{" "}
                    <span className="view">View</span>
                  </span>
                </p>
              </div>

              {approval && (
                <div className="household-detail">
                  {/* If pending actions */}
                  {item.status ===
                    GLOBAL.TENANT_APPLICATION_STATUS.DATA_PENDING && (
                    <div className="btn-wrapper">
                      <span
                        onClick={() => onApprove(item)}
                        className="approve font_s fw_3"
                      >
                        Approve
                      </span>
                      <span
                        onClick={() => onReject(item)}
                        className="reject font_s fw_3"
                      >
                        Reject
                      </span>
                    </div>
                  )}

                  {/* If data approved */}
                  {item?.status ===
                    GLOBAL.TENANT_APPLICATION_STATUS.DATA_APPROVED && (
                    <p className="fw_6 font_s font_green">Approved</p>
                  )}

                  {/* If data rejected */}
                  {item?.status ===
                    GLOBAL.TENANT_APPLICATION_STATUS.DATA_REJECTED && (
                    <p className="fw_6 font_s font_red">Rejected</p>
                  )}
                </div>
              )}

              <div className="household-detail">
                <p className="font_s fw_3">
                  Action Date <br />{" "}
                  <span className="font_m fw_4">
                    {item?.status === "approved"
                      ? moment(item?.updatedAt).format(DATE_TIME_FORMAT)
                      : "-"}
                  </span>
                </p>
              </div>
            </div>
          </>
        ))}

        {data?.length === 0 && (
          <div className=" text-center">
            <h3 className=" font_m font_yellow fw_6">No Data</h3>
          </div>
        )}
      </div>

      {/* Modals */}
      {/* File Viewer Modal */}
      <FileViewerModal
        show={showFileViewer}
        file={file?.url}
        type={file?.type}
        onClose={() => {
          setShowFileViewer(false);
          setFile({});
        }}
      />

      {/* Tenant Application Rejection Modal*/}
      <ApplicationDataRejectionModal
        showModal={rejectionModal}
        title="Are you sure you want to reject this Member?"
        onRejectWithNote={onRejectWithNote}
        onCancel={() => setRejectionModal(false)}
      />
    </div>
  );
};
/* Component ends */

export default HouseholdMembers;
