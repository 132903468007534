/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import { getUserTheme } from "../../../../../setup/store/slices/authSlice";
import { useSelector } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
/* Import local pages and component ends */

/* Component starts */
const Variant2 = (props) => {
  /* Props destructuring starts */
  const {
    label,
    value, // useState value / Formik State
    setValue = null,
    name,
    required = true,
    customOnchange = null,
    touched,
    errors,
    setFieldValue = null, // setFieldValue formik function
    setFieldTouched = null, // setFieldTouched formik function
    refValue = null, // If useRef used
    minDate = true,
    className,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const userTheme = useSelector(getUserTheme);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const onChange = (date) => {
    setValue && setValue(date);

    setFieldValue && setFieldValue(name, date);

    customOnchange && customOnchange(date);

    if (refValue !== null) {
      refValue.current = date;
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    if (refValue !== null) {
      refValue.current = value;
    }
  }, [value]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="custom-datetime-input custom-datetime-input-component-wrapper-v2">
      <div className="datepicker">
        <DateTimePicker
          // /|| userTheme === "automatic"
          className={`font_xs ${className}
          ${
            userTheme === "light" || userTheme === "automatic"
              ? "bg_lightThemeInput font_lightTheme"
              : "bg_black font_grey"
          }
          `}
          onChange={onChange}
          value={value}
          minDate={minDate && new Date()}
          onCalendarOpen={() => setFieldTouched && setFieldTouched(name, true)}
          onClockOpen={() => setFieldTouched && setFieldTouched(name, true)}
        />
        <label className="font_s font_grey">
          {label}
          {required && <span className="font_m font_red"> *</span>}
        </label>
      </div>

      {touched && errors && <p className="error font_xxs font_red">{errors}</p>}
    </div>
  );
};
/* Component ends */

export default Variant2;
