/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import moment from "moment";
import GLOBAL from "../../../../../setup/constants/global";
import { useNavigate } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Table } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import {
  EditGreen,
  EyeFilledBlue,
} from "../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../../../ui/button/IconButton/IconButton";
import PaymentModal from "../../../Invoicing/ClientInvoicing/PaymentModal/PaymentModal";
/* Import local pages and component ends */

/* Component starts */
const Deposit = (props) => {
  /* Props destructuring starts */
  const { moveOut, property, getMoveoutDetails } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [invoiceData, setInvoiceData] = useState();
  const [showModal, setShowModal] = useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const isOwner = localStorage.getItem("user_role") === "Owner";
  const isAcc = localStorage.getItem("user_role") === "Accountant";

  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  const onClose = () => {
    setShowModal(!showModal);
  };

  const onSuccess = () => {
    setShowModal(!showModal);
    navigate(-1);
  };

  const handlePaymentModal = (item) => {
    setInvoiceData(item);
    setShowModal(!showModal);
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="section container_md">
      <h3 className="table-heading font_m">Deposits</h3>

      <div className="custom-table-wrapper">
        {moveOut?.deposit_invoices?.length > 0 && (
          <Table
            className="custom-table font_s unit-table"
            borderless
            responsive
          >
            <thead>
              <tr>
                <th>Invoice</th>
                <th>Due Date</th>
                <th>Amount ({property?.currency})</th>
                <th>Status</th>
                <th>View</th>
              </tr>
            </thead>
            <tbody>
              {moveOut?.deposit_invoices?.map((item, index) => (
                <tr>
                  {/* Invoice */}
                  <td>{item.invoice_no}</td>
                  {/* Due Date */}
                  <td> {moment(item.due_date).format(DATE_FORMAT)}</td>
                  {/* Amount */}
                  <td>{item.total_amount?.toLocaleString("en-US")}</td>
                  {/* Status */}
                  <td
                    className={`${
                      item?.status === "paid" || item?.status === "collected"
                        ? "font_green"
                        : item?.status === "void" ||
                          item?.status === "adjusted" ||
                          item?.status === "settled"
                        ? "font_yellow"
                        : "font_red"
                    }`}
                  >
                    {item?.status === "paid" && "Paid"}
                    {item?.status === "collected" && "Collected"}
                    {item?.status === "partially_collected" &&
                      "Partially Collected"}
                    {item?.status === "not_collected" && "Not Collected"}
                    {item?.status === "partially_settled" &&
                      "Partially Settled"}
                    {item?.status === "settled" && "Settled"}
                    {item?.status === "partial-paid" && "Partial Paid"}
                    {item?.status === "unpaid" && "Unpaid"}
                    {item?.status === "void" && "Void"}
                    {item?.status === "adjusted" && "Adjusted"}
                  </td>
                  {/* View */}
                  <td>
                    <IconButton
                      onClick={() =>
                        navigate(
                          `invoicing/Tenant/deposits/${item?._id}${
                            window.location.search !== ""
                              ? window.location.search + "&"
                              : "?"
                          }from=accountant_moveout_review`
                        )
                      }
                    >
                      <img src={EyeFilledBlue} className="icon-md" alt="View" />
                    </IconButton>

                    {((isOwner && property?.mgmt_model === 0) ||
                      (isAcc && property?.mgmt_model === 2)) &&
                      (item?.status === "unpaid" ||
                        item?.status === "partial paid") &&
                      !item?.is_payable_by_cheque && (
                        <IconButton
                          onClick={() => handlePaymentModal(item)}
                          className="action-button"
                        >
                          <img
                            // src={EditWhite}
                            src={EditGreen}
                            className="h_100 mx-2"
                            alt="View Details"
                          />
                        </IconButton>
                      )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}

        {moveOut?.deposit_invoices?.length === 0 && (
          <div className=" text-center">
            <h3 className=" font_m font_yellow fw_6">No Data</h3>
          </div>
        )}
      </div>
      <PaymentModal
        showModal={showModal}
        onClose={onClose}
        onSuccess={onSuccess}
        invoiceData={invoiceData}
        property_id={property?._id}
        afterPayment={() => {
          getMoveoutDetails();
        }}
      />
    </div>
  );
};
/* Component ends */

export default Deposit;
