/**
 *
 */

import React, { useState, useRef, useEffect } from "react";

/* Import configuration starts */
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useDispatch, useSelector } from "react-redux";
import { Toastify } from "../../../../setup/utils/toast";
import { Elements } from "@stripe/react-stripe-js";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import MUI component starts */
/* Import MUI component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import PaymentByCard from "../PaymentByCard/PaymentByCard";
/* Import local pages and component ends */

/* Component starts */
const PaymentMethod = (props) => {
  /* Props destructuring starts */
  const {
    onCancel = null,
    isPaymentAllowed = true,
    payload,
    receiverStripeKey,
    setTransactionStatus,
    setShowTransactionStatus,
    size,
    paymentType,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [paymentMethod, setPaymentMethod] = useState(0);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  // Initial load
  useEffect(() => {
    if (receiverStripeKey !== "") {
      loadStripe(receiverStripeKey);
    }
  }, [receiverStripeKey]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="w_100">
      <PaymentByCard
        paymentType={paymentType}
        onCancel={onCancel}
        size={size}
        isPaymentAllowed={isPaymentAllowed}
        payload={payload}
        receiverStripeKey={receiverStripeKey}
        setTransactionStatus={setTransactionStatus}
        setShowTransactionStatus={setShowTransactionStatus}
      />
    </div>
  );
};
/* Component ends */

export default PaymentMethod;
