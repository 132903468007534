/**
 *
 */

import React from "react";

/* Import configuration starts */
import moment from "moment";
import { useNavigate } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Table } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { EyeFilledBlue } from "../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import GLOBAL from "../../../../setup/constants/global";
import IconButton from "../../../ui/button/IconButton/IconButton";
/* Import local pages and component ends */

// Styles
import "./style.scss";

/* Component starts */
const AdvertismentsTable = (props) => {
  /* Props destructuring starts */
  const {} = props;

  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */

  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";

  const advertimentsList = [
    {
      id: "AD45",
      client: "Eryn Stokell",
      start_date: "11/13/2022",
      end_date: "10/14/2022",
      note: "Hemorrhage in optic nerve sheath, right eye",
      views: "914",
      status: "Active",
      action: "Resume",
    },
    {
      id: "AD015",
      client: "Osborn Bazelle",
      start_date: "12/12/2022",
      end_date: "9/7/2022",
      note: "Chronic slipped upper femoral epiphysis, right hip",
      views: "6376",
      status: "Active",
      action: "Resume",
    },
    {
      id: "AD001",
      client: "Linn Cottage",
      start_date: "3/18/2023",
      end_date: "8/18/2022",
      note: "Sprain of lateral collateral ligament of right knee, init",
      views: "52693",
      status: "Expired",
      action: "Pause",
    },
    {
      id: "AD022",
      client: "Sybyl Orsman",
      start_date: "10/9/2022",
      end_date: "8/24/2022",
      note: "Unspecified deformity of orbit",
      views: "81398",
      status: "Active",
      action: "Resume",
    },
    {
      id: "AD105",
      client: "Sharleen Lowdes",
      start_date: "11/29/2022",
      end_date: "4/13/2023",
      note: "Encntr for suprvsn of normal first preg, third trimester",
      views: "015",
      status: "Expired",
      action: "Pause",
    },
  ];

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */

  return (
    <div className="custom-table-wrapper advertiments-table-wrapper">
      <Table className="custom-table font_s" borderless responsive>
        {/* Table Heading */}
        <thead>
          <tr>
            <th>ID</th>
            <th>Client</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Note</th>
            <th>Total Views</th>
            <th>Status</th>
            <th>Pause/Resume</th>
            <th>Action</th>
          </tr>
        </thead>
        {/* Table Body */}
        <tbody>
          {advertimentsList?.map((item) => (
            <tr key={item.id}>
              {/* ID */}
              <td>{item?.id}</td>

              {/* Client */}
              <td>{item?.client}</td>

              {/* Start Date */}
              <td>{moment(item?.start_date).format(DATE_FORMAT)}</td>

              {/* End Date */}
              <td>{moment(item?.end_date).format(DATE_FORMAT)}</td>

              {/* Note */}
              <td>{item?.note}</td>

              {/* Views */}
              <td>{item?.views}</td>

              {/* Status */}
              <td className={item?.status === "Active" ? "active" : "expired"}>
                {item?.status}
              </td>

              {/* Pause / Resume */}
              <td>{item?.action}</td>

              {/* Actions */}
              <td className="text-start txt-nowrap">
                <IconButton
                  onClick={() =>
                    navigate("/property/advertising/detail", {
                      state: { item },
                    })
                  }
                  className="action-button"
                >
                  <img
                    src={EyeFilledBlue}
                    className="h_100"
                    alt="View Details"
                  />
                </IconButton>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};
/* Component Ends */
export default AdvertismentsTable;
