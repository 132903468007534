/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
import { getUserRoles } from "../../../setup/store/slices/masterSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import {
  EditGreen,
  EditWhite,
  NoProfPicSet,
} from "../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import Image from "../../../components/ui/other/Image/Image";
import ConfirmationModal from "../../../components/ui/modal/ConfirmationModal/ConfirmationModal";

/* Import local pages and component ends */

/* Component starts */
const GeneralDetailsPhoto = (props) => {
  /* Props destructuring starts */
  const { user } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [currentUserRole, setCurrentUserRole] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const userRoles = useSelector(getUserRoles);
  const navigate = useNavigate();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const photo = user?.myphoto?.url || NoProfPicSet;
  const name = user?.first_name + " " + user?.last_name;
  const email = user?.email;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  /* Component function definition ends */

  /* Component useEffect starts */
  // Setting current user role
  useEffect(() => {
    if (userRoles?.length > 0 && user) {
      setCurrentUserRole(
        userRoles.filter((role) => role._id === user.role_id)[0]
      );
    }
  }, [userRoles, user]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="general-detail">
      <Image
        className="prof-pic"
        src={photo}
        alt="Profile Photo"
        objectFit="cover"
        objectPosition="center"
      />

      <p className="name text-center font_l fw_5">{name}</p>
      <p className="email text-center font_m font_grey fw_5">
        {email}
        <img
          src={EditGreen}
          alt="Edit"
          className="h_100"
          onClick={() => setShowConfirmationModal(!showConfirmationModal)}
        />
      </p>
      <div className="role text-center font_m font_blue fw_6">
        {currentUserRole?.name}
      </div>

      {/* Modals */}
      {/* Confirmation Modal */}
      <ConfirmationModal
        showModal={showConfirmationModal}
        description="Are you sure to change / update the email"
        title="Confirm Email Update"
        onClose={() => setShowConfirmationModal(false)}
        onConfirm={() => navigate("update-email")}
      />
    </div>
  );
};
/* Component ends */

export default GeneralDetailsPhoto;
