/**
 * This component is a Modal that is shown on success
 */

import React, { useState, useEffect } from "react";

/* Import react bootstrap component starts */
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import GLOBAL from "../../../../setup/constants/global";
import { DocusealForm } from "@docuseal/react";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { SuccessIconOutlinedBlue } from "../../../../setup/constants/images";
/* Import image and SVG ends */

import { fetchTemplateTokenByApplicationId } from "../../../../setup/store/slices/templateSlice";
import {
  getIsOwner,
  getIsPM,
  getIsTENANT,
  getUser,
  getUserTheme,
} from "../../../../setup/store/slices/authSlice";
import { showErrorAlert } from "../../../../setup/store/slices/globalAlertSlice";
import { updateInvoiceUnderProcess } from "../../../../setup/store/slices/tenantSlice";
import { setLoading } from "../../../../setup/store/slices/unpersistedSlice";
/* Import local pages and component starts */
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const ESignDocModal = (props) => {
  /* Props destructuring starts */
  const {
    showModal,
    type = "",
    application_id = "",
    move_out_id = "",
    onCompleted,
    onClose,
  } = props;
  /* Props destructuring ends */

  const [data, setData] = useState(null);
  const [role, setRole] = useState();
  const [isHidden, setIsHidden] = useState(true);
  const [isMounted, setIsMounted] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const isTenant = useSelector(getIsTENANT);
  const isPM = localStorage.getItem("user_role") === "Property Manager";
  const isOwner = localStorage.getItem("user_role") === "Owner";
  const userTheme = useSelector(getUserTheme);

  const getTemplateToken = async () => {
    // Creating or collecting payload data to be sent
    const payload = { type, application_id, move_out_id };

    // Backend response. Try, Catch
    try {
      const result = await dispatch(
        fetchTemplateTokenByApplicationId({ type, application_id, move_out_id })
      );

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setData();
          // dispatch(
          //   showErrorAlert({
          //     title: "Error!",
          //     description: result?.payload || "",
          //   })
          // );
          break;

        case GLOBAL.FULFILLED:
          setData(result?.payload);
          // dispatch(
          //   showSuccessAlert({
          //     title: "Success!",
          //     description: result?.payload?.message || "",
          //   })
          // );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  const updateInvoiceCreationStatus = async () => {
    // Creating or collecting payload data to be sent
    const payload = { application_id, is_invoice_under_process: true };

    // Backend response. Try, Catch
    try {
      const result = await dispatch(updateInvoiceUnderProcess(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description:
                typeof result?.payload === "string" ? result?.payload : "",
            })
          );
          break;

        case GLOBAL.FULFILLED:
          onCompleted();
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  useEffect(() => {
    if (isTenant) {
      setRole(GLOBAL.USER_ROLE.TENANT);
    }
    if (isPM) {
      setRole(GLOBAL.USER_ROLE.PM);
    }
    if (isOwner) {
      setRole(GLOBAL.USER_ROLE.OWNER);
    }
  }, [isTenant, isPM, isOwner]);

  useEffect(() => {
    getTemplateToken();
  }, []);

  useEffect(() => {
    if (isMounted && isLoaded) {
      setIsHidden(false);
    }
  }, [isLoaded, isMounted]);

  /* ************* Component rendering. JSX code ************* */
  return (
    <Modal
      fullscreen={true}
      centered
      show={showModal}
      className="esign-doc-modal-wrapper"
    >
      <Modal.Body
        className={`${userTheme === "dark" ? "bg_darkTheme" : "bg_lightTheme"}`}
      >
        <div className="modal-header-custom">
          <div className="icon-wrapper text-center">
            <CustomButton
              onClick={onClose}
              className="close-btn"
              variant="outline"
            >
              Close
            </CustomButton>
          </div>
        </div>
        <div className="modal-body-custom">
          {data && (
            <div
              className={`${
                isHidden ? "hidden" : ""
              } docuseal-form-sign-component`}
            >
              <DocusealForm
                src={`https://docuseal.co/s/${data?.template}`}
                email={user?.email}
                // onComplete={(data) => console.log(data)}
                readonlyFields={data?.readonly_variables}
                role={role}
                allowToResubmit={false}
                withSendCopyButton={false}
                withDownloadButton={false}
                applicationKey={
                  type === "clearance_cert"
                    ? move_out_id
                    : type + "_" + application_id
                }
                onComplete={() => {
                  if (type === "initial_agreement" && (isOwner || isPM)) {
                    dispatch(setLoading(true));
                    setTimeout(() => {
                      updateInvoiceCreationStatus();
                    }, 2000);
                  } else {
                    onCompleted();
                  }
                }}
                onInit={() => {
                  setIsMounted(true);
                  console.log("FORM MOUNTED");
                }}
                onLoad={() => {
                  setIsLoaded(true);
                  console.log("FORM LOADED");
                }}
                withTitle={false}
                customCss={`  
                span[data-tip="Take photo"] {
                  display: none !important;
                }
                `}
                i18n={{ type_here_: "Type Your Name" }}
              />
            </div>
          )}
          {isHidden && (
            <div className="docuseal-loading">
              <h1 className="font_xxl fw_7 font_yellow">
                {/* Please wait, your form is getting loaded... */}
                Please wait while the document is getting loaded
              </h1>
            </div>
          )}
        </div>
        <div className="modal-footer-custom"></div>
      </Modal.Body>
    </Modal>
  );
};
/* Component ends */

export default ESignDocModal;
