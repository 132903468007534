/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { Formik } from "formik";
import { formFields, validations } from "./helper";
import { useDispatch } from "react-redux";
import GLOBAL from "../../../../setup/constants/global";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  changePassword,
  sendForgotPasswordOtp,
} from "../../../../setup/store/slices/authSlice";
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../../setup/store/slices/globalAlertSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomPasswordInput from "../../../ui/input/CustomPasswordInput/CustomPasswordInput";
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
import ConfirmationOTPModal from "../../../ui/modal/ConfirmationOTPModal/ConfirmationOTPModal";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const ChangePassword = (props) => {
  /* Props destructuring starts */
  const { userName, forgotPassword } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [password, setPassword] = useState({});
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // On confirming changing password with OTP
  const confirmOTP = async (otp) => {
    // Creating or collecting payload data to be sent
    const payload = {
      ...password,
      username: userName,
      otp,
    };

    // Backend response. Try, Catch
    try {
      const result = await dispatch(changePassword(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;
        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: "Password changed successfully!",
            })
          );
          localStorage.clear();
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // On resending OTP
  const resendOtp = async () => {
    // Creating or collecting payload data to be sent
    const payload = {};

    // Backend response. Try, Catch
    try {
      const result = {};

      // Handling success response
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // On changing password
  const onSubmit = async (values) => {
    if (!userName) {
      dispatch(
        showErrorAlert({
          title: "Error!",
          description: "No username has been selected or entered.",
        })
      );
      return;
    }
    setPassword({
      password: values.newPassword,
    });
    // Creating or collecting payload data to be sent
    const payload = {
      username: userName,
    };

    // Backend response. Try, Catch
    try {
      const result = await dispatch(sendForgotPasswordOtp(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;
        case GLOBAL.FULFILLED:
          setConfirmationModal(true);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div
      className={`change-password-module-wrapper${
        forgotPassword
          ? " forget-password-module-wrapper"
          : " change-password-module-wrapper-specific"
      }`}
    >
      <div className="center">
        <h3 className="font_xl fw_6 text-center inner-heading">
          Change Password
        </h3>

        <Formik
          onSubmit={onSubmit}
          initialValues={formFields}
          validate={validations}
          validateOnBlur={true}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="form-wrapper">
                {/* Input for Old Password */}
                <div className="form-group">
                  <CustomPasswordInput
                    name="oldPassword"
                    showPasswordValidationErr={false}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    value={values.oldPassword}
                    errors={errors.oldPassword}
                    touched={touched.oldPassword}
                    label="Old Password"
                  />
                </div>

                {/* Input for New Password */}
                <div className="form-group">
                  <CustomPasswordInput
                    name="newPassword"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    value={values.newPassword}
                    errors={errors.newPassword}
                    touched={touched.newPassword}
                    label="New Password"
                  />
                </div>

                {/* Input for New Password Confirm */}
                <div className="form-group">
                  <CustomPasswordInput
                    name="newPasswordConfirm"
                    showPasswordValidationErr={false}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    value={values.newPasswordConfirm}
                    errors={errors.newPasswordConfirm}
                    touched={touched.newPasswordConfirm}
                    label="New Password Confirm"
                  />
                </div>
              </div>
              {/* Submit Button */}
              <div className="submit-button-wrapper">
                <CustomButton type="submit" size="l">
                  Change Password
                </CustomButton>
              </div>
            </form>
          )}
        </Formik>
      </div>

      {/* Modals */}
      <ConfirmationOTPModal
        title="Enter OTP"
        description="Enter OTP sent to your email."
        showModal={confirmationModal}
        onCancel={() => setConfirmationModal(false)}
        onVerify={confirmOTP}
        onResend={resendOtp}
      />
    </div>
  );
};
/* Component ends */

export default ChangePassword;
