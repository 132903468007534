/**
 * This component is a Modal that is shown on success
 */

import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Formik } from "formik";
/* Import react bootstrap component starts */
import { Modal } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

// Redux Slices Start
import { getUserTheme } from "../../../../setup/store/slices/authSlice";
// Redux Slices End

/* Import local pages and component starts */
import CustomTextInput from "../../../ui/input/CustomTextInput/CustomTextInput";
import CustomFileInput from "../../../ui/input/CustomFileInput/CustomFileInput";
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
import TextButton from "../../../ui/button/TextButton/TextButton";
import ErrorModal from "../../../ui/modal/ErrorModal/ErrorModal";
import SuccessModal from "../../../ui/modal/SuccessModal/SuccessModal";
import CustomDateInput from "../../../ui/input/CustomDateInput/CustomDateInput";
import CustomTextArea from "../../../ui/input/CustomTextArea/CustomTextArea";
import CustomCurrencyInput from "../../../ui/input/CustomCurrencyInput/CustomCurrencyInput";
import CustomSelectInput from "../../../ui/input/CustomSelectInput/CustomSelectInput";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const RefundPaymentModal = (props) => {
  /* Props destructuring starts */
  const {
    showModal,
    size = "lg",
    onClose,
    invoiceData,
    paidOnline,
    onSubmit,
    depositInvoice,
  } = props;

  /* Props destructuring ends */

  // useState
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [modalDesc, setModalDesc] = useState("");
  const [payMark, setPayMark] = useState();
  const [recpdate, setRecpdate] = useState(new Date());

  // useRef
  const receipt = useRef(null);
  const userTheme = useSelector(getUserTheme);

  // Initial Values
  const initialFormValues = {
    note: "",
    pay_amount: "",
    cash_amt: "",
    reciept_num: "",
    cheque_amt: "",
    cheque_num: "",
    issuer_name: "",
    account_number: "",
    bank_name: "",

    // WIRE
    receive_bank: "",
    sender_name: "",
    reference_number: "",
    wire_amount: "",

    // MONEY ORDER
    send_name: "",
    moneyOrder_amount: "",
    moneyOrder_number: "",

    // cashier check
    cashier_check_amopunt: "",
    cashier_check_number: "",
    cashier_check_bank: "",
  };

  // Payment Options
  const paymentMethods = [
    paidOnline && {
      _id: 1,
      key: "online",
      name: "Online",
    },
    {
      _id: 2,
      key: "wire",
      name: "Refund By Wire",
    },
    {
      _id: 3,
      key: "moneyOrder",
      name: "Refund By Money Order",
    },
    {
      _id: 4,
      key: "cashier cheque",
      name: "Refund By Cashier's Cheque",
    },
    {
      _id: 5,
      key: "cash",
      name: "Refund By Cash",
    },
    {
      _id: 6,
      key: "cheque",
      name: "Refund By Cheque",
    },
  ];

  // Handle Form Validations
  const formValidation = (values, paidVia) => {
    const error = {};

    if (values.note === "") {
      error.note = "Remarks is required";
    }

    switch (paidVia?.key) {
      case "cash":
        if (values.reciept_num === "") {
          error.reciept_num = "Reciept Number is required";
        }
        if (values.pay_amount === "") {
          error.pay_amount = "Amount is required";
        }
        // invoiceData?.pendingRefundAmount
        if (values.pay_amount !== "") {
          if (depositInvoice && invoiceData?.pendingRefundAmount) {
            if (
              values.pay_amount < invoiceData?.pendingRefundAmount
              // ||
              // invoiceData?.total
            ) {
              error.pay_amount = "Invalid amount";
            }
            if (
              values.pay_amount > invoiceData?.pendingRefundAmount
              // ||
              // invoiceData?.total
            ) {
              error.pay_amount = "Amount is more than the pending amount";
            }
          } else {
            if (values.pay_amount < invoiceData?.total) {
              error.pay_amount = "Invalid amount";
            }
            if (values.pay_amount > invoiceData?.total) {
              error.pay_amount = "Amount is more than the pending amount";
            }
          }
        }
        if (receipt.current === null) {
          error.receipt = "Upload Reciept is required";
        }
        break;

      case "cheque":
        if (values.cheque_amt === "") {
          error.cheque_amt = "Amount is required";
        }
        if (values.cheque_amt !== "") {
          if (depositInvoice && invoiceData?.pendingRefundAmount) {
            if (
              parseFloat(values.cheque_amt) < invoiceData?.pendingRefundAmount
              //   ||
              // invoiceData?.total
            ) {
              error.cheque_amt = "Invalid amount";
            }
            if (
              values.cheque_amt > invoiceData?.pendingRefundAmount
              // ||
              // invoiceData?.total
            ) {
              error.cheque_amt = "Amount is more than the pending amount";
            }
          } else {
            if (parseFloat(values.cheque_amt) < invoiceData?.total) {
              error.cheque_amt = "Invalid amount";
            }
            if (values.cheque_amt > invoiceData?.total) {
              error.cheque_amt = "Amount is more than the pending amount";
            }
          }
        }
        if (values.cheque_num === "") {
          error.cheque_num = "Cheque Number is required";
        }
        if (values.issuer_name === "") {
          error.issuer_name = "Issuer is required";
        }
        if (values.account_number === "") {
          error.account_number = "Account Number is required";
        }
        if (values.bank_name === "") {
          error.bank_name = "Bank Name is required";
        }
        if (receipt.current === null) {
          error.receipt = "Upload Cheque is required";
        }
        break;

      case "wire":
        if (values.receive_bank === "") {
          error.receive_bank = "Bank Name is required";
        }

        if (values.wire_amount === "") {
          error.wire_amount = "Amount is required";
        }
        if (values.wire_amount !== "") {
          if (depositInvoice && invoiceData?.pendingRefundAmount) {
            if (
              parseFloat(values.wire_amount) < invoiceData?.pendingRefundAmount
              //   ||
              // invoiceData?.total
            ) {
              error.wire_amount = "Invalid amount";
            }
            if (
              values.wire_amount > invoiceData?.pendingRefundAmount
              // ||
              // invoiceData?.total
            ) {
              error.wire_amount = "Amount is more than the pending amount";
            }
          } else {
            if (parseFloat(values.wire_amount) < invoiceData?.total) {
              error.wire_amount = "Invalid amount";
            }
            if (values.wire_amount > invoiceData?.total) {
              error.wire_amount = "Amount is more than the pending amount";
            }
          }
        }

        if (values.sender_name === "") {
          error.sender_name = "Sender Name is required";
        }
        if (values.reference_number === "") {
          error.reference_number = "Reference Number is required";
        }

        break;

      case "moneyOrder":
        if (values.moneyOrder_amount === "") {
          error.moneyOrder_amount = "Amount is required";
        }
        if (values.moneyOrder_amount !== "") {
          if (depositInvoice && invoiceData?.pendingRefundAmount) {
            if (
              parseFloat(values.moneyOrder_amount) <
              invoiceData?.pendingRefundAmount
              //   ||
              // invoiceData?.total
            ) {
              error.moneyOrder_amount = "Invalid amount";
            }
            if (
              values.moneyOrder_amount > invoiceData?.pendingRefundAmount
              // ||
              // invoiceData?.total
            ) {
              error.moneyOrder_amount =
                "Amount is more than the pending amount";
            }
          } else {
            if (parseFloat(values.moneyOrder_amount) < invoiceData?.total) {
              error.moneyOrder_amount = "Invalid amount";
            }
            if (values.moneyOrder_amount > invoiceData?.total) {
              error.moneyOrder_amount =
                "Amount is more than the pending amount";
            }
          }
        }

        if (values.send_name === "") {
          error.send_name = "Sender Name is required";
        }
        if (values.moneyOrder_number === "") {
          error.moneyOrder_number = "Reference Number is required";
        }

        break;

      case "cashier cheque":
        if (values.cashier_check_amopunt === "") {
          error.cashier_check_amopunt = "Amount is required";
        }
        if (values.cashier_check_amopunt !== "") {
          if (depositInvoice && invoiceData?.pendingRefundAmount) {
            if (
              parseFloat(values.cashier_check_amopunt) <
              invoiceData?.pendingRefundAmount
              //   ||
              // invoiceData?.total
            ) {
              error.cashier_check_amopunt = "Invalid amount";
            }
            if (
              values.cashier_check_amopunt > invoiceData?.pendingRefundAmount
              // ||
              // invoiceData?.total
            ) {
              error.cashier_check_amopunt =
                "Amount is more than the pending amount";
            }
          } else {
            if (parseFloat(values.cashier_check_amopunt) < invoiceData?.total) {
              error.cashier_check_amopunt = "Invalid amount";
            }
            if (values.cashier_check_amopunt > invoiceData?.total) {
              error.cashier_check_amopunt =
                "Amount is more than the pending amount";
            }
          }
        }

        if (values.cashier_check_bank === "") {
          error.cashier_check_bank = "Bank Name is required";
        }
        if (values.cashier_check_number === "") {
          error.cashier_check_number = "Reference Number is required";
        }

        break;

      default:
        break;
    }

    return error;
  };

  // Handle SUbmit
  const submitHandler = (values) => {
    const {
      // Cheque
      cheque_num,
      cheque_amt,
      issuer_name,
      account_number,
      bank_name,

      // Cash
      pay_amount,
      reciept_num,
    } = values;

    switch (payMark?.key) {
      case "cash":
        onSubmit({
          receipt_number: reciept_num,
          pay_amount,
          receipt_date: recpdate,
          invoice_doc: receipt.current,
          reason: values.note,
          refunded_via: "cash",
        });
        break;

      case "cheque":
        onSubmit({
          cheque_number: cheque_num,
          cheque_amt,
          cheque_date: recpdate,
          bank_name,
          invoice_doc: receipt.current,
          account_number,
          issuer_name,
          reason: values.note,
          refunded_via: "cheque",
        });
        break;

      case "online":
        onSubmit({
          refunded_via: "online",
          reason: values.note,
        });
        break;

      case "wire":
        onSubmit({
          payment_amount: values.wire_amount,
          reference_no: values.reference_number,
          sender_name: values.sender_name,
          bank_name: values.receive_bank,
          transfer_date: recpdate,
          reason: values.note,
          refunded_via: "wire",
        });
        break;

      case "moneyOrder":
        onSubmit({
          payment_amount: values.moneyOrder_amount,
          money_order_no: values.moneyOrder_number,
          sender_name: values.send_name,
          money_order_date: recpdate,
          reason: values.note,
          refunded_via: "money order",
        });
        break;

      case "cashier cheque":
        onSubmit({
          payment_amount: values.cashier_check_amopunt,
          cheque_no: values.cashier_check_number,
          bank_name: values.cashier_check_bank,
          cashier_cheque_date: recpdate,
          reason: values.note,
          refunded_via: "cashier cheque",
        });
        break;
    }
  };

  /* ************* Component rendering. JSX code ************* */
  return (
    <Modal
      size={size}
      centered
      show={showModal}
      className="claim-modal-wrapper"
    >
      <Modal.Body
        className={`${userTheme === "dark" ? "bg_darkTheme" : "bg_lightTheme"}`}
      >
        <div className="modal-header-custom">
          <p className="font_grey">
            {depositInvoice ? "Pending Refund Amount" : "Total Amount"} :{" "}
            <span className="font_blue">
              {!depositInvoice
                ? invoiceData?.total?.toLocaleString("en-US") ||
                  invoiceData?.total_amount?.toLocaleString("en-US")
                : invoiceData?.pendingRefundAmount?.toLocaleString("en-US") ||
                  invoiceData?.total?.toLocaleString("en-US")}{" "}
              {invoiceData?.currency}
            </span>
          </p>
        </div>
        <div className="modal-body-custom">
          <Formik
            onSubmit={submitHandler}
            initialValues={initialFormValues}
            validate={(values) => formValidation(values, payMark)}
            validateOnBlur={true}
            enableReinitialize={true}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              setFieldTouched,
            }) => (
              <form onSubmit={handleSubmit}>
                {/* Form Fields */}
                <div className="form-wrapper form-wrapper-flex">
                  <div className="form-group-full mt-2">
                    <CustomSelectInput
                      name="pay_Mark"
                      label="Select Payment Method"
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      valueExtractor={(item) => item?.name}
                      options={paymentMethods}
                      value={payMark}
                      setValue={setPayMark}
                      formikState={false}
                    />
                  </div>

                  {payMark?.key === "cash" && (
                    <>
                      <div className="form-group mt-2">
                        <CustomTextInput
                          name="reciept_num"
                          label="Receipt Number"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          touched={touched.reciept_num}
                          errors={errors.reciept_num}
                          value={values.reciept_num}
                          required={true}
                        />
                      </div>

                      <div className="form-group">
                        <CustomCurrencyInput
                          name="pay_amount"
                          label="Cash Amount"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldTouched}
                          touched={touched.pay_amount}
                          errors={errors.pay_amount}
                          value={values.pay_amount}
                          currency={invoiceData?.currency}
                          required={true}
                        />
                      </div>

                      <div className="form-group">
                        <CustomFileInput
                          label="Upload Reciept"
                          name="receipt"
                          files={receipt}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          errors={errors.receipt}
                          touched={touched.receipt}
                          values={values.receipt}
                          required={true}
                          formikState={false}
                        />
                      </div>
                    </>
                  )}

                  {payMark?.key === "cheque" && (
                    <>
                      <div className="form-group mt-2">
                        <CustomTextInput
                          name="cheque_num"
                          label="Cheque Number"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          touched={touched.cheque_num}
                          errors={errors.cheque_num}
                          value={values.cheque_num}
                          required={true}
                        />
                      </div>
                      {/* <div className="form-group">
                        <CustomTextInput
                          name="cheque_amt"
                          label="Cheque Amount"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          touched={touched.cheque_amt}
                          errors={errors.cheque_amt}
                          value={values.cheque_amt}
                          required={true}
                        />
                      </div> */}

                      <div className="form-group">
                        <CustomCurrencyInput
                          name="cheque_amt"
                          label="Cheque Amount"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          touched={touched.cheque_amt}
                          errors={errors.cheque_amt}
                          value={values.cheque_amt}
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldTouched}
                          currency={invoiceData?.currency}
                          required={true}
                        />
                      </div>

                      <div
                        className={`form-group ${
                          (userTheme === "light" ||
                            userTheme === "automatic") &&
                          "otherTheme"
                        } `}
                      >
                        <CustomDateInput
                          label="Cheque Date"
                          value={recpdate}
                          minDate={false}
                          customOnchange={(date) => setRecpdate(date)}
                          required={true}
                        />
                      </div>
                      <div className="form-group">
                        <CustomTextInput
                          name="bank_name"
                          label="Bank Name"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          touched={touched.bank_name}
                          errors={errors.bank_name}
                          value={values.bank_name}
                          required={true}
                        />
                      </div>

                      <div className="form-group">
                        <CustomTextInput
                          name="issuer_name"
                          label="Issuer Name"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          touched={touched.issuer_name}
                          errors={errors.issuer_name}
                          value={values.issuer_name}
                          required={true}
                        />
                      </div>

                      <div className="form-group">
                        <CustomTextInput
                          name="account_number"
                          label="Account Number"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          touched={touched.account_number}
                          errors={errors.account_number}
                          value={values.account_number}
                          required={true}
                        />
                      </div>

                      <div className="form-group mt-2">
                        <CustomFileInput
                          label="Upload Cheque"
                          name="cheque"
                          files={receipt}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          errors={errors.cheque}
                          touched={touched.cheque}
                          values={values.cheque}
                          required={true}
                          formikState={false}
                        />
                      </div>
                    </>
                  )}

                  {payMark?.key === "wire" && (
                    <>
                      <div className="form-group mt-2">
                        <CustomTextInput
                          name="receive_bank"
                          label="Receiving Bank Name"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          touched={touched.receive_bank}
                          errors={errors.receive_bank}
                          value={values.receive_bank}
                          required={true}
                        />
                      </div>
                      {/* <div className="form-group">
                        <CustomTextInput
                          name="cheque_amt"
                          label="Cheque Amount"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          touched={touched.cheque_amt}
                          errors={errors.cheque_amt}
                          value={values.cheque_amt}
                          required={true}
                        />
                      </div> */}

                      <div className="form-group">
                        <CustomCurrencyInput
                          name="wire_amount"
                          label="Amount"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          touched={touched.wire_amount}
                          errors={errors.wire_amount}
                          value={values.wire_amount}
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldTouched}
                          currency={invoiceData?.currency}
                          required={true}
                        />
                      </div>

                      {/* <div
                        className={`form-group ${
                          (userTheme === "light" ||
                            userTheme === "automatic") &&
                          "otherTheme"
                        } `}
                      >
                        <CustomDateInput
                          label="Transfer Date"
                          value={recpdate}
                          minDate={false}
                          customOnchange={(date) => setRecpdate(date)}
                          required={true}
                        />
                      </div> */}
                      {/* <div className="form-group">
                          <CustomTextInput
                            name="bank_name"
                            label="Bank Name"
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            touched={touched.bank_name}
                            errors={errors.bank_name}
                            value={values.bank_name}
                            required={true}
                          />
                        </div> */}

                      <div className="form-group">
                        <CustomTextInput
                          name="sender_name"
                          label="Sender Name"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          touched={touched.sender_name}
                          errors={errors.sender_name}
                          value={values.sender_name}
                          required={true}
                        />
                      </div>

                      <div className="form-group">
                        <CustomTextInput
                          name="reference_number"
                          label="Reference Number"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          touched={touched.reference_number}
                          errors={errors.reference_number}
                          value={values.reference_number}
                          required={true}
                        />
                      </div>

                      {/* <div className="form-group mt-2">
                          <CustomFileInput
                            label="Upload Cheque"
                            name="cheque"
                            files={receipt}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            errors={errors.cheque}
                            touched={touched.cheque}
                            values={values.cheque}
                            required={true}
                            formikState={false}
                          />
                        </div> */}
                    </>
                  )}

                  {payMark?.key === "moneyOrder" && (
                    <>
                      <div className="form-group">
                        <CustomCurrencyInput
                          name="moneyOrder_amount"
                          label="Amount"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          touched={touched.moneyOrder_amount}
                          errors={errors.moneyOrder_amount}
                          value={values.moneyOrder_amount}
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldTouched}
                          currency={invoiceData?.currency}
                          required={true}
                        />
                      </div>

                      <div
                        className={`form-group ${
                          (userTheme === "light" ||
                            userTheme === "automatic") &&
                          "otherTheme"
                        } `}
                      >
                        <CustomDateInput
                          label="Transfer Date"
                          value={recpdate}
                          minDate={false}
                          customOnchange={(date) => setRecpdate(date)}
                          required={true}
                        />
                      </div>

                      <div className="form-group">
                        <CustomTextInput
                          name="send_name"
                          label="Sender Name"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          touched={touched.send_name}
                          errors={errors.send_name}
                          value={values.send_name}
                          required={true}
                        />
                      </div>

                      <div className="form-group">
                        <CustomTextInput
                          name="moneyOrder_number"
                          label="Money Order Number"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          touched={touched.moneyOrder_number}
                          errors={errors.moneyOrder_number}
                          value={values.moneyOrder_number}
                          required={true}
                        />
                      </div>
                    </>
                  )}

                  {payMark?.key === "cashier cheque" && (
                    <>
                      <div className="form-group">
                        <CustomCurrencyInput
                          name="cashier_check_amopunt"
                          label="Cashier Cheque Amount"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          touched={touched.cashier_check_amopunt}
                          errors={errors.cashier_check_amopunt}
                          value={values.cashier_check_amopunt}
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldTouched}
                          currency={invoiceData?.currency}
                          required={true}
                        />
                      </div>

                      <div
                        className={`form-group ${
                          (userTheme === "light" ||
                            userTheme === "automatic") &&
                          "otherTheme"
                        } `}
                      >
                        <CustomDateInput
                          label="Date"
                          value={recpdate}
                          minDate={false}
                          customOnchange={(date) => setRecpdate(date)}
                          required={true}
                        />
                      </div>

                      <div className="form-group">
                        <CustomTextInput
                          name="cashier_check_bank"
                          label="Cashier Cheque Bank Name"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          touched={touched.cashier_check_bank}
                          errors={errors.cashier_check_bank}
                          value={values.cashier_check_bank}
                          required={true}
                        />
                      </div>

                      <div className="form-group">
                        <CustomTextInput
                          name="cashier_check_number"
                          label="Cashier Cheque Number"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          touched={touched.cashier_check_number}
                          errors={errors.cashier_check_number}
                          value={values.cashier_check_number}
                          required={true}
                        />
                      </div>
                    </>
                  )}

                  {payMark?.key && (
                    <div className="form-group-full mt-2">
                      <CustomTextArea
                        name="note"
                        label="Remark"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        value={values.note}
                        errors={errors.note}
                        touched={touched.note}
                        required={true}
                      />
                    </div>
                  )}
                </div>

                {/* Submit and Cancel */}
                <div className="button-wrapper text-center">
                  <>
                    <CustomButton type="submit" size="l">
                      Refund
                    </CustomButton>
                    <TextButton
                      type="button"
                      onClick={onClose}
                      className="font_m fw_6"
                    >
                      Cancel
                    </TextButton>
                  </>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </Modal.Body>

      {/* Success Modal */}
      <SuccessModal
        showModal={showSuccessModal}
        description={modalDesc}
        onClose={() => {
          onClose();
          setShowSuccessModal(false);
        }}
      />

      {/* Error Modal */}
      <ErrorModal
        showModal={showErrorModal}
        description={modalDesc}
        title="Error"
        onClose={() => setShowErrorModal(false)}
      />
    </Modal>
  );
};
/* Component ends */

export default RefundPaymentModal;
