export const initialValues = {
  doc_title: "",
  description: "",
  photos: [null],
};

export const validations = (values) => {
  const errorFields = {};

  /* Validations for Document Title Starts */
  if (values.doc_title === "") {
    errorFields.doc_title = "Document Title is required";
  }

  /* Validations for Document Title Ends */

  /* Validations for Description Starts */
  if (values.description === "") {
    errorFields.description = "Description is required";
  }
  /* Validations for Description Ends */

  return errorFields;
};

// Field Prop option extractor
export const fieldPropExtractor = (option, index) => {
  if (option?.photos?.length > 0) return option?.photos[index];
  else return undefined;
};
