/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import GLOBAL from "../../setup/constants/global";
/* Import configuration ends */

/* Import redux slices component starts */
import { fetchMoveoutPMReviewDetail } from "../../setup/store/slices/moveOutSlice";
import { showErrorAlert } from "../../setup/store/slices/globalAlertSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import MoveOutPMReview from "../../components/module/Moveout/MoveOutPMReview/MoveOutPMReview";
/* Import local pages and component ends */

/* Component starts */
const MoveOutPMReviewPage = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [moveOutDetail, setMoveOutDetail] = useState(null);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const { moveoutId } = useParams();
  // const moveOutDetail = useSelector(getMoveoutDetail);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const getMoveoutDetail = async () => {
    // Creating or collecting payload data to be sent
    const payload = { move_out_id: moveoutId };
    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      const result = await dispatch(fetchMoveoutPMReviewDetail(payload));
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setMoveOutDetail(null);
          dispatch(
            showErrorAlert({
              title: "Error!",
              description:
                typeof result?.payload === "string" ? result?.payload : "",
            })
          );
          break;

        case GLOBAL.FULFILLED:
          setMoveOutDetail(result.payload);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    getMoveoutDetail();
  }, []);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="">
      {moveOutDetail && (
        <MoveOutPMReview
          moveOutDetail={moveOutDetail}
          getMoveoutDetail={getMoveoutDetail}
        />
      )}
    </div>
  );
};
/* Component ends */

export default MoveOutPMReviewPage;
