/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useDispatch } from "react-redux";
import GLOBAL from "../../../../../setup/constants/global";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  showSuccessAlert,
  showErrorAlert,
} from "../../../../../setup/store/slices/globalAlertSlice";
import { updatePropertySettings } from "../../../../../setup/store/slices/propertySlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { EditGreen, EditWhite } from "../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomRadioInput from "../../../../ui/input/CustomRadioInput/CustomRadioInput";
import CustomButton from "../../../../ui/button/CustomButton/CustomButton";
import IconButton from "../../../../ui/button/IconButton/IconButton";
import EditUtilityDocumentsModal from "../../EditUtilityDocumentsModal/EditUtilityDocumentsModal";
/* Import local pages and component ends */

/* Component starts */
const UtilityDocMoveOut = (props) => {
  /* Props destructuring starts */
  const { user, settings, property_id } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [settingsChanged, setSettingsChanged] = useState(false);
  const [enabled, setEnabled] = useState();
  const [docList, setDocList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const onSave = async (data) => {
    setOpenModal(false);
    // Creating or collecting payload data to be sent
    const payload = {
      collect_utility_in_move_out: enabled,
      collect_utility_in_move_out_list: JSON.stringify(data),
      property_id,
    };
    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      const result = await dispatch(updatePropertySettings(payload));
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result?.payload || "",
            })
          );
          break;

        case GLOBAL.FULFILLED:
          setSettingsChanged(false);
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result?.payload?.message || "",
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  const onSwitching = async () => {
    // Creating or collecting payload data to be sent
    const payload = { collect_utility_in_move_out: enabled, property_id };
    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      const result = await dispatch(updatePropertySettings(payload));
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result?.payload || "",
            })
          );
          break;

        case GLOBAL.FULFILLED:
          setSettingsChanged(false);
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result?.payload?.message || "",
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    setDocList(
      settings?.collect_utility_in_move_out_list
        ? JSON.parse(settings?.collect_utility_in_move_out_list)
        : []
    );
    setEnabled(settings.collect_utility_in_move_out);
  }, [settings]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="tenant-id-count option">
      {/* Label */}
      <div className="label">
        <p className="font_m fw_5">
          {/* Utility Registration Documents During Move out */}
          Mandatory Documents Required During Move Out
        </p>
      </div>

      {/* Setting */}
      <div className="setting">
        <div className="row1">
          <div className="accept-fee-wrapper">
            <p className="font_s fw_5 font_grey accept-fee mb_1">
              Collect document during move out ?
            </p>
            <div className="radio-wrapper">
              <CustomRadioInput
                label="Yes"
                className="radio-input"
                isSelected={enabled}
                onSelecting={() => {
                  setSettingsChanged(true);
                  setEnabled(true);
                }}
              />
              <CustomRadioInput
                label="No"
                className="radio-input"
                isSelected={!enabled}
                onSelecting={() => {
                  setSettingsChanged(true);
                  setEnabled(false);
                }}
              />
            </div>
          </div>
        </div>

        {enabled && (
          <div className="row2 mt_3">
            <IconButton onClick={() => setOpenModal(true)}>
              <img src={EditGreen} className="icon" alt="Edit" />
            </IconButton>
          </div>
        )}

        {/* Save Button */}
        {settingsChanged &&
          ((settings?.collect_utility_in_move_out_list &&
            JSON.parse(settings?.collect_utility_in_move_out_list).length >
              0) ||
            !enabled) && (
            <div className="save-btn-wrapper mt_2">
              <CustomButton onClick={onSwitching} className="save-btn" size="s">
                Save
              </CustomButton>
            </div>
          )}
      </div>

      <EditUtilityDocumentsModal
        showModal={openModal}
        docList={docList}
        onSave={onSave}
        onClose={() => {
          setOpenModal(false);
        }}
      />
    </div>
  );
};
/* Component ends */

export default UtilityDocMoveOut;
