/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import GLOBAL from "../../setup/constants/global";
/* Import configuration ends */

/* Import redux slices component starts */
import { fetchMoveoutDetailsForAccountant } from "../../setup/store/slices/invoiceSlice";
import { showErrorAlert } from "../../setup/store/slices/globalAlertSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import AccountantMoveOutClearence from "../../components/module/Moveout/AccountantMoveOutClearence/AccountantMoveOutClearence";
import Breadcrumb from "../../components/ui/other/BreadCrumb/BreadCrumb";
/* Import local pages and component ends */

/* Component starts */
const AccountantMoveOutClearencePage = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [moveOut, setMoveOut] = useState(null);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const { moveoutId } = useParams();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const forceMoveout = searchParams.get("force") === "1";
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const getMoveoutDetails = async () => {
    // Creating or collecting payload data to be sent
    const payload = { move_out_id: moveoutId };
    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      const result = await dispatch(fetchMoveoutDetailsForAccountant(payload));
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setMoveOut(null);
          dispatch(
            showErrorAlert({
              title: "Error!",
              description:
                typeof result?.payload === "string" ? result?.payload : "",
            })
          );
          break;

        case GLOBAL.FULFILLED:
          setMoveOut(result?.payload);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    getMoveoutDetails();
  }, [moveoutId]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="">
      <Breadcrumb type="accountant_moveout_clearence" />
      {moveOut && (
        <AccountantMoveOutClearence
          move_out_id={moveoutId}
          application_id={moveOut?.tenant_application?._id}
          moveOut={moveOut}
          forceMoveout={forceMoveout}
          getMoveoutDetails={getMoveoutDetails}
        />
      )}
    </div>
  );
};
/* Component ends */

export default AccountantMoveOutClearencePage;
