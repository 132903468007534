/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import GLOBAL from "../../../../../setup/constants/global";
import { useDispatch } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../../../setup/store/slices/globalAlertSlice";

import {
  cancelTenantInvitation,
  tenantCancelApplicationAnytime,
  rejectApplicationPostInitialAgreement,
} from "../../../../../setup/store/slices/tenantSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomButton from "../../../../ui/button/CustomButton/CustomButton";
import ApplicationDataRejectionModal from "../../../Tenant/ApplicationDataRejectionModal/ApplicationDataRejectionModal";
import ConfirmationModal from "../../../../ui/modal/ConfirmationModal/ConfirmationModal";
/* Import local pages and component ends */

/* Component starts */
const CancelApplicationBlock = (props) => {
  /* Props destructuring starts */
  const { unit, fetchTenantUnitsNew } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const [showNoteModal, setShowNoteModal] = useState(false);
  const [selectedCancellationType, setSelectedCancellationType] = useState("");
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const currentUserRole = localStorage.getItem("user_role");
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const onCancelInvitation = async (invitation_id) => {
    // Backend response. Try, Catch
    try {
      const result = await dispatch(cancelTenantInvitation(invitation_id));
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description:
                typeof result?.payload === "string" ? result?.payload : "",
            })
          );
          break;

        case GLOBAL.FULFILLED:
          fetchTenantUnitsNew();
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description:
                typeof result?.payload?.message === "string"
                  ? result?.payload?.message
                  : "",
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  const onCancelApplicationWithNote = async (rejection_reason = "") => {
    // Creating or collecting payload data to be sent
    let payload, result;
    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      switch (unit?.status) {
        /* Case Block ***************************************************************** */
        case "pending_tenant_application":
        case "pending_review":
        case "pending_tenant_data_update":
        case "pending_initial_agreement_terms":
        case "pending_tenant_sign_initial_agreement":
        case "pending_pm_sign_initial_agreement":
          payload = {
            application_id: unit?.application_id,
            step: "cancel_before_review",
            rejection_reason,
            rejected_by: currentUserRole,
          };
          result = await dispatch(tenantCancelApplicationAnytime(payload));
          break;

        /* Case Block ***************************************************************** */
        case "tenant_invoice_payment":
        case "utilities":
        case "utility_rejected":
        case "utilities_submitted":
        case "pending_tenant_sign_final_agreement":
        case "pending_tenant_cheque_submission":
        case "pending_approver_sign_final_agreement":
          payload = { application_id: unit?.application_id, rejection_reason };
          result = await dispatch(
            rejectApplicationPostInitialAgreement(payload)
          );
          break;
      }
      console.log("Fetching Units............");
      fetchTenantUnitsNew();
      console.log("Response", result);
      setShowNoteModal(false);
      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description:
                typeof result?.payload === "string" ? result?.payload : "",
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: "Application cancelled successfully",
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  const confirmCancel = () => {
    setShowCancelConfirmation(false);
    switch (unit?.status) {
      case "pending_tenant_inv_confirmation":
        onCancelInvitation(unit?.invitation_id);
        break;

      case "pending_tenant_application":
      case "pending_review":
      case "pending_tenant_data_update":
      case "pending_initial_agreement_terms":
      case "pending_tenant_sign_initial_agreement":
      case "pending_pm_sign_initial_agreement":
      case "tenant_invoice_payment":
      case "utilities":
      case "utility_rejected":
      case "utilities_submitted":
      case "pending_tenant_sign_final_agreement":
      case "pending_tenant_cheque_submission":
      case "pending_approver_sign_final_agreement":
        setShowNoteModal(true);
        break;

      default:
        break;
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  if (unit && unit?.status)
    return (
      <>
        <div className="cancel-application-btn-wrapper mt-4">
          <CustomButton
            className="cancel-application-btn"
            variant="red"
            size="l"
            onClick={() => setShowCancelConfirmation(true)}
          >
            Cancel Application
          </CustomButton>
        </div>

        <ConfirmationModal
          showModal={showCancelConfirmation}
          title="Confirm Cancel Application ?"
          // description="Are you sure you want to cancel this onboarding process? Remember, this is an irreversible action."
          description="I would like to cancel the onboarding application process. I am aware
          that after cancelling the application I won't be able to undo my
          action."
          onClose={() => setShowCancelConfirmation(false)}
          onConfirm={confirmCancel}
        />

        <ApplicationDataRejectionModal
          showModal={showNoteModal}
          title="Cancel Onboarding"
          description={
            <p className="font_grey fw_6">
              Please provide a reason for Cancellation.
            </p>
          }
          btnText="Cancel"
          appLabel="Reason for cancellation"
          onCancel={() => setShowNoteModal(false)}
          onRejectWithNote={onCancelApplicationWithNote}
        />
      </>
      // <div className="cancel-application-slider-item-wrapper bg_grey">
      //   {/* <p className="font_m font_yellow fw_5">
      //     I would like to cancel the onboarding application process. I am aware
      //     that after cancelling the application I won't be able to undo my
      //     action.
      //   </p> */}

      // </div>
    );
};
/* Component ends */

export default CancelApplicationBlock;
