/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import MoveOutDetails from "../../components/module/Tenant/MoveOutDetails/MoveOutDetails";
/* Import local pages and component ends */

/* Component starts */
const MoveOutDetailsPage = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="moveout-details-page-wrapper">
      <MoveOutDetails />
    </div>
  );
};
/* Component ends */

export default MoveOutDetailsPage;
