/**
 *
 */

import React, { useState } from "react";

/* Import configuration starts */
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { validEmail } from "../../../setup/utils/validations";
import { Toastify } from "../../../setup/utils/toast";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  resendVerificationCode,
  verifyAccount,
  resendVerificationEmail,
  setToken,
  setUser,
  sendForgotPasswordOtp,
  verifyEmailOtp,
  signin,
  fetchUser,
  setUserRole,
} from "../../../setup/store/slices/authSlice";
import { addMember } from "../../../setup/store/slices/teamSlice";
import { getAuthLoading } from "../../../setup/store/slices/unpersistedSlice";
import { fetchTenant } from "../../../setup/store/slices/authSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { ArrowPointRightBlack } from "../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomTextInput from "../../../components/ui/input/CustomTextInput/CustomTextInput";
import CustomPasswordInput from "../../../components/ui/input/CustomPasswordInput/CustomPasswordInput";
import CustomButton from "../../../components/ui/button/CustomButton/CustomButton";
import ConfirmationOTPModal from "../../../components/ui/modal/ConfirmationOTPModal/ConfirmationOTPModal";
/* Import local pages and component ends */

/* Component starts */
const SignInForm = ({ onSignInSuccess }) => {
  /* Props destructuring starts */
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [errorWhileSubmiting, setErrorWhileSubmiting] = useState(null);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  // Redux Selector
  const authLoading = useSelector(getAuthLoading);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const initialValues = {
    username: "",
    password: "",
  };
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // Error text if the required input field is empty
  const errorMsgForEmptyFields = (fieldName) => {
    switch (fieldName) {
      case "username":
        return "Email or Phone Number is required";
      case "password":
        return "Password is required";
      default:
        return "Invalid Input";
    }
  };

  // Input fields that are required are validated
  const requiredFieldsValidation = (errorFields, values) => {
    Object.keys(values).map((key) => {
      if (values[key] === "") {
        errorFields[key] = errorMsgForEmptyFields(key);
      }
    });
    return errorFields;
  };

  // Other form validations logics
  const otherFieldValidations = (errorFields, values) => {
    /* Validation for username starts */
    if (isNaN(+values.username) && !validEmail(values.username)) {
      errorFields.username = "Email is not valid";
    }
    /* Validation for username ends */
  };

  // Form validations
  const formValidation = (values) => {
    const errorFields = {};
    requiredFieldsValidation(errorFields, values);
    otherFieldValidations(errorFields, values);
    return errorFields;
  };

  // Handling on signing in
  const onSignIn = async (values) => {
    setErrorWhileSubmiting(null);

    // Creating or gathering payload data to be sent
    const payload = {
      username: values.username,
      password: values.password,
    };

    // Backend Response. Try, Catch
    try {
      const result = await dispatch(signin(payload));
      // Handling success response
      switch (result.meta.requestStatus) {
        case "rejected":
          setErrorWhileSubmiting(result.payload.error.message);
          break;

        case "fulfilled":
          // await dispatch(setToken(result.payload.token));
          // await dispatch(setUser(result.payload.user));
          localStorage.setItem(
            "user_data",
            JSON.stringify(result.payload.user)
          );
          localStorage.setItem("user_token", result.payload.token);
          localStorage.setItem("user_role", result.payload.user.role_id.name);
          await dispatch(setUserRole(result.payload.user.role_id.name));
          await dispatch(fetchTenant());
          await dispatch(setUserRole("Tenant"));
          await onSignInSuccess();
          navigate("/tenant/home");
          break;
      }
    } catch (e) {
      // Handling error response
      console.error(e.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div>
      {/* Sign in form */}
      <Formik
        onSubmit={onSignIn}
        initialValues={initialValues}
        validate={formValidation}
        validateOnBlur={true}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            {/* Input for Username */}
            <div className="form-group">
              <CustomTextInput
                name="username"
                type="text"
                label="Email / Phone Number"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.username}
                touched={touched.username}
                errors={errors.username}
              />
            </div>

            {/* Input for Password */}
            <div className="form-group">
              <CustomPasswordInput
                name="password"
                type="password"
                showPasswordValidationErr={false}
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.password}
                errors={errors.password}
                touched={touched.password}
                label="Password"
              />
            </div>

            {/* If any error occured from backend while submitting */}
            {errorWhileSubmiting && (
              <p className="error-while-submitting font_m font_red text-center w_100">
                {errorWhileSubmiting}
              </p>
            )}

            {/* Submit button and Sign in loading */}
            {authLoading ? (
              <p className="sigining-in font_m font_grey text-center">
                Signing you in
              </p>
            ) : (
              <div className="submit-button-wrapper">
                <CustomButton className="custom-btn-2" type="submit" size="l">
                  Sign In
                  <img
                    src={ArrowPointRightBlack}
                    className="arrow icon"
                    alt=""
                  />
                </CustomButton>
              </div>
            )}
          </form>
        )}
      </Formik>

      {/* Modals */}
    </div>
  );
};
/* Component ends */

export default SignInForm;
