/**
 *
 */

import React, { useState, useEffect, useRef } from "react";

/* Import configuration starts */
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import GLOBAL from "../../../../setup/constants/global";
import { initialValues, validations, formReset } from "./helper";
import { useSearchParams, useParams } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
import { inviteMember } from "../../../../setup/store/slices/teamSlice";
import {
  fetchUserRoles,
  getUserRoles,
} from "../../../../setup/store/slices/masterSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import SuccessModal from "../../../ui/modal/SuccessModal/SuccessModal";
import ErrorModal from "../../../ui/modal/ErrorModal/ErrorModal";
import CustomTextInput from "../../../ui/input/CustomTextInput/CustomTextInput";
import CustomPhoneInput from "../../../ui/input/CustomPhoneInput/CustomPhoneInput";
import CustomFileInput from "../../../ui/input/CustomFileInput/CustomFileInput";
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
import TextButton from "../../../ui/button/TextButton/TextButton";
import CustomRadioInput from "../../../ui/input/CustomRadioInput/CustomRadioInput";
import SearchForAccount from "../../../ui/other/SearchForAccount/SearchForAccount";
import CustomTextArea from "../../../ui/input/CustomTextArea/CustomTextArea";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const AddMT = (props) => {
  /* Props destructuring starts */
  const { onSuccess, onCancel, parentUserId } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [initialFormValues, setInitialFormValues] = useState(initialValues);
  const [message, setMessage] = useState("");
  const [showErrModal, setShowErrModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [mtRole, setMtRole] = useState();
  const [search, setSearch] = useState(false);
  const [memberSelected, setMemberSelected] = useState(false);

  // useRef
  const customPhoneNum = useRef({ code: "", num: "" });
  const resume = useRef(null);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const { propertyId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  // Redux Slice
  const userRoles = useSelector(getUserRoles);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const property_id = searchParams.get("property_id") || propertyId;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // On selecting searched MT
  const inviteSearched = (searchResult) => {
    setInitialFormValues({
      first_name: searchResult.first_name,
      last_name: searchResult.last_name,
      email: searchResult.email,
      mobile: searchResult.countrycode + searchResult.mobile,
      business_name: searchResult.business_name,
      employee_id: "",
      profession: "",
      resume: "",
      notes: "",
    });
    customPhoneNum.current = {
      code: searchResult.countrycode,
      num: searchResult.mobile,
    };
    setSearch(false);
    setMemberSelected(true);
  };

  // Form validations
  const formValidation = (values) => {
    return validations(values, customPhoneNum);
  };

  // Handling on submit
  const onSubmit = async (values) => {
    // Creating or collecting payload data to be sent
    const payload = {
      role_id: mtRole._id,
      parent_user_id: parentUserId,
      property_id: property_id,
      first_name: values.first_name.trim(),
      last_name: values.last_name.trim(),
      email: values.email.toLowerCase().trim(),
      countrycode: customPhoneNum.current.code,
      mobile: customPhoneNum.current.num,
      business_name: values.business_name,
      employee_id: values.employee_id,
      notes: values.notes,
      profession: values.profession,
    };
    if (resume.current) {
      payload["resume"] = resume.current;
    }
    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      const result = await dispatch(inviteMember(payload));
      console.log("Result", result);
      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setMessage(result.payload);
          setShowErrModal(true);
          break;

        case GLOBAL.FULFILLED:
          setMessage(
            "Your Maintenance Technician information has been sent for Review"
          );
          setShowSuccessModal(true);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  // Fetching all user roles on initial load
  useEffect(() => {
    dispatch(fetchUserRoles());
  }, []);

  // Setting MT role after all user roles loaded
  useEffect(() => {
    let userRole = userRoles.filter((d) => d.name == GLOBAL.USER_ROLE.MT)[0];
    setMtRole(userRole);
  }, [userRoles]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="add-mt-module-wrapper">
      <h2 className="heading text-center font_xxl mb-5">
        Add Maintenance Technician
      </h2>

      <div className="add-new-form">
        <Formik
          onSubmit={onSubmit}
          initialValues={initialFormValues}
          validate={formValidation}
          validateOnBlur={true}
          enableReinitialize={true}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="form-wrapper">
                {/* Radio select if there is existing MT or not */}
                <div className="form-group form-group-full w_100">
                  <p className="font_m font_grey w_100">Adding existing MT ?</p>
                  <CustomRadioInput
                    label="Yes"
                    onSelecting={() => {
                      setSearch(true);
                    }}
                    isSelected={search || memberSelected}
                  />
                  <CustomRadioInput
                    label="No"
                    onSelecting={() =>
                      formReset(
                        setSearch,
                        setInitialFormValues,
                        setMemberSelected,
                        customPhoneNum,
                        resume
                      )
                    }
                    isSelected={!search && !memberSelected}
                  />
                </div>

                {/* Creating new MT */}
                {!search && (
                  <>
                    {/* Input for First Name */}
                    <div className="form-group">
                      <CustomTextInput
                        name="first_name"
                        label="First Name"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        touched={touched.first_name}
                        errors={errors.first_name}
                        value={values.first_name}
                        readOnly={memberSelected}
                      />
                    </div>

                    {/* Input for Last Name */}
                    <div className="form-group">
                      <CustomTextInput
                        name="last_name"
                        label="Last Name"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        touched={touched.last_name}
                        errors={errors.last_name}
                        value={values.last_name}
                        readOnly={memberSelected}
                      />
                    </div>

                    {/* Input for Email */}
                    <div className="form-group">
                      <CustomTextInput
                        name="email"
                        label="Email"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        touched={touched.email}
                        errors={errors.email}
                        value={values.email}
                        readOnly={memberSelected}
                      />
                    </div>

                    {/* Input for Phone */}
                    <div className="form-group">
                      <CustomPhoneInput
                        name="mobile"
                        label={"Phone Number"}
                        customPhoneNum={customPhoneNum}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        value={values.mobile}
                        countryCodeEditable={true}
                        errors={errors.mobile}
                        touched={touched.mobile}
                        readOnly={memberSelected}
                      />
                    </div>

                    {/* Input for Company Name */}
                    <div className="form-group">
                      <CustomTextInput
                        name="business_name"
                        label="Company Name"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        touched={touched.business_name}
                        errors={errors.business_name}
                        value={values.business_name}
                      />
                    </div>

                    {/* Input for Profession */}
                    <div className="form-group">
                      <CustomTextInput
                        name="profession"
                        label="Profession"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        touched={touched.profession}
                        errors={errors.profession}
                        value={values.profession}
                      />
                    </div>

                    {/* Input for Employee ID */}
                    <div className="form-group">
                      <CustomTextInput
                        name="employee_id"
                        label="Employee ID"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        touched={touched.employee_id}
                        errors={errors.employee_id}
                        value={values.employee_id}
                      />
                    </div>

                    {/* Input for Resume */}
                    <div className="form-group">
                      <CustomFileInput
                        label="Upload Resume"
                        name="resume"
                        files={resume}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        errors={errors.resume}
                        touched={touched.resume}
                        values={values.resume}
                        required={false}
                        formikState={false}
                      />
                    </div>

                    {/* Text Area for Notes */}
                    <div className="form-group form-group-full">
                      <CustomTextArea
                        name="notes"
                        label="Notes"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        value={values.notes}
                        errors={errors.notes}
                        touched={touched.notes}
                        required={false}
                      />
                    </div>
                  </>
                )}
              </div>

              <div className="button-wrapper text-center">
                {!search && (
                  <>
                    <CustomButton type="submit" size="l">
                      Add
                    </CustomButton>
                    <TextButton
                      type="button"
                      onClick={onCancel}
                      className="font_m fw_6"
                    >
                      Cancel
                    </TextButton>
                  </>
                )}
              </div>
            </form>
          )}
        </Formik>

        {/* Searching for existing MT */}
        {search && (
          <>
            <SearchForAccount
              roleType={mtRole}
              title="Search Property Manager"
              onInvite={inviteSearched}
            />
          </>
        )}

        {/* Modals */}
        {/* Error Modal */}
        <ErrorModal
          showModal={showErrModal}
          title="Error"
          description={message}
          onClose={() => {
            setShowErrModal(false);
          }}
        />

        {/* Success Modal */}
        <SuccessModal
          showModal={showSuccessModal}
          title="Success"
          description={message}
          onClose={() => {
            onSuccess();
          }}
        />
      </div>
    </div>
  );
};
/* Component ends */

export default AddMT;
