/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useDispatch } from "react-redux";
import GLOBAL from "../../../../../setup/constants/global";
import { dayMeasurement } from "../helper";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  showSuccessAlert,
  showErrorAlert,
} from "../../../../../setup/store/slices/globalAlertSlice";
import { updatePropertySettings } from "../../../../../setup/store/slices/propertySlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomButton from "../../../../ui/button/CustomButton/CustomButton";
import CustomNumberInput from "../../../../ui/input/CustomNumberInput/CustomNumberInput";
import CustomSelectInput from "../../../../ui/input/CustomSelectInput/CustomSelectInput";
/* Import local pages and component ends */

/* Component starts */
const UnitReadinessGap = (props) => {
  /* Props destructuring starts */
  const { user, settings, property_id } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [settingsChanged, setSettingsChanged] = useState(false);
  const [selectedDuration, setSelectedDuration] = useState();
  const [durationCount, setDurationCount] = useState();
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  // On updating setting
  const onUpdate = async () => {
    if (parseInt(durationCount) === 0 || durationCount === "") {
      dispatch(
        showErrorAlert({ title: "Error", description: "Invalid Number" })
      );
      return;
    }

    // Creating or collecting payload data to be sent
    const payload = {
      unit_readiness_gap_bet_movein_value: durationCount,
      unit_readiness_gap_bet_movein_duration: selectedDuration.type,
      property_id,
    };
    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      const result = await dispatch(updatePropertySettings(payload));
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          setSettingsChanged(false);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    let selDura = dayMeasurement.find(
      (d) => d.type === settings.unit_readiness_gap_bet_movein_duration
    );
    setSelectedDuration(selDura ? selDura : dayMeasurement[0]);
    setDurationCount(
      settings?.unit_readiness_gap_bet_movein_value
        ? settings.unit_readiness_gap_bet_movein_value.toString()
        : "3"
    );
  }, [settings]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="tenant-id-count option">
      {/* Label */}
      <div className="label">
        <p className="font_m fw_5">Unit Rediness gap before Move in Date</p>
      </div>

      {/* Setting */}
      <div className="setting">
        <div className="row1">
          {" "}
          <div className="duration">
            <CustomNumberInput
              variant="2"
              value={durationCount}
              setValue={(v) => {
                setSettingsChanged(true);
                setDurationCount(v);
              }}
              inputClass="setting-input font_xs sm text-center"
            />
            <CustomSelectInput
              inputClass="setting-select font_xs text-center"
              variant="2"
              valueExtractor={(item) => item?.name}
              optionValueExtractor={(item) => item?.type}
              options={dayMeasurement}
              formikState={false}
              emptyOption={false}
              value={selectedDuration}
              setValue={setSelectedDuration}
              customOnchange={() => {
                setSettingsChanged(true);
              }}
            />
          </div>
        </div>

        <div className="row2"></div>

        {/* Save Button */}
        {settingsChanged && (
          <div className="save-btn-wrapper mt_2">
            <CustomButton onClick={onUpdate} className="save-btn" size="s">
              Save
            </CustomButton>
          </div>
        )}
      </div>
    </div>
  );
};
/* Component ends */

export default UnitReadinessGap;
