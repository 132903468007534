/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";
/* Import configuration ends */

/* Import redux slices component starts */

/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Table } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { EyeFilledBlue } from "../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import GLOBAL from "../../../setup/constants/global";
import IconButton from "../../../components/ui/button/IconButton/IconButton";
import CheckboxMultiSelectDropdown from "../../../components/ui/input/CheckboxMultiSelectDropdown/CheckboxMultiSelectDropdown";
/* Import local pages and component ends */

/* Component starts */
const SparePartsTable = (props) => {
  /* Props destructuring starts */
  const {
    sparePartsListData,
    multiSelectCommunity,
    setMultiSelectCommunity,
    communityData,
    propertyData,
    multiSelectProperty,
    setMultiSelectProperty,
    typesData,
    multiSelectType,
    setMultiSelectType,
    statusData1,
    multiSelectStatus,
    setMultiSelectStatus,

    processing,
    multiSelectProcessing,
    setMultiSelectProcessing,
    quote,
    multiSelectQuote,
    setMultiSelectQuote,
    items,
    multiSelectItems,
    setMultiSelectItems,
    amount,
    multiSelectAmount,
    setMultiSelectAmount,
    approver,
    multiSelectApprover,
    setMultiSelectApprover,
  } = props;

  /* Props destructuring ends */

  /* Component states and useRef declaration starts */

  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  const { pathname } = useLocation();
  //   const dispatch = useDispatch();

  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  // To View The Item Report
  const handleViewReport = async (item) => {
    if (item?.type === "Internal Maintenance") {
      if (item?.community_id) {
        navigate(
          `/communities/${item?.community_id}/properties/${item?.property_id}/internal/maintenances/spareParts/${item?.main_id}`,
          { state: { pathname: pathname } }
        );
      } else {
        navigate(
          `/independent/properties/${item?.property_id}/internal/maintenances/spareParts/${item?.main_id}`,
          { state: { pathname: pathname } }
        );
      }
    } else {
      if (item?.community_id) {
        navigate(
          `/communities/${item?.community_id}/properties/${item?.property_id}/maintenances/Spare%20Parts/${item?.main_id}`,
          { state: { pathname: pathname } }
        );
      } else {
        navigate(
          `/independent/properties/${item?.property_id}/maintenances/Spare%20Parts/${item?.main_id}`,
          { state: { pathname: pathname } }
        );
      }
    }
  };

  /* Component function definition ends */

  /* Component useEffect starts */

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */

  return (
    <div className="custom-table-wrapper spare-parts-table">
      {sparePartsListData?.length > 0 && (
        <Table className="custom-table font_s" borderless responsive>
          {/* Table Heading */}
          <thead>
            <tr>
              <th>
                <CheckboxMultiSelectDropdown
                  multiSelectData={multiSelectCommunity}
                  setMultiSelectData={setMultiSelectCommunity}
                  optionLabel={communityData}
                  label={"Community"}
                />
              </th>
              <th>
                <CheckboxMultiSelectDropdown
                  multiSelectData={multiSelectProperty}
                  setMultiSelectData={setMultiSelectProperty}
                  optionLabel={propertyData}
                  label={"Property"}
                />
              </th>
              <th>Ticket</th>
              <th>Date</th>
              <th>
                <CheckboxMultiSelectDropdown
                  multiSelectData={multiSelectType}
                  setMultiSelectData={setMultiSelectType}
                  optionLabel={typesData}
                  label={"Maint. Category"}
                />
              </th>
              <th>
                <CheckboxMultiSelectDropdown
                  multiSelectData={multiSelectQuote}
                  setMultiSelectData={setMultiSelectQuote}
                  optionLabel={quote}
                  label={"Quote"}
                />
              </th>
              <th>
                <CheckboxMultiSelectDropdown
                  multiSelectData={multiSelectItems}
                  setMultiSelectData={setMultiSelectItems}
                  optionLabel={items}
                  label={"Items"}
                />
              </th>
              <th>
                <CheckboxMultiSelectDropdown
                  multiSelectData={multiSelectAmount}
                  setMultiSelectData={setMultiSelectAmount}
                  optionLabel={amount}
                  label={"Amount"}
                />
              </th>
              <th>
                <CheckboxMultiSelectDropdown
                  multiSelectData={multiSelectProcessing}
                  setMultiSelectData={setMultiSelectProcessing}
                  optionLabel={processing}
                  label={"Processing"}
                />
              </th>
              <th>
                <CheckboxMultiSelectDropdown
                  multiSelectData={multiSelectApprover}
                  setMultiSelectData={setMultiSelectApprover}
                  optionLabel={approver}
                  label={"Approver"}
                />
              </th>
              <th>
                <CheckboxMultiSelectDropdown
                  multiSelectData={multiSelectStatus}
                  setMultiSelectData={setMultiSelectStatus}
                  optionLabel={statusData1}
                  label={"Status"}
                />
              </th>
              <th className="text-end">Action</th>
            </tr>
          </thead>
          {/* Table Body */}
          {sparePartsListData?.length > 0 && (
            <tbody>
              {sparePartsListData?.map((item, index) => (
                <tr key={index}>
                  {/* Community */}
                  <td>
                    {item?.community_name !== undefined
                      ? item?.community_name
                      : "-"}
                  </td>

                  {/* Property */}
                  <td>
                    {item?.property_name !== undefined
                      ? item?.property_name
                      : "-"}
                  </td>

                  {/* Ticket */}
                  <td>{item?.ticket}</td>

                  {/* Date */}
                  <td>{moment(item?.date).format(DATE_FORMAT)}</td>

                  {/* type */}
                  <td>{item?.type !== undefined ? item?.type : "-"}</td>

                  {/* QUOTES */}
                  <td>{item?.quote}</td>

                  {/* Items */}
                  <td>{item?.items}</td>

                  {/* Amount */}
                  <td>{item?.amount?.toLocaleString("en-US")}</td>

                  {/* PROCESSING */}
                  <td>
                    {item?.processing !== undefined ? item?.processing : "-"}
                  </td>

                  {/* Approver */}
                  <td>{item?.approver !== undefined ? item?.approver : "-"}</td>

                  {/* Status */}
                  <td
                    className={
                      item?.status === "Approved"
                        ? "font_green"
                        : item?.status === "Rejected"
                        ? "font_red"
                        : "font_yellow"
                    }
                  >
                    {item?.status}
                  </td>

                  {/* Actions */}
                  <td className="text-start txt-nowrap">
                    <IconButton
                      onClick={() => handleViewReport(item)}
                      className="action-button"
                    >
                      <img
                        src={EyeFilledBlue}
                        className="h_100"
                        alt="View Details"
                      />
                    </IconButton>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </Table>
      )}

      {sparePartsListData?.length === 0 && (
        <div className=" text-center">
          <h3 className=" font_m font_yellow fw_6">No Data</h3>
        </div>
      )}
    </div>
  );
};
/* Component ends */
export default SparePartsTable;
