/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useDispatch, useSelector } from "react-redux";
import GLOBAL from "../../../../../setup/constants/global";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../../../setup/store/slices/globalAlertSlice";
import { initiateMoveOut } from "../../../../../setup/store/slices/moveOutSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomButton from "../../../../ui/button/CustomButton/CustomButton";
import ConfirmationModal from "../../../../ui/modal/ConfirmationModal/ConfirmationModal";
/* Import local pages and component ends */

/* Component starts */
const CancelRenewal = (props) => {
  /* Props destructuring starts */
  const { contract_id, unit_id, fetchContractDetails } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const startMoveOut = async () => {
    setShowConfirmationModal(false);
    // Creating or collecting payload data to be sent
    const payload = { contract_id, unit_id, from: "contract_detail" };

    // Backend response. Try, Catch
    try {
      const result = await dispatch(initiateMoveOut(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description:
                typeof result?.payload === "string" ? result?.payload : "",
            })
          );
          break;

        case GLOBAL.FULFILLED:
          fetchContractDetails();
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description:
                typeof result?.payload?.message === "string"
                  ? result?.payload?.message
                  : "",
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="cancel_renewal_wrapper">
      <CustomButton
        variant="red"
        size="l"
        onClick={() => setShowConfirmationModal(true)}
      >
        Cancel Renewal Process
      </CustomButton>

      <ConfirmationModal
        showModal={showConfirmationModal}
        title="Cancel Renewal"
        description="Are you sure about cancelling this renewal process and initiate move out ? Please keep in mind this is an irreversible action."
        onClose={() => setShowConfirmationModal(false)}
        onConfirm={startMoveOut}
      />
    </div>
  );
};
/* Component ends */

export default CancelRenewal;
