/**
 *
 */

import React, { useState, useEffect, useRef } from "react";

/* Import configuration starts */
import { Formik } from "formik";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import { initialValues, validations } from "./helper";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  createPartyInvoice,
  fetchQuotesListForPartyInvoices,
  getQuotesList,
} from "../../../../../../setup/store/slices/partyInvoiceSlice";
import {
  fetchPropertyDetailByID,
  getPropertyDetail,
} from "../../../../../../setup/store/slices/propertySlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import { partyInvoiceValidations } from "../helper";
import GLOBAL from "../../../../../../setup/constants/global";
import CustomDateInput from "../../../../../ui/input/CustomDateInput/CustomDateInput";
import CustomButton from "../../../../../ui/button/CustomButton/CustomButton";
import TextButton from "../../../../../ui/button/TextButton/TextButton";
import CustomTextArea from "../../../../../ui/input/CustomTextArea/CustomTextArea";
import CustomSelectInput from "../../../../../ui/input/CustomSelectInput/CustomSelectInput";
import CustomRadioInput from "../../../../../ui/input/CustomRadioInput/CustomRadioInput";
import SparePartAccordion from "../../../../../ui/other/SparePartAccordion/SparePartAccordion";
import ErrorModal from "../../../../../ui/modal/ErrorModal/ErrorModal";
import SuccessModal from "../../../../../ui/modal/SuccessModal/SuccessModal";
import CustomCurrencyInput from "../../../../../ui/input/CustomCurrencyInput/CustomCurrencyInput";

/* Import local pages and component ends */

/* Component starts */
const CreatePartyInvoice = (props) => {
  /* Props destructuring starts */

  const { onSuccess, onCancel, type } = props;

  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [dueDate, setDueDate] = useState(new Date());
  const [installmentDate, setInstallmentDate] = useState(new Date());
  const [serviceType, setServiceType] = useState("Contracts");
  const [selectedService, setSelectedService] = useState();
  const [spareQuotes, setSpareQuotes] = useState();
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [modalDesc, setModalDesc] = useState("");

  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const { propertyId } = useParams();
  const dispatch = useDispatch();
  const quotesList = useSelector(getQuotesList);
  const propertyDetail = useSelector(getPropertyDetail);

  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */

  const serviceList = [
    { name: "Electricity" },
    { name: "Sewerage" },
    { name: "Water" },
    { name: "GAS" },
    { name: "District Cooling" },
    { name: "Telecommunication" },
  ];

  let initialFormValues = {
    // invoice_num: "",
    description: "",
    total: "",
  };

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  const onSubmit = async (values) => {
    let payload = {};

    if (serviceType === "Contracts") {
      payload = {
        property_id: propertyId,
        type: "Service contract",
        upcoming_installment: installmentDate,
        total: values.total,
        description: values.description,
        due_date: dueDate,
      };
    } else {
      payload = {
        property_id: propertyId,
        type: "Additional work",
        upcoming_installment: installmentDate,
        service_name: selectedService?.name || "",
        quote_number: spareQuotes?.spare_part_quote_number,
        request_number: spareQuotes?.spare_part_request_number,
        parts: spareQuotes?.part,
        total: spareQuotes?.total_cost,
        description: values.description,
        due_date: dueDate,
      };
    }

    // Backend response. Try, Catch
    try {
      const result = await dispatch(createPartyInvoice(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setModalDesc(result.payload);
          setShowErrorModal(true);
          break;

        case GLOBAL.FULFILLED:
          setModalDesc(result.payload.message);
          setShowSuccessModal(true);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    if (serviceType === "Spare-Parts") {
      dispatch(fetchQuotesListForPartyInvoices(propertyId));
    }
  }, [serviceType]);

  // Setting selected city after loading cities
  useEffect(() => {
    if (quotesList?.length > 0) {
      let defaultQuote = quotesList[0];

      setSpareQuotes(defaultQuote || quotesList[0]);
    }
  }, [quotesList]);

  useEffect(() => {
    if (propertyId) {
      dispatch(fetchPropertyDetailByID(propertyId));
    }
  }, [propertyId]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */

  return (
    <div className="add-party-invoice-module-wrapper">
      <h2 className="heading text-center font_xxl mb-5">
        Create {type} Invoice
      </h2>

      <div className="options-wrapper">
        <CustomRadioInput
          label="Service Contract"
          onSelecting={() => {
            setServiceType("Contracts");
          }}
          isSelected={serviceType === "Contracts"}
        />
        <CustomRadioInput
          label="Spare Parts / Additional Work"
          onSelecting={() => {
            setServiceType("Spare-Parts");
          }}
          isSelected={serviceType === "Spare-Parts"}
        />
      </div>

      <div className="add-new-form">
        <Formik
          onSubmit={onSubmit}
          initialValues={initialFormValues}
          validate={(values) => partyInvoiceValidations(values, serviceType)}
          validateOnBlur={true}
          enableReinitialize={true}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="form-wrapper">
                {/* Creating Invoice */}
                <>
                  {serviceType === "Contracts" && (
                    <>
                      {/* <div className="form-group">
                        <CustomTextInput
                          name="invoice_num"
                          label="Invoice Number"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          touched={touched.invoice_num}
                          errors={errors.invoice_num}
                          value={values.invoice_num}
                          required={true}
                        />
                      </div> */}

                      {/* Input for Total */}
                      <div className="form-group">
                        <CustomCurrencyInput
                          name="total"
                          label={`Total (${propertyDetail?.currency})`}
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldTouched}
                          touched={touched.total}
                          errors={errors.total}
                          value={values.total}
                          currency={propertyDetail?.currency}
                          required={true}
                        />
                      </div>

                      {/* Text Area for Description */}
                      <div className="form-group form-group-full">
                        <CustomTextArea
                          name="description"
                          label="Description"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          value={values.description}
                          errors={errors.description}
                          touched={touched.description}
                          required={true}
                        />
                      </div>

                      {/* Input for Upcoming Installment Date */}
                      <div className="form-group">
                        <CustomDateInput
                          label="Upcoming Installment"
                          value={installmentDate}
                          minDate={false}
                          customOnchange={(date) => setInstallmentDate(date)}
                          required={true}
                        />
                      </div>

                      {/* Input for Due Date */}
                      <div className="form-group">
                        <CustomDateInput
                          label="Due Date"
                          value={dueDate}
                          minDate={false}
                          customOnchange={(date) => setDueDate(date)}
                          required={true}
                        />
                      </div>
                    </>
                  )}

                  {serviceType === "Spare-Parts" && (
                    <>
                      {/* <div className="form-group">
                        <CustomTextInput
                          name="invoice_num"
                          label="Invoice Number"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          touched={touched.invoice_num}
                          errors={errors.invoice_num}
                          value={values.invoice_num}
                          required={true}
                        />
                      </div> */}

                      <div className="form-group">
                        <CustomSelectInput
                          name="service_name"
                          label="Service Name"
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          valueExtractor={(item) => item?.name}
                          setValue={setSelectedService}
                          options={serviceList}
                          value={selectedService?.name}
                          touched={touched.service_name}
                          errors={errors.service_name}
                          emptyOption={false}
                        />
                      </div>

                      {/* Select Spare Parts Quote Number */}
                      <div className="form-group">
                        <CustomSelectInput
                          name="type"
                          label="Spare Parts Quote Number"
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          valueExtractor={(item) =>
                            item?.spare_part_quote_number
                          }
                          setValue={setSpareQuotes}
                          options={quotesList}
                          value={spareQuotes}
                          formikState={false}
                          emptyOption={false}
                        />
                      </div>

                      <br />

                      {spareQuotes && (
                        <div className="spareParts-wrapper">
                          <div className="sparePart-details ">
                            <div className="sparePart">
                              <p className="font_m fw_4 font_grey">
                                Spare Parts Request Number
                                <br />
                                <span className="font_s fw_3">
                                  {spareQuotes?.spare_part_request_number}
                                </span>
                              </p>

                              <p className="font_m fw_4 font_grey">
                                Spare Parts Quote Number
                                <br />
                                <span className="font_s fw_3">
                                  {spareQuotes?.spare_part_quote_number}
                                </span>
                              </p>
                            </div>

                            <hr />

                            <SparePartAccordion
                              spareQuotes={spareQuotes?.part}
                              currency={propertyDetail?.currency}
                            />
                            {spareQuotes?.part?.length > 1 && <hr />}
                            <hr />

                            <div className="sparePart">
                              <p className="font_m fw_4 font_grey">
                                Total ({propertyDetail?.currency})
                              </p>

                              <p className="font_l fw_5 font_blue">
                                {parseInt(
                                  spareQuotes?.total_cost
                                )?.toLocaleString(undefined, "en-US")}
                              </p>
                            </div>
                          </div>
                        </div>
                      )}

                      {/* Text Area for Description */}
                      <div className="form-group form-group-full">
                        <CustomTextArea
                          name="description"
                          label="Description"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          value={values.description}
                          errors={errors.description}
                          touched={touched.description}
                          required={true}
                        />
                      </div>

                      {/* Input for Upcoming Installment Date */}
                      <div className="form-group">
                        <CustomDateInput
                          label="Upcoming Installment"
                          value={installmentDate}
                          minDate={false}
                          customOnchange={(date) => setInstallmentDate(date)}
                          required={true}
                        />
                      </div>

                      {/* Input for Due Date */}
                      <div className="form-group">
                        <CustomDateInput
                          label="Due Date"
                          value={dueDate}
                          minDate={false}
                          customOnchange={(date) => setDueDate(date)}
                          required={true}
                        />
                      </div>
                    </>
                  )}
                </>
              </div>

              <div className="button-wrapper text-center">
                <>
                  <CustomButton type="submit" size="l">
                    Create
                  </CustomButton>
                  <TextButton
                    type="button"
                    onClick={onCancel}
                    className="font_m fw_6"
                  >
                    Cancel
                  </TextButton>
                </>
              </div>
            </form>
          )}
        </Formik>
      </div>

      {/* Success Modal */}
      <SuccessModal
        showModal={showSuccessModal}
        description={modalDesc}
        title="Successfully Created"
        onClose={() => {
          onSuccess();
          setShowSuccessModal(false);
        }}
      />

      {/* Error Modal */}
      <ErrorModal
        showModal={showErrorModal}
        description={modalDesc}
        title="Error"
        onClose={() => setShowErrorModal(false)}
      />
    </div>
  );
};
/* Component ends */

export default CreatePartyInvoice;
