/**
 * This component is a Modal that is shown on success
 */

import React from "react";

/* Import react bootstrap component starts */
import { Modal } from "react-bootstrap";
import { hardData } from "./helper";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { XRed, XWhite } from "../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../../ui/button/IconButton/IconButton";
/* Import local pages and component ends */

import "./style.scss";
import { getUserTheme } from "../../../../setup/store/slices/authSlice";
import { useSelector } from "react-redux";

/* Component starts */
const PrivacyPolicySignupModal = (props) => {
  /* Props destructuring starts */
  const { showModal, data = null, size = "lg", onClose } = props;
  /* Props destructuring ends */

  const userTheme = useSelector(getUserTheme);

  /* ************* Component rendering. JSX code ************* */
  return (
    <Modal
      size={size}
      centered
      show={showModal}
      className={`privacy-policy-modal-wrapper `}
    >
      <Modal.Body
        className={`${userTheme === "dark" ? "bg_darkTheme" : "bg_lightTheme"}`}
      >
        <IconButton className="" onClick={onClose}>
          <img src={XRed} className="close-icn" alt="Close" />
        </IconButton>
        <div className="modal-header-custom">
          <p className="text-center font_xxl font_blue">Privacy Policy</p>
        </div>
        <div className="modal-body-custom">
          {data && data?.length > 0 && data[0].main_sub_heading ? (
            <div
              className="main_sub_heading fw_6 font_m"
              dangerouslySetInnerHTML={{
                __html: data[0].main_sub_heading,
              }}
            />
          ) : (
            <div
              className="main_sub_heading fw_6 font_m"
              dangerouslySetInnerHTML={{
                __html: hardData[0].main_sub_heading,
              }}
            />
          )}

          {data && data?.length > 0 && data[0].main_sub_heading_two ? (
            <div
              className="main_sub_heading_two fw_4 font_s"
              dangerouslySetInnerHTML={{
                __html: data[0].main_sub_heading_two,
              }}
            />
          ) : (
            <div
              className="main_sub_heading_two fw_4 font_s"
              dangerouslySetInnerHTML={{
                __html: hardData[0].main_sub_heading_two,
              }}
            />
          )}
        </div>
        <div className="modal-footer-custom"></div>
      </Modal.Body>
    </Modal>
  );
};
/* Component ends */

export default PrivacyPolicySignupModal;
