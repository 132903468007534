export const typeData = ["Spare Parts"];

export const costCoveredByData = ["Tenant"];

export const fieldValue = {
  part_name_0: "",
  quantity_0: "",
  description_0: "",
};

// Validations
export const validation = (values, formCountIndex) => {
  const error = {};

  formCountIndex.forEach((itm, idx) => {
    // ********************** Generating Object Keys **********************
    const part_name = "part_name_" + itm;
    const description = "description_" + itm;
    const quantity = "quantity_" + itm;

    // ********************** Validations starts from here **********************
    /* Part Name Validation Starts */
    if (values[part_name] === "") {
      error[part_name] = "Part Name is required";
    }
    /* Part Name Validation End */

    /* Description Validation Starts */
    if (values[description] === "") {
      error[description] = "Description is required";
    }
    if (values[description] !== "" && values[description]?.length < 10) {
      error[description] = "Minimum 10 characters are required";
    }
    /* Description Validation End */

    /* Quantity Validation Starts */
    if (values[quantity] === "") {
      error[quantity] = "Quantity is required";
    }
    /* Quantity Validation End */
  });

  return error;
};

// Adding Document field
export const addPartDetail = (
  formRef,
  setFormValue,
  partDetails,
  setPartDetails
) => {
  const length = partDetails.length;
  const currentFormState = { ...formRef.current.values };

  setFormValue(() => {
    // Generating new field name according to array indexes
    currentFormState[`part_name_${length}`] = "";
    currentFormState[`quantity_${length}`] = "";
    currentFormState[`description_${length}`] = "";

    return currentFormState;
  });
  setPartDetails((p) => [...p, length]);
};

// Remove Document field
export const removePartDetail = (
  formRef,
  setFormValue,
  partDetails,
  setPartDetails
) => {
  const lastIdx = partDetails.length - 1;
  const currentFormState = { ...formRef.current.values };

  setFormValue(() => {
    delete currentFormState[`part_name_${lastIdx}`];
    delete currentFormState[`quantity_${lastIdx}`];
    delete currentFormState[`description_${lastIdx}`];

    return currentFormState;
  });

  setPartDetails((prevState) => {
    const prevFields = [...prevState];
    prevFields.pop();
    return prevFields;
  });
};

// Form prefill when reviewed by FM
export const prefill = (data, setFormValue, setPartDetails, setTotalPrice) => {
  const prefillContainer = {};
  const formCount = [];

  prefillContainer[`spare_part_quote_number`] = data.spare_part_quote_number;
  prefillContainer[`spare_part_request_number`] =
    data.spare_part_request_number;
  prefillContainer[`total_cost`] = data.total_cost;
  prefillContainer[`notes`] = data.notes;

  data?.part?.forEach((item, index) => {
    prefillContainer[`company_name_${index}`] = item.company_name;
    prefillContainer[`cost_covered_by_${index}`] = item.cost_covered_by;
    prefillContainer[`description_${index}`] = item.description;
    prefillContainer[`estimated_delivery_time_${index}`] = new Date(
      item.estimated_delivery_time
    );
    prefillContainer[`part_name_${index}`] = item.part_name;
    prefillContainer[`part_number_${index}`] = item.part_number;
    prefillContainer[`price_${index}`] = item.price;
    prefillContainer[`quantity_${index}`] = item.quantity;
    prefillContainer[`type_${index}`] = item.type;
    prefillContainer[`vendor_${index}`] = item.vendor;
    prefillContainer[`warrenty_${index}`] = item.warrenty;

    formCount.push(index);
  });

  setTotalPrice(data?.total_cost);
  setFormValue(prefillContainer);
  setPartDetails(formCount);
};
