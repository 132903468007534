/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
/* Import configurati0on ends */

/* Import redux slices component starts */
import {
  fetchClearenceRequirement,
  getClearenceDocument,
} from "../../setup/store/slices/moveOutSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import MoveOutPendingClearence from "../../components/module/TenantMoveOut/MoveOutPendingClearence/MoveOutPendingClearence";
/* Import local pages and component ends */

/* Component starts */
const TenantMoveOutClearencePage = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const { moveoutID, unitId } = useParams();
  const dispatch = useDispatch();
  const clearencedoc = useSelector(getClearenceDocument);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    dispatch(
      fetchClearenceRequirement({ unit_id: unitId, move_out_id: moveoutID })
    );
  }, []);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="">
      <MoveOutPendingClearence
        moveoutID={moveoutID}
        unitId={unitId}
        clearencedoc={clearencedoc}
      />
    </div>
  );
};
/* Component ends */

export default TenantMoveOutClearencePage;
