export const defaultFormValue = {
  description_0: "",
};

export const formValidation = (values, desc) => {
  const error = {};

  desc.forEach((item, index) => {
    if (values[`description_${item}`] === "") {
      error[`description_${item}`] = "Description required";
    }
  });

  return error;
};

export const addDesc = (formRef, desc, setInitialFormValues, setDesc) => {
  const length = desc.length;
  const currentFormState = { ...formRef.current.values };
  setInitialFormValues(() => {
    // Generating new field name according to array indexes
    currentFormState[`description_${length}`] = "";
    return currentFormState;
  });
  setDesc((p) => [...p, length]);
};

export const removeDesc = (formRef, desc, setInitialFormValues, setDesc) => {
  const lastIdx = desc.length - 1;
  const currentFormState = { ...formRef.current.values };

  setInitialFormValues(() => {
    delete currentFormState[`description_${lastIdx}`];
    return currentFormState;
  });

  setDesc((prevState) => {
    const prevFields = [...prevState];
    prevFields.pop();
    return prevFields;
  });
};
