/**
 * This component is a Modal that is shown on success
 */

import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
/* Import react bootstrap component starts */
import { Modal } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */
import { getUserTheme } from "../../../../setup/store/slices/authSlice";
/* Import local pages and component starts */
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
import TextButton from "../../../ui/button/TextButton/TextButton";
import CustomRadioInput from "../../../ui/input/CustomRadioInput/CustomRadioInput";
import CustomTextArea from "../../../ui/input/CustomTextArea/CustomTextArea";
import CustomTextInput from "../../../ui/input/CustomTextInput/CustomTextInput";
import CustomCurrencyInput from "../../../ui/input/CustomCurrencyInput/CustomCurrencyInput";
import CustomDateInput from "../../../ui/input/CustomDateInput/CustomDateInput";
import CustomFileInput from "../../../ui/input/CustomFileInput/CustomFileInput";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const ChecqueStatusChangeModal = (props) => {
  /* Props destructuring starts */
  const {
    showModal,
    size = "lg",
    onClose,
    onSubmit,
    detail,
    showError = false,
  } = props;
  /* Props destructuring ends */

  /* Component state definition starts */
  const [selectedOption, setSelectedOption] = useState("cleared");
  const [error, setError] = useState();
  const [recpdate, setRecpdate] = useState(new Date());
  /* Component state definition ends */

  const receipt = useRef(null);
  const userTheme = useSelector(getUserTheme);

  // Initial Values
  const initialFormValues = {
    cheque_amt: "",
    cheque_num: "",
    issuer_name: "",
    account_number: "",
    bank_name: "",
    note: "",
  };

  /* Component function definition starts */
  // Validating not text
  // const validation = (values) => {
  //   setError("");
  //   if (note === "") {
  //     setError("Please type reason for rejection");
  //     return true;
  //   }
  //   if (note !== "" && note.length < 10) {
  //     setError("Minimum ten characters are required");
  //     return true;
  //   }

  //   if (values.cheque_amt === "") {
  //     error.cheque_amt = "Amount is required";
  //   }
  //   if (values.cheque_amt !== "") {
  //     if (parseFloat(values.cheque_amt) < detail?.pending_amount) {
  //       error.cheque_amt = "Invalid amount";
  //     }
  //     if (
  //       values.cheque_amt >
  //       (detail?.pending_amount !== undefined
  //         ? detail?.pending_amount
  //         : detail?.total_amount)
  //     ) {
  //       error.cheque_amt = "Amount is more than the pending amount";
  //     }
  //   }
  //   if (values.cheque_num === "") {
  //     error.cheque_num = "Cheque Number is required";
  //   }
  //   if (values.issuer_name === "") {
  //     error.issuer_name = "Issuer is required";
  //   }
  //   if (values.account_number === "") {
  //     error.account_number = "Account Number is required";
  //   }
  //   if (values.bank_name === "") {
  //     error.bank_name = "Bank Name is required";
  //   }
  //   if (receipt.current === null) {
  //     error.receipt = "Upload Cheque is required";
  //   }

  //   return false;
  // };

  // Handle Form Validations
  const formValidation = (values) => {
    const error = {};

    if (selectedOption === "replace") {
      if (values.note === "") {
        error.note = "Remarks is required";
      }

      if (values.cheque_amt === "") {
        error.cheque_amt = "Amount is required";
      }
      if (values.cheque_amt !== "") {
        if (parseFloat(values.cheque_amt) < detail?.pending_amount) {
          error.cheque_amt = "Invalid amount";
        }
        if (
          values.cheque_amt >
          (detail?.pending_amount !== undefined
            ? detail?.pending_amount
            : detail?.total_amount)
        ) {
          error.cheque_amt = "Amount is more than the pending amount";
        }
      }
      if (values.cheque_num === "") {
        error.cheque_num = "Cheque Number is required";
      }
      if (values.issuer_name === "") {
        error.issuer_name = "Issuer is required";
      }
      if (values.account_number === "") {
        error.account_number = "Account Number is required";
      }
      if (values.bank_name === "") {
        error.bank_name = "Bank Name is required";
      }
      if (receipt.current === null) {
        error.receipt = "Upload Cheque is required";
      }
    } else if (selectedOption === "bounced") {
      if (values.note === "") {
        error.note = "Remarks is required";
      }
    }

    return error;
  };

  const submitHandler = async (values) => {
    const { note } = values;

    if (selectedOption === "replace") {
      await onSubmit({
        ...values,
        recpdate,
        receipt,
        selectedOption,
      });
      onClose();
    } else if (selectedOption === "bounced") {
      await onSubmit(selectedOption, note);
      onClose();
    } else {
      await onSubmit(selectedOption);
      onClose();
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <Modal
      size={size}
      centered
      show={showModal}
      className="cheque-status-change-modal-wrapper"
    >
      <Modal.Body
        className={`${userTheme === "dark" ? "bg_darkTheme" : "bg_lightTheme"}`}
      >
        <div className="modal-header-custom">
          <h3
            className={`text-center ${
              userTheme !== "dark" && "font_blue"
            } font_white font_xl fw_6`}
          >
            Change Cheque Status
          </h3>
        </div>

        <div className="modal-body-custom">
          <Formik
            onSubmit={submitHandler}
            initialValues={initialFormValues}
            validate={(values) => formValidation(values)}
            validateOnBlur={true}
            enableReinitialize={true}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              setFieldTouched,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="form-wrapper form-wrapper-flex">
                  <div className="form-group-full mt-2">
                    <div className="option form-group">
                      <CustomRadioInput
                        isSelected={selectedOption === "cleared"}
                        onSelecting={() => setSelectedOption("cleared")}
                      />
                      <h3
                        //className="font_s "
                        className={`font_s ${
                          userTheme === "light" && "font_blue"
                        }`}
                      >
                        Cleared
                      </h3>
                    </div>
                    <div className="option form-group">
                      <CustomRadioInput
                        isSelected={selectedOption === "bounced"}
                        onSelecting={() => setSelectedOption("bounced")}
                      />
                      <h3
                        //className="font_s font_blue"
                        className={`font_s ${
                          userTheme === "light" && "font_blue"
                        }`}
                      >
                        Bounced
                      </h3>
                    </div>
                  </div>

                  <div className="form-group-full mt-2">
                    <div className="option form-group">
                      <CustomRadioInput
                        isSelected={selectedOption === "replace"}
                        onSelecting={() => setSelectedOption("replace")}
                      />
                      <h3
                        //className="font_s font_blue"
                        className={`font_s ${
                          userTheme === "light" && "font_blue"
                        }`}
                      >
                        Replace
                      </h3>
                    </div>
                  </div>
                </div>

                {selectedOption === "bounced" && (
                  <div className="note-wrapper">
                    <CustomTextArea
                      name="note"
                      label="Cheque Reason"
                      className="note"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      value={values.note}
                      errors={errors.note}
                      touched={touched.note}
                      required={true}
                    />
                  </div>
                )}

                {selectedOption === "replace" && (
                  <div className="form-wrapper form-wrapper-flex">
                    <p className="font_l fw_4 font_yellow">
                      {" "}
                      Cheque Amount :{" "}
                      <span className="font_l fw_6 font_blue">
                        {detail?.pending_amount && detail?.pending_amount !== 0
                          ? detail?.pending_amount?.toLocaleString("en-US")
                          : detail?.total_amount?.toLocaleString("en-US")}{" "}
                        {detail?.currency}
                      </span>
                    </p>
                    <div className="note-wrapper">
                      <CustomTextArea
                        name="note"
                        label="Cheque Reason"
                        className="note"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        value={values.note}
                        errors={errors.note}
                        touched={touched.note}
                        required={true}
                      />

                      {error !== "" && (
                        <p className="font_xs error font_red fw_5">{error}</p>
                      )}
                    </div>

                    <div className="form-group mt-2">
                      <CustomTextInput
                        name="cheque_num"
                        label="Cheque Number"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        touched={touched.cheque_num}
                        errors={errors.cheque_num}
                        value={values.cheque_num}
                        required={true}
                      />
                    </div>

                    <div className="form-group">
                      <CustomCurrencyInput
                        name="cheque_amt"
                        label="Cheque Amount"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        touched={touched.cheque_amt}
                        errors={errors.cheque_amt}
                        value={values.cheque_amt}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        currency={detail?.currency}
                        required={true}
                      />
                    </div>

                    <div
                      className={`form-group ${
                        (userTheme === "light" || userTheme === "automatic") &&
                        "otherTheme"
                      } `}
                    >
                      <CustomDateInput
                        label="Cheque Date"
                        value={recpdate}
                        minDate={false}
                        customOnchange={(date) => setRecpdate(date)}
                        required={true}
                      />
                    </div>
                    <div className="form-group">
                      <CustomTextInput
                        name="bank_name"
                        label="Bank Name"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        touched={touched.bank_name}
                        errors={errors.bank_name}
                        value={values.bank_name}
                        required={true}
                      />
                    </div>

                    <div className="form-group">
                      <CustomTextInput
                        name="issuer_name"
                        label="Issuer Name"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        touched={touched.issuer_name}
                        errors={errors.issuer_name}
                        value={values.issuer_name}
                        required={true}
                      />
                    </div>

                    <div className="form-group">
                      <CustomTextInput
                        name="account_number"
                        label="Account Number"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        touched={touched.account_number}
                        errors={errors.account_number}
                        value={values.account_number}
                        required={true}
                      />
                    </div>

                    <div className="form-group mt-2 note-wrapper">
                      <CustomFileInput
                        label="Upload Cheque"
                        name="cheque"
                        files={receipt}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        errors={errors.cheque}
                        touched={touched.cheque}
                        values={values.cheque}
                        required={true}
                        formikState={false}
                      />
                    </div>
                    {/* </> */}
                  </div>
                )}

                <div className="modal-footer-custom mt-2">
                  <CustomButton size="l" variant="primary" type="submit">
                    Submit
                  </CustomButton>

                  <CustomButton onClick={onClose} size="l" variant="red">
                    Cancel
                  </CustomButton>
                </div>
              </form>
            )}
          </Formik>
        </div>

        {/* <div className="modal-body">
          <div className="option">
            <CustomRadioInput
              isSelected={selectedOption === "cleared"}
              onSelecting={() => setSelectedOption("cleared")}
            />
            <h3
              //className="font_s "
              className={`font_s ${userTheme === "light" && "font_blue"}`}
            >
              Cleared
            </h3>
          </div>
          <div className="option">
            <CustomRadioInput
              isSelected={selectedOption === "bounced"}
              onSelecting={() => setSelectedOption("bounced")}
            />
            <h3
              //className="font_s font_blue"
              className={`font_s ${userTheme === "light" && "font_blue"}`}
            >
              Bounced
            </h3>
          </div>
          <div className="option">
            <CustomRadioInput
              isSelected={selectedOption === "replace"}
              onSelecting={() => setSelectedOption("replace")}
            />
            <h3
              //className="font_s font_blue"
              className={`font_s ${userTheme === "light" && "font_blue"}`}
            >
              Replace
            </h3>
          </div>

          {(selectedOption === "bounced" || selectedOption === "replace") && (
            <div className="modal-body-custom note-wrapper">
              <CustomTextArea
                label="Cheque Reason"
                className="note"
                value={note}
                setValue={setNote}
                required={true}
              />

              {error !== "" && (
                <p className="font_xs error font_red fw_5">{error}</p>
              )}
            </div>
          )}
        </div>
        <div className="modal-footer-custom">
          <CustomButton onClick={onClose} size="m" variant="red">
            Cancel
          </CustomButton>
          <CustomButton
            onClick={async () => {
              if (showError && validation()) return;
              setNote("");
              await onSubmit(selectedOption, note);
              onClose();
            }}
            size="m"
            variant="primary"
          >
            Submit
          </CustomButton>
        </div> */}
      </Modal.Body>
    </Modal>
  );
};
/* Component ends */

export default ChecqueStatusChangeModal;
