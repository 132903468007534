/**
 *
 */

import React, { useState, useEffect, useRef } from "react";

/* Import configuration starts */
import { Formik } from "formik";
import {
  initialValues,
  tenantTypes,
  validations,
  onSelectSearched,
  formReset,
  remove,
  addMore,
} from "./helper";
import { useDispatch, useSelector } from "react-redux";
import GLOBAL from "../../../../setup/constants/global";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import {
  customMoment,
  customDateFormat,
} from "../../../../setup/utils/global-helper";
/* Import configuration ends */

/* Import redux slices component starts */
import { inviteTenant } from "../../../../setup/store/slices/tenantSlice";
import {
  clearApplication,
  getTenantApplication,
  transitionedTenantInitialAgreement,
  getInstalments,
} from "../../../../setup/store/slices/tenantSlice";
import {
  showSuccessAlert,
  showErrorAlert,
} from "../../../../setup/store/slices/globalAlertSlice";
import {
  fetchTransitionedTenantInvoices,
  inviteTransitionedTenant,
} from "../../../../setup/store/slices/tenantSlice";
import {
  fetchSetting,
  getSetting,
} from "../../../../setup/store/slices/settingSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import SearchTenant from "../SearchTenant/SearchTenant";
import ErrorModal from "../../../ui/modal/ErrorModal/ErrorModal";
import SuccessModal from "../../../ui/modal/SuccessModal/SuccessModal";
import CustomSelectInput from "../../../ui/input/CustomSelectInput/CustomSelectInput";
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
import TextButton from "../../../ui/button/TextButton/TextButton";
import CustomRadioInput from "../../../ui/input/CustomRadioInput/CustomRadioInput";
import CustomTextInput from "../../../ui/input/CustomTextInput/CustomTextInput";
import CustomPhoneInput from "../../../ui/input/CustomPhoneInput/CustomPhoneInput";
import CustomDateInput from "../../../ui/input/CustomDateInput/CustomDateInput";
import CustomNumberInput from "../../../ui/input/CustomNumberInput/CustomNumberInput";
import CustomCurrencyInput from "../../../ui/input/CustomCurrencyInput/CustomCurrencyInput";
import CustomCheckInput from "../../../ui/input/CustomCheckInput/CustomCheckInput";
import CustomFileInput from "../../../ui/input/CustomFileInput/CustomFileInput";
import InstallmentList from "./sub-component/InstallmentList";
import InvoiceList from "./sub-component/InvoiceList";
/* Import local pages and component ends */

import "./style.scss";
import {
  fetchPropertyDetailByID,
  getPropertyDetail,
  getPropertySettings,
  fetchPropertySettings,
} from "../../../../setup/store/slices/propertySlice";

/* Component starts */
const InviteTenant = (props) => {
  /* Props destructuring starts */
  const {
    onSuccess,
    onCancel,
    unitList,
    setVacantUnit,
    vacantUnit,
    fromManageTeams,
    allowTransitionedTenant,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [existingTenant, setExistingTenant] = useState(false);
  const [message, setMessage] = useState("");
  const [showErrModal, setShowErrModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [initialFormValues, setInitialFormValues] = useState(initialValues);
  const [tenantType, setTenantType] = useState();
  const [deposit, setDeposit] = useState(false);
  const [commision, setCommision] = useState(false);
  const [cheque, setCheque] = useState(false);
  const [memberSelected, setMemberSelected] = useState(false);
  const [goBack, setGoBack] = useState(false);
  const [invoicesList, setInvoicesList] = useState([]);
  const [formRefValues, setFormRefValue] = useState({});
  const [applicationID, setApplicationID] = useState("");
  const [totalAmt, setTotalAmt] = useState({ errorAmt: false, amount: 0 });
  const [instalmentPlan, setInstalmentPlan] = useState();
  const [canOnboard, setCanOnboard] = useState(true);
  const [depositVat, setDepositVat] = useState(false);
  const [depositTax, setDepositTax] = useState(false);
  const [commissionVat, setCommissionVat] = useState(false);
  const [commissionTax, setCommissionTax] = useState(false);
  const [instalmentVat, setInstalmentVat] = useState(false);
  const [instalmentTax, setInstalmentTax] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [depositList, setDepositList] = useState([0]);
  const [dataChanged, setDataChanged] = useState(false);
  const [instalments, setInstalments] = useState([]);
  const [oldInstallmentStartDate, setOldInstallmentStartDate] = useState(
    new Date()
  );
  const [tenantTypeList, setTenantTypeList] = useState([]);

  // useRefs
  const customPhoneNum = useRef({ code: "", num: "" });
  const [formInputChanged, setFormInputChanged] = useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { unitId, propertyId } = useParams();
  const { state } = useLocation();
  // const application = useSelector(getTenantApplication);
  // const installments = useSelector(getInstalments);
  const propertyData = useSelector(getPropertyDetail);
  const settings = useSelector(getPropertySettings);
  const globalSettings = useSelector(getSetting);
  const formRef = useRef(null);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";

  /* Component variable declaration / object destructure ends */
  console.log(
    "formInputChanged, Form has been manipulated ==> ",
    formInputChanged
  );
  /* Component function definition starts */
  const checkLastApplication = (values) => {
    if (instalmentPlan) {
      // const startDate = moment(instalmentPlan?.start_date).format("MM-DD-YYYY");
      const startDate = moment(oldInstallmentStartDate).format("MM-DD-YYYY");
      const duration = instalmentPlan?.duration;
      const rentPrice = instalmentPlan?.total_rent;
      const numInstallment = instalmentPlan?.no_of_installments;
      const collectInstallmentTax = instalmentPlan?.collect_instalment_tax;
      const collectInstallmentVat = instalmentPlan?.collect_instalment_vat;
      const collectDeposit = instalmentPlan?.deposit;
      const depositAmt = instalmentPlan?.deposit_amount;
      const collectDepositTax = instalmentPlan?.collect_deposit_tax;
      const collectDepositVat = instalmentPlan?.collect_deposit_vat;
      const collectCommision = instalmentPlan?.commission;
      const commisionAmt = instalmentPlan?.commission_amount;
      const collectCommisionTax = instalmentPlan?.collect_commission_tax;
      const collectCommisionVat = instalmentPlan?.collect_commission_vat;
      const collectCheque = instalmentPlan?.cheque;

      if (
        values &&
        values?.start_date &&
        startDate !== moment(values?.start_date).format("MM-DD-YYYY")
      ) {
        setIsChanged(true);
        console.log("1");
        return;
      }
      if (
        values &&
        values?.duration &&
        parseInt(duration) !== parseInt(values?.duration)
      ) {
        setIsChanged(true);
        console.log("2");
        return;
      }
      if (
        values &&
        values?.contract_amount &&
        parseInt(rentPrice) !== parseInt(values?.contract_amount)
      ) {
        setIsChanged(true);
        console.log("3");
        return;
      }
      if (
        values &&
        values?.installments &&
        parseInt(numInstallment) !== parseInt(values?.installments)
      ) {
        setIsChanged(true);
        console.log("4");
        return;
      }
      if (collectInstallmentTax !== instalmentTax) {
        setIsChanged(true);
        console.log("5");
        return;
      }
      if (collectInstallmentVat !== instalmentVat) {
        setIsChanged(true);
        console.log("6");
        return;
      }
      if (collectDeposit !== deposit) {
        setIsChanged(true);
        console.log("7");
        return;
      }
      if (
        values &&
        values?.deposits &&
        parseInt(depositAmt) !== parseInt(values?.deposits)
      ) {
        setIsChanged(true);
        console.log("8");
        return;
      }
      if (collectDepositTax !== depositTax) {
        setIsChanged(true);
        console.log("9");
        return;
      }
      if (collectDepositVat !== depositVat) {
        setIsChanged(true);
        console.log("10");
        return;
      }
      if (collectCommision !== commision) {
        setIsChanged(true);
        console.log("11");
        return;
      }
      if (
        values &&
        values.commision &&
        parseInt(commisionAmt) !== parseInt(values.commision)
      ) {
        setIsChanged(true);
        console.log("12");
        return;
      }
      if (collectCommisionTax !== commissionTax) {
        setIsChanged(true);
        console.log("13");
        return;
      }
      if (collectCommisionVat !== commissionVat) {
        setIsChanged(true);
        console.log("14");
        return;
      }
      if (collectCheque !== cheque) {
        setIsChanged(true);
        console.log("15");
        return;
      }
      setIsChanged(false);
      return true;
      // if (
      // startDate !== moment(values?.start_date).format(GLOBAL.DATE_FORMAT) ||
      // parseInt(duration) !== parseInt(values?.duration) ||
      // parseInt(rentPrice) !== parseInt(values?.contract_amount) ||
      // parseInt(numInstallment) !== parseInt(values?.installments) ||
      // collectInstallmentTax !== instalmentTax ||
      // collectInstallmentVat !== instalmentVat ||
      // collectDeposit !== deposit ||
      // parseInt(depositAmt) !== parseInt(values?.deposits) ||
      // collectDepositTax !== depositTax ||
      // collectDepositVat !== depositVat ||
      // collectCommision !== commision ||
      // parseInt(commisionAmt) !== parseInt(values.commision) ||
      // ) {
      // }
    }
  };

  // Form Validations
  const formValidation = (values) => {
    checkLastApplication(values);
    return validations(
      values,
      customPhoneNum,
      tenantType,
      deposit,
      commision,
      depositList,
      globalSettings
    );
  };

  // On checking installment due is passed
  const getIsDuePassed = (dueDate) => {
    return moment(dueDate).isBefore(moment());
  };

  // On checking if cheques are attached
  const hasAccAttachedCheques = () => {
    if (instalmentPlan?.cheque) {
      for (let i = 0; i < instalments.length; i++) {
        if (
          !instalments[i].cheque &&
          !getIsDuePassed(instalments[i].due_date)
        ) {
          dispatch(
            showErrorAlert({
              title: "Cannot invite!",
              description: "Please attach cheques to upcoming invoices.",
            })
          );
          return false;
        }
      }
    }
    return true;
  };

  // Checking valid duration
  const acceptedDate = () => {
    if (instalmentPlan) {
      if (moment(instalmentPlan?.end_date).isAfter(new Date())) {
        return true;
      } else {
        dispatch(
          showErrorAlert({
            title: "Cannot Invite!",
            description: "End date should be in future.",
          })
        );
        return false;
      }
    }
  };

  // On inviting tenant
  const handleSubmit = async (values) => {
    if (totalAmt?.errorAmt && instalments.length > 0 && !formInputChanged) {
      return;
    }
    if (!formInputChanged && !hasAccAttachedCheques()) {
      return;
    }
    // Creating or collecting payload data tobe sent
    const payload = {
      first_name: values.first_name.trim(),
      last_name: values.last_name.trim(),
      email: values.email.toLowerCase().trim(),
      countrycode: customPhoneNum.current.code,
      mobile: customPhoneNum.current.num,
      tenant_type: tenantType.type,
      unit_id: fromManageTeams ? vacantUnit?._id : unitId,
      property_id: propertyId,
    };
    if (tenantType?.type === GLOBAL.TENANT_TYPE.TRANSITIONED) {
      const depositData = [];
      let depositAmount = 0;
      depositList?.forEach((id, index) => {
        depositData.push({
          description: values[`desciption_${id}`],
          deposit_amount: values[`deposit_amount_${id}`],
        });
        depositAmount += parseInt(values[`deposit_amount_${id}`]) || 0;
      });

      payload["contract_number"] = values.contract_number;
      payload["start_date"] = moment(values.start_date)?.format("MM-DD-YYYY");
      payload["duration"] = values.duration;
      payload["total_rent"] = values.contract_amount;
      payload["no_of_installments"] = values.installments;
      payload["contract_pdf"] = values.contract_pdf;
      payload["deposit"] = deposit;
      payload["commission"] = commision;
      payload["cheque"] = cheque;
      payload["deposit_amount"] = depositAmount;
      payload["deposit_data"] = JSON.stringify(depositData);
      payload["commission_amount"] = values.commision;
      payload["collect_deposit_vat"] = depositVat;
      payload["collect_deposit_tax"] = depositTax;
      payload["collect_commission_vat"] = commissionVat;
      payload["collect_commission_tax"] = commissionTax;
      payload["collect_instalment_vat"] = instalmentVat;
      payload["collect_instalment_tax"] = instalmentTax;
      if (applicationID) {
        payload["application_id"] = applicationID;
        payload["applicationId"] = applicationID;
      }
      if (!values.contract_pdf) delete payload["contract_pdf"];
      if (!commision) delete payload["commission_amount"];
      if (!deposit) {
        delete payload["deposit_amount"];
        delete payload["deposit_data"];
      }
    }
    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      let result = {};

      if (tenantType?.type === GLOBAL.TENANT_TYPE.TRANSITIONED) {
        if (!dataChanged) {
          if (instalments.length > 0 && !formInputChanged) {
            if (!acceptedDate()) {
              return;
            }
            console.log("Inviting Tenant");
            result = await dispatch(inviteTransitionedTenant(payload));
          } else if (instalments.length > 0 && formInputChanged) {
            console.log("Generating Installments Again");
            result = await dispatch(
              transitionedTenantInitialAgreement(payload)
            );
          } else {
            console.log("Generating Installments");
            result = await dispatch(
              transitionedTenantInitialAgreement(payload)
            );
          }
        }
      } else {
        result = await dispatch(inviteTenant(payload));
      }
      console.log("Response", result);
      setIsChanged(false);
      setFormInputChanged(false);
      // Handling success response
      switch (result?.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setMessage(result.payload);
          setShowErrModal(true);
          break;

        case GLOBAL.FULFILLED:
          if (tenantType?.type === GLOBAL.TENANT_TYPE.TRANSITIONED) {
            setOldInstallmentStartDate(new Date(values.start_date));
            if (!dataChanged) {
              if (instalments.length > 0 && !formInputChanged) {
                setGoBack(true);
                setMessage(
                  "Tenant invitation has been successfully sent to the tenant."
                );
              } else if (instalments.length > 0 && formInputChanged) {
                setApplicationID(result.payload.application_id);
                setInstalmentPlan(result.payload.installment_plan);
                setInstalments(result.payload.installments);
                setDataChanged(false);
                setMessage("Initital agreement successfully generated again");
                setIsChanged(false);
              } else {
                setApplicationID(result.payload.application_id);
                setInstalmentPlan(result.payload.installment_plan);
                setInstalments(result.payload.installments);
                setDataChanged(false);
                setMessage("Initital agreement successfully generated");
                setIsChanged(false);
              }
            }
          } else {
            setGoBack(true);
            setApplicationID("");
            setMessage(
              "Tenant onboarding invitation has been successfully sent to the tenant."
            );
          }
          setShowSuccessModal(true);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  // On load of all tenant types
  useEffect(() => {
    dispatch(clearApplication());
    dispatch(fetchPropertySettings({ property_id: propertyId }));
    if (tenantTypes.length > 0) {
      setTenantType(tenantTypes[0]);
    }
  }, []);

  useEffect(() => {
    dispatch(fetchPropertyDetailByID({ property_id: propertyId }));
  }, []);

  useEffect(() => {
    checkLastApplication(null);
  }, [
    deposit,
    commision,
    cheque,
    depositVat,
    depositTax,
    commissionVat,
    commissionTax,
    instalmentVat,
    instalmentTax,
  ]);

  useEffect(() => {
    if (tenantType?.type) {
      setCanOnboard(true);
      // setCanOnboard(
      //   (tenantType.type === GLOBAL.TENANT_TYPE.TRANSITIONED &&
      //     instalments.length > 0 &&
      //     instalments[0]?.tenant_billing_invoice) ||
      //     tenantType.type === GLOBAL.TENANT_TYPE.NEW
      // );
    }
  }, [tenantType, instalments]);

  useEffect(() => {
    if (allowTransitionedTenant) {
      setTenantTypeList([
        {
          name: "New",
          type: GLOBAL.TENANT_TYPE.NEW,
        },
        {
          name: "Transitioned",
          type: GLOBAL.TENANT_TYPE.TRANSITIONED,
        },
      ]);
    } else {
      setTenantTypeList([
        {
          name: "New",
          type: GLOBAL.TENANT_TYPE.NEW,
        },
      ]);
    }
  }, [allowTransitionedTenant]);

  useEffect(() => {}, [formInputChanged]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      <div className="invite-tenant-component-wrapper">
        <h2 className="heading text-center font_xxl mb-5">Invite Tenant</h2>

        {/* Invite Tenant Form */}
        <Formik
          initialValues={initialFormValues}
          validate={formValidation}
          onSubmit={handleSubmit}
          enableReinitialize={true}
          innerRef={
            (d) => {
              if (d) {
                formRef.current = d;
                setFormRefValue(d.values);
              } else {
                setFormRefValue({});
              }
            }
            // d ? setFormRefValue(d.values) : setFormRefValue({})
          }
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            isValid,
            setFieldTouched,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="form-wrapper container_sm">
                <div className="invitation-form">
                  {/* Radio select if there is existing tenant or not */}
                  <div className="form-group form-group-full w_100">
                    <p className="font_m font_grey w_100">
                      Adding existing tenant ?
                    </p>
                    <CustomRadioInput
                      label="Yes"
                      onSelecting={() => {
                        // setApplicationID("");
                        setDataChanged(false);
                        setExistingTenant(true);
                      }}
                      isSelected={existingTenant || memberSelected}
                    />
                    <CustomRadioInput
                      label="No"
                      onSelecting={() => {
                        setApplicationID("");
                        dispatch(clearApplication());
                        setDataChanged(false);
                        formReset(
                          setInitialFormValues,
                          setTenantType,
                          setDeposit,
                          setCheque,
                          customPhoneNum,
                          setExistingTenant,
                          setMemberSelected,
                          setCommision
                        );
                      }}
                      isSelected={!existingTenant && !memberSelected}
                    />
                  </div>

                  {/* Creating new tenant */}
                  {!existingTenant && (
                    <>
                      {/* Select for Tenant Type */}
                      {propertyData?.mgmt_model !== 1 && (
                        <div className="form-group form-group-full">
                          <CustomSelectInput
                            label="Tenant Type"
                            valueExtractor={(item) => item?.name}
                            options={tenantTypeList}
                            setValue={setTenantType}
                            value={tenantType}
                            formikState={false}
                            emptyOption={false}
                            customOnchange={() => setFormInputChanged(true)}
                          />
                        </div>
                      )}

                      {/* Select for Unit Type */}
                      {fromManageTeams && (
                        <div className="form-group form-group-full">
                          <CustomSelectInput
                            label="Select Vacant Unit"
                            valueExtractor={(item) => item?.unit_name}
                            options={unitList}
                            setValue={setVacantUnit}
                            value={vacantUnit}
                            formikState={false}
                            emptyOption={false}
                            customOnchange={() => setFormInputChanged(true)}
                          />
                        </div>
                      )}

                      {/* Input For First Name */}
                      <div className="form-group">
                        <CustomTextInput
                          name="first_name"
                          label="First Name"
                          value={values.first_name}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          touched={touched.first_name}
                          errors={errors.first_name}
                          readOnly={memberSelected}
                          customOnchange={() => setFormInputChanged(true)}
                        />
                      </div>

                      {/* Input For Last Name */}
                      <div className="form-group">
                        <CustomTextInput
                          name="last_name"
                          label="Last Name"
                          value={values.last_name}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          touched={touched.last_name}
                          errors={errors.last_name}
                          readOnly={memberSelected}
                          customOnchange={() => setFormInputChanged(true)}
                        />
                      </div>

                      {/* Input For Email */}
                      <div className="form-group">
                        <CustomTextInput
                          name="email"
                          label="Email"
                          value={values.email}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          touched={touched.email}
                          errors={errors.email}
                          readOnly={memberSelected}
                          customOnchange={() => setFormInputChanged(true)}
                        />
                      </div>

                      {/* Input For Phone */}
                      <div className="form-group">
                        <CustomPhoneInput
                          name="mobile"
                          label={"Phone Number"}
                          customPhoneNum={customPhoneNum}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          value={values.mobile}
                          countryCodeEditable={true}
                          errors={errors.mobile}
                          touched={touched.mobile}
                          readOnly={memberSelected}
                          customOnchange={() => setFormInputChanged(true)}
                        />
                      </div>

                      {tenantType?.type === GLOBAL.TENANT_TYPE.TRANSITIONED && (
                        <>
                          {/* Input for Contract Number */}
                          <div className="form-group">
                            <CustomTextInput
                              name="contract_number"
                              label="Contract Number"
                              value={values.contract_number}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              touched={touched.contract_number}
                              errors={errors.contract_number}
                              customOnchange={() => setFormInputChanged(true)}
                            />
                          </div>

                          {/* Input for Start Date */}
                          <div className="form-group">
                            <CustomDateInput
                              label={`Start Date`}
                              name={`start_date`}
                              value={values.start_date}
                              setFieldValue={setFieldValue}
                              setFieldTouched={setFieldTouched}
                              touched={touched.start_date}
                              errors={errors.start_date}
                              minDate={false}
                              maxDate={new Date()}
                              customOnchange={() => setFormInputChanged(true)}
                            />
                          </div>

                          {/* Input for Duration */}
                          <div className="form-group">
                            <CustomNumberInput
                              label="Duration (Months)"
                              name="duration"
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              value={values.duration}
                              touched={touched.duration}
                              errors={errors.duration}
                              customOnchange={() => setFormInputChanged(true)}
                            />
                          </div>

                          {/* Input for Contract Value */}
                          <div className="form-group">
                            <CustomCurrencyInput
                              name="contract_amount"
                              currency={propertyData?.currency}
                              label={`Total Rent Amount (${propertyData?.currency})`}
                              setFieldTouched={setFieldTouched}
                              setFieldValue={setFieldValue}
                              value={values.contract_amount}
                              touched={touched.contract_amount}
                              errors={errors.contract_amount}
                              customOnchange={() => setFormInputChanged(true)}
                            />
                            <p className="font_yellow fw_4 font_xs">
                              Target Rent :{" "}
                              <span className="font_yellow fw_4 font_xs">
                                {state?.targetPrice?.toLocaleString() ||
                                  vacantUnit?.target_rent_price?.toLocaleString()}{" "}
                                ({propertyData?.currency})
                              </span>
                            </p>
                          </div>

                          {/* Input for Number of Installments */}
                          <div className="form-group">
                            <CustomNumberInput
                              label="Number of Installments"
                              name="installments"
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              value={values.installments}
                              touched={touched.installments}
                              errors={errors.installments}
                              customOnchange={() => setFormInputChanged(true)}
                            />

                            {(settings?.collect_vat_enabled ||
                              settings?.collect_vat_enabled) && (
                              <>
                                {settings?.collect_tax_enabled && (
                                  <div className="check">
                                    <CustomCheckInput
                                      value={instalmentTax}
                                      setValue={setInstalmentTax}
                                      customOnChange={() => {
                                        setIsChanged(true);
                                        setFormInputChanged(true);
                                      }}
                                    />
                                    <span className="font_s check-text">
                                      Collect Tax ({settings?.collect_tax_value}
                                      %)
                                    </span>
                                  </div>
                                )}

                                {settings?.collect_vat_enabled && (
                                  <div className="check">
                                    <CustomCheckInput
                                      value={instalmentVat}
                                      setValue={setInstalmentVat}
                                      customOnChange={() => {
                                        setIsChanged(true);
                                        setFormInputChanged(true);
                                      }}
                                    />
                                    <span className="font_s check-text">
                                      Collect VAT ({settings?.collect_vat_value}
                                      %)
                                    </span>
                                  </div>
                                )}
                              </>
                            )}
                          </div>

                          {/* Input for Upload Copy Contract */}
                          <div className="form-group">
                            <CustomFileInput
                              label="Contract"
                              name="contract_pdf"
                              setFieldValue={setFieldValue}
                              setFieldTouched={setFieldTouched}
                              errors={errors.contract_pdf}
                              touched={touched.contract_pdf}
                              value={values.contract_pdf}
                              formikState={true}
                              customOnChange={() => setFormInputChanged(true)}
                            />
                          </div>

                          <div className="form-group-full check-box w_100 check-box-group-wrapper">
                            <div className="checkbox checkbox-control">
                              <CustomCheckInput
                                value={deposit}
                                setValue={setDeposit}
                                customOnChange={() => {
                                  setIsChanged(true);
                                  setFormInputChanged(true);
                                }}
                              />
                              <p className="font_s">Deposits</p>
                            </div>
                            <div className="checkbox checkbox-control">
                              <CustomCheckInput
                                value={commision}
                                setValue={setCommision}
                                customOnChange={() => {
                                  setIsChanged(true);
                                  setFormInputChanged(true);
                                }}
                              />
                              <p className="font_s">Commission</p>
                            </div>
                            <div className="checkbox checkbox-control">
                              <CustomCheckInput
                                value={cheque}
                                setValue={setCheque}
                                customOnChange={() => {
                                  setIsChanged(true);
                                  setFormInputChanged(true);
                                }}
                              />
                              <p className="font_s">
                                Recieve payment in cheque
                              </p>
                            </div>
                          </div>

                          {deposit && (
                            <div className="form-group-full deposit-form-group">
                              {depositList.map((id, index) => (
                                <div className="deposit-field-group">
                                  <div className="form-group">
                                    <CustomTextInput
                                      name={`desciption_${id}`}
                                      label={`Description`}
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                      touched={touched[`desciption_${id}`]}
                                      errors={errors[`desciption_${id}`]}
                                      value={values[`desciption_${id}`]}
                                      customOnchange={() =>
                                        setFormInputChanged(true)
                                      }
                                    />
                                  </div>

                                  <div className="form-group">
                                    <CustomCurrencyInput
                                      name={`deposit_amount_${id}`}
                                      label={`Deposit (${propertyData?.currency})`}
                                      setFieldValue={setFieldValue}
                                      setFieldTouched={setFieldTouched}
                                      touched={touched[`deposit_amount_${id}`]}
                                      errors={errors[`deposit_amount_${id}`]}
                                      value={values[`deposit_amount_${id}`]}
                                      currency={propertyData?.currency}
                                      customOnchange={() =>
                                        setFormInputChanged(true)
                                      }
                                    />
                                  </div>

                                  <div className="deposit-remove-btn-wrapper">
                                    {depositList.length > 1 && (
                                      <TextButton
                                        variant="red"
                                        className="font_m fw_6"
                                        onClick={() => {
                                          setFormInputChanged(true);
                                          remove(
                                            formRef,
                                            setInitialFormValues,
                                            depositList,
                                            setDepositList,
                                            index
                                          );
                                        }}
                                      >
                                        Remove
                                      </TextButton>
                                    )}
                                  </div>
                                </div>
                              ))}

                              <div className="deposit-add-btn-wrapper text-end w_100">
                                <TextButton
                                  className="font_m fw_6"
                                  onClick={() => {
                                    setFormInputChanged(true);
                                    addMore(
                                      formRef,
                                      setInitialFormValues,
                                      depositList,
                                      setDepositList
                                    );
                                  }}
                                >
                                  Add
                                </TextButton>
                              </div>

                              {(settings?.collect_vat_enabled ||
                                settings?.collect_vat_enabled) && (
                                <>
                                  {settings?.collect_tax_enabled && (
                                    <div className="check">
                                      <CustomCheckInput
                                        value={depositTax}
                                        setValue={setDepositTax}
                                        customOnChange={() => {
                                          setFormInputChanged(true);
                                          setIsChanged(true);
                                        }}
                                      />
                                      <span className="font_s check-text">
                                        Collect Tax (
                                        {settings?.collect_tax_value}
                                        %)
                                      </span>
                                    </div>
                                  )}

                                  {settings?.collect_vat_enabled && (
                                    <div className="check">
                                      <CustomCheckInput
                                        value={depositVat}
                                        setValue={setDepositVat}
                                        customOnChange={() => {
                                          setFormInputChanged(true);
                                          setIsChanged(true);
                                        }}
                                      />
                                      <span className="font_s check-text">
                                        Collect VAT (
                                        {settings?.collect_vat_value}
                                        %)
                                      </span>
                                    </div>
                                  )}
                                </>
                              )}
                            </div>
                          )}

                          {commision && (
                            <div className="form-group">
                              <CustomCurrencyInput
                                name="commision"
                                label={`Commision (${propertyData?.currency})`}
                                currency={propertyData?.currency}
                                setFieldTouched={setFieldTouched}
                                setFieldValue={setFieldValue}
                                value={values.commision}
                                touched={touched.commision}
                                errors={errors.commision}
                                customOnchange={() => setFormInputChanged(true)}
                              />

                              {(settings?.collect_vat_enabled ||
                                settings?.collect_vat_enabled) && (
                                <>
                                  {settings?.collect_tax_enabled && (
                                    <div className="check">
                                      <CustomCheckInput
                                        value={commissionTax}
                                        setValue={setCommissionTax}
                                        customOnChange={() => {
                                          setFormInputChanged(true);
                                          setIsChanged(true);
                                        }}
                                      />
                                      <span className="font_s check-text">
                                        Collect Tax (
                                        {settings?.collect_tax_value}
                                        %)
                                      </span>
                                    </div>
                                  )}

                                  {settings?.collect_vat_enabled && (
                                    <div className="check">
                                      <CustomCheckInput
                                        value={commissionVat}
                                        setValue={setCommissionVat}
                                        customOnChange={() => {
                                          setFormInputChanged(true);
                                          setIsChanged(true);
                                        }}
                                      />
                                      <span className="font_s check-text">
                                        Collect VAT (
                                        {settings?.collect_vat_value}
                                        %)
                                      </span>
                                    </div>
                                  )}
                                </>
                              )}
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>

                {!existingTenant &&
                  tenantType?.type === GLOBAL.TENANT_TYPE.TRANSITIONED &&
                  instalments &&
                  !isChanged &&
                  instalments.length > 0 && (
                    <InstallmentList
                      data={instalments}
                      setInstalments={setInstalments}
                      application_id={applicationID}
                      totalRent={formRefValues.contract_amount}
                      invoicesList={invoicesList}
                      setInvoicesList={setInvoicesList}
                      isChequeEnabled={instalmentPlan.cheque}
                      // canAttachCheque={!isChanged && !dataChanged}
                      canAttachCheque={!formInputChanged && !dataChanged}
                      unitId={unitId}
                      totalAmt={totalAmt}
                      setTotalAmt={setTotalAmt}
                      currency={propertyData?.currency}
                      instalmentVat={instalmentVat}
                      instalmentTax={instalmentTax}
                      instalmentPlan={instalmentPlan}
                      dataChanged={dataChanged}
                      setDataChanged={setDataChanged}
                      isChanged={formInputChanged}
                      property={propertyData}
                      canGenerateInvoices={
                        false
                        // Object.keys(errors).length === 0 &&
                        // !isChanged &&
                        // !canOnboard &&
                        // !dataChanged
                      }
                    />
                  )}
              </div>

              {/* Submit Button */}
              <div className="button-wrapper text-center container_sm">
                {!existingTenant && (
                  <>
                    {tenantType?.type === GLOBAL.TENANT_TYPE.TRANSITIONED ? (
                      <>
                        {!dataChanged && (
                          <>
                            {!formInputChanged && (
                              <>
                                {!totalAmt?.errorAmt &&
                                  instalments.length <= 0 && (
                                    <CustomButton type="submit" size="l">
                                      Generate Installments
                                    </CustomButton>
                                  )}

                                {!totalAmt?.errorAmt &&
                                  instalments.length > 0 &&
                                  !formInputChanged &&
                                  canOnboard && (
                                    <CustomButton type="submit" size="l">
                                      Invite
                                    </CustomButton>
                                  )}
                              </>
                            )}

                            {instalments.length > 0 && formInputChanged && (
                              <CustomButton type="submit" size="l">
                                Generate Installments
                              </CustomButton>
                            )}

                            {instalments.length <= 0 && formInputChanged && (
                              <CustomButton type="submit" size="l">
                                Generate Installments
                              </CustomButton>
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <CustomButton type="submit" size="l">
                        Invite
                      </CustomButton>
                    )}

                    {/* <CustomButton type="submit" size="l">
                    {tenantType?.type === GLOBAL.TENANT_TYPE.TRANSITIONED ? (
                      <>
                        {!totalAmt?.errorAmt && installments.length <= 0 && (
                          <>Generate Installments</>
                        )}

                        {!totalAmt?.errorAmt && installments.length > 0 && (
                          <>Invite</>
                        )}
                      </>
                    ) : (
                      <>Invite</>
                    )}
                  </CustomButton> */}
                    <TextButton
                      type="button"
                      onClick={onCancel}
                      className="fw_6 font-m"
                    >
                      Cancel
                    </TextButton>
                  </>
                )}
              </div>
            </form>
          )}
        </Formik>

        {/* Searching and Selecting existing tenant */} 
        {existingTenant && (
          <div className="container_sm">
          <SearchTenant
            onSelect={(tenant) => {
              onSelectSearched(
                tenant,
                setInitialFormValues,
                setExistingTenant,
                customPhoneNum,
                setMemberSelected
              );
            }}
          />
          </div>
        )}

        {/* Modals */}
        {/* Error Modal */}
        <ErrorModal
          showModal={showErrModal}
          description={message}
          title="Error"
          onClose={() => {
            setShowErrModal(false);
          }}
        />

        {/* Success Modal */}
        <SuccessModal
          showModal={showSuccessModal}
          description={message}
          // title="Invitation Sent"
          title="Success"
          onClose={() => {
            // onSuccess();
            if (goBack) {
              navigate(-1);
            } else {
              setShowSuccessModal(false);
            }
          }}
        />
      </div>
    </>
  );
};
/* Component ends */

export default InviteTenant;
