/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import GLOBAL from "../../../../setup/constants/global";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { formatCurrency } from "../../../../setup/utils/global-helper";
import { useNavigate } from "react-router-dom";
import { dummyData } from "./helper";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../../setup/store/slices/globalAlertSlice";
import { requestAmendment } from "../../../../setup/store/slices/contractSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { EyeFilledBlue } from "../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import PropertyDetail from "./sub-component/PropertyDetail";
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
import NoteModal from "../../Maintenance/NoteModal/NoteModal";
import RevisionMessage from "./sub-component/RevisionMessage";
import InititalAgreementInstalmentPlan from "./sub-component/InititalAgreementInstalmentPlan";
import ESignDocModal from "../ESignDocModal/ESignDocModal";
import TextButton from "../../../ui/button/TextButton/TextButton";
import Status from "./sub-component/Status";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const InitialAgreementForm = (props) => {
  /* Props destructuring starts */
  const {
    renewal = false,
    isUnitCommercial,
    unit,
    contract,
    application,
    isUnitResidential,
    onSignatureSuccess,
    application_id,
    type = "initial_agreement",
    onCancelRenewal = null,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [showRevision, setShowRevision] = useState(false);
  const [showInitialAgreement, setShowInitialAgreement] = useState(true);
  const [openAgreement, setOpenAgreement] = useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currency = unit?.currency;
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // On requesting for revision
  const revisionRequest = async (note) => {
    // Creating or collecting payload data to be sent
    let payload = {
      contract_id: contract._id,
      message: note,
      unit_id: unit._id,
    };
    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      const result = await dispatch(requestAmendment(payload));
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          navigate(-1);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  // useEffect(() => {
  //   setShowInitialAgreement(true);
  //   if (contract?.actions && contract?.actions?.length > 0) {
  //     const action = contract.actions[contract.actions.length - 1];
  //     if (action.status === "pending") setShowInitialAgreement(false);
  //   }
  // }, [contract]);

  useEffect(() => {
    setShowInitialAgreement(false);
    if (application?.application?.tenant_type === "renewal") {
      if (contract?.status === "pending_tenant_approval") {
        setShowInitialAgreement(true);
      }
    } else {
      setShowInitialAgreement(true);
    }
  }, [contract]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="initial-agreement-form-wrapper container_sm">
      <h1 className="font_xxl fw_6 text-center page-heading">
        Initial Agreement
      </h1>
      {/* Rejected Note */}
      {/* {initialAgreements?.installment_plan?.status ===
        GLOBAL.TENANT_APPLICATION_STATUS.DATA_REJECTED && (
        <ErrorNote
          note={
            initialAgreements.installment_plan.actions[
              initialAgreements.installment_plan.actions.length - 1
            ].note
          }
        />
      )} */}

      {/* Total Rent and Number of Installments */}
      {/* <Payment
        totalRent={formatCurrency(
          initialAgreements?.installment_plan?.total_rent?.toString(),
          "USD"
        )}
        instalmentCount={
          initialAgreements?.installment_plan?.no_of_installments
        }
      /> */}

      {/* Deposit Amount */}
      {/* {initialAgreements?.installment_plan?.deposit && (
        <DepositAmount
          amount={formatCurrency(
            initialAgreements?.installment_plan?.deposit_amount?.toString(),
            "USD"
          )}
          dueDate={moment(initialAgreements.installment_plan.createdAt).format(
            GLOBAL.DATE_FORMAT
          )}
        />
      )} */}

      {/* Installment Lists */}
      {/* {initialAgreements?.installments?.map((item, index) => (
        <div key={index}>
          <Instalment
            label={item?.name}
            amount={formatCurrency(item?.amount?.toString(), "USD")}
            due_date={moment(item.due_date).format(GLOBAL.DATE_FORMAT)}
          />
        </div>
      ))} */}

      {/* <div className="template-trigger bg_grey">
        <p className="font_m">Initial Agreement</p>
        <div className="trigger">
          <img className="eye-icon" src={EyeFilledBlue} alt="" />
        </div>
      </div> */}

      {/* Property Details */}
      <div className="unit-detail-installment-plan bg_grey">
        <PropertyDetail
          unit={unit}
          isUnitResidential={isUnitResidential}
          isUnitCommercial={isUnitCommercial}
        />

        <InititalAgreementInstalmentPlan
          settings={application?.property_settings}
          isRenewal={
            application?.application?.tenant_type === "renewal" && true
          }
          currency={
            currency ||
            application?.installment_plan?.currency ||
            application?.installmentPlan?.currency
          }
          installmentPlan={
            application?.installment_plan || application?.installmentPlan
          }
          installments={
            application?.installments || application?.tenantInstallments
          }
        />
      </div>

      {contract?.actions?.length > 0 && <RevisionMessage contract={contract} />}

      {/* <Signature setSignatureImg={setSignatureImg} /> */}

      {showInitialAgreement && (
        <div className="doc-view-control-wrapper bg_grey">
          <p class="font_l fw_6 agreement-heading">Initial Agreement</p>
          <p className="font_s fw_5 font_yellow agreement-description">
            It's important to carefully review both the installment plan and the
            accompanying initial agreement before signing. Signing signifies
            your acceptance of the plan and all its associated terms and
            conditions.
          </p>

          <CustomButton
            size="m"
            className="view-document-btn"
            onClick={() => setOpenAgreement(true)}
          >
            Sign Agreement
          </CustomButton>

          <ESignDocModal
            onCompleted={onSignatureSuccess}
            application_id={application_id}
            type={type}
            showModal={openAgreement}
            onClose={() => setOpenAgreement(false)}
          />
        </div>
      )}

      <div className="btn-wrapper text-center">
        {/* {!renewal && (
          <CustomButton onClick={onSubmit} type="submit" size="l">
            Next
          </CustomButton>
        )} */}
        {renewal && showInitialAgreement && (
          <CustomButton
            onClick={() => setShowRevision(true)}
            type="submit"
            variant="yellow"
            size="l"
          >
            Revision
          </CustomButton>
        )}

        {renewal && (
          <>
            {contract?.status === "pending_revision_approval" && (
              <Status status="Pending Revision Approval" />
            )}
          </>
        )}

        {/* {renewal &&
          contract?.contract_details?.status === "reservor-pending-action" &&
          contract?.contract_details?.sub_status ===
            "pending_tenant_approval" && (
            <>
              <CustomButton onClick={onSubmit} type="submit" size="l">
                Next
              </CustomButton>

              {renewal && (
                <CustomButton
                  onClick={() => setShowRevision(true)}
                  type="submit"
                  variant="yellow"
                  size="l"
                >
                  Revision
                </CustomButton>
              )}
            </>
          )} */}
      </div>

      {renewal && onCancelRenewal && (
        <div className="cancel_renewal text-center">
          <TextButton
            onClick={onCancelRenewal}
            variant="red"
            className="font_m fw_5"
          >
            No Thanks, I want to move out.
          </TextButton>
        </div>
      )}

      <NoteModal
        showModal={showRevision}
        title="Initial Agreement Revision"
        onCancel={() => setShowRevision(false)}
        actionWithNote={revisionRequest}
        inputPlaceholder="Reason"
        btnText="Send"
        showError={true}
      />
    </div>
  );
};
/* Component ends */

export default InitialAgreementForm;
