// Vehicle intial form values
export const vehiclesInitVal = {};

export const isApproved = (status) => {
  return status && status === "approved" ? true : false;
};

// Vehicle form validation
export const vehicleValidation = (values, formCountList) => {
  const error = {};

  formCountList.forEach((i, indx) => {
    const make = `make_${i}`;
    const model = `model_${i}`;
    const color = `color_${i}`;
    const plate_number = `plate_number_${i}`;
    const plate_issued_from = `plate_issued_from_${i}`;
    const image = `image_${i}`;

    if (values[make] === "") {
      error[make] = "Vehicle Make is required";
    }

    if (values[model] === "") {
      error[model] = "Vehicle Model Name is required";
    }

    if (values[color] === "") {
      error[color] = "Vehicle Color is required";
    }

    if (values[plate_number] === "") {
      error[plate_number] = "Plate Number is required";
    }

    if (values[plate_issued_from] === "") {
      error[plate_issued_from] = "Plate Issued From is required";
    }

    if (values[image] === null) {
      error[image] = "Vehicle Image is required";
    }
  });

  return error;
};

// Vehicle form prefilling
export const vehicleFormPrefill = async (
  data,
  setInitialFormValues,
  setFormCountList,
  setVehicleModelData,
  vehicleMakeList,
  fetchVehicleModel
) => {
  const formValues = {};
  const vehicleModels = [];
  const vehicleMakes = [];
  const currentFormCount = [];

  data.forEach((item, i) => {
    formValues[`make_${i}`] = item?.make;
    formValues[`model_${i}`] = item?.model;
    formValues[`color_${i}`] = item?.color;
    formValues[`plate_number_${i}`] = item?.plate_number;
    formValues[`plate_issued_from_${i}`] = item?.plate_issued_from;
    formValues[`image_${i}`] = item?.image;
    formValues[`status_${i}`] = item?.status;

    currentFormCount.push(i);
    vehicleModels.push([]);
    vehicleMakes.push(vehicleMakeList.filter((d) => d?.name === item?.make)[0]);
  });
  setVehicleModelData(vehicleModels);

  vehicleMakes.forEach((item, index) => {
    fetchVehicleModel(item?._id).then((data) => {
      vehicleModels[index] = data?.payload;
      setVehicleModelData([...vehicleModels]);
    });
  });
  setInitialFormValues(formValues);
  setFormCountList(currentFormCount);

  // const vehicleModelListPromises = data.map((item) =>
  //   asyncGetVehicleModel(item, generateVehicleModels, vehicleMakeList)
  // );

  // const fetchedModelListAsPerMake = await Promise.all(vehicleModelListPromises);
};

export const setFormList = (
  formCountList,
  setFormCountList,
  requiredFormCountNumber,
  formRef,
  setInitialFormValues,
  vehicleModelData,
  setVehicleModelData
) => {
  const currentFormState = { ...formRef.current.values };
  const currentFormCount = [...formCountList];
  const vehicleModels = [...vehicleModelData];

  if (requiredFormCountNumber > formCountList.length) {
    // Adding forms
    for (let i = 0; i < requiredFormCountNumber; i++) {
      if (i >= formCountList.length) {
        currentFormState[`make_${i}`] = "";
        currentFormState[`model_${i}`] = "";
        currentFormState[`color_${i}`] = "";
        currentFormState[`plate_number_${i}`] = "";
        currentFormState[`plate_issued_from_${i}`] = "";
        currentFormState[`image_${i}`] = null;

        currentFormCount.push(i);
        vehicleModels.push([]);
      }
    }
  } else if (requiredFormCountNumber < formCountList.length) {
    const lastIdx = formCountList.length - 1;

    for (let i = lastIdx; i >= requiredFormCountNumber; i--) {
      delete currentFormState[`make_${formCountList[i]}`];
      delete currentFormState[`model_${formCountList[i]}`];
      delete currentFormState[`color_${formCountList[i]}`];
      delete currentFormState[`plate_number_${formCountList[i]}`];
      delete currentFormState[`plate_issued_from_${formCountList[i]}`];
      delete currentFormState[`image_${formCountList[i]}`];

      currentFormCount.pop();
      vehicleModels.pop();
    }
  }

  setInitialFormValues(currentFormState);
  setFormCountList(currentFormCount);
  setVehicleModelData(vehicleModels);
};

export const isDataChanged = (newData, prevData, keyList) => {
  for (let i = 0; i < keyList.length; i++) {
    const make_old = prevData[i].make;
    const model_old = prevData[i].model;
    const color_old = prevData[i].color;
    const plate_number_old = prevData[i].plate_number;
    const plate_issued_from_old = prevData[i].plate_issued_from;
    const image = prevData[i].image;

    if (newData[`make_${keyList[i]}`] !== make_old) {
      return true;
    }

    if (newData[`model_${keyList[i]}`] !== model_old) {
      return true;
    }

    if (newData[`color_${keyList[i]}`] !== color_old) {
      return true;
    }

    if (newData[`plate_number_${keyList[i]}`] !== plate_number_old) {
      return true;
    }

    if (newData[`plate_issued_from_${keyList[i]}`] !== plate_issued_from_old) {
      return true;
    }

    if (
      newData[`image_${keyList[i]}`] &&
      !newData[`image_${keyList[i]}`]?.url
    ) {
      return true;
    }
  }

  return false;
};
