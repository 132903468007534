/**
 *
 */

import React, { useState, useEffect, useRef } from "react";

/* Import configuration starts */
import { Formik } from "formik";
import { initialValues, validations } from "./helper";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */

import GLOBAL from "../../../../setup/constants/global";
import CustomRadioInput from "../../../ui/input/CustomRadioInput/CustomRadioInput";
import CustomTextInput from "../../../ui/input/CustomTextInput/CustomTextInput";
import CustomDateInput from "../../../ui/input/CustomDateInput/CustomDateInput";
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
import TextButton from "../../../ui/button/TextButton/TextButton";
import CustomPhoneInput from "../../../ui/input/CustomPhoneInput/CustomPhoneInput";
import ErrorModal from "../../../ui/modal/ErrorModal/ErrorModal";
import SuccessModal from "../../../ui/modal/SuccessModal/SuccessModal";
/* Import local pages and component ends */

// Styles
import "./style.scss";
import TextEditor from "../../../ui/other/TextEditor/TextEditor";
import NewHTMLTextEditor from "../../../ui/other/NewHTMLTextEditor/NewHTMLTextEditor";

/* Component starts */

const AddAdvertisments = (props) => {
  /* Props destructuring starts */

  const { onSuccess, onCancel } = props;
  //onSuccess, onCancel
  /* Props destructuring ends */

  let initialFormValues = initialValues;

  /* Component states and useRef declaration starts */
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [type, setType] = useState("Internal");
  const [descVal, setDescVal] = useState({ value: null });

  const [isEmptyContent, setIsEmptyContent] = useState(false);
  const [isEditorFocused, setIsEditorFocused] = useState(false); // State to track editor focus

  // const [showErrorModal, setShowErrorModal] = useState(false);
  // const [showSuccessModal, setShowSuccessModal] = useState(false);
  // const [modalDesc, setModalDesc] = useState("");
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const customPhoneNum = useRef({ code: "", num: "" });
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  // Form Validations
  const formValidation = (values) => {
    return validations(values, type);
  };

  const onSubmit = async (values) => {
    console.log(
      values,
      "VAL",
      descVal.value,
      "Start-Date",
      startDate,
      "End-Date",
      endDate
    );

    // Backend response. Try, Catch
    // try {
    //   const result = await dispatch(addVisitorsPass(payload));

    //   // Handling success response
    //   switch (result.meta.requestStatus) {
    //     case GLOBAL.REJECTED:
    //       setModalDesc(result.payload);
    //       setShowErrorModal(true);
    //       break;

    //     case GLOBAL.FULFILLED:
    //       setModalDesc(result.payload.message);
    //       setShowSuccessModal(true);
    //       break;
    //   }
    // } catch (error) {
    //   // Handling error response
    //   console.error(error.message);
    // }
  };

  /* Component function definition ends */

  /* Component useEffect starts */

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */

  return (
    <div className="add-advertisment-module-wrapper">
      <h2 className="heading text-center font_xxl mb-5">
        Create Advertisments
      </h2>

      <div className="add-new-form">
        <Formik
          onSubmit={onSubmit}
          initialValues={initialFormValues}
          validate={formValidation}
          validateOnBlur={true}
          enableReinitialize={true}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="form-wrapper">
                {/* Creating Advertisments */}
                <>
                  {/* Input for Title */}
                  <div className="form-group">
                    <CustomTextInput
                      name="title"
                      label="Title"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched.title}
                      errors={errors.title}
                      value={values.title}
                      required={true}
                    />
                  </div>
                  {/* Radio select the Type Of User */}
                  <div className="form-group d-flex align-items-center flex-row gap-5">
                    <CustomRadioInput
                      label="Internal"
                      onSelecting={() => {
                        setType("Internal");
                      }}
                      isSelected={type === "Internal"}
                    />
                    <CustomRadioInput
                      label="Vendor"
                      onSelecting={() => {
                        setType("Vendor");
                      }}
                      isSelected={type === "Vendor"}
                    />
                  </div>

                  {/* Input for First Name */}
                  {type === "Vendor" && (
                    <>
                      <div className="form-group">
                        <CustomTextInput
                          name="vendor_name"
                          label="Vendor Name"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          touched={touched.vendor_name}
                          errors={errors.vendor_name}
                          value={values.vendor_name}
                          required={true}
                        />
                      </div>
                      <div className="form-group">
                        <CustomTextInput
                          name="email"
                          label="Email"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          touched={touched.email}
                          errors={errors.email}
                          value={values.email}
                          required={true}
                        />
                      </div>

                      <div className="form-group">
                        <CustomPhoneInput
                          name="mobile_number"
                          label={"Phone Number"}
                          customPhoneNum={customPhoneNum}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          // onCountryCodeChanged={onCountryCodeChanged}
                          value={values.mobile_number}
                          countryCodeEditable={true}
                          errors={errors.mobile_number}
                          touched={touched.mobile_number}
                          required={true}
                        />
                      </div>

                      <div className="form-group">
                        <CustomTextInput
                          name="amount"
                          label="Amount"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          touched={touched.amount}
                          errors={errors.amount}
                          value={values.amount}
                          required={true}
                        />
                      </div>
                    </>
                  )}

                  {/* Text Editor */}
                  <div className="form-group form-group-full">
                    {/* <TextEditor
                      label="Add"
                      descVal={descVal}
                      setDescVal={setDescVal}
                    /> */}

                    <NewHTMLTextEditor
                      label="Add"
                      descVal={descVal}
                      setDescVal={setDescVal}
                      isEmptyContent={isEmptyContent}
                      setIsEmptyContent={setIsEmptyContent}
                      isEditorFocused={isEditorFocused}
                      setIsEditorFocused={setIsEditorFocused}
                    />

                    {(isEmptyContent && (
                      <p className="font_red fw_6 font_m">{`Editor content cannot be empty!`}</p>
                    )) ||
                      (isEditorFocused && (
                        <p className="font_red fw_6 font_m">{`Please finish editing before submitting!`}</p>
                      ))}
                  </div>

                  {/* Input for Start Date */}
                  <div className="form-group">
                    <CustomDateInput
                      label="Start Date"
                      value={startDate}
                      minDate={false}
                      customOnchange={(date) => setStartDate(date)}
                      required={true}
                    />
                  </div>

                  {/* Input for End Date */}
                  <div className="form-group">
                    <CustomDateInput
                      label="End Date"
                      value={endDate}
                      minDate={false}
                      customOnchange={(date) => setEndDate(date)}
                      required={true}
                    />
                  </div>
                </>
              </div>

              <div className="button-wrapper text-center">
                <>
                  <CustomButton type="submit" size="l">
                    Create
                  </CustomButton>
                  <TextButton
                    type="button"
                    onClick={onCancel}
                    className="font_m fw_6"
                  >
                    Cancel
                  </TextButton>
                </>
              </div>
            </form>
          )}
        </Formik>
      </div>

      {/* Success Modal */}
      {/* <SuccessModal
        showModal={showSuccessModal}
        description={modalDesc}
        title="Successfully Created"
        onClose={() => {
          onSuccess();
          setShowSuccessModal(false);
        }}
      /> */}

      {/* Error Modal */}
      {/* <ErrorModal
        showModal={showErrorModal}
        description={modalDesc}
        title="Error"
        onClose={() => setShowErrorModal(false)}
      /> */}
    </div>
  );
};
/* Component ends */

export default AddAdvertisments;
