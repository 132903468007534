/**
 * Slice to fetch countries, states, and cities
 * @author Abdul Ahad <abdulahad.ss@smartdatainc.net>
 *
 */

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../axios";
import { API_URL } from "../../config/devKeys";

const initialState = {
  countries: [],
  states: [],
  cities: [],
};

export const fetchCountries = createAsyncThunk(
  "countries/list",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await axios.get(API_URL + "/countries/list");
      return data.data;
    } catch (error) {
      return rejectWithValue(error?.response);
    }
  }
);

export const fetchStatesByCountryId = createAsyncThunk(
  "countries/states_by_country_id",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await axios.get(
        API_URL +
          "/countries/states_by_country_id?country_id=" +
          payload.country_id
      );
      return data.data;
    } catch (error) {
      return rejectWithValue(error?.response);
    }
  }
);

export const fetchCitiesByStateId = createAsyncThunk(
  "countries/cities_by_state_id",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await axios.get(
        API_URL + "/countries/cities_by_state_id?state_id=" + payload.state_id
      );
      return data.data;
    } catch (error) {
      return rejectWithValue(error?.response);
    }
  }
);

export const countriesSlice = createSlice({
  name: "country",
  initialState,
  reducers: {
    setStates: (state, action) => {
      state.states = action.payload;
    },
    setCities: (state, action) => {
      state.cities = action.payload;
    },
  },
  extraReducers: {
    [fetchCountries.rejected]: (state, action) => {
      state.countries = [];
    },
    [fetchCountries.fulfilled]: (state, action) => {
      state.countries = action.payload;
    },
    [fetchStatesByCountryId.rejected]: (state, action) => {
      state.states = [];
    },
    [fetchStatesByCountryId.fulfilled]: (state, action) => {
      state.states = action.payload;
    },
    [fetchCitiesByStateId.rejected]: (state, action) => {
      state.cities = [];
    },
    [fetchCitiesByStateId.fulfilled]: (state, action) => {
      state.cities = action.payload;
    },
  },
});

export const getCountries = (state) => state.country.countries;
export const getStates = (state) => state.country.states;
export const getCities = (state) => state.country.cities;
export const { setCities, setStates } = countriesSlice.actions;

export default countriesSlice.reducer;
