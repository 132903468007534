import React from "react";

const Header = () => {
  return (
    <>
      <h2 className="fw_6 font_xxl"> Occupancy </h2>
    </>
  );
};

export default Header;
