/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import moment from "moment/moment";
import { useFormikContext } from "formik";
import GLOBAL from "../../../../setup/constants/global";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import MUI component starts */
/* Import MUI component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import Variant1 from "./variant/Variant1";
import Variant2 from "./variant/Variant2";
import Variant3 from "./variant/Variant3";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const CustomDateInput = (props) => {
  /* Props destructuring starts */
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return <Variant2 {...props} />;
};
/* Component ends */

export default CustomDateInput;

// const {
//   setFieldTouched, // setFieldTouched formik function
//   label,
//   value, // useState value / Formik State
//   setValue = null,
//   name,
//   required = true,
//   customOnchange = null,
//   handleBlur,
//   handleChange,
//   touched,
//   errors,
//   setFieldValue = null, // setFieldValue formik function
//   refValue = null, // If useRef used
//   minDate = true,
//   showTimeInput = false,
//   version = "2",
// } = props;
