/**
 *
 */

import React, { useState, useEffect, useRef } from "react";

/* Import configuration starts */
import GLOBAL from "../../../../setup/constants/global";
import { useDispatch } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  addCommunityImage,
  deleteCommunityImage,
  setDefaultCommunityImage,
} from "../../../../setup/store/slices/communitySlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { DeleteWhite } from "../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import TextButton from "../../../ui/button/TextButton/TextButton";
import SuccessModal from "../../../ui/modal/SuccessModal/SuccessModal";
import ErrorModal from "../../../ui/modal/ErrorModal/ErrorModal";
import ConfirmationModal from "../../../ui/modal/ConfirmationModal/ConfirmationModal";
import FileViewerModal from "../../../ui/modal/FileViewerModal/FileViewerModal";
import Image from "../../../ui/other/Image/Image";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const CommunityImagesStripe = (props) => {
  /* Props destructuring starts */
  const { community } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [imageId, setImageId] = useState("");
  const [showfileViewer, setShowFileViewer] = useState(false);
  const [modalDesc, setModalDesc] = useState("");
  const [file, setFile] = useState({});

  // useRefs
  const fileInput = useRef(null);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const images = community?.images;

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // On viewing selected image
  const onView = (image) => {
    setFile({
      url: image.image,
      type: "jpeg",
    });
    setShowFileViewer(true);
  };

  // On setting default community image
  const setDefault = async (image) => {
    // Collecting or creating payload data to be sent
    const payload = {
      image_id: image?._id,
    };

    // Backend response. Try, catch
    try {
      const result = await dispatch(setDefaultCommunityImage(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setModalDesc(result.payload);
          setShowErrorModal(true);
          break;

        case GLOBAL.FULFILLED:
          setModalDesc(result.payload.message);
          setShowSuccessModal(true);
          break;
      }
    } catch (error) {
      // Handling error response
      console.log("error", error);
    }
  };

  // On delete community image
  const onDelete = (image) => {
    setImageId(image._id);
    setModalDesc("Are you sure you want to delete this community image ?");
    setShowConfirmationModal(true);
  };

  // On confirming delete
  const confirmDelete = async () => {
    // Collecting or creating payload data to be sent
    const payload = {
      image_id: imageId,
    };

    // Backend response. Try, catch
    try {
      const result = await dispatch(deleteCommunityImage(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setModalDesc(result.payload);
          setShowConfirmationModal(false);
          setShowErrorModal(true);
          break;
        case GLOBAL.FULFILLED:
          setModalDesc("Community image successfully deleted");
          setShowConfirmationModal(false);
          setShowSuccessModal(true);
          break;
      }
    } catch (error) {
      // Handling error response
      console.log("error", error);
    }
  };

  // On upload Community image
  const onUploadImage = async (e) => {
    const selectedImage = e.target.files[0];

    // Collecting or creating payload data to be sent
    const payload = {
      community_id: community?._id,
      image: selectedImage,
    };

    // Backend response. Try, catch
    try {
      const result = await dispatch(addCommunityImage(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setModalDesc(result.payload);
          setShowErrorModal(true);
          break;
        case GLOBAL.FULFILLED:
          setModalDesc("Image successfully uploaded");
          setShowSuccessModal(true);
          break;
      }
    } catch (error) {
      // Handling error response
      console.log("error", error);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="community-image-stripe-component-wrapper w_100 bg_black">
      <div className="label-add w_100">
        <p className="font_s font_grey fw_6">Community Images</p>
        <TextButton
          onClick={() => fileInput.current.click()}
          className="font_s font_blue fw_6"
        >
          + Add
        </TextButton>
        <input
          onChange={onUploadImage}
          ref={fileInput}
          type="file"
          className="hidden-input"
        />
      </div>
      <p className="limit font_xs fw_5 font_grey">Upto 5, max 1MB each</p>

      {/* Image List */}
      <div className="image-stripe">
        {images?.map((item, key) => (
          <div key={key} className="image-item">
            {/* Image */}
            <Image
              className="inner-image w_100 h_100"
              src={item?.image?.url}
              objectFit="cover"
              objectPosition="center center"
              alt="Community Image"
            />

            {/* View Image */}
            <div className="view-image" onClick={() => onView(item)}></div>

            {/* Delete Image */}
            <span onClick={() => onDelete(item)} className="action delete-icon">
              <img src={DeleteWhite} alt="Delete" />
            </span>

            {/* Set Default Image */}
            {item.isDefault ? (
              <span className="action tick-icon default"></span>
            ) : (
              <span
                onClick={(e) => setDefault(item, e)}
                className="action tick-icon"
              ></span>
            )}
          </div>
        ))}
      </div>

      {/* Modals */}
      {/* Confirmation Modal */}
      <ConfirmationModal
        showModal={showConfirmationModal}
        description={modalDesc}
        title="Confirm Delete"
        onClose={() => setShowConfirmationModal(false)}
        onConfirm={confirmDelete}
      />

      {/* File viewer modal */}
      <FileViewerModal
        show={showfileViewer}
        file={file.url}
        type={file.type}
        onClose={() => setShowFileViewer(false)}
      />

      {/* Success Modal */}
      <SuccessModal
        showModal={showSuccessModal}
        description={modalDesc}
        title="Successfully Uploaded"
        onClose={() => setShowSuccessModal(false)}
      />

      {/* Error Modal */}
      <ErrorModal
        showModal={showErrorModal}
        description={modalDesc}
        title="Error"
        onClose={() => setShowErrorModal(false)}
      />
    </div>
  );
};
/* Component ends */

export default CommunityImagesStripe;
