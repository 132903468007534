/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  logOutUser,
  updateUserEmail,
} from "../../../setup/store/slices/authSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import { validEmail } from "../../../setup/utils/validations";
import CustomTextInput from "../../../components/ui/input/CustomTextInput/CustomTextInput";
import CustomButton from "../../../components/ui/button/CustomButton/CustomButton";
import TextButton from "../../../components/ui/button/TextButton/TextButton";
import SuccessModal from "../../../components/ui/modal/SuccessModal/SuccessModal";
import ErrorModal from "../../../components/ui/modal/ErrorModal/ErrorModal";

// import { logout } from "../../../setup/store/slices/masterSlice";

import GLOBAL from "../../../setup/constants/global";

/* Import local pages and component ends */

/* Component starts */
const ChangeEmail = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [modalDesc, setModalDesc] = useState("");
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  const dispatch = useDispatch();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const initialValues = {
    newEmail: "",
  };
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  // Error text if the required input field is empty
  const errorMsgForEmptyFields = (fieldName) => {
    switch (fieldName) {
      case "newEmail":
        return "Email is required";
      default:
        return "Invalid Input";
    }
  };

  // Input fields that are required are validated
  const requiredFieldsValidation = (errorFields, values) => {
    Object.keys(values).map((key) => {
      if (values[key] === "") {
        errorFields[key] = errorMsgForEmptyFields(key);
      }
    });
    return errorFields;
  };

  // Other form validations logics
  const otherFieldValidations = (errorFields, values) => {
    /* Validation for newEmail starts */
    if (isNaN(+values.newEmail) && !validEmail(values.newEmail)) {
      errorFields.newEmail = "Email is not valid";
    }
    /* Validation for newEmail ends */
  };

  // Form validations
  const formValidation = (values) => {
    const errorFields = {};
    requiredFieldsValidation(errorFields, values);
    otherFieldValidations(errorFields, values);
    return errorFields;
  };

  // Handling on signing in
  const updateEmail = async (values) => {
    // setErrorWhileSubmiting(null);

    // Creating or gathering payload data to be sent
    const payload = {
      new_email: values.newEmail.toLowerCase(),
    };

    // // Backend Response. Try, Catch
    try {
      let result = await dispatch(updateUserEmail(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setModalDesc(result.payload);
          setShowErrorModal(true);
          break;
        case GLOBAL.FULFILLED:
          setModalDesc(result.payload.message);
          setShowSuccessModal(true);
          localStorage.clear();
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="form-wrapper">
      {/* Sign in form */}
      <Formik
        onSubmit={updateEmail}
        initialValues={initialValues}
        validate={formValidation}
        validateOnBlur={true}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            {/* Input for Email */}
            <div className="form-group">
              <CustomTextInput
                name="newEmail"
                type="text"
                label="Email"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.newEmail}
                touched={touched.newEmail}
                errors={errors.newEmail}
              />
            </div>

            {/* Input for Password */}
            <div className="form-group">
              <p className="font_s font_grey">
                Once you send update email address request, you will be logged
                out of DGRNTE. You need to verify email address in order to
                login again.
              </p>
            </div>

            <div className="button-wrapper">
              <CustomButton type="submit" size="l">
                Update Email
              </CustomButton>
              <TextButton
                type="button"
                onClick={() => navigate(-1)}
                className="font_m fw_6 text-btn"
              >
                Cancel
              </TextButton>
            </div>
          </form>
        )}
      </Formik>

      {/* MODALS */}
      {/* Success Modal */}
      <SuccessModal
        showModal={showSuccessModal}
        description={modalDesc}
        title="Successfully Updated Email"
        onClose={async () => {
          //   onSuccess();
          setShowSuccessModal(false);
          // await dispatch(logout());
          // navigate("/signin");
          localStorage.clear();
          await dispatch(logOutUser());
          navigate("/signin");
        }}
      />

      {/* Error Modal */}
      <ErrorModal
        showModal={showErrorModal}
        description={modalDesc}
        title="Error"
        onClose={() => setShowErrorModal(false)}
      />
    </div>
  );
};
/* Component ends */

export default ChangeEmail;
