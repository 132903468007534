export const logTitle = (item) => {
  let title = "";
  let role = item?.requester_role?.name;
  let currentStatus = item?.status;
  let logType = item?.log_type;

  //   Generating title according to action string
  switch (item?.action) {
    //
    case "":
      title = "";
      break;

    // Default Case...
    default:
      title = item?.action;
  }

  if (title === "Owner" && currentStatus === "violation_issued") {
    title = "Violation Issued";
  }

  return title;
};
