/**
 * @auther Aflak Arshi
 *
 * slice for current selection
 */

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  property: null,
  community: null,
  unit: null,
  lost: null,
  found: null,
};

export const selectionSlice = createSlice({
  name: "selection",
  initialState,
  reducers: {
    setSelectedProperty: (state, action) => {
      state.property = action.payload;
    },
    setSelectedCommunity: (state, action) => {
      state.community = action.payload;
    },
    setSelectedUnit: (state, action) => {
      state.unit = action.payload;
    },
    setSelectedLostItem: (state, action) => {
      state.lost = action.payload;
    },
    setSelectedFoundItem: (state, action) => {
      state.found = action.payload;
    },
  },
});

export const {
  setSelectedProperty,
  setSelectedCommunity,
  setSelectedUnit,
  setSelectedFoundItem,
  setSelectedLostItem,
} = selectionSlice.actions;

export const getSelectedProperty = (state) => state.selection.property;
export const getSelectedCommunity = (state) => state.selection.community;
export const getSelectedUnit = (state) => state.selection.unit;
export const getSelectedLostItem = (state) => state.selection.lost;
export const getSelectedFoundItem = (state) => state.selection.found;

export default selectionSlice.reducer;
