/**
 *
 */

import React, { useState, useEffect, useRef } from "react";

/* Import configuration starts */
import { Formik } from "formik";
import {
  defaultFormValue,
  addDesc,
  removeDesc,
  formValidation,
} from "./helper";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Modal } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
import CustomTextArea from "../../../ui/input/CustomTextArea/CustomTextArea";
import TextButton from "../../../ui/button/TextButton/TextButton";
/* Import local pages and component ends */

import "./style.scss";
import { getUserTheme } from "../../../../setup/store/slices/authSlice";
import { useSelector } from "react-redux";

/* Component starts */
const HandOverItemModal = (props) => {
  /* Props destructuring starts */
  const {
    showModal,
    callBack,
    title = "Handover Item",
    size = "lg",
    onClose,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [initialFormValues, setInitialFormValues] = useState(defaultFormValue);
  const [desc, setDesc] = useState([0]);
  const formikRef = useRef();
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const userTheme = useSelector(getUserTheme);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const validations = (values) => {
    return formValidation(values, desc);
  };

  const onSubmit = (values) => {
    const description = [];
    desc.forEach((item, index) => {
      description.push(values[`description_${item}`]);
    });

    callBack(description);
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <Modal
      size={size}
      centered
      show={showModal}
      className="handover-item-modal-wrapper"
    >
      <Modal.Body
        className={`${
          userTheme === "dark"
            ? "bg_darkTheme"
            : "bg_lightTheme font_lightTheme"
        }`}
      >
        <div className="modal-header-custom">
          <h2
            className={`text-center ${
              userTheme !== "dark" && "font_blue"
            } font_white font_xl fw_6`}
          >
            {title}
          </h2>
        </div>
        <div className="modal-body-custom">
          <div>
            <Formik
              onSubmit={onSubmit}
              initialValues={initialFormValues}
              validate={(values) => validations(values)}
              validateOnBlur={true}
              innerRef={formikRef}
              enableReinitialize={true}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="form-wrapper">
                    <div className="attachments-list">
                      {desc.map((item, index) => (
                        <div className="form-group form-group-full" key={index}>
                          <CustomTextArea
                            name={`description_${item}`}
                            label="Description"
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            touched={touched[`description_${item}`]}
                            errors={errors[`description_${item}`]}
                            value={values[`description_${item}`]}
                          />
                        </div>
                      ))}
                      <div className="action-button-wrapper">
                        <TextButton
                          onClick={() =>
                            addDesc(
                              formikRef,
                              desc,
                              setInitialFormValues,
                              setDesc
                            )
                          }
                          className="font_s fw_6"
                        >
                          Add
                        </TextButton>

                        {desc.length > 0 && (
                          <TextButton
                            onClick={() =>
                              removeDesc(
                                formikRef,
                                desc,
                                setInitialFormValues,
                                setDesc
                              )
                            }
                            className="font_s fw_6 font_red"
                          >
                            Remove
                          </TextButton>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="button-wrapper">
                    <CustomButton size="m" type="submit">
                      Submit
                    </CustomButton>
                    <CustomButton
                      size="m"
                      onClick={() => onClose()}
                      variant="red"
                    >
                      Close
                    </CustomButton>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
/* Component ends */

export default HandOverItemModal;
