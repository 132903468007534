/**
 *
 */

import React, { useEffect, useState } from "react";

/* Import configuration starts */
import { useSelector } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import { getUserTheme } from "../../../../setup/store/slices/authSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Modal } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import MUI component starts */
/* Import MUI component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
import CustomTextArea from "../../../ui/input/CustomTextArea/CustomTextArea";
import CustomDateInput from "../../input/CustomDateInput/CustomDateInput";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const AddDateModal = (props) => {
  /* Props destructuring starts */
  const {
    showModal,
    title,
    size = "md",
    onCancel,
    onSubmit,
    btnText = "Submit",
    inputPlaceholder = "Select Date",
    showError = false,
    errorMessage = "",
    setErrorMessage = null,
    validation = null,
    closeOnSubmit = true,
    descriptionHeader = null,
    descriptionHeaderClass = "",
    descriptionFooter = null,
    descriptionFooterClass = "",

    // Date Input Props
    minDate = true,
    maxDate = false,
    maxDateValue = null,
    minDateValue = null,
    format = "dd/MM/y",
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [date, setDate] = useState(new Date());
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const userTheme = useSelector(getUserTheme);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // On Action
  const handleSubmit = () => {
    if (showError && validation && !validation()) return;
    setDate(new Date());
    onSubmit(date.toString());
    closeOnSubmit && onCancel();
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    setErrorMessage && setErrorMessage("");
  }, [date]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <Modal
      size={size}
      centered
      show={showModal}
      className="add-date-modal-wrapper"
    >
      <Modal.Body
        className={`${
          userTheme === "dark"
            ? "bg_darkTheme"
            : "bg_lightTheme font_lightTheme"
        }`}
      >
        <div className="modal-header-custom">
          <h2 className="text-center font_blue font_xl">{title}</h2>
        </div>
        {descriptionHeader && (
          <p
            className={`header-description ${
              (userTheme === "light" || userTheme === "automatic") &&
              "otherTheme"
            } description ${descriptionHeaderClass}`}
          >
            {descriptionHeader}
          </p>
        )}
        <div className="modal-body-custom modal-date-value-wrapper">
          <div
            className={`form-group ${
              (userTheme === "light" || userTheme === "automatic") &&
              "otherTheme"
            } `}
          >
            <CustomDateInput
              value={date}
              setValue={setDate}
              label={inputPlaceholder}
              minDate={minDate}
              maxDate={maxDate}
              maxDateValue={maxDateValue && new Date(maxDateValue)}
              minDateValue={minDateValue && new Date(minDateValue)}
              format={format}
            />

            {showError && errorMessage !== "" && (
              <p className="font_xs error font_red fw_5">{errorMessage}</p>
            )}
          </div>
        </div>

        {descriptionFooter && (
          <p
            className={`footer-description description ${descriptionFooterClass}`}
          >
            {descriptionFooter}
          </p>
        )}
        <div className="modal-footer-custom">
          <CustomButton size="m" variant="outline-red" onClick={onCancel}>
            Close
          </CustomButton>
          <CustomButton
            size="m"
            variant={`${btnText === "Reject" ? "red" : "primary"}`}
            onClick={handleSubmit}
          >
            {btnText}
          </CustomButton>
        </div>
      </Modal.Body>
    </Modal>
  );
};
/* Component ends */

export default AddDateModal;
