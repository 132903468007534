export const dateFormatList = [
  { name: "DD/MM/YYYY" },
  { name: "MM/DD/YYYY" },
  { name: "DD-MM-YYYY" },
  { name: "MM-DD-YYYY" },
  { name: "MMM-DD-YYYY" },
  { name: "DD-MMM-YYYY" },
];

export const timeFormatList = [{ name: "hh:mm A" }, { name: "hh:mm a" }];

export const preFill = (setInitialFormValues) => {
  const currentUserDateFormat =
    localStorage.getItem("user_data") &&
    JSON.parse(localStorage.getItem("user_data"))?.date_format;
  const currentUserTimeFormat =
    localStorage.getItem("user_data") &&
    JSON.parse(localStorage.getItem("user_data"))?.time_format;

  setInitialFormValues({
    date_format: currentUserDateFormat || "",
    time_format: currentUserTimeFormat || "",
  });
};

export const validations = (values) => {
  const error = {};

  if (values?.date_format === "") {
    error.date_format = "Date format is required";
  }

  if (values?.time_format === "") {
    error.time_format = "Time format is required";
  }

  return error;
};
