/* /**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import GLOBAL from "../../../../../setup/constants/global";
import moment from "moment";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Table } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import {
  DocFilledWhite,
  DocumentGreen,
} from "../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../../../ui/button/IconButton/IconButton";
import FileViewerModal from "../../../../ui/modal/FileViewerModal/FileViewerModal";
import ApplicationDataRejectionModal from "../../ApplicationDataRejectionModal/ApplicationDataRejectionModal";
/* Import local pages and component ends */

/* Component starts */
const Vehicles = (props) => {
  /* Props destructuring starts */
  const { data, approval = false, onAction, index } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [file, setFile] = useState();
  const [showFileViewer, setShowFileViewer] = useState(false);
  const [rejectAction, setRejectAction] = useState({});
  const [rejectionModal, setRejectionModal] = useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // On viewing file
  const onViewFile = (item) => {
    setFile(item);
    setShowFileViewer(true);
  };

  // On approving details
  const onApprove = (item) => {
    onAction({
      type: "vehicles",
      info_id: item._id,
      info_details: {
        status: GLOBAL.TENANT_APPLICATION_STATUS.DATA_APPROVED,
      },
    });
  };

  // On rejecting details
  const onReject = (item) => {
    setRejectAction({
      type: "vehicles",
      info_id: item._id,
      info_details: {
        status: GLOBAL.TENANT_APPLICATION_STATUS.DATA_REJECTED,
      },
    });
    setRejectionModal(true);
  };

  // On rejecting with note
  const onRejectWithNote = (note) => {
    setRejectionModal(false);
    const action = { ...rejectAction };
    action.info_details.note = note;
    onAction(action);
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="vehicle-wrapper">
      <div className="vehicle-heading">
        <p className="font_l label"> {index && `${index} -`} Vehicles</p>
      </div>

      {/* ID Verfication Table */}
      <div className="custom-table-wrapper">
        {data?.length > 0 && (
          <Table className="custom-table font_s" borderless responsive>
            {/* Table Heading */}
            <thead>
              <tr>
                <th>Make</th>
                <th>Model</th>
                <th>Color</th>
                <th>Plate Number</th>
                <th>Place Issued From</th>
                <th>Image</th>
                {approval && <th>Actions</th>}
                <th>Action Date</th>
              </tr>
            </thead>
            {/* Table Body */}
            <tbody>
              {data?.map((item, index) => (
                <tr key={index}>
                  {/* Make */}
                  <td>{item?.make}</td>
                  {/* Model */}
                  <td>{item?.model}</td>
                  {/* Color */}
                  <td>{item?.color}</td>
                  {/* Plate Number */}
                  <td>{item?.plate_number}</td>
                  {/* Place Issued */}
                  <td>{item?.plate_issued_from}</td>
                  {/* ID Image */}
                  <td className="text-start txt-nowrap ">
                    <IconButton onClick={() => onViewFile(item?.image)}>
                      <img src={DocumentGreen} alt="View ID" />
                    </IconButton>
                  </td>

                  {approval && (
                    <td className="txt-nowrap action">
                      {/* If pending actions */}
                      {item.status ===
                        GLOBAL.TENANT_APPLICATION_STATUS.DATA_PENDING && (
                        <div className="btn-wrapper">
                          <span
                            onClick={() => onApprove(item)}
                            className="approve font_s fw_3"
                          >
                            Approve
                          </span>
                          <span
                            onClick={() => onReject(item)}
                            className="reject font_s fw_3"
                          >
                            Reject
                          </span>
                        </div>
                      )}
                      {/* If data approved */}
                      {item?.status ===
                        GLOBAL.TENANT_APPLICATION_STATUS.DATA_APPROVED && (
                        <p className="fw_6 font_s font_green">Approved</p>
                      )}
                      {/* If data rejected */}
                      {item?.status ===
                        GLOBAL.TENANT_APPLICATION_STATUS.DATA_REJECTED && (
                        <p className="fw_6 font_s font_red">Rejected</p>
                      )}
                    </td>
                  )}

                  <td>
                    {item?.status === "approved"
                      ? moment(item?.updatedAt).format(DATE_TIME_FORMAT)
                      : "-"}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}

        {data?.length === 0 && (
          <div className=" text-center">
            <h3 className=" font_m font_yellow fw_6">No Data</h3>
          </div>
        )}
      </div>

      {/* Modals */}
      {/* File Viewer Modal */}
      <FileViewerModal
        show={showFileViewer}
        file={file?.url}
        type={file?.type}
        onClose={() => {
          setShowFileViewer(false);
          setFile({});
        }}
      />

      {/* Tenant Application Rejection Modal*/}
      <ApplicationDataRejectionModal
        showModal={rejectionModal}
        title="Are you sure you want to reject this Vehicle?"
        onRejectWithNote={onRejectWithNote}
        onCancel={() => setRejectionModal(false)}
      />
    </div>
  );
};
/* Component ends */

export default Vehicles;
