/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useNavigate } from "react-router-dom";
import moment from "moment";
import GLOBAL from "../../../../../setup/constants/global";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Table } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { EyeFilledBlue } from "../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../../../ui/button/IconButton/IconButton";
import Pagination from "../../../../ui/other/Pagination/Pagination";
/* Import local pages and component ends */

/* Component starts */
const MoveOutRequest = (props) => {
  /* Props destructuring starts */
  const { maintenances, totalCount, currentPage, setCurrentPage } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      <div className="custom-table-wrapper moveOutRequest-table">
        {maintenances?.length > 0 && (
          <Table className="custom-table font_s" borderless responsive>
            {/* Table Heading */}
            <thead>
              <tr>
                <th>ID</th>
                <th>Date & Time</th>
                <th>Tenant</th>
                <th>Unit</th>
                <th>Move Out Date & Time</th>
                {/* <th>Technician</th> */}
                <th>Status</th>
                <th className="text-center">Action</th>
              </tr>
            </thead>
            {/* Table Body */}
            <tbody>
              {maintenances?.map((item) => (
                <tr key={item._id}>
                  {/* ID */}
                  <td>{item?.request_id}</td>

                  {/*  Date */}
                  <td>{moment(item?.createdAt).format(DATE_TIME_FORMAT)}</td>

                  {/* Tenant Name */}
                  <td>
                    {/* {`${item?.tenant_id?.first_name} !== undefined ? {item?.tenant_id?.first_name} {item?.tenant_id?.last_name}`} */}
                    {item?.tenant_id !== undefined
                      ? `${item?.tenant_id?.first_name} ${item?.tenant_id?.last_name}`
                      : "-"}
                  </td>

                  {/* Unit NUmber */}
                  <td>
                    {item?.unit_card?.unit_name !== undefined
                      ? item?.unit_card?.unit_name
                      : "-"}
                  </td>

                  {/* Move In Date */}
                  <td>
                    {item?.moveIn_date !== undefined ? item?.moveIn_date : "-"}
                  </td>

                  {/* Technician */}
                  {/* <td>{item?.technician}</td> */}

                  {/* Status */}
                  <td
                    className={
                      item?.status === "Completed"
                        ? "complete"
                        : item?.status === "Unassigned"
                        ? "unassign"
                        : item?.status.startsWith("Pending")
                        ? "pending"
                        : item?.status.startsWith("Waiting")
                        ? "waiting"
                        : item?.status === "New"
                        ? "font_blue"
                        : item?.status === "Closed"
                        ? "font_red"
                        : ""
                    }
                  >
                    {item?.status}
                  </td>

                  {/* Actions */}
                  <td className="text-center txt-nowrap">
                    <IconButton
                      onClick={() => navigate(`${item?._id}`)}
                      className="action-button"
                    >
                      <img
                        src={EyeFilledBlue}
                        className="h_100"
                        alt="View Details"
                      />
                    </IconButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}

        {maintenances?.length === 0 && (
          <div className=" text-center">
            <h3 className=" font_m font_yellow fw_6">No Data</h3>
          </div>
        )}
      </div>

      {maintenances?.length > 0 && (
        <Pagination
          itemsPerPage="10"
          totalItems={totalCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
    </>
  );
};
/* Component ends */

export default MoveOutRequest;
