/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Table } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { EyeFilledBlue } from "../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../../ui/button/IconButton/IconButton";
/* Import local pages and component ends */

/* Component starts */
const ExpiredSubscriptionRequest = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */

  // DUMMY DATA
  const ongoingList = [
    {
      id: "MT01",
      date: "9/13/2022 09:30 AM",
      tenant: "Orville Harrison",
      unit: "G72",
      invoice: "IN014",
      req_date: "06/15/2022",
    },
    {
      id: "MT02",
      date: "11/21/2022 08:00 AM",
      tenant: "Valencia Cuddehy",
      unit: "F28",
      invoice: "IN224",
      req_date: "02/10/2023",
    },
    {
      id: "MT03",
      date: "9/3/2022 09:30 PM",
      tenant: "Dunn Roffey",
      unit: "G89",
      invoice: "IN004",
      req_date: "02/18/2020",
    },
    {
      id: "MT04",
      date: "6/26/2022 11:30 PM",
      tenant: "Heriberto Potier",
      unit: "S05",
      invoice: "IN024",
      req_date: "12/10/2023",
    },
    {
      id: "MT05",
      date: "12/9/2022 09:30 AM",
      tenant: "Gail Dolle",
      unit: "T09",
      invoice: "IN114",
      req_date: "02/10/2023",
    },
  ];

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="custom-table-wrapper rejectRequest-table">
      <Table className="custom-table font_s" borderless responsive>
        {/* Table Heading */}
        <thead>
          <tr>
            <th>Unit#</th>
            <th>Tenant</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Invoice#</th>

            <th className="text-center">Action</th>
          </tr>
        </thead>
        {/* Table Body */}
        <tbody>
          {ongoingList?.map((item) => (
            <tr key={item.id}>
              {/* ID */}
              <td>{item?.unit}</td>

              {/* Tenant Name */}
              <td>{item?.tenant}</td>

              {/*  Date */}
              <td>{item?.date}</td>

              {/*  Date */}
              <td>{item?.req_date}</td>

              {/* Invoices */}
              <td>{item?.invoice}</td>

              {/* Actions */}
              <td className="text-center txt-nowrap">
                <IconButton
                  // onClick={() =>
                  //   navigate("/tenant/security/details", {
                  //     state: { item },
                  //   })
                  // }
                  className="action-button"
                >
                  <img
                    src={EyeFilledBlue}
                    className="h_100"
                    alt="View Details"
                  />
                </IconButton>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};
/* Component ends */

export default ExpiredSubscriptionRequest;
