/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import moment from "moment";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import GLOBAL from "../../../../../setup/constants/global";
/* Import local pages and component ends */

/* Component starts */
const AccordionItem = (props) => {
  /* Props destructuring starts */
  const { setAccordionOpened, accordionOpened, part, idx, currency } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const isOpened = "title" === accordionOpened;
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="accordion">
      <div
        onClick={() => setAccordionOpened("title")}
        className="accordion-header"
      >
        <p className="font_m fw_4">Part {idx + 1}</p>
        <div className="accordion-indicator">
          {isOpened ? (
            <p className="font_xxl fw_4 font_grey icon">
              <FaAngleUp size={25} />
            </p>
          ) : (
            <p className="font_xxl fw_4 font_grey icon">
              <FaAngleDown size={25} />
            </p>
          )}
        </div>
      </div>
      {isOpened && (
        <div className="accordion-body">
          <div className="part-details">
            <p className="font_xs fw_4 font_grey">
              Type <br />
              <span className="font_xs fw_4">{part?.type}</span>
            </p>

            <p className="font_xs fw_4 font_grey">
              Cost Covered By <br />
              <span className="font_xs fw_4 ">{part?.cost_covered_by}</span>
            </p>

            <p className="font_xs fw_4 font_grey">
              Part Number <br />
              <span className="font_xs fw_4 ">{part?.part_number}</span>
            </p>
          </div>
          <div>
            <p className="font_xs fw_4 font_grey">
              Description <br />
              <span className="font_xs fw_4">{part?.description}</span>
            </p>
          </div>

          <div>
            <p className="font_xs fw_4 font_grey">
              Company Name <br />
              <span className="font_xs fw_4">{part?.company_name}</span>
            </p>
          </div>

          <div className="part-details">
            <p className="font_xs fw_4 font_grey">
              Quantity <br />
              <span className="font_xs fw_4">{part?.quantity}</span>
            </p>

            <p className="font_xs fw_4 font_grey">
              Estimated Delivery <br />
              <span className="font_xs fw_4 ">
                {moment(part?.estimated_delivery_time).format(DATE_TIME_FORMAT)}
              </span>
            </p>

            <p className="font_xs fw_4 font_grey">
              Vendor <br />
              <span className="font_xs fw_4 ">{part?.vendor}</span>
            </p>
          </div>

          <div>
            <p className="font_xs fw_4 font_grey">
              Warranty <br />
              <span className="font_xs fw_4">{part?.warrenty}</span>
            </p>
          </div>

          <div>
            <p className="font_xs fw_4 font_grey">
              Price ({currency}) <br />
              <span className="font_xs fw_4">
                {parseInt(part?.price)?.toLocaleString("en-US")}
              </span>
            </p>
          </div>
        </div>
      )}
    </div>
  );
};
/* Component ends */

export default AccordionItem;
