/**
 * This component is a Modal that is shown on success
 */

import React from "react";

/* Import react bootstrap component starts */
import { Modal } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { XRed, XWhite } from "../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../../ui/button/IconButton/IconButton";
/* Import local pages and component ends */

import "./style.scss";
import { getUserTheme } from "../../../../setup/store/slices/authSlice";
import { useSelector } from "react-redux";

/* Component starts */
const TermsConditionSignupModal = (props) => {
  /* Props destructuring starts */
  const { showModal, data, size = "lg", onClose, title } = props;
  /* Props destructuring ends */

  const userTheme = useSelector(getUserTheme);

  /* ************* Component rendering. JSX code ************* */
  return (
    <Modal
      size={size}
      centered
      show={showModal}
      className={`terms-condition-modal-wrapper `}
    >
      <Modal.Body
        className={`${userTheme === "dark" ? "bg_darkTheme" : "bg_lightTheme"}`}
      >
        <IconButton className="" onClick={onClose}>
          <img src={XRed} className="close-icn" alt="Close" />
        </IconButton>
        <div className="modal-header-custom">
          <p className="text-center font_xxl font_blue">{title}</p>
        </div>
        <div className="modal-body-custom">
          <div
            dangerouslySetInnerHTML={{
              __html: data,
            }}
          />
        </div>
        <div className="modal-footer-custom"></div>
      </Modal.Body>
    </Modal>
  );
};
/* Component ends */

export default TermsConditionSignupModal;
