/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useDispatch, useSelector } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import { getSetting } from "../../setup/store/slices/settingSlice";
import { updateTenantAutomaticPaymentStatus } from "../../setup/store/slices/tenantSlice";
import {
  fetchTenant,
  getIsCLIENT,
  getIsTENANT,
  getUser,
} from "../../setup/store/slices/authSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import AutoPaymentSettings from "../../components/module/AccountSettings/AutoPaymentSettings/AutoPaymentSettings";
import BreadCrumb from "../../components/ui/other/BreadCrumb/BreadCrumb";
/* Import local pages and component ends */

/* Component starts */
const AutoPaymentSettingsPage = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [showAddModal, setShowAddModal] = useState(false);
  const [automaticPaymentOn, setAutomaticPaymentOn] = useState(null);
  const [settingChanged, setSettingChanged] = useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();

  const user = useSelector(getUser);
  const settings = useSelector(getSetting);
  const isClient = useSelector(getIsCLIENT);
  const isTenant = useSelector(getIsTENANT);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    setAutomaticPaymentOn(user?.automatic_payment);
  }, [user, user?.automatic_payment]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      {!isTenant && <BreadCrumb type="settings_autoPayment_page" />}
      <div className="container_sm">
        <AutoPaymentSettings
          automaticPaymentOn={automaticPaymentOn}
          setAutomaticPaymentOn={setAutomaticPaymentOn}
        />
      </div>
    </>
  );
};
/* Component ends */

export default AutoPaymentSettingsPage;
