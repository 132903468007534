/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const TabButton = (props) => {
  /* Props destructuring starts */
  const {
    buttonList,
    onSelect = null,
    currentKey,
    setCurrentKey = null,
    currentSubKey,
    setCurrentSubKey = null,
    className = null,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="tab-button-component-wrapper">
      {buttonList?.map((item, index) => (
        <button
          className={
            className === null
              ? `${currentKey === item.key ? "active" : ""} ${
                  currentSubKey === item.key ? "sub-active" : ""
                }`
              : `${className} ${currentKey === item.key ? "active" : ""} ${
                  currentSubKey === item.key ? "sub-active" : ""
                }`
          }
          onClick={() => {
            setCurrentKey && setCurrentKey(item.key);
            setCurrentSubKey && setCurrentSubKey(item.key);

            onSelect && onSelect(item.key);
          }}
          key={index}
        >
          {item.label}
        </button>
      ))}
    </div>
  );
};
/* Component ends */

export default TabButton;
