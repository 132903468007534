/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  fetchViolationBasedOnId,
  getViolationData,
} from "../../setup/store/slices/violationSlice";
import { getIsTENANT } from "../../setup/store/slices/authSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import ViolationDetailReport from "../../components/module/Voilations/VoilationsTables/ClientViolationTable/ViolationDetailPage/ViolationDetailReport";
import BreadCrumb from "../../components/ui/other/BreadCrumb/BreadCrumb";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const ViolationDetailedPage = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  // const { state } = useLocation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const violation = useSelector(getViolationData);
  const isTenant = useSelector(getIsTENANT);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    if (id) {
      dispatch(fetchViolationBasedOnId({ request_id: id }));
    }
  }, [id, dispatch]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      {!isTenant && <BreadCrumb type="client_violation_detail" />}

      <div className="violation-detail-page-wrapper">
        <ViolationDetailReport violation={violation} violation_id={id} />
      </div>
    </>
  );
};
/* Component ends */

export default ViolationDetailedPage;
