/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import Header from "./local-component/Header";
import PieChartGraph from "./local-component/PieChartGraph";
import Searchbar from "../../components/ui/other/Searchbar/Searchbar";
import CustomDateInput from "../../components/ui/input/CustomDateInput/CustomDateInput";
import SharedFacilityTable from "./local-component/SharedFacilityTable";
// import CustomButton from "../../components/ui/button/CustomButton/CustomButton";
// import TextButton from "../../components/ui/button/TextButton/TextButton";
/* Import local pages and component ends */

import "./style.scss";
import EmailModal from "../../components/ui/modal/EmailModal/EmailModal";
import { EnvelopeOutLinedBlue, PrintIcon } from "../../setup/constants/images";

/* Component starts */
const SharedFacilityDashboard = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());

  const [showModal, setShowModal] = useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  // SEND EMAIL FUNCTION
  const handleSendEmail = () => {
    setShowModal(!showModal);
  };

  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="shared-facility-dashboard-wrapper">
      <div className="action-wrapper">
        <div>
          <img src={PrintIcon} alt="Print-Icon" />
          <span className="font_m fw_3 font_blue">Print</span>
        </div>

        <div onClick={handleSendEmail}>
          <img src={EnvelopeOutLinedBlue} alt="Print-Icon" />
          <span className="font_m fw_3 font_blue">Email</span>
        </div>
      </div>
      <div className="header">
        <Header />
      </div>

      <div className="report-chart-wrapper">
        <div className="detail-box ">
          <div>
            <p className="font_l fw_4">Categories</p>
          </div>
          <div>
            <PieChartGraph />
          </div>
        </div>

        <div className="detail-box ">
          <div>
            <p className="font_l fw_4">Status</p>
          </div>
          <div>
            <PieChartGraph />
          </div>
        </div>
      </div>

      <div className="search-box mt-4 mb-4">
        <div className="search">
          <Searchbar className="dash-search" />
        </div>

        <div className="date">
          <CustomDateInput
            label="From"
            value={fromDate}
            minDate={false}
            customOnchange={(date) => setFromDate(date)}
            required={true}
          />
        </div>

        <div className="date">
          <CustomDateInput
            label="To"
            value={toDate}
            minDate={false}
            customOnchange={(date) => setToDate(date)}
            required={true}
          />
        </div>

        <div className="btn-wrapper">
          <button>Apply</button>
          <button className="btn-clear">Clear</button>
        </div>
      </div>

      <div className="shared-facility-table-wrapper">
        <SharedFacilityTable />
      </div>

      {/* EMAIL MODAL */}
      <EmailModal
        showModal={showModal}
        onSuccess={() => setShowModal(!showModal)}
        onClose={() => setShowModal(!showModal)}
      />
    </div>
  );
};
/* Component ends */

export default SharedFacilityDashboard;
