/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import moment from "moment";
import GLOBAL from "../../../../../setup/constants/global";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Table } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
/* Import local pages and component ends */

/* Component starts */
const ApplicationRejectedStatus = (props) => {
  /* Props destructuring starts */
  const { application, index } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="keys-wrapper">
      <div className="keys-heading">
        <p className="font_l label">
          {" "}
          {index !== undefined ? `${index} ${"-"}` : ""} Application Status
        </p>
      </div>

      {/* ID Verfication Table */}
      <div className="custom-table-wrapper">
        <Table className="custom-table font_s" borderless responsive>
          {/* Table Heading */}
          <thead>
            <tr>
              <th>Request ID</th>
              <th>Status</th>
              <th>Rejection Reason</th>
              <th>Rejected By</th>
              <th>Action Date</th>
            </tr>
          </thead>
          {/* Table Body */}
          <tbody>
            <tr key={application?.request_id}>
              {/* ID */}
              <td>{application?.request_id}</td>

              {/* Status */}
              <td>
                <p className="font_red fw_6">Rejected</p>
              </td>

              {/* Rejection Reason */}
              <td>
                {application?.rejection_reason !== undefined
                  ? application?.rejection_reason
                  : "-"}
              </td>

              {/* Rejected By */}
              <td>
                {application?.rejected_user_name !== undefined
                  ? `${application?.rejected_user_name} (${application?.rejected_by})`
                  : "-"}
              </td>

              {/* Action Date */}
              <td>
                {application?.rejected_timeStamp !== undefined
                  ? moment(application?.rejected_timeStamp).format(
                      DATE_TIME_FORMAT
                    )
                  : "-"}
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </div>
  );
};
/* Component ends */

export default ApplicationRejectedStatus;
