/**
 *
 */

import React from "react";

/* Import configuration starts */
import moment from "moment";
import { useNavigate } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Table } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { EyeFilledBlue } from "../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import GLOBAL from "../../../../setup/constants/global";
import IconButton from "../../../ui/button/IconButton/IconButton";
/* Import local pages and component ends */

// Styles
import "./style.scss";

/* Component starts */
const RequestsTable = (props) => {
  /* Props destructuring starts */
  const {} = props;

  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */

  const requestList = [];

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */

  return (
    <div className="custom-table-wrapper request-table-wrapper">
      {requestList?.length > 0 && (
        <Table className="custom-table font_s" borderless responsive>
          {/* Table Heading */}
          <thead>
            <tr>
              <th>ID</th>
              <th>Subject</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          {/* Table Body */}
          <tbody>
            {requestList?.map((item) => (
              <tr key={item.id}>
                {/* ID */}

                <td>{item?.id}</td>

                {/* Subject */}
                <td>{item?.subject}</td>

                {/* Status */}
                <td
                  className={
                    item?.status === "Open"
                      ? "open"
                      : item?.status === "In Progress"
                      ? "progress"
                      : "completed"
                  }
                >
                  {item?.status}
                </td>

                {/* Actions */}
                <td className="text-start txt-nowrap">
                  <IconButton
                    // onClick={() =>
                    //   navigate("/tenant/guest-pass/edit", {
                    //     state: { item },
                    //   })
                    // }
                    className="action-button"
                  >
                    <img
                      src={EyeFilledBlue}
                      className="h_100"
                      alt="View Details"
                    />
                  </IconButton>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      {requestList?.length === 0 && (
        <div className=" text-center">
          <h3 className=" font_m font_yellow fw_6">No Data</h3>
        </div>
      )}
    </div>
  );
};
/* Component Ends */
export default RequestsTable;
