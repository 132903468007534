/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer } from "recharts";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import {
  EnvelopeOutLinedBlue,
  PrintIcon,
} from "../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
/* Import local pages and component ends */

/* Component starts */
const AdvertismentChart = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */

  const data = [
    {
      name: "M",
      value: 160,
    },
    {
      name: "T",
      value: 300,
    },
    {
      name: "W",
      value: 900,
    },
    {
      name: "T",
      value: 480,
    },
    {
      name: "F",
      value: 1890,
    },
    {
      name: "S",
      value: 690,
    },
    {
      name: "S",
      value: 990,
    },
  ];

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="bar-chart-wrapper">
      <div className="chart-details">
        <h3 className="font_xl fw_6 font_blue text-center py-2">
          Sponsor a Radio Contest
        </h3>
        <div className="actions">
          <p className="font_xxs fw_4 font_grey">
            <img src={PrintIcon} alt="Print-Pic" />
            Print
          </p>
          <p className="font_xxs fw_4 font_grey">
            <img src={EnvelopeOutLinedBlue} alt="Email-Pic" />
            Email
          </p>
        </div>
        <div className="text-center">
          <p className="font_m font_grey fw_4">15 April - 21 April</p>
        </div>
      </div>
      <div className="chart-bar-details">
        <span className="font_l fw_4 font_grey text-rotate">
          Ad Views So Far
        </span>

        <ResponsiveContainer width="100%" aspect={3}>
          <BarChart height={250} data={data} barSize={60}>
            <Bar dataKey="value" fill="#37BDB0" />
            <XAxis dataKey="name" axisLine={false} tickLine={false} />
            <YAxis
              axisLine={false}
              padding={{ top: 20, bottom: 20 }}
              tickLine={false}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};
/* Component ends */

export default AdvertismentChart;
