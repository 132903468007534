import React, { useState, useEffect, useRef } from "react";

/* Import configuration starts */
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  formPrefill,
  initialFormValue,
  validations,
  addMore,
  remove,
} from "./helper";
import GLOBAL from "../../../../setup/constants/global";
import { useParams } from "react-router-dom";
import {
  customMoment,
  customDateFormat,
} from "../../../../setup/utils/global-helper";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  fetchPropertySettings,
  getPropertySettings,
} from "../../../../setup/store/slices/propertySlice";
import {
  fetchSetting,
  getSetting,
} from "../../../../setup/store/slices/settingSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Modal } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
import CustomCheckInput from "../../../ui/input/CustomCheckInput/CustomCheckInput";
import CustomCurrencyInput from "../../../ui/input/CustomCurrencyInput/CustomCurrencyInput";
import CustomDateInput from "../../../ui/input/CustomDateInput/CustomDateInput";
import CustomNumberInput from "../../../ui/input/CustomNumberInput/CustomNumberInput";
import CustomTextInput from "../../../ui/input/CustomTextInput/CustomTextInput";
import TextButton from "../../../ui/button/TextButton/TextButton";
/* Import local pages and component ends */

import "./style.scss";
import { getUserTheme } from "../../../../setup/store/slices/authSlice";

/* Component starts */
const CreateInstallmentModal = (props) => {
  /* Props destructuring starts */
  const {
    showModal,
    application_id,
    target_rent_price,
    onClose,
    instalmentPlan,
    declareInstallment,
    startDate,
    currency,
    isContractRenewal = false,
    property,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [formValues, setFormValues] = useState(initialFormValue);
  const [deposit, setDeposit] = useState(false);
  const [commission, setCommission] = useState(false);
  const [cheque, setCheque] = useState(false);
  const [depositList, setDepositList] = useState([0]);
  const [depositVat, setDepositVat] = useState(false);
  const [depositTax, setDepositTax] = useState(false);
  const [commissionVat, setCommissionVat] = useState(false);
  const [commissionTax, setCommissionTax] = useState(false);
  const [instalmentVat, setInstalmentVat] = useState(false);
  const [instalmentTax, setInstalmentTax] = useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const formRef = useRef();
  const dispatch = useDispatch();
  const { propertyId } = useParams();
  const settings = useSelector(getPropertySettings);
  const userTheme = useSelector(getUserTheme);
  const globalSettings = useSelector(getSetting);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // Form validation
  const formValidation = (values) => {
    return validations(
      values,
      deposit,
      commission,
      depositList,
      globalSettings
    );
  };

  // On generating installment plans
  const onSubmit = async (values) => {
    // Creating or collecting payload data to be sent
    const depositData = [];
    let depositAmount = 0;
    depositList?.forEach((id, index) => {
      depositData.push({
        description: values[`description_${id}`],
        deposit_amount: values[`deposit_amount_${id}`],
      });
      depositAmount += parseInt(values[`deposit_amount_${id}`]) || 0;
    });

    const payload = {
      application_id,
      ...values,
      start_date: moment(values?.start_date)?.format("MM-DD-YYYY"),
      deposit,
      commission,
      cheque,
      collect_deposit_vat: depositVat,
      collect_deposit_tax: depositTax,
      collect_commission_vat: commissionVat,
      collect_commission_tax: commissionTax,
      collect_instalment_vat: instalmentVat,
      collect_instalment_tax: instalmentTax,
      deposit_data: JSON.stringify(depositData),
      deposit_amount: depositAmount,
    };
    if (!commission) delete payload["commission_amount"];
    if (!deposit) {
      delete payload["deposit_amount"];
      delete payload["deposit_data"];
    }
    // if (isContractRenewal) {
    //   payload["start_date"] = new Date(startDate).toString();
    // }

    onClose();
    await declareInstallment(payload);
  };
  /* Component function definition ends */
  /* Component useEffect starts */
  // On load of existing form field data, to prefill form field
  useEffect(() => {
    if (instalmentPlan || target_rent_price) {
      formPrefill(
        setFormValues,
        target_rent_price,
        instalmentPlan,
        setDeposit,
        setCommission,
        startDate,
        setDepositVat,
        setDepositTax,
        setCommissionVat,
        setCommissionTax,
        setInstalmentVat,
        setInstalmentTax,
        setDepositList,
        property
      );
    }
  }, [instalmentPlan, target_rent_price]);

  useEffect(() => {
    dispatch(fetchPropertySettings({ property_id: propertyId }));
    dispatch(fetchSetting());
  }, []);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <Modal
      size="lg"
      centered
      show={showModal}
      className="installment-modal-wrapper"
    >
      <Modal.Body
        className={`${userTheme === "dark" ? "bg_darkTheme" : "bg_lightTheme"}`}
      >
        <div className="modal-header-custom">
          <h2
            className={`text-center ${
              userTheme !== "dark" && "font_blue"
            } font_white font_xl`}
          >
            Create Installment
          </h2>
        </div>
        <div className="modal-body-custom">
          <Formik
            onSubmit={onSubmit}
            initialValues={formValues}
            validate={formValidation}
            validateOnBlur={true}
            enableReinitialize={true}
            innerRef={formRef}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              setFieldTouched,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="form-wrapper">
                  {/* Input for Total Annual Rent */}
                  <div className="form-group">
                    <CustomCurrencyInput
                      name="total_rent"
                      label={`Total Rent (${currency})`}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      touched={touched.total_rent}
                      errors={errors.total_rent}
                      value={values.total_rent}
                      currency={currency}
                    />
                    <p className="font_yellow fw_4 font_xs">
                      Target Rent :{" "}
                      <span className="font_yellow fw_4 font_xs">
                        {target_rent_price?.toLocaleString()} ({currency})
                      </span>
                    </p>
                  </div>

                  {/* Input for Number Of Installment */}
                  <div className="form-group">
                    <CustomNumberInput
                      label="Number Of Installment"
                      name="no_of_installments"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      value={values.no_of_installments}
                      touched={touched.no_of_installments}
                      errors={errors.no_of_installments}
                    />
                    {(settings?.collect_tax_enabled ||
                      settings?.collect_vat_enabled) && (
                      <>
                        {settings?.collect_tax_enabled && (
                          <div className="check">
                            <CustomCheckInput
                              value={instalmentTax}
                              setValue={setInstalmentTax}
                            />
                            <span className="font_s font_grey check-text">
                              Collect Tax ({settings?.collect_tax_value}%)
                            </span>
                          </div>
                        )}

                        {settings?.collect_vat_enabled && (
                          <div className="check">
                            <CustomCheckInput
                              value={instalmentVat}
                              setValue={setInstalmentVat}
                            />
                            <span className="font_s font_grey check-text">
                              Collect VAT ({settings?.collect_vat_value}%)
                            </span>
                          </div>
                        )}
                      </>
                    )}
                  </div>

                  {/* Input for Duration */}
                  <div className="form-group">
                    <CustomNumberInput
                      label="Duration"
                      name="duration"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      value={values.duration}
                      touched={touched.duration}
                      errors={errors.duration}
                    />
                  </div>

                  {/* Input for Start Date */}
                  {!isContractRenewal && (
                    <div
                      className={`form-group ${
                        (userTheme === "light" || userTheme === "automatic") &&
                        "otherTheme"
                      } `}
                    >
                      <CustomDateInput
                        name="start_date"
                        label="Start Date"
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        touched={touched.start_date}
                        errors={errors.start_date}
                        value={values.start_date}
                        canSetOffset={true}
                        canSetTimezone={true}
                        offset={property?.offset}
                        timeZone={property?.time_zone}
                      />
                    </div>
                  )}

                  {/* Input for Deposit and Commission */}
                  <div className="form-group form-group-full form-group-flex">
                    {!isContractRenewal && (
                      <div className="check">
                        <CustomCheckInput
                          value={deposit}
                          setValue={setDeposit}
                        />
                        <span className="font_s font_grey check-text">
                          Deposit
                        </span>
                      </div>
                    )}

                    {!isContractRenewal && (
                      <div className="check">
                        <CustomCheckInput
                          value={commission}
                          setValue={setCommission}
                        />
                        <span className="font_s font_grey check-text">
                          Commission
                        </span>
                      </div>
                    )}

                    <div className="check">
                      <CustomCheckInput value={cheque} setValue={setCheque} />
                      <span className="font_s font_grey check-text">
                        Recieve payment in cheque
                      </span>
                    </div>
                  </div>

                  {/* Deposit Amount */}
                  {deposit && (
                    <>
                      <div className="deposit-list">
                        {depositList.map((id, index) => (
                          <div className="deposit-item" key={id}>
                            <div className="form-group">
                              <CustomTextInput
                                name={`description_${id}`}
                                label={`Description`}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                touched={touched[`description_${id}`]}
                                errors={errors[`description_${id}`]}
                                value={values[`description_${id}`]}
                              />
                            </div>
                            <div className="form-group">
                              <CustomCurrencyInput
                                name={`deposit_amount_${id}`}
                                label={`Deposit (${currency})`}
                                setFieldValue={setFieldValue}
                                setFieldTouched={setFieldTouched}
                                touched={touched[`deposit_amount_${id}`]}
                                errors={errors[`deposit_amount_${id}`]}
                                value={values[`deposit_amount_${id}`]}
                                currency={currency}
                              />
                            </div>

                            {depositList.length > 1 && (
                              <div className="remove-desc-btn-wrapper">
                                <TextButton
                                  onClick={() => {
                                    remove(
                                      formRef,
                                      setFormValues,
                                      depositList,
                                      setDepositList,
                                      index
                                    );
                                  }}
                                  className="font_m fw_6 remove-desc-btn font_red"
                                >
                                  Remove Deposit
                                </TextButton>
                              </div>
                            )}
                          </div>
                        ))}

                        <div className="add-desc-btn-wrapper text-end">
                          <TextButton
                            onClick={() => {
                              addMore(
                                formRef,
                                setFormValues,
                                depositList,
                                setDepositList
                              );
                            }}
                            className="font_m fw_6 add-desc-btn"
                          >
                            Add Deposit
                          </TextButton>
                        </div>
                      </div>

                      {(settings?.collect_tax_enabled ||
                        settings?.collect_vat_enabled) && (
                        <div className="deposit-tax-check-wrapper">
                          {settings?.collect_tax_enabled && (
                            <div className="check">
                              <CustomCheckInput
                                value={depositTax}
                                setValue={setDepositTax}
                              />
                              <span className="font_s font_grey check-text">
                                Collect Tax ({settings?.collect_tax_value}%)
                              </span>
                            </div>
                          )}

                          {settings?.collect_vat_enabled && (
                            <div className="check">
                              <CustomCheckInput
                                value={depositVat}
                                setValue={setDepositVat}
                              />
                              <span className="font_s font_grey check-text">
                                Collect VAT ({settings?.collect_vat_value}%)
                              </span>
                            </div>
                          )}
                        </div>
                      )}
                    </>
                  )}

                  {/* Commission Amount */}
                  {commission && (
                    <div className="form-group">
                      <CustomCurrencyInput
                        name="commission_amount"
                        label={`Commission Amount (${currency})`}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        touched={touched.commission_amount}
                        errors={errors.commission_amount}
                        value={values.commission_amount}
                        currency={currency}
                      />

                      {(settings?.collect_tax_enabled ||
                        settings?.collect_vat_enabled) && (
                        <>
                          {settings?.collect_tax_enabled && (
                            <div className="check">
                              <CustomCheckInput
                                value={commissionTax}
                                setValue={setCommissionTax}
                              />
                              <span className="font_s font_grey check-text">
                                Collect Tax ({settings?.collect_tax_value}%)
                              </span>
                            </div>
                          )}

                          {settings?.collect_vat_enabled && (
                            <div className="check">
                              <CustomCheckInput
                                value={commissionVat}
                                setValue={setCommissionVat}
                              />
                              <span className="font_s font_grey check-text">
                                Collect VAT ({settings?.collect_vat_value}%)
                              </span>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  )}
                </div>

                {/* Action Buttons */}
                <div className="button-wrapper">
                  <CustomButton onClick={onClose} variant="red" size="m">
                    Cancel
                  </CustomButton>

                  {/* Generate Installment */}
                  <CustomButton type="submit" size="m">
                    Generate Installment
                  </CustomButton>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </Modal.Body>
    </Modal>
  );
};
/* Component ends */

export default CreateInstallmentModal;
