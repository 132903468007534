export const validations = (values, docList) => {
  const error = {};

  if (values["reason"] === "") {
    error["reason"] = "Rejection Reason required";
  }

  docList.forEach((item, index) => {
    const document = `document_${item}`;
    if (values[document] === null) {
      error[document] = "Photo or Video required";
    }
  });
  return error;
};

export const addMore = (formRef, setFormValue, list, setList) => {
  const generetedID = list.length === 0 ? 0 : list[list.length - 1] + 1;
  const currentFormState = { ...formRef.current.values };

  setFormValue(() => {
    // Generating new field name according to array indexes
    currentFormState[`document_${generetedID}`] = null;
    return currentFormState;
  });
  setList((p) => [...p, generetedID]);
};

export const remove = (formRef, setFormValue, list, setList, index) => {
  const removeID = list[index];
  const currentFormState = { ...formRef.current.values };

  setFormValue(() => {
    delete currentFormState[`document_${removeID}`];
    return currentFormState;
  });

  setList((prevState) => {
    const prevFields = [...prevState];
    prevFields.splice(index, 1);
    return prevFields;
  });
};
