/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useLocation, useParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */

/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import InvoicingDetails from "../../components/module/Invoicing/ClientInvoicing/InvoicingDetails/InvoicingDetails";
import TenantInvoicingDetails from "../../components/module/Invoicing/TenantInvoicing/TenantInvoicingDetail/TenantInvoicingDetails";
/* Import local pages and component ends */

// import "./style.scss";

/* Component starts */
const TenantInvoicingDetailPage = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */

  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  // useEffect(() => {
  //   if (id) {
  //     dispatch(fetchComplaintBasedOnId({ request_id: id }));
  //   }
  // }, [id, dispatch]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="">
      <TenantInvoicingDetails />
    </div>
  );
};
/* Component ends */

export default TenantInvoicingDetailPage;
