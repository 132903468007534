import {
  validEmail,
  validateAtleastOneNumber,
  validateAtleastOneSpecialChar,
} from "../../../../setup/utils/validations";

export const initialValues = {
  first_name: "",
  last_name: "",
  email: "",
  mobile: "",
  business_name: "",
  employee_id: "",
  profession: "",
  resume: "",
  notes: "",
};

export const validations = (values, customPhoneNum) => {
  const errorFields = {};

  /* Validations for First Name Starts */
  if (values.first_name === "") {
    errorFields.first_name = "First Name is required";
  }
  if (values.first_name && validateAtleastOneNumber(values.first_name)) {
    errorFields.first_name = "First Name Cannot Contains Numbers";
  }
  if (values.first_name && validateAtleastOneSpecialChar(values.first_name)) {
    errorFields.first_name = "First Name Cannot Contains Special Characters";
  }
  /* Validations for First Name Ends */

  /* Validations for Last Name Starts */
  if (values.last_name === "") {
    errorFields.last_name = "Last Name is required";
  }
  if (values.last_name && validateAtleastOneNumber(values.last_name)) {
    errorFields.last_name = "Last Name Cannot Contains Numbers";
  }
  if (values.last_name && validateAtleastOneSpecialChar(values.last_name)) {
    errorFields.last_name = "Last Name Cannot Contains Special Characters";
  }
  /* Validations for Last Name Ends */

  /* Validations for Email Starts */
  if (values.email === "") {
    errorFields.email = "Email is required";
  }
  if (values.email && !validEmail(values.email)) {
    errorFields.email = "Email is not valid";
  }
  /* Validations for Email Ends */

  /* Validations for Phone Number Starts */
  if (values.mobile === "") {
    errorFields.mobile = "Phone Number is required";
  }
  if (values.mobile && customPhoneNum.current.num.length < 8) {
    errorFields.mobile = "Phone number is not valid";
  }
  /* Validations for Phone Number Ends */

  /* Validations for Company Name Starts */
  if (values.business_name === "") {
    errorFields.business_name = "Company Name is required";
  }
  /* Validations for Company Name Ends */

  /* Validations for Profession Starts */
  if (values.profession === "") {
    errorFields.profession = "Profession is required";
  }
  /* Validations for Profession Ends */

  /* Validations for Employee ID Starts */
  if (values.employee_id === "") {
    errorFields.employee_id = "Employee ID is required";
  }
  /* Validations for Employee ID Ends */

  return errorFields;
};
