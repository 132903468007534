/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import Searchbar from "../../ui/other/Searchbar/Searchbar";
import TabButton from "../../ui/other/TabButton/TabButton";
import DrawingsPrints from "./sub-component/DrawingsPrints";
import EquipmentPrints from "./sub-component/EquipmentPrints";
import ManualPrints from "./sub-component/ManualPrints";
import MaterialPrints from "./sub-component/MaterialPrints";
import OthersPrints from "./sub-component/OthersPrints";
import WarrantiesPrint from "./sub-component/WarrantiesPrint";

/* Import local pages and component ends */

// Styles
import "./style.scss";

/* Component starts */
const BluePrints = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [currentKey, setCurrentKey] = useState("drawing");
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();

  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const buttonList = [
    { key: "drawing", label: "As Built Drawings" },
    { key: "materials", label: "Materials" },
    { key: "equipments", label: "List Of Equipments" },
    { key: "manual", label: "User Manual" },
    { key: "warranties", label: "Warranties" },
    { key: "others", label: "Others" },
  ];
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const onTabSelect = (key) => {
    setCurrentKey(key);
  };
  /* Component function definition ends */

  /* Component useEffect starts */

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="blueprints-wrapper">
      <div className="heading">
        <Link
          to="/property/blueprints/add"
          className="font_m fw_6 d_block link"
        >
          + Add New Document
        </Link>
        <h2 className="font_xxl fw_6">Blueprints</h2>
      </div>

      <Searchbar />

      {/* TAB BUTTONS */}
      <TabButton
        buttonList={buttonList}
        currentKey={currentKey}
        onSelect={onTabSelect}
      />

      {/* Display Table Data Based On The Current Tab */}

      {currentKey === "drawing" ? (
        <DrawingsPrints />
      ) : currentKey === "materials" ? (
        <MaterialPrints />
      ) : currentKey === "equipments" ? (
        <EquipmentPrints />
      ) : currentKey === "manual" ? (
        <ManualPrints />
      ) : currentKey === "warranties" ? (
        <WarrantiesPrint />
      ) : (
        <OthersPrints />
      )}
    </div>
  );
};
/* Component ends */

export default BluePrints;
