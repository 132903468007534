/**
 *
 */

import React from "react";

/* Import configuration starts */
import moment from "moment";
import { useLocation, useNavigate, useParams } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Table } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { EyeFilledBlue } from "../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../../../ui/button/IconButton/IconButton";
import { pathNamePopped } from "../../../../../setup/utils/global-helper";
/* Import local pages and component ends */

/* Component starts */
const Violations = (props) => {
  /* Props destructuring starts */
  const { violationList } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { propertyId, tenantId, unitId } = useParams();

  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const handleViewReport = (id) => {
    navigate(`${pathNamePopped(pathname, 7)}/${propertyId}/violations/${id}`, {
      state: { pathname: pathname },
    });
  };

  const handleAddViolation = () => {
    const newPathName = pathNamePopped(pathname, 6);
    navigate(`${newPathName}/violations/add`, {
      state: { pathname: pathname, fromTenant: true, tenantId, unitId },
    });
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="custom-table-wrapper violation-table">
      {violationList?.length > 0 && (
        <Table className="custom-table font_s" borderless responsive>
          {/* Table Heading */}
          <thead>
            <tr>
              <th>Date</th>
              <th>Violation Number</th>
              <th>Title</th>
              <th className="text-start">Action</th>
            </tr>
          </thead>
          {/* Table Body */}
          <tbody>
            {violationList?.map((item) => (
              <tr key={item?._id}>
                {/* Date */}
                <td>{moment(item?.createdAt).format(DATE_FORMAT)}</td>

                {/* Violation Number */}
                <td>{item?.violation_id}</td>

                {/* Title */}
                <td>{item?.title}</td>

                {/* Actions */}
                <td className="text-start txt-nowrap">
                  <IconButton
                    onClick={() => handleViewReport(item?._id)}
                    className="action-button"
                  >
                    <img
                      src={EyeFilledBlue}
                      className="h_100"
                      alt="View Details"
                    />
                  </IconButton>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      {violationList?.length === 0 && (
        <div className=" text-center my-4">
          <h3 className=" font_m font_yellow fw_6">No Data</h3>
        </div>
      )}

      {!pathname?.split("/")?.includes("previous-tenants") && (
        <p
          onClick={() => handleAddViolation()}
          className="font_m fw_6 font_blue text-center"
        >
          + Issue Violation
        </p>
      )}
    </div>
  );
};
/* Component ends */

export default Violations;
