/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { FaStar, FaStarHalfAlt } from "react-icons/fa";
import { AiOutlineStar } from "react-icons/ai";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const CustomStarRate = (props) => {
  /* Props destructuring starts */
  const { size, stars } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  const starRating = Array.from({ length: 5 }, (ele, idx) => {
    let number = idx + 0.5;

    return (
      <span key={idx}>
        {stars >= idx + 1 ? (
          <FaStar className="icon" size={size} />
        ) : stars >= number ? (
          <FaStarHalfAlt className="icon" size={size} />
        ) : (
          <AiOutlineStar className="icon" size={size + 5} />
        )}
      </span>
    );
  });

  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return <div className="rate-wrapper">{starRating}</div>;
};
/* Component ends */

export default CustomStarRate;
