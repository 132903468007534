/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useSelector, useDispatch } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  getManagers,
  getTechnicians,
  listManagers,
  listTechnicians,
} from "../../../../setup/store/slices/maintenanceSlice";
import {
  getIsFM,
  getIsTPM,
  getIsPM,
} from "../../../../setup/store/slices/authSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import DetailCard from "./sub-component/DetailCard";
import TimeLineLists from "./sub-component/TimeLineLists";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const NormalMaintenanceDetail = (props) => {
  /* Props destructuring starts */
  const {
    isT1,
    isT2,
    isT3,
    mgmtModel,
    type,
    status,
    maintenance,
    maintenance_id,
    property,
    user,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const technicians = useSelector(getTechnicians);
  const managers = useSelector(getManagers);
  const isFM = useSelector(getIsFM);
  const isTPM = useSelector(getIsTPM);
  const isPM = useSelector(getIsPM);

  console.log(managers, "[Manager]", technicians);

  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    if (maintenance) {
      if (!isT1 && (isFM || isTPM || isPM)) {
        dispatch(listManagers({ property_id: maintenance.property_id._id }));
      }
      if (!isT1 && (isFM || isTPM)) {
        dispatch(listTechnicians({ property_id: maintenance.property_id._id }));
      }
    }
  }, [maintenance]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="normal-maintenance-detail-wrapper container_sm">
      {/* Details */}
      <DetailCard
        type={type}
        isT1={isT1}
        isT2={isT2}
        isT3={isT3}
        status={status}
        mgmtModel={mgmtModel}
        maintenance={maintenance}
      />

      {/* Timeline */}
      <TimeLineLists
        isT1={isT1}
        isT2={isT2}
        isT3={isT3}
        status={status}
        mgmtModel={mgmtModel}
        maintenance={maintenance}
        maintenance_id={maintenance_id}
        logs={maintenance?.logs}
        property={property}
        user={user}
        technicians={technicians}
        managers={managers}
      />
    </div>
  );
};
/* Component ends */

export default NormalMaintenanceDetail;
