/*
 * Slice to handle TEAMS DASHBOARD Module
 */

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_URL } from "../../config/devKeys";
import { setLoading } from "./unpersistedSlice";

const initialState = {
  teamsList: [],
  propertyTeamsList: [],
  communityTeamsList: [],
  searchedTeamsList: [],
  // annoucement: null,
};

// FETCH TEAMS DASHBOARD LIST - Client Side (HOME)
export const fetchTeamsList = createAsyncThunk(
  "dashboard_home/dashboard_teams_listData",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(
        `${API_URL}/dashboard/dashboard_teams_listData`,
        {
          method: "POST",
          headers: headers,
        }
      );

      const data = await response.json();

      dispatch(setLoading(false));

      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// FETCH TEAMS DASHBOARD LIST - Client Side  (Property_ID)
export const fetchTeamsListBasedProperty = createAsyncThunk(
  "dashboard_property/dashboard_teams_listData",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(
        `${API_URL}/dashboard/dashboard_teams_listData`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            property_id: payload?.property_id,
          }),
        }
      );

      const data = await response.json();
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// FETCH TEAMS DASHBOARD LIST - Client Side  (Community_ID)
export const fetchTeamsListBasedCommunity = createAsyncThunk(
  "dashboard_community/dashboard_teams_listData",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(
        API_URL + `/dashboard/dashboard_teams_listData`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            community_id: payload?.community_id,
          }),
        }
      );

      const data = await response.json();

      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// FETCH SEARCHED TEAMS DASHBOARD LIST - Client Side (HOME)
export const fetchSearchedTeamsList = createAsyncThunk(
  "dashboard_search/dashboard_teams_listData",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(
        `${API_URL}/dashboard/dashboard_teams_listData?searchKey=${payload.searchKey}&start_date=${payload.start_date}&end_date=${payload.end_date}`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            property_id: payload?.property_id,
            community_id: payload?.community_id,
          }),
        }
      );

      const data = await response.json();

      dispatch(setLoading(false));

      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// FETCH ANNOUCEMENT DATA BASED ON ID  - (ANNOUCEMENT_ID)
// export const fetchAnnoucementById = createAsyncThunk(
//   "announcement/get_annoucement_by_id",
//   async (payload, { rejectWithValue, getState, dispatch }) => {
//     try {
//       dispatch(setLoading(true));
//       const headers = {
//         Authorization: `Bearer ${localStorage.getItem("user_token")}`,
//         "Content-Type": "application/json",
//       };

//       const response = await fetch(
//         `${API_URL}/announcement/get_annoucement_by_id?id=${payload}`,
//         {
//           method: "GET",
//           headers: headers,
//         }
//       );

//       const data = await response.json();

//       dispatch(setLoading(false));

//       if (data.success) {
//         return data.data;
//       } else {
//         return rejectWithValue(data.error.message);
//       }
//     } catch (error) {
//       dispatch(setLoading(false));
//       return rejectWithValue(error?.response);
//     }
//   }
// );

export const teamsDashboardSlice = createSlice({
  name: "teamDash",
  initialState,
  reducers: {
    clearSearchTeams: (state, action) => {
      state.teamsList = [];
    },
  },
  extraReducers: {
    [fetchTeamsList.rejected]: (state, action) => {
      state.teamsList = [];
    },
    [fetchTeamsList.fulfilled]: (state, action) => {
      state.teamsList = action.payload;
    },
    [fetchTeamsListBasedProperty.rejected]: (state, action) => {
      state.propertyTeamsList = [];
    },
    [fetchTeamsListBasedProperty.fulfilled]: (state, action) => {
      state.propertyTeamsList = action.payload;
    },
    [fetchTeamsListBasedCommunity.rejected]: (state, action) => {
      state.communityTeamsList = [];
    },
    [fetchTeamsListBasedCommunity.fulfilled]: (state, action) => {
      state.communityTeamsList = action.payload;
    },
    [fetchSearchedTeamsList.rejected]: (state, action) => {
      state.searchedTeamsList = [];
    },
    [fetchSearchedTeamsList.fulfilled]: (state, action) => {
      state.searchedTeamsList = action.payload;
    },
  },
});

// To Get The TEAMS List For Home
export const getTeamsList = (state) => state.teamDash.teamsList;

// To Get The TEAMS List For Property
export const getPropertyTeamsList = (state) => state.teamDash.propertyTeamsList;

// To Get The SECURITY List For Community
export const getCommunityTeamsList = (state) =>
  state.teamDash.communityTeamsList;

// To Get The SEARCH SECURITY List For Community
export const getSeacrhedTeamsList = (state) => state.teamDash.searchedTeamsList;

export const { clearSearchTeams } = teamsDashboardSlice.actions;

// // To Get The Annoucement Based On ID
// export const getAnnoucementById = (state) => state.annoucement.annoucement;

export default teamsDashboardSlice.reducer;
