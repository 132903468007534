export const initialValues = {
  subject: "",
  description: "",
};

export const validations = (values) => {
  const errorFields = {};

  /* Validations for Subject Starts */
  if (values.subject === "") {
    errorFields.subject = "Subject is required";
  }

  /* Validations for Subject Ends */

  /* Validations for Description Starts */
  if (values.description === "") {
    errorFields.description = "Description is required";
  }
  /* Validations for Description Ends */

  return errorFields;
};
