/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import moment from "moment";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  fetchComplaintBasedOnId,
  getSecurityComplaintData,
} from "../../setup/store/slices/securitySlice";
import { getUserTheme } from "../../setup/store/slices/authSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { EyeFilledBlue } from "../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import { pathNamePopped } from "../../setup/utils/global-helper";
import Header from "./local-components/Header";
import Report from "./local-components/Report";
import FileViewerModal from "../../components/ui/modal/FileViewerModal/FileViewerModal";
import Image from "../../components/ui/other/Image/Image";
import IconButton from "../../components/ui/button/IconButton/IconButton";
/* Import local pages and component ends */

// Styles
import "./style.scss";

/* Component starts */
const TenantSecurityDetail = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [showFileViewer, setShowFileViewer] = useState(false);
  const [file, setFile] = useState({});
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const { complainId } = useParams();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const complaint = useSelector(getSecurityComplaintData);
  const userTheme = useSelector(getUserTheme);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  const renderReport = () => {
    return (
      <div className="so-report-wrapper">
        <div>
          <p className="font_m fw_4 font_grey">
            Description :{" "}
            <span className="font_xs fw_4">
              {complaint?.report?.description}
            </span>
          </p>
        </div>
        {complaint?.report?.document?.length > 0 && (
          <>
            <div className="report-doc-wrapper">
              {complaint?.report?.document?.map((image, index) => (
                <div className="proof-img" key={index}>
                  <Image
                    key={index}
                    className="media"
                    imgClass="thumbnail"
                    src={image?.url}
                    videoThumbnail={image?.type2 === "video"}
                    alt="Attatched Media"
                    objectFit="cover"
                    onClick={() => {
                      setFile(image);
                      setShowFileViewer(true);
                    }}
                  />
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    );
  };

  // To View Voilation
  const handleView = (id) => {
    const newPathname = pathNamePopped(pathname, 2);

    navigate(`${newPathname}/violations/${id}`, {
      state: {
        pathname: pathname,
      },
    });
  };

  /* Component function definition ends */

  /* Component useEffect starts */

  useEffect(() => {
    if (complainId) {
      dispatch(fetchComplaintBasedOnId({ request_id: complainId }));
    }
  }, [complainId]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */

  return (
    <div className="wrapper-security-detail">
      <div className="header-security">
        <Header />
      </div>

      <div className="report-wrapper container_sm">
        <div className="detail-box">
          <Report incident={complaint} />
        </div>

        {/* TIME-LINE */}

        {complaint?.logs.map((timeLine, index) => (
          <div
            className={`detail-box-timeLine mt-3  ${
              index === 0 ? "bg_lightgreen" : "bg_grey"
            }`}
            key={index}
          >
            <div className="main-timeline-wrapper">
              <div className="dot">
                <div className="dot-1">
                  <span className="dot-2"></span>
                </div>
                <div className="vl"></div>
              </div>
              <div className="detail">
                <div className="data">
                  <div className="name">
                    <p className="font_m fw_4 font_grey">
                      {timeLine?.user?.role_id?.name ||
                        timeLine?.tenant?.role_id?.name}{" "}
                      <span className="font_m fw_4 ">
                        {timeLine?.user?.first_name ||
                          timeLine?.tenant?.first_name}{" "}
                        {timeLine?.user?.last_name ||
                          timeLine?.tenant?.last_name}
                      </span>
                    </p>
                  </div>
                  <div className="current-status">
                    {/* {timeLine?.comment !== undefined &&
                      timeLine?.comment !== "" && (
                        <p className="font_s fw_4 font_grey">
                          Comment :{" "}
                          <span className="font_m fw_4">
                            {timeLine?.comment}
                          </span>
                        </p>
                      )}
                    {timeLine?.action !== undefined && (
                      <p className="font_m fw_4 font_grey">
                        Action :{" "}
                        <span className="font_m fw_4">{timeLine?.action}</span>
                      </p>
                    )}
                    {timeLine?.status !== undefined && (
                      <p className="font_m fw_4">
                        {timeLine?.status.toUpperCase()}
                      </p>
                    )} */}

                    {timeLine?.comment !== undefined && (
                      <p className="font_m fw_4 font_grey">
                        Comment :{" "}
                        <span className="font_xs fw_4">
                          {timeLine?.comment}
                        </span>
                      </p>
                    )}

                    {timeLine?.action !== undefined &&
                      timeLine?.status !== "violation_issued" && (
                        <div className="currentStatus">
                          <p className="font_m fw_4 font_grey">
                            Action :{" "}
                            <span className="font_xs fw_4">
                              {timeLine?.action}
                            </span>
                          </p>

                          {timeLine?.status === "resolved" &&
                            timeLine?.violation_id && (
                              <IconButton
                                className="action-button"
                                onClick={() =>
                                  handleView(timeLine?.violation_id)
                                }
                              >
                                <img
                                  src={EyeFilledBlue}
                                  alt="Icon"
                                  className="h_100"
                                />
                              </IconButton>
                            )}
                        </div>
                      )}

                    {timeLine?.status === "violation_issued" && (
                      <p className="font_m fw_4 font_grey">
                        Status :{" "}
                        <span className="font_xs fw_4">
                          {timeLine?.status === "violation_issued" &&
                            "Violation Issued"}
                        </span>
                      </p>
                    )}

                    {timeLine?.report !== undefined && renderReport()}
                  </div>
                </div>

                <div className="date">
                  <p className="font_m fw_4 font_grey">
                    {moment(timeLine.date).format(DATE_TIME_FORMAT)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* MODALS */}

      {/* File viewer modal */}
      <FileViewerModal
        show={showFileViewer}
        type={file.type}
        file={file.url}
        onClose={() => {
          setShowFileViewer(false);
          setFile({});
        }}
      />
    </div>
  );
};
/* Component Ends */
export default TenantSecurityDetail;
