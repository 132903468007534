/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import AccordionItem from "./local-component/AccordionItem";
/* Import local pages and component ends */

// Styles
import "./style.scss";

/* Component starts */
const SparePartAccordion = (props) => {
  /* Props destructuring starts */
  const { spareQuotes, currency } = props;

  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [accordionOpened, setAccordionOpened] = useState(null);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const toggleAccordionOpened = (accordion) => {
    if (accordionOpened === accordion) {
      setAccordionOpened(null);
    } else {
      setAccordionOpened(accordion);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="">
      <div className="Accordion-wrapper">
        {spareQuotes?.map((part, idx) => (
          <div key={idx}>
            <AccordionItem
              accordionOpened={accordionOpened}
              setAccordionOpened={toggleAccordionOpened}
              currency={currency}
              part={part}
              idx={idx}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
/* Component ends */

export default SparePartAccordion;
