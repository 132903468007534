/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { formValues, validations } from "./helper";
import GLOBAL from "../../../../setup/constants/global";
import { isProperNumber } from "../../../../setup/utils/global-helper";
/* Import configuration ends */

/* Import Redux Slices Starts */
import {
  deleteEmergencyContact,
  updateEmergencyContact,
} from "../../../../setup/store/slices/emergencyContactSlice";
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../../setup/store/slices/globalAlertSlice";
import { getUserTheme } from "../../../../setup/store/slices/authSlice";
/* Import Redux Slices Ends */

/* Import react bootstrap component starts */
import { Modal } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import MUI component starts */
/* Import MUI component ends */

/* Import image and SVG starts */
import {
  EditGreen,
  DeleteRed,
  XGreen,
} from "../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
import IconButton from "../../../ui/button/IconButton/IconButton";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const EmergencyContactsList = (props) => {
  /* Props destructuring starts */
  const {
    showModal,
    size = "lg",
    onClose,
    data,
    property_id,
    settings,
    getPropertySettingsDetail,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [edit, setEdit] = useState(null);
  const [contact, setContact] = useState("");
  const [dataChanged, setDataChanged] = useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const userTheme = useSelector(getUserTheme);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // On changing value
  const handleChange = (e) => {
    const value = e.target.value;
    if (isProperNumber(value)) {
      setContact(value);

      if (edit !== null && data[parseInt(edit)]?.contact !== parseInt(value)) {
        setDataChanged(true);
      } else {
        setDataChanged(false);
      }
    }
  };

  // On Editing
  const onEdit = (index, item) => {
    setEdit(index);
    setContact(item?.contact);
  };

  // On cancelling editing mode
  const onCancel = () => {
    setEdit(null);
    setContact("");
    setDataChanged(false);
  };

  // On deleting contact
  const onDelete = async (item) => {
    // Creating or collecting payload data to be sent
    const payload = {
      _id: item?._id,
      property_id: property_id,
    };

    // Backend response. Try, Catch
    try {
      const result = await dispatch(deleteEmergencyContact(payload));

      onClose();

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          onClose();
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          getPropertySettingsDetail();
          // onClose();
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // On updating emergency contact
  const onSubmit = async () => {
    // Creating or collecting payload data to be sent
    const payload = {
      _id: data[parseInt(edit)]?._id,
      department: data[parseInt(edit)]?.department,
      contact: contact,
    };

    // Backend response. Try, Catch
    try {
      const result = await dispatch(updateEmergencyContact(payload));

      //   Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          onClose();
          break;
        case GLOBAL.FULFILLED:
          setDataChanged(false);
          setEdit(null);
          setContact("");
          getPropertySettingsDetail();
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {}, [settings, property_id]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <Modal
      size={size}
      centered
      show={showModal}
      className="emergency-contract-list-modal-main-wrapper"
    >
      <Modal.Body
        className={`${userTheme === "dark" ? "bg_darkTheme" : "bg_lightTheme"}`}
      >
        <div className="modal-header-custom">
          <h2 className="text-center font_grey font_xl fw_6">
            Emergency Contact
          </h2>
        </div>
        <div className="modal-body-custom">
          <div className="contract-list">
            <div className="header">
              <div
                className={`r ${
                  userTheme === "dark" ? "bg_black" : "bg_lightgrey"
                } font_s fw_5`}
              >
                <p className="c lg">Deparment</p>
                <p className="c lg">Number</p>
                <p className="c sm">Actions</p>
              </div>
            </div>
            {data?.length === 0 && (
              <p className="empty fw_6 font_yellow font_m text-center">
                No Emergency Contacts Added Yet
              </p>
            )}
            {data?.map((item, index) => (
              <div key={index} className="r">
                <p className="c lg font_s font_grey fw_5">{item?.department}</p>
                <p className="c lg">
                  {edit === index ? (
                    <input
                      className={`bg_black contact-inp transparent-input editing font_s font_grey fw_5`}
                      type="text"
                      onChange={handleChange}
                      value={contact}
                    />
                  ) : (
                    <span className="font_s font_grey fw_5">
                      {item?.contact}
                    </span>
                  )}
                </p>
                <div className="actions sm">
                  {edit !== null && edit === index && (
                    <IconButton
                      onClick={() => onCancel(index, item)}
                      className="icon"
                    >
                      <img src={XGreen} alt="Edit" />
                    </IconButton>
                  )}

                  {edit === null && (
                    <>
                      <IconButton
                        onClick={() => onEdit(index, item)}
                        className="icon"
                      >
                        <img src={EditGreen} alt="Edit" />
                      </IconButton>

                      <IconButton
                        onClick={() => onDelete(item)}
                        className="icon"
                      >
                        <img src={DeleteRed} alt="Edit" />
                      </IconButton>
                    </>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="modal-footer-custom">
          <div className="button-wrapper text-center">
            <CustomButton
              variant={
                edit !== null && dataChanged
                  ? //&& userTheme === "light"
                    "primary"
                  : "primary"
              }
              onClick={() => edit !== null && dataChanged && onSubmit()}
              size="m"
            >
              Update
            </CustomButton>
            <CustomButton size="m" variant="red" onClick={onClose}>
              Close
            </CustomButton>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
/* Component ends */

export default EmergencyContactsList;
