/**
 *
 */

import React from "react";

/* Import configuration starts */

import { useSelector } from "react-redux";
import moment from "moment/moment";

/* Import configuration ends */

/* Import redux slices component starts */
import { getUserTheme } from "../../../../../setup/store/slices/authSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Modal } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { XGreen } from "../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../../../ui/button/IconButton/IconButton";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const RentHistoryDetailModal = (props) => {
  /* Props destructuring starts */
  const { showModal, detail, size = "lg", onClose } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */

  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */

  const userTheme = useSelector(getUserTheme);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const property_name = detail?.property_name;
  const unit = detail?.unit;
  const unitType = detail?.type;
  const tenancyType = detail?.tenancy_type;
  const contact = `${detail?.countrycode}${" "}${detail?.contact_number}`;
  const address = detail?.address;
  const email = detail?.owner_email;
  const StartDate = detail?.start_date;
  const EndDate = detail?.end_date;

  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  /* Component function definition ends */

  /* Component useEffect starts */

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */

  return (
    <Modal
      size={size}
      centered
      show={showModal}
      className="contract-detail-modal-wrapper"
    >
      <Modal.Body
        className={`custom-modal-body ${
          userTheme === "dark" ? "bg_darkTheme" : "bg_lightTheme"
        }`}
      >
        {/* Header */}
        <div className="close-button-wrapper">
          <IconButton onClick={onClose} className="close-button">
            <img className="h_100" src={XGreen} alt="Close" />
          </IconButton>

          <h2 className="text-center font_xl fw_5 font_yellow">
            Tenant Rental History
          </h2>
        </div>

        {/* Body */}
        <div className="details-content">
          <div className="right">
            <div
              //className="wrapper bg_grey"
              className={`wrapper ${
                userTheme === "dark" ? "bg_grey" : "bg_lightThemeDetail"
              }`}
            >
              {/* Property Name */}

              {property_name && (
                <div className="custom_row">
                  <p className="key font_grey font_s">Property Name</p>
                  <p
                    className={`val font_s ${
                      (userTheme === "light" || userTheme === "automatic") &&
                      "font_lightTheme"
                    } `}
                  >
                    {property_name}
                  </p>
                </div>
              )}

              {/* Unit Name */}

              {unit && (
                <div className="custom_row">
                  <p className="key font_grey font_s">Unit </p>
                  <p
                    className={`val font_s ${
                      (userTheme === "light" || userTheme === "automatic") &&
                      "font_lightTheme"
                    } `}
                  >
                    {detail?.unit}
                  </p>
                </div>
              )}

              {/* Unit Type */}

              {unitType && (
                <div className="custom_row">
                  <p className="key font_grey font_s">Unit Type</p>
                  <p
                    className={`val font_s ${
                      (userTheme === "light" || userTheme === "automatic") &&
                      "font_lightTheme"
                    } `}
                  >
                    {unitType}
                  </p>
                </div>
              )}

              {/* Tenancy Type */}

              {tenancyType && (
                <div className="custom_row">
                  <p className="key font_grey font_s">Tenancy Type</p>
                  <p
                    className={`val font_s ${
                      (userTheme === "light" || userTheme === "automatic") &&
                      "font_lightTheme"
                    } `}
                  >
                    {tenancyType?.charAt(0)?.toUpperCase() +
                      tenancyType?.slice(1)}
                  </p>
                </div>
              )}

              {/* Address */}

              {address && (
                <div className="custom_row">
                  <p className="key font_grey font_s">Address</p>
                  <p
                    className={`val font_s ${
                      (userTheme === "light" || userTheme === "automatic") &&
                      "font_lightTheme"
                    } `}
                  >
                    {address}
                  </p>
                </div>
              )}

              {/* Contact Number */}

              {contact && (
                <div className="custom_row">
                  <p className="key font_grey font_s">Contact Number</p>
                  <p
                    className={`val font_s ${
                      (userTheme === "light" || userTheme === "automatic") &&
                      "font_lightTheme"
                    } `}
                  >
                    {contact ? contact : "-"}
                  </p>
                </div>
              )}

              {/* Email */}

              {email && (
                <div className="custom_row">
                  <p className="key font_grey font_s">Email</p>
                  <p
                    className={`val font_s ${
                      (userTheme === "light" || userTheme === "automatic") &&
                      "font_lightTheme"
                    } `}
                  >
                    {email}
                  </p>
                </div>
              )}

              {/* Start Date */}
              {StartDate && (
                <div className="custom_row">
                  <p className="key font_grey font_s">Start Date</p>
                  <p
                    className={`val font_s ${
                      (userTheme === "light" || userTheme === "automatic") &&
                      "font_lightTheme"
                    } `}
                  >
                    {moment(StartDate).format(DATE_FORMAT)}
                  </p>
                </div>
              )}

              {/*  End Date */}
              {EndDate && (
                <div className="custom_row">
                  <p className="key font_grey font_s">End Date</p>
                  <p
                    className={`val font_s ${
                      (userTheme === "light" || userTheme === "automatic") &&
                      "font_lightTheme"
                    } `}
                  >
                    {moment(EndDate).format(DATE_FORMAT)}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
/* Component ends */

export default RentHistoryDetailModal;
