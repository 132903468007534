/**
 *
 */

import React, { useState } from "react";

/* Import configuration starts */
import { useDispatch } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import { deleteCard } from "../../../../setup/store/slices/paymentSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import MUI component starts */
/* Import MUI component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import PaymentCard from "../PaymentCard/PaymentCard";
import ConfirmationModal from "../../../ui/modal/ConfirmationModal/ConfirmationModal";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const CardList = (props) => {
  /* Props destructuring starts */
  const { cards = [], selectedCard, setSelectedCard, size } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // On Delete Card
  const onDelete = (cardInfo) => {
    setSelectedCard(cardInfo);
    setShowDeleteModal(true);
  };

  // On confirmed delete card
  const onConfirmDelete = async () => {
    await dispatch(deleteCard({ id: selectedCard.id }));
    setShowDeleteModal(false);
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div
      className={`card-list-wrapper-main w_100 ${
        size === "l" ? "" : "form-wrapper form-wrapper-bottom"
      }`}
    >
      {cards.length === 0 && (
        <h3 className="font_m text-center w_100 font_yellow fw_6">
          No Card Added Yet!
        </h3>
      )}

      {/* Card list */}
      {cards.map((item, index) => (
        <PaymentCard
          size={size}
          cardInfo={item}
          selected={selectedCard}
          setSelected={setSelectedCard}
          key={index}
          onDelete={onDelete}
        />
      ))}

      {/* Delete confirmation modal */}
      <ConfirmationModal
        onConfirm={onConfirmDelete}
        onClose={() => {
          setShowDeleteModal(false);
        }}
        showModal={showDeleteModal}
        title="Delete Card"
        description="Are you sure you want to delete this card ?"
      />
    </div>
  );
};
/* Component ends */

export default CardList;
