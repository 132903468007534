/**
 *
 */

import React, { useState, useEffect, useRef } from "react";

/* Import configuration starts */
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import { initialValues, loadInitialValue } from "./helper";
import GLOBAL from "../../../../setup/constants/global";
import { useParams } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  fetchCommunityDetailByID,
  getCommunityDetail,
} from "../../../../setup/store/slices/communitySlice";
import {
  clearCountries,
  fetchCitiesByStateId,
  fetchCountries,
  fetchStatesByCountryId,
  getCities,
  getCountries,
  getStates,
} from "../../../../setup/store/slices/countrySlice";
import { updateCommunityWithImage } from "../../../../setup/store/slices/communitySlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */

import CustomTextInput from "../../../ui/input/CustomTextInput/CustomTextInput";
import CustomSelectInput from "../../../ui/input/CustomSelectInput/CustomSelectInput";
import CommunityImagesStripe from "../CommunityImagesStripe/CommunityImagesStripe";

import TextButton from "../../../ui/button/TextButton/TextButton";
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
import SuccessModal from "../../../ui/modal/SuccessModal/SuccessModal";
import ErrorModal from "../../../ui/modal/ErrorModal/ErrorModal";
import ConfirmationModal from "../../../ui/modal/ConfirmationModal/ConfirmationModal";
/* Import local pages and component ends */

import "./style.scss";
import CustomFileInput from "../../../ui/input/CustomFileInput/CustomFileInput";
import CustomTextArea from "../../../ui/input/CustomTextArea/CustomTextArea";

/* Component starts */
const EditCommunity = (props) => {
  /* Props destructuring starts */
  const { onSuccess, onCancel } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [initialFormValues, setInitialFormValues] = useState(initialValues);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [modalDesc, setModalDesc] = useState("");

  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const { communityId } = useParams();

  // Redux Selector
  const community = useSelector(getCommunityDetail);
  const countries = useSelector(getCountries);
  const states = useSelector(getStates);
  const cities = useSelector(getCities);
  /* Other hooks declaration ends */

  // useRef
  const community_title_deed = useRef(null);
  const affection_plan = useRef(null);

  /* Component variable declaration / object destructure starts */
  // Form validations
  const formValidation = () => {};

  // On submitting form
  const onSubmit = async (values) => {
    // Collecting or creating payload data to be sent
    const payload = {
      id: community?._id,
      community_name: values.community_name,
      address_line1: values.address_line1,
      address_line2: values.address_line2,
      description: values.description,
      community_permit: values.community_permit,
      plot_number: values.plot_number,
      electricity_id: values.electricity_id,
      telecommunication: values.telecommunication,
      sewerage_id: values.sewerage_id,
      water_id: values.water_id,
      gas_id: values.gas_id,
      colling_id: values.colling_id,
      heating_id: values.heating_id,
      city: selectedCity?.name || "",
      state: selectedState?.name || "",
      country: selectedCountry?.name || "",
    };

    if (community_title_deed?.current && !community_title_deed?.current?.url) {
      payload["community_title_deed"] = community_title_deed.current;
    }

    if (affection_plan?.current && !affection_plan?.current?.url) {
      payload["affection_plan"] = affection_plan.current;
    }

    // Backend response. Try, Catch
    try {
      const result = await dispatch(updateCommunityWithImage(payload));
      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setModalDesc(result.payload);
          setShowErrorModal(true);
          break;
        case GLOBAL.FULFILLED:
          setModalDesc("Community has been updated successfully");
          setShowSuccessModal(true);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // Loading all countries
  const loadCountries = async () => {
    await dispatch(fetchCountries());
  };

  //Loading all states
  const loadStates = async () => {
    if (selectedCountry !== null) {
      setSelectedState(null);
      setSelectedCity(null);
      await dispatch(
        fetchStatesByCountryId({ country_id: selectedCountry.id })
      );
    }
  };

  // Loading all cities
  const loadCities = async () => {
    if (selectedState != null) {
      setSelectedCity(null);
      await dispatch(fetchCitiesByStateId({ state_id: selectedState.id }));
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  // Loading all countries and fetching user roles initially
  useEffect(() => {
    loadCountries();
  }, []);

  // Setting selected country after loading countries
  useEffect(() => {
    if (countries.length > 0 && community) {
      let defaultCountry;

      defaultCountry = countries.filter(
        (country) => country.name === community?.country
      )[0];

      setSelectedCountry(defaultCountry);
    }
  }, [countries, community]);

  // Loading states accoring to country selected
  useEffect(() => {
    loadStates();
  }, [selectedCountry]);

  // Setting selected state after loading state
  useEffect(() => {
    if (states.length > 0 && community) {
      let defaultState = states.filter(
        (state) => state.name === community?.state
      )[0];

      setSelectedState(defaultState || states[0]);
    }
  }, [states, community]);

  // Loading cities accoring to state selected
  useEffect(() => {
    loadCities();
  }, [selectedState]);

  // Setting selected city after loading cities
  useEffect(() => {
    if (cities.length > 0 && community) {
      let defaultCity = cities.filter(
        (city) => city.name === community?.city
      )[0];

      setSelectedCity(defaultCity || cities[0]);
    }
  }, [cities, community]);

  // Setting form initial value according to current seleted community
  useEffect(() => {
    if (community) {
      setInitialFormValues(loadInitialValue(community));
    }
  }, [community]);

  useEffect(() => {
    dispatch(fetchCommunityDetailByID({ community_id: communityId }));
  }, []);

  // Setting form initial value according to current seleted property
  useEffect(() => {
    if (community) {
      setInitialFormValues(loadInitialValue(community));
    }
    affection_plan.current = community?.affection_plan || null;
    community_title_deed.current = community?.community_title_deed || null;
  }, [community]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="edit-community-module-wrapper">
      <h2 className="heading text-center font_xxl mb-5">Edit Community</h2>

      {/* Edit Community Form */}
      <Formik
        onSubmit={onSubmit}
        initialValues={initialFormValues}
        validate={formValidation}
        validateOnBlur={true}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="form-wrapper">
              {/* Community Name */}
              <div className="form-group">
                <CustomTextInput
                  name="community_name"
                  label="Community Name"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values.community_name}
                  errors={errors.community_name}
                  touched={touched.community_name}
                />
              </div>

              {/* Address Line 1 */}
              <div className="form-group">
                <CustomTextInput
                  name="address_line1"
                  label="Address"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values.address_line1}
                  errors={errors.address_line1}
                  touched={touched.address_line1}
                />
              </div>

              {/* Address Line 2 */}
              <div className="form-group">
                <CustomTextInput
                  name="address_line2"
                  label="Address Line 2"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values.address_line2}
                  errors={errors.address_line2}
                  touched={touched.address_line2}
                  required={false}
                />
              </div>

              {/* Select field for Cities */}
              <div className="form-group">
                <CustomSelectInput
                  name="city"
                  label="City"
                  handleBlur={handleBlur}
                  onChange={handleChange}
                  valueExtractor={(item) => item?.name}
                  setValue={setSelectedCity}
                  options={cities}
                  value={selectedCity}
                  formikState={false}
                  emptyOption={false}
                />
              </div>

              {/* Select field for States */}
              <div className="form-group">
                <CustomSelectInput
                  name="state"
                  label="State"
                  handleBlur={handleBlur}
                  onChange={handleChange}
                  valueExtractor={(item) => item?.name}
                  setValue={setSelectedState}
                  options={states}
                  value={selectedState}
                  formikState={false}
                  emptyOption={false}
                />
              </div>

              {/* Select field for Countries */}
              <div className="form-group">
                <CustomSelectInput
                  name="country"
                  label="Country"
                  handleBlur={handleBlur}
                  onChange={handleChange}
                  valueExtractor={(item) => item?.name}
                  setValue={setSelectedCountry}
                  options={countries}
                  value={selectedCountry}
                  formikState={false}
                  emptyOption={false}
                />
              </div>

              {/* OTHER DETAILS OF COMMUNITY */}

              {/* Description */}
              <div className="form-group form-group-full">
                <CustomTextArea
                  name="description"
                  label="Description"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values.description}
                  errors={errors.description}
                  touched={touched.description}
                  required={false}
                />
              </div>

              {/* Community Title Deed */}
              <div className="form-group">
                <CustomFileInput
                  name="community_title_deed"
                  label="Community Title Deed"
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  files={community_title_deed}
                  errors={errors.community_title_deed}
                  touched={touched.community_title_deed}
                  required={false}
                  formikState={false}
                />
              </div>

              {/* Community Permit */}
              <div className="form-group">
                <CustomTextInput
                  name="community_permit"
                  label="Community Permit"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values.community_permit}
                  errors={errors.community_permit}
                  touched={touched.community_permit}
                  required={false}
                />
              </div>

              {/* Affection Plan */}
              <div className="form-group">
                <CustomFileInput
                  name="affection_plan"
                  label="Affection Plan"
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  files={affection_plan}
                  errors={errors.affection_plan}
                  touched={touched.affection_plan}
                  required={false}
                  formikState={false}
                />
              </div>

              {/* Telecommunication ID */}
              <div className="form-group">
                <CustomTextInput
                  name="telecommunication"
                  label="Telecommunication ID"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values.telecommunication}
                  errors={errors.telecommunication}
                  touched={touched.telecommunication}
                  required={false}
                />
              </div>

              {/* Plot Number */}
              <div className="form-group">
                <CustomTextInput
                  name="plot_number"
                  label="Plot Number"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values.plot_number}
                  errors={errors.plot_number}
                  touched={touched.plot_number}
                  required={false}
                />
              </div>

              {/* Electricity ID */}
              <div className="form-group">
                <CustomTextInput
                  name="electricity_id"
                  label="Electricity ID"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values.electricity_id}
                  errors={errors.electricity_id}
                  touched={touched.electricity_id}
                  required={false}
                />
              </div>

              {/* Sewerage ID */}
              <div className="form-group">
                <CustomTextInput
                  name="sewerage_id"
                  label="Sewerage ID"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values.sewerage_id}
                  errors={errors.sewerage_id}
                  touched={touched.sewerage_id}
                  required={false}
                />
              </div>

              {/* Water ID */}
              <div className="form-group">
                <CustomTextInput
                  name="water_id"
                  label="Water ID"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values.water_id}
                  errors={errors.water_id}
                  touched={touched.water_id}
                  required={false}
                />
              </div>

              {/* Gas ID */}
              <div className="form-group">
                <CustomTextInput
                  name="gas_id"
                  label="Gas ID"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values.gas_id}
                  errors={errors.gas_id}
                  touched={touched.gas_id}
                  required={false}
                />
              </div>

              {/* Cooling ID */}
              <div className="form-group">
                <CustomTextInput
                  name="colling_id"
                  label="Cooling ID"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values.colling_id}
                  errors={errors.colling_id}
                  touched={touched.colling_id}
                  required={false}
                />
              </div>

              {/* Heating ID */}
              <div className="form-group">
                <CustomTextInput
                  name="heating_id"
                  label="Heating ID"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values.heating_id}
                  errors={errors.heating_id}
                  touched={touched.heating_id}
                  required={false}
                />
              </div>

              <CommunityImagesStripe community={community} />
            </div>

            <div className="button-wrapper text-center">
              <CustomButton type="submit" size="l">
                Update
              </CustomButton>
              <TextButton onClick={onCancel} className="font_m fw_6">
                Cancel
              </TextButton>
            </div>
          </form>
        )}
      </Formik>

      {/* Modals */}
      {/* Confirmation Modal */}
      <ConfirmationModal
        showModal={showConfirmationModal}
        description={modalDesc}
        title="Confirm Delete"
        onClose={() => setShowConfirmationModal(false)}
      />

      {/* Success Modal */}
      <SuccessModal
        showModal={showSuccessModal}
        description={modalDesc}
        title="Successfully Updated"
        onClose={() => {
          onSuccess();
          setShowSuccessModal(false);
        }}
      />

      {/* Error Modal */}
      <ErrorModal
        showModal={showErrorModal}
        description={modalDesc}
        title="Error"
        onClose={() => setShowErrorModal(false)}
      />
    </div>
  );
};
/* Component ends */

export default EditCommunity;
