/**
 * This component is for custom password input field
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import {
  validatePassword,
  validateAtleastOneLowercase,
  validateAtleastOneUppercase,
  validateAtleastOneSpecialChar,
  validateAtleastOneNumber,
} from "../../../../setup/utils/validations";
/* Import configuration ends */

/* Component starts */
const CustomPasswordInput = (props) => {
  /* Props destructuring starts */
  const {
    name,
    type = "password",
    showPasswordValidationErr = true,
    required = true,
    handleChange,
    handleBlur,
    value,
    errors,
    touched,
    label,
    autoFill = "false",
    formikPasswordValidation = null, // useRef from parent component
  } = props;
  /* Props destructuring ends */

  /* Component states declaration starts */
  const [oneLowercaseChar, setOneLowercaseChar] = useState(false);
  const [oneUppercaseChar, setOneUppercaseChar] = useState(false);
  const [oneNumber, setOneNumber] = useState(false);
  const [oneSpecialChar, setOneSpecialChar] = useState(false);
  const [eightCharLong, setEightCharLong] = useState(false);
  /* Component states declaration ends */

  /* Component function definition starts */
  const validations = (password) => {
    // At least 1 lowercase character
    if (validateAtleastOneLowercase(password)) {
      setOneLowercaseChar(true);
    } else {
      setOneLowercaseChar(false);
      if (formikPasswordValidation !== null)
        formikPasswordValidation.current = true;
    }

    // At least 1 uppercase character
    if (validateAtleastOneUppercase(password)) {
      setOneUppercaseChar(true);
    } else {
      setOneUppercaseChar(false);
      if (formikPasswordValidation !== null)
        formikPasswordValidation.current = true;
    }

    // At least 1 number
    if (validateAtleastOneNumber(password)) {
      setOneNumber(true);
    } else {
      setOneNumber(false);
      if (formikPasswordValidation !== null)
        formikPasswordValidation.current = true;
    }

    // At least 1 special character
    if (validateAtleastOneSpecialChar(password)) {
      setOneSpecialChar(true);
    } else {
      setOneSpecialChar(false);
      if (formikPasswordValidation !== null)
        formikPasswordValidation.current = true;
    }

    // exact 8 characters long
    if (password.length >= 8) {
      setEightCharLong(true);
    } else {
      setEightCharLong(false);
      if (formikPasswordValidation !== null)
        formikPasswordValidation.current = true;
    }
  };

  const onChange = (e) => {
    const password = e.target.value;

    // password regex to check
    // if (!validatePassword(password) || password.length === 8) {
    // setOneLowercaseChar(validateAtleastOneLowercase(password)); // At least 1 lowercase character
    // setOneUppercaseChar(validateAtleastOneUppercase(password)); // At least 1 uppercase character
    // setOneNumber(validateAtleastOneNumber(password)); // At least 1 number
    // setOneSpecialChar(validateAtleastOneSpecialChar(password)); // At least 1 special character
    // setEightCharLong(password.length >= 8); // exact 8 characters long
    // }

    if (formikPasswordValidation !== null)
      formikPasswordValidation.current = false;

    if (showPasswordValidationErr) {
      validations(password);
    }

    handleChange(e);
  };

  useEffect(() => {}, [value]);
  /* Component function definition ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="custom-form-control">
      <div className="form-input-wrapper">
        <label className="font_s font_grey">
          {label}
          {required && <span className="font_m font_red"> *</span>}
        </label>
        <input
          type={type}
          name={name}
          onChange={onChange}
          onBlur={handleBlur}
          value={value}
          autoComplete={autoFill}
          className="bg_black font_xs"
        />

        {/* Touched and required validations error */}
        {touched && errors && (
          <p className="error font_xxs font_red"> {errors}</p>
        )}

        {/* Other password validation errors */}
        {showPasswordValidationErr && value && touched && (
          <p className="error font_xxs font_red">
            {!oneLowercaseChar && "One lowercase letter"}{" "}
          </p>
        )}
        {showPasswordValidationErr && value && touched && (
          <p className="error font_xxs font_red">
            {!oneUppercaseChar && "One uppercase letter"}
          </p>
        )}
        {showPasswordValidationErr && value && touched && (
          <p className="error font_xxs font_red">
            {" "}
            {!oneNumber && "One number"}{" "}
          </p>
        )}
        {showPasswordValidationErr && value && touched && (
          <p className="error font_xxs font_red">
            {" "}
            {!oneSpecialChar && "One special character"}{" "}
          </p>
        )}
        {showPasswordValidationErr && value && touched && (
          <p className="error font_xxs font_red">
            {" "}
            {!eightCharLong && "8 Characters long"}{" "}
          </p>
        )}
      </div>
    </div>
  );
};
/* Component ends */

export default CustomPasswordInput;
