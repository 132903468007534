/**
 *
 */

import React, { useState } from "react";

/* Import configuration starts */
import moment from "moment";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { VisitorPass } from "../../../setup/constants/images";

/* Import image and SVG ends */

/* Import local pages and component starts */
import GLOBAL from "../../../setup/constants/global";
import FileViewerModal from "../../../components/ui/modal/FileViewerModal/FileViewerModal";
/* Import local pages and component ends */

/* Component starts */
const Report = (props) => {
  /* Props destructuring starts */
  const { incident } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [showFileViewer, setShowFileViewer] = useState(false);
  const [file, setFile] = useState({});
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="visitor-unit-detail-box">
      <div className="log-header">
        <div className="img-wrapper">
          <img src={incident?.upload_photo || VisitorPass} alt="Visitor-Pass" />
        </div>

        <div className="detail">
          <div className="detail-report">
            <h3 className="num font_m fw_4 font_grey">
              {" "}
              Ticket No. :-{" "}
              <span className="font_blue fw_6">
                {incident?.logbook_id}
              </span>{" "}
            </h3>
            <h4 className="fw_6 font_l">
              <span>{incident?.visitor_name}</span>
            </h4>
          </div>
        </div>
      </div>

      <hr />

      <div className="visitor-info">
        <div>
          <p className="fw_4 font_m font_grey">
            Mobile Number <br />
            <span className="fw_4 font_m">{incident?.contact_number}</span>
          </p>
        </div>

        <div>
          <p className="fw_4 font_m font_grey">
            Company
            <br />
            <span className="fw_4 font_m">{incident?.company || "-"}</span>
          </p>
        </div>

        <div>
          <p className="fw_4 font_m font_grey">
            Unit
            <br />
            <span className="fw_6 font_m font_blue">
              {incident?.unit_no?.unit_name}
            </span>
          </p>
        </div>
      </div>

      {incident?.car_data !== undefined && incident?.car_data !== null ? (
        <>
          <hr />
          <p className="font_m font_grey fw_4">Visitor's car details</p>
          <div className="visitor-info">
            <div>
              <p className="fw_4 font_m font_grey">
                Make <br />
                <span className="fw_4 font_m">
                  {incident?.car_data?.car_make}
                  {/* BMW */}
                </span>
              </p>
            </div>

            <div>
              <p className="fw_4 font_m font_grey">
                Model
                <br />
                <span className="fw_4 font_m">
                  {incident?.car_data?.car_model}
                  {/* X7 */}
                </span>
              </p>
            </div>

            <div>
              <p className="fw_4 font_m font_grey">
                Color
                <br />
                <span className="fw_4 font_m">
                  {incident?.car_data?.car_color}
                </span>
              </p>
            </div>

            <div>
              <p className="fw_4 font_m font_grey">
                Plate Number <br />
                <span className="fw_6 font_m font_blue">
                  {incident?.car_data?.car_plate}
                  {/* USA-1425 */}
                </span>
              </p>
            </div>

            <div>
              <p className="fw_4 font_m font_grey">
                Plate Issued From
                <br />
                <span className="fw_4 font_m">
                  {incident?.car_data?.car_plate_issued}
                  {/* USA */}
                </span>
              </p>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      <hr />

      <div className="time-info">
        <div>
          <p className="fw_4 font_m font_grey">
            Reason for access
            <br />
            <span className="fw_4 font_m">{incident?.reason}</span>
          </p>
        </div>

        {incident?.notes !== undefined && incident?.notes !== "" && (
          <div className="notes">
            <p className="fw_4 font_m font_grey">
              Notes <br />
              <span className="fw_4 font_m">{incident?.notes}</span>
            </p>
          </div>
        )}
      </div>

      <hr />

      <div className="id-proof">
        <p className="fw_3 font_m font_grey">Id Copy</p>

        <div className="proofs">
          {incident?.doc_files?.map((image, index) => (
            <div className="proof-img" key={index}>
              <img
                src={image.url}
                onClick={() => {
                  setFile(image);
                  setShowFileViewer(true);
                }}
                alt="ID-Proof"
              />
            </div>
          ))}
        </div>
      </div>

      <hr />

      <div className="time-info">
        <div>
          <p className="fw_4 font_m font_grey">
            Time-In
            <br />
            <span className="fw_4 font_m ">
              {incident?.time_in === undefined
                ? "-"
                : moment(incident?.time_in).format(DATE_TIME_FORMAT)}
            </span>
          </p>
        </div>
        <div>
          <p className="fw_4 font_m font_grey">
            Time-Out
            <br />
            <span className="fw_4 font_m">
              {incident?.time_out === undefined
                ? "-"
                : moment(incident?.time_out).format(DATE_TIME_FORMAT)}
            </span>
          </p>
        </div>
      </div>

      {/* Modal */}
      {/* File viewer modal */}
      <FileViewerModal
        show={showFileViewer}
        type={file.type}
        file={file.url}
        onClose={() => {
          setShowFileViewer(false);
          setFile({});
        }}
      />
    </div>
  );
};
/* Component Ends */
export default Report;
