/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useDispatch } from "react-redux";
import GLOBAL from "../../../../../setup/constants/global";
/* Import configuration ends */

/* Import redux slices component starts */
import { updatePropertySettings } from "../../../../../setup/store/slices/propertySlice";
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../../../setup/store/slices/globalAlertSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomRadioInput from "../../../../ui/input/CustomRadioInput/CustomRadioInput";
import CustomButton from "../../../../ui/button/CustomButton/CustomButton";
import CustomNumberInput from "../../../../ui/input/CustomNumberInput/CustomNumberInput";
/* Import local pages and component ends */

/* Component starts */
const CollectVehicleDetails = (props) => {
  /* Props destructuring starts */
  const { user, settings, property_id } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [collect, setCollect] = useState(settings?.collect_vehicle_details);
  const [count, setCount] = useState(settings?.vehicle_limit?.toString());
  const [settingChanged, setSettingChanged] = useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // Validations
  const validations = () => {
    if (collect && isNaN(parseInt(count))) {
      dispatch(
        showErrorAlert({
          title: "Error!",
          description: "Please enter a valid number.",
        })
      );
      return true;
    }
    if (collect && parseInt(count) < 1) {
      dispatch(
        showErrorAlert({
          title: "Error!",
          description: "Minimum 1 is required.",
        })
      );
      return true;
    }
    return false;
  };

  // On updating setting
  const onUpdate = async () => {
    if (validations()) return;

    // Creating or collecting payload data to be sent
    const payload = {
      vehicle_limit: count,
      collect_vehicle_details: collect,
      property_id,
    };

    // Backend response. Try, Catch
    try {
      const result = await dispatch(updatePropertySettings(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          setSettingChanged(false);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  // On initial load or when all the settings is loaded
  useEffect(() => {
    if (settings) {
      setCollect(settings?.collect_vehicle_details);
      setCount(settings?.vehicle_limit?.toString());
    }
  }, [settings]);

  // If any setting is changed
  useEffect(() => {
    setSettingChanged(false);
    if (settings?.collect_vehicle_details !== collect) {
      setSettingChanged(true);
    }
    if (settings?.vehicle_limit?.toString() !== count?.toString()) {
      setSettingChanged(true);
    }
  }, [collect, count, settings]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="tenant-vehicle-details option">
      {/* Label */}
      <div className="label">
        <p className="font_m fw_5">Tenant Vehicle Details</p>
      </div>

      {/* Setting */}
      <div className="setting">
        <div className="row1">
          {/* Collect Tenant Vehicle Details */}
          <div className="custom-col col1">
            <p className="font_s fw_5 font_grey accept-fee mb_1">
              Collect tenant vehicle details ?
            </p>
            <div className="radio-wrapper">
              <CustomRadioInput
                label="Yes"
                className="radio-input"
                isSelected={collect}
                onSelecting={() => setCollect(true)}
              />
              <CustomRadioInput
                label="No"
                className="radio-input"
                isSelected={!collect}
                onSelecting={() => {
                  setCollect(false);
                  setCount(1);
                }}
              />
            </div>
          </div>

          {/* Tenant Vehicle Count */}
          {collect && (
            <div className="custom-col col2 mt_3-900">
              <p className="fw_5 font_grey font_s mb_1">
                Maximum allowed vehicles
              </p>
              <CustomNumberInput
                variant="2"
                value={count}
                setValue={setCount}
                inputClass="setting-input font_xs sm text-center"
              />
            </div>
          )}
        </div>

        {settingChanged && (
          <div className="save-btn-wrapper mt_2">
            <CustomButton onClick={onUpdate} className="save-btn" size="s">
              Save
            </CustomButton>
          </div>
        )}
      </div>
    </div>
  );
};
/* Component ends */

export default CollectVehicleDetails;
