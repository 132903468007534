/*
 * Slice to handle SECURITY
 */

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { axiosInstance } from "../axios";
import { API_URL } from "../../config/devKeys";
import {
  setLoading,
  setShowUploadProgress,
  setUploadProgress,
} from "./unpersistedSlice";
import { PLATFORM } from "../../platformUtil";

const initialState = {
  complainTypes: [],
  secuirtyComplains: [],
  securityResolvedComplains: [],
  secuirtyIncidents: [],
  tenantsList: [],
  companyList: [],
  teamMembersList: [],
  securityOfficersList: [],
  tenantSecuirtyComplains: [],
  securityComplaint: null,
  securityIncident: null,
};

// FETCH Security Complains Type -> Select Options For Tenant's
export const fetchComplainType = createAsyncThunk(
  "security_complain_type/list",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await axios.get(
        API_URL + "/security_complain_type/list",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        return data.data;
      } else {
        rejectWithValue(data.error);
      }
    } catch (error) {
      return rejectWithValue(error?.response);
    }
  }
);

// FETCH SECURITY COMPLAINTS LIST - Client Based On Property
export const fetchSecurityComplaints = createAsyncThunk(
  "tenant_security_request/list",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(API_URL + "/tenant_security_request/list", {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          property_id: payload,
        }),
      });

      const data = await response.json();
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// NEW FETCH SECURITY COMPLAINTS LIST - Client Based On Property
export const fetchSecurityComplaintRequests = createAsyncThunk(
  "tenant_security_request/complainList",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));

      const { data } = await axios.post(
        API_URL + "/tenant_security_request/complainList",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );

      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// FETCH SECURITY RESOLVED COMPLAINTS LIST - Client Based On Property
export const fetchSecurityResolvedComplaints = createAsyncThunk(
  "tenant_security_request-closed/list",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(API_URL + "/tenant_security_request/list", {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          property_id: payload,
          status: "resolved",
        }),
      });

      const data = await response.json();
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// FETCH SECURITY INCIDENTS LIST - Client Based On Property
export const fetchSecurityIncidents = createAsyncThunk(
  "security_incident/list",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(API_URL + "/security_incident/list", {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          property_id: payload,
        }),
      });

      const data = await response.json();
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// ADD Security Incident By Client
export const addSecurityIncident = createAsyncThunk(
  "security_incident/add",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const formData = new FormData();
      Object.keys(payload).map((key) => {
        if (key === "document") {
          payload[key].forEach((item) => {
            if (PLATFORM !== "web") {
              formData.append(key, {
                name: item.name,
                type: item.type,
                uri:
                  PLATFORM === "android"
                    ? item.uri
                    : item.uri.replace("file://", ""),
              });
            } else {
              formData.append(key, item, item.name);
            }
          });
        } else {
          formData.append(key, payload[key]);
        }
      });

      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }

      // const response = await fetch(API_URL + "/security_incident/add", {
      //   method: "POST",
      //   headers: headers,
      //   body: formData,
      // });
      // const data = await response.json();
      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "POST",
        url: "/security_incident/add",
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      // const data = await response.json();

      const { data } = await axiosInstance.request(config);

      dispatch(setLoading(false));
      if (data.success) {
        dispatch(fetchSecurityIncidents(payload.property_id));
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error.message);
    }
  }

  // async (payload, { rejectWithValue, getState, dispatch }) => {
  //   try {
  //     dispatch(setLoading(true));
  //     const formData = new FormData();
  //     Object.keys(payload).map((key) => {
  //       if (key === "document") {
  //         payload[key].forEach((item) => {
  //           if (PLATFORM !== "web") {
  //             formData.append(key, {
  //               name: item.name,
  //               type: item.type,
  //               uri:
  //                 PLATFORM === "android"
  //                   ? item.uri
  //                   : item.uri.replace("file://", ""),
  //             });
  //           } else {
  //             formData.append(key, item, item.name);
  //           }
  //         });
  //       } else {
  //         formData.append(
  //           key,
  //           typeof payload[key] === "object"
  //             ? JSON.stringify(payload[key])
  //             : payload[key]
  //         );
  //       }
  //     });

  //     const headers = {
  //       Authorization: `Bearer ${localStorage.getItem("user_token")}`,
  //     };
  //     if (PLATFORM !== "web") {
  //       headers["Content-Type"] = "multipart/form-data";
  //     }

  //     const { response } = await axios.post(
  //       API_URL + "/security_incident/add",
  //       formData,
  //       {
  //         headers,
  //       }
  //     );
  //     const data = await response.json();
  //     dispatch(setLoading(false));
  //     if (data.success) {
  //       // dispatch(fetchSecurityIncidents());
  //       return data.data;
  //     } else {
  //       return rejectWithValue(data.error.message);
  //     }
  //   } catch (error) {
  //     dispatch(setLoading(false));
  //     return rejectWithValue(error.message);
  //   }
  // }
);

export const fetchTenantUnitsBasedPropertyId = createAsyncThunk(
  "property/tenant-units",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.get(
        API_URL + `/property/${payload?.property_id}/tenantslist`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// ---------------------- SECURITY COMPLAINT ----------------------------- //

// GET Complaint Data Based on ID - Tenant Side
export const fetchComplaintBasedOnId = createAsyncThunk(
  "tenant_security_request_complain/get",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.get(
        `${API_URL}/tenant_security_request/get?_id=${payload.request_id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        rejectWithValue(data.error);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// Update Complaint If Accepted By SO --> Client ( SO - T3{Model} )
export const soAcceptSecurityComplainReq = createAsyncThunk(
  "security/tenant_complain/so_accept",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { request_id } = payload;
      dispatch(setLoading(true));
      const { data } = await axios.patch(
        API_URL + "/tenant_security_request/so_accept",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      await dispatch(fetchComplaintBasedOnId({ request_id }));
      dispatch(setLoading(false));
      return data.data;
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// Update Complaint If Rejected By SO --> Client ( SO - T3{Model} )
export const soRejectSecurityComplainReq = createAsyncThunk(
  "security/tenant_complain/so_reject",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { request_id } = payload;
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/tenant_security_request/so_reject",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      await dispatch(fetchComplaintBasedOnId({ request_id }));
      dispatch(setLoading(false));
      return data.data;
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// Update Complaint - SO Submit Report --> Client ( SO - T3{Model} )
export const soSubmitSecurityComplainReport = createAsyncThunk(
  "security/tenant_complain/so_submit_report",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { request_id } = payload;
      dispatch(setLoading(true));
      const formData = new FormData();
      Object.keys(payload).map((key) => {
        if (key === "document") {
          payload[key].forEach((item) => {
            if (PLATFORM !== "web") {
              formData.append(key, {
                name: item.name,
                type: item.type,
                uri:
                  PLATFORM === "android"
                    ? item.uri
                    : item.uri.replace("file://", ""),
              });
            } else {
              formData.append(key, item, item.name);
            }
          });
        } else {
          formData.append(key, payload[key]);
        }
      });
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }
      // const response = await fetch(
      //   API_URL + "/tenant_security_request/so_submit_report",
      //   {
      //     method: "POST",
      //     headers: headers,
      //     body: formData,
      //   }
      // );
      // const data = await response.json();
      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "POST",
        url: "/tenant_security_request/so_submit_report",
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      // const data = await response.json();

      const { data } = await axiosInstance.request(config);

      if (data.success) {
        await dispatch(fetchComplaintBasedOnId({ request_id }));
        dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// Resolved Complaint - SM RESOLVED --> Client ( {SM / PM - T3{Model}} / { Owner - T1{model}} )
export const closeSecurityRequestWithoutViolation = createAsyncThunk(
  "tenant_security_request/close_without_violation",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      // const { request_id } = payload;
      dispatch(setLoading(true));
      const { data } = await axios.patch(
        `${API_URL}/tenant_security_request/closewithoutviolation`,
        // 'http://localhost:3000/tenant_security_request/closewithoutviolation',
        //?id=${payload.request_id}
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        let request_id = payload?.id;
        // await dispatch(fetchTenantComplainsForPartners({}));
        await dispatch(fetchComplaintBasedOnId({ request_id }));
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return error?.response;
    }
  }
);

// ESCALATE TO PM Complaint By SM --> Client ( SM - T3{Model} )
export const smEscalateToPM = createAsyncThunk(
  "tenant_security_request/sm_escalate_to_pm",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { request_id } = payload;
      dispatch(setLoading(true));
      const { data } = await axios.post(
        `${API_URL}/tenant_security_request/sm_escalate_to_pm`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        await dispatch(fetchComplaintBasedOnId({ request_id }));
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return error?.response;
    }
  }
);

// Assign SO to Complaint - SM ASSIGN --> Client ( SM - T3{Model} )
export const assignSo = createAsyncThunk(
  "security/tenant_complain/assign_so",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { request_id } = payload;
      dispatch(setLoading(true));
      const { data } = await axios.patch(
        API_URL + "/tenant_security_request/assign_so",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      await dispatch(fetchComplaintBasedOnId({ request_id }));
      dispatch(setLoading(false));
      return data.data;
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// Assign Back to SO Complaint - SM ASSIGN Back to SO For NEED MORE INFO --> Client ( SM - T3{Model} )
export const smNeedMoreInfo = createAsyncThunk(
  "tenant_security_request/sm_need_more_info",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { request_id } = payload;
      dispatch(setLoading(true));
      const { data } = await axios.post(
        `${API_URL}/tenant_security_request/sm_need_more_info`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        await dispatch(fetchComplaintBasedOnId({ request_id }));
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return error?.response;
    }
  }
);

// Update Complaint With Violation --> Client ( Owner / PM - T1/T3{Model} )
export const closeComplaintRequestWithVioation = createAsyncThunk(
  "tenant_security_request/closewithviolation",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));

      // const headers = {
      //   Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      // };
      console.log(payload, "[PAY-TEST]");

      // const response = await fetch(
      //   `${API_URL}/tenant_security_request/closewithviolation?id=${payload.request_id}`,
      //   {
      //     method: "PATCH",
      //     headers: headers,
      //   },
      //   payload
      // );
      // const data = await response.json();

      const { data } = await axios.patch(
        `${API_URL}/tenant_security_request/closewithviolation?id=${payload.request_id}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );

      dispatch(setLoading(false));
      if (data.success) {
        await dispatch(fetchSecurityComplaintRequests());

        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return error?.response;
    }
  }
);

// ---------------------- SECURITY INCIDENT ----------------------------- //

// GET Incident Data Based on ID - Client Side
export const fetchIncidentBasedOnId = createAsyncThunk(
  "security_incident/get",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.get(
        `${API_URL}/security_incident/get?security_incident_id=${payload}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        rejectWithValue(data.error);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// FETCH Company List Data Based on Property-ID  64854f61ac46a5b106703a0b
export const fetchCompanyListBasedProperty = createAsyncThunk(
  "property-company/list",
  async (payload, { getState, rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        API_URL + `/property/companylist?id=${payload}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );

      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error?.response);
    }
  }
);

// FETCH Team Mmbers Based on company & property  LIST
export const fetchTeamMemberBasedOnCompany = createAsyncThunk(
  "property-company-teams/list",
  async (payload, { getState, rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        API_URL +
          `/property/teamslist?id=${payload.propertyId}&company_id=${payload.compId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );

      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error?.response);
    }
  }
);

// GET Incident Data Based on ID - Client Side
export const fetchSecurityOfficerBasedOnProperty = createAsyncThunk(
  "securityofficerslist/get",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await axios.get(
        `${API_URL}/property/securityofficerslist?id=${payload}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        return data.data;
      } else {
        rejectWithValue(data.error);
      }
    } catch (error) {
      return rejectWithValue(error?.response);
    }
  }
);

// Update Incident With Resolved By SM/PM --> Client ( SM / PM - T3{Model} )
export const updateIncidentWithResolved = createAsyncThunk(
  "security_incident/resolve_incident",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));

      const { data } = await axios.post(
        `${API_URL}/security_incident/resolve_incident`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );

      dispatch(setLoading(false));
      if (data.success) {
        // await dispatch(fetchSecurityComplaints());
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return error?.response;
    }
  }
);

// Escalated incident By SM --> Client ( SM - T3{Model} )
export const updateIncidentEscalateBySM = createAsyncThunk(
  "security_incident/escalateto_pm",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));

      const { data } = await axios.post(
        `${API_URL}/security_incident/escalateto_pm`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );

      dispatch(setLoading(false));

      if (data.success) {
        // await dispatch(fetchSecurityComplaints());
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return error?.response;
    }
  }
);

// Assign Incident TO SO By SM --> Client ( SM - T3{Model} )
export const assignIncidentToSO = createAsyncThunk(
  "security_incident/assign_to_so",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        `${API_URL}/security_incident/assign_to_so`,
        // 'http://localhost:3000/security_incident/assign_to_so',
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );

      dispatch(setLoading(false));

      if (data.success) {
        // await dispatch(fetchSecurityComplaints());
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return error?.response;
    }
  }
);

// Assigned Back Incident TO SM By PM --> Client ( PM - T3{Model} )
export const assignedBackIncidentToSM = createAsyncThunk(
  "security_incident/assign_to_sm",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));

      const { data } = await axios.post(
        `${API_URL}/security_incident/assign_to_sm`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );

      dispatch(setLoading(false));

      if (data.success) {
        // await dispatch(fetchSecurityComplaints());
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return error?.response;
    }
  }
);

// VIOLATIONS ISSUES BY PM FOR INCIDENT -> Client ( PM - T3{Model} )
export const issueViolationForIncidentByPM = createAsyncThunk(
  "security_incident/resolve_with_violation",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await axios.post(
        API_URL + `/security_incident/resolve_with_violation`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );

      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// FETCH SECURITY COMPLAINTS LIST - Client Based On Tenant / Unit ID
export const fetchTenantSecurityComplaints = createAsyncThunk(
  "tenant_security_complain/list",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(API_URL + "/tenant_security_request/list", {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          unit_id: payload?.unit_id,
          tenant_id: payload?.tenant_id,
        }),
      });

      const data = await response.json();
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// NEW FETCH SECURITY COMPLAINTS LIST - Client Based On Tenant / Unit ID
export const fetchTenantSecurityComplaintRequests = createAsyncThunk(
  "tenant_security_complaint/complainList",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));

      const { data } = await axios.post(
        API_URL + "/tenant_security_request/complainList",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );

      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// ADD Security Complaint By Tenant
export const addSecurityComplaint = createAsyncThunk(
  "tenant_security_request/add",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const formData = new FormData();
      Object.keys(payload).map((key) => {
        if (key === "document") {
          payload[key].forEach((item) => {
            if (PLATFORM !== "web") {
              formData.append(key, {
                name: item.name,
                type: item.type,
                uri:
                  PLATFORM === "android"
                    ? item.uri
                    : item.uri.replace("file://", ""),
              });
            } else {
              formData.append(key, item, item.name);
            }
          });
        } else {
          formData.append(key, payload[key]);
        }
      });

      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }

      // const response = await fetch(
      //   // API_URL + "/tenant_security_request/add",
      //   'http://localhost:3000/tenant_security_request/add',
      //   {
      //   method: "POST",
      //   headers: headers,
      //   body: formData,
      // });
      // const data = await response.json();
      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "POST",
        url: "/tenant_security_request/add",
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      // const data = await response.json();

      const { data } = await axiosInstance.request(config);

      dispatch(setLoading(false));

      if (data.success) {
        // await dispatch(fetchTenantSecurityComplaints());
        await dispatch(fetchTenantSecurityComplaintRequests());
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));

      return rejectWithValue(error.message);
    }
  }
);

// Update Complaint Accepted By SO --> Client ( SO - T3{Model} )
export const updateComplaintAcceptedBySO = createAsyncThunk(
  "tenant_security_request/accepted",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));

      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };

      const response = await fetch(
        `${API_URL}/tenant_security_request/accepted?id=${payload}`,
        {
          method: "PATCH",
          headers: headers,
        }
      );
      const data = await response.json();

      dispatch(setLoading(false));

      if (data.success) {
        // await dispatch(fetchSecurityComplaints());
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return error?.response;
    }
  }
);

// Update Complaint With Resolved By SO --> Client ( SO - T3{Model} )
export const updateComplaintResolvedBySO = createAsyncThunk(
  "tenant_security_request/so_forward_sm",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));

      const { data } = await axios.patch(
        `${API_URL}/tenant_security_request/so_forward_sm?id=${payload.id}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );

      dispatch(setLoading(false));

      if (data.success) {
        // await dispatch(fetchSecurityComplaints());
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return error?.response;
    }
  }
);

// Assign Complaint TO SO by SM --> Client ( SM - T3{Model} )
// Close / Resolved Complaint by SM --> Client ( SM - T3{Model} )
// Close / Resolved Complaint by PM --> Client ( PM - T3{Model} )
export const updateComplaintLogs = createAsyncThunk(
  "tenant_security_request/update",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));

      const { data } = await axios.patch(
        `${API_URL}/tenant_security_request/update?id=${payload.id}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );

      dispatch(setLoading(false));
      console.log(data, "WITHOUT-VIOLATION");
      if (data.success) {
        // await dispatch(fetchSecurityComplaints());
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return error?.response;
    }
  }
);

// Update Complaint Rejected By SO --> Client ( SO - T3{Model} )
export const updateComplaintRejectedBySO = createAsyncThunk(
  "tenant_security_request/rejected",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));

      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };

      const response = await fetch(
        `${API_URL}/tenant_security_request/rejected?id=${payload}`,
        {
          method: "PATCH",
          headers: headers,
        }
      );
      const data = await response.json();

      dispatch(setLoading(false));

      if (data.success) {
        // await dispatch(fetchSecurityComplaints());
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return error?.response;
    }
  }
);

// VIOLATIONS ISSUES BY OWNER FOR COMPLAINT -> Client ( OWNER - T1{Model} )
export const issueViolationForComplaintByPM = createAsyncThunk(
  "tenant_security_request/pm_closewith_violation",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await axios.patch(
        API_URL +
          `/tenant_security_request/pm_closewith_violation?id=${payload.id}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );

      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const securitySlice = createSlice({
  name: "security",
  initialState,
  extraReducers: {
    [fetchComplainType.rejected]: (state, action) => {
      state.complainTypes = [];
    },
    [fetchComplainType.fulfilled]: (state, action) => {
      state.complainTypes = action.payload;
    },
    [fetchSecurityComplaints.rejected]: (state, action) => {
      state.secuirtyComplains = [];
    },
    [fetchSecurityComplaints.fulfilled]: (state, action) => {
      state.secuirtyComplains = action.payload;
    },

    [fetchSecurityComplaintRequests.rejected]: (state, action) => {
      state.secuirtyComplains = [];
    },
    [fetchSecurityComplaintRequests.fulfilled]: (state, action) => {
      state.secuirtyComplains = action.payload;
    },

    [fetchComplaintBasedOnId.rejected]: (state, action) => {
      state.securityComplaint = null;
    },
    [fetchComplaintBasedOnId.fulfilled]: (state, action) => {
      state.securityComplaint = action.payload;
    },

    [fetchSecurityIncidents.rejected]: (state, action) => {
      state.secuirtyIncidents = [];
    },
    [fetchSecurityIncidents.fulfilled]: (state, action) => {
      state.secuirtyIncidents = action.payload;
    },

    [fetchIncidentBasedOnId.rejected]: (state, action) => {
      state.securityIncident = null;
    },
    [fetchIncidentBasedOnId.fulfilled]: (state, action) => {
      state.securityIncident = action.payload;
    },

    [fetchTenantUnitsBasedPropertyId.rejected]: (state, action) => {
      state.tenantsList = [];
    },

    [fetchTenantUnitsBasedPropertyId.fulfilled]: (state, action) => {
      state.tenantsList = action.payload;
    },
    [fetchCompanyListBasedProperty.rejected]: (state, action) => {
      state.companyList = [];
    },
    [fetchCompanyListBasedProperty.fulfilled]: (state, action) => {
      state.companyList = action.payload;
    },
    [fetchTeamMemberBasedOnCompany.rejected]: (state, action) => {
      state.teamMembersList = [];
    },
    [fetchTeamMemberBasedOnCompany.fulfilled]: (state, action) => {
      state.teamMembersList = action.payload;
    },
    [fetchSecurityOfficerBasedOnProperty.rejected]: (state, action) => {
      state.securityOfficersList = [];
    },
    [fetchSecurityOfficerBasedOnProperty.fulfilled]: (state, action) => {
      state.securityOfficersList = action.payload;
    },

    [fetchTenantSecurityComplaints.rejected]: (state, action) => {
      state.tenantSecuirtyComplains = [];
    },
    [fetchTenantSecurityComplaints.fulfilled]: (state, action) => {
      state.tenantSecuirtyComplains = action.payload;
    },
    [fetchTenantSecurityComplaintRequests.rejected]: (state, action) => {
      state.tenantSecuirtyComplains = [];
    },
    [fetchTenantSecurityComplaintRequests.fulfilled]: (state, action) => {
      state.tenantSecuirtyComplains = action.payload;
    },
    [fetchSecurityResolvedComplaints.rejected]: (state, action) => {
      state.securityResolvedComplains = [];
    },
    [fetchSecurityResolvedComplaints.fulfilled]: (state, action) => {
      state.securityResolvedComplains = action.payload;
    },
  },
});

// To Get The Complain Types For Tenants
export const getComplainType = (state) => state.security.complainTypes;

// To Fetch Copmlaints For Property - Client
export const getSecurityComplaints = (state) =>
  state.security.secuirtyComplains;

// To Fetch Resolved Copmlaints For Property - Client
export const getSecurityResolvedComplaints = (state) =>
  state.security.securityResolvedComplains;

// To Fetch The Compalint Based On ID
export const getSecurityComplaintData = (state) =>
  state.security.securityComplaint;

// To Fetch Copmlaints For Property - Tenant
export const getTenantSecurityComplaints = (state) =>
  state.security.tenantSecuirtyComplains;

// To Fetch Incidents For Property - Client
export const getSecurityIncidents = (state) => state.security.secuirtyIncidents;

// To Fetch The Incident Based On ID
export const getSecurityIncidentData = (state) =>
  state.security.securityIncident;

export const getTenantsLists = (state) => state.security.tenantsList;

// To Get The Companies List Based on Property
export const getCompaniesList = (state) => state.security.companyList;

// To Get The Company Membrers List Based on Property & Company
export const getCompanyMembersList = (state) => state.security.teamMembersList;

// To Get The Security Officers List Based on Property
export const getSecurityOfficersList = (state) =>
  state.security.securityOfficersList;

export default securitySlice.reducer;
