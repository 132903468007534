import React from "react";

const Header = () => {
  return (
    <>
      <h2>Lost Item</h2>
    </>
  );
};

export default Header;
