/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useDispatch } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { EditGreen, EditWhite } from "../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../../../ui/button/IconButton/IconButton";
import PropertySettingsDefaultModal from "../../PropertySettingsDefaultModal/PropertySettingsDefaultModal";
/* Import local pages and component ends */

/* Component starts */
const PropertyDefaults = (props) => {
  /* Props destructuring starts */
  const { user, settings, property_id } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [openEdit, setOpenEdit] = useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const changeHandler = (value, key) => {};
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // On updating setting
  const onUpdate = async () => {};
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="tenant-id-count option">
      {/* Label */}
      <div className="label">
        <p className="font_m fw_5">Property Defaults</p>
      </div>

      {/* Setting */}
      <div className="setting">
        <div className="row1">
          <div>
            <p className="font_xs font_grey">
              Property Contact Number:{" "}
              <span className="font_blue">
                {" "}
                {settings?.property_contact_number || "-"}{" "}
              </span>
            </p>
            <p className="font_xs font_grey">
              Property Email:{" "}
              <span className="font_blue">
                {settings?.property_email || "-"}
              </span>
            </p>
            <p className="font_xs font_grey">
              Property Website:{" "}
              <span className="font_blue">
                {" "}
                {settings?.property_website || "-"}{" "}
              </span>
            </p>
          </div>
          <IconButton onClick={() => setOpenEdit(true)}>
            <img src={EditGreen} alt="Edit" className="icon" />
          </IconButton>
        </div>

        <div className="row2"></div>
      </div>

      <PropertySettingsDefaultModal
        property_id={property_id}
        showModal={openEdit}
        settings={settings}
        onClose={() => setOpenEdit(false)}
      />
    </div>
  );
};
/* Component ends */

export default PropertyDefaults;
