export const fieldValue = {};

// Validations
export const validation = (values, desc) => {
  const error = {};

  // Description and Estimation starts
  desc.forEach((itm, idx) => {
    const description = "description_" + itm;
    // ********************** Creating Object Keys **********************
    if (values[description] === "") {
      error[description] = "Description is required";
    }
  });
  // Description and Estimation ends

  return error;
};

// Adding Description field
export const addMoreDesc = (formRef, setFormValue, desc, setDesc) => {
  const length = desc.length;
  const currentFormState = { ...formRef.current.values };

  setFormValue(() => {
    // Generating new field name according to array indexes
    currentFormState[`description_${length}`] = "";
    return currentFormState;
  });
  setDesc((p) => [...p, length]);
};

// Removing Description field
export const removeDesc = (formRef, setFormValue, desc, setDesc) => {
  const lastIdx = desc.length - 1;
  const currentFormState = { ...formRef.current.values };

  setFormValue(() => {
    delete currentFormState[`description_${lastIdx}`];
    return currentFormState;
  });

  setDesc((prevState) => {
    const prevFields = [...prevState];
    prevFields.pop();
    return prevFields;
  });
};
