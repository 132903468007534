/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { RandomProperty } from "../../../../../setup/constants/images";
import Image from "../../../../ui/other/Image/Image";
import FileViewerModal from "../../../../ui/modal/FileViewerModal/FileViewerModal";
/* Import image and SVG ends */

/* Import local pages and component starts */
/* Import local pages and component ends */

/* Component starts */
const DetailSection4 = (props) => {
  /* Props destructuring starts */
  const { maintenance } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [showFileViewer, setShowFileViewer] = useState(false);
  const [file, setFile] = useState({});
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      <div className="section pair-section section4 font_s fw_6">
        {/* Documents Attached */}
        {maintenance?.documents?.length > 0 ? (
          <>
            <p className="left font_grey">Attachments</p>
            <div className="docs-list right">
              {maintenance?.documents.map((item, index) => (
                <Image
                  key={index}
                  className="media"
                  imgClass="thumbnail"
                  src={item?.url}
                  videoThumbnail={item?.type2 === "video"}
                  alt="Attatched Media"
                  objectFit="cover"
                  onClick={() => {
                    setFile(item);
                    setShowFileViewer(true);
                  }}
                />
              ))}
            </div>
          </>
        ) : (
          <p className="font_m fw_4">No Attachments Added!</p>
        )}
      </div>

      {/* File viewer modal */}
      <FileViewerModal
        show={showFileViewer}
        type={file?.type}
        file={file?.url}
        onClose={() => {
          setShowFileViewer(false);
          setFile({});
        }}
      />
    </>
  );
};
/* Component ends */

export default DetailSection4;
