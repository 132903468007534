/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useDispatch, useSelector } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  fetchEmergencyContacts,
  getContacts,
} from "../../../../../setup/store/slices/emergencyContactSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import {
  WarningRoundedOutlineYellow,
  EyeFilledBlue,
  ExcalmatoryYellow,
} from "../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import AddEmergencyContactModal from "../../AddEmergencyContactModal/AddEmergencyContactModal";
import EmergencyContactsList from "../../EmergencyContactsList/EmergencyContactsList";
import TextButton from "../../../../ui/button/TextButton/TextButton";
/* Import local pages and component ends */

/* Component starts */
const EmergencyContacts = (props) => {
  /* Props destructuring starts */
  const { property_id, settings, getPropertySettingsDetail } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [addContact, setAddContact] = useState(false);
  const [editContact, setEditContact] = useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();

  //   Redux Selector
  const contacts = useSelector(getContacts);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  // Fetching all emergency contracts
  useEffect(() => {
    dispatch(fetchEmergencyContacts({ property_id: property_id }));
  }, [settings, property_id]);

  // Displaying edit after the emergency contracts are fetched
  useEffect(() => {}, [settings]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="emergency-contract-option option">
      {/* Label */}
      <div className="label">
        <p className="font_m fw_5">Emergency Contracts</p>
      </div>

      {/* Setting */}
      <div className="setting">
        {/* Emergency Contract List */}
        {contacts?.length === 0 ? (
          <div className="warning font_yellow">
            <p className="v_middle font_yellow fw_5 font_s">
              <img
                src={ExcalmatoryYellow}
                alt="Warning Icon"
                className="v_middle"
              />{" "}
              <span className="v_middle font_yellow fw_5 font_s">
                No Emergency Contacts Added Yet!
              </span>
            </p>
            <div className="btn-wrapper mt_1">
              <TextButton
                onClick={() => setAddContact(true)}
                className="font_s fw_5"
              >
                + Add
              </TextButton>
            </div>
          </div>
        ) : (
          <div className="contacts-add-view">
            <TextButton
              onClick={() => setAddContact(true)}
              className="font_s fw_5"
            >
              + Add
            </TextButton>
            <TextButton
              onClick={() => setEditContact(true)}
              className="font_s fw_5"
            >
              View
            </TextButton>
          </div>
        )}
      </div>

      {/* Modals */}
      {/* Adding Emergency Modal */}
      <AddEmergencyContactModal
        onClose={() => setAddContact(false)}
        showModal={addContact}
        property_id={property_id}
        settings={settings}
      />

      {/* Emergency Contact List Modal */}
      <EmergencyContactsList
        onClose={() => setEditContact(false)}
        data={contacts}
        showModal={editContact}
        property_id={property_id}
        settings={settings}
        getPropertySettingsDetail={getPropertySettingsDetail}
      />
    </div>
  );
};
/* Component ends */

export default EmergencyContacts;
