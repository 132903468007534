import moment from "moment";
import {
  validateAtleastOneNumber,
  validateAtleastOneSpecialChar,
} from "../../../../setup/utils/validations";

export const formValue = {
  business_name: "",
  relation_to_business: "",
  trade_license_number: "",
  trade_license_expiry_date: new Date(),
  tax_id_number: "",
  img_trade_license: "",
  img_tax_id: "",
  img_tax_id: "",
  bank_statement_document: "",
};

export const commercialDetailValidation = (values) => {
  const errorFields = {};

  /* Validations for Bussiness Name Starts */
  if (values.business_name === "") {
    errorFields.business_name = "Bussiness Name is required";
  }
  if (values.business_name && validateAtleastOneNumber(values.business_name)) {
    errorFields.business_name = "Bussiness Name Cannot Contains Numbers";
  }
  if (
    values.business_name &&
    validateAtleastOneSpecialChar(values.business_name)
  ) {
    errorFields.business_name =
      "Bussiness Name Cannot Contains Special Characters";
  }
  /* Validations for Bussiness Name Ends */

  /* Validations for Relation To Bussiness Starts */
  if (values.relation_to_business === "") {
    errorFields.relation_to_business = "Relation To Bussiness is required";
  }
  if (
    values.relation_to_business &&
    validateAtleastOneNumber(values.relation_to_business)
  ) {
    errorFields.relation_to_business =
      "Relation To Bussiness Cannot Contains Numbers";
  }
  if (
    values.relation_to_business &&
    validateAtleastOneSpecialChar(values.relation_to_business)
  ) {
    errorFields.relation_to_business =
      "Relation To Bussiness Cannot Contains Special Characters";
  }
  /* Validations for Relation To Bussiness Ends */

  /* Validations for Trade License Number Starts */
  if (values.trade_license_number === "") {
    errorFields.trade_license_number = "Trade License Number is required";
  }

  /* Validations for Trade License Number Ends */

  /* Validations for Tax ID Starts */
  if (values.tax_id_number === "") {
    errorFields.tax_id_number = "Tax ID Number is required";
  }
  /* Validations for Tax ID Ends */

  if (values.img_trade_license === null) {
    errorFields.img_trade_license = "Photo is required";
  }

  if (values.img_tax_id === null) {
    errorFields.img_tax_id = "Photo is required";
  }

  return errorFields;
};

export const formPrefill = (
  data,
  setInitialFormValues,
  img_trade_license,
  img_tax_id,
  bank_statement_document
) => {
  console.log(data, "[DATA]");
  setInitialFormValues({
    business_name: data?.business_name,
    relation_to_business: data?.relation_to_business,
    trade_license_number: data?.trade_license_number,
    trade_license_expiry_date: new Date(data?.trade_license_expiry_date),
    tax_id_number: data?.tax_id_number,
    img_trade_license: data?.trade_license_document?.url,
    img_tax_id: data?.tax_id_document?.url,
    bank_statement_document: data?.bank_statement_document?.url,
  });

  img_trade_license.current = data?.trade_license_document;
  img_tax_id.current = data?.tax_id_document;
  bank_statement_document.current = data?.bank_statement_document;
};

export const isDataChanged = (newData, prevData) => {
  const business_name_new = newData.business_name;
  const relation_to_business_new = newData.relation_to_business;
  const trade_license_number_new = newData.trade_license_number;
  const trade_license_expiry_date_new = newData.trade_license_expiry_date;
  const tax_id_number_new = newData.tax_id_number;
  const img_trade_license_new = newData.img_trade_license;
  const img_tax_id_new = newData.img_tax_id;
  const bank_statement_document_new = newData.bank_statement_document;

  const business_name_prev = prevData.business_name;
  const relation_to_business_prev = prevData.relation_to_business;
  const trade_license_number_prev = prevData.trade_license_number;
  const trade_license_expiry_date_prev = prevData.trade_license_expiry_date;
  const tax_id_number_prev = prevData.tax_id_number;
  const img_trade_license_prev = prevData.img_trade_license;
  const img_tax_id_prev = prevData.img_tax_id;
  const bank_statement_document_prev = prevData.bank_statement_document;

  console.log("NEW DATA", newData);
  console.log("OLD DATA", prevData);

  if (business_name_new !== business_name_prev) {
    return true;
  }

  if (relation_to_business_new !== relation_to_business_prev) {
    return true;
  }

  if (trade_license_number_new !== trade_license_number_prev) {
    return true;
  }

  if (
    !moment(trade_license_expiry_date_new).isSame(
      trade_license_expiry_date_prev
    )
  ) {
    return true;
  }

  if (tax_id_number_new !== tax_id_number_prev) {
    return true;
  }

  if (img_trade_license_new && !img_trade_license_new?.url) {
    return true;
  }

  if (img_tax_id_new && !img_tax_id_new?.url) {
    return true;
  }

  if (bank_statement_document_new && !bank_statement_document_new?.url) {
    return true;
  }

  return false;
};
