/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import TextButton from "../../../components/ui/button/TextButton/TextButton";
/* Import local pages and component ends */

/* Component starts */
const PropertySliderHeading = (props) => {
  /* Props destructuring starts */
  const { count, community } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // On adding property under selected property
  const onAddProperty = async () => {
    navigate(`/communities/${community._id}/properties/add?under-community=yes`);
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="property-slider-heading">
      <h2 className="heading font_xl">Community Properties ({count})</h2>

      <TextButton onClick={onAddProperty} className="font_m fw_6 d_block">
        + Add Property
      </TextButton>
    </div>
  );
};
/* Component ends */

export default PropertySliderHeading;
