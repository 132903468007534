import GLOBAL from "../../../../setup/constants/global";

export const buttonList = [
  { key: GLOBAL.MAINTENANCE.NEW, label: "New" },
  { key: GLOBAL.MAINTENANCE.ONGOING, label: "Ongoing" },
  { key: GLOBAL.MAINTENANCE.SPARE_PARTS, label: "Spare Parts" },
  { key: GLOBAL.MAINTENANCE.COMPLETED, label: "Completed" },
  { key: GLOBAL.MAINTENANCE.REJECTED, label: "Rejected" },
  { key: GLOBAL.MAINTENANCE.ESCALATED, label: "Escalated" },
  { key: GLOBAL.MAINTENANCE.MOVE_IN, label: "Move In" },
  { key: GLOBAL.MAINTENANCE.MOVE_OUT, label: "Move Out" },
  { key: GLOBAL.MAINTENANCE.UNITS, label: "Units" },
  { key: GLOBAL.MAINTENANCE.COMMON_AREA, label: "Common Area" },
  { key: GLOBAL.MAINTENANCE.CLOSED, label: "Closed" },
];

export const newCategoryRender = (item) => {
  const isInside = item.location?.category_type === "Inside My Unit";
  let category = "";
  let subCategory = "";
  let innerCategory = "";

  category = isInside ? item.category?.category_name : item.outercategory?.name;
  subCategory = isInside ? item.subcategory?.subcategory_name : "-";
  innerCategory =
    isInside && item.innercategory?.innercategory_name
      ? item.innercategory?.innercategory_name
      : "-";

  return {
    category,
    subCategory,
    innerCategory,
  };
};
