/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  fetchMaintenanceInList,
  fetchMaintenanceInListBasedCommunity,
  fetchMaintenanceInListBasedProperty,
  fetchSearchedMaintenanceInList,
  getCommunityMaintenanceInList,
  getMaintenanceInList,
  getPropertyMaintenanceInList,
} from "../../setup/store/slices/maintenanceInDashboardSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { EnvelopeOutLinedBlue, PrintIcon } from "../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import GLOBAL from "../../setup/constants/global";
import Header from "./local-component/Header";
import StatusPieChartGraph from "./local-component/StatusPieChartGraph";
import PieChartGraph from "./local-component/PieChartGraph";
import Type3DPieChart from "../../components/ui/other/PieCharts/Type3DPieChart";
import Status3DPieChart from "../../components/ui/other/PieCharts/Status3DPieChart";
import Searchbar from "../../components/ui/other/Searchbar/Searchbar";
import MaintenanceInUnitTable from "./local-component/MaintenanceInUnitTable";
import CustomDateInput from "../../components/ui/input/CustomDateInput/CustomDateInput";
import Pagination from "../../components/module/RnD/Pagination/Pagination";
// import CustomButton from "../../components/ui/button/CustomButton/CustomButton";
// import TextButton from "../../components/ui/button/TextButton/TextButton";
import { getUniqueValues } from "../../setup/utils/global-helper";
import BreadCrumb from "../../components/ui/other/BreadCrumb/BreadCrumb";
import EmailModal from "../../components/ui/modal/EmailModal/EmailModal";
/* Import local pages and component ends */

import "./style.scss";
import moment from "moment";

/* Component starts */
const MaintenanceInUnitDashboard = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const startDate = new Date();
  // const endDate = new Date();
  const [fromDate, setFromDate] = useState(
    new Date(startDate.getFullYear(), startDate.getMonth(), 1)
  );
  const [toDate, setToDate] = useState(
    // new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0)
    new Date()
  );

  const [search, setSearch] = useState("");
  const [maintenanceInList, setMaintenanceInList] = useState([]);
  const [filteredMaintenanceIn, setFilteredMaintenanceIn] = useState();

  const [typeData, setTypeData] = useState();
  const [statusData, setStatusData] = useState();
  const [cleared, setCleared] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [showModal, setShowModal] = useState(false);

  // FILTERS
  const [communityData, setCommunityData] = useState();
  const [multiSelectCommunity, setMultiSelectCommunity] = useState();
  const [propertyData, setPropertyData] = useState();
  const [multiSelectProperty, setMultiSelectProperty] = useState();

  const [tenantData, setTenantData] = useState();
  const [multiSelectTenant, setMultiSelectTenant] = useState();

  const [unitNumData, setUnitNumData] = useState();
  const [multiSelectUnitNum, setMultiSelectUnitNum] = useState();

  const [sparePartData, setSparePartData] = useState();
  const [multiSelectSparePart, setMultiSelectSparePart] = useState();

  const [processingData, setProcessingData] = useState();
  const [multiSelectProcessing, setMultiSelectProcessing] = useState();

  const [locationData, setLocationData] = useState();
  const [multiSelectLocation, setMultiSelectLocation] = useState();

  const [severityData, setSeverityData] = useState();
  const [multiSelectSeverity, setMultiSelectSeverity] = useState();

  const [categoryData, setCategoryData] = useState();
  const [multiSelectCategory, setMultiSelectCategory] = useState();

  const [subcategoryData, setSubcategoryData] = useState();
  const [multiSelectSubCategory, setMultiSelectSubCategory] = useState();

  const [statusData1, setStatusData1] = useState();
  const [multiSelectStatus, setMultiSelectStatus] = useState();

  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */

  const { pathname } = useLocation();
  const param = useParams();
  const dispatch = useDispatch();
  // const userRoles = useSelector(getUserRoles);
  // const user = useSelector(getUser);
  const maintenanceList = useSelector(getMaintenanceInList);
  const propertyMaintenanceList = useSelector(getPropertyMaintenanceInList);
  const communityMaintenanceList = useSelector(getCommunityMaintenanceInList);

  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItemsList = filteredMaintenanceIn?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  const loadMaintenanceInUnitList = async () => {
    if (pathname.includes("communities")) {
      await dispatch(
        fetchMaintenanceInListBasedCommunity({
          community_id: param.communityId,
        })
      );
    } else if (pathname.includes("independent")) {
      await dispatch(
        fetchMaintenanceInListBasedProperty({ property_id: param.propertyId })
      );
    } else {
      await dispatch(fetchMaintenanceInList());
    }
  };

  const filterTypeOfMaintenanceIn = (occupancy) => {
    let electrical = occupancy?.filter((d) => d.category === "Electrical");
    let aC = occupancy?.filter((d) => d.category === "AC");
    let Specialties = occupancy?.filter((d) => d.category === "Specialties");

    const obj = [];
    obj.push(["Type", "No Of Types"]);
    if (electrical?.length > 0) {
      obj.push(["Electrical", electrical?.length]);
    }
    if (aC?.length > 0) {
      obj.push(["AC", aC?.length]);
    }
    if (Specialties?.length > 0) {
      obj.push(["Specialties", Specialties?.length]);
    }

    setTypeData(obj);
  };

  const filterStatusOfMaintenanceIn = (occupancy) => {
    let newStatus = occupancy?.filter((d) => d.status === "New");
    let completed = occupancy?.filter((d) => d.status === "Completed");
    let closed = occupancy?.filter((d) => d.status === "Closed");

    const obj = [];
    obj.push(["Status Type", "No of Status"]);
    if (newStatus?.length > 0) {
      obj.push(["New", newStatus?.length]);
    }
    if (completed?.length > 0) {
      obj.push(["Completed", completed?.length]);
    }
    if (closed?.length > 0) {
      obj.push(["Closed", closed?.length]);
    }

    setStatusData(obj);

    // setStatusData([
    //   { name: "Vacant", value: vacant.length },
    //   { name: "Occupied", value: occupied.length },
    //   { name: "Reserved", value: reserved.length },
    // ]);
  };

  const handleSearch = async (isReset = false, resetValue = {}) => {
    let payload = {};
    if (!isReset) {
      if (search && fromDate && toDate) {
        payload = {
          searchKey: search,
          start_date: moment(fromDate)?.format("MM-DD-YYYY"),
          end_date: moment(toDate)?.format("MM-DD-YYYY"),
        };
        // Backend response. Try, Catch
        try {
          let result;
          if (pathname.includes("communities")) {
            payload = {
              ...payload,
              community_id: param.communityId,
            };
            result = await dispatch(fetchSearchedMaintenanceInList(payload));
          } else if (pathname.includes("independent")) {
            payload = {
              ...payload,
              property_id: param.propertyId,
            };
            result = await dispatch(fetchSearchedMaintenanceInList(payload));
          } else {
            result = await dispatch(fetchSearchedMaintenanceInList(payload));
          }
          // Handling success response
          switch (result.meta.requestStatus) {
            case GLOBAL.REJECTED:
              // setModalDesc(result.payload);
              // setShowErrorModal(true);
              break;

            case GLOBAL.FULFILLED:
              // setModalDesc(result.payload.message);
              // setShowSuccessModal(true);
              setMaintenanceInList(result.payload);
              setFilteredMaintenanceIn(result.payload);

              break;
          }
        } catch (error) {
          // Handling error response
          console.error(error.message);
        }
      } else if (search) {
        payload = {
          searchKey: search,
        };

        await dispatch(fetchSearchedMaintenanceInList(payload));
      } else if (!search && fromDate && toDate) {
        payload = {
          searchKey: "",
          start_date: moment(fromDate)?.format("MM-DD-YYYY"),
          end_date: moment(toDate)?.format("MM-DD-YYYY"),
        };
        // Backend response. Try, Catch
        try {
          let result;
          if (pathname.includes("communities")) {
            payload = {
              ...payload,
              community_id: param.communityId,
            };
            result = await dispatch(fetchSearchedMaintenanceInList(payload));
          } else if (pathname.includes("independent")) {
            payload = {
              ...payload,
              property_id: param.propertyId,
            };
            result = await dispatch(fetchSearchedMaintenanceInList(payload));
          } else {
            result = await dispatch(fetchSearchedMaintenanceInList(payload));
          }
          // Handling success response
          switch (result.meta.requestStatus) {
            case GLOBAL.REJECTED:
              // setModalDesc(result.payload);
              // setShowErrorModal(true);
              break;

            case GLOBAL.FULFILLED:
              // setModalDesc(result.payload.message);
              // setShowSuccessModal(true);
              setMaintenanceInList(result.payload);
              setFilteredMaintenanceIn(result?.payload);
              break;
          }
        } catch (error) {
          // Handling error response
          console.error(error.message);
        }
      }
    }
    // else {
    //   payload = {
    //     ...resetValue,
    //   };
    // }
  };

  const handleClear = () => {
    let payload = {
      searchKey: "",
      start_date: new Date(startDate.getFullYear(), startDate.getMonth(), 1),
      end_date: new Date(),
    };
    setSearch(payload.searchKey);
    setFromDate(payload.start_date);
    setToDate(payload.end_date);

    handleSearch(true, payload);
    setCleared(!cleared);
  };

  // To check the any checkbox is true or not
  const checkAnyFilterChecked = (filter) => {
    return Object?.keys(filter)?.every(function (k) {
      return !filter[k];
    });
  };

  // SEND EMAIL FUNCTION
  const handleSendEmail = () => {
    setShowModal(!showModal);
  };

  /* Component function definition ends */

  /* Component useEffect starts */

  useEffect(() => {
    loadMaintenanceInUnitList();
  }, [pathname, !cleared]);

  useEffect(() => {
    if (pathname.includes("communities") && communityMaintenanceList) {
      setMaintenanceInList(communityMaintenanceList);
      setFilteredMaintenanceIn([...communityMaintenanceList]);
      filterTypeOfMaintenanceIn(maintenanceInList);
      filterStatusOfMaintenanceIn(maintenanceInList);
    } else if (pathname.includes("independent") && propertyMaintenanceList) {
      setMaintenanceInList(propertyMaintenanceList);
      setFilteredMaintenanceIn([...propertyMaintenanceList]);
      filterTypeOfMaintenanceIn(maintenanceInList);
      filterStatusOfMaintenanceIn(maintenanceInList);
    } else {
      setMaintenanceInList(maintenanceList);
      setFilteredMaintenanceIn([...maintenanceInList]);
      filterTypeOfMaintenanceIn(maintenanceInList);
      filterStatusOfMaintenanceIn(maintenanceInList);
    }
  }, [
    pathname,
    maintenanceList,
    communityMaintenanceList,
    propertyMaintenanceList,
    !cleared,
  ]);

  useEffect(() => {
    if (pathname.includes("communities") && communityMaintenanceList) {
      filterTypeOfMaintenanceIn(maintenanceInList);
      filterStatusOfMaintenanceIn(maintenanceInList);
    } else if (pathname.includes("independent") && propertyMaintenanceList) {
      filterTypeOfMaintenanceIn(maintenanceInList);
      filterStatusOfMaintenanceIn(maintenanceInList);
    } else {
      filterTypeOfMaintenanceIn(maintenanceInList);
      filterStatusOfMaintenanceIn(maintenanceInList);
    }
  }, [!search, maintenanceInList]);

  useEffect(() => {
    const community = getUniqueValues(maintenanceInList, "community_name");
    const property = getUniqueValues(maintenanceInList, "property_name");
    const location = getUniqueValues(maintenanceInList, "location");
    const tenant = getUniqueValues(maintenanceInList, "tenant");
    const category = getUniqueValues(maintenanceInList, "category");
    const severity = getUniqueValues(maintenanceInList, "severity");

    const unitNum = getUniqueValues(maintenanceInList, "unit_name");
    const sparePart = getUniqueValues(maintenanceInList, "spareParts");
    const processing = getUniqueValues(maintenanceInList, "processing");

    const subCat = getUniqueValues(maintenanceInList, "subcategory");
    const status = getUniqueValues(maintenanceInList, "status");

    const communityObject = {};
    community.forEach((prop) => (communityObject[prop] = false));
    setMultiSelectCommunity(communityObject);
    setCommunityData(community);

    const propertyObject = {};
    property.forEach((prop) => (propertyObject[prop] = false));
    setMultiSelectProperty(propertyObject);
    setPropertyData(property);

    const processingObject = {};
    processing
      ?.sort((a, b) => a - b)
      .forEach((prop) => (processingObject[prop] = false));
    setMultiSelectProcessing(processingObject);
    setProcessingData(processing?.sort((a, b) => a - b));

    const unitNumObject = {};
    unitNum.forEach((prop) => (unitNumObject[prop] = false));
    setMultiSelectUnitNum(unitNumObject);
    setUnitNumData(unitNum);

    const sparePartObject = {};
    sparePart.forEach((prop) => (sparePartObject[prop] = false));
    setMultiSelectSparePart(sparePartObject);
    setSparePartData(sparePart);

    const locationObject = {};
    location.forEach((prop) => (locationObject[prop] = false));
    setMultiSelectLocation(locationObject);
    setLocationData(location);

    const tenantObject = {};
    tenant.forEach((prop) => (tenantObject[prop] = false));
    setMultiSelectTenant(tenantObject);
    setTenantData(tenant);

    const severityObject = {};
    severity.forEach((prop) => (severityObject[prop] = false));
    setMultiSelectSeverity(severityObject);
    setSeverityData(severity);

    const categoryObject = {};
    category.forEach((prop) => (categoryObject[prop] = false));
    setMultiSelectCategory(categoryObject);
    setCategoryData(category);

    const subCatObject = {};
    subCat.forEach((prop) => (subCatObject[prop] = false));
    setMultiSelectSubCategory(subCatObject);
    setSubcategoryData(subCat);

    const statusObject = {};
    status.forEach((prop) => (statusObject[prop] = false));
    setMultiSelectStatus(statusObject);
    setStatusData1(status);
  }, [maintenanceInList]);

  useEffect(() => {
    if (
      multiSelectCommunity !== undefined ||
      multiSelectProperty !== undefined ||
      multiSelectStatus !== undefined ||
      multiSelectTenant !== undefined ||
      multiSelectLocation !== undefined ||
      multiSelectProcessing !== undefined ||
      multiSelectSeverity !== undefined ||
      multiSelectUnitNum !== undefined ||
      multiSelectSparePart !== undefined ||
      multiSelectCategory !== undefined ||
      multiSelectSubCategory !== undefined
    ) {
      let allUnCheckedCommunity = checkAnyFilterChecked(multiSelectCommunity);
      let allUnCheckedProperty = checkAnyFilterChecked(multiSelectProperty);
      let allUnCheckedTenant = checkAnyFilterChecked(multiSelectTenant);
      let allUnCheckedLocation = checkAnyFilterChecked(multiSelectLocation);
      let allUnCheckedCategory = checkAnyFilterChecked(multiSelectCategory);
      let allUnCheckedSeverity = checkAnyFilterChecked(multiSelectSeverity);
      let allUnCheckedUnitNum = checkAnyFilterChecked(multiSelectUnitNum);
      let allUnCheckedProcessing = checkAnyFilterChecked(multiSelectProcessing);
      let allUnCheckedSparePart = checkAnyFilterChecked(multiSelectSparePart);

      let allUnCheckedSubCat = checkAnyFilterChecked(multiSelectSubCategory);
      let allUnCheckedStatus = checkAnyFilterChecked(multiSelectStatus);

      if (!allUnCheckedCommunity && multiSelectCommunity) {
        let selectedValue = Object.keys(multiSelectCommunity)?.filter(
          (key) => multiSelectCommunity[key]
        );

        const filteredCommunity = maintenanceInList?.filter((d) =>
          selectedValue?.includes(d.community_name)
        );

        setFilteredMaintenanceIn(filteredCommunity);
      } else if (!allUnCheckedProperty && multiSelectProperty) {
        let selectedValue = Object.keys(multiSelectProperty)?.filter(
          (key) => multiSelectProperty[key]
        );

        const filteredProperty = maintenanceInList?.filter((d) =>
          selectedValue?.includes(d.property_name)
        );

        setFilteredMaintenanceIn(filteredProperty);
      } else if (!allUnCheckedStatus && multiSelectStatus) {
        let selectedValue = Object.keys(multiSelectStatus)?.filter(
          (key) => multiSelectStatus[key]
        );

        const filteredStatus = maintenanceInList?.filter((d) =>
          selectedValue?.includes(d.status)
        );

        setFilteredMaintenanceIn(filteredStatus);
      } else if (!allUnCheckedLocation && multiSelectLocation) {
        let selectedValue = Object.keys(multiSelectLocation)?.filter(
          (key) => multiSelectLocation[key]
        );

        const filteredUnitType = maintenanceInList?.filter((d) =>
          selectedValue?.includes(d.location)
        );

        setFilteredMaintenanceIn(filteredUnitType);
      } else if (!allUnCheckedTenant && multiSelectTenant) {
        let selectedValue = Object.keys(multiSelectTenant)?.filter(
          (key) => multiSelectTenant[key]
        );

        const filteredTenant = maintenanceInList?.filter((d) =>
          selectedValue?.includes(d.tenant)
        );

        setFilteredMaintenanceIn(filteredTenant);
      } else if (!allUnCheckedUnitNum && multiSelectUnitNum) {
        let selectedValue = Object.keys(multiSelectUnitNum)?.filter(
          (key) => multiSelectUnitNum[key]
        );

        const filteredUnitNum = maintenanceInList?.filter((d) =>
          selectedValue?.includes(d.unit_name)
        );

        setFilteredMaintenanceIn(filteredUnitNum);
      } else if (!allUnCheckedProcessing && multiSelectProcessing) {
        let selectedValue = Object.keys(multiSelectProcessing)
          ?.filter((key) => multiSelectProcessing[key])
          ?.map(Number);

        const filteredProcessing = maintenanceInList?.filter((d) =>
          selectedValue?.includes(d.processing)
        );

        setFilteredMaintenanceIn(filteredProcessing);
      } else if (!allUnCheckedSparePart && multiSelectSparePart) {
        let selectedValue = Object.keys(multiSelectSparePart)?.filter(
          (key) => multiSelectSparePart[key]
        );

        const filteredSparePart = maintenanceInList?.filter((d) =>
          selectedValue?.includes(d.spareParts)
        );

        setFilteredMaintenanceIn(filteredSparePart);
      } else if (!allUnCheckedSeverity && multiSelectSeverity) {
        let selectedValue = Object.keys(multiSelectSeverity)?.filter(
          (key) => multiSelectSeverity[key]
        );

        const filteredSever = maintenanceInList?.filter((d) =>
          selectedValue?.includes(d.severity)
        );

        setFilteredMaintenanceIn(filteredSever);
      } else if (!allUnCheckedCategory && multiSelectCategory) {
        let selectedValue = Object.keys(multiSelectCategory)?.filter(
          (key) => multiSelectCategory[key]
        );

        const filteredCat = maintenanceInList?.filter((d) =>
          selectedValue?.includes(d.category)
        );

        setFilteredMaintenanceIn(filteredCat);
      } else if (!allUnCheckedSubCat && multiSelectSubCategory) {
        let selectedValue = Object.keys(multiSelectSubCategory)?.filter(
          (key) => multiSelectSubCategory[key]
        );

        const filteredSubCat = maintenanceInList?.filter((d) =>
          selectedValue?.includes(d.subcategory)
        );

        setFilteredMaintenanceIn(filteredSubCat);
      }
    } else {
      setFilteredMaintenanceIn(maintenanceInList);
    }
  }, [
    multiSelectCommunity,
    multiSelectProperty,
    multiSelectStatus,
    multiSelectTenant,
    multiSelectLocation,
    multiSelectProcessing,
    multiSelectSparePart,
    multiSelectSeverity,
    multiSelectUnitNum,
    multiSelectCategory,
    multiSelectSubCategory,
  ]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      <BreadCrumb type="dashboard_tenant_main" />
      <div className="maintenance-in-dashboard-wrapper">
        <div className="action-wrapper">
          <div>
            <img src={PrintIcon} alt="Print-Icon" />
            <span className="font_m fw_3 font_blue">Print</span>
          </div>

          <div onClick={handleSendEmail}>
            <img src={EnvelopeOutLinedBlue} alt="Print-Icon" />
            <span className="font_m fw_3 font_blue">Email</span>
          </div>
        </div>
        <div className="header">
          <Header />
        </div>

        {maintenanceInList?.length > 0 && (
          <div className="report-chart-wrapper">
            <div className="detail-box ">
              <div>
                <p className="font_l fw_4">Categories</p>
              </div>
              {/* <div>
              <PieChartGraph typeData={typeData} />
            </div> */}
              <Type3DPieChart data={typeData} label="Categories" />
            </div>

            <div className="detail-box ">
              <div>
                <p className="font_l fw_4">Status</p>
              </div>
              {/* <div>
              <StatusPieChartGraph statusData={statusData} />
            </div> */}
              <Status3DPieChart data={statusData} label="Status" />
            </div>
          </div>
        )}

        <div className="search-box mt-4 mb-4">
          <div className="search">
            <Searchbar
              setQuery={setSearch}
              query={search}
              className="dash-search"
            />
          </div>

          <div className="date">
            <div>
              <CustomDateInput
                label="From"
                value={fromDate}
                minDate={false}
                customOnchange={(date) => setFromDate(date)}
                required={true}
              />
            </div>

            <div>
              <CustomDateInput
                label="To"
                value={toDate}
                minDate={false}
                customOnchange={(date) => setToDate(date)}
                required={true}
              />
            </div>
          </div>

          <div className="btn-wrapper">
            <button
              onClick={() => handleSearch()}
              // disabled={!search}
            >
              Apply
            </button>
            <button className="btn-clear" onClick={handleClear}>
              Clear
            </button>
          </div>
        </div>

        <div className="maintenance-in-table-wrapper">
          <MaintenanceInUnitTable
            maintenanceInListData={currentItemsList}
            communityData={communityData}
            multiSelectCommunity={multiSelectCommunity}
            setMultiSelectCommunity={setMultiSelectCommunity}
            propertyData={propertyData}
            multiSelectProperty={multiSelectProperty}
            setMultiSelectProperty={setMultiSelectProperty}
            location={locationData}
            multiSelectLocation={multiSelectLocation}
            setMultiSelectLocation={setMultiSelectLocation}
            tenant={tenantData}
            multiSelectTenant={multiSelectTenant}
            setMultiSelectTenant={setMultiSelectTenant}
            severity={severityData}
            multiSelectSeverity={multiSelectSeverity}
            setMultiSelectSeverity={setMultiSelectSeverity}
            unitNum={unitNumData}
            multiSelectUnitNum={multiSelectUnitNum}
            setMultiSelectUnitNum={setMultiSelectUnitNum}
            processing={processingData}
            multiSelectProcessing={multiSelectProcessing}
            setMultiSelectProcessing={setMultiSelectProcessing}
            sparePart={sparePartData}
            multiSelectSparePart={multiSelectSparePart}
            setMultiSelectSparePart={setMultiSelectSparePart}
            category={categoryData}
            multiSelectCategory={multiSelectCategory}
            setMultiSelectCategory={setMultiSelectCategory}
            subCat={subcategoryData}
            multiSelectSubCategory={multiSelectSubCategory}
            setMultiSelectSubCategory={setMultiSelectSubCategory}
            statusData1={statusData1}
            multiSelectStatus={multiSelectStatus}
            setMultiSelectStatus={setMultiSelectStatus}
          />

          {filteredMaintenanceIn?.length > 0 && (
            <Pagination
              itemsPerPage={itemsPerPage}
              totalItems={filteredMaintenanceIn?.length}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          )}
        </div>

        {/* EMAIL MODAL */}
        <EmailModal
          showModal={showModal}
          onSuccess={() => setShowModal(!showModal)}
          onClose={() => setShowModal(!showModal)}
        />
      </div>
    </>
  );
};
/* Component ends */

export default MaintenanceInUnitDashboard;
