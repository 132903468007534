/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import GLOBAL from "../../../../../setup/constants/global";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import SimpleLog from "./SimpleLog";
import QuotationLog from "./QuotationLog";
import TaskLog from "./TaskLog";
import MaintenanceFinalReportLog from "./MaintenanceFinalReportLog";
/* Import local pages and component ends */

/* Component starts */
const TimeLineLists = (props) => {
  /* Props destructuring starts */
  const {
    maintenance_id,
    maintenance,
    logs,
    mgmtModel,
    isT1,
    isT2,
    isT3,
    status,
    property,
    user,
    currency,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  // console.log(maintenance)
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const getCompany = () => {
    let company = maintenance?.info?.find(
      (c) => c.role === GLOBAL.USER_ROLE.FMC || c.role === GLOBAL.USER_ROLE.TPC
    );
    return company;
  };

  const getTechnician = () => {
    let tech = maintenance?.info?.find(
      (c) => c.role === GLOBAL.USER_ROLE.MT || c.role === GLOBAL.USER_ROLE.TPT
    );
    return tech;
  };
  // console.log(getCompany())
  const generateLogType = (item) => {
    const action = item?.action;
    const currentStatus = item?.current_status;
    const logType = item?.log_type;
    const requesterRole = item?.requester_role?.name;

    // Quotaion
    if (
      action === "Quoatation assigned To PM" ||
      (action === "Quoatation submitted succesfully" &&
        requesterRole === "Owner")
    ) {
      return "quotation";
    }

    // Task
    // if (action === "Waiting for tenant approval") {
    //   return "task";
    // }

    if (currentStatus === "tenant_approval_pending") {
      return "final_report";
    }
    return "simple";
  };
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="timeline-list-wrapper">
      {/* Time Line List */}
      {logs?.map((item, index) => (
        <div className="log-wrapper" key={index}>
          {/* Simple Log */}
          {generateLogType(item) === "simple" && (
            <SimpleLog
              isT1={isT1}
              isT2={isT2}
              status={status}
              maintenance={maintenance}
              item={item}
              takeAction={index === 0}
              maintenance_id={maintenance_id}
              property={property}
              user={user}
              assigned_to_role={maintenance.assigned_role.name}
              property_mgmt_model={maintenance.property_id?.mgmt_model}
              company={getCompany()}
              technician={getTechnician()}
            />
          )}

          {/* Maintenance Final Report Log */}
          {generateLogType(item) === "final_report" && (
            <MaintenanceFinalReportLog
              isT2={isT2}
              isT1={isT1}
              status={status}
              maintenance={maintenance}
              item={item}
              takeAction={index === 0}
              maintenance_id={maintenance_id}
              property={property}
              user={user}
            />
          )}

          {/* Quotation Log */}
          {generateLogType(item) === "quotation" && (
            <QuotationLog
              isT1={isT1}
              status={status}
              maintenance={maintenance}
              item={item}
              takeAction={index === 0}
              maintenance_id={maintenance_id}
              property={property}
              user={user}
              currency={currency}
            />
          )}

          {/* Task Log */}
          {generateLogType(item) === "task" && (
            <TaskLog
              isT2={isT2}
              isT1={isT1}
              status={status}
              maintenance={maintenance}
              item={item}
              takeAction={index === 0}
              maintenance_id={maintenance_id}
              property={property}
              user={user}
            />
          )}
        </div>
      ))}
    </div>
  );
};
/* Component ends */

export default TimeLineLists;
