/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useCookies } from "react-cookie";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Table } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { EyeFilledBlue } from "../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */

import GLOBAL from "../../../../../setup/constants/global";
import IconButton from "../../../../ui/button/IconButton/IconButton";
import Pagination from "../../../../ui/other/Pagination/Pagination";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const SecurityTable = (props) => {
  /* Props destructuring starts */
  const { complainList, totalCount, currentPage, setCurrentPage } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [highlightedItems, setHighlightedItems] = useState([]);
  const [cookies, setCookie] = useCookies(["openedItems"]);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  const dispatch = useDispatch();

  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  const getStatus = (status) => {
    let txt_status = status;
    let statusColor = "";
    switch (status) {
      case GLOBAL.SECURITY_STATUS.PENDING_SO_ASSIGNMENT:
      case GLOBAL.SECURITY_STATUS.PENDING_DECISION:
        statusColor = "font_yellow";
        txt_status = "Pending Decision";
        break;
      case GLOBAL.SECURITY_STATUS.ESCALATED:
        statusColor = "font_yellow";
        txt_status = "Escalated";
        break;
      case GLOBAL.SECURITY_STATUS.CLOSED:
        statusColor = "font_green";
        break;
      case GLOBAL.SECURITY_STATUS.SO_ASSIGNED:
        statusColor = "font_yellow";
        txt_status = "Security Officer Assigned";
        break;
      case GLOBAL.SECURITY_STATUS.RESOLVED:
        statusColor = "font_green";
        txt_status = "resolved";
        break;
    }
    return (
      <span className={`font_s fw_4 ${statusColor}`}>
        {txt_status.toUpperCase()}
      </span>
    );
  };

  // To View The Item Report
  const handleViewReport = async (id) => {
    // Add the clicked item to cookies
    const openedItems = cookies?.openedItems || [];
    if (!openedItems?.includes(id)) {
      openedItems?.push(id);
      setCookie("openedItems", openedItems);
    }

    navigate(`${id}`);
  };

  /* Component function definition ends */

  /* Component useEffect starts */

  useEffect(() => {
    // Update highlighted incidentList based on cookies
    const openedItems = cookies?.openedItems || [];
    const newItems = complainList?.filter(
      (item) => !openedItems?.includes(item._id)
    );

    setHighlightedItems(newItems);
  }, [complainList, cookies.openedItems]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */

  return (
    <>
      <div className="custom-table-wrapper security-table">
        {complainList?.length > 0 && (
          <Table className="custom-table font_s" borderless responsive>
            {/* Table Heading */}
            <thead>
              <tr>
                <th>ID</th>
                <th>Unit No.</th>
                <th>Tenant Name</th>
                <th>Type Of Unit</th>
                <th>Date & Time</th>
                <th>Type</th>
                {/* <th>Officer Name</th> */}
                <th>Status</th>
                <th className="text-end">Action</th>
              </tr>
            </thead>
            {/* Table Body */}
            {complainList?.length > 0 ? (
              <tbody>
                {complainList?.map((item, index) => (
                  <>
                    {highlightedItems?.includes(item) ? (
                      <>
                        <span className="font_xxl new-label"></span>
                        <tr key={index}>
                          {/* ID */}

                          <td>{item?.ticket_id}</td>

                          {/* Unit No */}
                          <td>{item?.unit_id?.unit_name}</td>

                          {/* Tenant Name */}
                          <td>
                            {item?.tenant_id?.first_name}{" "}
                            {item?.tenant_id?.last_name}
                          </td>

                          {/* Unit Type */}
                          <td>{item?.unit_id?.unit_type?.name}</td>

                          {/* Date & Time */}
                          <td>
                            {moment(item?.createdAt).format(DATE_TIME_FORMAT)}
                          </td>

                          {/* Subject */}
                          <td>{item?.security_complaint_id?.name}</td>

                          {/* Status */}
                          <td>{getStatus(item?.status)}</td>

                          {/* Actions */}
                          <td className="text-end txt-nowrap">
                            <IconButton
                              onClick={() => handleViewReport(item?._id)}
                              className="action-button"
                            >
                              <img
                                src={EyeFilledBlue}
                                className="h_100"
                                alt="View Details"
                              />
                            </IconButton>
                          </td>
                        </tr>
                      </>
                    ) : (
                      <>
                        <tr key={index}>
                          {/* ID */}

                          <td>{item?.ticket_id}</td>

                          {/* Unit No */}
                          <td>{item?.unit_id?.unit_name}</td>

                          {/* Tenant Name */}
                          <td>
                            {item?.tenant_id?.first_name}{" "}
                            {item?.tenant_id?.last_name}
                          </td>

                          {/* Unit Type */}
                          <td>{item?.unit_id?.unit_type?.name}</td>

                          {/* Date & Time */}
                          <td>
                            {moment(item?.createdAt).format(DATE_TIME_FORMAT)}
                          </td>

                          {/* Subject */}
                          <td>{item?.security_complaint_id?.name}</td>

                          {/* Status */}
                          <td>{getStatus(item?.status)}</td>

                          {/* Actions */}
                          <td className="text-end txt-nowrap">
                            <IconButton
                              onClick={() => handleViewReport(item?._id)}
                              className="action-button"
                            >
                              <img
                                src={EyeFilledBlue}
                                className="h_100"
                                alt="View Details"
                              />
                            </IconButton>
                          </td>
                        </tr>
                      </>
                    )}
                  </>
                ))}
              </tbody>
            ) : (
              ""
            )}
          </Table>
        )}

        {complainList?.length === 0 && (
          <div className=" text-center">
            <h3 className=" font_m font_yellow fw_6">No Data</h3>
          </div>
        )}
      </div>

      {/* PAGINATION */}
      {complainList?.length > 0 && (
        <Pagination
          itemsPerPage="10"
          totalItems={totalCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
    </>
  );
};
/* Component ends */
export default SecurityTable;
