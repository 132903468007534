/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  getIsACC,
  getIsOwner,
} from "../../../../../setup/store/slices/authSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Table } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import {
  EditGreen,
  EyeFilledBlue,
} from "../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../../../ui/button/IconButton/IconButton";
import SliderButton from "../../../../ui/button/SliderButton/SliderButton";
import DetailViewModal from "./DetailViewModal/DetailViewModal";
import PaymentModal from "../../../Invoicing/ClientInvoicing/PaymentModal/PaymentModal";
import { fetchTenantDepositDetails } from "../../../../../setup/store/slices/tenatDetailSlice";
import { useParams } from "react-router-dom";
/* Import local pages and component ends */

/* Component starts */
const Deposits = (props) => {
  /* Props destructuring starts */
  const { depositList, property } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [showContractDetailModal, setShowContractDetailModal] = useState(false);
  const [detail, setDetail] = useState({});
  const [invoiceData, setInvoiceData] = useState();
  const [showModal, setShowModal] = useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const isAccountant = useSelector(getIsACC);
  const isOwner = useSelector(getIsOwner);
  const { tenantId, unitId } = useParams();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const handleView = (item) => {
    setDetail(item);
    setShowContractDetailModal(!showContractDetailModal);
  };

  const handlePaymentModal = (item) => {
    setInvoiceData(item);
    setShowModal(!showModal);
  };

  const onClose = () => {
    setShowModal(!showModal);
  };

  const onSuccess = () => {
    setShowModal(!showModal);
  };

  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="custom-table-wrapper deposit-table">
      {depositList?.length > 0 && (
        <Table className="custom-table font_s" borderless responsive>
          {/* Table Heading */}
          <thead>
            <tr>
              <th>Amount </th>
              <th>Deposit Description</th>
              <th>Deposit Date</th>
              <th>Payment Method</th>
              <th>Status</th>
              <th>Release</th>
              <th className="text-start">Action</th>
            </tr>
          </thead>
          {/* Table Body */}
          <tbody>
            {depositList?.map((item) => (
              <tr key={item?.id}>
                {/* Amount*/}
                <td>
                  {item?.total_amount?.toLocaleString("en-US")}{" "}
                  {property?.currency}
                  {/* {formatCurrency(item?.total_amount, property?.currency)} */}
                </td>

                {/* Deposit Description */}
                <td>
                  {item?.description !== undefined ? item?.description : "-"}
                </td>

                {/* Item Deposit Date */}
                <td>{moment(item?.due_date).format(DATE_FORMAT)}</td>

                {/* Item Pay Method */}
                <td>
                  {item?.method !== undefined
                    ? item?.method?.charAt(0).toUpperCase() +
                      item?.method?.slice(1)
                    : "-"}
                </td>

                {/* Status */}
                <td
                  className={
                    item?.status === "paid" ||
                    item?.status === "collected" ||
                    item?.status === "settled"
                      ? "font_green"
                      : item?.status === "void"
                      ? "font_red"
                      : item?.status === "modified"
                      ? "modified"
                      : "font_yellow"
                  }
                >
                  {/* {item?.status?.charAt(0).toUpperCase() +
                    item?.status?.slice(1) */}
                  {item?.status === "paid" && "Paid"}
                  {item?.status === "unpaid" && "Unpaid"}
                  {item?.status === "collected" && "Collected"}
                  {item?.status === "partially_collected" &&
                    "Partially Collected"}
                  {item?.status === "not_collected" && "Not Collected"}
                  {item?.status === "partially_settled" && "Partially Settled"}
                  {item?.status === "settled" && "Settled"}
                  {item?.status === "void" && "Void"}

                  {/* } */}
                </td>

                <td>
                  <SliderButton
                    active={
                      (item?.status === "adjusted" ||
                        item?.status === "settled") &&
                      item?.type === "deposit"
                    }
                    // disabled={disabledStatusSwitch(item?.status)}
                    // onChange={(currentState) =>
                    //   toggleActiveSuspend(item, currentState)
                    // }
                  />
                </td>

                {/* Actions */}
                <td className="text-start txt-nowrap">
                  <IconButton
                    onClick={() => handleView(item)}
                    className="action-button"
                  >
                    <img
                      src={EyeFilledBlue}
                      className="h_100"
                      alt="View Details"
                    />
                  </IconButton>

                  {((isOwner && property?.mgmt_model === 0) ||
                    (isAccountant && property?.mgmt_model === 2)) &&
                    (item?.status === "not_collected" ||
                      item?.status === "partially_collected" ||
                      item?.status === "unpaid" ||
                      item?.status === "partial paid") &&
                    !item?.is_payable_by_cheque && (
                      <IconButton
                        onClick={() => handlePaymentModal(item)}
                        className="action-button icon-m"
                      >
                        <img
                          // src={EditWhite}
                          src={EditGreen}
                          className="h_100 mx-2"
                          alt="View Details"
                        />
                      </IconButton>
                    )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      {depositList?.length === 0 && (
        <div className=" text-center">
          <h3 className=" font_m font_yellow fw_6">No Data</h3>
        </div>
      )}

      {/* MODALS */}
      <PaymentModal
        showModal={showModal}
        onClose={onClose}
        onSuccess={onSuccess}
        invoiceData={invoiceData}
        property_id={property?._id}
        afterPayment={() => {
          dispatch(fetchTenantDepositDetails({ tenantId, unitId }));
        }}
      />

      {/* Rent detail modal */}
      <DetailViewModal
        type="Deposit"
        showModal={showContractDetailModal}
        detail={detail}
        onClose={() => {
          setShowContractDetailModal(false);
        }}
      />
    </div>
  );
};
/* Component ends */

export default Deposits;
