/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { FieldArray, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { fieldPropExtractor, initialValues, validations } from "./helper";

/* Import configuration ends */

/* Import redux slices component starts */
import {
  fetchFacilityType,
  getFacilityType,
} from "../../../../setup/store/slices/facilitySlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */

/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */

import TextButton from "../../../ui/button/TextButton/TextButton";
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
import CustomSelectInput from "../../../ui/input/CustomSelectInput/CustomSelectInput";
import CustomRadioInput from "../../../ui/input/CustomRadioInput/CustomRadioInput";
import CustomTextArea from "../../../ui/input/CustomTextArea/CustomTextArea";
import CustomTextInput from "../../../ui/input/CustomTextInput/CustomTextInput";
import CustomFileInput from "../../../ui/input/CustomFileInput/CustomFileInput";
/* Import local pages and component ends */

// Styles
import "./style.scss";

/* Component starts */
const AddFacility = (props) => {
  /* Props destructuring starts */
  const { onCancel } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [initialFormValues, setInitialFormValues] = useState(initialValues);
  const [facilityType, setFacilityType] = useState("paid");

  const [approval, setApproval] = useState("yes");
  const [type, setType] = useState("predefined");

  //   const [showErrorModal, setShowErrorModal] = useState(false);
  //   const [showSuccessModal, setShowSuccessModal] = useState(false);
  //   const [modalDesc, setModalDesc] = useState("");

  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const facilityTypeList = useSelector(getFacilityType);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */

  const subPeriod = [
    { id: 1, name: "Weekly" },
    { id: 1, name: "Monthly" },
    { id: 1, name: "Yearly" },
  ];

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  // On adding photo field
  const onAddPhoto = (callBackPush) => {
    callBackPush(null);
  };

  // On removing last photo field
  const onRemovePhoto = (callBackPop) => {
    callBackPop();
  };

  const formValidation = (values) => {
    return validations(values);
  };

  const onSubmit = (values) => {
    const payload = {
      document: [],
    };

    values.photos.forEach((i) => {
      if (i) {
        payload["document"]?.push(i);
      }
    });

    // console.log(payload, "PAY");

    // // Backend response. Try, Catch
    // try {
    //   const result = await dispatch(addSecurityIncident(payload));

    //   // Handling success response
    //   switch (result.meta.requestStatus) {
    //     case GLOBAL.REJECTED:
    //       setModalDesc(result.payload);
    //       setShowErrorModal(true);
    //       break;

    //     case GLOBAL.FULFILLED:
    //       setModalDesc("Security Incident Created Successfully");
    //       setShowSuccessModal(true);
    //       break;
    //   }
    // } catch (error) {
    //   // Handling error response
    //   console.error(error.message);
    // }

    console.log("VAL", payload);
  };

  /* Component function definition ends */

  /* Component useEffect starts */

  useEffect(() => {
    dispatch(fetchFacilityType());
  }, []);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="add-facility-wrapper">
      <h2 className="heading text-center font_xxl mb-5">Add Facility</h2>

      <div className="bg_grey type-selection">
        <div className="form-wrapper">
          {/* Creating new Security Incident */}
          <>
            <div className="form-group form-group-full w_100">
              <CustomRadioInput
                label="Pre-defined"
                onSelecting={() => {
                  setType("predefined");
                }}
                isSelected={type === "predefined"}
              />

              <CustomRadioInput
                label="Custom"
                onSelecting={() => {
                  setType("custom");
                }}
                isSelected={type === "custom"}
              />
            </div>
          </>
        </div>
      </div>

      <div className="add-new-form">
        <Formik
          onSubmit={onSubmit}
          initialValues={initialFormValues}
          validate={formValidation}
          validateOnBlur={true}
          enableReinitialize={true}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="form-wrapper">
                {/* Creating new Security Incident */}
                <>
                  {/* Radio select the Member */}
                  <div className="form-group form-group-full w_100">
                    <p className="font_m font_grey w_100">
                      Is this facility paid or free?{" "}
                      <span className="font_xs font_red">*</span>
                    </p>

                    <CustomRadioInput
                      label="Paid"
                      onSelecting={() => {
                        setFacilityType("paid");
                      }}
                      isSelected={facilityType === "paid"}
                    />

                    <CustomRadioInput
                      label="Free"
                      onSelecting={() => {
                        setFacilityType("free");
                      }}
                      isSelected={facilityType === "free"}
                    />
                  </div>

                  <div className="form-group form-group-full w_100">
                    <p className="font_m font_grey w_100">
                      Does this facility require prior approval from facility
                      manager?
                    </p>

                    <CustomRadioInput
                      label="Yes"
                      onSelecting={() => {
                        setApproval("yes");
                      }}
                      isSelected={approval === "yes"}
                    />

                    <CustomRadioInput
                      label="No"
                      onSelecting={() => {
                        setApproval("no");
                      }}
                      isSelected={approval === "no"}
                    />
                  </div>

                  {/* Select Facility */}
                  {type === "custom" ? (
                    <div className="form-group">
                      <CustomTextInput
                        name="deposit"
                        label="Deposit"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        touched={touched.deposit}
                        errors={errors.deposit}
                        value={values.deposit}
                      />
                    </div>
                  ) : (
                    <div className="form-group">
                      <CustomSelectInput
                        name="requested_member"
                        label="Facility Name"
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        valueExtractor={(item) => item?.name}
                        // setValue={setSelectedTenant}
                        options={facilityTypeList}
                        value={values.requested_member}
                        formikState={true}
                        emptyOption={false}
                        // touched={touched.gender}
                        // errors={errors.gender}
                      />
                    </div>
                  )}

                  {/* Input for Deposit */}
                  {facilityType === "paid" ? (
                    <>
                      <div className="form-group">
                        <CustomTextInput
                          name="deposit"
                          label="Deposit"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          touched={touched.deposit}
                          errors={errors.deposit}
                          value={values.deposit}
                        />
                      </div>

                      <div className="form-group form-group-full">
                        <CustomTextArea
                          name="description"
                          label="Enter Description"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          value={values.description}
                          errors={errors.description}
                          touched={touched.description}
                          required={true}
                        />
                      </div>

                      <div className="form-group">
                        <CustomTextInput
                          name="sub_term"
                          label="Enter Subscription Term"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          touched={touched.sub_term}
                          errors={errors.sub_term}
                          value={values.sub_term}
                          required={true}
                        />
                      </div>

                      <div className="form-group">
                        <CustomSelectInput
                          name="period"
                          label="Select Subscription Period"
                          handleBlur={handleBlur}
                          onChange={handleChange}
                          valueExtractor={(item) => item?.name}
                          // setValue={setSelectedLocation}
                          options={subPeriod}
                          // value={selectedLocation}
                          formikState={false}
                          emptyOption={false}
                        />
                      </div>

                      <div className="form-group">
                        <CustomTextInput
                          name="sub_fee"
                          label="Enter Subscription Fee"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          touched={touched.sub_fee}
                          errors={errors.sub_fee}
                          value={values.sub_fee}
                          required={true}
                        />
                      </div>

                      <FieldArray
                        name="photos"
                        render={({ push, pop }) => (
                          <div className="form-group">
                            {/* Upload Image List */}
                            {values?.photos?.map((item, index) => (
                              <div
                                key={index}
                                className={index >= 1 ? "add-image" : ""}
                              >
                                <CustomFileInput
                                  name={`photos[${index}]`}
                                  label="Upload Image/Video"
                                  setFieldValue={setFieldValue}
                                  setFieldTouched={setFieldTouched}
                                  value={values.photos[index]}
                                  formikState={true}
                                  touched={fieldPropExtractor(touched, index)}
                                  validaterror={fieldPropExtractor(
                                    errors,
                                    index
                                  )}
                                  required={false}
                                />
                              </div>
                            ))}

                            <div className="action-button text-center">
                              {values.photos && (
                                <TextButton
                                  variant="outline"
                                  size="m"
                                  className="txtBtn"
                                  onClick={() => onAddPhoto(push)}
                                >
                                  + Add More Images/Videos
                                </TextButton>
                              )}

                              {values.photos.length > 1 && (
                                <CustomButton
                                  className="remove"
                                  // variant="outline-none"
                                  size="m"
                                  onClick={() => onRemovePhoto(pop)}
                                >
                                  Remove
                                </CustomButton>
                              )}
                            </div>
                          </div>
                        )}
                      />
                    </>
                  ) : (
                    <>
                      <div className="form-group form-group-full">
                        <CustomTextArea
                          name="description"
                          label="Enter Description"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          value={values.description}
                          errors={errors.description}
                          touched={touched.description}
                          required={true}
                        />
                      </div>

                      <FieldArray
                        name="photos"
                        render={({ push, pop }) => (
                          <div className="form-group">
                            {/* Upload Image List */}
                            {values?.photos?.map((item, index) => (
                              <div
                                key={index}
                                className={index >= 1 ? "add-image" : ""}
                              >
                                <CustomFileInput
                                  name={`photos[${index}]`}
                                  label="Upload Image/Video"
                                  setFieldValue={setFieldValue}
                                  setFieldTouched={setFieldTouched}
                                  value={values.photos[index]}
                                  formikState={true}
                                  touched={fieldPropExtractor(touched, index)}
                                  validaterror={fieldPropExtractor(
                                    errors,
                                    index
                                  )}
                                  required={false}
                                />
                              </div>
                            ))}

                            <div className="action-button text-center">
                              {values.photos && (
                                <TextButton
                                  variant="outline"
                                  size="m"
                                  className="txtBtn"
                                  onClick={() => onAddPhoto(push)}
                                >
                                  + Add More Images/Videos
                                </TextButton>
                              )}

                              {values.photos.length > 1 && (
                                <CustomButton
                                  className="remove"
                                  // variant="outline-none"
                                  size="m"
                                  onClick={() => onRemovePhoto(pop)}
                                >
                                  Remove
                                </CustomButton>
                              )}
                            </div>
                          </div>
                        )}
                      />
                    </>
                  )}
                </>
              </div>

              <div className="button-wrapper text-center">
                <>
                  <CustomButton type="submit" size="l">
                    Add
                  </CustomButton>
                  <TextButton
                    type="button"
                    onClick={onCancel}
                    className="font_m fw_6"
                  >
                    Cancel
                  </TextButton>
                </>
              </div>
            </form>
          )}
        </Formik>
      </div>

      {/* Success Modal */}
      {/* <SuccessModal
        showModal={showSuccessModal}
        description={modalDesc}
        title="Successfully Created"
        onClose={() => {
          onSuccess();
          setShowSuccessModal(false);
        }}
      /> */}

      {/* Error Modal */}
      {/* <ErrorModal
        showModal={showErrorModal}
        description={modalDesc}
        title="Error"
        onClose={() => setShowErrorModal(false)}
      /> */}
    </div>
  );
};
/* Component ends */

export default AddFacility;
