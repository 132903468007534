/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";
/* Import configuration ends */

/* Import redux slices component starts */

/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Table } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { EyeFilledBlue } from "../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import GLOBAL from "../../../setup/constants/global";
import IconButton from "../../../components/ui/button/IconButton/IconButton";
import CheckboxMultiSelectDropdown from "../../../components/ui/input/CheckboxMultiSelectDropdown/CheckboxMultiSelectDropdown";
/* Import local pages and component ends */

/* Component starts */
const ContractTable = (props) => {
  /* Props destructuring starts */
  const {
    contractListData,
    multiSelectCommunity,
    setMultiSelectCommunity,
    communityData,
    propertyData,
    multiSelectProperty,
    setMultiSelectProperty,
    unitType,
    multiSelectUnitType,
    setMultiSelectUnitType,

    unitNum,
    multiSelectUnitNum,
    setMultiSelectUnitNum,

    installment,
    multiSelectInstallment,
    setMultiSelectInstallment,

    amount,
    multiSelectAmount,
    setMultiSelectAmount,

    tenant,
    multiSelectTenant,
    setMultiSelectTenant,
    statusData1,
    multiSelectStatus,
    setMultiSelectStatus,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */

  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  //   const dispatch = useDispatch();

  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  // To View The Item Report
  const handleViewReport = async (item) => {
    if (item?.community_id) {
      navigate(
        `/communities/${item?.community_id}/properties/${item?.property_id}/tenants/detail/${item?.tenant_id}?unit_id=${item?.unit_id}`
      );
    } else {
      navigate(
        `/independent/properties/${item?.property_id}/tenants/detail/${item?.tenant_id}?unit_id=${item?.unit_id}`
      );
    }
  };

  /* Component function definition ends */

  /* Component useEffect starts */

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */

  return (
    <div className="custom-table-wrapper contract-table">
      {contractListData?.length > 0 && (
        <Table className="custom-table font_s" borderless responsive>
          {/* Table Heading */}
          <thead>
            <tr>
              <th>
                <CheckboxMultiSelectDropdown
                  multiSelectData={multiSelectCommunity}
                  setMultiSelectData={setMultiSelectCommunity}
                  optionLabel={communityData}
                  label={"Community"}
                />
              </th>
              <th>
                <CheckboxMultiSelectDropdown
                  multiSelectData={multiSelectProperty}
                  setMultiSelectData={setMultiSelectProperty}
                  optionLabel={propertyData}
                  label={"Property"}
                />
              </th>
              <th>
                <CheckboxMultiSelectDropdown
                  multiSelectData={multiSelectUnitNum}
                  setMultiSelectData={setMultiSelectUnitNum}
                  optionLabel={unitNum}
                  label={"Unit No."}
                />
              </th>
              <th>
                <CheckboxMultiSelectDropdown
                  multiSelectData={multiSelectUnitType}
                  setMultiSelectData={setMultiSelectUnitType}
                  optionLabel={unitType}
                  label={"Unit Type"}
                />
              </th>
              <th>
                <CheckboxMultiSelectDropdown
                  multiSelectData={multiSelectTenant}
                  setMultiSelectData={setMultiSelectTenant}
                  optionLabel={tenant}
                  label={"Tenant"}
                />
              </th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Days Elapsed</th>
              <th>
                <CheckboxMultiSelectDropdown
                  multiSelectData={multiSelectInstallment}
                  setMultiSelectData={setMultiSelectInstallment}
                  optionLabel={installment}
                  label={"Installments"}
                />
              </th>
              <th>
                <CheckboxMultiSelectDropdown
                  multiSelectData={multiSelectAmount}
                  setMultiSelectData={setMultiSelectAmount}
                  optionLabel={amount}
                  label={"Amount"}
                />
              </th>
              <th>
                <CheckboxMultiSelectDropdown
                  multiSelectData={multiSelectStatus}
                  setMultiSelectData={setMultiSelectStatus}
                  optionLabel={statusData1}
                  label={"Status"}
                />
              </th>
              <th className="text-start">Action</th>
            </tr>
          </thead>
          {/* Table Body */}
          {contractListData?.length > 0 && (
            <tbody>
              {contractListData?.map((item, index) => (
                <tr key={index}>
                  {/* Community Name */}
                  <td>
                    {item?.community_name !== undefined
                      ? item?.community_name
                      : "-"}
                  </td>

                  {/* Property Name */}
                  <td>
                    {item?.property_name !== undefined
                      ? item?.property_name
                      : "-"}
                  </td>

                  {/* Unit Number */}
                  <td>{item?.unit_number}</td>

                  {/* type */}
                  <td>{item?.type}</td>

                  {/* Tenant Name */}
                  <td>{item?.tenant}</td>

                  {/* Start Date */}
                  <td>
                    {item?.start_date !== undefined
                      ? moment(item?.start_date).format(DATE_FORMAT)
                      : "-"}
                  </td>

                  {/* End Date */}
                  <td>
                    {item?.end_date !== undefined
                      ? moment(item?.end_date).format(DATE_FORMAT)
                      : "-"}
                  </td>

                  {/* daysElapsed */}
                  <td>
                    {item?.daysElapsed !== "NA" ? item?.daysElapsed : "-"}
                  </td>

                  {/* Installments */}
                  <td>{item?.installments}</td>

                  {/* Amount */}
                  <td>{item?.amount}</td>

                  {/* Status */}
                  <td
                    className={
                      item?.status === "renewed"
                        ? "font_blue"
                        : item?.status === "approved"
                        ? "font_green"
                        : item?.status === ("expired" || "canceled")
                        ? "font_red"
                        : "font_yellow"
                    }
                  >
                    {item?.status?.charAt(0).toUpperCase() +
                      item?.status?.slice(1)}
                  </td>

                  {/* Actions */}
                  <td className="text-start txt-nowrap">
                    <IconButton
                      onClick={() => handleViewReport(item)}
                      className="action-button"
                    >
                      <img
                        src={EyeFilledBlue}
                        className="h_100"
                        alt="View Details"
                      />
                    </IconButton>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </Table>
      )}

      {contractListData?.length === 0 && (
        <div className=" text-center">
          <h3 className=" font_m font_yellow fw_6">No Data</h3>
        </div>
      )}
    </div>
  );
};
/* Component ends */
export default ContractTable;
