/**
 *
 */

import React from "react";

/* Import configuration starts */
import moment from "moment";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import GLOBAL from "../../../../setup/constants/global";
/* Import local pages and component ends */

/* Component starts */
const Report = (props) => {
  /* Props destructuring starts */
  const { incident } = props;
  console.log(incident, "[inc]");
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */

  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="visitor-unit-detail-box">
      <div className="log-header">
        <div className="img-wrapper">
          <img src={incident?.image} alt="Visitor-Pass" />
        </div>

        <div className="detail">
          <h3 className="num font_s fw_4 font_grey">
            {" "}
            Ticket No. :-{" "}
            <span className="fw_6 font_blue">{incident?.visitorpassId}</span>
          </h3>
          <h4 className="fw_6 font_l font_blue">
            <span>{incident?.guest_name}</span>
          </h4>
          <p className="font_s font_grey">
            Type :- <span className="font_s fw_6">{incident?.type}</span>
          </p>
        </div>
      </div>

      <hr />

      <div className="visitor-info">
        <div>
          <p className="fw_4 font_m font_grey">
            Mobile Number <br />
            <span className="fw_4 font_m">{incident?.mobile_number}</span>
          </p>
        </div>

        <div className="reason-info">
          <p className="fw_4 font_m font_grey">
            Reason For Access
            <br />
            <span className="fw_6 font_m">
              {incident?.guest_type !== undefined ? incident?.guest_type : "-"}
            </span>
          </p>
        </div>

        <div className="reason-info">
          <p className="fw_4 font_m font_grey">
            License Plate Number
            <br />
            <span className="fw_6 font_m">{incident?.car_number}</span>
          </p>
        </div>
      </div>

      <hr />

      <div className="time-info">
        <div>
          <p className="fw_4 font_m font_grey">
            Start Date
            <br />
            <span className="fw_4 font_m">
              {moment(incident?.start_date).format(DATE_TIME_FORMAT)}
            </span>
          </p>
        </div>
        <div>
          <p className="fw_4 font_m font_grey">
            End Date
            <br />
            <span className="fw_4 font_m">
              {moment(incident?.end_date).format(DATE_TIME_FORMAT)}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};
/* Component Ends */
export default Report;
