/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../../setup/store/slices/globalAlertSlice";
import { tenantRejectRenewal } from "../../../../setup/store/slices/contractSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import GLOBAL from "../../../../setup/constants/global";
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
import TextButton from "../../../ui/button/TextButton/TextButton";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const TenantRenewalConsent = (props) => {
  /* Props destructuring starts */
  const { onNext, old_contract_id, onCancelRenewal } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  const dispatch = useDispatch();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // const handleRejectRenewal = async () => {
  //   let payload = {
  //     contract_id: old_contract_id,
  //   };
  //   // Backend response. Try, Catch
  //   try {
  //     const result = await dispatch(tenantRejectRenewal(payload));

  //     // Handling success response
  //     switch (result.meta.requestStatus) {
  //       case GLOBAL.REJECTED:
  //         dispatch(
  //           showErrorAlert({
  //             title: "Error!",
  //             description: result.payload,
  //           })
  //         );
  //         break;

  //       case GLOBAL.FULFILLED:
  //         dispatch(
  //           showSuccessAlert({
  //             title: "Success!",
  //             description: result.payload.message,
  //           })
  //         );
  //         navigate(-1);
  //         break;
  //     }
  //   } catch (error) {
  //     // Handling error response
  //     console.error(error.message);
  //   }
  // };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="tenant-renewal-consent container_sm">
      <h3 className="font_m font_yellow fw_6 text-center card-wrapper bg_grey">
        Your contract is due for renewal, are you happy with staying with us and
        extending a stay?
      </h3>

      <div className="submit-cancel-btn-wrapper">
        <CustomButton onClick={onNext} size="l" className="">
          Yes
        </CustomButton>
        <TextButton onClick={onCancelRenewal}>No</TextButton>
      </div>
    </div>
  );
};
/* Component ends */

export default TenantRenewalConsent;
