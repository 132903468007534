/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import moment from "moment";
import { Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  fetchTenantInvoiceById,
  getTenantInvoice,
} from "../../../../../../setup/store/slices/invoiceSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import {
  DocumentGreen,
  EyeFilledBlue,
  LocationPinFilledGrey,
  PrintIcon,
} from "../../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import FileViewerModal from "../../../../../ui/modal/FileViewerModal/FileViewerModal";
import IconButton from "../../../../../ui/button/IconButton/IconButton";
import InvoiceDetailModal from "../../../ClientInvoicing/InvoicingDetails/InvoiceDetailModal/InvoiceDetailModal";

/* Import local pages and component ends */

/* Component starts */
const InvoiceReceiptDetail = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [showFileViewer, setShowFileViewer] = useState(false);
  const [file, setFile] = useState({});

  const [showContractDetailModal, setShowContractDetailModal] = useState(false);
  const [detail, setDetail] = useState({});
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const { id } = useParams();
  const tenantInvoice = useSelector(getTenantInvoice);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const handleView = (item) => {
    setDetail(item);
    setShowContractDetailModal(!showContractDetailModal);
  };

  function printPDF(url) {
    // Open PDF in a new window/tab
    const newWindow = window.open(url);

    // Wait for the new window/tab to load
    newWindow.onload = function () {
      // Trigger print dialog
      newWindow.print();
    };
  }

  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    if (id) {
      dispatch(fetchTenantInvoiceById(id));
    }
  }, [id]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      <h1 className="text-center font_xxl mb-4">Receipt</h1>

      <div className="detail-card bg_grey">
        <div className="d-flex justify-content-between">
          <p>
            Invoice#{" "}
            <span className="font_blue fw_6">
              {tenantInvoice?.invoice?.invoice_num}
            </span>
          </p>
          <p className="font_m fw_3 font_grey">
            Type :
            <span className="font_m fw_6 font_yellow">
              {" "}
              {tenantInvoice?.invoice?.type?.charAt(0).toUpperCase() +
                tenantInvoice?.invoice?.type?.slice(1)}
            </span>
          </p>
        </div>

        <hr />

        <div className="property-wrapper">
          <div className="property-data">
            <div>
              <span className="font_m fw_6 font_blue">
                {tenantInvoice?.invoice?.property?.property_name}
              </span>
            </div>

            <div>
              <p className="font_m fw_3 font_grey">
                Unit :{" "}
                <span className="font_m fw_6 font_blue">
                  {tenantInvoice?.invoice?.unit}
                </span>
              </p>
            </div>
          </div>

          <div className="property-data">
            <div>
              <img src={LocationPinFilledGrey} alt="Location-Pin" />
              <span className="font_s fw_4 font_grey">
                {tenantInvoice?.invoice?.property?.city},{" "}
                {tenantInvoice?.invoice?.property?.state},{" "}
                {tenantInvoice?.invoice?.property?.country}
              </span>
            </div>

            <div>
              <p className="font_m fw_3 font_grey">
                Tenant Name :{" "}
                <span className="font_m fw_6 font_blue">
                  {tenantInvoice?.invoice?.tenant}
                </span>
              </p>
            </div>
          </div>
        </div>

        <hr />

        <div className="invoice-data">
          <p className="font_m fw_3 font_grey">
            Invoice Date <br />
            <span className="font_m fw_4">
              {moment(tenantInvoice?.invoice?.invoice_date).format(DATE_FORMAT)}
            </span>
          </p>

          <p className="font_m fw_3 font_grey">
            Due Date <br />
            <span className="font_m fw_4">
              {moment(tenantInvoice?.invoice?.due_date).format(DATE_FORMAT)}
            </span>
          </p>
        </div>

        <hr />

        <div className="status-wrapper">
          <p className="font_m fw_4">Status</p>
          <p
            className={`font_m fw_6
        ${
          tenantInvoice?.invoice?.status === "paid" ||
          tenantInvoice?.invoice?.status === "collected"
            ? "font_green"
            : tenantInvoice?.invoice?.status === "unpaid" ||
              tenantInvoice?.invoice?.status === "not_collected"
            ? "font_yellow"
            : "font_red"
        }
        `}
          >
            {tenantInvoice?.invoice?.status === "collected"
              ? "Collected"
              : tenantInvoice?.invoice?.status === "partially_collected"
              ? "Partially Collected"
              : tenantInvoice?.invoice?.status === "not_collected"
              ? "Not Collected"
              : tenantInvoice?.invoice?.status === "partially_settled"
              ? "Partially Settled"
              : tenantInvoice?.invoice?.status === "settled"
              ? "Settled"
              : tenantInvoice?.invoice?.status?.charAt(0).toUpperCase() +
                tenantInvoice?.invoice?.status?.slice(1)}
          </p>
        </div>

        <hr />

        {/* <div className="payment-data">
          <p className="font_m fw_3">Invoice Amount</p>

          <p>
            <span className="font_s fw_4">
              Amount :{" "}
              {tenantInvoice?.invoice?.amount !== undefined
                ? tenantInvoice?.invoice?.amount?.toLocaleString("en-US")
                : "0"}
            </span>
            <br />
            <span className="font_s fw_4">
              VAT:{" "}
              {tenantInvoice?.invoice?.vat_amount !== undefined
                ? tenantInvoice?.invoice?.vat_amount?.toFixed(2)
                : "0"}
            </span>
            <br />
            <span className="font_s fw_4">
              Tax:{" "}
              {tenantInvoice?.invoice?.tax_amount !== undefined
                ? tenantInvoice?.invoice?.tax_amount?.toFixed(2)
                : "0"}
            </span>
            <hr />
            <span className="font_s fw_4">
              Total :{" "}
              <span className="font_m fw_6 font_blue">
                {tenantInvoice?.invoice?.total !== undefined
                  ? `${tenantInvoice?.invoice?.total?.toLocaleString(
                      "en-US"
                    )}${" "}${tenantInvoice?.invoice?.currency}`
                  : "-"}
              </span>
            </span>
          </p>
        </div> */}

        <div className="invoice-data-price">
          {/* <p className="font_m fw_4 font_grey">
            Description <br />
            <span className="font_xs fw_3">
              {tenantInvoice?.invoice?.description !== undefined
                ? tenantInvoice?.invoice?.description
                : "-"}
            </span>
          </p> */}

          {tenantInvoice?.invoice?.type !== "rent" && (
            <p className="font_m fw_4 font_grey">
              Description <br />
              <span className="font_xs fw_3">
                {tenantInvoice?.invoice?.description !== undefined
                  ? tenantInvoice?.invoice?.description
                  : "-"}
              </span>
            </p>
          )}

          {tenantInvoice?.invoice?.type === "rent" && (
            <p className="font_m fw_4 font_grey">
              Description <br />
              <span className="font_m fw_6">
                {tenantInvoice?.invoice?.instaName !== undefined
                  ? tenantInvoice?.invoice?.instaName
                  : "-"}
              </span>
            </p>
          )}

          <p className="font_m fw_4 font_grey">
            Quantity <br />
            <span className="font_m fw_4">
              {tenantInvoice?.invoice?.quantity !== undefined
                ? tenantInvoice?.invoice?.quantity
                : "1"}
            </span>
          </p>

          <div>
            <p className="font_m fw_4 font_grey">
              Invoice Amount <br />
            </p>
            <p>
              <span className="font_s fw_4">
                Amount :{" "}
                {tenantInvoice?.invoice?.amount?.toLocaleString("en-US")}
              </span>
              <br />
              <span className="font_s fw_4">
                VAT:{" "}
                {tenantInvoice?.invoice?.vat_amount
                  ?.toFixed(2)
                  ?.toLocaleString("en-US")}
              </span>
              <br />
              <span className="font_s fw_4">
                Tax:{" "}
                {tenantInvoice?.invoice?.tax_amount
                  ?.toFixed(2)
                  ?.toLocaleString("en-US")}
              </span>
              <hr />
              <span className="font_s fw_4">
                Total :{" "}
                <span className="font_m fw_6 font_blue">
                  {tenantInvoice?.invoice?.total !== undefined
                    ? `${tenantInvoice?.invoice?.total?.toLocaleString(
                        "en-US"
                      )}${" "}${tenantInvoice?.invoice?.currency}`
                    : "-"}
                </span>
              </span>
            </p>
          </div>
        </div>

        {tenantInvoice?.invoice?.invoice_pdf && (
          <>
            <hr />
            <div className="doc-wrapper">
              <p className="font_m fw_4 pr-2">Invoice</p>

              {tenantInvoice?.invoice?.invoice_pdf && (
                <>
                  <div className="doc">
                    <img
                      src={DocumentGreen}
                      onClick={() => {
                        setFile(tenantInvoice?.invoice?.invoice_pdf);
                        setShowFileViewer(true);
                      }}
                    />
                    <img
                      src={PrintIcon}
                      onClick={() => {
                        printPDF(tenantInvoice?.invoice?.invoice_pdf?.url);
                      }}
                    />
                  </div>
                </>
              )}
            </div>

            {(tenantInvoice?.invoice?.status === "paid" ||
              tenantInvoice?.invoice?.status === "collected") && (
              <div className="doc-wrapper mt-2">
                <p className="font_m fw_4">Receipt</p>

                {tenantInvoice?.invoice?.receiptPayment_pdf ? (
                  <>
                    <div className="doc">
                      <img
                        src={DocumentGreen}
                        onClick={() => {
                          setFile(tenantInvoice?.invoice?.receiptPayment_pdf);
                          setShowFileViewer(true);
                        }}
                      />{" "}
                      <img
                        src={PrintIcon}
                        onClick={() => {
                          printPDF(
                            tenantInvoice?.invoice?.receiptPayment_pdf?.url
                          );
                        }}
                      />
                    </div>
                  </>
                ) : (
                  <p>No Receipt</p>
                )}
              </div>
            )}
          </>
        )}

        {tenantInvoice?.invoice?.invoice_transaction?.length > 0 && (
          <>
            <hr />

            <div className="payment-trans-data">
              <p className="font_l fw_4">Payment Details</p>

              <div className="trans-table">
                <Table className="custom-table font_s" borderless responsive>
                  {/* Table Heading */}
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Amount</th>
                      <th>Method</th>
                      <th>Date</th>
                      <th>Status</th>
                      {tenantInvoice?.invoice?.method !== "online" && (
                        <th>Received By</th>
                      )}
                      <th>Actions</th>
                    </tr>
                  </thead>
                  {/* Table Body */}
                  <tbody>
                    {tenantInvoice?.invoice?.invoice_transaction?.map(
                      (trans, idx) => (
                        <tr key={trans?._id}>
                          {/* ID */}
                          <td>{idx + 1}</td>

                          {/* Payment Amount */}
                          <td>
                            {`${trans?.total_amount?.toLocaleString("en-US")} ${
                              tenantInvoice?.invoice?.currency
                            }` ||
                              `${trans?.cheque_amount?.toLocaleString(
                                "en-US"
                              )} ${tenantInvoice?.invoice?.currency}`}
                          </td>

                          {/* Payment Method */}
                          <td className="font_blue">
                            {trans?.payment_method?.charAt(0).toUpperCase() +
                              trans?.payment_method?.slice(1) ||
                              tenantInvoice?.invoice?.method
                                ?.charAt(0)
                                .toUpperCase() +
                                tenantInvoice?.invoice?.method?.slice(1)}
                          </td>

                          {/* Date */}

                          <td>
                            {moment(trans?.createdAt).format(DATE_TIME_FORMAT)}
                          </td>

                          {/* STATUS */}
                          <td>
                            {trans?.status?.charAt(0).toUpperCase() +
                              trans?.status?.slice(1)}
                          </td>

                          {/* Payment Recieved By */}
                          {tenantInvoice?.invoice?.method !== "online" && (
                            <td>
                              {trans?.received_by?.first_name}{" "}
                              {trans?.received_by?.last_name}
                            </td>
                          )}

                          {/* Actions */}
                          <td>
                            <IconButton
                              className="action-button"
                              onClick={() => handleView(trans)}
                            >
                              <img src={EyeFilledBlue} className="h_100" />
                            </IconButton>
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
          </>
        )}

        <InvoiceDetailModal
          showModal={showContractDetailModal}
          detail={detail}
          currency={tenantInvoice?.invoice?.currency}
          onClose={() => {
            setShowContractDetailModal(false);
          }}
        />

        {/* File viewer modal */}
        <FileViewerModal
          show={showFileViewer}
          type={file?.type}
          file={file?.url}
          onClose={() => {
            setShowFileViewer(false);
            setFile({});
          }}
        />
      </div>
    </>
  );
};
/* Component ends */

export default InvoiceReceiptDetail;
