/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Table } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import {
  DeleteGrey,
  DeleteRed,
  Download,
} from "../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../../ui/button/IconButton/IconButton";
/* Import local pages and component ends */

/* Component starts */
const DrawingsPrints = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */

  // DUMMY DATA
  const ongoingList = [
    {
      id: "MT01",
      date: "9/13/2022 09:30 AM",
      title: "Roofing (Metal)",
    },
    {
      id: "MT02",
      date: "11/21/2022 08:00 AM",

      title: "Granite Surfaces",

      rating: 2,
    },
    {
      id: "MT03",
      date: "9/3/2022 09:30 PM",

      title: "Exterior Signage",

      rating: 4,
    },
    {
      id: "MT04",
      date: "6/26/2022 11:30 PM",

      title: "Framing (Wood)",
    },
    {
      id: "MT05",
      date: "12/9/2022 09:30 AM",
      title: "Finishing",
    },
  ];

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="custom-table-wrapper ongoing-table">
      <Table className="custom-table font_s" borderless responsive>
        {/* Table Heading */}
        <thead>
          <tr>
            <th>ID</th>
            <th>Title</th>
            <th>Date & Time</th>
            <th className="text-center">Action</th>
          </tr>
        </thead>
        {/* Table Body */}
        <tbody>
          {ongoingList?.map((item) => (
            <tr key={item.id}>
              {/* ID */}
              <td>{item?.id}</td>

              {/* Title */}
              <td>{item?.title}</td>

              {/*  Date */}
              <td>{item?.date}</td>

              {/* Actions */}
              <td className="text-center txt-nowrap">
                <IconButton
                  // onClick={() =>
                  //   navigate("/tenant/security/details", {
                  //     state: { item },
                  //   })
                  // }
                  className="action-button"
                >
                  <img src={Download} className="h_100" alt="View Details" />
                </IconButton>
                <IconButton
                  // onClick={() =>
                  //   navigate("/tenant/security/details", {
                  //     state: { item },
                  //   })
                  // }
                  className="action-button"
                >
                  <img src={DeleteRed} className="h_100" alt="View Details" />
                </IconButton>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};
/* Component ends */

export default DrawingsPrints;
