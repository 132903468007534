/**
 *
 */

import React, { useState, useEffect, useRef } from "react";

/* Import configuration starts */
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ReactToPrint, { useReactToPrint } from "react-to-print";

import { jsPDF } from "jspdf";
// import 'jspdf-autotable'
// import Print from 'react-print-html';
/* Import configuration ends */

/* Import redux slices component starts */
import {
  fetchOccupancyList,
  fetchOccupancyListBasedCommunity,
  fetchOccupancyListBasedProperty,
  fetchSearchedOccupancyList,
  getCommunityOccupancyList,
  getOccupancyList,
  getPropertyOccupancyList,
} from "../../setup/store/slices/occupancyDashboardSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { EnvelopeOutLinedBlue, PrintIcon } from "../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import { getUniqueValues } from "../../setup/utils/global-helper";

import { getPrintableHtmlData } from "../PrintData";
import GLOBAL from "../../setup/constants/global";
import Header from "./local-component/Header";
import PieChartGraph from "./local-component/PieChartGraph";
import StatusPieChartGraph from "./local-component/StatusPieChartGraph";

import Type3DPieChart from "../../components/ui/other/PieCharts/Type3DPieChart";
import Status3DPieChart from "../../components/ui/other/PieCharts/Status3DPieChart";
import Searchbar from "../../components/ui/other/Searchbar/Searchbar";
import CustomDateInput from "../../components/ui/input/CustomDateInput/CustomDateInput";
import OccupationTable from "./local-component/OccupationTable";
import Pagination from "../../components/module/RnD/Pagination/Pagination";
import EmailModal from "../../components/ui/modal/EmailModal/EmailModal";
import BreadCrumb from "../../components/ui/other/BreadCrumb/BreadCrumb";
/* Import local pages and component ends */

import "./style.scss";
import moment from "moment";

/* Component starts */
const OccupationDashboard = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const startDate = new Date();
  // const endDate = new Date();
  const [fromDate, setFromDate] = useState(
    new Date(startDate.getFullYear(), startDate.getMonth(), 1)
  );
  const [toDate, setToDate] = useState(
    // new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0)
    new Date()
  );

  const [search, setSearch] = useState("");
  const [occupancy, setOccupancy] = useState([]);
  const [filterOccupancy, setFilterOccupancy] = useState();

  const [typeData, setTypeData] = useState();
  const [statusData, setStatusData] = useState();
  const [cleared, setCleared] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  const [showModal, setShowModal] = useState(false);

  // FILTERS
  const [communityData, setCommunityData] = useState();
  const [multiSelectCommunity, setMultiSelectCommunity] = useState();
  const [propertyData, setPropertyData] = useState();
  const [multiSelectProperty, setMultiSelectProperty] = useState();

  const [unitNumData, setUnitNumData] = useState();
  const [multiSelectUnitNum, setMultiSelectUnitNum] = useState();

  const [unitFloorData, setUnitFloorData] = useState();
  const [multiSelectUnitFloor, setMultiSelectUnitFloor] = useState();

  const [unitSizeData, setUnitSizeData] = useState();
  const [multiSelectUnitSize, setMultiSelectUnitSize] = useState();

  const [unitTypeData, setUnitTypeData] = useState();
  const [multiSelectUnitType, setMultiSelectUnitType] = useState();

  const [tenantData, setTenantData] = useState();
  const [multiSelectTenant, setMultiSelectTenant] = useState();

  const [statusData1, setStatusData1] = useState();
  const [multiSelectStatus, setMultiSelectStatus] = useState();

  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const { pathname } = useLocation();
  const param = useParams();
  const dispatch = useDispatch();
  const occupancyList = useSelector(getOccupancyList);
  const propertyOccupancyList = useSelector(getPropertyOccupancyList);
  const communityOccupancyList = useSelector(getCommunityOccupancyList);

  // Ref for Print Component
  const componentRef = useRef();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItemsList = filterOccupancy?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  const loadOccupancyList = async () => {
    if (pathname.includes("communities")) {
      await dispatch(
        fetchOccupancyListBasedCommunity({
          community_id: param.communityId,
        })
      );
    } else if (pathname.includes("independent")) {
      await dispatch(
        fetchOccupancyListBasedProperty({ property_id: param.propertyId })
      );
    } else {
      await dispatch(fetchOccupancyList());
    }
  };

  const filterTypeOfOccupancy = (occupancy) => {
    let oneBedRoom = occupancy?.filter((d) => d.type === "1 Bedroom Apartment");
    let twoBedRoom = occupancy?.filter((d) => d.type === "2 Bedroom Apartment");
    let threeBedRoom = occupancy?.filter(
      (d) => d.type === "3 Bedroom Apartment"
    );
    let fourBedRoom = occupancy?.filter(
      (d) => d.type === "4 Bedroom Apartment"
    );
    let condominium = occupancy?.filter((d) => d.type === "Condominium");
    let multiHome = occupancy?.filter((d) => d.type === "Multi Family Home");
    let singleHome = occupancy?.filter((d) => d.type === "Single Family Home");
    let studio = occupancy?.filter((d) => d.type === "Studio");
    let townHouse = occupancy?.filter((d) => d.type === "Town House");
    let villa = occupancy?.filter((d) => d.type === "Villa");
    let pentHouse = occupancy?.filter((d) => d.type === "Pent House");
    let retailShop = occupancy?.filter((d) => d.type === "Retail Shop");
    let store = occupancy?.filter((d) => d.type === "Store");
    let warehouse = occupancy?.filter((d) => d.type === "Warehouse");
    let office = occupancy?.filter((d) => d.type === "Office");

    const obj = [];
    obj.push(["Unit Type", "No. Of Type"]);
    if (oneBedRoom?.length > 0) {
      obj.push(["1 Bedroom Apartment", oneBedRoom?.length]);
    }
    if (twoBedRoom?.length > 0) {
      obj.push(["2 Bedroom Apartment", twoBedRoom?.length]);
    }
    if (threeBedRoom?.length > 0) {
      obj.push(["3 Bedroom Apartment", threeBedRoom?.length]);
    }

    if (fourBedRoom?.length > 0) {
      obj.push(["4 Bedroom Apartment", fourBedRoom?.length]);
    }
    if (condominium?.length > 0) {
      obj.push(["Condominium", condominium?.length]);
    }
    if (multiHome?.length > 0) {
      obj.push(["Multi Family Home", multiHome?.length]);
    }

    if (singleHome?.length > 0) {
      obj.push(["Single Family Home", singleHome?.length]);
    }
    if (studio?.length > 0) {
      obj.push(["Studio", studio?.length]);
    }
    if (townHouse?.length > 0) {
      obj.push(["Town House", townHouse?.length]);
    }

    if (villa?.length > 0) {
      obj.push(["Villa", villa?.length]);
    }
    if (pentHouse?.length > 0) {
      obj.push(["Pent House", pentHouse?.length]);
    }

    if (retailShop?.length > 0) {
      obj.push(["Retail Shop", retailShop?.length]);
    }

    if (store?.length > 0) {
      obj.push(["Store", store?.length]);
    }
    if (warehouse?.length > 0) {
      obj.push(["Warehouse", warehouse?.length]);
    }
    if (office?.length > 0) {
      obj.push(["Office", office?.length]);
    }
    setTypeData(obj);
  };

  const filterStatusOfOccupancy = (occupancy) => {
    let vacant = occupancy?.filter((d) => d.status === "Vacant");
    let occupied = occupancy?.filter((d) => d.status === "Occupied");
    let reserved = occupancy?.filter((d) => d.status === "Reserved");

    const obj = [];
    obj.push(["Status", "No Of Status"]);
    if (vacant?.length > 0) {
      obj.push(["Vacant", vacant?.length]);
    }
    if (occupied?.length > 0) {
      obj.push(["Occupied", occupied?.length]);
    }
    if (reserved?.length > 0) {
      obj.push(["Reserved", reserved?.length]);
    }

    setStatusData(obj);

    // setStatusData([
    //   { name: "Vacant", value: vacant.length },
    //   { name: "Occupied", value: occupied.length },
    //   { name: "Reserved", value: reserved.length },
    // ]);
  };

  const handleSearch = async (isReset = false, resetValue = {}) => {
    let payload = {};
    if (!isReset) {
      if (search && fromDate && toDate) {
        payload = {
          searchKey: search,
          start_date: moment(fromDate)?.format("MM-DD-YYYY"),
          end_date: moment(toDate)?.format("MM-DD-YYYY"),
        };
        // Backend response. Try, Catch
        try {
          let result;
          if (pathname.includes("communities")) {
            payload = {
              ...payload,
              community_id: param.communityId,
            };
            result = await dispatch(fetchSearchedOccupancyList(payload));
          } else if (pathname.includes("independent")) {
            payload = {
              ...payload,
              property_id: param.propertyId,
            };
            result = await dispatch(fetchSearchedOccupancyList(payload));
          } else {
            result = await dispatch(fetchSearchedOccupancyList(payload));
          }
          // Handling success response
          switch (result.meta.requestStatus) {
            case GLOBAL.REJECTED:
              // setModalDesc(result.payload);
              // setShowErrorModal(true);
              break;

            case GLOBAL.FULFILLED:
              // setModalDesc(result.payload.message);
              // setShowSuccessModal(true);
              setOccupancy(result.payload);
              setFilterOccupancy(result?.payload);
              break;
          }
        } catch (error) {
          // Handling error response
          console.error(error.message);
        }
      } else if (search) {
        payload = {
          searchKey: search,
        };

        await dispatch(fetchSearchedOccupancyList(payload));
      } else if (!search && fromDate && toDate) {
        payload = {
          searchKey: "",
          start_date: moment(fromDate)?.format("MM-DD-YYYY"),
          end_date: moment(toDate)?.format("MM-DD-YYYY"),
        };
        // Backend response. Try, Catch
        try {
          let result;
          if (pathname.includes("communities")) {
            payload = {
              ...payload,
              community_id: param.communityId,
            };
            result = await dispatch(fetchSearchedOccupancyList(payload));
          } else if (pathname.includes("independent")) {
            payload = {
              ...payload,
              property_id: param.propertyId,
            };
            result = await dispatch(fetchSearchedOccupancyList(payload));
          } else {
            result = await dispatch(fetchSearchedOccupancyList(payload));
          }
          // Handling success response
          switch (result.meta.requestStatus) {
            case GLOBAL.REJECTED:
              // setModalDesc(result.payload);
              // setShowErrorModal(true);
              break;

            case GLOBAL.FULFILLED:
              // setModalDesc(result.payload.message);
              // setShowSuccessModal(true);
              setOccupancy(result.payload);
              setFilterOccupancy(result?.payload);

              break;
          }
        } catch (error) {
          // Handling error response
          console.error(error.message);
        }
      }
    }
    // else {
    //   payload = {
    //     ...resetValue,
    //   };
    // }
  };

  const handleClear = () => {
    let payload = {
      searchKey: "",
      start_date: new Date(startDate.getFullYear(), startDate.getMonth(), 1),
      end_date: new Date(),
    };
    setSearch(payload.searchKey);
    setFromDate(payload.start_date);
    setToDate(payload.end_date);

    handleSearch(true, payload);
    setCleared(!cleared);
  };

  // To check the any checkbox is true or not
  const checkAnyFilterChecked = (filter) => {
    return Object?.keys(filter)?.every(function (k) {
      return !filter[k];
    });
  };

  // PRINT FUNCTION
  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
  // });

  const handlePrint = () => {
    let type;
    const htmlData = getPrintableHtmlData(
      (type = "Occupancy"),
      filterOccupancy
    );

    const doc = new jsPDF("landscape", "px", "a4");

    doc.html(document.getElementById("occupy-table"), {
      callback: function (doc) {
        doc.save();
      },
    });
  };

  // SEND EMAIL FUNCTION
  const handleSendEmail = () => {
    window.location = "mailto:yourmail@domain.com";
  };

  /* Component function definition ends */

  /* Component useEffect starts */

  useEffect(() => {
    loadOccupancyList();
  }, [pathname, !cleared]);

  useEffect(() => {
    if (pathname.includes("communities") && communityOccupancyList) {
      setOccupancy(communityOccupancyList);
      setFilterOccupancy([...communityOccupancyList]);
      filterTypeOfOccupancy(occupancy);
      filterStatusOfOccupancy(occupancy);
    } else if (pathname.includes("independent") && propertyOccupancyList) {
      setOccupancy(propertyOccupancyList);
      setFilterOccupancy([...propertyOccupancyList]);
      filterTypeOfOccupancy(occupancy);
      filterStatusOfOccupancy(occupancy);
    } else {
      setOccupancy(occupancyList);
      setFilterOccupancy([...occupancyList]);
      filterTypeOfOccupancy(occupancy);
      filterStatusOfOccupancy(occupancy);
    }
  }, [
    pathname,
    occupancyList,
    communityOccupancyList,
    propertyOccupancyList,
    !cleared,
  ]);

  useEffect(() => {
    if (pathname.includes("communities") && communityOccupancyList) {
      filterTypeOfOccupancy(occupancy);
      filterStatusOfOccupancy(occupancy);
    } else if (pathname.includes("independent") && propertyOccupancyList) {
      filterTypeOfOccupancy(occupancy);
      filterStatusOfOccupancy(occupancy);
    } else {
      filterTypeOfOccupancy(occupancy);
      filterStatusOfOccupancy(occupancy);
    }
  }, [!search, occupancy]);

  useEffect(() => {
    const community = getUniqueValues(occupancy, "community_name");
    const property = getUniqueValues(occupancy, "property_name");
    const unitType = getUniqueValues(occupancy, "type");

    const unitNum = getUniqueValues(occupancy, "unit_number");

    //   let testUnit = unitNum.sort(
    //     function(a, b) {
    //       return a.toLowerCase() > b.toLowerCase();
    //  }
    //   )

    const unitFllor = getUniqueValues(occupancy, "floor");
    const unitSize = getUniqueValues(occupancy, "size");

    const tenant = getUniqueValues(occupancy, "tenant");
    const status = getUniqueValues(occupancy, "status");

    const communityObject = {};
    community.forEach((prop) => (communityObject[prop] = false));
    setMultiSelectCommunity(communityObject);
    setCommunityData(community);

    const propertyObject = {};
    property.forEach((prop) => (propertyObject[prop] = false));
    setMultiSelectProperty(propertyObject);
    setPropertyData(property);

    const unitTypeObject = {};
    unitType.forEach((prop) => (unitTypeObject[prop] = false));
    setMultiSelectUnitType(unitTypeObject);
    setUnitTypeData(unitType);

    const unitNumObject = {};
    unitNum?.sort().forEach((prop) => (unitNumObject[prop] = false));
    setMultiSelectUnitNum(unitNumObject);
    setUnitNumData(unitNum?.sort());

    const unitFloorObject = {};
    unitFllor
      ?.sort((a, b) => a - b)
      .forEach((prop) => (unitFloorObject[prop] = false));
    setMultiSelectUnitFloor(unitFloorObject);
    setUnitFloorData(unitFllor?.sort((a, b) => a - b));

    const unitSizeObject = {};
    unitSize
      ?.sort((a, b) => a - b)
      .forEach((prop) => (unitSizeObject[prop] = false));
    setMultiSelectUnitSize(unitSizeObject);
    setUnitSizeData(unitSize?.sort((a, b) => a - b));

    const tenantObject = {};
    tenant.forEach((prop) => (tenantObject[prop] = false));
    setMultiSelectTenant(tenantObject);
    setTenantData(tenant);

    const statusObject = {};
    status.forEach((prop) => (statusObject[prop] = false));
    setMultiSelectStatus(statusObject);
    setStatusData1(status);
  }, [occupancy]);

  useEffect(() => {
    if (
      multiSelectCommunity !== undefined ||
      multiSelectProperty !== undefined ||
      multiSelectStatus !== undefined ||
      multiSelectTenant !== undefined ||
      multiSelectUnitType !== undefined ||
      multiSelectUnitFloor !== undefined ||
      multiSelectUnitNum !== undefined ||
      multiSelectUnitSize !== undefined
    ) {
      let allUnCheckedCommunity = checkAnyFilterChecked(multiSelectCommunity);
      let allUnCheckedProperty = checkAnyFilterChecked(multiSelectProperty);
      let allUnCheckedTenant = checkAnyFilterChecked(multiSelectTenant);
      let allUnCheckedUnitType = checkAnyFilterChecked(multiSelectUnitType);

      let allUnCheckedUnitNum = checkAnyFilterChecked(multiSelectUnitNum);
      let allUnCheckedUnitFloor = checkAnyFilterChecked(multiSelectUnitFloor);
      let allUnCheckedUnitSize = checkAnyFilterChecked(multiSelectUnitSize);

      let allUnCheckedStatus = checkAnyFilterChecked(multiSelectStatus);

      if (!allUnCheckedCommunity && multiSelectCommunity) {
        let selectedValue = Object.keys(multiSelectCommunity)?.filter(
          (key) => multiSelectCommunity[key]
        );

        const filteredCommunity = occupancy?.filter((d) =>
          selectedValue?.includes(d.community_name)
        );

        setFilterOccupancy(filteredCommunity);
      } else if (!allUnCheckedProperty && multiSelectProperty) {
        let selectedValue = Object.keys(multiSelectProperty)?.filter(
          (key) => multiSelectProperty[key]
        );

        const filteredProperty = occupancy?.filter((d) =>
          selectedValue?.includes(d.property_name)
        );

        setFilterOccupancy(filteredProperty);
      } else if (!allUnCheckedStatus && multiSelectStatus) {
        let selectedValue = Object.keys(multiSelectStatus)?.filter(
          (key) => multiSelectStatus[key]
        );

        const filteredStatus = occupancy?.filter((d) =>
          selectedValue?.includes(d.status)
        );

        setFilterOccupancy(filteredStatus);
      } else if (!allUnCheckedUnitType && multiSelectUnitType) {
        let selectedValue = Object.keys(multiSelectUnitType)?.filter(
          (key) => multiSelectUnitType[key]
        );

        const filteredUnitType = occupancy?.filter((d) =>
          selectedValue?.includes(d.type)
        );

        setFilterOccupancy(filteredUnitType);
      } else if (!allUnCheckedUnitNum && multiSelectUnitNum) {
        let selectedValue = Object.values(multiSelectUnitNum)?.filter(
          (key) => key === true
        );

        let selectedValue1 = Object.keys(multiSelectUnitNum)?.filter(
          (key) => multiSelectUnitNum[key] === true
        );

        const filteredUnitNum = occupancy?.filter((d) =>
          selectedValue1?.includes(d.unit_number)
        );

        setFilterOccupancy(filteredUnitNum);
      } else if (!allUnCheckedUnitFloor && multiSelectUnitFloor) {
        let selectedValue = Object.keys(multiSelectUnitFloor)?.filter(
          (key) => multiSelectUnitFloor[key]
        );

        const filteredUnitFloor = occupancy?.filter((d) =>
          selectedValue?.includes(d.floor)
        );

        setFilterOccupancy(filteredUnitFloor);
      } else if (!allUnCheckedUnitSize && multiSelectUnitSize) {
        let selectedValue = Object.keys(multiSelectUnitSize)?.filter(
          (key) => multiSelectUnitSize[key]
        );

        const filteredUnitSize = occupancy?.filter((d) =>
          selectedValue?.includes(d.size)
        );

        setFilterOccupancy(filteredUnitSize);
      } else if (!allUnCheckedTenant && multiSelectTenant) {
        let selectedValue = Object.keys(multiSelectTenant)?.filter(
          (key) => multiSelectTenant[key]
        );

        const filteredTenant = occupancy?.filter((d) =>
          selectedValue?.includes(d.tenant)
        );

        setFilterOccupancy(filteredTenant);
      }
    } else {
      setFilterOccupancy(occupancy);
    }
  }, [
    multiSelectCommunity,
    multiSelectProperty,
    multiSelectStatus,
    multiSelectTenant,
    multiSelectUnitType,
    multiSelectUnitNum,
    multiSelectUnitFloor,
    multiSelectUnitSize,
  ]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      <BreadCrumb type="dashboard_occupancy" />
      <div
        className="occupation-dashboard-wrapper"
        // ref={componentRef}
      >
        {/* <ReactToPrint ></ReactToPrint>; */}
        <div className="action-wrapper">
          <div onClick={handlePrint}>
            <img src={PrintIcon} alt="Print-Icon" />
            <span className="font_m fw_3 font_blue">Print</span>
          </div>

          <div onClick={handleSendEmail}>
            <img src={EnvelopeOutLinedBlue} alt="Email-Icon" />
            <span className="font_m fw_3 font_blue">Email</span>
          </div>
        </div>
        <div className="header" id="header">
          <Header />
        </div>

        {occupancy?.length > 0 && (
          <div className="report-chart-wrapper" id="charts">
            <div className="detail-box ">
              <div>
                <p className="font_l fw_4">Unit Types</p>
              </div>
              {/* <div>
              <PieChartGraph typeData={typeData} />
            </div> */}
              <Type3DPieChart data={typeData} />
              {/* <div>
              <p className="font_l text_center fw_4">
                Total Unit : {occupancy?.length}{" "}
              </p>
            </div> */}
            </div>

            <div className="detail-box ">
              <div>
                <p className="font_l fw_4">Status</p>
              </div>
              {/* <div>
              <StatusPieChartGraph statusData={statusData} />
            </div> */}
              <Status3DPieChart data={statusData} />
            </div>
          </div>
        )}

        <div className="search-box mt-4 mb-4">
          <div className="search">
            <Searchbar
              className="dash-search"
              setQuery={setSearch}
              query={search}
              // placeholder="Community Name / Property Name / Status"
            />
          </div>
          <div className="date">
            <div>
              <CustomDateInput
                label="From"
                value={fromDate}
                minDate={false}
                customOnchange={(date) => setFromDate(date)}
                required={true}
              />
            </div>

            <div>
              <CustomDateInput
                label="To"
                value={toDate}
                minDate={false}
                customOnchange={(date) => setToDate(date)}
                required={true}
              />
            </div>
          </div>

          <div className="btn-wrapper">
            <button
              onClick={() => handleSearch()}
              // disabled={!search}
            >
              Apply
            </button>
            <button className="btn-clear" onClick={handleClear}>
              Clear
            </button>
          </div>
        </div>

        <div className="occup-table-wrapper" id="occupy-table">
          <OccupationTable
            occupancyListData={currentItemsList}
            communityData={communityData}
            multiSelectCommunity={multiSelectCommunity}
            setMultiSelectCommunity={setMultiSelectCommunity}
            propertyData={propertyData}
            multiSelectProperty={multiSelectProperty}
            setMultiSelectProperty={setMultiSelectProperty}
            unitType={unitTypeData}
            multiSelectUnitType={multiSelectUnitType}
            setMultiSelectUnitType={setMultiSelectUnitType}
            unitNum={unitNumData}
            multiSelectUnitNum={multiSelectUnitNum}
            setMultiSelectUnitNum={setMultiSelectUnitNum}
            unitFloor={unitFloorData}
            multiSelectUnitFloor={multiSelectUnitFloor}
            setMultiSelectUnitFloor={setMultiSelectUnitFloor}
            unitSize={unitSizeData}
            multiSelectUnitSize={multiSelectUnitSize}
            setMultiSelectUnitSize={setMultiSelectUnitSize}
            tenant={tenantData}
            multiSelectTenant={multiSelectTenant}
            setMultiSelectTenant={setMultiSelectTenant}
            statusData1={statusData1}
            multiSelectStatus={multiSelectStatus}
            setMultiSelectStatus={setMultiSelectStatus}
          />

          {filterOccupancy?.length > 0 && (
            <Pagination
              itemsPerPage={itemsPerPage}
              totalItems={filterOccupancy?.length}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          )}
        </div>

        {/* EMAIL MODAL */}
        <EmailModal
          showModal={showModal}
          onSuccess={() => setShowModal(!showModal)}
          onClose={() => setShowModal(!showModal)}
        />
      </div>
    </>
  );
};
/* Component ends */

export default OccupationDashboard;
