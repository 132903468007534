/**
 *
 */

import React, { useState, useEffect, useRef } from "react";

/* Import configuration starts */
import GLOBAL from "../../../setup/constants/global";
import { useDispatch, useSelector } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  addTenantProfilePicture,
  getIsTENANT,
  getUser,
} from "../../../setup/store/slices/authSlice";
import { addProfilePicture } from "../../../setup/store/slices/authSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import {
  NoProfPicSet,
  UploadFilledWhite,
} from "../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import SuccessModal from "../../../components/ui/modal/SuccessModal/SuccessModal";
import ErrorModal from "../../../components/ui/modal/ErrorModal/ErrorModal";
import Image from "../../../components/ui/other/Image/Image";
/* Import local pages and component ends */

/* Component starts */
const ProfilePhoto = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [modalDesc, setModalDesc] = useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  // useRef
  const photoInput = useRef(null);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();

  // Redux Selector
  const user = useSelector(getUser);
  const isTenant = useSelector(getIsTENANT);

  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const photo = user?.myphoto?.url || NoProfPicSet;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // On selecting image
  const onFileUpload = async (event) => {
    if (!event.target.files[0]) {
      return false;
    }

    // Creating or collecting payload data to be sent
    const payload = { myphoto: event.target.files[0] };
    console.log("Payload", payload);
    // Backend response Try, Catch
    let result;
    try {
      if (isTenant) {
        result = await dispatch(addTenantProfilePicture(payload));
      } else {
        result = await dispatch(addProfilePicture(payload));
      }
      console.log("Result", result);
      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setModalDesc(result.payload);
          setShowErrorModal(true);
          break;

        case GLOBAL.FULFILLED:
          setModalDesc("Profile picture has been updated successfully");
          setShowSuccessModal(true);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="bg_grey form-wrapper photo">
      {/* Photo */}
      <div className="prof-pic" onClick={() => photoInput.current.click()}>
        <Image
          className="w_100 h_100"
          src={photo}
          alt="Profile Photo"
          objectFit="cover"
          objectPosition="center"
        />

        <input
          onChange={onFileUpload}
          className="hidden"
          type="file"
          ref={photoInput}
        />
        <div className="upload-icon">
          <img src={UploadFilledWhite} alt="Upload" />
        </div>
      </div>

      {/* Modals */}
      {/* Success Modal */}
      <SuccessModal
        showModal={showSuccessModal}
        description={modalDesc}
        title="Photo Updated!"
        onClose={() => setShowSuccessModal(false)}
      />

      {/* Error Modal */}
      <ErrorModal
        showModal={showErrorModal}
        description={modalDesc}
        title="Error!"
        onClose={() => setShowErrorModal(false)}
      />
    </div>
  );
};
/* Component ends */

export default ProfilePhoto;
