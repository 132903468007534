/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import GLOBAL from "../../../../setup/constants/global";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment/moment";
import { formatCurrency } from "../../../../setup/utils/global-helper";
import { useParams } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  fetchUserRoles,
  getUserRoles,
} from "../../../../setup/store/slices/masterSlice";
import {
  fetchPropertyDetailByID,
  getPropertyDetail,
} from "../../../../setup/store/slices/propertySlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Modal } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import {
  EyeFilledBlue,
  NoProfPicSet,
  XGreen,
  XWhite,
} from "../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../button/IconButton/IconButton";
import CustomButton from "../../button/CustomButton/CustomButton";
import FileViewerModal from "../../modal/FileViewerModal/FileViewerModal";
/* Import local pages and component ends */

import "./style.scss";
import { getUserTheme } from "../../../../setup/store/slices/authSlice";

/* Component starts */
const TeamDetailModal = (props) => {
  /* Props destructuring starts */
  const {
    showModal,
    detail,
    size = "xl",
    onClose,
    showApprovalReq = false,
    requestAction,
  } = props;

  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [file, setFile] = useState(null);
  const [showFileViewer, setShowFileViewer] = useState(false);
  const [currentRole, setCurrentRole] = useState("");
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const { propertyId } = useParams();
  // Redux selector
  const userRoles = useSelector(getUserRoles);
  const property = useSelector(getPropertyDetail);
  const userTheme = useSelector(getUserTheme);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const profile_pic = detail?.myphoto?.url || NoProfPicSet;
  const name = detail?.first_name + " " + detail?.last_name;
  const mobile = detail?.countrycode + " " + detail?.mobile;
  const email = detail?.email;
  const address1 = detail?.address_line1;
  const address2 = detail?.address_line2;
  const city = detail?.city;
  const state = detail?.state;
  const country = detail?.country;
  const zipcode = detail?.zipcode;
  const profession = detail?.profession;
  const business_name = detail?.business_name;
  const employeeId = detail?.employee_id;
  const contractStartDate = detail?.contract_start_date;
  const contractEndDate = detail?.contract_end_date;
  const contractCopyImg = detail?.contract_copy_img;
  const contractNumber = detail?.contract_number;
  const contractValue = detail?.contract_value;
  const resume = detail?.resume;
  const joining_date = detail?.joining_date;
  const exit_date =
    detail?.status === "deactivated" ? detail?.deactivation_date : "";
  const status = detail?.status;
  const expiredAt = detail?.invitation_id?.updatedAt;

  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // On viewing file
  const viewFile = (file) => {
    setFile(file);
    setShowFileViewer(true);
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Fetching and selecting current user role */
  useEffect(() => {
    dispatch(fetchUserRoles());
    dispatch(fetchPropertyDetailByID({ property_id: propertyId }));
  }, []);

  useEffect(() => {
    if (userRoles.length > 0 && detail) {
      let fetchedRole = userRoles.filter(
        (role) => role?._id === detail?.role
      )[0];

      setCurrentRole(fetchedRole?.name);
    }
  }, [userRoles, detail]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  if (!showFileViewer)
    return (
      <Modal
        size={size}
        centered
        show={showModal}
        className="team-detail-modal-wrapper"
      >
        <Modal.Body
          className={`custom-modal-body ${
            userTheme === "dark" ? "bg_darkTheme" : "bg_lightTheme"
          }`}
        >
          {/* Header */}
          <div className="close-button-wrapper">
            <IconButton onClick={onClose} className="close-button">
              {/* <img className="h_100" src={XWhite} alt="Close" /> */}
              <img className="h_100" src={XGreen} alt="Close" />
            </IconButton>
          </div>

          {/* Body */}
          <div className="details-content">
            {/* Left Section. Profile Picture, Name, Email */}
            <div className="left">
              <div
                className="profile_pic"
                style={{
                  backgroundImage: `url(${profile_pic})`,
                }}
              ></div>

              <p className="name text-center font_grey font_m">{name}</p>
              <p className="role text-center font_blue fw_6 font_s">
                {currentRole}
              </p>
            </div>

            {/* Right Section. Other Details */}
            <div className="right">
              <div
                className={`wrapper ${
                  userTheme === "dark" ? "bg_grey" : "bg_lightThemeDetail"
                }`}
              >
                {/* Mobile Number */}
                <div className="custom_row">
                  <p className="key font_grey font_s">Mobile Number</p>
                  <p
                    className={`val font_s ${
                      (userTheme === "light" || userTheme === "automatic") &&
                      "font_lightTheme"
                    } `}
                  >
                    {mobile}
                  </p>
                </div>

                {/* Email */}
                <div className="custom_row">
                  <p className="key font_grey font_s">Email</p>
                  <p
                    // className="val font_s"
                    className={`val font_s ${
                      (userTheme === "light" || userTheme === "automatic") &&
                      "font_lightTheme"
                    } `}
                  >
                    <input
                      className="transparent-input"
                      type="text"
                      value={email}
                      readOnly
                    />
                  </p>
                </div>

                {/* City */}
                {city && (
                  <div className="custom_row">
                    <p className="key font_grey font_s">City</p>
                    <p
                      //className="val font_s"
                      className={`val font_s ${
                        (userTheme === "light" || userTheme === "automatic") &&
                        "font_lightTheme"
                      } `}
                    >
                      {city}
                    </p>
                  </div>
                )}

                {/* State */}
                {state && (
                  <div className="custom_row">
                    <p className="key font_grey font_s">State</p>
                    <p
                      // className="val font_s"
                      className={`val font_s ${
                        (userTheme === "light" || userTheme === "automatic") &&
                        "font_lightTheme"
                      } `}
                    >
                      {state}
                    </p>
                  </div>
                )}

                {/* Country */}
                {country && (
                  <div className="custom_row">
                    <p className="key font_grey font_s">Country</p>
                    <p
                      // className="val font_s"
                      className={`val font_s ${
                        (userTheme === "light" || userTheme === "automatic") &&
                        "font_lightTheme"
                      } `}
                    >
                      {country}
                    </p>
                  </div>
                )}

                {/* Zip Code */}
                {zipcode && (
                  <div className="custom_row">
                    <p className="key font_grey font_s">ZIP Code</p>
                    <p
                      // className="val font_s"
                      className={`val font_s ${
                        (userTheme === "light" || userTheme === "automatic") &&
                        "font_lightTheme"
                      } `}
                    >
                      {zipcode}
                    </p>
                  </div>
                )}

                {/* Address Line 1 */}
                {address1 && (
                  <div className="custom_row">
                    <p className="key font_grey font_s">Address</p>
                    <p
                      // className="val font_s"
                      className={`val font_s ${
                        (userTheme === "light" || userTheme === "automatic") &&
                        "font_lightTheme"
                      } `}
                    >
                      {address1}
                    </p>
                  </div>
                )}

                {/* Address Line 2 */}
                {address2 && (
                  <div className="custom_row">
                    <p className="key font_grey font_s">Address Line 2</p>
                    <p
                      // className="val font_s"
                      className={`val font_s ${
                        (userTheme === "light" || userTheme === "automatic") &&
                        "font_lightTheme"
                      } `}
                    >
                      {address2}
                    </p>
                  </div>
                )}

                {/* Business Name */}
                {business_name && (
                  <div className="custom_row">
                    <p className="key font_grey font_s">Business Name</p>
                    <p
                      // className="val font_s"
                      className={`val font_s ${
                        (userTheme === "light" || userTheme === "automatic") &&
                        "font_lightTheme"
                      } `}
                    >
                      {business_name}
                    </p>
                  </div>
                )}

                {/* Profession */}
                {profession && (
                  <div className="custom_row">
                    <p className="key font_grey font_s">Profession</p>
                    <p
                      // className="val font_s"
                      className={`val font_s ${
                        (userTheme === "light" || userTheme === "automatic") &&
                        "font_lightTheme"
                      } `}
                    >
                      {profession}
                    </p>
                  </div>
                )}

                {/* Employee ID */}
                {employeeId && (
                  <div className="custom_row">
                    <p className="key font_grey font_s">Employee ID</p>
                    <p
                      // className="val font_s"
                      className={`val font_s ${
                        (userTheme === "light" || userTheme === "automatic") &&
                        "font_lightTheme"
                      } `}
                    >
                      {employeeId}
                    </p>
                  </div>
                )}

                {/* Contract Start Date */}
                {contractStartDate && (
                  <div className="custom_row">
                    <p className="key font_grey font_s">Contract Start Date</p>
                    <p
                      // className="val font_s"
                      className={`val font_s ${
                        (userTheme === "light" || userTheme === "automatic") &&
                        "font_lightTheme"
                      } `}
                    >
                      {moment(contractStartDate).format(DATE_FORMAT)}
                    </p>
                  </div>
                )}

                {/* Contract End Date */}
                {contractEndDate && (
                  <div className="custom_row">
                    <p className="key font_grey font_s">Contract End Date</p>
                    <p
                      // className="val font_s"
                      className={`val font_s ${
                        (userTheme === "light" || userTheme === "automatic") &&
                        "font_lightTheme"
                      } `}
                    >
                      {moment(contractEndDate).format(DATE_FORMAT)}
                    </p>
                  </div>
                )}

                {/* Contract Copy Image */}
                {contractCopyImg && (
                  <div className="custom_row">
                    <p className="key font_grey font_s">Contract Copy Image</p>
                    <p className="val font_s">
                      <IconButton
                        onClick={() => viewFile(contractCopyImg)}
                        className="view"
                      >
                        <img className="h_100" src={EyeFilledBlue} alt="" />
                      </IconButton>
                    </p>
                  </div>
                )}

                {/* Resume */}
                {resume && (
                  <div className="custom_row">
                    <p className="key font_grey font_s">Resume</p>
                    <p className="val font_s">
                      <IconButton
                        onClick={() => viewFile(resume)}
                        className="view"
                      >
                        <img className="h_100" src={EyeFilledBlue} alt="" />
                      </IconButton>
                    </p>
                  </div>
                )}

                {/* Contract Number */}
                {contractNumber && (
                  <div className="custom_row">
                    <p className="key font_grey font_s">Contract Number</p>
                    <p
                      // className="val font_s"
                      className={`val font_s ${
                        (userTheme === "light" || userTheme === "automatic") &&
                        "font_lightTheme"
                      } `}
                    >
                      {contractNumber}
                    </p>
                  </div>
                )}

                {/* Contract Value */}
                {contractValue && (
                  <div className="custom_row">
                    <p className="key font_grey font_s">Contract Value</p>
                    <p
                      // className="val font_s"
                      className={`val font_s ${
                        (userTheme === "light" || userTheme === "automatic") &&
                        "font_lightTheme"
                      } `}
                    >
                      {formatCurrency(contractValue, property.currency)}
                    </p>
                  </div>
                )}

                {/* Joining Date */}
                {joining_date && (
                  <div className="custom_row">
                    <p className="key font_grey font_s">Joining Date</p>
                    <p
                      // className="val font_s"
                      className={`val font_s ${
                        (userTheme === "light" || userTheme === "automatic") &&
                        "font_lightTheme"
                      } `}
                    >
                      {moment(joining_date).format(DATE_FORMAT)}
                    </p>
                  </div>
                )}

                {/* Exit Date */}
                {exit_date && (
                  <div className="custom_row">
                    <p className="key font_grey font_s">Exit Date</p>
                    <p
                      // className="val font_s"
                      className={`val font_s ${
                        (userTheme === "light" || userTheme === "automatic") &&
                        "font_lightTheme"
                      } `}
                    >
                      {moment(exit_date).format(DATE_FORMAT)}
                    </p>
                  </div>
                )}

                {/* Status */}
                {status === GLOBAL.USER_STATUS.EXPIRED && (
                  <div className="custom_row">
                    <p className="key font_grey font_s">Invitation Status</p>
                    <p
                      // className="val font_s"
                      className={`val font_s font_red `}
                    >
                      {status?.charAt(0).toUpperCase() + status?.slice(1)}
                    </p>
                  </div>
                )}

                {/* Inviattion Expired Date */}
                {expiredAt && status === GLOBAL.USER_STATUS.EXPIRED && (
                  <div className="custom_row">
                    <p className="key font_grey font_s">
                      Invitation Expired Date
                    </p>
                    <p
                      // className="val font_s"
                      className={`val font_s ${
                        (userTheme === "light" || userTheme === "automatic") &&
                        "font_lightTheme"
                      } `}
                    >
                      {moment(expiredAt).format(DATE_TIME_FORMAT)}
                    </p>
                  </div>
                )}
              </div>

              {/* Approve request buttons */}
              {showApprovalReq && (
                <div className="approve-request">
                  <CustomButton
                    onClick={() =>
                      requestAction(detail, GLOBAL.USER_STATUS.APPROVED)
                    }
                    size="m"
                  >
                    Approve
                  </CustomButton>
                  <CustomButton
                    onClick={() =>
                      requestAction(detail, GLOBAL.USER_STATUS.REJECTED)
                    }
                    size="m"
                    variant="red"
                  >
                    Reject
                  </CustomButton>
                </div>
              )}
            </div>
          </div>
        </Modal.Body>

        {/* Other Modals */}
        {/* File Viewer Modal */}
      </Modal>
    );

  if (showFileViewer)
    return (
      <FileViewerModal
        show={showFileViewer}
        file={file?.url}
        type={file?.type}
        onClose={() => {
          setShowFileViewer(false);
          setFile(null);
        }}
      />
    );
};
/* Component ends */

export default TeamDetailModal;
