/*
 * Slice to handle Lost, Found And Claimed Module
 */

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { axiosInstance } from "../axios";
import { API_URL } from "../../config/devKeys";
import {
  setLoading,
  setShowUploadProgress,
  setUploadProgress,
} from "./unpersistedSlice";
import { PLATFORM } from "../../platformUtil";

const initialState = {
  foundList: [],
  lostList: [],
  claimedItemsList: [],
  tenantFoundList: [],
  tenantLostList: [],
};

// FETCH FOUND ITEMS LIST - Client Based On Property
export const fetchFoundItemList = createAsyncThunk(
  "lostfound-found/list",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(
        API_URL + "/lostfound/list",

        {
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            property_id: payload?.property_id,
            type: "Found",
            status: "Open",
            page: payload?.page,
          }),
        }
      );

      const data = await response.json();
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// FETCH LOST ITEMS LIST - Client Based On Property
export const fetchLostItemList = createAsyncThunk(
  "lostfound-lost/list",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(API_URL + "/lostfound/list", {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          property_id: payload?.property_id,
          type: "Lost",
          status: "Open",
          page: payload?.page,
        }),
      });

      const data = await response.json();
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);
// FETCH CLAIMED ITEMS LIST - Client Based On Property
export const fetchClaimedItemList = createAsyncThunk(
  "claimed/list",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        "Content-Type": "application/json",
      };
      const response = await fetch(
        API_URL + "/lostfound/list",

        {
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            property_id: payload?.property_id,
            type: "Claimed",
            page: payload?.page,
          }),
        }
      );

      const data = await response.json();
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const fetchItemBasedOnId = createAsyncThunk(
  "lostfound/get",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.get(
        `${API_URL}/lostfound/get?id=${payload}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        console.log(data.data, "DATA");
        return data.data;
      } else {
        rejectWithValue(data.error);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// ADD FOUND ITEM -> Client Side
export const addFoundItem = createAsyncThunk(
  "lostfound/found/add",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      console.log(payload, "[PAY]");
      const formData = new FormData();
      Object.keys(payload).map((key) => {
        // if (key === "file") {
        //   payload[key].forEach((item) => {
        //     if (PLATFORM !== "web") {
        //       formData.append(key, {
        //         name: item.name,
        //         type: item.type,
        //         uri:
        //           PLATFORM === "android"
        //             ? item.uri
        //             : item.uri.replace("file://", ""),
        //       });
        //     } else {
        //       formData.append(key, item, item.name);
        //     }
        //   });
        // } else {
        //   formData.append(
        //     key,
        //     typeof payload[key] === "object"
        //       ? JSON.stringify(payload[key])
        //       : payload[key]
        //   );
        // }

        if (key === "file") {
          payload[key].forEach((item) => {
            if (PLATFORM !== "web") {
              formData.append(key, {
                name: item.name,
                type: item.type,
                uri:
                  PLATFORM === "android"
                    ? item.uri
                    : item.uri.replace("file://", ""),
              });
            } else {
              formData.append(key, item, item.name);
            }
          });
        } else {
          formData.append(key, payload[key]);
        }
      });

      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }

      // const response = await fetch(API_URL + "/lostfound/found/add", {
      //   method: "POST",
      //   headers: headers,
      //   body: formData,
      // });
      // const data = await response.json();
      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "POST",
        url: "/lostfound/found/add",
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      // const data = await response.json();

      const { data } = await axiosInstance.request(config);

      dispatch(setLoading(false));

      if (data.success) {
        await dispatch(fetchFoundItemList(payload.property_id));
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));

      return rejectWithValue(error.message);
    }
  }
);

// ADD LOST ITEM -> Tenant Side
export const addLostItem = createAsyncThunk(
  "lostfound/lost/add",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const formData = new FormData();
      Object.keys(payload).map((key) => {
        if (key === "file") {
          payload[key].forEach((item) => {
            if (PLATFORM !== "web") {
              formData.append(key, {
                name: item.name,
                type: item.type,
                uri:
                  PLATFORM === "android"
                    ? item.uri
                    : item.uri.replace("file://", ""),
              });
            } else {
              formData.append(key, item, item.name);
            }
          });
        } else {
          formData.append(
            key,
            typeof payload[key] === "object"
              ? JSON.stringify(payload[key])
              : payload[key]
          );
        }
      });

      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }

      // const response = await fetch(API_URL + "/lostfound/lost/add", {
      //   method: "POST",
      //   headers: headers,
      //   body: formData,
      // });
      // const data = await response.json();
      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "POST",
        url: "/lostfound/lost/add",
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      // const data = await response.json();

      const { data } = await axiosInstance.request(config);

      dispatch(setLoading(false));

      if (data.success) {
        await dispatch(fetchTenantLostItemList(payload));
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// FETCH FOUND ITEMS LIST - Tenant Based On Unit
export const fetchTenantFoundItemList = createAsyncThunk(
  "tenant-lostfound-found/list",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(API_URL + "/lostfound/list", {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          property_id: payload?.property_id,
          status: "Open",
          type: "Found",
          page: payload?.page,
        }),
      });

      const data = await response.json();
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// FETCH LOST ITEMS LIST - Tenant Based On Unit
export const fetchTenantLostItemList = createAsyncThunk(
  "tenant-lostfound-lost/list",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(API_URL + "/lostfound/list", {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          unit_id: payload?.unit_id,
          tenant_id: payload?.tenant_id,
          type: "Lost",
        }),
      });

      const data = await response.json();
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// DELETE LOST ITEM BY ID - Tenant Side
export const deleteTenantLostItemById = createAsyncThunk(
  "tenant-lostfound-lost/delete",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(
        API_URL + `/lostfound/delete?id=${payload.id}`,
        {
          method: "DELETE",
          headers: headers,
        }
      );

      const data = await response.json();

      dispatch(setLoading(false));
      if (data.success) {
        await dispatch(fetchTenantLostItemList(payload));
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// ADD FOUND CLAIMED ITEM -> Client Side
export const addFoundClaimedItem = createAsyncThunk(
  "lostfound/claimbyfound",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await axios.post(
        API_URL + "/lostfound/claimbyfound",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );

      if (data.success) {
        await dispatch(fetchClaimedItemList());
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// ADD LOST CLAIMED ITEM -> Client Side
export const addLostClaimedItem = createAsyncThunk(
  "lostfound/claimbylost",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await axios.post(
        API_URL + "/lostfound/claimbylost",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );

      if (data.success) {
        await dispatch(fetchClaimedItemList());
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const lostAndFoundSlice = createSlice({
  name: "lostFound",
  initialState,
  extraReducers: {
    [fetchFoundItemList.rejected]: (state, action) => {
      state.foundList = [];
    },
    [fetchFoundItemList.fulfilled]: (state, action) => {
      state.foundList = action.payload;
    },
    [fetchLostItemList.rejected]: (state, action) => {
      state.lostList = [];
    },
    [fetchLostItemList.fulfilled]: (state, action) => {
      state.lostList = action.payload;
    },
    [fetchClaimedItemList.rejected]: (state, action) => {
      state.claimedItemsList = [];
    },
    [fetchClaimedItemList.fulfilled]: (state, action) => {
      state.claimedItemsList = action.payload;
    },
    [fetchTenantFoundItemList.rejected]: (state, action) => {
      state.tenantFoundList = [];
    },
    [fetchTenantFoundItemList.fulfilled]: (state, action) => {
      state.tenantFoundList = action.payload;
    },
    [fetchTenantLostItemList.rejected]: (state, action) => {
      state.tenantLostList = [];
    },
    [fetchTenantLostItemList.fulfilled]: (state, action) => {
      state.tenantLostList = action.payload;
    },
  },
});

// To Get The Found  Items List
export const getFoundsItemsList = (state) => state.lostFound.foundList;

// To Get The Lost Items List
export const getLostItemsList = (state) => state.lostFound.lostList;

// To Get The Claimed Items List
export const getClaimedItemsList = (state) => state.lostFound.claimedItemsList;

// To Get The Found Items List Based on Unit -> Tenant Side
export const getTennatFoundItemsList = (state) =>
  state.lostFound.tenantFoundList;

// To Get The Lost Items List Based on Unit -> Tenant Side
export const getTennatLostItemsList = (state) => state.lostFound.tenantLostList;

export default lostAndFoundSlice.reducer;
