/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  fetchSearchedViolationList,
  fetchViolationList,
  fetchViolationListBasedCommunity,
  fetchViolationListBasedProperty,
  getCommunityViolationList,
  getPropertyViolationList,
  getViolationList,
} from "../../setup/store/slices/violationDashboardSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { EnvelopeOutLinedBlue, PrintIcon } from "../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */

import GLOBAL from "../../setup/constants/global";
import Header from "./local-component/Header";
import PieChartGraph from "./local-component/PieChartGraph";
import Type3DPieChart from "../../components/ui/other/PieCharts/Type3DPieChart";
import Status3DPieChart from "../../components/ui/other/PieCharts/Status3DPieChart";
import Searchbar from "../../components/ui/other/Searchbar/Searchbar";
import ViolationTable from "./local-component/ViolationTable";
import CustomDateInput from "../../components/ui/input/CustomDateInput/CustomDateInput";
import Pagination from "../../components/module/RnD/Pagination/Pagination";
// import CustomButton from "../../components/ui/button/CustomButton/CustomButton";
// import TextButton from "../../components/ui/button/TextButton/TextButton";
import { getUniqueValues } from "../../setup/utils/global-helper";
import EmailModal from "../../components/ui/modal/EmailModal/EmailModal";
/* Import local pages and component ends */

import "./style.scss";
import BreadCrumb from "../../components/ui/other/BreadCrumb/BreadCrumb";
import moment from "moment";

/* Component starts */
const ViolationsDashboard = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const startDate = new Date();
  // const endDate = new Date();
  const [fromDate, setFromDate] = useState(
    new Date(startDate.getFullYear(), startDate.getMonth(), 1)
  );
  const [toDate, setToDate] = useState(
    // new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0)
    new Date()
  );

  const [search, setSearch] = useState("");
  const [violation, setViolation] = useState([]);
  const [filterViolations, setFilterViolations] = useState();
  const [typeData, setTypeData] = useState();
  const [statusData, setStatusData] = useState();
  const [cleared, setCleared] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  const [showModal, setShowModal] = useState(false);

  // FOR FILTERS
  const [communityData, setCommunityData] = useState();
  const [multiSelectCommunity, setMultiSelectCommunity] = useState();
  const [propertyData, setPropertyData] = useState();
  const [multiSelectProperty, setMultiSelectProperty] = useState();

  const [issuerData, setIssuerData] = useState();
  const [multiSelectIssuer, setMultiSelectIssuer] = useState();

  const [incidentData, setIncidentData] = useState();
  const [multiSelectIncident, setMultiSelectIncident] = useState();

  const [voilator, setVoilator] = useState();
  const [multiSelectViolator, setMultiSelectViolator] = useState();

  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const { pathname } = useLocation();
  const param = useParams();
  const dispatch = useDispatch();
  const violationList = useSelector(getViolationList);
  const propertyViolationList = useSelector(getPropertyViolationList);
  const communityViolationList = useSelector(getCommunityViolationList);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItemsList = filterViolations?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  const loadViolationList = async () => {
    if (pathname.includes("communities")) {
      await dispatch(
        fetchViolationListBasedCommunity({
          community_id: param.communityId,
        })
      );
    } else if (pathname.includes("independent")) {
      await dispatch(
        fetchViolationListBasedProperty({ property_id: param.propertyId })
      );
    } else {
      await dispatch(fetchViolationList());
    }
  };

  const filterTypeOfViolation = (violation) => {
    let tenants = violation?.filter((d) => d.violator === "Tenants");
    let teams = violation?.filter((d) => d.violator === "Teams");
    let others = violation?.filter((d) => d.violator === "Others");

    const obj = [];
    obj.push(["Violator Type", "No Of Violator"]);
    if (tenants?.length > 0) {
      obj.push(["Tenants", tenants?.length]);
    }
    if (teams?.length > 0) {
      obj.push(["Teams", teams?.length]);
    }
    if (others?.length > 0) {
      obj.push(["Others", others?.length]);
    }

    setTypeData(obj);
  };

  const filterStatusOfViolation = (occupancy) => {
    let isIncident = occupancy?.filter((d) => d.incident === "Yes");
    let notIncident = occupancy?.filter((d) => d.incident === "No");

    const obj = [];
    obj.push(["Is Incident", "No Of Incident"]);
    if (isIncident?.length > 0) {
      obj.push(["Incident", isIncident?.length]);
    }
    if (notIncident?.length > 0) {
      obj.push(["None Incident", notIncident?.length]);
    }

    setStatusData(obj);
  };

  const handleSearch = async (isReset = false, resetValue = {}) => {
    let payload = {};
    if (!isReset) {
      if (search && fromDate && toDate) {
        payload = {
          searchKey: search,
          start_date: moment(fromDate)?.format("MM-DD-YYYY"),
          end_date: moment(toDate)?.format("MM-DD-YYYY"),
        };
        // Backend response. Try, Catch
        try {
          let result;
          if (pathname.includes("communities")) {
            payload = {
              ...payload,
              community_id: param.communityId,
            };
            result = await dispatch(fetchSearchedViolationList(payload));
          } else if (pathname.includes("independent")) {
            payload = {
              ...payload,
              property_id: param.propertyId,
            };
            result = await dispatch(fetchSearchedViolationList(payload));
          } else {
            result = await dispatch(fetchSearchedViolationList(payload));
          }
          // Handling success response
          switch (result.meta.requestStatus) {
            case GLOBAL.REJECTED:
              // setModalDesc(result.payload);
              // setShowErrorModal(true);
              break;

            case GLOBAL.FULFILLED:
              // setModalDesc(result.payload.message);
              // setShowSuccessModal(true);
              setViolation(result.payload);
              setFilterViolations(result?.payload);
              break;
          }
        } catch (error) {
          // Handling error response
          console.error(error.message);
        }
      } else if (search) {
        payload = {
          searchKey: search,
        };

        await dispatch(fetchSearchedViolationList(payload));
      } else if (!search && fromDate && toDate) {
        payload = {
          searchKey: "",
          start_date: moment(fromDate)?.format("MM-DD-YYYY"),
          end_date: moment(toDate)?.format("MM-DD-YYYY"),
        };
        // Backend response. Try, Catch
        try {
          let result;
          if (pathname.includes("communities")) {
            payload = {
              ...payload,
              community_id: param.communityId,
            };
            result = await dispatch(fetchSearchedViolationList(payload));
          } else if (pathname.includes("independent")) {
            payload = {
              ...payload,
              property_id: param.propertyId,
            };
            result = await dispatch(fetchSearchedViolationList(payload));
          } else {
            result = await dispatch(fetchSearchedViolationList(payload));
          }
          // Handling success response
          switch (result.meta.requestStatus) {
            case GLOBAL.REJECTED:
              // setModalDesc(result.payload);
              // setShowErrorModal(true);
              break;

            case GLOBAL.FULFILLED:
              // setModalDesc(result.payload.message);
              // setShowSuccessModal(true);
              setViolation(result?.payload);
              setFilterViolations(result?.payload);
              break;
          }
        } catch (error) {
          // Handling error response
          console.error(error.message);
        }
      }
    }
  };

  const handleClear = () => {
    let payload = {
      searchKey: "",
      start_date: new Date(startDate.getFullYear(), startDate.getMonth(), 1),
      end_date: new Date(),
    };
    setSearch(payload.searchKey);
    setFromDate(payload.start_date);
    setToDate(payload.end_date);

    handleSearch(true, payload);
    setCleared(!cleared);
  };

  // To check the any checkbox is true or not
  const checkAnyFilterChecked = (filter) => {
    return Object?.keys(filter)?.every(function (k) {
      return !filter[k];
    });
  };

  // SEND EMAIL FUNCTION
  const handleSendEmail = () => {
    setShowModal(!showModal);
  };

  /* Component function definition ends */

  /* Component useEffect starts */

  useEffect(() => {
    loadViolationList();
  }, [pathname, !cleared]);

  useEffect(() => {
    if (pathname.includes("communities") && communityViolationList) {
      setViolation(communityViolationList);
      setFilterViolations([...communityViolationList]);
      filterTypeOfViolation(violation);
      filterStatusOfViolation(violation);
    } else if (pathname.includes("independent") && propertyViolationList) {
      setViolation(propertyViolationList);
      setFilterViolations([...propertyViolationList]);
      filterTypeOfViolation(violation);
      filterStatusOfViolation(violation);
    } else {
      setViolation(violationList);
      setFilterViolations([...violationList]);
      filterTypeOfViolation(violation);
      filterStatusOfViolation(violation);
    }
  }, [
    pathname,
    violationList,
    communityViolationList,
    propertyViolationList,
    !cleared,
  ]);

  useEffect(() => {
    if (pathname.includes("communities") && communityViolationList) {
      filterTypeOfViolation(violation);
      filterStatusOfViolation(violation);
    } else if (pathname.includes("independent") && propertyViolationList) {
      filterTypeOfViolation(violation);
      filterStatusOfViolation(violation);
    } else {
      filterTypeOfViolation(violation);
      filterStatusOfViolation(violation);
    }
  }, [!search, violation]);

  useEffect(() => {
    const community = getUniqueValues(violation, "community_name");
    const property = getUniqueValues(violation, "property_name");
    const violator = getUniqueValues(violation, "violator");

    const issuer = getUniqueValues(violation, "issuer");
    const incident = getUniqueValues(violation, "incident");

    const communityObject = {};
    community.forEach((prop) => (communityObject[prop] = false));
    setMultiSelectCommunity(communityObject);
    setCommunityData(community);

    const propertyObject = {};
    property.forEach((prop) => (propertyObject[prop] = false));
    setMultiSelectProperty(propertyObject);
    setPropertyData(property);

    const statusObject = {};
    violator.forEach((prop) => (statusObject[prop] = false));
    setMultiSelectViolator(statusObject);
    setVoilator(violator);

    const issuerObject = {};
    issuer.forEach((prop) => (issuerObject[prop] = false));
    setMultiSelectIssuer(issuerObject);
    setIssuerData(issuer);

    const incidentObject = {};
    incident.forEach((prop) => (incidentObject[prop] = false));
    setMultiSelectIncident(incidentObject);
    setIncidentData(incident);
  }, [violation]);

  useEffect(() => {
    if (
      multiSelectCommunity !== undefined ||
      multiSelectProperty !== undefined ||
      multiSelectIncident !== undefined ||
      multiSelectIssuer !== undefined ||
      multiSelectViolator !== undefined
    ) {
      let allUnCheckedCommunity = checkAnyFilterChecked(multiSelectCommunity);
      let allUnCheckedProperty = checkAnyFilterChecked(multiSelectProperty);

      let allUnCheckedIssuer = checkAnyFilterChecked(multiSelectIssuer);
      let allUnCheckedIncident = checkAnyFilterChecked(multiSelectIncident);

      let allUnCheckedStatus = checkAnyFilterChecked(multiSelectViolator);

      if (!allUnCheckedCommunity && multiSelectCommunity) {
        let selectedValue = Object.keys(multiSelectCommunity)?.filter(
          (key) => multiSelectCommunity[key]
        );

        const filteredCommunity = violation?.filter((d) =>
          selectedValue?.includes(d.community_name)
        );

        setFilterViolations(filteredCommunity);
      } else if (!allUnCheckedProperty && multiSelectProperty) {
        let selectedValue = Object.keys(multiSelectProperty)?.filter(
          (key) => multiSelectProperty[key]
        );

        const filteredProperty = violation?.filter((d) =>
          selectedValue?.includes(d.property_name)
        );

        setFilterViolations(filteredProperty);
      } else if (!allUnCheckedStatus && multiSelectViolator) {
        let selectedValue = Object.keys(multiSelectViolator)?.filter(
          (key) => multiSelectViolator[key]
        );

        const filteredStatus = violation?.filter((d) =>
          selectedValue?.includes(d.violator)
        );

        setFilterViolations(filteredStatus);
      } else if (!allUnCheckedIncident && multiSelectIncident) {
        let selectedValue = Object.keys(multiSelectIncident)?.filter(
          (key) => multiSelectIncident[key]
        );

        const filteredIncident = violation?.filter((d) =>
          selectedValue?.includes(d.incident)
        );

        setFilterViolations(filteredIncident);
      } else if (!allUnCheckedIssuer && multiSelectIssuer) {
        let selectedValue = Object.keys(multiSelectIssuer)?.filter(
          (key) => multiSelectIssuer[key]
        );

        const filteredIssuer = violation?.filter((d) =>
          selectedValue?.includes(d.issuer)
        );

        setFilterViolations(filteredIssuer);
      }
    } else {
      setFilterViolations(violation);
    }
  }, [
    multiSelectCommunity,
    multiSelectProperty,
    multiSelectViolator,
    multiSelectIncident,
    multiSelectIssuer,
  ]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      <BreadCrumb type="dashboard_violation" />
      <div className="violations-dashboard-wrapper">
        <div className="action-wrapper">
          <div>
            <img src={PrintIcon} alt="Print-Icon" />
            <span className="font_m fw_3 font_blue">Print</span>
          </div>

          <div onClick={handleSendEmail}>
            <img src={EnvelopeOutLinedBlue} alt="Print-Icon" />
            <span className="font_m fw_3 font_blue">Email</span>
          </div>
        </div>
        <div className="header">
          <Header />
        </div>

        {violation.length > 0 && (
          <div className="report-chart-wrapper">
            <div className="detail-box ">
              <div>
                <p className="font_l fw_4">Violator</p>
              </div>
              {/*    <div>
              <PieChartGraph typeData={typeData} />
            </div> */}
              <Type3DPieChart data={typeData} label="Violator" />
            </div>

            <div className="detail-box ">
              <div>
                <p className="font_l fw_4">Incident/None_incident</p>
              </div>
              {/*  <div>
            <PieChartGraph />
          </div> */}
              <Status3DPieChart
                data={statusData}
                label="Incident/None_incident"
              />
            </div>
          </div>
        )}

        <div className="search-box mt-4 mb-4">
          <div className="search">
            <Searchbar
              setQuery={setSearch}
              query={search}
              className="dash-search"
            />
          </div>

          <div className="date">
            <div>
              <CustomDateInput
                label="From"
                value={fromDate}
                minDate={false}
                customOnchange={(date) => setFromDate(date)}
                required={true}
              />
            </div>

            <div>
              <CustomDateInput
                label="To"
                value={toDate}
                minDate={false}
                customOnchange={(date) => setToDate(date)}
                required={true}
              />
            </div>
          </div>
          <div className="btn-wrapper">
            <button
              onClick={() => handleSearch()}
              // disabled={!search}
            >
              Apply
            </button>
            <button className="btn-clear" onClick={handleClear}>
              Clear
            </button>
          </div>
        </div>

        <div className="violations-table-wrapper">
          <ViolationTable
            violationListData={currentItemsList}
            communityData={communityData}
            multiSelectCommunity={multiSelectCommunity}
            setMultiSelectCommunity={setMultiSelectCommunity}
            propertyData={propertyData}
            multiSelectProperty={multiSelectProperty}
            setMultiSelectProperty={setMultiSelectProperty}
            violator={voilator}
            multiSelectViolator={multiSelectViolator}
            setMultiSelectViolator={setMultiSelectViolator}
            incident={incidentData}
            multiSelectIncident={multiSelectIncident}
            setMultiSelectIncident={setMultiSelectIncident}
            issuer={issuerData}
            multiSelectIssuer={multiSelectIssuer}
            setMultiSelectIssuer={setMultiSelectIssuer}
          />

          {filterViolations?.length > 0 && (
            <Pagination
              itemsPerPage={itemsPerPage}
              totalItems={filterViolations?.length}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          )}
        </div>

        {/* EMAIL MODAL */}
        <EmailModal
          showModal={showModal}
          onSuccess={() => setShowModal(!showModal)}
          onClose={() => setShowModal(!showModal)}
        />
      </div>
    </>
  );
};
/* Component ends */

export default ViolationsDashboard;
