/**
 *
 */

import React, { useState, useEffect, useRef } from "react";

/* Import configuration starts */
import { useParams } from "react-router-dom";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  addSecurityComplaint,
  fetchComplainType,
  getComplainType,
} from "../../../../../setup/store/slices/securitySlice";
import {
  fetchUnitDetailByID,
  getUnitDetail,
} from "../../../../../setup/store/slices/unitSlice";
import { showErrorAlert } from "../../../../../setup/store/slices/globalAlertSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import { addDoc, initialValues, removeDoc, validations } from "./helper";
import GLOBAL from "../../../../../setup/constants/global";
import SuccessModal from "../../../../ui/modal/SuccessModal/SuccessModal";
import ErrorModal from "../../../../ui/modal/ErrorModal/ErrorModal";
import CustomFileInput from "../../../../ui/input/CustomFileInput/CustomFileInput";
import CustomButton from "../../../../ui/button/CustomButton/CustomButton";
import TextButton from "../../../../ui/button/TextButton/TextButton";
import CustomTextArea from "../../../../ui/input/CustomTextArea/CustomTextArea";
import CustomSelectInput from "../../../../ui/input/CustomSelectInput/CustomSelectInput";
/* Import local pages and component ends */

// Styles
import "./style.scss";

/* Component starts */
const AddComplainForm = (props) => {
  /* Props destructuring starts */
  const { onSuccess, onCancel } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */

  const [message, setMessage] = useState("");
  const [showErrModal, setShowErrModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [selectComplainType, setSelectComplainType] = useState();

  const [docList, setDocList] = useState([]);
  const [initialFormValues, setInitialFormValues] = useState(initialValues);

  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const formRef = useRef();
  // Redux Selector
  const complainTypes = useSelector(getComplainType);
  const { unitId } = useParams();
  const unit = useSelector(getUnitDetail);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  // // Form validations
  const formValidation = (values) => {
    return validations(values, docList);
  };

  // Handling on submit
  const onSubmit = async (values) => {
    if (docList?.length === 0) {
      dispatch(
        showErrorAlert({
          title: "Cannot Create Ticket",
          description: "Please add atleast one media.",
        })
      );
      return;
    }

    let security_complain_id = complainTypes?.filter(
      (complain) => complain?.name === selectComplainType?.name
    )[0]?._id;

    // Creating or collecting payload data to be sent
    const payload = {
      tenant_id: unit?.tenant?._id,
      description: values?.description,
      security_complaint_id: security_complain_id,
      unit_id: unit?._id,
    };

    const document = [];

    docList?.forEach((item, index) => {
      if (values[`document_${item}`]) {
        document.push(values[`document_${item}`]);
      }
    });
    payload["document"] = document;

    console.log(payload, "PAYLOAD");

    // // Backend response. Try, Catch
    try {
      const result = await dispatch(addSecurityComplaint(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setMessage(result.payload);
          setShowErrModal(true);
          break;

        case GLOBAL.FULFILLED:
          setMessage("Complaint Added Successfully");
          setShowSuccessModal(true);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */

  // Fetching all Complain Types on initial load
  useEffect(() => {
    dispatch(fetchComplainType());
  }, []);

  useEffect(() => {
    if (complainTypes?.length > 0) {
      setSelectComplainType(complainTypes[0]);
    }
  }, [complainTypes]);

  useEffect(() => {
    dispatch(fetchUnitDetailByID({ unit_id: unitId }));
  }, [unitId]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="add-so-module-wrapper">
      <h2 className="heading text-center font_xxl mb-5">
        Register Security Complain
      </h2>

      <div className="add-new-form">
        <Formik
          onSubmit={onSubmit}
          initialValues={initialFormValues}
          validate={formValidation}
          validateOnBlur={true}
          innerRef={formRef}
          enableReinitialize={true}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="form-wrapper">
                {/* Creating new Complain */}

                <div className="form-group">
                  <CustomSelectInput
                    name="complain"
                    label="Select Complain Type"
                    handleBlur={handleBlur}
                    onChange={handleChange}
                    valueExtractor={(item) => item?.name}
                    setValue={setSelectComplainType}
                    options={complainTypes}
                    value={selectComplainType}
                    formikState={false}
                    emptyOption={false}
                  />
                </div>

                {/* Text Area for Description */}
                <div className="form-group form-group-full">
                  <CustomTextArea
                    name="description"
                    label="Description"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    value={values.description}
                    errors={errors.description}
                    touched={touched.description}
                    required={true}
                  />
                </div>

                {/* Input for Photo */}
                <div className="doc-list-wrapper">
                  <h3 className="font_m fw_6">Photos and Videos</h3>
                  <div className="doc-list">
                    {docList?.length === 0 && (
                      <h3 className="text-center font_m font_yellow fw_6 no-attachments">
                        No photos or videos attached!
                      </h3>
                    )}

                    {docList?.map((item, index) => (
                      <div className="form-group doc" key={index}>
                        <div className="file-input-wrapper">
                          <CustomFileInput
                            name={`document_${item}`}
                            label="Document"
                            setFieldValue={setFieldValue}
                            setFieldTouched={setFieldTouched}
                            formikState={true}
                            touched={touched[`document_${item}`]}
                            errors={errors[`document_${item}`]}
                            value={values[`document_${item}`]}
                            required={true}
                            acceptable={true}
                            acceptType="image/*,video/*"
                          />
                        </div>

                        {docList.length > 1 && (
                          <div className="remove-btn-wrapper">
                            <TextButton
                              className="button font_m fw_6"
                              variant="red"
                              onClick={() => {
                                removeDoc(
                                  formRef,
                                  docList,
                                  setInitialFormValues,
                                  setDocList,
                                  index
                                );
                              }}
                            >
                              Remove
                            </TextButton>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>

                  <div className="doc_action_btn-wrapper add-btn-wrapper">
                    {(docList.length === 0 ||
                      values[`document_${docList[docList.length - 1]}`]) && (
                      <CustomButton
                        className="font_m fw_6 add-btn"
                        variant="outline"
                        size="s"
                        onClick={() => {
                          addDoc(
                            formRef,
                            docList,
                            setInitialFormValues,
                            setDocList
                          );
                        }}
                      >
                        + Add
                      </CustomButton>
                    )}
                  </div>
                </div>
              </div>

              <div className="button-wrapper text-center">
                <>
                  <CustomButton type="submit" size="l">
                    Submit
                  </CustomButton>
                  <TextButton
                    type="button"
                    onClick={onCancel}
                    className="font_m fw_6"
                  >
                    Cancel
                  </TextButton>
                </>
              </div>
            </form>
          )}
        </Formik>

        {/* Modals */}
        {/* Error Modal */}
        <ErrorModal
          showModal={showErrModal}
          title="Error"
          description={message}
          onClose={() => {
            setShowErrModal(false);
          }}
        />

        {/* Success Modal */}
        <SuccessModal
          showModal={showSuccessModal}
          title="Success"
          description={message}
          onClose={() => {
            onSuccess();
          }}
        />
      </div>
    </div>
  );
};
/* Component ends */

export default AddComplainForm;
