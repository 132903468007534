/**
 *
 */

import React, { useState, useEffect, useCallback } from "react";

/* Import configuration starts */
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  LabelList,
  Legend,
  ResponsiveContainer,
} from "recharts";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
/* Import local pages and component ends */

/* Component starts */
const BarChartGraph = (props) => {
  /* Props destructuring starts */
  const { financialData } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */

  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  //   const COLORS = ["#1097FF", "#D9AF58", "#1AD3AA", "#FE5D6E", "#37BDB0"];

  // const renderCustomizedLabel = (props) => {
  //   const { x, y, width, height, value } = props;
  //   // console.log(x, y, width, height, value, "145");
  //   const radius = 10;

  //   return (
  //     <g>
  //       {/* <circle cx={x + width / 2} cy={y - radius} r={radius} fill="#8884d8" /> */}
  //       <text
  //         x={x + width / 2}
  //         y={y - radius}
  //         fill="#fff"
  //         textAnchor="middle"
  //         dominantBaseline="middle"
  //       >
  //         {value}
  //       </text>
  //     </g>
  //   );
  // };

  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div style={{ width: "100%", height: 400 }}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={300}
          data={financialData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          {/* <CartesianGrid strokeDasharray="3 3" /> */}
          <XAxis dataKey="name" tickLine={false} />
          <YAxis
            padding={{ top: 10, bottom: 10 }}
            tickLine={false}
            type="number"
            // dataKey={(v) => parseInt(v.someValue)}
            domain={[0, "dataMax+100"]}
            // label={{ value: 'INCOME', angle: -90, position: 'insideLeft', textAnchor: 'middle' }} - To SHow The Y-axix label
          />
          {/* <Tooltip /> */}
          <Legend />
          <Bar
            dataKey="Tenant"
            fill="#D9AF58"
            barSize={20}
            // activeBar={<Rectangle fill="pink" stroke="blue" />}
          >
            {/* <LabelList dataKey="Tenant" content={renderCustomizedLabel} /> */}
          </Bar>
          <Bar
            dataKey="Property"
            fill="#37BDB0"
            barSize={20}
            // activeBar={<Rectangle fill="gold" stroke="purple" />}
          >
            {/* <LabelList dataKey="Property" content={renderCustomizedLabel} /> */}
          </Bar>
          <Bar
            dataKey="Party"
            fill="#FF0000"
            barSize={20}
            // activeBar={<Rectangle fill="pink" stroke="blue" />}
          >
            {/* <LabelList dataKey="Party" content={renderCustomizedLabel} /> */}
          </Bar>
          {/* <Bar
            dataKey="Advertisment"
            fill="#1097FF"
            barSize={20}
            // activeBar={<Rectangle fill="gold" stroke="purple" />}
          >
          <LabelList dataKey="name" content={renderCustomizedLabel} />
          </Bar>
          */}
          <Bar
            dataKey="Deposit"
            fill="#0000FF"
            barSize={20}
            // activeBar={<Rectangle fill="pink" stroke="blue" />}
          >
            {/* <LabelList dataKey="Deposit" content={renderCustomizedLabel} /> */}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};
/* Component ends */

export default BarChartGraph;
