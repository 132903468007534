/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */

import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  fetchSearchedSparePartsList,
  fetchSparePartsList,
  fetchSparePartsListBasedCommunity,
  fetchSparePartsListBasedProperty,
  getCommunitySparePartsList,
  getPropertySparePartsList,
  getSparePartsList,
} from "../../setup/store/slices/sparePartsDashboardSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { EnvelopeOutLinedBlue, PrintIcon } from "../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import GLOBAL from "../../setup/constants/global";
import Pagination from "../../components/module/RnD/Pagination/Pagination";
import Header from "./local-component/Header";
import Searchbar from "../../components/ui/other/Searchbar/Searchbar";
import SparePartsTable from "./local-component/SparePartsTable";
import CustomDateInput from "../../components/ui/input/CustomDateInput/CustomDateInput";
import Type3DPieChart from "../../components/ui/other/PieCharts/Type3DPieChart";
import Status3DPieChart from "../../components/ui/other/PieCharts/Status3DPieChart";
import { getUniqueValues } from "../../setup/utils/global-helper";
import EmailModal from "../../components/ui/modal/EmailModal/EmailModal";
/* Import local pages and component ends */

import "./style.scss";
import BreadCrumb from "../../components/ui/other/BreadCrumb/BreadCrumb";
import moment from "moment";

/* Component starts */
const SparePartsDashboard = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const startDate = new Date();
  // const endDate = new Date();
  const [fromDate, setFromDate] = useState(
    new Date(startDate.getFullYear(), startDate.getMonth(), 1)
  );
  const [toDate, setToDate] = useState(
    // new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0)
    new Date()
  );

  const [search, setSearch] = useState("");
  const [spareParts, setSpareParts] = useState([]);
  const [filteredSpareParts, setFilteredSpareParts] = useState();

  const [typeData, setTypeData] = useState();
  const [statusData, setStatusData] = useState();
  const [cleared, setCleared] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [showModal, setShowModal] = useState(false);

  // FILTERS
  const [communityData, setCommunityData] = useState();
  const [multiSelectCommunity, setMultiSelectCommunity] = useState();
  const [propertyData, setPropertyData] = useState();
  const [multiSelectProperty, setMultiSelectProperty] = useState();

  const [typesData, setTypesData] = useState();
  const [multiSelectType, setMultiSelectType] = useState();

  const [quoteData, setQuoteData] = useState();
  const [multiSelectQuote, setMultiSelectQuote] = useState();

  const [itemsData, setItemsData] = useState();
  const [multiSelectItems, setMultiSelectItems] = useState();

  const [processingData, setProcessingData] = useState();
  const [multiSelectProcessing, setMultiSelectProcessing] = useState();

  const [amountData, setAmountData] = useState();
  const [multiSelectAmount, setMultiSelectAmount] = useState();

  const [approverData, setApproverData] = useState();
  const [multiSelectApprover, setMultiSelectApprover] = useState();

  const [statusData1, setStatusData1] = useState();
  const [multiSelectStatus, setMultiSelectStatus] = useState();
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const { pathname } = useLocation();
  const param = useParams();
  const dispatch = useDispatch();
  const sparePartsList = useSelector(getSparePartsList);
  const propertySparePartsList = useSelector(getPropertySparePartsList);
  const communitySparePartsList = useSelector(getCommunitySparePartsList);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItemsList = filteredSpareParts?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  const loadSparePartsList = async () => {
    if (pathname.includes("communities")) {
      await dispatch(
        fetchSparePartsListBasedCommunity({
          community_id: param.communityId,
        })
      );
    } else if (pathname.includes("independent")) {
      await dispatch(
        fetchSparePartsListBasedProperty({ property_id: param.propertyId })
      );
    } else {
      await dispatch(fetchSparePartsList());
    }
  };

  const filterTypeOfSpareParts = (contracts) => {
    let moveInType = contracts?.filter((d) => d.type === "Move In");
    let moveOutType = contracts?.filter((d) => d.type === "Move Out");
    let maintenanceType = contracts?.filter((d) => d.type === "Maintenance");
    let interMaintenanceType = contracts?.filter(
      (d) => d.type === "Internal Maintenance"
    );

    const obj = [];
    obj.push(["Type", "No Of Types"]);
    if (moveInType?.length > 0) {
      obj.push(["Move In", moveInType?.length]);
    }
    if (moveOutType?.length > 0) {
      obj.push(["Move Out", moveOutType?.length]);
    }
    if (maintenanceType?.length > 0) {
      obj.push(["Maintenance", maintenanceType?.length]);
    }
    if (interMaintenanceType?.length > 0) {
      obj.push(["Internal Maintenance", interMaintenanceType?.length]);
    }

    setTypeData(obj);
  };

  const filterStatusOfSpareParts = (contracts) => {
    let approved = contracts?.filter((d) => d.status === "Approved");
    let pending = contracts?.filter((d) => d.status === "Pending");

    let rejected = contracts?.filter((d) => d.status === "Rejected");

    const obj = [];
    obj.push(["Status Type", "No of Status"]);
    if (approved?.length > 0) {
      obj.push(["Aprroved", approved?.length]);
    }
    if (pending?.length > 0) {
      obj.push(["Pending", pending?.length]);
    }
    if (rejected?.length > 0) {
      obj.push(["Rejected", rejected?.length]);
    }

    setStatusData(obj);
  };

  const handleSearch = async (isReset = false, resetValue = {}) => {
    let payload = {};
    if (!isReset) {
      if (search && fromDate && toDate) {
        payload = {
          searchKey: search,
          start_date: moment(fromDate)?.format("MM-DD-YYYY"),
          end_date: moment(toDate)?.format("MM-DD-YYYY"),
        };
        // Backend response. Try, Catch
        try {
          let result;
          if (pathname.includes("communities")) {
            payload = {
              ...payload,
              community_id: param.communityId,
            };
            result = await dispatch(fetchSearchedSparePartsList(payload));
          } else if (pathname.includes("independent")) {
            payload = {
              ...payload,
              property_id: param.propertyId,
            };
            result = await dispatch(fetchSearchedSparePartsList(payload));
          } else {
            result = await dispatch(fetchSearchedSparePartsList(payload));
          }
          // Handling success response
          switch (result.meta.requestStatus) {
            case GLOBAL.REJECTED:
              // setModalDesc(result.payload);
              // setShowErrorModal(true);
              break;

            case GLOBAL.FULFILLED:
              setSpareParts(result.payload);
              setFilteredSpareParts(result?.payload);
              break;
          }
        } catch (error) {
          // Handling error response
          console.error(error.message);
        }
      } else if (search) {
        payload = {
          searchKey: search,
        };
        await dispatch(fetchSearchedSparePartsList(payload));
      } else if (!search && fromDate && toDate) {
        payload = {
          searchKey: "",
          start_date: moment(fromDate)?.format("MM-DD-YYYY"),
          end_date: moment(toDate)?.format("MM-DD-YYYY"),
        };
        // Backend response. Try, Catch
        try {
          let result;
          if (pathname.includes("communities")) {
            payload = {
              ...payload,
              community_id: param.communityId,
            };
            result = await dispatch(fetchSearchedSparePartsList(payload));
          } else if (pathname.includes("independent")) {
            payload = {
              ...payload,
              property_id: param.propertyId,
            };
            result = await dispatch(fetchSearchedSparePartsList(payload));
          } else {
            result = await dispatch(fetchSearchedSparePartsList(payload));
          }
          // Handling success response
          switch (result.meta.requestStatus) {
            case GLOBAL.REJECTED:
              // setModalDesc(result.payload);
              // setShowErrorModal(true);
              break;

            case GLOBAL.FULFILLED:
              setSpareParts(result.payload);
              setFilteredSpareParts(result?.payload);
              break;
          }
        } catch (error) {
          // Handling error response
          console.error(error.message);
        }
      }
    }
    // else {
    //   payload = {
    //     ...resetValue,
    //   };
    // }
  };

  const handleClear = () => {
    let payload = {
      searchKey: "",
      start_date: new Date(startDate.getFullYear(), startDate.getMonth(), 1),
      end_date: new Date(),
    };
    setSearch(payload.searchKey);
    setFromDate(payload.start_date);
    setToDate(payload.end_date);

    handleSearch(true, payload);
    setCleared(!cleared);
  };

  // To check the any checkbox is true or not
  const checkAnyFilterChecked = (filter) => {
    return Object?.keys(filter)?.every(function (k) {
      return !filter[k];
    });
  };

  // SEND EMAIL FUNCTION
  const handleSendEmail = () => {
    setShowModal(!showModal);
  };

  /* Component function definition ends */

  /* Component useEffect starts */

  useEffect(() => {
    loadSparePartsList();
  }, [pathname, !cleared]);

  useEffect(() => {
    if (pathname.includes("communities") && communitySparePartsList) {
      setSpareParts(communitySparePartsList);
      setFilteredSpareParts([...communitySparePartsList]);
      filterTypeOfSpareParts(spareParts);
      filterStatusOfSpareParts(spareParts);
    } else if (pathname.includes("independent") && propertySparePartsList) {
      setSpareParts(propertySparePartsList);
      setFilteredSpareParts([...propertySparePartsList]);
      filterTypeOfSpareParts(spareParts);
      filterStatusOfSpareParts(spareParts);
    } else {
      setSpareParts(sparePartsList);
      setFilteredSpareParts([...sparePartsList]);
      filterTypeOfSpareParts(spareParts);
      filterStatusOfSpareParts(spareParts);
    }
  }, [
    pathname,
    sparePartsList,
    communitySparePartsList,
    propertySparePartsList,
    !cleared,
  ]);

  useEffect(() => {
    if (pathname.includes("communities") && communitySparePartsList) {
      filterTypeOfSpareParts(spareParts);
      filterStatusOfSpareParts(spareParts);
    } else if (pathname.includes("independent") && propertySparePartsList) {
      filterTypeOfSpareParts(spareParts);
      filterStatusOfSpareParts(spareParts);
    } else {
      filterTypeOfSpareParts(spareParts);
      filterStatusOfSpareParts(spareParts);
    }
  }, [!search, spareParts]);

  useEffect(() => {
    const community = getUniqueValues(spareParts, "community_name");
    const property = getUniqueValues(spareParts, "property_name");
    const quote = getUniqueValues(spareParts, "quote");
    const items = getUniqueValues(spareParts, "items");
    const processing = getUniqueValues(spareParts, "processing");
    const amount = getUniqueValues(spareParts, "amount");
    const approver = getUniqueValues(spareParts, "approver");

    const type = getUniqueValues(spareParts, "type");

    const status = getUniqueValues(spareParts, "status");

    const communityObject = {};
    community.forEach((prop) => (communityObject[prop] = false));
    setMultiSelectCommunity(communityObject);
    setCommunityData(community);

    const propertyObject = {};
    property.forEach((prop) => (propertyObject[prop] = false));
    setMultiSelectProperty(propertyObject);
    setPropertyData(property);

    const quoteObject = {};
    quote.forEach((prop) => (quoteObject[prop] = false));
    setMultiSelectQuote(quoteObject);
    setQuoteData(quote);

    const itemsObject = {};
    items?.sort((a, b) => a - b).forEach((prop) => (itemsObject[prop] = false));
    setMultiSelectItems(itemsObject);
    setItemsData(items?.sort((a, b) => a - b));

    const amountObject = {};
    amount
      ?.sort((a, b) => a - b)
      .forEach((prop) => (amountObject[prop] = false));
    setMultiSelectAmount(amountObject);
    setAmountData(amount?.sort((a, b) => a - b));

    const processingObject = {};
    processing
      ?.sort((a, b) => a - b)
      .forEach((prop) => (processingObject[prop] = false));
    setMultiSelectProcessing(processingObject);
    setProcessingData(processing?.sort((a, b) => a - b));

    const approverObject = {};
    approver.forEach((prop) => (approverObject[prop] = false));
    setMultiSelectApprover(approverObject);
    setApproverData(approver);

    const typeObject = {};
    type.forEach((prop) => (typeObject[prop] = false));
    setMultiSelectType(typeObject);
    setTypesData(type);

    const statusObject = {};
    status.forEach((prop) => (statusObject[prop] = false));
    setMultiSelectStatus(statusObject);
    setStatusData1(status);
  }, [spareParts]);

  useEffect(() => {
    if (
      multiSelectCommunity !== undefined ||
      multiSelectProperty !== undefined ||
      multiSelectStatus !== undefined ||
      multiSelectQuote !== undefined ||
      multiSelectAmount !== undefined ||
      multiSelectItems !== undefined ||
      multiSelectProcessing !== undefined ||
      multiSelectApprover !== undefined ||
      multiSelectType !== undefined
    ) {
      let allUnCheckedCommunity = checkAnyFilterChecked(multiSelectCommunity);
      let allUnCheckedProperty = checkAnyFilterChecked(multiSelectProperty);
      let allUnCheckedType = checkAnyFilterChecked(multiSelectType);
      let allUnCheckedStatus = checkAnyFilterChecked(multiSelectStatus);

      let allUnCheckedQuote = checkAnyFilterChecked(multiSelectQuote);
      let allUnCheckedItems = checkAnyFilterChecked(multiSelectItems);
      let allUnCheckedAmount = checkAnyFilterChecked(multiSelectAmount);
      let allUnCheckedProcessing = checkAnyFilterChecked(multiSelectProcessing);
      let allUnCheckedApprover = checkAnyFilterChecked(multiSelectApprover);

      if (!allUnCheckedCommunity && multiSelectCommunity) {
        let selectedValue = Object.keys(multiSelectCommunity)?.filter(
          (key) => multiSelectCommunity[key]
        );

        const filteredCommunity = spareParts?.filter((d) =>
          selectedValue?.includes(d.community_name)
        );

        setFilteredSpareParts(filteredCommunity);
      } else if (!allUnCheckedProperty && multiSelectProperty) {
        let selectedValue = Object.keys(multiSelectProperty)?.filter(
          (key) => multiSelectProperty[key]
        );

        const filteredProperty = spareParts?.filter((d) =>
          selectedValue?.includes(d.property_name)
        );

        setFilteredSpareParts(filteredProperty);
      } else if (!allUnCheckedStatus && multiSelectStatus) {
        let selectedValue = Object.keys(multiSelectStatus)?.filter(
          (key) => multiSelectStatus[key]
        );

        const filteredStatus = spareParts?.filter((d) =>
          selectedValue?.includes(d.status)
        );

        setFilteredSpareParts(filteredStatus);
      } else if (!allUnCheckedType && multiSelectType) {
        let selectedValue = Object.keys(multiSelectType)?.filter(
          (key) => multiSelectType[key]
        );

        const filteredUnitType = spareParts?.filter((d) =>
          selectedValue?.includes(d.type)
        );

        setFilteredSpareParts(filteredUnitType);
      } else if (!allUnCheckedQuote && multiSelectQuote) {
        let selectedValue = Object.keys(multiSelectQuote)?.filter(
          (key) => multiSelectQuote[key]
        );

        const filteredQuote = spareParts?.filter((d) =>
          selectedValue?.includes(d.quote)
        );

        setFilteredSpareParts(filteredQuote);
      } else if (!allUnCheckedItems && multiSelectItems) {
        let selectedValue = Object.keys(multiSelectItems)
          ?.filter((key) => multiSelectItems[key])
          .map(Number);

        const filteredUnitType = spareParts?.filter((d) =>
          selectedValue?.includes(d.items)
        );

        setFilteredSpareParts(filteredUnitType);
      } else if (!allUnCheckedAmount && multiSelectAmount) {
        let selectedValue = Object.keys(multiSelectAmount)?.filter(
          (key) => multiSelectAmount[key]
        );

        const filteredAmount = spareParts?.filter((d) =>
          selectedValue?.includes(d.amount)
        );

        setFilteredSpareParts(filteredAmount);
      } else if (!allUnCheckedProcessing && multiSelectProcessing) {
        let selectedValue = Object.keys(multiSelectProcessing)
          ?.filter((key) => multiSelectProcessing[key])
          .map(Number);

        const filteredProcess = spareParts?.filter((d) =>
          selectedValue?.includes(d.processing)
        );

        setFilteredSpareParts(filteredProcess);
      } else if (!allUnCheckedApprover && multiSelectApprover) {
        let selectedValue = Object.keys(multiSelectApprover)?.filter(
          (key) => multiSelectApprover[key]
        );

        const filteredApprove = spareParts?.filter((d) =>
          selectedValue?.includes(d.approver)
        );

        setFilteredSpareParts(filteredApprove);
      }
    } else {
      setFilteredSpareParts(spareParts);
    }
  }, [
    multiSelectCommunity,
    multiSelectProperty,
    multiSelectStatus,
    multiSelectType,
    multiSelectAmount,
    multiSelectApprover,
    multiSelectItems,
    multiSelectProcessing,
    multiSelectQuote,
  ]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      <BreadCrumb type="dashboard_spare" />
      <div className="spare-parts-dashboard-wrapper">
        <div className="action-wrapper">
          <div>
            <img src={PrintIcon} alt="Print-Icon" />
            <span className="font_m fw_3 font_blue">Print</span>
          </div>

          <div onClick={handleSendEmail}>
            <img src={EnvelopeOutLinedBlue} alt="Print-Icon" />
            <span className="font_m fw_3 font_blue">Email</span>
          </div>
        </div>
        <div className="header">
          <Header />
        </div>

        {spareParts?.length > 0 && (
          <div className="report-chart-wrapper">
            <div className="detail-box bg_grey">
              {/* <div>
            <p className="font_l fw_4">Categories</p>
          </div>
          <div>
            <PieChartGraph />
          </div> */}
              {/* <ThreeDGraph /> */}
              <div>
                <p className="font_l fw_4">Maint. Cat.</p>
              </div>
              <Type3DPieChart data={typeData} label="" />
            </div>

            <div className="detail-box bg_grey">
              {/* <div>
            <p className="font_l fw_4">Status</p>
          </div>
          <div>
            <PieChartGraph />
          </div> */}
              <div>
                <p className="font_l fw_4">Quote Status</p>
              </div>
              <Status3DPieChart data={statusData} label="Quote Status" />
            </div>
          </div>
        )}

        <div className="search-box mt-4 mb-4">
          <div className="search">
            <Searchbar
              setQuery={setSearch}
              query={search}
              className="dash-search"
            />
          </div>

          <div className="date">
            <CustomDateInput
              label="From"
              value={fromDate}
              minDate={false}
              customOnchange={(date) => setFromDate(date)}
              required={true}
            />
          </div>

          <div className="date">
            <CustomDateInput
              label="To"
              value={toDate}
              minDate={false}
              customOnchange={(date) => setToDate(date)}
              required={true}
            />
          </div>

          <div className="btn-wrapper">
            <button onClick={() => handleSearch()}>Apply</button>
            <button className="btn-clear" onClick={() => handleClear()}>
              Clear
            </button>
          </div>
        </div>

        <div className="spare-parts-table-wrapper">
          <SparePartsTable
            sparePartsListData={currentItemsList}
            communityData={communityData}
            multiSelectCommunity={multiSelectCommunity}
            setMultiSelectCommunity={setMultiSelectCommunity}
            propertyData={propertyData}
            multiSelectProperty={multiSelectProperty}
            setMultiSelectProperty={setMultiSelectProperty}
            typesData={typesData}
            multiSelectType={multiSelectType}
            setMultiSelectType={setMultiSelectType}
            statusData1={statusData1}
            multiSelectStatus={multiSelectStatus}
            setMultiSelectStatus={setMultiSelectStatus}
            processing={processingData}
            multiSelectProcessing={multiSelectProcessing}
            setMultiSelectProcessing={setMultiSelectProcessing}
            quote={quoteData}
            multiSelectQuote={multiSelectQuote}
            setMultiSelectQuote={setMultiSelectQuote}
            items={itemsData}
            multiSelectItems={multiSelectItems}
            setMultiSelectItems={setMultiSelectItems}
            amount={amountData}
            multiSelectAmount={multiSelectAmount}
            setMultiSelectAmount={setMultiSelectAmount}
            approver={approverData}
            multiSelectApprover={multiSelectApprover}
            setMultiSelectApprover={setMultiSelectApprover}
          />

          {filteredSpareParts?.length > 0 && (
            <Pagination
              itemsPerPage={itemsPerPage}
              totalItems={filteredSpareParts?.length}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          )}
        </div>

        {/* EMAIL MODAL */}
        <EmailModal
          showModal={showModal}
          onSuccess={() => setShowModal(!showModal)}
          onClose={() => setShowModal(!showModal)}
        />
      </div>
    </>
  );
};
/* Component ends */

export default SparePartsDashboard;
