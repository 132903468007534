/**
 * This component is for custom text input field
 */

import React from "react";

/* Import configuration starts */
/* Import configuration ends */

import "./style.scss";
import { useSelector } from "react-redux";
import { getUserTheme } from "../../../../setup/store/slices/authSlice";

/* Component starts */
const CustomTextArea = (props) => {
  /* Props destructuring starts */
  const {
    label,
    handleChange = null,
    value,
    setValue = null,
    name,
    errors,
    handleBlur,
    touched,
    required = true,
    className,
    readOnly = false,
  } = props;
  /* Props destructuring ends */

  const userTheme = useSelector(getUserTheme);

  /* Component function definition starts */
  const changHandler = (e) => {
    handleChange && handleChange(e);
    setValue && setValue(e.target.value);
  };
  /* Component function definition ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className={`custom-form-control ${className}`}>
      <div className="form-textarea-wrapper">
        <label className="font_s font_grey">
          {label}
          {required && <span className="font_m font_red"> *</span>}
        </label>
        <textarea
          onBlur={handleBlur}
          onChange={changHandler}
          value={value}
          name={name}
          // className="bg_black font_xs" || userTheme === "automatic"
          className={` font_xs ${
            userTheme === "light"
              ? "bg_lightThemeInput font_lightTheme"
              : "bg_black font_white"
          }`}
          readOnly={readOnly}
        ></textarea>
      </div>

      {touched && errors && <p className="error font_xxs font_red">{errors}</p>}
    </div>
  );
};
/* Component ends */

export default CustomTextArea;
