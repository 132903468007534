/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useSelector } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import { getIsTENANT } from "../../setup/store/slices/authSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import ChangeEmail from "./local-component/ChangeEmail";
import BreadCrumb from "../../components/ui/other/BreadCrumb/BreadCrumb";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const ChangeEmailPage = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const isTenant = useSelector(getIsTENANT);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      {!isTenant && <BreadCrumb type="settings_changeEmail_page" />}
      <div className="change-email-wrapper container_sm">
        <div className="center bg_grey">
          <div className="logo-wrapper text-center">
            <p className="fw_6 font_l">Update Email</p>
          </div>
          <ChangeEmail />
        </div>
      </div>
    </>
  );
};
/* Component ends */

export default ChangeEmailPage;
