/**
 *
 */

import React, { useState, useEffect, useRef } from "react";

/* Import configuration starts */
import { FieldArray, Formik } from "formik";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  householdMemberValidation,
  formPrefill,
  setFormList,
  isDataChanged,
  isApproved,
} from "./helper";
import GLOBAL from "../../../../setup/constants/global";
import { useDispatch } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import { showErrorAlert } from "../../../../setup/store/slices/globalAlertSlice";
import { fetchCountries } from "../../../../setup/store/slices/countrySlice";
import {
  clearMasterData,
  fetchGenders,
  fetchIdTypes,
  fetchLocales,
  fetchRelations,
} from "../../../../setup/store/slices/masterSlice";
import { updateTenantApplication } from "../../../../setup/store/slices/tenantSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomNumberInput from "../../../ui/input/CustomNumberInput/CustomNumberInput";
import TextButton from "../../../ui/button/TextButton/TextButton";
import CustomDateInput from "../../../ui/input/CustomDateInput/CustomDateInput";
import CustomTextInput from "../../../ui/input/CustomTextInput/CustomTextInput";
import CustomSelectInput from "../../../ui/input/CustomSelectInput/CustomSelectInput";
import CustomPhoneInput from "../../../ui/input/CustomPhoneInput/CustomPhoneInput";
import CustomFileInput from "../../../ui/input/CustomFileInput/CustomFileInput";
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
import SuccessModal from "../../../ui/modal/SuccessModal/SuccessModal";
import ErrorModal from "../../../ui/modal/ErrorModal/ErrorModal";
/* Import local pages and component ends */

/* Component starts */
const HouseholdMembersForm = (props) => {
  /* Props destructuring starts */
  // states
  const {
    countries,
    _availableLocales,
    IdTypes,
    genders,
    relations,
    data,
    applicationId,
    maxAllowed,
    canCancelApplication = false,
    validateDataUpdated = true,
  } = props;

  // functions
  const {
    fetchLocales,
    fetchCountries,
    fetchIdTypes,
    fetchGenders,
    fetchRelations,
    update,
    onNext,
    clearCountries,
    clearMasterData,
    fetchApplication = null,
    onCancelStep = null,
    onCancelApplication,
    onUpdatingInfo = null,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [message, setMessage] = useState("");
  const [showErrModal, setShowErrModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [count, setCount] = useState("");
  const [initialFormValues, setInitialFormValues] = useState({});
  const [formCountList, setFormCountList] = useState([]);
  const [counterChanged, setCounterChanged] = useState(false);

  // useRef
  const phoneNumList = useRef([]);
  const formRef = useRef();
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatchHook = useDispatch();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // On count change
  const handleCountChange = (e) => {
    const value = e.target.value;
    const intValue = value !== "" ? parseInt(value) : 0;
    if (!isNaN(intValue) && intValue > maxAllowed) {
      setMessage(`Maximum member allowed is ${maxAllowed}`);
      setShowErrModal(true);
      return;
    }
    setCount(value);
    setCounterChanged(true);
    setFormList(
      formCountList,
      setFormCountList,
      intValue,
      formRef,
      setInitialFormValues,
      phoneNumList
    );
  };

  // Getting or Processing action error note from PM
  const getActionError = (d) => {
    if (d?.actions?.length > 0) {
      let lastAction = d["actions"][d["actions"].length - 1];
      if (
        lastAction.status === GLOBAL.TENANT_APPLICATION_STATUS.DATA_REJECTED
      ) {
        return true;
      }
    }
    return false;
  };

  // Form validations
  const formValidation = (values) => {
    return householdMemberValidation(values, formCountList);
  };

  // On updating household members
  const onSubmit = async (values) => {
    if (formCountList.length === 0 && data.length === 0) {
      onNext();
      return;
    }

    if (
      validateDataUpdated &&
      !counterChanged &&
      !isDataChanged(values, data, formCountList)
    ) {
      dispatchHook(
        showErrorAlert({
          title: "Error",
          description: "Please update the form",
        })
      );
      return;
    }

    // Creating or collecting payload data to be sent
    const payload = {
      applicationId,
      type: "household_members",
      household_members: [],
    };

    formCountList.forEach((i, indx) => {
      if (!isApproved(values[`status_${i}`])) {
        const detail = {};
        detail.first_name = values[`first_name_${i}`];
        detail.last_name = values[`last_name_${i}`];
        detail.dob = values[`dob_${i}`];
        detail.gender = values[`gender_${i}`];
        detail.relation = values[`relation_${i}`];
        detail.nationality = values[`nationality_${i}`];
        detail.email = values[`email_${i}`];
        detail.countrycode = phoneNumList.current[indx].code;
        detail.mobile = phoneNumList.current[indx].num;
        detail.id_type = values[`id_type_${i}`];
        detail.id_number = values[`id_number_${i}`];
        detail.id_expiry_date = values[`id_expiry_date_${i}`];
        detail.language = values[`language_${i}`];

        if (indx < data.length) {
          detail._id = data[indx]._id;
        }

        if (
          values[`id_image_${i}`] &&
          values[`id_image_${i}`] instanceof File
        ) {
          payload[`img_${indx}`] = values[`id_image_${i}`];
        }

        payload.household_members.push(detail);
      }
    });

    console.log("Payload", payload);
    // Backend response. Try, Catch
    try {
      const result = await update(payload);
      console.log(result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setMessage(result.payload);
          setShowErrModal(true);
          break;

        case GLOBAL.FULFILLED:
          onUpdatingInfo && onUpdatingInfo();
          fetchApplication && fetchApplication();
          setMessage(result.payload.message);
          setShowSuccessModal(true);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  // Fetching form select options on initial load
  useEffect(() => {
    fetchCountries();
    fetchLocales();
    fetchIdTypes();
    fetchGenders();
    fetchRelations();
  }, []);

  // On load of household member details previous data
  useEffect(() => {
    if (data.length > 0) {
      setCount(parseInt(data?.length));
      formPrefill(data, setInitialFormValues, setFormCountList, phoneNumList);
    }
  }, [data]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="household-members container_sm">
      <h2 className="heading text-center font_xxl mb-5">Household Members</h2>

      <Formik
        onSubmit={onSubmit}
        initialValues={initialFormValues}
        validate={formValidation}
        validateOnBlur={true}
        enableReinitialize={true}
        innerRef={formRef}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="form-wrapper">
              {/* Household Member List Counter */}
              <div className="list-counter">
                <CustomNumberInput
                  label="Number of Members"
                  handleChange={(e) => handleCountChange(e)}
                  value={count}
                  required={false}
                />
              </div>

              {/* Maximum member allowed */}
              <p className="fw_5 font_s font_yellow max-allowed">
                Maximum member allowed is {maxAllowed}
              </p>

              {/* If no household members added */}
              {formCountList.length === 0 && (
                <p className="font_m fw_5 text-center">
                  No household members added !
                </p>
              )}

              {formCountList.map((item, index) => (
                <div key={index} className="form-section-group">
                  {/* Member Count Title */}
                  <h3 className="title-bubble font_blue font_m fw_6 w_100">
                    Household Member #{index + 1}
                    {isApproved(values[`status_${item}`]) && (
                      <p className="font_m fw_5 already-approved-warning">
                        <span className="font_yellow">
                          All fields are disabled, as this information is
                          already
                        </span>{" "}
                        <span className="font_green">Approved</span>
                      </p>
                    )}
                  </h3>

                  {/* Require action */}
                  {getActionError(data[index]) && (
                    <p className="w_100 font_red font_s fw_5">
                      {
                        data[index]?.actions[data[index]?.actions?.length - 1]
                          .note
                      }
                    </p>
                  )}

                  {/* Form Fields */}
                  {/* Input for First Name */}
                  <div className="form-group">
                    <CustomTextInput
                      name={`first_name_${item}`}
                      label="First Name"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched[`first_name_${item}`]}
                      errors={errors[`first_name_${item}`]}
                      value={values[`first_name_${item}`]}
                      required={true}
                      readOnly={isApproved(values[`status_${item}`])}
                    />
                  </div>

                  {/* Input for Last Name */}
                  <div className="form-group">
                    <CustomTextInput
                      name={`last_name_${item}`}
                      label="Last Name"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched[`last_name_${item}`]}
                      errors={errors[`last_name_${item}`]}
                      value={values[`last_name_${item}`]}
                      required={true}
                      readOnly={isApproved(values[`status_${item}`])}
                    />
                  </div>

                  {/* Input for Date of Birth */}
                  <div className="form-group">
                    <CustomDateInput
                      name={`dob_${item}`}
                      label="Date of birth"
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      touched={touched[`dob_${item}`]}
                      errors={errors[`dob_${item}`]}
                      value={values[`dob_${item}`]}
                      maxDate={new Date()}
                      minDate={false}
                      required={true}
                      disabled={isApproved(values[`status_${item}`])}
                    />
                  </div>

                  {/* Select for Gender */}
                  <div className="form-group">
                    <CustomSelectInput
                      label="Gender"
                      name={`gender_${item}`}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      options={genders}
                      value={values[`gender_${item}`]}
                      errors={errors[`gender_${item}`]}
                      touched={touched[`gender_${item}`]}
                      valueExtractor={(d) => d?.name}
                      disabled={isApproved(values[`status_${item}`])}
                    />
                  </div>

                  {/* Select for Relation */}
                  <div className="form-group">
                    <CustomSelectInput
                      label="Relation"
                      name={`relation_${item}`}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      options={relations}
                      value={values[`relation_${item}`]}
                      errors={errors[`relation_${item}`]}
                      touched={touched[`relation_${item}`]}
                      valueExtractor={(d) => d?.name}
                      disabled={isApproved(values[`status_${item}`])}
                    />
                  </div>

                  {/* Select for Nationality */}
                  <div className="form-group">
                    <CustomSelectInput
                      label="Nationality"
                      name={`nationality_${item}`}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      options={countries}
                      value={values[`nationality_${item}`]}
                      errors={errors[`nationality_${item}`]}
                      touched={touched[`nationality_${item}`]}
                      valueExtractor={(d) => d?.name}
                      disabled={isApproved(values[`status_${item}`])}
                    />
                  </div>

                  {/* Input for Email */}
                  <div className="form-group form-group-full">
                    <CustomTextInput
                      name={`email_${item}`}
                      label="Email"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched[`email_${item}`]}
                      errors={errors[`email_${item}`]}
                      value={values[`email_${item}`]}
                      required={true}
                      readOnly={isApproved(values[`status_${item}`])}
                    />
                  </div>

                  {/* Input for Phone */}
                  <div className="form-group">
                    <CustomPhoneInput
                      name={`mobile_${item}`}
                      label="Phone"
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      touched={touched[`mobile_${item}`]}
                      errors={errors[`mobile_${item}`]}
                      value={values[`mobile_${item}`]}
                      required={true}
                      customOnChange={(v) => (phoneNumList.current[index] = v)}
                      readOnly={isApproved(values[`status_${item}`])}
                    />
                  </div>

                  {/* Select for ID Type */}
                  <div className="form-group">
                    <CustomSelectInput
                      label="ID Type"
                      name={`id_type_${item}`}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      options={IdTypes}
                      value={values[`id_type_${item}`]}
                      errors={errors[`id_type_${item}`]}
                      touched={touched[`id_type_${item}`]}
                      valueExtractor={(d) => d?.name}
                      disabled={isApproved(values[`status_${item}`])}
                    />
                  </div>

                  {/* Input for ID Number */}
                  <div className="form-group">
                    <CustomTextInput
                      name={`id_number_${item}`}
                      label="ID Number"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched[`id_number_${item}`]}
                      errors={errors[`id_number_${item}`]}
                      value={values[`id_number_${item}`]}
                      required={true}
                      readOnly={isApproved(values[`status_${item}`])}
                    />
                  </div>

                  {/* Input for ID Expiry Date */}
                  <div className="form-group">
                    <CustomDateInput
                      name={`id_expiry_date_${item}`}
                      label="ID Expiry Date"
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      touched={touched[`id_expiry_date_${item}`]}
                      errors={errors[`id_expiry_date_${item}`]}
                      value={values[`id_expiry_date_${item}`]}
                      required={true}
                      disabled={isApproved(values[`status_${item}`])}
                    />
                  </div>

                  {/* Input for ID Photo */}
                  <div className="form-group">
                    <CustomFileInput
                      name={`id_image_${item}`}
                      label="ID Photo"
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      formikState={true}
                      touched={touched[`id_image_${item}`]}
                      errors={errors[`id_image_${item}`]}
                      value={values[`id_image_${item}`]}
                      required={true}
                      disabled={isApproved(values[`status_${item}`])}
                    />
                  </div>

                  {/* Select for Language */}
                  <div className="form-group">
                    <CustomSelectInput
                      label="Language"
                      name={`language_${item}`}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      options={_availableLocales}
                      value={values[`language_${item}`]}
                      errors={errors[`language_${item}`]}
                      touched={touched[`language_${item}`]}
                      valueExtractor={(d) => d?.name}
                      disabled={isApproved(values[`status_${item}`])}
                    />
                  </div>
                </div>
              ))}
            </div>

            <div className="button-wrapper text-center">
              <CustomButton type="submit" size="l">
                Next
              </CustomButton>
              {onCancelStep && (
                <TextButton className="font_m fw_6" onClick={onCancelStep}>
                  Back
                </TextButton>
              )}
            </div>
          </form>
        )}
      </Formik>

      {canCancelApplication && (
        <div className="cancel-application-btn-wrapper mt-4">
          <CustomButton
            className="cancel-application-btn"
            variant="red"
            size="l"
            onClick={onCancelApplication}
          >
            Cancel Application
          </CustomButton>
        </div>

        // <div className="cancel-application-wrapper bg_grey">
        //   <p className="font_m font_yellow fw_5">
        //     I would like to cancel the onboarding application process. I am
        //     aware that after cancelling the application I won't be able to undo
        //     my action.
        //   </p>
        //   <div className="cancel-application-btn-wrapper">
        //     <CustomButton
        //       className="cancel-application-btn"
        //       variant="red"
        //       size="m"
        //       onClick={onCancelApplication}
        //     >
        //       Cancel Application
        //     </CustomButton>
        //   </div>
        // </div>
      )}

      {/* Modals */}
      {/* Success Modal */}
      <SuccessModal
        showModal={showSuccessModal}
        description={message}
        title="Success"
        onClose={() => onNext()}
      />

      {/* Error Modal */}
      <ErrorModal
        showModal={showErrModal}
        description={message}
        title="Error"
        onClose={() => setShowErrModal(false)}
      />
    </div>
  );
};
/* Component ends */

const mapStateToProps = (state) => {
  return {
    _availableLocales: state.master.locales,
    countries: state.country.countries,
    IdTypes: state.master.idTypes,
    genders: state.master.genderTypes,
    relations: state.master.relationTypes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchLocales: bindActionCreators(fetchLocales, dispatch),
    fetchCountries: bindActionCreators(fetchCountries, dispatch),
    fetchIdTypes: bindActionCreators(fetchIdTypes, dispatch),
    fetchGenders: bindActionCreators(fetchGenders, dispatch),
    fetchRelations: bindActionCreators(fetchRelations, dispatch),
    update: bindActionCreators(updateTenantApplication, dispatch),
    clearMasterData: bindActionCreators(clearMasterData, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HouseholdMembersForm);
