/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { Link } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import Searchbar from "../../ui/other/Searchbar/Searchbar";
import TabButton from "../../ui/other/TabButton/TabButton";

import ParkingRequest from "./sub-component/ParkingRequest";
import FacilitiesRequest from "./sub-component/FacilitiesRequest";
import SubscriptionRequest from "./sub-component/SubscriptionRequest";
import ExpiredSubscriptionRequest from "./sub-component/ExpiredSubscriptionRequest";
import ActiveSubscriptionRequest from "./sub-component/ActiveSubscriptionRequest";
/* Import local pages and component ends */

// Styles
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchTenantUnitsBasedPropertyId,
  getTenantsLists,
} from "../../../setup/store/slices/securitySlice";

/* Component starts */
const ManageFacilities = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [currentKey, setCurrentKey] = useState("parking");
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();

  const tenantsList = useSelector(getTenantsLists);
  console.log(tenantsList, "tenantsList*1458");
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const buttonList = [
    { key: "parking", label: "Parking" },
    { key: "facilities", label: "Facilities" },
    { key: "subscription", label: "Subscription Request" },
    { key: "expired", label: "Expired Subscription" },
    { key: "active", label: "Active Subscription" },
  ];
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const onTabSelect = (key) => {
    setCurrentKey(key);
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    dispatch(fetchTenantUnitsBasedPropertyId());
  }, [dispatch]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="internal-maintenance-wrapper">
      <div className="heading">
        {currentKey === "parking" ? (
          <Link
            to="/property/manage-facilities/add-parking"
            className="font_m fw_6 d_block link"
          >
            + Add Parking
          </Link>
        ) : currentKey === "facilities" ? (
          <Link
            to="/property/manage-facilities/add-facility"
            className="font_m fw_6 d_block link"
          >
            + Add Facility
          </Link>
        ) : (
          ""
        )}
        <h2 className="font_xxl fw_6">Manage Facilities</h2>
      </div>

      <Searchbar />

      {/* TAB BUTTONS */}
      <TabButton
        buttonList={buttonList}
        currentKey={currentKey}
        onSelect={onTabSelect}
      />

      {/* Display Table Data Based On The Current Tab */}

      {currentKey === "parking" ? (
        <ParkingRequest />
      ) : currentKey === "facilities" ? (
        <FacilitiesRequest />
      ) : currentKey === "subscription" ? (
        <SubscriptionRequest />
      ) : currentKey === "expired" ? (
        <ExpiredSubscriptionRequest />
      ) : (
        <ActiveSubscriptionRequest />
      )}
    </div>
  );
};
/* Component ends */

export default ManageFacilities;
