import moment from "moment";

// ID Verification intial form values
export const idVerificationInitVal = {};

export const isApproved = (status) => {
  return status && status === "approved" ? true : false;
};

// export const isApproved = (item) => {
//   return item?.hasOwnProperty("status") && item?.status === "approved";
// };

// ID Verification form validation
export const idVerificationValidation = (values, keyList) => {
  const error = {};

  keyList.forEach((item, index) => {
    const type = `type_${item}`;
    const number = `number_${item}`;
    const id_photo = `image_${item}`;

    if (values[type] === "") {
      error[type] = "Type is required";
    }

    if (values[number] === "") {
      error[number] = "Number is required";
    }

    if (values[id_photo] === null) {
      error[id_photo] = "Photo is required";
    }
  });

  return error;
};

// ID Verification form prefilling
export const idVerifyFormPrefill = (data, setInitialFormValues, setKeyList) => {
  const formValues = {};
  const keyList = [];

  data.forEach((item, index) => {
    formValues[`type_${index}`] = item?.type;
    formValues[`number_${index}`] = item?.number;
    formValues[`expiry_date_${index}`] = new Date(item?.expiry_date);
    formValues[`image_${index}`] = item?.image;
    formValues[`status_${index}`] = item?.status;

    keyList.push(parseInt(index));
  });

  setInitialFormValues(formValues);
  setKeyList(keyList);
};

// ID Count Set
export const countSet = (numIds, setInitialFormValues, setKeyList) => {
  const formValues = {};
  const keyList = [];
  for (let i = 0; i < parseInt(numIds); i++) {
    formValues[`type_${i}`] = "";
    formValues[`number_${i}`] = "";
    formValues[`expiry_date_${i}`] = new Date();
    formValues[`image_${i}`] = null;

    keyList.push(parseInt(i));
  }

  setInitialFormValues(formValues);
  setKeyList(keyList);
};

// On adding more forms
export const addMore = (callBackPush, selectedPhotoList) => {
  // Add new photo
  selectedPhotoList.current.push(null);

  callBackPush({
    type: "",
    number: "",
    expiry_date: new Date(),
    id_photo: "",
  });
};

// On remove last form
export const remove = (callBackPop, selectedPhotoList) => {
  selectedPhotoList.current.pop();
  callBackPop();
};

export const isDataChanged = (newData, prevData, keyList) => {
  for (let i = 0; i < keyList.length; i++) {
    const type_old = prevData[i].type;
    const number_old = prevData[i].number;
    const expiry_date_old = prevData[i].expiry_date;
    const image_old = prevData[i].image;

    if (newData[`type_${keyList[i]}`] !== type_old) {
      return true;
    }

    if (newData[`number_${keyList[i]}`] !== number_old) {
      return true;
    }

    if (!moment(newData[`expiry_date_${keyList[i]}`]).isSame(expiry_date_old)) {
      return true;
    }

    if (
      newData[`image_${keyList[i]}`] &&
      !newData[`image_${keyList[i]}`]?.url
    ) {
      return true;
    }
  }

  return false;
};
