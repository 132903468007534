/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import { BsFillPlayFill } from "react-icons/bs";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import GLOBAL from "../../setup/constants/global";
/* Import local pages and component ends */

import "./style.scss";
import FileViewerModal from "../../components/ui/modal/FileViewerModal/FileViewerModal";

/* Component starts */
const TenantLostAndFoundDetail = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */
  const navigate = useNavigate();

  const { state } = useLocation();
  console.log(state);
  /* Component states and useRef declaration starts */
  const [showFileViewer, setShowFileViewer] = useState(false);
  const [file, setFile] = useState({});
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="lost-wrapper">
      <h3>Lost Details</h3>

      <div className="report-wrapper container_sm">
        <div className="detail-box">
          <div className="unit-detail-box">
            <p className="num fw_6 font_l font_grey">
              {" "}
              Ticket No :{" "}
              <span className="fw_6 font_l font_blue">
                {state?.lostfound_id}{" "}
              </span>
            </p>
            <p className="num fw_6 font_l font_grey">
              {" "}
              Item Name :{" "}
              <span className="fw_6 font_l">{state?.item_name} </span>
            </p>

            <p className="desc font_m fw_4 font_grey">
              {state?.item_description}
            </p>

            <hr />

            <div className="d-flex justify-content-between">
              <p className="fw_4 font_grey">
                Report ID <br />
                <span className="fw_4 font_m">{state?.lostfound_id}</span>
              </p>

              <p className="fw_4 font_grey">
                Reported Date <br />
                <span className="fw_4 font_m">
                  {moment(state?.date_time).format(DATE_FORMAT)}
                </span>
              </p>

              <p className="fw_4 font_grey">
                Reported Time <br />
                <span className="fw_4 font_m">
                  {moment(state?.date_time).format(TIME_FORMAT)}
                </span>
              </p>
            </div>

            <hr />

            {state?.files?.length > 0 && (
              <div className="image-wrapper">
                {state?.files?.map((image, index) => (
                  <div className="proof-img" key={index}>
                    {image.type !== "mp4" ? (
                      <img
                        src={image.url}
                        alt="ID-Proof"
                        onClick={() => {
                          setFile(image);
                          setShowFileViewer(true);
                        }}
                      />
                    ) : (
                      <div className="video-wrapper">
                        <BsFillPlayFill
                          size={35}
                          color="red"
                          className="icon"
                        />
                        <video
                          src={image.url}
                          // autoPlay
                          // controls
                          onClick={() => {
                            setFile(image);
                            setShowFileViewer(true);
                          }}
                        />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        {/* BUTTON */}
        <button className="back-btn" onClick={() => navigate(-1)}>
          Back
        </button>
      </div>

      {/* File viewer modal */}
      <FileViewerModal
        show={showFileViewer}
        type={file.type}
        file={file.url}
        onClose={() => {
          setShowFileViewer(false);
          setFile({});
        }}
      />
    </div>
  );
};
/* Component ends */

export default TenantLostAndFoundDetail;
