/**
 *
 */

import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";

/* Import configuration starts */
import { FieldArray, Formik } from "formik";
import { fieldPropExtractor, initialValues, validations } from "./helper";

/* Import configuration ends */

/* Import redux slices component starts */

/* Import redux slices component ends */

/* Import react bootstrap component starts */

/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */

import TextButton from "../../../ui/button/TextButton/TextButton";
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
import CustomSelectInput from "../../../ui/input/CustomSelectInput/CustomSelectInput";
import CustomTextArea from "../../../ui/input/CustomTextArea/CustomTextArea";
import CustomTextInput from "../../../ui/input/CustomTextInput/CustomTextInput";
import CustomFileInput from "../../../ui/input/CustomFileInput/CustomFileInput";
/* Import local pages and component ends */

// Styles
import "./style.scss";
import { bindActionCreators } from "@reduxjs/toolkit";
import { fetchIdTypes } from "../../../../setup/store/slices/masterSlice";

/* Component starts */
const AddBluePrint = (props) => {
  /* Props destructuring starts */
  const { onCancel, IdTypes } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [initialFormValues, setInitialFormValues] = useState(initialValues);

  const [selectedId, setSelectedId] = useState();

  //   const [showErrorModal, setShowErrorModal] = useState(false);
  //   const [showSuccessModal, setShowSuccessModal] = useState(false);
  //   const [modalDesc, setModalDesc] = useState("");

  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */

  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  // On adding photo field
  const onAddPhoto = (callBackPush) => {
    callBackPush(null);
  };

  // On removing last photo field
  const onRemovePhoto = (callBackPop) => {
    callBackPop();
  };

  const formValidation = (values) => {
    return validations(values);
  };

  const onSubmit = (values) => {
    // let violatorID = tenantListData?.filter(
    //   (tenant) => tenant.name === values.tenantName
    // );

    // const payload = {
    //   // property_id: property._id,
    //   subject: values.subject,
    //   selectedMember: member,
    //   description: values.description,
    //   category: selectedCategory?._id || "",
    //   location: selectedLocation?._id || "",
    //   subCategory: selectedSubCategory?._id || "",
    //   document: [],
    // };

    // values.photos.forEach((i) => {
    //   if (i) {
    //     payload["document"]?.push(i);
    //   }
    // });

    // console.log(payload, "PAY");

    // // Backend response. Try, Catch
    // try {
    //   const result = await dispatch(addSecurityIncident(payload));

    //   // Handling success response
    //   switch (result.meta.requestStatus) {
    //     case GLOBAL.REJECTED:
    //       setModalDesc(result.payload);
    //       setShowErrorModal(true);
    //       break;

    //     case GLOBAL.FULFILLED:
    //       setModalDesc("Security Incident Created Successfully");
    //       setShowSuccessModal(true);
    //       break;
    //   }
    // } catch (error) {
    //   // Handling error response
    //   console.error(error.message);
    // }

    console.log("VAL", values);
  };

  /* Component function definition ends */

  /* Component useEffect starts */

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="add-blueprint-wrapper">
      <h2 className="heading text-center font_xxl mb-5">Add New Document</h2>

      <div className="add-new-form">
        <Formik
          onSubmit={onSubmit}
          initialValues={initialFormValues}
          validate={formValidation}
          validateOnBlur={true}
          enableReinitialize={true}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="form-wrapper">
                {/* Creating new Blue Print */}
                <>
                  {/* Input for Document Title */}
                  <div className="form-group">
                    <CustomTextInput
                      name="doc_title"
                      label="Enter Document Title"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched.doc_title}
                      errors={errors.doc_title}
                      value={values.doc_title}
                    />
                  </div>

                  {/* Select Document Type */}
                  <div className="form-group">
                    <CustomSelectInput
                      name="doc_type"
                      label="Type Of Document"
                      handleBlur={handleBlur}
                      onChange={handleChange}
                      valueExtractor={(item) => item?.name}
                      setValue={setSelectedId}
                      options={IdTypes}
                      value={selectedId}
                      formikState={false}
                      emptyOption={false}
                    />
                  </div>

                  {/* Text Area for Description */}
                  <div className="form-group form-group-full">
                    <CustomTextArea
                      name="description"
                      label="Enter Description"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      value={values.description}
                      errors={errors.description}
                      touched={touched.description}
                      required={true}
                    />
                  </div>

                  {/* Input for ID Photo */}
                  <FieldArray
                    name="photos"
                    render={({ push, pop }) => (
                      <div className="form-group">
                        {/* Upload Image List */}
                        {values?.photos?.map((item, index) => (
                          <div
                            key={index}
                            className={index >= 1 ? "add-image" : ""}
                          >
                            <CustomFileInput
                              name={`photos[${index}]`}
                              label="Upload Image/Video"
                              setFieldValue={setFieldValue}
                              setFieldTouched={setFieldTouched}
                              value={values.photos[index]}
                              formikState={true}
                              touched={fieldPropExtractor(touched, index)}
                              validaterror={fieldPropExtractor(errors, index)}
                              required={false}
                            />
                          </div>
                        ))}

                        <div className="action-button text-center">
                          {values.photos[values.photos.length - 1] && (
                            <TextButton
                              variant="outline"
                              size="m"
                              className="txtBtn"
                              onClick={() => onAddPhoto(push)}
                            >
                              + Add More Images/Videos
                            </TextButton>
                          )}

                          {values.photos.length > 1 && (
                            <CustomButton
                              className="remove"
                              // variant="outline-none"
                              size="m"
                              onClick={() => onRemovePhoto(pop)}
                            >
                              Remove
                            </CustomButton>
                          )}
                        </div>
                      </div>
                    )}
                  />
                </>
              </div>

              <div className="button-wrapper text-center">
                <>
                  <CustomButton type="submit" size="l">
                    Create
                  </CustomButton>
                  <TextButton
                    type="button"
                    onClick={onCancel}
                    className="font_m fw_6"
                  >
                    Cancel
                  </TextButton>
                </>
              </div>
            </form>
          )}
        </Formik>
      </div>

      {/* Success Modal */}
      {/* <SuccessModal
        showModal={showSuccessModal}
        description={modalDesc}
        title="Successfully Created"
        onClose={() => {
          onSuccess();
          setShowSuccessModal(false);
        }}
      /> */}

      {/* Error Modal */}
      {/* <ErrorModal
        showModal={showErrorModal}
        description={modalDesc}
        title="Error"
        onClose={() => setShowErrorModal(false)}
      /> */}
    </div>
  );
};
/* Component ends */

// export default AddBluePrint;

const mapStateToProps = (state) => {
  return {
    IdTypes: state.master.idTypes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchIdTypes: bindActionCreators(fetchIdTypes, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddBluePrint);
