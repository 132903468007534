/**
 *
 */

import React, { useState, useEffect, useRef } from "react";

/* Import configuration starts */
import moment from "moment";
import { useDispatch } from "react-redux";
import GLOBAL from "../../../../../setup/constants/global";
import { logTitle, canSkipDocSubmission } from "../helper";
import { useParams } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  pmRespondClearence,
  skipMoveoutStep,
} from "../../../../../setup/store/slices/moveOutSlice";
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../../../setup/store/slices/globalAlertSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import {
  DocFilledWhite,
  DocumentGreen,
} from "../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import TextButton from "../../../../ui/button/TextButton/TextButton";
import IconButton from "../../../../ui/button/IconButton/IconButton";
import FileViewerModal from "../../../../ui/modal/FileViewerModal/FileViewerModal";
import NoteModal from "../../../Maintenance/NoteModal/NoteModal";
import CustomButton from "../../../../ui/button/CustomButton/CustomButton";
/* Import local pages and component ends */

/* Component starts */
const TaskLog = (props) => {
  /* Props destructuring starts */
  const { item, user, takeAction, moveoutId, moveOut, getMoveoutDetail } =
    props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [showFile, setShowFile] = useState(false);
  const [file, setFile] = useState({});
  const [rejectionItem, setRejectionItem] = useState(null);
  const [showNote, setShowNote] = useState(false);
  const { propertyId } = useParams();
  const [showSkipNote, setShowSkipNote] = useState(false);
  // useRef
  // const reviewItem = useRef(null);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const docs = item?.data.docs;
  const name = item?.requester
    ? `${item?.requester?.first_name} ${item?.requester?.last_name}`
    : `${item?.tenant?.first_name} ${item?.tenant?.last_name}`;
  const role = item?.requester_role?.name;
  const createdAt = item?.createdAt;
  const moveOutStatus = moveOut?.status;
  const moveOutSubStatus = moveOut?.sub_status;

  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // On Approving or Rejecting document
  const reviewDoc = async (doc, status, note) => {
    // Creating or collecting payload data to be sent
    const payload = {
      move_out_id: moveoutId,
      item_id: doc._id,
      log_id: item._id,
      property_id: propertyId,
      status,
      // status: reviewItem.accepted ? "Approved" : "Rejected",
    };
    if (note) {
      payload["note"] = note;
    }
    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      const result = await dispatch(pmRespondClearence(payload));
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          getMoveoutDetail();
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // On skipping steps
  const skipWithNote = async (comment) => {
    // Creating or collecting payload data to be sent
    const payload = {
      move_out_id: moveoutId,
      step: "utility_clearance",
      comment,
    };
    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      const result = await dispatch(skipMoveoutStep(payload));
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description:
                typeof result?.payload === "string" ? result?.payload : "",
            })
          );
          break;

        case GLOBAL.FULFILLED:
          getMoveoutDetail();
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description:
                typeof result?.payload?.message === "string"
                  ? result?.payload?.message
                  : "",
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="time-line task-log">
      {/* Dot */}
      <div className="dot-wrapper">
        <i className="dot"></i>
        <div className="vl"></div>
      </div>

      {/* Title */}
      <div className="detail-wrapper">
        <h3 className="title font_m fw_6">{logTitle(item)}</h3>

        {/* Technician Name and Date */}
        <div className="black-container-sm fw_5 font_s bg_black flex-between">
          <p className="left">
            <span className="font_grey">{role} </span>
            <span className="font_white"> {name}</span>
          </p>
          <p className="right font_grey">
            {moment(createdAt).format(DATE_TIME_FORMAT)}
          </p>
        </div>

        {/* Task List */}
        <div className="spare-part-quote black-container-lg fw_5 font_s">
          <p className="sub-title font_m fw_6">Documents</p>

          <div className="doc-list">
            <div className="table-header">
              <div className="t-row">
                <p className="t-col t-col-1 font_grey">Document</p>
                <p className="t-col t-col-2 font_grey">File</p>
                {takeAction && (
                  <p className="t-col t-col-3 font_grey">Action</p>
                )}
              </div>
            </div>
            {docs?.map((item, index) => (
              <div className="table-body">
                <div className="t-row">
                  <p className="t-col t-col-1">{item.description}</p>
                  <div className="t-col t-col-2">
                    <IconButton
                      onClick={() => {
                        setShowFile(true);
                        setFile(item.document);
                      }}
                      className="icon-sm"
                    >
                      <img className="w_100" src={DocumentGreen} alt="File" />
                    </IconButton>
                  </div>
                  {/* Action */}

                  <div className="t-col t-col-3">
                    {takeAction && (
                      <>
                        {item.status === "Approval Pending" && (
                          <>
                            <TextButton
                              onClick={() => {
                                reviewDoc(item, "Approved");
                              }}
                              className="action-txt-btn font_s fw_6 font_blue"
                            >
                              Accept
                            </TextButton>
                            <TextButton
                              onClick={() => {
                                setRejectionItem(item);
                                setShowNote(true);
                              }}
                              className="action-txt-btn font_s fw_6 font_red"
                            >
                              Reject
                            </TextButton>
                          </>
                        )}
                      </>
                    )}
                    {item.status === "Rejected" && (
                      <p className="font_s fw_5 font_red">Rejected</p>
                    )}

                    {item.status === "Approved" && (
                      <p className="font_s fw_5 font_green">Approved</p>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Log Footer */}
        {takeAction && (
          <div className="log-footer">
            <div className="button-wrapper">
              {canSkipDocSubmission(item, user) && (
                <CustomButton
                  onClick={() => {
                    setShowSkipNote(true);
                  }}
                  size="m"
                  variant="yellow"
                >
                  Skip Utility Clearence
                </CustomButton>
              )}
            </div>
          </div>
        )}
      </div>

      {/* Modals */}
      {/* File Viewer Modal */}
      <FileViewerModal
        show={showFile}
        file={file.url}
        type={file.type}
        onClose={() => setShowFile(false)}
      />

      {/* Rejection Note */}
      <NoteModal
        showModal={showNote}
        title="Reject Document"
        btnText="Reject"
        showError={true}
        actionWithNote={(note) => {
          reviewDoc(rejectionItem, "Rejected", note);
          setShowNote(false);
        }}
        onCancel={() => {
          setRejectionItem(null);
          setShowNote(false);
        }}
      />

      {/* Skip Note */}
      <NoteModal
        showModal={showSkipNote}
        title="Skip with comment"
        btnText="Skip"
        onCancel={() => setShowSkipNote(false)}
        actionWithNote={skipWithNote}
      />
    </div>
  );
};
/* Component ends */

export default TaskLog;
