/**
 *
 */

import React, { useState } from "react";
import FileViewerModal from "../../../components/ui/modal/FileViewerModal/FileViewerModal";

/* Import configuration starts */

/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */

/* Import local pages and component ends */

/* Component starts */
const Report = (props) => {
  /* Props destructuring starts */
  const { task } = props;
  console.log(task, "TASK");
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [showFileViewer, setShowFileViewer] = useState(false);
  const [file, setFile] = useState({});
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const docs = [
    {
      id: 1,
      type: "jpg",
      url: "https://learn.g2.com/hubfs/internal%20communication%20plan.jpg",
    },
    {
      id: 2,
      type: "jpg",
      url: "https://resources.sansan.com/hubfs/YumYumVideos-Sansan-guest%20post.jpg",
    },
    {
      id: 3,
      type: "jpg",
      url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTHaD7al1JYTdd8aNmmE6u8oTFaoZ2uo5106QDE5WhzoxopkXOsYajRVKEabmQZKDaAWP4&usqp=CAU",
    },
    {
      id: 4,
      type: "jpg",
      url: "https://blog.vantagecircle.com/content/images/2021/03/internal-communication-strategy--1-.png",
    },
  ];
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="communication-detail-box">
      <div className="task-wrapper">
        <p className="font_m fw_6">{task?.item?.subject}</p>
        <p className="font_s fw_5">
          Status :{" "}
          <span className="font_s fw_4 font_yellow">{task?.item?.status}</span>
        </p>
      </div>

      <p className="font_xs fw_4 font_grey">{task?.item?.description}</p>

      <div className="task-wrapper">
        <p className="font_xs fw_4 font_grey">
          Request ID : <span className="font_xs fw_4">{task?.item?.id}</span>
        </p>
        <p className="font_xs fw_4 font_grey">
          Date Of Request :{" "}
          <span className="font_xs fw_4">{task?.item?.start_date}</span>
        </p>
      </div>

      <p className="font_xs fw_4 font_grey">
        Initiated By :{" "}
        <span className="font_xs fw_4">{task?.item?.assigned_to}</span>
      </p>

      <hr />

      <div className="uploaded-doc">
        <div>
          <p className="font_m fw_6">Photo/Video Uploaded</p>
        </div>
        <div className="img-wrapper">
          {docs.map((doc) => (
            <img
              key={doc.id}
              src={doc.url}
              alt="doc"
              onClick={() => {
                setFile(doc);
                setShowFileViewer(true);
              }}
            />
          ))}
        </div>
      </div>

      <hr />

      <div>Add Notes</div>

      {/* File viewer modal */}
      <FileViewerModal
        show={showFileViewer}
        type={file.type}
        file={file.url}
        onClose={() => {
          setShowFileViewer(false);
          setFile({});
        }}
      />
    </div>
  );
};
/* Component Ends */
export default Report;
