/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import Report from "./sub-component/Report";
import Header from "./sub-component/Header";
import TimeLineLists from "./sub-component/TimeLineLists";
import BreadCrumb from "../../../ui/other/BreadCrumb/BreadCrumb";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const MoveOutPMReview = (props) => {
  /* Props destructuring starts */
  const { moveOutDetail, getMoveoutDetail } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const logs = moveOutDetail?.logs;
  const moveoutId = moveOutDetail?.ticket?._id;
  const moveOut = moveOutDetail?.ticket;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      <BreadCrumb type="moveout_tenant_detail" />
      <div className="move-out-pm-review-wrapper">
        <div className="move-out-header">
          <Header />
        </div>

        <div className="move-out-report-wrapper container_sm">
          <div className="moveOut-detail-box">
            <Report moveOut={moveOut} />
          </div>

          <TimeLineLists
            moveOut={moveOut}
            moveoutId={moveoutId}
            logs={logs}
            getMoveoutDetail={getMoveoutDetail}
          />
        </div>
      </div>
    </>
  );
};
/* Component ends */

export default MoveOutPMReview;
