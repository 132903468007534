/**
 * This component is for custom text input field
 */

import React from "react";

import "../style1.scss";
import { getUserTheme } from "../../../../../setup/store/slices/authSlice";
import { useSelector } from "react-redux";

/* Component starts */
const V1 = (props) => {
  /* Props destructuring starts */
  const {
    type = "text",
    label,
    handleChange,
    value,
    name,
    errors,
    handleBlur,
    touched,
    required = true,
    disabled = false,
    readOnly = false,
    glowOnFocus = true,
    borderBlue = false,
    inputClass = "",
    customOnchange = null,
  } = props;
  /* Props destructuring ends */

  const userTheme = useSelector(getUserTheme);

  const onChange = (e) => {
    handleChange(e);
    customOnchange && customOnchange(e);
  };

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="custom-form-control">
      <div className="form-input-wrapper">
        <label className="font_s font_grey">
          {label}
          {required && <span className="font_m font_red"> *</span>}
        </label>
        <input
          type={type}
          onBlur={handleBlur}
          onChange={onChange}
          value={value}
          name={name}
          // /|| userTheme === "automatic"
          className={`${inputClass} font_xs ${
            userTheme === "light" || userTheme === "automatic"
              ? "bg_lightThemeInput font_lightTheme"
              : "bg_black font_white"
          } ${glowOnFocus ? "glowOnFocus" : ""} ${
            borderBlue ? "border-blue" : ""
          }`}
          disabled={disabled}
          readOnly={readOnly}
          autoComplete="off"
        />
      </div>

      {touched && errors && <p className="error font_xxs font_red">{errors}</p>}
    </div>
  );
};
/* Component ends */

export default V1;
