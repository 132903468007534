/**
 *
 */

import React, { useState } from "react";

/* Import configuration starts */
import { useSelector } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import { getUserTheme } from "../../../../setup/store/slices/authSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Modal } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import MUI component starts */
/* Import MUI component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
import CustomDateTimeInput from "../../../ui/input/CustomDateTimeInput/CustomDateTimeInput";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const RescheduleModal = (props) => {
  /* Props destructuring starts */
  const { showModal, size = "lg", onCancel, action } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [dateNTime, setDateNTime] = useState(new Date());
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const userTheme = useSelector(getUserTheme);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // On Action
  const onAction = () => {
    action(dateNTime.toString());
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <Modal
      size={size}
      centered
      show={showModal}
      className="reschedule-modal-wrapper"
    >
      <Modal.Body
        className={`${userTheme === "dark" ? "bg_darkTheme" : "bg_lightTheme"}`}
      >
        <div className="modal-header-custom">
          <h2 className="text-center font_blue font_l">Reschedule</h2>
        </div>
        <div className="modal-body-custom">
          <div
            className={`form-group ${
              (userTheme === "light" || userTheme === "automatic") &&
              "otherTheme"
            } `}
          >
            <CustomDateTimeInput
              label="Date and Time"
              className="note"
              value={dateNTime}
              setValue={setDateNTime}
            />
          </div>
        </div>
        <div className="modal-footer-custom">
          <CustomButton size="m" variant="red" onClick={onCancel}>
            Close
          </CustomButton>
          <CustomButton size="m" onClick={onAction}>
            Reschedule
          </CustomButton>
        </div>
      </Modal.Body>
    </Modal>
  );
};
/* Component ends */

export default RescheduleModal;
