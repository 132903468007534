/**
 * @auther Abdul Ahad <abdulahad.ss@smartdatainc.net>
 *
 * This slice will handle all cards and payments
 */

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_URL } from "../../config/devKeys";
import { setLoading } from "./unpersistedSlice";
import axios from "../axios";

const initialState = {
  cards: [],
};

export const fetchCards = createAsyncThunk(
  "card/list",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await axios.get(API_URL + "/cards/list", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        },
      });
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response.data.error.message);
    }
  }
);

export const deleteCard = createAsyncThunk(
  "card/delete",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.delete(
        API_URL + "/cards/delete?id=" + payload.id,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        dispatch(fetchCards());
        dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response.data.error.message);
    }
  }
);

export const deleteTenantCard = createAsyncThunk(
  "card/tenant/delete",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.delete(API_URL + "/tenant/cards", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        },
        data: payload,
      });
      if (data.success) {
        dispatch(fetchTenantCards({ property_id: payload.property_id }));
        dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response.data.error.message);
    }
  }
);

export const fetchClientSecret = createAsyncThunk(
  "card/generate_client_secret",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await axios.post(
        API_URL + "/cards/add",
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error?.response.data.error.message);
    }
  }
);

export const fetchTenantSecret = createAsyncThunk(
  "card/generate_tenant_secret",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await axios.post(
        API_URL + "/tenant/cards",
        { property_id: payload.property_id },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error?.response.data.error.message);
    }
  }
);

export const generateStripeToken = createAsyncThunk(
  "card/stripe_token",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const stripeKey = payload.stripePublicKey;
      delete payload["stripePublicKey"];
      const payloadData = Object.keys(payload)
        .map((key) => `${key}=${encodeURIComponent(payload[key])}`)
        .join("&");
      const { data } = await axios.post(
        "https://api.stripe.com/v1/tokens",
        payloadData,
        {
          headers: {
            Authorization: `Bearer ${stripeKey}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      if (data.id) {
        return data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error?.response.data.error.message);
    }
  }
);

export const fetchTenantCards = createAsyncThunk(
  "tenant/card/list",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await axios.get(
        API_URL + "/tenant/cards?property_id=" + payload.property_id,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response.data.error.message);
    }
  }
);

export const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    clearCards: (state, action) => {
      state.cards = [];
    },
  },
  extraReducers: {
    [fetchCards.rejected]: (state, action) => {
      state.cards = [];
    },
    [fetchCards.fulfilled]: (state, action) => {
      state.cards = action.payload;
    },
    [fetchTenantCards.fulfilled]: (state, action) => {
      state.cards = action.payload;
    },
  },
});

export const { clearCards } = paymentSlice.actions;

export const getCards = (state) => state.payment.cards;

export default paymentSlice.reducer;
