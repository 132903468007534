/**
 *
 */

import React, { useState } from "react";

/* Import configuration starts */
import moment from "moment";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Table } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { EyeFilledBlue } from "../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import Pagination from "../../../components/ui/other/Pagination/Pagination";
import { calculateDaysBetweenDates } from "../../../setup/utils/global-helper";
import IconButton from "../../../components/ui/button/IconButton/IconButton";
import TenantDetailModal from "./TenantDetailModal/TenantDetailModal";
/* Import local pages and component ends */

/* Component starts */
const UnitHistory = (props) => {
  /* Props destructuring starts */
  const { unitHistory, totalCount, currentPage, setCurrentPage } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */

  const [showTenantDetailModal, setShowTenantDetailModal] = useState(false);
  const [detail, setDetail] = useState({});
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */

  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const handleView = (item) => {
    setDetail(item);
    setShowTenantDetailModal(!showTenantDetailModal);
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="unit-history-wrapper">
      {/* <h3 className="font_xl fw_6 text-center heading">Unit History</h3> */}

      <div>
        <div className="custom-table-wrapper unit-history-table">
          {unitHistory?.length > 0 && (
            <Table className="custom-table font_s" borderless responsive>
              {/* Table Heading */}
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Tenant Name</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>No. Of Days</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              {/* Table Body */}
              <tbody>
                {unitHistory?.map((item, index) => (
                  <tr key={item.id}>
                    {/* ID */}
                    <td>{index + 1}</td>
                    {/* Tenant Name */}
                    <td>{item?.tenant ? item?.tenant : "-"}</td>
                    {/* Start Date */}
                    <td>{moment(item?.start_date).format(DATE_FORMAT)}</td>
                    {/* End Date */}
                    <td>
                      {item?.end_date !== undefined
                        ? moment(item?.end_date).format(DATE_FORMAT)
                        : "-"}
                    </td>

                    {/* Days Difference */}
                    <td>
                      {item?.end_date !== undefined
                        ? `${calculateDaysBetweenDates(
                            item?.start_date,
                            item?.end_date
                          )} Days`
                        : `${calculateDaysBetweenDates(
                            item?.start_date,
                            new Date()
                          )} Days`}
                    </td>
                    {/* Status */}
                    <td
                      className={
                        item?.status === "occupied"
                          ? "font_blue"
                          : item?.status === "reserved"
                          ? "font_yellow"
                          : "font_grey"
                      }
                    >
                      {item?.status?.charAt(0).toUpperCase() +
                        item?.status?.slice(1)}
                    </td>

                    {/* Actions */}

                    <td className="text-start txt-nowrap">
                      {item?.status !== "vacant" && (
                        <IconButton
                          onClick={() => handleView(item)}
                          className="action-button"
                        >
                          <img
                            src={EyeFilledBlue}
                            className="h_100"
                            alt="View Details"
                          />
                        </IconButton>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}

          {unitHistory?.length === 0 && (
            <div className=" text-center">
              <h3 className=" font_m font_yellow fw_6">No Unit Histry</h3>
            </div>
          )}
        </div>

        {unitHistory?.length > 0 && (
          <Pagination
            itemsPerPage="10"
            totalItems={totalCount}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        )}
      </div>

      {/* Rent detail modal */}
      <TenantDetailModal
        showModal={showTenantDetailModal}
        detail={detail}
        onClose={() => {
          setShowTenantDetailModal(false);
        }}
      />
    </div>
  );
};
/* Component ends */

export default UnitHistory;
