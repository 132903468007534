/**
 *
 */

import React, { useEffect, useState } from "react";

/* Import configuration starts */
import { useSelector } from "react-redux";
import moment from "moment";
/* Import configuration ends */

/* Import redux slices component starts */
import { getUserTheme } from "../../../../setup/store/slices/authSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Modal } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import MUI component starts */
/* Import MUI component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
import DatePickerModal from "./sub-components/DatePickerModal";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const PopUpDatePicker = (props) => {
  /* Props destructuring starts */
  const {
    title,
    onClose = null,
    label = "Select Date",
    date = new Date(),
    setDate = null,
    handleChange = null,

    // Date Input Props
    minDate = null,
    maxDate = null,
    format = "dd/MM/y",
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [dateInnerState, setDateInnerState] = useState(date);
  const [showDatePickerModal, setShowDatePickerModal] = useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const userTheme = useSelector(getUserTheme);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    if(setDate) {
      setDateInnerState(date)
    }
  }, [date])
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="pop-up-date-picker-wrapper">
      <label className="font_s font_grey">{label}</label>
      <input
        type="text"
        readOnly
        value={moment(dateInnerState).format(DATE_FORMAT)}
        className={`font_xs ${
          userTheme === "light" || userTheme === "automatic"
            ? "bg_lightThemeInput font_lightTheme"
            : "bg_black font_white"
        }`}
      />
      <div
        className="backdrop"
        onClick={() => setShowDatePickerModal(true)}
      ></div>
      <DatePickerModal
        title={title}
        showModal={showDatePickerModal}
        date={dateInnerState}
        setDate={setDate || setDateInnerState}
        minDate={minDate}
        maxDate={maxDate}
        handleChange={handleChange}
        onCancel={onClose || (() => setShowDatePickerModal(false))}
      />
    </div>
  );
};
/* Component ends */

export default PopUpDatePicker;
