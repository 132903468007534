/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { initialSteps, cancelApplicationAllowed } from "./helper";
import GLOBAL from "../../../../setup/constants/global";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  fetchTenantUnits,
  tenantCancelApplication,
  tenantCancelApplicationAnytime,
  rejectApplicationPostInitialAgreement,
} from "../../../../setup/store/slices/tenantSlice";
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../../setup/store/slices/globalAlertSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { EyeFilledBlue } from "../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import ProgressSteps from "../../../ui/other/ProgressSteps/ProgressSteps";
import BasicDetailForm from "../BasicDetailForm/BasicDetailForm";
import CommercialDetailForm from "../CommercialDetailForm/CommercialDetailForm";
import IDVerificationForm from "../IDVerificationForm/IDVerificationForm";
import HouseholdMembersForm from "../HouseholdMembersForm/HouseholdMembersForm";
import DisabilityForm from "../DisabilityForm/DisabilityForm";
import VehiclesForm from "../VehicleForm/VehiclesForm";
import PetsForm from "../PetsForm/PetsForm";
import RentalHistoryForm from "../RentalHistoryForm/RentalHistoryForm";
import ReviewTenantApplicaion from "../ReviewTenantApplicaion/ReviewTenantApplicaion";
import ApplicationFeePayment from "../ApplicationFeePayment/ApplicationFeePayment";
import InitialAgreementForm from "../InitialAgreementForm/InitialAgreementForm";
import UtilityRegistrationForm from "../UtilityRegistrationForm/UtilityRegistrationForm";
import ContractPayment from "../ContractPayment/ContractPayment";
import UnitKeys from "../UnitKeys/UnitKeys";
import ApplicationDataRejectionModal from "../ApplicationDataRejectionModal/ApplicationDataRejectionModal";
import ConfirmationModal from "../../../ui/modal/ConfirmationModal/ConfirmationModal";
/* Import local pages and component ends */

import "./style.scss";
import CustomButton from "../../../ui/button/CustomButton/CustomButton";

/* Component starts */
const TenantOnboarding = (props) => {
  /* Props destructuring starts */
  const {
    sequence,
    resubmitApplication,
    application,
    application_id,
    fetchApplication,
    propertySetting,
    redirect,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  // const [steps, setSteps] = useState(initialSteps);

  // To Update the Steps Based on application Sequence
  const [steps1, setSteps1] = useState([]);

  const [currentStep, setCurrentStep] = useState("basic_details");
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const [showRejectionReasonModal, setShowRejectionReasonModal] =
    useState(false);
  const [initialAgreementModal, setInitialAgreementModal] = useState(false);
  const [sequences, setSequences] = useState([]);
  const [sequenceNumber, setSequenceNumber] = useState(null);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const currentUserRole = localStorage.getItem("user_role");
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // On successful payment
  const onPaymentSuccess = async () => {
    fetchApplication();
    navigate("/tenant/home");
  };

  // On proceeding to next step of the add tenant wizard
  const onNext = () => {
    let currStepIdx = sequence.indexOf(currentStep);
    currStepIdx += 1;

    if (sequence?.length === 1) {
      navigate(-1);
    }

    try {
      setCurrentStep(sequence[currStepIdx]);
    } catch (e) {
      if (
        application.application.status === "reservor-data-rejected" ||
        application.contract.status === "reservor-data-rejected"
      ) {
        resubmitApplication();
      } else {
        navigate(-1);
      }
    }
  };

  const onSignatureSuccess = async () => {
    await dispatch(fetchTenantUnits());
    navigate(-1);
  };

  // On cancelling application by tenant
  const cancelApplication = async (rejection_reason) => {
    // Creating or collecting payload data to be sent
    const payload = {
      application_id,
      status: GLOBAL.TENANT_APPLICATION_STATUS.DATA_REJECTED,
      rejection_reason,
      rejected_by: "Tenant",
    };
    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      const result = await dispatch(tenantCancelApplication(payload));
      setShowCancelConfirmation(false);
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          navigate("/tenant/home");
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  const onBack = () => {
    let currStepIdx = sequence.indexOf(currentStep);
    currStepIdx -= 1;

    if (currStepIdx >= 0 && !redirect) {
      setCurrentStep(sequence[currStepIdx]);
    } else {
      navigate(-1);
    }
  };

  const cancelApplicationPostAcceptanceWithNote = async (
    rejection_reason = ""
  ) => {
    // Creating or collecting payload data to be sent
    let payload, result;

    // Backend response. Try, Catch
    try {
      switch (currentStep) {
        /* Case Block ***************************************************************** */
        case "basic_details":
        case "commercial_details":
        case "ids":
        case "household_members":
        case "disability":
        case "vehicles":
        case "pets":
        case "rental_history":
        case "review":
        case "review_again":
        case "application_fee":
          payload = {
            application_id,
            step: "cancel_before_review",
            rejection_reason,
            rejected_by: currentUserRole,
          };
          result = await dispatch(tenantCancelApplicationAnytime(payload));
          break;

        /* Case Block ***************************************************************** */
        case "contract_payment":
        case "utilities":
          payload = { application_id, rejection_reason: rejection_reason };
          result = await dispatch(
            rejectApplicationPostInitialAgreement(payload)
          );
          break;
      }

      console.log("Response", result);
      setShowRejectionReasonModal(false);
      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description:
                typeof result?.payload === "string" ? result?.payload : "",
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: "Application cancelled successfully",
            })
          );
          navigate("/tenant/home");
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  const confirmCancelApplicationPostAcceptance = () => {
    setShowCancelConfirmation(false);
    switch (currentStep) {
      case "basic_details":
      case "commercial_details":
      case "ids":
      case "household_members":
      case "disability":
      case "vehicles":
      case "pets":
      case "rental_history":
      case "review":
      case "review_again":
      case "application_fee":
        setShowRejectionReasonModal(true);
        break;

      case "contract_payment":
      case "utilities":
        cancelApplicationPostAcceptanceWithNote();
        break;
    }
  };

  const cancelApplicationPostAcceptance = () => {
    setShowCancelConfirmation(true);
  };

  const initializeSequenceNumber = (data) => {
    let s = [];
    let step = [];
    const isResidential = data?.is_unit_residential;
    const isCommercial = !data?.is_unit_residential;

    if (data?.basic_details) {
      s.push("basic_details");

      // To Update the Step Based on application Sequence
      step.push({ title: "Basic Details", key: "basic_details" });
    }

    if (isCommercial) {
      s.push("commercial_details");
      step.push({ title: "Commercial Details", key: "commercial_details" });
    }
    if (data?.ids) {
      s.push("ids");
      step.push({ title: "ID Verification", key: "ids" });
    }
    if (isResidential && data?.household_members) {
      s.push("household_members");
      step.push({ title: "Household Members", key: "household_members" });
    }
    if (data?.disability) {
      s.push("disability");
      step.push({ title: "Disability", key: "disability" });
    }
    if (data?.vehicles) {
      s.push("vehicles");
      step.push({ title: "Vehicles", key: "vehicles" });
    }
    if (data?.pets) {
      s.push("pets");
      step.push({ title: "Pets", key: "pets" });
    }
    if (data?.rental_history) {
      s.push("rental_history");
      step.push({ title: "Rental History", key: "rental_history" });
    }
    // if (data?.application_fee) {
    //   s.push("application_fee");
    // }

    step.push(
      { title: "Review", key: "review" },
      { title: "Application Fee", key: "application_fee" }
    );

    setSequenceNumber(s);

    // To Update the Steps Based on application Sequence
    setSteps1(step);
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    if (application) {
      if (
        application.application.status === "reservor-data-rejected" ||
        application.application.status === "reservor-approved"
      ) {
        setCurrentStep(sequence[0]);
      } else {
        setCurrentStep(application.application.step_to_show);
      }
    }
  }, [application]);

  useEffect(() => {
    if (sequence) {
      setSequences(sequence);
      initializeSequenceNumber(application);
    }
  }, [sequence, application]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="tenant-onboard-module-wrapper">
      {console.log(currentStep, "Current step")}

      {sequence && sequence.length > 1 && (
        <div className="steps">
          <ProgressSteps steps={steps1} currentStep={currentStep} version="2" />
        </div>
      )}

      {application && currentStep && (
        <div>
          {/* Basic Details */}
          {currentStep === "basic_details" && (
            <BasicDetailForm
              applicationId={application_id}
              data={application?.basic_details}
              onNext={onNext}
              onCancelStep={onBack}
              fetchApplication={fetchApplication}
              canCancelApplication={true}
              onCancelApplication={cancelApplicationPostAcceptance}
            />
          )}

          {/* Commercial Detail */}
          {currentStep === "commercial_details" && (
            <CommercialDetailForm
              applicationId={application_id}
              data={application?.commercial_details}
              onNext={onNext}
              onCancelStep={onBack}
              fetchApplication={fetchApplication}
              canCancelApplication={true}
              onCancelApplication={cancelApplicationPostAcceptance}
            />
          )}

          {/* ID Verification */}
          {currentStep === "ids" && (
            <IDVerificationForm
              applicationId={application_id}
              data={application?.ids}
              onNext={onNext}
              numIds={application?.property_settings?.tenant_id_limit}
              onCancelStep={onBack}
              fetchApplication={fetchApplication}
              canCancelApplication={true}
              onCancelApplication={cancelApplicationPostAcceptance}
            />
          )}

          {/* Household Members */}
          {currentStep === "household_members" && (
            <HouseholdMembersForm
              applicationId={application_id}
              data={application.household_members}
              maxAllowed={application.property_settings.household_member - 1}
              onNext={onNext}
              onCancelStep={onBack}
              fetchApplication={fetchApplication}
              canCancelApplication={true}
              onCancelApplication={cancelApplicationPostAcceptance}
            />
          )}

          {/* Disability */}
          {currentStep === "disability" && (
            <DisabilityForm
              applicationId={application_id}
              data={application.disability}
              onNext={onNext}
              onCancelStep={onBack}
              fetchApplication={fetchApplication}
              canCancelApplication={true}
              onCancelApplication={cancelApplicationPostAcceptance}
            />
          )}

          {/* Vehicles */}
          {currentStep === "vehicles" && (
            <VehiclesForm
              applicationId={application_id}
              data={application.vehicles}
              maxAllowed={application.property_settings.vehicle_limit}
              onNext={onNext}
              onCancelStep={onBack}
              fetchApplication={fetchApplication}
              canCancelApplication={true}
              onCancelApplication={cancelApplicationPostAcceptance}
            />
          )}

          {/* Pets */}
          {currentStep === "pets" && (
            <PetsForm
              applicationId={application_id}
              data={application.pets}
              onNext={onNext}
              maxAllowed={application.property_settings.pet_limit}
              onCancelStep={onBack}
              fetchApplication={fetchApplication}
              canCancelApplication={true}
              onCancelApplication={cancelApplicationPostAcceptance}
            />
          )}

          {/* Rental History */}
          {currentStep === "rental_history" && (
            <RentalHistoryForm
              applicationId={application_id}
              data={application.rental_history}
              minLimit={application.property_settings.rent_history_limit}
              onNext={onNext}
              onCancelStep={onBack}
              fetchApplication={fetchApplication}
              canCancelApplication={true}
              onCancelApplication={cancelApplicationPostAcceptance}
            />
          )}

          {/* Review */}
          {currentStep === "review" && (
            <ReviewTenantApplicaion
              application_id={application_id}
              onNext={onNext}
              onCancelStep={onBack}
              data={application}
              isUnitCommercial={!application?.is_unit_residential}
              tenantType={application?.application?.tenant_type}
              acceptApplicationFee={
                application?.property_settings?.application_fee
              }
              cancelBtn={true}
              propertySetting={application?.property_settings}
              canCancelApplication={true}
              onCancelApplication={cancelApplicationPostAcceptance}
              sequenceNumber={sequenceNumber}
              sequence={sequences}
              application={application}
            />
          )}

          {currentStep === "review_again" && (
            <ReviewTenantApplicaion
              application_id={application_id}
              onNext={onNext}
              onCancelStep={onBack}
              data={application}
              isUnitCommercial={!application.is_unit_residential}
              tenantType={application.application.tenant_type}
              acceptApplicationFee={
                application.property_settings.application_fee
              }
              cancelBtn={true}
              propertySetting={application?.property_settings}
              reviewAgain={true}
              canCancelApplication={true}
              onCancelApplication={cancelApplicationPostAcceptance}
              sequenceNumber={sequenceNumber}
              sequence={sequences}
              application={application}
            />
          )}

          {/* Application Fee */}
          {currentStep === "application_fee" && (
            <ApplicationFeePayment
              application_id={application_id}
              data={application.application.unit}
              onPaymentSuccess={() => {
                onPaymentSuccess();
              }}
              acceptOnlinePayment={application.property_settings.online_payment}
              property_settings={application.property_settings}
              canCancelApplication={true}
              onCancelApplication={cancelApplicationPostAcceptance}
            />
          )}

          {/* Initial Agreement */}
          {currentStep === "initial_agreement" && (
            <InitialAgreementForm
              application_id={application_id}
              unit={application.application.unit}
              isUnitCommercial={!application.is_unit_residential}
              isUnitResidential={application.is_unit_residential}
              application={application}
              onSignatureSuccess={onSignatureSuccess}
            />
          )}

          {/* Contract Payment */}
          {currentStep === "contract_payment" &&
            application.billing_invoices && (
              <ContractPayment
                data={application.application.unit}
                attachCheque={application.is_payable_by_cheque}
                onPaymentSuccess={onPaymentSuccess}
                acceptOnlinePayment={
                  application.property_settings.online_payment
                }
                property_settings={application.property_settings}
                onCancelStep={onBack}
                billing_invoices={application.billing_invoices}
                contract={application.contract}
              />
            )}

          {/* Utility Registration */}
          {currentStep === "utilities" && (
            <UtilityRegistrationForm
              onNext={onNext}
              application_id={application_id}
              tenant_contract={application.contract._id}
              data={application.utilities}
              onCancelStep={onBack}
              utilities={
                application.property_settings.collect_utility_in_onboarding_list
              }
            />
          )}
        </div>
      )}

      {/* Key Collection */}
      {currentStep === "keys" && (
        <UnitKeys application={application} application_id={application_id} />
      )}

      {/* Cancelling Application */}
      {/* {cancelApplicationAllowed(currentStep) && (
        <div className="cancel-application text-center container_sm">
          <CustomButton
            type="button"
            onClick={() => setShowCancelConfirmation(true)}
            className="font_m fw_6"
            variant="red"
            size="l"
          >
            Cancel Application
          </CustomButton>
        </div>
      )} */}

      {/* Modals */}
      {/* Confirmation Modal */}
      <ApplicationDataRejectionModal
        showModal={showRejectionReasonModal}
        title="Reason for Cancelling."
        description={
          <p className="font_grey fw_6">
            Please provide a reason for rejection.
          </p>
        }
        onCancel={() => setShowRejectionReasonModal(false)}
        onRejectWithNote={cancelApplicationPostAcceptanceWithNote}
      />

      <ConfirmationModal
        showModal={showCancelConfirmation}
        title="Confirm Cancel Application ?"
        // description="Are you sure you want to cancel this onboarding process? Remember, this is an irreversible action."
        description="I would like to cancel the onboarding application process. 
        I am aware that after cancelling the application I won't be able to undo my action."
        onClose={() => setShowCancelConfirmation(false)}
        onConfirm={confirmCancelApplicationPostAcceptance}
      />
    </div>
  );
};
/* Component ends */

export default TenantOnboarding;
