import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { axiosInstance } from "../axios";
import { API_URL } from "../../config/devKeys";
import { PLATFORM } from "../../platformUtil";
import {
  setUploadProgress,
  setShowUploadProgress,
  setLoading,
} from "./unpersistedSlice";

const initialState = {
  guestVisitorPassTypes: [],
  contractorVisitorPassTypes: [],
  visitorPass: null,
};

export const fetchVisitorPassList = createAsyncThunk(
  "visitorpass/list",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/visitor-pass/list",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const fetchVisitorPassListByProperty = createAsyncThunk(
  "visitorpass/list_by_property",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { type, property_id, page } = payload;
      dispatch(setLoading(true));
      const { data } = await axios.get(
        `${API_URL}/visitor-pass/property/list?property_id=${property_id}&type=${type}&page=${page}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const fetchVisitorPassDetail = createAsyncThunk(
  "visitorpass/get",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.get(
        `${API_URL}/visitor-pass/get?id=${payload.id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const fetchGuestAccessPassTypes = createAsyncThunk(
  "visitorpass/guest/access_pass_types",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.get(
        API_URL + "/guest_access_pass_type/list",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const fetchContractorAccessPassTypes = createAsyncThunk(
  "visitorpass/contractor/access_pass_types",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.get(
        API_URL + "/contractor_access_pass_type/list",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const createVisitorPass = createAsyncThunk(
  "visitorpass/create",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const formData = new FormData();
      Object.keys(payload).map((key) => {
        if (key == "image") {
          if (PLATFORM !== "web") {
            formData.append(key, {
              name: payload[key].name,
              type: payload[key].type,
              uri:
                PLATFORM === "android"
                  ? payload[key].uri
                  : payload[key].uri.replace("file://", ""),
            });
          } else {
            formData.append(key, payload[key], payload[key].name);
          }
        } else {
          formData.append(key, payload[key]);
        }
      });
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }

      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "POST",
        url: "/visitor-pass/add",
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      const { data } = await axiosInstance.request(config);

      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const approveVisitorPass = createAsyncThunk(
  "visitorpass/approve",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { id } = payload;
      dispatch(setLoading(true));
      const { data } = await axios.patch(
        `${API_URL}/visitor-pass/approved?id=${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const revokeVisitorPass = createAsyncThunk(
  "visitorpass/revoke",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { id } = payload;
      dispatch(setLoading(true));
      const { data } = await axios.patch(
        `${API_URL}/visitor-pass/revoked?id=${id}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const rejectVisitorPass = createAsyncThunk(
  "visitorpass/reject",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { id } = payload;
      dispatch(setLoading(true));
      const { data } = await axios.patch(
        `${API_URL}/visitor-pass/rejected?id=${id}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const visitorpassSlice = createSlice({
  name: "visitorpass",
  initialState,
  extraReducers: {
    [fetchVisitorPassDetail.fulfilled]: (state, action) => {
      state.visitorPass = action.payload;
    },
    [fetchVisitorPassDetail.rejected]: (state, action) => {
      state.visitorPass = null;
    },
    [fetchGuestAccessPassTypes.fulfilled]: (state, action) => {
      state.guestVisitorPassTypes = action.payload;
    },
    [fetchGuestAccessPassTypes.rejected]: (state, action) => {
      state.guestVisitorPassTypes = [];
    },
    [fetchContractorAccessPassTypes.fulfilled]: (state, action) => {
      state.contractorVisitorPassTypes = action.payload;
    },
    [fetchContractorAccessPassTypes.rejected]: (state, action) => {
      state.contractorVisitorPassTypes = [];
    },
  },
});

export const getVisitorPass = (state) => state.visitorpass.visitorPass;
export const getGuestAccessPassTypes = (state) =>
  state.visitorpass.guestVisitorPassTypes;
export const getContractorAccessPassTypes = (state) =>
  state.visitorpass.contractorVisitorPassTypes;

export default visitorpassSlice.reducer;
