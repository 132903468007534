/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  fetchTenantSecurityComplaintRequests,
  getTenantSecurityComplaints,
} from "../../../setup/store/slices/securitySlice";
// import WarningModal from "../../../components/ui/modal/WarningModal/WarningModal";
import {
  fetchUnitDetailByID,
  getUnitDetail,
} from "../../../setup/store/slices/unitSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import TenantSecurityTable from "../../../components/module/SecurityManage/TenantSecurityManage/TenantComplainTable/TenantSecurityTable";
/* Import local pages and component ends */

// Styles
import "./style.scss";

/* Component starts */
const TenantSecurity = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [currentPage, setCurrentPage] = useState(1);
  // const [showWarningModal, setShowWarningModal] = useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const { unitId } = useParams();
  const unit = useSelector(getUnitDetail);
  const complaintList = useSelector(getTenantSecurityComplaints);

  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  function loadCompaintList() {
    const payload = {
      unit_id: unitId,
      tenant_id: unit?.tenant?._id,
      page: currentPage,
    };
    dispatch(fetchTenantSecurityComplaintRequests(payload));
  }

  // const handleShow = () => {
  //   setShowWarningModal(!showWarningModal);
  // };

  // const handleShowIn = () => {
  //   setShowWarningModal(!showWarningModal);

  // };
  // // On closing success modal and warning modal
  // const onModalClose = () => {
  //   setShowWarningModal(false);
  //   navigate("/tenant/security/complain/add");
  // };
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    dispatch(fetchUnitDetailByID({ unit_id: unitId }));
  }, [unitId]);

  useEffect(() => {
    loadCompaintList();
  }, [unitId, currentPage]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */

  return (
    <div className="wrapper-security">
      <div className="heading-wrapper">
        <div className="heading">
          <h2 className="font_xxl fw_5">Security Complains</h2>
        </div>
        <div className="register-wrapper">
          <Link
            to="add"
            // onClick={() => handleShow()}
            className="font_m fw_4 font_blue d_block link-security"
          >
            + Register Complain
          </Link>
        </div>
      </div>

      <TenantSecurityTable
        complainList={complaintList?.securityComplaints}
        totalCount={complaintList?.total}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />

      {/* Warning Modal */}
      {/* <WarningModal
        showModal={showWarningModal}
        title="Life-threatening Incident"
        description="Property Added sucessfully, but missing the minimum required roles to operate the property."
        onClose={onModalClose}
        onSuccess={handleShowIn}
        btnName={"Yes"}
      /> */}
    </div>
  );
};
/* Component Ends */
export default TenantSecurity;
