/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useDispatch } from "react-redux";
import GLOBAL from "../../../../../setup/constants/global";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  showSuccessAlert,
  showErrorAlert,
} from "../../../../../setup/store/slices/globalAlertSlice";
import { updatePropertySettings } from "../../../../../setup/store/slices/propertySlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomNumberInput from "../../../../ui/input/CustomNumberInput/CustomNumberInput";
import CustomButton from "../../../../ui/button/CustomButton/CustomButton";
/* Import local pages and component ends */

/* Component starts */
const TenantIdCount = (props) => {
  /* Props destructuring starts */
  const { user, settings, property_id } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [settingChanged, setSettingChanged] = useState(false);
  const [count, setCount] = useState("1");
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  // On updating setting
  const onUpdate = async () => {
    if (parseInt(count) !== 1 && parseInt(count) !== 2) {
      dispatch(
        showErrorAlert({
          title: "Error!",
          description: "Only 1 or 2 are accepted",
        })
      );
      return;
    }

    // Creating or collecting payload data to be sent
    const payload = {
      tenant_id_limit: count,
      property_id,
    };

    // Backend response. Try, Catch
    try {
      const result = await dispatch(updatePropertySettings(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Settings Updated!",
              description: result.payload.message,
            })
          );
          setSettingChanged(false);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  // On initial load or when all the settings is loaded
  useEffect(() => {
    if (settings) {
      setCount(settings?.tenant_id_limit?.toString());
    }
  }, [settings]);

  // If any setting is changed
  useEffect(() => {
    setSettingChanged(false);
    if (settings?.tenant_id_limit?.toString() !== count?.toString()) {
      setSettingChanged(true);
    }
  }, [settings, count]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="tenant-id-count option">
      {/* Label */}
      <div className="label">
        <p className="font_m fw_5">Tenant ID Verification</p>
      </div>

      {/* Setting */}
      <div className="setting">
        <div className="row1">
          <p className="font_s fw_5 font_grey mb_1">Number of IDs</p>
          <CustomNumberInput
            variant="2"
            value={count}
            setValue={setCount}
            inputClass="setting-input font_xs sm text-center"
          />
        </div>

        <div className="row2">
          <p className="font_s fw_5 font_grey mt_2">
            Number of IDs needed to be collected from the tenant. Only 1 or 2
            are accepted
          </p>
        </div>

        {/* Save Button */}
        {settingChanged && (
          <div className="save-btn-wrapper mt_2">
            <CustomButton onClick={onUpdate} className="save-btn" size="s">
              Save
            </CustomButton>
          </div>
        )}
      </div>
    </div>
  );
};
/* Component ends */

export default TenantIdCount;
