/**
 *
 */

import React, { useState, useEffect } from "react";
import { NoProfPicSet } from "../../../setup/constants/images";
import moment from "moment";
import GLOBAL from "../../../setup/constants/global";

/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
/* Import local pages and component ends */

/* Component starts */
const Detail = (props) => {
  /* Props destructuring starts */
  const { tenantInfo } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const getAmmenities = (unit) => {
    let ammenities = [];
    if (unit?.balcony) {
      ammenities.push(`${unit?.balcony} Balcony`);
    }
    if (unit?.bathroom) {
      ammenities.push(`${unit?.bathroom} Bathroom`);
    }
    if (unit?.kitchen) {
      ammenities.push(`${unit?.kitchen} Kitchen`);
    }
    if (unit?.laundry) {
      ammenities.push(`${unit?.laundry} Laundry`);
    }
    if (unit?.living_room) {
      ammenities.push(`${unit?.living_room} Living Room`);
    }
    if (unit?.maids_room) {
      ammenities.push(`${unit?.maids_room} Maid's Room`);
    }
    if (unit?.offices) {
      ammenities.push(`${unit?.offices} Office`);
    }
    if (unit?.pantry) {
      ammenities.push(`${unit?.pantry} Pantry`);
    }
    if (unit?.showroom) {
      ammenities.push(`${unit?.showroom} Showroom`);
    }
    if (unit?.store) {
      ammenities.push(`${unit?.store} Store`);
    }
    if (unit?.terrace) {
      ammenities.push(`${unit?.terrace} Terrace`);
    }
    if (unit?.toilet) {
      ammenities.push(`${unit?.toilet} Toilet`);
    }

    return ammenities.join(", ");
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="detail-wrapper">
      <div className="main-data-wrapper">
        <div className="tenant-unit-detail">
          <div>
            <p className="font_blue fw_5 font_m">
              {tenantInfo?.unit_info[0]?.property_name}
            </p>

            <p className="unitName font_m fw_5 font_grey">
              Unit:{" "}
              <span className="unitName font_m fw_5 font_blue">
                {tenantInfo?.unit_info[0]?.unit_name}
              </span>
            </p>
          </div>

          {/* <div className="right d-flex"> */}

          <div className="unit-data">
            <p className="ammenties font_xs font_grey fw_5">
              {getAmmenities(tenantInfo?.unit_info[0])}
            </p>
          </div>

          {/* </div> */}
        </div>

        <div className="tenant-profile-wrapper">
          <div className="profile-wrapper ">
            <div className="heading">
              <h4 className="font_xl fw_6 text-center">Tenant Profile</h4>
            </div>
            <img
              src={tenantInfo?.tenant_info?.myphoto?.url || NoProfPicSet}
              alt="Profile"
            />
            <h4 className="font_s fw_4">
              {tenantInfo?.tenant_info?.first_name}{" "}
              {tenantInfo?.tenant_info?.last_name}
            </h4>
          </div>

          <div className="basic-wrapper">
            <div className="detail-box">
              <div className="detail">
                <p className="font_s fw_3">
                  Mobile Number <br />{" "}
                  <span className="font_m fw_4">
                    {tenantInfo?.tenant_info?.countrycode}{" "}
                    {tenantInfo?.tenant_info?.mobile}
                  </span>
                </p>
              </div>
              <div className="detail">
                <p className="font_s fw_3">
                  Date Of Birth <br />{" "}
                  <span className="font_m fw_4">
                    {tenantInfo?.tenant_info?.dob !== undefined
                      ? moment(tenantInfo?.tenant_info?.dob).format(DATE_FORMAT)
                      : "-"}
                  </span>
                </p>
              </div>

              <div className="detail">
                <p className="font_s fw_3">
                  Email <br />{" "}
                  <span className="font_m fw_4">
                    {tenantInfo?.tenant_info?.email !== undefined
                      ? tenantInfo?.tenant_info?.email
                      : "-"}
                  </span>
                </p>
              </div>

              <div className="detail">
                <p className="font_s fw_3">
                  Profession <br />{" "}
                  <span className="font_m fw_4">
                    {tenantInfo?.tenant_info?.profession !== undefined
                      ? tenantInfo?.tenant_info?.profession
                      : "-"}
                  </span>
                </p>
              </div>

              <div className="detail">
                <p className="font_s fw_3">
                  Gender <br />{" "}
                  <span className="font_m fw_4">
                    {tenantInfo?.tenant_info?.gender !== undefined
                      ? tenantInfo?.tenant_info?.gender
                      : "-"}
                  </span>
                </p>
              </div>

              <div className="detail">
                <p className="font_s fw_3">
                  Nationality <br />{" "}
                  <span className="font_m fw_4">
                    {tenantInfo?.tenant_info?.nationality !== undefined
                      ? tenantInfo?.tenant_info?.nationality
                      : "-"}
                  </span>
                </p>
              </div>

              <div className="detail">
                <p className="font_s fw_3">
                  Company Name <br />{" "}
                  <span className="font_m fw_4">
                    {tenantInfo?.tenant_info?.company !== undefined
                      ? tenantInfo?.tenant_info?.company
                      : "-"}
                  </span>
                </p>
              </div>

              <div className="detail">
                <p className="font_s fw_3">
                  Language <br />{" "}
                  <span className="font_m fw_4">
                    {tenantInfo?.tenant_info?.language !== undefined
                      ? tenantInfo?.tenant_info?.language
                      : "-"}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
/* Component ends */

export default Detail;
