/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import moment from "moment";
import GLOBAL from "../../../../../setup/constants/global";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { RightArrowOutlineGrey } from "../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
/* Import local pages and component ends */

/* Component starts */
const ClientLicenseInvoiceListItem = (props) => {
  /* Props destructuring starts */
  const { invoice, ...rest } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const number = invoice?.invoice_no;
  const status = invoice?.status;
  const date = invoice?.invoice_date;

  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="invoice-item-component-wrapper" {...rest}>
      <p className="font_grey fw_6 font_m num">#{number}</p>

      <div className="status-date">
        <p
          className={`${
            status === "unpaid" ? "font_yellow" : "font_blue"
          } font_xs fw_6 status`}
        >
          {status.toUpperCase()}
        </p>
        <p className="font_grey fw_5 font_s date">
          {moment(date).format(DATE_TIME_FORMAT)}
        </p>
        <img className="arr-right" src={RightArrowOutlineGrey} alt="" />
      </div>
    </div>
  );
};
/* Component ends */

export default ClientLicenseInvoiceListItem;
