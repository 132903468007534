/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { loadStripe } from "@stripe/stripe-js";
import { useDispatch, useSelector } from "react-redux";
import GLOBAL from "../../../../setup/constants/global";
/* Import configuration ends */

/* Import redux slices component starts */
import { fetchUser } from "../../../../setup/store/slices/authSlice";
import { fetchInvoices } from "../../../../setup/store/slices/invoiceSlice";
import { purchaseLicense } from "../../../../setup/store/slices/licenseSlice";
import {
  fetchCards,
  fetchTenantCards,
  getCards,
} from "../../../../setup/store/slices/paymentSlice";
import {
  payTenantApplicationFee,
  payTenantContract,
} from "../../../../setup/store/slices/tenantSlice";
import { payTenantInvoiceOnline } from "../../../../setup/store/slices/invoiceSlice";
import { setLoading } from "../../../../setup/store/slices/unpersistedSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import MUI component starts */
/* Import MUI component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import CardList from "../CardList/CardList";
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
import TextButton from "../../../ui/button/TextButton/TextButton";
import AddNewCard from "../AddNewCard/AddNewCard";
/* Import local pages and component ends */

/* Component starts */
const PaymentByCard = (props) => {
  /* Props destructuring starts */
  const {
    onCancel,
    isPaymentAllowed,
    payload,
    receiverStripeKey,
    setTransactionStatus,
    setShowTransactionStatus,
    size,
    paymentType,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [addNewCard, setAddNewCard] = useState(false);
  const [selectedCard, setSelectedCard] = useState({});
  const [cardList, setCardList] = useState();
  const [showCVCModal, setShowCVCModal] = useState(false);
  const [showAddNewCard, setShowAddNewCard] = useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  //   const stripe = useStripe();
  // Redux selector
  const cards = useSelector(getCards);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  // On clicking add new card button
  const onAddNewCard = () => {
    setShowAddNewCard(false);
  };

  // On cancelling adding new card
  const cancelAddCard = () => {
    setShowAddNewCard(false);
  };

  // If payment is successful
  const paymentSuccessful = async (status) => {
    await dispatch(fetchInvoices());
    await dispatch(fetchUser());
    setTransactionStatus(status);
    setShowTransactionStatus(true);
  };

  // If your card is 3d secure
  const confirmPaymentIntent = async (client_secret) => {
    await dispatch(setLoading(true));
    const stripe = await loadStripe(receiverStripeKey);
    const data = await stripe.confirmCardPayment(client_secret);
    if (data.error) {
      await dispatch(setLoading(false));
      setTransactionStatus({
        transaction_status: "Failed",
        reason: data.error.localizedMessage,
      });
      setShowTransactionStatus(true);
    } else {
      await dispatch(setLoading(false));
      if (
        data.paymentIntent.status ===
        GLOBAL.PAYMENT_STATUS.SUCCEEDED.toLocaleLowerCase()
      ) {
        paymentSuccessful({
          transaction_status: "Success",
          transaction_id: data.paymentIntent.id,
          transaction_amount: data.paymentIntent.amount / 100,
        });
      }
    }
  };

  // On purchasing with selected card
  const onPayNow = async () => {
    let data = {
      ...payload,
      card_id: selectedCard.id,
    };
    console.log("Payload", data);

    try {
      // const result = await dispatch(purchaseLicense(data));
      let result;
      if (
        paymentType === GLOBAL.PAYMENT_TYPE.LICENSE ||
        paymentType === GLOBAL.PAYMENT_TYPE.INVOICE
      ) {
        result = await dispatch(purchaseLicense(data));
      } else if (paymentType === GLOBAL.PAYMENT_TYPE.TENANT_APPLICATION_FEE) {
        result = await dispatch(payTenantApplicationFee(data));
      } else if (paymentType === GLOBAL.PAYMENT_TYPE.TENANT_CONTRACT) {
        result = await dispatch(payTenantContract(data));
      } else if (paymentType === GLOBAL.PAYMENT_TYPE.TENANT_INVOICE) {
        result = await dispatch(payTenantInvoiceOnline(data));
      }
      console.log("Response", result);

      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setTransactionStatus({
            transaction_status: "Failed",
            reason: result.payload?.error?.message,
          });
          setShowTransactionStatus(true);
          break;

        case GLOBAL.FULFILLED:
          if (
            result.payload.status === GLOBAL.PAYMENT_STATUS.REQUIRE_ACTION ||
            result.payload.transaction_status ===
              GLOBAL.PAYMENT_STATUS.REQUIRE_ACTION
          ) {
            let client_secret = result.payload.client_secret;
            confirmPaymentIntent(client_secret);
          } else if (
            result.payload.transaction_status.toLowerCase() ===
            GLOBAL.PAYMENT_STATUS.SUCCESS.toLowerCase()
          ) {
            paymentSuccessful({
              transaction_status: "Success",
              transaction_id: result.payload.transaction_id,
              transaction_amount: result.payload.transaction_amount,
            });
          } else if (
            result.payload.transaction_status.toLowerCase() ===
            GLOBAL.PAYMENT_STATUS.FAILED.toLowerCase()
          ) {
            setTransactionStatus({
              transaction_status: "Failed",
              reason: result.payload?.message,
            });
            setShowTransactionStatus(true);
          }
          break;
      }
    } catch (error) {
      console.error(error.message);
    }
  };
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  // Initial load
  useEffect(() => {
    if (paymentType) {
      switch (paymentType) {
        case GLOBAL.PAYMENT_TYPE.LICENSE:
        case GLOBAL.PAYMENT_TYPE.INVOICE:
          dispatch(fetchCards());
          break;
        case GLOBAL.PAYMENT_TYPE.TENANT_APPLICATION_FEE:
        case GLOBAL.PAYMENT_TYPE.TENANT_CONTRACT:
        case GLOBAL.PAYMENT_TYPE.TENANT_INVOICE:
          const { property_id } = payload;
          dispatch(fetchTenantCards({ property_id }));
          break;
      }
    }
  }, [paymentType]);

  useEffect(() => {
    if (cards.length > 0) {
      setSelectedCard(cards[0]);
    }
  }, [cards]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div>
      {/* Card list */}
      <CardList
        size={size}
        cards={cards}
        selectedCard={selectedCard}
        setSelectedCard={setSelectedCard}
      />

      {/* If add new card component is not shown */}
      {!showAddNewCard && (
        <>
          <div className="button-wrapper">
            <CustomButton
              size="l"
              variant="outline"
              onClick={() => {
                setShowAddNewCard(true);
              }}
            >
              + Add new card
            </CustomButton>
            {cards?.length > 0 && isPaymentAllowed && (
              <CustomButton
                className="mt-4"
                size="l"
                onClick={() => {
                  isPaymentAllowed && onPayNow();
                }}
              >
                Pay
              </CustomButton>
            )}

            {onCancel && (
              <TextButton
                onClick={onCancel}
                size="l"
                className="fw_6 w_100 font_m"
              >
                Cancel
              </TextButton>
            )}
          </div>
        </>
      )}

      {/* Add new Card */}
      {showAddNewCard && (
        <AddNewCard
          receiverStripeKey={receiverStripeKey}
          onCancel={cancelAddCard}
          onAddNewCard={onAddNewCard}
          property_id={payload?.property_id}
        />
      )}
    </div>
  );
};
/* Component ends */

export default PaymentByCard;
