/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import GLOBAL from "../../../../../setup/constants/global";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  formatCurrency,
  customMoment,
  customDateFormat,
  pathNamePopped,
} from "../../../../../setup/utils/global-helper";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  updateTenantInstallmentPlan,
  updateTenantInitialAgreement,
} from "../../../../../setup/store/slices/tenantSlice";
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../../../setup/store/slices/globalAlertSlice";
import { updateChequeDetails } from "../../../../../setup/store/slices/chequeSlice";
import {
  getPropertySettings,
  fetchPropertySettings,
} from "../../../../../setup/store/slices/propertySlice";
import {
  fetchPropertyDetailByID,
  getPropertyDetail,
} from "../../../../../setup/store/slices/propertySlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Table } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import {
  EditWhite,
  XWhite,
  WarningRoundedOutlineYellow,
  WarningRoundedBlue,
  ChequeOutlineBlue,
  EditGreen,
  XGreen,
  ExcalmatoryYellow,
  DocumentGreen,
} from "../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../../../ui/button/IconButton/IconButton";
import CustomButton from "../../../../ui/button/CustomButton/CustomButton";
import ChequeDetailModal from "../../../Invoicing/ChequeDetailModal/ChequeDetailModal";
import ShowChequeDetailModal from "../../../Invoicing/ShowChequeDetailModal/ShowChequeDetailModal";
import FileViewerModal from "../../../../ui/modal/FileViewerModal/FileViewerModal";
import CustomCurrencyInput from "../../../../ui/input/CustomCurrencyInput/CustomCurrencyInput";
import CustomDateInput from "../../../../ui/input/CustomDateInput/CustomDateInput";
/* Import local pages and component ends */

/* Component starts */
const RentDetails = (props) => {
  /* Props destructuring starts */
  const {
    data = [],
    canEdit,
    application_id,
    instalmentPlan,
    setShowSetInstallment,
    currency,
    canAttachCheque,
    onUpdateChequeCallback,
    index,
    depositData,
    canSend,
    getApplication,
    manuallyFetchPropertySettings = true,
    manuallyFetchPropertyData = true,
    property_settings = null,
    property_data = null,
    isRenew = false,
    isOld = false,
    property,
    isTransitioned = false,
    canViewCheque = false,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [thresholdAmt, setThresholdAmt] = useState(0);
  const [totalAmt, setTotalAmt] = useState({ errorAmt: false, amount: 0 });
  const [newInstallment, setNewInstallment] = useState([]);
  const [newDueDates, setNewDueDates] = useState([]);
  const [dataChanged, setDataChanged] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [selectedInstallment, setSelectedInstallment] = useState(null);
  const [openChequeDetailModal, setOpenChequeDetailModal] = useState(false);
  const [showCheque, setShowCheque] = useState(false);
  const [showChequeFile, setShowChequeFile] = useState(false);
  const [settings, setSettings] = useState(null);
  const [propertyData, setPropertyData] = useState(null);
  const [isDatePickerCalenderOpen, setIsDatePickerCalenderOpen] =
    useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { propertyId } = useParams();
  const { pathname } = useLocation();

  // Property Settings
  // const settings =
  //   manuallyFetchPropertySettings && !property_settings
  //     ? useSelector(getPropertySettings)
  //     : property_settings;

  // Property Data
  // const propertyData =
  //   manuallyFetchPropertyData && !property_data
  //     ? useSelector(getPropertyDetail)
  //     : property_data;
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const deposit = instalmentPlan?.deposit;
  const depositAmt = instalmentPlan?.deposit_amount?.toLocaleString("en-US");
  const commission = instalmentPlan?.commission;
  const commissionAmt =
    instalmentPlan?.commission_amount?.toLocaleString("en-US");
  const duration = instalmentPlan?.duration;
  const noOfInstallments = instalmentPlan?.no_of_installments;
  const startDate = instalmentPlan?.start_date;
  const endDate = instalmentPlan?.end_date;
  const actionDate = instalmentPlan?.updatedAt;
  const totalRent = instalmentPlan?.total_rent;

  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // Checking if any one installment is changed and setting dataChanged state
  const checkEqual = (installments) => {
    for (let i = 0; i < installments.length; i++) {
      if (parseInt(installments[i]) !== data[i].amount) {
        setDataChanged(true);
        return;
      }
    }
    setDataChanged(false);
  };

  // Check if the custom edited installment total amount is equal to the provided total amount
  const checkTotal = (installments) => {
    let total = installments.reduce((acc, curr) => acc + parseInt(curr), 0);

    setTotalAmt({
      errorAmt: total !== thresholdAmt,
      amount: total,
    });
  };

  // Handling on installment change event
  const handleChange = (value, index) => {
    if (isNaN(Number(value)) || value[value.length - 1] === ".") {
      return false;
    } else {
      if (value[0] === "0" && value[1] !== undefined) {
        return false;
      } else {
        const array = [...newInstallment];
        array[index] = value;
        checkEqual(array);
        checkTotal(array);
        setNewInstallment(array);
      }
    }
  };

  // Handling on due date change event
  const handleDateChange = (date, index) => {
    const array = [...newDueDates];
    array[index] = new Date(date);
    setNewDueDates(array);
    setDataChanged(true);
  };

  // Date Validations
  const dateValidations = () => {
    for (let i = 0; i < newDueDates.length; i++) {
      if (i > 0) {
        if (
          newDueDates[i].toDateString() === newDueDates[i - 1].toDateString()
        ) {
          dispatch(
            showErrorAlert({
              title: "Error!",
              description:
                "A due date selected is same as it's previous due date. Please check again",
            })
          );
          return false;
        }
        if (!moment(newDueDates[i]).isAfter(newDueDates[i - 1])) {
          dispatch(
            showErrorAlert({
              title: "Error!",
              description:
                "A due date selected is not after it's previous due date. Please check again",
            })
          );
          return false;
        }
      }

      if (moment(newDueDates[i]).isAfter(instalmentPlan?.end_date)) {
        dispatch(
          showErrorAlert({
            title: "Error!",
            description:
              "A due date selected is after the installment plan end date. Please check again",
          })
        );
        return false;
      }
    }
    return true;
  };

  // On updating installments
  const onUpdate = async () => {
    setEditIndex(null);
    if (totalAmt.errorAmt) {
      dispatch(
        showErrorAlert({
          title: "Error!",
          description:
            "Edited installment amount is not matching with the provided Total Rent.",
        })
      );
      return;
    }
    if (!dateValidations()) {
      return;
    }

    // Creating or collecting payload data to be sent
    const payload = {
      application_id,
      property_id: propertyId,
      updated_installment: [],
    };

    data.forEach((item, index) => {
      payload.updated_installment.push({
        _id: item?._id,
        amount: parseInt(newInstallment[index]),
        due_date: newDueDates[index],
      });
    });

    payload["updated_installment"] = JSON.stringify(
      payload["updated_installment"]
    );

    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      const result = await dispatch(updateTenantInstallmentPlan(payload));
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;
        case GLOBAL.FULFILLED:
          getApplication();
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          setDataChanged(false);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // On sending installments to tenant
  const sendInstalmentsToTenant = async () => {
    // Creating or collecting payload data to be sent
    const payload = {
      application_id,
      type: "installment_plan",
      installment_plan: instalmentPlan,
      property_id: propertyId,
    };

    // Backend response. Try, Catch
    try {
      const result = await dispatch(updateTenantInitialAgreement(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          getApplication();
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // On updating cheque details
  const onChequeUpdate = async (values) => {
    // Creating or collecting payload data to be sent
    const payload = {
      installment_id: selectedInstallment?._id,
      issuer_name: values?.cheque_issuer,
      account_number: values?.account_no,
      cheque_number: values?.cheque_number,
      cheque_amount: selectedInstallment?.amount,
      payment_amount: selectedInstallment?.amount,
      cheque_date: new Date(selectedInstallment?.due_date),
      bank_name: values?.bank_name,
      cheque_doc: values?.cheque_doc,
      application_id,
    };
    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      const result = await dispatch(updateChequeDetails(payload));
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          onUpdateChequeCallback && onUpdateChequeCallback();
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  const getTotal = (amt, collectVat, collectTax) => {
    let amount = amt;
    if (collectVat && settings?.collect_vat_enabled) {
      amount += (amt * settings?.collect_vat_value) / 100;
    }
    if (collectTax && settings?.collect_tax_enabled) {
      amount += (amt * settings?.collect_tax_value) / 100;
    }
    // return `${amount?.toLocaleString("en-US")}`;
    //?.toLocaleString('en-US')
    return `${amount?.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`;
  };

  // On fetching property settings
  const handleFetchPropertySettings = async () => {
    // Backend response. Try, Catch
    try {
      const result = await dispatch(
        fetchPropertySettings({ property_id: propertyId })
      );
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description:
                typeof result?.payload === "string" ? result?.payload : "",
            })
          );
          setSettings(null);
          break;

        case GLOBAL.FULFILLED:
          setSettings(result.payload);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // On fetching property data
  const handleFetchPropertyData = async () => {
    // Backend response. Try, Catch
    try {
      const result = await dispatch(
        fetchPropertyDetailByID({ property_id: propertyId })
      );
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description:
                typeof result?.payload === "string" ? result?.payload : "",
            })
          );
          setPropertyData(null);
          break;

        case GLOBAL.FULFILLED:
          setPropertyData(result.payload);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // To View The Invoice Old Contracts Installments
  const handleView = (item) => {
    const newPathname = pathNamePopped(pathname, 3);

    navigate(
      `${newPathname}/invoicing/Tenant/rent/${item?.tenant_billing_invoice}`,
      {
        state: { pathname: pathname },
      }
    );
  };

  /* Component function definition ends */

  /* Component useEffect starts */
  // Calculating/Adding all installments amount to get the total installment amount
  useEffect(() => {
    if (data.length > 0 && instalmentPlan) {
      const installments = [];
      const dates = [];
      const totalAmountValue = parseInt(
        data.reduce((acc, curr) => acc + curr.amount, 0)
      );

      data.forEach((item) => {
        installments.push(item.amount + "");
        dates.push(customDateFormat(item.due_date, property?.offset));
      });

      setThresholdAmt(totalRent);
      setTotalAmt((prevState) => ({
        errorAmt: totalRent !== totalAmountValue,
        amount: totalAmountValue,
      }));
      setDataChanged(totalRent !== totalAmountValue);
      setNewInstallment(installments);
      setNewDueDates(dates);
    }
  }, [data, instalmentPlan]);

  useEffect(() => {
    if (manuallyFetchPropertyData) {
      handleFetchPropertyData();
    } else if (property_data) {
      setPropertyData(property_data);
    }

    if (manuallyFetchPropertySettings) {
      handleFetchPropertySettings();
    } else if (property_settings) {
      setSettings(property_settings);
    }
  }, []);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="rent-detail-wrapper">
      <div className="rent-detail-heading">
        <p className="font_l label">
          {" "}
          {index && `${index} -`} {isRenew && "Renewal Contract Terms"}{" "}
          {isOld && "Current Contract Terms"}
          {isTransitioned && "Contract Terms"}
          {!isRenew && !isOld && !isTransitioned && "Contract Terms"}
        </p>
      </div>

      {/* ID Verfication Table */}
      <div className="custom-table-wrapper">
        {/* Top Options */}
        <div className="top-options"></div>
        {/* More Details */}
        <div className="more-details-edit">
          {canEdit && (
            <div className="edit-wrapper">
              <IconButton
                onClick={() => setShowSetInstallment(true)}
                className="action-btn installment-plan-edit-btn"
              >
                <img className="h_100" src={EditGreen} alt="Cancel" />
              </IconButton>
              <span className="font_s fw_5">Edit Installment Plan</span>
            </div>
          )}
          <div className="more-details">
            <div className="more-data">
              {/* Total Rent */}
              <div className="c-row">
                <p className="font_xs fw_5 key font_grey">Total Rent</p>
                <p className="font_xs fw_5 value">
                  {totalRent?.toLocaleString("en-US")} ({currency})
                </p>
              </div>
              {/* Deposit Amount */}
              {deposit && (
                <div className="c-row">
                  <p className="font_xs fw_5 key font_grey">
                    Deposit ({currency})
                    {settings?.collect_vat_enabled &&
                      instalmentPlan.collect_deposit_vat && (
                        <p>VAT({settings?.collect_vat_value}%)</p>
                      )}
                    {settings?.collect_tax_enabled &&
                      instalmentPlan.collect_deposit_tax && (
                        <p>Tax({settings?.collect_tax_value}%)</p>
                      )}
                    {(settings?.collect_vat_enabled &&
                      instalmentPlan.collect_deposit_vat) ||
                    (settings?.collect_tax_enabled &&
                      instalmentPlan.collect_deposit_tax) ? (
                      <p>Total</p>
                    ) : (
                      ""
                    )}
                  </p>
                  <p className="font_xs fw_5 value">
                    {depositAmt}
                    {settings?.collect_vat_enabled &&
                      instalmentPlan.collect_deposit_vat && (
                        <p>
                          {instalmentPlan.collect_deposit_vat
                            ? (
                                (instalmentPlan.deposit_amount *
                                  settings?.collect_vat_value) /
                                100
                              ).toFixed(2)
                            : 0}
                        </p>
                      )}
                    {settings?.collect_tax_enabled &&
                      instalmentPlan.collect_deposit_tax && (
                        <p>
                          {instalmentPlan.collect_deposit_tax
                            ? (
                                (instalmentPlan.deposit_amount *
                                  settings?.collect_tax_value) /
                                100
                              ).toFixed(2)
                            : 0}
                        </p>
                      )}
                    {((settings?.collect_vat_enabled &&
                      instalmentPlan.collect_deposit_vat) ||
                      (settings?.collect_tax_enabled &&
                        instalmentPlan.collect_deposit_tax)) && (
                      <>
                        {getTotal(
                          instalmentPlan.deposit_amount,
                          instalmentPlan.collect_deposit_vat,
                          instalmentPlan.collect_deposit_tax
                        )?.toLocaleString("en-US")}
                      </>
                    )}
                  </p>
                </div>
              )}
              {/* Commission Amount */}
              {commission && (
                <div className="c-row">
                  <p className="font_xs fw_5 key font_grey">
                    Commission ({currency})
                    {settings?.collect_vat_enabled &&
                      instalmentPlan.collect_commission_vat && (
                        <p>VAT({settings?.collect_vat_value}%)</p>
                      )}
                    {settings?.collect_tax_enabled &&
                      instalmentPlan.collect_commission_tax && (
                        <p>Tax({settings?.collect_tax_value}%)</p>
                      )}
                    {(settings?.collect_vat_enabled &&
                      instalmentPlan.collect_commission_vat) ||
                    (settings?.collect_tax_enabled &&
                      instalmentPlan.collect_commission_tax) ? (
                      <p>Total</p>
                    ) : (
                      ""
                    )}
                  </p>
                  <p className="font_xs fw_5 value">
                    {commissionAmt}{" "}
                    {settings?.collect_vat_enabled &&
                      instalmentPlan.collect_commission_vat && (
                        <p>
                          {" "}
                          {instalmentPlan.collect_commission_vat
                            ? (
                                (instalmentPlan.commission_amount *
                                  settings?.collect_vat_value) /
                                100
                              ).toFixed(2)
                            : 0}{" "}
                        </p>
                      )}{" "}
                    {settings?.collect_tax_enabled &&
                      instalmentPlan.collect_commission_tax && (
                        <p>
                          {instalmentPlan.collect_commission_tax
                            ? (
                                (instalmentPlan.commission_amount *
                                  settings?.collect_tax_value) /
                                100
                              ).toFixed(2)
                            : 0}
                        </p>
                      )}
                    {(settings?.collect_vat_enabled ||
                      settings?.collect_tax_enabled) && (
                      <>
                        {getTotal(
                          instalmentPlan.commission_amount,
                          instalmentPlan.collect_commission_vat,
                          instalmentPlan.collect_commission_tax
                        )?.toLocaleString("en-US")}
                      </>
                    )}
                  </p>
                </div>
              )}
            </div>

            <div className="more-data-duration">
              {/* Duration */}
              <div className="c-row">
                <p className="font_xs fw_5 key font_grey">Duration</p>
                <p className="font_xs fw_5 value">{duration}</p>
              </div>
              {/* No. Of Installment */}
              <div className="c-row">
                <p className="font_xs fw_5 key font_grey">No. Of Installment</p>
                <p className="font_xs fw_5 value">{noOfInstallments}</p>
              </div>
            </div>

            <div className="more-data">
              {/* Start Date */}
              <div className="c-row">
                <p className="font_xs fw_5 key font_grey">Start Date</p>
                <p className="font_xs fw_5 value">
                  {customMoment(startDate, DATE_FORMAT, true, property?.offset)}
                </p>
              </div>
              {/* End Date */}
              <div className="c-row">
                <p className="font_xs fw_5 key font_grey">End Date</p>
                <p className="font_xs fw_5 value">
                  {customMoment(endDate, DATE_FORMAT, true, property?.offset)}
                </p>
              </div>

              <div className="c-row">
                {/* <p className="font_xs fw_5 key font_grey">Action Date</p>
                <p className="font_xs fw_5 value">
                  {moment(actionDate).format(DATE_TIME_FORMAT)}
                </p> */}
              </div>
            </div>

            <div className="more-data">
              {/* Action Date */}
              <div className="c-row">
                <p className="font_xs fw_5 key font_grey">Action Date</p>
                <p className="font_xs fw_5 value">
                  {moment(actionDate).format(DATE_TIME_FORMAT)}
                </p>
              </div>
            </div>

            {instalmentPlan?.cheque && (
              <p className="recieve-by-cheque-txt font_xs fw_6 font_yellow">
                Recieve payments by cheque
              </p>
            )}
          </div>
        </div>

        {depositData?.length > 0 && (
          <>
            <p className="py-2 font_l fw_5 font_blue">Deposit Breakdown</p>
            {Object.keys(depositData[0]).length !== 0 ? (
              <Table className="custom-table font_s" borderless responsive>
                {/* Table Heading */}
                <thead>
                  <tr>
                    <th>Description</th>
                    <th>Deposit Amount ({currency})</th>
                    <th>Due Date</th>
                  </tr>
                </thead>
                {/* Table Body */}
                <tbody>
                  {depositData?.map((item, index) => (
                    <tr key={index} className="install">
                      {/* Name */}
                      <td>{item?.description ? item?.description : "-"}</td>
                      {/* Amount */}
                      <td className="">
                        {item?.deposit_amount
                          ? parseInt(item?.deposit_amount)?.toLocaleString(
                              "en-US"
                            )
                          : "-"}
                      </td>
                      <td>
                        {instalmentPlan?.updatedAt &&
                        Object.keys(depositData[0]).length !== 0
                          ? customMoment(
                              instalmentPlan?.updatedAt,
                              DATE_FORMAT,
                              true,
                              property?.offset
                            )
                          : "-"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <div className=" text-center">
                <h3 className=" font_m font_yellow fw_6">No Deposits</h3>
              </div>
            )}
          </>
        )}
        {console.log("isDatePickerCalenderOpen ==> ", isDatePickerCalenderOpen)}
        {(!isOld || !isTransitioned) && (
          <div
            className={`rent-details-table-wrapper ${
              isDatePickerCalenderOpen ? "unset-responsive" : ""
            }`}
          >
            <p className="py-2 font_blue fw_5 font_l">Rent Installments</p>
            <Table
              className="custom-table font_s rent-detail-table"
              borderless
              responsive
              // responsive={isDatePickerCalenderOpen === false}
            >
              {/* Table Heading */}
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Amount ({currency})</th>
                  <th>Due Date</th>
                  <th>Action</th>
                  {/* {(canEdit || canAttachCheque) && <th>Action</th>} */}
                </tr>
              </thead>
              {/* Table Body */}
              <tbody>
                {data?.map((item, index) => (
                  <tr key={index} className="install">
                    {/* Name */}
                    <td>
                      {item?.name}
                      {(editIndex === null || editIndex !== index) && (
                        <>
                          {settings?.collect_vat_enabled &&
                            instalmentPlan.collect_instalment_vat && (
                              <p>VAT({settings?.collect_vat_value}%)</p>
                            )}

                          {settings?.collect_tax_enabled &&
                            instalmentPlan.collect_instalment_tax && (
                              <p>Tax({settings?.collect_tax_value}%)</p>
                            )}

                          {(settings?.collect_vat_enabled &&
                            instalmentPlan.collect_instalment_vat) ||
                          (settings?.collect_tax_enabled &&
                            instalmentPlan.collect_instalment_tax) ? (
                            <p>Total</p>
                          ) : (
                            ""
                          )}
                        </>
                      )}
                    </td>
                    {/* Amount */}
                    <td className="amount-column-wrapper">
                      <div className="amount-wrapper">
                        {editIndex === null || editIndex !== index ? (
                          <input
                            className={`amt-input transparent-input v_middle ${
                              editIndex === null || editIndex !== index
                                ? ""
                                : "edit-input"
                            }`}
                            type="text"
                            onChange={({ target: { value } }) =>
                              handleChange(value, index)
                            }
                            value={`${parseInt(
                              newInstallment[index]
                            )?.toLocaleString("en-US")}`}
                            readOnly={editIndex === null || editIndex !== index}
                          />
                        ) : (
                          <CustomCurrencyInput
                            value={`${newInstallment[index]}`}
                            onCustomChange={(value) =>
                              handleChange(value, index)
                            }
                            label={`${item?.name} Amount`}
                            currency={currency}
                          />
                        )}

                        {(editIndex === null || editIndex !== index) && (
                          <>
                            {settings?.collect_vat_enabled &&
                              instalmentPlan.collect_instalment_vat && (
                                <p>
                                  {newInstallment[index]
                                    ? (
                                        (parseFloat(newInstallment[index]) *
                                          settings?.collect_vat_value) /
                                        100
                                      )?.toLocaleString("en-US", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })
                                    : // .toFixed(2)
                                      // .toLocaleString("en-US")
                                      0}
                                </p>
                              )}
                            {settings?.collect_tax_enabled &&
                              instalmentPlan.collect_instalment_tax && (
                                <p>
                                  {newInstallment[index]
                                    ? (
                                        (parseFloat(newInstallment[index]) *
                                          settings?.collect_tax_value) /
                                        100
                                      )?.toLocaleString("en-US", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })
                                    : // .toFixed(2)
                                      // .toLocaleString("en-US")
                                      0}
                                </p>
                              )}
                            {(settings?.collect_vat_enabled &&
                              instalmentPlan.collect_instalment_vat) ||
                            (settings?.collect_tax_enabled &&
                              instalmentPlan.collect_instalment_tax) ? (
                              <p>
                                {getTotal(
                                  parseFloat(newInstallment[index]),
                                  instalmentPlan.collect_instalment_vat,
                                  instalmentPlan.collect_instalment_tax
                                )?.toLocaleString("en-US")}
                              </p>
                            ) : (
                              ""
                            )}
                          </>
                        )}
                      </div>
                    </td>
                    {/* Due Date */}
                    <td className="date-column-wrapper">
                      <div className="datepick-wrapper">
                        {editIndex === null || editIndex !== index ? (
                          moment(newDueDates[index]).format(DATE_FORMAT) !==
                          "Invalid date" ? (
                            moment(newDueDates[index]).format(DATE_FORMAT)
                          ) : (
                            moment(item?.due_date).format(DATE_FORMAT)
                          )
                        ) : (
                          <CustomDateInput
                            label={`${item?.name} Due Date`}
                            value={newDueDates[index]}
                            setIsCalenderOpen={setIsDatePickerCalenderOpen}
                            customOnchange={(date) =>
                              handleDateChange(date, index)
                            }
                            maxDateValue={new Date(instalmentPlan?.end_date)}
                            minDateValue={
                              index === 0
                                ? new Date(instalmentPlan.start_date)
                                : new Date(
                                    moment(newDueDates[index - 1]).add(1, "d")
                                  )
                            }
                          />
                        )}
                      </div>
                    </td>
                    {/* Action */}
                    {/* {(canEdit || canAttachCheque) && (
                    )} */}
                    <td>
                      {canEdit && (
                        <>
                          {editIndex !== null && editIndex === index ? (
                            <IconButton
                              onClick={() => setEditIndex(null)}
                              className="action-btn"
                            >
                              <img
                                className="h_100"
                                src={XGreen}
                                alt="Cancel"
                              />
                            </IconButton>
                          ) : (
                            <IconButton
                              onClick={() => setEditIndex(index)}
                              className="action-btn"
                            >
                              <img
                                className="h_100"
                                src={EditGreen}
                                alt="Edit"
                              />
                            </IconButton>
                          )}
                        </>
                      )}

                      {canAttachCheque && (
                        <>
                          {instalmentPlan?.cheque && (
                            <>
                              <IconButton
                                onClick={() => {
                                  setSelectedInstallment(item);
                                  setOpenChequeDetailModal(true);
                                }}
                                className="cheque-btn action-btn"
                              >
                                <img
                                  className="h_100"
                                  src={
                                    item?.cheque
                                      ? WarningRoundedBlue
                                      : ExcalmatoryYellow
                                  }
                                  alt=""
                                />
                              </IconButton>

                              {(instalmentPlan?.cheque || item?.cheque) && (
                                <IconButton
                                  onClick={() => {
                                    setSelectedInstallment(item);
                                    setShowCheque(true);
                                  }}
                                  className="cheque-btn action-btn"
                                >
                                  <img
                                    className="h_100"
                                    src={ChequeOutlineBlue}
                                    alt="View Cheque"
                                  />
                                </IconButton>
                              )}
                            </>
                          )}
                        </>
                      )}

                      {(isRenew || isTransitioned || isOld) &&
                        canViewCheque &&
                        !canAttachCheque && (
                          <>
                            {(instalmentPlan?.cheque || item?.cheque) && (
                              <IconButton
                                onClick={() => {
                                  setSelectedInstallment(item);
                                  setShowCheque(true);
                                }}
                                className="cheque-btn action-btn"
                              >
                                <img
                                  className="h_100"
                                  src={ChequeOutlineBlue}
                                  alt="View Cheque"
                                />
                              </IconButton>
                            )}
                          </>
                        )}

                      {
                        //isOld &&
                        // item?.cheque &&
                        item?.tenant_billing_invoice && (
                          <IconButton
                            onClick={() => handleView(item)}
                            className="cheque-btn action-btn"
                          >
                            <img
                              className="h_100"
                              src={DocumentGreen}
                              alt="View Cheque"
                            />
                          </IconButton>
                        )
                      }
                    </td>
                  </tr>
                ))}
                {/* <tr>
              <td>
                <p className="font_blue fw_6">Total</p>
              </td>
              <td>
                <p
                  className={`fw_6 ${
                    totalAmt.errorAmt ? "font_red" : "font_blue"
                  }`}
                >
                  {totalAmt.amount} {propertyData?.currency}
                </p>
              </td>
              <td></td>
            </tr> */}
              </tbody>
            </Table>
          </div>
        )}

        {(!isOld || !isTransitioned) && (
          <>
            {" "}
            <div className="installment-summ-wrapper">
              <p className="py-2 font_blue fw_5 font_l">Summary</p>
              <div className="custom-row">
                <p className="left font_s font_grey">
                  Total Instalments Amount :{" "}
                </p>
                <p className="right font_s font_blue">
                  {formatCurrency(totalRent + "")} {propertyData?.currency}
                </p>
              </div>
              {settings?.collect_tax_enabled &&
                instalmentPlan?.collect_instalment_tax && (
                  <div className="custom-row">
                    <p className="left font_s font_grey">
                      Total Instalments Tax :{" "}
                    </p>
                    <p className="right font_s">
                      {" "}
                      {formatCurrency(
                        (
                          parseFloat(totalRent) *
                          (parseFloat(settings?.collect_tax_value) / 100)
                        )
                          .toFixed(2)
                          .toLocaleString("en-US")
                      )}{" "}
                      {propertyData?.currency}
                    </p>
                  </div>
                )}
              {settings?.collect_vat_enabled &&
                instalmentPlan?.collect_instalment_vat && (
                  <div className="custom-row">
                    <p className="left font_s font_grey">
                      Total Instalments VAT :{" "}
                    </p>
                    <p className="right font_s">
                      {" "}
                      {formatCurrency(
                        (
                          parseFloat(totalRent) *
                          (parseFloat(settings?.collect_vat_value) / 100)
                        )
                          .toFixed(2)
                          .toLocaleString("en-US")
                      )}{" "}
                      {propertyData?.currency}
                    </p>
                  </div>
                )}
            </div>
            <div className="btn-full mt-2">
              {canEdit && canSend && (
                <>
                  {dataChanged ? (
                    <CustomButton onClick={onUpdate} size="l" className="">
                      Update Initial Agreement
                    </CustomButton>
                  ) : (
                    <CustomButton onClick={sendInstalmentsToTenant} size="l">
                      Send Initial Agreement
                    </CustomButton>
                  )}
                </>
              )}
            </div>
          </>
        )}
      </div>

      {/* <div className="installment-summ-wrapper bg_grey">
        <h3 className="font_xl">Installment Summary</h3>
        <div className="custom-row">
          <p className="left font_s font_grey">Total Instalments : </p>
          <p className="right font_s">
            {formatCurrency(totalRent + "")} {propertyData.currency}
          </p>
        </div>
        {settings?.collect_tax_enabled &&
          instalmentPlan.collect_deposit_tax && (
            <div className="custom-row">
              <p className="left font_s font_grey">Total Tax : </p>
              <p className="right font_s">
                {" "}
                {formatCurrency(
                  (
                    parseInt(totalRent) *
                    (parseInt(settings?.collect_tax_value) / 100)
                  ).toString()
                )}{" "}
                {propertyData.currency}
              </p>
            </div>
          )}
        {settings?.collect_vat_enabled &&
          instalmentPlan.collect_deposit_tax && (
            <div className="custom-row">
              <p className="left font_s font_grey">Total VAT : </p>
              <p className="right font_s">
                {" "}
                {formatCurrency(
                  (
                    parseInt(totalRent) *
                    (parseInt(settings?.collect_vat_value) / 100)
                  ).toString()
                )}{" "}
                {propertyData.currency}
              </p>
            </div>
          )}
      </div> */}

      <ChequeDetailModal
        amt={selectedInstallment?.amount}
        date={selectedInstallment?.due_date}
        showModal={openChequeDetailModal}
        onClose={() => setOpenChequeDetailModal(false)}
        onSubmit={onChequeUpdate}
        currency={currency}
      />

      <ShowChequeDetailModal
        data={selectedInstallment?.cheque || selectedInstallment}
        isCheque={selectedInstallment?.cheque && true}
        showModal={showCheque}
        onClose={() => setShowCheque(false)}
        setShowFile={setShowChequeFile}
        currency={currency}
      />

      <FileViewerModal
        show={showChequeFile}
        file={selectedInstallment?.cheque?.document.url}
        type={selectedInstallment?.cheque?.document.type}
        onClose={() => setShowChequeFile(false)}
      />
    </div>
  );
};
/* Component ends */

export default RentDetails;
