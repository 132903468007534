/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
/* Import local pages and component ends */

import "./style.scss";
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
import TextButton from "../../../ui/button/TextButton/TextButton";
import Report from "./local-component/Report";

/* Component starts */
const MoveOutRequest = (props) => {
  /* Props destructuring starts */
  const { contractId, unitId, contract_end_date, getTenantMoveOutDetail } =
    props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="tenant-move-out-request-wrapper">
      <div className="tenant-move-out-request-header">
        <h2 className="fw_6 font_xxl">Move Out</h2>
      </div>

      <div className="report-wrapper">
        <div className="detail-box bg_grey">
          <Report
            contractId={contractId}
            unitId={unitId}
            contract_end_date={contract_end_date}
            getTenantMoveOutDetail={getTenantMoveOutDetail}
          />
        </div>

        {/* <div className="button-wrapper text-center mt-4">
          <>
            <CustomButton type="submit" size="l">
              Submit
            </CustomButton>
            <TextButton
              type="button"
              // onClick={onCancel}
              className="font_m fw_6 mt-3"
            >
              Cancel
            </TextButton>
          </>
        </div> */}
      </div>
    </div>
  );
};
/* Component ends */

export default MoveOutRequest;
