/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import moment from "moment";
import GLOBAL from "../../../../../setup/constants/global";
import CustomStarRate from "../../../../ui/other/CustomStarRate/CustomStarRate";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
/* Import local pages and component ends */

/* Component starts */
const DetailSection8 = (props) => {
  /* Props destructuring starts */
  const { maintenance } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const createdAt = maintenance?.createdAt;
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="section section8">
      <div className="ticket-assign">
        <p className="left fw_5 font_m">Ticket Assign</p>
        <div className="assign">
          <p className="right font_m fw_3 font_grey">
            {maintenance?.assigned?.first_name}{" "}
            {maintenance?.assigned?.last_name}
          </p>
          <p className="right font_grey font_m fw_3">
            {maintenance?.assigned_role?.name}
          </p>
        </div>
      </div>

      {/* RATINGS */}
      {/* <div className="ratings">
        <p className="left fw_5 font_m">Rating</p>
        <div className="rate">
          <CustomStarRate size={20} stars={3.5} />
        </div>
      </div> */}

      {/* REVIEW */}
      {/* <div className="reviews">
        <p className="left fw_5 font_m">Review</p>
        <p className="assign fw_5 font_m font_grey">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard.
        </p>
      </div> */}

      <div className="black-container-sm fw_6 font_m bg_black flex-between">
        <p className="left font_grey">Request Date</p>
        <p className="right font_grey">
          {moment(createdAt).format(DATE_TIME_FORMAT)}
        </p>
      </div>
    </div>
  );
};
/* Component ends */

export default DetailSection8;
