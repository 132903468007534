/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { initialValues, validations } from "./helper";

/* Import configuration ends */

/* Import redux slices component starts */

/* Import redux slices component ends */

/* Import react bootstrap component starts */

/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */

import TextButton from "../../../ui/button/TextButton/TextButton";
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
import CustomSelectInput from "../../../ui/input/CustomSelectInput/CustomSelectInput";
import CustomTextInput from "../../../ui/input/CustomTextInput/CustomTextInput";

/* Import local pages and component ends */

// Styles
import "./style.scss";

/* Component starts */
const AddParking = (props) => {
  /* Props destructuring starts */
  const { onCancel } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [initialFormValues, setInitialFormValues] = useState(initialValues);

  //   const [showErrorModal, setShowErrorModal] = useState(false);
  //   const [showSuccessModal, setShowSuccessModal] = useState(false);
  //   const [modalDesc, setModalDesc] = useState("");

  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */

  const subPeriod = [
    { id: 1, name: "Weekly" },
    { id: 1, name: "Monthly" },
    { id: 1, name: "Yearly" },
  ];

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  const formValidation = (values) => {
    return validations(values);
  };

  const onSubmit = (values) => {
    const payload = {};

    // console.log(payload, "PAY");

    // // Backend response. Try, Catch
    // try {
    //   const result = await dispatch(addSecurityIncident(payload));

    //   // Handling success response
    //   switch (result.meta.requestStatus) {
    //     case GLOBAL.REJECTED:
    //       setModalDesc(result.payload);
    //       setShowErrorModal(true);
    //       break;

    //     case GLOBAL.FULFILLED:
    //       setModalDesc("Security Incident Created Successfully");
    //       setShowSuccessModal(true);
    //       break;
    //   }
    // } catch (error) {
    //   // Handling error response
    //   console.error(error.message);
    // }

    console.log(values, "VAL", payload);
  };

  /* Component function definition ends */

  /* Component useEffect starts */

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="add-parking-wrapper">
      <h2 className="heading text-center font_xxl mb-5">Add Parking</h2>

      <div className="add-new-form">
        <Formik
          onSubmit={onSubmit}
          initialValues={initialFormValues}
          validate={formValidation}
          validateOnBlur={true}
          enableReinitialize={true}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="form-wrapper">
                {/* Creating new Security Incident */}
                <>
                  {/* Input for Subject */}
                  <div className="form-group">
                    <CustomTextInput
                      name="total_parking"
                      label="Enter Total No. Of Parking Spots"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched.total_parking}
                      errors={errors.total_parking}
                      value={values.total_parking}
                    />
                  </div>

                  <div className="form-group">
                    <CustomTextInput
                      name="paid_parking"
                      label="Enter No. Of Paid Parking Spot"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched.paid_parking}
                      errors={errors.paid_parking}
                      value={values.paid_parking}
                    />
                  </div>

                  <div className="form-group-full parking-spot">
                    <p className="font_m font_grey fw_4">
                      No. Of Free Parking Spots <span>30</span>
                    </p>
                  </div>

                  <div className="form-group">
                    <CustomSelectInput
                      name="period"
                      label="Select Subscription Period"
                      handleBlur={handleBlur}
                      onChange={handleChange}
                      valueExtractor={(item) => item?.name}
                      // setValue={setSelectedLocation}
                      options={subPeriod}
                      // value={selectedLocation}
                      formikState={false}
                      emptyOption={false}
                    />
                  </div>

                  <div className="form-group">
                    <CustomTextInput
                      name="fee"
                      label="Subscription Fee"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched.fee}
                      errors={errors.fee}
                      value={values.fee}
                    />
                  </div>
                </>
              </div>

              <div className="button-wrapper text-center">
                <>
                  <CustomButton type="submit" size="l">
                    Add
                  </CustomButton>
                  <TextButton
                    type="button"
                    onClick={onCancel}
                    className="font_m fw_6"
                  >
                    Cancel
                  </TextButton>
                </>
              </div>
            </form>
          )}
        </Formik>
      </div>

      {/* Success Modal */}
      {/* <SuccessModal
        showModal={showSuccessModal}
        description={modalDesc}
        title="Successfully Created"
        onClose={() => {
          onSuccess();
          setShowSuccessModal(false);
        }}
      /> */}

      {/* Error Modal */}
      {/* <ErrorModal
        showModal={showErrorModal}
        description={modalDesc}
        title="Error"
        onClose={() => setShowErrorModal(false)}
      /> */}
    </div>
  );
};
/* Component ends */

export default AddParking;
