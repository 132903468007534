/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Formik } from "formik";
import {
  disabilityInitVal,
  disabilityValidation,
  disabilityFormPrefill,
} from "./helper";
import GLOBAL from "../../../../setup/constants/global";
/* Import configuration ends */

/* Import redux slices component starts */
import { updateTenantApplication } from "../../../../setup/store/slices/tenantSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomRadioInput from "../../../ui/input/CustomRadioInput/CustomRadioInput";
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
import TextButton from "../../../ui/button/TextButton/TextButton";
import SuccessModal from "../../../ui/modal/SuccessModal/SuccessModal";
import ErrorModal from "../../../ui/modal/ErrorModal/ErrorModal";
/* Import local pages and component ends */

/* Component starts */
const Disability = (props) => {
  /* Props destructuring starts */
  const { applicationId, data, canCancelApplication = false } = props;

  const {
    update,
    onNext,
    onCancelStep = null,
    fetchApplication = null,
    onCancelApplication,
    onUpdatingInfo = null,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [initialFormValues, setInitialFormValues] = useState(disabilityInitVal);
  const [message, setMessage] = useState("");
  const [showErrModal, setShowErrModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // On adding disability
  const onSubmit = async (values) => {
    let strToBool;

    if (values.disability === "1") {
      strToBool = true;
    } else if (values.disability === "0") {
      strToBool = false;
    }

    // Creating or collecting payload data to be sent
    const payload = {
      applicationId,
      type: "disability",
      disability: {
        disability: strToBool,
      },
    };

    // Backend response. Try, Catch
    try {
      const result = await update(payload);
      console.log(result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setMessage(result.payload);
          setShowErrModal(true);
          break;

        case GLOBAL.FULFILLED:
          onUpdatingInfo && onUpdatingInfo();
          fetchApplication && fetchApplication();
          setMessage(result.payload.message);
          setShowSuccessModal(true);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // Form validations
  const formValidation = (values) => {
    return disabilityValidation(values);
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  // On load of disability previous data
  useEffect(() => {
    if (data) {
      disabilityFormPrefill(data, setInitialFormValues);
    }
  }, [data]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="disability container_sm">
      <h2 className="heading text-center font_xxl mb-5">Disability</h2>

      <Formik
        onSubmit={onSubmit}
        initialValues={initialFormValues}
        validate={formValidation}
        validateOnBlur={true}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            {/* Form Fields */}
            <div className="form-wrapper form-wrapper-flex">
              {/* Input for Disability */}
              <div className="form-group form-group-full">
                <CustomRadioInput
                  isFormField={true}
                  name="disability"
                  mainLabel="Do you or any of your family members have any disability ?"
                  buttonLabelArray={["Yes", "No"]}
                  data={["1", "0"]}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  touched={touched.disability}
                  errors={errors.disability}
                  value={values.disability}
                  className="d_block"
                  mainLabelClass="font_m fw_5"
                  buttonContainerClass="radio-btn-flex"
                  radioButtonClass="radio-button"
                  buttonLabelClass="font_s fw_5"
                />
              </div>
            </div>

            {/* Submit and Cancel */}
            <div className="button-wrapper text-center">
              <CustomButton type="submit" size="l">
                Next
              </CustomButton>

              {onCancelStep && (
                <TextButton
                  type="button"
                  onClick={onCancelStep}
                  className="font_m fw_6"
                >
                  Back
                </TextButton>
              )}
            </div>
          </form>
        )}
      </Formik>

      {canCancelApplication && (
        <div className="cancel-application-btn-wrapper mt-4">
          <CustomButton
            className="cancel-application-btn"
            variant="red"
            size="l"
            onClick={onCancelApplication}
          >
            Cancel Application
          </CustomButton>
        </div>

        // <div className="cancel-application-wrapper bg_grey">
        //   <p className="font_m font_yellow fw_5">
        //     I would like to cancel the onboarding application process. I am
        //     aware that after cancelling the application I won't be able to undo
        //     my action.
        //   </p>
        //   <div className="cancel-application-btn-wrapper">
        //     <CustomButton
        //       className="cancel-application-btn"
        //       variant="red"
        //       size="m"
        //       onClick={onCancelApplication}
        //     >
        //       Cancel Application
        //     </CustomButton>
        //   </div>
        // </div>
      )}

      {/* Modals */}
      {/* Success Modal */}
      <SuccessModal
        showModal={showSuccessModal}
        description={message}
        title="Success"
        onClose={() => onNext()}
      />

      {/* Error Modal */}
      <ErrorModal
        showModal={showErrModal}
        description={message}
        title="Error"
        onClose={() => setShowErrModal(false)}
      />
    </div>
  );
};
/* Component ends */

const mapDispatchToProps = (dispatch) => {
  return {
    update: bindActionCreators(updateTenantApplication, dispatch),
  };
};

export default connect(null, mapDispatchToProps)(Disability);
