/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { skipLink, skipLink2, renderKey } from "./helper";
/* Import configuration ends */

/* Import redux slices component starts */

/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const TenantBreadCrumb = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */

  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */

  // const { state } = useLocation();
  const location = useLocation();
  const pathSegments = location?.pathname
    ?.split("/")
    ?.filter((segment) => segment !== ""); // Split path segments

  const pathSegmentsPrev = location?.state?.pathname
    ?.split("/")
    ?.filter((segment) => segment !== ""); // Split path segments
  console.log(pathSegments, "SEG", pathSegmentsPrev);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const homeCrumb = ["dashboard", "annoucements", "advertising"];
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  /* Component function definition ends */

  /* Component useEffect starts */

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="bread_cumb_wrapper container_lg">
      <ul className="custom-bread-crumb">
        {!pathSegments?.includes("home") ? (
          <>
            <li>
              <Link className="font_m fw_5" to="/">
                Home
              </Link>
            </li>
          </>
        ) : homeCrumb?.some((i) => pathSegments.includes(i)) ? (
          <>
            <li>
              <Link className="font_m fw_5" to="/">
                Home
              </Link>
            </li>
          </>
        ) : (
          ""
        )}
        {pathSegments?.map((segment, index, array) => {
          // Construct the path for each breadcrumb item
          if (skipLink(segment) && skipLink2(segment, index, array)) {
            const breadcrumbPath = `/${pathSegments
              .slice(0, index + 1)
              .join("/")}`;
            return (
              <li key={index}>
                <span className="seperator">&gt;</span>
                <Link className="font_m fw_5 link-color" to={breadcrumbPath}>
                  {renderKey(segment, index, array)}
                </Link>
              </li>
            );
          }
        })}

        {/* {location?.state !== null && location?.state?.pathname ? (
          <>
            {pathSegmentsPrev?.map((segment, index, array) => {
              // Construct the path for each breadcrumb item
              if (skipLink(segment, index, array)) {
                const breadcrumbPath = `/${pathSegmentsPrev
                  .slice(0, index + 1)
                  .join("/")}`;
                return (
                  <li key={index}>
                    <span className="seperator">&gt;</span>
                    <Link
                      className="font_m fw_5 link-color"
                      to={breadcrumbPath}
                    >
                      {renderKey(segment, index, array)}
                    </Link>
                  </li>
                );
              }
            })}
          </>
        ) : (
          <>
            {pathSegments?.map((segment, index, array) => {
              // Construct the path for each breadcrumb item
              if (skipLink(segment, index, array)) {
                const breadcrumbPath = `/${pathSegments
                  .slice(0, index + 1)
                  .join("/")}`;

                return (
                  <li key={index}>
                    <span className="seperator">&gt;</span>
                    <Link
                      className="font_m fw_5 link-color"
                      to={breadcrumbPath}
                    >
                      {renderKey(segment, index, array)}
                    </Link>
                  </li>
                );
              }
            })}
          </>
        )} */}
      </ul>
    </div>
  );
};
/* Component ends */

export default TenantBreadCrumb;
