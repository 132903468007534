/*
 * Slice to handle OCCUPANCY DASHBOARD Module
 */

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_URL } from "../../config/devKeys";
import { setLoading } from "./unpersistedSlice";

const initialState = {
  occupancyList: [],
  propertyOccupancyList: [],
  communityOccupancyList: [],
  searchedOccupancyList: [],
  // annoucement: null,
};

// FETCH OCCUPANCY DASHBOARD LIST - Client Side (HOME)
export const fetchOccupancyList = createAsyncThunk(
  "dashboard_home/dashboard_occupancy_listData",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(
        `${API_URL}/dashboard/dashboard_occupancy_listData`,
        {
          method: "POST",
          headers: headers,
        }
      );

      const data = await response.json();

      dispatch(setLoading(false));

      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// FETCH OCCUPANCY DASHBOARD LIST - Client Side  (Property_ID)
export const fetchOccupancyListBasedProperty = createAsyncThunk(
  "dashboard_property/dashboard_occupancy_listData",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(
        `${API_URL}/dashboard/dashboard_occupancy_listData`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            property_id: payload?.property_id,
          }),
        }
      );

      const data = await response.json();
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// FETCH OCCUPANCY DASHBOARD LIST - Client Side  (Community_ID)
export const fetchOccupancyListBasedCommunity = createAsyncThunk(
  "dashboard_community/dashboard_occupancy_listData",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(
        API_URL + `/dashboard/dashboard_occupancy_listData`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            community_id: payload?.community_id,
          }),
        }
      );

      const data = await response.json();

      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// FETCH SEARCHED OCCUPANCY DASHBOARD LIST - Client Side (HOME)
export const fetchSearchedOccupancyList = createAsyncThunk(
  "dashboard_search/dashboard_occupancy_listData",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(
        `${API_URL}/dashboard/dashboard_occupancy_listData?searchKey=${payload.searchKey}&start_date=${payload.start_date}&end_date=${payload.end_date}`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            property_id: payload?.property_id,
            community_id: payload?.community_id,
          }),
        }
      );

      const data = await response.json();

      dispatch(setLoading(false));

      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// FETCH ANNOUCEMENT DATA BASED ON ID  - (ANNOUCEMENT_ID)
// export const fetchAnnoucementById = createAsyncThunk(
//   "announcement/get_annoucement_by_id",
//   async (payload, { rejectWithValue, getState, dispatch }) => {
//     try {
//       dispatch(setLoading(true));
//       const headers = {
//         Authorization: `Bearer ${localStorage.getItem("user_token")}`,
//         "Content-Type": "application/json",
//       };

//       const response = await fetch(
//         `${API_URL}/announcement/get_annoucement_by_id?id=${payload}`,
//         {
//           method: "GET",
//           headers: headers,
//         }
//       );

//       const data = await response.json();

//       dispatch(setLoading(false));

//       if (data.success) {
//         return data.data;
//       } else {
//         return rejectWithValue(data.error.message);
//       }
//     } catch (error) {
//       dispatch(setLoading(false));
//       return rejectWithValue(error?.response);
//     }
//   }
// );

export const occupancyDashboardSlice = createSlice({
  name: "occupancy",
  initialState,
  reducers: {
    clearSearchOccupancy: (state, action) => {
      state.occupancyList = [];
    },
  },
  extraReducers: {
    [fetchOccupancyList.rejected]: (state, action) => {
      state.occupancyList = [];
    },
    [fetchOccupancyList.fulfilled]: (state, action) => {
      state.occupancyList = action.payload;
    },
    [fetchOccupancyListBasedProperty.rejected]: (state, action) => {
      state.propertyOccupancyList = [];
    },
    [fetchOccupancyListBasedProperty.fulfilled]: (state, action) => {
      state.propertyOccupancyList = action.payload;
    },
    [fetchOccupancyListBasedCommunity.rejected]: (state, action) => {
      state.communityOccupancyList = [];
    },
    [fetchOccupancyListBasedCommunity.fulfilled]: (state, action) => {
      state.communityOccupancyList = action.payload;
    },
    [fetchSearchedOccupancyList.rejected]: (state, action) => {
      state.searchedOccupancyList = [];
    },
    [fetchSearchedOccupancyList.fulfilled]: (state, action) => {
      state.searchedOccupancyList = action.payload;
    },
  },
});

// To Get The Occupancy List For Home
export const getOccupancyList = (state) => state.occupancy.occupancyList;

// To Get The Occupancy List For Property
export const getPropertyOccupancyList = (state) =>
  state.occupancy.propertyOccupancyList;

// To Get The Occupancy List For Community
export const getCommunityOccupancyList = (state) =>
  state.occupancy.communityOccupancyList;

// To Get The SEARCH Occupancy List For Community
export const getSeacrhedOccupancyList = (state) =>
  state.occupancy.searchedOccupancyList;

export const { clearSearchOccupancy } = occupancyDashboardSlice.actions;

// // To Get The Annoucement Based On ID
// export const getAnnoucementById = (state) => state.annoucement.annoucement;

export default occupancyDashboardSlice.reducer;
