/**
 *
 */

/* Import configuration starts */
import React, { useEffect, useState } from "react";
import { detect } from "detect-browser";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
/* Import local pages and component ends */

/* Component starts */
const useBrowserInfo = () => {
  /* Props destructuring starts */
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [browserInfo, setBrowserInfo] = useState({ name: "", version: "" });
  const [loaded, setLoaded] = useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    const browser = detect();
    setBrowserInfo({
      name: browser.name,
      version: browser.version,
    });
    setLoaded(true);
  }, []);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return { info: browserInfo, loaded };
};
/* Component ends */

export default useBrowserInfo;
