import * as zxcvbn from "zxcvbn";
export function minMaxLength(text, minLength, maxLength) {
  let result = !text || text.length < minLength;
  if (maxLength) result = result || text.length < minLength;
  return result;
}

export function validEmail(text) {
  const regex = RegExp(
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
  );

  return regex.test(text);
}

export function validatePassword(val) {
  const regex = RegExp(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{1,4}$)/
  );
  return regex.test(val);
}

export function validateAtleastOneLowercase(val) {
  const regex = RegExp(/(?=.*[a-z])/);
  return regex.test(val);
}

export function validateAtleastOneUppercase(val) {
  const regex = RegExp(/(?=.*[A-Z])/);
  return regex.test(val);
}

export function validateAtleastOneNumber(val) {
  const regex = RegExp(/(?=.*[0-9])/);
  return regex.test(val);
}

export function validateAtleastOneSpecialChar(val) {
  const regex = RegExp(/(?=.*[!@#\$%\^&\*])/);
  return regex.test(val);
}

export function containsSpecialChar(val) {
  const regex = RegExp(/^(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?/~\\-]).*$/);
  return regex.test(val);
}

let registeredUsers = ["ravi@kiran.com", "mail@myblog.in", "contact@lucky.com"];

export function userExists(email) {
  return new Promise((resolve) => {
    setTimeout(() => {
      if (registeredUsers.findIndex((u) => u === email) !== -1) {
        resolve(true);
      } else {
        resolve(false);
      }
    });
  });
}
export function passwordStrength(text) {
  let result = zxcvbn(text);
  return result.score < 3;
}
