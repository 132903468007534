import React from "react";

/* Import configuration starts */
// import { Link, Navigate } from "react-router-dom";
// import { useSelector, useDispatch } from "react-redux";
/* Import configuration ends */

/* Import redux slices starts */
// import { fetchUser, getUser } from "../../setup/store/slices/authSlice";
// import { getAuthLoading } from "../../setup/store/slices/unpersistedSlice";
/* Import redux slices ends */

/* Import local pages and component starts */
import TestForm from "./local-components/TestForm";
/* Import local pages and component ends */

/* Import image and SVG starts */
import { LogoWhite } from "../../setup/constants/images";
/* Import image and SVG ends */

import "./style.scss";

/* Component starts */
const AddTestForm = () => {
  /* Component states declaration starts */
  /* Component states declaration ends */

  /* Other hooks declaration starts */
  //   const dispatch = useDispatch();
  // Redux Selector
  //   const user = useSelector(getUser);
  //   const authLoading = useSelector(getAuthLoading);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  //   useEffect(() => {
  //     dispatch(fetchUser());
  //   }, []);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  // If user is logged in then navigating back
  //   if (user) {
  //     return <Navigate to="/" />;
  //   }

  return (
    <div className="sign-up-swrapper">
      <div className="image"></div>
      <div className="form-wrapper">
        <div className="logo-wrapper">
          <img src={LogoWhite} alt="Logo" />
        </div>
        <TestForm />
      </div>
    </div>
  );
};
/* Component ends */

export default AddTestForm;
