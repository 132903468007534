import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// Replace this firebaseConfig object with the congurations for the project you created on your firebase console.
var firebaseConfig = {
  apiKey: "AIzaSyAQAlEZ8uEX2nT9K8Qi4SSHHylRtJy2Npk",
  authDomain: "dgrnte.firebaseapp.com",
  projectId: "dgrnte",
  storageBucket: "dgrnte.appspot.com",
  messagingSenderId: "244706501446",
  appId: "1:244706501446:web:07f37bdbcca2e773f1fb47",
  measurementId: "G-SFSP4HNGCY",
};

initializeApp(firebaseConfig);
const messaging = getMessaging();

export const requestForToken = () => {
  console.log("I am requestForToken Function");
  return getToken(messaging, {
    vapidKey:
      "BM23UkY0gQyRIJ-gkqciFGSgPqOj5iQ3gM7wFyss627g9afc4E_Z1dbmmLU49Bctnw7_owXNzKp7AXQ1e2W0MAo",
  })
    .then((currentToken) => {
      if (currentToken) {
        console.log("current token for client: ", currentToken);
        // Perform any other neccessary action with the token
        return currentToken;
      } else {
        // Show permission request UI
        console.log(
          "No registration token available. Request permission to generate one."
        );
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    console.log("In message recieve");
    onMessage(messaging, (payload) => {
      console.log("payload ====", payload);
      resolve(payload);
    });
  });
