/**
 *
 */

import React, { useState, useEffect, useRef } from "react";

/* Import configuration starts */
import { Formik, FieldArray } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  addCommunicationDetail,
  fieldPropExtractor,
  fieldValue,
  initialValues,
  removeCommunicationDetail,
  validation,
  validations,
} from "./helper";
/* Import configuration ends */

/* Import redux slices component starts */

/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */

import CustomTextInput from "../../../ui/input/CustomTextInput/CustomTextInput";
import CustomTextArea from "../../../ui/input/CustomTextArea/CustomTextArea";
import CustomDateTimeInput from "../../../ui/input/CustomDateTimeInput/CustomDateTimeInput";
import CustomDateInput from "../../../ui/input/CustomDateInput/CustomDateInput";
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
import TextButton from "../../../ui/button/TextButton/TextButton";
/* Import local pages and component ends */

// Styles
import "./style.scss";
import CustomRadioInput from "../../../ui/input/CustomRadioInput/CustomRadioInput";
import CustomSelectInput from "../../../ui/input/CustomSelectInput/CustomSelectInput";
import CustomFileInput from "../../../ui/input/CustomFileInput/CustomFileInput";
import CustomCheckInput from "../../../ui/input/CustomCheckInput/CustomCheckInput";

/* Component starts */
const AddCommunication = (props) => {
  /* Props destructuring starts */
  const { onSuccess, onCancel } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [formValue, setFormValue] = useState(initialValues);
  // const [communicationDetails, setCommunicationDetails] = useState([0]);

  //   const [selectedWeekDay, setSelectedWeekDay] = useState();
  const [selectedMedian, setSelectedMedian] = useState();
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const formRef = useRef();
  const uploadDoc = useRef(null);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */

  const frequencyList = [
    { id: 1, frequency: "Hourly" },
    { id: 2, frequency: "Daily" },
    { id: 3, frequency: "Weekly" },
    { id: 4, frequency: "Monthly" },
  ];

  // On adding photo field
  const onAddPhoto = (callBackPush) => {
    callBackPush(null);
  };

  // On removing last photo field
  const onRemovePhoto = (callBackPop) => {
    callBackPop();
  };

  // Form validations
  const formValidation = (values) => {
    return validations(values);
  };

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  const onSubmit = async (values) => {
    // Backend response. Try, Catch
    // try {
    //   const result = await dispatch(addSecurityIncident(payload));
    //   console.log(result, "ADD_SCF");
    //   // Handling success response
    //   switch (result.meta.requestStatus) {
    //     case GLOBAL.REJECTED:
    //       setModalDesc(result.payload);
    //       setShowErrorModal(true);
    //       break;
    //     case GLOBAL.FULFILLED:
    //       setModalDesc("Security Incident Created Successfully");
    //       setShowSuccessModal(true);
    //       break;
    //   }
    // } catch (error) {
    //   // Handling error response
    //   console.error(error.message);
    // }
  };

  /* Component function definition ends */

  /* Component useEffect starts */

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="add-communication-module-wrapper">
      <h2 className="heading text-center font_xxl mb-5">
        Internal Communication Form
      </h2>

      <div className="add-new-form">
        <Formik
          onSubmit={onSubmit}
          initialValues={formValue}
          validate={formValidation}
          validateOnBlur={true}
          enableReinitialize={true}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="form-wrapper">
                {/* Creating new Routine Task */}

                <div className="form-group">
                  <CustomSelectInput
                    name="manager"
                    label="Select Manager"
                    //   handleBlur={handleBlur}
                    //   handleChange={handleChange}
                    //   valueExtractor={(item) =>
                    //     //item?.unit_number
                    //     `${item?.unit_name} - ${item?.tenant_name}`
                    //   }
                    //   setValue={setSelectedUnitNo}
                    //   options={tenantList}
                    //   value={selectedUnitNo}
                    formikState={false}
                    emptyOption={false}
                  />
                </div>

                {/* Input for Subject */}
                <div className="form-group">
                  <CustomTextInput
                    name="subject"
                    label="Enter Subject"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    touched={touched.subject}
                    errors={errors.subject}
                    value={values.subject}
                  />
                </div>

                {/* Text Area for Description */}
                <div className="form-group form-group-full">
                  <CustomTextArea
                    name="description"
                    label="Enter Description"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    value={values.description}
                    errors={errors.description}
                    touched={touched.description}
                    required={true}
                  />
                </div>

                {/* Input for ID Photo */}
                <FieldArray
                  name="photos"
                  render={({ push, pop }) => (
                    <div className="form-group">
                      {/* Upload Image List */}
                      {values?.photos?.map((item, index) => (
                        <div
                          key={index}
                          className={index >= 1 ? "add-image" : ""}
                        >
                          <CustomFileInput
                            name={`photos[${index}]`}
                            label="Photo"
                            setFieldValue={setFieldValue}
                            setFieldTouched={setFieldTouched}
                            value={values.photos[index]}
                            formikState={true}
                            touched={fieldPropExtractor(touched, index)}
                            validaterror={fieldPropExtractor(errors, index)}
                            required={false}
                          />
                        </div>
                      ))}

                      <div className="action-button text-center">
                        {values.photos[values.photos.length - 1] && (
                          <TextButton
                            variant="outline"
                            size="m"
                            className="txtBtn"
                            onClick={() => onAddPhoto(push)}
                          >
                            + Add More Images/Videos
                          </TextButton>
                        )}

                        {values.photos.length > 1 && (
                          <CustomButton
                            className="remove"
                            size="m"
                            onClick={() => onRemovePhoto(pop)}
                          >
                            Remove
                          </CustomButton>
                        )}
                      </div>
                    </div>
                  )}
                />
              </div>
              <div className="button-wrapper text-center">
                <>
                  <CustomButton type="submit" size="l">
                    Create
                  </CustomButton>
                  <TextButton
                    type="button"
                    onClick={onCancel}
                    className="font_m fw_6"
                  >
                    Cancel
                  </TextButton>
                </>
              </div>
            </form>
          )}
        </Formik>
      </div>

      {/* Success Modal */}
      {/* <SuccessModal
        showModal={showSuccessModal}
        description={modalDesc}
        title="Successfully Created"
        onClose={() => {
          onSuccess();
          setShowSuccessModal(false);
        }}
      />

      {/* Error Modal 
      <ErrorModal
        showModal={showErrorModal}
        description={modalDesc}
        title="Error"
        onClose={() => setShowErrorModal(false)}
      /> */}
    </div>
  );
};
/* Component Ends */
export default AddCommunication;
