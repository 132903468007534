export const containerStyle = {
  width: "100%",
  minHeight: "70vh",
};

export const defaultCenter = {
  lat: -3.745,
  lng: -38.523,
};

export const GOODLE_MAP_API_KEY = "";
