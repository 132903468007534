export const logTitle = (item) => {
  let title;
  const action = item.action;
  const currentStatus = item.current_status;
  const logType = item.log_type;

  switch (action) {
    // Move out initiated by Tenant
    case "Move out initiated by Tenant":
      title = "Move out initiated by Tenant";
      break;

    // Move out ticket assigned Property Manager
    case "Move out ticket assigned Property Manager":
      title = "Move out ticket assigned Property Manager";
      break;

    // Move out ticket assigned Owner
    case "Move out ticket assigned Owner":
      title = "Move out ticket assigned Owner";
      break;

    // Move out ticket assigned Property Manager
    case "Approval Pending":
      title = "Document approval pending";
      break;

    // Docs Approved
    case "Docs Approved":
      title = "Waiting to recieve keys";
      break;

    // Docs specified
    case "Docs specified":
      title = "Docs specified";
      break;

    // Docs submission pending
    case "Docs submission pending":
      title = "Docs submission pending";
      break;

    // Keys recieved
    // case "Keys recieved":
    //   title = "Keys recieved, Maintenance inspection ticket created";
    //   break;

    default:
      title = action;
      break;
  }

  return title;
};

// PM can request for documents from Tenant
export const pmReqDoc = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;

  if (
    action === "Move out ticket assigned Property Manager" ||
    action === "Move out ticket assigned Owner"
  ) {
    return true;
  }
};

export const canRecieveKeys = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;

  if (currentStatus === "keys_handover_left") {
    return true;
  }
};

export const canSkipDocSubmission = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  if (
    currentStatus === "Docs submission pending" ||
    currentStatus === "Approval Pending"
  ) {
    return true;
  }
};

export const canGoToMaintenance = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  if (currentStatus === "URIC phase 1 initiated") {
    return true;
  }
};

export const canSkipKeyRecieve = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  if (currentStatus === "keys_handover_left") {
    return true;
  }
};

export const pmReviewClearence = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;

  if (currentStatus === "pending_clearance_approval") {
    return true;
  }
};

export const pmViewClearenceDoc = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;

  if (currentStatus === "Issued Clearance certificate") {
    return true;
  }
};

export const canOwnerIssueClearence = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;

  if (action === "Owner clearance pending") {
    return true;
  }
};

export const canViewMoveOutMaintenance = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;

  if (action === "Keys collected") {
    return true;
  }
};

export const canViewClearenceDetails = (item, user) => {
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;

  if (action === "Issued Clearance certificate") {
    return true;
  }
};
