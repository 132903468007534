/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import GLOBAL from "../../setup/constants/global";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  getTenantContract,
  getTenantApplication,
} from "../../setup/store/slices/tenantSlice";
import { fetchContractRenewalDetails } from "../../setup/store/slices/contractSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import TenantContractRenewal from "../../components/module/Contracts/TenantContractRenewal/TenantContractRenewal";
/* Import local pages and component ends */

/* Component starts */
const TenantContractRenewalPage = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [sequence, setSequence] = useState([]);
  const [result, setResult] = useState(null);
  const [keyRender, setKeyRender] = useState(null);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const { applicationId, unitId } = useParams();
  const [searchParams] = useSearchParams();

  const application = useSelector(getTenantApplication);
  const contract = useSelector(getTenantContract);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const fetchContractDetails = async () => {
    // Backend response. Try, Catch
    try {
      const result = await dispatch(fetchContractRenewalDetails(applicationId));
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setSequence([]);
          setResult();
          break;

        case GLOBAL.FULFILLED:
          setSequence(result.payload.sequence);
          setResult(result.payload.data);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    fetchContractDetails();
  }, [applicationId]);

  useEffect(() => {
    if (sequence.length > 0) {
      const stepObj = {};
      sequence.forEach((item) => {
        switch (item) {
          case "request":
            stepObj[item] = "Request";
            break;

          case "initial_agreement":
            stepObj[item] = "Initial Agreement";
            break;

          case "tenant_info":
            stepObj[item] = "Tenant Info";
            break;

          case "installments":
            stepObj[item] = "Installments";
            break;
        }
      });
      setKeyRender(stepObj);
    } else {
      setKeyRender(null);
    }
  }, [sequence]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="">
      {result && sequence?.length > 0 && (
        <TenantContractRenewal
          application_id={applicationId}
          result={result}
          sequence={sequence}
          unit_id={unitId}
          keyRender={keyRender}
          fetchContractDetails={fetchContractDetails}
        />
      )}
    </div>
  );
};
/* Component ends */

export default TenantContractRenewalPage;
