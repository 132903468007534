/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import MUI component starts */
/* Import MUI component ends */

/* Import image and SVG starts */
import {
  BlankImagePlaceholder,
  LocationPinFilledGrey,
} from "../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const CommunityLicenseSliderItem = (props) => {
  /* Props destructuring starts */
  const { onSelectCommunity, community } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const city = community?.city;
  const state = community?.state;
  const country = community?.country;
  const name = community?.community_name;
  const image = community?.image?.url || BlankImagePlaceholder;
  const licenses = community?.allotted_licenses;
  const units = community?.unit_count;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div
      onClick={() => onSelectCommunity(community)}
      className="community-license-slider-item-component-wrapper"
    >
      <div
        className="image"
        style={{
          backgroundImage: `url(${image})`,
        }}
      ></div>

      <div className="details">
        <div className="name-license">
          <p className="name font_s fw_6">{name}</p>
          <p className="license font_xxs font_blue fw_5">
            Licenses: {licenses}
          </p>
        </div>

        <p className="address">
          <img
            className="location-pin v_middle"
            src={LocationPinFilledGrey}
            alt="Location Pin Icon"
          />
          <span className="font_grey font_xs v_middle">
            {city}; {state}; {country}
          </span>
        </p>

        <hr className="bg_lightgrey" />

        <p className="units font_xxs font_yellow">Units: {units}</p>
      </div>
    </div>
  );
};
/* Component ends */

export default CommunityLicenseSliderItem;
