/**
 *
 */

import React from "react";
import { useNavigate } from "react-router-dom";
/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Table } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { EyeFilledBlue } from "../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../../../ui/button/IconButton/IconButton";
/* Import local pages and component ends */

// Styles
import "./style.scss";

/* Component starts */
const ClosedCommunication = (props) => {
  /* Props destructuring starts */
  const {} = props;

  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */

  // Dummy Data
  const violationList = [];

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  // function getDate(date) {
  //   let theDate = new Date(Date.parse(date));

  //   return (
  //     theDate.toLocaleDateString() + " " + theDate.toLocaleTimeString("en-US")
  //   );
  // }

  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */

  return (
    <div className="custom-table-wrapper closed-communication-table-wrapper">
      <Table className="custom-table font_s" borderless responsive>
        {/* Table Heading */}
        <thead>
          <tr>
            <th>ID</th>
            <th>Subject</th>
            <th>Date & Time</th>
            <th>Initiated By</th>
            <th>Last Updated Date & Time</th>
            <th>Last Updated By</th>
            <th>Closed On</th>
            <th>Action</th>
          </tr>
        </thead>
        {/* Table Body */}
        <tbody>
          {violationList?.map((item) => (
            <tr key={item.id}>
              {/* ID */}
              <td>{item?.id}</td>

              {/* Subject */}
              <td>{item?.subject}</td>

              {/* Start Date */}
              <td>{item?.start_date}</td>

              {/* Assigned To */}
              <td>{item?.assigned_to}</td>

              {/* Next Due Date & Time */}
              <td>{item?.next_due_date}</td>

              {/* Assigned To */}
              <td>{item?.assigned_to}</td>

              {/* Next Due Date & Time */}
              <td>{item?.next_due_date}</td>

              {/* Actions */}
              <td className="text-start">
                <IconButton
                  // onClick={() =>
                  //   navigate("/property/routine-request/detail", {
                  //     state: { item },
                  //   })
                  // }
                  className="action-button"
                >
                  <img
                    src={EyeFilledBlue}
                    className="h_100"
                    alt="View Details"
                  />
                </IconButton>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};
/* Component Ends */
export default ClosedCommunication;
