/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useNavigate } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Table } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import {
  EditGreen,
  EditWhite,
  EyeFilledBlue,
} from "../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../../ui/button/IconButton/IconButton";

/* Import local pages and component ends */

/* Component starts */
const ParkingRequest = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  /* Other hooks declaration ends */

  // DUMMY DATA
  const newRequestList = [
    {
      id: "FM012",
      community: "Bog Yelloweyed Grass",
      property: "Lyda",
      unit: "173",
      tenant: "Lyda Mattis",
      fee: "Paid",
      status: "Occupied",
      type: "BMW",
    },
    {
      id: "FM052",
      community: "Asterothyrium Lichen",
      property: "Feliza",
      unit: "45",
      tenant: "Feliza Speer",
      fee: "Paid",
      status: "Vacant",
      type: "Scoda",
    },
    {
      id: "FM002",
      community: "Annual Ragweed",
      property: "Sandra",
      unit: "00",
      tenant: "Sandra Strethill",
      fee: "Free",
      status: "Vacant",
      type: "Truck",
    },
    {
      id: "FM442",
      community: "California Juniper",
      property: "Cyndia",
      unit: "14059",
      tenant: "Cyndia Batchelour",
      fee: "Paid",
      status: "Occupied",
      type: "MotorCycle",
    },
    {
      id: "FM112",
      community: "Basil",
      property: "Elaine",
      unit: "2271",
      tenant: "Elaine Whistlecraft",
      fee: "Free",
      status: "Occupied",
      type: "MotorBike",
    },
  ];

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="custom-table-wrapper newRequest-table">
      <Table className="custom-table font_s" borderless responsive>
        {/* Table Heading */}
        <thead>
          <tr>
            <th>Parking Spot No.</th>
            <th>Community</th>
            <th>Property</th>
            <th>Unit</th>
            <th>Tenant</th>
            <th>Fee</th>
            <th>Status</th>
            <th>Type</th>
            <th className="text-center">Action</th>
          </tr>
        </thead>
        {/* Table Body */}
        <tbody>
          {newRequestList?.map((item) => (
            <tr key={item.id}>
              {/* ID */}
              <td>{item?.id}</td>

              {/*  Communiyt */}
              <td>{item?.community}</td>

              {/* Property */}
              <td>{item?.property}</td>

              {/* Unit NUmber */}
              <td>{item?.unit}</td>

              {/* Tenant */}
              <td>{item?.tenant}</td>

              {/* Fee */}
              <td>{item?.fee}</td>

              {/* Status */}
              <td>{item?.status}</td>

              {/* Type */}
              <td>{item?.type}</td>

              {/* Actions */}
              <td className="text-center txt-nowrap">
                <IconButton
                  onClick={() =>
                    navigate("/property/manage-facilities/detail", {
                      state: { item },
                    })
                  }
                  className="action-button"
                >
                  <img
                    src={EyeFilledBlue}
                    className="h_100"
                    alt="View Details"
                  />
                </IconButton>

                <IconButton
                  onClick={() =>
                    navigate("/property/manage-facilities/edit-parking", {
                      state: { item },
                    })
                  }
                  className="action-button"
                >
                  <img src={EditGreen} className="h_100" alt="View Details" />
                </IconButton>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};
/* Component ends */

export default ParkingRequest;
