/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useDispatch } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../../../setup/store/slices/globalAlertSlice";

import { updatePropertySettings } from "../../../../../setup/store/slices/propertySlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import GLOBAL from "../../../../../setup/constants/global";
import SliderButton from "../../../../ui/button/SliderButton/SliderButton";
import CustomButton from "../../../../ui/button/CustomButton/CustomButton";

/* Import local pages and component ends */

/* Component starts */
const NotarizedContract = (props) => {
  /* Props destructuring starts */
  const { property_id, settings } = props;

  /* Props destructuring ends */

  /* Component states and useRef declaration starts */

  const [settingChanged, setSettingChanged] = useState(false);
  const [enabled, setEnabled] = useState(false);

  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  // On updating setting
  const onUpdate = async () => {
    // Creating or collecting payload data to be sent
    const payload = {
      notarized_contract: enabled,
      property_id,
    };

    // Backend response. Try, Catch
    try {
      const result = await dispatch(updatePropertySettings(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Settings Updated!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  /* Component function definition ends */

  /* Component useEffect starts */

  useEffect(() => {
    setSettingChanged(false);
    if (settings?.notarized_contract !== enabled) {
      setSettingChanged(true);
    }
  }, [enabled, settings]);

  useEffect(() => {
    if (settings) {
      setEnabled(settings?.notarized_contract);
    }
  }, [settings]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="tenant-application-fees option">
      {/* Label */}
      <div className="label">
        <p className="font_m fw_5">Notarized Contract</p>
      </div>

      {/* Setting */}
      <div className="setting">
        <div>
          <SliderButton
            // active={settings?.notarized_contract}
            active={enabled}
            // disabled={disabledStatusSwitch(item?.status)}
            onChange={() => setEnabled(!enabled)}
          />
        </div>

        {settingChanged && (
          <div className="save-btn-wrapper mt_2">
            <CustomButton onClick={onUpdate} className="save-btn" size="s">
              Save
            </CustomButton>
          </div>
        )}
      </div>
    </div>
  );
};
/* Component ends */

export default NotarizedContract;
