/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useParams } from "react-router-dom";
import { logTitle } from "../helper";
import moment from "moment";
import GLOBAL from "../../../../../setup/constants/global";
import { useDispatch } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import FileViewerModal from "../../../../ui/modal/FileViewerModal/FileViewerModal";
import Image from "../../../../ui/other/Image/Image";
/* Import local pages and component ends */

/* Component starts */
const KeyRejectDocLog = (props) => {
  /* Props destructuring starts */
  const {
    property,
    maintenance_id,
    maintenance,
    item,
    status,
    isT1,
    takeAction,
    user,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [file, setFile] = useState({});
  const [showFile, setShowFile] = useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const { propertyId } = useParams();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const name = item?.requester
    ? `${item?.requester?.first_name} ${item?.requester?.last_name}`
    : `${item.tenant.first_name} ${item.tenant.last_name}`;
  // const name = "sdad";
  const role = item?.requester_role?.name;
  const createdAt = item?.createdAt;
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  const comment = item?.comment;
  const initialURIC = maintenance?.initial_URIC;
  const finalUIRC = maintenance?.final_URIC;
  const maintenanceType = maintenance?.type;
  const uircP1InitItems = initialURIC?.items;
  const uircP1FinlItems = finalUIRC?.items;
  const unitCard = maintenance?.unit_card;
  const assignedToPM = currentStatus === "Ticket Assigned to PM";
  const p2URIC = maintenance?.phase2_URIC;

  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="time-line key-reject-doc-log">
      {/* Dot */}

      <div className="dot-wrapper">
        <i className="dot"></i>
        <div className="vl"></div>
      </div>

      <div className="detail-wrapper">
        {/* Title */}
        <h3 className="title font_m fw_6">{logTitle(item)}</h3>

        {/* Technician Name and Date */}
        <div className="black-container-sm fw_5 font_s bg_black flex-between">
          {/* Name */}
          <p className="left">
            <span className="font_grey">{role} </span>
            <span className="font_white"> {name}</span>
          </p>

          {/* Date and Time */}
          <p className="right font_grey">
            {moment(createdAt).format(DATE_TIME_FORMAT)}
          </p>
        </div>

        <div className="uirc-detail">
          {/* Details */}
          <div className="black-container-sm docs uirc-data bg_black">
            <h3 className="sub-heading fw_6 font_l font_white">
              Reason
            </h3>

            <div className="reason">
              <p className="font_s font_grey fw_4">{item?.comment}</p>
            </div>

            <h3 className="sub-heading fw_6 font_l font_white">
              Photos or Videos
            </h3>

            {item?.key_docs?.length === 0 && (
              <div className="no-doc">
                <p className="font_s font_yellow fw_6 text-center">
                  No photos or videos added !
                </p>
              </div>
            )}
            <div className="doc-list-wrapper">
              {item?.key_docs?.map((item, index) => (
                <div
                  className="document-wrapper"
                  onClick={() => {
                    setFile(item);
                    setShowFile(true);
                  }}
                >
                  <Image
                    src={item?.url}
                    alt="Media"
                    videoThumbnail={item?.type2 === "video"}
                  />
                </div>
              ))}
            </div>
          </div>

          {/* Footer */}
          <div className="log-footer"></div>
        </div>
      </div>

      {/* File Viewer Modal */}
      <FileViewerModal
        show={showFile}
        file={file.url}
        type={file.type}
        onClose={() => {
          setShowFile(false);
          setFile({});
        }}
      />
    </div>
  );
};
/* Component ends */

export default KeyRejectDocLog;
