/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Table } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { EyeFilledBlue } from "../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../../ui/button/IconButton/IconButton";
/* Import local pages and component ends */

/* Component starts */
const FacilitiesRequest = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */

  // DUMMY DATA
  const ongoingList = [
    {
      id: "UI001",
      fee: "Free",
      requester: "Orville Harrison",

      cost: "0",
    },
    {
      id: "UI002",
      fee: "Paid",
      requester: "Valencia Cuddehy",

      cost: "40",
    },
    {
      id: "UI003",
      fee: "Paid",
      requester: "Dunn Roffey",

      cost: "25",
    },
    {
      id: "UI004",
      fee: "Free",
      requester: "Heriberto Potier",

      cost: "0",
    },
    {
      id: "UI005",
      fee: "Paid",
      requester: "Gail Dolle",

      cost: "20",
    },
  ];

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="custom-table-wrapper completeRequest-table">
      <Table className="custom-table font_s" borderless responsive>
        {/* Table Heading */}
        <thead>
          <tr>
            <th>Facility Name</th>
            <th>Fee</th>
            <th>Description</th>
            <th>Term</th>
            <th>Cost</th>

            <th className="text-center">Action</th>
          </tr>
        </thead>
        {/* Table Body */}
        <tbody>
          {ongoingList?.map((item) => (
            <tr key={item.id}>
              {/* Facility Name */}
              <td>{item?.id}</td>

              {/*  Fee */}
              <td>{item?.fee}</td>

              {/* Description */}
              <td>{item?.requester}</td>

              {/* Term */}
              <td> Monthly</td>

              {/* Cost */}
              <td>{item?.cost} USD</td>

              {/* Actions */}
              <td className="text-center txt-nowrap">
                <IconButton
                  // onClick={() =>
                  //   navigate("/tenant/security/details", {
                  //     state: { item },
                  //   })
                  // }
                  className="action-button"
                >
                  <img
                    src={EyeFilledBlue}
                    className="h_100"
                    alt="View Details"
                  />
                </IconButton>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};
/* Component ends */

export default FacilitiesRequest;
