import React, { useState, useEffect, useRef } from "react";

/* Import configuration starts */
import { useDispatch, useSelector } from "react-redux";

import { Formik } from "formik";
/* Import configuration ends */

/* Import redux slices starts */
import { getUser } from "../../../setup/store/slices/authSlice";
import {
  fetchCountries,
  fetchStatesByCountryId,
  fetchCitiesByStateId,
  getCountries,
  getStates,
  getCities,
} from "../../../setup/store/slices/countrySlice";
import { fetchUserRoles } from "../../../setup/store/slices/masterSlice";

/* Import redux slices ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomPhoneInput from "../../../components/ui/input/CustomPhoneInput/CustomPhoneInput";
import CustomTextInput from "../../../components/ui/input/CustomTextInput/CustomTextInput";
import CustomSelectInput from "../../../components/ui/input/CustomSelectInput/CustomSelectInput";
import CustomButton from "../../../components/ui/button/CustomButton/CustomButton";
import CustomTextArea from "../../../components/ui/input/CustomTextArea/CustomTextArea";
import CustomCurrencyInput from "../../../components/ui/input/CustomCurrencyInput/CustomCurrencyInput";
import CustomDateInput from "../../../components/ui/input/CustomDateInput/CustomDateInput";
import CustomFileInput from "../../../components/ui/input/CustomFileInput/CustomFileInput";

/* Component starts */
const TestForm = () => {
  /* Props destructuring starts */
  /* Props destructuring ends */

  // Custom input field values
  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedState, setSelectedState] = useState();
  const [selectedCity, setSelectedCity] = useState();
  const [dob, setDOB] = useState(new Date());

  // useRefs declarations
  const photo = useRef(null);
  const customPhoneNum = useRef({ code: "", num: "" });
  //   const formikPasswordValidation = useRef(false);
  /* Component states and useRefs declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();

  // Redux selector
  const countries = useSelector(getCountries);
  const states = useSelector(getStates);
  const cities = useSelector(getCities);
  const user = useSelector(getUser);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const initialValues = {
    full_name: "",
    mobile: "",
    email: "",
    description: "",
    age: "",
    avail_bank_balance: "",
  };
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // Handling On Country code change in Phone field
  const onCountryCodeChanged = (country) => {
    const defaultCountry = countries.filter((c) => {
      return c.iso2.toLowerCase() == country.countryCode;
    })[0];
    setSelectedCountry(defaultCountry);
  };

  // Loading all countries
  const loadCountries = async () => {
    await dispatch(fetchCountries());
  };

  //Loading all states
  const loadStates = async () => {
    if (selectedCountry != null) {
      setSelectedState(null);
      setSelectedCity(null);
      await dispatch(
        fetchStatesByCountryId({ country_id: selectedCountry.id })
      );
    }
  };

  // Loading all cities
  const loadCities = async () => {
    if (selectedState != null) {
      setSelectedCity(null);
      await dispatch(fetchCitiesByStateId({ state_id: selectedState.id }));
    }
  };

  // Calculate Age Based On Date Of Birth
  const calculateAge = (dob) => {
    let birthDate = new Date(dob);

   // get difference from current date;
   let difference=Date.now() - birthDate.getTime(); 
   let  ageDate = new Date(difference); 
   let calculatedAge =   Math.abs(ageDate.getUTCFullYear() - 1970);
   return calculatedAge;
  }
  

  // Handling on signing up
  const onSignUp = async (values) => {
    // Creating or gathering payload data to be sent

    

    const payload = {
      ...values,
      full_name: values.full_name.trim(),
      email: values.email.toLowerCase(),
      dob: dob,
      city: selectedCity?.name || "",
      state: selectedState?.name || "",
      country: selectedCountry?.name || "",
      countrycode: customPhoneNum.current.code,
      mobile: customPhoneNum.current.num,
      avail_bank_balance: values.avail_bank_balance,
    };

    console.log(payload,'PAYLOAD');
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  // Loading all countries and fetching user roles initially
  useEffect(() => {
    loadCountries();
    dispatch(fetchUserRoles());
  }, []);

  // Setting selected country after loading countries
  useEffect(() => {
    if (countries.length > 0) {
      const defaultCountry = countries.filter(
        (country) => country.iso3 === "USA"
      )[0];
      setSelectedCountry(defaultCountry);
    }
  }, [countries]);

  // Loading states accoring to country selected
  useEffect(() => {
    loadStates();
  }, [selectedCountry]);

  // Setting selected state after loading state
  useEffect(() => {
    if (states.length > 0) {
      setSelectedState(states[0]);
    }
  }, [states]);

  // Loading cities accoring to state selected
  useEffect(() => {
    loadCities();
  }, [selectedState]);

  // Setting selected city after loading cities
  useEffect(() => {
    if (cities.length > 0) {
      setSelectedCity(cities[0]);
    }
  }, [cities]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div>
      {/* Sign up form */}
      <Formik
        onSubmit={onSignUp}
        initialValues={initialValues}
        validateOnBlur={true}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          handleBlur,
          touched,
          setFieldValue
        }) => (
          <form className="form" onSubmit={handleSubmit}>
            {/* Input for Full Name */}
            <div className="form-group">
              <CustomTextInput
                type="text"
                label="Full Name"
                handleBlur={handleBlur}
                handleChange={handleChange}
                value={values.full_name}
                name="full_name"
                touched={touched.full_name}
              />
            </div>

            {/* Input for Email Address */}
            <div className="form-group">
              <CustomTextInput
                type="text"
                label="Email Address"
                handleBlur={handleBlur}
                handleChange={handleChange}
                value={values.email}
                name="email"
                touched={touched.email}
              />
            </div>

            {/* Input for Phone Number */}
            <div className="form-group">
              <CustomPhoneInput
                name="mobile"
                label={"Phone Number"}
                customPhoneNum={customPhoneNum}
                handleChange={handleChange}
                handleBlur={handleBlur}
                onCountryCodeChanged={onCountryCodeChanged}
                value={values.mobile}
                countryCodeEditable={true}
                touched={touched.mobile}
              />
            </div>

            {/* Input for Date Of Birth */}
            <div className="form-group">
              <CustomDateInput
                label="Date Of Birth"
                value={dob}
                customOnchange={(date) => setDOB(date)}
              />
            </div>

            {/* Input for Age */}
            <div className="form-group">
              <CustomTextInput
                type="text"
                label="Age"
                handleBlur={handleBlur}
                handleChange={handleChange}
                value={values.age = calculateAge(dob)}
                name="age"
                touched={touched.age}
              />
            </div>

            {/* Input for Bank Balance */}
            <div className="form-group">
              <CustomCurrencyInput
                name="avail_bank_balance"
                label={`Bank Account Balance`}
                onChange={handleChange}
                setValue={setFieldValue}
                handleBlur={handleBlur}
                touched={touched.avail_bank_balance}
                value={values.avail_bank_balance}
                currency={'INR'}
              />
            </div>

            {/* Select field for Cities */}
            <div className="form-group">
              <CustomSelectInput
                name="city"
                label="City"
                handleBlur={handleBlur}
                onChange={handleChange}
                valueExtractor={(item) => item?.name}
                setValue={setSelectedCity}
                options={cities}
                value={selectedCity}
                formikState={false}
                emptyOption={false}
              />
            </div>

            {/* Select field for State */}
            <div className="form-group">
              <CustomSelectInput
                name="state"
                label="State"
                handleBlur={handleBlur}
                onChange={handleChange}
                valueExtractor={(item) => item?.name}
                setValue={setSelectedState}
                options={states}
                value={selectedState}
                formikState={false}
                emptyOption={false}
              />
            </div>

            {/* Select field for Countries */}
            <div className="form-group">
              <CustomSelectInput
                name="country"
                label="Country"
                handleBlur={handleBlur}
                onChange={handleChange}
                valueExtractor={(item) => item?.name}
                setValue={setSelectedCountry}
                options={countries}
                value={selectedCountry}
                formikState={false}
                emptyOption={false}
              />
            </div>

            {/* Input for Photo */}
            <div className="form-group">
              <CustomFileInput
                name="photo"
                label="Profile Photo"
                handleBlur={handleBlur}
                handleChange={handleChange}
                files={photo}
                touched={touched.photo}
                required={false}
                formikState={false}
              />
            </div>

            {/* Input for Descriptiom */}
            <div className="form-group text-area">
              <CustomTextArea
                name="description"
                label="Description"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.description}
                touched={touched.description}
                required={false}
              />
            </div>

            {/* Submit button  */}
            <div className="button-wrapper w_100">
              <CustomButton size="l" className="" type="submit">
                Submit Form
              </CustomButton>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};
/* Component ends */

export default TestForm;
