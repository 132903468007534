/**
 * @auther Abdul Ahad <aabdul@dgrnte.com>
 * Slice to handle severity
 */

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_URL } from "../../config/devKeys";
import axios from "../axios";

const initialState = {
  severityList: [],
};

export const fetchSeverityList = createAsyncThunk(
  "severity/list",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await axios.get(API_URL + "/severity/list");
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error?.response);
    }
  }
);

export const severitySlice = createSlice({
  name: "severity",
  initialState,
  extraReducers: {
    [fetchSeverityList.fulfilled]: (state, action) => {
      state.severityList = action.payload;
    },
  },
});

export const getSeverities = (state) => state.severity.severityList;

export default severitySlice.reducer;
