/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
import { setSelectedClientInvoice } from "../../../../setup/store/slices/unpersistedSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import ClientLicenseInvoiceListItem from "./sub-component/ClientLicenseInvoiceListItem";
import Pagination from "../../../ui/other/Pagination/Pagination";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const ClientLicenseInvoiceList = (props) => {
  /* Props destructuring starts */
  const { invoices } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItemsList = invoices?.slice(indexOfFirstItem, indexOfLastItem);
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // On selecting invoice
  const onInvoiceSelect = async (invoice) => {
    await dispatch(setSelectedClientInvoice(invoice));
    navigate(`${invoice._id}`);
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      <div className="invoice-list-component-wrapper">
        <h2 className="heading text-center font_xxl mb-5">Licenses Invoice</h2>

        <div className="invoice-list bg_grey">
          {currentItemsList?.map((item, index) => (
            <ClientLicenseInvoiceListItem
              onClick={() => onInvoiceSelect(item)}
              invoice={item}
              key={index}
            />
          ))}

          {currentItemsList?.length === 0 && (
            <div className=" text-center">
              <h3 className=" font_m font_yellow fw_6">No Data</h3>
            </div>
          )}

          {invoices?.length > 0 && (
            <Pagination
              itemsPerPage={itemsPerPage}
              totalItems={invoices?.length}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          )}
        </div>
      </div>
    </>
  );
};
/* Component ends */

export default ClientLicenseInvoiceList;
