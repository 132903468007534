/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */

import { useSelector } from "react-redux";

/* Import configuration ends */

/* Import redux slices component starts */
import { getUserTheme } from "../../../../setup/store/slices/authSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Modal } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { XGreen } from "../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../../../components/ui/button/IconButton/IconButton";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const TenantDetailModal = (props) => {
  /* Props destructuring starts */
  const { showModal, detail, size = "lg", onClose, user, type } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */

  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const userTheme = useSelector(getUserTheme);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */

  return (
    <Modal
      size={size}
      centered
      show={showModal}
      className="tenat-detail-view-modal-wrapper container_lg"
    >
      <Modal.Body
        //className="custom-modal-body"
        className={`custom-modal-body ${
          userTheme === "dark" ? "bg_darkTheme" : "bg_lightTheme"
        }`}
      >
        {/* Header */}
        <div className="close-button-wrapper">
          <IconButton onClick={onClose} className="close-button">
            <img className="h_100" src={XGreen} alt="Close" />
          </IconButton>

          <p
            className={`fw_5 font_m mb-2 ${
              userTheme !== "dark" && "font_blue"
            } font_white text-center`}
          >
            Tenant Detail
          </p>
        </div>

        {/* Body */}
        <div className="details-content">
          <div className="right">
            <div
              // className="wrapper bg_grey"
              className={`wrapper ${
                userTheme === "dark" ? "bg_grey" : "bg_lightThemeDetail"
              }`}
            >
              {/* Invoice Number */}

              <div className="custom_row">
                <p className="key font_grey font_s">Tenant Name</p>
                <p className="val font_s font_blue">{detail?.tenant}</p>
              </div>

              {/* Email */}

              <div className="custom_row">
                <p className="key font_grey font_s"> Email</p>
                <p
                  className={`val font_s ${
                    (userTheme === "light" || userTheme === "automatic") &&
                    "font_lightTheme"
                  } `}
                >
                  {detail?.tenant_email !== undefined
                    ? detail?.tenant_email
                    : ""}
                </p>
              </div>

              {/* Mobile */}

              <div className="custom_row">
                <p className="key font_grey font_s"> Mobile</p>
                <p
                  className={`val font_s ${
                    (userTheme === "light" || userTheme === "automatic") &&
                    "font_lightTheme"
                  } `}
                >
                  {detail?.tenant_mobile !== undefined
                    ? detail?.tenant_mobile
                    : "-"}
                </p>
              </div>

              {/* Profession */}

              <div className="custom_row">
                <p className="key font_grey font_s"> Profession</p>
                <p
                  className={`val font_s ${
                    (userTheme === "light" || userTheme === "automatic") &&
                    "font_lightTheme"
                  } `}
                >
                  {detail?.tenant_profession !== undefined
                    ? detail?.tenant_profession
                    : "-"}
                </p>
              </div>

              {/* Bussiness Name */}

              <div className="custom_row">
                <p className="key font_grey font_s"> Bussiness Name</p>
                <p
                  className={`val font_s ${
                    (userTheme === "light" || userTheme === "automatic") &&
                    "font_lightTheme"
                  } `}
                >
                  {detail?.tenant_business_name !== undefined
                    ? detail?.tenant_business_name
                    : "-"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
/* Component ends */

export default TenantDetailModal;
