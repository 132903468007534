/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  clientAccountDeactivation,
  clientAccountDeactivationInitiate,
  fetchTenant,
  fetchUser,
  getIsCLIENT,
  getUser,
} from "../../setup/store/slices/authSlice";
import { getIsTENANT } from "../../setup/store/slices/authSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import GeneralDetailsPhoto from "./local-components/GeneralDetailsPhoto";
import OtherDetailBox from "./local-components/OtherDetailBox";
import Header from "./local-components/Header";
import BreadCrumb from "../../components/ui/other/BreadCrumb/BreadCrumb";
/* Import local pages and component ends */

import "./style.scss";
import CustomButton from "../../components/ui/button/CustomButton/CustomButton";
import ConfirmationModal from "../../components/ui/modal/ConfirmationModal/ConfirmationModal";
import ConfirmationOTPModal from "../../components/ui/modal/ConfirmationOTPModal/ConfirmationOTPModal";
import SuccessModal from "../../components/ui/modal/SuccessModal/SuccessModal";
import ErrorModal from "../../components/ui/modal/ErrorModal/ErrorModal";
import GLOBAL from "../../setup/constants/global";

/* Component starts */
const ProfilePage = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [sessionId, setSessionId] = useState();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [modalDesc, setModalDesc] = useState("");
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Redux Selector
  const user = useSelector(getUser);
  const isTenant = useSelector(getIsTENANT);
  const isClient = useSelector(getIsCLIENT);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */
  console.log("User >", user);
  /* Component function definition starts */
  const deactivate = () => {
    setModalDesc(
      "Caution: This action will significantly affect the account. Suspending the account will result in the removal of all your teams and tenants from properties, cancellation of any active contracts or invoices. This action is irreversible. While the account is suspended, you will be unable to add teams or tenants. If you wish to onboard teams and tenants again, you must unsuspend the account."
    );
    setShowConfirmationModal(true);
  };

  // To Initiating the Account Deactivation
  const handleDeactivateAccount = async () => {
    // Backend response. Try, catch
    try {
      const result = await dispatch(clientAccountDeactivationInitiate());
      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setModalDesc(result.payload);
          setShowConfirmationModal(!showErrorModal);
          setShowErrorModal(true);
          break;
        case GLOBAL.FULFILLED:
          // setModalDesc("Enter the code sent to your email");
          setSessionId(result.payload.Session);
          setShowConfirmationModal(!showErrorModal);
          setShowOtpModal(true);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
    setShowConfirmationModal(!showConfirmationModal);
  };

  const handleClosedConfiramtion = () => {
    setShowConfirmationModal(false);
  };

  // On verifying otp for Account Deactivation
  const onVerify = async (otp) => {
    if (otp.length !== 4) return;

    // Collecting or creating payload data to be sent
    const payload = {
      OTP: otp,
      session: sessionId,
    };

    // // Backend response. Try, catch
    try {
      const result = await dispatch(clientAccountDeactivation(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setModalDesc(result.payload);
          setShowOtpModal(false);
          setShowErrorModal(true);
          break;

        case GLOBAL.FULFILLED:
          setModalDesc("Account has been marked suspended.");
          setShowOtpModal(false);
          setShowSuccessModal(true);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  /* Component function definition ends */

  /* Component useEffect starts */
  // Fetching user and user role list on initial load
  useEffect(() => {
    if (isTenant) {
      dispatch(fetchTenant());
    } else {
      dispatch(fetchUser());
    }
  }, [isTenant]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      {!isTenant && <BreadCrumb type="myProfile_page" />}
      <div className="profile-page-wrapper">
        <div className="header container_lg">
          <Header />
        </div>

        <div className="details container_sm">
          <h2 className="heading text-center font_xxl mb-5">My Profile</h2>
          <div className="detail-wrapper">
            <GeneralDetailsPhoto user={user} />
            <OtherDetailBox user={user} />

            {isClient && (
              <div className="deactive-btn-wrapper">
                <button onClick={() => deactivate()} className="deactive-btn">
                  Deactivate
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Modals */}
      {/* Confirmation Modal */}
      <ConfirmationModal
        showModal={showConfirmationModal}
        title="Account Suspension"
        description={modalDesc}
        onClose={() => handleClosedConfiramtion()}
        onConfirm={() => handleDeactivateAccount()}
      />

      {/* OTP Modal */}
      <ConfirmationOTPModal
        showModal={showOtpModal}
        onVerify={onVerify}
        title="Enter OTP"
        digits={4}
        description={modalDesc}
        onCancel={() => setShowOtpModal(false)}
      />

      {/* Success Modal */}
      <SuccessModal
        showModal={showSuccessModal}
        title="Successful"
        // title={
        //   !user?.is_account_suspended ? "Account Suspention" : "Enabled Account"
        // }
        description={modalDesc}
        onClose={() => navigate("/")}
      />

      {/* Error Modal */}
      <ErrorModal
        showModal={showErrorModal}
        title="Error"
        description={modalDesc}
        onClose={() => setShowErrorModal(false)}
      />
    </>
  );
};
/* Component ends */

export default ProfilePage;
