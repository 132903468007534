/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import Settings from "./sub-component/Settings";
import SLAs from "./sub-component/SLAs";
import Templates from "./sub-component/Templates";
/* Import local pages and component ends */

import "./style.scss";
import "./margin_spacing_style.scss";

/* Component starts */
const PropertySettings = (props) => {
  /* Props destructuring starts */
  const { user, settings, property, getPropertySettingsDetail } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [currentTab, setCurrentTab] = useState("settings");
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */
  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="property-setting-wrapper container_md">
      {/* <div className="setting-wrapper bg_grey">
        <T1 user={user} settings={settings} />
      </div> */}
      <div className="tab_list">
        <button
          className={`tab font_m ${currentTab === "settings" && "active"}`}
          onClick={() => setCurrentTab("settings")}
        >
          Settings
        </button>
        {property?.mgmt_model !== 1 && (
          <button
            className={`tab font_m ${currentTab === "template" && "active"}`}
            onClick={() => setCurrentTab("template")}
          >
            Template
          </button>
        )}
        {/* <button
          className={`tab font_m ${currentTab === "sla" && "active"}`}
          onClick={() => setCurrentTab("sla")}
        >
          SLA
        </button> */}
      </div>
      <div className="setting-wrapper">
        {currentTab === "settings" && (
          <Settings
            user={user}
            settings={settings}
            property={property}
            getPropertySettingsDetail={getPropertySettingsDetail}
          />
        )}
        {currentTab === "template" && (
          <Templates
            user={user}
            settings={settings}
            property={property}
            getPropertySettingsDetail={getPropertySettingsDetail}
          />
        )}
        {currentTab === "sla" && (
          <SLAs user={user} settings={settings} property={property} />
        )}
      </div>
    </div>
  );
};
/* Component ends */

export default PropertySettings;
