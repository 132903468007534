/**
 * This component is a Modal that is shown on success
 */

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
/* Import react bootstrap component starts */
import { Modal, Table } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

// Redux Slices Start
import { updatePropertyInvoice } from "../../../../../setup/store/slices/propertyInvoiceSlice";
import { updatePartyInvoice } from "../../../../../setup/store/slices/partyInvoiceSlice";
import {
  adjustFromDeposit,
  fetchActiveDepositInvoiceList,
  getActiveDepositInvoice,
  updateTenantInvoice,
} from "../../../../../setup/store/slices/invoiceSlice";
import { getUserTheme } from "../../../../../setup/store/slices/authSlice";
// Redux Slices End

/* Import local pages and component starts */
import CustomTextInput from "../../../../ui/input/CustomTextInput/CustomTextInput";
import CustomFileInput from "../../../../ui/input/CustomFileInput/CustomFileInput";
import CustomButton from "../../../../ui/button/CustomButton/CustomButton";
import TextButton from "../../../../ui/button/TextButton/TextButton";
import GLOBAL from "../../../../../setup/constants/global";
import ErrorModal from "../../../../ui/modal/ErrorModal/ErrorModal";
import SuccessModal from "../../../../ui/modal/SuccessModal/SuccessModal";
import CustomDateInput from "../../../../ui/input/CustomDateInput/CustomDateInput";
import CustomTextArea from "../../../../ui/input/CustomTextArea/CustomTextArea";
import CustomCurrencyInput from "../../../../ui/input/CustomCurrencyInput/CustomCurrencyInput";
import CustomSelectInput from "../../../../ui/input/CustomSelectInput/CustomSelectInput";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const PaymentModal = (props) => {
  /* Props destructuring starts */
  const {
    showModal,
    size = "lg",
    onClose,
    invoiceData,
    newType,
    property_id,
    afterPayment = null,
    moveOut = false,
    currency,
  } = props;
  /* Props destructuring ends */

  // useState
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [modalDesc, setModalDesc] = useState("");
  const [payMark, setPayMark] = useState();
  const [recpdate, setRecpdate] = useState(new Date());
  const [initialFormState, setInitialFormState] = useState({
    note: "",
    pay_amount: "",
    cash_amt: "",
    reciept_num: "",
    cheque_amt: "",
    cheque_num: "",
    issuer_name: "",
    account_number: "",
    bank_name: "",

    // WIRE
    receive_bank: "",
    sender_name: "",
    reference_number: "",
    wire_amount: "",

    // MONEY ORDER
    send_name: "",
    moneyOrder_amount: "",
    moneyOrder_number: "",

    // cashier check
    cashier_check_amopunt: "",
    cashier_check_number: "",
    cashier_check_bank: "",

    // POS
    pos_amount: "",
    transactionId: "",
  });
  const [selectedDeposit, setSelectedDeposit] = useState();

  // useRef
  const receipt = useRef(null);
  const dispatch = useDispatch();
  const userTheme = useSelector(getUserTheme);

  const depositList = useSelector(getActiveDepositInvoice);

  const pending_amount =
    invoiceData?.pending_amount !== 0 &&
    invoiceData?.pending_amount?.toLocaleString("en-US");

  // Payment Options
  const paymentMethods = [
    moveOut && {
      _id: 1,
      key: "deposit",
      name: "Deposit",
    },
    {
      _id: 2,
      key: "wire",
      name: "Wire",
    },
    {
      _id: 3,
      key: "moneyOrder",
      name: "Money Order",
    },
    {
      _id: 4,
      key: "cashier check",
      name: "Cashier's Cheque",
    },
    {
      _id: 5,
      key: "cash",
      name: "Cash",
    },
    {
      _id: 6,
      key: "cheque",
      name: "Cheque",
    },

    {
      _id: 7,
      key: "pos",
      name: "POS",
    },
    {
      _id: 8,
      key: "void",
      name: "Void Invoice",
    },
  ];

  // Handle Form Validations
  const formValidation = (values, paidVia) => {
    const error = {};

    if (values.note === "") {
      error.note = "Remarks is required";
    }
    // if (payMark === "paid") {
    switch (paidVia?.key) {
      case "cash":
        if (values.reciept_num === "") {
          error.reciept_num = "Reciept Number is required";
        }
        if (values.pay_amount === "") {
          error.pay_amount = "Amount is required";
        }
        if (values.pay_amount !== "") {
          if (values.pay_amount < invoiceData?.pending_amount) {
            error.pay_amount = "Invalid amount";
          }
          if (
            values.pay_amount >
            (invoiceData?.pending_amount !== undefined
              ? invoiceData?.pending_amount
              : invoiceData?.total)
          ) {
            error.pay_amount = "Amount is more than the pending amount";
          }
        }
        if (receipt.current === null) {
          error.receipt = "Upload Reciept is required";
        }
        break;

      case "cheque":
        if (values.cheque_amt === "") {
          error.cheque_amt = "Amount is required";
        }
        if (values.cheque_amt !== "") {
          if (parseFloat(values.cheque_amt) < invoiceData?.pending_amount) {
            error.cheque_amt = "Invalid amount";
          }
          if (
            values.cheque_amt >
            (invoiceData?.pending_amount !== undefined
              ? invoiceData?.pending_amount
              : invoiceData?.total)
          ) {
            error.cheque_amt = "Amount is more than the pending amount";
          }
        }
        if (values.cheque_num === "") {
          error.cheque_num = "Cheque Number is required";
        }
        if (values.issuer_name === "") {
          error.issuer_name = "Issuer is required";
        }
        if (values.account_number === "") {
          error.account_number = "Account Number is required";
        }
        if (values.bank_name === "") {
          error.bank_name = "Bank Name is required";
        }
        if (receipt.current === null) {
          error.receipt = "Upload Cheque is required";
        }
        break;

      case "wire":
        if (values.receive_bank === "") {
          error.receive_bank = "Bank Name is required";
        }

        if (values.wire_amount === "") {
          error.wire_amount = "Amount is required";
        }
        if (values.wire_amount !== "") {
          if (parseFloat(values.wire_amount) < invoiceData?.pending_amount) {
            error.wire_amount = "Invalid amount";
          }
          if (
            values.wire_amount >
            (invoiceData?.pending_amount !== undefined
              ? invoiceData?.pending_amount
              : invoiceData?.total)
          ) {
            error.wire_amount = "Amount is more than the pending amount";
          }
        }

        if (values.sender_name === "") {
          error.sender_name = "Sender Name is required";
        }
        if (values.reference_number === "") {
          error.reference_number = "Reference Number is required";
        }

        break;

      case "moneyOrder":
        if (values.moneyOrder_amount === "") {
          error.moneyOrder_amount = "Amount is required";
        }
        if (values.moneyOrder_amount !== "") {
          if (parseFloat(values.moneyOrder_amount) <= 0) {
            error.moneyOrder_amount = "Invalid amount";
          }
          if (
            values.moneyOrder_amount >
            (invoiceData?.pending_amount !== undefined
              ? invoiceData?.pending_amount
              : invoiceData?.total)
          ) {
            error.moneyOrder_amount = "Amount is more than the pending amount";
          }
        }

        if (values.send_name === "") {
          error.send_name = "Sender Name is required";
        }
        if (values.moneyOrder_number === "") {
          error.moneyOrder_number = "Reference Number is required";
        }

        break;

      case "cashier check":
        if (values.cashier_check_amopunt === "") {
          error.cashier_check_amopunt = "Amount is required";
        }
        if (values.cashier_check_amopunt !== "") {
          if (
            parseFloat(values.cashier_check_amopunt) <
            invoiceData?.pending_amount
          ) {
            error.cashier_check_amopunt = "Invalid amount";
          }
          if (
            values.cashier_check_amopunt >
            (invoiceData?.pending_amount !== undefined
              ? invoiceData?.pending_amount
              : invoiceData?.total)
          ) {
            error.cashier_check_amopunt =
              "Amount is more than the pending amount";
          }
        }

        if (values.cashier_check_bank === "") {
          error.cashier_check_bank = "Bank Name is required";
        }
        if (values.cashier_check_number === "") {
          error.cashier_check_number = "Reference Number is required";
        }

        break;

      case "pos":
        if (values.pos_amount === "") {
          error.pos_amount = "Amount is required";
        }
        if (values.pos_amount !== "") {
          if (parseFloat(values.pos_amount) < invoiceData?.pending_amount) {
            error.pos_amount = "Invalid amount";
          }
          if (
            values.pos_amount >
            (invoiceData?.pending_amount !== undefined
              ? invoiceData?.pending_amount
              : invoiceData?.total)
          ) {
            error.pos_amount = "Amount is more than the pending amount";
          }
        }

        if (values.transactionId === "") {
          error.transactionId = "Transaction ID is required";
        }

        break;

      default:
        break;
    }
    // }
    // else {
    // }

    return error;
  };

  // Prefill on load
  const prefill = () => {
    if (invoiceData?.pending_amount && invoiceData?.pending_amount !== 0) {
      setInitialFormState((p) => ({
        ...p,

        // Cash
        pay_amount: invoiceData?.pending_amount,

        // Cheque
        cheque_amt: invoiceData?.pending_amount,

        // WIRE
        wire_amount: invoiceData?.pending_amount,

        // MONEY ORDER
        moneyOrder_amount: invoiceData?.pending_amount,

        // cashier check
        cashier_check_amopunt: invoiceData?.pending_amount,

        // POS
        pos_amount: invoiceData?.pending_amount,
      }));
    } else {
      setInitialFormState((p) => ({
        ...p,

        // Cash
        pay_amount: invoiceData?.total || invoiceData?.total_amount,

        // Cheque
        cheque_amt: invoiceData?.total || invoiceData?.total_amount,

        // WIRE
        wire_amount: invoiceData?.total || invoiceData?.total_amount,

        // MONEY ORDER
        moneyOrder_amount: invoiceData?.total || invoiceData?.total_amount,

        // cashier check
        cashier_check_amopunt: invoiceData?.total || invoiceData?.total_amount,

        // POS
        pos_amount: invoiceData?.total || invoiceData?.total_amount,
      }));
    }
  };

  // Handle SUbmit
  const onSubmit = async (values) => {
    let payload = {};
    if (payMark?.key === "cash") {
      payload = {
        invoice_id: invoiceData?._id,
        payment_amount: values.pay_amount,
        receipt_number: values.reciept_num,
        invoice_doc: receipt.current,
        receipt_date: recpdate,
        remarks: values.note,
        paid_via: payMark?.key,
        type: invoiceData?.type,
      };
    } else if (payMark?.key === "cheque") {
      payload = {
        invoice_id: invoiceData?._id,
        payment_amount: values.cheque_amt,
        cheque_amount: values.cheque_amt,
        cheque_number: values.cheque_num,
        issuer: values.issuer_name,
        account_number: values.account_number,
        invoice_doc: receipt.current,
        bank_name: values.bank_name,
        cheque_date: recpdate,
        remarks: values.note,
        paid_via: payMark?.key,
        type: invoiceData?.type,
      };
    } else if (payMark?.key === "deposit") {
      payload = {
        invoice_id: invoiceData?._id,
        application_id: invoiceData?.tenant_application,
        remarks: values.note,
        deposit_id: selectedDeposit?._id,
        // type: invoiceData?.type,
      };
    } else if (payMark?.key === "wire") {
      payload = {
        invoice_id: invoiceData?._id,
        payment_amount: values.wire_amount,
        reference_no: values.reference_number,
        sender_name: values.sender_name,
        bank_name: values.receive_bank,
        transfer_date: recpdate,
        remarks: values.note,
        paid_via: payMark?.key,
        type: invoiceData?.type,
      };
    } else if (payMark?.key === "moneyOrder") {
      payload = {
        invoice_id: invoiceData?._id,
        payment_amount: values.moneyOrder_amount,
        money_order_no: values.moneyOrder_number,
        sender_name: values.send_name,
        money_order_date: recpdate,
        remarks: values.note,
        paid_via: "money order",
        type: invoiceData?.type,
      };
    } else if (payMark?.key === "cashier check") {
      payload = {
        invoice_id: invoiceData?._id,
        payment_amount: values.cashier_check_amopunt,
        cheque_no: values.cashier_check_number,
        bank_name: values.cashier_check_bank,
        cashier_cheque_date: recpdate,
        remarks: values.note,
        paid_via: "cashier cheque",
        type: invoiceData?.type,
      };
    } else if (payMark?.key === "pos") {
      payload = {
        invoice_id: invoiceData?._id,
        payment_amount: values.pos_amount,
        pos_transaction_id: values.transactionId,
        pos_transaction_date: recpdate,
        remarks: values.note,
        paid_via: "point of sale",
        type: invoiceData?.type,
      };
    } else {
      payload = {
        invoice_id: invoiceData?._id,
        status: "void",
        remarks: values.note,
        type: invoiceData?.type,
      };
    }

    // Backend response. Try, Catch
    try {
      let result;
      if (payMark?.key === "deposit") {
        result = await dispatch(adjustFromDeposit(payload));
      } else if (
        (invoiceData?.type === "Utility" && newType !== "tenant-Utility") ||
        (invoiceData?.type === "Other" && newType !== "tenant-Other")
      ) {
        result = await dispatch(
          updatePropertyInvoice({ ...payload, property_id })
        );
      } else if (
        invoiceData?.type === "Additional work" ||
        invoiceData?.type === "Service contract"
      ) {
        result = await dispatch(
          updatePartyInvoice({ ...payload, property_id })
        );
      } else {
        result = await dispatch(
          updateTenantInvoice({ ...payload, property_id })
        );
      }

      afterPayment && afterPayment();

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setModalDesc(result.payload);
          setShowErrorModal(true);
          break;

        case GLOBAL.FULFILLED:
          setModalDesc(result.payload.message);
          setShowSuccessModal(true);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // useEffect
  useEffect(() => {
    dispatch(
      fetchActiveDepositInvoiceList({
        application_id: invoiceData?.tenant_application,
      })
    );
  }, [invoiceData?.tenant_application]);

  useEffect(() => {
    prefill();
  }, [payMark, invoiceData]);

  /* ************* Component rendering. JSX code ************* */
  return (
    <Modal
      size={size}
      centered
      show={showModal}
      className="claim-modal-wrapper"
    >
      <Modal.Body
        className={`${userTheme === "dark" ? "bg_darkTheme" : "bg_lightTheme"}`}
      >
        <div className="modal-header-custom">
          {invoiceData?.pending_amount !== undefined ? (
            <p
              className={`${userTheme !== "dark" && "font_lightTheme"} font_xl`}
            >
              Pending Amount :{" "}
              <span className="font_blue font_xl fw_6">
                {pending_amount} {invoiceData?.currency || currency}
              </span>
            </p>
          ) : (
            <p
              className={`${userTheme !== "dark" && "font_lightTheme"} font_xl`}
            >
              Total Amount :{" "}
              <span className="font_blue font_xl fw_6">
                {invoiceData?.total?.toLocaleString("en-US") ||
                  invoiceData?.total_amount?.toLocaleString("en-US")}{" "}
                {invoiceData?.currency || currency}
              </span>
            </p>
          )}
        </div>
        <div className="modal-body-custom">
          <Formik
            onSubmit={onSubmit}
            initialValues={initialFormState}
            validate={(values) => formValidation(values, payMark)}
            validateOnBlur={true}
            enableReinitialize={true}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              setFieldTouched,
            }) => (
              <form onSubmit={handleSubmit}>
                {/* Form Fields */}
                <div className="form-wrapper form-wrapper-flex">
                  <div className="form-group-full mt-2">
                    <CustomSelectInput
                      name="pay_Mark"
                      label="Select Payment Method"
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      valueExtractor={(item) => item?.name}
                      options={paymentMethods}
                      value={payMark}
                      setValue={setPayMark}
                      formikState={false}
                      emptyOption={moveOut ? true : false}
                    />
                  </div>

                  {payMark?.key === "cash" && (
                    <>
                      <div className="form-group mt-2">
                        <CustomTextInput
                          name="reciept_num"
                          label="Receipt Number"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          touched={touched.reciept_num}
                          errors={errors.reciept_num}
                          value={values.reciept_num}
                          required={true}
                        />
                      </div>

                      <div className="form-group">
                        <CustomCurrencyInput
                          name="pay_amount"
                          label="Cash Amount"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldTouched}
                          touched={touched.pay_amount}
                          errors={errors.pay_amount}
                          value={values.pay_amount}
                          currency={invoiceData?.currency || currency}
                          required={true}
                        />
                      </div>

                      <div className="form-group">
                        <CustomFileInput
                          label="Upload Reciept"
                          name="receipt"
                          files={receipt}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          errors={errors.receipt}
                          touched={touched.receipt}
                          values={values.receipt}
                          required={true}
                          formikState={false}
                        />
                      </div>
                    </>
                  )}

                  {payMark?.key === "cheque" && (
                    <>
                      <div className="form-group mt-2">
                        <CustomTextInput
                          name="cheque_num"
                          label="Cheque Number"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          touched={touched.cheque_num}
                          errors={errors.cheque_num}
                          value={values.cheque_num}
                          required={true}
                        />
                      </div>

                      <div className="form-group">
                        <CustomCurrencyInput
                          name="cheque_amt"
                          label="Cheque Amount"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          touched={touched.cheque_amt}
                          errors={errors.cheque_amt}
                          value={values.cheque_amt}
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldTouched}
                          currency={invoiceData?.currency || currency}
                          required={true}
                        />
                      </div>

                      <div
                        className={`form-group ${
                          (userTheme === "light" ||
                            userTheme === "automatic") &&
                          "otherTheme"
                        } `}
                      >
                        <CustomDateInput
                          label="Cheque Date"
                          value={recpdate}
                          minDate={false}
                          customOnchange={(date) => setRecpdate(date)}
                          required={true}
                        />
                      </div>
                      <div className="form-group">
                        <CustomTextInput
                          name="bank_name"
                          label="Bank Name"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          touched={touched.bank_name}
                          errors={errors.bank_name}
                          value={values.bank_name}
                          required={true}
                        />
                      </div>

                      <div className="form-group">
                        <CustomTextInput
                          name="issuer_name"
                          label="Issuer Name"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          touched={touched.issuer_name}
                          errors={errors.issuer_name}
                          value={values.issuer_name}
                          required={true}
                        />
                      </div>

                      <div className="form-group">
                        <CustomTextInput
                          name="account_number"
                          label="Account Number"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          touched={touched.account_number}
                          errors={errors.account_number}
                          value={values.account_number}
                          required={true}
                        />
                      </div>

                      <div className="form-group mt-2">
                        <CustomFileInput
                          label="Upload Cheque"
                          name="cheque"
                          files={receipt}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          errors={errors.cheque}
                          touched={touched.cheque}
                          values={values.cheque}
                          required={true}
                          formikState={false}
                        />
                      </div>
                    </>
                  )}

                  {payMark?.key === "deposit" && (
                    <>
                      <div className="form-group-full mt-2">
                        <CustomSelectInput
                          name="doc_type"
                          label="Select Deposit"
                          handleBlur={handleBlur}
                          onChange={handleChange}
                          valueExtractor={(item) => item?.description}
                          setValue={setSelectedDeposit}
                          options={depositList}
                          value={selectedDeposit}
                          formikState={false}
                          emptyOption={true}
                        />
                      </div>

                      {selectedDeposit && (
                        <div
                          className={`custom-table-wrapper w-100 my-2 ${
                            userTheme !== "dark" && "tenant-invoice-table"
                          } `}
                        >
                          <Table
                            className="custom-table font_s"
                            borderless
                            responsive
                          >
                            <thead>
                              <tr>
                                <th>Description</th>
                                <th>Type</th>
                                <th>Amount</th>
                              </tr>
                            </thead>
                            {/* Table Body */}
                            <tbody>
                              <tr key={selectedDeposit?._id}>
                                {/* Invoice */}
                                <td>{selectedDeposit?.description}</td>
                                <td>{selectedDeposit?.type}</td>
                                <td>
                                  {selectedDeposit?.total_amount?.toLocaleString(
                                    "en-US"
                                  )}{" "}
                                  {selectedDeposit?.currency}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      )}
                    </>
                  )}

                  {payMark?.key === "wire" && (
                    <>
                      <div className="form-group mt-2">
                        <CustomTextInput
                          name="receive_bank"
                          label="Receiving Bank Name"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          touched={touched.receive_bank}
                          errors={errors.receive_bank}
                          value={values.receive_bank}
                          required={true}
                        />
                      </div>

                      <div className="form-group">
                        <CustomCurrencyInput
                          name="wire_amount"
                          label="Amount"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          touched={touched.wire_amount}
                          errors={errors.wire_amount}
                          value={values.wire_amount}
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldTouched}
                          currency={invoiceData?.currency || currency}
                          required={true}
                        />
                      </div>

                      <div className="form-group">
                        <CustomTextInput
                          name="sender_name"
                          label="Sender Name"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          touched={touched.sender_name}
                          errors={errors.sender_name}
                          value={values.sender_name}
                          required={true}
                        />
                      </div>

                      <div className="form-group">
                        <CustomTextInput
                          name="reference_number"
                          label="Reference Number"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          touched={touched.reference_number}
                          errors={errors.reference_number}
                          value={values.reference_number}
                          required={true}
                        />
                      </div>

                      {/* <div className="form-group mt-2">
                          <CustomFileInput
                            label="Upload Cheque"
                            name="cheque"
                            files={receipt}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            errors={errors.cheque}
                            touched={touched.cheque}
                            values={values.cheque}
                            required={true}
                            formikState={false}
                          />
                        </div> */}
                    </>
                  )}

                  {payMark?.key === "moneyOrder" && (
                    <>
                      <div className="form-group">
                        <CustomCurrencyInput
                          name="moneyOrder_amount"
                          label="Amount"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          touched={touched.moneyOrder_amount}
                          errors={errors.moneyOrder_amount}
                          value={values.moneyOrder_amount}
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldTouched}
                          currency={invoiceData?.currency || currency}
                          required={true}
                        />
                      </div>

                      <div
                        className={`form-group ${
                          (userTheme === "light" ||
                            userTheme === "automatic") &&
                          "otherTheme"
                        } `}
                      >
                        <CustomDateInput
                          label="Transfer Date"
                          value={recpdate}
                          minDate={false}
                          customOnchange={(date) => setRecpdate(date)}
                          required={true}
                        />
                      </div>

                      <div className="form-group">
                        <CustomTextInput
                          name="send_name"
                          label="Sender Name"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          touched={touched.send_name}
                          errors={errors.send_name}
                          value={values.send_name}
                          required={true}
                        />
                      </div>

                      <div className="form-group">
                        <CustomTextInput
                          name="moneyOrder_number"
                          label="Money Order Number"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          touched={touched.moneyOrder_number}
                          errors={errors.moneyOrder_number}
                          value={values.moneyOrder_number}
                          required={true}
                        />
                      </div>
                    </>
                  )}

                  {payMark?.key === "cashier check" && (
                    <>
                      <div className="form-group">
                        <CustomCurrencyInput
                          name="cashier_check_amopunt"
                          label="Cashier Cheque Amount"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          touched={touched.cashier_check_amopunt}
                          errors={errors.cashier_check_amopunt}
                          value={values.cashier_check_amopunt}
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldTouched}
                          currency={invoiceData?.currency || currency}
                          required={true}
                        />
                      </div>

                      <div
                        className={`form-group ${
                          (userTheme === "light" ||
                            userTheme === "automatic") &&
                          "otherTheme"
                        } `}
                      >
                        <CustomDateInput
                          label="Date"
                          value={recpdate}
                          minDate={false}
                          customOnchange={(date) => setRecpdate(date)}
                          required={true}
                        />
                      </div>

                      <div className="form-group">
                        <CustomTextInput
                          name="cashier_check_bank"
                          label="Cashier Cheque Bank Name"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          touched={touched.cashier_check_bank}
                          errors={errors.cashier_check_bank}
                          value={values.cashier_check_bank}
                          required={true}
                        />
                      </div>

                      <div className="form-group">
                        <CustomTextInput
                          name="cashier_check_number"
                          label="Cashier Cheque Number"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          touched={touched.cashier_check_number}
                          errors={errors.cashier_check_number}
                          value={values.cashier_check_number}
                          required={true}
                        />
                      </div>
                    </>
                  )}

                  {payMark?.key === "pos" && (
                    <>
                      <div className="form-group">
                        <CustomTextInput
                          name="transactionId"
                          label="Transaction ID Number"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          touched={touched.transactionId}
                          errors={errors.transactionId}
                          value={values.transactionId}
                          required={true}
                        />
                      </div>

                      <div className="form-group">
                        <CustomCurrencyInput
                          name="pos_amount"
                          label="POS Amount"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          touched={touched.pos_amount}
                          errors={errors.pos_amount}
                          value={values.pos_amount}
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldTouched}
                          currency={invoiceData?.currency || currency}
                          required={true}
                        />
                      </div>

                      <div
                        className={`form-group ${
                          (userTheme === "light" ||
                            userTheme === "automatic") &&
                          "otherTheme"
                        } `}
                      >
                        <CustomDateInput
                          label="POS Date"
                          value={recpdate}
                          minDate={false}
                          customOnchange={(date) => setRecpdate(date)}
                          required={true}
                        />
                      </div>
                    </>
                  )}

                  {payMark?.key && (
                    <div className="form-group-full mt-2">
                      <CustomTextArea
                        name="note"
                        label="Remark"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        value={values.note}
                        errors={errors.note}
                        touched={touched.note}
                        required={true}
                      />
                    </div>
                  )}
                </div>

                {/* Submit and Cancel */}
                <div className="button-wrapper text-center">
                  <>
                    <CustomButton type="submit" size="l">
                      Submit
                    </CustomButton>
                    <TextButton
                      type="button"
                      onClick={onClose}
                      className="font_m fw_6"
                    >
                      Cancel
                    </TextButton>
                  </>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </Modal.Body>

      {/* Success Modal */}
      <SuccessModal
        showModal={showSuccessModal}
        description={modalDesc}
        onClose={() => {
          onClose();
          setShowSuccessModal(false);
        }}
      />

      {/* Error Modal */}
      <ErrorModal
        showModal={showErrorModal}
        description={modalDesc}
        title="Error"
        onClose={() => setShowErrorModal(false)}
      />
    </Modal>
  );
};
/* Component ends */

export default PaymentModal;
