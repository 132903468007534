/**
 *
 */

import React from "react";

/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import MUI component starts */
/* Import MUI component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
/* Import local pages and component ends */

import "./style.scss";
import { useSelector } from "react-redux";
import { getUserTheme } from "../../../../setup/store/slices/authSlice";

/* Component starts */
const CustomRadioInput = (props) => {
  /* Props destructuring starts */
  const {
    label,
    className = "",
    isFormField = false,

    // Labels
    labelClass,
    mainLabel,
    buttonLabelArray,

    // Class names for style
    containerClass = "",
    mainLabelClass = "",
    buttonContainerClass = "",
    radioButtonClass = "",
    buttonLabelClass = "",

    // If normal button to change state. Not a form field
    isSelected,
    onSelecting,

    // If it is a form field
    name,
    handleChange,
    handleBlur,
    data, // Array of data to be set in Radio
    value,
    errors,
    touched,
    labelVertical = true,
    disabled = false,
  } = props;

  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const userTheme = useSelector(getUserTheme);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const onChange = (e) => {
    handleChange(e);
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */
  // className={`${userTheme === "dark" ? "bg_darkTheme" : "bg_lightTheme"}`}
  /* ************* Component rendering. JSX code ************* */
  /* If it is a form field */
  if (isFormField) {
    return (
      <div
        className={`custom-radio-component-wrapper w_100 ${className} ${containerClass}`}
      >
        <p className={`main-label ${mainLabelClass}`}>{mainLabel}</p>

        <div className={`radio-btn-container ${buttonContainerClass}`}>
          {data?.map((item, index) => (
            <div className={`radio-button ${radioButtonClass}`} key={index}>
              <label className="v_middle">
                <input
                  onBlur={handleBlur}
                  onChange={onChange}
                  name={name}
                  type="radio"
                  className="form-field-input"
                  id=""
                  value={item}
                  checked={item === value}
                  disabled={disabled}
                />

                <span className="d_block circle">
                  <span className="inner-dot d_block"></span>
                </span>
              </label>

              <label
                className={`radio-btn-label ${
                  userTheme === "dark" ? "font_white" : "font_lightTheme"
                } v_middle ${buttonLabelClass}`}
              >
                {buttonLabelArray[index]}
              </label>
            </div>
          ))}
        </div>

        {touched && errors && (
          <p className="error font_xxs font_red">{errors}</p>
        )}
      </div>
    );
  }

  /* If it is not a form field. A normal button to change state */
  return (
    <div
      className={`custom-radio-component-wrapper ${className}  
      ${labelVertical ? "label_vertical" : ""}`}
    >
      <div
        className={`radio-button ${isSelected && "active"}`}
        onClick={onSelecting}
      >
        <span className="d_block circle">
          <span className="inner-dot d_block"></span>
        </span>
      </div>
      <div
        className={`label ${labelClass} font_xs ${
          userTheme === "dark" ? "font_white" : "font_lightTheme"
        }`}
      >
        {label}
      </div>
    </div>
  );
};
/* Component ends */

export default CustomRadioInput;
