/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  fetchAnnoucementById,
  fetchAnnouncementStatsById,
  updateOpenedAnnoucementByID,
} from "../../setup/store/slices/annoucementSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import AnnoucementDetail from "../../components/module/Annoucemnets/AnnoucementDetail/AnnoucementDetail";
import BreadCrumb from "../../components/ui/other/BreadCrumb/BreadCrumb";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const AnnoucementDetailedPage = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  // const [role, setRole] = useState();
  const [annoucement, setAnnouncement] = useState();
  const [announcementStats, setAnnouncementStats] = useState();
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const { id } = useParams();
  const dispatch = useDispatch();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const isOwner = localStorage.getItem("user_role") === "Owner";
  // const isPM = localStorage.getItem("user_role") === "Property Manager";
  const isTenant = localStorage.getItem("user_role") === "Tenant";
  const isClient = localStorage.getItem("user_role") === "Client";
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */

  // useEffect(() => {
  //   if (state !== "Client" && id) {
  //     // dispatch(updateOpenedAnnoucementByID(id));
  //     dispatch(fetchAnnoucementById(id));
  //   } else {
  //     dispatch(fetchAnnoucementById(id));
  //   }
  // }, [id, state]);

  useEffect(() => {
    const getAnnouncement = async () => {
      const result = await dispatch(fetchAnnoucementById(id));
      setAnnouncement(result.payload);

      if (!isClient) await dispatch(updateOpenedAnnoucementByID(id));
    };
    const getAnnouncementStats = async () => {
      const result = await dispatch(
        fetchAnnouncementStatsById({ announcement_id: id })
      );
      setAnnouncementStats(result.payload);
    };
    getAnnouncement();
    if (isClient || isOwner) {
      getAnnouncementStats();
    }
  }, [id]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      {!isTenant && <BreadCrumb type="client_annoucement_detail" />}
      <div className="">
        <AnnoucementDetail
          annoucement={annoucement}
          announcementStats={announcementStats}
        />
      </div>
    </>
  );
};
/* Component ends */

export default AnnoucementDetailedPage;
