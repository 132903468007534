import { customDateFormat } from "../../../../setup/utils/global-helper";

export const initialFormValue = {
  total_rent: "",
  no_of_installments: "",
  duration: "",
  start_date: new Date(),
  deposit_amount_0: "",
  description_0: "",
  commission_amount: "",
};

export const validations = (
  values,
  deposit,
  commission,
  depositList,
  globalSettings
) => {
  const error = {};

  /* Validations for Total Annual Rent starts */
  if (values.total_rent === "") {
    error.total_rent = "Total Annual Rent is required";
  }
  /* Validations for Total Annual Rent ends */

  /* Validations for Number Of Installment starts */
  if (values.no_of_installments === "") {
    error.no_of_installments = "Number Of Installment is required";
  } else if (
    parseInt(values.no_of_installments) >
    parseInt(values.duration) *
      parseInt(globalSettings?.max_monthly_installments)
  ) {
    error.no_of_installments =
      "Number of installments should be less than " +
      parseInt(values.duration) *
        parseInt(globalSettings?.max_monthly_installments);
  }
  /* Validations for Number Of Installment ends */

  /* Validations for Duration starts */
  if (values.duration === "") {
    error.duration = "Duration is required";
  } else if (
    parseInt(values.duration) > globalSettings?.max_contract_duration
  ) {
    error.duration =
      "Contract duration should be less than " +
      globalSettings?.max_contract_duration;
  }
  /* Validations for Duration ends */

  /* Validations for Deposit starts */
  if (deposit) {
    depositList?.forEach((id, index) => {
      const description = `description_${id}`;
      const depositAmount = `deposit_amount_${id}`;

      if (values[description] === "") {
        error[description] = "Deposit description is required";
      }
      if (values[depositAmount] === "") {
        error[depositAmount] = "Deposit amount is required";
      }
    });
  }
  /* Validations for Deposit ends */

  /* Validations for Commission Amount starts */
  if (commission && values.commission_amount === "") {
    error.commission_amount = "Commission is required";
  }
  /* Validations for Commission Amount ends */

  return error;
};

export const formPrefill = (
  setFormValues,
  target_rent_price,
  instalmentPlan,
  setDeposit,
  setCommission,
  startDate,
  setDepositVat,
  setDepositTax,
  setCommissionVat,
  setCommissionTax,
  setInstalmentVat,
  setInstalmentTax,
  setDepositList,
  property
) => {
  if (instalmentPlan) {
    setDeposit(instalmentPlan?.deposit);
    setCommission(instalmentPlan?.commission);
    setDepositVat(instalmentPlan?.collect_deposit_vat);
    setDepositTax(instalmentPlan?.collect_deposit_tax);

    setCommissionVat(instalmentPlan?.collect_commission_vat);
    setCommissionTax(instalmentPlan?.collect_commission_tax);

    setInstalmentVat(instalmentPlan?.collect_instalment_vat);
    setInstalmentTax(instalmentPlan?.collect_instalment_tax);

    const depositData = {};
    const depositListID = [];
    if (instalmentPlan?.deposit_data?.length > 0) {
      instalmentPlan?.deposit_data?.forEach((item, index) => {
        depositData[`description_${index}`] = item?.description || "";
        depositData[`deposit_amount_${index}`] = item?.deposit_amount || "";
        depositListID.push(index);
      });
    } else {
      depositData[`description_0`] = "";
      depositData[`deposit_amount_0`] = "";
      depositListID.push(0);
    }
    setDepositList(depositListID);
    setFormValues((prevState) => {
      return {
        total_rent: instalmentPlan?.total_rent
          ? instalmentPlan?.total_rent?.toString()
          : target_rent_price
          ? target_rent_price.toString()
          : "",
        no_of_installments:
          instalmentPlan?.no_of_installments?.toString() || "",
        duration: instalmentPlan?.duration?.toString() || "",
        start_date: startDate
          ? customDateFormat(startDate, property?.offset)
          : instalmentPlan?.start_date
          ? customDateFormat(instalmentPlan?.start_date, property?.offset)
          : new Date(),
        // start_date: startDate
        //   ? new Date(startDate)
        //   : instalmentPlan?.start_date
        //   ? new Date(instalmentPlan?.start_date)
        //   : new Date(),
        deposit_amount: instalmentPlan?.deposit_amount || "",
        commission_amount: instalmentPlan?.commission_amount || "",
        ...depositData,
      };
    });
  } else {
    setFormValues((prevState) => {
      return {
        ...prevState,
        total_rent: target_rent_price.toString(),
      };
    });
  }
};

export const addMore = (formRef, setFormValue, list, setList) => {
  const generetedID = list.length === 0 ? 0 : list[list.length - 1] + 1;
  const currentFormState = { ...formRef.current.values };

  setFormValue(() => {
    // Generating new field name according to array indexes
    currentFormState[`description_${generetedID}`] = "";
    currentFormState[`deposit_amount_${generetedID}`] = "";
    return currentFormState;
  });
  setList((p) => [...p, generetedID]);
};

export const remove = (formRef, setFormValue, list, setList, index) => {
  const removeID = list[index];
  const currentFormState = { ...formRef.current.values };

  setFormValue(() => {
    delete currentFormState[`description_${removeID}`];
    delete currentFormState[`deposit_amount_${removeID}`];
    return currentFormState;
  });

  setList((prevState) => {
    const prevFields = [...prevState];
    prevFields.splice(index, 1);
    return prevFields;
  });
};
