export const formatRenderer = (format) => {
  switch (format) {
    case "DD/MM/YYYY":
      return "dd/MM/y";

    case "MM/DD/YYYY":
      return "MM/dd/y";

    case "DD-MM-YYYY":
      return "dd-MM-y";

    case "MM-DD-YYYY":
      return "MM-dd-y";

    case "MMM-DD-YYYY":
      return "MMM-dd-y";

    case "DD-MMM-YYYY":
      return "dd-MMM-y";

    default:
      return "";
  }
};
