/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";
/* Import configuration ends */

/* Import redux slices component starts */

/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Table } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { EyeFilledBlue } from "../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import GLOBAL from "../../../setup/constants/global";
import IconButton from "../../../components/ui/button/IconButton/IconButton";
import CheckboxMultiSelectDropdown from "../../../components/ui/input/CheckboxMultiSelectDropdown/CheckboxMultiSelectDropdown";
/* Import local pages and component ends */

/* Component starts */
const FinancialTable = (props) => {
  /* Props destructuring starts */
  const {
    financeListData,
    multiSelectCommunity,
    setMultiSelectCommunity,
    communityData,
    propertyData,
    multiSelectProperty,
    setMultiSelectProperty,
    amount,
    multiSelectAmount,
    setMultiSelectAmount,
    category,
    multiSelectCategory,
    setMultiSelectCategory,
    subCat,
    multiSelectSubCategory,
    setMultiSelectSubCategory,
    // tenant,
    // multiSelectTenant,
    // setMultiSelectTenant,
    statusData1,
    multiSelectStatus,
    setMultiSelectStatus,
  } = props;

  /* Props destructuring ends */

  /* Component states and useRef declaration starts */

  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  const { pathname } = useLocation();
  //   const dispatch = useDispatch();

  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  // To View The Item Report
  const handleViewReport = async (item) => {
    if (item?.community_id) {
      navigate(
        `/communities/${item?.community_id}/properties/${item?.property_id}/invoicing/${item?.category}/${item?.sub_category}/${item?.invoice_id}`,
        { state: { pathname: pathname } }
      );
    } else {
      //`independent/properties/64ca21ae75c0ef4d8468c8ab/invoicing/${item?.category}/${item?.category}/${item?.invoice_id}`
      navigate(
        // `/independent/properties/${item?.property_id}/invoicing/detail?type=${item?.category}/${item?.invoice_id}`
        `/independent/properties/${item?.property_id}/invoicing/${item?.category}/${item?.sub_category}/${item?.invoice_id}`,
        { state: { pathname: pathname } }
      );
    }
  };

  /* Component function definition ends */

  /* Component useEffect starts */

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */

  return (
    <div className="custom-table-wrapper financial-table">
      {financeListData?.length > 0 && (
        <Table className="custom-table font_s" borderless responsive>
          {/* Table Heading */}
          <thead>
            <tr>
              <th>
                <CheckboxMultiSelectDropdown
                  multiSelectData={multiSelectCommunity}
                  setMultiSelectData={setMultiSelectCommunity}
                  optionLabel={communityData}
                  label={"Community"}
                />
              </th>
              <th>
                <CheckboxMultiSelectDropdown
                  multiSelectData={multiSelectProperty}
                  setMultiSelectData={setMultiSelectProperty}
                  optionLabel={propertyData}
                  label={"Property"}
                />
              </th>
              <th>Due Date</th>
              <th>Invoice</th>
              <th>
                <CheckboxMultiSelectDropdown
                  multiSelectData={multiSelectAmount}
                  setMultiSelectData={setMultiSelectAmount}
                  optionLabel={amount}
                  label={"Amount"}
                />
              </th>
              <th>Payment Method</th>
              <th>
                <CheckboxMultiSelectDropdown
                  multiSelectData={multiSelectCategory}
                  setMultiSelectData={setMultiSelectCategory}
                  optionLabel={category}
                  label={"Category"}
                />
              </th>
              <th>
                <CheckboxMultiSelectDropdown
                  multiSelectData={multiSelectSubCategory}
                  setMultiSelectData={setMultiSelectSubCategory}
                  optionLabel={subCat}
                  label={"Sub Category"}
                />
              </th>
              <th>
                <CheckboxMultiSelectDropdown
                  multiSelectData={multiSelectStatus}
                  setMultiSelectData={setMultiSelectStatus}
                  optionLabel={statusData1}
                  label={"Status"}
                />
              </th>
              <th className="text-end">Action</th>
            </tr>
          </thead>
          {/* Table Body */}
          {financeListData?.length > 0 && (
            <tbody>
              {financeListData?.map((item, index) => (
                <tr key={index}>
                  {/* Community */}
                  <td>
                    {item?.community_name !== undefined
                      ? item?.community_name
                      : "-"}
                  </td>

                  {/* Property */}
                  <td>{item?.property_name}</td>

                  {/* Due Date */}
                  <td>{moment(item?.due_date).format(DATE_TIME_FORMAT)}</td>

                  {/* Invoice */}
                  <td>
                    {item?.invoice_number !== undefined
                      ? item?.invoice_number
                      : "-"}
                  </td>

                  {/* Amount */}
                  <td>
                    {item?.invoice_amount !== undefined
                      ? `${item?.invoice_amount?.toLocaleString(
                          "en-US"
                        )}${" "}${
                          item?.currency !== undefined ? item?.currency : ""
                        }`
                      : "-"}
                  </td>

                  {/* Pay Method */}
                  <td>
                    {item?.payment_method !== undefined &&
                    item?.payment_method !== ""
                      ? item?.payment_method?.charAt(0)?.toUpperCase() +
                        item?.payment_method?.slice(1)
                      : "-"}
                  </td>

                  {/* Category */}
                  <td>{item?.category !== undefined ? item?.category : "-"}</td>

                  {/* Sub Category */}
                  <td>
                    {item?.sub_category !== undefined
                      ? item?.sub_category?.charAt(0)?.toUpperCase() +
                        item?.sub_category?.slice(1)
                      : "-"}
                  </td>

                  {/* Status */}
                  <td
                    className={
                      item?.status === "paid" || item?.status === "Paid"
                        ? "font_green"
                        : item?.status === "unpaid" || item?.status === "Unpaid"
                        ? "font_red"
                        : item?.status === "void"
                        ? "font_grey"
                        : "font_yellow"
                    }
                  >
                    {item?.status?.charAt(0).toUpperCase() +
                      item?.status?.slice(1)}
                  </td>

                  {/* Actions */}
                  <td className="text-start txt-nowrap">
                    <IconButton
                      onClick={() => handleViewReport(item)}
                      className="action-button"
                    >
                      <img
                        src={EyeFilledBlue}
                        className="h_100"
                        alt="View Details"
                      />
                    </IconButton>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </Table>
      )}

      {financeListData?.length === 0 && (
        <div className=" text-center">
          <h3 className=" font_m font_yellow fw_6">No Data</h3>
        </div>
      )}
    </div>
  );
};
/* Component ends */
export default FinancialTable;
