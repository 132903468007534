/**
 *
 */

import React, { useState, useEffect, useRef } from "react";
/* Import configuration starts */
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Formik } from "formik";
import { initialValues, validations, addDoc, removeDoc } from "./helper";
import GLOBAL from "../../../../setup/constants/global";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  fetchSeverityList,
  getSeverities,
} from "../../../../setup/store/slices/severitySlice";
import {
  createInternalMaintenanceReq,
  fetchInternalMaintenanceCategories,
  fetchInternalMaintenanceInnerCategories,
  fetchInternalMaintenanceLocations,
  fetchInternalMaintenanceSubCategories,
  getInternalMaintenanceCategories,
  getInternalMaintenanceInnerCategories,
  getInternalMaintenanceLocations,
  getInternalMaintenanceSubCategories,
} from "../../../../setup/store/slices/internalMaintenanceSlice";
import { fetchUnits } from "../../../../setup/store/slices/unitSlice";
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../../setup/store/slices/globalAlertSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomFileInput from "../../../ui/input/CustomFileInput/CustomFileInput";
import CustomSelectInput from "../../../ui/input/CustomSelectInput/CustomSelectInput";
import CustomTextArea from "../../../ui/input/CustomTextArea/CustomTextArea";
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
import TextButton from "../../../ui/button/TextButton/TextButton";
/* Import local pages and component ends */

// Styles
import "./style.scss";

/* Component starts */

const AddInternalMaintenance = (props) => {
  /* Props destructuring starts */
  const { onSuccess, onCancel } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const formRef = useRef();
  const [docList, setDocList] = useState([]);
  const [initialFormValues, setInitialFormValues] = useState(initialValues);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const { propertyId } = useParams();

  const maintenanceLocations = useSelector(getInternalMaintenanceLocations);
  const maintenanceCategories = useSelector(getInternalMaintenanceCategories);
  const maintenanceSubCategories = useSelector(
    getInternalMaintenanceSubCategories
  );
  const maintenanceInnerCategories = useSelector(
    getInternalMaintenanceInnerCategories
  );
  const severityList = useSelector(getSeverities);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // Form Validations
  const formValidation = (values) => {
    return validations(
      values,
      docList,
      maintenanceSubCategories.length,
      maintenanceInnerCategories.length
    );
  };

  const onCategoryChange = async (v) => {
    formRef.current.setFieldValue("subcategory", "");
    formRef.current.setFieldValue("innercategory", "");
    if (v) {
      await dispatch(fetchInternalMaintenanceSubCategories(v._id));
    }
  };

  const onSubCategoryChange = async (v) => {
    formRef.current.setFieldValue("innercategory", "");
    if (v) {
      await dispatch(fetchInternalMaintenanceInnerCategories(v._id));
    }
  };

  const onSubmit = async (values) => {
    const {
      location,
      category,
      subcategory,
      innercategory,
      severity,
      description,
    } = values;
    // Creating or collecting payload data to be sent
    const payload = {
      property_id: propertyId,
      location,
      category,
      severity,
      description,
    };
    if (maintenanceSubCategories.length > 0) {
      payload["subcategory"] = subcategory;
    }
    if (maintenanceInnerCategories.length > 0) {
      payload["innercategory"] = innercategory;
    }

    const document = [];

    docList.forEach((item, index) => {
      if (values[`document_${item}`]) {
        document.push(values[`document_${item}`]);
      }
    });
    payload["document"] = document;

    // Backend response. Try, Catch
    try {
      const result = await dispatch(createInternalMaintenanceReq(payload));

      console.log(result,'[RES]', result.meta);
      

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          onSuccess();
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    dispatch(fetchInternalMaintenanceLocations());
    dispatch(fetchInternalMaintenanceCategories());
    dispatch(fetchUnits({ property_id: propertyId }));
    dispatch(fetchSeverityList());
  }, []);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="add-tenant-maintenance-module-wrapper">
      <h2 className="heading text-center font_xxl mb-5">Request Maintenance</h2>

      <div className="add-new-form">
        <Formik
          onSubmit={onSubmit}
          initialValues={initialFormValues}
          validate={formValidation}
          validateOnBlur={true}
          innerRef={formRef}
          enableReinitialize={true}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="form-wrapper">
                <div className="form-group">
                  <CustomSelectInput
                    name="location"
                    label="Location"
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    valueExtractor={(item) => item?.name}
                    optionValueExtractor={(item) => item?._id}
                    options={maintenanceLocations}
                    touched={touched.location}
                    errors={errors.location}
                    value={values.location}
                  />
                </div>

                <div className="form-group">
                  <CustomSelectInput
                    name="category"
                    label="Categories"
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    customOnchange={onCategoryChange}
                    valueExtractor={(item) => item?.category_name}
                    optionValueExtractor={(item) => item?._id}
                    options={maintenanceCategories}
                    touched={touched.category}
                    errors={errors.category}
                    value={values.category}
                  />
                </div>

                {values.category !== "" && (
                  <div className="form-group">
                    <CustomSelectInput
                      name="subcategory"
                      label="Sub Category"
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      customOnchange={onSubCategoryChange}
                      valueExtractor={(item) => item?.subcategory_name}
                      optionValueExtractor={(item) => item?._id}
                      options={maintenanceSubCategories}
                      touched={touched.subcategory}
                      errors={errors.subcategory}
                      value={values.subcategory}
                    />
                  </div>
                )}

                {values.subcategory !== "" &&
                  maintenanceInnerCategories.length > 0 && (
                    <div className="form-group">
                      <CustomSelectInput
                        name="innercategory"
                        label="Inner Category"
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        valueExtractor={(item) => item?.innercategory_name}
                        optionValueExtractor={(item) => item?._id}
                        options={maintenanceInnerCategories}
                        touched={touched.innercategory}
                        errors={errors.innercategory}
                        value={values.innercategory}
                      />
                    </div>
                  )}

                <div className="form-group">
                  <CustomSelectInput
                    name="severity"
                    label="Severity"
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    valueExtractor={(item) => item?.severity}
                    optionValueExtractor={(item) => item?._id}
                    options={severityList}
                    touched={touched.severity}
                    errors={errors.severity}
                    value={values.severity}
                  />
                </div>

                <div className="form-group form-group-full">
                  <CustomTextArea
                    name="description"
                    label="Description"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    touched={touched.description}
                    errors={errors.description}
                    value={values.description}
                    required={true}
                  />
                </div>

                <div className="doc-list-wrapper">
                  <h3 className="font_m fw_6">Photos and Videos</h3>
                  {/* {docList.map((item, index) => (
                    <div className="form-group" key={index}>
                      <CustomFileInput
                        name={`document_${item}`}
                        label="Document"
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        formikState={true}
                        touched={touched[`document_${item}`]}
                        errors={errors[`document_${item}`]}
                        value={values[`document_${item}`]}
                        required={true}
                      />
                    </div>
                  ))} */}

                  <div className="doc-list">
                    {docList.length === 0 && (
                      <h3 className="text-center font_m font_yellow fw_6 no-attachments">
                        No photos or videos attached!
                      </h3>
                    )}

                    {docList.map((item, index) => (
                      <div className="form-group doc" key={index}>
                        <div className="file-input-wrapper">
                          <CustomFileInput
                            name={`document_${item}`}
                            label="Document"
                            setFieldValue={setFieldValue}
                            setFieldTouched={setFieldTouched}
                            formikState={true}
                            touched={touched[`document_${item}`]}
                            errors={errors[`document_${item}`]}
                            value={values[`document_${item}`]}
                            required={true}
                            acceptable={true}
                            acceptType="image/*,video/*"
                          />
                        </div>

                        {docList.length > 1 && (
                          <div className="remove-btn-wrapper">
                            <TextButton
                              className="button font_m fw_6"
                              variant="red"
                              // onClick={() => {
                              //   removeDoc(
                              //     formRef,
                              //     setInitialFormValues,
                              //     docList,
                              //     setDocList,
                              //     index
                              //   );
                              // }}
                              onClick={() => {
                                removeDoc(
                                  formRef,
                                  docList,
                                  setInitialFormValues,
                                  setDocList,
                                  index
                                );
                              }}
                            >
                              Remove
                            </TextButton>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>

                  <div className="doc_action_btn-wrapper add-btn-wrapper">
                    {/* {(docList.length === 0 ||
                      values[`document_${docList.length - 1}`]) && (
                      <TextButton
                        className="button font_m fw_6"
                        onClick={() => {
                          addDoc(
                            formRef,
                            docList,
                            setInitialFormValues,
                            setDocList
                          );
                        }}
                      >
                        + Add
                      </TextButton>
                    )} */}

                    {(docList.length === 0 ||
                      values[`document_${docList[docList.length - 1]}`]) && (
                      <CustomButton
                        className="font_m fw_6 add-btn"
                        variant="outline"
                        size="s"
                        onClick={() => {
                          addDoc(
                            formRef,
                            docList,
                            setInitialFormValues,
                            setDocList
                          );
                        }}
                      >
                        + Add
                      </CustomButton>
                    )}

                    {/* {docList.length > 0 && (
                      <TextButton
                        className="button font_m fw_6"
                        variant="red"
                        onClick={() => {
                          removeDoc(
                            formRef,
                            docList,
                            setInitialFormValues,
                            setDocList
                          );
                        }}
                      >
                        Remove
                      </TextButton>
                    )} */}
                  </div>
                </div>
              </div>

              <div className="button-wrapper text-center">
                <>
                  <CustomButton type="submit" size="l">
                    Submit
                  </CustomButton>
                  <TextButton
                    type="button"
                    onClick={onCancel}
                    className="font_m fw_6"
                  >
                    Cancel
                  </TextButton>
                </>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};
/* Component ends */

export default AddInternalMaintenance;
