/*
 * Slice to handle Visitor Log Module
 */

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { axiosInstance } from "../axios";
import { API_URL } from "../../config/devKeys";
import { PLATFORM } from "../../platformUtil";
import {
  setLoading,
  setShowUploadProgress,
  setUploadProgress,
} from "./unpersistedSlice";

const initialState = {
  visitorLogs: [],
};

// FETCH Visitors LOG LIST - Client Based On Property
export const fetchVisitorLogsList = createAsyncThunk(
  "logbook/list",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        "Content-Type": "application/json",
      };
      const response = await fetch(API_URL + "/logbook/list", {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          property_id: payload?.property_id,
          page: payload?.page,
        }),
      });

      const data = await response.json();
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// ADD / Register VISITORS PASS - Client Side
export const addVisitorsPass = createAsyncThunk(
  "logbook/add",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const formData = new FormData();
      Object.keys(payload).map((key) => {
        if (key === "upload_photo") {
          if (PLATFORM !== "web") {
            formData.append("upload_photo", {
              name: payload.upload_photo.name,
              type: payload.upload_photo.type,
              uri:
                PLATFORM === "android"
                  ? payload.upload_photo.uri
                  : payload.upload_photo.uri.replace("file://", ""),
            });
          } else {
            formData.append("upload_photo", payload[key], payload[key].name);
          }
        } else if (key === "doc_files") {
          payload[key].forEach((item) => {
            if (PLATFORM !== "web") {
              formData.append(key, {
                name: item.name,
                type: item.type,
                uri:
                  PLATFORM === "android"
                    ? item.uri
                    : item.uri.replace("doc_files://", ""),
              });
            } else {
              formData.append(key, item, item.name);
            }
          });
        } else {
          formData.append(
            key,
            typeof payload[key] === "object"
              ? JSON.stringify(payload[key])
              : payload[key]
          );
        }
      });

      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }

      // const response = await fetch(API_URL + "/logbook/add", {
      //   method: "POST",
      //   headers: headers,
      //   body: formData,
      // });
      // const data = await response.json();
      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "POST",
        url: "/logbook/add",
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      // const data = await response.json();

      const { data } = await axiosInstance.request(config);

      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error.message);
    }
  }
);

// GET Complaint Data Based on ID - Tenant Side
export const fetchVisitorPassBasedOnId = createAsyncThunk(
  "logbook-pass/get",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(API_URL + `/logbook/get?id=${payload}`, {
        method: "GET",
        headers: headers,
      });

      const data = await response.json();
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// SIGN-OUT VISITORS PASS - Client Side
export const signOutVisitorsPass = createAsyncThunk(
  "logbook/update",

  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(
        API_URL + `/logbook/update?_id=${payload?.id}`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            notes: payload?.notes,
          }),
        }
      );

      const data = await response.json();
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const visitorLogBook = createSlice({
  name: "visitorLog",
  initialState,
  extraReducers: {
    [fetchVisitorLogsList.rejected]: (state, action) => {
      state.visitorLogs = [];
    },
    [fetchVisitorLogsList.fulfilled]: (state, action) => {
      state.visitorLogs = action.payload;
    },
  },
});

// To Get The Visitors Log List
export const getVisitorLogsList = (state) => state.visitorLog.visitorLogs;

export default visitorLogBook.reducer;
