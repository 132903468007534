/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { revisionStatus } from "../helper";
import GLOBAL from "../../../../../setup/constants/global";
import moment from "moment";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
/* Import local pages and component ends */

/* Component starts */
const RevisionMessage = (props) => {
  /* Props destructuring starts */
  const { contract } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="revision-message bg_grey">
      <h4 className="font_yellow fw_5 font_m">Revision Request(s)</h4>

      {contract.actions.map((item, index) => (
        <div key={index}>
          {index !== 0 && <hr className="line" />}
          <div className="message">
            <div className="box">
              <p className="key font_s font_grey fw_5">Message</p>
              <div className="value fw_5 font_s">{item.message}</div>
            </div>
            <div className="box">
              <p className="key font_s font_grey fw_5">Response</p>
              <div className="value fw_5 font_s">
                {item?.messageRes ? item.messageRes : "-"}
              </div>
            </div>
            <div className="box">
              <p className="key font_s font_grey fw_5">Requested At</p>
              <div className="value fw_5 font_s">
                {moment(item.createdAt).format(DATE_TIME_FORMAT)}
              </div>
            </div>
            <div className="box">
              <p className="key font_s font_grey fw_5">Status</p>
              <div className="value font_s fw_5">
                <span className={`${revisionStatus(item.status).color}`}>
                  {revisionStatus(item.status).text}
                </span>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
/* Component ends */

export default RevisionMessage;
