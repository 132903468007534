import React from "react";
import { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GLOBAL from "../../../../setup/constants/global";

/* Import react bootstrap component starts */
import { Modal } from "react-bootstrap";
import { Formik } from "formik";
/* Import react bootstrap component ends */

import {
  showSuccessAlert,
  showErrorAlert,
} from "../../../../setup/store/slices/globalAlertSlice";
import { updatePropertySettings } from "../../../../setup/store/slices/propertySlice";

/* Import image and SVG starts */
import {
  SuccessIconOutlinedBlue,
  XGreen,
  XWhite,
} from "../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomTextInput from "../../../ui/input/CustomTextInput/CustomTextInput";
import CustomPhoneInput from "../../../ui/input/CustomPhoneInput/CustomPhoneInput";
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
import IconButton from "../../../ui/button/IconButton/IconButton";
/* Import local pages and component ends */

import "./style.scss";
import { getUserTheme } from "../../../../setup/store/slices/authSlice";

/* Component starts */
const PropertySettingsDefaultModal = (props) => {
  /* Props destructuring starts */
  const { showModal, property_id, settings, size = "md", onClose } = props;
  /* Props destructuring ends */
  const [initialFormValues, setInitialFormValues] = useState({
    property_contact_number: "",
    property_email: "",
    property_website: "",
  });
  const customPhoneNum = useRef({ code: "", num: "" });
  const dispatch = useDispatch();

  const userTheme = useSelector(getUserTheme);

  const validations = () => {};

  const onSubmit = async (values) => {
    // Creating or collecting payload data to be sent
    const payload = {
      property_id: property_id,
      ...values,
      property_contact_number: `${customPhoneNum.current.code} ${customPhoneNum.current.num}`,
    };
    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      const result = await dispatch(updatePropertySettings(payload));
      console.log("Response", result);
      onClose();
      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result?.payload || "",
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result?.payload?.message || "",
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  useEffect(() => {
    if (
      settings &&
      settings?.property_contact_number &&
      settings?.property_email &&
      settings?.property_website
    ) {
      setInitialFormValues({
        property_contact_number: settings?.property_contact_number || "",
        property_email: settings?.property_email || "",
        property_website: settings?.property_website || "",
      });
      const number = settings?.property_contact_number?.split(" ");
      customPhoneNum.current = { code: number[0], num: number[1] };
    }
  }, [settings]);
  /* ************* Component rendering. JSX code ************* */
  return (
    <Modal
      size={size}
      centered
      show={showModal}
      className="success-modal-wrapper"
    >
      <Modal.Body
        className={`${userTheme === "dark" ? "bg_darkTheme" : "bg_lightTheme"}`}
      >
        <div className="modal-header-custom">
          <h2 className="text-center font_blue font_xl">
            <div className="close-btn-wrapper text-end">
              <IconButton onClick={onClose}>
                <img src={XGreen} className="close" alt="" />
              </IconButton>
            </div>
            Edit Property Defaults
          </h2>
        </div>
        <div className="modal-body-custom">
          <div>
            <Formik
              onSubmit={onSubmit}
              initialValues={initialFormValues}
              validate={(values) => validations(values)}
              validateOnBlur={true}
              enableReinitialize={true}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                setFieldTouched,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div
                    //className="form-group"
                    className={`form-group ${
                      (userTheme === "light" || userTheme === "automatic") &&
                      "otherTheme"
                    } `}
                  >
                    <CustomPhoneInput
                      name="property_contact_number"
                      label={"Phone Number"}
                      customPhoneNum={customPhoneNum}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      value={values.property_contact_number}
                      countryCodeEditable={true}
                      errors={errors.property_contact_number}
                      touched={touched.property_contact_number}
                      required={false}
                    />
                  </div>
                  <div className="form-group">
                    <CustomTextInput
                      name="property_email"
                      label="Email"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched.property_email}
                      errors={errors.property_email}
                      value={values.property_email}
                      required={true}
                    />
                  </div>
                  <div className="form-group">
                    <CustomTextInput
                      name="property_website"
                      label="Website"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched.property_website}
                      errors={errors.property_website}
                      value={values.property_website}
                      required={false}
                    />
                  </div>

                  <div className="btn-wrapper">
                    <CustomButton type="submit" size="m">
                      Save
                    </CustomButton>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
        <div className="modal-footer-custom"></div>
      </Modal.Body>
    </Modal>
  );
};
/* Component ends */

export default PropertySettingsDefaultModal;
