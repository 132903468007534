/**
 *
 */

import React, { useState } from "react";

/* Import configuration starts */
import moment from "moment";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Table } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { DocumentGreen } from "../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../../../ui/button/IconButton/IconButton";
import FileViewerModal from "../../../../ui/modal/FileViewerModal/FileViewerModal";

/* Import local pages and component ends */

/* Component starts */
const ClearenceDocViewTable = (props) => {
  /* Props destructuring starts */
  const { moveOut, doc, auditLogs, moveoutStatus } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [file, setFile] = useState({});
  const [showFile, setShowFile] = useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="">
      <h3 className="table-heading font_m">Clearance Letter</h3>

      <div className="custom-table-wrapper">
        <Table className="custom-table font_s unit-table" borderless responsive>
          <thead>
            <tr>
              <th></th>
              <th>
                {
                  moveOut?.tenant_application
                    ?.party_signed_by_on_clearence_agrmnt
                }{" "}
                Signature Date and Time
              </th>
              <th>File</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Clearance Letter Document</td>
              <td>
                {moveOut?.tenant_application
                  ?.party_clearence_signature_timestamp
                  ? moment(
                      moveOut?.tenant_application
                        ?.party_clearence_signature_timestamp
                    ).format(DATE_TIME_FORMAT)
                  : "-"}
              </td>
              <td>
                <IconButton
                  onClick={() => {
                    setFile(doc);
                    setShowFile(true);
                  }}
                >
                  <img src={DocumentGreen} className="file-icon" alt="file" />
                </IconButton>
              </td>
            </tr>
            <tr>
              <td>Audit Logs</td>
              <td>-</td>
              <td>
                <IconButton
                  onClick={() => {
                    setFile(auditLogs);
                    setShowFile(true);
                  }}
                >
                  <img src={DocumentGreen} className="file-icon" alt="file" />
                </IconButton>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>

      {moveoutStatus === "Approved" && (
        <h3 className="unit-status-heading font_xl fw_5 font_blue text-center">
          Unit is successfully released with clearence letter.
        </h3>
      )}

      <FileViewerModal
        show={showFile}
        file={file?.url}
        type={file?.type}
        onClose={() => setShowFile(false)}
      />
    </div>
  );
};
/* Component ends */

export default ClearenceDocViewTable;
