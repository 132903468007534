/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useNavigate } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomButton from "../../../../../components/ui/button/CustomButton/CustomButton";
import CancelApplicationBlock from "../../../Unit/UnitSliderTenantHome/sub-component/CancelApplicationBlock";
/* Import local pages and component ends */

/* Component starts */
const ApplicationPendingApplication = (props) => {
  /* Props destructuring starts */
  const { unit, fetchTenantUnitsNew } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="application-pending-application">
      <h3 className="text-center font_xxl fw_6">
        Details Required For Onboarding
      </h3>
      <p className="font_l fw_5 font_grey text-center mt-4">
        Please complete all required steps for the onboarding process
      </p>
      <div className="button-wrapper">
        <CustomButton
          size="l"
          className="mt-5"
          onClick={() =>
            navigate(
              `/tenant/unit/${unit?._id}/application/${unit.application_id}`,
              {
                state: { unit: unit },
              }
            )
          }
        >
          Start
        </CustomButton>
      </div>

      <CancelApplicationBlock
        unit={unit}
        fetchTenantUnitsNew={fetchTenantUnitsNew}
      />
    </div>
  );
};
/* Component ends */

export default ApplicationPendingApplication;
