/**
 * This component is a Modal that is shown on success
 */

import React, { useState, useEffect, useRef } from "react";

/* Import react bootstrap component starts */
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import GLOBAL from "../../../../setup/constants/global";
import { Formik } from "formik";
/* Import react bootstrap component ends */

/* Import redux slices starts */
import { getUserTheme } from "../../../../setup/store/slices/authSlice";
/* Import redux slices ends */

/* Import image and SVG starts */
import { SuccessIconOutlinedBlue } from "../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomRadioInput from "../../../ui/input/CustomRadioInput/CustomRadioInput";
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
import CustomCurrencyInput from "../../../ui/input/CustomCurrencyInput/CustomCurrencyInput";
import CustomTextArea from "../../../ui/input/CustomTextArea/CustomTextArea";
import CustomSelectInput from "../../../ui/input/CustomSelectInput/CustomSelectInput";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const CostCoveredByModal = (props) => {
  /* Props destructuring starts */
  const {
    showModal,
    title = "Cost Covered By",
    size = "lg",
    currency,
    onClose,
    onSubmit,
    totalQuoatationCost,
    requestNumber,
    quoteNumber,
    isT1,
    closeOnSubmit = true,
    isInternalMaintenance = false,
    showTenantlist = false,
    tenants = [],
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [costCoveredBy, setCostCoveredBy] = useState(GLOBAL.USER_ROLE.PMC);
  const [initialFormValues, setInitialFormValues] = useState();
  const [selectedTenant, setSelectedTenant] = useState(null);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const userTheme = useSelector(getUserTheme);
  const formikRef = useRef();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const validations = (values) => {
    const errors = {};

    if (values?.amount === "") {
      errors.amount = "Amount is required";
    }

    if (values?.description === "") {
      errors.description = "Description is required";
    }

    if (showTenantlist && tenants.length > 0 && values?.tenant_id === "") {
      errors.tenant_id = "Please select a Tenant";
    }

    return errors;
  };

  const onFinalizing = () => {
    if (costCoveredBy === GLOBAL?.USER_ROLE?.TENANT) {
      formikRef?.current?.handleSubmit();
      closeOnSubmit && onClose();
    } else {
      onSubmit({ tenant_covers_cost: false });
      closeOnSubmit && onClose();
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    if (typeof showTenantlist === "boolean") {
      if (showTenantlist && tenants.length > 0) {
        setInitialFormValues({
          amount: "",
          description: "",
          tenant_id: "",
        });
      } else {
        setInitialFormValues({
          amount: "",
          description: "",
        });
      }
    }
  }, [showTenantlist]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <Modal
      size={size}
      centered
      show={showModal}
      className={`cost-covered-by-modal-wrapper `}
    >
      <Modal.Body
        className={`${userTheme === "dark" ? "bg_darkTheme" : "bg_lightTheme"}`}
      >
        <div className="modal-header-custom">
          <h2
            className={`text-center font_xl fw_6 ${
              userTheme !== "dark" && "font_blue"
            } font_white`}
          >
            {title}
          </h2>
        </div>
        <div className="modal-body-custom">
          <p
            className={`font_s fw_5 please-select-text ${
              userTheme !== "dark" && "font_grey"
            }`}
          >
            Please select who is going to cover the cost for the items or
            services mentioned in the quoatation.
          </p>
          <div className="cost-covered-by-group">
            <CustomRadioInput
              className="radio-group"
              labelClass="font_xs fw_5"
              label={isT1 ? "Owner" : "Property Management Company"}
              isSelected={costCoveredBy === GLOBAL.USER_ROLE.PMC}
              onSelecting={() => setCostCoveredBy(GLOBAL.USER_ROLE.PMC)}
            />
            {((typeof showTenantlist === "boolean" &&
              showTenantlist &&
              tenants.length > 0) ||
              (typeof showTenantlist === "boolean" && !showTenantlist)) && (
              <CustomRadioInput
                className="radio-group"
                labelClass="font_xs fw_5"
                label="Tenant"
                isSelected={costCoveredBy === GLOBAL.USER_ROLE.TENANT}
                onSelecting={() => {
                  setCostCoveredBy(GLOBAL.USER_ROLE.TENANT);
                }}
              />
            )}
          </div>

          {costCoveredBy === GLOBAL.USER_ROLE.TENANT && (
            <div className="generate-invoice-group">
              <h3
                className={`generate-invoice-heading text-center font_xl ${
                  userTheme !== "dark" && "font_grey"
                } fw_6`}
              >
                Generate Invoice For Tenant
              </h3>

              <p className="quotation-description font_xs font_yellow fw_6">
                Generate invoice for Quotation Number - {quoteNumber}. The total
                cost is {parseInt(totalQuoatationCost)?.toLocaleString("en-US")}{" "}
                ({currency})
              </p>

              <div className="generate-invoice-form">
                <Formik
                  onSubmit={(values) => {
                    const payload = { tenant_covers_cost: true, ...values };
                    if (
                      typeof showTenantlist === "boolean" &&
                      showTenantlist &&
                      tenants.length > 0 &&
                      selectedTenant
                    ) {
                      payload["tenant_id"] = selectedTenant?.tenant_id;
                      payload["unit_id"] = selectedTenant?.unit_id;
                    }
                    onSubmit(payload);
                  }}
                  initialValues={initialFormValues}
                  validate={(values) => validations(values)}
                  validateOnBlur={true}
                  innerRef={formikRef}
                  enableReinitialize={true}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    setFieldValue,
                    setFieldTouched,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="form-wrapper">
                        <div className="form-group">
                          <CustomCurrencyInput
                            name="amount"
                            label={`Amount (${currency})`}
                            setFieldValue={setFieldValue}
                            setFieldTouched={setFieldTouched}
                            touched={touched.amount}
                            errors={errors.amount}
                            value={values.amount}
                            currency={currency}
                            required={true}
                          />
                        </div>
                        {typeof showTenantlist === "boolean" &&
                          showTenantlist &&
                          tenants.length > 0 && (
                            <div className="form-group">
                              <CustomSelectInput
                                label="Type"
                                name={`tenant_id`}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                options={tenants}
                                value={values[`tenant_id`]}
                                errors={errors[`tenant_id`]}
                                touched={touched[`tenant_id`]}
                                valueExtractor={(d) => d?.tenant_name}
                                optionValueExtractor={(d) => `${d?.unit_id}`}
                                customOnchange={(v) => {
                                  if (v) {
                                    setSelectedTenant(v);
                                  } else {
                                    setSelectedTenant(null);
                                  }
                                }}
                              />
                            </div>
                          )}

                        {showTenantlist &&
                          tenants.length > 0 &&
                          selectedTenant && (
                            <div className="tenant-details">
                              <div className="custom-line">
                                <p
                                  className={`label font_s fw_5 ${
                                    userTheme !== "dark" && "font_grey"
                                  }`}
                                >
                                  Tenant Name
                                </p>
                                <p className="value font_s font_blue fw_5">
                                  {selectedTenant?.tenant_name}
                                </p>
                              </div>
                              <div className="custom-line">
                                <p
                                  className={`label font_s fw_5 ${
                                    userTheme !== "dark" && "font_grey"
                                  }`}
                                >
                                  Unit Name
                                </p>
                                <p className="value font_s font_blue fw_5">
                                  {selectedTenant?.unit_name}
                                </p>
                              </div>
                              <div className="custom-line">
                                <p
                                  className={`label font_s fw_5 ${
                                    userTheme !== "dark" && "font_grey"
                                  }`}
                                >
                                  Unit Number
                                </p>
                                <p className="value font_s font_blue fw_5">
                                  {selectedTenant?.unit_number}
                                </p>
                              </div>
                            </div>
                          )}

                        <div className="form-group form-group-full">
                          <CustomTextArea
                            name="description"
                            label="Description"
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            touched={touched.description}
                            errors={errors.description}
                            value={values.description}
                            required={true}
                          />
                        </div>
                      </div>

                      {/* <div className="modal-footer-custom">
                      <CustomButton onClick={onFinalizing} size="m">
                        Submit
                      </CustomButton>
                      <CustomButton
                        onClick={onClose}
                        size="m"
                        variant="outline"
                      >
                        Close
                      </CustomButton>
                    </div> */}
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          )}
        </div>
        <div className="modal-footer-custom">
          <CustomButton onClick={onFinalizing} size="m">
            Submit
          </CustomButton>
          <CustomButton onClick={onClose} size="m" variant="red">
            Close
          </CustomButton>
        </div>
      </Modal.Body>
    </Modal>
  );
};
/* Component ends */

export default CostCoveredByModal;
