/**
 *
 */

import React, { useState, useEffect, useRef } from "react";

/* Import configuration starts */
import { useDispatch, useSelector } from "react-redux";
import { Formik, FieldArray } from "formik";
import CustomDateInput from "../../../ui/input/CustomDateInput/CustomDateInput";
import CustomTimeInput from "../../../ui/input/CustomTimeInput/CustomTimeInput";
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
import TextButton from "../../../ui/button/TextButton/TextButton";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */

/* Import local pages and component ends */

import "./style.scss";
import CustomCheckInput from "../../../ui/input/CustomCheckInput/CustomCheckInput";

/* Component starts */
const CreateSchedule = (props) => {
  /* Props destructuring starts */
  const { onCancel } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [initialFormValues, setInitialFormValues] = useState({
    //     startDate: new Date(),
    //     endDate: new Date(),
    time: "",
  });

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [monday, setMonday] = useState(false);
  const [tuesday, setTuesday] = useState(false);
  const [wednesday, setWednesday] = useState(false);
  const [thursday, setThursday] = useState(false);
  const [friday, setFriday] = useState(false);
  const [saturday, setSaturday] = useState(false);
  const [sunday, setSunday] = useState(false);

  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // Form validation
  //   const formValidation = (value) => {
  //     const err = {};

  //     if (value.time === "") {
  //       err.time = "asd";
  //     }

  //     return err;
  //   };

  // On updating commercial detail
  const onSubmit = (value) => {
    console.log(value);
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="schedule-module-wrapper">
      <h2 className="heading text-center font_xxl mb-5">Create Schedule</h2>

      <div className="add-new-form">
        <Formik
          onSubmit={onSubmit}
          initialValues={initialFormValues}
          //   validate={formValidation}
          validateOnBlur={true}
          enableReinitialize={true}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="form-wrapper">
                {/* Creating Schedule */}
                <>
                  {/* Input for Start Date */}
                  <div className="form-group">
                    <CustomDateInput
                      label="Start Date"
                      value={startDate}
                      minDate={false}
                      customOnchange={(date) => setStartDate(date)}
                    />
                  </div>

                  {/* Input for End Date */}
                  <div className="form-group">
                    <CustomDateInput
                      label="End Date"
                      value={endDate}
                      minDate={false}
                      customOnchange={(date) => setEndDate(date)}
                    />
                  </div>

                  {/* Input for Start Date */}
                  <div className="form-group">
                    <CustomDateInput
                      label="Start Date"
                      value={startDate}
                      minDate={false}
                      customOnchange={(date) => setStartDate(date)}
                    />
                  </div>

                  {/* Input for End Date */}
                  <div className="form-group">
                    <CustomDateInput
                      label="End Date"
                      value={endDate}
                      minDate={false}
                      customOnchange={(date) => setEndDate(date)}
                    />
                  </div>

                  <div className="form-group form-group-full week-off">
                    <div>
                      <span className="font_l fw_4">Week off</span>
                    </div>

                    <div className="week-check-box">
                      <div className="check">
                        <CustomCheckInput
                          value={monday}
                          setValue={setMonday}
                          //   customOnchange={(monday) => setSelectedWeekDay(monday)}
                        />
                        <span className="font_s check-text mx-2">Monday</span>
                      </div>

                      <div className="check">
                        <CustomCheckInput
                          value={tuesday}
                          setValue={setTuesday}
                        />
                        <span className="font_s check-text mx-2">Tuesday</span>
                      </div>
                      <div className="check">
                        <CustomCheckInput
                          value={wednesday}
                          setValue={setWednesday}
                        />
                        <span className="font_s check-text mx-2">
                          Wednesday
                        </span>
                      </div>
                      <div className="check">
                        <CustomCheckInput
                          value={thursday}
                          setValue={setThursday}
                        />
                        <span className="font_s check-text mx-2">Thursday</span>
                      </div>
                      <div className="check">
                        <CustomCheckInput value={friday} setValue={setFriday} />
                        <span className="font_s check-text mx-2">Friday</span>
                      </div>
                      <div className="check">
                        <CustomCheckInput
                          value={saturday}
                          setValue={setSaturday}
                        />
                        <span className="font_s check-text mx-2">Saturday</span>
                      </div>
                      <div className="check">
                        <CustomCheckInput value={sunday} setValue={setSunday} />
                        <span className="font_s check-text mx-2">Sunday</span>
                      </div>
                    </div>
                  </div>
                </>
              </div>

              <div className="button-wrapper text-center">
                <>
                  <CustomButton type="submit" size="l">
                    Create Schedule
                  </CustomButton>
                  <TextButton
                    type="button"
                    onClick={onCancel}
                    className="font_m fw_6"
                  >
                    Cancel
                  </TextButton>
                </>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};
/* Component ends */

export default CreateSchedule;
