/**
 *
 */

import React, { useState, useEffect } from "react";
import IconButton from "../../../../ui/button/IconButton/IconButton";
import { Table } from "react-bootstrap";
import {
  DocFilledWhite,
  EyeFilledBlue,
} from "../../../../../setup/constants/images";

/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
/* Import local pages and component ends */

/* Component starts */
const TenantHistory = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  // Dummy Data
  const propertyData = [
    {
      id: 1,
      property: "Gina Burke",
      unit_no: "U102",
      type: "Studio",
      expiry: "03/02/2022",
    },
    {
      id: 2,
      property: "George Caroll",
      unit_no: "F102",
      type: "Villa",
      expiry: "03/12/2023",
    },
  ];

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="tenantHistory-wrapper">
      {/* Tenant History Table */}
      <div className="tenant-wrapper">
        <p className="font_l fw_3">DGRNTE Tenant History</p>

        {/* ID Verfication Table */}
        <div className="custom-table-wrapper">
          <Table className="custom-table font_s" borderless responsive>
            {/* Table Heading */}
            <thead>
              <tr>
                <th>Property</th>
                <th>Unit No.</th>
                <th>Type Of Unit</th>
                <th>Contract Expiry Date</th>
                <th>Image</th>
              </tr>
            </thead>
            {/* Table Body */}
            <tbody>
              {propertyData.map((item) => (
                <tr key={item.id}>
                  {/* Property Name */}
                  <td>{item?.property}</td>

                  {/* Unit No. */}
                  <td>{item?.id}</td>

                  {/* Type Of Unit */}
                  <td>{item?.type}</td>

                  {/* Contract Expiry Date */}
                  <td>{item?.expiry}</td>

                  {/* ID Image */}
                  <td className="text-start txt-nowrap">
                    <IconButton
                      // onClick={() => onViewContract(item)}
                      className="action-button"
                    >
                      <img src={EyeFilledBlue} alt="View ID" />
                    </IconButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>

      {/* Non Tenant Table */}
      <div className="nonTenant-wrapper">
        <p className="font_l fw_3">NON - DGNRTE Tenant History</p>

        {/* ID Verfication Table */}
        <div className="custom-table-wrapper">
          <Table className="custom-table font_s" borderless responsive>
            {/* Table Heading */}
            <thead>
              <tr>
                <th>Property</th>
                <th>Unit No</th>
                <th>Type Of Unit</th>
                <th>Contract Expiry Date</th>
                <th>Actions</th>
              </tr>
            </thead>
            {/* Table Body */}
            <tbody>
              {propertyData.map((item) => (
                <tr key={item.id}>
                  {/* Property */}
                  <td>{item?.property}</td>
                  {/* Unit No */}
                  <td>{item?.unit_no}</td>
                  {/* Type Of Unit */}
                  <td>{item?.type}</td>
                  {/* COontract Expiry Date */}
                  <td>{item?.expiry}</td>

                  {/* ID Image */}
                  <td className="text-start txt-nowrap">
                    <IconButton
                      // onClick={() => onViewContract(item)}
                      className="action-button"
                    >
                      <img src={EyeFilledBlue} alt="View ID" />
                    </IconButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};
/* Component ends */

export default TenantHistory;
