/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import moment from "moment";
import GLOBAL from "../../../../../setup/constants/global";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { logTitle, canTenantRateService, canTenantReschedule } from "../helper";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../../../setup/store/slices/globalAlertSlice";
import {
  partyTenantRecheduleMaintenance,
  t2TenantRecheduleMaintenance,
  t2partyTenantRecheduleMaintenance,
  t2tenantRespondToPartyMaintenance,
  tenantRescheduleMaintenance,
  tenantRespondToPartyMaintenance,
  tenantRespondToT1Maintenance,
  tenantRespondToT2Maintenance,
  tenantRespondtoMaintenance,
  tenantRescheduleT1Maintenance,
} from "../../../../../setup/store/slices/maintenanceSlice";
import { addMaintenanceRating } from "../../../../../setup/store/slices/ratingSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import RateAndReviewModal from "../../RateAndReviewModal/RateAndReviewModal";
import CustomButton from "../../../../ui/button/CustomButton/CustomButton";
import RescheduleModal from "../../RescheduleModal/RescheduleModal";
import AddDateModal from "../../../../ui/modal/AddDateModal/AddDateModal";
import Image from "../../../../ui/other/Image/Image";
import FileViewerModal from "../../../../ui/modal/FileViewerModal/FileViewerModal";
import { EyeFilledBlue } from "../../../../../setup/constants/images";
import IconButton from "../../../../ui/button/IconButton/IconButton";
import { pathNamePopped } from "../../../../../setup/utils/global-helper";
/* Import local pages and component ends */

/* Component starts */
const SimpleLog = (props) => {
  /* Props destructuring starts */
  const {
    property,
    maintenance_id,
    maintenance,
    item,
    status,
    isT1,
    takeAction,
    user,
    assigned_to_role,
    property_mgmt_model,
    isT2,
    company,
    technician,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [showRateReviewModal, setShowRateReviewModal] = useState(false);
  const [rescheduleModal, setRescheduleModal] = useState(false);

  const [showFileViewer, setShowFileViewer] = useState(false);
  const [file, setFile] = useState({});
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const name = item?.requester
    ? `${item?.requester?.first_name} ${item?.requester?.last_name}`
    : `${maintenance?.tenant_id?.first_name} ${maintenance?.tenant_id?.last_name}`;
  const role = item?.requester_role?.name;
  const createdAt = item?.createdAt;
  const action = item?.action;
  const currentStatus = item?.current_status;
  const logType = item?.log_type;
  const comment = item?.comment;
  const maintenanceType = maintenance?.type;
  const unitCard = maintenance?.unit_card;
  const quote = maintenance?.quotes;
  const applicationID = maintenance?.application_id;
  const reportData = item?.data;

  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // On tenant rate service
  const onRateService = async (value) => {
    const {
      technician_review,
      technician_rating,
      service_review,
      service_rating,
    } = value;
    // Creating or collecting payload data to be sent
    const payload = {
      maintenance_id,
      maintenanceType,
      company: company?._id,
      technician: technician?._id,
      ...value,
    };
    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      let result = await dispatch(addMaintenanceRating(payload));
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  // On rescheduling maintenance service
  const onReshedule = async (date) => {
    setRescheduleModal(false);
    // Creating or collecting payload data to be sent
    const payload = {
      maintenance_id,
      type: maintenanceType,
      date,
    };
    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      let result;
      switch (property_mgmt_model) {
        case GLOBAL.MGMT_MODEL.T1:
          result = await dispatch(tenantRescheduleT1Maintenance(payload));
          break;

        case GLOBAL.MGMT_MODEL.T2:
          if (assigned_to_role === GLOBAL.USER_ROLE.FM) {
            result = await dispatch(t2TenantRecheduleMaintenance(payload));
          } else {
            result = await dispatch(t2partyTenantRecheduleMaintenance(payload));
          }
          break;
        case GLOBAL.MGMT_MODEL.T3:
          if (assigned_to_role === GLOBAL.USER_ROLE.FM) {
            result = await dispatch(tenantRescheduleMaintenance(payload));
          } else {
            result = await dispatch(partyTenantRecheduleMaintenance(payload));
          }
          break;
      }
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // REPORT DATA FOR INTERNAL MAINTENANVCE TICKET
  const renderReport = () => {
    return (
      <>
        {reportData?.items?.map((item) => (
          <div className="so-report-wrapper" key={item?._id}>
            <div>
              <div className="font_m fw_5 font_grey">
                Description :
                <p className="font_xs fw_4 font_white report_in_escape">
                  {item?.comment || item?.description || item?.notes
                    ? item?.comment || item?.description || item?.notes
                    : "-"}
                </p>
              </div>
            </div>
            {item?.documents?.length > 0 && (
              <>
                <div className="report-doc-wrapper">
                  {item?.documents?.map((image, index) => (
                    <div className="proof-img" key={index}>
                      <Image
                        key={index}
                        className="media"
                        imgClass="thumbnail"
                        src={image?.url}
                        videoThumbnail={image?.type2 === "video"}
                        alt="Attatched Media"
                        objectFit="cover"
                        onClick={() => {
                          setFile(image);
                          setShowFileViewer(true);
                        }}
                      />
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        ))}
      </>
    );
  };

  // To View The Tenant Invoice
  const handleView = (id) => {
    const newPathname = pathNamePopped(pathname, 2);

    navigate(`${newPathname}/invoicing/unpaid/${id}`, {
      state: { pathname: pathname },
    });
  };

  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="time-line simple-log bg_grey">
      {/* Dot */}
      <div className="dot-wrapper">
        <i className="dot"></i>
        <div className="vl"></div>
      </div>

      {/* Title */}
      <div className="detail-wrapper">
        <h3 className="title font_m fw_6">{logTitle(item)}</h3>

        {/* Technician Name and Date */}
        <div className="black-container-sm fw_5 font_s bg_black flex-between">
          {/* Name */}
          <p className="left">
            <span className="font_grey">{role} </span>
            <span className="font_white"> {name}</span>
          </p>

          {/* Date and Time */}
          <p className="right font_grey">
            {moment(createdAt).format(DATE_TIME_FORMAT)}
          </p>
        </div>

        {action === "Waiting for tenant payment" && item?.tenant_invoice_id && (
          <div className="black-container-sm fw_5 font_s bg_black flex-between rescheduled_date_time my-2">
            {/* Name */}
            <p className="left">
              <span className="font_white">Tenant Invoice</span>
            </p>

            {/* Date and Time */}
            <p className="right font_grey">
              {/* //{item?.tenant_invoice_id} */}
              <IconButton onClick={() => handleView(item?.tenant_invoice_id)}>
                <img src={EyeFilledBlue} alt="Image" className="viewImg" />
              </IconButton>
            </p>
          </div>
        )}

        {/* Log Footer */}
        <div className="log-footer">
          {/* Comments */}
          {action === "Maintenance is rejected by tenant" && comment && (
            <>
              <p className="comments font_s fw-5">
                <span className="font_grey fw_6">Comments: </span>
                <span className="fw_5 font_red"> {comment}</span>
              </p>
              {reportData?.document?.length > 0 && (
                <div className="so-report-wrapper">
                  <p className="font_yellow fw_4 font_m my-2">Attachments</p>
                  <div className="report-doc-wrapper">
                    {reportData?.document?.map((image, index) => (
                      <div className="proof-img" key={index}>
                        <Image
                          key={index}
                          className="media"
                          imgClass="thumbnail"
                          src={image?.url}
                          videoThumbnail={image?.type2 === "video"}
                          alt="Attatched Media"
                          objectFit="cover"
                          onClick={() => {
                            setFile(image);
                            setShowFileViewer(true);
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </>
          )}

          {/* Comments */}

          {action === "Technician added inspection report" && (
            <>{reportData && renderReport()}</>
          )}

          {/* Action Buttons */}
          {takeAction && (
            <div className="button-wrapper">
              {/* Tenant rating service */}
              {canTenantRateService(item, user) && !maintenance?.is_rated && (
                <CustomButton
                  onClick={() => setShowRateReviewModal(true)}
                  size="m"
                  variant="yellow"
                >
                  Rate and Review
                </CustomButton>
              )}

              {/* Tenant rescheduling servide */}
              {canTenantReschedule(item, user) && (
                <CustomButton
                  onClick={() => setRescheduleModal(true)}
                  size="m"
                  variant="yellow"
                >
                  Rescheduling
                </CustomButton>
              )}
            </div>
          )}
        </div>
      </div>

      {/* Modals */}
      {takeAction && (
        <>
          {!maintenance?.is_rated && (
            <RateAndReviewModal
              showModal={showRateReviewModal}
              btnText="Submit"
              onCancel={() => setShowRateReviewModal(false)}
              onSubmit={onRateService}
              // rateTechnician={!isT1}
            />
          )}

          <RescheduleModal
            showModal={rescheduleModal}
            action={onReshedule}
            onCancel={() => setRescheduleModal(false)}
          />

          {/* File viewer modal */}
          <FileViewerModal
            show={showFileViewer}
            type={file?.type}
            file={file?.url}
            onClose={() => {
              setShowFileViewer(false);
              setFile({});
            }}
          />
        </>
      )}
    </div>
  );
};
/* Component ends */

export default SimpleLog;
