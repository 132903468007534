/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  fetchClaimedItemList,
  fetchFoundItemList,
  fetchLostItemList,
  getClaimedItemsList,
  getFoundsItemsList,
  getLostItemsList,
} from "../../../setup/store/slices/lostAndFoundSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import Searchbar from "../../../components/ui/other/Searchbar/Searchbar";
import TabButton from "../../../components/ui/other/TabButton/TabButton";
import FoundItemTable from "../../../components/module/LostAndFound/ClientLostAndFoundModule/ClientFound/FoundItemsTable/FoundItemTable";
import LostItemTable from "../../../components/module/LostAndFound/ClientLostAndFoundModule/ClientLost/LostItemsTable/LostTable";
import ClaimedTable from "../../../components/module/LostAndFound/ClientLostAndFoundModule/ClientClaimed/ClaimedTable";
import BreadCrumb from "../../../components/ui/other/BreadCrumb/BreadCrumb";
/* Import local pages and component ends */

// styles sheet
import "./style.scss";

const ClientLostAndFound = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [currentKey, setCurrentKey] = useState("found");

  const [currentPage, setCurrentPage] = useState(1);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { lostAndFoundListTab, propertyId, tab } = useParams();
  const navigate = useNavigate();
  /* Other hooks declaration ends */

  // Redux Selectors
  const foundList = useSelector(getFoundsItemsList);
  const lostList = useSelector(getLostItemsList);
  const claimedList = useSelector(getClaimedItemsList);

  /* Component variable declaration / object destructure starts */
  const buttonList = [
    { key: "found", label: "Found" },
    { key: "lost", label: "Lost" },
    { key: "claimed", label: "Claimed" },
  ];

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const onTabSelect = (key) => {
    const pathnameArr = pathname.split("/");
    pathnameArr.pop();
    navigate(pathnameArr.join("/") + "/" + key);
    setCurrentPage(1);
    // setCurrentKey(key);
  };

  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    if (tab === "found") {
      dispatch(
        fetchFoundItemList({ property_id: propertyId, page: currentPage })
      );
    } else if (tab === "lost") {
      dispatch(
        fetchLostItemList({ property_id: propertyId, page: currentPage })
      );
    } else if (tab === "claimed") {
      dispatch(
        fetchClaimedItemList({ property_id: propertyId, page: currentPage })
      );
    }
  }, [propertyId, tab, currentPage]);

  useEffect(() => {
    if (lostAndFoundListTab) {
      setCurrentKey(lostAndFoundListTab);
    }
  }, [lostAndFoundListTab]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  /* Component starts */
  return (
    <>
      <BreadCrumb type="client_lostAndFound" />
      <div className="found-wrapper">
        <div className="found-heading">
          <h2 className="font_xxl fw_6">Lost And Found</h2>
          {tab === "found" ? (
            <Link to="add" className="font_m fw_6 d_block link">
              + Add Found Item
            </Link>
          ) : (
            ""
          )}
        </div>

        {/* <Searchbar /> */}

        {/* TAB BUTTONS */}
        <TabButton
          buttonList={buttonList}
          currentKey={tab}
          onSelect={onTabSelect}
        />

        {/* Display Table Data Based On The Current Tab */}

        {tab === "found" ? (
          <FoundItemTable
            foundList={foundList?.result}
            totalCount={foundList?.totalCount}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        ) : tab === "lost" ? (
          <LostItemTable
            lostList={lostList?.result}
            totalCount={lostList?.totalCount}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        ) : (
          <ClaimedTable
            claimedList={claimedList?.result}
            totalCount={claimedList?.totalCount}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        )}
      </div>
    </>
  );
};
/* Component End */

export default ClientLostAndFound;
