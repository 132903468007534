/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import GLOBAL from "../../../../../setup/constants/global";
import { useDispatch } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../../../setup/store/slices/globalAlertSlice";
import { initiateMoveOut } from "../../../../../setup/store/slices/moveOutSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomButton from "../../../../ui/button/CustomButton/CustomButton";
import ConfirmationModal from "../../../../ui/modal/ConfirmationModal/ConfirmationModal";
/* Import local pages and component ends */

/* Component starts */
const Renewal = (props) => {
  /* Props destructuring starts */
  const { setShowInstallment, fetchContractDetails, moveout_payload } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [showMoveOutConfirmation, setShowMoveOutConfirmation] = useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // On starting move out
  const startMoveout = async () => {
    // Creating or collecting payload data to be sent

    // Backend response. Try, Catch
    try {
      const result = await dispatch(initiateMoveOut(moveout_payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          fetchContractDetails();
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="initiate-renewal-action">
      <p className="font_m fw_5 font_yellow text-center">
        Do you want to renew contract ?
      </p>

      <div className="renewal-action">
        <CustomButton
          onClick={() => setShowMoveOutConfirmation(true)}
          size="l"
          variant="red"
        >
          No
        </CustomButton>
        <CustomButton
          onClick={() => setShowInstallment(true)}
          size="l"
          className="right"
        >
          Yes
        </CustomButton>
      </div>

      <ConfirmationModal
        showModal={showMoveOutConfirmation}
        description="Do you really want to move out this tenant ? This is an irreversible action."
        title="Move Out"
        onClose={() => setShowMoveOutConfirmation(false)}
        onConfirm={startMoveout}
      />
    </div>
  );
};
/* Component ends */

export default Renewal;
