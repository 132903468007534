/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { SuccessIconOutlinedBlue } from "../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomButton from "../../../../../components/ui/button/CustomButton/CustomButton";
import FileGroupModal from "../../../../ui/modal/FileGroupModal/FileGroupModal";
import FileViewerModal from "../../../../ui/modal/FileViewerModal/FileViewerModal";
/* Import local pages and component ends */

/* Component starts */
const KeyRejected = (props) => {
  /* Props destructuring starts */
  const { unit } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [showFileGroup, setShowFileGroup] = useState(false);
  const [showFile, setShowFile] = useState(false);
  const [file, setFile] = useState({});
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const handleSelectFile = (file) => {
    setFile(file);
    setShowFileGroup(false);
    setShowFile(true);
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div>
      <div className="status-icon-wrapper text-center">
        <img src={SuccessIconOutlinedBlue} alt="Warning" />
      </div>

      <h3 className="text-center fw_6 font_xxl font_blue mt-4">
        Keys rejected by you!
      </h3>
      <p className="font_l fw_5 text-center mt-4">
        Reason: <span className="font_red">{unit?.key_rejection_reason}</span>
      </p>

      <p className="font_l fw_5 text-center mt-4 font_yellow">
        Unit Under Maintenance
      </p>

      <div className="button-wrapper">
        {unit?.key_docs?.length > 0 && (
          <CustomButton
            size="l"
            className="mt-5"
            onClick={() => setShowFileGroup(true)}
          >
            View Files
          </CustomButton>
        )}
      </div>

      <FileGroupModal
        showModal={showFileGroup}
        title="Key Rejection"
        files={unit?.key_docs}
        onSelectFile={handleSelectFile}
        onClose={() => setShowFileGroup(false)}
      />
      <FileViewerModal
        show={showFile}
        file={file?.url}
        type={file?.type}
        onClose={() => {
          setShowFile(false);
          setShowFileGroup(true);
        }}
      />
    </div>
  );
};
/* Component ends */

export default KeyRejected;
