import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import i18nHttpLoader from 'i18next-http-backend';
import Axios from 'axios';
import {LOCALE_URL} from '../config/devKeys';

export const t = name => i18n.t(name);

export const setLocale = async (locale) => {
  i18n.changeLanguage(locale);
};

export const getCurrentLocale = () => i18n.language;

const initData = () => ({
  compatibilityJSON: 'v3',
  fallbackLng: 'en',
  debug: false,
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: true,
  },
  backend: {
    loadPath: LOCALE_URL,
    parse: data => {
      return data;
    },
    request: (options, url, payload, callback) => {
      Axios(url)
        .then(async res => {
          callback(null, res);
        })
        .catch(err => {
          callback(err, null);
        });
    },
  },
});

i18n.use(initReactI18next).use(i18nHttpLoader).init(initData());

export default i18n;
