import React, { useEffect, useState } from "react";
/* Import configuration starts */
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import GLOBAL from "../../setup/constants/global";
import jwtDecode from "jwt-decode";
/* Import configuration ends */

/* Import redux slices component starts */
import { getAuthLoading } from "../../setup/store/slices/unpersistedSlice";
import {
  fetchUser,
  getUser,
  setUserRole,
  validateUserInvitation,
} from "../../setup/store/slices/authSlice";
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../setup/store/slices/globalAlertSlice";
import { addMember } from "../../setup/store/slices/teamSlice";
/* Import redux slices component ends */

/* Import image and SVG starts */
import {
  LogoPrimary,
  WarningRoundedOutlineYellow,
} from "../../setup/constants/images";
import {
  getTokenExpiredModal,
  setTokenExpiredModal,
} from "../../setup/store/slices/unpersistedSlice";

import {
  LogoWhite,
  BgShadeSignUp,
  SignUpLogo,
  DGRNTEtext,
  ArrowPointRightBlack,
} from "../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import SignInForm from "./local-components/SignInForm";
import WarningModal from "../../components/ui/modal/WarningModal/WarningModal";
/* Import local pages and component ends */

import "./style.scss";
import ErrorModal from "../../components/ui/modal/ErrorModal/ErrorModal";

/* Component starts */
const SignInTeamInvite = () => {
  /* Component states declaration starts */
  const [loaded, setLoaded] = useState(false);
  const [userCopy, setUserCopy] = useState(null);
  const [isInviteLoaded, setIsInviteLoaded] = useState(false);
  const [validateInvite, setValidateInvite] = useState();
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [modalDesc, setModalDesc] = useState("");
  /* Component states declaration ends */

  /* Other hooks declaration starts */
  // const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { encodedToken } = useParams();

  // Redux Selector
  const authLoading = useSelector(getAuthLoading);
  const tokenExpiredModal = useSelector(getTokenExpiredModal);
  const user = useSelector(getUser);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const handleFetchUser = async () => {
    const result = await dispatch(fetchUser());
    setLoaded(true);
    switch (result.meta.requestStatus) {
      case GLOBAL.REJECTED:
        setUserCopy(null);
        break;

      case GLOBAL.FULFILLED:
        setUserCopy(result.payload.user);
        break;
    }
  };

  const checkInviteValidation = async (token) => {
    const result = await dispatch(validateUserInvitation({ token: token }));
    console.log(result, "RES");
    setIsInviteLoaded(true);
    switch (result.meta.requestStatus) {
      case GLOBAL.REJECTED:
        setModalDesc(result.payload);
        setValidateInvite(result.payload);
        setShowErrorModal(true);
        break;

      case GLOBAL.FULFILLED:
        setValidateInvite(result.payload);
        break;
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    handleFetchUser();
  }, []);

  useEffect(() => {
    (async () => {
      if (loaded) {
        const { email, countrycode, mobile } = jwtDecode(encodedToken);
        if (userCopy) {
          if (userCopy?.email === email) {
            const result = await dispatch(addMember({ token: encodedToken }));
            console.log("Existing User signed in with invitation link");
            console.log(result);
            switch (result.meta.requestStatus) {
              case GLOBAL.REJECTED:
                dispatch(
                  showErrorAlert({
                    title: "Error!",
                    description: result?.payload?.error?.message || "",
                  })
                );
                break;

              case GLOBAL.FULFILLED:
                dispatch(
                  showSuccessAlert({
                    title: "Success",
                    description: "Property added successfully",
                  })
                );
                break;
            }
            navigate("/");
          } else {
            console.log(email);
            dispatch(
              showErrorAlert({
                title: "Error!",
                description: "Please log out from the existing user first.",
              })
            );
            navigate("/");
          }
        } else {
        }
      }
    })();
  }, [userCopy, loaded]);

  useEffect(() => {
    if (encodedToken) {
      checkInviteValidation(encodedToken);
    }
  }, [encodedToken]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  // If user is logged in then navigating back
  // if (user) {
  //   return <Navigate to="/" />;
  // }
  // <>
  if (
    isInviteLoaded &&
    loaded &&
    !userCopy &&
    validateInvite?.message === "Valid invitation."
  ) {
    return (
      <div className="signin-outer-wrapper">
        <div className="bgShade">
          <img src={BgShadeSignUp} alt="" />
        </div>
        <div className="image">
          <div className="inner-wrapper">
            <div className="text-logo">
              <img src={SignUpLogo} className="logo-icon" alt="" />
              <img src={DGRNTEtext} alt="" />
            </div>
            <h2 className="heading font-darkgreen fw_6">
              Start your journey with us
            </h2>
            <h3 className="content font-darkgreen">
              Discover the power of collaboration and create lasting
              relationships that transcend boundaries
            </h3>
          </div>
        </div>

        <div className="form-wrapper">
          <div
            className="blur-blue"
            style={{ top: "7%", left: "35%", zIndex: "10" }}
          ></div>
          <div
            className="blur-cherry"
            style={{ top: "-3%", right: "5%", zIndex: "10" }}
          ></div>
          <div
            className="blur-blue"
            style={{ top: "75%", left: "97%", zIndex: "10" }}
          ></div>
          <div
            className="blur-cherry"
            style={{ top: "90%", right: "50%", zIndex: "10" }}
          ></div>
          <div className="logo-wrapper">
            {/* <img src={LogoWhite} alt="Logo" /> */}
            <h1 className="big-h1 font-darkgreen">Sign In Team</h1>
          </div>
          <SignInForm />
          {/* {!authLoading && (
          <div className="footer text-center">
            <Link className="font_m" to="/signin">
              Return back to Sign In
            </Link>
          </div>
        )} */}
        </div>
        <WarningModal
          showModal={tokenExpiredModal}
          title="Session Expired"
          description="Your session has been expired. Please Log In again."
          onClose={async () => {
            await dispatch(setTokenExpiredModal(false));
          }}
        />
      </div>
    );
  } else if (
    isInviteLoaded &&
    loaded &&
    (validateInvite === "Invitation link expired." ||
      validateInvite ===
        "Your invitation has been expired. Please request new invitation.")
  ) {
    return (
      <div className="signin-outer-wrapper-valid">
        <div className="valid-wrapper container_sm">
          <img src={WarningRoundedOutlineYellow} alt="Alert" />
          <p className="font_xl fw_5 font_red">{validateInvite}</p>

          <div className="footer text-center">
            <Link className="font_m back-link" to="/signin">
              Return back to Sign In
            </Link>
          </div>
        </div>
      </div>
    );
  }

  /* Error Modal */

  // <ErrorModal
  //   showModal={showErrorModal}
  //   description={modalDesc}
  //   title="Success"
  //   onClose={() => {
  //     setShowErrorModal(false);
  //     navigate("/signin");
  //   }}
  // />;
  // </>;
};
/* Component ends */

export default SignInTeamInvite;
