/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import FileGroupModal from "../../../../ui/modal/FileGroupModal/FileGroupModal";
import FileViewerModal from "../../../../ui/modal/FileViewerModal/FileViewerModal";
/* Import local pages and component ends */

/* Component starts */
const InspectionItemFileViewer = (props) => {
  /* Props destructuring starts */
  const { showInspectionFiles, selectedInspectionItem, onClose } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [showfileViewer, setShowfileViewer] = useState(false);
  const [file, setFile] = useState(null);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  if (!showfileViewer)
    return (
      <FileGroupModal
        showModal={showInspectionFiles}
        onSelectFile={(f) => {
          setFile(f);
          setShowfileViewer(true);
        }}
        files={selectedInspectionItem?.documents}
        onClose={onClose}
      />
    );

  if (showfileViewer)
    return (
      <FileViewerModal
        show={showfileViewer}
        type={file?.type}
        file={file?.url}
        onClose={() => setShowfileViewer(false)}
      />
    );
};
/* Component ends */

export default InspectionItemFileViewer;
