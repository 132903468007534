/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import moment from "moment";
import GLOBAL from "../../../../../setup/constants/global";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import {
  RandomProperty,
  DocFilledWhite,
  DocumentGreen,
} from "../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import FileViewerModal from "../../../../ui/modal/FileViewerModal/FileViewerModal";
/* Import local pages and component ends */

/* Component starts */
const CommercialDetails = (props) => {
  /* Props destructuring starts */
  const { details, index } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [showFile, setShowFile] = useState(false);
  const [file, setFile] = useState({});
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";

  const companyName = details?.business_name ? details?.business_name : "-";
  const relationToBusiness =
    details?.relation_to_business !== undefined
      ? details?.relation_to_business
      : "-";
  const tradeLicenseNum =
    details?.trade_license_number !== undefined
      ? details?.trade_license_number
      : "-";
  const tradeLicenseDoc =
    details?.trade_license_document !== undefined
      ? details?.trade_license_document
      : "-";
  const tradeLicenseExp = details?.trade_license_expiry_date
    ? moment(details?.trade_license_expiry_date).format(DATE_FORMAT)
    : "-";
  const taxIdNum =
    details?.tax_id_number !== undefined ? details?.tax_id_number : "-";
  const taxIdDoc =
    details?.tax_id_document !== undefined ? details?.tax_id_document : "-";
  const bankStatement =
    details?.bank_statement_document !== undefined
      ? details?.bank_statement_document
      : "-";
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="basic-wrapper commercial-wrapper">
      <p className="font_l">{index} - Commercial Details</p>

      <div className="detail-box bg_grey">
        {/* {details ? ( */}
        <>
          <div className="detail">
            <p className="font_s fw_3">
              Company Name <br />{" "}
              <span className="font_m fw_4">{companyName}</span>
            </p>
          </div>
          <div className="detail">
            <p className="font_s fw_3">
              Relation To Business <br />{" "}
              <span className="font_m fw_4">{relationToBusiness}</span>
            </p>
          </div>
          <div className="detail">
            <p className="font_s fw_3">
              Trade License Number <br />
              <span className="font_m fw_4">{tradeLicenseNum}</span>
            </p>
          </div>
          <div className="detail">
            <p className="font_s fw_3">
              Trade License Document <br />
              <img
                src={DocumentGreen}
                onClick={() => {
                  setFile(tradeLicenseDoc);
                  setShowFile(true);
                }}
                className="file-icon"
                alt="Doc"
              />
            </p>
          </div>
          <div className="detail">
            <p className="font_s fw_3">
              Trade License Expiry <br />{" "}
              <span className="font_m fw_4">{tradeLicenseExp}</span>
            </p>
          </div>
          <div className="detail">
            <p className="font_s fw_3">
              Tax ID Number <br />{" "}
              <span className="font_m fw_4">{taxIdNum}</span>
            </p>
          </div>
          <div className="detail">
            <p className="font_s fw_3">
              Tax ID Document <br />
              <img
                src={DocumentGreen}
                onClick={() => {
                  setFile(taxIdDoc);
                  setShowFile(true);
                }}
                className="file-icon"
                alt="Doc"
              />
            </p>
          </div>
          <div className="detail">
            <p className="font_s fw_3">
              Bank Statement <br />
              <img
                src={DocumentGreen}
                onClick={() => {
                  setFile(bankStatement);
                  setShowFile(true);
                }}
                className="file-icon"
                alt="Doc"
              />
            </p>
          </div>
        </>
        {/* // ) : (
        //   <div className="noData text-center">
        //     <h3 className="font_m font_yellow fw_6">No Data</h3>
        //   </div>
        // )} */}
      </div>

      <FileViewerModal
        show={showFile}
        file={file.url}
        type={file.type}
        onClose={() => setShowFile(false)}
      />
    </div>
  );
};
/* Component ends */

export default CommercialDetails;
