/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useNavigate } from "react-router-dom";
import GLOBAL from "../../../setup/constants/global";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import MUI component starts */
/* Import MUI component ends */

/* Import image and SVG starts */
import { EditGreen, EditWhite } from "../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../../components/ui/button/IconButton/IconButton";
/* Import local pages and component ends */

/* Component starts */
const Header = (props) => {
  /* Props destructuring starts */
  const { property } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="header">
      <h2 className="font_xxl fw_6 w-50">Property Details</h2>

      <div className="property-action text-end w-50">
        <IconButton onClick={() => navigate("edit")} className="option-button">
          <img src={EditGreen} className="w_100" alt="Edit Icon" />
        </IconButton>
      </div>
    </div>
  );
};
/* Component ends */

export default Header;
