/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import GLOBAL from "../../../../../setup/constants/global";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Table } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import {
  ExcalmatoryYellow,
  EyeFilledBlue,
  ProfileFilledGrey,
} from "../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../../../ui/button/IconButton/IconButton";
import Pagination from "../../../../ui/other/Pagination/Pagination";

/* Import local pages and component ends */

/* Component starts */
const ExistingTenants = (props) => {
  /* Props destructuring starts */
  const { list, totalCount, currentPage, setCurrentPage } = props;

  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [data, setData] = useState();
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  const handleReportView = (data) => {
    navigate(`detail/${data.tenantId}/unit/${data.unitId}`, {});
  };

  /* Component function definition ends */

  /* Component useEffect starts */
  // useEffect(() => {
  //   if (data) {
  //     handleReportView(data);
  //   }
  // }, [currentPage]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      <div className="custom-table-wrapper existing-tenants-table">
        {list?.length > 0 && (
          <Table className="custom-table font_s" borderless responsive>
            {/* Table Heading */}
            <thead>
              <tr>
                <th>Tenant Name</th>
                <th>Unit Name</th>
                <th>Type Of Unit</th>
                <th>Join Date</th>
                <th>End Date</th>
                <th>Status</th>
                <th className="text-start">Action</th>
              </tr>
            </thead>
            {/* Table Body */}
            <tbody>
              {list?.map((item, index) => (
                <tr key={index}>
                  {/* Tenant Name */}
                  <td>{`${item?.tenant}`}</td>

                  {/* Unit Name */}
                  <td>{item?.unit_name}</td>

                  {/* Unit Type */}
                  <td>{item?.unit_type}</td>

                  {/* Join Date & Time */}
                  <td>{moment(item?.join_date).format(DATE_FORMAT)}</td>

                  {/* End Date & Time */}
                  <td>{moment(item?.end_date).format(DATE_FORMAT)}</td>

                  {/* Status */}
                  <td>
                    <p className="font_green">
                      {item?.status?.charAt(0)?.toUpperCase() +
                        item?.status?.slice(1)}{" "}
                      {/* <span className="font_red">
                        {item?.sub_status === "pending_invoice_clearance"
                          ? `(Pending Invoice Clearance)`
                          : ""}
                      </span> */}
                    </p>
                  </td>

                  {/* Actions */}
                  <td className="text-start txt-nowrap">
                    <IconButton
                      onClick={
                        () =>
                          handleReportView({
                            tenantId: item?.tenant_id,
                            unitId: item?.unit_id,
                          })
                        // setData({
                        //   tenantId: item?.tenant_id,
                        //   unitId: item?.unit_id,
                        // })
                      }
                      className="action-button"
                    >
                      <img
                        src={EyeFilledBlue}
                        className="h_100"
                        alt="View Details"
                      />
                    </IconButton>

                    {item?.sub_status === "pending_invoice_clearance" && (
                      <IconButton className="action-button">
                        <img
                          src={ExcalmatoryYellow}
                          alt="Image"
                          className="h_100 px-2"
                          data-tooltip-id="my-tooltip-2"
                          data-tooltip-delay-hide={1000}
                        />
                        <Tooltip
                          id="my-tooltip-2"
                          place="top-end"
                          variant="warning"
                          content="Pending Invoice Clearance"
                          style={{
                            backgroundColor: "#FFB900",
                            color: "#fff",
                            padding: "7px 15px",
                            fontWeight: "bold",
                          }}
                          // opacity={0.3}
                        />
                      </IconButton>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}

        {list?.length === 0 && (
          <div className=" text-center">
            <h3 className=" font_m font_yellow fw_6">No Data</h3>
          </div>
        )}
      </div>

      {/* Pagination */}
      {list?.length > 0 && (
        <Pagination
          itemsPerPage="10"
          totalItems={totalCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
    </>
  );
};
/* Component ends */

export default ExistingTenants;
