/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
/* Import local pages and component ends */

/* Component starts */
const DetailSection1 = (props) => {
  /* Props destructuring starts */
  const { type, maintenance } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const requestId = maintenance?.request_id;
  const unitName = maintenance?.unit_card?.unit_name;
  const unitType = maintenance?.unit_card?.unit_type?.name;
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="section section1">
      <p className="name font_m fw_6 font_grey">
        Ticket No .:
        <span className="font_blue fw_6">{requestId}</span>
      </p>

      {type !== "Move In" && (
        <p className="summary font_m font_grey fw_5">
          Description
          <br />
          <span className="fw_6 font_l">{maintenance?.description}</span>
        </p>
      )}

      <hr className="section-divider" />

      {/* Ticket and Unit Number */}
      <div className="ticket-unit">
        <p className="ticket-no font_xs fw_5 w-50">
          <span className="font_grey">Property </span>
          <br />
          <span>{maintenance?.property_id?.property_name}</span>
        </p>

        {/* Unit Name */}
        <p className="unit-name font_m fw_5 w-50">
          <span className="font_grey">Unit </span>
          <br />
          <span className="font_blue fw_6">{unitName}</span>
        </p>

        {/* Unit Type */}
        <p className="unit-name font_s fw_5 w-50">
          <span className="font_grey">Unit Type </span>
          <br />
          <span className="font_blue fw_6">{unitType}</span>
        </p>
      </div>
    </div>
  );
};
/* Component ends */

export default DetailSection1;
