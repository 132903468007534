/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import GLOBAL from "../../../setup/constants/global";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import PropertySlider from "../../../components/module/Property/PropertySlider/PropertySlider";
import PropertySliderHeading from "./PropertySliderHeading";
/* Import local pages and component ends */

/* Component starts */
const CommunityProperties = (props) => {
  /* Props destructuring starts */
  const { properties, community } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // On click property item
  const onClickProperty = async (property) => {
    if (property.wizard !== GLOBAL.PROP_STEP.DONE) {
      navigate(
        `/communities/${community._id}/properties/add?under-community=yes&property_id=${property._id}&community_id=${community._id}`
      );
    } else {
      navigate(`/communities/${community._id}/properties/${property._id}`);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="community-properties">
      <PropertySliderHeading community={community} count={properties?.length} />
      <PropertySlider
        onClickProperty={onClickProperty}
        propertyList={properties}
        heading={null}
      />
    </div>
  );
};
/* Component ends */

export default CommunityProperties;
