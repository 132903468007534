/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
/* Import configuration ends */

/* Import redux slices component starts */
import { Modal } from "react-bootstrap";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import MUI component starts */
/* Import MUI component ends */

/* Import image and SVG starts */
import {
  ZoomIn,
  ZoomOut,
  Download,
  XWhite,
} from "../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../button/IconButton/IconButton";
/* Import local pages and component ends */

import "./style.scss";

//pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}3.0.279/pdf.worker.js`;

//pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

/* Component starts */
const PDFViewerModal = (props) => {
  /* Props destructuring starts */
  const { file, show, onClose } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [numPages, setNumPages] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [width, setWindowWidth] = useState(0);
  const [scale, setScale] = useState(1.5);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(() => {
      const numArray = [];
      for (let i = 0; i < numPages; i++) {
        numArray.push(i + 1);
      }
      return numArray;
    });
  }

  // Zoom in
  const zoomIn = () => {
    setScale(scale + 0.5);
  };

  // Zoom out
  const zoomOut = () => {
    if (scale > 0.5) {
      setScale(scale - 0.5);
    }
  };

  // Function to updatae the width of the window
  const updateDimensions = () => {
    const width = window.innerWidth;
    setWindowWidth(width);
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  useEffect(() => {
    if (width <= 1200) {
      setScale(0.5);
    } else {
      setScale(1.5);
    }
  }, [width]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <Modal size="xl" show={show} className="pdf-viewer-modal-wrapper">
      <IconButton className="close" onClick={onClose}>
        <img src={XWhite} className="w_100 d_block" alt="Close Icon" />
      </IconButton>
      <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
        {numPages.map((num, key) => (
          <Page key={key} pageNumber={num} scale={scale} />
        ))}
      </Document>
      <div className="tool-btn-wrapper d_flex">
        <IconButton className="tool-btn d_block" onClick={zoomIn}>
          <img
            className="w_100 d_block v_middle"
            src={ZoomIn}
            alt="Zoom in Icon"
          />
        </IconButton>
        <IconButton className="tool-btn d_block" onClick={zoomOut}>
          <img
            className="w_100 d_block v_middle"
            src={ZoomOut}
            alt="Zoom Out Icon"
          />
        </IconButton>
        <a target="_blank" href={file} className="tool-btn d_block">
          <img
            className="w_100 d_block v_middle"
            src={Download}
            alt="Download Icon"
          />
        </a>
      </div>
    </Modal>
  );
};
/* Component ends */

export default PDFViewerModal;
