/**
 *
 */

import React from "react";

/* Import configuration starts */
import { useNavigate } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import MUI component starts */
/* Import MUI component ends */

/* Import image and SVG starts */
import {
  CrossIconFailedRed,
  SuccessIconOutlinedBlue,
} from "../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const TransactionStatus = ({
  status,
  onSuccessClose,
  onFailedClose,
  currency = "",
}) => {
  /* Props destructuring starts */
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // On closing transaction status
  const onClose = () => {
    if (status?.transaction_status === "Success") {
      onSuccessClose();
      // navigate(-2);
      // navigate(-1);
    } else {
      onFailedClose();
      navigate(-1);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="transaction-status-wrapper-main bg_darkgrey">
      <div className="icon text-center">
        {status?.transaction_status === "Success" ? (
          <img src={SuccessIconOutlinedBlue} alt="" />
        ) : (
          <img src={CrossIconFailedRed} alt="" />
        )}
      </div>
      <h1 className="font_xl text-center font_blue">
        Payment {status?.transaction_status}
      </h1>
      <p className="text-center font_m">
        Your transaction details are given below
      </p>
      <div className="details text-center">
        <p className="font_m">
          Transaction status :
          {status?.transaction_status === "Success" ? (
            <span className="fw_5 font_green"> Successful</span>
          ) : (
            <span className="fw_5 font_red"> Failed</span>
          )}
        </p>
        {status?.transaction_amount && (
          <p className="font_m">
            Transaction amount :{" "}
            <span className="font_blue fw_5">
              {status?.transaction_amount?.toLocaleString("en-US")} {currency}
            </span>
          </p>
        )}
        {status?.transaction_id && (
          <p className="font_m">
            Transaction id :{" "}
            <span className="font_blue fw_5">{status?.transaction_id}</span>
          </p>
        )}
        {status.reason && (
          <p className="font_m">
            Reason :{" "}
            <span className="font_blue fw_5">
              {typeof status?.reason === "string" ? (
                status?.reason
              ) : (
                <>
                  {status?.reason?.error?.message
                    ? status?.reason?.error?.message
                    : ""}
                </>
              )}
            </span>
          </p>
        )}
      </div>
      <div className="button-wrapper text-center">
        <CustomButton
          onClick={onClose}
          variant="red"
          size="m"
          className="font_l fw_6"
        >
          Close
        </CustomButton>
      </div>
    </div>
  );
};
/* Component ends */

export default TransactionStatus;
