/**
 *
 */

import React, { useState } from "react";

/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Modal } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import MUI component starts */
/* Import MUI component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
import CustomTextArea from "../../../ui/input/CustomTextArea/CustomTextArea";
import Rating from "../../../ui/other/Rating/Rating";
/* Import local pages and component ends */

import "./style.scss";
import { getUserTheme } from "../../../../setup/store/slices/authSlice";
import { useSelector } from "react-redux";

/* Component starts */
const RateAndReviewModal = (props) => {
  /* Props destructuring starts */
  const {
    showModal,
    title = "Rate and Review",
    size = "lg",
    onCancel,
    onSubmit,
    btnText,
    technicianReviewPlaceholder = "Technician Review",
    serviceReviewPlaceholder = "Service Review",
    validateRating = true,
    validateReview = false,
    rateService = true,
    rateTechnician = false,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [technicianReview, setTechnicianReview] = useState("");
  const [technicianRating, setTechnicianRating] = useState(0);

  const [serviceReview, setServiceReview] = useState("");
  const [serviceRate, setServiceRate] = useState(0);

  const [technicianError, setTechnicianError] = useState();
  const [serviceError, setServiceError] = useState();
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const userTheme = useSelector(getUserTheme);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // Validating not text
  const technicianValidation = () => {
    setTechnicianError("");
    if (technicianReview === "") {
      setTechnicianError("Please type reason for rejection");
      return true;
    }
    if (technicianReview !== "" && technicianReview.length < 10) {
      setTechnicianError("Minimum ten characters are required");
      return true;
    }

    return false;
  };

  const serviceValidation = () => {
    setServiceError("");
    if (technicianReview === "") {
      setServiceError("Please type reason for rejection");
      return true;
    }
    if (technicianReview !== "" && technicianReview.length < 10) {
      setServiceError("Minimum ten characters are required");
      return true;
    }

    return false;
  };

  const ratingValidation = () => {
    setServiceError("");
    setTechnicianError("");
    let d = false;
    if (rateService && serviceRate < 1) {
      setServiceError("Please rate the service");
      d = true;
    }
    if (rateTechnician && technicianRating < 1) {
      setTechnicianError("Please rate the technician");
      d = true;
    }

    return d;
  };

  // On Action
  const onAction = () => {
    if (
      (validateReview && rateTechnician && technicianValidation()) ||
      (validateReview && rateService && serviceValidation()) ||
      (validateRating && ratingValidation())
    )
      return;
    setTechnicianReview("");
    onSubmit({
      technician_review: technicianReview,
      technician_rating: technicianRating,
      service_review: serviceReview,
      service_rating: serviceRate,
    });
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <Modal
      size={size}
      centered
      show={showModal}
      className="rate-n-review-modal-wrapper"
    >
      <Modal.Body
        className={`${userTheme === "dark" ? "bg_darkTheme" : "bg_lightTheme"}`}
      >
        <div className="modal-header-custom">
          <h2 className="text-center font_blue font_xl">{title}</h2>
        </div>
        <div className="modal-body-custom note-wrapper">
          {/* {rateTechnician && ( */}
          <div className="for-technician">
            <h3 className="font_m font_blue fw_6">Rate Technician</h3>
            <Rating rating={technicianRating} setRating={setTechnicianRating} />
            <CustomTextArea
              label={technicianReviewPlaceholder}
              className="note"
              value={technicianReview}
              setValue={setTechnicianReview}
              required={false}
            />
            {technicianError !== "" && (
              <p className="font_xs error font_red fw_5">{technicianError}</p>
            )}
          </div>
          {/* )} */}

          <div className="for-service">
            <h3 className="font_m font_blue fw_6">Rate Service</h3>
            <Rating rating={serviceRate} setRating={setServiceRate} />
            <CustomTextArea
              label={serviceReviewPlaceholder}
              className="note"
              value={serviceReview}
              setValue={setServiceReview}
              required={false}
            />
            {serviceError !== "" && (
              <p className="font_xs error font_red fw_5">{serviceError}</p>
            )}
          </div>
        </div>
        <div className="modal-footer-custom">
          <CustomButton size="m" variant="red" onClick={onCancel}>
            Close
          </CustomButton>
          <CustomButton
            size="m"
            variant={`${btnText === "Reject" ? "red" : "primary"}`}
            onClick={onAction}
          >
            {btnText}
          </CustomButton>
        </div>
      </Modal.Body>
    </Modal>
  );
};
/* Component ends */

export default RateAndReviewModal;
