/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  getUnitDetail,
  fetchUnitDetailByID,
} from "../../../setup/store/slices/unitSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import MUI component starts */
/* Import MUI component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import EditUnitModule from "../../../components/module/Unit/EditUnitModule/EditUnitModule";
/* Import local pages and component ends */

/* Component starts */
const EditUnitForm = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { propertyId, unitId } = useParams();
  const unit = useSelector(getUnitDetail);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  useEffect(() => {
    dispatch(fetchUnitDetailByID({ unit_id: unitId }));
  }, [unitId]);
  /* Other hooks declaration ends */
  console.log(unit, "Unit Details");
  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="edit-unit-page-wrapper">
      <EditUnitModule
        unit={unit}
        onSuccess={() => {
          navigate(-1);
        }}
        onCancel={() => {
          navigate(-1);
        }}
      />
    </div>
  );
};
/* Component ends */

export default EditUnitForm;
