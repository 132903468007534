import React from "react";

const Header = () => {
  return (
    <>
      <h2>Claimed Item</h2>
    </>
  );
};

export default Header;
