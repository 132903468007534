import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { axiosInstance } from "../axios";
import {
  setLoading,
  setShowUploadProgress,
  setUploadProgress,
} from "./unpersistedSlice";
import { API_URL } from "../../config/devKeys";
import { PLATFORM } from "../../platformUtil";
import {
  fetchMaintenanceById,
  fetchMaintenanceListByProperty,
} from "./maintenanceSlice";
import { fetchTenantUnits, fetchTenantApplicationById } from "./tenantSlice";
import { fetchTenancyContracts } from "./contractSlice";
import { fetchPropertyTenants } from "./propertySlice";
import { fetchTenantContractsDetails } from "./tenatDetailSlice";

const initialState = {
  initialCard: null,
  moveoutDetail: null,
  clearenceDocument: null,
};

export const skipMoveoutStep = createAsyncThunk(
  "move_out/skip_move_out_step",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/move_out/skip_move_out_step",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const fetchTenantMoveOutDetail = createAsyncThunk(
  "move_out/get_tenant_moveout_detail",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.get(
        `${API_URL}/move_out/get_tenant_moveout_detail?contract_id=${payload.contract_id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      await dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const initialMoveout = createAsyncThunk(
  "move_out/initial_move_out",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        `${API_URL}/move_out/initial_move_out`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        await dispatch(fetchTenantApplicationById(payload?.application_id));
        await dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const forceMoveOut = createAsyncThunk(
  "move_out/force_move_out",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      // const {unit_id} = payload;
      const { data } = await axios.post(
        `${API_URL}/move_out/force_move_out`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        await dispatch(fetchTenantContractsDetails(payload));
        await dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const releaseClearanceLetter = createAsyncThunk(
  "move_out/give_clearance",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { move_out_id, property_id } = payload;
      const { data } = await axios.post(
        `${API_URL}/move_out/give_clearance`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        await dispatch(setLoading(false));
        dispatch(fetchMoveoutPMReviewDetail({ move_out_id }));
        await dispatch(fetchPropertyTenants({ property_id }));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const releaseUnit = createAsyncThunk(
  "move_out/release_unit",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { move_out_id, property_id } = payload;
      const { data } = await axios.post(
        `${API_URL}/move_out/release_unit`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        await dispatch(setLoading(false));
        dispatch(fetchMoveoutPMReviewDetail({ move_out_id }));
        await dispatch(fetchPropertyTenants({ property_id }));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const acceptKeys = createAsyncThunk(
  "move_out/accept_tenant_keys",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { move_out_id, property_id } = payload;
      const { data } = await axios.post(
        `${API_URL}/move_out/accept_tenant_keys`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        await dispatch(setLoading(false));
        dispatch(fetchMoveoutPMReviewDetail({ move_out_id }));
        await dispatch(fetchPropertyTenants({ property_id }));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const pmRespondClearence = createAsyncThunk(
  "move_out/respond_clearance_docs",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { move_out_id, property_id } = payload;
      const { data } = await axios.post(
        `${API_URL}/move_out/respond_clearance_docs`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        await dispatch(setLoading(false));
        // dispatch(fetchMoveoutPMReviewDetail({ move_out_id }));
        await dispatch(fetchPropertyTenants({ property_id }));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const sendClearenceDoc = createAsyncThunk(
  "move_out/submit_clearance_docs",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { move_out_id, unit_id } = payload;
      const formData = new FormData();
      Object.keys(payload).map((key) => {
        if (key === "document") {
          if (PLATFORM !== "web") {
            formData.append(key, {
              name: payload[key].name,
              type: payload[key].type,
              uri:
                PLATFORM === "android"
                  ? payload[key].uri
                  : payload[key].uri.replace("file://", ""),
            });
          } else {
            formData.append(key, payload[key], payload[key].name);
          }
        } else {
          formData.append(
            key,
            typeof payload[key] === "object"
              ? JSON.stringify(payload[key])
              : payload[key]
          );
        }
      });
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }
      // const response = await fetch(
      //   API_URL + "/move_out/submit_clearance_docs",
      //   {
      //     method: "POST",
      //     headers: headers,
      //     body: formData,
      //   }
      // );
      // const data = await response.json();
      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "POST",
        url: "/move_out/submit_clearance_docs",
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      // const data = await response.json();

      const { data } = await axiosInstance.request(config);

      if (data.success) {
        // dispatch(fetchClearenceRequirement({ unit_id, move_out_id }));
        await dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const fetchClearenceRequirement = createAsyncThunk(
  "move_out/get_docs_listing",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { unit_id, move_out_id } = payload;
      const { data } = await axios.get(
        `${API_URL}/move_out/get_docs_listing?move_out_id=${move_out_id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );

      if (data.success) {
        dispatch(fetchTenancyContracts({ unit_id }));
        await dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const fetchMoveoutPMReviewDetail = createAsyncThunk(
  "move_out/get_moveout_by_id",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));

      const { data } = await axios.post(
        `${API_URL}/move_out/get_moveout_by_id`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        await dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const specifyReqDoc = createAsyncThunk(
  "move_out/specify_required_docs",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { move_out_id, property_id } = payload;
      const formData = new FormData();
      Object.keys(payload).map((key) => {
        formData.append(
          key,
          typeof payload[key] === "object"
            ? JSON.stringify(payload[key])
            : payload[key]
        );
      });
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }
      // const response = await fetch(
      //   API_URL + "/move_out/specify_required_docs",
      //   {
      //     method: "POST",
      //     headers: headers,
      //     body: formData,
      //   }
      // );
      // const data = await response.json();
      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "POST",
        url: "/move_out/specify_required_docs",
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      // const data = await response.json();

      const { data } = await axiosInstance.request(config);

      if (data.success) {
        dispatch(fetchMoveoutPMReviewDetail({ move_out_id }));
        await dispatch(fetchPropertyTenants({ property_id }));
        await dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const initiateMoveOut = createAsyncThunk(
  "move_out/start_move_out",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { unit_id } = payload;
      const { data } = await axios.post(
        `${API_URL}/move_out/start_move_out`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        await dispatch(fetchTenancyContracts({ unit_id }));
        await dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const assignMT_moveout = createAsyncThunk(
  "move_out/assign_technician",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { maintenance_id, type } = payload;
      dispatch(setLoading(true));
      const { data } = await axios.post(
        `${API_URL}/move_out/assign_technician`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        await dispatch(fetchMaintenanceListByProperty({ type }));
        await dispatch(fetchMaintenanceById({ maintenance_id }));
        dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const mtRespondToAssign_moveout = createAsyncThunk(
  "move_out/technician_respond_to_request",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { maintenance_id, type } = payload;
      dispatch(setLoading(true));
      const { data } = await axios.post(
        `${API_URL}/move_out/technician_respond_to_request`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        await dispatch(fetchMaintenanceListByProperty({ type }));
        await dispatch(fetchMaintenanceById({ maintenance_id }));
        dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const mtFillInitialReport_moveout = createAsyncThunk(
  "move_out/technician_fill_move_out_card",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { maintenance_id, type } = payload;
      dispatch(setLoading(true));
      const formData = new FormData();
      Object.keys(payload).map((key) => {
        if (key === "document") {
          payload[key].forEach((item) => {
            if (PLATFORM !== "web") {
              formData.append(key, {
                name: item.name,
                type: item.type,
                uri:
                  PLATFORM === "android"
                    ? item.uri
                    : item.uri.replace("file://", ""),
              });
            } else {
              formData.append(key, item.file, item.name);
            }
          });
        } else {
          if (key === "description") {
            // stringifying array of string
            formData.append(key, JSON.stringify(payload[key]));
          } else {
            formData.append(key, payload[key]);
          }
        }
      });
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }
      // const response = await fetch(
      //   API_URL + "/move_out/technician_fill_move_out_card",
      //   {
      //     method: "POST",
      //     headers: headers,
      //     body: formData,
      //   }
      // );
      // const data = await response.json();
      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "POST",
        url: "/move_out/technician_fill_move_out_card",
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      // const data = await response.json();

      const { data } = await axiosInstance.request(config);

      if (data.success) {
        await dispatch(fetchMaintenanceListByProperty({ type }));
        await dispatch(fetchMaintenanceById({ maintenance_id }));
        dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const ownerFillInitialReport_moveout = createAsyncThunk(
  "move_out/owner_submit_inspection_report",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { maintenance_id, type } = payload;
      dispatch(setLoading(true));
      const formData = new FormData();
      Object.keys(payload).map((key) => {
        if (key === "document") {
          payload[key].forEach((item) => {
            if (PLATFORM !== "web") {
              formData.append(key, {
                name: item.name,
                type: item.type,
                uri:
                  PLATFORM === "android"
                    ? item.uri
                    : item.uri.replace("file://", ""),
              });
            } else {
              formData.append(key, item.file, item.name);
            }
          });
        } else {
          if (key === "description") {
            // stringifying array of string
            formData.append(key, JSON.stringify(payload[key]));
          } else {
            formData.append(key, payload[key]);
          }
        }
      });
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }
      // const response = await fetch(
      //   API_URL + "/move_out/owner_submit_inspection_report",
      //   {
      //     method: "POST",
      //     headers: headers,
      //     body: formData,
      //   }
      // );
      // const data = await response.json();
      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "POST",
        url: "/move_out/owner_submit_inspection_report",
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      // const data = await response.json();

      const { data } = await axiosInstance.request(config);

      if (data.success) {
        await dispatch(fetchMaintenanceListByProperty({ type }));
        await dispatch(fetchMaintenanceById({ maintenance_id }));
        dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const mtChangeStatus_moveout = createAsyncThunk(
  "move_out/technician_change_moveout_card_status",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { maintenance_id, type } = payload;
      dispatch(setLoading(true));
      const { data } = await axios.post(
        `${API_URL}/move_out/technician_change_moveout_card_status`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        await dispatch(fetchMaintenanceListByProperty({ type }));
        await dispatch(fetchMaintenanceById({ maintenance_id }));
        dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const fmRespond_moveout = createAsyncThunk(
  "move_out/fm_respond_moveout_card",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { maintenance_id, type } = payload;
      dispatch(setLoading(true));
      const { data } = await axios.post(
        `${API_URL}/move_out/fm_respond_moveout_card`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        await dispatch(fetchMaintenanceListByProperty({ type }));
        await dispatch(fetchMaintenanceById({ maintenance_id }));
        dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const fmUpdateReadinessCard_moveout = createAsyncThunk(
  "move_out/fm_update_moveout_readiness_card",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { maintenance_id, type } = payload;
      dispatch(setLoading(true));
      const { data } = await axios.post(
        `${API_URL}/move_out/fm_update_moveout_readiness_card`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        await dispatch(fetchMaintenanceListByProperty({ type }));
        await dispatch(fetchMaintenanceById({ maintenance_id }));
        dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const pmRespond_moveout = createAsyncThunk(
  "move_out/pm_respond_moveout_card",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { maintenance_id, type } = payload;
      dispatch(setLoading(true));
      const { data } = await axios.post(
        `${API_URL}/move_out/pm_respond_moveout_card`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        await dispatch(fetchMaintenanceListByProperty({ type }));
        await dispatch(fetchMaintenanceById({ maintenance_id }));
        dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const moveOutSlice = createSlice({
  name: "moveout",
  initialState,
  extraReducers: {
    [fetchMoveoutPMReviewDetail.rejected]: (state, action) => {
      state.moveoutDetail = null;
    },
    [fetchMoveoutPMReviewDetail.fulfilled]: (state, action) => {
      state.moveoutDetail = action.payload;
    },
    [fetchClearenceRequirement.rejected]: (state, action) => {
      state.clearenceDocument = null;
    },
    [fetchClearenceRequirement.fulfilled]: (state, action) => {
      state.clearenceDocument = action.payload;
    },
  },
});

export const getMoveoutDetail = (state) => state.moveout.moveoutDetail;
export const getClearenceDocument = (state) => state.moveout.clearenceDocument;

export default moveOutSlice.reducer;
