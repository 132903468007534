/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import moment from "moment";
import { Document, Page } from "react-pdf";

/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import Image from "../../../../../../ui/other/Image/Image";
import FileViewerModal from "../../../../../../ui/modal/FileViewerModal/FileViewerModal";
// import PDFViewer from "../../../../../../ui/other/PDFViewer/PDFViewer";
import PDFViewerNew from "../../../../../../ui/other/PDFViewer/PDFViewerNew";

/* Import local pages and component ends */

/* Component starts */
const ViolationDescriptionDetail = (props) => {
  /* Props destructuring starts */
  const { violation } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [numPages, setNumPages] = useState(null);
  const [showFileViewer, setShowFileViewer] = useState(false);
  const [file, setFile] = useState({});
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */

  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const violationDocument = violation?.document?.url;
  const customType = ["jpeg", "png", "jpg", "mp4"];
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  /* Component function definition ends */

  /* Component useEffect starts */

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      <div className="detail-card document-wrapper bg_grey mt-3">
        {violation?.description !== undefined && (
          <div
            className="description"
            dangerouslySetInnerHTML={{ __html: violation?.description }}
          />
        )}

        {customType?.includes(violation?.document?.type) && (
          <div className="doc-image-wrapper">
            {/* <img src={violation?.document?.url} alt="Violation_document" /> */}
            <Image
              key={violation?.document?.url}
              className="media"
              imgClass="thumbnail"
              src={violation?.document?.url}
              alt="Attatched Media"
              objectFit="cover"
              videoThumbnail={violation?.document?.type2 === "video"}
              onClick={() => {
                setFile(violation?.document);
                setShowFileViewer(true);
              }}
            />
          </div>
        )}

        {violation?.document?.type === "pdf" && (
          <div className="pdf-container">
            {/* <Document
              file={violationDocument}
              onLoadSuccess={onDocumentLoadSuccess}
              options={{
                workerSrc: `https://unpkg.com/pdfjs-dist@3.6.172/build/pdf.worker.min.js`,
              }}
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Page
                  key={`page_${index + 1}`}
                  scale={0.52}
                  width={window.innerWidth}
                  pageNumber={index + 1}
                />
              ))}
            </Document> */}

            {/* <PDFViewer file={violationDocument} /> */}
            <PDFViewerNew file={violationDocument} />
          </div>
        )}
      </div>

      {/* File viewer modal */}
      <FileViewerModal
        show={showFileViewer}
        type={file?.type}
        file={file?.url}
        onClose={() => {
          setShowFileViewer(false);
          setFile({});
        }}
      />
    </>
  );
};
/* Component ends */

export default ViolationDescriptionDetail;
