/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";
/* Import configuration ends */

/* Import redux slices component starts */

/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Table } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { EyeFilledBlue } from "../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import GLOBAL from "../../../setup/constants/global";
import IconButton from "../../../components/ui/button/IconButton/IconButton";
import CheckboxMultiSelectDropdown from "../../../components/ui/input/CheckboxMultiSelectDropdown/CheckboxMultiSelectDropdown";
/* Import local pages and component ends */

/* Component starts */
const MaintenanceInUnitTable = (props) => {
  /* Props destructuring starts */
  const {
    maintenanceInListData,
    multiSelectCommunity,
    setMultiSelectCommunity,
    communityData,
    propertyData,
    multiSelectProperty,
    setMultiSelectProperty,
    location,
    multiSelectLocation,
    setMultiSelectLocation,

    unitNum,
    multiSelectUnitNum,
    setMultiSelectUnitNum,

    processing,
    multiSelectProcessing,
    setMultiSelectProcessing,

    sparePart,
    multiSelectSparePart,
    setMultiSelectSparePart,

    severity,
    multiSelectSeverity,
    setMultiSelectSeverity,

    category,
    multiSelectCategory,
    setMultiSelectCategory,
    subCat,
    multiSelectSubCategory,
    setMultiSelectSubCategory,
    tenant,
    multiSelectTenant,
    setMultiSelectTenant,
    statusData1,
    multiSelectStatus,
    setMultiSelectStatus,
  } = props;

  /* Props destructuring ends */

  /* Component states and useRef declaration starts */

  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  //   const dispatch = useDispatch();

  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  /// To View The Item Report
  const handleViewReport = async (item) => {
    if (item?.community_id) {
      navigate(
        `/communities/${item?.community_id}/properties/${item?.property_id}/maintenances/${item?.main_id}`
      );
    } else {
      navigate(
        `/independent/properties/${item?.property_id}/maintenances/${item?.main_id}`
      );
    }
  };

  /* Component function definition ends */

  /* Component useEffect starts */

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */

  return (
    <div className="custom-table-wrapper maintenance-in-table">
      {maintenanceInListData?.length > 0 && (
        <Table className="custom-table font_s" borderless responsive>
          {/* Table Heading */}
          <thead>
            <tr>
              <th>
                <CheckboxMultiSelectDropdown
                  multiSelectData={multiSelectCommunity}
                  setMultiSelectData={setMultiSelectCommunity}
                  optionLabel={communityData}
                  label={"Community"}
                />
              </th>
              <th>
                <CheckboxMultiSelectDropdown
                  multiSelectData={multiSelectProperty}
                  setMultiSelectData={setMultiSelectProperty}
                  optionLabel={propertyData}
                  label={"Property"}
                />
              </th>
              <th>Ticket</th>
              <th>Date</th>
              <th>
                <CheckboxMultiSelectDropdown
                  multiSelectData={multiSelectTenant}
                  setMultiSelectData={setMultiSelectTenant}
                  optionLabel={tenant}
                  label={"Tenant"}
                />
              </th>
              <th>
                <CheckboxMultiSelectDropdown
                  multiSelectData={multiSelectUnitNum}
                  setMultiSelectData={setMultiSelectUnitNum}
                  optionLabel={unitNum}
                  label={"Unit No."}
                />
              </th>
              <th>
                <CheckboxMultiSelectDropdown
                  multiSelectData={multiSelectLocation}
                  setMultiSelectData={setMultiSelectLocation}
                  optionLabel={location}
                  label={"Location"}
                />
              </th>
              <th>
                <CheckboxMultiSelectDropdown
                  multiSelectData={multiSelectCategory}
                  setMultiSelectData={setMultiSelectCategory}
                  optionLabel={category}
                  label={"Category"}
                />
              </th>
              <th>
                <CheckboxMultiSelectDropdown
                  multiSelectData={multiSelectSubCategory}
                  setMultiSelectData={setMultiSelectSubCategory}
                  optionLabel={subCat}
                  label={"Sub Category"}
                />
              </th>
              <th>
                <CheckboxMultiSelectDropdown
                  multiSelectData={multiSelectSparePart}
                  setMultiSelectData={setMultiSelectSparePart}
                  optionLabel={sparePart}
                  label={"Spare Parts"}
                />
              </th>
              <th>
                <CheckboxMultiSelectDropdown
                  multiSelectData={multiSelectSeverity}
                  setMultiSelectData={setMultiSelectSeverity}
                  optionLabel={severity}
                  label={"Severity"}
                />
              </th>
              <th>
                <CheckboxMultiSelectDropdown
                  multiSelectData={multiSelectProcessing}
                  setMultiSelectData={setMultiSelectProcessing}
                  optionLabel={processing}
                  label={"Processing"}
                />
              </th>
              <th>Escalation</th>
              <th>
                <CheckboxMultiSelectDropdown
                  multiSelectData={multiSelectStatus}
                  setMultiSelectData={setMultiSelectStatus}
                  optionLabel={statusData1}
                  label={"Status"}
                />
              </th>
              <th className="text-start">Action</th>
            </tr>
          </thead>
          {/* Table Body */}
          {maintenanceInListData?.length > 0 && (
            <tbody>
              {maintenanceInListData?.map((item, index) => (
                <tr key={index}>
                  {/* Community Name */}
                  <td>
                    {item?.community_name !== undefined &&
                    item?.community_name?.length > 0
                      ? item?.community_name
                      : "-"}
                  </td>

                  {/* Property Name */}
                  <td>
                    {item?.property_name !== undefined
                      ? item?.property_name
                      : "-"}
                  </td>

                  {/* ID */}
                  <td>{item?.request_id}</td>

                  {/* Date */}
                  <td>
                    {item?.date !== undefined
                      ? moment(item?.date).format(DATE_FORMAT)
                      : "-"}
                  </td>

                  {/* Tenant Name */}
                  <td>{item?.tenant}</td>

                  {/* UNIT NO */}
                  <td>{item?.unit_name}</td>

                  {/* LOCATION */}
                  <td>{item?.location}</td>

                  {/* Category */}
                  <td>{item?.category !== undefined ? item?.category : "-"}</td>

                  {/* Sub Category */}
                  <td>
                    {item?.subcategory !== undefined ? item?.subcategory : "-"}
                  </td>

                  {/* Spare Parts */}
                  <td>
                    {item?.spareParts !== undefined || null
                      ? item?.spareParts
                      : "-"}
                  </td>

                  {/* Severity */}
                  <td>{item?.severity !== undefined ? item?.severity : "-"}</td>

                  {/* PROCESSING */}
                  <td>
                    {item?.processing !== undefined ? item?.processing : "-"}
                  </td>

                  {/* ESCALATION */}
                  <td>
                    {item?.escalated !== undefined
                      ? item?.escalated.toString()
                      : "-"}
                  </td>

                  {/* Status */}
                  <td
                    className={
                      item?.status === "Completed"
                        ? "font_green"
                        : item?.status === "Closed"
                        ? "font_red"
                        : item?.status === "New"
                        ? "font_blue"
                        : "in-complete"
                    }
                  >
                    {item?.status}
                  </td>

                  {/* Actions */}
                  <td className="text-start txt-nowrap">
                    <IconButton
                      onClick={() => handleViewReport(item)}
                      className="action-button"
                    >
                      <img
                        src={EyeFilledBlue}
                        className="h_100"
                        alt="View Details"
                      />
                    </IconButton>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </Table>
      )}

      {maintenanceInListData?.length === 0 && (
        <div className=" text-center">
          <h3 className=" font_m font_yellow fw_6">No Data</h3>
        </div>
      )}
    </div>
  );
};
/* Component ends */
export default MaintenanceInUnitTable;
