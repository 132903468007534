/**
 *
 */

import moment from "moment";

/* Import configuration starts */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import { getUserTheme } from "../../../../../setup/store/slices/authSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { AiOutlineRight } from "react-icons/ai";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
/* Import local pages and component ends */

/* Component starts */
const Annoucement = (props) => {
  /* Props destructuring starts */
  const { annoucement, bgColor } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const userTheme = useSelector(getUserTheme);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div
      className={`annoucement-detail-wrapper ${
        bgColor === undefined ? `bg_lightishgrey` : "bg_black"
      }  `}
      // ${
      //   bgColor === undefined && userTheme !== "dark" ? `bg_red` : "bg_yellow"
      // }
    >
      <div>
        <p className="font_s fw_4">{annoucement?.title}</p>
      </div>
      <div className="annouce-date-wrapper">
        <p className="font_xxs fw_3 font_grey">
          {moment(annoucement?.createdAt).format(DATE_TIME_FORMAT)}
        </p>
        <p>
          <AiOutlineRight size={20} />
        </p>
      </div>
    </div>
  );
};
/* Component ends */

export default Annoucement;
