/**
 * Slice to handle authentication
 * @author Abdul Ahad <aabdul@dgrnte.com>
 *
 */

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { axiosInstance } from "../axios";
import { PLATFORM } from "../../platformUtil";
import { API_URL } from "../../config/devKeys";
import {
  setLoading,
  setShowUploadProgress,
  setUploadProgress,
} from "./unpersistedSlice";
import GLOBAL from "../../constants/global";
import { setDateFormat } from "./masterSlice";
import { setTimeFormat } from "./masterSlice";

const initialState = {
  accessToken: null,
  user: null,
  userCurrency: "",
  userTheme: "",
  userRole: null,
  isCLIENT: false,
  isOwner: false,
  isPMC: false,
  isPM: false,
  isACC: false,
  isFMC: false,
  isFM: false,
  isMT: false,
  isSMC: false,
  isSM: false,
  isSO: false,
  isTPC: false,
  isTPM: false,
  isTPT: false,
  isTENANT: false,
};

export const signup = createAsyncThunk(
  "user/signup",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(API_URL + "/user/signUp", payload);
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const signin = createAsyncThunk(
  "user/signIn",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(API_URL + "/user/signIn", payload);
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const privacyPolicyContent = createAsyncThunk(
  "user/privacy_policy_content",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.get(
        "https://www.dgrnte.com/api/privacyAndPolicyPage/getDetails/privacyAndPolicy"
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const tenantSetPassword = createAsyncThunk(
  "tenant/set_password",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/tenant/set_password",
        payload
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const fetchTenant = createAsyncThunk(
  "user/tenant/fetchTenant",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      let token = !payload ? localStorage.getItem("user_token") : payload;
      dispatch(setLoading(true));
      const { data } = await axios.get(API_URL + "/tenant/me", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch(setLoading(false));
      if (data.success) {
        localStorage.setItem("user_data", JSON.stringify(data.data));
        dispatch(setUser(data.data));
        return data.data;
      } else {
        return rejectWithValue(data);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const fetchTenantWithoutSet = createAsyncThunk(
  "user/tenant/fetchTenant/Wihout_setting",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      let token = !payload ? localStorage.getItem("user_token") : payload;
      dispatch(setLoading(true));
      const { data } = await axios.get(API_URL + "/tenant/me", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const resendVerificationEmail = createAsyncThunk(
  "user/resend_email_verification_link",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await axios.post(
        API_URL + "/user/resend_email_verification_link",
        payload
      );
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error?.response);
    }
  }
);

export const fetchUser = createAsyncThunk(
  "user/get_token",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { countrycode, mobile } = JSON.parse(
        localStorage.getItem("user_data")
      );

      const { data } = await axios.post(
        API_URL + "/user/get_token",
        {
          countrycode,
          mobile,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success && localStorage.getItem("user_token")) {
        dispatch(setUser(data.data.user));
        await dispatch(setDateFormat(data.data.user.date_format));
        await dispatch(setTimeFormat(data.data.user.time_format));
        localStorage.setItem("user_data", JSON.stringify(data.data.user));
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const resendVerificationCode = createAsyncThunk(
  "user/resend_verification_code",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await axios.post(
        API_URL + "/user/resend_verification_code",
        payload
      );
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error?.response);
    }
  }
);

export const verifyAccount = createAsyncThunk(
  "user/accountverify",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/user/otp/accountverify",
        payload
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const verifyEmailOtp = createAsyncThunk(
  "user/otp/verifyEmail",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/user/otp/verifyEmail",
        payload,
        {
          headers: {
            Authorization: `Bearer ${payload.jwt}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const validateUserInvitation = createAsyncThunk(
  "invitation/validate",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/invitation/validate",
        payload
        // {
        //   headers: {
        //     Authorization: `Bearer ${payload.jwt}`,
        //   },
        // }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const updateUserPreference = createAsyncThunk(
  "user/update_user_pref",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/user/update_user_pref",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        if (localStorage.getItem("user_role") === "Tenant") {
          await dispatch(fetchTenant());
        } else {
          await dispatch(fetchUser());
        }
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const addProfilePicture = createAsyncThunk(
  "user/updateProfilePicture",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const formData = new FormData();
      Object.keys(payload).map((key) => {
        if (key == "myphoto") {
          if (PLATFORM !== "web") {
            formData.append("myphoto", {
              name: payload.myphoto.name,
              type: payload.myphoto.type,
              uri:
                PLATFORM === "android"
                  ? payload.myphoto.uri
                  : payload.myphoto.uri.replace("file://", ""),
            });
          } else {
            formData.append("myphoto", payload[key], payload[key].name);
          }
        } else {
          formData.append(key, payload[key]);
        }
      });
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }
      // const response = await fetch(API_URL + "/user/updateProfilePicture", {
      //   method: "POST",
      //   headers: headers,
      //   body: formData,
      // });
      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "POST",
        url: "/user/updateProfilePicture",
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      // const data = await response.json();

      const { data } = await axiosInstance.request(config);

      dispatch(setLoading(false));
      if (data.success) {
        await dispatch(fetchUser());
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error.message);
    }
  }
);

export const updateProfile = createAsyncThunk(
  "user/updateProfile",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.patch(
        API_URL + "/user/updateProfile",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        dispatch(fetchUser());
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const updateUserTheme = createAsyncThunk(
  "user/updateUserTheme",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/user/update_user_theme",
        // "http://localhost:3000/user/update_user_theme",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );

      dispatch(setLoading(false));
      if (data.success) {
        dispatch(fetchUser());
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// TO UPDATE TENANT THEME
export const updateTenantTheme = createAsyncThunk(
  "tenant/update_tenant_theme",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/tenant/update_tenant_theme",
        // "http://localhost:3000/tenant/update_tenant_theme",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );

      dispatch(setLoading(false));
      if (data.success) {
        dispatch(fetchTenant());
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// UPDATE TENANT
export const updateTenantProfile = createAsyncThunk(
  "tenant/update",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(API_URL + "/tenant/update", payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        },
      });
      dispatch(setLoading(false));
      if (data.success) {
        dispatch(fetchTenant());
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// UPDATE TEANANT PROFILE PICTURE
export const addTenantProfilePicture = createAsyncThunk(
  "tenant/updateProfile",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const formData = new FormData();
      Object.keys(payload).map((key) => {
        if (key == "myphoto") {
          if (PLATFORM !== "web") {
            formData.append("myphoto", {
              name: payload.myphoto.name,
              type: payload.myphoto.type,
              uri:
                PLATFORM === "android"
                  ? payload.myphoto.uri
                  : payload.myphoto.uri.replace("file://", ""),
            });
          } else {
            formData.append("myphoto", payload[key], payload[key].name);
          }
        } else {
          formData.append(key, payload[key]);
        }
      });
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }
      // const response = await fetch(API_URL + "/tenant/updateProfile", {
      //   method: "POST",
      //   headers: headers,
      //   body: formData,
      // });
      // const data = await response.json();
      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "POST",
        url: "/tenant/updateProfile",
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      const { data } = await axiosInstance.request(config);

      dispatch(setLoading(false));
      if (data.success) {
        await dispatch(fetchTenant());
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error.message);
    }
  }
);

export const sendForgotPasswordOtp = createAsyncThunk(
  "user/forgot_password",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/user/forgot_password",
        payload
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const changePassword = createAsyncThunk(
  "user/change_password",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/user/change_password",
        payload
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// Update Email
export const updateUserEmail = createAsyncThunk(
  "user/change_email",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/user/change_email",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// Update Mobile Number
export const updateUserMobile = createAsyncThunk(
  "user/change_mobile",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/user/change_phone_number",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const searchUser = createAsyncThunk(
  "user/search",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.get(API_URL + "/user?" + payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        },
      });
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const updateUserBilling = createAsyncThunk(
  "user/billing",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.patch(API_URL + "/user/billing", payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        },
      });
      dispatch(setLoading(false));
      if (data.success) {
        dispatch(fetchUser());
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const clientAccountSuspensionInitiate = createAsyncThunk(
  "client/initiate_account_suspension",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.get(
        API_URL + "/client/initiate_account_suspension",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        dispatch(fetchUser());
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const clientAccountSuspension = createAsyncThunk(
  "client/suspend_my_account",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/client/suspend_my_account",
        // "http://localhost:3000/client/suspend_my_account",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        dispatch(fetchUser());
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const clientAccountEnable = createAsyncThunk(
  "/client/enable_my_account",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.get(API_URL + "/client/enable_my_account", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        },
      });
      dispatch(setLoading(false));
      if (data.success) {
        dispatch(fetchUser());
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const clientAccountDeactivationInitiate = createAsyncThunk(
  "client/initiate_account_deactivation",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.get(
        API_URL + "/client/initiate_account_deactivation",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        dispatch(fetchUser());
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const clientAccountDeactivation = createAsyncThunk(
  "client/deactivate_my_account",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/client/deactivate_my_account",
        // 'http://localhost:3000/client/deactivate_my_account',
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        dispatch(fetchUser());
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const logoutNow = createAsyncThunk(
  "user/logout",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/user/log_out",
        { token: localStorage.getItem("notification_token") || "" },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    setToken: (state, action) => {
      state.accessToken = action.payload;
    },
    setUser: (state, action) => {
      if (action.payload) {
        state.user = action.payload;
        state.userCurrency = action.payload.currency;
        state.userTheme = action.payload.theme;
      } else {
        state.user = null;
      }
    },
    setUserRole: (state, action) => {
      state.userRole = action.payload;
      state.isCLIENT = action.payload === GLOBAL.USER_ROLE.CLIENT;
      state.isOwner = action.payload === GLOBAL.USER_ROLE.OWNER;
      state.isPMC = action.payload === GLOBAL.USER_ROLE.PMC;
      state.isPM = action.payload === GLOBAL.USER_ROLE.PM;
      state.isACC = action.payload === GLOBAL.USER_ROLE.ACCOUNTANT;
      state.isFMC = action.payload === GLOBAL.USER_ROLE.FMC;
      state.isFM = action.payload === GLOBAL.USER_ROLE.FM;
      state.isMT = action.payload === GLOBAL.USER_ROLE.MT;
      state.isSMC = action.payload === GLOBAL.USER_ROLE.SMC;
      state.isSM = action.payload === GLOBAL.USER_ROLE.SM;
      state.isSO = action.payload === GLOBAL.USER_ROLE.SO;
      state.isTPC = action.payload === GLOBAL.USER_ROLE.TPC;
      state.isTPM = action.payload === GLOBAL.USER_ROLE.TPM;
      state.isTPT = action.payload === GLOBAL.USER_ROLE.TPT;
      state.isTENANT = action.payload === GLOBAL.USER_ROLE.TENANT;
    },
    logOutUser: (state, action) => {
      state.accessToken = null;
      state.user = null;
      state.userCurrency = "";
      state.userTheme = "";
      state.userRole = null;
      state.isCLIENT = false;
      state.isOwner = false;
      state.isPMC = false;
      state.isPM = false;
      state.isACC = false;
      state.isFMC = false;
      state.isFM = false;
      state.isMT = false;
      state.isSMC = false;
      state.isSM = false;
      state.isSO = false;
      state.isTPC = false;
      state.isTPM = false;
      state.isTPT = false;
      state.isTENANT = false;
    },
  },
  extraReducers: {
    [fetchTenant.fulfilled]: (state, action) => {
      state.user = action.payload;
    },
  },
});

export const { setUser, setToken, setUserRole, logOutUser } = authSlice.actions;

export const getAccessToken = (state) => state.auth.accessToken;
export const getUser = (state) => state.auth.user;
export const getLoggedinUserRole = (state) => state.auth.userRole;

export const getUserCurrency = (state) => state.auth.userCurrency;

// USER THEME
export const getUserTheme = (state) => state.auth.userTheme;

export const getIsCLIENT = (state) => state.auth.isCLIENT;
export const getIsOwner = (state) => state.auth.isOwner;
export const getIsPMC = (state) => state.auth.isPMC;
export const getIsPM = (state) => state.auth.isPM;
export const getIsACC = (state) => state.auth.isACC;
export const getIsFMC = (state) => state.auth.isFMC;
export const getIsFM = (state) => state.auth.isFM;
export const getIsMT = (state) => state.auth.isMT;
export const getIsSMC = (state) => state.auth.isSMC;
export const getIsSM = (state) => state.auth.isSM;
export const getIsSO = (state) => state.auth.isSO;
export const getIsTPC = (state) => state.auth.isTPC;
export const getIsTPM = (state) => state.auth.isTPM;
export const getIsTPT = (state) => state.auth.isTPT;
export const getIsTENANT = (state) => state.auth.isTENANT;

export default authSlice.reducer;
