/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  fetchMovesList,
  fetchMovesListBasedCommunity,
  fetchMovesListBasedProperty,
  fetchSearchedMovesList,
  getCommunityMovesList,
  getMovesList,
  getPropertyMovesList,
} from "../../setup/store/slices/movesDashboardSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { EnvelopeOutLinedBlue, PrintIcon } from "../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import GLOBAL from "../../setup/constants/global";
import Header from "./local-component/Header";
import PieChartGraph from "./local-component/PieChartGraph";
import StatusPieChartGraph from "./local-component/StatusPieChartGraph";
import Pagination from "../../components/module/RnD/Pagination/Pagination";
import Type3DPieChart from "../../components/ui/other/PieCharts/Type3DPieChart";
import Status3DPieChart from "../../components/ui/other/PieCharts/Status3DPieChart";
import Searchbar from "../../components/ui/other/Searchbar/Searchbar";
import MoveTable from "./local-component/MoveTable";
import CustomDateInput from "../../components/ui/input/CustomDateInput/CustomDateInput";
// import CustomButton from "../../components/ui/button/CustomButton/CustomButton";
// import TextButton from "../../components/ui/button/TextButton/TextButton";
import { getUniqueValues } from "../../setup/utils/global-helper";
import EmailModal from "../../components/ui/modal/EmailModal/EmailModal";
/* Import local pages and component ends */

import "./style.scss";
import BreadCrumb from "../../components/ui/other/BreadCrumb/BreadCrumb";
import moment from "moment";

/* Component starts */
const MoveDashboard = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const startDate = new Date();
  // const endDate = new Date();
  const [fromDate, setFromDate] = useState(
    new Date(startDate.getFullYear(), startDate.getMonth(), 1)
  );
  const [toDate, setToDate] = useState(
    // new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0)
    new Date()
  );

  const [search, setSearch] = useState("");
  const [movesData, setMovesData] = useState([]);
  const [filterMoveIns, setFilterMoveIns] = useState();

  const [typeData, setTypeData] = useState();
  const [statusData, setStatusData] = useState();
  const [cleared, setCleared] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [showModal, setShowModal] = useState(false);

  // FILTERS
  const [communityData, setCommunityData] = useState();
  const [multiSelectCommunity, setMultiSelectCommunity] = useState();
  const [propertyData, setPropertyData] = useState();
  const [multiSelectProperty, setMultiSelectProperty] = useState();

  const [unitTypeData, setUnitTypeData] = useState();
  const [multiSelectUnitType, setMultiSelectUnitType] = useState();

  const [tenantData, setTenantData] = useState();
  const [multiSelectTenant, setMultiSelectTenant] = useState();

  const [statusData1, setStatusData1] = useState();
  const [multiSelectStatus, setMultiSelectStatus] = useState();

  const [unitNumData, setUnitNumData] = useState();
  const [multiSelectUnitNum, setMultiSelectUnitNum] = useState();

  const [processingData, setProcessingData] = useState();
  const [multiSelectProcessing, setMultiSelectProcessing] = useState();

  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const { pathname } = useLocation();
  const param = useParams();
  const dispatch = useDispatch();
  const movesList = useSelector(getMovesList);
  const propertyMovesList = useSelector(getPropertyMovesList);
  const communityMovesList = useSelector(getCommunityMovesList);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItemsList = filterMoveIns?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  const loadMovesList = async () => {
    if (pathname.includes("communities")) {
      await dispatch(
        fetchMovesListBasedCommunity({
          community_id: param.communityId,
        })
      );
    } else if (pathname.includes("independent")) {
      await dispatch(
        fetchMovesListBasedProperty({ property_id: param.propertyId })
      );
    } else {
      await dispatch(fetchMovesList());
    }
  };

  const filterTypeOfMoves = (occupancy) => {
    let oneBedRoom = occupancy?.filter(
      (d) => d.unit_type === "1 Bedroom Apartment"
    );
    let twoBedRoom = occupancy?.filter(
      (d) => d.unit_type === "2 Bedroom Apartment"
    );
    let threeBedRoom = occupancy?.filter(
      (d) => d.unit_type === "3 Bedroom Apartment"
    );
    let fourBedRoom = occupancy?.filter(
      (d) => d.unit_type === "4 Bedroom Apartment"
    );
    let condominium = occupancy?.filter((d) => d.unit_type === "Condominium");
    let multiHome = occupancy?.filter(
      (d) => d.unit_type === "Multi Family Home"
    );
    let singleHome = occupancy?.filter(
      (d) => d.unit_type === "Single Family Home"
    );
    let studio = occupancy?.filter((d) => d.unit_type === "Studio");
    let townHouse = occupancy?.filter((d) => d.unit_type === "Town House");
    let villa = occupancy?.filter((d) => d.unit_type === "Villa");
    let pentHouse = occupancy?.filter((d) => d.unit_type === "Pent House");
    let retailShop = occupancy?.filter((d) => d.unit_type === "Retail Shop");
    let store = occupancy?.filter((d) => d.unit_type === "Store");
    let warehouse = occupancy?.filter((d) => d.unit_type === "Warehouse");
    let office = occupancy?.filter((d) => d.unit_type === "Office");

    const obj = [];
    obj.push(["Unit Type", "No. Of Type"]);
    if (oneBedRoom?.length > 0) {
      obj.push(["1 Bedroom Apartment", oneBedRoom?.length]);
    }
    if (twoBedRoom?.length > 0) {
      obj.push(["2 Bedroom Apartment", twoBedRoom?.length]);
    }
    if (threeBedRoom?.length > 0) {
      obj.push(["3 Bedroom Apartment", threeBedRoom?.length]);
    }

    if (fourBedRoom?.length > 0) {
      obj.push(["4 Bedroom Apartment", fourBedRoom?.length]);
    }
    if (condominium?.length > 0) {
      obj.push(["Condominium", condominium?.length]);
    }
    if (multiHome?.length > 0) {
      obj.push(["Multi Family Home", multiHome?.length]);
    }

    if (singleHome?.length > 0) {
      obj.push(["Single Family Home", singleHome?.length]);
    }
    if (studio?.length > 0) {
      obj.push(["Studio", studio?.length]);
    }
    if (townHouse?.length > 0) {
      obj.push(["Town House", townHouse?.length]);
    }

    if (villa?.length > 0) {
      obj.push(["Villa", villa?.length]);
    }
    if (pentHouse?.length > 0) {
      obj.push(["Pent House", pentHouse?.length]);
    }

    if (retailShop?.length > 0) {
      obj.push(["Retail Shop", retailShop?.length]);
    }

    if (store?.length > 0) {
      obj.push(["Store", store?.length]);
    }
    if (warehouse?.length > 0) {
      obj.push(["Warehouse", warehouse?.length]);
    }
    if (office?.length > 0) {
      obj.push(["Office", office?.length]);
    }
    setTypeData(obj);
  };

  const filterStatusOfMoves = (occupancy) => {
    let newStatus = occupancy?.filter((d) => d.status === "New");
    let completed = occupancy?.filter((d) => d.status === "Completed");
    let closed = occupancy?.filter((d) => d.status === "Closed");
    let pending = occupancy?.filter((d) => d.status === "Pending");

    const obj = [];
    obj.push(["Status", "No. Of Status"]);
    if (newStatus?.length > 0) {
      obj.push(["New", newStatus?.length]);
    }
    if (completed?.length > 0) {
      obj.push(["Completed", completed?.length]);
    }
    if (closed?.length > 0) {
      obj.push(["Closed", closed?.length]);
    }

    if (pending?.length > 0) {
      obj.push(["Pending", pending?.length]);
    }

    setStatusData(obj);

    // setStatusData([
    //   { name: "Vacant", value: vacant.length },
    //   { name: "Occupied", value: occupied.length },
    //   { name: "Reserved", value: reserved.length },
    // ]);
  };

  const handleSearch = async (isReset = false, resetValue = {}) => {
    let payload = {};
    if (!isReset) {
      if (search && fromDate && toDate) {
        payload = {
          searchKey: search,
          start_date: moment(fromDate)?.format("MM-DD-YYYY"),
          end_date: moment(toDate)?.format("MM-DD-YYYY"),
        };
        // Backend response. Try, Catch
        try {
          let result;
          if (pathname.includes("communities")) {
            payload = {
              ...payload,
              community_id: param.communityId,
            };
            result = await dispatch(fetchSearchedMovesList(payload));
          } else if (pathname.includes("independent")) {
            payload = {
              ...payload,
              property_id: param.propertyId,
            };
            result = await dispatch(fetchSearchedMovesList(payload));
          } else {
            result = await dispatch(fetchSearchedMovesList(payload));
          }
          // Handling success response
          switch (result.meta.requestStatus) {
            case GLOBAL.REJECTED:
              // setModalDesc(result.payload);
              // setShowErrorModal(true);
              break;

            case GLOBAL.FULFILLED:
              // setModalDesc(result.payload.message);
              // setShowSuccessModal(true);
              setMovesData(result.payload);
              setFilterMoveIns(result.payload);

              break;
          }
        } catch (error) {
          // Handling error response
          console.error(error.message);
        }
      } else if (search) {
        payload = {
          searchKey: search,
        };

        await dispatch(fetchSearchedMovesList(payload));
      } else if (!search && fromDate && toDate) {
        payload = {
          searchKey: "",
          start_date: moment(fromDate)?.format("MM-DD-YYYY"),
          end_date: moment(toDate)?.format("MM-DD-YYYY"),
        };
        // Backend response. Try, Catch
        try {
          let result;
          if (pathname.includes("communities")) {
            payload = {
              ...payload,
              community_id: param.communityId,
            };
            result = await dispatch(fetchSearchedMovesList(payload));
          } else if (pathname.includes("independent")) {
            payload = {
              ...payload,
              property_id: param.propertyId,
            };
            result = await dispatch(fetchSearchedMovesList(payload));
          } else {
            result = await dispatch(fetchSearchedMovesList(payload));
          }
          // Handling success response
          switch (result.meta.requestStatus) {
            case GLOBAL.REJECTED:
              // setModalDesc(result.payload);
              // setShowErrorModal(true);
              break;

            case GLOBAL.FULFILLED:
              // setModalDesc(result.payload.message);
              // setShowSuccessModal(true);
              setMovesData(result.payload);
              setFilterMoveIns(result.payload);
              break;
          }
        } catch (error) {
          // Handling error response
          console.error(error.message);
        }
      }
    }
    // else {
    //   payload = {
    //     ...resetValue,
    //   };
    // }
  };

  const handleClear = () => {
    let payload = {
      searchKey: "",
      start_date: new Date(startDate.getFullYear(), startDate.getMonth(), 1),
      end_date: new Date(),
    };
    setSearch(payload.searchKey);
    setFromDate(payload.start_date);
    setToDate(payload.end_date);

    handleSearch(true, payload);
    setCleared(!cleared);
  };

  // To check the any checkbox is true or not
  const checkAnyFilterChecked = (filter) => {
    return Object?.keys(filter)?.every(function (k) {
      return !filter[k];
    });
  };

  // SEND EMAIL FUNCTION
  const handleSendEmail = () => {
    setShowModal(!showModal);
  };

  /* Component function definition ends */

  /* Component useEffect starts */

  useEffect(() => {
    loadMovesList();
  }, [pathname, !cleared]);

  useEffect(() => {
    if (pathname.includes("communities") && communityMovesList) {
      setMovesData(communityMovesList);
      setFilterMoveIns([...communityMovesList]);
      filterTypeOfMoves(movesData);
      filterStatusOfMoves(movesData);
    } else if (pathname.includes("independent") && propertyMovesList) {
      setMovesData(propertyMovesList);
      setFilterMoveIns([...propertyMovesList]);
      filterTypeOfMoves(movesData);
      filterStatusOfMoves(movesData);
    } else {
      setMovesData(movesList);
      setFilterMoveIns([...movesList]);
      filterTypeOfMoves(movesData);
      filterStatusOfMoves(movesData);
    }
  }, [pathname, movesList, communityMovesList, propertyMovesList, !cleared]);

  useEffect(() => {
    if (pathname.includes("communities") && communityMovesList) {
      filterTypeOfMoves(movesData);
      filterStatusOfMoves(movesData);
    } else if (pathname.includes("independent") && propertyMovesList) {
      filterTypeOfMoves(movesData);
      filterStatusOfMoves(movesData);
    } else {
      filterTypeOfMoves(movesData);
      filterStatusOfMoves(movesData);
    }
  }, [!search, movesData]);

  useEffect(() => {
    const community = getUniqueValues(movesData, "community_name");
    const property = getUniqueValues(movesData, "property_name");
    const unitType = getUniqueValues(movesData, "unit_type");
    const tenant = getUniqueValues(movesData, "tenant");
    const status = getUniqueValues(movesData, "status");

    const processing = getUniqueValues(movesData, "processing");
    const unitNum = getUniqueValues(movesData, "unit_num");

    const communityObject = {};
    community.forEach((prop) => (communityObject[prop] = false));
    setMultiSelectCommunity(communityObject);
    setCommunityData(community);

    const propertyObject = {};
    property.forEach((prop) => (propertyObject[prop] = false));
    setMultiSelectProperty(propertyObject);
    setPropertyData(property);

    const unitTypeObject = {};
    unitType.forEach((prop) => (unitTypeObject[prop] = false));
    setMultiSelectUnitType(unitTypeObject);
    setUnitTypeData(unitType);

    const tenantObject = {};
    tenant.forEach((prop) => (tenantObject[prop] = false));
    setMultiSelectTenant(tenantObject);
    setTenantData(tenant);

    const statusObject = {};
    status.forEach((prop) => (statusObject[prop] = false));
    setMultiSelectStatus(statusObject);
    setStatusData1(status);

    const processingObject = {};
    processing
      ?.sort((a, b) => a - b)
      .forEach((prop) => (processingObject[prop] = false));
    setMultiSelectProcessing(processingObject);
    setProcessingData(processing?.sort((a, b) => a - b));

    const unitNumObject = {};
    unitNum.forEach((prop) => (unitNumObject[prop] = false));
    setMultiSelectUnitNum(unitNumObject);
    setUnitNumData(unitNum);
  }, [movesData]);

  useEffect(() => {
    if (
      multiSelectCommunity !== undefined ||
      multiSelectProperty !== undefined ||
      multiSelectStatus !== undefined ||
      multiSelectProcessing !== undefined ||
      multiSelectUnitNum !== undefined ||
      multiSelectTenant !== undefined ||
      multiSelectUnitType !== undefined
    ) {
      let allUnCheckedCommunity = checkAnyFilterChecked(multiSelectCommunity);
      let allUnCheckedProperty = checkAnyFilterChecked(multiSelectProperty);
      let allUnCheckedTenant = checkAnyFilterChecked(multiSelectTenant);
      let allUnCheckedUnitType = checkAnyFilterChecked(multiSelectUnitType);
      let allUnCheckedStatus = checkAnyFilterChecked(multiSelectStatus);

      let allUnCheckedUnitNum = checkAnyFilterChecked(multiSelectUnitNum);
      let allUnCheckedProcessing = checkAnyFilterChecked(multiSelectProcessing);

      if (!allUnCheckedCommunity && multiSelectCommunity) {
        let selectedValue = Object.keys(multiSelectCommunity)?.filter(
          (key) => multiSelectCommunity[key]
        );

        const filteredCommunity = movesData?.filter((d) =>
          selectedValue?.includes(d.community_name)
        );

        setFilterMoveIns(filteredCommunity);
      } else if (!allUnCheckedProperty && multiSelectProperty) {
        let selectedValue = Object.keys(multiSelectProperty)?.filter(
          (key) => multiSelectProperty[key]
        );

        const filteredProperty = movesData?.filter((d) =>
          selectedValue?.includes(d.property_name)
        );

        setFilterMoveIns(filteredProperty);
      } else if (!allUnCheckedStatus && multiSelectStatus) {
        let selectedValue = Object.keys(multiSelectStatus)?.filter(
          (key) => multiSelectStatus[key]
        );

        const filteredStatus = movesData?.filter((d) =>
          selectedValue?.includes(d.status)
        );

        setFilterMoveIns(filteredStatus);
      } else if (!allUnCheckedUnitType && multiSelectUnitType) {
        let selectedValue = Object.keys(multiSelectUnitType)?.filter(
          (key) => multiSelectUnitType[key]
        );

        const filteredUnitType = movesData?.filter((d) =>
          selectedValue?.includes(d.unit_type)
        );

        setFilterMoveIns(filteredUnitType);
      } else if (!allUnCheckedUnitNum && multiSelectUnitNum) {
        let selectedValue = Object.keys(multiSelectUnitNum)?.filter(
          (key) => multiSelectUnitNum[key]
        );

        const filteredUnitNum = movesData?.filter((d) =>
          selectedValue?.includes(d.unit_num)
        );

        setFilterMoveIns(filteredUnitNum);
      } else if (!allUnCheckedProcessing && multiSelectProcessing) {
        let selectedValue = Object.keys(multiSelectProcessing)
          ?.filter((key) => multiSelectProcessing[key])
          ?.map(Number);

        const filteredProcessing = movesData?.filter((d) =>
          selectedValue?.includes(d.processing)
        );

        setFilterMoveIns(filteredProcessing);
      } else if (!allUnCheckedTenant && multiSelectTenant) {
        let selectedValue = Object.keys(multiSelectTenant)?.filter(
          (key) => multiSelectTenant[key]
        );

        const filteredTenant = movesData?.filter((d) =>
          selectedValue?.includes(d.tenant)
        );

        setFilterMoveIns(filteredTenant);
      }
    } else {
      setFilterMoveIns(movesData);
    }
  }, [
    multiSelectCommunity,
    multiSelectProperty,
    multiSelectStatus,
    multiSelectTenant,
    multiSelectUnitType,
    multiSelectUnitNum,
    multiSelectProcessing,
  ]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      <BreadCrumb type="dashboard_moveIn" />
      <div className="move-dashboard-wrapper">
        <div className="action-wrapper">
          <div>
            <img src={PrintIcon} alt="Print-Icon" />
            <span className="font_m fw_3 font_blue">Print</span>
          </div>

          <div onClick={handleSendEmail}>
            <img src={EnvelopeOutLinedBlue} alt="Print-Icon" />
            <span className="font_m fw_3 font_blue">Email</span>
          </div>
        </div>
        <div className="header">
          <Header />
        </div>

        {movesData?.length > 0 && (
          <div className="report-chart-wrapper">
            <div className="detail-box ">
              <div>
                <p className="font_l fw_4">Unit Type</p>
              </div>
              {/* <div>
              <PieChartGraph typeData={typeData} />
            </div> */}
              <Type3DPieChart data={typeData} label="Unit Type" />
            </div>

            <div className="detail-box ">
              <div>
                <p className="font_l fw_4">Status</p>
              </div>
              {/*  <div>
              <StatusPieChartGraph statusData={statusData} />
            </div> */}
              <Status3DPieChart data={statusData} label="Status" />
            </div>
          </div>
        )}

        <div className="search-box mt-4 mb-4">
          <div className="search">
            <Searchbar
              setQuery={setSearch}
              query={search}
              className="dash-search"
            />
          </div>

          <div className="date">
            <div>
              <CustomDateInput
                label="From"
                value={fromDate}
                minDate={false}
                customOnchange={(date) => setFromDate(date)}
                required={true}
              />
            </div>

            <div>
              <CustomDateInput
                label="To"
                value={toDate}
                minDate={false}
                customOnchange={(date) => setToDate(date)}
                required={true}
              />
            </div>
          </div>

          <div className="btn-wrapper">
            <button
              onClick={() => handleSearch()}
              // disabled={!search}
            >
              Apply
            </button>
            <button className="btn-clear" onClick={handleClear}>
              Clear
            </button>
          </div>
        </div>

        <div className="move-table-wrapper">
          <MoveTable
            movesListData={currentItemsList}
            communityData={communityData}
            multiSelectCommunity={multiSelectCommunity}
            setMultiSelectCommunity={setMultiSelectCommunity}
            propertyData={propertyData}
            multiSelectProperty={multiSelectProperty}
            setMultiSelectProperty={setMultiSelectProperty}
            unitType={unitTypeData}
            multiSelectUnitType={multiSelectUnitType}
            setMultiSelectUnitType={setMultiSelectUnitType}
            tenant={tenantData}
            multiSelectTenant={multiSelectTenant}
            setMultiSelectTenant={setMultiSelectTenant}
            statusData1={statusData1}
            multiSelectStatus={multiSelectStatus}
            setMultiSelectStatus={setMultiSelectStatus}
            unitNum={unitNumData}
            multiSelectUnitNum={multiSelectUnitNum}
            setMultiSelectUnitNum={setMultiSelectUnitNum}
            processing={processingData}
            multiSelectProcessing={multiSelectProcessing}
            setMultiSelectProcessing={setMultiSelectProcessing}
          />

          {filterMoveIns?.length > 0 && (
            <Pagination
              itemsPerPage={itemsPerPage}
              totalItems={filterMoveIns?.length}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          )}
        </div>

        {/* EMAIL MODAL */}
        <EmailModal
          showModal={showModal}
          onSuccess={() => setShowModal(!showModal)}
          onClose={() => setShowModal(!showModal)}
        />
      </div>
    </>
  );
};
/* Component ends */

export default MoveDashboard;
