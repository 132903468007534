/**
 * Slice to handle community
 * @author Abdul Ahad <abdulahad.ss@smartdatainc.net>
 *
 */

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { axiosInstance } from "../axios";
import { PLATFORM } from "../../platformUtil";
import { API_URL } from "../../config/devKeys";
import {
  setLoading,
  setShowUploadProgress,
  setUploadProgress,
} from "./unpersistedSlice";

const initialState = {
  communities: [],
  communityDetail: {},
};

export const fetchCommunityDetailByID = createAsyncThunk(
  "community/fetch_details_by_id",
  async (payload, { getState, dispatch, rejectWithValue }) => {
    const { community_id } = payload;
    try {
      dispatch(setLoading(true));
      const { data } = await axios.get(
        API_URL + `/community/get/${community_id}`,

        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error);
      }
    } catch (error) {
      return rejectWithValue(error?.response.message);
    }
  }
);

export const fetchCommunities = createAsyncThunk(
  "community/list",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await axios.get(API_URL + "/community/list", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        },
      });
      if (data.success) {
        return data.data;
      } else {
        rejectWithValue(data.error);
      }
    } catch (error) {
      return rejectWithValue(error?.response);
    }
  }
);

export const addCommunity = createAsyncThunk(
  "community/add",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      // dispatch(setLoading(true));
      const formData = new FormData();
      Object.keys(payload).map((key) => {
        if (key == "image") {
          if (PLATFORM !== "web") {
            formData.append("image", {
              name: payload.image.name,
              type: payload.image.type,
              uri:
                PLATFORM === "android"
                  ? payload.image.uri
                  : payload.image.uri.replace("file://", ""),
            });
          } else {
            formData.append("image", payload[key], payload[key].name);
          }
        } else {
          formData.append(key, payload[key]);
        }
      });
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }

      // const response = await fetch(API_URL + "/community/add", {
      //   method: "POST",
      //   headers: headers,
      //   body: formData,
      // });
      // const data = await response.json();
      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "POST",
        url: "/community/add",
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };
      const { data } = await axiosInstance.request(config);

      // dispatch(setLoading(false));
      if (data.success) {
        await dispatch(fetchCommunities());
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      // dispatch(setLoading(false));
      console.error(error.response);
      return rejectWithValue(error.message);
    }
  }
);

export const updateCommunityWithoutImage = createAsyncThunk(
  "community/update",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/community/update",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        await dispatch(fetchCommunities());
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return error?.response;
    }
  }
);

export const updateCommunityWithImage = createAsyncThunk(
  "community/update",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const formData = new FormData();
      Object.keys(payload).map((key) => {
        if (key == "image") {
          if (PLATFORM !== "web") {
            formData.append("image", {
              name: "image." + payload.image.type.split("/")[1],
              type: payload.image.type,
              uri:
                PLATFORM === "android"
                  ? payload.image.uri
                  : payload.image.uri.replace("file://", ""),
            });
          } else {
            formData.append("image", payload[key], payload[key].name);
          }
        } else {
          formData.append(key, payload[key]);
        }
      });
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }
      // const response = await fetch(API_URL + "/community/update", {
      //   method: "POST",
      //   headers: headers,
      //   body: formData,
      // });
      // const data = await response.json();
      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "POST",
        url: "/community/update",
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      // const data = await response.json();

      const { data } = await axiosInstance.request(config);

      dispatch(setLoading(false));
      if (data.success) {
        await dispatch(fetchCommunities());
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return error?.response;
    }
  }
);

export const deleteCommunity = createAsyncThunk(
  "community/delete",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/community/delete",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        await dispatch(fetchCommunities());
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const addCommunityImage = createAsyncThunk(
  "community/image",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const formData = new FormData();

      Object.keys(payload).map((key) => {
        if (key === "image") {
          if (PLATFORM !== "web") {
            formData.append("image", {
              name: payload.image.name,
              type: payload.image.type,
              uri:
                PLATFORM === "android"
                  ? payload.image.uri
                  : payload.image.uri.replace("file://", ""),
            });
          } else {
            formData.append("image", payload[key], payload[key].name);
          }
        } else {
          formData.append(key, payload[key]);
        }
      });

      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        // "Content-Type": "multipart/form-data",
      };

      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }

      // const response = await fetch(API_URL + "/community/image", {
      //   method: "POST",
      //   headers: headers,
      //   body: formData,
      // });
      // const data = await response.json();
      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "POST",
        url: "/community/image",
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      // const data = await response.json();

      const { data } = await axiosInstance.request(config);

      dispatch(setLoading(false));
      if (data.success) {
        await dispatch(fetchCommunities());
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      console.error(error.response);
      return rejectWithValue(error.message);
    }
  }
);

export const deleteCommunityImage = createAsyncThunk(
  "community/delete_property_image",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.delete(API_URL + "/community/delete_image", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        },
        data: payload,
      });
      dispatch(setLoading(false));
      if (data.success) {
        await dispatch(fetchCommunities());
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const setDefaultCommunityImage = createAsyncThunk(
  "community/set_default_image",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.patch(
        API_URL + "/community/set_default_image",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        await dispatch(fetchCommunities());
        dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return error?.response;
    }
  }
);

const communitySlice = createSlice({
  name: "community",
  initialState: initialState,
  extraReducers: {
    [fetchCommunities.rejected]: (state, action) => {
      state.communities = [];
    },
    [fetchCommunities.fulfilled]: (state, action) => {
      state.communities = action.payload;
    },
    [fetchCommunityDetailByID.rejected]: (state, action) => {
      state.communityDetail = {};
    },
    [fetchCommunityDetailByID.fulfilled]: (state, action) => {
      state.communityDetail = action.payload;
    },
  },
});

export const getCommunities = (state) => state.community.communities;
export const getCommunityDetail = (state) => state.community.communityDetail;

export default communitySlice.reducer;
