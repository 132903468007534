/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { WarningRoundedOutlineYellow } from "../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
/* Import local pages and component ends */

/* Component starts */
const ContractRenewalStatus = (props) => {
  /* Props destructuring starts */
  const { text = "No text", color = "font_yellow" } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="contract-renewal-status-wrapper">
      <div className="img-wrapper text-center">
        <img src={WarningRoundedOutlineYellow} className="icon-l" alt="" />
      </div>
      <h2 className={`status-text text-center ${color} font_l fw_6`}>{text}</h2>
    </div>
  );
};
/* Component ends */

export default ContractRenewalStatus;
