/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import moment from "moment";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import GLOBAL from "../../../../../../../setup/constants/global";
/* Import local pages and component ends */

/* Component starts */
const DetailCard = (props) => {
  /* Props destructuring starts */
  const { violation } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */

  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */

  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="detail-card">
      <h3 className="num fw_6 font_l font_grey">
        {" "}
        Ticket No. :-{" "}
        <span className="font_blue fw_6">{violation?.violation_id}</span>
      </h3>

      <div className="my-3">
        <p className="title font_m fw_3 font_grey">
          Title <br />
          <span className="title font_m fw_3">{violation?.title}</span>
        </p>
      </div>

      <div className="location-wrapper">
        <div>
          <span className="font_m fw_3 font_grey issuer_data">Date & Time</span>
          <br />
          <span className="font_m fw_3">
            {moment(violation?.createdAt).format(DATE_TIME_FORMAT)}
          </span>
        </div>

        <div>
          {violation?.issued_to_role === "Tenant" && (
            <>
              <span className="font_m fw_3 font_grey issuer_role">Unit</span>
              <br />
              <span className="font_m fw_6 font_blue">{violation?.unit}</span>
            </>
          )}
        </div>
      </div>

      <hr />

      <div className="location-wrapper">
        <div>
          <span className="font_m fw_3 font_grey issuer_data">Issued To</span>
          <br /> <span className="font_m fw_6">{violation?.issued_to}</span>
        </div>

        <div>
          <span className="font_m fw_3 font_grey issuer_role">Role</span>
          <br />{" "}
          <span className="font_m fw_4">{violation?.issued_to_role}</span>
        </div>
      </div>

      <hr />

      <div className="location-wrapper">
        <div>
          <span className="font_m fw_3 font_grey issuer_data">Issuer</span>
          <br /> <span className={`font_m fw_6`}>{violation?.issuer}</span>
        </div>

        <div>
          <span className="font_m fw_3 font_grey issuer_role">Issuer Role</span>
          <br /> <span className="font_m fw_4">{violation?.issuer_role}</span>
        </div>
      </div>

      {violation?.email !== undefined &&
        violation?.issued_to_role !== "Others" && (
          <>
            <hr />
            <div>
              <span className="font_m fw_3 font_grey">Email</span>
              <br /> <span className="font_m fw_6 ">{violation?.email}</span>
            </div>
          </>
        )}

      {violation?.issued_to_role === "Others" && (
        <>
          <hr />

          <div className="location-wrapper">
            <div>
              <span className="font_m fw_3 font_grey issuer_data">Email</span>
              <br /> <span className="font_m fw_4 ">{violation?.email}</span>
            </div>

            <div>
              <span className="font_m fw_3 font_grey issuer_role">
                Mobile Number
              </span>
              <br /> <span className="font_m fw_4 ">{violation?.mobile}</span>
            </div>
          </div>

          <hr />

          <div className="location-wrapper">
            <div>
              <span className="font_m fw_3 font_grey issuer_data">Company</span>
              <br />{" "}
              <span className={`font_m fw_3 issuer_role`}>
                {violation?.company !== undefined ? violation?.company : "-"}
              </span>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
/* Component ends */

export default DetailCard;
