import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  show: false,
  title: "",
  type: "",
  description: "",
};
const globalAlertSlice = createSlice({
  name: "globalAlert",
  initialState,
  reducers: {
    showErrorAlert: (state, action) => {
      state.show = true;
      state.type = "error";
      state.title = action.payload.title;
      state.description = action.payload.description;
    },
    showSuccessAlert: (state, action) => {
      state.show = true;
      state.type = "success";
      state.title = action.payload.title;
      state.description = action.payload.description;
    },
    showWarningAlert: (state, action) => {
      state.show = true;
      state.type = "warning";
      state.title = action.payload.title;
      state.description = action.payload.description;
    },
    closeAlert: (state, action) => {
      state.show = false;
      state.type = "";
      state.title = "";
      state.description = "";
    },
  },
});

export const getShowAlert = (state) => state.globalAlert.show;
export const getAlertTitle = (state) => state.globalAlert.title;
export const getAlertType = (state) => state.globalAlert.type;
export const getAlertDescription = (state) => state.globalAlert.description;

export const {
  showSuccessAlert,
  showErrorAlert,
  showWarningAlert,
  closeAlert,
} = globalAlertSlice.actions;

export default globalAlertSlice.reducer;
