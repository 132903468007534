import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setLoading } from "./unpersistedSlice";
import axios from "../axios";
import { API_URL } from "../../config/devKeys";
import { fetchMaintenanceById } from "./maintenanceSlice";
import { fetchInternalMaintenanceById } from "./internalMaintenanceSlice";

const initialState = {
  ratingList: [],
};

export const addMaintenanceRating = createAsyncThunk(
  "rating_review/add",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/rating_review/add",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        if (payload.maintenance_id) {
          await dispatch(
            fetchMaintenanceById({ maintenance_id: payload.maintenance_id })
          );
        }
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const fetchTeamRatings = createAsyncThunk(
  "rating_review/team_list",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/rating_review/team_list",
        payload,
        // { property_id: getState().selection.property._id },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const fetchTenantTicketRatings = createAsyncThunk(
  "rating_review/tenant_ticket_list",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      console.log(payload, "PYA");
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/rating_review/tenant_ticket_list",
        payload,
        // { property_id: getState().selection.property._id },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const fetchTenantMemberRatings = createAsyncThunk(
  "rating_review/tenant_member_list",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/rating_review/tenant_member_list",
        { property_id: getState().selection.property._id },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const addInternalMaintenanceRating = createAsyncThunk(
  "rating_review/internal_add",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/internal_rating_review/add",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        if (payload.maintenance_id) {
          await dispatch(
            fetchInternalMaintenanceById({
              maintenance_id: payload.maintenance_id,
            })
          );
        }
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const ratingSlice = createSlice({
  name: "rating",
  initialState,
  extraReducers: {
    [fetchTeamRatings.fulfilled]: (state, action) => {
      state.ratingList = action.payload;
    },
    [fetchTenantMemberRatings.fulfilled]: (state, action) => {
      state.ratingList = action.payload;
    },
    [fetchTenantTicketRatings.fulfilled]: (state, action) => {
      state.ratingList = action.payload;
    },
  },
});

export const getRatings = (state) => state.rating.ratingList;

export default ratingSlice.reducer;
