/**
 *
 */

import React, { useState, useEffect, useRef } from "react";

/* Import configuration starts */
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */

/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import GLOBAL from "../../../../setup/constants/global";
import CustomTextArea from "../../../../components/ui/input/CustomTextArea/CustomTextArea";
import CustomButton from "../../../../components/ui/button/CustomButton/CustomButton";
import ErrorModal from "../../../../components/ui/modal/ErrorModal/ErrorModal";
import SuccessModal from "../../../../components/ui/modal/SuccessModal/SuccessModal";
import {
  assignedBackIncidentToSM,
  updateComplaintResolvedBySO,
} from "../../../../setup/store/slices/securitySlice";

/* Import local pages and component ends */

/* Component starts */
const T3IncidentReportBySO = (props) => {
  /* Props destructuring starts */
  const { incidentID } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [modalDesc, setModalDesc] = useState("");
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  const dispatch = useDispatch();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */

  const initialFormValues = {
    description: "",
  };

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  const onSuccess = () => {
    navigate(-1);
  };

  const handleClose = () => {
    navigate(-1);
  };

  const onSubmit = async (values) => {
    const payload = {
      note: values.description,
      security_incident_id: incidentID,
    };
    console.log(payload, "DESC");

    // Backend response. Try, Catch
    try {
      const result = await dispatch(assignedBackIncidentToSM(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setModalDesc(result.payload);
          setShowErrorModal(true);
          break;

        case GLOBAL.FULFILLED:
          setModalDesc(result.payload.message);
          setShowSuccessModal(true);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="complaint-report-form-wrapper">
      <Formik
        onSubmit={onSubmit}
        initialValues={initialFormValues}
        // validate={(values) => formValidation(values)}
        validateOnBlur={true}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isValid,
          dirty,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="form-wrapper p-3">
              <>
                {/* Text Area for Reason */}
                <div className="form-group form-group-full mt-2">
                  <CustomTextArea
                    name="description"
                    label="Comments"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    value={values.description}
                    errors={errors.description}
                    touched={touched.description}
                    required={true}
                  />
                </div>
              </>
            </div>

            <div className="form-btn-wrapper">
              <>
                <CustomButton
                  type="button"
                  size="l"
                  onClick={() => handleClose()}
                  className="btn1"
                >
                  Cancel
                </CustomButton>
                <CustomButton
                  type="submit"
                  disable={!(dirty && isValid)}
                  size="l"
                >
                  Submit
                </CustomButton>
              </>
            </div>
          </form>
        )}
      </Formik>

      {/* Success Modal */}
      <SuccessModal
        showModal={showSuccessModal}
        description={modalDesc}
        title="Successfully Updated"
        onClose={() => {
          onSuccess();
          setShowSuccessModal(false);
        }}
      />

      {/* Error Modal */}
      <ErrorModal
        showModal={showErrorModal}
        description={modalDesc}
        title="Error"
        onClose={() => setShowErrorModal(false)}
      />
    </div>
  );
};
/* Component ends */

export default T3IncidentReportBySO;
