/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  fetchPropertySettings,
  getPropertySettings,
} from "../../setup/store/slices/propertySlice";
import { getUser } from "../../setup/store/slices/authSlice";
import { setLoading } from "../../setup/store/slices/unpersistedSlice";
import {
  fetchPropertyDetailByID,
  getPropertyDetail,
} from "../../setup/store/slices/propertySlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import PropertySettings from "../../components/module/Property/PropertySettings/PropertySettings";
import BreadCrumb from "../../components/ui/other/BreadCrumb/BreadCrumb";
import GLOBAL from "../../setup/constants/global";
/* Import local pages and component ends */

/* Component starts */
const PropertySettingsPage = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();

  // Redux Selector
  const settings = useSelector(getPropertySettings);
  const property = useSelector(getPropertyDetail);
  const user = useSelector(getUser);
  const { propertyId } = useParams();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const getPropertySettingsDetail = async () => {
    try {
      const result = await dispatch(
        fetchPropertySettings({
          property_id: propertyId,
        })
      );

      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          // setSequence([]);
          // setApplication();
          break;
        case GLOBAL.FULFILLED:
          // initializeSequenceNumber(result.payload.data);
          // setSequence(result.payload.sequence);
          // setApplication(result.payload.data);
          break;
      }
    } catch (e) {
      console.error("error", e);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  // Fetching propert settings on initial load
  // useEffect(() => {
  //   (async () => {
  //     await dispatch(
  //       fetchPropertySettings({
  //         property_id: propertyId,
  //       })
  //     );

  //     await dispatch(fetchPropertyDetailByID({ property_id: propertyId }));
  //     dispatch(setLoading(false));
  //   })();
  // }, []);

  useEffect(() => {
    dispatch(
      fetchPropertyDetailByID({
        property_id: propertyId,
      })
    );
    if (propertyId) {
      getPropertySettingsDetail();
    }
  }, [dispatch, propertyId]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      <BreadCrumb type="property_setting" />

      <div>
        <PropertySettings
          settings={settings}
          property={property}
          user={user}
          getPropertySettingsDetail={getPropertySettingsDetail}
        />
      </div>
    </>
  );
};
/* Component ends */

export default PropertySettingsPage;
