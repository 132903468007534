export const revisionStatus = (status) => {
  switch (status) {
    case "pending":
      return {
        text: "Pending",
        color: "font_yellow",
      };

    case "rejected":
      return {
        text: "Rejected",
        color: "font_red",
      };

    case "approved":
      return {
        text: "Approved",
        color: "font_green",
      };

    default:
      return { text: status, color: "font_yellow" };
      break;
  }
};
export const dummyData = {
  installment_plan: {
    _id: "66294cf82525ac3846f3d6a0",
    deposit_data: [
      {
        description: "Damage",
        deposit_amount: "5585",
      },
      {
        description: "Water",
        deposit_amount: "7899",
      },
      {
        description: "Electricity",
        deposit_amount: "4556",
      },
    ],
    status: "pending",
    actions: [],
    unit: "66294b9c2525ac3846f3c773",
    tenant: "64ca3fa075c0ef4d8468f671",
    tenant_application: "66294bf72525ac3846f3c8d0",
    tenant_initial_agreement: "66294cf82525ac3846f3d69e",
    createdAt: "2024-04-24T18:18:32.032Z",
    updatedAt: "2024-04-24T18:19:38.130Z",
    __v: 1,
    cheque: true,
    collect_commission_tax: true,
    collect_commission_vat: true,
    collect_deposit_tax: true,
    collect_deposit_vat: true,
    collect_instalment_tax: true,
    collect_instalment_vat: true,
    commission: true,
    commission_amount: 670,
    currency: "INR",
    deposit: true,
    deposit_amount: 18040,
    duration: 60,
    end_date: "2024-06-29T18:30:00.000Z",
    no_of_installments: 2,
    start_date: "2024-04-30T18:30:00.000Z",
    total_rent: 232123,
  },
  installments: [
    {
      _id: "66294d3a2525ac3846f3d6c9",
      is_initial_installment: true,
      is_payable_by_cheque: true,
      unit: "66294b9c2525ac3846f3c773",
      tenant_application: "66294bf72525ac3846f3c8d0",
      tenant_initial_agreement: "66294cf82525ac3846f3d69e",
      name: "Installment 1 / 2 ",
      due_date: "2024-04-30T18:30:00.000Z",
      amount: 116062,
      currency: "INR",
      vat_amount: 580.31,
      tax_amount: 812.43,
      collect_instalment_vat: true,
      collect_instalment_tax: true,
      total_amount: 117454.74,
      createdAt: "2024-04-24T18:19:38.137Z",
      updatedAt: "2024-04-24T18:19:38.137Z",
      __v: 0,
    },
    {
      _id: "66294d3a2525ac3846f3d6cb",
      is_initial_installment: false,
      is_payable_by_cheque: true,
      unit: "66294b9c2525ac3846f3c773",
      tenant_application: "66294bf72525ac3846f3c8d0",
      tenant_initial_agreement: "66294cf82525ac3846f3d69e",
      name: "Installment 2 / 2 ",
      due_date: "2024-05-31T18:30:00.000Z",
      amount: 116061,
      currency: "INR",
      vat_amount: 580.3,
      tax_amount: 812.43,
      collect_instalment_vat: true,
      collect_instalment_tax: true,
      total_amount: 117453.73,
      createdAt: "2024-04-24T18:19:38.141Z",
      updatedAt: "2024-04-24T18:19:52.944Z",
      __v: 0,
    },
  ],
};
