/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import moment from "moment";
import { useNavigate } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Table } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import {
  EyeFilledBlue,
  ProfileFilledGrey,
} from "../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../../../ui/button/IconButton/IconButton";
import GLOBAL from "../../../../../setup/constants/global";
import Pagination from "../../../../ui/other/Pagination/Pagination";

/* Import local pages and component ends */

/* Component starts */
const CancelledTenants = (props) => {
  /* Props destructuring starts */
  const { list, totalCount, currentPage, setCurrentPage } = props;

  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  /* Other hooks declaration ends */
  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const checkStatus = (item) => {
    let status = "";
    let rejected = false;
    let canReview = false;

    switch (item.status) {
      case GLOBAL.TENANT_APPLICATION_STATUS.INVITATION_CANCELLED:
        status = "Invitaion Cancelled";
        canReview = false;
        rejected = true;
        break;

      case GLOBAL.TENANT_APPLICATION_STATUS.REJECTED:
        status = "Rejected";
        rejected = true;
        canReview = true;
        break;

      case GLOBAL.TENANT_APPLICATION_STATUS.EXPIRED:
        status = "Application Expired";
        canReview = true;
        break;

      case "tenant-canceled":
        status = "Tenant Canceled";
        canReview = true;
        break;

      case "owner-canceled":
        status = "Owner Canceled";
        canReview = true;
        break;

      case "pm-canceled":
        status = "Property Manager Canceled";
        canReview = true;
        break;

      case "reservor-data-rejected":
        status = "Application Data Rejected";
        canReview = true;
    }

    return {
      status,
      canReview,
      rejected,
    };
  };

  // Go to review tenant page
  const review = (canReview, application) => {
    navigate(`${application._id}`);
  };

  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      <div className="custom-table-wrapper request-table">
        {list?.length > 0 && (
          <Table className="custom-table font_s" borderless responsive>
            {/* Table Heading */}
            <thead>
              <tr>
                <th>Tenant Name</th>
                <th>Unit Name</th>
                <th>Unit Type</th>
                <th>Request Date & Time</th>
                <th>Status</th>
                <th className="text-center">Action</th>
              </tr>
            </thead>
            {/* Table Body */}
            <tbody>
              {list?.map((item) => (
                <tr key={item?._id}>
                  {/* Tenant Name */}
                  <td>{item?.tenant}</td>

                  {/* Unit NUmber */}
                  <td>{item?.unit_name}</td>

                  {/* Unit Type  */}
                  <td>{item?.unit_type}</td>

                  {/* Request Date & Time */}
                  <td>{moment(item?.date_time).format(DATE_TIME_FORMAT)}</td>

                  {/* Status */}
                  <td className="status">
                    <p
                      className={`${
                        checkStatus(item).rejected ? "font_red" : "font_red"
                      }`}
                    >
                      {checkStatus(item).status}
                    </p>
                  </td>

                  {/* Actions */}
                  <td className="text-center txt-nowrap">
                    <IconButton
                      onClick={() => review(checkStatus(item), item)}
                      className="action-button"
                    >
                      <img
                        src={EyeFilledBlue}
                        className="h_100"
                        alt="View Details"
                      />
                    </IconButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}

        {list?.length === 0 && (
          <div className=" text-center">
            <h3 className=" font_m font_yellow fw_6">No Data</h3>
          </div>
        )}
      </div>

      {/* Pagination */}
      {list?.length > 0 && (
        <Pagination
          itemsPerPage="10"
          totalItems={totalCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
    </>
  );
};
/* Component ends */

export default CancelledTenants;
