/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { revisionStatus } from "../../../Tenant/InitialAgreementForm/helper";
import GLOBAL from "../../../../../setup/constants/global";
import moment from "moment";
import { useDispatch } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import { respondAmendment } from "../../../../../setup/store/slices/contractSlice";
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../../../setup/store/slices/globalAlertSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomButton from "../../../../ui/button/CustomButton/CustomButton";
import NoteModal from "../../../Maintenance/NoteModal/NoteModal";
/* Import local pages and component ends */

/* Component starts */
const Revision = (props) => {
  /* Props destructuring starts */
  const {
    data,
    contract_id,
    unit_id,
    application_id,
    fetchContractDetails,
    index,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [rejectRevision, setRejectRevision] = useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const reviewRevision = async (approved = true, messageRes = "") => {
    setRejectRevision(false);
    // Creating or collecting payload data to be sent
    const payload = {
      application_id,
      contract_id,
      unit_id,
      messageRes,
      status: approved
        ? GLOBAL.TENANT_APPLICATION_STATUS.DATA_APPROVED
        : GLOBAL.TENANT_APPLICATION_STATUS.DATA_REJECTED,
    };

    // Backend response. Try, Catch
    try {
      const result = await dispatch(respondAmendment(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          fetchContractDetails();
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="revision">
      {/* <h3 className="font_m">Revision Request(s)</h3> */}
      <p className="font_l label mx-4">
        {index !== undefined ? `${index} ${"-"}` : ""} Revision Request(s)
      </p>
      {/* {index !== undefined ? `${index} ${"-"}` : ""} */}
      <div className="message-wrapper bg_grey">
        {data?.map((item, index, arr) => (
          <div key={index}>
            {index !== 0 && <hr className="line" />}
            <div className="message">
              <div className="box">
                <p className="key font_s font_grey fw_5">Message</p>
                <div className="value fw_5 font_s">{item.message}</div>
              </div>
              <div className="box">
                <p className="key font_s font_grey fw_5">Response</p>
                <div className="value fw_5 font_s">
                  {item?.messageRes ? item.messageRes : "-"}
                </div>
              </div>
              <div className="box">
                <p className="key font_s font_grey fw_5">Requested At</p>
                <div className="value fw_5 font_s">
                  {moment(item.createdAt).format(DATE_TIME_FORMAT)}
                </div>
              </div>
              <div className="box">
                <p className="key font_s font_grey fw_5">Status</p>
                <div className="value font_s fw_5">
                  <span className={`${revisionStatus(item.status).color}`}>
                    {revisionStatus(item.status).text}
                  </span>
                </div>
              </div>
              {item.status === "pending" && (
                <div className="box revision-action-btn-wrapper">
                  <CustomButton
                    onClick={() => reviewRevision()}
                    className=""
                    size="m"
                  >
                    Accept
                  </CustomButton>

                  <CustomButton
                    onClick={() => setRejectRevision(true)}
                    className="reject"
                    variant="red"
                    size="m"
                  >
                    Reject
                  </CustomButton>

                  {/* {arr.length - 1 === index &&
                    contract?.contract_details?.status ===
                      "reservor-pending-action" &&
                    contract?.contract_details?.sub_status ===
                      "pending_revision_approval" &&} */}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>

      <NoteModal
        showModal={rejectRevision}
        title="Installment Plan Revision"
        onCancel={() => setRejectRevision(false)}
        actionWithNote={(n) => reviewRevision(false, n)}
        inputPlaceholder="Reason"
        btnText="Send"
        showError={true}
      />
    </div>
  );
};
/* Component ends */

export default Revision;
