/**
 *
 */

import React, { useState, useEffect, useRef } from "react";

/* Import configuration starts */
import { connect } from "react-redux";
import { Formik } from "formik";
import { bindActionCreators } from "redux";
import {
  formValue,
  formPrefill,
  isDataChanged,
  commercialDetailValidation,
} from "./helper";
import GLOBAL from "../../../../setup/constants/global";
import { useDispatch } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import { updateTenantApplication } from "../../../../setup/store/slices/tenantSlice";
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../../setup/store/slices/globalAlertSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
import TextButton from "../../../ui/button/TextButton/TextButton";
import CustomTextInput from "../../../ui/input/CustomTextInput/CustomTextInput";
import CustomFileInput from "../../../ui/input/CustomFileInput/CustomFileInput";
import CustomDateInput from "../../../ui/input/CustomDateInput/CustomDateInput";
import SuccessModal from "../../../ui/modal/SuccessModal/SuccessModal";
import ErrorModal from "../../../ui/modal/ErrorModal/ErrorModal";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const CommercialDetailForm = (props) => {
  /* Props destructuring starts */
  const {
    update,
    onNext,
    data,
    applicationId,
    onCancelStep = null,
    fetchApplication = null,
    canCancelApplication = false,
    onCancelApplication,
    validateDataUpdated = true,
    onUpdatingInfo = null,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [initialFormValues, setInitialFormValues] = useState(formValue);
  const [showErrModal, setShowErrModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [message, setMessage] = useState("");

  // useRef
  const img_trade_license = useRef(null);
  const img_tax_id = useRef(null);
  const bank_statement_document = useRef(null);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatchHook = useDispatch();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // Form validation
  const formValidation = (value) => {
    return commercialDetailValidation(value);
  };

  // On updating commercial detail
  const onSubmit = async (value) => {
    const {
      business_name,
      relation_to_business,
      trade_license_number,
      trade_license_expiry_date,
      tax_id_number,
    } = value;

    if (
      validateDataUpdated &&
      data &&
      !isDataChanged(
        {
          business_name,
          relation_to_business,
          trade_license_number,
          trade_license_expiry_date,
          tax_id_number,
          img_trade_license: img_trade_license.current,
          img_tax_id: img_tax_id.current,
          bank_statement_document: bank_statement_document.current,
        },
        data
      )
    ) {
      dispatchHook(
        showErrorAlert({
          title: "Error",
          description: "Please update the form",
        })
      );
      return;
    }

    // Creating or collecting payload data to be sent
    const payload = {
      applicationId,
      type: "commercial_details",
      commercial_details: {
        business_name,
        relation_to_business,
        trade_license_number,
        trade_license_expiry_date,
        tax_id_number,
      },
    };
    if (data?._id) {
      payload.commercial_details["_id"] = data._id;
    }
    if (img_trade_license.current && !img_trade_license?.current?.url) {
      payload["img_trade_license"] = img_trade_license.current;
    }
    if (img_tax_id.current && !img_tax_id?.current?.url) {
      payload["img_tax_id"] = img_tax_id.current;
    }

    if (
      bank_statement_document.current &&
      !bank_statement_document?.current?.url
    ) {
      payload["bank_statement"] = bank_statement_document.current;
    }

    // Backend response. Try, Catch
    try {
      const result = await update(payload);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setMessage(result.payload);
          setShowErrModal(true);
          break;

        case GLOBAL.FULFILLED:
          onUpdatingInfo && onUpdatingInfo();
          fetchApplication && fetchApplication();
          setMessage(result.payload.message);
          setShowSuccessModal(true);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  // On load of commercial details previous data
  useEffect(() => {
    if (data) {
      formPrefill(
        data,
        setInitialFormValues,
        img_trade_license,
        img_tax_id,
        bank_statement_document
      );
    }
  }, [data]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="commercial-detail container_sm">
      <h2 className="heading text-center font_xxl mb-5">Commercial Details</h2>

      <Formik
        onSubmit={onSubmit}
        initialValues={initialFormValues}
        validate={formValidation}
        validateOnBlur={true}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            {/* Form Fields */}
            <div className="form-wrapper form-wrapper-flex">
              {data?.status === "rejected" &&
                data?.actions &&
                data?.actions?.length > 0 &&
                data?.actions[0]?.note && (
                  <p className="font_s font_red fw_5 rejected_text">
                    {data?.actions[0]?.note}
                  </p>
                )}

              {/* Input for Compoany Name */}
              <div className="form-group form-group-full">
                <CustomTextInput
                  name="business_name"
                  label="Company Name"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  touched={touched.business_name}
                  errors={errors.business_name}
                  value={values.business_name}
                />
              </div>

              {/* Input for Relation to the Business */}
              <div className="form-group">
                <CustomTextInput
                  name="relation_to_business"
                  label="Relation to the Business"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  touched={touched.relation_to_business}
                  errors={errors.relation_to_business}
                  value={values.relation_to_business}
                />
              </div>

              {/* Input for Trade License Number */}
              <div className="form-group">
                <CustomTextInput
                  name="trade_license_number"
                  label="Trade License Number"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  touched={touched.trade_license_number}
                  errors={errors.trade_license_number}
                  value={values.trade_license_number}
                />
              </div>

              {/* Input for Trade License Copy */}
              <div className="form-group">
                <CustomFileInput
                  label="Trade License Copy"
                  name="img_trade_license"
                  files={img_trade_license}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  errors={errors.img_trade_license}
                  touched={touched.img_trade_license}
                  formikState={false}
                />
              </div>

              {/* Input for Trade License Expiry Date */}
              <div className="form-group">
                <CustomDateInput
                  label="Trade License Expiry Date"
                  name="trade_license_expiry_date"
                  value={values.trade_license_expiry_date}
                  errors={errors.img_trade_license}
                  touched={touched.img_trade_license}
                  handleBlur={handleBlur}
                  setFieldValue={setFieldValue}
                />
              </div>

              {/* Input for Tax ID Number */}
              <div className="form-group">
                <CustomTextInput
                  name="tax_id_number"
                  label="Tax ID Number"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  touched={touched.tax_id_number}
                  errors={errors.tax_id_number}
                  value={values.tax_id_number}
                />
              </div>

              {/* Input for Tax ID Document */}
              <div className="form-group">
                <CustomFileInput
                  label="Tax ID Document"
                  name="img_tax_id"
                  files={img_tax_id}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  errors={errors.img_tax_id}
                  touched={touched.img_tax_id}
                  formikState={false}
                />
              </div>

              {/* Input for Bank Statement Document */}
              <div className="form-group">
                <CustomFileInput
                  label="Bank Statement Document"
                  name="bank_statement_document"
                  files={bank_statement_document}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  errors={errors.bank_statement_document}
                  touched={touched.bank_statement_document}
                  formikState={false}
                />
              </div>
            </div>

            {/* Submit and Cancel */}
            <div className="button-wrapper text-center">
              <CustomButton type="submit" size="l">
                Next
              </CustomButton>

              {onCancelStep && (
                <TextButton
                  type="button"
                  onClick={onCancelStep}
                  className="font_m fw_6"
                >
                  Back
                </TextButton>
              )}
            </div>
          </form>
        )}
      </Formik>

      {canCancelApplication && (
        <div className="cancel-application-btn-wrapper mt-4">
          <CustomButton
            className="cancel-application-btn"
            variant="red"
            size="l"
            onClick={onCancelApplication}
          >
            Cancel Application
          </CustomButton>
        </div>

        // <div className="cancel-application-wrapper bg_grey">
        //   <p className="font_m font_yellow fw_5">
        //     I would like to cancel the onboarding application process. I am
        //     aware that after cancelling the application I won't be able to undo
        //     my action.
        //   </p>
        //   <div className="cancel-application-btn-wrapper">
        //     <CustomButton
        //       className="cancel-application-btn"
        //       variant="red"
        //       size="m"
        //       onClick={onCancelApplication}
        //     >
        //       Cancel Application
        //     </CustomButton>
        //   </div>
        // </div>
      )}

      {/* Modals */}
      {/* Success Modal */}
      <SuccessModal
        showModal={showSuccessModal}
        description={message}
        title="Success"
        onClose={() => onNext()}
      />

      {/* Error Modal */}
      <ErrorModal
        showModal={showErrModal}
        description={message}
        title="Error"
        onClose={() => setShowErrModal(false)}
      />
    </div>
  );
};
/* Component ends */

const mapDispatchToProps = (dispatch) => {
  return {
    update: bindActionCreators(updateTenantApplication, dispatch),
  };
};

export default connect(null, mapDispatchToProps)(CommercialDetailForm);
