/**
 *
 */

import React, { useState, useEffect, useRef } from "react";

/* Import configuration starts */
import { Formik } from "formik";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useDispatch } from "react-redux";
import GLOBAL from "../../../../setup/constants/global";
import {
  rentalHistValidation,
  rentalHistFormPrefill,
  tenancyTypeList,
  addMore,
  remove,
  isApproved,
  isDataChanged,
} from "./helper";
/* Import configuration ends */

/* Import redux slices component starts */
import { updateTenantApplication } from "../../../../setup/store/slices/tenantSlice";
import {
  clearMasterData,
  fetchUnitTypes,
} from "../../../../setup/store/slices/masterSlice";
import { showErrorAlert } from "../../../../setup/store/slices/globalAlertSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import TextButton from "../../../ui/button/TextButton/TextButton";
import CustomDateInput from "../../../ui/input/CustomDateInput/CustomDateInput";
import CustomTextInput from "../../../ui/input/CustomTextInput/CustomTextInput";
import CustomSelectInput from "../../../ui/input/CustomSelectInput/CustomSelectInput";
import CustomPhoneInput from "../../../ui/input/CustomPhoneInput/CustomPhoneInput";
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
import SuccessModal from "../../../ui/modal/SuccessModal/SuccessModal";
import ErrorModal from "../../../ui/modal/ErrorModal/ErrorModal";
/* Import local pages and component ends */

/* Component starts */
const RentalHistoryForm = (props) => {
  /* Props destructuring starts */
  // states
  const {
    unitTypes,
    data,
    applicationId,
    minLimit,
    fetchApplication = null,
    canCancelApplication = false,
    validateDataUpdated = true,
  } = props;

  // functions
  const {
    onNext,
    fetchUnitTypes,
    update,
    clearMasterData,
    onCancelStep = null,
    onCancelApplication,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [initialFormValues, setInitialFormValues] = useState({});
  const [formCountList, setFormCountList] = useState([]);
  const [message, setMessage] = useState("");
  const [showErrModal, setShowErrModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const formRef = useRef([]);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatchHook = useDispatch();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // Getting or Processing action error note from PM
  const getActionError = (d) => {
    if (d?.actions?.length > 0) {
      let lastAction = d["actions"][d["actions"].length - 1];
      if (
        lastAction.status === GLOBAL.TENANT_APPLICATION_STATUS.DATA_REJECTED
      ) {
        return true;
      }
    }
    return false;
  };

  // Form validations
  const formValidation = (values) => {
    return rentalHistValidation(values, formCountList);
  };

  // On updating vehicles
  const onSubmit = async (values) => {
    // Creating or collecting payload data to be sent
    const payload = {
      applicationId,
      type: "rental_history",
      rental_history: [],
    };

    if (
      validateDataUpdated &&
      formCountList.length > 0 &&
      data.length === formCountList.length &&
      !isDataChanged(values, data, formCountList)
    ) {
      dispatchHook(
        showErrorAlert({
          title: "Error",
          description: "Please update the form",
        })
      );
      return;
    }

    formCountList?.forEach((i, indx) => {
      if (!isApproved(values[`status_${i}`])) {
        const detail = {};
        detail.property_name = values[`property_name_${i}`];
        detail.unit = values[`unit_${i}`];
        detail.type = values[`type_${i}`];
        detail.address = values[`address_${i}`];
        detail.countrycode = values[`countrycode_${i}`];
        detail.contact_number = values[`contact_number_${i}`];
        detail.owner_email = values[`email_${i}`];
        detail.tenancy_type = values[`tenancy_type_${i}`];
        detail.start_date = values[`start_date_${i}`];
        detail.end_date = values[`end_date_${i}`];

        payload.rental_history.push(detail);

        if (indx < data?.length) {
          detail._id = data[indx]._id;
        }
      }
    });

    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      const result = await update(payload);
      console.log(result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setMessage(result.payload);
          setShowErrModal(true);
          break;

        case GLOBAL.FULFILLED:
          fetchApplication && fetchApplication();
          setMessage(result.payload.message);
          setShowSuccessModal(true);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  // Fetching necessary data on initial load
  useEffect(() => {
    fetchUnitTypes();
  }, []);

  // On load of rental history details previous data
  useEffect(() => {
    if (data?.length > 0) {
      rentalHistFormPrefill(data, setInitialFormValues, setFormCountList);
    }
  }, [data]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="rental-history container_sm">
      <h2 className="heading text-center font_xxl mb-5">Rental History</h2>

      <Formik
        onSubmit={onSubmit}
        initialValues={initialFormValues}
        validate={formValidation}
        validateOnBlur={true}
        enableReinitialize={true}
        innerRef={formRef}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="form-wrapper">
              {/* Minimum history needed */}
              <p className="fw_5 font_s font_yellow max-allowed">
                Minimum rental history duration required (years): {minLimit}
              </p>
              {/* If no rental history added */}
              {formCountList?.length === 0 && (
                <p className="font_m fw_5 text-center">
                  No rental history added !
                </p>
              )}

              {/* Rental history form List */}
              {formCountList?.map((item, index) => (
                <>
                  <div key={index} className="form-section-group">
                    {/* Member Count Title */}
                    <h3 className="title-bubble font_blue font_m fw_6 w_100">
                      Rental History #{index + 1}
                      {isApproved(values[`status_${item}`]) && (
                        <p className="font_m fw_5 already-approved-warning">
                          <span className="font_yellow">
                            All fields are disabled, as this information is
                            already
                          </span>{" "}
                          <span className="font_green">Approved</span>
                        </p>
                      )}
                    </h3>

                    {/* Require action */}
                    {getActionError(data[index]) && (
                      <p className="w_100 font_red font_s fw_5">
                        {
                          data[index]?.actions[data[index]?.actions?.length - 1]
                            .note
                        }
                      </p>
                    )}

                    {/* Form Fields */}
                    {/* Input for Property Name */}
                    <div className="form-group">
                      <CustomTextInput
                        name={`property_name_${item}`}
                        label="Property Name"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        touched={touched[`property_name_${item}`]}
                        errors={errors[`property_name_${item}`]}
                        value={values[`property_name_${item}`]}
                        required={true}
                        readOnly={isApproved(values[`status_${item}`])}
                      />
                    </div>

                    {/* Input for Unit */}
                    <div className="form-group">
                      <CustomTextInput
                        name={`unit_${item}`}
                        label="Unit"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        touched={touched[`unit_${item}`]}
                        errors={errors[`unit_${item}`]}
                        value={values[`unit_${item}`]}
                        required={true}
                        readOnly={isApproved(values[`status_${item}`])}
                      />
                    </div>

                    {/* Input for Unit Type */}
                    <div className="form-group">
                      <CustomTextInput
                        name={`type_${item}`}
                        label="Unit Type"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        touched={touched[`type_${item}`]}
                        errors={errors[`type_${item}`]}
                        value={values[`type_${item}`]}
                        required={true}
                        readOnly={isApproved(values[`status_${item}`])}
                      />
                    </div>

                    {/* Input for Tenancy Type */}
                    <div className="form-group">
                      <CustomSelectInput
                        label="Tenancy Type"
                        name={`tenancy_type_${item}`}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        options={tenancyTypeList}
                        value={values[`tenancy_type_${item}`]}
                        errors={errors[`tenancy_type_${item}`]}
                        touched={touched[`tenancy_type_${item}`]}
                        valueExtractor={(item) => item?.name}
                        optionValueExtractor={(item) => item?.type}
                        disabled={isApproved(values[`status_${item}`])}
                      />
                    </div>

                    {/* Input for Property Address */}
                    <div className="form-group form-group-full">
                      <CustomTextInput
                        name={`address_${item}`}
                        label="Property Address"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        touched={touched[`address_${item}`]}
                        errors={errors[`address_${item}`]}
                        value={values[`address_${item}`]}
                        required={true}
                        readOnly={isApproved(values[`status_${item}`])}
                      />
                    </div>

                    {/* Input for Phone */}
                    <div className="form-group">
                      <CustomPhoneInput
                        name={`contact_number_${item}`}
                        countrycodeName={`countrycode_${item}`}
                        label="Phone"
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        touched={touched[`contact_number_${item}`]}
                        errors={errors[`contact_number_${item}`]}
                        value={values[`contact_number_${item}`]}
                        countrycodeValue={values[`countrycode_${item}`]}
                        required={true}
                        readOnly={isApproved(values[`status_${item}`])}
                        // countryCodeEditable={true}
                      />
                    </div>

                    {/* Input for Email Address */}
                    <div className="form-group">
                      <CustomTextInput
                        name={`email_${item}`}
                        label="Email Address"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        touched={touched[`email_${item}`]}
                        errors={errors[`email_${item}`]}
                        value={values[`email_${item}`]}
                        required={false}
                        readOnly={isApproved(values[`status_${item}`])}
                      />
                    </div>

                    {/* Input for Start Date */}
                    <div className="form-group">
                      <CustomDateInput
                        name={`start_date_${item}`}
                        label="Start Date"
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        touched={touched[`start_date_${item}`]}
                        errors={errors[`start_date_${item}`]}
                        value={values[`start_date_${item}`]}
                        maxDate={new Date()}
                        minDate={false}
                        required={true}
                        disabled={isApproved(values[`status_${item}`])}
                      />
                    </div>

                    {/* Input for End Date */}
                    <div className="form-group">
                      <CustomDateInput
                        name={`end_date_${item}`}
                        label="End Date"
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        touched={touched[`end_date_${item}`]}
                        errors={errors[`end_date_${item}`]}
                        value={values[`end_date_${item}`]}
                        maxDate={new Date()}
                        minDate={false}
                        required={true}
                        disabled={isApproved(values[`status_${item}`])}
                      />
                    </div>
                  </div>

                  {formCountList?.length > 0 && (
                    <CustomButton
                      className="remove"
                      variant="outline-red"
                      size="m"
                      onClick={() =>
                        remove(
                          formRef,
                          setInitialFormValues,
                          formCountList,
                          setFormCountList,
                          index
                        )
                      }
                    >
                      Remove
                    </CustomButton>
                  )}
                </>
              ))}

              <div className="action-button text-center">
                <CustomButton
                  variant="outline"
                  size="m"
                  onClick={() =>
                    addMore(
                      formRef,
                      setInitialFormValues,
                      formCountList,
                      setFormCountList
                    )
                  }
                >
                  + Add More
                </CustomButton>
              </div>
            </div>

            <div className="button-wrapper text-center">
              <CustomButton type="submit" size="l">
                Next
              </CustomButton>
              {onCancelStep && (
                <TextButton className="font_m fw_6" onClick={onCancelStep}>
                  Back
                </TextButton>
              )}
            </div>
          </form>
        )}
      </Formik>

      {canCancelApplication && (
        <div className="cancel-application-btn-wrapper mt-4">
          <CustomButton
            className="cancel-application-btn"
            variant="red"
            size="l"
            onClick={onCancelApplication}
          >
            Cancel Application
          </CustomButton>
        </div>

        // <div className="cancel-application-wrapper bg_grey">
        //   <p className="font_m font_yellow fw_5">
        //     I would like to cancel the onboarding application process. I am
        //     aware that after cancelling the application I won't be able to undo
        //     my action.
        //   </p>
        //   <div className="cancel-application-btn-wrapper">
        //     <CustomButton
        //       className="cancel-application-btn"
        //       variant="red"
        //       size="m"
        //       onClick={onCancelApplication}
        //     >
        //       Cancel Application
        //     </CustomButton>
        //   </div>
        // </div>
      )}

      {/* Modals */}
      {/* Success Modal */}
      <SuccessModal
        showModal={showSuccessModal}
        description={message}
        title="Success"
        onClose={() => onNext()}
      />

      {/* Error Modal */}
      <ErrorModal
        showModal={showErrModal}
        description={message}
        title="Error"
        onClose={() => setShowErrModal(false)}
      />
    </div>
  );
};
/* Component ends */

const mapStateToProps = (state) => {
  return {
    unitTypes: state.master.unitTypes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUnitTypes: bindActionCreators(fetchUnitTypes, dispatch),
    update: bindActionCreators(updateTenantApplication, dispatch),
    clearMasterData: bindActionCreators(clearMasterData, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RentalHistoryForm);
