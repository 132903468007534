/**
 *
 * Slice to manage Property invoice
 */

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_URL } from "../../config/devKeys";

import axios, { axiosInstance } from "../axios";
import {
  setLoading,
  setShowUploadProgress,
  setUploadProgress,
} from "./unpersistedSlice";
import { PLATFORM } from "../../platformUtil";

const initialState = {
  propertyInvoices: [],
  propertyInvoicesAgg: [],
  invoice: null,
};

// FETCH PROPERTY INVOICES BASED ON PROPERTY_ID
export const fetchPropertyInvoices = createAsyncThunk(
  "property_invoice/list",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/property_invoice/list_property_invoice",
        // "http://localhost:3000/property_invoice/list_property_invoice",
        { ...payload },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        rejectWithValue(data.error);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// ADD PROPERTY INVOICE ON PROPERTY_ID
export const createPropertyInvoice = createAsyncThunk(
  "property_invoice/create",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const formData = new FormData();
      Object.keys(payload).map((key) => {
        if (key === "invoice_doc" || key === "reciept_doc") {
          if (PLATFORM !== "web") {
            formData.append(key, {
              name: payload[key].name,
              type: payload[key].type,
              uri:
                PLATFORM === "android"
                  ? payload[key].uri
                  : payload[key].uri.replace("file://", ""),
            });
          } else {
            formData.append(key, payload[key], payload[key].name);
          }
        } else {
          formData.append(key, payload[key]);
        }
      });
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }
      // const response = await fetch(
      //   API_URL + "/property_invoice/add_property_invoice",
      //   {
      //     method: "POST",
      //     headers: headers,
      //     body: formData,
      //   }
      // );
      // const data = await response.json();
      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "POST",
        url: "/property_invoice/add_property_invoice",
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      // const data = await response.json();

      const { data } = await axiosInstance.request(config);

      dispatch(setLoading(false));
      if (data.success) {
        dispatch(
          fetchPropertyInvoices({
            property_id: payload.property_id,
            type: payload.type,
          })
        );
        return data.data;
      } else {
        rejectWithValue(data.error);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// FETCH PROPERTY INVOICES BASED ON INVOICE_ID
export const fetchPropertyInvoiceById = createAsyncThunk(
  "property_invoice/get_property_invoice",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/property_invoice/get_property_invoice",
        { invoice_id: payload },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// UPDATE PROPERTY INVOICES BASED ON INVOICE_ID
export const updatePropertyInvoice = createAsyncThunk(
  "property_invoice/update_property_invoice_status",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const formData = new FormData();
      Object.keys(payload).map((key) => {
        if (key == "invoice_doc") {
          if (PLATFORM !== "web") {
            formData.append(key, {
              name: payload[key].name,
              type: payload[key].type,
              uri:
                PLATFORM === "android"
                  ? payload[key].uri
                  : payload[key].uri.replace("file://", ""),
            });
          } else {
            formData.append(key, payload[key], payload[key].name);
          }
        } else {
          formData.append(key, payload[key]);
        }
      });
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }

      // const response = await fetch(
      //   API_URL + "/property_invoice/update_property_invoice_status",
      //   {
      //     method: "POST",
      //     headers: headers,
      //     body: formData,
      //   }
      // );
      // const data = await response.json();
      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "POST",
        url: "/property_invoice/update_property_invoice_status",
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      // const data = await response.json();

      const { data } = await axiosInstance.request(config);

      dispatch(setLoading(false));
      if (data.success) {
        dispatch(
          fetchPropertyInvoices({
            type: payload.type,
            property_id: payload?.property_id,
          })
        );
        dispatch(
          fetchPropInvoicesAggregationBasedOnProperty({
            type: payload.type,
            property_id: payload?.property_id,
          })
        );
        return data.data;
      } else {
        return rejectWithValue(data);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// FETCH INVOICES AGGREGATION LIST BASED ON PROPERTY ID - { CLIENT }
export const fetchPropInvoicesAggregationBasedOnProperty = createAsyncThunk(
  "property_invoice/property_invoice_aggrigation",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/property_invoice/property_invoice_aggrigation",
        // "http://localhost:3000/property_invoice/property_invoice_aggrigation",
        { ...payload },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        rejectWithValue(data.error);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const updtePropertyInvCheque = createAsyncThunk(
  "property_invoice/update_property_invoice_cheque_status",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/property_invoice/update_property_invoice_cheque_status_new",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        // dispatch(fetchPropertyInvoices({ type: payload.type }));
        return data.data;
      } else {
        return rejectWithValue(data);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const updteTenantInvCheque = createAsyncThunk(
  "property_invoice/update_tenant_invoice_cheque_status",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const formData = new FormData();
      Object.keys(payload).map((key) => {
        if (key == "invoice_doc") {
          if (PLATFORM !== "web") {
            formData.append(key, {
              name: payload[key].name,
              type: payload[key].type,
              uri:
                PLATFORM === "android"
                  ? payload[key].uri
                  : payload[key].uri.replace("file://", ""),
            });
          } else {
            formData.append(key, payload[key], payload[key].name);
          }
        } else {
          formData.append(key, payload[key]);
        }
      });
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }

      // const response = await fetch(
      //   API_URL + "/property_invoice/update_property_invoice_status",
      //   {
      //     method: "POST",
      //     headers: headers,
      //     body: formData,
      //   }
      // );
      // const data = await response.json();
      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "POST",
        url: "/invoice/update_tenant_invoice_cheque_status",
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      // const data = await response.json();

      const { data } = await axiosInstance.request(config);

      dispatch(setLoading(false));

      if (data.success) {
        // dispatch(fetchPropertyInvoices({ type: payload.type }));
        return data.data;
      } else {
        return rejectWithValue(data);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const propertyInvoiceSlice = createSlice({
  name: "propertyInvoice",
  initialState,
  extraReducers: {
    [fetchPropertyInvoices.rejected]: (state, action) => {
      state.propertyInvoices = [];
    },
    [fetchPropertyInvoices.fulfilled]: (state, action) => {
      state.propertyInvoices = action.payload;
    },
    [fetchPropertyInvoiceById.rejected]: (state, action) => {
      state.invoice = null;
    },
    [fetchPropertyInvoiceById.fulfilled]: (state, action) => {
      state.invoice = action.payload;
    },

    [fetchPropInvoicesAggregationBasedOnProperty.rejected]: (state, action) => {
      state.propertyInvoicesAgg = [];
    },
    [fetchPropInvoicesAggregationBasedOnProperty.fulfilled]: (
      state,
      action
    ) => {
      state.propertyInvoicesAgg = action.payload;
    },
  },
});

export const getPropertyInvoices = (state) =>
  state.propertyInvoice.propertyInvoices;

// PROPERTY INVOICE AGGREGATION
export const getPropertyInvoicesAggregation = (state) =>
  state.propertyInvoice.propertyInvoicesAgg;

export const getPropertyInvoice = (state) => state.propertyInvoice.invoice;

export default propertyInvoiceSlice.reducer;
