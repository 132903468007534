/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import GLOBAL from "../../../../../setup/constants/global";
import { useDispatch } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../../../setup/store/slices/globalAlertSlice";
import { updatePropertySettings } from "../../../../../setup/store/slices/propertySlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomButton from "../../../../ui/button/CustomButton/CustomButton";
import CustomSelectInput from "../../../../ui/input/CustomSelectInput/CustomSelectInput";
/* Import local pages and component ends */

/* Component starts */
const TemplateDateFormat = (props) => {
  /* Props destructuring starts */
  const { property_id, user, settings } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [selectedDateFormat, setSelectedDateFormat] = useState("");
  const [settingsChanged, setSettingsChanged] = useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const selectOptions = [{ name: "MMM DD, YYYY" }, { name: "DD MMM, YYYY" }];
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const onSave = async () => {
    if (selectedDateFormat === "") {
      dispatch(
        showErrorAlert({
          title: "Error!",
          description: "Please select proper value.",
        })
      );
      return;
    }

    // Creating or collecting payload data to be sent
    const payload = { template_date_format: selectedDateFormat, property_id };
    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      const result = await dispatch(updatePropertySettings(payload));
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description:
                typeof result?.payload === "string" ? result?.payload : "",
            })
          );
          break;

        case GLOBAL.FULFILLED:
          setSettingsChanged(false);
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description:
                typeof result?.payload?.message === "string"
                  ? result?.payload?.message
                  : "",
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    if (settings && settings?.template_date_format) {
      setSelectedDateFormat(
        settings?.template_date_format ? settings?.template_date_format : ""
      );
    }
  }, [settings]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="template-date-format-wrapper">
      <div className="label">
        <p class="font_m fw_5">Template Date Format</p>
      </div>
      <div className="value">
        <div className="input-wrapper">
          <CustomSelectInput
            inputClass="setting-select font_xs text-center"
            variant="2"
            setValue={setSelectedDateFormat}
            value={selectedDateFormat}
            emptyOptionText="Select Template Date Format"
            valueAsString={true}
            options={selectOptions}
            valueExtractor={(d) => d?.name}
            customOnchange={() => setSettingsChanged(true)}
          />
        </div>
        {settingsChanged && (
          <div className="save-btn-wrapper">
            <CustomButton size="s" onClick={onSave}>
              Save
            </CustomButton>
          </div>
        )}
      </div>
    </div>
  );
};
/* Component ends */

export default TemplateDateFormat;
