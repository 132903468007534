/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useDispatch } from "react-redux";
import GLOBAL from "../../../../../setup/constants/global";
/* Import configuration ends */

/* Import redux slices component starts */
import { updatePropertySettings } from "../../../../../setup/store/slices/propertySlice";
import {
  showSuccessAlert,
  showErrorAlert,
} from "../../../../../setup/store/slices/globalAlertSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomNumberInput from "../../../../ui/input/CustomNumberInput/CustomNumberInput";
import CustomButton from "../../../../ui/button/CustomButton/CustomButton";
/* Import local pages and component ends */

/* Component starts */
const HouseholdMember = (props) => {
  /* Props destructuring starts */
  const { user, settings, property_id } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [memberCount, setMemberCount] = useState("");
  const [idCount, setIdCount] = useState("");
  const [settingChanged, setSettingChanged] = useState(true);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // Error validations while submitting or updating settings
  const errorValidation = () => {
    // Member and ID count invalid value error
    if (memberCount === "") {
      dispatch(
        showErrorAlert({
          title: "Error!",
          description: "Please enter valid number",
        })
      );
      return true;
    }

    // Member count error
    if (parseInt(memberCount) === 0) {
      dispatch(
        showErrorAlert({
          title: "Error!",
          description: "THHL should be minimum 1",
        })
      );
      return true;
    }

    return false;
  };

  // On updating setting
  const onUpdate = async () => {
    if (errorValidation()) return;

    // Creating or collecting payload data to be sent
    const payload = {
      household_member: memberCount,
      property_id,
    };

    // Backend response. Try, Catch
    try {
      const result = await dispatch(updatePropertySettings(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Settings Updated!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  // On initial load or when all the settings is loaded
  useEffect(() => {
    if (settings) {
      setMemberCount(settings?.household_member?.toString());
    }
  }, [settings]);

  // If any setting is changed
  useEffect(() => {
    setSettingChanged(false);
    if (settings?.household_member?.toString() !== memberCount?.toString()) {
      setSettingChanged(true);
    }
  }, [settings, memberCount, idCount]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="household-member-option option">
      {/* Label */}
      <div className="label">
        <p className="font_m fw_5">Tenant Household Members</p>
      </div>

      {/* Setting */}
      <div className="setting">
        <div className="row1">
          {/* Member Count */}
          <div className="member-count">
            <p className="font_s fw_5 font_grey mb_1">
              Maximum Number of Household Mmembers
            </p>
            <CustomNumberInput
              variant="2"
              inputClass="setting-input font_xs sm text-center"
              value={memberCount}
              setValue={setMemberCount}
            />
          </div>

          {/* Member ID Count */}
          {/* <div className="id-count">
            <p className="font_s fw_5 font_grey mb_1 mt_3-900">
              Number of Household Mmember's ID
            </p>
            <CustomNumberInput
              variant="2"
              inputClass="setting-input font_xs sm text-center"
              value={idCount}
              setValue={setIdCount}
            />
          </div> */}
        </div>

        <div className="row2 mt_2">
          <p className="font_s fw_5 font_grey">
            The tenant should input number equal to or less than this value for
            number of household members, and if THHMF Yes then the tenant need
            to complete (THHL - 1) household member forms
          </p>
        </div>

        {/* Save Button */}
        {settingChanged && (
          <div className="save-btn-wrapper mt_2">
            <CustomButton onClick={onUpdate} className="save-btn" size="s">
              Save
            </CustomButton>
          </div>
        )}
      </div>
    </div>
  );
};
/* Component ends */

export default HouseholdMember;
