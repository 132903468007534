/**
 *
 */

import React, { useState, useEffect, useCallback, useRef } from "react";

/* Import configuration starts */
import { GOODLE_MAP_API_KEY, containerStyle, defaultCenter } from "./helper";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  Autocomplete,
} from "@react-google-maps/api";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Modal } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { XWhite, GMapColoured } from "../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../../ui/button/IconButton/IconButton";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const LocationPickerModal = (props) => {
  /* Props destructuring starts */
  const {
    showModal = true,
    onSave = null,
    size = "lg",
    onClose,
    google_api_key,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [map, setMap] = useState(null);
  const [firstLoad, setFirstLoad] = useState(false);
  const [center, setCenter] = useState(defaultCenter);
  const [place, setPlace] = useState(null);
  const [marker, setMarker] = useState(null);

  /** @type React.MutableRefObject<HTMLInputElement> */
  const inputRef = useRef();
  const autocompleteRef = useRef();
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: google_api_key,
    libraries: ["places"],
  });
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const onLoad = React.useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const onPlaceChanged = (p) => {
    if (autocompleteRef.current !== null) {
      setPlace(autocompleteRef.current.getPlace());
      if (
        autocompleteRef.current.getPlace()?.geometry?.location?.lat() &&
        autocompleteRef.current.getPlace()?.geometry?.location?.lng()
      ) {
        setCenter({
          lat: autocompleteRef.current.getPlace()?.geometry?.location?.lat(),
          lng: autocompleteRef.current.getPlace()?.geometry?.location?.lng(),
        });
      }
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        // Success Callback
        (p) => {
          setCenter({ lat: p.coords.latitude, lng: p.coords.longitude });
        },
        // Error Callback
        () => {
          setCenter(defaultCenter);
        }
      );
    }
  }, []);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */

  return (
    <Modal
      size={size}
      centered
      show={showModal}
      className={`location-picker-modal-wrapper`}
    >
      <Modal.Body className={``}>
        <div className="modal-header-custom text-end">
          <img src={GMapColoured} className="g-map-logo" alt="" />
          {marker && onSave && (
            <IconButton
              onClick={() => onSave(marker)}
              className="save-btn font_m font_black fw_6"
            >
              Save
            </IconButton>
          )}
          <IconButton
            onClick={onClose}
            className="close-btn font_m font_black fw_6"
          >
            Close
          </IconButton>
        </div>
        <div className="modal-body-custom">
          {isLoaded ? (
            <>
              <Autocomplete
                onLoad={(autocompleteInstance) => {
                  autocompleteRef.current = autocompleteInstance;
                }}
                onPlaceChanged={onPlaceChanged}
                className="location-picker-modal-autocomplete"
              >
                <div className="custom-search">
                  <input
                    type="text"
                    name=""
                    id=""
                    className="font_s"
                    ref={inputRef}
                  />
                </div>
              </Autocomplete>
              <GoogleMap
                center={center}
                zoom={15}
                mapContainerStyle={containerStyle}
                onClick={(e) => {
                  // setCenter({
                  //   lat: e.latLng.lat(),
                  //   lng: e.latLng.lng(),
                  // });
                  setMarker({
                    lat: e.latLng.lat(),
                    lng: e.latLng.lng(),
                  });
                }}
                options={{
                  streetViewControl: false,
                  mapTypeControl: false,
                  fullscreenControl: false,
                }}
                onLoad={(map) => setMap(map)}
              >
                {center && marker && <Marker position={marker} />}
              </GoogleMap>
            </>
          ) : (
            <></>
          )}
        </div>
        <div className="modal-footer-custom"></div>
      </Modal.Body>
    </Modal>
  );
};
/* Component ends */

export default LocationPickerModal;
