/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import moment from "moment";
import GLOBAL from "../../../../../setup/constants/global";
import { useDispatch } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../../../setup/store/slices/globalAlertSlice";
import {
  fmCancelT3MaintenanceTicket,
  ownerCancelT1MaintenanceTicket,
} from "../../../../../setup/store/slices/maintenanceSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomStarRate from "../../../../ui/other/CustomStarRate/CustomStarRate";
import NoteModal from "../../NoteModal/NoteModal";
import CustomButton from "../../../../ui/button/CustomButton/CustomButton";
/* Import local pages and component ends */

/* Component starts */
const DetailSection8 = (props) => {
  /* Props destructuring starts */
  const { maintenance } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [openNote, setOpenNote] = useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // Tenant Cancel maintenance request
  const cancelMaintenanceTicket = async (comment = "") => {
    // Creating or collecting payload data to be sent
    const payload = {
      maintenance_id: maintenance?._id,
      comment,
      type: maintenance?.type,
    };

    // Backend response. Try, Catch
    try {
      let result;
      if (maintenance?.property_id?.mgmt_model === 0) {
        result = await dispatch(ownerCancelT1MaintenanceTicket(payload));
      } else {
        result = await dispatch(fmCancelT3MaintenanceTicket(payload));
      }

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      <div className="section section8">
        <div className="ticket-assign">
          <p className="left fw_4 font_m font_grey">Ticket Assign</p>
          <div className="assign">
            <p className="right font_m fw_6 ">
              {maintenance?.assigned?.first_name}{" "}
              {maintenance?.assigned?.last_name}
            </p>
            <p className="right font_m fw_4">
              {maintenance?.assigned_role?.name}
            </p>
          </div>
        </div>

        {/* RATINGS & REVIEW - COMPANY */}

        {maintenance?.is_rated && (
          <>
            <hr className="section-divider" />

            <div className="ratingAndReview">
              <div className="ticket-assign">
                <p className="left fw_5 font_m">Service Rating & Review</p>
                <div className="assign my-2">
                  <p className="right font_m fw_3 font_grey">
                    {maintenance?.rating?.company?.business_name}{" "}
                  </p>
                  <p className="right font_grey font_m fw_3">
                    {maintenance?.rating?.company?.role_id?.name}
                  </p>
                </div>
              </div>

              <div className="test">
                <div className="ratings">
                  <p className="left fw_5 font_m">Rating</p>
                  <div className="rate">
                    <CustomStarRate
                      size={20}
                      stars={maintenance?.rating?.service_rating}
                    />
                  </div>
                </div>

                <div className="reviews">
                  <p className="left fw_5 font_m">Review</p>
                  <p className="assign fw_5 font_m font_grey">
                    {maintenance?.rating?.service_review
                      ? maintenance?.rating?.service_review
                      : "-"}
                  </p>
                </div>
              </div>
            </div>

            {maintenance?.rating?.technician_rating !== 0 && (
              <>
                <hr className="section-divider" />

                <div className="ratingAndReview">
                  <div className="ticket-assign">
                    <p className="left fw_5 font_m my-2">
                      Technician Rating & Review
                    </p>
                    <div className="assign">
                      <p className="right font_m fw_3 font_grey">
                        {maintenance?.rating?.technician?.first_name}{" "}
                        {maintenance?.rating?.technician?.last_name}
                      </p>
                      <p className="right font_grey font_m fw_3">
                        {maintenance?.rating?.technician?.role_id?.name}
                      </p>
                    </div>
                  </div>

                  <div className="ratings">
                    <p className="left fw_5 font_m">Rating</p>
                    <div className="rate">
                      <CustomStarRate
                        size={20}
                        stars={maintenance?.rating?.technician_rating}
                      />
                    </div>
                  </div>

                  <div className="reviews">
                    <p className="left fw_5 font_m">Review</p>
                    <p className="assign fw_5 font_m font_grey">
                      {maintenance?.rating?.technician_review
                        ? maintenance?.rating?.technician_review
                        : "-"}
                    </p>
                  </div>
                </div>
              </>
            )}
          </>
        )}

        <div className="black-container-sm fw_6 font_m bg_black flex-between">
          <p className="left font_grey">Prefered Maintenance Date</p>
          <p className="right font_grey">
            {moment(maintenance?.prefered_maintenance_date).format(
              DATE_TIME_FORMAT
            )}
          </p>
        </div>

        {!maintenance?.is_rated &&
          (maintenance?.status === "New" ||
            maintenance?.status === "Ongoing" ||
            maintenance?.status === "Rejected") && (
            // (maintenance?.status !== "Completed" ||
            //   maintenance?.status !== "Canceled")
            <div className="mt-2 d-flex align-item-center justify-content-right w-100">
              <CustomButton
                variant="red"
                size="l"
                onClick={() => setOpenNote(true)}
              >
                Cancel Ticket
              </CustomButton>
            </div>
          )}
      </div>

      {/* Note Modal */}
      <NoteModal
        showModal={openNote}
        actionWithNote={cancelMaintenanceTicket}
        title="Reject"
        inputPlaceholder="Comment"
        btnText="Reject"
        showError={true}
        onCancel={() => setOpenNote(false)}
      />
    </>
  );
};
/* Component ends */

export default DetailSection8;
