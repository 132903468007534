/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import GLOBAL from "../../../../../setup/constants/global";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../../../setup/store/slices/globalAlertSlice";
import { updateTenantContract } from "../../../../../setup/store/slices/tenantSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Table } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { DocumentGreen } from "../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../../../ui/button/IconButton/IconButton";
import FileViewerModal from "../../../../ui/modal/FileViewerModal/FileViewerModal";
import ApplicationDataRejectionModal from "../../ApplicationDataRejectionModal/ApplicationDataRejectionModal";
/* Import local pages and component ends */

/* Component starts */
const UtilityDetail = (props) => {
  /* Props destructuring starts */
  const { data, application_id, getApplication, approval, index } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [rejectAction, setRejectAction] = useState({});
  const [rejectionModal, setRejectionModal] = useState(false);
  const [file, setFile] = useState();
  const [showFileViewer, setShowFileViewer] = useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { propertyId } = useParams();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // On viewing utility document file
  const onViewFile = (item) => {
    setFile(item);
    setShowFileViewer(true);
  };

  // On taking action on utility contract details data only. Accepting or Rejecting
  const onAction = async (action) => {
    // Creating or collecting payload data to be sent
    const payload = {
      info_status_update: true,
      application_id,
      property_id: propertyId,
      ...action,
    };

    // Backend response. Try, Catch
    try {
      const result = await dispatch(updateTenantContract(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          getApplication();
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // On approving details
  const onApprove = (item) => {
    onAction({
      type: "utilities",
      info_id: item._id,
      info_details: {
        status: GLOBAL.TENANT_APPLICATION_STATUS.DATA_APPROVED,
        isLastUtility:
          data.length - 1 ===
          data.reduce(
            (p, n) =>
              (p = n.status === "approved" && n._id !== item._id ? p + 1 : p),
            0
          ),
      },
    });
  };

  // On rejecting details
  const onReject = (item) => {
    setRejectAction({
      type: "utilities",
      info_id: item._id,
      info_details: {
        status: GLOBAL.TENANT_APPLICATION_STATUS.DATA_REJECTED,
        isLastUtility:
          data.length - 1 ===
          data.reduce(
            (p, n) =>
              (p = n.status === "approved" && n._id !== item._id ? p + 1 : p),
            0
          ),
      },
    });
    setRejectionModal(true);
  };

  // On rejecting with note
  const onRejectWithNote = (note) => {
    setRejectionModal(false);
    const action = { ...rejectAction };
    action.info_details.note = note;
    onAction(action);
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="utility-wrapper">
      <div className="utility-heading">
        <p className="font_l label"> {index && `${index} -`} Utility Details</p>
      </div>

      {/* ID Verfication Table */}
      <div className="custom-table-wrapper">
        <Table className="custom-table font_s" borderless responsive>
          {/* Table Heading */}
          <thead>
            <tr>
              <th>Utility Name</th>
              <th>Document</th>
              <th>Actions</th>
              <th>Action Date</th>
            </tr>
          </thead>
          {/* Table Body */}
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                {/* Name */}
                <td>{item?.name}</td>

                {/* Document */}
                <td className="text-start txt-nowrap ">
                  <IconButton onClick={() => onViewFile(item.image)}>
                    <img src={DocumentGreen} alt="View ID" />
                  </IconButton>
                </td>

                <td className="text-left txt-nowrap action">
                  {/* If Pending Action */}
                  {approval &&
                    item.status ===
                      GLOBAL.TENANT_APPLICATION_STATUS.DATA_PENDING && (
                      <div className="btn-wrapper">
                        <span
                          onClick={() => onApprove(item)}
                          className="approve font_s fw_3"
                        >
                          Approve
                        </span>
                        <span
                          onClick={() => onReject(item)}
                          className="reject font_s fw_3"
                        >
                          Reject
                        </span>
                      </div>
                    )}

                  {/* If data approved */}
                  {item?.status ===
                    GLOBAL.TENANT_APPLICATION_STATUS.DATA_APPROVED && (
                    <p className="fw_6 font_s font_green">Approved</p>
                  )}
                  {/* If data rejected */}
                  {item?.status ===
                    GLOBAL.TENANT_APPLICATION_STATUS.DATA_REJECTED && (
                    <p className="fw_6 font_s font_red">Rejected</p>
                  )}
                </td>

                <td>
                  {item?.status === "approved"
                    ? moment(item?.updatedAt).format(DATE_TIME_FORMAT)
                    : "-"}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      {/* Modals */}
      {/* File Viewer Modal */}
      <FileViewerModal
        show={showFileViewer}
        file={file?.url}
        type={file?.type}
        onClose={() => {
          setShowFileViewer(false);
          setFile({});
        }}
      />

      {/* Tenant Application Rejection Modal*/}
      <ApplicationDataRejectionModal
        showModal={rejectionModal}
        title="Are you sure you want to reject this Signature?"
        onRejectWithNote={onRejectWithNote}
        onCancel={() => setRejectionModal(false)}
      />
    </div>
  );
};
/* Component ends */

export default UtilityDetail;
