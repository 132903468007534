/**
 *
 */

import React, { useState } from "react";

/* Import configuration starts */

/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */

/* Import local pages and component ends */

/* Component starts */
const Report = (props) => {
  /* Props destructuring starts */
  const { task } = props;
  console.log(task, "TASK");
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */

  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="routine-task-detail-box">
      <h4 className="font_l fw_6">{task?.item?.assigned_to}</h4>
      <p className="font_m fw_4 font_grey">{task?.item?.description}</p>

      <div className="task-wrapper">
        <p className="font_m fw_4 font_grey">
          {" "}
          Task ID : <span className="font_m fw_4"> {task?.item?.id} </span>{" "}
        </p>
        <p className="font_m fw_4 font_grey">
          Status :{" "}
          <span className="font_m fw_4 font_yellow">{task?.item?.status}</span>
        </p>
      </div>
    </div>
  );
};
/* Component Ends */
export default Report;
