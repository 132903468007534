/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
/* Import local pages and component ends */

/* Component starts */
const UnitDetails = (props) => {
  /* Props destructuring starts */
  const { details, index, isRenew, className = null } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className={`${className !== null ? className : "basic-wrapper"}  `}>
      <div className="heading-wrapper">
        <p className={`font_l label my-2 ${isRenew && "mx-4"} `}>
          {" "}
          {index && `${index} -`} Unit Details
        </p>
      </div>

      <div className="detail-box">
        <div className="basic">
          <div className="detail">
            <p className="font_s fw_3">
              Unit Name <br />{" "}
              <span className="font_m fw_6 font_blue">
                {details?.unit_name}
              </span>
            </p>
          </div>
          <div className="detail">
            <p className="font_s fw_3">
              Unit Number <br />{" "}
              <span className="font_m fw_4">{details?.unit_number}</span>
            </p>
          </div>

          <div className="detail">
            <p className="font_s fw_3">
              Unit Type <br />{" "}
              <span className="font_m fw_4">
                {details?.unit_type?.name !== undefined
                  ? details?.unit_type?.name
                  : "-"}
              </span>
            </p>
          </div>

          <div className="detail">
            <p className="font_s fw_3">
              Floor <br />{" "}
              <span className="font_m fw_4">
                {details?.unit_floor !== undefined ? details?.unit_floor : "-"}
              </span>
            </p>
          </div>

          {details?.kitchen > 0 && (
            <div className="detail">
              <p className="font_s fw_3">
                Kitchen <br />{" "}
                <span className="font_m fw_4">{details?.kitchen}</span>
              </p>
            </div>
          )}

          {details?.bedroom > 0 && (
            <div className="detail">
              <p className="font_s fw_3">
                Bedroom <br />{" "}
                <span className="font_m fw_4">{details?.bedroom}</span>
              </p>
            </div>
          )}

          {details?.bathroom > 0 && (
            <div className="detail">
              <p className="font_s fw_3">
                Bathroom <br />{" "}
                <span className="font_m fw_4">{details?.bathroom}</span>
              </p>
            </div>
          )}

          {details?.living_room > 0 && (
            <div className="detail">
              <p className="font_s fw_3">
                Living Room <br />{" "}
                <span className="font_m fw_4">{details?.living_room}</span>
              </p>
            </div>
          )}

          {details?.laundry > 0 && (
            <div className="detail">
              <p className="font_s fw_3">
                Laundry <br />{" "}
                <span className="font_m fw_4">{details?.laundry}</span>
              </p>
            </div>
          )}

          <div className="detail">
            <p className="font_m fw_3">
              Size({details?.size_measurement}) <br />
              <span className="font_m fw_4">
                {details?.unit_size !== null ? details?.unit_size : "-"}
              </span>
            </p>
          </div>

          {details?.toilet > 0 && (
            <div className="detail">
              <p className="font_m fw_3">
                Toilet
                <br />
                <span className="font_m fw_4">{details?.toilet}</span>
              </p>
            </div>
          )}

          {details?.balcony > 0 && (
            <div className="detail">
              <p className="font_m fw_3">
                Balcony/Patio <br />
                <span className="font_m fw_4">{details?.balcony}</span>
              </p>
            </div>
          )}

          {details?.maids_room > 0 && (
            <div className="detail">
              <p className="font_m fw_3">
                Maid's Room <br />
                <span className="font_m fw_4">{details?.maids_room}</span>
              </p>
            </div>
          )}

          {details?.store > 0 && (
            <div className="detail">
              <p className="font_m fw_3">
                Store <br />
                <span className="font_m fw_4">{details?.store}</span>
              </p>
            </div>
          )}

          {/* COMMERCIAL PROPERTY */}

          {/* Pantry */}
          {details?.pantry > 0 && (
            <div className="detail">
              <p className="font_m fw_3">
                Pantry <br />
                <span className="font_m fw_4">{details?.pantry}</span>
              </p>
            </div>
          )}

          {/* Showroom */}
          {details?.showroom > 0 && (
            <div className="detail">
              <p className="font_m fw_3">
                Showroom <br />
                <span className="font_m fw_4">{details?.showroom}</span>
              </p>
            </div>
          )}

          {/* Terrace */}
          {details?.terrace > 0 && (
            <div className="detail">
              <p className="font_m fw_3">
                Terrace <br />
                <span className="font_m fw_4">{details?.terrace}</span>
              </p>
            </div>
          )}

          {/* Offices */}
          {details?.offices > 0 && (
            <div className="detail">
              <p className="font_m fw_3">
                Offices <br />
                <span className="font_m fw_4">{details?.offices}</span>
              </p>
            </div>
          )}
        </div>

        <>
          <div className="detail">
            <p className="font_s fw_3">
              Unit Description <br />{" "}
              <span className="font_m fw_4">
                {details?.unit_description !== ""
                  ? details?.unit_description
                  : "-"}
              </span>
            </p>
          </div>
          <br />
        </>
      </div>
    </div>
  );
};
/* Component ends */

export default UnitDetails;
