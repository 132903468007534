/**
 *
 */

import React, { useCallback, useEffect, useRef } from "react";

/* Import configuration starts */
/* Import configuration ends */

import V1 from "./variants/V1";
import V2 from "./variants/V2";

import "./style.scss";
/* Component starts */
const CustomSelectInput = (props) => {
  /* Props destructuring starts */
  const {
    name,
    label,
    handleBlur,
    handleChange,
    valueExtractor,
    optionValueExtractor = null,
    options = [],
    customOnchange = null, //
    value, // useState value or formik value

    /* If value captured in useRef */
    refValue = null, // useRef

    /* If the value captured in useState */
    setValue = null, // useState set function definition

    /* For null or empty value enabled */
    emptyOption = true,
    emptyOptionText = "",

    /* If value captured in formik state/property */
    setFieldValue = null, // Formik setFieldValue function
    formikState = true,

    /* For validations */
    required = true,
    errors,
    touched,
    disabled = false,
    variant = "1",
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const refValueLoaded = useRef(false);
  const formikValueLoaded = useRef(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  if (variant === "1") return <V1 {...props} />;
  if (variant === "2") return <V2 {...props} />;
};
/* Component ends */

export default CustomSelectInput;
