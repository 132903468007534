import {
  validEmail,
  validateAtleastOneNumber,
  validateAtleastOneSpecialChar,
  containsSpecialChar,
} from "../../../../setup/utils/validations";

export const initialValues = {
  first_name: "",
  last_name: "",
  profession: "Security Management Company",
  email: "",
  employee_id: "",
  contract_number: "",
  contract_value: "",
  mobile: "",
  business_name: "",
  contract_copy_img: "",
};

export const validations = (values, customPhoneNum, contract_copy_img) => {
  const errorFields = {};

  /* Validations for First Name Starts */
  if (values.first_name === "") {
    errorFields.first_name = "First Name is required";
  }
  if (values.first_name && validateAtleastOneNumber(values.first_name)) {
    errorFields.first_name = "First Name Cannot Contains Numbers";
  }
  if (values.first_name && containsSpecialChar(values.first_name)) {
    errorFields.first_name = "First Name Cannot Contains Special Characters";
  }
  /* Validations for First Name Ends */

  /* Validations for Last Name Starts */
  if (values.last_name === "") {
    errorFields.last_name = "Last Name is required";
  }
  if (values.last_name && validateAtleastOneNumber(values.last_name)) {
    errorFields.last_name = "Last Name Cannot Contains Numbers";
  }
  if (values.last_name && containsSpecialChar(values.last_name)) {
    errorFields.last_name = "Last Name Cannot Contains Special Characters";
  }
  /* Validations for Last Name Ends */

  /* Validations for Profession Starts */
  if (values.profession === "") {
    errorFields.profession = "Profession is required";
  }
  /* Validations for Profession Ends */

  /* Validations for Company Name Starts */
  if (values.business_name === "") {
    errorFields.business_name = "Company Name is required";
  }
  /* Validations for Company Name Ends */

  /* Validations for Email Starts */
  if (values.email === "") {
    errorFields.email = "Email is required";
  }
  if (values.email && !validEmail(values.email)) {
    errorFields.email = "Email is not valid";
  }
  /* Validations for Email Ends */

  /* Validations for Phone Number Starts */
  if (values.mobile === "") {
    errorFields.mobile = "Phone Number is required";
  }
  if (values.mobile && customPhoneNum.current.num.length < 8) {
    errorFields.mobile = "Phone number is not valid";
  }
  /* Validations for Phone Number Ends */

  /* Validations for Employee ID Starts */
  if (values.employee_id === "") {
    errorFields.employee_id = "Employee ID is required";
  }
  /* Validations for Employee ID Ends */

  /* Validations for Contract Number Starts */
  if (values.contract_number === "") {
    errorFields.contract_number = "Contract Number is required";
  }
  /* Validations for Contract Number Ends */

  /* Validations for Contract Value Starts */
  if (values.contract_value === "") {
    errorFields.contract_value = "Contract Value is required";
  }
  /* Validations for Contract Value Ends */

  /* Validations for Contract Copy Starts */
  if (contract_copy_img.current === null) {
    errorFields.contract_copy_img = "Contract Copy is required";
  }
  /* Validations for Contract Copy Ends */

  /* Validations for Photo Starts */
  /* Validations for Photo Ends */

  return errorFields;
};

export const formReset = (
  setSearch,
  setInitialFormValues,
  setStartDate,
  setEndDate,
  setMemberSelected,
  customPhoneNum,
  contract_copy_img
) => {
  customPhoneNum.current = { code: "", num: "" };
  contract_copy_img.current = null;

  setInitialFormValues(initialValues);
  setStartDate(new Date());
  setEndDate(new Date());
  setSearch(false);
  setMemberSelected(false);
};
