/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
/* Import configuration ends */

/* Import redux slices component starts */
import { fetchItemBasedOnId } from "../../../../../../setup/store/slices/lostAndFoundSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Table } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { EyeFilledBlue } from "../../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import GLOBAL from "../../../../../../setup/constants/global";
import IconButton from "../../../../../ui/button/IconButton/IconButton";
/* Import local pages and component ends */

import "./style.scss";
import { useCookies } from "react-cookie";
import Pagination from "../../../../../ui/other/Pagination/Pagination";

/* Component starts */

const LostItemTable = (props) => {
  /* Props destructuring starts */
  const { lostList, totalCount, currentPage, setCurrentPage } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [highlightedItems, setHighlightedItems] = useState([]);
  const [cookies, setCookie] = useCookies(["openedItems"]);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  const dispatch = useDispatch();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  // To View The Item Report
  const handleViewReport = async (id) => {
    // Add the clicked item to cookies
    const openedItems = cookies?.openedItems || [];
    if (!openedItems?.includes(id)) {
      openedItems?.push(id);
      setCookie("openedItems", openedItems);
    }

    const itemData = await dispatch(fetchItemBasedOnId(id));
    navigate(`${id}`, {
      state: itemData?.payload,
    });
  };
  /* Component function definition ends */

  /* Component useEffect starts */

  useEffect(() => {
    // Update highlighted lostList based on cookies
    const openedItems = cookies.openedItems || [];
    const newItems = lostList?.filter(
      (item) => !openedItems?.includes(item._id)
    );

    setHighlightedItems(newItems);
  }, [lostList, cookies.openedItems]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      <div className="custom-table-wrapper lost-table">
        {lostList?.length > 0 && (
          <Table className="custom-table font_s" borderless responsive>
            {/* Table Heading */}
            <thead>
              <tr>
                <th>ID</th>
                <th>Date</th>
                <th>Tenant</th>
                <th>Unit</th>
                <th>Item Description</th>
                {/* <th>Phone Number</th> */}
                <th>Action</th>
              </tr>
            </thead>
            {/* Table Body */}
            <tbody>
              {lostList?.map((item, index) => (
                <>
                  {highlightedItems?.includes(item) ? (
                    <>
                      <span className="font_xxl new-label"></span>
                      <tr key={item?._id}>
                        {/* ID */}
                        <td>{item?.lostfound_id}</td>

                        {/* Date */}
                        <td>{moment(item?.date_time).format(DATE_FORMAT)}</td>

                        {/* Tenant  */}
                        <td>
                          {item?.tenant_id?.first_name}{" "}
                          {item?.tenant_id?.last_name}
                        </td>

                        {/* Unit No */}
                        <td>{item?.unit?.unit_number}</td>

                        {/* Item Description */}
                        <td>{item?.item_name}</td>

                        {/* Phone Number */}
                        {/* <td>{item?.phone === undefined ? "-" : item?.phone}</td> */}

                        {/* Actions */}
                        <td className=" txt-nowrap">
                          <IconButton
                            onClick={() => handleViewReport(item?._id)}
                            className="action-button"
                          >
                            <img
                              src={EyeFilledBlue}
                              className="h_100"
                              alt="View Details"
                            />
                          </IconButton>
                        </td>
                      </tr>
                    </>
                  ) : (
                    <tr key={item?._id}>
                      {/* ID */}
                      <td>{item?.lostfound_id}</td>

                      {/* Date */}
                      <td>{moment(item?.date_time).format(DATE_FORMAT)}</td>

                      {/* Tenant  */}
                      <td>
                        {item?.tenant_id?.first_name}{" "}
                        {item?.tenant_id?.last_name}
                      </td>

                      {/* Unit No */}
                      <td>{item?.unit?.unit_number}</td>

                      {/* Item Description */}
                      <td>{item?.item_name}</td>

                      {/* Phone Number */}
                      {/* <td>{item?.phone === undefined ? "-" : item?.phone}</td> */}

                      {/* Actions */}
                      <td className=" txt-nowrap">
                        <IconButton
                          onClick={() => handleViewReport(item?._id)}
                          className="action-button"
                        >
                          <img
                            src={EyeFilledBlue}
                            className="h_100"
                            alt="View Details"
                          />
                        </IconButton>
                      </td>
                    </tr>
                  )}
                </>
              ))}
            </tbody>
          </Table>
        )}

        {lostList?.length === 0 && (
          <div className=" text-center">
            <h3 className=" font_m font_yellow fw_6">No Data</h3>
          </div>
        )}
      </div>

      {/* PAGINATION */}
      {lostList?.length > 0 && (
        <Pagination
          itemsPerPage="10"
          totalItems={totalCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
    </>
  );
};
/* Component ends */
export default LostItemTable;
