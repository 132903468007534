import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  unit: {
    unit_type: null,
    status: null,
    sub_status: null,
  },
};

export const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    setUnitFilters: (state, action) => {
      console.log(state?.unit, "+++");
      state.unit = action.payload;
    },
    resetUnitFilters: (state, action) => {
      state.unit = initialState.unit;
    },
  },
});

export const { setUnitFilters, resetUnitFilters } = filterSlice.actions;

export const getUnitFilters = (state) => state.filter.unit;

export default filterSlice.reducer;
