/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { supportedBrowsers } from "./helper";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { LogoPrimary, XWhite } from "../constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../components/ui/button/IconButton/IconButton";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const NotSupportedBrowser = (props) => {
  /* Props destructuring starts */
  const { data, onClose } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="not-supported-browsers-wrapper bg_darkgrey">
      <IconButton onClick={onClose} className="close-btn">
        <img src={XWhite} alt="Close" />
      </IconButton>

      <div className="logo-wrapper">
        <img src={LogoPrimary} alt="DGRNTE Logo" />
      </div>
      <h2 className="heading font_xl font_yellow fw_6 text-center">
        Your current browser may not be able to display everything properly. We
        suggest using one of these browsers for the best results.
      </h2>
      <div className="browser-details">
        <h3 className="browser-details-heading font_m fw_6 font_white text-center">
          Current Browser Details
        </h3>
        <div className="key-value-pair">
          <p className="name font_white font_xs fw_5 text-center">
            Browser :{" "}
            {data?.name &&
              data?.name.charAt(0).toUpperCase() + data?.name.slice(1)}
          </p>
          {/* <h3 className="key font_white font_xs fw_4">Browser :</h3> */}
          {/* <h3 className="value font_white font_xs fw_4">{data?.name}</h3> */}
        </div>
        <div className="key-value-pair">
          <p className="name font_white font_xs fw_5 text-center">
            Version : {data?.version}
          </p>
          {/* <h3 className="key font_white font_xs fw_4">Browser :</h3> */}
          {/* <h3 className="value font_white font_xs fw_4">{data?.version}</h3> */}
        </div>
      </div>

      <div className="supported-browsers">
        <h3 className="supported-browsers-heading fw_6 font_white font_l text-center">
          Supported Browsers
        </h3>

        <div className="supported-browsers-flex">
          {supportedBrowsers.map(({ name, version }) => (
            <div className="r">
              <div className="c c1">
                <p className="font_white fw_5 font_s">{name}</p>
              </div>
              <div className="c c2">
                <p className="font_white fw_5 font_s">{version}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
/* Component ends */

export default NotSupportedBrowser;
