/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { generateStatus } from "./helper";
/* Import configuration ends */

/* Import redux slices component starts */
import { fetchVisitorPassDetail } from "../../../../setup/store/slices/visitorpassSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import {
  DGRNTEtext,
  RandomProperty,
  NoProfPicSet,
  ShareFilledBlue,
  DownloadRoundedFilledBlue,
  CopyFilledBlue,
} from "../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import GLOBAL from "../../../../setup/constants/global";
import Image from "../../../ui/other/Image/Image";
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
import TextButton from "../../../ui/button/TextButton/TextButton";
import IconButton from "../../../ui/button/IconButton/IconButton";
/* Import local pages and component ends */

import "./style.scss";
import moment from "moment";
import { getUserTheme } from "../../../../setup/store/slices/authSlice";

/* Component starts */
const VisitorAccessPassInfo = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [visitorPass, setVisitorPass] = useState();
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const { visitorPassID } = useParams();
  const dispatch = useDispatch();
  const userTheme = useSelector(getUserTheme);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;

  const mainLogo = RandomProperty;
  const propertyLogo = RandomProperty;
  const propertyName = visitorPass?.property_name;
  const address = "Seatle, Washington";
  const unitName = visitorPass?.unit;
  const visitorName = visitorPass?.guest_name;
  const photo =
    visitorPass?.image !== undefined ? visitorPass?.image : RandomProperty;
  const guestType = visitorPass?.type;
  const reason = visitorPass?.guest_type;
  const license = visitorPass?.car_number;
  const mobileNumber = visitorPass?.mobile_number;
  const validFrom = moment(visitorPass?.start_date).format(DATE_TIME_FORMAT);
  const validTill = moment(visitorPass?.end_date).format(DATE_TIME_FORMAT);
  const qrCodeImage =
    visitorPass?.qr_code !== undefined ? visitorPass?.qr_code : RandomProperty;
  const createdAt = new Date().toLocaleDateString();
  const status = generateStatus(visitorPass?.contract_status);
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  const fetchPassDetails = async (id) => {
    try {
      const result = await dispatch(fetchVisitorPassDetail({ id }));
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setVisitorPass();
          break;
        case GLOBAL.FULFILLED:
          setVisitorPass(result.payload);
          break;
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    if (visitorPassID) {
      fetchPassDetails(visitorPassID);
    }
  }, [visitorPassID]);

  console.log(visitorPass, "[PASS]");

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="visitor-access-pass-info-wrapper">
      <div className="component-header">
        <h1 className="font_xxl fw_6 text-center">Visitor Pass</h1>
      </div>
      <div className="component-body">
        <div className="custom-card-wrapper container_sm">
          <div
            className={`custom-card ${
              userTheme === "dark" && "bg_grey"
            } otherTheme`}
          >
            <div className="custom-card-header">
              {/* <div className="main-logo-wrapper">
              <Image
                className="custom-image"
                objectFit="cover"
                src={mainLogo}
                alt="DGRNTE"
              />
            </div> */}
            </div>
            <div className="custom-card-body">
              {/* Row 1 Property Name and Unit Name */}
              <div className="custom-row row-1">
                <div className="custom-column propety-name">
                  <p className="label fw_6 font_l">Property Name</p>
                  <p className="name fw_6 font_blue font_l">{propertyName}</p>
                </div>
                <div className="custom-column text-end unit-name">
                  <p className="label fw_6 font_l">Unit Name</p>
                  <p className="name fw_6 font_blue font_l">{unitName}</p>
                </div>
              </div>

              <hr className="divider top" />

              {/* Row 2 Visitor Name and Visitor Name */}
              <div className="custom-row row-2">
                <div className="custom-column visitor-name">
                  <p className="label fw_5 font_m font_lightgrey">
                    Visitor Name
                  </p>
                  <p className="name fw_5 font_m">{visitorName}</p>
                  <p className="type fw_5 font_m font_blue">{guestType}</p>
                </div>
                <div className="custom-column visitor-photo">
                  <Image
                    className="custom-image square-img"
                    objectFit="cover"
                    src={photo}
                    alt="Visitor Photo"
                  />
                </div>
              </div>

              <hr className="divider" />

              {/* Row 3 Reason */}
              <div className="custom-row row-3 key-value-row">
                <div className="custom-column key">
                  <p className="fw_5 font_m font_lightgrey">Reason</p>
                </div>
                <div className="custom-column value">
                  <p className="fw_5 font_m">{reason}</p>
                </div>
              </div>

              {license && (
                <>
                  <hr className="divider" />

                  {/* Row 4 Vehicle License Plate Number */}
                  <div className="custom-row row-4 key-value-row">
                    <div className="custom-column key">
                      <p className="fw_5 font_m font_lightgrey">
                        Vehicle License Plate Number
                      </p>
                    </div>
                    <div className="custom-column value">
                      <p className="fw_5 font_m">{license}</p>
                    </div>
                  </div>
                </>
              )}

              {mobileNumber && (
                <>
                  <hr className="divider" />

                  {/* Row 4 Mobile Number */}
                  <div className="custom-row row-4 key-value-row">
                    <div className="custom-column key">
                      <p className="fw_5 font_m font_lightgrey">
                        Mobile Number
                      </p>
                    </div>
                    <div className="custom-column value">
                      <p className="fw_5 font_m">{mobileNumber}</p>
                    </div>
                  </div>
                </>
              )}

              <hr className="divider" />

              {/* Row 5 Valid From and Valid Till */}
              <div className="custom-row row-5">
                <div className="custom-column valid-from">
                  <p className="label fw_5 font_m font_lightgrey">Valid From</p>
                  <p className="name fw_5 font_m">{validFrom}</p>
                </div>
                <div className="custom-column valid-till">
                  <p className="label fw_5 font_m font_lightgrey">Valid Till</p>
                  <p className="name fw_5 font_m">{validTill}</p>
                </div>
              </div>

              <hr className="divider bottom" />

              {/* Row 6 QR code image and action */}
              <div className=" row-6">
                <div className="qr-code">
                  <Image
                    className="custom-image square-img"
                    objectFit="cover"
                    src={qrCodeImage}
                    alt="QR Code"
                  />
                </div>
                {/* <div className="custom-column action">
                  <div className="action-btn-wrapper">
                    <IconButton>
                      <img
                        src={DownloadRoundedFilledBlue}
                        className="btn-icon"
                        alt="Save"
                      />
                      <span className="font_m fw_6 font_blue v_middle">
                        Save
                      </span>
                    </IconButton>
                  </div>
                  <div className="action-btn-wrapper">
                    <IconButton>
                      <img
                        src={ShareFilledBlue}
                        className="btn-icon"
                        alt="Share"
                      />
                      <span className="font_m fw_6 font_blue v_middle">
                        Share
                      </span>
                    </IconButton>
                  </div>
                  <div className="action-btn-wrapper">
                    <IconButton>
                      <img
                        src={CopyFilledBlue}
                        className="btn-icon"
                        alt="Copy Link"
                      />
                      <span className="font_m fw_6 font_blue v_middle">
                        Copy Link
                      </span>
                    </IconButton>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="custom-card-footer">
              <div className="created-at bg_black">
                <p className="label font_grey fw_6 font_m">Status</p>
                <p className={`value fw_6 font_m ${status.color}`}>
                  {status.text}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="component-footer"></div>
    </div>
  );
};
/* Component ends */

export default VisitorAccessPassInfo;
