/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  fetchContractsList,
  fetchContractsListBasedCommunity,
  fetchContractsListBasedProperty,
  fetchSearchedContractsList,
  getCommunityContractsList,
  getContractsList,
  getPropertyContractsList,
} from "../../setup/store/slices/contractDashboardSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import GLOBAL from "../../setup/constants/global";
import Header from "./local-component/Header";
import StatusPieChartGraph from "./local-component/StatusPieChartGraph";
import PieChartGraph from "./local-component/PieChartGraph";
import Searchbar from "../../components/ui/other/Searchbar/Searchbar";
import ContractTable from "./local-component/ContractTable";
import CustomDateInput from "../../components/ui/input/CustomDateInput/CustomDateInput";
import CustomButton from "../../components/ui/button/CustomButton/CustomButton";
import TextButton from "../../components/ui/button/TextButton/TextButton";
import Pagination from "../../components/module/RnD/Pagination/Pagination";
import Type3DPieChart from "../../components/ui/other/PieCharts/Type3DPieChart";
import Status3DPieChart from "../../components/ui/other/PieCharts/Status3DPieChart";
import { getUniqueValues } from "../../setup/utils/global-helper";
import { EnvelopeOutLinedBlue, PrintIcon } from "../../setup/constants/images";
import EmailModal from "../../components/ui/modal/EmailModal/EmailModal";
import BreadCrumb from "../../components/ui/other/BreadCrumb/BreadCrumb";
/* Import local pages and component ends */

import "./style.scss";
import moment from "moment";

/* Component starts */
const ContractDashboard = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const startDate = new Date();
  // const endDate = new Date();
  const [fromDate, setFromDate] = useState(
    new Date(startDate.getFullYear(), startDate.getMonth(), 1)
  );
  const [toDate, setToDate] = useState(
    // new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0)
    new Date()
  );

  const [search, setSearch] = useState("");
  const [contracts, setContracts] = useState([]);
  const [filterContracts, setFilterContracts] = useState();

  const [typeData, setTypeData] = useState();
  const [statusData, setStatusData] = useState();
  const [cleared, setCleared] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [showModal, setShowModal] = useState(false);
  // FILTERS
  const [communityData, setCommunityData] = useState();
  const [multiSelectCommunity, setMultiSelectCommunity] = useState();
  const [propertyData, setPropertyData] = useState();
  const [multiSelectProperty, setMultiSelectProperty] = useState();

  const [unitTypeData, setUnitTypeData] = useState();
  const [multiSelectUnitType, setMultiSelectUnitType] = useState();

  const [unitNumData, setUnitNumData] = useState();
  const [multiSelectUnitNum, setMultiSelectUnitNum] = useState();

  const [installmentData, setInstallmentData] = useState();
  const [multiSelectInstallment, setMultiSelectInstallment] = useState();

  const [amountData, setAmountData] = useState();
  const [multiSelectAmount, setMultiSelectAmount] = useState();

  const [tenantData, setTenantData] = useState();
  const [multiSelectTenant, setMultiSelectTenant] = useState();

  const [statusData1, setStatusData1] = useState();
  const [multiSelectStatus, setMultiSelectStatus] = useState();

  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const { pathname } = useLocation();
  const param = useParams();
  const dispatch = useDispatch();
  const contractsList = useSelector(getContractsList);
  const propertyContractsList = useSelector(getPropertyContractsList);
  const communityContractsList = useSelector(getCommunityContractsList);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItemsList = filterContracts?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  const loadContractsList = async () => {
    if (pathname.includes("communities")) {
      await dispatch(
        fetchContractsListBasedCommunity({
          community_id: param.communityId,
        })
      );
    } else if (pathname.includes("independent")) {
      await dispatch(
        fetchContractsListBasedProperty({ property_id: param.propertyId })
      );
    } else {
      await dispatch(fetchContractsList());
    }
  };

  const filterTypeOfContracts = (contracts) => {
    let oneBedRoom = contracts.filter((d) => d.type === "1 Bedroom Apartment");
    let twoBedRoom = contracts.filter((d) => d.type === "2 Bedroom Apartment");
    let threeBedRoom = contracts.filter(
      (d) => d.type === "3 Bedroom Apartment"
    );
    let fourBedRoom = contracts.filter((d) => d.type === "4 Bedroom Apartment");
    let condominium = contracts.filter((d) => d.type === "Condominium");
    let multiHome = contracts.filter((d) => d.type === "Multi Family Home");
    let singleHome = contracts.filter((d) => d.type === "Single Family Home");
    let studio = contracts.filter((d) => d.type === "Studio");
    let townHouse = contracts.filter((d) => d.type === "Town House");
    let villa = contracts.filter((d) => d.type === "Villa");
    let pentHouse = contracts.filter((d) => d.type === "Pent House");
    let retailShop = contracts.filter((d) => d.type === "Retail Shop");
    let store = contracts.filter((d) => d.type === "Store");
    let warehouse = contracts.filter((d) => d.type === "Warehouse");
    let office = contracts.filter((d) => d.type === "Office");

    const obj = [];
    obj.push(["Unit Type", "No. Of Type"]);
    if (oneBedRoom?.length > 0) {
      obj.push(["1 Bedroom Apartment", oneBedRoom?.length]);
    }
    if (twoBedRoom?.length > 0) {
      obj.push(["2 Bedroom Apartment", twoBedRoom?.length]);
    }
    if (threeBedRoom?.length > 0) {
      obj.push(["3 Bedroom Apartment", threeBedRoom?.length]);
    }

    if (fourBedRoom?.length > 0) {
      obj.push(["4 Bedroom Apartment", fourBedRoom?.length]);
    }
    if (condominium?.length > 0) {
      obj.push(["Condominium", condominium?.length]);
    }
    if (multiHome?.length > 0) {
      obj.push(["Multi Family Home", multiHome?.length]);
    }

    if (singleHome?.length > 0) {
      obj.push(["Single Family Home", singleHome?.length]);
    }
    if (studio?.length > 0) {
      obj.push(["Studio", studio?.length]);
    }
    if (townHouse?.length > 0) {
      obj.push(["Town House", townHouse?.length]);
    }

    if (villa?.length > 0) {
      obj.push(["Villa", villa?.length]);
    }
    if (pentHouse?.length > 0) {
      obj.push(["Pent House", pentHouse?.length]);
    }

    if (retailShop?.length > 0) {
      obj.push(["Retail Shop", retailShop?.length]);
    }

    if (store?.length > 0) {
      obj.push(["Store", store?.length]);
    }
    if (warehouse?.length > 0) {
      obj.push(["Warehouse", warehouse?.length]);
    }
    if (office?.length > 0) {
      obj.push(["Office", office?.length]);
    }
    setTypeData(obj);
  };

  const filterStatusOfContracts = (contracts) => {
    let renewed = contracts?.filter((d) => d.status === "renewed");
    let approved = contracts?.filter((d) => d.status === "approved");
    let pending = contracts?.filter(
      (d) => d.status === "reservor-pending-action"
    );
    let expired = contracts?.filter((d) => d.status === "expired");
    let canceled = contracts?.filter((d) => d.status === "canceled");

    const obj = [];
    obj.push(["Status", "No. Of Status"]);
    if (renewed?.length > 0) {
      obj.push(["Renewed", renewed?.length]);
    }
    if (approved?.length > 0) {
      obj.push(["Approved", approved?.length]);
    }
    if (pending?.length > 0) {
      obj.push(["Pending", pending?.length]);
    }
    if (expired?.length > 0) {
      obj.push(["Expired", expired?.length]);
    }
    if (canceled?.length > 0) {
      obj.push(["Canceled", canceled?.length]);
    }

    setStatusData(obj);

    // setStatusData([
    //   { name: "Vacant", value: vacant.length },
    //   { name: "Occupied", value: occupied.length },
    //   { name: "Reserved", value: reserved.length },
    // ]);
  };

  const handleSearch = async (isReset = false, resetValue = {}) => {
    let payload = {};
    if (!isReset) {
      if (search && fromDate && toDate) {
        payload = {
          searchKey: search,
          start_date: moment(fromDate)?.format("MM-DD-YYYY"),
          end_date: moment(toDate)?.format("MM-DD-YYYY"),
        };
        // Backend response. Try, Catch
        try {
          let result;
          if (pathname.includes("communities")) {
            payload = {
              ...payload,
              community_id: param.communityId,
            };
            result = await dispatch(fetchSearchedContractsList(payload));
          } else if (pathname.includes("independent")) {
            payload = {
              ...payload,
              property_id: param.propertyId,
            };
            result = await dispatch(fetchSearchedContractsList(payload));
          } else {
            result = await dispatch(fetchSearchedContractsList(payload));
          }
          // Handling success response
          switch (result.meta.requestStatus) {
            case GLOBAL.REJECTED:
              // setModalDesc(result.payload);
              // setShowErrorModal(true);
              break;

            case GLOBAL.FULFILLED:
              // setModalDesc(result.payload.message);
              // setShowSuccessModal(true);
              setContracts(result.payload);
              setFilterContracts(result?.payload);
              break;
          }
        } catch (error) {
          // Handling error response
          console.error(error.message);
        }
      } else if (search) {
        payload = {
          searchKey: search,
        };

        await dispatch(fetchSearchedContractsList(payload));
      } else if (!search && fromDate && toDate) {
        payload = {
          searchKey: "",
          start_date: moment(fromDate)?.format("MM-DD-YYYY"),
          end_date: moment(toDate)?.format("MM-DD-YYYY"),
        };

        // Backend response. Try, Catch
        try {
          let result;
          if (pathname.includes("communities")) {
            payload = {
              ...payload,
              community_id: param.communityId,
            };
            result = await dispatch(fetchSearchedContractsList(payload));
          } else if (pathname.includes("independent")) {
            payload = {
              ...payload,
              property_id: param.propertyId,
            };
            result = await dispatch(fetchSearchedContractsList(payload));
          } else {
            result = await dispatch(fetchSearchedContractsList(payload));
          }
          // Handling success response
          switch (result.meta.requestStatus) {
            case GLOBAL.REJECTED:
              // setModalDesc(result.payload);
              // setShowErrorModal(true);
              break;

            case GLOBAL.FULFILLED:
              // setModalDesc(result.payload.message);
              // setShowSuccessModal(true);
              setContracts(result.payload);
              setFilterContracts(result?.payload);

              break;
          }
        } catch (error) {
          // Handling error response
          console.error(error.message);
        }
      }
    }
    // else {
    //   payload = {
    //     ...resetValue,
    //   };
    // }
  };

  const handleClear = () => {
    let payload = {
      searchKey: "",
      start_date: new Date(startDate.getFullYear(), startDate.getMonth(), 1),
      end_date: new Date(),
    };
    setSearch(payload.searchKey);
    setFromDate(payload.start_date);
    setToDate(payload.end_date);

    handleSearch(true, payload);
    setCleared(!cleared);
  };

  // To check the any checkbox is true or not
  const checkAnyFilterChecked = (filter) => {
    return Object?.keys(filter)?.every(function (k) {
      return !filter[k];
    });
  };

  // SEND EMAIL FUNCTION
  const handleSendEmail = () => {
    setShowModal(!showModal);
  };

  /* Component function definition ends */

  /* Component useEffect starts */

  useEffect(() => {
    loadContractsList();
  }, [pathname, !cleared]);

  useEffect(() => {
    if (pathname.includes("communities") && communityContractsList) {
      setContracts(communityContractsList);
      setFilterContracts([...communityContractsList]);
      filterTypeOfContracts(contracts);
      filterStatusOfContracts(contracts);
    } else if (pathname.includes("independent") && propertyContractsList) {
      setContracts(propertyContractsList);
      setFilterContracts([...propertyContractsList]);
      filterTypeOfContracts(contracts);
      filterStatusOfContracts(contracts);
    } else {
      setContracts(contractsList);
      setFilterContracts([...contractsList]);
      filterTypeOfContracts(contracts);
      filterStatusOfContracts(contracts);
    }
  }, [
    pathname,
    contractsList,
    communityContractsList,
    propertyContractsList,
    !cleared,
  ]);

  useEffect(() => {
    if (pathname.includes("communities") && communityContractsList) {
      filterTypeOfContracts(contracts);
      filterStatusOfContracts(contracts);
    } else if (pathname.includes("independent") && propertyContractsList) {
      filterTypeOfContracts(contracts);
      filterStatusOfContracts(contracts);
    } else {
      filterTypeOfContracts(contracts);
      filterStatusOfContracts(contracts);
    }
  }, [!search, contracts]);

  useEffect(() => {
    const community = getUniqueValues(contracts, "community_name");
    const property = getUniqueValues(contracts, "property_name");
    const unitType = getUniqueValues(contracts, "type");

    const unitNum = getUniqueValues(contracts, "unit_number");
    const installment = getUniqueValues(contracts, "installments");
    const amount = getUniqueValues(contracts, "amount");

    const tenant = getUniqueValues(contracts, "tenant");
    const status = getUniqueValues(contracts, "status");

    const communityObject = {};
    community.forEach((prop) => (communityObject[prop] = false));
    setMultiSelectCommunity(communityObject);
    setCommunityData(community);

    const propertyObject = {};
    property.forEach((prop) => (propertyObject[prop] = false));
    setMultiSelectProperty(propertyObject);
    setPropertyData(property);

    const amountObject = {};
    amount
      ?.sort((a, b) => a - b)
      .forEach((prop) => (amountObject[prop] = false));
    setMultiSelectAmount(amountObject);
    setAmountData(amount?.sort((a, b) => a - b));

    const installmentObject = {};
    installment
      ?.sort((a, b) => a - b)
      .forEach((prop) => (installmentObject[prop] = false));
    setMultiSelectInstallment(installmentObject);
    setInstallmentData(installment?.sort((a, b) => a - b));

    const unitTypeObject = {};
    unitType.forEach((prop) => (unitTypeObject[prop] = false));
    setMultiSelectUnitType(unitTypeObject);
    setUnitTypeData(unitType);

    const unitNumObject = {};
    unitNum.forEach((prop) => (unitNumObject[prop] = false));
    setMultiSelectUnitNum(unitNumObject);
    setUnitNumData(unitNum);

    const tenantObject = {};
    tenant.forEach((prop) => (tenantObject[prop] = false));
    setMultiSelectTenant(tenantObject);
    setTenantData(tenant);

    const statusObject = {};
    status.forEach((prop) => (statusObject[prop] = false));
    setMultiSelectStatus(statusObject);
    setStatusData1(status);
  }, [contracts]);

  useEffect(() => {
    if (
      multiSelectCommunity !== undefined ||
      multiSelectProperty !== undefined ||
      multiSelectStatus !== undefined ||
      multiSelectTenant !== undefined ||
      multiSelectAmount !== undefined ||
      multiSelectInstallment !== undefined ||
      multiSelectUnitNum !== undefined ||
      multiSelectUnitType !== undefined
    ) {
      let allUnCheckedCommunity = checkAnyFilterChecked(multiSelectCommunity);
      let allUnCheckedProperty = checkAnyFilterChecked(multiSelectProperty);
      let allUnCheckedTenant = checkAnyFilterChecked(multiSelectTenant);
      let allUnCheckedUnitType = checkAnyFilterChecked(multiSelectUnitType);
      let allUnCheckedUnitNum = checkAnyFilterChecked(multiSelectUnitNum);

      let allUnCheckedInstallment = checkAnyFilterChecked(
        multiSelectInstallment
      );
      let allUnCheckedAmount = checkAnyFilterChecked(multiSelectAmount);

      let allUnCheckedStatus = checkAnyFilterChecked(multiSelectStatus);

      if (!allUnCheckedCommunity && multiSelectCommunity) {
        let selectedValue = Object.keys(multiSelectCommunity)?.filter(
          (key) => multiSelectCommunity[key]
        );

        const filteredCommunity = contracts?.filter((d) =>
          selectedValue?.includes(d.community_name)
        );

        setFilterContracts(filteredCommunity);
      } else if (!allUnCheckedProperty && multiSelectProperty) {
        let selectedValue = Object.keys(multiSelectProperty)?.filter(
          (key) => multiSelectProperty[key]
        );

        const filteredProperty = contracts?.filter((d) =>
          selectedValue?.includes(d.property_name)
        );

        setFilterContracts(filteredProperty);
      } else if (!allUnCheckedStatus && multiSelectStatus) {
        let selectedValue = Object.keys(multiSelectStatus)?.filter(
          (key) => multiSelectStatus[key]
        );

        const filteredStatus = contracts?.filter((d) =>
          selectedValue?.includes(d.status)
        );

        setFilterContracts(filteredStatus);
      } else if (!allUnCheckedUnitType && multiSelectUnitType) {
        let selectedValue = Object.keys(multiSelectUnitType)?.filter(
          (key) => multiSelectUnitType[key]
        );

        const filteredUnitType = contracts?.filter((d) =>
          selectedValue?.includes(d.type)
        );

        setFilterContracts(filteredUnitType);
      } else if (!allUnCheckedUnitNum && multiSelectUnitNum) {
        let selectedValue = Object.keys(multiSelectUnitNum)?.filter(
          (key) => multiSelectUnitNum[key]
        );

        const filteredUnitNum = contracts?.filter((d) =>
          selectedValue?.includes(d.unit_number)
        );

        setFilterContracts(filteredUnitNum);
      } else if (!allUnCheckedInstallment && multiSelectInstallment) {
        let selectedValue = Object.keys(multiSelectInstallment)
          ?.filter((key) => multiSelectInstallment[key])
          ?.map(Number);

        const filteredInstallment = contracts?.filter((d) =>
          selectedValue?.includes(d.installments)
        );

        setFilterContracts(filteredInstallment);
      } else if (!allUnCheckedAmount && multiSelectAmount) {
        let selectedValue = Object.keys(multiSelectAmount)
          ?.filter((key) => multiSelectAmount[key])
          ?.map(Number);

        const filteredAmount = contracts?.filter((d) =>
          selectedValue?.includes(d.amount)
        );

        setFilterContracts(filteredAmount);
      } else if (!allUnCheckedTenant && multiSelectTenant) {
        let selectedValue = Object.keys(multiSelectTenant)?.filter(
          (key) => multiSelectTenant[key]
        );

        const filteredTenant = contracts?.filter((d) =>
          selectedValue?.includes(d.tenant)
        );

        setFilterContracts(filteredTenant);
      }
    } else {
      setFilterContracts(contracts);
    }
  }, [
    multiSelectCommunity,
    multiSelectProperty,
    multiSelectStatus,
    multiSelectTenant,
    multiSelectUnitType,
    multiSelectUnitNum,
    multiSelectAmount,
    multiSelectInstallment,
  ]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      <BreadCrumb type="dashboard_contracts" />
      <div className="contract-dashboard-wrapper">
        <div className="action-wrapper">
          <div>
            <img src={PrintIcon} alt="Print-Icon" />
            <span className="font_m fw_3 font_blue">Print</span>
          </div>

          <div onClick={handleSendEmail}>
            <img src={EnvelopeOutLinedBlue} alt="Print-Icon" />
            <span className="font_m fw_3 font_blue">Email</span>
          </div>
        </div>
        <div className="header">
          <Header />
        </div>

        {contracts.length > 0 && (
          <div className="report-chart-wrapper">
            <div className="detail-box">
              <div>
                <p className="font_l fw_4">Unit Types</p>
              </div>
              {/* <div>
              <PieChartGraph typeData={typeData} />
            </div> */}
              <Type3DPieChart data={typeData} label="Unit Types" />
            </div>

            <div className="detail-box">
              <div>
                <p className="font_l fw_4">Status</p>
              </div>
              {/* <div>
              <StatusPieChartGraph statusData={statusData} />
            </div> */}
              <Status3DPieChart data={statusData} label="Status" />
            </div>
          </div>
        )}

        <div className="search-box mt-4 mb-4">
          <div className="search">
            <Searchbar
              setQuery={setSearch}
              query={search}
              className="dash-search"
            />
          </div>

          <div className="date">
            <div>
              <CustomDateInput
                label="From"
                value={fromDate}
                minDate={false}
                customOnchange={(date) => setFromDate(date)}
                required={true}
              />
            </div>

            <div>
              <CustomDateInput
                label="To"
                value={toDate}
                minDate={false}
                customOnchange={(date) => setToDate(date)}
                required={true}
              />
            </div>
          </div>

          <div className="btn-wrapper">
            <button
              onClick={() => handleSearch()}
              // disabled={!search}
            >
              Apply
            </button>

            {/* <CustomButton
            type="submit"
            size="l"
            className="cus-btn"
            onClick={() => handleSearch()}
            // disabled={!search}
          >
            Apply
          </CustomButton> */}

            <button className="btn-clear" onClick={() => handleClear()}>
              Clear
            </button>
            {/* <TextButton
            type="button"
            // onClick={onCancel}
            onClick={() => handleClear()}
            className="font_m fw_6"
          >
            Clear
          </TextButton> */}
          </div>
        </div>

        <div className="contract-table-wrapper">
          <ContractTable
            contractListData={currentItemsList}
            communityData={communityData}
            multiSelectCommunity={multiSelectCommunity}
            setMultiSelectCommunity={setMultiSelectCommunity}
            propertyData={propertyData}
            multiSelectProperty={multiSelectProperty}
            setMultiSelectProperty={setMultiSelectProperty}
            unitType={unitTypeData}
            multiSelectUnitType={multiSelectUnitType}
            setMultiSelectUnitType={setMultiSelectUnitType}
            unitNum={unitNumData}
            multiSelectUnitNum={multiSelectUnitNum}
            setMultiSelectUnitNum={setMultiSelectUnitNum}
            installment={installmentData}
            multiSelectInstallment={multiSelectInstallment}
            setMultiSelectInstallment={setMultiSelectInstallment}
            amount={amountData}
            multiSelectAmount={multiSelectAmount}
            setMultiSelectAmount={setMultiSelectAmount}
            tenant={tenantData}
            multiSelectTenant={multiSelectTenant}
            setMultiSelectTenant={setMultiSelectTenant}
            statusData1={statusData1}
            multiSelectStatus={multiSelectStatus}
            setMultiSelectStatus={setMultiSelectStatus}
          />

          {filterContracts?.length > 0 && (
            <Pagination
              itemsPerPage={itemsPerPage}
              totalItems={filterContracts?.length}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          )}
        </div>

        {/* EMAIL MODAL */}
        <EmailModal
          showModal={showModal}
          onSuccess={() => setShowModal(!showModal)}
          onClose={() => setShowModal(!showModal)}
        />
      </div>
    </>
  );
};
/* Component ends */

export default ContractDashboard;
