/**
 * This component is a Modal that is shown on success
 */

import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
/* Import react bootstrap component starts */
import { Modal } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

import { getUserTheme } from "../../../../setup/store/slices/authSlice";

/* Import local pages and component starts */
import CustomButton from "../../button/CustomButton/CustomButton";
import TextButton from "../../button/TextButton/TextButton";
import NewHTMLTextEditor from "../../other/NewHTMLTextEditor/NewHTMLTextEditor";
/* Import local pages and component ends */

import "./style.scss";
import TextEditor from "../../other/TextEditor/TextEditor";

/* Component starts */
const TextEditorModal = (props) => {
  /* Props destructuring starts */
  const {
    showModal,
    size = "lg",
    onClose,
    onSuccess,
    setDescVal,
    descVal,
    title = "",
  } = props;
  /* Props destructuring ends */

  const [isEmptyContent, setIsEmptyContent] = useState(false);
  const [isEditorFocused, setIsEditorFocused] = useState(false); // State to track editor focus
  const userTheme = useSelector(getUserTheme);

  // Handle SUbmit
  const onSubmit = async (values) => {
    onClose();
    onSuccess(values, "VAL", descVal.value);
  };

  /* ************* Component rendering. JSX code ************* */
  return (
    <Modal
      size={size}
      centered
      show={showModal}
      className="editor-modal-wrapper"
    >
      <Modal.Body
        className={`${userTheme === "dark" ? "bg_darkTheme" : "bg_lightTheme"}`}
      >
        <div className="modal-header-custom">
          <p className="font_xl fw_6 font_blue text-center">{title}</p>
        </div>
        <div className="modal-body-custom">
          <Formik
            onSubmit={onSubmit}
            initialValues={descVal?.value}
            validateOnBlur={true}
            enableReinitialize={true}
          >
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                {/* Form Fields */}
                <div className="form-wrapper form-wrapper-flex">
                  <div
                    //className="form-group
                    className={`form-group form-group-full ${
                      (userTheme === "light" || userTheme === "automatic") &&
                      "otherTheme"
                    } `}
                  >
                    <NewHTMLTextEditor
                      label="Text Editor"
                      descVal={descVal}
                      setDescVal={setDescVal}
                      isEmptyContent={isEmptyContent}
                      setIsEmptyContent={setIsEmptyContent}
                      isEditorFocused={isEditorFocused}
                      setIsEditorFocused={setIsEditorFocused}
                    />
                  </div>
                  {(isEmptyContent && (
                    <p className="font_red fw_6 font_m">{`Editor content cannot be empty!`}</p>
                  )) ||
                    (isEditorFocused && (
                      <p className="font_red fw_6 font_m">{`Please finish editing before submitting!`}</p>
                    ))}
                  {/* "Please finish editing before submitting!" */}
                </div>

                {/* Submit and Cancel */}
                <div className="button-wrapper text-center">
                  <>
                    <CustomButton
                      type="submit"
                      size="l"
                      disable={isEmptyContent}
                    >
                      Save
                    </CustomButton>
                    <TextButton
                      type="button"
                      onClick={onClose}
                      className="font_m fw_6"
                    >
                      Cancel
                    </TextButton>
                  </>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </Modal.Body>
    </Modal>
  );
};
/* Component ends */

export default TextEditorModal;
