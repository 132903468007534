/**
 *
 */

import React, { useState, useEffect } from "react";
import { ExcalmatoryYellow } from "../../../../../setup/constants/images";

/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
/* Import local pages and component ends */

/* Component starts */
const MissingPropertySettings = (props) => {
  /* Props destructuring starts */
  const { property_id, user, settings } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="missing-property-settings settings-option">
      {/* Label */}
      <div className="setting-label">
        <p className="font_l fw_6 font_yellow">Property setting has missing</p>
      </div>

      <div className="settings-container">
        {settings?.missing?.map((item, index) => (
          <div className="settings-item" key={index}>
            <img
              className="warning"
              src={ExcalmatoryYellow}
              alt="Warning Icon"
            />
            <p className="font_s fw_3 font_yellow">{item}</p>
          </div>
        ))}
      </div>
    </div>
  );
};
/* Component ends */

export default MissingPropertySettings;
