/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useDispatch } from "react-redux";
import GLOBAL from "../../../../../setup/constants/global";
/* Import configuration ends */

/* Import redux slices component starts */
import { forceMoveOut } from "../../../../../setup/store/slices/moveOutSlice";
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../../../setup/store/slices/globalAlertSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import ConfirmationModal from "../../../../ui/modal/ConfirmationModal/ConfirmationModal";
import CustomButton from "../../../../ui/button/CustomButton/CustomButton";
import AddDateModal from "../../../../ui/modal/AddDateModal/AddDateModal";
/* Import local pages and component ends */

/* Component starts */
const ForceMoveOut = (props) => {
  /* Props destructuring starts */
  const { getApplication, contract_id } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [showConfirmMoveOut, setShowConfirmMoveOut] = useState(false);
  const [showAddDateModal, setShowAddDateModal] = useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const moveOutNow = async () => {
    setShowConfirmMoveOut(false);

    // Creating or collecting payload data to be sent
    const payload = { contract_id };

    // Backend response. Try, Catch
    try {
      const result = await dispatch(forceMoveOut(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description:
                typeof result?.payload === "string" ? result?.payload : "",
            })
          );
          break;

        case GLOBAL.FULFILLED:
          getApplication();
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description:
                typeof result?.payload?.message === "string"
                  ? result?.payload?.message
                  : "",
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="early-move-out-btn-wrapper">
      <div className="btn-wrapper-lg">
        <CustomButton
          onClick={() => setShowConfirmMoveOut(true)}
          size="l"
          variant="red"
          className="force-move-out-btn"
        >
          Eviction
        </CustomButton>
      </div>

      <ConfirmationModal
        showModal={showConfirmMoveOut}
        title={"Eviction"}
        description={
          "Are you sure you want to start eviction for this tenant ? Please note that this action is irreversible, once triggered you cannot undo the action."
        }
        onClose={() => {
          setShowConfirmMoveOut(false);
        }}
        onConfirm={moveOutNow}
      />

      {/* <AddDateModal
        showModal={showAddDateModal}
        onCancel={() => setShowAddDateModal(false)}
        title="Start Move Out"
        onSubmit={moveOutNow}
      /> */}
    </div>
  );
};
/* Component ends */

export default ForceMoveOut;
