/**
 *
 */

import React from "react";

/* Import configuration starts */
import moment from "moment";
import { useLocation } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import GLOBAL from "../../setup/constants/global";
import Header from "./local-components/Header";
import Report from "./local-components/Report";

/* Import local pages and component ends */

// Styles
import "./style.scss";
import TextButton from "../../components/ui/button/TextButton/TextButton";
import CustomButton from "../../components/ui/button/CustomButton/CustomButton";

/* Component starts */
const TenantSharedFacilityDetail = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */

  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const { state } = useLocation();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */

  return (
    <div className="wrapper-tenant-facility-detail">
      <div className="header-facility">
        <Header />
      </div>

      <div className="report-wrapper">
        <div className="detail-box bg_grey">
          <Report incident={state} />
        </div>

        <div className="add-card">
          <button>Add New Card</button>
        </div>

        <div className="btn-wrapper">
          <CustomButton
            type="submit"
            size="l"
            // onClick={() => handleSubmit()}
          >
            Subscribe
          </CustomButton>
          <TextButton
            type="button"
            // onClick={onCancel}
            className="font_m fw_6 text-center mt-2 w-100"
          >
            Cancel
          </TextButton>
        </div>
      </div>
    </div>
  );
};
/* Component Ends */
export default TenantSharedFacilityDetail;
