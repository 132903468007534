/**
 * This component is a Modal that is shown on success
 */

import React from "react";

/* Import react bootstrap component starts */
import { Modal } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import {
  SuccessIconOutlinedBlue,
  RandomProperty,
  XGreen,
} from "../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../button/IconButton/IconButton";
import Image from "../../other/Image/Image";
/* Import local pages and component ends */

import "./style.scss";
import { getUserTheme } from "../../../../setup/store/slices/authSlice";
import { useSelector } from "react-redux";

/* Component starts */
const FileGroupModal = (props) => {
  /* Props destructuring starts */
  const {
    showModal,
    title = "Inspection Documents",
    size = "lg",
    onClose,
    onSelectFile = null,
    files = [],
  } = props;
  /* Props destructuring ends */

  const userTheme = useSelector(getUserTheme);

  /* ************* Component rendering. JSX code ************* */
  return (
    <Modal
      size={size}
      centered
      show={showModal}
      className={`file-group-modal-wrapper`}
    >
      <Modal.Body
        className={`${userTheme === "dark" ? "bg_darkTheme" : "bg_lightTheme"}`}
      >
        <div className="modal-header-custom">
          <IconButton onClick={onClose} className="close-btn">
            <img src={XGreen} className="close-icon" alt="Close" />
          </IconButton>
          <h2 className="text-center font_xl fw_6 font_yellow">{title}</h2>
        </div>
        <div className="modal-body-custom">
          <div className="file-grid">
            {files?.length > 0 && (
              <>
                {files?.map((img, index) => (
                  <div className="file-img" onClick={() => onSelectFile(img)}>
                    {/* <Image
                    key={index}
                    className=""
                    objectFit="cover"
                    src={img?.url}
                    videoThumbnail={img?.type2 === "video"}
                    aspectRatioResponsiveness={true}
                  /> */}
                    <Image
                      key={index}
                      className="media"
                      imgClass="thumbnail"
                      src={img?.url}
                      videoThumbnail={img?.type2 === "video"}
                      alt="Attached Media"
                      objectFit="cover"
                    />
                  </div>
                ))}
              </>
            )}

            {files?.length === 0 && (
              <div className="text-center">
                <h3 className=" font_m font_yellow fw_6 text-center">
                  No Attachments Added!
                </h3>
              </div>
            )}
          </div>
        </div>
        <div className="modal-footer-custom"></div>
      </Modal.Body>
    </Modal>
  );
};
/* Component ends */

export default FileGroupModal;
