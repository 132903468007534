export const initialValues = {
  deposit: "",
  description: "",
  sub_term: "",
  sub_fee: "",
  photos: [null],
};

export const validations = (values) => {
  const errorFields = {};

  /* Validations for Deposit Starts */
  if (values.deposit === "") {
    errorFields.deposit = "Deposit is required";
  }

  /* Validations for Deposit Ends */

  /* Validations for Description Starts */
  if (values.description === "") {
    errorFields.description = "Description is required";
  }
  /* Validations for Description Ends */

  /* Validations for Subscription Term Starts */
  if (values.sub_term === "") {
    errorFields.sub_term = "Subscription Term is required";
  }

  /* Validations for Subscription Term Ends */

  /* Validations for Subscription Fee Starts */
  if (values.sub_fee === "") {
    errorFields.sub_fee = "Subscription Fee is required";
  }

  /* Validations for Subscription Fee Ends */

  return errorFields;
};

// Field Prop option extractor
export const fieldPropExtractor = (option, index) => {
  if (option?.photos?.length > 0) return option?.photos[index];
  else return undefined;
};
