export const fieldValue = {
  description: "",
};

// Validations
export const validation = (values, docs) => {
  const error = {};

  // Description and Estimation starts
  if (values["description"] === "") {
    error["description"] = "Task Completed is required";
  }
  // Description and Estimation ends

  // Attachments starts
  docs.forEach((itm, idx) => {
    const document = "document_" + itm;

    // ********************** Creating Object Keys **********************
    if (values[document] === null) {
      error[document] = "Attachment is required";
    }
  });
  // Attachments ends

  return error;
};

// Adding Document field
export const addMoreDoc = (formRef, setFormValue, docs, setDocs) => {
  const generetedID = docs.length === 0 ? 0 : docs[docs.length - 1] + 1;
  const currentFormState = { ...formRef.current.values };

  setFormValue(() => {
    // Generating new field name according to array indexes
    currentFormState[`document_${generetedID}`] = null;
    return currentFormState;
  });
  setDocs((p) => [...p, generetedID]);
};

// Remove Document field
export const removeDoc = (formRef, setFormValue, docs, setDocs, index) => {
  const removeVal = docs[index];
  const currentFormState = { ...formRef.current.values };

  setFormValue(() => {
    delete currentFormState[`document_${removeVal}`];
    return currentFormState;
  });

  setDocs((prevState) => {
    const prevFields = [...prevState];
    prevFields.splice(index, 1);
    return prevFields;
  });
};
