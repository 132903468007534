/*
 * Slice to handle Tenant Details
 */

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_URL } from "../../config/devKeys";
import { setLoading } from "./unpersistedSlice";
import axios from "axios";

const initialState = {
  contractsDetails: [],
  depositDetails: [],
  otherDetails: [],
  rentalDetails: [],
  invoiceDetails: [],
  violationDetails: [],
  securityDetails: [],
  moveOutDetails: [],
  onboardingDetails: [],
  tenantInfoDetail: null,
};

// FETCH TENANT INFO DETAILS - Client Based On Tenant & Property ID
export const fetchTenantInfoDetail = createAsyncThunk(
  "tenant_info/unit",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(
        API_URL + "/tenant_info/unit",
        // "http://localhost:3000/tenant_info/unit",
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            property_id: payload.propertyId,
            tenant_id: payload.tenantId,
            unit_id: payload.unitId,
          }),
        }
      );

      const data = await response.json();
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// FETCH TENANT CONTRACTS DETAILS LIST - Client Based On Tenant & Unit ID
export const fetchTenantContractsDetails = createAsyncThunk(
  "tenant_info/unit/all_contract",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(API_URL + "/tenant_info/unit/all_contract", {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          unit_id: payload.unitId,
          tenant_id: payload.tenantId,
        }),
      });

      const data = await response.json();
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// FETCH TENANT DEPOSIT DETAILS LIST - Client Based On Tenant & Unit ID
export const fetchTenantDepositDetails = createAsyncThunk(
  "tenant_info/unit/tenant_deposit_details",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(
        API_URL + "/tenant_info/unit/tenant_deposit_details",
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            unit_id: payload.unitId,
            tenant_id: payload.tenantId,
          }),
        }
      );

      const data = await response.json();
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// FETCH TENANT OTHER DETAILS LIST - Client Based On Tenant & Unit ID
export const fetchTenantOtherDetails = createAsyncThunk(
  "tenant_info/unit/tenant_other_details",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(
        API_URL + "/tenant_info/unit/tenant_other_details",
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            unit_id: payload.unitId,
            tenant_id: payload.tenantId,
          }),
        }
      );

      const data = await response.json();
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// FETCH TENANT RENTAL DETAILS LIST - Client Based On Tenant & Unit ID
export const fetchTenantRentalDetails = createAsyncThunk(
  "tenant_info/unit/tenant_rental_details",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(
        API_URL + "/tenant_info/unit/tenant_rental_details",
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            unit_id: payload.unitId,
            tenant_id: payload.tenantId,
          }),
        }
      );

      const data = await response.json();
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// FETCH TENANT INVOICE DETAILS LIST - Client Based On Tenant & Unit ID
export const fetchTenantInvoiceDetails = createAsyncThunk(
  "tenant_info/unit/tenant_invoice_details",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(
        API_URL + "/tenant_info/unit/tenant_invoice_details",
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            unit_id: payload.unitId,
            tenant_id: payload.tenantId,
          }),
        }
      );

      const data = await response.json();
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// FETCH TENANT VIOLATION DETAILS LIST - Client Based On Tenant & Unit ID
export const fetchTenantViolationDetails = createAsyncThunk(
  "tenant_info/unit/tenant_violation_details",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(
        API_URL + "/tenant_info/unit/tenant_violation_details",
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            unit_id: payload.unitId,
            tenant_id: payload.tenantId,
          }),
        }
      );

      const data = await response.json();
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// FETCH TENANT SECURITY DETAILS LIST - Client Based On Tenant & Unit ID
export const fetchTenantSecurityDetails = createAsyncThunk(
  "tenant_info/unit/tenant_security_incidence_details",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(
        API_URL + "/tenant_info/unit/tenant_security_incidence_details",
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            unit_id: payload.unitId,
            tenant_id: payload.tenantId,
          }),
        }
      );

      const data = await response.json();
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// FETCH TENANT MOVEOUT DETAILS LIST - Client Based On Tenant & Unit ID
export const fetchTenantMoveOutDetails = createAsyncThunk(
  "tenant_info/unit/prev_tenant_move_out_details",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      console.log(payload, "{PAY}");

      dispatch(setLoading(true));
      // const headers = {
      //   Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      //   "Content-Type": "application/json",
      // };

      // const response = await fetch(
      //   API_URL + "/tenant_info/unit/prev_tenant_move_out_details",
      //   {
      //     method: "POST",
      //     headers: headers,
      //     body: JSON.stringify({
      //       unit_id: payload.unitId,
      //       tenant_id: payload.tenantId,
      //     }),
      //   }
      // );

      // const data = await response.json();

      const { data } = await axios.post(
        API_URL + "/tenant_info/unit/prev_tenant_move_out_details",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
            "Content-Type": "application/json",
          },
        }
      );

      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// FETCH TENANT ONBOARDING DETAILS LIST - Client Based On Tenant & Unit ID
export const fetchTenantOnboardingDetails = createAsyncThunk(
  "tenant_info/unit/tenant_on_boarding_details",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(
        API_URL + "/tenant_info/unit/tenant_on_boarding_details",
        // "http://localhost:3000/tenant_info/unit/tenant_on_boarding_details",
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            unit_id: payload.unitId,
            tenant_id: payload.tenantId,
          }),
        }
      );

      const data = await response.json();
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const tenantDetails = createSlice({
  name: "tenantDetails",
  initialState,
  extraReducers: {
    [fetchTenantInfoDetail.rejected]: (state, action) => {
      state.tenantInfoDetail = null;
    },
    [fetchTenantInfoDetail.fulfilled]: (state, action) => {
      state.tenantInfoDetail = action.payload;
    },
    [fetchTenantContractsDetails.rejected]: (state, action) => {
      state.contractsDetails = [];
    },
    [fetchTenantContractsDetails.fulfilled]: (state, action) => {
      state.contractsDetails = action.payload;
    },
    [fetchTenantDepositDetails.rejected]: (state, action) => {
      state.depositDetails = [];
    },
    [fetchTenantDepositDetails.fulfilled]: (state, action) => {
      state.depositDetails = action.payload;
    },
    [fetchTenantOtherDetails.rejected]: (state, action) => {
      state.otherDetails = [];
    },
    [fetchTenantOtherDetails.fulfilled]: (state, action) => {
      state.otherDetails = action.payload;
    },
    [fetchTenantRentalDetails.rejected]: (state, action) => {
      state.rentalDetails = [];
    },
    [fetchTenantRentalDetails.fulfilled]: (state, action) => {
      state.rentalDetails = action.payload;
    },
    [fetchTenantInvoiceDetails.rejected]: (state, action) => {
      state.invoiceDetails = [];
    },
    [fetchTenantInvoiceDetails.fulfilled]: (state, action) => {
      state.invoiceDetails = action.payload;
    },
    [fetchTenantViolationDetails.rejected]: (state, action) => {
      state.violationDetails = [];
    },
    [fetchTenantViolationDetails.fulfilled]: (state, action) => {
      state.violationDetails = action.payload;
    },
    [fetchTenantSecurityDetails.rejected]: (state, action) => {
      state.securityDetails = [];
    },
    [fetchTenantSecurityDetails.fulfilled]: (state, action) => {
      state.securityDetails = action.payload;
    },

    [fetchTenantMoveOutDetails.rejected]: (state, action) => {
      state.moveOutDetails = [];
    },
    [fetchTenantMoveOutDetails.fulfilled]: (state, action) => {
      state.moveOutDetails = action.payload;
    },

    [fetchTenantOnboardingDetails.rejected]: (state, action) => {
      state.onboardingDetails = [];
    },
    [fetchTenantOnboardingDetails.fulfilled]: (state, action) => {
      state.onboardingDetails = action.payload;
    },
  },
});

// To Get The Tenant Info Detail List
export const getTenantInfoDetail = (state) =>
  state.tenantDetails.tenantInfoDetail;

// To Get The Contracts Details List
export const getContractsDetailList = (state) =>
  state.tenantDetails.contractsDetails;

// To Get The Deposit Details List
export const getDepositDetailList = (state) =>
  state.tenantDetails.depositDetails;

// To Get The Other Details List
export const getOthertDetailList = (state) => state.tenantDetails.otherDetails;

// To Get The Rental Details List
export const getRentalDetailList = (state) => state.tenantDetails.rentalDetails;

// To Get The Invoice Details List
export const getInvoiceDetailList = (state) =>
  state.tenantDetails.invoiceDetails;

// To Get The Violation Details List
export const getViolationDetailList = (state) =>
  state.tenantDetails.violationDetails;

// To Get The Security Details List
export const getSecurityDetailList = (state) =>
  state.tenantDetails.securityDetails;

// To Get The MoveOut Details List
export const getMoveOutDetailList = (state) =>
  state.tenantDetails.moveOutDetails;

// To Get The Onboarding Details List
export const getOnboardingDetailList = (state) =>
  state.tenantDetails.onboardingDetails;

export default tenantDetails.reducer;
