export const taxNameRender = (name) => {
  if (name && typeof name === "string") {
    let dashRemoved = "";
    for (let i = 0; i < name.length; i++) {
      dashRemoved += name.charAt(i) === "_" ? " " : name.charAt(i);
    }

    return dashRemoved
      .split(" ")
      .map((i) => i.charAt(0).toUpperCase().concat(i.slice(1)))
      .join(" ");
  } else return "";

  // switch (name) {
  //   case "sales_tax":
  //     return "Sales Tax";

  //   default:
  //     return name;
  // }
};
