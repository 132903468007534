/**
 *
 */

import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */
import {
  fetchAccessPassBasedOnId,
  getVisitorPassData,
} from "../../../setup/store/slices/visitorPass";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import Report from "./local-component/Report";
import BreadCrumb from "../../../components/ui/other/BreadCrumb/BreadCrumb";
/* Import local pages and component ends */

// Styles
import "./style.scss";

/* Component starts */
const VisitorsAccessDetail = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */

  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  // const { state } = useLocation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const state = useSelector(getVisitorPassData);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    if (id) {
      dispatch(fetchAccessPassBasedOnId(id));
    }
  }, [id]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */

  return (
    <>
      <BreadCrumb type="visitor_access_detail" />
      <div className="visitor-pass-wrapper">
        <div className="visitor-pass-header">
          <h2 className="fw_6 font_xxl">View Visitor Access Pass</h2>
        </div>

        <div className="report-wrapper container_sm">
          <div className="detail-box">
            <Report incident={state} />
          </div>
        </div>
      </div>
    </>
  );
};
/* Component Ends */
export default VisitorsAccessDetail;
