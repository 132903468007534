/**
 *
 */

import React from "react";
/* Import configuration starts */
import { Rating } from "@mui/material";
import { useNavigate } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Table } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { EyeFilledBlue } from "../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomStarRate from "../../../ui/other/CustomStarRate/CustomStarRate";
import IconButton from "../../../ui/button/IconButton/IconButton";
import Pagination from "../../../ui/other/Pagination/Pagination";
/* Import local pages and component ends */

// Styles
import "./style.scss";

/* Component starts */
const TeamsRatingTable = (props) => {
  /* Props destructuring starts */
  const { ratings, totalCount, currentPage, setCurrentPage } = props;

  /* Props destruc/turing ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */

  // Dummy Data
  const tenantRatingList = [
    {
      id: 1,
      date: "5/25/2023",
      tenant: "Fulton Glasscoe",
      category: "Finishing",
      sub_cat: "Ceiling",
      technician: "Isadore Rouby",
      rating: 3.5,
    },
    {
      id: 2,
      date: "4/21/2023",
      tenant: "Shanan McIndrew",
      category: "Electrical",
      sub_cat: "Fans",
      technician: "Lemar Rathjen",
      rating: 2.5,
    },
    {
      id: 3,
      date: "2/27/2023",
      tenant: "Madelina Spurdon",
      category: "AC",
      sub_cat: "Not Cooling",
      technician: "Ruthi Glavias",
      rating: 3.5,
    },
    {
      id: 4,
      date: "2/6/2023",
      tenant: "Gearalt McShea",
      category: "Plumbing",
      sub_cat: "Blockage",
      technician: "Mickey Timms",
      rating: 4.5,
    },
    {
      id: 5,
      date: "11/25/2022 11:00 PM",
      tenant: "Claire Collum",
      category: "Finishing",
      sub_cat: "Painting",
      technician: "Tobias Teodori",
      rating: 5,
    },
    {
      id: 6,
      date: "7/7/2023 09:15 AM",
      tenant: "Tedd Banaszewski",
      category: "Electricals",
      sub_cat: "Bulbs",
      technician: "Jamesy Thorold",
      rating: 3,
    },
  ];

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */

  return (
    <>
      <div className="custom-table-wrapper teams-rating-table-wrapper">
        {ratings?.length > 0 && (
          <Table className="custom-table font_s" borderless responsive>
            {/* Table Heading */}
            <thead>
              <tr>
                <th>ID</th>
                <th>Date & Time</th>
                <th>Teams</th>
                <th>Category</th>
                <th>Sub Category</th>
                <th>Technician</th>
                <th>Rating</th>
                <th>Action</th>
              </tr>
            </thead>
            {/* Table Body */}
            <tbody>
              {ratings?.map((item) => (
                <tr key={item._id}>
                  {/* ID */}
                  <td>{item?.request_id}</td>

                  {/* Date */}
                  <td>{item?.date}</td>

                  {/* Tenant */}
                  <td>{item?.team_name}</td>

                  {/* Category */}
                  <td>{item?.category}</td>

                  {/* Sub Category */}
                  <td>{item?.sub_cat}</td>

                  {/* Technician */}
                  <td>{item?.technician}</td>

                  {/* Rating */}
                  <td className="ratings">
                    {item?.rating !== undefined ? (
                      <CustomStarRate stars={item?.rating} size={20} />
                    ) : (
                      "NA"
                    )}
                  </td>

                  {/* Actions */}
                  <td className="text-start">
                    <IconButton
                      // onClick={() =>
                      //   navigate("/property/internal-communication/detail", {
                      //     state: { item },
                      //   })
                      // }
                      className="action-button"
                    >
                      <img
                        src={EyeFilledBlue}
                        className="h_100"
                        alt="View Details"
                      />
                    </IconButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}

        {ratings?.length === 0 && (
          <div className=" text-center">
            <h3 className=" font_m font_yellow fw_6">No Data</h3>
          </div>
        )}
      </div>

      {/* PAGINATION */}
      {ratings?.length > 0 && (
        <Pagination
          itemsPerPage="10"
          totalItems={totalCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
    </>
  );
};
/* Component Ends */
export default TeamsRatingTable;
