/**
 * This component is a Modal that is shown on success
 */

import React, { useRef, useState } from "react";

import { FieldArray, Formik } from "formik";
/* Import react bootstrap component starts */
import { Modal } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomTextInput from "../../input/CustomTextInput/CustomTextInput";
import CustomFileInput from "../../input/CustomFileInput/CustomFileInput";
import CustomButton from "../../button/CustomButton/CustomButton";
import TextButton from "../../button/TextButton/TextButton";
/* Import local pages and component ends */

import "./style.scss";

import { useDispatch } from "react-redux";
import GLOBAL from "../../../../setup/constants/global";
import ErrorModal from "../ErrorModal/ErrorModal";
import SuccessModal from "../SuccessModal/SuccessModal";
import CustomTextArea from "../../input/CustomTextArea/CustomTextArea";

/* Component starts */
const EmailModal = (props) => {
  /* Props destructuring starts */
  const { showModal, size = "lg", onClose, onSuccess } = props;
  /* Props destructuring ends */

  // useState
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [modalDesc, setModalDesc] = useState("");

  // useRef
  const photo = useRef(null);
  const dispatch = useDispatch();
  // Initial Values
  const initialFormValues = {
    long_desc: "",
    subject: "",
    email: [null],
    photo: "",
  };

  // Handle Form Validations
  //   const formValidation = (values) => {
  //     const error = {};

  //     if (values.first_name === "") {
  //       error.first_name = "First Name Is Required";
  //     }

  //     if (values.last_name === "") {
  //       error.last_name = "Last Name Is Required";
  //     }

  //     if (values.subject === "") {
  //       error.mobile_number = "Subject Is Required";
  //     }

  //     if (values.photo === "") {
  //       error.photo = "Please select an image";
  //     }

  //     return error;
  //   };

  // On adding photo field
  const onAddPhoto = (callBackPush) => {
    callBackPush(null);
  };

  // On removing last photo field
  const onRemovePhoto = (callBackPop) => {
    callBackPop();
  };

  // Field Prop option extractor
  const fieldPropExtractor = (option, index) => {
    if (option?.email?.length > 0) return option?.email[index];
    else return undefined;
  };

  // Handle SUbmit
  const onSubmit = async (values) => {
    console.log(values, "VAL");
  };

  /* ************* Component rendering. JSX code ************* */
  return (
    <Modal
      size={size}
      centered
      show={showModal}
      className="email-modal-wrapper"
    >
      <Modal.Body>
        <div className="modal-header-custom">
          <p className="font_xl fw_6 text-start">Email</p>
        </div>
        <div className="modal-body-custom">
          <Formik
            onSubmit={onSubmit}
            initialValues={initialFormValues}
            // validate={formValidation}
            validateOnBlur={true}
            enableReinitialize={true}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              setFieldTouched,
            }) => (
              <form onSubmit={handleSubmit}>
                {/* Form Fields */}
                <div className="form-wrapper form-wrapper-flex">
                  <div className="form-group-full">
                    {/* Input for Email */}
                    <FieldArray
                      name="email"
                      render={({ push, pop }) => (
                        <div className="form-group">
                          {/* Upload Image List */}
                          {values?.email?.map((item, index) => (
                            <div
                              key={index}
                              className={index >= 1 ? "add-image" : ""}
                            >
                              <CustomTextInput
                                name={`email[${index}]`}
                                label="Enter Address"
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                value={values.email[index]}
                                formikState={true}
                                touched={fieldPropExtractor(touched, index)}
                                validaterror={fieldPropExtractor(errors, index)}
                                setFieldValue={setFieldValue}
                                setFieldTouched={setFieldTouched}
                                required={false}
                              />
                            </div>
                          ))}

                          <div className="action-button text-center">
                            {values.email[values.email.length - 1] && (
                              <TextButton
                                variant="outline"
                                size="m"
                                className="txtBtn"
                                onClick={() => onAddPhoto(push)}
                              >
                                + Add More
                              </TextButton>
                            )}

                            {values.email.length > 1 && (
                              <CustomButton
                                className="remove"
                                size="m"
                                onClick={() => onRemovePhoto(pop)}
                              >
                                Remove
                              </CustomButton>
                            )}
                          </div>
                        </div>
                      )}
                    />
                  </div>

                  {/* Input for Subject */}
                  <div className="form-group form-group-full">
                    <CustomTextInput
                      name="subject"
                      label="Subject"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched.subject}
                      errors={errors.subject}
                      value={values.subject}
                      required={false}
                    />
                  </div>

                  {/* Input for Description */}
                  <div className="form-group form-group-full">
                    <CustomTextArea
                      name="long_desc"
                      label="Write something here"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      value={values.long_desc}
                      errors={errors.long_desc}
                      touched={touched.long_desc}
                      required={false}
                    />
                  </div>

                  {/* Upload Image */}
                  <div className="form-group">
                    <CustomFileInput
                      name="photo"
                      label="Upload Document"
                      files={photo}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      errors={errors.photo}
                      touched={touched.photo}
                      setFieldValue={setFieldValue}
                      values={values.photo}
                      required={false}
                      formikState={true}
                    />
                  </div>
                </div>

                {/* Submit and Cancel */}
                <div className="button-wrapper text-center">
                  <>
                    <CustomButton type="submit" size="l">
                      Send
                    </CustomButton>
                    <CustomButton
                      // TextButton
                      // type="button"
                      type="submit"
                      size="l"
                      variant="red"
                      onClick={onClose}
                      className="font_m fw_6"
                    >
                      Cancel
                    </CustomButton>
                  </>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </Modal.Body>

      {/* Success Modal */}
      {/* <SuccessModal
        showModal={showSuccessModal}
        description={modalDesc}
        title="Successfully Created"
        onClose={() => {
          onSuccess();
          setShowSuccessModal(false);
        }}
      /> */}

      {/* Error Modal */}
      {/* <ErrorModal
        showModal={showErrorModal}
        description={modalDesc}
        title="Error"
        onClose={() => setShowErrorModal(false)}
      /> */}
    </Modal>
  );
};
/* Component ends */

export default EmailModal;
