/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  fetchRatingsList,
  fetchRatingsListBasedCommunity,
  fetchRatingsListBasedProperty,
  fetchSearchedRatingsList,
  getCommunityRatingsList,
  getPropertyRatingsList,
  getRatingsList,
} from "../../setup/store/slices/ratingDashboardSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { EnvelopeOutLinedBlue, PrintIcon } from "../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import Header from "./local-component/Header";
import GLOBAL from "../../setup/constants/global";
import Pagination from "../../components/module/RnD/Pagination/Pagination";
import Type3DPieChart from "../../components/ui/other/PieCharts/Type3DPieChart";
import Searchbar from "../../components/ui/other/Searchbar/Searchbar";
import RatingTable from "./local-component/RatingTable";
import CustomDateInput from "../../components/ui/input/CustomDateInput/CustomDateInput";
import { getUniqueValues } from "../../setup/utils/global-helper";
import CustomStarRate from "../../components/ui/other/CustomStarRate/CustomStarRate";
import EmailModal from "../../components/ui/modal/EmailModal/EmailModal";
/* Import local pages and component ends */

import "./style.scss";
import BreadCrumb from "../../components/ui/other/BreadCrumb/BreadCrumb";
import moment from "moment";

/* Component starts */
const RatingsDashboard = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const startDate = new Date();
  // const endDate = new Date();
  const [fromDate, setFromDate] = useState(
    new Date(startDate.getFullYear(), startDate.getMonth(), 1)
  );
  const [toDate, setToDate] = useState(
    // new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0)
    new Date()
  );

  const [search, setSearch] = useState("");
  const [ratings, setRatings] = useState([]);
  const [filteredRatings, setFilteredRatings] = useState();

  const [typeData, setTypeData] = useState();
  const [statusData, setStatusData] = useState();
  const [serviceRate, setServiceRate] = useState();
  const [techRate, setTechRate] = useState();

  const [cleared, setCleared] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [showModal, setShowModal] = useState(false);
  // FILTERS

  const [communityData, setCommunityData] = useState();
  const [multiSelectCommunity, setMultiSelectCommunity] = useState();
  const [propertyData, setPropertyData] = useState();
  const [multiSelectProperty, setMultiSelectProperty] = useState();

  const [typeData1, setTypeData1] = useState();
  const [multiSelectType, setMultiSelectType] = useState();

  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const { pathname } = useLocation();
  const param = useParams();
  const dispatch = useDispatch();
  const ratingsList = useSelector(getRatingsList);
  const propertyRatingsList = useSelector(getPropertyRatingsList);
  const communityRatingsList = useSelector(getCommunityRatingsList);

  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItemsList = filteredRatings?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  const loadRatingsList = async () => {
    if (pathname.includes("communities")) {
      await dispatch(
        fetchRatingsListBasedCommunity({
          community_id: param.communityId,
        })
      );
    } else if (pathname.includes("independent")) {
      await dispatch(
        fetchRatingsListBasedProperty({ property_id: param.propertyId })
      );
    } else {
      await dispatch(fetchRatingsList());
    }
  };

  const filterTypeOfRatings = (rating) => {
    let tenantMain = rating?.filter((d) => d.type === "Tenant Maintenance");
    let internalMain = rating?.filter((d) => d.type === "Internal Maintenance");

    const obj = [];
    obj.push(["Rating Type", "No. Of Type"]);
    if (tenantMain?.length > 0) {
      obj.push(["Tenant Maintenance", tenantMain?.length]);
    }
    if (internalMain?.length > 0) {
      obj.push(["Internal Maintenance", internalMain?.length]);
    }

    setTypeData(obj);
  };

  const filterStatusOfRatings = (rating) => {
    let _5starService = rating?.filter(
      (r) => r?.service_rating === 5 && r?.service_rating !== undefined
    ).length;
    let _4starService = rating?.filter(
      (r) => r?.service_rating === 4 && r?.service_rating !== undefined
    ).length;
    let _3starService = rating?.filter(
      (r) => r.service_rating === 3 && r?.service_rating !== undefined
    ).length;
    let _2starService = rating?.filter(
      (r) => r?.service_rating === 2 && r?.service_rating !== undefined
    ).length;
    let _1starService = rating?.filter(
      (r) => r?.service_rating === 1 && r?.service_rating !== undefined
    ).length;

    //Sum of individual star.
    let sumOfRating = parseInt(
      _5starService +
        _4starService +
        _3starService +
        _2starService +
        _1starService
    );

    //Total number of rating
    let overallRating = parseInt(
      5 * _5starService +
        4 * _4starService +
        3 * _3starService +
        2 * _2starService +
        1 * _1starService
    );

    //Average of all rating
    let averageServiceRating = parseFloat(overallRating / sumOfRating);

    setServiceRate(
      isNaN(averageServiceRating) !== true
        ? Math.round(averageServiceRating)
        : 0
    );

    let _5starTech = rating?.filter(
      (r) => r?.technician_rating === 5 && r?.technician_rating !== undefined
    ).length;
    let _4starTech = rating?.filter(
      (r) => r?.technician_rating === 4 && r?.technician_rating !== undefined
    ).length;
    let _3starTech = rating?.filter(
      (r) => r?.technician_rating === 3 && r?.technician_rating !== undefined
    ).length;
    let _2starTech = rating?.filter(
      (r) => r?.technician_rating === 2 && r?.technician_rating !== undefined
    ).length;
    let _1starTech = rating?.filter(
      (r) => r?.technician_rating === 1 && r?.technician_rating !== undefined
    ).length;

    //Sum of individual star.
    let sumOfRatingTech = parseInt(
      _5starTech + _4starTech + _3starTech + _2starTech + _1starTech
    );

    //Total number of rating
    let overallRatingTech = parseInt(
      5 * _5starTech +
        4 * _4starTech +
        3 * _3starTech +
        2 * _2starTech +
        1 * _1starTech
    );

    //Average of all rating
    let averageTechRating = parseFloat(overallRatingTech / sumOfRatingTech);
    setTechRate(
      isNaN(averageTechRating) !== true ? Math.round(averageTechRating) : 0
    );
  };

  const handleSearch = async (isReset = false, resetValue = {}) => {
    let payload = {};
    if (!isReset) {
      if (search && fromDate && toDate) {
        payload = {
          searchKey: search,
          start_date: moment(fromDate)?.format("MM-DD-YYYY"),
          end_date: moment(toDate)?.format("MM-DD-YYYY"),
        };
        // Backend response. Try, Catch
        try {
          let result;
          if (pathname.includes("communities")) {
            payload = {
              ...payload,
              community_id: param.communityId,
            };
            result = await dispatch(fetchSearchedRatingsList(payload));
          } else if (pathname.includes("independent")) {
            payload = {
              ...payload,
              property_id: param.propertyId,
            };
            result = await dispatch(fetchSearchedRatingsList(payload));
          } else {
            result = await dispatch(fetchSearchedRatingsList(payload));
          }
          // Handling success response
          switch (result.meta.requestStatus) {
            case GLOBAL.REJECTED:
              // setModalDesc(result.payload);
              // setShowErrorModal(true);
              break;

            case GLOBAL.FULFILLED:
              // setModalDesc(result.payload.message);
              // setShowSuccessModal(true);
              setRatings(result.payload);
              setFilteredRatings(result?.payload);
              break;
          }
        } catch (error) {
          // Handling error response
          console.error(error.message);
        }
      } else if (!search && fromDate && toDate) {
        payload = {
          searchKey: "",
          start_date: moment(fromDate)?.format("MM-DD-YYYY"),
          end_date: moment(toDate)?.format("MM-DD-YYYY"),
        };
        // Backend response. Try, Catch
        try {
          let result;
          if (pathname.includes("communities")) {
            payload = {
              ...payload,
              community_id: param.communityId,
            };
            result = await dispatch(fetchSearchedRatingsList(payload));
          } else if (pathname.includes("independent")) {
            payload = {
              ...payload,
              property_id: param.propertyId,
            };
            result = await dispatch(fetchSearchedRatingsList(payload));
          } else {
            result = await dispatch(fetchSearchedRatingsList(payload));
          }
          // Handling success response
          switch (result.meta.requestStatus) {
            case GLOBAL.REJECTED:
              // setModalDesc(result.payload);
              // setShowErrorModal(true);
              break;

            case GLOBAL.FULFILLED:
              // setModalDesc(result.payload.message);
              // setShowSuccessModal(true);
              setRatings(result.payload);
              setFilteredRatings(result?.payload);

              break;
          }
        } catch (error) {
          // Handling error response
          console.error(error.message);
        }
      } else if (search) {
        payload = {
          searchKey: search,
        };

        await dispatch(fetchSearchedRatingsList(payload));
      }
    }
    // else {
    //   payload = {
    //     ...resetValue,
    //   };
    // }
  };

  const handleClear = () => {
    let payload = {
      searchKey: "",
      start_date: new Date(startDate.getFullYear(), startDate.getMonth(), 1),
      end_date: new Date(),
    };
    setSearch(payload.searchKey);
    setFromDate(payload.start_date);
    setToDate(payload.end_date);

    handleSearch(true, payload);
    setCleared(!cleared);
  };

  // To check the any checkbox is true or not
  const checkAnyFilterChecked = (filter) => {
    return Object?.keys(filter)?.every(function (k) {
      return !filter[k];
    });
  };

  // SEND EMAIL FUNCTION
  const handleSendEmail = () => {
    setShowModal(!showModal);
  };

  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    loadRatingsList();
  }, [pathname, !cleared]);

  useEffect(() => {
    if (pathname.includes("communities") && communityRatingsList) {
      setRatings(communityRatingsList);
      setFilteredRatings([...communityRatingsList]);
      filterTypeOfRatings(ratings);
      filterStatusOfRatings(ratings);
    } else if (pathname.includes("independent") && propertyRatingsList) {
      setRatings(propertyRatingsList);
      setFilteredRatings([...propertyRatingsList]);
      filterTypeOfRatings(ratings);
      filterStatusOfRatings(ratings);
    } else {
      setRatings(ratingsList);
      setFilteredRatings([...ratingsList]);
      filterTypeOfRatings(ratings);
      filterStatusOfRatings(ratings);
    }
  }, [
    pathname,
    ratingsList,
    communityRatingsList,
    propertyRatingsList,
    !cleared,
  ]);

  useEffect(() => {
    if (pathname.includes("communities") && communityRatingsList) {
      filterTypeOfRatings(ratings);
      filterStatusOfRatings(ratings);
    } else if (pathname.includes("independent") && propertyRatingsList) {
      filterTypeOfRatings(ratings);
      filterStatusOfRatings(ratings);
    } else {
      filterTypeOfRatings(ratings);
      filterStatusOfRatings(ratings);
    }
  }, [!search, ratings]);

  useEffect(() => {
    const community = getUniqueValues(ratings, "community_name");
    const property = getUniqueValues(ratings, "property_name");
    const type = getUniqueValues(ratings, "type");

    const communityObject = {};
    community.forEach((prop) => (communityObject[prop] = false));
    setMultiSelectCommunity(communityObject);
    setCommunityData(community);

    const propertyObject = {};
    property.forEach((prop) => (propertyObject[prop] = false));
    setMultiSelectProperty(propertyObject);
    setPropertyData(property);

    const statusObject = {};
    type.forEach((prop) => (statusObject[prop] = false));
    setMultiSelectType(statusObject);
    setTypeData1(type);
  }, [ratings]);

  useEffect(() => {
    if (
      multiSelectCommunity !== undefined ||
      multiSelectProperty !== undefined ||
      multiSelectType !== undefined
    ) {
      let allUnCheckedCommunity = checkAnyFilterChecked(multiSelectCommunity);
      let allUnCheckedProperty = checkAnyFilterChecked(multiSelectProperty);

      let allUnCheckedType = checkAnyFilterChecked(multiSelectType);

      if (!allUnCheckedCommunity && multiSelectCommunity) {
        let selectedValue = Object.keys(multiSelectCommunity)?.filter(
          (key) => multiSelectCommunity[key]
        );

        const filteredCommunity = ratings?.filter((d) =>
          selectedValue?.includes(d.community_name)
        );

        setFilteredRatings(filteredCommunity);
      } else if (!allUnCheckedProperty && multiSelectProperty) {
        let selectedValue = Object.keys(multiSelectProperty)?.filter(
          (key) => multiSelectProperty[key]
        );

        const filteredProperty = ratings?.filter((d) =>
          selectedValue?.includes(d.property_name)
        );

        setFilteredRatings(filteredProperty);
      } else if (!allUnCheckedType && multiSelectType) {
        let selectedValue = Object.keys(multiSelectType)?.filter(
          (key) => multiSelectType[key]
        );

        const filteredStatus = ratings?.filter((d) =>
          selectedValue?.includes(d.type)
        );

        setFilteredRatings(filteredStatus);
      }
    } else {
      setFilteredRatings(ratings);
    }
  }, [multiSelectCommunity, multiSelectProperty, multiSelectType]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      <BreadCrumb type="dashboard_rating" />
      <div className="ratings-dashboard-wrapper">
        <div className="action-wrapper">
          <div>
            <img src={PrintIcon} alt="Print-Icon" />
            <span className="font_m fw_3 font_blue">Print</span>
          </div>

          <div onClick={handleSendEmail}>
            <img src={EnvelopeOutLinedBlue} alt="Print-Icon" />
            <span className="font_m fw_3 font_blue">Email</span>
          </div>
        </div>
        <div className="header">
          <Header />
        </div>

        {ratings?.length > 0 && (
          <div className="report-chart-wrapper">
            <div className="detail-box bg_grey">
              <div>
                <p className="font_l fw_4">Type</p>
              </div>
              {/* <div>
            <PieChartGraph />
          </div> */}
              <Type3DPieChart data={typeData} />
            </div>

            <div className="detail-box bg_grey rate">
              <div>
                <p className="font_l fw_4">Rating</p>
              </div>
              <div className="rating-main-wrapper">
                <div>
                  <p className="font_xl fw_4">Service Rating</p>
                  <div className="rated-value">
                    <CustomStarRate stars={serviceRate} size={30} />
                    <p className="font_xxl fw_5 font_grey">{serviceRate} / 5</p>
                  </div>
                </div>
                <div>
                  <p className="font_xl fw_4">Technician Rating</p>

                  <div className="rated-value">
                    <CustomStarRate stars={techRate} size={30} />
                    <p className="font_xxl fw_5 font_grey">{techRate} / 5</p>
                  </div>
                </div>
              </div>
              {/* <Status3DPieChart data={statusData} /> */}
            </div>
          </div>
        )}

        <div className="search-box mt-4 mb-4">
          <div className="search">
            <Searchbar
              setQuery={setSearch}
              query={search}
              className="dash-search"
            />
          </div>

          <div className="date">
            <div>
              <CustomDateInput
                label="From"
                value={fromDate}
                minDate={false}
                customOnchange={(date) => setFromDate(date)}
                required={true}
              />
            </div>

            <div>
              <CustomDateInput
                label="To"
                value={toDate}
                minDate={false}
                customOnchange={(date) => setToDate(date)}
                required={true}
              />
            </div>
          </div>
          <div className="btn-wrapper">
            <button onClick={() => handleSearch()}>Apply</button>
            <button className="btn-clear" onClick={() => handleClear()}>
              Clear
            </button>
          </div>
        </div>

        <div className="ratings-table-wrapper">
          <RatingTable
            ratingListData={currentItemsList}
            communityData={communityData}
            multiSelectCommunity={multiSelectCommunity}
            setMultiSelectCommunity={setMultiSelectCommunity}
            propertyData={propertyData}
            multiSelectProperty={multiSelectProperty}
            setMultiSelectProperty={setMultiSelectProperty}
            typeData1={typeData1}
            multiSelectType={multiSelectType}
            setMultiSelectType={setMultiSelectType}
          />

          {filteredRatings?.length > 0 && (
            <Pagination
              itemsPerPage={itemsPerPage}
              totalItems={filteredRatings?.length}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          )}
        </div>

        {/* EMAIL MODAL */}
        <EmailModal
          showModal={showModal}
          onSuccess={() => setShowModal(!showModal)}
          onClose={() => setShowModal(!showModal)}
        />
      </div>
    </>
  );
};
/* Component ends */

export default RatingsDashboard;
