/**
 *
 */

import React from "react";

/* Import configuration starts */
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Table } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { EyeFilledBlue } from "../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../../../ui/button/IconButton/IconButton";
import GLOBAL from "../../../../../setup/constants/global";
import { pathNamePopped } from "../../../../../setup/utils/global-helper";
/* Import local pages and component ends */

/* Component starts */
const Security = (props) => {
  /* Props destructuring starts */
  const { securityList } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const navigate = useNavigate();
  const { pathname } = useLocation();
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const handleViewReport = (item) => {
    const newPathName = pathNamePopped(pathname, 6);

    if (item?.status === "resolved") {
      navigate(`${newPathName}/security/closed/complaints/${item?._id}`, {
        state: { pathname: pathname },
      });
    } else {
      navigate(`${newPathName}/security/complaints/open/${item?._id}`, {
        state: { pathname: pathname },
      });
    }
  };

  const getStatus = (status) => {
    let txt_status = status;
    let statusColor = "";
    switch (status) {
      case GLOBAL.SECURITY_STATUS.PENDING_SO_ASSIGNMENT:
      case GLOBAL.SECURITY_STATUS.PENDING_DECISION:
        statusColor = "font_yellow";
        txt_status = "Pending Decision";
        break;
      case GLOBAL.SECURITY_STATUS.ESCALATED:
        statusColor = "font_yellow";
        txt_status = "Escalated";
        break;
      case GLOBAL.SECURITY_STATUS.CLOSED:
        statusColor = "font_green";
        break;
      case GLOBAL.SECURITY_STATUS.SO_ASSIGNED:
        statusColor = "font_yellow";
        txt_status = "Security Officer Assigned";
        break;
      case GLOBAL.SECURITY_STATUS.RESOLVED:
        statusColor = "font_green";
        txt_status = "resolved";
        break;
    }
    return (
      <span className={`font_s fw_4 ${statusColor}`}>
        {txt_status.toUpperCase()}
      </span>
    );
  };

  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="custom-table-wrapper security-table">
      {securityList?.length > 0 && (
        <Table className="custom-table font_s" borderless responsive>
          {/* Table Heading */}
          <thead>
            <tr>
              <th>ID</th>
              <th>Date</th>
              <th>Complain Type</th>
              <th>Status</th>
              <th className="text-start">Action</th>
            </tr>
          </thead>
          {/* Table Body */}
          <tbody>
            {securityList?.map((item) => (
              <tr key={item?._id}>
                {/* ID */}
                <td>{item?.ticket_id}</td>

                {/* Item Due Date */}
                <td>{moment(item?.createdAt).format(DATE_FORMAT)}</td>

                {/* Complain Type*/}
                <td>{item?.security_complaint_id?.name}</td>

                {/* Status */}
                <td>{getStatus(item?.status)}</td>

                {/* Actions */}
                <td className="text-start txt-nowrap">
                  <IconButton
                    onClick={() => handleViewReport(item)}
                    className="action-button"
                  >
                    <img
                      src={EyeFilledBlue}
                      className="h_100"
                      alt="View Details"
                    />
                  </IconButton>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      {securityList?.length === 0 && (
        <div className=" text-center">
          <h3 className=" font_m font_yellow fw_6">No Data</h3>
        </div>
      )}
    </div>
  );
};
/* Component ends */

export default Security;
