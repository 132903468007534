/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Accordion, Table } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { EyeFilledBlue } from "../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../../../ui/button/IconButton/IconButton";
import moment from "moment";
import GLOBAL from "../../../../../setup/constants/global";
import DetailViewModal from "./DetailViewModal/DetailViewModal";
/* Import local pages and component ends */

/* Component starts */
const Rent = (props) => {
  /* Props destructuring starts */
  const { rentalList, property } = props;
  console.log(rentalList, "{RENT}");
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [showContractDetailModal, setShowContractDetailModal] = useState(false);
  const [detail, setDetail] = useState({});
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const handleView = (item) => {
    setDetail(item);
    setShowContractDetailModal(!showContractDetailModal);
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="wrapper">
      {rentalList?.map((item, index) => (
        <div className="my-4" key={index}>
          <Accordion>
            <Accordion.Item eventKey={index}>
              <Accordion.Header>
                <div
                  className="d-flex justify-content-between"
                  style={{ width: "75%" }}
                >
                  <p className=" font_m fw_4">#{item?.contract_req_id}</p>
                  <p className=" font_m fw_4">
                    Total Rent
                    <br />
                    <span className=" font_m fw_4">
                      {item?.total_rent?.toLocaleString("en-US")}{" "}
                      {item?.currency}
                    </span>
                  </p>
                  <p className=" font_m fw_4">
                    No. Of Installments
                    <br />
                    <span className=" font_m fw_4">
                      {item?.no_of_installments}
                    </span>
                  </p>
                  <p className=" font_m fw_4">
                    Start Date <br />
                    <span className=" font_m fw_4">
                      {moment(item?.contract_start_date).format(DATE_FORMAT)}
                    </span>
                  </p>

                  <p className=" font_m fw_4">
                    End Date <br />
                    <span className=" font_m fw_4">
                      {moment(item?.contract_end_date).format(DATE_FORMAT)}
                    </span>
                  </p>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <div className="custom-table-wrapper rent-table">
                  {item?.instalments?.length > 0 && (
                    <Table
                      className="custom-table font_s"
                      borderless
                      responsive
                    >
                      {/* Table Heading */}
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Due Date</th>
                          {/* <th>Payment Method</th> */}
                          <th>Amount </th>
                          {/* <th>Status</th> */}
                          <th className="text-start">Action</th>
                        </tr>
                      </thead>
                      {/* Table Body */}
                      <tbody>
                        {item?.instalments?.map((item) => (
                          <tr key={item?._id}>
                            {/* ID */}
                            <td>{item?.name}</td>

                            {/* Item Due Date */}
                            <td>
                              {moment(item?.due_date).format(DATE_FORMAT)}
                            </td>

                            {/* Item Pay Method */}
                            {/* <td>
                {item?.pay_method !== undefined ? item?.pay_method : "-"}
              </td> */}

                            {/* Amount*/}
                            <td>
                              {item?.amount?.toLocaleString("en-US")}{" "}
                              {property?.currency}
                            </td>

                            {/* Status */}
                            {/* <td
                className={
                  item?.status === "Paid"
                    ? "paid"
                    : item?.status === "Unpaid"
                    ? "unpaid"
                    : ""
                }
              >
                {item?.status}
              </td> */}

                            {/* Actions */}
                            <td className="text-start txt-nowrap">
                              <IconButton
                                onClick={() => handleView(item)}
                                className="action-button"
                              >
                                <img
                                  src={EyeFilledBlue}
                                  className="h_100"
                                  alt="View Details"
                                />
                              </IconButton>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  )}

                  {item?.instalments?.length === 0 && (
                    <div className=" text-center">
                      <h3 className=" font_m font_yellow fw_6">No Data</h3>
                    </div>
                  )}
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      ))}

      {/* Rent detail modal */}
      <DetailViewModal
        type="Rent"
        fromContract={false}
        showModal={showContractDetailModal}
        detail={detail}
        onClose={() => {
          setShowContractDetailModal(false);
        }}
      />
    </div>
  );
};
/* Component ends */

export default Rent;
