import React from "react";

const Header = () => {
  return (
    <>
      <h2 className="fw_5 font_xxl"> Internal Communication </h2>
    </>
  );
};

export default Header;
