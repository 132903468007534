export const initialValues = {
  title: "",
  vendor_name: "",
  email: "",
  mobile_number: "",
  amount: "",
};

export const validations = (values, type) => {
  const error = {};
  /* Validation for Title starts */
  if (values.title === "") {
    error.title = "Title is required";
  }
  /* Validation for Title ends */
  /* ******************************************************************************* */

  /* Validation for Last Name starts */
  if (type === "Vendor" && values.vendor_name === "") {
    error.last_name = "Last Name is required";
  }
  /* Validation for Last Name ends */
  /* ******************************************************************************* */

  /* Validation for Mobile Number starts */
  if (type === "Vendor" && values.mobile_number === " ") {
    error.mobile_number = "Mobile Number is required";
  }
  /* Validation for Mobile Number ends */
  /* ******************************************************************************* */

  /* Validation for Email starts */
  if (type === "Vendor" && values.email === " ") {
    error.email = "Email is required";
  }
  /* Validation for Email ends */
  /* ******************************************************************************* */

  /* Validation for Amount starts */
  if (type === "Vendor" && values.amount === " ") {
    error.amount = "Amount is required";
  }
  /* Validation for Amount ends */
  /* ******************************************************************************* */
};
