/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import GLOBAL from "../../../../../setup/constants/global";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../../../setup/store/slices/globalAlertSlice";
import { initiateMoveOut } from "../../../../../setup/store/slices/moveOutSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomDateInput from "../../../../ui/input/CustomDateInput/CustomDateInput";
import CustomButton from "../../../../ui/button/CustomButton/CustomButton";
import TextButton from "../../../../ui/button/TextButton/TextButton";
import ConfirmationModal from "../../../../ui/modal/ConfirmationModal/ConfirmationModal";
/* Import local pages and component ends */

/* Component starts */
const Report = (props) => {
  /* Props destructuring starts */
  const { unitId, contractId, contract_end_date, getTenantMoveOutDetail } =
    props;

  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [selectedDate, setSelectedDate] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);

  const [initialValues, setIntialValues] = useState({
    date: new Date(),
  });

  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const navigate = useNavigate();

  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // On confirming move out
  const onConfirmMoveout = async () => {
    setShowConfirm(false);
    // Creating or collecting payload data to be sent
    const payload = {
      contract_id: contractId,
      move_out_date: selectedDate,
    };
    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      const result = await dispatch(initiateMoveOut(payload));
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          getTenantMoveOutDetail();
          navigate(-1);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // On submitting form
  const onSubmit = (values) => {
    setSelectedDate(values.date);
    setShowConfirm(true);
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    if (contract_end_date) {
      setIntialValues({
        date: contract_end_date,
      });
    }
  }, [contract_end_date]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="moveout-unit-detail-box">
      <div className="time-info">
        <div>
          <p className="font_s fw_4">Select preferred move out date</p>
          <Formik
            onSubmit={onSubmit}
            initialValues={initialValues}
            validateOnBlur={true}
            enableReinitialize={true}
            validate={(values) => {}}
          >
            {({
              values,
              errors,
              touched,
              handleSubmit,
              setFieldValue,
              setFieldTouched,
            }) => (
              <form onSubmit={handleSubmit}>
                {/* Form Fields */}
                <div className="form-wrapper form-wrapper-flex">
                  {/* Input for Date */}
                  <div className="form-group">
                    <CustomDateInput
                      name="date"
                      label="Select Date"
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      touched={touched.date}
                      errors={errors.date}
                      value={values.date}
                      maxDate={true}
                      maxDateValue={new Date(contract_end_date)}
                      required={true}
                    />
                  </div>
                </div>

                {/* Submit and Cancel */}
                <div className="button-wrapper text-center mt-3">
                  <CustomButton type="submit" size="l">
                    Submit
                  </CustomButton>

                  <TextButton
                    type="button"
                    onClick={() => navigate(-1)}
                    className="font_m fw_6 mt-2"
                  >
                    Cancel
                  </TextButton>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
      {/* Modals */}
      {/* Confirmation Modal */}
      <ConfirmationModal
        showModal={showConfirm}
        title="Move Out ?"
        description="Are you sure you want to move out ? This is an irreversible action."
        onClose={() => {
          setShowConfirm(false);
          setSelectedDate(null);
        }}
        onConfirm={onConfirmMoveout}
      />
    </div>
  );
};
/* Component Ends */
export default Report;
