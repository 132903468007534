/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useDispatch, useSelector } from "react-redux";
import {
  useNavigate,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import GLOBAL from "../../setup/constants/global";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  getTenantApplication,
  getTenantContract,
  fetchTenantApplicationByIdNew,
  resubmitApplicationData,
} from "../../setup/store/slices/tenantSlice";
import {
  showSuccessAlert,
  showErrorAlert,
} from "../../setup/store/slices/globalAlertSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import TenantOnboarding from "../../components/module/Tenant/TenantOnboarding/TenantOnboarding";
/* Import local pages and component ends */

/* Component starts */
const TenantOnboardPage = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [application, setApplication] = useState(null);
  const [sequence, setSequence] = useState([]);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Redux Selector
  const { state } = useLocation();
  const { unitId, applicationId } = useParams();
  const [searchParams] = useSearchParams();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const fetchApplication = async () => {
    // Creating or collecting payload data to be sent

    // Backend response. Try, Catch
    try {
      const result = await dispatch(
        fetchTenantApplicationByIdNew(applicationId)
      );
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setSequence([]);
          setApplication(null);
          break;

        case GLOBAL.FULFILLED:
          setSequence(result.payload.sequence);
          setApplication(result.payload.data);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  const resubmitApplication = async () => {
    // Creating or collecting payload data to be sent

    // Backend response. Try, Catch
    try {
      const result = await dispatch(
        resubmitApplicationData({ application_id: applicationId })
      );
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description:
                typeof result?.payload === "string" ? result?.payload : "",
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description:
                typeof result?.payload?.message === "string"
                  ? result?.payload?.message
                  : "",
            })
          );
          navigate(-1);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    fetchApplication();
  }, [applicationId]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="">
      {application && (
        <TenantOnboarding
          application={application}
          sequence={sequence}
          fetchApplication={fetchApplication}
          resubmitApplication={resubmitApplication}
          application_id={applicationId}
          redirect={searchParams.get("redirect")}
        />
      )}
    </div>
  );
};
/* Component ends */

export default TenantOnboardPage;
