/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import moment from "moment";
import { customMoment } from "../../../../../setup/utils/global-helper";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
/* Import local pages and component ends */

/* Component starts */
const InstallmentPlanDetail = (props) => {
  /* Props destructuring starts */
  const { settings, currency, installmentPlan, property } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";

  const collectTax = settings?.collect_tax_enabled;
  const taxValue = settings?.collect_tax_value;
  const collectVat = settings?.collect_vat_enabled;
  const vatValue = settings?.collect_vat_value;

  const startDate = installmentPlan?.start_date
    ? customMoment(
        installmentPlan?.start_date,
        DATE_FORMAT,
        true,
        property?.offset
      )
    : "";
  const endDate = installmentPlan?.end_date
    ? customMoment(
        installmentPlan?.end_date,
        DATE_FORMAT,
        true,
        property?.offset
      )
    : "";
  const numInstallment = installmentPlan?.no_of_installments;
  const duration = installmentPlan?.duration;
  const collectCommission = installmentPlan?.commission;
  const commisionAmt = installmentPlan?.commission_amount;
  const collectCommissionVat =
    collectVat && installmentPlan?.collect_commission_vat;
  const collectCommissionTax =
    collectTax && installmentPlan?.collect_commission_tax;
  const collectDeposit = installmentPlan?.deposit;
  const depositAmt = installmentPlan?.deposit_amount;
  const collectDepositVat = collectVat && installmentPlan?.collect_deposit_vat;
  const collectDepositTax = collectTax && installmentPlan?.collect_deposit_tax;
  const collectCheque = installmentPlan?.cheque;
  const totalRent = installmentPlan?.total_rent;
  const collectInstallmentVat =
    collectVat && installmentPlan?.collect_instalment_vat;
  const collectInstallmentTax =
    collectTax && installmentPlan?.collect_instalment_tax;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const getTotal = (amt, cvt, ctx) => {
    let amount = parseFloat(amt);
    if (cvt && settings?.collect_vat_value) {
      amount += (parseFloat(amt) * vatValue) / 100;
    }
    if (ctx && settings?.collect_tax_value) {
      amount += (parseFloat(amt) * taxValue) / 100;
    }

    // return `${amount.toFixed(2)}`;
    return `${amount?.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`;
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="installment-plan-detail-wrapper">
      <div className="custom-row custom-row-flex custom-row-flex-2">
        <div className="detail-box start-date-detail custom-column">
          <p className="label fw_5 font_s font_grey">Contract Start Date</p>
          <p className="value fw_5 font_s ">{startDate}</p>
        </div>
        <div className="detail-box end-date-detail custom-column">
          <p className="label fw_5 font_s font_grey">Contract End Date</p>
          <p className="value fw_5 font_s ">{endDate}</p>
        </div>
      </div>
      <div className="custom-row custom-row-flex custom-row-flex-2">
        <div className="detail-box duration-detail custom-column">
          <p className="label fw_5 font_s font_grey">Contract Duration</p>
          <p className="value fw_5 font_s ">{duration}</p>
        </div>
        <div className="detail-box installment-num-detail custom-column">
          <p className="label fw_5 font_s font_grey">Number Of Installments</p>
          <p className="value fw_5 font_s ">{numInstallment}</p>
        </div>
      </div>
      <div className="custom-row custom-row-flex custom-row-flex-2">
        <div className="detail-box total-rent-detail custom-column">
          <p className="label fw_5 font_s font_grey">
            Total Rent ({currency}) <br />
            {(collectInstallmentTax || collectInstallmentVat) && (
              <>
                {collectInstallmentTax && (
                  <span className="font_xs font_grey">TAX({taxValue}%)</span>
                )}
                <br />
                {collectInstallmentVat && (
                  <span className="font_xxs font_grey">VAT({vatValue}%)</span>
                )}
                <br />
                <span className="font_xxs font_grey">Total ({currency})</span>
              </>
            )}
          </p>
          <p className="value fw_5 font_s ">
            {totalRent?.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}{" "}
            <br />
            {(collectInstallmentTax || collectInstallmentVat) && (
              <>
                {collectInstallmentTax && (
                  <span className="font_xxs font_grey">
                    {((totalRent * taxValue) / 100)?.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </span>
                )}
                <br />
                {collectInstallmentVat && (
                  <span className="font_xxs font_grey">
                    {((totalRent * vatValue) / 100)?.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </span>
                )}
                <br />
                <span className="font_xxs font_grey">
                  {getTotal(
                    totalRent,
                    collectInstallmentVat,
                    collectInstallmentTax
                  )}
                </span>
              </>
            )}
          </p>
        </div>
        {collectDeposit && (
          <div className="detail-box deposit-detail custom-column">
            <p className="label fw_5 font_s font_grey">
              Total Deposit ({currency}) <br />
              {(collectDepositTax || collectDepositVat) && (
                <>
                  {collectDepositTax && (
                    <span className="font_xxs font_grey">TAX({taxValue}%)</span>
                  )}
                  <br />
                  {collectDepositVat && (
                    <span className="font_xxs font_grey">VAT({vatValue}%)</span>
                  )}
                  <br />
                  <span className="font_xxs font_grey">Total ({currency})</span>
                </>
              )}
            </p>
            <p className="value fw_5 font_s ">
              {depositAmt?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}{" "}
              <br />
              {(collectDepositTax || collectDepositVat) && (
                <>
                  {collectDepositTax && (
                    <span className="font_xxs font_grey">
                      {((depositAmt * taxValue) / 100)?.toLocaleString(
                        undefined,
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }
                      )}
                    </span>
                  )}
                  <br />
                  {collectDepositVat && (
                    <span className="font_xxs font_grey">
                      {((depositAmt * vatValue) / 100)?.toLocaleString(
                        undefined,
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }
                      )}
                    </span>
                  )}
                  <br />
                  <span className="font_xxs font_grey">
                    {getTotal(depositAmt, collectDepositVat, collectDepositTax)}
                  </span>
                </>
              )}
            </p>
          </div>
        )}
      </div>

      {collectCommission && (
        <div className="custom-row">
          <div className="detail-box commission-detail custom-column">
            <p className="label fw_5 font_s font_grey">
              Commission({currency}) <br />
              {(collectCommissionTax || collectCommissionVat) && (
                <>
                  {collectCommissionTax && (
                    <span className="font_xxs font_grey">TAX({taxValue}%)</span>
                  )}
                  <br />
                  {collectCommissionVat && (
                    <span className="font_xxs font_grey">VAT({vatValue}%)</span>
                  )}
                  <br />
                  Total({currency})
                </>
              )}
            </p>
            <p className="value fw_5 font_s ">
              {commisionAmt?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}{" "}
              <br />
              {(collectCommissionTax || collectCommissionVat) && (
                <>
                  {collectCommissionTax && (
                    <span className="font_xxs font_grey">
                      {((commisionAmt * taxValue) / 100)?.toLocaleString(
                        undefined,
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }
                      )}
                    </span>
                  )}
                  <br />
                  {collectCommissionVat && (
                    <span className="font_xxs font_grey">
                      {((commisionAmt * vatValue) / 100)?.toLocaleString(
                        undefined,
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }
                      )}
                    </span>
                  )}
                  <br />
                  <span className="font_xxs font_grey">
                    {getTotal(
                      commisionAmt,
                      collectCommissionVat,
                      collectCommissionTax
                    )}
                  </span>
                </>
              )}
            </p>
          </div>
        </div>
      )}
      {collectCheque && (
        <div className="custom-row">
          <p className="font_s fw_6 font_yellow">Receive Rent By Cheques</p>
        </div>
      )}
    </div>
  );
};
/* Component ends */

export default InstallmentPlanDetail;
