/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";
/* Import configuration ends */

/* Import redux slices component starts */

/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Table } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { EyeFilledBlue } from "../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import GLOBAL from "../../../setup/constants/global";
import IconButton from "../../../components/ui/button/IconButton/IconButton";
import { fetchIncidentBasedOnId } from "../../../setup/store/slices/securitySlice";
import CheckboxMultiSelectDropdown from "../../../components/ui/input/CheckboxMultiSelectDropdown/CheckboxMultiSelectDropdown";
/* Import local pages and component ends */

/* Component starts */
const SecurityTable = (props) => {
  /* Props destructuring starts */
  const {
    securityListData,
    multiSelectCommunity,
    setMultiSelectCommunity,
    communityData,
    propertyData,
    multiSelectProperty,
    setMultiSelectProperty,
    types,
    multiSelectType,
    setMultiSelectType,
    location,
    multiSelectLocation,
    setMultiSelectLocation,
    violator,
    multiSelectViolator,
    setMultiSelectViolator,
    statusData1,
    multiSelectStatus,
    setMultiSelectStatus,
    processing,
    multiSelectProcessing,
    setMultiSelectProcessing,

    unitNum,
    multiSelectUnitNum,
    setMultiSelectUnitNum,

    category,
    multiSelectCategory,
    setMultiSelectCategory,
  } = props;

  /* Props destructuring ends */

  /* Component states and useRef declaration starts */

  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  // To View The Item Report
  const handleViewReport = async (item) => {
    if (item?.type === "Complaint") {
      if (item?.community_id) {
        navigate(
          `/communities/${item?.community_id}/properties/${item?.property_id}/securities/complaints/${item?.security_complain_id}`
        );
      } else {
        navigate(
          `/independent/properties/${item?.property_id}/securities/complaints/${item?.security_complain_id}`
        );
      }
    } else {
      const itemData = await dispatch(
        fetchIncidentBasedOnId(item?.security_incident_id)
      );

      if (item?.community_id) {
        navigate(
          `/communities/${item?.community_id}/properties/${item?.property_id}/security/incidents/open/${item?.security_incident_id}`,
          {
            state: itemData.payload,
          }
        );
      } else {
        navigate(
          `/independent/properties/${item?.property_id}/security/incidents/open/${item?.security_incident_id}`,
          {
            state: { pathname: pathname },
          }
        );
      }
    }
  };

  /* Component function definition ends */

  /* Component useEffect starts */

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */

  return (
    <div className="custom-table-wrapper security-table">
      {securityListData?.length > 0 && (
        <Table className="custom-table font_s" borderless responsive>
          {/* Table Heading */}
          <thead>
            <tr>
              <th>
                <CheckboxMultiSelectDropdown
                  multiSelectData={multiSelectCommunity}
                  setMultiSelectData={setMultiSelectCommunity}
                  optionLabel={communityData}
                  label={"Community"}
                />
              </th>
              <th>
                <CheckboxMultiSelectDropdown
                  multiSelectData={multiSelectProperty}
                  setMultiSelectData={setMultiSelectProperty}
                  optionLabel={propertyData}
                  label={"Property"}
                />
              </th>
              <th>Ticket</th>
              <th>Date</th>
              <th>
                <CheckboxMultiSelectDropdown
                  multiSelectData={multiSelectUnitNum}
                  setMultiSelectData={setMultiSelectUnitNum}
                  optionLabel={unitNum}
                  label={"Unit No."}
                />
              </th>
              <th>
                <CheckboxMultiSelectDropdown
                  multiSelectData={multiSelectLocation}
                  setMultiSelectData={setMultiSelectLocation}
                  optionLabel={location}
                  label={"Location"}
                />
              </th>
              <th>
                <CheckboxMultiSelectDropdown
                  multiSelectData={multiSelectType}
                  setMultiSelectData={setMultiSelectType}
                  optionLabel={types}
                  label={"Type"}
                />
              </th>
              <th>
                <CheckboxMultiSelectDropdown
                  multiSelectData={multiSelectCategory}
                  setMultiSelectData={setMultiSelectCategory}
                  optionLabel={category}
                  label={"Category"}
                />
              </th>
              <th>Violation</th>
              <th>
                <CheckboxMultiSelectDropdown
                  multiSelectData={multiSelectViolator}
                  setMultiSelectData={setMultiSelectViolator}
                  optionLabel={violator}
                  label={"Violator"}
                />
              </th>
              <th>
                <CheckboxMultiSelectDropdown
                  multiSelectData={multiSelectProcessing}
                  setMultiSelectData={setMultiSelectProcessing}
                  optionLabel={processing}
                  label={"Processing"}
                />
              </th>
              <th>
                <CheckboxMultiSelectDropdown
                  multiSelectData={multiSelectStatus}
                  setMultiSelectData={setMultiSelectStatus}
                  optionLabel={statusData1}
                  label={"Status"}
                />
              </th>
              <th className="text-start">Action</th>
            </tr>
          </thead>
          {/* Table Body */}
          {
            securityListData?.length > 0 && (
              <tbody>
                {securityListData?.map((item, index) => (
                  <tr key={index}>
                    {/* Community Name */}
                    <td>
                      {item?.community_name !== undefined
                        ? item?.community_name
                        : "-"}
                    </td>

                    {/* Property Name */}
                    <td>
                      {item?.property_name !== undefined
                        ? item?.property_name
                        : "-"}
                    </td>

                    {/*  ID */}
                    <td>{item?.ticket}</td>

                    {/* Date */}
                    <td>{moment(item?.date).format(DATE_TIME_FORMAT)}</td>

                    {/* UNIT NO */}
                    <td>
                      {item?.unit_number !== undefined
                        ? item?.unit_number
                        : "-"}
                    </td>

                    {/* Location */}
                    <td>
                      {item?.location !== undefined ? item?.location : "-"}
                    </td>

                    {/* Type */}
                    <td>{item?.type}</td>

                    {/* Category */}
                    <td>
                      {item?.category !== undefined ? item?.category : "-"}
                    </td>

                    {/* VIOLATION */}
                    <td>
                      {item?.violation !== undefined
                        ? item?.violation.toString()
                        : "-"}
                    </td>

                    {/* Violator */}
                    <td>
                      {item?.violator !== undefined ? item?.violator : "-"}
                    </td>

                    {/* PROCESSING */}
                    <td>
                      {item?.processing !== undefined ? item?.processing : "-"}
                    </td>

                    {/* Status */}
                    <td
                      className={
                        item?.status === "Closed"
                          ? "font_green"
                          : item?.status === "resolved"
                          ? "font_blue"
                          : item?.status === "pending_decision" || "so_assigned"
                          ? "font_yellow"
                          : "font_white"
                      }
                    >
                      {item?.status}
                    </td>

                    {/* Actions */}
                    <td className="text-start txt-nowrap">
                      <IconButton
                        onClick={() => handleViewReport(item)}
                        className="action-button"
                      >
                        <img
                          src={EyeFilledBlue}
                          className="h_100"
                          alt="View Details"
                        />
                      </IconButton>
                    </td>
                  </tr>
                ))}
              </tbody>
            )
            // : (
            //   <p className="font_yellow font_l fw_5 text-center">No Data</p>
            // )
          }
        </Table>
      )}

      {securityListData?.length === 0 && (
        <div className=" text-center">
          <h3 className=" font_m font_yellow fw_6">No Data</h3>
        </div>
      )}
    </div>
  );
};
/* Component ends */
export default SecurityTable;
