/**
 *
 */

import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";

/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
/* Import local pages and component ends */

/* Component starts */
const Disability = (props) => {
  /* Props destructuring starts */
  const { data, index } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="keys-wrapper mt-2">
      <div className="keys-heading">
        <p className="font_l label">
          {" "}
          {index !== undefined ? `${index} ${"-"}` : ""} Disability
        </p>
        <p className="font_s time"></p>
      </div>

      {/* ID Verfication Table */}
      <div className="custom-table-wrapper">
        <Table className="custom-table font_s" borderless responsive>
          {/* Table Heading */}
          <thead>
            <tr>
              <th></th>
              <th>Status</th>
            </tr>
          </thead>
          {/* Table Body */}
          <tbody>
            <tr>
              {/* ID */}
              <td>
                Do you or any of your family members <br />
                have any disability ?
              </td>

              {/* Status */}
              <td>
                <p className="font_blue fw_6">
                  {" "}
                  {data?.disability ? "Yes" : "No"}{" "}
                </p>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </div>
  );
};
/* Component ends */

export default Disability;
