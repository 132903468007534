/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Table } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { EyeFilledBlue } from "../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../../../ui/button/IconButton/IconButton";
/* Import local pages and component ends */

/* Component starts */
const Facilities = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  // DUMMY DATA
  const facilitiesData = [];

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="custom-table-wrapper facilities-table">
      {facilitiesData?.length > 0 && (
        <Table className="custom-table font_s" borderless responsive>
          {/* Table Heading */}
          <thead>
            <tr>
              <th>Date</th>
              <th>Facilities</th>
              <th>Tenant Request</th>
              <th>Start</th>
              <th>End</th>
              <th>Status</th>
              <th>Amount </th>
            </tr>
          </thead>
          {/* Table Body */}
          <tbody>
            {facilitiesData?.map((item) => (
              <tr key={item?.id}>
                {/* Date */}
                <td>{item?.date}</td>
                {/* Facilities */}
                <td>{item?.facility}</td>

                {/* Item Tenant Request */}
                <td>{item?.tenant_req}</td>

                {/* Start Date */}
                <td>{item?.start_date}</td>

                {/* End Date */}
                <td>{item?.end_date}</td>

                {/* Status */}
                <td
                  className={
                    item?.status === "Renewed"
                      ? "renewed"
                      : item?.status === "Pending"
                      ? "pending"
                      : ""
                  }
                >
                  {item?.status}
                </td>

                {/* Amount*/}
                <td>{item?.rent_amt} USD</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      {facilitiesData?.length === 0 && (
        <div className=" text-center">
          <h3 className=" font_m font_yellow fw_6">No Data</h3>
        </div>
      )}
    </div>
  );
};
/* Component ends */

export default Facilities;
