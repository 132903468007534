/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
/* Import local pages and component ends */

/* Component starts */
const DetailSection2 = (props) => {
  /* Props destructuring starts */
  const { type, maintenance } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */

  return (
    <div className="">
      {/* /* Categories and it's Level  */}
      <div className="section section2 font_s fw_5">
        {/* Location */}
        <div className="category-column category">
          <p className="name font_grey">Location</p>
          <p className="value">
            {maintenance?.location?.name}
            {/* // ||
              //   maintenance?.outercategory?.name} */}
          </p>
        </div>

        {/* Category */}
        <div className="category-column category">
          <p className="name font_grey">Category</p>
          <p className="value">
            {maintenance?.category?.category_name ||
              maintenance?.outercategory?.name}
          </p>
        </div>

        {/* Sub Category */}
        {maintenance?.subcategory?.subcategory_name && (
          <div className="category-column sub-category">
            <p className="name font_grey">Sub Category</p>
            <p className="value">
              {maintenance?.subcategory?.subcategory_name || "-"}
            </p>
          </div>
        )}

        {/* Third Level Category */}
        {maintenance?.innercategory?.innercategory_name && (
          <div className="category-column third-company">
            <p className="name font_grey">Inner Level Category</p>
            <p className="value">
              {maintenance?.innercategory?.innercategory_name || "-"}
            </p>
          </div>
        )}

        {/* {maintenance?.status && (
            <div className="category-column third-company">
              <p className="name font_grey">Status</p>
              <p
                className={`value ${statusColorGenerate(maintenance?.status)}`}
              >
                {maintenance?.status || "-"}
              </p>
            </div>
          )} */}
      </div>
    </div>
  );

  return (
    <div className="">
      {type === "Move In" ? (
        /* Maintenance Type */
        <div className="section pair-section section4 font_s fw_6">
          <p className="left font_grey">Type</p>
          <p className="value right">{type}</p>
        </div>
      ) : (
        /* Categories and it's Level */
        <div className="section section2 font_s fw_5">
          {/* Category */}
          <div className="category-column category">
            <p className="name font_grey">Category</p>
            <p className="value">
              {maintenance?.category?.category_name ||
                maintenance?.outercategory?.name}
            </p>
          </div>

          {/* Sub Category */}
          {maintenance?.subcategory?.subcategory_name && (
            <div className="category-column sub-category">
              <p className="name font_grey">Sub Category</p>
              <p className="value">
                {maintenance?.subcategory?.subcategory_name || "-"}
              </p>
            </div>
          )}

          {/* Third Level Category */}
          {maintenance?.innercategory?.innercategory_name && (
            <div className="category-column third-company">
              <p className="name font_grey">Inner Level Category</p>
              <p className="value">
                {maintenance?.innercategory?.innercategory_name || "-"}
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
/* Component ends */

export default DetailSection2;
