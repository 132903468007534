/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import MUI component starts */
/* Import MUI component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import V1 from "./variants/V1";
import V2 from "./variants/V2";
/* Import local pages and component ends */

/* Component starts */
const CustomCurrencyInput = (props) => {
  /* Props destructuring starts */
  const {
    name,
    label,
    handleChange,
    handleBlur = null,
    touched,
    errors,
    value,
    setFieldValue, // setFieldValue function definition of Formik
    setFieldTouched = null, // setFieldTouched function definition of Formik
    required = true,
    prefix = null,
    currency = null,
    variant = "1",
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  if (variant === "1") return <V1 {...props} />;
  if (variant === "2") return <V2 {...props} />;
};
/* Component ends */

export default CustomCurrencyInput;
