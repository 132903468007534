/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import GLOBAL from "../../../../../setup/constants/global";
import { useDispatch } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import { updatePropertySettings } from "../../../../../setup/store/slices/propertySlice";
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../../../setup/store/slices/globalAlertSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import {
  ExcalmatoryYellow,
  WarningRoundedOutlineYellow,
} from "../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomRadioInput from "../../../../ui/input/CustomRadioInput/CustomRadioInput";
import CustomButton from "../../../../ui/button/CustomButton/CustomButton";
/* Import local pages and component ends */

/* Component starts */
const OnlinePaymentOption = (props) => {
  /* Props destructuring starts */
  const { user, settings, property_id } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [canEnableOnlinePayment, setCanEnableOnlinePayment] = useState(true);
  const [acceptOnlinePayment, setAcceptOnlinePayment] = useState(false);
  const [settingChanged, setSettingChanged] = useState(true);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // On updating setting
  const onUpdate = async () => {
    // Creating or collecting payload data to be sent
    const payload = {
      property_id,
      online_payment: acceptOnlinePayment,
    };

    // Backend response. Try, Catch
    try {
      const result = await dispatch(updatePropertySettings(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Settings Updated!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  // Check if the payment gateway is set or not, to enable the online payment
  useEffect(() => {
    setCanEnableOnlinePayment(false);
    if (
      user?.payment_processor_details?.gateway === GLOBAL.PAYMENT_GATEWAY.STRIPE
    ) {
      // TODO: update the condition for another payment gateways
      if (
        user?.payment_processor_details?.stripe_public_key !== "" &&
        user?.payment_processor_details?.stripe_secret_key !== ""
      ) {
        setCanEnableOnlinePayment(true);
      }
    }
  }, [user]);

  // If any setting is changed
  useEffect(() => {
    setSettingChanged(false);
    if (settings?.online_payment !== acceptOnlinePayment) {
      setSettingChanged(true);
    }
  }, [acceptOnlinePayment]);

  useEffect(() => {
    if (settings) {
      setAcceptOnlinePayment(settings?.online_payment);
    }
  }, [settings]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="online-payment-option option">
      {/* Label */}
      <div className="label">
        <p className="font_m fw_5">Online Payments</p>
      </div>

      {/* Setting */}
      <div className="setting">
        {/* Online payment option */}
        {canEnableOnlinePayment && (
          <div className="online-payment-wrapper mb_2">
            <p className="font_s fw_5 font_grey online-payment mb_1">
              Accept online payment ?
            </p>
            <div className="radio-wrapper">
              <CustomRadioInput
                label="Yes"
                className="radio-input"
                isSelected={acceptOnlinePayment}
                onSelecting={() => setAcceptOnlinePayment(true)}
              />
              <CustomRadioInput
                label="No"
                className="radio-input"
                isSelected={!acceptOnlinePayment}
                onSelecting={() => setAcceptOnlinePayment(false)}
              />
            </div>
          </div>
        )}

        {/* Cannot enable online payment warning */}
        {!canEnableOnlinePayment && (
          <p className="warning font_yellow">
            <img
              src={ExcalmatoryYellow}
              alt="Warning Icon"
              className="v_middle"
            />{" "}
            <span className="v_middle font_yellow fw_5 font_s">
              You cannot activate online payment unless payment settings are
              added in profile
            </span>
          </p>
        )}

        {settingChanged && (
          <div className="save-btn-wrapper">
            <CustomButton onClick={onUpdate} className="save-btn" size="s">
              Save
            </CustomButton>
          </div>
        )}
      </div>
    </div>
  );
};
/* Component ends */

export default OnlinePaymentOption;
