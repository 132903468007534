/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import moment from "moment";
import { useLocation } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import GLOBAL from "../../setup/constants/global";
import Header from "./local-component/Header";
import Report from "./local-component/Report";
/* Import local pages and component ends */

// Styles
import "./style.scss";

/* Component starts */
const InternalCommunicationDetail = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const { state } = useLocation();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */

  const logs = [
    {
      id: "1",
      technician: "Janet Jhonson",
      action: "There are many variations of passages of Lorem Ipsum available.",
      updated: "10/08/2023 11:30 AM",
      role: "Technician",
    },
    {
      id: "2",
      technician: "Janet Jhonson",
      action:
        "But the majority have suffered alteration in some form, by injected humour.",
      updated: "10/08/2023 10:30 AM",
      role: "Technician",
    },
  ];

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="internal-communication-detail-wrapper">
      <div className="header-security">
        <Header />
      </div>

      <div className="report-wrapper">
        <div className="detail-box bg_grey">
          <Report task={state} />
        </div>

        <p className="font_m fw_5 my-4">Previously uploaded Notes</p>

        {/* TIME-LINE */}

        {logs?.reverse().map((timeLine, index) => (
          <div className={`detail-box-timeLine mt-3 bg_grey`} key={index}>
            <div className="main-timeline-wrapper">
              <div className="dot">
                <div className="dot-1">
                  <span className="dot-2"></span>
                </div>
                <div className="vl"></div>
              </div>
              <div className="detail">
                <div className="data">
                  <div className="name">
                    <div>
                      <p className="font_s fw_4 font_grey">
                        {timeLine?.role}
                        <span className="font_s fw_4 ">
                          {timeLine?.technician}
                        </span>
                      </p>
                    </div>
                    <div>
                      <p className="font_s fw_4 font_grey">
                        {moment(timeLine.updated).format(DATE_TIME_FORMAT)}
                      </p>
                    </div>
                  </div>
                  <div className="current-status">
                    {timeLine?.action !== undefined && (
                      <p className="font_m fw_4 font_grey">
                        <span className="font_xxs fw_4">
                          {timeLine?.action}
                        </span>
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
/* Component ends */

export default InternalCommunicationDetail;
