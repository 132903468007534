export const initialValues = {
  visitor_first_name: "",
  visitor_last_name: "",
  company_name: "",
  mobile: "",
  car_data: [
    {
      car_make: "",
      car_model: "",
      car_color: "",
      car_plate: "",
      car_plate_issued: "",
    },
  ],

  reason: "",
  docs: [null],
};

export const validations = (values, customPhoneNum, photo) => {
  const errorFields = {};

  /* Validations for Visitor First Name Starts */
  if (values.visitor_first_name === "") {
    errorFields.visitor_first_name = "Visitor First Name is required";
  }

  /* Validations for Visitor First Name Ends */

  /* Validations for Visitor Last Name Starts */
  if (values.visitor_last_name === "") {
    errorFields.visitor_last_name = "Visitor Last Name is required";
  }

  /* Validations for Visitor Last Name Ends */

  /* Validations for Phone Number Starts */
  if (values.mobile === "") {
    errorFields.mobile = "Phone Number is required";
  }
  if (values.mobile && customPhoneNum.current.num.length < 8) {
    errorFields.mobile = "Phone number is not valid";
  }
  /* Validations for Phone Number Ends */

  /* Validations for Reason Starts */
  if (values.reason === "") {
    errorFields.reason = "Reason is required";
  }

  /* Validations for Reason Ends */

  return errorFields;
};

// Field Prop option extractor
export const fieldPropExtractor = (option, index) => {
  if (option?.docs?.length > 0) return option?.docs[index];
  else return undefined;
};
