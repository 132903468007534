/**
 *
 */

import React, { useRef, useState, useEffect } from "react";

/* Import configuration starts */
import { FieldArray, Formik } from "formik";
import { prefill, generateDocStatus } from "../helper";
import { useDispatch } from "react-redux";
import GLOBAL from "../../../../../setup/constants/global";
/* Import configuration ends */

/* Import redux slices component starts */
import { sendClearenceDoc } from "../../../../../setup/store/slices/moveOutSlice";
import {
  showSuccessAlert,
  showErrorAlert,
} from "../../../../../setup/store/slices/globalAlertSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { DeleteGrey } from "../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import { addMore, moveOutValues } from "../helper";
import CustomTextInput from "../../../../ui/input/CustomTextInput/CustomTextInput";
import CustomFileInput from "../../../../ui/input/CustomFileInput/CustomFileInput";
import CustomButton from "../../../../ui/button/CustomButton/CustomButton";
import TextButton from "../../../../ui/button/TextButton/TextButton";
/* Import local pages and component ends */

/* Component starts */
const Report = (props) => {
  /* Props destructuring starts */
  const { clearencedoc, moveoutID, getTenantMoveOutDetail } = props;

  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [docList, setDocList] = useState([]);
  const [initialFormValues, setInitialFormValues] = useState(moveOutValues);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  console.log(clearencedoc, "Clearence Doc list");
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // On uploading file
  const onFileUpload = async (f, index) => {
    // Creating or collecting payload data to be sent
    const payload = {
      move_out_id: moveoutID,
      item_id: clearencedoc[index]._id,
      document: f,
    };
    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      const result = await dispatch(sendClearenceDoc(payload));
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          getTenantMoveOutDetail();
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="moveout-unit-detail-box">
      {/* {clearencedoc?.map((item, index) => (
          <div className="doc-section-each" key={item._id}>
            <div className="key">
              <p className="font_m fw_5">{item?.description}</p>
              <p
                className={`doc-status fw_5 font_xs ${
                  generateDocStatus(item?.status)?.color
                }`}
              >
                {generateDocStatus(item?.status)?.text}
              </p>
            </div>
            <div className="value">
              <CustomFileInput
                label="Document"
                files={item?.document ? item?.document : null}
                customOnChange={(file) => onFileUpload(file, index)}
              />
            </div>

            {item.note && item?.status === "Rejected" && (
              <p className="note w_100 font_s font_red fw_5">{item.note}</p>
            )}
          </div>
        ))} */}

      {clearencedoc?.map((item, index) =>
        item?.status !== "Approved" ? (
          <>
            <div className="util_doc">
              <div className="doc-section-each" key={item._id}>
                <div className="key">
                  <p className="font_m fw_5">{item?.description}</p>
                  <p
                    className={`doc-status fw_5 font_xs ${
                      generateDocStatus(item?.status)?.color
                    }`}
                  >
                    {generateDocStatus(item?.status)?.text}
                  </p>
                </div>
                <div className="value">
                  {item?.status === "Approval Pending" ? (
                    <p className="font_m fw_5">Document is under review !</p>
                  ) : (
                    <CustomFileInput
                      label="Document"
                      files={item?.document ? item?.document : null}
                      customOnChange={(file) => onFileUpload(file, index)}
                    />
                  )}
                </div>

                {item.note && item?.status === "Rejected" && (
                  <p className="note w_100 font_s font_red fw_5">{item.note}</p>
                )}
              </div>
            </div>
          </>
        ) : null
      )}

      {/* <Formik
          onSubmit={onSubmit}
          initialValues={initialFormValues}
          validateOnBlur={true}
          enableReinitialize={true}
        >
          {({
            values,
            handleSubmit,
            handleBlur,
            handleChange,
            setFieldTouched,
            setFieldValue,
            errors,
            touched,
          }) => (
            <form onSubmit={handleSubmit}>
              {docList.map((item, index) => (
                <div className="form-group-section form-wrapper">
                  <div className="form-group">
                    <CustomTextInput
                      name={`name_${item}`}
                      label="Enter Name"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      value={values[`name_${item}`]}
                      touched={touched[`name_${item}`]}
                      errors={errors[`name_${item}`]}
                      required={true}
                    />
                  </div>
                  <div className="form-group">
                    <CustomFileInput
                      name={`document_${item}`}
                      label="Attachment"
                      setFieldTouched={setFieldTouched}
                      setFieldValue={setFieldValue}
                      value={values[`document_${item}`]}
                      touched={touched[`document_${item}`]}
                      errors={errors[`document_${item}`]}
                      formikState={true}
                    />
                  </div>
                </div>
              ))}

              <div className="button-wrapper text-center mt-3">
                <CustomButton type="submit" size="l">
                  Submit
                </CustomButton>

                <TextButton type="button" className="font_m fw_6 mt-2">
                  Cancel
                </TextButton>
              </div>
            </form>
          )}
        </Formik> */}
    </div>
  );
};
/* Component Ends */
export default Report;
