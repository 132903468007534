/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { formatCurrency } from "../../../../../setup/utils/global-helper";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
/* Import local pages and component ends */

/* Component starts */
const PropertyDetail = (props) => {
  /* Props destructuring starts */
  const { unit, isUnitCommercial, isUnitResidential } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const propertyName = unit?.property_id.property_name
    ? unit?.property_id.property_name
    : "-";
  const propertyCity = unit?.property_id.city ? unit?.property_id.city : "-";
  const propertyState = unit?.property_id.state ? unit?.property_id.state : "-";
  const propertyCountry = unit?.property_id.country
    ? unit?.property_id.country
    : "-";
  const address1 = unit?.property_id.address_line1
    ? unit?.property_id.address_line1
    : "-";
  const address2 = unit?.property_id.address_line2
    ? unit?.property_id.address_line2
    : "-";

  const unitName = unit?.unit_name;
  const unitDescription = unit?.unit_description;
  const note = unit?.note;
  const unitType = unit?.unit_type?.name;
  const unitFLoor = unit?.unit_floor;
  const targetRentPrice = unit?.target_rent_price;
  const unitSizeMeasurement = unit?.size_measurement;
  const unitSize = unit?.unit_size;
  const currency = unit?.currency;

  const kitchen = unit?.kitchen;
  const pantry = unit?.pantry;
  const toilet = unit?.toilet;
  const bathroom = unit?.bathroom;
  const livingRoom = unit?.living_room;
  const maidRoom = unit?.maids_room;
  const balcony = unit?.balcony;
  const showRoom = unit?.showroom;
  const terrace = unit?.terrace;
  const laundry = unit?.laundry;
  const offices = unit?.offices;
  const store = unit?.store;
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="property-wrapper">
      <div className="property_details">
        <h3 className="font_xl fw_6 property-detail-heading font_blue">
          Property Details
        </h3>

        <div className="property-detail-body section_body">
          <div className="custom_card property_name">
            <div className="top">
              <p className="font_m font_grey fw_5">Property Name</p>
            </div>
            <div className="bottom">
              <p className="font_m fw_5">{propertyName}</p>
            </div>
          </div>

          <div className="custom_card property_city">
            <div className="top">
              <p className="font_m font_grey fw_5">City</p>
            </div>
            <div className="bottom">
              <p className="font_m fw_5">{propertyCity}</p>
            </div>
          </div>

          <div className="custom_card property_state">
            <div className="top">
              <p className="font_m font_grey fw_5">State</p>
            </div>
            <div className="bottom">
              <p className="font_m fw_5">{propertyState}</p>
            </div>
          </div>

          <div className="custom_card property_country">
            <div className="top">
              <p className="font_m font_grey fw_5">Country</p>
            </div>
            <div className="bottom">
              <p className="font_m fw_5">{propertyCountry}</p>
            </div>
          </div>

          <div className="custom_card property_address1">
            <div className="top">
              <p className="font_m font_grey fw_5">Address</p>
            </div>
            <div className="bottom">
              <p className="font_m fw_5">{address1}</p>
            </div>
          </div>

          {address2 && (
            <div className="custom_card property_address2">
              <div className="top">
                <p className="font_m font_grey fw_5">Address Line 2</p>
              </div>
              <div className="bottom">
                <p className="font_m fw_5">{address2}</p>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="unit-detail">
        <h3 className="font_xl fw_6 property-detail-heading font_blue">
          Unit Details
        </h3>

        <div className="unit_detail_external">
          <div className="custom_card unit_name">
            <div className="top">
              <p className="font_m font_grey fw_5">Unit Name</p>
            </div>
            <div className="bottom">
              <p className="font_m fw_5">{unitName}</p>
            </div>
          </div>

          <div className="custom_card unit_type">
            <div className="top">
              <p className="font_m font_grey fw_5">Unit Type</p>
            </div>
            <div className="bottom">
              <p className="font_m fw_5">{unitType}</p>
            </div>
          </div>

          <div className="custom_card unit_floor">
            <div className="top">
              <p className="font_m font_grey fw_5">Unit Floor</p>
            </div>
            <div className="bottom">
              <p className="font_m fw_5">{unitFLoor}</p>
            </div>
          </div>

          <div className="custom_card unit_size">
            <div className="top">
              <p className="font_m font_grey fw_5">Unit Size</p>
            </div>
            <div className="bottom">
              <p className="font_m fw_5">
                {unitSize} {unitSizeMeasurement}
              </p>
            </div>
          </div>

          {/* <div className="custom_card total_rent">
            <div className="top">
              <p className="font_m font_grey fw_5">Target Rent ({currency})</p>
            </div>
            <div className="bottom">
              <p className="font_m fw_5">
                {formatCurrency(targetRentPrice, currency)}
              </p>
            </div>
          </div> */}
        </div>

        <div className="unit_detail_ammenties">
          {unit?.kitchen > 0 && (
            <div className="custom_card kitchen">
              <div className="top">
                <p className="font_m font_grey fw_5">Kitchen</p>
              </div>
              <div className="bottom">
                <p className="font_m fw_5">{kitchen}</p>
              </div>
            </div>
          )}

          {unit?.bedroom > 0 && (
            <div className="custom_card pantry">
              <div className="top">
                <p className="font_m font_grey fw_5">Bedroom</p>
              </div>
              <div className="bottom">
                <p className="font_m fw_5">{unit?.bedroom}</p>
              </div>
            </div>
          )}

          {unit?.pantry > 0 && (
            <div className="custom_card pantry">
              <div className="top">
                <p className="font_m font_grey fw_5">Pantry</p>
              </div>
              <div className="bottom">
                <p className="font_m fw_5">{pantry}</p>
              </div>
            </div>
          )}

          {unit?.toilet > 0 && (
            <div className="custom_card toilet">
              <div className="top">
                <p className="font_m font_grey fw_5">Toilet</p>
              </div>
              <div className="bottom">
                <p className="font_m fw_5">{toilet}</p>
              </div>
            </div>
          )}

          {unit?.bathroom > 0 && (
            <div className="custom_card bathroom">
              <div className="top">
                <p className="font_m font_grey fw_5">Bathroom</p>
              </div>
              <div className="bottom">
                <p className="font_m fw_5">{bathroom}</p>
              </div>
            </div>
          )}

          {unit?.living_room > 0 && (
            <div className="custom_card livingroom">
              <div className="top">
                <p className="font_m font_grey fw_5">Living Room</p>
              </div>
              <div className="bottom">
                <p className="font_m fw_5">{livingRoom}</p>
              </div>
            </div>
          )}

          {unit?.maids_room > 0 && (
            <div className="custom_card maidroom">
              <div className="top">
                <p className="font_m font_grey fw_5">Maid's Room</p>
              </div>
              <div className="bottom">
                <p className="font_m fw_5">{maidRoom}</p>
              </div>
            </div>
          )}

          {unit?.balcony > 0 && (
            <div className="custom_card balcony">
              <div className="top">
                <p className="font_m font_grey fw_5">Balcony</p>
              </div>
              <div className="bottom">
                <p className="font_m fw_5">{balcony}</p>
              </div>
            </div>
          )}

          {unit?.showroom > 0 && (
            <div className="custom_card showroom">
              <div className="top">
                <p className="font_m font_grey fw_5">Show Room</p>
              </div>
              <div className="bottom">
                <p className="font_m fw_5">{showRoom}</p>
              </div>
            </div>
          )}

          {unit?.terrace > 0 && (
            <div className="custom_card terrace">
              <div className="top">
                <p className="font_m font_grey fw_5">Terrace</p>
              </div>
              <div className="bottom">
                <p className="font_m fw_5">{terrace}</p>
              </div>
            </div>
          )}

          {unit?.laundry > 0 && (
            <div className="custom_card laundry">
              <div className="top">
                <p className="font_m font_grey fw_5">Laundry</p>
              </div>
              <div className="bottom">
                <p className="font_m fw_5">{laundry}</p>
              </div>
            </div>
          )}

          {unit?.offices > 0 && (
            <div className="custom_card offices">
              <div className="top">
                <p className="font_m font_grey fw_5">Office</p>
              </div>
              <div className="bottom">
                <p className="font_m fw_5">{offices}</p>
              </div>
            </div>
          )}

          {unit?.store > 0 && (
            <div className="custom_card store">
              <div className="top">
                <p className="font_m font_grey fw_5">Store</p>
              </div>
              <div className="bottom">
                <p className="font_m fw_5">{store}</p>
              </div>
            </div>
          )}
        </div>

        <div className="unit_detail_external my-2">
          {unitDescription && (
            <div className="custom_card unit_description">
              <div className="top">
                <p className="font_m font_grey fw_5">Description</p>
              </div>
              <div className="bottom">
                <p className="font_m fw_5">{unitDescription}</p>
              </div>
            </div>
          )}

          {note && (
            <div className="custom_card note">
              <div className="top">
                <p className="font_m font_grey fw_5">Note</p>
              </div>
              <div className="bottom">
                <p className="font_m fw_5">{note}</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
/* Component ends */

export default PropertyDetail;
