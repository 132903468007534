/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import moment from "moment";
import GLOBAL from "../../../../../setup/constants/global";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Table } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */

import {
  DocFilledWhite,
  DocumentGreen,
} from "../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import FileViewerModal from "../../../../ui/modal/FileViewerModal/FileViewerModal";
import IconButton from "../../../../ui/button/IconButton/IconButton";
/* Import local pages and component ends */

/* Component starts */
const Vehicles = (props) => {
  /* Props destructuring starts */
  const { data, index } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [file, setFile] = useState();
  const [showFileViewer, setShowFileViewer] = useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  // On viewing file
  const onViewFile = (item) => {
    setFile(item);
    setShowFileViewer(true);
  };
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="vehicle-wrapper">
      <p className="font_l">{index} - Vehicles</p>

      {/* ID Verfication Table */}
      <div className="custom-table-wrapper">
        {data?.length > 0 && (
          <Table className="custom-table font_s" borderless responsive>
            {/* Table Heading */}
            <thead>
              <tr>
                <th>Make</th>
                <th>Model</th>
                <th>Color</th>
                <th>Plate Number</th>
                <th>Place Issued From</th>
                <th>Image</th>
              </tr>
            </thead>
            {/* Table Body */}
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  {/* Make */}
                  <td>{item?.make}</td>
                  {/* Model */}
                  <td>{item?.model}</td>
                  {/* Color */}
                  <td>{item?.color}</td>
                  {/* Plate Number */}
                  <td>{item?.plate_number}</td>
                  {/* Place Issued */}
                  <td>{item?.plate_issued_from}</td>
                  {/* ID Image */}
                  <td className="text-start txt-nowrap">
                    <IconButton onClick={() => onViewFile(item?.image)}>
                      <img src={DocumentGreen} alt="View ID" />
                    </IconButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}

        {data?.length === 0 && (
          <div className=" text-center">
            <h3 className=" font_m font_yellow fw_6">No Data</h3>
          </div>
        )}
      </div>

      {/* Modals */}
      {/* File Viewer Modal */}
      <FileViewerModal
        show={showFileViewer}
        file={file?.url}
        type={file?.type}
        onClose={() => {
          setShowFileViewer(false);
          setFile({});
        }}
      />
    </div>
  );
};
/* Component ends */

export default Vehicles;
