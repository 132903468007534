/**
 *
 */

import React from "react";

/* Import configuration starts */
import moment from "moment";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Table } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import GLOBAL from "../../../../../setup/constants/global";
import Pagination from "../../../../ui/other/Pagination/Pagination";
/* Import local pages and component ends */

// Styles
import "./style.scss";

/* Component starts */
const TenantFoundTable = (props) => {
  /* Props destructuring starts */
  const { foundList, totalCount, currentPage, setCurrentPage } = props;

  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */

  return (
    <>
      <div className="custom-table-wrapper tenant-found-table">
        {foundList?.length > 0 && (
          <Table className="custom-table font_s" borderless responsive>
            {/* Table Heading */}
            <thead>
              <tr className="text-wrap">
                <th>Serial No</th>
                <th>Item ID</th>
                <th>Item</th>
                <th>Date Reported</th>
              </tr>
            </thead>
            {/* Table Body */}
            <tbody>
              {foundList?.map((item, index) => (
                <tr key={item?._id}>
                  {/* ID */}
                  <td>{index + 1}</td>

                  {/* Item ID */}
                  <td>{item?.lostfound_id}</td>

                  {/* Item Name */}
                  <td>{item?.item_name}</td>

                  {/* Date  */}
                  <td>{moment(item?.date_time).format(DATE_FORMAT)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}

        {foundList?.length === 0 && (
          <div className=" text-center">
            <h3 className=" font_m font_yellow fw_6">No Data</h3>
          </div>
        )}
      </div>

      {/* PAGINATION */}
      {foundList?.length > 0 && (
        <Pagination
          itemsPerPage="10"
          totalItems={totalCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
    </>
  );
};
/* Component Ends */
export default TenantFoundTable;
