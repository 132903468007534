/**
 * @author Aflak Arshi
 *
 * slice to manage settings
 */

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../axios";
import { API_URL } from "../../config/devKeys";
import { setLoading } from "./unpersistedSlice";

const initialState = {
  allSettings: {
    perUnitPrice: 10,
    supportEmail: "support@gmail.com",
    supportMobile: "+88 123 456 789",
    about: "",
    versionCode: 0,
    androidUrl: "",
    iOSUrl: "",
    awsRegion: "",
    awsPoolId: "",
    awsClientId: "",
    stripePublicKey: "",
    stripeCallbackURL: "",
    terms: "",
  },
};

export const fetchSetting = createAsyncThunk(
  "setting/list",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.get(API_URL + "/setting/list");
      dispatch(setLoading(false));
      if (payload?.init) {
        const {
          androidUrl,
          iOSUrl,
          perUnitPrice,
          stripePublicKey,
          stripeCallbackURL,
          versionCode,
          awsRegion,
          awsPoolId,
          awsClientId,
          terms,
        } = data.data;
        return {
          androidUrl,
          iOSUrl,
          perUnitPrice,
          stripePublicKey,
          stripeCallbackURL,
          versionCode,
          awsRegion,
          awsPoolId,
          awsClientId,
          terms,
        };
      }
      if (payload?.about) {
        const { about } = data.data;
        return { ...getState().setting.allSettings, about };
      }
      if (payload?.support) {
        const { supportEmail, supportMobile } = data.data;
        return {
          ...getState().setting.allSettings,
          supportEmail,
          supportMobile,
        };
      }
      return data.data;
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const settingSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {
    clearAbout: (state, action) => {
      delete state.allSettings.about;
    },
    clearSupport: (state, action) => {
      delete state.allSettings.supportEmail;
      delete state.allSettings.supportMobile;
    },
  },
  extraReducers: {
    [fetchSetting.fulfilled]: (state, action) => {
      state.allSettings = action.payload;
    },
    [fetchSetting.rejected]: (state, action) => {
      state.allSettings = initialState;
    },
  },
});

export const { clearAbout, clearSupport } = settingSlice.actions;

export const getSetting = (state) => state.setting.allSettings;

export default settingSlice.reducer;
