/**
 *
 */

import React, { useEffect, useState } from "react";

/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Modal } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import MUI component starts */
/* Import MUI component ends */

/* Import image and SVG starts */
import {
  NoProfPicSet,
  WarningRoundedOutlineYellow,
} from "../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
import CustomTextArea from "../../../ui/input/CustomTextArea/CustomTextArea";
import CustomSelectInput from "../../../ui/input/CustomSelectInput/CustomSelectInput";
/* Import local pages and component ends */

import "./style.scss";
import { getUserTheme } from "../../../../setup/store/slices/authSlice";
import { useSelector } from "react-redux";

/* Component starts */
const ReassignManagerModal = (props) => {
  /* Props destructuring starts */
  const {
    showModal,
    title = "Reassign Ticket",
    size = "lg",
    onCancel,
    actionWithNote,
    inputPlaceholder = "Comment",
    showError = true,
    managers,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [selectedManager, setSelectedManager] = useState();
  const [note, setNote] = useState("");
  const [error, setError] = useState();
  //   const [selected]
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const userTheme = useSelector(getUserTheme);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const thirdPTManagerAvailable = managers && managers?.length > 0;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // Validating not text
  const validation = () => {
    setError("");
    if (note === "") {
      setError("Please type some comment");
      return true;
    }
    if (note !== "" && note.length < 10) {
      setError("Minimum ten characters are required");
      return true;
    }

    return false;
  };

  // On Action
  const onAction = () => {
    if (showError && validation()) return;
    setNote("");
    actionWithNote({ ...selectedManager, comment: note });
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    setSelectedManager(managers?.length > 0 ? managers[0] : {});
  }, [managers]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <Modal
      size={size}
      centered
      show={showModal}
      className="reassign-manager-modal-wrapper"
    >
      <Modal.Body
        className={`${
          userTheme === "dark"
            ? "bg_darkTheme"
            : "bg_lightTheme font_lightTheme"
        }`}
      >
        <div className="modal-header-custom">
          <h2
            className={`text-center ${
              userTheme !== "dark" && "font_blue"
            } font_white font_xl`}
          >
            {title}
          </h2>
        </div>
        <div className="modal-body-custom note-wrapper">
          {thirdPTManagerAvailable ? (
            <div className="manager-available">
              <CustomSelectInput
                label="Managers"
                valueExtractor={(item) =>
                  `${item?.first_name} ${item?.last_name}`
                }
                setValue={setSelectedManager}
                options={managers}
                value={selectedManager}
                formikState={false}
                emptyOption={false}
              />
              {managers?.length > 0 && (
                <div className="userProfile">
                  <div className="userImage">
                    <img
                      src={selectedManager?.myphoto?.url || NoProfPicSet}
                      alt="Profile"
                    />
                  </div>
                  <div className="userData">
                    <div className="details">
                      <p
                        className={`key font_s ${
                          userTheme !== "dark" ? "font_grey" : "font_white"
                        } `}
                      >
                        Email:
                      </p>
                      <p
                        className={`value font_s ${
                          userTheme !== "dark" ? "font_grey" : "font_white"
                        } `}
                      >
                        {selectedManager?.email}
                      </p>
                    </div>

                    <div className="details">
                      <p
                        className={`key font_s ${
                          userTheme !== "dark" ? "font_grey" : "font_white"
                        } `}
                      >
                        Mobile:
                      </p>
                      <p
                        className={`value font_s ${
                          userTheme !== "dark" ? "font_grey" : "font_white"
                        } `}
                      >
                        {selectedManager?.mobile}
                      </p>
                    </div>

                    {selectedManager?.profession && (
                      <div className="details">
                        <p
                          className={`key font_s ${
                            userTheme !== "dark" ? "font_grey" : "font_white"
                          } `}
                        >
                          Profession:
                        </p>
                        <p
                          className={`value font_s ${
                            userTheme !== "dark" ? "font_grey" : "font_white"
                          } `}
                        >
                          {selectedManager?.profession}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              )}
              <CustomTextArea
                label={inputPlaceholder}
                className="note mt-4"
                value={note}
                setValue={setNote}
                required={false}
              />

              {error !== "" && (
                <p className="font_xs error font_red fw_5">{error}</p>
              )}
            </div>
          ) : (
            <div className="manager-not-available">
              <div className="warning-icon-wrapper">
                <img src={WarningRoundedOutlineYellow} alt="Warning" />
              </div>
              <h2 className="font_m fw_6 font_yellow text-center">
                No active Third Party Manager available to reassign this ticket.
              </h2>
            </div>
          )}
        </div>
        <div className="modal-footer-custom">
          <CustomButton size="m" variant="red" onClick={onCancel}>
            Close
          </CustomButton>
          <CustomButton
            variant={thirdPTManagerAvailable ? "primary" : "darkgrey"}
            size="m"
            onClick={() => {
              if (thirdPTManagerAvailable) {
                onAction();
              }
            }}
          >
            Reassign
          </CustomButton>
        </div>
      </Modal.Body>
    </Modal>
  );
};
/* Component ends */

export default ReassignManagerModal;
