/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useDispatch, useSelector } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import { fetchUser, getIsTENANT } from "../../setup/store/slices/authSlice";

/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

import "./style.scss";

/* Import local pages and component starts */
import ProfilePhoto from "./local-components/ProfilePhoto";
import EditProfileForm from "./local-components/EditProfileForm";
import BreadCrumb from "../../components/ui/other/BreadCrumb/BreadCrumb";
/* Import local pages and component ends */

/* Component starts */
const EditProfilePage = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const isTenant = useSelector(getIsTENANT);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  // Fetching current user on inititial load
  useEffect(() => {
    dispatch(fetchUser());
  }, []);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      {!isTenant && <BreadCrumb type="edit_myProfile_page" />}
      <div className="edit-profile-page-wrapper">
        <div className="container_sm">
          <h2 className="heading text-center font_xxl mb-5">Edit Profile</h2>
          <ProfilePhoto />
          <EditProfileForm />
        </div>
      </div>
    </>
  );
};
/* Component ends */

export default EditProfilePage;
