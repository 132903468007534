/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import GLOBAL from "../../../../setup/constants/global";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
/* Import local pages and component ends */

/* Component starts */
const StatusHighLight = (props) => {
  /* Props destructuring starts */
  const { status } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      {/* Status Pending */}
      {status === GLOBAL.USER_STATUS.PENDING_INVITATION && (
        <span className="font_blue">Pending Invitation</span>
      )}
      {/* Status Pending Approval */}
      {status === GLOBAL.USER_STATUS.PENDING_APPROVAL && (
        <span className="font_blue">Pending Approval</span>
      )}
      {/* Status Suspended */}
      {status === GLOBAL.USER_STATUS.SUSPENDED && (
        <span className="font_yellow">Suspended</span>
      )}
      {/* Status Active */}
      {status === GLOBAL.USER_STATUS.ACTIVE && (
        <span className="font_green">Active</span>
      )}
      {/* Status Deactivated */}
      {status === GLOBAL.USER_STATUS.DEACTIVATED && (
        <span className="font_grey">Deactivated</span>
      )}
      {/* Status Rejected */}
      {status === GLOBAL.USER_STATUS.REJECTED && (
        <span className="font_red">Rejected</span>
      )}
      {/* Status Expired */}
      {status === GLOBAL.USER_STATUS.EXPIRED && (
        <span className="font_red">Invitation Expired</span>
      )}
      {status === GLOBAL.USER_STATUS.CANCELLED && (
        <span className="font_red">Invitation Cancelled</span>
      )}
    </>
  );
};
/* Component ends */

export default StatusHighLight;
