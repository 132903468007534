export const getAmmenities = (unit) => {
  let ammenities = [];
  if (unit.balcony) {
    ammenities.push(`${unit.balcony} Balcony`);
  }
  if (unit.bathroom) {
    ammenities.push(`${unit.bathroom} Bathroom`);
  }
  if (unit.kitchen) {
    ammenities.push(`${unit.kitchen} Kitchen`);
  }
  if (unit.laundry) {
    ammenities.push(`${unit.laundry} Laundry`);
  }
  if (unit.living_room) {
    ammenities.push(`${unit.living_room} Living Room`);
  }
  if (unit.maids_room) {
    ammenities.push(`${unit.maids_room} Maid's Room`);
  }
  if (unit.offices) {
    ammenities.push(`${unit.offices} Office`);
  }
  if (unit.pantry) {
    ammenities.push(`${unit.pantry} Pantry`);
  }
  if (unit.showroom) {
    ammenities.push(`${unit.showroom} Showroom`);
  }
  if (unit.store) {
    ammenities.push(`${unit.store} Store`);
  }
  if (unit.terrace) {
    ammenities.push(`${unit.terrace} Terrace`);
  }
  if (unit.toilet) {
    ammenities.push(`${unit.toilet} Toilet`);
  }
  return ammenities.join(", ");
};

export const renewalStatus = (renewal) => {
  let status = {
    text: "",
    color: "",
  };
  switch (renewal) {
    case "renewal_pending_approval":
    case "pending_tenant_approval":
      status.text = "Pending Approval";
      status.color = "font_yellow";
      break;

    case "pending_revision_approval":
      status.text = "Revision request is under review";
      status.color = "font_yellow";
      break;

    case "pending_contract_dispatch":
      status.text = "Pending Contract";
      status.color = "font_yellow";
      break;

    case "pending_tenant_signature":
      status.text = "Pending Contract Approval";
      status.color = "font_yellow";
      break;

    case "pending-contract-review":
      status.text = "Contract Under Review";
      status.color = "font_yellow";
      break;

    case "pending_tenant_info":
      if (renewal?.application_status === "pending-application-review") {
        status.text = "Pending Review";
      } else if (renewal?.application_status === "reservor-data-rejected") {
        status.text = "Application data rejected";
      } else {
        status.text = "Pending Information Update";
      }
      status.color = "font_yellow";
      break;

    default:
      status.text = renewal;
  }
  return status;
};
