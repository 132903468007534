import { validEmail } from "../../../../setup/utils/validations";

export const initialValues = {
  email: "",
  mobile: "",
};

export const validation = (values, customPhoneNum) => {
  const error = {};

  if (values.email === "" && customPhoneNum.current.num === "") {
    if (values.email === "") {
      error.email = "Email or Phone is required";
    }
    if (customPhoneNum.current.num === "") {
      error.mobile = "Email or Phone is required";
    }
  }
  if (values.email && !validEmail(values.email)) {
    error.email = "Email is not valid";
  }
  if (
    customPhoneNum.current.num !== "" &&
    customPhoneNum.current.num.length < 8
  ) {
    error.mobile = "Phone number is not valid";
  }
  return error;
};
