export const formValues = {
  department: "",
  contact: "",
};

export const validations = (values) => {
  const errorFields = {};

  // Department Starts
  if (values.department === "") {
    errorFields.department = "Department is required";
  }
  // Department Ends

  // Contact Starts
  if (values.contact === "") {
    errorFields.contact = "Contact is required";
  }
  // Contact Ends

  return errorFields;
};
