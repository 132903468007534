/**
 *
 */

import React, { useState } from "react";

/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Modal } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import MUI component starts */
/* Import MUI component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
import CustomTextArea from "../../../ui/input/CustomTextArea/CustomTextArea";
/* Import local pages and component ends */

import "./style.scss";
import { getUserTheme } from "../../../../setup/store/slices/authSlice";
import { useSelector } from "react-redux";

/* Component starts */
const ApplicationDataRejectionModal = (props) => {
  /* Props destructuring starts */
  const {
    showModal,
    title,
    size = "lg",
    onCancel,
    onRejectWithNote,
    description = null,
    btnText,
    appLabel,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [note, setNote] = useState("");
  const [error, setError] = useState();
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const userTheme = useSelector(getUserTheme);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // Validating not text
  const validation = () => {
    setError("");
    if (note === "") {
      setError("Please type reason for rejection");
      return true;
    }
    if (note !== "" && note.length < 10) {
      setError("Minimum ten characters are required");
      return true;
    }

    return false;
  };

  // On rejection
  const onReject = () => {
    if (validation()) return;
    onRejectWithNote(note);
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <Modal
      size={size}
      centered
      show={showModal}
      className="application-rejection-modal-wrapper"
    >
      <Modal.Body
        className={`${userTheme === "dark" ? "bg_darkTheme" : "bg_lightTheme"}`}
      >
        <div className="modal-header-custom">
          <h2
            className={`text-center ${
              userTheme !== "dark" && "font_blue"
            } font_white font_l`}
          >
            {title}
          </h2>
          {description && (
            <p
              className={`description ${userTheme !== "dark" && "font_white"}`}
            >
              {description}
            </p>
          )}
        </div>
        <div
          //className="modal-body-custom note-wrapper"
          className={`modal-body-custom note-wrapper ${
            (userTheme === "light" || userTheme === "automatic") && "otherTheme"
          } `}
        >
          <CustomTextArea
            label={appLabel ? appLabel : "Reason for rejection"}
            className="note"
            value={note}
            setValue={setNote}
          />

          {error !== "" && (
            <p className="font_xs error font_red fw_5">{error}</p>
          )}
        </div>
        <div className="modal-footer-custom">
          <CustomButton size="m" variant="primary" onClick={onCancel}>
            Close
          </CustomButton>
          <CustomButton size="m" variant="red" onClick={onReject}>
            {btnText ? btnText : "Reject"}
          </CustomButton>
        </div>
      </Modal.Body>
    </Modal>
  );
};
/* Component ends */

export default ApplicationDataRejectionModal;
