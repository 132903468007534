/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import T1Settings from "./T1Settings";
import T2Settings from "./T2Settings";
import T3Settings from "./T3Settings";
/* Import local pages and component ends */

import "../style.scss";
import "../margin_spacing_style.scss";

/* Component starts */
const Settings = (props) => {
  /* Props destructuring starts */
  const { user, settings, property, getPropertySettingsDetail } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  // console.log(property)
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="property-setting-module-wrapper container_md">
      {property?.mgmt_model === 0 && (
        <T1Settings
          user={user}
          settings={settings}
          getPropertySettingsDetail={getPropertySettingsDetail}
        />
      )}
      {property?.mgmt_model === 1 && (
        <T2Settings user={user} settings={settings} />
      )}
      {property?.mgmt_model === 2 && (
        <T3Settings user={user} settings={settings} />
      )}
    </div>
  );
};
/* Component ends */

export default Settings;
