/**
 *
 */

import React, { useState, useEffect, useRef } from "react";

/* Import configuration starts */
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import { initialValues, loadInitialValue, validations } from "./helper";
import GLOBAL from "../../../../setup/constants/global";
/* Import configuration ends */

/* Import redux slices component starts */
import { updateProperty } from "../../../../setup/store/slices/propertySlice";
import {
  clearCountries,
  fetchCitiesByStateId,
  fetchCountries,
  fetchStatesByCountryId,
  getCities,
  getCountries,
  getStates,
} from "../../../../setup/store/slices/countrySlice";
import {
  fetchSetting,
  getSetting,
} from "../../../../setup/store/slices/settingSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomFileInput from "../../../ui/input/CustomFileInput/CustomFileInput";
import CustomTextInput from "../../../ui/input/CustomTextInput/CustomTextInput";
import CustomSelectInput from "../../../ui/input/CustomSelectInput/CustomSelectInput";
import CustomTextArea from "../../../ui/input/CustomTextArea/CustomTextArea";
import PropertyImagesStripe from "../PropertyImagesStripe/PropertyImagesStripe";
import TextButton from "../../../ui/button/TextButton/TextButton";
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
import SuccessModal from "../../../ui/modal/SuccessModal/SuccessModal";
import ErrorModal from "../../../ui/modal/ErrorModal/ErrorModal";
import ConfirmationModal from "../../../ui/modal/ConfirmationModal/ConfirmationModal";
import LocationPickerModal from "../../../ui/modal/LocationPickerModal/LocationPickerModal";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const EditProperty = (props) => {
  /* Props destructuring starts */
  const { underCommunity, onSuccess, onCancel, property } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [initialFormValues, setInitialFormValues] = useState(initialValues);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [modalDesc, setModalDesc] = useState("");
  const [locationPicker, setLocationPicker] = useState(false);

  // useRef
  const property_title_deed = useRef(null);
  const affection_plan = useRef(null);
  const formikRef = useRef(null);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();

  // Redux Selector
  const countries = useSelector(getCountries);
  const states = useSelector(getStates);
  const cities = useSelector(getCities);
  const setting = useSelector(getSetting);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  // Form validations
  const formValidation = () => {};

  // On submitting form
  const onSubmit = async (values) => {
    // Collecting or creating payload data to be sent
    const payload = {
      id: property?._id,
      property_name: values.property_name,
      address_line1: values.address_line1,
      address_line2: values.address_line2,
      latitude: parseFloat(values.latitude) + "",
      longitude: parseFloat(values.longitude) + "",
      description: values.description,
      property_permit: values.property_permit,
      plot_number: values.plot_number,
      electricity_id: values.electricity_id,
      sewerage_id: values.sewerage_id,
      water_id: values.water_id,
      gas_id: values.gas_id,
      colling_id: values.colling_id,
      heating_id: values.heating_id,
      city: selectedCity?.name || "",
      state: selectedState?.name || "",
      country: selectedCountry?.name || "",
    };

    if (property_title_deed?.current && !property_title_deed?.current?.url) {
      payload["property_title_deed"] = property_title_deed.current;
    }

    if (affection_plan?.current && !affection_plan?.current?.url) {
      payload["affection_plan"] = affection_plan.current;
    }

    // Backend response. Try, Catch
    try {
      const result = await dispatch(updateProperty(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setModalDesc(result.payload);
          setShowErrorModal(true);
          break;

        case GLOBAL.FULFILLED:
          setModalDesc("Property has been updated successfully");
          setShowSuccessModal(true);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // Loading all countries
  const loadCountries = async () => {
    await dispatch(fetchCountries());
  };

  //Loading all states
  const loadStates = async () => {
    if (selectedCountry !== null) {
      setSelectedState(null);
      setSelectedCity(null);
      await dispatch(
        fetchStatesByCountryId({ country_id: selectedCountry.id })
      );
    }
  };

  // Loading all cities
  const loadCities = async () => {
    if (selectedState != null) {
      setSelectedCity(null);
      await dispatch(fetchCitiesByStateId({ state_id: selectedState.id }));
    }
  };

  const onPickLocation = (coords) => {
    formikRef.current.setFieldValue("latitude", coords.lat);
    formikRef.current.setFieldValue("longitude", coords.lng);
    setLocationPicker(false);
  };

  const getCurrentCoords = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        // Success Callback
        (p) => {
          formikRef.current.setFieldValue("latitude", p.coords.latitude);
          formikRef.current.setFieldValue("longitude", p.coords.longitude);
        },
        // Error Callback
        () => {
          formikRef.current.setFieldValue("latitude", "");
          formikRef.current.setFieldValue("longitude", "");
          setModalDesc(
            "Location is off, please turn it on to get current location coordinate"
          );
          setShowErrorModal(true);
        }
      );
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  // Loading all countries and fetching user roles initially
  useEffect(() => {
    loadCountries();
    dispatch(fetchSetting());
  }, []);

  // Setting selected country after loading countries
  useEffect(() => {
    if (countries?.length > 0 && property) {
      let defaultCountry;

      defaultCountry = countries?.filter(
        (country) => country?.name === property?.country
      )[0];

      setSelectedCountry(defaultCountry);
    }
  }, [countries, property]);

  // Loading states accoring to country selected
  useEffect(() => {
    loadStates();
  }, [selectedCountry]);

  // Setting selected state after loading state
  useEffect(() => {
    if (states?.length > 0 && property) {
      let defaultState = states?.filter(
        (state) => state?.name === property?.state
      )[0];

      setSelectedState(defaultState || states[0]);
    }
  }, [states, property]);

  // Loading cities accoring to state selected
  useEffect(() => {
    loadCities();
  }, [selectedState]);

  // Setting selected city after loading cities
  useEffect(() => {
    if (cities?.length > 0 && property) {
      let defaultCity = cities?.filter(
        (city) => city?.name === property?.city
      )[0];

      setSelectedCity(defaultCity || cities[0]);
    }
  }, [cities, property]);

  // Setting form initial value according to current seleted property
  useEffect(() => {
    if (property) {
      setInitialFormValues(loadInitialValue(property));
    }
    affection_plan.current = property?.affection_plan || null;
    property_title_deed.current = property?.property_title_deed || null;
  }, [property]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="edit-property-module-wrapper">
      <h2 className="heading text-center font_xxl mb-5">Edit Property</h2>

      {/* Edit Property Form */}
      <Formik
        innerRef={formikRef}
        onSubmit={onSubmit}
        initialValues={initialFormValues}
        validate={(values) => validations(values)}
        validateOnBlur={true}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="form-wrapper">
              {/* Property Name */}
              <div className="form-group form-group-full">
                <CustomTextInput
                  name="property_name"
                  label="Property Name"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values.property_name}
                  errors={errors.property_name}
                  touched={touched.property_name}
                />
              </div>

              {/* If the property is under community then not showing the address field */}
              {!underCommunity && (
                <>
                  {/* Address Line 1 */}
                  <div className="form-group">
                    <CustomTextInput
                      name="address_line1"
                      label="Address"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      value={values.address_line1}
                      errors={errors.address_line1}
                      touched={touched.address_line1}
                    />
                  </div>

                  {/* Address Line 2 */}
                  <div className="form-group">
                    <CustomTextInput
                      name="address_line2"
                      label="Address Line 2"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      value={values.address_line2}
                      errors={errors.address_line2}
                      touched={touched.address_line2}
                      required={false}
                    />
                  </div>

                  {/* Coordinates */}
                  <div className="form-group form-group-flex coordinate-fields">
                    <div className="sub-from-group">
                      <CustomTextInput
                        name="latitude"
                        label="Latitude"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        value={values.latitude}
                        errors={errors.latitude}
                        touched={touched.latitude}
                        required={false}
                      />
                    </div>
                    <div className="sub-from-group">
                      <CustomTextInput
                        name="longitude"
                        label="Longitude"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        value={values.longitude}
                        errors={errors.longitude}
                        touched={touched.longitude}
                        required={false}
                      />
                    </div>
                    <div className="w_100 open-map">
                      <TextButton
                        onClick={() => setLocationPicker(true)}
                        className="font_s fw_6"
                      >
                        Open Map
                      </TextButton>
                      <TextButton
                        onClick={() => getCurrentCoords()}
                        className="font_s fw_6"
                      >
                        Current Location
                      </TextButton>
                    </div>
                  </div>

                  {/* Select field for Cities */}
                  <div className="form-group">
                    <CustomSelectInput
                      name="city"
                      label="City"
                      handleBlur={handleBlur}
                      onChange={handleChange}
                      valueExtractor={(item) => item?.name}
                      setValue={setSelectedCity}
                      options={cities}
                      value={selectedCity}
                      formikState={false}
                      emptyOption={false}
                    />
                  </div>

                  {/* Select field for States */}
                  <div className="form-group">
                    <CustomSelectInput
                      name="state"
                      label="State"
                      handleBlur={handleBlur}
                      onChange={handleChange}
                      valueExtractor={(item) => item?.name}
                      setValue={setSelectedState}
                      options={states}
                      value={selectedState}
                      formikState={false}
                      emptyOption={false}
                    />
                  </div>

                  {/* Select field for Countries */}
                  <div className="form-group">
                    <CustomSelectInput
                      name="country"
                      label="Country"
                      handleBlur={handleBlur}
                      onChange={handleChange}
                      valueExtractor={(item) => item?.name}
                      setValue={setSelectedCountry}
                      options={countries}
                      value={selectedCountry}
                      formikState={false}
                      emptyOption={false}
                    />
                  </div>
                </>
              )}

              {/* Description */}
              <div className="form-group form-group-full">
                <CustomTextArea
                  name="description"
                  label="Description"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values.description}
                  errors={errors.description}
                  touched={touched.description}
                  required={false}
                />
              </div>

              {/* Property Title Deed */}
              <div className="form-group">
                <CustomFileInput
                  name="property_title_deed"
                  label="Property Title Deed"
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  files={property_title_deed}
                  errors={errors.property_title_deed}
                  touched={touched.property_title_deed}
                  required={false}
                  formikState={false}
                />
              </div>

              {/* Property Permit */}
              <div className="form-group">
                <CustomTextInput
                  name="property_permit"
                  label="Property Permit"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values.property_permit}
                  errors={errors.property_permit}
                  touched={touched.property_permit}
                  required={false}
                />
              </div>

              {/* Site Plan */}
              <div className="form-group">
                <CustomFileInput
                  name="affection_plan"
                  label="Site Plan"
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  files={affection_plan}
                  errors={errors.affection_plan}
                  touched={touched.affection_plan}
                  required={false}
                  formikState={false}
                />
              </div>

              {/* Plot Number */}
              <div className="form-group">
                <CustomTextInput
                  name="plot_number"
                  label="Plot Number"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values.plot_number}
                  errors={errors.plot_number}
                  touched={touched.plot_number}
                  required={false}
                />
              </div>

              {/* Electricity ID */}
              <div className="form-group">
                <CustomTextInput
                  name="electricity_id"
                  label="Electricity ID"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values.electricity_id}
                  errors={errors.electricity_id}
                  touched={touched.electricity_id}
                  required={false}
                />
              </div>

              {/* Sewerage ID */}
              <div className="form-group">
                <CustomTextInput
                  name="sewerage_id"
                  label="Sewerage ID"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values.sewerage_id}
                  errors={errors.sewerage_id}
                  touched={touched.sewerage_id}
                  required={false}
                />
              </div>

              {/* Water ID */}
              <div className="form-group">
                <CustomTextInput
                  name="water_id"
                  label="Water ID"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values.water_id}
                  errors={errors.water_id}
                  touched={touched.water_id}
                  required={false}
                />
              </div>

              {/* Gas ID */}
              <div className="form-group">
                <CustomTextInput
                  name="gas_id"
                  label="Gas ID"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values.gas_id}
                  errors={errors.gas_id}
                  touched={touched.gas_id}
                  required={false}
                />
              </div>

              {/* Cooling ID */}
              <div className="form-group">
                <CustomTextInput
                  name="colling_id"
                  label="Cooling ID"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values.colling_id}
                  errors={errors.colling_id}
                  touched={touched.colling_id}
                  required={false}
                />
              </div>

              {/* Heating ID */}
              <div className="form-group">
                <CustomTextInput
                  name="heating_id"
                  label="Heating ID"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values.heating_id}
                  errors={errors.heating_id}
                  touched={touched.heating_id}
                  required={false}
                />
              </div>

              <PropertyImagesStripe property={property} />
            </div>

            <div className="button-wrapper text-center">
              <CustomButton type="submit" size="l">
                Update
              </CustomButton>
              <TextButton onClick={onCancel} className="font_m fw_6">
                Cancel
              </TextButton>
            </div>
          </form>
        )}
      </Formik>

      {/* Modals */}
      {/* Confirmation Modal */}
      <ConfirmationModal
        showModal={showConfirmationModal}
        description={modalDesc}
        title="Confirm Delete"
        onClose={() => setShowConfirmationModal(false)}
      />

      {/* Success Modal */}
      <SuccessModal
        showModal={showSuccessModal}
        description={modalDesc}
        title="Successfully Updated"
        onClose={() => {
          onSuccess();
          setShowSuccessModal(false);
        }}
      />

      {/* Error Modal */}
      <ErrorModal
        showModal={showErrorModal}
        description={modalDesc}
        title="Error"
        onClose={() => setShowErrorModal(false)}
      />

      {setting && setting?.google_api_key && (
        <LocationPickerModal
          showModal={locationPicker}
          onSave={onPickLocation}
          google_api_key={setting?.google_api_key}
          onClose={() => {
            setLocationPicker(false);
          }}
        />
      )}
    </div>
  );
};
/* Component ends */

export default EditProperty;
