/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useParams } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import MaxTimeOnboard from "./MaxTimeOnboard";
import MaxTimeCompleteRenewal from "./MaxTimeCompleteRenewal";
import MaxTimeCompleteMoveOut from "./MaxTimeCompleteMoveOut";
import MaxTimeResolveMaintenance from "./MaxTimeResolveMaintenance";
import MaxTimeResolveIntMaintenance from "./MaxTimeResolveIntMaintenance";
import MaxTimeResolveSecurityInc from "./MaxTimeResolveSecurityInc";
import MaxTimeResolveSecurityCom from "./MaxTimeResolveSecurityCom";
/* Import local pages and component ends */

/* Component starts */
const T2SLA = (props) => {
  /* Props destructuring starts */
  const { user, settings } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const { propertyId } = useParams();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="">
      {/* **************** SLA ******************************** */}
      <h2 className="main_heading font_xxl fw_7 font_grey">S.L.A.</h2>
      {/* Pending Review */}
      {/* <PendingReview property_id={propertyId} user={user} settings={settings} /> */}

      {/* Reviewed and waiting to be assigned to Technician */}
      {/* <ReviewedWaitingAssignedTech
        property_id={propertyId}
        user={user}
        settings={settings}
      /> */}

      {/* Maximum Time to Resolve Tenant Maintenance Ticket */}
      <MaxTimeResolveMaintenance
        property_id={propertyId}
        user={user}
        settings={settings}
      />

      {/* Maximum Time to Resolve Internal Maintenance Ticket */}
      <MaxTimeResolveIntMaintenance
        property_id={propertyId}
        user={user}
        settings={settings}
      />
    </div>
  );
};
/* Component ends */

export default T2SLA;
