import GLOBAL from "../../../../setup/constants/global";
import { validEmail } from "../../../../setup/utils/validations";

export const timeMeasurement = [
  {
    name: "Minutes",
    type: "minutes",
  },
  {
    name: "Hours",
    type: "hours",
  },
  {
    name: "Days",
    type: "days",
  },
];

export const monthMeasurement = [
  {
    name: "Day",
    type: "day",
  },
  {
    name: "Month",
    type: "month",
  },
];

export const dayMeasurement = [
  {
    name: "Days",
    type: "days",
  },
];

export const notifyList = [
  { name: GLOBAL.USER_ROLE.OWNER },
  { name: GLOBAL.USER_ROLE.TENANT },
];

export const checkValidEmailList = (emailArr) => {
  let isValidEmail = true;

  emailArr.forEach((item) => {
    if (item === "" || !validEmail(item)) {
      isValidEmail = false;
    }
  });

  return !isValidEmail;
};
