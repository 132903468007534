/**
 *
 */

import React, { useRef } from "react";

/* Import configuration starts */
import { Formik } from "formik";
import { validEmail } from "../../../../setup/utils/validations";
import { useNavigate } from "react-router-dom";
import { formFields, validations } from "./helper";
/* Import configuration ends */

/* Import react bootstrap component starts */
import { Modal } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
import CustomPasswordInput from "../../../ui/input/CustomPasswordInput/CustomPasswordInput";
import TextButton from "../../../ui/button/TextButton/TextButton";
/* Import local pages and component ends */

import "./style.scss";
import { getUserTheme } from "../../../../setup/store/slices/authSlice";
import { useSelector } from "react-redux";

/* Component starts */
const ChangePasswordModal = (props) => {
  /* Props destructuring starts */
  const {
    showModal,
    description = "Please enter your new password",
    title = "Change Password",
    size = "lg",
    onClose,
    closeOnSubmit = true,
    onSubmit = null,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const formikPasswordValidation = useRef(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  const userTheme = useSelector(getUserTheme);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const initialValues = {
    username: "",
  };
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // Handling Form submission
  const changePassword = (values) => {
    if (onSubmit) {
      onSubmit(values);
    }
    closeOnSubmit && onClose();
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <Modal
      className="change-password-modal"
      size={size}
      centered
      show={showModal}
    >
      <Modal.Body
        className={`${userTheme === "dark" ? "bg_darkTheme" : "bg_lightTheme"}`}
      >
        <div className="modal-header-custom">
          <h2 className="text-center font_blue">{title}</h2>
        </div>
        <div className="modal-body-custom">
          <p className="description font_yellow text-center font_m">
            {description}
          </p>
          <Formik
            onSubmit={changePassword}
            initialValues={formFields}
            validate={(values) => validations(values, formikPasswordValidation)}
            validateOnBlur={true}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="form-wrapper">
                  {/* Input for New Password */}
                  <div className="form-group">
                    <CustomPasswordInput
                      name="newPassword"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      value={values.newPassword}
                      errors={errors.newPassword}
                      touched={touched.newPassword}
                      formikPasswordValidation={formikPasswordValidation}
                      label="New Password"
                    />
                  </div>

                  {/* Input for New Password Confirm */}
                  <div className="form-group">
                    <CustomPasswordInput
                      name="newPasswordConfirm"
                      showPasswordValidationErr={false}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      value={values.newPasswordConfirm}
                      errors={errors.newPasswordConfirm}
                      touched={touched.newPasswordConfirm}
                      label="New Password Confirm"
                    />
                  </div>
                </div>
                {/* Submit Button */}
                <div className="button-wrapper">
                  <CustomButton type="submit" size="l">
                    Change Password
                  </CustomButton>
                  <CustomButton
                    onClick={onClose}
                    type="button"
                    size="m"
                    variant="outline-red"
                  >
                    Close
                  </CustomButton>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </Modal.Body>
    </Modal>
  );
};
/* Component ends */

export default ChangePasswordModal;
