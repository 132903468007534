/**
 *
 */

import React, { useState, useEffect, useRef } from "react";

/* Import configuration starts */
import { Formik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import GLOBAL from "../../../../setup/constants/global";
import { fieldValue, validation, addMoreDoc, removeDoc } from "./helper";
import { useParams } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  getUser,
  getUserTheme,
} from "../../../../setup/store/slices/authSlice";
import {
  showSuccessAlert,
  showErrorAlert,
} from "../../../../setup/store/slices/globalAlertSlice";
import { mtFillP2Report } from "../../../../setup/store/slices/moveInSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Modal } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
import TextButton from "../../../ui/button/TextButton/TextButton";
import CustomFileInput from "../../../ui/input/CustomFileInput/CustomFileInput";
import CustomTextArea from "../../../ui/input/CustomTextArea/CustomTextArea";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const UIRCP2 = (props) => {
  /* Props destructuring starts */
  const {
    maintenance_id,
    showModal,
    size = "lg",
    onClose,
    maintenanceType,
    reviewByFM,
    data = null,
    currentTaskItem,
    setCurrentTaskItem,
    UIRCp2,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [formValue, setFormValue] = useState(fieldValue);
  const [docs, setDocs] = useState([]);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const formRef = useRef();
  const { propertyId } = useParams();
  // Redux Selector
  const user = useSelector(getUser);
  const userTheme = useSelector(getUserTheme);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // On MT Filling UIRC Phase 2
  const mtFillP2 = async (document, description) => {
    // Creating or collecting payload data to be sent
    const payload = {
      item_id: currentTaskItem?._id,
      readiness_card: UIRCp2?._id,
      description,
      document,
      maintenance_id,
      type: maintenanceType,
      property_id: propertyId,
    };
    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      const result = await dispatch(mtFillP2Report(payload));
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // On submitting form
  const onSubmit = async (values) => {
    const documents = [];
    for (let i = 0; i < docs.length; i++) {
      documents.push(values[`document_${docs[i]}`]);
    }
    onClose();
    setCurrentTaskItem(null);
    mtFillP2(documents, values?.description);
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <Modal
      size={size}
      centered
      show={showModal}
      className="uirc-p2-modal-wrapper"
    >
      <Modal.Body
        className={`${userTheme === "dark" ? "bg_darkTheme" : "bg_lightTheme"}`}
      >
        <div className="modal-header-custom">
          <p className="font_l fw_6 font_blue text-center">
            {/* Initial Unit Readiness Phase1 Form  */}
            Unit Readiness Final Assessment {reviewByFM && "Review"}
          </p>
        </div>
        <div className="modal-body-custom">
          <Formik
            onSubmit={onSubmit}
            initialValues={formValue}
            validate={(values) => validation(values, docs)}
            validateOnBlur={true}
            enableReinitialize={true}
            innerRef={formRef}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              setFieldTouched,
            }) => (
              <form onSubmit={handleSubmit}>
                {/* Form Fields */}
                <div className="form-wrapper form-wrapper-flex">
                  <div className="form-group-full">
                    <CustomTextArea
                      label="Task Completed"
                      name={`description`}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      value={values[`description`]}
                      touched={touched[`description`]}
                      errors={errors[`description`]}
                    />
                  </div>

                  {/* Attachments */}
                  <div className="attachment w_100">
                    <h3 className="font_blue font_m fw_5">Add attachments</h3>
                    {docs.length === 0 && (
                      <h3 className="font_yellow font_m fw_5 text-center attachments-not-added">
                        No attachment added !
                      </h3>
                    )}
                    {docs.map((item, index) => (
                      <div className="doc-item">
                        <div key={index} className="form-group-full">
                          <CustomFileInput
                            name={`document_${item}`}
                            label="Attachment"
                            setFieldTouched={setFieldTouched}
                            setFieldValue={setFieldValue}
                            value={values[`document_${item}`]}
                            touched={touched[`document_${item}`]}
                            errors={errors[`document_${item}`]}
                            formikState={true}
                            acceptable={true}
                            acceptType="image/*,video/*"
                          />
                        </div>
                        {docs.length > 0 && (
                          <TextButton
                            onClick={() =>
                              removeDoc(
                                formRef,
                                setFormValue,
                                docs,
                                setDocs,
                                index
                              )
                            }
                            className="fw_6 font_m font_red v_middle"
                          >
                            Remove
                          </TextButton>
                        )}
                      </div>
                    ))}
                    {console.log(values, "DOCS")}
                    {/* Add or Remove Field Action */}
                    <div className="action-btn w_100 text-end">
                      <TextButton
                        onClick={() =>
                          addMoreDoc(formRef, setFormValue, docs, setDocs)
                        }
                        className="fw_6 font_m font_blue v_middle"
                      >
                        + Add More
                      </TextButton>
                    </div>
                  </div>
                </div>

                {/* Submit and Cancel */}
                <div className="button-wrapper">
                  <>
                    <CustomButton
                      variant="red"
                      size="m"
                      onClick={onClose}
                      className="btn1"
                    >
                      Cancel
                    </CustomButton>
                    <CustomButton type="submit" size="m">
                      Submit
                    </CustomButton>
                  </>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </Modal.Body>
    </Modal>
  );
};
/* Component ends */

export default UIRCP2;
