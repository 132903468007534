/**
 *
 */

import React, { useState, useEffect } from "react";
// import DetailCard from "./sub-component/DetailCard";

/* Import configuration starts */
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  fetchPropertyDetailByID,
  getPropertyDetail,
  getPropertySettings,
} from "../../../../../setup/store/slices/propertySlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import TenantInvoiceDetail from "./local-component/TenantInvoiceDetail";
import PropertyInvoiceDetail from "./local-component/PropertyInvoiceDetail";
import PartiesInvoiceDetail from "./local-component/PartiesInvoiceDetail";
/* Import local pages and component ends */

import "./style.scss";
import { getSetting } from "../../../../../setup/store/slices/settingSlice";

/* Component starts */
const InvoicingDetails = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { propertyId, invoicingListTab, invoiceID } = useParams();
  const propertyData = useSelector(getPropertyDetail);

  const settings = useSelector(getPropertySettings);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    dispatch(fetchPropertyDetailByID({ property_id: propertyId }));
  }, []);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="invoicing-report-component-wrapper container_sm">
      <h1 className="text-center font_xxl mb-4">
        View{" "}
        {invoicingListTab === "Property Invoice"
          ? "Property"
          : invoicingListTab === "Party Invoice"
          ? "Party"
          : invoicingListTab}{" "}
        Invoice
      </h1>

      {/* Tenant Invoice */}
      {invoicingListTab === "Tenant" && (
        <TenantInvoiceDetail
          state={state}
          invoiceID={invoiceID}
          currency={propertyData?.currency}
          settings={settings}
        />
      )}

      {/* Property Invoice */}
      {invoicingListTab === "Property Invoice" && (
        <PropertyInvoiceDetail
          invoiceID={invoiceID}
          currency={propertyData?.currency}
          settings={settings}
        />
      )}

      {/* Parties Invoice */}
      {invoicingListTab === "Parties Invoice" && (
        <PartiesInvoiceDetail
          invoiceID={invoiceID}
          currency={propertyData?.currency}
          settings={settings}
        />
      )}
    </div>
  );
};
/* Component ends */

export default InvoicingDetails;
