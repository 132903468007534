/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { initialSteps } from "./helper";
import { useParams, useNavigate } from "react-router-dom";
import GLOBAL from "../../../../setup/constants/global";
import { useDispatch } from "react-redux";
import moment from "moment";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  showSuccessAlert,
  showErrorAlert,
} from "../../../../setup/store/slices/globalAlertSlice";
import { fetchTenantContracts } from "../../../../setup/store/slices/tenantSlice";
import { tenantRejectRenewal } from "../../../../setup/store/slices/contractSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { EyeFilledBlue } from "../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import ProgressSteps from "../../../ui/other/ProgressSteps/ProgressSteps";
import TenantRenewalConsent from "../TenantRenewalConsent/TenantRenewalConsent";
import InitialAgreementForm from "../../Tenant/InitialAgreementForm/InitialAgreementForm";
import TenantInfoUpdate from "../TenantInfoUpdate/TenantInfoUpdate";
import TextButton from "../../../ui/button/TextButton/TextButton";
import ConfirmationModal from "../../../ui/modal/ConfirmationModal/ConfirmationModal";
import AddDateModal from "../../../ui/modal/AddDateModal/AddDateModal";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const TenantContractRenewal = (props) => {
  /* Props destructuring starts */
  const {
    application_id,
    result,
    sequence,
    unit_id,
    fetchContractDetails,
    keyRender,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [currentStep, setCurrentStep] = useState(null);
  const [initialAgreementModal, setInitialAgreementModal] = useState(false);
  const [showSelectDateModal, setShowSelectDateModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [confirmationModalTitle, setConfirmationModalTitle] = useState("");
  const [confirmationModalDescription, setConfirmationModalDescription] =
    useState("");
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // On proceeding next step to the wizard
  const onNext = () => {
    let currStepIdx = sequence.indexOf(currentStep);
    currStepIdx += 1;
    setCurrentStep(sequence[currStepIdx]);
  };

  const onSignatureSuccess = async () => {
    await dispatch(fetchTenantContracts({ unit_id }));
    navigate(-1);
  };

  const onCancelRenewal = () => {
    setConfirmationModalTitle("Reject Renewal");
    setConfirmationModalDescription(
      "Are you sure you want to reject renewal and start the Move Out process ? Please keep in mind this is an irreversible action."
    );
    setShowConfirmationModal(true);
  };

  const onMoveOutByRejecting = async (move_out_date = null) => {
    setShowSelectDateModal(false);

    // Creating or collecting payload data to be sent
    const payload = { contract_id: result.old_contract_id };
    if (move_out_date) {
      payload["move_out_date"] = move_out_date;
    }

    // Backend response. Try, Catch
    try {
      const result = await dispatch(tenantRejectRenewal(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description:
                typeof result?.payload === "string" ? result?.payload : "",
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description:
                typeof result?.payload?.message === "string"
                  ? result?.payload?.message
                  : "",
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  const onConfirmCancelRenewal = () => {
    setShowConfirmationModal(false);
    setShowSelectDateModal(true);
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    if (result) {
      setCurrentStep(result.step_to_show);
    }
  }, [result]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="tenant-contract-renewal-wrapper">
      {/* Steps Counter */}
      <div className="steps container_sm">
        <ProgressSteps
          steps={sequence}
          keyRender={keyRender}
          currentStep={currentStep}
          version={sequence?.includes("installments") ? "4" : "3"}
        />
      </div>

      {currentStep === "request" && (
        <div className="step-content">
          <TenantRenewalConsent
            old_contract_id={result.old_contract_id}
            onNext={onNext}
            onCancelRenewal={onCancelRenewal}
          />
        </div>
      )}

      {currentStep === "initial_agreement" && (
        <div className="initial-agreement-form-wrapper-outer step-content">
          <InitialAgreementForm
            renewal={true}
            isUnitCommercial={!result.is_unit_residential}
            unit={result.application.unit}
            contract={result.contract}
            onSignatureSuccess={onSignatureSuccess}
            application_id={application_id}
            application={result}
            onCancelRenewal={onCancelRenewal}
          />
        </div>
      )}

      {currentStep === "tenant_info" && (
        <TenantInfoUpdate
          application={result}
          application_id={application_id}
          isUnitCommercial={!result.is_unit_residential}
          fetchContractDetails={fetchContractDetails}
          info_sequence={result?.info_sequence}
          currentStepPrev={currentStep}
          onCancelRenewal={onCancelRenewal}
        />
      )}

      <ConfirmationModal
        showModal={showConfirmationModal}
        description={confirmationModalDescription}
        title={confirmationModalTitle}
        onClose={() => {
          setShowConfirmationModal(false);
        }}
        onConfirm={onConfirmCancelRenewal}
      />

      <AddDateModal
        title="Move Out Date"
        descriptionHeader="Please select your preferred move out date. Your move out date should be before the contract end date"
        descriptionHeaderClass="font_s fw_5 move-out-on-rejecting-renewal"
        descriptionFooter="Note: If you do not choose your preferred move out data, your contract end date will be considered as the default one."
        descriptionFooterClass="font_xs fw_5 font_yellow"
        maxDate={true}
        maxDateValue={result?.old_contract_end_date}
        showModal={showSelectDateModal}
        onSubmit={onMoveOutByRejecting}
        onCancel={() => {
          setShowSelectDateModal(!showSelectDateModal);
          navigate(-1);
        }}
      />
    </div>
  );
};
/* Component ends */

export default TenantContractRenewal;
