/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import moment from "moment";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
/* Import local pages and component ends */

/* Component starts */
const BasicDetails = (props) => {
  /* Props destructuring starts */
  const { details, index, isRenew } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";

  const firstName = details?.first_name;
  const lastName = details?.last_name;
  const email = details?.email;
  const phone = `${details?.countrycode} ${details?.mobile}`;
  const dob = moment(details?.dob).format(DATE_FORMAT);
  const gender = details?.gender;
  const profession = details?.profession;
  const company = details?.business_name;
  const nationality = details?.nationality;
  const language = details?.language;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="basic-wrapper">
      <div className="heading-wrapper ">
        <p className={`font_l label my-2 ${isRenew && "mx-4"} `}>
          {" "}
          {index && `${index} -`} Basic Details
        </p>
      </div>
      <div className="detail-box">
        <div className="basic">
          <div className="detail">
            <p className="font_s fw_3">
              First Name <br /> <span className="font_m fw_4">{firstName}</span>
            </p>
          </div>
          <div className="detail">
            <p className="font_s fw_3">
              Last Name <br /> <span className="font_m fw_4">{lastName}</span>
            </p>
          </div>
          <div className="detail">
            <p className="font_s fw_3">
              Email <br /> <span className="font_m fw_4">{email}</span>
            </p>
          </div>
          <div className="detail">
            <p className="font_s fw_3">
              Mobile Number <br />{" "}
              <span className="font_m fw_4">
                {phone !== undefined ? phone : "-"}
              </span>
            </p>
          </div>
          <div className="detail">
            <p className="font_s fw_3">
              Date Of Birth <br />{" "}
              <span className="font_m fw_4">
                {dob !== undefined ? dob : "-"}
              </span>
            </p>
          </div>
          <div className="detail">
            <p className="font_s fw_3">
              Gender <br />{" "}
              <span className="font_m fw_4">
                {gender !== undefined ? gender : "-"}
              </span>
            </p>
          </div>
          {profession && (
            <div className="detail">
              <p className="font_s fw_3">
                Profession <br />{" "}
                <span className="font_m fw_4">
                  {profession !== undefined ? profession : "-"}
                </span>
              </p>
            </div>
          )}
          {company && (
            <div className="detail">
              <p className="font_s fw_3">
                Company Name <br />{" "}
                <span className="font_m fw_4">
                  {company !== undefined ? company : "-"}
                </span>
              </p>
            </div>
          )}
          <div className="detail">
            <p className="font_s fw_3">
              Nationality <br />{" "}
              <span className="font_m fw_4">
                {nationality !== undefined ? nationality : "-"}
              </span>
            </p>
          </div>
          <div className="detail">
            <p className="font_s fw_3">
              Language <br />{" "}
              <span className="font_m fw_4">
                {language !== undefined ? language : "-"}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
/* Component ends */

export default BasicDetails;
