export const browserSupported = ({ name, version }) => {
  const versionDetail = version.split(".");
  const major = versionDetail[0] && parseInt(versionDetail[0]);
  const minor = versionDetail[1] && parseInt(versionDetail[1]);
  const patch = versionDetail[2] && parseInt(versionDetail[2]);

  switch (name) {
    case "safari":
      if (major <= 15) {
        if (minor <= 6) {
          return false;
        }
      }
      break;
  }
  return true;
};

export const supportedBrowsers = [
  { name: "Safari", version: "15.7 ^" },
  { name: "Chrome", version: "125.0.0 ^" },
];
