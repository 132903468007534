import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  setLoading,
  setShowUploadProgress,
  setUploadProgress,
} from "./unpersistedSlice";
import axios, { axiosInstance } from "../axios";
import { API_URL } from "../../config/devKeys";
import { fetchPropertySettings } from "./propertySlice";
import { PLATFORM } from "../../platformUtil";

const initialState = {};

export const fetchTemplateToken = createAsyncThunk(
  "template/token/get",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { type, property_id } = payload;
      const { data } = await axios.get(
        `${API_URL}/template/get_token?type=${type}&property_id=${property_id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const fetchTemplateTokenByApplicationId = createAsyncThunk(
  "template/token/get_by_application",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.get(
        `${API_URL}/template/get_token_by_application_id?type=${payload.type}&application_id=${payload.application_id}&move_out_id=${payload.move_out_id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const uploadInitialAgreementTemplate = createAsyncThunk(
  "template/create/initial_agreement_template",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const formData = new FormData();
      const { property_id } = payload;
      Object.keys(payload).map((key) => {
        if (key == "document") {
          if (PLATFORM !== "web") {
            formData.append(key, {
              name: payload[key].name,
              type: payload[key].type,
              uri:
                PLATFORM === "android"
                  ? payload[key].uri
                  : payload[key].uri.replace("file://", ""),
            });
          } else {
            formData.append(key, payload[key], payload[key].name);
          }
        } else {
          formData.append(key, payload[key]);
        }
      });
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }
      // const response = await fetch(
      //   `${API_URL}/template/create_initial_agreement_template`,
      //   {
      //     method: "POST",
      //     headers: headers,
      //     body: formData,
      //   }
      // );
      // const data = await response.json();
      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "POST",
        url: "/template/create_initial_agreement_template",
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      // const data = await response.json();

      const { data } = await axiosInstance.request(config);

      dispatch(setLoading(false));
      if (data.success) {
        await dispatch(fetchPropertySettings({ property_id }));
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const uploadFinalAgreementTemplate = createAsyncThunk(
  "template/create/final_agreement_template",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const formData = new FormData();
      const { property_id } = payload;
      Object.keys(payload).map((key) => {
        if (key == "document") {
          if (PLATFORM !== "web") {
            formData.append(key, {
              name: payload[key].name,
              type: payload[key].type,
              uri:
                PLATFORM === "android"
                  ? payload[key].uri
                  : payload[key].uri.replace("file://", ""),
            });
          } else {
            formData.append(key, payload[key], payload[key].name);
          }
        } else {
          formData.append(key, payload[key]);
        }
      });
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }
      // const response = await fetch(
      //   `${API_URL}/template/create_final_agreement_template`,
      //   {
      //     method: "POST",
      //     headers: headers,
      //     body: formData,
      //   }
      // );
      // const data = await response.json();
      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "POST",
        url: "/template/create_final_agreement_template",
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      // const data = await response.json();

      const { data } = await axiosInstance.request(config);

      dispatch(setLoading(false));
      if (data.success) {
        await dispatch(fetchPropertySettings({ property_id }));
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const uploadClearanceCertTemplate = createAsyncThunk(
  "template/create/clearance_cert_template",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const formData = new FormData();
      const { property_id } = payload;
      Object.keys(payload).map((key) => {
        if (key == "document") {
          if (PLATFORM !== "web") {
            formData.append(key, {
              name: payload[key].name,
              type: payload[key].type,
              uri:
                PLATFORM === "android"
                  ? payload[key].uri
                  : payload[key].uri.replace("file://", ""),
            });
          } else {
            formData.append(key, payload[key], payload[key].name);
          }
        } else {
          formData.append(key, payload[key]);
        }
      });
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }
      // const response = await fetch(
      //   `${API_URL}/template/create_clearance_cert_template`,
      //   {
      //     method: "POST",
      //     headers: headers,
      //     body: formData,
      //   }
      // );
      // const data = await response.json();
      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "POST",
        url: "/template/create_clearance_cert_template",
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      // const data = await response.json();

      const { data } = await axiosInstance.request(config);

      dispatch(setLoading(false));
      if (data.success) {
        await dispatch(fetchPropertySettings({ property_id }));
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const templateSlice = createSlice({ name: "template", initialState });

export default templateSlice.reducer;
