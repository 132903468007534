/**
 * This component is used to render the routes.
 * All the routes configurations are available in "ROUTES" variable in "routes.js" file.
 * "ROUTES" is and object that contains two arrays of routing info. One is for "public"
 * routes and another is for "private", and we are just rendering each
 * array item in <Route/> component.
 */

import { useState } from "react";

/* Import configuration starts */
import { Route, Routes, Navigate } from "react-router-dom";
import ROUTES from "./routes";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import local pages and component starts */
import Layout1 from "../layout/Layout1";
import Layout2 from "../layout/Layout2/Layout2";
import RequireAuth from "./protected-route-component/RequireAuth";
/* Import local pages and component ends */

const CustomRoutes = () => {
  /* Component states declaration starts */
  /* Component states declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <Routes>
      {/* Public routing array, and use of Layout 1 */}
      {ROUTES.public.map((item, index) => (
        <Route
          key={item.title}
          path={`${item.path}`}
          element={
            <Layout1>
              <item.component />
            </Layout1>
          }
        />
      ))}

      {/* Private routing array, and use of Layout 2 */}
      {ROUTES.private.map((item, index) => (
        <Route key={`${item.title}-${index}`} element={<RequireAuth />}>
          <Route
            key={item.title}
            path={`${item.path}`}
            element={
              <Layout2>
                <item.component />
              </Layout2>
            }
          />
        </Route>
      ))}
    </Routes>
  );
};

export default CustomRoutes;
