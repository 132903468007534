export const formKeys = {
  cheque_number: "",
  cheque_amount: "",

  bank_name: "",
  account_no: "",

  cheque_issuer: "",
  cheque_doc: null,

  cheque_date: new Date(),
};

export const validations = (values) => {
  const error = {};

  if (values.cheque_number === "") {
    error.cheque_number = "Cheque Number is required";
  }

  if (values.cheque_amount === "") {
    error.cheque_amount = "Cheque Amount is required";
  }

  if (values.bank_name === "") {
    error.bank_name = "Bank Name is required";
  }

  if (values.account_no === "") {
    error.account_no = "Account Number is required";
  }

  if (values.cheque_issuer === "") {
    error.cheque_issuer = "Cheque Issuer is required";
  }

  if (values.cheque_doc === null) {
    error.cheque_doc = "Cheque Documnet is required";
  }

  return error;
};
