/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useDispatch } from "react-redux";
import GLOBAL from "../../../../../setup/constants/global";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  showSuccessAlert,
  showErrorAlert,
} from "../../../../../setup/store/slices/globalAlertSlice";
import {
  updatePropertySettings,
  getPropertySettings,
  updatePropertyLogo,
  fetchPropertySettings,
} from "../../../../../setup/store/slices/propertySlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import {
  DocFilledWhite,
  DocumentGreen,
} from "../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import FileViewerModal from "../../../../ui/modal/FileViewerModal/FileViewerModal";
import CustomButton from "../../../../ui/button/CustomButton/CustomButton";
import IconButton from "../../../../ui/button/IconButton/IconButton";
/* Import local pages and component ends */

/* Component starts */
const PropertyLogo = (props) => {
  /* Props destructuring starts */
  const { user, settings, property_id } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [settingChanged, setSettingChanged] = useState(false);
  const [showFile, setShowFile] = useState(false);
  const [file, setFile] = useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const logo = settings?.logo;
  /* Component variable declaration / object destructure ends */
  /* Component function definition starts */
  // On updating setting
  const onUpdate = async (e) => {
    const value = e.target.files[0];
    // Creating or collecting payload data to be sent
    const payload = {
      logo: value,
      property_id,
    };
    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      const result = await dispatch(updatePropertyLogo(payload));
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result?.payload || "",
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result?.payload?.message || "",
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  // On initial load or when all the settings is loaded
  useEffect(() => {}, [settings]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="property-logo option">
      {/* Label */}
      <div className="label">
        <p className="font_m fw_5">Property Logo</p>
      </div>

      {/* Setting */}
      <div className="setting">
        <div className="row1">
          <div className={`file-box ${logo ? "bg_blue" : ""}`}>
            <label className="font_xs">
              File
              <input type="file" onChange={onUpdate} className="inp" />
            </label>
          </div>

          {logo && (
            <IconButton
              className="view-file"
              onClick={() => {
                setFile(logo);
                setShowFile(true);
              }}
            >
              <img src={DocumentGreen} className="icon" alt="" />
            </IconButton>
          )}
        </div>

        <div className="row2"></div>

        {/* Save Button */}
        {settingChanged && (
          <div className="save-btn-wrapper mt_2">
            <CustomButton onClick={onUpdate} className="save-btn" size="s">
              Save
            </CustomButton>
          </div>
        )}
      </div>

      <FileViewerModal
        show={showFile}
        file={file.url}
        type={file.type}
        onClose={() => setShowFile(false)}
      />
    </div>
  );
};
/* Component ends */

export default PropertyLogo;
