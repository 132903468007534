export const initialValues = {
  visitor_firstname: "",
  visitor_lastname: "",
  mobile_number: "",
  reason: "",
  car_number: "",
  image: null,
  start_date: new Date(),
  end_date: new Date(),
};

export const validations = (values) => {
  const error = {};
  if (values.first_name === "") {
    error.first_name = "First Name is required";
  }

  if (values.last_name === "") {
    error.last_name = "Last Name is required";
  }

  if (values.reason === "") {
    error.reason = "Reason For Access is required";
  }

  return error;
};
