/**
 *
 */

import React, { useState, useEffect, useRef } from "react";

/* Import configuration starts */
import { Formik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import GLOBAL from "../../../../setup/constants/global";
import { useParams } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  getUser,
  getUserTheme,
} from "../../../../setup/store/slices/authSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Modal } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { XWhite } from "../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../../ui/button/IconButton/IconButton";
import Image from "../../../ui/other/Image/Image";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const RejectedReportModal = (props) => {
  /* Props destructuring starts */
  const {
    showModal = true,
    size = "lg",
    onClose,
    prev_initial_URIC,
    prev_final_URIC,
    assesment_type = "Initial",
    handleViewFile,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  // Redux Selector
  const user = useSelector(getUser);
  const userTheme = useSelector(getUserTheme);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <Modal
      size={size}
      centered
      show={showModal}
      className="rejected-report-modal-wrapper"
    >
      <Modal.Body
        className={`${userTheme === "dark" ? "bg_darkTheme" : "bg_lightTheme"}`}
      >
        <div className="modal-header-custom">
          <p
            className={`font_xxl fw_6 ${
              userTheme !== "dark" && "font_blue"
            } font_white text-center`}
          >
            Phase 1 UIRC {assesment_type} Rejected Report
          </p>

          <IconButton onClick={onClose} className="close-btn">
            <img src={XWhite} alt="" />
          </IconButton>
        </div>
        <div className="modal-body-custom">
          {assesment_type === "Initial" &&
            prev_initial_URIC?.map((reportItem, reportIndex) => (
              <div className="report-item" key={`report-${reportIndex}`}>
                <div className="report-header">
                  <h3 className="item-number-heading font_l font_blue fw_6 text-center">
                    Report #{reportIndex + 1}
                  </h3>

                  <div className="submitted-by">
                    <h3 className="font_grey font_m fw_5 report-item-label text-end">
                      Submitted By
                    </h3>
                    <p className="font_m fw_5 text-end">
                      {reportItem?.technician?.first_name}{" "}
                      {reportItem?.technician?.last_name}
                    </p>
                  </div>
                </div>

                {reportItem?.items?.map((item, index) => (
                  <div
                    className="report-detail"
                    key={`report-${reportIndex}_item-${index}`}
                  >
                    <h3 className="report-item-header fw_5 font_m">
                      Item #{index + 1}
                    </h3>

                    <h3 className="font_grey font_m fw_5 report-item-label">
                      Description
                    </h3>
                    <p className="font_m fw_5">{item?.description}</p>
                    <h3 className="font_grey font_m fw_5 report-item-label">
                      Attachments
                    </h3>
                    <div className="attachment-stripe">
                      {item?.documents?.map((img, indx) => (
                        <Image
                          key={`report-${reportIndex}_item-${index}_doc-${indx}`}
                          onClick={() => handleViewFile(img?.document)}
                          src={img?.document?.url}
                          videoThumbnail={img?.document?.type2 === "video"}
                          className="attachment-item"
                          objectFit="cover"
                        />
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            ))}

          {assesment_type === "Final" &&
            prev_final_URIC?.map((reportItem, reportIndex) => (
              <div className="report-item" key={`report-${reportIndex}`}>
                <div className="report-header">
                  <h3 className="item-number-heading font_l font_blue fw_6 text-center">
                    Report #{reportIndex + 1}
                  </h3>

                  <div className="submitted-by">
                    <h3 className="font_grey font_m fw_5 report-item-label text-end">
                      Submitted By
                    </h3>
                    <p className="font_m fw_5 text-end">
                      {reportItem?.technician?.first_name}{" "}
                      {reportItem?.technician?.last_name}
                    </p>
                  </div>
                </div>

                {reportItem?.items?.map((item, index) => (
                  <div
                    className="report-detail"
                    key={`report-${reportIndex}_item-${index}`}
                  >
                    <h3 className="report-item-header fw_5 font_m">
                      Item #{index + 1}
                    </h3>

                    <h3 className="font_grey font_m fw_5 report-item-label">
                      Description
                    </h3>
                    <p className="font_m fw_5">{item?.description}</p>
                    <h3 className="font_grey font_m fw_5 report-item-label">
                      Attachments
                    </h3>
                    <div className="attachment-stripe">
                      {item?.documents?.map((img, indx) => (
                        <Image
                          key={`report-${reportIndex}_item-${index}_doc-${indx}`}
                          onClick={() => handleViewFile(img?.document)}
                          src={img?.document?.url}
                          videoThumbnail={img?.document?.type2 === "video"}
                          className="attachment-item"
                          objectFit="cover"
                        />
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            ))}
        </div>
      </Modal.Body>
    </Modal>
  );
};
/* Component ends */

export default RejectedReportModal;
