/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useSelector, useDispatch } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import { getClearenceDocument } from "../../../../setup/store/slices/moveOutSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import MoveOutRequest from "../../TenantMoveOut/MoveOutRequest/MoveOutRequest";
import DisplayMoveoutDate from "./sub-component/DisplayMoveoutDate";
import MoveOutPendingClearence from "../../TenantMoveOut/MoveOutPendingClearence/MoveOutPendingClearence";
import Status from "./sub-component/Status";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const TenantMoveOutDetail = (props) => {
  /* Props destructuring starts */
  const {
    sequence,
    data,
    contract_id,
    unit_id,
    getTenantMoveOutDetail,
    move_out_id,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="tenant-moveout-detail-wrapper">
      {sequence.map((seq) => {
        switch (seq) {
          case "capture_move_out_date":
            return (
              <>
                <MoveOutRequest
                  unitId={unit_id}
                  contractId={contract_id}
                  getTenantMoveOutDetail={getTenantMoveOutDetail}
                  contract_end_date={data.contract_end_date}
                />
              </>
            );

          case "display_move_out_date":
            return (
              <>
                <DisplayMoveoutDate
                  unit_name={data.unit}
                  move_out_date={data.move_out_date}
                />
              </>
            );

          case "clearance_docs":
            return (
              <>
                <MoveOutPendingClearence
                  clearencedoc={data.clearance_docs}
                  getTenantMoveOutDetail={getTenantMoveOutDetail}
                  moveoutID={move_out_id}
                />
              </>
            );

          case "text_pending_clearance_docs":
            return (
              <>
                <Status text="You are required to submit Utility Clearence Documents" />
              </>
            );

          case "unit_under_inspection":
            return (
              <>
                <Status text="Please wait, unit is under inspection." />
              </>
            );

          case "keys_handover_left":
            return (
              <>
                <Status text={data?.handover_to} />
              </>
            );

          case "waiting_clearance_cert":
            return (
              <>
                {/* <Status text="Waiting for clearence letter" /> */}
                <Status
                  text={`We're currently processing your account clearance. We'll let you know as soon as it's complete and if any further steps are required on your end.`}
                />
              </>
            );

          default:
            break;
        }
      })}
    </div>
  );
};
/* Component ends */

export default TenantMoveOutDetail;
