/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useSelector, useDispatch } from "react-redux";
import GLOBAL from "../../../../setup/constants/global";
import { useParams } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  getManagers,
  getTechnicians,
  listManagers,
  listTechnicians,
} from "../../../../setup/store/slices/maintenanceSlice";
import {
  getIsFM,
  getIsTPM,
  getIsPM,
} from "../../../../setup/store/slices/authSlice";
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../../setup/store/slices/globalAlertSlice";
import { fetchPropertyTenantsBasic } from "../../../../setup/store/slices/propertySlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import DetailCard from "./sub-component/DetailCard";
import TimeLineLists from "./sub-component/TimeLineLists";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const InternalMaintenanceDetails = (props) => {
  /* Props destructuring starts */
  const {
    isT1,
    isT2,
    isT3,
    mgmtModel,
    status,
    maintenance,
    maintenance_id,
    user,
    currency,
    property,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [tenants, setTenants] = useState([]);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const technicians = useSelector(getTechnicians);
  const managers = useSelector(getManagers);
  const isFM = useSelector(getIsFM);
  const isTPM = useSelector(getIsTPM);
  const isPM = useSelector(getIsPM);
  const { propertyId } = useParams();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const fetchingTenantsInProperty = async () => {
    // Creating or collecting payload data to be sent
    const payload = { property_id: propertyId };
    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      const result = await dispatch(fetchPropertyTenantsBasic(payload));
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description:
                typeof result?.payload === "string" ? result?.payload : "",
            })
          );
          setTenants([]);
          break;

        case GLOBAL.FULFILLED:
          setTenants(result?.payload);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    if (maintenance) {
      if (!isT1 && (isFM || isTPM || isPM)) {
        dispatch(listManagers({ property_id: maintenance.property_id._id }));
      }
      if (!isT1 && (isFM || isTPM)) {
        dispatch(listTechnicians({ property_id: maintenance.property_id._id }));
      }
    }
  }, [maintenance]);

  useEffect(() => {
    fetchingTenantsInProperty();
  }, []);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="internal-maintenance-detail-wrapper container_sm">
      {/* Details */}
      <DetailCard
        isT1={isT1}
        isT2={isT2}
        isT3={isT3}
        status={status}
        mgmtModel={mgmtModel}
        maintenance={maintenance}
      />

      {/* Timeline */}
      <TimeLineLists
        isT1={isT1}
        isT2={isT2}
        isT3={isT3}
        status={status}
        mgmtModel={mgmtModel}
        maintenance={maintenance}
        maintenance_id={maintenance_id}
        logs={maintenance?.logs}
        user={user}
        technicians={technicians}
        tenants={tenants}
        managers={managers}
        currency={currency}
        property={property}
      />
    </div>
  );
};
/* Component ends */

export default InternalMaintenanceDetails;
