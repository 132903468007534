import GLOBAL from "../../../../setup/constants/global";

export const buttonList = [
  { key: "existing", label: "Existing" },
  { key: "transitioned", label: "Tranistioned" },
  { key: "new", label: "New" },
  { key: "previous-tenants", label: "Previous" },
  // { key: "requests", label: "Requests" },
  { key: "move-out", label: "Move Out" },
  { key: "renewals", label: "Renewals" },
  { key: "cancelled", label: "Cancelled" },
];

export const transitionedAction = (item) => {
  const action = {
    status: "",
    rejected: false,
    canReview: true,
    isPendingConfirmation: false,
    color: "font_yellow",
  };

  if (item) {
    switch (item?.status) {
      case GLOBAL.TENANT_APPLICATION_STATUS.INVITATION_CANCELLED:
        action.status = "Invitaion Cancelled";
        // action.canReview = false;
        action.rejected = true;
        break;

      case GLOBAL.TENANT_APPLICATION_STATUS.TENANT:
        switch (item.sub_status) {
          case "pending_invoice_clearance":
            action.status = "Pending Invoice Clearance";
            action.canReview = true;
            break;
        }
        break;

      case GLOBAL.TENANT_APPLICATION_STATUS.PENDING_CONFIRMATION:
        action.status = "Pending Confirmation";
        // action.canReview = false;
        action.isPendingConfirmation = true;
        break;

      case GLOBAL.TENANT_APPLICATION_STATUS.PENDING_APPLICATION:
        action.status = "Pending Application";
        // action.canReview = false;
        break;

      case "expired":
        action.status = "Invitation Expired";
        action.color = "font_red";
        // action.canReview = false;
        break;
    }
  }

  return action;
};

export const newAction = (item) => {
  const action = {
    status: "",
    rejected: false,
    canReview: false,
    isPendingConfirmation: false,
  };

  if (item) {
    switch (item?.status) {
      case GLOBAL.TENANT_APPLICATION_STATUS.INVITATION_CANCELLED:
        action.status = "Invitaion Cancelled";
        action.canReview = false;
        action.rejected = true;
        break;

      case GLOBAL.TENANT_APPLICATION_STATUS.PENDING_CONFIRMATION:
        action.status = "Pending Confirmation";
        action.canReview = false;
        action.isPendingConfirmation = true;
        break;

      case GLOBAL.TENANT_APPLICATION_STATUS.PENDING_REVIEW:
        action.status = "Pending Review";
        action.canReview = true;
        break;

      case GLOBAL.TENANT_APPLICATION_STATUS.APPROVED:
        action.status = "Pending Action";
        action.canReview = true;
        break;

      case GLOBAL.TENANT_APPLICATION_STATUS.REJECTED:
        action.status = "Rejected";
        action.rejected = true;
        action.canReview = true;
        break;

      case GLOBAL.TENANT_APPLICATION_STATUS.PENDING_APPLICATION:
        action.status = "Pending Application";
        action.canReview = false;
        break;

      case GLOBAL.TENANT_APPLICATION_STATUS.PENDING_ACTION:
        action.status = "Pending Action";
        action.canReview = true;
        break;

      case GLOBAL.TENANT_APPLICATION_STATUS.TENANT:
        action.status = "Occupied";
        action.canReview = true;
        break;

      case GLOBAL.TENANT_APPLICATION_STATUS.PENDING_AGREEMENT_TERMS:
        action.status = "Pending Initial Agreement Terms";
        action.canReview = true;
        break;

      case GLOBAL.TENANT_APPLICATION_STATUS.PENDING_AGREEMENT_REVIEW:
        action.status = "Pending Review";
        action.canReview = true;
        break;

      case GLOBAL.TENANT_APPLICATION_STATUS.PENDING_CONTRACT_REVIEW:
        action.status = "Pending Contract Review";
        action.canReview = true;
        break;

      case GLOBAL.TENANT_APPLICATION_STATUS.PENDING_CONTRACT_SIGNATURE:
        action.status = "Pending Contract Signature";
        action.canReview = true;
        break;

      case GLOBAL.TENANT_APPLICATION_STATUS.UNIT_MAINTENANCE:
        if (item?.contract?.contract_details?.move_in_date) {
          action.status = "Unit Under Maintenance";
        } else {
          action.status = "Pending Tenant Action";
        }
        action.canReview = true;
        break;

      case GLOBAL.TENANT_APPLICATION_STATUS.PENDING_KEY_COLLECTION:
        action.status = "Pending Tenant Key Collection";
        action.canReview = true;
        break;

      default:
        break;
    }
  }

  return action;
};
