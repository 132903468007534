/*
 * Slice to handle TENANT Guest Pass Module
 */

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../axios";
import { API_URL } from "../../config/devKeys";
// import { setLoading } from "./unpersistedSlice";
// import { PLATFORM } from "../../platformUtil";

const initialState = {
  facilityType: [],
};

// FETCH Facility Type -> Select Options For Facility Type Options.
export const fetchFacilityType = createAsyncThunk(
  "facility_type/list",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await axios.get(API_URL + "/facility_type/list", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        },
      });
      if (data.success) {
        return data.data;
      } else {
        rejectWithValue(data.error);
      }
    } catch (error) {
      return rejectWithValue(error?.response);
    }
  }
);

export const facilitySlice = createSlice({
  name: "facility",
  initialState,
  extraReducers: {
    [fetchFacilityType.rejected]: (state, action) => {
      state.facilityType = [];
    },
    [fetchFacilityType.fulfilled]: (state, action) => {
      state.facilityType = action.payload;
    },
  },
});

// To Get the Facility Types
export const getFacilityType = (state) => state.facility.facilityType;

export default facilitySlice.reducer;
