/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  fetchFinancialsList,
  fetchFinancialsListBasedCommunity,
  fetchFinancialsListBasedProperty,
  fetchSearchedFinancialsList,
  getCommunityFinancialsList,
  getFinancialsList,
  getPropertyFinancialsList,
} from "../../setup/store/slices/financialDashboardSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { EnvelopeOutLinedBlue, PrintIcon } from "../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import GLOBAL from "../../setup/constants/global";
import Pagination from "../../components/ui/other/Pagination/Pagination";
import Header from "./local-component/Header";
import PieChartGraph from "./local-component/PieChartGraph";
import Searchbar from "../../components/ui/other/Searchbar/Searchbar";
import FinancialTable from "./local-component/FinancialTable";
import CustomDateInput from "../../components/ui/input/CustomDateInput/CustomDateInput";
// import CustomButton from "../../components/ui/button/CustomButton/CustomButton";
// import TextButton from "../../components/ui/button/TextButton/TextButton";
import { getUniqueValues } from "../../setup/utils/global-helper";
import BarChartGraph from "./local-component/BarChartGraph";
import EmailModal from "../../components/ui/modal/EmailModal/EmailModal";
import BreadCrumb from "../../components/ui/other/BreadCrumb/BreadCrumb";
/* Import local pages and component ends */

import "./style.scss";
import moment from "moment";

/* Component starts */
const FinancialDashboard = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const startDate = new Date();
  // const endDate = new Date();
  const [fromDate, setFromDate] = useState(
    new Date(startDate.getFullYear(), startDate.getMonth(), 1)
  );
  const [toDate, setToDate] = useState(
    // new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0)
    new Date()
  );

  const [search, setSearch] = useState("");
  const [financialList, setFinancialList] = useState([]);
  const [filteredFinancialList, setFilteredFinancialList] = useState([]);

  const [financialData, setFinancialData] = useState();
  // const [statusData, setStatusData] = useState();
  const [cleared, setCleared] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [showModal, setShowModal] = useState(false);

  // FILTERS
  const [communityData, setCommunityData] = useState();
  const [multiSelectCommunity, setMultiSelectCommunity] = useState();
  const [propertyData, setPropertyData] = useState();
  const [multiSelectProperty, setMultiSelectProperty] = useState();

  // const [tenantData, setTenantData] = useState();
  // const [multiSelectTenant, setMultiSelectTenant] = useState();

  const [amountData, setAmountData] = useState();
  const [multiSelectAmount, setMultiSelectAmount] = useState();

  const [categoryData, setCategoryData] = useState();
  const [multiSelectCategory, setMultiSelectCategory] = useState();

  const [subcategoryData, setSubcategoryData] = useState();
  const [multiSelectSubCategory, setMultiSelectSubCategory] = useState();

  const [statusData1, setStatusData1] = useState();
  const [multiSelectStatus, setMultiSelectStatus] = useState();

  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const { pathname } = useLocation();
  const param = useParams();
  const dispatch = useDispatch();
  // const userRoles = useSelector(getUserRoles);
  // const user = useSelector(getUser);
  const financialListData = useSelector(getFinancialsList);
  const propertyFinancialList = useSelector(getPropertyFinancialsList);
  const communityFinancialList = useSelector(getCommunityFinancialsList);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItemsList = filteredFinancialList?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  const loadFinancialsList = async () => {
    if (pathname.includes("communities") && pathname.includes("properties")) {
      await dispatch(
        fetchFinancialsListBasedProperty({ property_id: param.propertyId })
      );
    } else if (pathname.includes("independent")) {
      await dispatch(
        fetchFinancialsListBasedProperty({ property_id: param.propertyId })
      );
    } else if (pathname.includes("communities")) {
      await dispatch(
        fetchFinancialsListBasedCommunity({
          community_id: param.communityId,
        })
      );
    } else {
      await dispatch(fetchFinancialsList());
    }
  };

  const filterFinancialForGraph = (finance) => {
    const arrayUniqueByKey = [
      ...new Map(finance.map((item) => [item["property_name"], item])).values(),
    ];

    let objFinance = [];
    arrayUniqueByKey.forEach((item) => {
      if (item?._id) {
        let tenantCount = finance?.filter(
          (d) =>
            d.property_name === item?.property_name &&
            (d.category === "Tenant" ||
              d.category === "Property Invoice" ||
              d.category === "Parties Invoice" ||
              d.sub_category === "deposit")
        );

        let objTent = {
          name: item?.property_name,
          Tenant: tenantCount
            ?.filter((d) => d.category === "Tenant")
            .reduce((n, { invoice_amount }) => n + invoice_amount, 0),
          Property: tenantCount
            ?.filter((d) => d.category === "Property Invoice")
            .reduce((n, { invoice_amount }) => n + invoice_amount, 0),
          Party: tenantCount
            ?.filter((d) => d.category === "Parties Invoice")
            .reduce((n, { invoice_amount }) => n + invoice_amount, 0),
          Deposit: tenantCount
            ?.filter(
              (d) => d.category === "Tenant" && d.sub_category === "deposit"
            )
            .reduce((n, { invoice_amount }) => n + invoice_amount, 0),
        };
        objFinance.push(objTent);
        setFinancialData(objFinance);
      }
    });
  };

  const handleSearch = async (isReset = false, resetValue = {}) => {
    let payload = {};
    if (!isReset) {
      if (search && fromDate && toDate) {
        payload = {
          searchKey: search,
          start_date: moment(fromDate)?.format("MM-DD-YYYY"),
          end_date: moment(toDate)?.format("MM-DD-YYYY"),
        };

        // Backend response. Try, Catch
        try {
          let result;
          if (pathname.includes("communities")) {
            payload = {
              ...payload,
              community_id: param.communityId,
            };
            result = await dispatch(fetchSearchedFinancialsList(payload));
          } else if (pathname.includes("independent")) {
            payload = {
              ...payload,
              property_id: param.propertyId,
            };
            result = await dispatch(fetchSearchedFinancialsList(payload));
          } else {
            result = await dispatch(fetchSearchedFinancialsList(payload));
          }
          // Handling success response
          switch (result.meta.requestStatus) {
            case GLOBAL.REJECTED:
              // setModalDesc(result.payload);
              // setShowErrorModal(true);
              break;

            case GLOBAL.FULFILLED:
              // setModalDesc(result.payload.message);
              // setShowSuccessModal(true);
              setFinancialList(result?.payload);
              setFilteredFinancialList(result?.payload);
              break;
          }
        } catch (error) {
          // Handling error response
          console.error(error.message);
        }
      } else if (search) {
        payload = {
          searchKey: search,
        };

        await dispatch(fetchSearchedFinancialsList(payload));
      } else if (!search && fromDate && toDate) {
        payload = {
          searchKey: "",
          start_date: moment(fromDate)?.format("MM-DD-YYYY"),
          end_date: moment(toDate)?.format("MM-DD-YYYY"),
        };

        // Backend response. Try, Catch
        try {
          let result;
          if (pathname.includes("communities")) {
            payload = {
              ...payload,
              community_id: param.communityId,
            };
            result = await dispatch(fetchSearchedFinancialsList(payload));
          } else if (pathname.includes("independent")) {
            payload = {
              ...payload,
              property_id: param.propertyId,
            };
            result = await dispatch(fetchSearchedFinancialsList(payload));
          } else {
            result = await dispatch(fetchSearchedFinancialsList(payload));
          }
          // Handling success response
          switch (result.meta.requestStatus) {
            case GLOBAL.REJECTED:
              // setModalDesc(result.payload);
              // setShowErrorModal(true);
              break;

            case GLOBAL.FULFILLED:
              // setModalDesc(result.payload.message);
              // setShowSuccessModal(true);
              setFinancialList(result.payload);
              setFilteredFinancialList(result?.payload);

              break;
          }
        } catch (error) {
          // Handling error response
          console.error(error.message);
        }
      }
    }
    // else {
    //   payload = {
    //     ...resetValue,
    //   };
    // }
  };

  const handleClear = () => {
    let payload = {
      searchKey: "",
      start_date: new Date(startDate.getFullYear(), startDate.getMonth(), 1),
      end_date: new Date(),
    };
    setSearch(payload.searchKey);
    setFromDate(payload.start_date);
    setToDate(payload.end_date);

    handleSearch(true, payload);
    setCleared(!cleared);
  };

  // To check the any checkbox is true or not
  const checkAnyFilterChecked = (filter) => {
    return Object?.keys(filter)?.every(function (k) {
      return !filter[k];
    });
  };

  // SEND EMAIL FUNCTION
  const handleSendEmail = () => {
    setShowModal(!showModal);
  };

  /* Component function definition ends */

  /* Component useEffect starts */

  useEffect(() => {
    loadFinancialsList();
  }, [pathname, !cleared]);

  useEffect(() => {
    if (pathname.includes("communities") && communityFinancialList) {
      setFinancialList(communityFinancialList);
      setFilteredFinancialList([...communityFinancialList]);
      filterFinancialForGraph(communityFinancialList);
    } else if (pathname.includes("independent") && propertyFinancialList) {
      setFinancialList(propertyFinancialList);
      setFilteredFinancialList([...propertyFinancialList]);
      filterFinancialForGraph(propertyFinancialList);
    } else {
      setFinancialList(financialListData);
      setFilteredFinancialList([...financialListData]);
      filterFinancialForGraph(financialListData);
    }
  }, [
    pathname,
    financialListData,
    communityFinancialList,
    propertyFinancialList,
    !cleared,
  ]);

  useEffect(() => {
    const community = getUniqueValues(financialList, "community_name");
    const property = getUniqueValues(financialList, "property_name");
    // const location = getUniqueValues(financialList, "location");
    const amount = getUniqueValues(financialList, "invoice_amount");
    const category = getUniqueValues(financialList, "category");
    const subCat = getUniqueValues(financialList, "sub_category");
    const status = getUniqueValues(financialList, "status");

    const communityObject = {};
    community.forEach((prop) => (communityObject[prop] = false));
    setMultiSelectCommunity(communityObject);
    setCommunityData(community);

    const propertyObject = {};
    property.forEach((prop) => (propertyObject[prop] = false));
    setMultiSelectProperty(propertyObject);
    setPropertyData(property);

    const amountObject = {};
    amount
      ?.sort((a, b) => a - b)
      .forEach((prop) => (amountObject[prop] = false));
    setMultiSelectAmount(amountObject);
    setAmountData(amount?.sort((a, b) => a - b));

    // const tenantObject = {};
    // tenant.forEach((prop) => (tenantObject[prop] = false));
    // setMultiSelectTenant(tenantObject);
    // setTenantData(tenant);

    const categoryObject = {};
    category.forEach((prop) => (categoryObject[prop] = false));
    setMultiSelectCategory(categoryObject);
    setCategoryData(category);

    const subCatObject = {};
    subCat.forEach((prop) => (subCatObject[prop] = false));
    setMultiSelectSubCategory(subCatObject);
    setSubcategoryData(subCat);

    const statusObject = {};
    status.forEach((prop) => (statusObject[prop] = false));
    setMultiSelectStatus(statusObject);
    setStatusData1(status);
  }, [financialList]);

  useEffect(() => {
    if (
      multiSelectCommunity !== undefined ||
      multiSelectProperty !== undefined ||
      multiSelectStatus !== undefined ||
      // multiSelectTenant !== undefined ||
      multiSelectAmount !== undefined ||
      multiSelectCategory !== undefined ||
      multiSelectSubCategory !== undefined
    ) {
      let allUnCheckedCommunity = checkAnyFilterChecked(multiSelectCommunity);
      let allUnCheckedProperty = checkAnyFilterChecked(multiSelectProperty);
      // let allUnCheckedTenant = checkAnyFilterChecked(multiSelectTenant);
      let allUnCheckedAmount = checkAnyFilterChecked(multiSelectAmount);
      let allUnCheckedCategory = checkAnyFilterChecked(multiSelectCategory);
      let allUnCheckedSubCat = checkAnyFilterChecked(multiSelectSubCategory);
      let allUnCheckedStatus = checkAnyFilterChecked(multiSelectStatus);

      if (!allUnCheckedCommunity && multiSelectCommunity) {
        let selectedValue = Object.keys(multiSelectCommunity)?.filter(
          (key) => multiSelectCommunity[key]
        );

        const filteredCommunity = financialList?.filter((d) =>
          selectedValue?.includes(d.community_name)
        );

        setFilteredFinancialList(filteredCommunity);
      } else if (!allUnCheckedProperty && multiSelectProperty) {
        let selectedValue = Object.keys(multiSelectProperty)?.filter(
          (key) => multiSelectProperty[key]
        );

        const filteredProperty = financialList?.filter((d) =>
          selectedValue?.includes(d.property_name)
        );

        setFilteredFinancialList(filteredProperty);
      } else if (!allUnCheckedStatus && multiSelectStatus) {
        let selectedValue = Object.keys(multiSelectStatus)?.filter(
          (key) => multiSelectStatus[key]
        );

        const filteredStatus = financialList?.filter((d) =>
          selectedValue?.includes(d.status)
        );

        setFilteredFinancialList(filteredStatus);
      } else if (!allUnCheckedAmount && multiSelectAmount) {
        let selectedValue = Object.keys(multiSelectAmount)?.filter(
          (key) => multiSelectAmount[key]
        );

        const filteredAmount = financialList?.filter((d) =>
          selectedValue?.includes(d.invoice_amount)
        );

        setFilteredFinancialList(filteredAmount);
      } else if (!allUnCheckedCategory && multiSelectCategory) {
        let selectedValue = Object.keys(multiSelectCategory)?.filter(
          (key) => multiSelectCategory[key]
        );

        const filteredCat = financialList?.filter((d) =>
          selectedValue?.includes(d.category)
        );

        setFilteredFinancialList(filteredCat);
      } else if (!allUnCheckedSubCat && multiSelectSubCategory) {
        let selectedValue = Object.keys(multiSelectSubCategory)?.filter(
          (key) => multiSelectSubCategory[key]
        );

        const filteredSubCat = financialList?.filter((d) =>
          selectedValue?.includes(d.sub_category)
        );

        setFilteredFinancialList(filteredSubCat);
      }
    } else {
      setFilteredFinancialList(financialList);
    }
  }, [
    multiSelectCommunity,
    multiSelectProperty,
    multiSelectStatus,
    multiSelectAmount,
    // multiSelectLocation,
    multiSelectCategory,
    multiSelectSubCategory,
  ]);

  useEffect(() => {
    if (pathname.includes("communities") && communityFinancialList) {
      filterFinancialForGraph(financialList);
    } else if (pathname.includes("independent") && propertyFinancialList) {
      filterFinancialForGraph(financialList);
    } else {
      filterFinancialForGraph(financialList);
    }
  }, [!search, financialList]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      <BreadCrumb type="dashboard_financial" />
      <div className="financial-dashboard-wrapper">
        <div className="action-wrapper">
          <div>
            <img src={PrintIcon} alt="Print-Icon" />
            <span className="font_m fw_3 font_blue">Print</span>
          </div>

          <div onClick={handleSendEmail}>
            <img src={EnvelopeOutLinedBlue} alt="Print-Icon" />
            <span className="font_m fw_3 font_blue">Email</span>
          </div>
        </div>
        <div className="header">
          <Header />
        </div>

        {financialList?.length > 0 && (
          <div className="report-chart-wrapper">
            <div className="detail-box">
              <div>
                <p className="font_l fw_4">INCOME / EXPENSE</p>
              </div>
              <div>
                <BarChartGraph financialData={financialData} />
              </div>
            </div>
          </div>
        )}

        <div className="search-box mt-4 mb-4">
          <div className="search">
            <Searchbar
              setQuery={setSearch}
              query={search}
              className="dash-search"
            />
          </div>

          <div className="date">
            <div>
              <CustomDateInput
                label="From"
                value={fromDate}
                minDate={false}
                customOnchange={(date) => setFromDate(date)}
                required={true}
              />
            </div>

            <div>
              <CustomDateInput
                label="To"
                value={toDate}
                minDate={false}
                customOnchange={(date) => setToDate(date)}
                required={true}
              />
            </div>
          </div>
          <div className="btn-wrapper">
            <button
              onClick={() => handleSearch()}
              // disabled={!search}
            >
              Apply
            </button>
            <button className="btn-clear" onClick={handleClear}>
              Clear
            </button>
          </div>
        </div>

        <div className="financial-table-wrapper">
          <FinancialTable
            financeListData={currentItemsList}
            communityData={communityData}
            multiSelectCommunity={multiSelectCommunity}
            setMultiSelectCommunity={setMultiSelectCommunity}
            propertyData={propertyData}
            multiSelectProperty={multiSelectProperty}
            setMultiSelectProperty={setMultiSelectProperty}
            amount={amountData}
            multiSelectAmount={multiSelectAmount}
            setMultiSelectAmount={setMultiSelectAmount}
            // tenant={tenantData}
            // multiSelectTenant={multiSelectTenant}
            // setMultiSelectTenant={setMultiSelectTenant}
            category={categoryData}
            multiSelectCategory={multiSelectCategory}
            setMultiSelectCategory={setMultiSelectCategory}
            subCat={subcategoryData}
            multiSelectSubCategory={multiSelectSubCategory}
            setMultiSelectSubCategory={setMultiSelectSubCategory}
            statusData1={statusData1}
            multiSelectStatus={multiSelectStatus}
            setMultiSelectStatus={setMultiSelectStatus}
          />

          {filteredFinancialList?.length > 0 && (
            <Pagination
              itemsPerPage={itemsPerPage}
              totalItems={filteredFinancialList?.length}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          )}
        </div>

        {/* EMAIL MODAL */}
        <EmailModal
          showModal={showModal}
          onSuccess={() => setShowModal(!showModal)}
          onClose={() => setShowModal(!showModal)}
        />
      </div>
    </>
  );
};
/* Component ends */

export default FinancialDashboard;
