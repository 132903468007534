/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import moment from "moment";
import GLOBAL from "../../../../../setup/constants/global";
import { useSelector } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Table } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { EyeFilledBlue } from "../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import InspectionItemFileViewer from "./InspentionItemFileViewer";
import IconButton from "../../../../ui/button/IconButton/IconButton";
/* Import local pages and component ends */

/* Component starts */
const InspectionReport = (props) => {
  /* Props destructuring starts */
  const { moveOut, property } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [showInspectionFiles, setShowInspectionFiles] = useState(false);
  const [selectedInspectionItem, setSelectedInspectionItem] = useState(null);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="section container_md">
      <h3 className="table-heading font_m">Inspection Report</h3>

      <div className="custom-table-wrapper">
        {moveOut?.inspection_items?.length > 0 && (
          <Table
            className="custom-table font_s unit-table"
            borderless
            responsive
          >
            <thead>
              <tr>
                <th>Description</th>
                <th>Estimate ({property?.currency})</th>
                <th>Inspection Files</th>
              </tr>
            </thead>
            <tbody>
              {moveOut?.inspection_items?.map((item, index) => (
                <tr>
                  <td>{item.description}</td>
                  <td>{item.estimate?.toLocaleString("en-US")}</td>
                  <td>
                    <IconButton onClick={() => {
                      setSelectedInspectionItem(item)
                      setShowInspectionFiles(true)
                    }}>
                      <img
                        src={EyeFilledBlue}
                        className="icon-md"
                        alt="View Files"
                      />
                    </IconButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}

        {(moveOut?.inspection_items?.length === 0 ||
          moveOut?.inspection_items === undefined) && (
          <div className=" text-center">
            <h3 className=" font_m font_yellow fw_6">No Data</h3>
          </div>
        )}
      </div>
      <InspectionItemFileViewer
        showInspectionFiles={showInspectionFiles}
        selectedInspectionItem={selectedInspectionItem}
        onClose={() => setShowInspectionFiles(false)}
      />
    </div>
  );
};
/* Component ends */

export default InspectionReport;
