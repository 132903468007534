/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BsFillShareFill } from "react-icons/bs";
import { RWebShare } from "react-web-share";
import { API_URL_DEV } from "../../../setup/config/devKeys";
/* Import configuration ends */

/* Import redux slices component starts */
import { revokeGuestPass } from "../../../setup/store/slices/guestPass";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */

/* Import image and SVG ends */

/* Import local pages and component starts */
import GLOBAL from "../../../setup/constants/global";
// import CustomButton from "../../../components/ui/button/CustomButton/CustomButton";
import { ShareFilledBlue, VisitorPass } from "../../../setup/constants/images";
import ConfirmationModal from "../../../components/ui/modal/ConfirmationModal/ConfirmationModal";
import ErrorModal from "../../../components/ui/modal/ErrorModal/ErrorModal";
import IconButton from "../../../components/ui/button/IconButton/IconButton";
/* Import local pages and component ends */

/* Component starts */
const Report = (props) => {
  /* Props destructuring starts */
  const { incident } = props;
  console.log(incident, "[inc]");
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [reportId, setReportId] = useState("");
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [modalDesc, setModalDesc] = useState("");
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  const shareHandler = async () => {
    const data = {
      title: "MDN",
      text: "Learn web development on MDN!",
      url: "https://developer.mozilla.org",
    };
    // Check if navigator.share is supported by the browser
    if (navigator.share) {
      console.log("Congrats! Your browser supports Web Share API");
      try {
        await navigator.share(data);
        console.log("Shared....");
        // resultPara.textContent = "MDN shared successfully";
      } catch (err) {
        console.log(err);
        // resultPara.textContent = `Error: ${err}`;
      }
    } else {
      console.log("Sorry! Your browser does not support Web Share API");
    }
  };

  const handleEdit = (id) => {
    navigate(`edit`);
  };

  const handleRevokePass = async (id) => {
    setReportId(id);
    setModalDesc("Are you sure you want to revoke this pass ?");
    setShowConfirmationModal(true);
  };

  // On confirming Revoke
  const confirmRevoke = async () => {
    // Collecting or creating payload data to be sent

    const payload = {
      id: reportId,
    };

    // Backend response. Try, catch
    try {
      const result = await dispatch(revokeGuestPass(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setModalDesc(result.payload);
          setShowConfirmationModal(false);
          setShowErrorModal(true);
          break;
        case GLOBAL.FULFILLED:
          setModalDesc("Guest Pass Successfully Revoked");
          setShowConfirmationModal(false);
          navigate(-1);
          break;
      }
    } catch (error) {
      // Handling error response
      console.log("error", error);
    }
  };

  /* Component function definition ends */

  /* Component useEffect starts */

  // Setting role after all user roles loaded

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="contractor-unit-detail-box">
      <div className="log-header">
        <div className="img-wrapper">
          <img
            src={incident?.image !== undefined ? incident?.image : VisitorPass}
            alt="Visitor-Pass"
          />
        </div>

        <div className="detail">
          <h3 className="num font_m"> {incident?.visitorpassId} </h3>
          <h4 className="fw_5 font_xxl">
            <span>{incident?.guest_name && incident?.guest_name}</span>
          </h4>
          <p className="font_m font_grey">
            Type <span>{incident?.type}</span>
          </p>
        </div>
      </div>

      <hr />

      <div className="visitor-info">
        <div>
          <p className="fw_3 font_m font_grey">
            Mobile Number <br />
            <span className="fw_3 font_m">
              {incident?.mobile_number !== undefined
                ? incident?.mobile_number
                : "-"}
            </span>
          </p>
        </div>

        <div className="reason-info">
          <p className="fw_3 font_m font_grey">
            Reason For Access
            <br />
            <span className="fw_3 font_m">
              {incident?.reason !== undefined ? incident?.reason : "-"}
            </span>
          </p>
        </div>

        <div className="reason-info">
          <p className="fw_3 font_m font_grey">
            License Plate Number
            <br />
            <span className="fw_3 font_m">
              {incident?.car_number !== undefined ? incident?.car_number : "-"}
            </span>
          </p>
        </div>
      </div>

      <hr />

      <div className="time-info">
        <div>
          <p className="fw_3 font_m font_grey">
            Start Date
            <br />
            <span className="fw_3 font_m">
              {moment(incident?.start_date).format(DATE_FORMAT)}
            </span>
          </p>
        </div>
        <div>
          <p className="fw_3 font_m font_grey">
            End Date
            <br />
            <span className="fw_3 font_m">
              {moment(incident?.end_date).format(DATE_FORMAT)}
            </span>
          </p>
        </div>
        <div>
          <p className="fw_3 font_m font_grey">
            Status
            <br />
            <span
              className={
                incident?.contract_status === "approved"
                  ? "approve"
                  : incident?.contract_status === "rejected" ||
                    incident?.contract_status === "expired"
                  ? "reject"
                  : "font_yellow"
              }
            >
              {incident?.contract_status?.charAt(0).toUpperCase() +
                incident?.contract_status?.slice(1)}
            </span>
          </p>
        </div>
      </div>

      {incident?.rejected_reason && (
        <>
          <hr />
          <div className="time-info">
            <div>
              <p className="fw_4 font_m font_grey">
                Rejected Reason
                <br />
                <span className="fw_4 font_m">{incident?.rejected_reason}</span>
              </p>
            </div>
          </div>
        </>
      )}

      {incident?.updated_by !== undefined && incident?.updated_by !== null && (
        <>
          <hr />

          <div className="request_by">
            <p className="fw_3 font_m font_grey">
              Updated By <br />
              <span className="fw_3 font_m px-3">
                {incident?.updated_by?.first_name}{" "}
                {incident?.updated_by?.last_name}
              </span>
            </p>
          </div>
        </>
      )}

      <hr />
      <div className="actions-btn">
        {
          //incident?.status === "OnRequest" &&
          //incident?.guest_status === "active" ||
          incident?.contract_status !== "expired" &&
            incident?.contract_status !== "revoked" && (
              <>
                <div>
                  <button
                    className="edit-btn"
                    onClick={() => handleEdit(incident?._id)}
                  >
                    Edit
                  </button>
                </div>

                <div>
                  <button
                    className="revoke-btn"
                    onClick={() => handleRevokePass(incident?._id)}
                  >
                    Revoke
                  </button>
                </div>
              </>
            )
        }

        {incident?.contract_status !== "expired" && (
          <div className="btn">
            <RWebShare
              data={{
                text: "Dgrnte Guest Pass Detail",
                url: `${API_URL_DEV}/shared_visitor_pass/${incident?._id}`,
                // url: `https://devone.dgrnte.net:8000/shared_visitor_pass/${incident?._id}`,
                title: "Share Dgrnte Guest Pass",
              }}
              sites={["mail", "whatsapp", "copy"]}
              onClick={() => console.log("shared successfully!")}
            >
              <IconButton
                onClick={() => shareHandler()}
                className="action-button"
              >
                <img
                  src={ShareFilledBlue}
                  className="h_100"
                  alt="View Details"
                />
              </IconButton>
            </RWebShare>
          </div>
        )}
      </div>

      {/* Confirmation Modal */}
      <ConfirmationModal
        showModal={showConfirmationModal}
        description={modalDesc}
        title="Confirm Revoke"
        onClose={() => setShowConfirmationModal(false)}
        onConfirm={confirmRevoke}
      />

      {/* Error Modal */}
      <ErrorModal
        showModal={showErrorModal}
        description={modalDesc}
        title="Error"
        onClose={() => setShowErrorModal(false)}
      />
    </div>
  );
};
/* Component Ends */
export default Report;
