/**
 *
 */

import React, { useState, useEffect } from "react";
// import DetailSection1 from "./DetailSection1";
// import DetailSection2 from "./DetailSection2";
import GLOBAL from "../../../../../../setup/constants/global";
import moment from "moment";
import FileViewerModal from "../../../../../ui/modal/FileViewerModal/FileViewerModal";
import Image from "../../../../../ui/other/Image/Image";

/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
// import DetailSection1 from "./DetailSection1";
// import DetailSection2 from "./DetailSection2";
// import DetailSection3 from "./DetailSection3";
// import DetailSection4 from "./DetailSection4";
// import DetailSection5 from "./DetailSection5";
// import DetailSection8 from "./DetailSection8";
/* Import local pages and component ends */

/* Component starts */
const DetailCard = (props) => {
  /* Props destructuring starts */
  const { complaint } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */

  const [showFileViewer, setShowFileViewer] = useState(false);
  const [file, setFile] = useState({});

  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */

  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const getStatus = (status) => {
    let txt_status = status;
    let statusColor = "";
    switch (status) {
      case GLOBAL.SECURITY_STATUS.PENDING_SO_ASSIGNMENT:
      case GLOBAL.SECURITY_STATUS.PENDING_DECISION:
        statusColor = "font_yellow";
        txt_status = "Pending Decision";
        break;
      case GLOBAL.SECURITY_STATUS.ESCALATED:
        statusColor = "font_yellow";
        txt_status = "Escalated";
        break;
      case GLOBAL.SECURITY_STATUS.CLOSED:
        statusColor = "font_green";
        break;
      case GLOBAL.SECURITY_STATUS.SO_ASSIGNED:
        statusColor = "font_yellow";
        txt_status = "Security Officer Assigned";
        break;
      case GLOBAL.SECURITY_STATUS.RESOLVED:
        statusColor = "font_green";
        txt_status = "resolved";
        break;
    }
    return (
      <span className={`font_s fw_4 ${statusColor}`}>
        {txt_status?.toUpperCase()}
      </span>
    );
  };
  /* Component function definition ends */

  /* Component useEffect starts */

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="detail-card">
      <h3 className="num fw_6 font_l font_grey">
        Ticket No. <span className="font_blue">{complaint?.ticket_id}</span>
      </h3>

      <p className="summary font_m font_grey fw_5 my-2">
        Description
        <br />
        <span className="fw_6 font_l">{complaint?.description}</span>
      </p>
      <div className="date-wrapper">
        <div>
          <span className="font_m fw_3 font_grey">Date & Time</span>
          <br />
          <span className="font_m fw_3">
            {moment(complaint?.createdAt).format(DATE_TIME_FORMAT)}
          </span>
        </div>

        <div>
          <span className="font_m fw_3 font_grey">Type Of Unit</span>
          <br />
          <span className="font_m fw_3">
            {complaint?.unit_id?.unit_type?.name}
          </span>
        </div>
      </div>
      <hr />
      <div className="location-wrapper">
        <div>
          <span className="font_m fw_3 font_grey">Tenant Name</span>
          <br />{" "}
          <span className="font_m fw_6 font_blue">
            {complaint?.tenant_id?.first_name} {complaint?.tenant_id?.last_name}
          </span>
        </div>

        <div>
          <span className="font_m fw_3 font_grey">Unit</span>
          <br />{" "}
          <span className={`font_m fw_6 font_blue`}>
            {complaint?.unit_id?.unit_name}
          </span>
        </div>

        <div>
          <span className="font_m fw_3 font_grey">Status</span>
          <br />{" "}
          <span className={`font_m fw_3 `}>{getStatus(complaint?.status)}</span>
        </div>
      </div>
      <hr />
      {complaint?.documents.length > 0 && (
        <>
          <div className="image-wrapper">
            {complaint?.documents.map((image, index) => (
              <div className="proof-img" key={index}>
                <Image
                  key={index}
                  className="media"
                  imgClass="thumbnail"
                  src={image?.url}
                  videoThumbnail={image?.type2 === "video"}
                  alt="Attatched Media"
                  objectFit="cover"
                  onClick={() => {
                    setFile(image);
                    setShowFileViewer(true);
                  }}
                />

                {/* <Image
                  key={index}
                  className="media"
                  imgClass="thumbnail"
                  src={image?.url}
                  alt="Attatched Media"
                  objectFit="cover"
                  videoThumbnail={image?.type2 === "video"}
                  onClick={() => {
                    setFile(image);
                    setShowFileViewer(true);
                  }}
                /> */}
              </div>
            ))}
          </div>

          <hr />
        </>
      )}
      <div className="ticket-wrapper">
        <p className="font_m fw_4 font_grey">
          Ticket Assign{" "}
          <span className="font_m fw_3">
            {complaint?.assigned_to !== null
              ? `${complaint?.assigned_to?.first_name}${" "}
            ${complaint?.assigned_to?.last_name} ${
                  complaint?.assigned_to?.role_id?.name === "Owner"
                    ? complaint?.assigned_to?.role_id?.name
                    : complaint?.assigned_to?.role_id?.name
                        ?.split(" ")
                        .map((role) => role[0])
                        .join("")
                }`
              : "NA"}
          </span>
        </p>
      </div>
      <div className="last-update bg_black">
        <p className="font_m fw_3 font_grey">
          Request Date{" "}
          <span className="font_s fw_4">
            {moment(complaint?.createdAt).format(DATE_TIME_FORMAT)}
          </span>
        </p>
      </div>

      {/* File viewer modal */}
      <FileViewerModal
        show={showFileViewer}
        type={file.type}
        file={file.url}
        onClose={() => {
          setShowFileViewer(false);
          setFile({});
        }}
      />
    </div>
  );
};
/* Component ends */

export default DetailCard;
