/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import GLOBAL from "../../setup/constants/global";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  fetchPropertyDetailByID,
  getPropertyDetail,
} from "../../setup/store/slices/propertySlice";
import { fetchContractRenewalDetails } from "../../setup/store/slices/contractSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import ContractRenewal from "../../components/module/Contracts/ContractRenewal/ContractRenewal";
import BreadCrumb from "../../components/ui/other/BreadCrumb/BreadCrumb";
/* Import local pages and component ends */

/* Component starts */
const ContractRenewalPage = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [result, setResult] = useState(null);
  const [sequence, setSequence] = useState([]);
  const [sequenceNumber, setSequenceNumber] = useState(null);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const { propertyId, applicationID } = useParams();
  const property = useSelector(getPropertyDetail);
  const [searchParams] = useSearchParams();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  const initializeSequenceNumber = (data) => {
    let s = [];
    const isResidential = data?.is_unit_residential;
    const isCommercial = !data?.is_unit_residential;

    s.push("oldTenantContract_details");

    s.push("basic_details");
    if (isCommercial) {
      s.push("commercial_details");
    }
    if (data?.ids) {
      s.push("ids");
    }
    if (isResidential && data?.household_members) {
      s.push("household_members");
    }
    if (data?.disability) {
      s.push("disability");
    }
    if (data?.vehicles) {
      s.push("vehicles");
    }
    if (data?.pets) {
      s.push("pets");
    }
    if (data?.rental_history) {
      s.push("rental_history");
    }
    if (data?.voilationDetail) {
      s.push("violations");
    }

    if (data?.outStandingInvoices) {
      s.push("outstanding_invoices");
    }

    s.push("maintenances");

    if (data?.installments) {
      s.push("installments");
    }

    if (data?.contract?.actions > 0) {
      s.push("revision_requests");
    }
    s.push("initial_agreement");
    // s.push("contract");
    // if (data?.utilities) {
    //   s.push("utilities");
    // }
    s.push("final_agreement");
    // s.push("maintenance");
    // s.push("keys");

    setSequenceNumber(s);
  };

  const fetchContractDetails = async () => {
    // Backend response. Try, Catch
    try {
      const result = await dispatch(fetchContractRenewalDetails(applicationID));
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setSequence([]);
          setResult(null);
          break;

        case GLOBAL.FULFILLED:
          initializeSequenceNumber(result.payload.data);
          setSequence(result.payload.sequence);
          setResult(result.payload.data);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  // Fetching application useEffect
  useEffect(() => {
    dispatch(
      fetchPropertyDetailByID({
        property_id: propertyId,
      })
    );
    fetchContractDetails();
  }, [applicationID]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      <BreadCrumb type="renewal_tenant_detail" />
      {sequence?.length > 0 && result && (
        <ContractRenewal
          fetchContractDetails={fetchContractDetails}
          property={property}
          result={result}
          sequence={sequence}
          applicationID={applicationID}
          tenant_id={searchParams.get("tenant_id")}
          sequenceNumber={sequenceNumber}
        />
      )}
    </>
  );
};
/* Component ends */

export default ContractRenewalPage;
