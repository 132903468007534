/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  fetchUserRoles,
  getUserRoles,
} from "../../../setup/store/slices/masterSlice";
import { fetchUser, getUser } from "../../../setup/store/slices/authSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import Header from "../local-components/Header";
import Report from "../local-components/Report";
import T3IncidentReportForm from "./sub-component/T3IncidentReportForm";
/* Import local pages and component ends */

// Styles
import "./style.scss";
import moment from "moment";
import GLOBAL from "../../../setup/constants/global";
import T3IncidentReportBySO from "./sub-component/T3IncidentReportBySO";
import BreadCrumb from "../../../components/ui/other/BreadCrumb/BreadCrumb";
import {
  fetchIncidentBasedOnId,
  getSecurityIncidentData,
} from "../../../setup/store/slices/securitySlice";

/* Component starts */
const SecurityReport = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [role, setRole] = useState();
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const { id } = useParams();

  const { state } = useLocation();
  const dispatch = useDispatch();

  const userRoles = useSelector(getUserRoles);
  const user = useSelector(getUser);
  const incidentData = useSelector(getSecurityIncidentData);

  console.log(id, "{PP}", incidentData);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */

  const customRoles = ["Security Manager", "Property Manager"];

  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */

  useEffect(() => {
    dispatch(fetchIncidentBasedOnId(id));
    dispatch(fetchUserRoles());
    dispatch(fetchUser());
  }, [id]);

  // Setting role after all user roles loaded
  useEffect(() => {
    let userRole = userRoles.filter((d) => d._id === user?.role_id)[0].name;
    setRole(userRole);
  }, [userRoles]);

  console.log(role, "ROLE>");

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */

  return (
    <>
      <BreadCrumb type="security_incident_detail" />
      <div className="security-incident-wrapper">
        <div className="header">
          {incidentData?.type === "Incident" && <Header title={"Incident"} />}
        </div>

        <div className="report-wrapper container_sm">
          <div className="detail-box">
            <Report incident={incidentData} />
          </div>

          {incidentData?.assigned_to?.role_id?.name === role &&
            incidentData?.assigned_to?.role_id?.name !== "Security Manager" &&
            incidentData?.assigned_to?.first_name === user?.first_name &&
            incidentData?.action === "Need More Information" && (
              <div className="detail-box bg_grey mt-3">
                <T3IncidentReportBySO
                  role={role}
                  incidentID={incidentData?._id}
                />
              </div>
            )}

          {customRoles.includes(role) &&
            incidentData?.assigned_to?.role_id?.name === role &&
            incidentData?.status === "Open" && (
              <div className="detail-box bg_grey mt-3">
                <T3IncidentReportForm
                  role={role}
                  incident={incidentData}
                  incidentID={id}
                />
              </div>
            )}

          {/* TIME-LINE */}
          {incidentData?.logs?.map(
            (timeLine, index) =>
              timeLine?.tenant?.role_id?.name !== "Tenant" && (
                <div
                  className={`detail-box-timeLine mt-3  
                ${index === 0 ? "bg_lightgreen" : "bg_grey"}
                `}
                  key={index}
                >
                  <div className="main-timeline-wrapper">
                    <div className="dot">
                      <div className="dot-1">
                        <span className="dot-2"></span>
                      </div>
                      <div className="vl"></div>
                    </div>
                    <div className="detail">
                      <div className="data">
                        <div className="name">
                          <p className="font_m fw_4 font_grey">
                            {timeLine?.user?.role_id?.name ||
                              timeLine?.tenant?.role_id?.name}{" "}
                            <span className="font_m fw_4 ">
                              {timeLine?.user?.first_name ||
                                timeLine?.tenant?.first_name}{" "}
                              {timeLine?.user?.last_name ||
                                timeLine?.tenant?.last_name}
                            </span>
                          </p>
                        </div>
                        <div className="current-status">
                          {timeLine?.comment !== undefined &&
                            timeLine?.comment !== "" && (
                              <p className="font_s fw_4 font_grey">
                                Comment :{" "}
                                <span className="font_m fw_4 font_white">
                                  {timeLine?.comment}
                                </span>
                              </p>
                            )}
                          <p className="font_m fw_4 font_grey">
                            Action :{" "}
                            <span className="font_m fw_4 font_white">
                              {timeLine?.action}
                            </span>
                          </p>
                          {/* {timeLine.updated_by !== undefined && (
                          <p className="font_s fw_4 font_grey">
                            {/* {timeLine?.currentStatus.toUpperCase()} 
                            Updated By :{" "}
                            <span className="font_xs fw_3">
                              {timeLine?.updated_by?.first_name}{" "}{timeLine?.updated_by?.last_name}
                            </span>
                          </p>
                        )} */}
                        </div>
                      </div>

                      <div className="date">
                        <p className="font_m fw_4 font_grey">
                          {moment(timeLine.date).format(DATE_TIME_FORMAT)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )
          )}
        </div>
      </div>
    </>
  );
};
/* Component Ends */
export default SecurityReport;
