export const formKeys = {
  cheque_number: "",
  cheque_date: new Date(),
  bank_name: "",
  invoice_doc: null,
  cheque_amount: "",

  receipt_number: "",
  amount: "",
  receipt_date: new Date(),
  issuer: "",
  account_number: "",

  transaction_id: "",
  transaction_amount: "",
  payment_date: new Date(),
};

export const validations = (values, isPaid, paymentType) => {
  const error = {};
  if (isPaid) {
    if (paymentType === "cash") {
      if (values.receipt_number === "") {
        error.receipt_number = "Receipt Number is required";
      }
      if (values.amount === "") {
        error.amount = "Amount is required";
      }
      if (values.invoice_doc === null) {
        error.invoice_doc = "Invoice Doc is required";
      }
    }
    if (paymentType === "cheque") {
      if (values.cheque_number === "") {
        error.cheque_number = "Cheque Number is required";
      }
      if (values.cheque_amount === "") {
        error.cheque_amount = "Cheque Amount is required";
      }
      if (values.bank_name === "") {
        error.bank_name = "Bank Name is required";
      }
      if (values.issuer === "") {
        error.issuer = "Issuer Name is required";
      }
      if (values.account_number === "") {
        error.account_number = "Account Number is required";
      }
      if (values.invoice_doc === null) {
        error.invoice_doc = "Cheque Doc is required";
      }
    }
    if (paymentType === "online") {
      if (values.transaction_id === "") {
        error.transaction_id = "Transaction ID is required";
      }
      if (values.transaction_amount === "") {
        error.transaction_amount = "Amount is required";
      }
    }
  }
  return error;
};
