/**
 *
 */

import React from "react";

/* Import configuration starts */
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import { setLoading } from "../../../setup/store/slices/unpersistedSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import MUI component starts */
/* Import MUI component ends */

/* Import image and SVG starts */
import {
  Unit,
  License,
  SettingsFilledBlue,
  Advertising,
  Annoucments,
  StaffSchedule,
  Dashboard,
} from "../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../../components/ui/button/IconButton/IconButton";
/* Import local pages and component ends */

/* Component starts */
const Modules = (props) => {
  /* Props destructuring starts */
  const { communityId } = props;
  /* Props destructuring ends  */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  const dispatch = useDispatch();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      <div className="options">
        {/* Announcments */}
        <IconButton
          className="box d_block"
          onClick={() => {
            navigate("annoucements");
          }}
        >
          <div className="icon text-center mx-auto">
            <img className="h_100" src={Annoucments} alt="Internal Icon" />
          </div>
          <p className="text font_m fw_6 font_grey">Announcements</p>
        </IconButton>

        {/* Dashboards  */}
        {/* <IconButton
          className="box d_block"
          // onClick={() => {
          //   navigate("dashboard");
          // }}
        >
          <div className="icon text-center mx-auto">
            <img className="h_100" src={Dashboard} alt="Unit Icon" />
          </div>
          <p className="text font_m fw_6 font_grey">Dashboards</p>
          <span className="text font_m fw_6 font_yellow my-2">
            Comming Soon!
          </span>
        </IconButton> */}

        {/* Manage Community Facility */}
        {/* <IconButton
          className="box d_block"
          // onClick={() => {
          //   navigate("units");
          // }}
        >
          <div className="icon text-center mx-auto">
            <img className="h_100" src={Unit} alt="Unit Icon" />
          </div>
          <p className="text font_m fw_6 font_grey">
            Manage <br /> Community <br /> Facilities
          </p>
          <span className="text font_m fw_6 font_yellow my-2">
            Comming Soon!
          </span>
        </IconButton> */}

        {/* Advertising */}
        {/* <IconButton
          className="box d_block"
          // onClick={() => {
          //   navigate("/property/advertising/list");
          // }}
        >
          <div className="icon text-center mx-auto">
            <img className="h_100" src={Advertising} alt="Internal Icon" />
          </div>
          <p className="text font_m fw_6 font_grey">Advertising</p>
          <span className="text font_m fw_6 font_yellow my-2">
            Comming Soon!
          </span>
        </IconButton> */}

        {/* Community Settings */}
        {/* <IconButton
          className="box d_block"
          // onClick={() => {
          //   dispatch(setLoading(true));
          //   navigate("settings");
          // }}
        >
          <div className="icon text-center mx-auto">
            <img className="h_100" src={SettingsFilledBlue} alt="Unit Icon" />
          </div>
          <p className="text font_m fw_6 font_grey">
            Community <br /> Settings
          </p>
          <span className="text font_m fw_6 font_yellow my-2">
            Comming Soon!
          </span>
        </IconButton> */}

        {/* Staff Schedule */}
        {/* <IconButton
          className="box d_block"
          // onClick={() => {
          //   navigate("/property/staff-scheduling");
          // }}
        >
          <div className="icon text-center mx-auto">
            <img className="h_100" src={StaffSchedule} alt="Unit Icon" />
          </div>
          <p className="text font_m fw_6 font_grey">
            Staff <br /> Scheduling
          </p>
          <span className="text font_m fw_6 font_yellow my-2">
            Comming Soon!
          </span>
        </IconButton> */}

        {/* Manage License */}
        {/* <IconButton
          className="box d_block "
          // onClick={() => {
          //   navigate("license/assign");
          // }}
        >
          <div className="icon text-center mx-auto">
            <img className="h_100 font_grey" src={License} alt="Unit Icon" />
          </div>
          <p className="text font_m fw_6 font_grey">
            Manage <br /> License
          </p>
          <span className="text font_m fw_6 font_yellow my-2">
            Comming Soon!
          </span>
        </IconButton> */}
      </div>

      {/* <hr /> */}

      {/* FUTURE FEATURES */}
      {/* <div className="future-options">
        
      </div> */}
    </>
  );
};
/* Component ends */

export default Modules;
