/**
 *
 */

import React from "react";

import "./style.scss";

/* Component starts */
const IconButton = ({ children, className = "", type = "button", ...rest }) => {
  /* ************* Component rendering. JSX code ************* */
  return (
    <button type={type} className={`custom-icon-btn ${className}`} {...rest}>
      {children}
    </button>
  );
};
/* Component ends */

export default IconButton;
