/**
 * @auther Abdul Ahad <abdulahad.ss@smartdatainc.net>
 * Slice to manage license
 */

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../axios";
import { API_URL } from "../../config/devKeys";
import { fetchUser } from "./authSlice";
import { setLoading } from "./unpersistedSlice";
import { fetchPropertyDetailByID } from "./propertySlice";

const initialState = {
  payableAmount: 0,
  taxCalculatedAmt: 0,
  taxIncludedAmt: 0,
};

export const purchaseLicense = createAsyncThunk(
  "license/purchase",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/license/purchase",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        await dispatch(fetchUser());
        return data.data;
      } else {
        return rejectWithValue(data);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const licenseAssignToProperty = createAsyncThunk(
  "license/assignToProperty",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { property_id } = payload;
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/license/assignToProperty",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );

      if (data.success) {
        await dispatch(fetchUser());
        await dispatch(fetchPropertyDetailByID({ property_id }));
        dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const licenseUnassignFromProperty = createAsyncThunk(
  "license/unassignFromProperty",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { property_id } = payload;
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/license/unassignFromProperty",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        await dispatch(fetchUser());
        await dispatch(fetchPropertyDetailByID({ property_id }));
        dispatch(setLoading(false));
        return data.data;
      } else {
        dispatch(setLoading(false));
        return rejectWithValue(data);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const removeLicenseFromAccount = createAsyncThunk(
  "license/delete",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.post(API_URL + "/license/delete", payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        },
      });
      dispatch(setLoading(false));
      if (data.success) {
        dispatch(fetchUser());
        return data.data;
      } else {
        return rejectWithValue(data);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const fetchLicenseAmount = createAsyncThunk(
  "license/amount",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await axios.get(API_URL + "/license/amount/" + payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        },
      });
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data);
      }
    } catch (error) {
      return rejectWithValue(error?.response);
    }
  }
);

export const fetchCalculatedLicenseAmount = createAsyncThunk(
  "license/calculate_tax",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await axios.post(
        API_URL + "/license/calculcate_tax",
        { number_of_license: payload },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data);
      }
    } catch (error) {
      return rejectWithValue(error?.response);
    }
  }
);

export const licenseSlice = createSlice({
  name: "license",
  initialState,
  extraReducers: {
    [fetchLicenseAmount.fulfilled]: (state, action) => {
      state.payableAmount = action.payload.payableAmount;
    },
    [fetchCalculatedLicenseAmount.rejected]: (state, action) => {
      state.taxCalculatedAmt = 0;
    },
    [fetchCalculatedLicenseAmount.fulfilled]: (state, action) => {
      state.taxCalculatedAmt = action.payload;
      state.taxIncludedAmt = action?.payload?.amount_total;
    },
  },
});

export const getPayableAmount = (state) => state.license.payableAmount;
export const getTaxCalculatedAmt = (state) => state.license.taxCalculatedAmt;
export const getTaxIncludedAmount = (state) => state.license.taxIncludedAmt;

export default licenseSlice.reducer;
