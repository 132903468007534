/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import MUI component starts */
/* Import MUI component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import PDFViewerModal from "../PDFViewerModal/PDFViewerModal";
import ImageViewerModal from "../ImageViewerModal/ImageViewerModal";
import VideoPlayerModal from "../VideoPlayerModal/VideoPlayerModal";
/* Import local pages and component ends */

/* Component starts */
const FileViewerModal = (prop) => {
  /* Props destructuring starts */
  const { show, file, type, onClose, multipleImage } = prop;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const lowerCaseType = type?.toLowerCase();
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    if (
      file &&
      lowerCaseType &&
      lowerCaseType !== "pdf" &&
      lowerCaseType !== "jpg" &&
      lowerCaseType !== "jpeg" &&
      lowerCaseType !== "png" &&
      lowerCaseType !== "mp4" &&
      lowerCaseType !== "gif"
    ) {
      const fileName = "File_DGRNTE";
      const anchorTag = document.createElement("a");
      anchorTag.href = file;
      anchorTag.setAttribute("download", fileName);
      document.body.appendChild(anchorTag);
      anchorTag.click();
      anchorTag.remove();
      onClose();
    }
  }, [type, file]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      {lowerCaseType === "pdf" ? (
        // PDF Viewer
        <PDFViewerModal show={show} onClose={onClose} file={file} />
      ) : lowerCaseType === "jpg" ||
        lowerCaseType === "jpeg" ||
        lowerCaseType === "png" ||
        lowerCaseType === "gif" ? (
        // Image Viewer
        <ImageViewerModal
          visible={show}
          onClose={onClose}
          images={[{ src: file, alt: "" }]}
        />
      ) : lowerCaseType === "mp4" ? (
        // Video Player
        <VideoPlayerModal show={show} file={file} onClose={onClose} />
      ) : (
        ""
      )}
    </>
  );
};
/* Component ends */

export default FileViewerModal;
