/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useNavigate } from "react-router-dom";
import moment from "moment";
/* Import configuration ends */

/* Import redux slices component starts */

/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Table } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { EyeFilledBlue } from "../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import GLOBAL from "../../../setup/constants/global";
import IconButton from "../../../components/ui/button/IconButton/IconButton";
import CheckboxMultiSelectDropdown from "../../../components/ui/input/CheckboxMultiSelectDropdown/CheckboxMultiSelectDropdown";
/* Import local pages and component ends */

/* Component starts */
const ViolationTable = (props) => {
  /* Props destructuring starts */
  const {
    violationListData,
    multiSelectCommunity,
    setMultiSelectCommunity,
    communityData,
    propertyData,
    multiSelectProperty,
    setMultiSelectProperty,
    violator,
    multiSelectViolator,
    setMultiSelectViolator,
    incident,
    multiSelectIncident,
    setMultiSelectIncident,
    issuer,
    multiSelectIssuer,
    setMultiSelectIssuer,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */

  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();

  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  // To View The Item Report
  const handleViewReport = async (item) => {
    if (item?.community_id) {
      navigate(
        `/communities/${item?.community_id}/properties/${item?.property_id}/violations/${item?.issueVio_id}`
      );
    } else {
      navigate(
        `/independent/properties/${item?.property_id}/violations/${item?.issueVio_id}`
      );
    }
  };

  /* Component function definition ends */

  /* Component useEffect starts */

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */

  return (
    <div className="custom-table-wrapper violations-table">
      {violationListData?.length > 0 && (
        <Table className="custom-table font_s" borderless responsive>
          {/* Table Heading */}
          <thead>
            <tr>
              <th>
                <CheckboxMultiSelectDropdown
                  multiSelectData={multiSelectCommunity}
                  setMultiSelectData={setMultiSelectCommunity}
                  optionLabel={communityData}
                  label={"Community"}
                />
              </th>
              <th>
                <CheckboxMultiSelectDropdown
                  multiSelectData={multiSelectProperty}
                  setMultiSelectData={setMultiSelectProperty}
                  optionLabel={propertyData}
                  label={"Property"}
                />
              </th>
              <th>Ticket</th>
              <th>Date</th>
              <th>Subject</th>
              <th>
                <CheckboxMultiSelectDropdown
                  multiSelectData={multiSelectViolator}
                  setMultiSelectData={setMultiSelectViolator}
                  optionLabel={violator}
                  label={"Violator"}
                />
              </th>
              <th>
                <CheckboxMultiSelectDropdown
                  multiSelectData={multiSelectIssuer}
                  setMultiSelectData={setMultiSelectIssuer}
                  optionLabel={issuer}
                  label={"Issuer"}
                />
              </th>
              <th>
                <CheckboxMultiSelectDropdown
                  multiSelectData={multiSelectIncident}
                  setMultiSelectData={setMultiSelectIncident}
                  optionLabel={incident}
                  label={"Incident"}
                />
              </th>
              <th className="text-start">Action</th>
            </tr>
          </thead>
          {/* Table Body */}
          {violationListData?.length > 0 && (
            <tbody>
              {violationListData?.map((item, index) => (
                <tr key={index}>
                  {/* Community Name */}
                  <td>
                    {item?.community_name !== undefined &&
                    item?.community_name?.length > 0
                      ? item?.community_name
                      : "-"}
                  </td>

                  {/* Property Name */}
                  <td>
                    {item?.property_name !== undefined
                      ? item?.property_name
                      : "-"}
                  </td>

                  {/*  ID */}
                  <td>{item?.ticket}</td>

                  {/* Date */}
                  <td>{moment(item?.date).format(DATE_FORMAT)}</td>

                  {/* Subject */}
                  <td>{item?.subject}</td>

                  {/* Violator */}
                  <td>{item?.violator}</td>

                  {/* Issuer */}
                  <td>{item?.issuer}</td>

                  {/* Incident */}
                  <td>
                    {item?.incident !== undefined
                      ? item?.incident.toString()
                      : "-"}
                  </td>

                  {/* Actions */}
                  <td className="text-start txt-nowrap">
                    <IconButton
                      onClick={() => handleViewReport(item)}
                      className="action-button"
                    >
                      <img
                        src={EyeFilledBlue}
                        className="h_100"
                        alt="View Details"
                      />
                    </IconButton>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </Table>
      )}

      {violationListData?.length === 0 && (
        <div className=" text-center">
          <h3 className=" font_m font_yellow fw_6">No Data</h3>
        </div>
      )}
    </div>
  );
};
/* Component ends */
export default ViolationTable;
