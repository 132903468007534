/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  fetchVisitorAccessPassList,
  getVisitorAccessPassList,
} from "../../../setup/store/slices/visitorPass";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import Searchbar from "../../../components/ui/other/Searchbar/Searchbar";
import VisitorAccessPassTable from "../../../components/module/VisitorAccessPass/VisitorAccessPass/VisitorAccessPassTable";
import TabButton from "../../../components/ui/other/TabButton/TabButton";
import ContractorAccessPassTable from "../../../components/module/VisitorAccessPass/ContractorAccessPass/ContractorAccessPassTable";
import BreadCrumb from "../../../components/ui/other/BreadCrumb/BreadCrumb";
/* Import local pages and component ends */

// styles sheet
import "./style.scss";

/* Component starts */
const ClientVisitorAccessPass = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [currentKey, setCurrentKey] = useState("visitors");
  const [currentPage, setCurrentPage] = useState(1);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { accessPassTabList, propertyId, tab } = useParams();
  const accessPassList = useSelector(getVisitorAccessPassList);

  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const buttonList = [
    { key: "visitors", label: "Visitors" },
    { key: "contractors", label: "Contractors" },
  ];

  // Guest List
  // const guestList = accessPassList?.filter((item) => item?.type === "Guest");

  // // Contractor List
  // const contractorList = accessPassList?.filter(
  //   (item) => item?.type === "Contractor"
  // );

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const onTabSelect = (key) => {
    const pathnameArr = pathname.split("/");
    pathnameArr.pop();

    navigate(pathnameArr.join("/") + "/" + key);
    setCurrentPage(1);
    // setCurrentKey(key);
  };
  /* Component function definition ends */

  /* Component useEffect starts */

  useEffect(() => {
    if (tab === "visitors") {
      dispatch(
        fetchVisitorAccessPassList({
          property_id: propertyId,
          type: "Guest",
          page: currentPage,
        })
      );
    } else {
      dispatch(
        fetchVisitorAccessPassList({
          property_id: propertyId,
          type: "Contractor",
          page: currentPage,
        })
      );
    }
  }, [tab, currentPage]);

  useEffect(() => {
    if (accessPassTabList) {
      setCurrentKey(accessPassTabList);
    }
  }, [accessPassTabList]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */

  return (
    <>
      <BreadCrumb type="acess_pass" />
      <div className="visitor-wrapper">
        <div className="heading mb-2">
          <h2 className="font_xl fw_6">Visitor Access Pass</h2>
        </div>

        {/* <Searchbar /> */}

        {/* TAB BUTTONS */}
        <TabButton
          buttonList={buttonList}
          currentKey={tab}
          onSelect={onTabSelect}
        />

        {/* Display Table Data Based On The Current Tab */}

        {tab === "visitors" ? (
          <VisitorAccessPassTable
            // guestList={guestList?.result}
            guestList={accessPassList?.visitorpass}
            totalCount={accessPassList?.totalCount}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        ) : (
          <ContractorAccessPassTable
            contractorList={accessPassList?.visitorpass}
            totalCount={accessPassList?.totalCount}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        )}
      </div>
    </>
  );
};
/* Component End */

export default ClientVisitorAccessPass;
