/**
 * This component is for custom text input field
 */

import React from "react";

/* Import configuration starts */
import { blockInvalidChar, blockInvalidCharNoDot } from "../helper";
/* Import configuration ends */

import "../style.scss";
import { getUserTheme } from "../../../../../setup/store/slices/authSlice";
import { useSelector } from "react-redux";

/* Component starts */
const V1 = (props) => {
  /* Props destructuring starts */
  const {
    type = "text",
    label,
    handleChange,
    value,
    name,
    errors,
    handleBlur,
    touched,
    required = true,
    disableDot = true,
    customOnChange = null,
    customOnchange = null,
  } = props;
  /* Props destructuring ends */

  const userTheme = useSelector(getUserTheme);

  /* Component function definition starts */
  const changeHandler = (e) => {
    customOnchange && customOnchange(e);

    const inputValue = e.target.value;
    if (disableDot && inputValue[(inputValue + "").length - 1] === ".")
      return false;

    if (isNaN(Number(inputValue))) {
      return false;
    } else {
      if (inputValue[0] === "0" && inputValue[1] !== undefined) {
        return false;
      } else {
        handleChange(e);
        customOnChange && customOnChange(e.target.value);
      }
    }
  };
  /* Component function definition ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="custom-form-control">
      <div className="form-input-wrapper">
        <label className="font_s font_grey">
          {label}
          {required && <span className="font_m font_red"> *</span>}
        </label>
        <input
          onKeyDown={disableDot ? blockInvalidCharNoDot : blockInvalidChar}
          type={type}
          onBlur={handleBlur}
          onChange={changeHandler}
          value={value}
          name={name}
          className={` font_xs ${
            userTheme === "light"
              ? "bg_lightThemeInput font_lightTheme"
              : "bg_black font_white"
          }`}
          autoComplete="off"
        />
      </div>

      {touched && errors && <p className="error font_xxs font_red">{errors}</p>}
    </div>
  );
};
/* Component ends */

export default V1;
