/**
 *
 */

import React, { useState, useEffect, useRef } from "react";

/* Import configuration starts */
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../../setup/store/slices/globalAlertSlice";
import {
  closeComplaintRequestWithVioation,
  fetchCompanyListBasedProperty,
  fetchTeamMemberBasedOnCompany,
  fetchTenantUnitsBasedPropertyId,
  getCompaniesList,
  getCompanyMembersList,
  getTenantsLists,
} from "../../../../setup/store/slices/securitySlice";
import {
  addViolationIssues,
  fetchEscalatedIncidentList,
  getEscalatedIncidentList,
} from "../../../../setup/store/slices/violationSlice";
import {
  fetchPropertyDetailByID,
  getPropertyDetail,
} from "../../../../setup/store/slices/propertySlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */

import { initialValues, validations } from "./helper";
import GLOBAL from "../../../../setup/constants/global";
import ErrorModal from "../../../ui/modal/ErrorModal/ErrorModal";
import SuccessModal from "../../../ui/modal/SuccessModal/SuccessModal";
import CustomTextInput from "../../../ui/input/CustomTextInput/CustomTextInput";
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
import TextButton from "../../../ui/button/TextButton/TextButton";
import CustomSelectInput from "../../../ui/input/CustomSelectInput/CustomSelectInput";
import CustomRadioInput from "../../../ui/input/CustomRadioInput/CustomRadioInput";
import CustomFileInput from "../../../ui/input/CustomFileInput/CustomFileInput";
// import TextEditor from "../../../ui/other/TextEditor/TextEditor";
import CustomPhoneInput from "../../../ui/input/CustomPhoneInput/CustomPhoneInput";
import NewHTMLTextEditor from "../../../ui/other/NewHTMLTextEditor/NewHTMLTextEditor";
/* Import local pages and component ends */

// Styles
import "./style.scss";

/* Component starts */

const AddViolationForm = (props) => {
  /* Props destructuring starts */
  const { onSuccess, onCancel } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [initialFormValues, setInitialFormValues] = useState(initialValues);
  const [selectedIncident, setSelectedIncident] = useState("");
  const [securityIncident, setSecurityIncident] = useState(true);
  const [violationIncident, setViolationIncident] = useState("Tenants");
  const [selectedUnitNo, setSelectedUnitNo] = useState();
  const [filterCompanyList, setFilterCompanyList] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState();
  const [selectedMemberList, setSelectedMemberList] = useState();
  const [vioationDesc, setvioationDesc] = useState("");

  const [descVal, setDescVal] = useState({ value: null });

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [modalDesc, setModalDesc] = useState("");

  const [isEmptyContent, setIsEmptyContent] = useState(false);
  const [isEditorFocused, setIsEditorFocused] = useState(false); // State to track editor focus

  const [defaultTenant, setDefaultTenant] = useState();

  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const customPhoneNum = useRef({ code: "", num: "" });
  const uploadDoc = useRef(null);
  const dispatch = useDispatch();
  const { propertyId } = useParams();
  const property = useSelector(getPropertyDetail);
  const tenantList = useSelector(getTenantsLists);
  const companyList = useSelector(getCompaniesList);
  const teamMembersList = useSelector(getCompanyMembersList);
  const escalatedIncidentList = useSelector(getEscalatedIncidentList);
  const { state } = useLocation();

  // console.log(state);

  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  //Loading all Team Members For Selected Company Name
  const loadTeamMembers = async () => {
    if (selectedCompany != null) {
      setSelectedMemberList(null);

      let payload = {
        propertyId: property?._id,
        compId: selectedCompany?.company_id,
      };

      await dispatch(fetchTeamMemberBasedOnCompany(payload));
    }
  };

  const closeRequestWithViolation = async (id) => {
    const payload = {
      violation_id: id,
      request_id: state?.request_id,
    };

    console.log(payload, "{{}}");

    try {
      const result = await dispatch(closeComplaintRequestWithVioation(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;
        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          onSuccess();
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // Form validations
  const formValidation = (values, violationIncident) => {
    return validations(values, violationIncident);
  };

  const onSubmit = async (values) => {
    let payload = {
      title: values.title,
      violator: violationIncident,
      property_id: propertyId,
    };

    if (property?.mgmt_model === 2) {
      if (!securityIncident) {
        if (violationIncident === "Tenants") {
          payload["violator_id"] = selectedUnitNo?.tenant_id || null;
          payload["unit_id"] = selectedUnitNo?.unit_id || null;
        }

        if (violationIncident === "Teams") {
          payload["violator_id"] = selectedMemberList?.mem_id || null;
          // payload["property_id"] = property?._id;
        }

        if (violationIncident === "Others") {
          payload["other_full_name"] = values.fullName;
          payload["other_mobile"] = values.mobile_number;
          payload["other_email"] = values.email;
          payload["other_company"] = values.company || "";
          // payload["property_id"] = property?._id;
        }
      } else if (state !== null) {
        if (violationIncident === "Tenants") {
          payload["violator_id"] = selectedUnitNo?.tenant_id || null;
          payload["unit_id"] = selectedUnitNo?.unit_id || null;
        }

        if (violationIncident === "Teams") {
          payload["violator_id"] = selectedMemberList?.mem_id || null;
          // payload["property_id"] = property?._id;
        }

        if (violationIncident === "Others") {
          payload["other_full_name"] = values.fullName;
          payload["other_mobile"] = values.mobile_number;
          payload["other_email"] = values.email;
          payload["other_company"] = values.company || "";
          // payload["property_id"] = property?._id;
        }
      } else {
        payload["security_incident_id"] = selectedIncident?.escaltedInicdent_id;
      }

      if (state === null) payload["is_security_incident"] = securityIncident;
      else {
        payload["is_security_incident"] = state?.is_security_incident;
      }
    } else {
      console.log("T1-PROP");
      if (violationIncident === "Tenants") {
        payload["violator_id"] = state?.fromTenant
          ? defaultTenant[0]?.tenant_id
          : selectedUnitNo?.tenant_id || null;
        payload["unit_id"] = state?.fromTenant
          ? defaultTenant[0]?.unit_id
          : selectedUnitNo?.unit_id || null;
        // payload["property_id"] = property?._id;
      }

      if (violationIncident === "Others") {
        payload["other_full_name"] = values.fullName;
        payload["other_mobile"] = values.mobile_number;
        payload["other_email"] = values.email;
        payload["other_company"] = values.company || "";
        // payload["property_id"] = property?._id;
      }
    }

    if (vioationDesc === "Draft") {
      payload["description"] = descVal.value;
    } else {
      payload["document"] = uploadDoc.current;
    }

    console.log(payload, "PaY");

    // Backend response. Try, Catch
    try {
      const result = await dispatch(addViolationIssues(payload));

      console.log(result, "RESULT");

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setModalDesc(result.payload);
          setShowErrorModal(true);
          break;

        case GLOBAL.FULFILLED:
          if (state !== null && state?.closeWithViolation)
            closeRequestWithViolation(result.payload.violation_id);
          else {
            setModalDesc(result?.payload?.message);
            setShowSuccessModal(true);
          }
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  /* Component function definition ends */

  /* Component useEffect starts */

  useEffect(() => {
    dispatch(fetchPropertyDetailByID({ property_id: propertyId }));
  }, [propertyId]);

  useEffect(() => {
    dispatch(fetchTenantUnitsBasedPropertyId({ property_id: propertyId }));
    dispatch(fetchCompanyListBasedProperty(property?._id));
    dispatch(fetchEscalatedIncidentList(property?._id));
  }, [propertyId]);

  // Loading All Existing Tenants Details
  useEffect(() => {
    if (tenantList?.length > 0) {
      setSelectedUnitNo(tenantList[0]);
    }
  }, [tenantList]);

  // Setting selected Company after loading Companies
  useEffect(() => {
    if (companyList?.length > 0) {
      setFilterCompanyList(
        companyList?.filter((item) => item.role !== "Owner")
      );
    }
  }, [companyList]);

  useEffect(() => {
    if (filterCompanyList?.length > 0) {
      setSelectedCompany(companyList);
    }
  }, [filterCompanyList]);

  // Loading All Team Members Based on selected Company
  useEffect(() => {
    loadTeamMembers();
  }, [selectedCompany]);

  // Setting selected Team Member after loading Companies List
  useEffect(() => {
    if (teamMembersList?.length > 0) {
      setSelectedMemberList(teamMembersList[0]);
    }
  }, [teamMembersList]);

  useEffect(() => {
    if (state?.fromTenant) {
      let defaultTenant = tenantList?.filter(
        (item) =>
          item?.tenant_id === state?.tenantId && item?.unit_id === state?.unitId
      );

      setDefaultTenant(defaultTenant);
    }
  }, [state?.fromTenant]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */

  return (
    <div className="add-voilation-module-wrapper">
      <h2 className="heading text-center font_xxl mb-5">Issue Violation</h2>

      <div className="add-new-form">
        <Formik
          onSubmit={onSubmit}
          initialValues={initialFormValues}
          validate={formValidation}
          validateOnBlur={true}
          enableReinitialize={true}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="form-wrapper">
                {/* Creating Voilations */}

                {property?.mgmt_model === 2 ? (
                  <>
                    {/* Radio select if it is part of existing Community or not */}
                    {state === null && (
                      <div className="form-group form-group-full">
                        <p className="font_m font_grey w_100">
                          Is this violation related to security incident?
                        </p>
                        <CustomRadioInput
                          label="Yes"
                          onSelecting={() => {
                            setSecurityIncident(true);
                          }}
                          isSelected={securityIncident === true}
                        />
                        <CustomRadioInput
                          label="No"
                          onSelecting={() => {
                            setSecurityIncident(false);
                          }}
                          isSelected={securityIncident === false}
                        />
                      </div>
                    )}
                    {/* Select Escalted Security Incident */}
                    {securityIncident === true && state === null ? (
                      <>
                        <div className="form-group">
                          <CustomSelectInput
                            name="incident"
                            label="Select Escalted Security Incident"
                            handleBlur={handleBlur}
                            onChange={handleChange}
                            valueExtractor={(item) =>
                              `${item?.incident_id} - ${item?.subject}  `
                            }
                            setValue={setSelectedIncident}
                            options={escalatedIncidentList}
                            value={selectedIncident}
                            formikState={false}
                            emptyOption={false}
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        {state === null && <hr />}
                        <div className="form-group form-group-full">
                          <CustomRadioInput
                            label="Tenants"
                            onSelecting={() => {
                              setViolationIncident("Tenants");
                            }}
                            isSelected={violationIncident === "Tenants" || ""}
                          />
                          {property?.mgmt_model === 2 && (
                            <CustomRadioInput
                              label="Teams"
                              onSelecting={() => {
                                setViolationIncident("Teams");
                              }}
                              isSelected={violationIncident === "Teams" || ""}
                            />
                          )}
                          <CustomRadioInput
                            label="Others"
                            onSelecting={() => {
                              setViolationIncident("Others");
                            }}
                            isSelected={violationIncident === "Others" || ""}
                          />
                        </div>
                      </>
                    )}

                    {/* If Selected Tenant */}
                    {violationIncident === "Tenants" &&
                      (!securityIncident ||
                        state?.is_security_incident === false) && (
                        <>
                          <div className="form-group">
                            <CustomSelectInput
                              name="unit_num"
                              label="Select Tenant Unit"
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              valueExtractor={(item) =>
                                `${item?.unit_name} - ${item?.tenant_name}`
                              }
                              setValue={setSelectedUnitNo}
                              options={tenantList}
                              value={selectedUnitNo}
                              formikState={false}
                              emptyOption={false}
                            />
                          </div>
                        </>
                      )}

                    {/* If Selected Team */}
                    {violationIncident === "Teams" &&
                      (!securityIncident ||
                        state?.is_security_incident === false) &&
                      property?.mgmt_model === 2 && (
                        <>
                          <div className="form-group">
                            <CustomSelectInput
                              name="companyName"
                              label="Select Company Name"
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              valueExtractor={(item) => item?.company_name}
                              setValue={setSelectedCompany}
                              options={filterCompanyList}
                              value={selectedCompany}
                              formikState={false}
                              emptyOption={true}
                            />
                          </div>

                          <div className="form-group">
                            <CustomSelectInput
                              name="teamMember"
                              label="Team Member Name"
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              valueExtractor={(item) =>
                                `${item?.mem_name} - ${item?.mem_role}`
                              }
                              setValue={setSelectedMemberList}
                              options={teamMembersList}
                              value={selectedMemberList}
                              formikState={false}
                              emptyOption={false}
                            />
                          </div>
                        </>
                      )}

                    {/* If Selected Others */}
                    {violationIncident === "Others" &&
                      (!securityIncident ||
                        state?.is_security_incident === false) && (
                        <>
                          <div className="form-group">
                            <CustomTextInput
                              name="fullName"
                              label="Full Name"
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              touched={touched.fullName}
                              errors={errors.fullName}
                              value={values.fullName}
                            />
                          </div>

                          <div className="form-group">
                            <CustomPhoneInput
                              name="mobile_number"
                              label={" Number"}
                              customPhoneNum={customPhoneNum}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              value={values.mobile_number}
                              countryCodeEditable={true}
                              errors={errors.mobile_number}
                              touched={touched.mobile_number}
                            />
                          </div>

                          <div className="form-group">
                            <CustomTextInput
                              name="email"
                              label="Email"
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              touched={touched.email}
                              errors={errors.email}
                              value={values.email}
                            />
                          </div>

                          <div className="form-group">
                            <CustomTextInput
                              name="company"
                              label="Company"
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              touched={touched.company}
                              errors={errors.company}
                              value={values.company}
                              required={false}
                            />
                          </div>
                        </>
                      )}

                    <p className="font_m font_grey w_100">Violations</p>
                    {/* Input for Title */}
                    <div className="form-group">
                      <CustomTextInput
                        name="title"
                        label="Violation Subject"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        touched={touched.title}
                        errors={errors.title}
                        value={values.title}
                      />
                    </div>

                    <div className="form-group form-group-full mt-4 d-flex gap-auto mb-4">
                      <CustomRadioInput
                        label="Create Draft/Description"
                        onSelecting={() => {
                          setvioationDesc("Draft");
                        }}
                        isSelected={vioationDesc === "Draft" || ""}
                      />

                      <CustomRadioInput
                        label="Upload Doc/Pdf"
                        onSelecting={() => {
                          setvioationDesc("Upload-Image");
                        }}
                        isSelected={vioationDesc === "Upload-Image" || ""}
                      />
                    </div>

                    {/* Input for Description */}
                    {vioationDesc === "Draft" && (
                      <div className="form-group form-group-full">
                        {/* <TextEditor
                          label="Description"
                          descVal={descVal}
                          setDescVal={setDescVal}
                        /> */}

                        <NewHTMLTextEditor
                          label="Description"
                          descVal={descVal}
                          setDescVal={setDescVal}
                          isEmptyContent={isEmptyContent}
                          setIsEmptyContent={setIsEmptyContent}
                          isEditorFocused={isEditorFocused}
                          setIsEditorFocused={setIsEditorFocused}
                        />

                        {(isEmptyContent && (
                          <p className="font_red fw_6 font_m">{`Editor content cannot be empty!`}</p>
                        )) ||
                          (isEditorFocused && (
                            <p className="font_red fw_6 font_m">{`Please finish editing before submitting!`}</p>
                          ))}
                      </div>
                    )}

                    {/* Input for Upload ID */}
                    {vioationDesc === "Upload-Image" && (
                      <div className="form-group">
                        <CustomFileInput
                          label="Upload Doc/Pdf"
                          name="uploadDoc"
                          files={uploadDoc}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          errors={errors.uploadDoc}
                          touched={touched.uploadDoc}
                          values={values.uploadDoc}
                          required={true}
                          formikState={false}
                        />
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <div className="form-group form-group-full">
                      <CustomRadioInput
                        label="Tenants"
                        onSelecting={() => {
                          setViolationIncident("Tenants");
                        }}
                        isSelected={violationIncident === "Tenants" || ""}
                      />
                      {property?.mgmt_model === 2 && (
                        <CustomRadioInput
                          label="Teams"
                          onSelecting={() => {
                            setViolationIncident("Teams");
                          }}
                          isSelected={violationIncident === "Teams" || ""}
                        />
                      )}
                      <CustomRadioInput
                        label="Others"
                        onSelecting={() => {
                          setViolationIncident("Others");
                        }}
                        isSelected={violationIncident === "Others" || ""}
                      />
                    </div>

                    {violationIncident === "Tenants" && state?.fromTenant && (
                      <>
                        <div className="form-group">
                          <CustomSelectInput
                            name="unit_num"
                            label="Select Tenant Unit"
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            valueExtractor={(item) =>
                              `${item?.unit_name} - ${item?.tenant_name}`
                            }
                            // setValue={setSelectedUnitNo}
                            options={defaultTenant}
                            value={defaultTenant}
                            formikState={false}
                            emptyOption={false}
                          />
                        </div>
                      </>
                    )}

                    {/* If Selected Tenant */}
                    {violationIncident === "Tenants" && !state?.fromTenant && (
                      <>
                        <div className="form-group">
                          <CustomSelectInput
                            name="unit_num"
                            label="Select Tenant Unit"
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            valueExtractor={(item) =>
                              `${item?.unit_name} - ${item?.tenant_name}`
                            }
                            setValue={setSelectedUnitNo}
                            options={tenantList}
                            value={selectedUnitNo}
                            formikState={false}
                            emptyOption={false}
                          />
                        </div>
                      </>
                    )}

                    {/* If Selected Others */}
                    {violationIncident === "Others" && (
                      <>
                        <div className="form-group">
                          <CustomTextInput
                            name="fullName"
                            label="Full Name"
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            touched={touched.fullName}
                            errors={errors.fullName}
                            value={values.fullName}
                          />
                        </div>
                        <div className="form-group">
                          <CustomPhoneInput
                            name="mobile_number"
                            label={" Number"}
                            customPhoneNum={customPhoneNum}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            value={values.mobile_number}
                            countryCodeEditable={true}
                            errors={errors.mobile_number}
                            touched={touched.mobile_number}
                          />
                        </div>

                        <div className="form-group">
                          <CustomTextInput
                            name="email"
                            label="Email"
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            touched={touched.email}
                            errors={errors.email}
                            value={values.email}
                          />
                        </div>

                        <div className="form-group">
                          <CustomTextInput
                            name="company"
                            label="Company"
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            touched={touched.company}
                            errors={errors.company}
                            value={values.company}
                            required={false}
                          />
                        </div>
                      </>
                    )}

                    <p className="font_m font_grey w_100">Violations</p>
                    {/* Input for Title */}
                    <div className="form-group">
                      <CustomTextInput
                        name="title"
                        label="Violation Subject"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        touched={touched.title}
                        errors={errors.title}
                        value={values.title}
                      />
                    </div>

                    <div className="form-group form-group-full mt-4 d-flex gap-auto mb-4">
                      <CustomRadioInput
                        label="Create Draft/Description"
                        onSelecting={() => {
                          setvioationDesc("Draft");
                        }}
                        isSelected={vioationDesc === "Draft" || ""}
                      />

                      <CustomRadioInput
                        label="Upload Doc/Pdf"
                        onSelecting={() => {
                          setvioationDesc("Upload-Image");
                        }}
                        isSelected={vioationDesc === "Upload-Image" || ""}
                      />
                    </div>

                    {/* Input for Description */}
                    {vioationDesc === "Draft" && (
                      <div className="form-group form-group-full">
                        {/* <TextEditor
                          label="Description"
                          descVal={descVal}
                          setDescVal={setDescVal}
                        /> */}

                        <NewHTMLTextEditor
                          label="Description"
                          descVal={descVal}
                          setDescVal={setDescVal}
                          isEmptyContent={isEmptyContent}
                          setIsEmptyContent={setIsEmptyContent}
                          isEditorFocused={isEditorFocused}
                          setIsEditorFocused={setIsEditorFocused}
                        />

                        {(isEmptyContent && (
                          <p className="font_red fw_6 font_m">{`Editor content cannot be empty!`}</p>
                        )) ||
                          (isEditorFocused && (
                            <p className="font_red fw_6 font_m">{`Please finish editing before submitting!`}</p>
                          ))}
                      </div>
                    )}

                    {/* Input for Upload ID */}
                    {vioationDesc === "Upload-Image" && (
                      <div className="form-group">
                        <CustomFileInput
                          label="Upload Doc/Pdf"
                          name="uploadDoc"
                          files={uploadDoc}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          errors={errors.uploadDoc}
                          touched={touched.uploadDoc}
                          values={values.uploadDoc}
                          required={true}
                          formikState={false}
                        />
                      </div>
                    )}
                  </>
                )}
              </div>

              <div className="button-wrapper text-center">
                <>
                  <CustomButton type="submit" size="l">
                    Issue Violation
                  </CustomButton>
                  <TextButton
                    type="button"
                    onClick={onCancel}
                    className="font_m fw_6"
                  >
                    Cancel
                  </TextButton>
                </>
              </div>
            </form>
          )}
        </Formik>
      </div>

      {/* Success Modal */}
      <SuccessModal
        showModal={showSuccessModal}
        description={modalDesc}
        title="Successfully Created"
        onClose={() => {
          onSuccess();
          setShowSuccessModal(false);
        }}
      />

      {/* Error Modal */}
      <ErrorModal
        showModal={showErrorModal}
        description={modalDesc}
        title="Error"
        onClose={() => setShowErrorModal(false)}
      />
    </div>
  );
};
/* Component ends */

export default AddViolationForm;
