/* Import configuration starts */
import { useState, useEffect } from "react";
import { Suspense } from "react";
import store from "./setup/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import i18n from "./setup/i18n";
import { setUser, setUserRole } from "./setup/store/slices/authSlice";
/* Import configuration ends */

/* Import redux slices component starts */
import { fetchSetting } from "./setup/store/slices/settingSlice";
/* Import redux slices component ends */

/* Import local pages and component starts */
import Loader from "./components/ui/other/Loader/Loader";
import FrontEnd from "./setup/layout/FrontEnd";
/* Import local pages and component ends */

import "./App.css";

toast.configure();
let persistor = persistStore(store);

function App(props) {
  // useStates
  // const [theme, setTheme] = useState("");

  // const handleToggler = () => {
  //   theme === "" ? setTheme("light_theme") : setTheme("");
  // };

  /* Component useEffect starts */
  // useEffect(() => {
  //   document.body.className = theme;
  // }, [theme]);

  /* Component useEffect ends */

  const chechUser = () => {
    if (
      localStorage.getItem("user_data") &&
      localStorage.getItem("user_token") &&
      localStorage.getItem("user_role")
    ) {
      store.dispatch(setUser(JSON.parse(localStorage.getItem("user_data"))));
      store.dispatch(setUserRole(localStorage.getItem("user_role")));
    } else {
      store.dispatch(setUser(null));
      localStorage.removeItem("user_data");
      localStorage.removeItem("user_token");
      localStorage.removeItem("user_role");
    }
  };

  /* ************* Root Component rendering. JSX code ************* */
  return (
    <div onClick={chechUser} className="App">
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Suspense fallback={<Loader/>}>
            <FrontEnd
            // theme={theme} toggler={handleToggler}
            />
          </Suspense>
        </PersistGate>
      </Provider>
    </div>
  );
}

export default App;
