/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";
/* Import configuration ends */

/* Import redux slices component starts */

/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Table } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { EyeFilledBlue } from "../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import GLOBAL from "../../../setup/constants/global";
import IconButton from "../../../components/ui/button/IconButton/IconButton";
import CheckboxMultiSelectDropdown from "../../../components/ui/input/CheckboxMultiSelectDropdown/CheckboxMultiSelectDropdown";
/* Import local pages and component ends */

/* Component starts */
const RatingTable = (props) => {
  /* Props destructuring starts */
  const {
    ratingListData,
    multiSelectCommunity,
    setMultiSelectCommunity,
    communityData,
    propertyData,
    multiSelectProperty,
    setMultiSelectProperty,
    typeData1,
    multiSelectType,
    setMultiSelectType,
  } = props;

  /* Props destructuring ends */

  /* Component states and useRef declaration starts */

  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  //   const dispatch = useDispatch();

  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  // const getCompany = (data) => {
  //   if (Object.keys(data).length > 0)
  //     return `${data?.first_name}${" "}${data?.last_name}`;
  //   return "-";
  // };

  // To View The Item Report
  const handleViewReport = async (item) => {
    if (item?.type === "Internal Maintenance") {
      if (item?.community_id) {
        navigate(
          `/communities/${item?.community_id}/properties/${item?.property_id}/internal/maintenances/${item?.interMain_id}`
        );
      } else {
        navigate(
          `/independent/properties/${item?.property_id}/internal/maintenances/${item?.interMain_id}`
        );
      }
    } else {
      if (item?.community_id) {
        navigate(
          `/communities/${item?.community_id}/properties/${item?.property_id}/maintenances/${item?.tenMain_id}`
        );
      } else {
        navigate(
          `/independent/properties/${item?.property_id}/maintenances/${item?.tenMain_id}`
        );
      }
    }
  };

  /* Component function definition ends */

  /* Component useEffect starts */

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */

  return (
    <div className="custom-table-wrapper ratings-table">
      {ratingListData?.length > 0 && (
        <Table className="custom-table font_s" borderless responsive>
          {/* Table Heading */}
          <thead>
            <tr>
              <th>
                <CheckboxMultiSelectDropdown
                  multiSelectData={multiSelectCommunity}
                  setMultiSelectData={setMultiSelectCommunity}
                  optionLabel={communityData}
                  label={"Community"}
                />
              </th>
              <th>
                <CheckboxMultiSelectDropdown
                  multiSelectData={multiSelectProperty}
                  setMultiSelectData={setMultiSelectProperty}
                  optionLabel={propertyData}
                  label={"Property"}
                />
              </th>
              <th>Ticket</th>
              <th>Date</th>
              <th>
                <CheckboxMultiSelectDropdown
                  multiSelectData={multiSelectType}
                  setMultiSelectData={setMultiSelectType}
                  optionLabel={typeData1}
                  label={"Type"}
                />
              </th>
              <th>Company Name</th>
              <th>Technician</th>
              <th>Service Rating</th>
              <th>Service Review</th>
              <th>Technician Rating</th>
              <th>Technician Review</th>
              <th>Given By</th>
              <th className="text-start">Action</th>
            </tr>
          </thead>
          {/* Table Body */}
          {ratingListData?.length > 0 && (
            <tbody>
              {ratingListData?.map((item, index) => (
                <tr key={index}>
                  {/* Community */}
                  <td>
                    {item?.community_name !== undefined || null
                      ? item?.community_name
                      : "-"}
                  </td>

                  {/* Property */}
                  <td>{item?.property_name}</td>

                  {/* TICKET */}
                  <td>{item?.ticket}</td>

                  {/* Date */}
                  <td>{moment(item?.rated_date).format(DATE_TIME_FORMAT)}</td>

                  {/* Type */}
                  <td>{item?.type}</td>

                  {/* Company Name */}
                  <td>{item?.company !== "" ? item?.company : "-"}</td>

                  {/* Technician Name */}
                  <td>{item?.technician !== "" ? item?.technician : "-"}</td>

                  {/* SERVICE RATING */}
                  <td>
                    {item?.service_rating !== undefined
                      ? item?.service_rating
                      : "-"}
                  </td>

                  {/* SERVICE REVIEW */}
                  <td>
                    {item?.service_review !== null &&
                    item?.service_review !== ""
                      ? "true"
                      : "false"}
                  </td>

                  {/* TECHNICIAN RATING */}
                  <td>
                    {item?.technician_rating !== undefined
                      ? item?.technician_rating
                      : "-"}
                  </td>

                  {/* TECHNICIAN REVIEW */}
                  <td>
                    {item?.tech_review !== null && item?.tech_review !== ""
                      ? "true"
                      : "false"}
                  </td>

                  {/* Rated By Name */}
                  <td>{item?.given_by !== undefined ? item?.given_by : "-"}</td>

                  {/* Actions */}
                  <td className="text-start txt-nowrap">
                    <IconButton
                      onClick={() => handleViewReport(item)}
                      className="action-button"
                    >
                      <img
                        src={EyeFilledBlue}
                        className="h_100"
                        alt="View Details"
                      />
                    </IconButton>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </Table>
      )}

      {ratingListData?.length === 0 && (
        <div className=" text-center">
          <h3 className=" font_m font_yellow fw_6">No Data</h3>
        </div>
      )}
    </div>
  );
};
/* Component ends */
export default RatingTable;
