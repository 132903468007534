/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import moment from "moment";
import { useDispatch } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  soAcceptSecurityComplainReq,
  soRejectSecurityComplainReq,
  soSubmitSecurityComplainReport,
} from "../../../../../../setup/store/slices/securitySlice";
import {
  showSuccessAlert,
  showErrorAlert,
} from "../../../../../../setup/store/slices/globalAlertSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import GLOBAL from "../../../../../../setup/constants/global";
import SoReportModal from "../../SoReportModal/SoReportModal";
import SoRejectModal from "../../SoRejecttModal/SoRejectModal";
import { logTitle } from "../helper";
import CustomButton from "../../../../../ui/button/CustomButton/CustomButton";
/* Import local pages and component ends */

/* Component starts */
const SOLog = (props) => {
  /* Props destructuring starts */
  const {
    complaint,
    active = false,
    isLast = false,
    report = null,
    user,
    role,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [openReportForm, setOpenReportForm] = useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();

  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const name = complaint?.user
    ? `${complaint?.user?.first_name} ${complaint?.user?.last_name}`
    : `${complaint.tenant.first_name} ${complaint.tenant.last_name}`;
  // const role = complaint?.user?.role_id?.name;
  const createdAt = complaint?.createdAt;
  const comment = complaint?.comment;
  const reportData = complaint?.data;

  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const getActionButtons = () => {
    if (role !== GLOBAL.USER_ROLE.SO || user?._id !== complaint?.user?._id)
      return;
    switch (complaint?.status) {
      case GLOBAL.SECURITY_STATUS.PENDING_DECISION:
        return (
          <div className="btn-wrapper">
            <CustomButton
              onClick={() => handleAcceptRequest()}
              variant="yellow"
              size="l"
            >
              Accept
            </CustomButton>
            <CustomButton
              //   className="reject-btn"
              variant="red"
              size="l"
              onClick={() => setShowCommentModal(!showCommentModal)}
            >
              Reject
            </CustomButton>
          </div>
        );
      case GLOBAL.SECURITY_STATUS.SO_ASSIGNED:
        return (
          <div className="btn-wrapper">
            <CustomButton
              onClick={() => setOpenReportForm(true)}
              variant="yellow"
              size="l"
            >
              Add Report
            </CustomButton>
          </div>
        );
    }
  };

  // ACCEPT REQUEST BY SO
  const handleAcceptRequest = async () => {
    const payload = {
      request_id: complaint.tenant_security_request_id,
    };

    // Backend response. Try, Catch
    try {
      const result = await dispatch(soAcceptSecurityComplainReq(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;
        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // REJECT REQUEST BY SO
  const handleRejectRequest = async (comment) => {
    const payload = {
      request_id: complaint.tenant_security_request_id,
      comment: comment.description,
    };

    setShowCommentModal(!showCommentModal);
    // Backend response. Try, Catch
    try {
      const result = await dispatch(soRejectSecurityComplainReq(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;
        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  const onSubmitReport = async (val) => {
    setOpenReportForm(!openReportForm);

    // Backend response. Try, Catch
    try {
      const result = await dispatch(soSubmitSecurityComplainReport(val));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;
        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      <div className="time-line simple-log">
        {/* Dot */}

        <div className="dot-wrapper">
          <i className="dot"></i>
          <div className="vl"></div>
        </div>

        <div className="detail-wrapper">
          {/* Title */}
          <h3 className="title font_m fw_6">{logTitle(complaint)}</h3>

          {/* Technician Name and Date */}
          <div className="black-container-sm fw_5 font_s bg_black flex-between">
            {/* Name */}
            <p className="left">
              <span className="font_grey">{role} </span>
              <span className="font_white"> {name}</span>
            </p>

            {/* Date and Time */}
            <p className="right font_grey">
              {moment(createdAt).format(DATE_TIME_FORMAT)}
            </p>
          </div>
          {/* Log Footer */}
          <div className="log-footer">
            {/* Comments */}
            {comment && (
              <p className="comments font_s fw-5">
                <span className="font_grey fw_6">Comments: </span>
                <span className="fw_5 font_red"> {comment}</span>
              </p>
            )}

            {/* {complaint?.status === "resolved" && complaint?.violation_id && (
              <p className="currentStatus font_s fw-5 bg_black">
                <span className="font_grey fw_6">View Violation: </span>
                <span className="fw_5">
                  <IconButton
                    className="action-button"
                    onClick={() => handleView(complaint?.violation_id)}
                  >
                    <img src={EyeFilledBlue} alt="Icon" className="w_100" />
                  </IconButton>
                </span>
              </p>
            )} */}

            {/* Action Buttons */}
            {active && getActionButtons()}
          </div>
        </div>
      </div>

      {/* MODALS */}

      <SoRejectModal
        showModal={showCommentModal}
        setShowModal={setShowCommentModal}
        onClose={() => setShowCommentModal(false)}
        onCommentAdded={(comment) => handleRejectRequest(comment)}
      />

      {/* SO REPORT MODAL */}
      <SoReportModal
        showModal={openReportForm}
        setShowModal={setOpenReportForm}
        title={"Security Complaint Report"}
        onClose={() => setOpenReportForm(false)}
        // singleDescAllowed={true}
        prevReport={report}
        request_id={complaint?.tenant_security_request_id}
        onSubmitReport={(val) => onSubmitReport(val)}
      />
    </>
  );
};
/* Component ends */

export default SOLog;
