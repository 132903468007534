/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import moment from "moment";
import GLOBAL from "../../../../../setup/constants/global";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Table } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import {
  DocFilledWhite,
  DocumentGreen,
} from "../../../../../setup/constants/images";

/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../../../ui/button/IconButton/IconButton";
import FileViewerModal from "../../../../ui/modal/FileViewerModal/FileViewerModal";
/* Import local pages and component ends */

/* Component starts */
const IDVerification = (props) => {
  /* Props destructuring starts */
  const { data, index } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [file, setFile] = useState();
  const [showFileViewer, setShowFileViewer] = useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // On viewing id file
  const onViewID = (item) => {
    setFile(item);
    setShowFileViewer(true);
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="idVerify-wrapper">
      <p className="font_l">{index} - ID Verifcation</p>

      {/* ID Verfication Table */}
      <div className="custom-table-wrapper">
        {data?.length > 0 && (
          <Table className="custom-table font_s" borderless responsive>
            {/* Table Heading */}
            <thead>
              <tr>
                <th>Id Type</th>
                <th>Id Number</th>
                <th>Id Expiry Date</th>
                <th>File</th>
              </tr>
            </thead>
            {/* Table Body */}
            <tbody>
              {data?.map((item, index) => (
                <tr key={index}>
                  {/* Id Type */}
                  <td>{item?.type}</td>

                  {/* Id Number */}
                  <td>{item?.number}</td>

                  {/* Id Expiry Date */}
                  <td>
                    {moment(item.expiry_date).format(DATE_FORMAT)}
                    {moment(item.expiry_date).isBefore(moment()) ? (
                      <p className="font_m font_red fw_5">Expired</p>
                    ) : (
                      ""
                    )}
                  </td>

                  {/* ID Image */}
                  <td className="text-start txt-nowrap">
                    <IconButton onClick={() => onViewID(item.image)}>
                      <img src={DocumentGreen} alt="View ID" />
                    </IconButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}

        {data?.length === 0 && (
          <div className=" text-center">
            <h3 className=" font_m font_yellow fw_6">No Data</h3>
          </div>
        )}
      </div>

      {/* Modals */}
      {/* File Viewer Modal */}
      <FileViewerModal
        show={showFileViewer}
        file={file?.url}
        type={file?.type}
        onClose={() => {
          setShowFileViewer(false);
          setFile({});
        }}
      />
    </div>
  );
};
/* Component ends */

export default IDVerification;
