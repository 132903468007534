import GLOBAL from "../../setup/constants/global";

export const getPrintableHtmlData = (type, data, t) => {
  switch (type) {
    case GLOBAL.DASHBOARD.OCCUPANCY:
      return getOccupancyTable(data, type, t);
    case GLOBAL.DASHBOARD.CONTRACTS:
      break;
    //   return getContractTable(data, type, t);
    case GLOBAL.DASHBOARD.FINANCIAL:
      break;
    //   return getFinanceTable(data, type, t);
    case GLOBAL.DASHBOARD.TENANT_MAINTENANCE:
      break;
    //   return getTenantMaintenanceTable(data, type, t);
    case GLOBAL.DASHBOARD.INTERNAL_MAINTENANCE:
      break;
    case GLOBAL.DASHBOARD.SECURITY:
      break;
    case GLOBAL.DASHBOARD.SPARE_PARTS:
      break;
    case GLOBAL.DASHBOARD.RATINGS:
      break;
    case GLOBAL.DASHBOARD.TEAMS:
      break;
    case GLOBAL.DASHBOARD.VIOLATIONS:
      break;
    case GLOBAL.DASHBOARD.MOVE_IN:
      break;
    case GLOBAL.DASHBOARD.MOVE_OUT:
      break;
  }
};

const getOccupancyTable = (data, type) => {
  const tableData = data.map(
    (d) => `<tr>
          <td>${d?.community_name ? d.community_name : "-"}</td>
          <td>${d.property_name}</td>
          <td>${d.unit_number}</td>
          <td>${d?.floor ? d.floor : "-"}</td>
          <td>${d?.size ? d.size : "-"} sq. ft.</td>
          <td>${d?.type ? d.type : "-"}</td>
          <td>${d?.tenant ? d.tenant : "-"}</td>
          <td>${d.status}</td>
      </tr>`
  );

  return `
      <body>
      <div id="content-id">
          <h1>${type} Report</h1>
          <table>
              <tr>
                  <th>Community</th>
                  <th>Property</th>
                  <th>Unit_Number</th>
                  <th>Floor</th>
                  <th>Size</th>
                  <th>Unit_Type</th>
                  <th>Tenant_Name</th>
                  <th>Status</th>
              </tr>
              <tr>${tableData.join("")}</tr>
          </table>
          </div>
          </body>`;
};

/* 

<html>
      <head>
          <style>
              h1 {text-align: center;}
              p {text-align: center;}
              div {text-align: center;}
              table, th, td {
                  border: 1px solid black;
                  border-collapse: collapse;
              }
          </style>
      </head>
 
    </html>
*/

// const getContractTable = (data, type, t) => {
//   const tableData = data.map(
//     (d) => `<tr>
//           <td>${d?.community_name ? d.community_name : "-"}</td>
//           <td>${d.property_name}</td>
//           <td>${d.unit_number}</td>
//           <td>${d.type}</td>
//           <td>${d.tenant}</td>
//           <td>${moment(d.start_date).format(GLOBAL.DATE_FORMAT)}</td>
//           <td>${moment(d.end_date).format(GLOBAL.DATE_FORMAT)}</td>
//           <td>${d.daysElapsed}</td>
//           <td>${d.installments}</td>
//           <td>${d.amount}</td>
//           <td>${d.status}</td>
//       </tr>`
//   );

//   return `<html>
//       <head>
//           <style>
//               h1 {text-align: center;}
//               p {text-align: center;}
//               div {text-align: center;}
//               table, th, td {
//                   border: 1px solid black;
//                   border-collapse: collapse;
//               }
//           </style>
//       </head>
//       <body>
//           <h1>${t(type)}</h1>
//           <table>
//               <tr>
//                   <th>${t(I.COMMON.COMMUNITY)}</th>
//                   <th>${t(I.TEXT.PROPERTY)}</th>
//                   <th>${t(I.TEXT.UNIT_NUMBER)}</th>
//                   <th>${t(I.TEXT.UNIT_TYPE)}</th>
//                   <th>${t(I.TEXT.TENANT_NAME)}</th>
//                   <th>${t(I.TEXT.START_DATE)}</th>
//                   <th>${t(I.TEXT.END_DATE)}</th>
//                   <th>${t(I.TEXT.DAYS_ELAPSED)}</th>
//                   <th>${t(I.TEXT.INSTALMENTS)}</th>
//                   <th>${t(I.TEXT.AMOUNT)}</th>
//                   <th>${t(I.TEXT.STATUS)}</th>
//               </tr>
//               <tr>${tableData.join("")}</tr>
//           </table>
//       </body>
//     </html>`;
// };

// const getFinanceTable = (data, type, t) => {
//   const tableData = data.map(
//     (d) => `<tr>
//           <td>${d?.community_name ? d.community_name : "-"}</td>
//           <td>${d.property_name}</td>
//           <td>${moment(d.dueDate).format(GLOBAL.DATE_FORMAT)}</td>
//           <td>${d?.invoice_number ? d.invoice_number : "-"}</td>
//           <td>${d.invoice_amount} ${d.currency}</td>
//           <td>${d?.payment_method ? d.payment_method : "-"}</td>
//           <td>${d?.category ? d.category : "-"}</td>
//           <td>${d?.sub_category ? d.sub_category : "-"}</td>
//           <td>${d.status}</td>
//       </tr>`
//   );

//   return `<html>
//       <head>
//           <style>
//               h1 {text-align: center;}
//               p {text-align: center;}
//               div {text-align: center;}
//               table, th, td {
//                   border: 1px solid black;
//                   border-collapse: collapse;
//               }
//           </style>
//       </head>
//       <body>
//           <h1>${t(type)}</h1>
//           <table>
//               <tr>
//                   <th>${t(I.COMMON.COMMUNITY)}</th>
//                   <th>${t(I.TEXT.PROPERTY)}</th>
//                   <th>${t(I.TEXT.DUE_ON)}</th>
//                   <th>${t(I.TEXT.INVOICE)}</th>
//                   <th>${t(I.TEXT.AMOUNT)}</th>
//                   <th>${t(I.TEXT.PAYMENT_METHOD)}</th>
//                   <th>${t(I.TEXT.CATEGORY)}</th>
//                   <th>${t(I.TEXT.SUB_CATEGORY)}</th>
//                   <th>${t(I.TEXT.STATUS)}</th>
//               </tr>
//               <tr>${tableData.join("")}</tr>
//           </table>
//       </body>
//     </html>`;
// };

// const getTenantMaintenanceTable = (data, type, t) => {
//   const tableData = data.map(
//     (d) => `<tr>
//           <td>${d?.community_name ? d.community_name : "-"}</td>
//           <td>${d.property_name}</td>
//           <td>${d?.request_id ? d.request_id : "-"}</td>
//           <td>${moment(d.date).format(GLOBAL.DATE_FORMAT)}</td>
//           <td>${d?.unit_name ? d.unit_name : "-"}</td>
//           <td>${d?.location ? d.location : "-"}</td>
//           <td>${d?.category ? d.category : "-"}</td>
//           <td>${d?.subcategory ? d.subcategory : "-"}</td>
//           <td>${d?.company ? d.company : "-"}</td>
//           <td>${d?.spareParts ? d.spareParts : "-"}</td>
//           <td>${d?.severity ? d.severity : "-"}</td>
//           <td>${d.processing}</td>
//           <td>${d?.escalated ? d.escalated : "-"}</td>
//           <td>${d.status}</td>
//       </tr>`
//   );

//   return `<html>
//       <head>
//           <style>
//               h1 {text-align: center;}
//               p {text-align: center;}
//               div {text-align: center;}
//               table, th, td {
//                   border: 1px solid black;
//                   border-collapse: collapse;
//               }
//           </style>
//       </head>
//       <body>
//           <h1>${t(type)}</h1>
//           <table>
//               <tr>
//                   <th>${t(I.COMMON.COMMUNITY)}</th>
//                   <th>${t(I.TEXT.PROPERTY)}</th>
//                   <th>${t(I.TEXT.TICKET)}</th>
//                   <th>${t(I.TEXT.DATE)}</th>
//                   <th>${t(I.TEXT.UNIT_NUMBER)}</th>
//                   <th>${t(I.TEXT.LOCATION)}</th>
//                   <th>${t(I.TEXT.CATEGORY)}</th>
//                   <th>${t(I.TEXT.SUB_CATEGORY)}</th>
//                   <th>${t(I.TEXT.COMPANY)}</th>
//                   <th>${t(I.TEXT.SPARE_PARTS)}</th>
//                   <th>${t(I.TEXT.SEVERITY)}</th>
//                   <th>${t(I.TEXT.PROCESSING)}</th>
//                   <th>${t(I.TEXT.ESCALATION)}</th>
//                   <th>${t(I.TEXT.STATUS)}</th>
//               </tr>
//               <tr>${tableData.join("")}</tr>
//           </table>
//       </body>
//     </html>`;
// };
