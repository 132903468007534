import React from "react";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";

import "./style.scss";

const events = [
  { title: "Meeting", start: new Date() },
  { title: "Meeting", start: "2023-09-22T07:45" },
  { title: "Meeting", start: "2023-08-22T07:45" },
];

const FullCalenderPage = ({ type }) => {
  console.log(type, "{}");

  return (
    <div className="calender-wrapper">
      {/* <h2 className="font_l fw_6 font_grey text-center">FULL CALENDER</h2> */}
      {type === "month" ? (
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin]}
          headerToolbar={{
            left: "prev,next",
            center: "title",
            right: "dayGridMonth",
          }}
          initialView="dayGridMonth"
          weekends={false}
          events={events}
          editable={true}
          // eventContent={renderEventContent}
        />
      ) : type === "week" ? (
        <FullCalendar
          //   plugins={[dayGridPlugin, timeGridPlugin]}
          //   headerToolbar={{
          //     left: "prev,next",
          //     center: "title",
          //     right: "dayGridMonth",
          //   }}
          //   initialView="timeGridWeek"
          //   weekends={false}
          //   events={events}
          plugins={[dayGridPlugin]}
          initialView="dayGridWeek"
          headerToolbar={{
            left: "prev,next",
            center: "title",
            // right: "dayGridWeek,timeGridDay", // user can switch between the two
          }}
          // eventContent={renderEventContent}
        />
      ) : (
        <FullCalendar
          plugins={[timeGridPlugin]}
          headerToolbar={{
            left: "prev,next",
            center: "title",
            right: "timeGridDay",
          }}
          initialView="timeGridDay"
          weekends={false}
          events={events}
          // eventContent={renderEventContent}
        />
      )}
    </div>
  );
};

export default FullCalenderPage;
