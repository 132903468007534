/**
 *
 */

import React, { useState, useEffect, useRef } from "react";

/* Import configuration starts */
import GLOBAL from "../../../setup/constants/global";
import { useNavigate } from "react-router-dom";
import { initialValues, validations } from "../helper";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  updateProfile,
  getUser,
  updateTenantProfile,
} from "../../../setup/store/slices/authSlice";
import {
  fetchCitiesByStateId,
  fetchCountries,
  fetchStatesByCountryId,
  getCities,
  getCountries,
  getStates,
} from "../../../setup/store/slices/countrySlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomButton from "../../../components/ui/button/CustomButton/CustomButton";
import TextButton from "../../../components/ui/button/TextButton/TextButton";
import CustomTextInput from "../../../components/ui/input/CustomTextInput/CustomTextInput";
import CustomSelectInput from "../../../components/ui/input/CustomSelectInput/CustomSelectInput";
import SuccessModal from "../../../components/ui/modal/SuccessModal/SuccessModal";
import ErrorModal from "../../../components/ui/modal/ErrorModal/ErrorModal";
import {
  availableLocales,
  fetchGenders,
  fetchLocales,
  fetchUserRoles,
  getGenderTypes,
  getUserRoles,
} from "../../../setup/store/slices/masterSlice";
import CustomDateInput from "../../../components/ui/input/CustomDateInput/CustomDateInput";
import CustomPhoneInput from "../../../components/ui/input/CustomPhoneInput/CustomPhoneInput";
/* Import local pages and component ends */

/* Component starts */
const EditProfileForm = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [initialFormValues, setInitialFormValues] = useState(initialValues());
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [modalDesc, setModalDesc] = useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  // const [dob, setDob] = useState();
  const [role, setRole] = useState();

  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // UseRef
  const customPhoneNum = useRef({ code: "", num: "" });

  // Redux Selector
  const countries = useSelector(getCountries);
  const states = useSelector(getStates);
  const cities = useSelector(getCities);
  const user = useSelector(getUser);
  const userRoles = useSelector(getUserRoles);
  const _availableLocales = useSelector(availableLocales);
  const genders = useSelector(getGenderTypes);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // Loading all countries
  const loadCountries = async () => {
    await dispatch(fetchCountries());
  };

  // Loading all states according to selected country
  const loadStates = async () => {
    if (selectedCountry != null) {
      setSelectedState(null);
      setSelectedCity(null);
      await dispatch(
        fetchStatesByCountryId({ country_id: selectedCountry.id })
      );
    }
  };

  // Loading all cities according to selected state
  const loadCities = async () => {
    if (selectedState != null) {
      setSelectedCity(null);
      await dispatch(fetchCitiesByStateId({ state_id: selectedState.id }));
    }
  };

  // Form validations
  const formValidation = (values) => {
    return validations(values, role);
  };

  // Handling on submit
  const handleSubmit = async (values) => {
    // Creating or collecting payload data to be sent
    let payload = {};

    if (role === "Tenant") {
      payload = {
        tenant_id: user._id,
        first_name: values?.first_name.trim(),
        last_name: values?.last_name.trim(),
        profession: values?.profession,
        company_name: values?.business_name,
        gender: values.gender,
        nationality: values.nationality,
        language: values.language,
        email: user?.email,
        mobile: user?.mobile,
        countrycode: user?.countrycode,
      };

      console.log("HI TEST FOR TENANT", payload);
    } else {
      payload = {
        first_name: values?.first_name,
        last_name: values?.last_name,
        business_name: values?.business_name,
        address_line1: values?.address_line1,
        address_line2: values?.address_line2,
        zipcode: values?.zipcode,
        city: selectedCity?.name || "",
        state: selectedState?.name || "",
        country: selectedCountry?.name || "",
      };

      console.log(payload, "OTHER ROLES");
    }

    // Backend response Try, Catch
    let result;
    try {
      if (role !== "Tenant") {
        result = await dispatch(updateProfile(payload));
      } else {
        result = await dispatch(updateTenantProfile(payload));
      }

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setModalDesc(result.payload);
          setShowErrorModal(true);
          break;

        case GLOBAL.FULFILLED:
          setModalDesc("Your profile details has been updated successfully.");
          setShowSuccessModal(true);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  // Loading countries on initial load
  useEffect(() => {
    dispatch(fetchUserRoles());
    dispatch(fetchLocales());
    dispatch(fetchGenders());
    loadCountries();
  }, []);

  // Setting current user
  useEffect(() => {
    if (user) {
      setInitialFormValues(initialValues(user));
    }
  }, [user]);

  // Setting selected country after loading countries
  useEffect(() => {
    if (countries?.length > 0 && user) {
      const defaultCountry = countries?.filter(
        (country) => country?.name === user?.country
      )[0];
      setSelectedCountry(defaultCountry);
    }
  }, [countries, user]);

  // Loading states accoring to country selected
  useEffect(() => {
    loadStates();
  }, [selectedCountry]);

  // Setting selected state after loading state
  useEffect(() => {
    if (states?.length > 0 && user) {
      let defaultState = states?.filter(
        (state) => state?.name === user?.state
      )[0];

      setSelectedState(defaultState || states[0]);
    }
  }, [states, user]);

  // Loading cities accoring to state selected
  useEffect(() => {
    loadCities();
  }, [selectedState]);

  // Setting selected city after loading cities
  useEffect(() => {
    if (cities?.length > 0 && user) {
      let defaultCity = cities?.filter((city) => city?.name === user?.city)[0];

      setSelectedCity(defaultCity || cities[0]);
    }
  }, [cities, user]);

  useEffect(() => {
    let userRole = userRoles.filter((d) => d._id === user?.role_id)[0]?.name;
    setRole(userRole);
  }, [userRoles]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="edit-profile-page-wrapper">
      {/* Edit Form */}
      <Formik
        initialValues={initialFormValues}
        validate={formValidation}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          isValid,
        }) => (
          <form onSubmit={handleSubmit}>
            {role !== "Tenant" ? (
              <div className="form-wrapper detail">
                {/* Input for First Name */}
                <div className="form-group">
                  <CustomTextInput
                    name="first_name"
                    label="First Name"
                    value={values.first_name}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    touched={touched.first_name}
                    errors={errors.first_name}
                  />
                </div>

                {/* Input for Last Name */}
                <div className="form-group">
                  <CustomTextInput
                    name="last_name"
                    label="Last Name"
                    value={values.last_name}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    touched={touched.last_name}
                    errors={errors.last_name}
                  />
                </div>

                {/* Input for Company Name */}
                <div className="form-group form-group-full">
                  <CustomTextInput
                    name="business_name"
                    label="Company Name"
                    value={values.business_name}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    touched={touched.business_name}
                    errors={errors.business_name}
                  />
                </div>

                {/* Input for Address 1 */}
                <div className="form-group">
                  <CustomTextInput
                    name="address_line1"
                    label="Address 1"
                    value={values.address_line1}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    touched={touched.address_line1}
                    errors={errors.address_line1}
                  />
                </div>

                {/* Input for Address 2 */}
                <div className="form-group">
                  <CustomTextInput
                    name="address_line2"
                    label="Address 2"
                    value={values.address_line2}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    touched={touched.address_line2}
                    errors={errors.address_line2}
                    required={false}
                  />
                </div>

                {/* Select for City */}
                <div className="form-group">
                  <CustomSelectInput
                    label="City"
                    valueExtractor={(item) => item?.name}
                    setValue={setSelectedCity}
                    options={cities}
                    value={selectedCity}
                    formikState={false}
                    emptyOption={false}
                  />
                </div>

                {/* Select for State */}
                <div className="form-group">
                  <CustomSelectInput
                    label="State"
                    valueExtractor={(item) => item?.name}
                    setValue={setSelectedState}
                    options={states}
                    value={selectedState}
                    formikState={false}
                    emptyOption={false}
                  />
                </div>

                {/* Select for Country */}

                <div className="form-group">
                  <CustomSelectInput
                    label="Country"
                    valueExtractor={(item) => item?.name}
                    setValue={setSelectedCountry}
                    options={countries}
                    value={selectedCountry}
                    formikState={false}
                    emptyOption={false}
                  />
                </div>

                {/* Input for Zipcode */}
                <div className="form-group">
                  <CustomTextInput
                    name="zipcode"
                    label="Zip Code"
                    value={values.zipcode}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    touched={touched.zipcode}
                    errors={errors.zipcode}
                  />
                </div>
              </div>
            ) : (
              <div className="form-wrapper detail">
                {/* Input for First Name */}
                <div className="form-group">
                  <CustomTextInput
                    name="first_name"
                    label="First Name"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    touched={touched.first_name}
                    errors={errors.first_name}
                    value={values.first_name}
                  />
                </div>

                {/* Input for Last Name */}
                <div className="form-group">
                  <CustomTextInput
                    name="last_name"
                    label="Last Name"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    touched={touched.last_name}
                    errors={errors.last_name}
                    value={values.last_name}
                  />
                </div>

                {/* Input for Email */}
                <div className="form-group">
                  <CustomTextInput
                    name="email"
                    label="Email"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    touched={touched.email}
                    errors={errors.email}
                    value={values.email}
                    readOnly={true}
                  />
                </div>

                {/* Input for Phone */}
                <div className="form-group">
                  <CustomPhoneInput
                    name="mobile"
                    label={"Phone Number"}
                    customPhoneNum={customPhoneNum}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    value={values?.countrycode + " " + values?.mobile}
                    countryCodeEditable={true}
                    errors={errors.mobile}
                    touched={touched.mobile}
                    readOnly={true}
                  />
                </div>

                {/* Input for Date of Birth */}
                {/* <div className="form-group">
                  <CustomDateInput
                    label="Date of Birth"
                    value={dob}
                    setValue={setDob}
                    minDate={false}
                  />
                </div> */}

                {/* Select for Gender */}
                <div className="form-group">
                  <CustomSelectInput
                    name="gender"
                    value={values.gender}
                    touched={touched.gender}
                    errors={errors.gender}
                    label="Gender"
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    options={genders}
                    valueExtractor={(item) => item?.name}
                    formikState={true}
                    emptyOption={true}
                  />
                </div>

                {/* Input for Profession */}
                <div className="form-group">
                  <CustomTextInput
                    name="profession"
                    label="Profession"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    touched={touched.profession}
                    errors={errors.profession}
                    value={values.profession}
                    required={false}
                  />
                </div>

                {/* Input for Company Name */}
                <div className="form-group">
                  <CustomTextInput
                    name="business_name"
                    label="Company Name"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    touched={touched.business_name}
                    errors={errors.business_name}
                    value={values.business_name}
                    required={false}
                  />
                </div>

                {/* Select for Nationality */}
                <div className="form-group">
                  <CustomSelectInput
                    name="nationality"
                    value={values.nationality}
                    touched={touched.nationality}
                    errors={errors.nationality}
                    label="Nationality"
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    options={countries}
                    valueExtractor={(item) => item?.name}
                    formikState={true}
                    emptyOption={true}
                  />
                </div>

                {/* Select for Language */}
                <div className="form-group">
                  <CustomSelectInput
                    name="language"
                    value={values.language}
                    touched={touched.language}
                    errors={errors.language}
                    label="Language"
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    options={_availableLocales}
                    valueExtractor={(item) => item?.name}
                    formikState={true}
                    emptyOption={true}
                  />
                </div>
              </div>
            )}
            {/* Buttons */}
            <div className="button-wrapper">
              <CustomButton type="submit" size="l">
                Update
              </CustomButton>
              <TextButton
                type="button"
                onClick={() => navigate(-1)}
                className="fw_6 w_100 font_m"
              >
                Cancel
              </TextButton>
            </div>
          </form>
        )}
      </Formik>

      {/* Modals */}
      {/* Success Modal */}
      <SuccessModal
        showModal={showSuccessModal}
        description={modalDesc}
        title="Profile Updated!"
        onClose={() => navigate(-1)}
      />

      {/* Error Modal */}
      <ErrorModal
        showModal={showErrorModal}
        description={modalDesc}
        title="Error!"
        onClose={() => setShowErrorModal(false)}
      />
    </div>
  );
};
/* Component ends */

export default EditProfileForm;
