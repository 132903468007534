/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { formatCurrency } from "../../../../setup/utils/global-helper";
import moment from "moment/moment";
import GLOBAL from "../../../../setup/constants/global";
import { useNavigate } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import MUI component starts */
/* Import MUI component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
import RemoveLicenseModal from "../RemoveLicenseModal/RemoveLicenseModal";
import SuccessModal from "../../../ui/modal/SuccessModal/SuccessModal";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const LicenseOverViewDetail = (props) => {
  /* Props destructuring starts */
  const { user, settings } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [showRemoveLicenseModal, setShowRemoveLicenseModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  // Add licenses to the account
  const purchaseLicense = () => {
    navigate("/settings/manageLicense/purchase");
  };

  // Delete licenses from account
  const deleteLicense = () => {
    setShowRemoveLicenseModal(true);
  };

  // On successfully removing license
  const onRemoveLicenseSuccess = () => {
    setModalMessage("License successfully removed from account");
    setShowRemoveLicenseModal(false);
    setShowSuccessModal(true);
  };

  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="license-overview-detail-component-wrapper">
      {/* Avaiable Licenses */}
      <div className="box">
        <p className="title fw_5 font_m font_grey">Available Licenses</p>
        <p className="num font_xl fw_5">
          {user?.available_license?.toLocaleString("en-US")}
        </p>
        <div className="footer-wrapper button-wrapper">
          <CustomButton onClick={purchaseLicense} variant="primary" size="s">
            +Add
          </CustomButton>
          <CustomButton onClick={deleteLicense} variant="red" size="s">
            Remove
          </CustomButton>
        </div>
      </div>

      {/* Total Licenses */}

      <div className="box">
        <p className="title fw_5 font_m font_grey">Total Licenses</p>
        <p className="num font_xl fw_5">
          {user?.total_license?.toLocaleString("en-US")}
        </p>
        <div className="footer-wrapper">
          <div className="plan fw_5 d_inlineblock font_grey font_s">
            Monthly Plan
          </div>
        </div>
      </div>

      {/* Upcoming Invoice Amount */}
      <div className="box" onClick={() => navigate("invoices")}>
        <p className="title fw_5 font_m font_grey">Upcoming Invoice Amount</p>
        <p className="num font_xl fw_5">
          {formatCurrency(
            (user?.total_license * settings?.perUnitPrice)
              .toFixed(2)
              .toString(),
            "USD"
          )}
        </p>
        <div className="footer-wrapper">
          <span className="due-date font_grey font_s fw_5">
            Due On:{" "}
            {moment().add(1, "months").startOf("month").format(DATE_FORMAT)}
          </span>
        </div>
      </div>

      {/* Modal */}
      {/* Remove License */}
      <RemoveLicenseModal
        maxLicense={user?.available_license}
        showModal={showRemoveLicenseModal}
        onSuccess={onRemoveLicenseSuccess}
        onCancel={() => setShowRemoveLicenseModal(false)}
      />

      {/* Success Modal */}
      <SuccessModal
        showModal={showSuccessModal}
        description={modalMessage}
        title="Success"
        onClose={() => setShowSuccessModal(false)}
      />
    </div>
  );
};
/* Component ends */

export default LicenseOverViewDetail;
