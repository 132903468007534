/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Table } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { EyeFilledBlue } from "../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../../../ui/button/IconButton/IconButton";
import TenantNotificationModal from "../../TenantNotificationModal/TenantNotificationModal";
import { useLocation } from "react-router-dom";
/* Import local pages and component ends */

/* Component starts */
const Notifications = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [showModal, setShowModal] = useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const { pathname } = useLocation();
  /* Other hooks declaration ends */

  // DUMMY DATA
  const notificationData = [];

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const onClose = () => {
    setShowModal(!showModal);
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="custom-table-wrapper notification-table">
      {notificationData?.length > 0 && (
        <Table className="custom-table font_s" borderless responsive>
          {/* Table Heading */}
          <thead>
            <tr>
              <th>Date</th>
              <th>Time</th>
              <th>Notification</th>
              <th>Type</th>
              <th className="text-start">Action</th>
            </tr>
          </thead>
          {/* Table Body */}
          <tbody>
            {notificationData?.map((item) => (
              <tr key={item?.id}>
                {/* Date */}
                <td>{item?.date}</td>

                {/* Time */}
                <td>{item?.time}</td>

                {/*Notification */}
                <td>{item?.notification}</td>

                {/* Type */}
                <td>{item?.type}</td>

                {/* Actions */}
                <td className="text-start txt-nowrap">
                  <IconButton
                    // onClick={() =>
                    //   navigate("/property/lostAndFound/detail-found", {
                    //     state: { item },
                    //   })
                    // }
                    className="action-button"
                  >
                    <img
                      src={EyeFilledBlue}
                      className="h_100"
                      alt="View Details"
                    />
                  </IconButton>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      {notificationData?.length === 0 && (
        <div className=" text-center">
          <h3 className=" font_m font_yellow fw_6">No Data</h3>
        </div>
      )}

      {!pathname?.split("/")?.includes("previous-tenants") && (
        <div className="btn">
          <p
            className="font_m fw_3 font_blue text-center"
            onClick={() => setShowModal(!showModal)}
          >
            Issue Notification
          </p>
        </div>
      )}

      {/* NOTIFICATION MODAL */}
      <TenantNotificationModal showModal={showModal} onclose={onClose} />
    </div>
  );
};
/* Component ends */

export default Notifications;
