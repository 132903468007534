/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import moment from "moment";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import GLOBAL from "../../../../setup/constants/global";
/* Import local pages and component ends */

/* Component starts */

const Report = (props) => {
  /* Props destructuring starts */
  const { incident, type } = props;
  console.log(incident, "CLAIM ===", type);
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */

  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */

  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="unit-detail-box">
      <p className="num fw_4 font_m font_grey">
        {" "}
        Ticket No. :-{" "}
        <span className="font_blue fw_6">
          {incident?.found_id?.lostfound_id}
        </span>{" "}
      </p>
      <h3 className="num fw_6 font_l">
        {" "}
        {type === undefined
          ? incident?.found_id?.item_name
          : incident?.lost_id?.item_name}{" "}
      </h3>
      <p className="title">
        {type === undefined
          ? incident?.found_id?.item_description
          : incident?.lost_id?.item_description}
      </p>

      <div className="date">
        {type === undefined && (
          <>
            <span>{`Date : ${moment(incident?.lost_id?.createdAt).format(
              DATE_FORMAT
            )}`}</span>
            <span>{`Time : ${moment(incident?.lost_id?.createdAt).format(
              TIME_FORMAT
            )}`}</span>{" "}
          </>
        )}
      </div>

      <hr />

      <div className="date-wrapper">
        <div className="detail">
          <p className="fw_4">
            {type === undefined ? "Found Date" : "Lost Date"}
            <br />{" "}
            <span>
              {type === undefined
                ? `${moment(incident?.found_id?.createdAt).format(DATE_FORMAT)}`
                : `${moment(incident?.lost_id?.createdAt).format(DATE_FORMAT)}`}
            </span>
          </p>
        </div>
        <div className="detail">
          <p className="fw_4">
            {type === undefined ? "Found Time" : "Lost Time"} <br />{" "}
            <span>
              {type === undefined
                ? `${moment(incident?.found_id?.createdAt).format(TIME_FORMAT)}`
                : `${moment(incident?.lost_id?.createdAt).format(TIME_FORMAT)}`}
            </span>
          </p>
        </div>
        <div className="detail">
          <p className="fw_4">
            {type === undefined ? "Location" : ""}
            <br />
            <span>{incident?.found_id?.location}</span>
          </p>
        </div>
        <div className="detail">
          <p className="fw_4">
            {type === undefined ? "Found By" : ""}
            <br />
            <span>{incident?.found_id?.found_by}</span>
          </p>
        </div>
      </div>

      <hr />

      <div className="ticket-wrapper">
        <div className="fw_4">
          <p className="font_grey font_m">
            Claimer's Name <br />
            <span className="font_m fw_6">
              {incident?.first_name !== undefined
                ? `${incident?.first_name}${" "}${incident?.last_name}`
                : "-"}
            </span>
          </p>
        </div>

        <div className="fw_4">
          <p className="font_grey font_m">
            Claimer's Mobile Number <br />
            <span className="font_m">
              {incident?.mobile_number !== undefined
                ? incident?.mobile_number
                : "-"}
            </span>
          </p>
        </div>

        <div className="fw_4">
          <p className="font_grey font_m">
            Claimed Date <br />
            <span className="font_m">
              {moment(incident?.claim_date).format(DATE_FORMAT)}
            </span>
          </p>
        </div>

        <div className="fw_4">
          <p className="font_grey font_m">
            Claimed Time <br />
            <span className="font_m">
              {moment(incident?.claim_date).format(TIME_FORMAT)}
            </span>
          </p>
        </div>
      </div>

      <hr />

      <div className="ticket-id-wrapper">
        <div className="fw_4">
          <p className="font_grey font_m">
            Claimer's ID Type <br />
            <span className="font_m fw_6">
              {incident?.id_type !== undefined ? incident?.id_type : "-"}
            </span>
          </p>
        </div>

        <div className="fw_4">
          <p className="font_grey font_m">
            Claimer's ID Number <br />
            <span className="font_m fw_6 font_blue">
              {incident?.id_number !== undefined ? incident?.id_number : "-"}
            </span>
          </p>
        </div>
      </div>

      <hr />

      <div className="claim-by">
        <p className="font_grey font_m fw_4">
          Issued By{" "}
          <span className="font_m fw_6">
            {incident?.claim_by?.first_name} {incident?.claim_by?.last_name}
          </span>
        </p>
      </div>
    </div>
  );
};
/* Component Ends */
export default Report;
