/**
 *
 */

import React, { useState } from "react";

/* Import configuration starts */
import GLOBAL from "../../../../../setup/constants/global";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../../../setup/store/slices/globalAlertSlice";
import {
  releaseClearanceLetter,
  releaseUnit,
} from "../../../../../setup/store/slices/moveOutSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomButton from "../../../../ui/button/CustomButton/CustomButton";
import FileViewerModal from "../../../../ui/modal/FileViewerModal/FileViewerModal";
import ClearenceDocViewTable from "./ClearenceDocViewTable";
/* Import local pages and component ends */

/* Component starts */
const ReleaseClearence = (props) => {
  /* Props destructuring starts */
  const {
    move_out_id,
    forceMoveout,
    moveOut,
    application_id,
    moveoutStatus,
    clearenceDoc,
    clearenceAuditLog,
    getMoveoutDetails,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [file, setFile] = useState({});
  const [showFile, setShowFile] = useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const { propertyId } = useParams();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const release = async () => {
    const payload = {
      move_out_id,
      property_id: propertyId,
      application_id,
    };

    try {
      const result = await dispatch(releaseClearanceLetter(payload));
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error",
              description: result.payload,
            })
          );
          break;
        case GLOBAL.FULFILLED:
          getMoveoutDetails();
          dispatch(
            showSuccessAlert({
              title: "Success",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  const releaseUnitWithoutClearence = async () => {
    const payload = {
      move_out_id,
      unit_id: moveOut.tenant_application.unit._id,
      tenant_id: moveOut.tenant_application.tenant._id,
      property_id: propertyId,
    };

    try {
      const result = await dispatch(releaseUnit(payload));
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error",
              description: result.payload,
            })
          );
          break;
        case GLOBAL.FULFILLED:
          getMoveoutDetails();
          dispatch(
            showSuccessAlert({
              title: "Success",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  const canReleaseEarlyMoveOutClearance = () => {
    if (
      moveOut?.move_out?.status === "Accountant clearance pending" &&
      moveOut?.move_out?.type === "initial_move_out"
    ) {
      if (moveOut?.other_invoices?.length === 0) return true;
      for (let i = 0; i < moveOut.other_invoices.length; i++) {
        let invoice = moveOut.other_invoices[i];
        if (invoice.status === GLOBAL.UNPAID) return false;
      }
      return true;
    }
    return false;
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="container_md">
      <div className="btn-wrapper">
        {((moveOut?.issue_clearance &&
          (moveOut?.move_out?.status === "Accountant clearance pending" ||
            moveOut?.move_out?.status === "Owner clearance pending")) ||
          canReleaseEarlyMoveOutClearance()) && (
          <CustomButton onClick={release} size="l">
            Release Clearence Letter
          </CustomButton>
        )}

        {(moveOut?.move_out?.status === "Accountant clearance pending" ||
          moveOut?.move_out?.status === "Owner clearance pending") && (
          <CustomButton onClick={releaseUnitWithoutClearence} size="l">
            Release Unit Without Clearence
          </CustomButton>
        )}
      </div>

      {moveOut?.move_out?.clearance_certificate_pdf && (
        <ClearenceDocViewTable
          moveoutStatus={moveoutStatus}
          moveOut={moveOut}
          doc={moveOut.move_out?.clearance_certificate_pdf}
          auditLogs={moveOut.move_out.clearance_certificate_audit_logs}
        />
      )}

      {moveOut?.move_out?.status === "pending_clearance_approval" && (
        <h2 className="font_xl fw_6 font_yellow text-center clearence-status">
          Pending Clearance Certificate Signature
        </h2>
      )}

      {moveoutStatus === "Approved" && !clearenceDoc && !clearenceAuditLog && (
        <h2 className="font_xl fw_6 font_yellow text-center clearence-status">
          {/* Unit successfully released without any clearence. */}
          Unit successfully released without clearance certificate.
        </h2>
      )}

      <FileViewerModal
        show={showFile}
        file={file?.url}
        type={file?.type}
        onClose={() => setShowFile(false)}
      />
    </div>
  );
};
/* Component ends */

export default ReleaseClearence;
