/**
 *
 */

import React, { useEffect, useState } from "react";

/* Import configuration starts */
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import { setLoading } from "../../../setup/store/slices/unpersistedSlice";
import {
  fetchUserRoles,
  getUserRoles,
} from "../../../setup/store/slices/masterSlice";
import { fetchUser, getUser } from "../../../setup/store/slices/authSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import MUI component starts */
/* Import MUI component ends */

/* Import image and SVG starts */
import {
  Unit,
  License,
  WarningRoundedOutlineYellow,
  Teams,
  LostAndFound,
  Voilation,
  VisitorLogBook,
  VisitorPass,
  Security,
  Tenants,
  SettingsFilledBlue,
  ManageMaintenance,
  RoutineRequest,
  InternalMaintenance,
  InternalCommunication,
  Advertising,
  Annoucments,
  BluePrint,
  RatingAndReview,
  ManageFacilities,
  StaffSchedule,
  Dashboard,
  Invoicing,
  ExcalmatoryYellow,
} from "../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../../components/ui/button/IconButton/IconButton";

/* Import local pages and component ends */

/* Component starts */
const Modules = (props) => {
  /* Props destructuring starts */
  const { property } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [role, setRole] = useState();
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userRoles = useSelector(getUserRoles);
  const user = useSelector(getUser);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const missingLicense = property?.missing_items.includes("license");
  const missingUnit = property?.missing_items.includes("units");
  const missingTeam = property?.missing_items.includes("team");
  const missingPropertySetting =
    property?.missing_items.includes("property_setting");

  const manageModel = property?.mgmt_model;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // On clicking on manage teams
  const manageTeam = () => {};

  // On clicking on manage teams
  const manageUnits = () => {};
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    dispatch(fetchUserRoles());
    dispatch(fetchUser());
  }, []);

  // Setting role after all user roles loaded
  useEffect(() => {
    let userRole = userRoles?.filter((d) => d?._id === user?.role_id)[0]?.name;
    setRole(userRole);
  }, [userRoles]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      <div className="options">
        {/* Manage Units */}
        {["Client", "Owner", "Property Manager"].includes(role) && (
          <IconButton
            className="box d_block"
            onClick={() => {
              navigate("units");
            }}
          >
            {missingUnit && (
              <span className="missing d_block">
                <img
                  src={ExcalmatoryYellow}
                  className="w_100"
                  alt="Missing Icon"
                />
              </span>
            )}
            <div className="icon text-center mx-auto">
              <img className="h_100" src={Unit} alt="Unit Icon" />
            </div>
            <p className="text font_m fw_6 font_grey">
              {/* Manage <br />  */}
              Units
            </p>
          </IconButton>
        )}

        {/* Assign License */}
        {["Client"].includes(role) && (
          <IconButton
            className="box d_block"
            onClick={() => {
              navigate("license/assign");
            }}
          >
            {missingLicense && (
              <span className="missing d_block">
                <img
                  src={ExcalmatoryYellow}
                  className="w_100"
                  alt="Missing Icon"
                />
              </span>
            )}
            <div className="icon text-center mx-auto">
              <img className="h_100" src={License} alt="Unit Icon" />
            </div>
            <p className="text font_m fw_6 font_grey">
              Assign <br /> License
            </p>
          </IconButton>
        )}

        {/* Manage Teams  */}
        {[
          "Client",
          "Owner",
          "Property Management Company",
          "Property Manager",
          "Facility Management Company",
          "Facility Manager",
          "Security Management Company",
          "Security Manager",
          "Third-Party Company",
          "Third Party Manager",
        ].includes(role) && (
          <IconButton
            className="box d_block"
            onClick={() => {
              navigate("teams");
            }}
          >
            {missingTeam && (
              <span className="missing d_block">
                <img
                  src={ExcalmatoryYellow}
                  className="w_100"
                  alt="Missing Icon"
                />
              </span>
            )}
            <div className="icon text-center mx-auto">
              <img className="h_100" src={Teams} alt="Unit Icon" />
            </div>
            <p className="text font_m fw_6 font_grey">
              {/* Manage <br /> */}
              Teams
            </p>
          </IconButton>
        )}

        {/* Manage Tenants */}
        {[
          "Client",
          "Owner",
          "Property Manager",
          "Accountant",
          "Security Manager",
          "Security Officer",
        ].includes(role) && (
          <IconButton
            className="box d_block"
            onClick={() => {
              navigate("tenants/existing");
            }}
          >
            <div className="icon text-center mx-auto">
              <img className="h_100" src={Tenants} alt="Unit Icon" />
            </div>
            <p className="text font_m fw_6 font_grey">
              {/* Manage <br />  */}
              Tenants
            </p>
          </IconButton>
        )}

        {/* Manage Maintenance */}
        {[
          "Client",
          "Owner",
          "Property Manager",
          "Facility Management Company",
          "Facility Manager",
          "Security Manager",
          "Security Officer",
          "Maintenance Technician",
          "Third-Party Company",
          "Third Party Manager",
          "Third Party Technician",
        ].includes(role) && (
          <IconButton
            className="box d_block"
            onClick={() => {
              navigate("maintenances/New");
            }}
          >
            <div className="icon text-center mx-auto">
              <img
                className="h_100"
                src={ManageMaintenance}
                alt="Maintenance Icon"
              />
            </div>
            <p className="text font_m fw_6 font_grey">
              {/* Manage <br />  */}
              Maintenance
            </p>
          </IconButton>
        )}

        {/* Internal Maintenance Requests */}
        {[
          "Client",
          "Owner",
          "Property Manager",
          "Facility Management Company",
          "Facility Manager",
          "Security Manager",
          "Security Officer",
          "Maintenance Technician",
          "Third-Party Company",
          "Third Party Manager",
          "Third Party Technician",
        ].includes(role) && (
          <IconButton
            className="box d_block"
            onClick={() => {
              navigate("internal/maintenances/new");
            }}
          >
            <div className="icon text-center mx-auto">
              <img
                className="h_100"
                src={InternalMaintenance}
                alt="Internal Icon"
              />
            </div>
            <p className="text font_m fw_6 font_grey">
              Internal <br /> Maintenance
            </p>
          </IconButton>
        )}

        {/* INVOICING */}
        {[
          "Client",
          "Owner",
          "Property Management Company",
          "Property Manager",
          "Accountant",
          "Facility Management Company",
          "Facility Manager",
          "Security Management Company",
          "Security Manager",
          "Third-Party Company",
          "Third Party Manager",
        ].includes(role) &&
          manageModel !== 1 && (
            <IconButton
              className="box d_block"
              onClick={() => {
                navigate("invoicing/Tenant/rent");
              }}
            >
              <div className="icon text-center mx-auto">
                <img className="h_100" src={Invoicing} alt="Unit Icon" />
              </div>
              <p className="text font_m fw_6 font_grey">Invoicing</p>
            </IconButton>
          )}

        {/* Voilation */}
        {[
          "Client",
          "Owner",
          "Property Manager",
          "Accountant",
          "Facility Management Company",
          "Facility Manager",
          "Security Management Company",
          "Security Manager",
          "Security Officer",
          "Maintenance Technician",
          "Third-Party Company",
          "Third Party Manager",
        ].includes(role) &&
          manageModel !== 1 && (
            <IconButton
              className="box d_block"
              onClick={() => {
                navigate("violations");
              }}
            >
              <div className="icon text-center mx-auto">
                <img className="h_100" src={Voilation} alt="Unit Icon" />
              </div>
              <p className="text font_m fw_6 font_grey">Violations</p>
            </IconButton>
          )}

        {/* Announcments */}
        {[
          "Client",
          "Owner",
          "Property Manager",
          "Facility Manager",
          "Security Manager",
          "Security Officer",
          "Maintenance Technician",
          "Third Party Manager",
          "Third Party Technician",
        ].includes(role) && (
          <IconButton
            className="box d_block"
            onClick={() => {
              navigate("annoucements");
            }}
          >
            <div className="icon text-center mx-auto">
              <img className="h_100" src={Annoucments} alt="Internal Icon" />
            </div>
            <p className="text font_m fw_6 font_grey">Announcements</p>
          </IconButton>
        )}

        {/* Lost And Found */}
        {[
          "Client",
          "Owner",
          "Property Manager",
          "Security Manager",
          "Security Officer",
        ].includes(role) &&
          manageModel === 2 && (
            <IconButton
              className="box d_block"
              onClick={() => {
                navigate("lost_and_found/found");
              }}
            >
              <div className="icon text-center mx-auto">
                <img className="h_100" src={LostAndFound} alt="Unit Icon" />
              </div>
              <p className="text font_m fw_6 font_grey">
                Lost & <br /> Found
              </p>
            </IconButton>
          )}

        {/* Manage Security */}
        {[
          "Client",
          "Owner",
          "Property Manager",
          "Security Manager",
          "Security Officer",
        ].includes(role) &&
          manageModel !== 1 && (
            // manageModel !== 0 &&
            <IconButton
              className="box d_block"
              onClick={() => {
                navigate("security/complaints/open");
              }}
            >
              <div className="icon text-center mx-auto">
                <img className="h_100" src={Security} alt="Unit Icon" />
              </div>
              <p className="text font_m fw_6 font_grey">
                {/* Manage <br />  */}
                Security
              </p>
            </IconButton>
          )}

        {/* Property Settings */}
        {["Client", "Owner", "Property Manager"].includes(role) && (
          <IconButton
            className="box d_block"
            onClick={() => {
              dispatch(setLoading(true));
              navigate("settings");
            }}
          >
            {missingPropertySetting && (
              <span className="missing d_block">
                <img
                  src={ExcalmatoryYellow}
                  className="w_100"
                  alt="Missing Icon"
                />
              </span>
            )}
            <div className="icon text-center mx-auto">
              <img className="h_100" src={SettingsFilledBlue} alt="Unit Icon" />
            </div>
            <p className="text font_m fw_6 font_grey">
              Property <br /> Settings
            </p>
          </IconButton>
        )}

        {/* Rating And Review */}
        {[
          "Client",
          "Owner",
          "Property Management Company",
          "Property Manager",
          "Facility Management Company",
          "Facility Manager",
          "Security Management Company",
          "Security Manager",
          "Third-Party Company",
          "Third Party Manager",
        ].includes(role) && (
          <IconButton
            className="box d_block"
            onClick={() => {
              navigate("ratingAndReviews");
            }}
          >
            <div className="icon text-center mx-auto">
              <img
                className="h_100"
                src={RatingAndReview}
                alt="Internal Icon"
              />
            </div>
            <p className="text font_m fw_6 font_grey">
              Ratings
              <br />& Reviews
            </p>
          </IconButton>
        )}

        {/* Visitor Access Pass */}
        {[
          "Client",
          "Owner",
          "Property Manager",
          "Security Manager",
          "Security Officer",
        ].includes(role) &&
          manageModel === 2 && (
            <IconButton
              className="box d_block"
              onClick={() => {
                navigate("access_pass/visitors");
              }}
            >
              <div className="icon text-center mx-auto">
                <img className="h_100" src={VisitorPass} alt="Unit Icon" />
              </div>
              <p className="text font_m fw_6 font_grey">
                Visitor <br /> Access Pass
              </p>
            </IconButton>
          )}

        {/* Visitor Log Book */}
        {[
          "Client",
          "Owner",
          "Property Manager",
          "Security Manager",
          "Security Officer",
        ].includes(role) &&
          manageModel === 2 && (
            <IconButton
              className="box d_block"
              onClick={() => {
                navigate("visitor-logs");
              }}
            >
              <div className="icon text-center mx-auto">
                <img className="h_100" src={VisitorLogBook} alt="Unit Icon" />
              </div>
              <p className="text font_m fw_6 font_grey">
                Visitors <br /> Log Book
              </p>
            </IconButton>
          )}
      </div>

      <hr
        className="divider"
        // style={{ marginTop: "2rem", border: "2px solid #939393" }}
      />

      <div className="future-options">
        {/* FUTURE FEATURES */}

        {/* Manage Facilities */}
        {[
          "Client",
          "Owner",
          "Property Manager",
          "Accountant",
          "Security Manager",
          "Security Officer",
        ].includes(role) &&
          manageModel !== 0 && (
            <IconButton
              className="box d_block"
              // onClick={() => {
              //   navigate("/property/manage-facilities/list");
              // }}
            >
              <div className="icon text-center mx-auto">
                <img className="h_100" src={ManageFacilities} alt="Unit Icon" />
              </div>
              <p className="text font_m fw_6 font_grey">
                {/* Manage <br />  */}
                Facilities
              </p>
              <span className="text font_m fw_6 font_yellow my-2">
                Comming Soon!
              </span>
            </IconButton>
          )}

        {/* Routine Requests */}
        {[
          "Client",
          "Owner",
          "Property Manager",
          "Facility Management Company",
          "Facility Manager",
          "Security Manager",
          "Security Officer",
          "Maintenance Technician",
          "Third-Party Company",
          "Third Party Manager",
          "Third Party Technician",
        ].includes(role) &&
          manageModel !== 0 && (
            <IconButton
              className="box d_block"
              // onClick={() => {
              //   navigate("/property/routine-request/list");
              // }}
            >
              <div className="icon text-center mx-auto">
                <img
                  className="h_100"
                  src={RoutineRequest}
                  alt="Routine Icon"
                />
              </div>
              <p className="text font_m fw_6 font_grey">
                Routine <br /> Requests
              </p>
              <span className="text font_m fw_6 font_yellow my-2">
                Comming Soon!
              </span>
            </IconButton>
          )}

        {/* Internal Communication */}
        {[
          "Client",
          "Owner",
          "Property Manager",
          "Facility Manager",
          "Accountant",
          "Security Manager",
          "Security Officer",
          "Maintenance Technician",
          "Third Party Manager",
          "Third Party Technician",
        ].includes(role) &&
          manageModel !== 0 && (
            <IconButton
              className="box d_block"
              // onClick={() => {
              //   navigate("/property/internal-communication/list");
              // }}
            >
              <div className="icon text-center mx-auto">
                <img
                  className="h_100"
                  src={InternalCommunication}
                  alt="Routine Icon"
                />
              </div>
              <p className="text font_m fw_6 font_grey">
                Internal <br /> Communication
              </p>
              <span className="text font_m fw_6 font_yellow my-2">
                Comming Soon!
              </span>
            </IconButton>
          )}

        {/* Advertising */}
        {[
          "Client",
          "Owner",
          "Property Manager",
          "Third-Party Company",
        ].includes(role) &&
          manageModel !== 1 && (
            <IconButton
              className="box d_block"
              // onClick={() => {
              //   navigate("/property/advertising/list");
              // }}
            >
              <div className="icon text-center mx-auto">
                <img className="h_100" src={Advertising} alt="Internal Icon" />
              </div>
              <p className="text font_m fw_6 font_grey">Advertising</p>
              <span className="text font_m fw_6 font_yellow my-2">
                Comming Soon!
              </span>
            </IconButton>
          )}

        {/* Blue Prints */}
        {[
          "Client",
          "Owner",
          "Property Manager",
          "Accountant",
          "Facility Management Company",
          "Facility Manager",
          "Security Management Company",
          "Security Manager",
          "Security Officer",
          "Maintenance Technician",
          "Third-Party Company",
          "Third Party Manager",
          "Third Party Technician",
        ].includes(role) && (
          <IconButton
            className="box d_block"
            // onClick={() => {
            //   navigate("/property/blueprints/list");
            // }}
          >
            <div className="icon text-center mx-auto">
              <img className="h_100" src={BluePrint} alt="Internal Icon" />
            </div>
            <p className="text font_m fw_6 font_grey">Blueprints</p>
            <span className="text font_m fw_6 font_yellow my-2">
              Comming Soon!
            </span>
          </IconButton>
        )}

        {/* Dashboards  */}
        {[
          "Client",
          "Owner",
          "Property Management Company",
          "Property Manager",
          "Accountant",
          "Facility Management Company",
          "Facility Manager",
          "Security Management Company",
          "Security Manager",
          "Security Officer",
          "Maintenance Technician",
          "Third-Party Company",
          "Third Party Manager",
          "Third Party Technician",
        ].includes(role) && (
          <IconButton
            className="box d_block"
            // onClick={() => {
            //   navigate("dashboard");
            // }}
          >
            <div className="icon text-center mx-auto">
              <img className="h_100" src={Dashboard} alt="Unit Icon" />
            </div>
            <p className="text font_m fw_6 font_grey">Dashboards</p>
            <span className="text font_m fw_6 font_yellow my-2">
              Comming Soon!
            </span>
          </IconButton>
        )}

        {/* Staff Schedule */}
        {[
          "Client",
          "Owner",
          "Property Management Company",
          "Property Manager",
          "Facility Management Company",
          "Facility Manager",
          "Security Management Company",
          "Security Manager",
          "Security Officer",
          "Maintenance Technician",
          "Third-Party Company",
          "Third Party Manager",
          "Third Party Technician",
        ].includes(role) &&
          manageModel !== 0 && (
            <IconButton
              className="box d_block"
              // onClick={() => {
              //   navigate("/property/staff-scheduling");
              // }}
            >
              <div className="icon text-center mx-auto">
                <img className="h_100" src={StaffSchedule} alt="Unit Icon" />
              </div>
              <p className="text font_m fw_6 font_grey">
                Staff <br /> Scheduling
              </p>
              <span className="text font_m fw_6 font_yellow my-2">
                Comming Soon!
              </span>
            </IconButton>
          )}
      </div>
    </>
  );
};
/* Component ends */

export default Modules;
