/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import GLOBAL from "../../setup/constants/global";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  fetchTenantContracts,
  getTenantContractRenewal,
  getTenantContracts,
} from "../../setup/store/slices/tenantSlice";
import { showErrorAlert } from "../../setup/store/slices/globalAlertSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import ContractList from "../../components/module/Contracts/ContractList/ContractList";
/* Import local pages and component ends */

/* Component starts */
const ContractListPage = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const { unitId } = useParams();
  const dispatch = useDispatch();
  // Redux Selectors
  const contracts = useSelector(getTenantContracts);
  const renewal = useSelector(getTenantContractRenewal);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const handleFetchContractList = async () => {
    // Creating or collecting payload data to be sent
    const payload = { unit_id: unitId };
    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      const result = await dispatch(fetchTenantContracts(payload));
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description:
                typeof result?.payload === "string" ? result?.payload : "",
            })
          );
          break;

        case GLOBAL.FULFILLED:
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    handleFetchContractList();
  }, []);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="container_md">
      <ContractList
        contracts={contracts}
        renewal={renewal}
        handleFetchContractList={handleFetchContractList}
      />
    </div>
  );
};
/* Component ends */

export default ContractListPage;
