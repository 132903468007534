/**
 *
 */

import React from "react";

/* Import configuration starts */
import { useSelector } from "react-redux";
import moment from "moment";
import { useNavigate } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
import { getUserTheme } from "../../../../setup/store/slices/authSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Table } from "react-bootstrap";
import { Modal } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import {
  XGreen,
  EyeFilledBlue,
  ChequeOutlineBlue,
  DocumentGreen,
} from "../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../../ui/button/IconButton/IconButton";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const InstallmentPlanDetailModal = (props) => {
  /* Props destructuring starts */
  const {
    showModal = true,
    title = "Installment Plan",
    size = "lg",
    onClose,
    instalments,
    instalmentPlan,
    settings,
    data,
    onViewChequeDetails,
    setSelectedCheque,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */

  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const userTheme = useSelector(getUserTheme);
  const navigate = useNavigate();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const deposit = instalmentPlan?.deposit;
  const depositAmt = instalmentPlan?.deposit_amount?.toLocaleString("en-US");
  const commission = instalmentPlan?.commission;
  const commissionAmt =
    instalmentPlan?.commission_amount?.toLocaleString("en-US");
  const duration = instalmentPlan?.duration;
  const noOfInstallments = instalmentPlan?.no_of_installments;
  const startDate = instalmentPlan?.start_date;
  const endDate = instalmentPlan?.end_date;
  const depositData = instalmentPlan?.deposit_data;
  const totalRent = instalmentPlan?.total_rent?.toLocaleString("en-US");

  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const getTotal = (amt, collectVat, collectTax) => {
    let amount = amt;
    if (collectVat && settings?.collect_vat_enabled) {
      amount += (amt * settings?.collect_vat_value) / 100;
    }
    if (collectTax && settings?.collect_tax_enabled) {
      amount += (amt * settings?.collect_tax_value) / 100;
    }
    // return `${amount?.toFixed(2)?.toLocaleString("en-US")}`;
    return `${amount?.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`;
    //
  };

  const handleView = (item) => {
    // if (item?.tenant_billing_invoice) {
    //   navigate(`invoicing/${item?.tenant_billing_invoice}`);
    // } else {
    setSelectedCheque(item);
    onViewChequeDetails();
    // }
  };

  const handleViewInvoice = (item) => {
    if (item?.tenant_billing_invoice) {
      navigate(`invoicing/${item?.tenant_billing_invoice}`);
    }
  };

  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      <Modal
        size={size}
        centered
        show={showModal}
        className={`installment-plan-detail-modal-wrapper `}
      >
        <Modal.Body
          className={`${
            userTheme === "dark" ? "bg_darkTheme" : "bg_lightTheme"
          }`}
        >
          <div className="modal-header-custom">
            <IconButton onClick={onClose} className="close-icon-btn">
              <img src={XGreen} className="close-icon-img" alt="" />
            </IconButton>
            <div className="icon-wrapper text-center"></div>
            <h2 className="text-center font_xl fw_6 font_blue">{title}</h2>
          </div>
          <div className="modal-body-custom">
            <div
              className={`${
                userTheme === "dark" ? "custom-table-wrapper" : "otherTheme"
              } `}
            >
              <div className="more-details">
                <div className="more-data">
                  {/* Total Rent */}
                  <div className="c-row">
                    <p className="font_xs fw_5 key font_grey">Total Rent</p>
                    <p
                      //className="font_xs fw_5 value"
                      className={`val font_xs fw_5 ${
                        userTheme === "light" || userTheme === "automatic"
                          ? "font_lightTheme"
                          : "font_white"
                      } `}
                    >
                      {totalRent?.toLocaleString("en-US")} ({data?.currency})
                    </p>
                  </div>
                  {/* Deposit Amount */}
                  {deposit && (
                    <div className="c-row">
                      <p className="font_xs fw_5 key font_grey">
                        Deposit ({data?.currency})
                        {settings?.collect_vat_enabled &&
                          instalmentPlan.collect_deposit_vat && (
                            <p
                              //className="font_xs fw_5"
                              className={`val font_xs fw_5 ${
                                userTheme === "light" ||
                                userTheme === "automatic"
                                  ? "font_lightTheme"
                                  : "font_white"
                              } `}
                            >
                              VAT({settings?.collect_vat_value}%)
                            </p>
                          )}
                        {settings?.collect_tax_enabled &&
                          instalmentPlan.collect_deposit_tax && (
                            <p
                              //className="font_xs fw_5"
                              className={`val font_xs fw_5 ${
                                userTheme === "light" ||
                                userTheme === "automatic"
                                  ? "font_lightTheme"
                                  : "font_white"
                              } `}
                            >
                              Tax({settings?.collect_tax_value}%)
                            </p>
                          )}
                        {((settings?.collect_vat_enabled &&
                          instalmentPlan.collect_deposit_vat) ||
                          (settings?.collect_tax_enabled &&
                            instalmentPlan.collect_deposit_tax)) && (
                          <p
                            //className="font_xs fw_5"
                            className={`val font_xs fw_5 ${
                              userTheme === "light" || userTheme === "automatic"
                                ? "font_lightTheme"
                                : "font_white"
                            } `}
                          >
                            Total
                          </p>
                        )}
                      </p>
                      <p className="font_xs fw_5 value">
                        <p
                          className={`val font_xs fw_5 ${
                            userTheme === "light" || userTheme === "automatic"
                              ? "font_lightTheme"
                              : "font_white"
                          } `}
                        >
                          {depositAmt}
                        </p>
                        {settings?.collect_vat_enabled &&
                          instalmentPlan.collect_deposit_vat && (
                            <p
                              className={`val font_xs fw_5 ${
                                userTheme === "light" ||
                                userTheme === "automatic"
                                  ? "font_lightTheme"
                                  : "font_white"
                              } `}
                            >
                              {instalmentPlan.collect_deposit_vat
                                ? (
                                    (instalmentPlan.deposit_amount *
                                      settings?.collect_vat_value) /
                                    100
                                  ).toFixed(2)
                                : 0}
                            </p>
                          )}
                        {settings?.collect_tax_enabled &&
                          instalmentPlan.collect_deposit_tax && (
                            <p
                              className={`val font_xs fw_5 ${
                                userTheme === "light" ||
                                userTheme === "automatic"
                                  ? "font_lightTheme"
                                  : "font_white"
                              } `}
                            >
                              {instalmentPlan.collect_deposit_tax
                                ? (
                                    (instalmentPlan.deposit_amount *
                                      settings?.collect_tax_value) /
                                    100
                                  ).toFixed(2)
                                : 0}
                            </p>
                          )}
                        {((settings?.collect_vat_enabled &&
                          instalmentPlan.collect_deposit_vat) ||
                          (settings?.collect_tax_enabled &&
                            instalmentPlan.collect_deposit_tax)) && (
                          <p
                            className={`val font_xs fw_5 ${
                              userTheme === "light" || userTheme === "automatic"
                                ? "font_lightTheme"
                                : "font_white"
                            } `}
                          >
                            {getTotal(
                              instalmentPlan.deposit_amount,
                              instalmentPlan.collect_deposit_vat,
                              instalmentPlan.collect_deposit_tax
                            )}
                          </p>
                        )}
                      </p>
                    </div>
                  )}
                </div>

                <div className="more-data">
                  {/* Commission Amount */}
                  {commission && (
                    <div className="c-row">
                      <p className="font_xs fw_5 key font_grey">
                        Commission ({data?.currency})
                        {settings?.collect_vat_enabled &&
                          instalmentPlan.collect_commission_vat && (
                            <p
                              className={`val font_xs fw_5 ${
                                userTheme === "light" ||
                                userTheme === "automatic"
                                  ? "font_lightTheme"
                                  : "font_white"
                              } `}
                            >
                              VAT({settings?.collect_vat_value}%)
                            </p>
                          )}
                        {settings?.collect_tax_enabled &&
                          instalmentPlan.collect_commission_tax && (
                            <p
                              className={`val font_xs fw_5 ${
                                userTheme === "light" ||
                                userTheme === "automatic"
                                  ? "font_lightTheme"
                                  : "font_white"
                              } `}
                            >
                              Tax({settings?.collect_tax_value}%)
                            </p>
                          )}
                        {((settings?.collect_vat_enabled &&
                          instalmentPlan.collect_commission_vat) ||
                          (settings?.collect_tax_enabled &&
                            instalmentPlan.collect_commission_tax)) && (
                          <p
                            className={`val font_xs fw_5 ${
                              userTheme === "light" || userTheme === "automatic"
                                ? "font_lightTheme"
                                : "font_white"
                            } `}
                          >
                            Total
                          </p>
                        )}
                      </p>
                      <p className="font_xs fw_5 value">
                        <p
                          className={`val font_xs fw_5 ${
                            userTheme === "light" || userTheme === "automatic"
                              ? "font_lightTheme"
                              : "font_white"
                          } `}
                        >
                          {commissionAmt}
                        </p>{" "}
                        {settings?.collect_vat_enabled && (
                          <p
                            className={`val font_xs fw_5 ${
                              userTheme === "light" || userTheme === "automatic"
                                ? "font_lightTheme"
                                : "font_white"
                            } `}
                          >
                            {instalmentPlan.collect_commission_vat
                              ? (
                                  (instalmentPlan.commission_amount *
                                    settings?.collect_vat_value) /
                                  100
                                ).toFixed(2)
                              : 0}
                          </p>
                        )}
                        {settings?.collect_tax_enabled && (
                          <p
                            className={`val font_xs fw_5 ${
                              userTheme === "light" || userTheme === "automatic"
                                ? "font_lightTheme"
                                : "font_white"
                            } `}
                          >
                            {instalmentPlan.collect_commission_tax
                              ? (
                                  (instalmentPlan.commission_amount *
                                    settings?.collect_tax_value) /
                                  100
                                ).toFixed(2)
                              : 0}
                          </p>
                        )}
                        {(settings?.collect_vat_enabled ||
                          settings?.collect_tax_enabled) && (
                          <p
                            className={`val font_xs fw_5 ${
                              userTheme === "light" || userTheme === "automatic"
                                ? "font_lightTheme"
                                : "font_white"
                            } `}
                          >
                            {getTotal(
                              instalmentPlan.commission_amount,
                              instalmentPlan.collect_commission_vat,
                              instalmentPlan.collect_commission_tax
                            )}
                          </p>
                        )}
                      </p>
                    </div>
                  )}

                  {/* Duration */}
                  <div className="c-row">
                    <p className="font_xs fw_5 key font_grey">Duration</p>
                    <p
                      //className="font_xs fw_5 value"
                      className={`val font_xs fw_5 ${
                        userTheme === "light" || userTheme === "automatic"
                          ? "font_lightTheme"
                          : "font_white"
                      } `}
                    >
                      {duration}
                    </p>
                  </div>
                </div>

                <div className="more-data">
                  {/* No. Of Installment */}
                  <div className="c-row">
                    <p className="font_xs fw_5 key font_grey">
                      No. Of Installment
                    </p>
                    <p
                      // className="font_xs fw_5 value"
                      className={`val font_xs fw_5 ${
                        userTheme === "light" || userTheme === "automatic"
                          ? "font_lightTheme"
                          : "font_white"
                      } `}
                    >
                      {noOfInstallments}
                    </p>
                  </div>
                  {/* Start Date */}
                  <div className="c-row">
                    <p className="font_xs fw_5 key font_grey">Start Date</p>
                    <p
                      //className="font_xs fw_5 value"
                      className={`val font_xs fw_5 ${
                        userTheme === "light" || userTheme === "automatic"
                          ? "font_lightTheme"
                          : "font_white"
                      } `}
                    >
                      {moment(startDate).format(DATE_FORMAT)}
                    </p>
                  </div>
                </div>

                <div className="more-data">
                  {/* End Date */}
                  <div className="c-row">
                    <p className="font_xs fw_5 key font_grey">End Date</p>
                    <p
                      // className="font_xs fw_5 value"
                      className={`val font_xs fw_5 ${
                        userTheme === "light" || userTheme === "automatic"
                          ? "font_lightTheme"
                          : "font_white"
                      } `}
                    >
                      {moment(endDate).format(DATE_FORMAT)}
                    </p>
                  </div>
                </div>

                {instalmentPlan?.cheque && (
                  <p className="recieve-by-cheque-txt font_xs fw_6 font_yellow">
                    Recieve payments by cheque
                  </p>
                )}
              </div>

              {depositData && depositData?.length > 0 && (
                <>
                  <p className="py-2 font_yellow">Deposit BreakDowns</p>
                  <Table className="custom-table font_s" borderless responsive>
                    {/* Table Heading */}
                    <thead>
                      <tr>
                        <th>Description</th>
                        <th>Deposit Amount ({data?.currency})</th>
                      </tr>
                    </thead>
                    {/* Table Body */}
                    <tbody>
                      {depositData?.map((item, index) => (
                        <tr key={index} className="install">
                          {/* Name */}
                          <td>{item?.description}</td>
                          {/* Amount */}
                          <td className="">
                            {parseInt(item?.deposit_amount)?.toLocaleString(
                              "en-US"
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </>
              )}

              <Table
                className={`font_s ${
                  userTheme === "dark" ? "custom-table" : "otherThemeTable"
                }`}
                borderless
                responsive
              >
                {/* Table Heading */}
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Amount ({data?.currency})</th>
                    <th>Due Date</th>
                    <th></th>
                  </tr>
                </thead>
                {/* Table Body */}
                <tbody>
                  {instalments?.map((item, index) => (
                    <tr key={index} className="install">
                      {/* Name */}
                      <td>
                        {item?.name}

                        {settings?.collect_vat_enabled &&
                          instalmentPlan.collect_instalment_vat && (
                            <p>VAT({settings?.collect_vat_value}%)</p>
                          )}

                        {settings?.collect_tax_enabled &&
                          instalmentPlan.collect_instalment_tax && (
                            <p>Tax({settings?.collect_tax_value}%)</p>
                          )}

                        {(settings?.collect_vat_enabled &&
                          instalmentPlan.collect_instalment_vat) ||
                        (settings?.collect_tax_enabled &&
                          instalmentPlan.collect_instalment_tax) ? (
                          <p>Total</p>
                        ) : (
                          ""
                        )}
                      </td>
                      {/* Amount */}
                      <td className="">
                        <p>
                          {item?.amount?.toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </p>
                        {settings?.collect_vat_enabled &&
                          instalmentPlan.collect_instalment_vat && (
                            <p>
                              {item?.vat_amount?.toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </p>
                          )}
                        {settings?.collect_tax_enabled &&
                          instalmentPlan.collect_instalment_tax && (
                            <p>
                              {item?.tax_amount?.toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </p>
                          )}
                        {((settings?.collect_vat_enabled &&
                          instalmentPlan.collect_instalment_vat) ||
                          (settings?.collect_tax_enabled &&
                            instalmentPlan.collect_instalment_tax)) && (
                          <p>
                            {item?.total_amount?.toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </p>
                        )}
                      </td>
                      {/* Due Date */}
                      <td>{moment(item.due_date).format(DATE_FORMAT)}</td>
                      <td>
                        <IconButton
                          className="view-inv-btn"
                          onClick={() => handleView(item)}
                        >
                          <img
                            src={
                              instalmentPlan?.cheque
                                ? ChequeOutlineBlue
                                : EyeFilledBlue
                            }
                            alt="View"
                            className="w_100"
                          />
                        </IconButton>
                        {item?.tenant_billing_invoice && (
                          <IconButton
                            className="view-inv-btn1 mx-2"
                            onClick={() => handleViewInvoice(item)}
                          >
                            <img
                              src={DocumentGreen}
                              alt="View"
                              className="w_100"
                            />
                          </IconButton>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
          <div className="modal-footer-custom"></div>
        </Modal.Body>
      </Modal>
    </>
  );
};
/* Component ends */

export default InstallmentPlanDetailModal;
