/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import DetailCard from "./sub-component/DetailCard";
import TimeLineLists from "./sub-component/TimeLineLists";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const TenantMaintenanceDetail = (props) => {
  /* Props destructuring starts */
  const {
    isT1,
    isT2,
    isT3,
    mgmtModel,
    type,
    status,
    maintenance,
    maintenance_id,
    user,
    currency,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="tenant-maintenance-detail-wrapper container_sm">
      {/* Details */}
      <DetailCard
        type={type}
        isT1={isT1}
        isT2={isT2}
        isT3={isT3}
        status={status}
        mgmtModel={mgmtModel}
        maintenance={maintenance}
      />

      {/* Timeline */}
      <TimeLineLists
        isT1={isT1}
        isT2={isT2}
        isT3={isT3}
        status={status}
        mgmtModel={mgmtModel}
        maintenance={maintenance}
        maintenance_id={maintenance_id}
        logs={maintenance?.logs}
        user={user}
        currency={currency}
      />
    </div>
  );
};
/* Component ends */

export default TenantMaintenanceDetail;
