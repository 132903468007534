/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useSelector } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import { getUser, getIsTENANT } from "../../../../setup/store/slices/authSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import Item from "./sub-component/Item";
import Pagination from "../../../ui/other/Pagination/Pagination";
import BreadCrumb from "../../../ui/other/BreadCrumb/BreadCrumb";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const NotificationList = (props) => {
  /* Props destructuring starts */
  const { notifications, fetchNotifs } = props;

  console.log(notifications, "NOT");
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const user = useSelector(getUser);
  const isTenant = useSelector(getIsTENANT);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItemsList = notifications?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      {!isTenant && <BreadCrumb type="notification_page" />}
      <div className="notification-list-wrapper container_sm">
        {currentItemsList?.map((item, index) => (
          <>
            {/* User */}
            {user?._id === item?.user && (
              <Item fetchNotifs={fetchNotifs} key={index} item={item} />
            )}
            {/* Tenant */}
            {user?._id === item?.tenant && (
              <Item fetchNotifs={fetchNotifs} key={index} item={item} />
            )}
          </>
        ))}

        {currentItemsList?.length === 0 && (
          <div className=" text-center">
            <h3 className=" font_m font_yellow fw_6">No Data</h3>
          </div>
        )}

        {notifications?.length > 0 && (
          <Pagination
            itemsPerPage={itemsPerPage}
            totalItems={notifications?.length}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        )}
      </div>
    </>
  );
};
/* Component ends */

export default NotificationList;
