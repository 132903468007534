import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_URL } from "../../config/devKeys";
import {
  setLoading,
  setShowUploadProgress,
  setUploadProgress,
} from "./unpersistedSlice";
import axios, { axiosInstance } from "../axios";
import { PLATFORM } from "../../platformUtil";

const initialState = {
  contractsList: [],
  rentsList: [],
  depositsList: [],
  invoicesList: {},
  otherDetails: {},
  tenantHistoryList: [],
  facilitiesList: [],
  violationsList: [],
  maintenanceList: [],
  securityList: [],
  notificationsList: [],
  requestsList: [],
  unitDetails: [],
  tenantUnitList: [],
};

export const fetchTenantUnits = createAsyncThunk(
  "tenant_info/unit/list",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { property_id } = payload;
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/tenant_info/unit/get_list",
        { property_id },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response.data.error.message);
    }
  }
);

export const fetchContractList = createAsyncThunk(
  "tenant_info/contracts",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(clearTenantInfo());
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/tenant_info/unit/all_contract",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response.data.error.message);
    }
  }
);

// TO Upload Notarized Contract
export const uploadNotarizedContract = createAsyncThunk(
  "tenant_info/upload_notarized",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    const { unit_id, tenant_id } = payload;
    try {
      dispatch(setLoading(true));
      const formData = new FormData();
      Object.keys(payload).map((key) => {
        if (key == "contract") {
          if (PLATFORM !== "web") {
            formData.append(key, {
              name: payload[key].name,
              type: payload[key].type,
              uri:
                PLATFORM === "android"
                  ? payload[key].uri
                  : payload[key].uri.replace("file://", ""),
            });
          } else {
            formData.append(key, payload[key], payload[key].name);
          }
        } else {
          formData.append(key, payload[key]);
        }
      });
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
      };
      if (PLATFORM !== "web") {
        headers["Content-Type"] = "multipart/form-data";
      }

      // const config = getConfig(
      //   'POST',
      //   '/tenant/upload_notarized_contract',
      //   headers,
      //   formData,
      // );

      // const {data} = await axiosInstance.request(config);
      dispatch(setShowUploadProgress(true));
      const config = {
        onUploadProgress: ({ loaded, total }) => {
          const progress = (loaded / total) * 100;
          dispatch(setUploadProgress(progress));
        },
        method: "POST",
        url: "/tenant/upload_notarized_contract",
        headers,
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
        responseType: "json",
      };

      // const data = await response.json();

      const { data } = await axiosInstance.request(config);

      dispatch(setLoading(false));
      if (data.success) {
        await dispatch(fetchContractList({ unit_id, tenant_id }));
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error.message);
    }
  }
);

export const fetchRentList = createAsyncThunk(
  "tenant_info/rents",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(clearTenantInfo());
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/tenant_info/unit/tenant_rental_details",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response.data.error.message);
    }
  }
);

export const fetchDepositList = createAsyncThunk(
  "tenant_info/deposits",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(clearTenantInfo());
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/tenant_info/unit/tenant_deposit_details",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response.data.error.message);
    }
  }
);

export const fetchInvoiceList = createAsyncThunk(
  "tenant_info/invoices",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(clearTenantInfo());
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/tenant_info/unit/tenant_invoice_details",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response.data.error.message);
    }
  }
);

export const fetchOtherDetails = createAsyncThunk(
  "tenant_info/other_details",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(clearTenantInfo());
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/tenant_info/unit/tenant_other_details",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response.data.error.message);
    }
  }
);

export const fetchViolationList = createAsyncThunk(
  "tenant_info/violation_list",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(clearTenantInfo());
      dispatch(setLoading(true));
      const { data } = await axios.post(
        API_URL + "/tenant_info/unit/tenant_violation_details",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response.data.error.message);
    }
  }
);

export const tenantInfoSlice = createSlice({
  name: "tenantInfo",
  initialState,
  reducers: {
    clearTenantInfo: (state, action) => {
      let newState = { ...initialState, unitDetails: state.unitDetails };
      state = newState;
    },
  },
  extraReducers: {
    [fetchContractList.fulfilled]: (state, action) => {
      state.contractsList = action.payload;
    },
    [fetchContractList.rejected]: (state, action) => {
      state.contractsList = [];
    },
    [fetchRentList.fulfilled]: (state, action) => {
      state.rentsList = action.payload;
    },
    [fetchRentList.rejected]: (state, action) => {
      state.rentsList = [];
    },
    [fetchDepositList.fulfilled]: (state, action) => {
      state.depositsList = action.payload;
    },
    [fetchDepositList.rejected]: (state, action) => {
      state.depositsList = [];
    },
    [fetchInvoiceList.fulfilled]: (state, action) => {
      state.invoicesList = action.payload;
    },
    [fetchInvoiceList.rejected]: (state, action) => {
      state.invoicesList = {};
    },
    [fetchOtherDetails.fulfilled]: (state, action) => {
      state.otherDetails = action.payload;
    },
    [fetchOtherDetails.rejected]: (state, action) => {
      state.otherDetails = {};
    },
    [fetchViolationList.fulfilled]: (state, action) => {
      state.violationsList = action.payload;
    },
    [fetchViolationList.rejected]: (state, action) => {
      state.violationsList = [];
    },
    [fetchTenantUnits.fulfilled]: (state, action) => {
      state.tenantUnitList = action.payload;
    },
    [fetchTenantUnits.rejected]: (state, action) => {
      state.tenantUnitList = [];
    },
  },
});

export const { clearTenantInfo } = tenantInfoSlice.actions;

export const getContractsList = (state) => state.tenantInfo.contractsList;
export const getRentsList = (state) => state.tenantInfo.rentsList;
export const getDepositsList = (state) => state.tenantInfo.depositsList;
export const getInvoicesList = (state) => state.tenantInfo.invoicesList;
export const getOtherDetails = (state) => state.tenantInfo.otherDetails;
export const getViolationList = (state) => state.tenantInfo.violationsList;
export const getSecurityList = (state) => state.tenantInfo.securityList;
export const getUnitDetails = (state) => state.tenantInfo.unitDetails;
export const getTenantUnits = (state) => state.tenantInfo.tenantUnitList;

export default tenantInfoSlice.reducer;
