/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useLocation, useParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  fetchPropertyDetailByID,
  getPropertyDetail,
} from "../../setup/store/slices/propertySlice";
import { getUser } from "../../setup/store/slices/authSlice";
import {
  fetchComplaintBasedOnId,
  getSecurityComplaintData,
} from "../../setup/store/slices/securitySlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import SecurityComplaintReport from "../../components/module/SecurityManage/ClientSecurityManage/SecurityComplaintReport/SecurityComplaintReport";

/* Import local pages and component ends */

import "./style.scss";
import BreadCrumb from "../../components/ui/other/BreadCrumb/BreadCrumb";
import SecurityComplaintDetail from "../../components/module/SecurityManage/ClientSecurityManage/TenantSecurityComplaintDetail/SecurityComplaintDetail";

/* Component starts */
const SecurityComplaintPage = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  // const { state } = useLocation();
  const { id, propertyId } = useParams();
  const dispatch = useDispatch();
  const complaint = useSelector(getSecurityComplaintData);
  const property = useSelector(getPropertyDetail);
  const user = useSelector(getUser);
  console.log(user, "USER-MAIN");
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const type = complaint?.type;
  const mgmtModel = complaint?.level;
  const isT1 = mgmtModel === "t1";
  const isT2 = mgmtModel === "t2";
  const isT3 = mgmtModel === "t3";
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    if (id) {
      dispatch(fetchComplaintBasedOnId({ request_id: id }));
    }
  }, [id, dispatch]);

  useEffect(() => {
    dispatch(fetchPropertyDetailByID({ property_id: propertyId }));
  }, [propertyId]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      <BreadCrumb type="security_complain_detail" />
      <div className="security-complaint-page-wrapper">
        {/* <SecurityComplaintReport
          isT1={isT1}
          isT2={isT2}
          isT3={isT3}
          mgmtModel={mgmtModel}
          type={type}
          complaint={complaint}
          complaint_id={id}
          property={property}
          user={user}
        /> */}

        <SecurityComplaintDetail
          isT1={isT1}
          isT2={isT2}
          isT3={isT3}
          mgmtModel={mgmtModel}
          type={type}
          complaint={complaint}
          complaint_id={id}
          property={property}
          user={user}
        />
      </div>
    </>
  );
};
/* Component ends */

export default SecurityComplaintPage;
