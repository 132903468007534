/**
 *
 */

import React, { useState, useEffect, useRef } from "react";

/* Import configuration starts */
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  logOutUser,
  updateUserMobile,
} from "../../../setup/store/slices/authSlice";
import { logout } from "../../../setup/store/slices/masterSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import GLOBAL from "../../../setup/constants/global";
import CustomButton from "../../../components/ui/button/CustomButton/CustomButton";
import TextButton from "../../../components/ui/button/TextButton/TextButton";
import CustomPhoneInput from "../../../components/ui/input/CustomPhoneInput/CustomPhoneInput";
import ErrorModal from "../../../components/ui/modal/ErrorModal/ErrorModal";
import SuccessModal from "../../../components/ui/modal/SuccessModal/SuccessModal";
/* Import local pages and component ends */

/* Component starts */
const ChangeMobile = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [modalDesc, setModalDesc] = useState("");
  /* Component states and useRef declaration ends */

  // useRef
  const customPhoneNum = useRef({ code: "", num: "" });

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  const dispatch = useDispatch();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const initialValues = {
    mobile: "",
  };
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // Form validations
  const formValidation = (values) => {
    const errorFields = {};
    if (values.mobile === "") {
      errorFields.mobile = "Phone Number is required";
    }
    if (values.mobile && customPhoneNum.current.num.length < 8) {
      errorFields.mobile = "Phone number is not valid";
    }
    return errorFields;
  };

  // Handling on signing in
  const updateMobile = async (values) => {
    // setErrorWhileSubmiting(null);

    // Creating or gathering payload data to be sent
    const payload = {
      country_code: customPhoneNum.current.code,
      new_mobile_number: customPhoneNum.current.num,
    };

    // // Backend Response. Try, Catch
    try {
      const result = await dispatch(updateUserMobile(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setModalDesc(result.payload);
          setShowErrorModal(true);
          break;
        case GLOBAL.FULFILLED:
          setModalDesc(result.payload.message);
          setShowSuccessModal(true);
          localStorage.clear();
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="form-wrapper">
      {/* Sign in form */}
      <Formik
        onSubmit={updateMobile}
        initialValues={initialValues}
        validate={formValidation}
        validateOnBlur={true}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            {/* Input for Email */}
            {/* Input for Phone */}
            <div className="form-group">
              <CustomPhoneInput
                name="mobile"
                label={" Number"}
                customPhoneNum={customPhoneNum}
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.mobile}
                countryCodeEditable={true}
                errors={errors.mobile}
                touched={touched.mobile}
              />
            </div>

            {/* Input for Password */}
            <div className="form-group">
              <p className="font_s font_grey">
                Once you send update mobile number request, you will be logged
                out of DGRNTE. You need to verify mobile number in order to
                login again.
              </p>
            </div>

            <div className="button-wrapper">
              <CustomButton type="submit" size="l">
                Update Mobile
              </CustomButton>
              <TextButton
                type="button"
                onClick={() => navigate(-1)}
                className="font_m fw_6 text-btn"
              >
                Cancel
              </TextButton>
            </div>
          </form>
        )}
      </Formik>

      {/* MODALS */}
      {/* Success Modal */}
      <SuccessModal
        showModal={showSuccessModal}
        description={modalDesc}
        title="Successfully Updated Mobile Number"
        onClose={async () => {
          setShowSuccessModal(false);
          localStorage.clear();
          await dispatch(logOutUser());
          navigate("/signin");
        }}
      />

      {/* Error Modal */}
      <ErrorModal
        showModal={showErrorModal}
        description={modalDesc}
        title="Error"
        onClose={() => setShowErrorModal(false)}
      />
    </div>
  );
};
/* Component ends */

export default ChangeMobile;
