/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../../../../../setup/store/slices/globalAlertSlice";
import {
  fetchUser,
  getIsACC,
  getIsOwner,
  getUser,
} from "../../../../../../../setup/store/slices/authSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Table } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import {
  EditGreen,
  ExcalmatoryYellow,
  EyeFilledBlue,
} from "../../../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import GLOBAL from "../../../../../../../setup/constants/global";
import IconButton from "../../../../../../ui/button/IconButton/IconButton";
import PaymentModal from "../../../PaymentModal/PaymentModal";
import ChecqueStatusChangeModal from "../../../../ChecqueStatusChangeModal/ChecqueStatusChangeModal";
import Pagination from "../../../../../../ui/other/Pagination/Pagination";
import {
  fetchUserRoles,
  getUserRoles,
} from "../../../../../../../setup/store/slices/masterSlice";
/* Import local pages and component ends */

/* Component starts */
const RentInvoicing = (props) => {
  /* Props destructuring starts */
  const {
    tenantInvoices,
    property,
    currentPage,
    setCurrentPage,
    totalCount,
    invoiceAggregation,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [invoiceData, setInvoiceData] = useState();
  const [showModal, setShowModal] = useState(false);
  const [updateCheque, setUpdateCheque] = useState(false);
  const [role, setRole] = useState();
  // const [currentPage, setCurrentPage] = useState(1);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAccountant = useSelector(getIsACC);
  const isOwner = useSelector(getIsOwner);
  const userRoles = useSelector(getUserRoles);
  const user = useSelector(getUser);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const onClose = () => {
    setShowModal(!showModal);
  };

  const onSuccess = () => {
    setShowModal(!showModal);
    navigate(-1);
  };

  const handleReportView = (item) => {
    // const newPathname = pathNamePopped(pathname, 2);
    // navigate(`${newPathname}/detail?type=${currentKey}/${item?._id}`);
    navigate(`${item?._id}`);
  };

  const handlePaymentModal = (item) => {
    setInvoiceData(item);
    setShowModal(!showModal);
  };

  const onChequeStatusChange = async (status) => {
    // Creating or collecting payload data to be sent
    const payload = {};

    // Backend response. Try, Catch
    try {
      const result = {};

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    dispatch(fetchUserRoles());
    dispatch(fetchUser());
  }, []);

  // Setting role after all user roles loaded
  useEffect(() => {
    let userRole = userRoles?.filter((d) => d?._id === user?.role_id)[0]?.name;
    setRole(userRole);
  }, [userRoles]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      <div className="custom-table-wrapper tenant-invoice-table">
        {tenantInvoices?.length > 0 && (
          <Table className="custom-table font_s" borderless responsive>
            {/* Table Heading */}
            <thead>
              <tr>
                <th>Invoice</th>
                <th>Community</th>
                <th>Property</th>
                <th>Tenant</th>
                <th>Unit</th>
                <th>Total</th>
                <th>Due On</th>
                {/* <th>Remark</th> */}
                <th>Method</th>
                <th>Status</th>
                <th className="text-start">Action</th>
              </tr>
            </thead>
            {/* Table Body */}
            <tbody>
              {tenantInvoices?.map((item) => (
                <tr key={item?._id}>
                  {/* Invoice */}
                  <td>#{item?.invoice_num}</td>

                  {/* Community */}
                  <td>
                    {item?.community_name !== undefined
                      ? item?.community_name
                      : "-"}
                  </td>

                  {/* Property */}
                  <td>
                    {item?.property_name !== undefined
                      ? item?.property_name
                      : "-"}
                  </td>

                  {/* Tenant Name */}
                  <td>{item?.tenant !== null ? item?.tenant : "-"}</td>

                  {/* Unit NUmber */}
                  <td>{item?.unit}</td>

                  {/* Total */}
                  <td>{`${item?.total
                    // ?.toFixed(2)
                    ?.toLocaleString("en-US")}${" "}${property?.currency}`}</td>

                  {/*  Due On */}
                  <td>{moment(item?.due_date).format(DATE_FORMAT)}</td>

                  {/* Remark */}
                  {/* <td>{item?.remarks !== undefined ? item?.remarks : "-"}</td> */}

                  {/* Method */}
                  <td>
                    {item?.method !== undefined
                      ? item?.method?.charAt(0)?.toUpperCase() +
                        item?.method?.slice(1)
                      : "-"}
                  </td>

                  {/* Status */}
                  <td
                    className={`${
                      item?.status === "paid"
                        ? "font_green"
                        : item?.status === "unpaid"
                        ? "font_yellow"
                        : "font_red"
                    }`}
                  >
                    {item?.method === "Cheque" && item?.status === "unpaid" ? (
                      <>Hold</>
                    ) : (
                      <>
                        {item?.status?.charAt(0).toUpperCase() +
                          item?.status?.slice(1)}
                      </>
                    )}
                  </td>

                  {/* Actions */}
                  <td className="text-start txt-nowrap">
                    <IconButton
                      onClick={() => handleReportView(item)}
                      className="action-button"
                    >
                      <img
                        src={EyeFilledBlue}
                        className="h_100"
                        alt="View Details"
                      />
                    </IconButton>

                    {((isOwner && property?.mgmt_model === 0) ||
                      (isAccountant && property?.mgmt_model === 2)) &&
                      (item?.status === "unpaid" ||
                        item?.status === "partial paid") &&
                      !item?.is_payable_by_cheque && (
                        <IconButton
                          onClick={() => handlePaymentModal(item)}
                          className="action-button "
                        >
                          <img
                            // src={EditWhite}
                            src={EditGreen}
                            className="h_100 mx-2"
                            alt="View Details"
                          />
                        </IconButton>
                      )}

                    {((isOwner && property?.mgmt_model === 0) ||
                      (isAccountant && property?.mgmt_model === 2)) &&
                      (item?.status === "unpaid" ||
                        item?.status === "partial paid") &&
                      item?.is_payable_by_cheque &&
                      item?.transaction?.length === 0 && (
                        <IconButton
                          onClick={() => handlePaymentModal(item)}
                          className="action-button "
                        >
                          <img
                            // src={EditWhite}
                            src={EditGreen}
                            className="h_100 mx-2"
                            alt="View Details"
                          />
                        </IconButton>
                      )}

                    {((isOwner && property?.mgmt_model === 0) ||
                      (isAccountant && property?.mgmt_model === 2)) &&
                      item?.is_payable_by_cheque &&
                      item?.transaction?.length > 0 &&
                      (item?.status !== "paid" ||
                        item?.status === "bounced") && (
                        <IconButton className="action-button mx-2">
                          <img src={ExcalmatoryYellow} className="h_100" />
                        </IconButton>
                      )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}

        {tenantInvoices?.length === 0 && (
          <div className=" text-center">
            <h3 className=" font_m font_yellow fw_6">No Data</h3>
          </div>
        )}

        {/* MODALS */}
        <PaymentModal
          showModal={showModal}
          onClose={onClose}
          onSuccess={onSuccess}
          invoiceData={invoiceData}
          property_id={property?._id}
        />

        {/* MODALS */}
        <ChecqueStatusChangeModal
          showModal={updateCheque}
          onClose={() => setUpdateCheque(false)}
          onSubmit={onChequeStatusChange}
        />
      </div>

      {/* PAGINATION */}
      {tenantInvoices?.length > 0 && (
        <Pagination
          itemsPerPage="10"
          totalItems={totalCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          type="invoice"
          currency={property?.currency}
          paid={invoiceAggregation?.paid_rent}
          unPaid={invoiceAggregation?.unpaid_rent}
          overdue={invoiceAggregation?.overDue_rent}
          totalAmount={invoiceAggregation?.total_amount}
          voidAmount={invoiceAggregation?.void_rent}
        />
      )}
    </>
  );
};
/* Component ends */

export default RentInvoicing;
