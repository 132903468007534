/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useDispatch } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import { fetchCommunityProperties } from "../../../../setup/store/slices/propertySlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import MUI component starts */
/* Import MUI component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomSlider from "../../../ui/other/CustomSlider/CustomSlider";
import CommunityLicenseSliderItem from "../CommunityLicenseSliderItem/CommunityLicenseSliderItem";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const CommunityLicenseSlider = (props) => {
  /* Props destructuring starts */
  const {
    communities,
    setShowCommunityProperties,
    setSelectedCommunity,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // On selecting community
  const onSelectCommunity = async (community) => {
    await dispatch(fetchCommunityProperties(community._id));
    setSelectedCommunity(community)
    setShowCommunityProperties(true);
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="community-license-slider-component-wrapper">
      <h2 className="heading font_xl fw_6">
        Communities ({communities?.length})
      </h2>
     
      <CustomSlider type="license">
        {communities?.map((item, index) => (
          <CommunityLicenseSliderItem
            onSelectCommunity={onSelectCommunity}
            key={index}
            community={item}
          />
        ))}
      </CustomSlider>
    </div>
  );
};
/* Component ends */

export default CommunityLicenseSlider;
