/**
 *
 */

import React, { useState, useEffect, useRef } from "react";

/* Import configuration starts */
import { useParams } from "react-router-dom";
import GLOBAL from "../../../../setup/constants/global";
import { useDispatch } from "react-redux";
import { DocusealBuilder } from "@docuseal/react";
import escape from "escape-html";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../../setup/store/slices/globalAlertSlice";
import { fetchTemplateToken } from "../../../../setup/store/slices/templateSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import BreadCrumb from "../../../ui/other/BreadCrumb/BreadCrumb";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const SignatureTemplateEdit = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [data, setData] = useState(null);
  const [requiredFields, setRequiredFields] = useState([]);
  const [nonRequiredFields, setNonRequiredFields] = useState([]);
  const [currentInstantiatedField, setCurrentInstantiatedField] =
    useState(null);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const { propertyId, type } = useParams();
  const dispatch = useDispatch();
  const docusealComponentRef = useRef();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const populateFieldData = (data) => {
    const required = [];
    const nonRequired = [];

    for (let i = 0; i < data.length; i++) {
      if (data[i]?.tag === "required") {
        required.push(data[i]);
      } else {
        nonRequired.push(data[i]);
      }
    }

    setNonRequiredFields(nonRequired);
    setRequiredFields(required);
  };

  const getTemplateToken = async () => {
    // Creating or collecting payload data to be sent
    const payload = { property_id: propertyId, type };
    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      const result = await dispatch(fetchTemplateToken(payload));
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setData();
          break;
        case GLOBAL.FULFILLED:
          setData(result.payload);
          populateFieldData(result?.payload?.variables);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  const onSave = () => {
    const popUpContent = { title: "Template Saved", description: "" };

    switch (type) {
      case "initial_agreement":
        popUpContent.description =
          "Initial Agreement template has been saved successfully";
        break;

      case "final_agreement":
        popUpContent.description =
          "Final Agreement template has been saved successfully";
        break;

      case "clearance_cert":
        popUpContent.description =
          "Clearence Certificate template has been saved successfully";
        break;
    }

    dispatch(showSuccessAlert(popUpContent));
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    getTemplateToken();
  }, []);

  // useEffect(() => {
  //   if (data) {
  //     if (
  //       window?.docusealBuilder?.component?.$refs?.builder?.template?.fields
  //     ) {
  //       setCurrentInstantiatedField(
  //         JSON.parse(
  //           JSON.stringify(
  //             window?.docusealBuilder?.component?.$refs?.builder?.template
  //               ?.fields
  //           )
  //         )
  //       );
  //     }
  //   }
  // }, [data]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      <BreadCrumb type="property_setting_template" />
      <div className="signature-template-edit-wrapper">
        {data &&
          requiredFields?.length > 0 &&
          nonRequiredFields?.length > 0 && (
            <DocusealBuilder
              // id="docusealBuilder"
              // ref={docusealComponentRef}
              token={data?.token}
              fields={nonRequiredFields}
              withUploadButton={false}
              roles={data?.roles}
              allowToResubmit={false}
              // onlyDefinedFields={false}
              withSignYourselfButton={false}
              withSendButton={false}
              withTitle={false}
              autosave={false}
              // withFieldsList={false}
              requiredFields={requiredFields}
              onlyDefinedFields={true}
              onSave={onSave}
            />
          )}

        {/* {data && (
          <div
            dangerouslySetInnerHTML={{
              __html: `
                <docuseal-builder
                  id="docusealBuilder"
                  data-token="${data?.token}"
                  data-roles="${data?.roles.join(",")}"
                  data-fields="${escape(JSON.stringify(data?.variables))}"
                  data-autosave="false"
                  data-with-title="false"
                  data-with-send-button="false"
                  data-allow-to-resubmit="false"
                  data-with-upload-button="false"
                  data-with-sign-yourself-button="false">
                </docuseal-builder>
            `,
            }}
          />
        )} */}
      </div>
    </>
  );
};
/* Component ends */

export default SignatureTemplateEdit;
