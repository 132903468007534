import GLOBAL from "../../../../setup/constants/global";
import moment from "moment";

export const isApproved = (status) => {
  return status && status === "approved" ? true : false;
};

// Rental History form validation
export const rentalHistValidation = (values, formCountList) => {
  const error = {};

  formCountList.forEach((item, index) => {
    const property_name = `property_name_${item}`;
    const unit = `unit_${item}`;
    const type = `type_${item}`;
    const tenancy_type = `tenancy_type_${item}`;
    const address = `address_${item}`;
    const contact_number = `contact_number_${item}`;

    if (values[property_name] === "") {
      error[property_name] = "Property Name is required";
    }

    if (values[unit] === "") {
      error[unit] = "Unit is required";
    }

    if (values[type] === "") {
      error[type] = "Type is required";
    }

    if (values[tenancy_type] === "") {
      error[tenancy_type] = "Tenancy Type is required";
    }

    if (values[address] === "") {
      error[address] = "Address is required";
    }

    if (values[contact_number] === "") {
      error[contact_number] = "Contact Number is required";
    }
  });

  return error;
};

// Rental History form prefilling
export const rentalHistFormPrefill = (
  data,
  setInitialFormValues,
  setFormCountList
  // ownerPhoneNumList
) => {
  const formValues = {};
  const currentFormCount = [];

  data.forEach((item, index) => {
    formValues[`address_${index}`] = item?.address || "";
    formValues[`property_name_${index}`] = item?.property_name || "";
    formValues[`unit_${index}`] = item?.unit || "";
    formValues[`type_${index}`] = item?.type || "";
    formValues[`countrycode_${index}`] = item?.countrycode || "";
    formValues[`contact_number_${index}`] = item?.contact_number || "";
    formValues[`email_${index}`] = item?.owner_email || "";
    formValues[`tenancy_type_${index}`] = item?.tenancy_type || "";
    formValues[`start_date_${index}`] =
      new Date(item?.start_date) || new Date();
    formValues[`end_date_${index}`] = new Date(item?.end_date) || new Date();
    formValues[`status_${index}`] = item?.status;

    currentFormCount.push(index);
  });

  setInitialFormValues(formValues);
  setFormCountList(currentFormCount);
};

export const tenancyTypeList = [
  {
    name: "Rent",
    type: GLOBAL.TENANCY_TYPE.RENT,
  },
  {
    name: "Own",
    type: GLOBAL.TENANCY_TYPE.OWN,
  },
];

export const addMore = (formRef, setFormValue, list, setList) => {
  const generetedID = list.length === 0 ? 0 : list[list.length - 1] + 1;
  const currentFormState = { ...formRef.current.values };

  setFormValue(() => {
    // Generating new field name according to array indexes
    currentFormState[`address_${generetedID}`] = "";
    currentFormState[`property_name_${generetedID}`] = "";
    currentFormState[`unit_${generetedID}`] = "";
    currentFormState[`type_${generetedID}`] = "";
    currentFormState[`countrycode_${generetedID}`] = "";
    currentFormState[`contact_number_${generetedID}`] = "";
    currentFormState[`email_${generetedID}`] = "";
    currentFormState[`tenancy_type_${generetedID}`] = "";
    currentFormState[`start_date_${generetedID}`] = new Date();
    currentFormState[`end_date_${generetedID}`] = new Date();

    return currentFormState;
  });
  setList((p) => [...p, generetedID]);
};

export const remove = (formRef, setFormValue, list, setList, index) => {
  const removeID = list[index];
  const currentFormState = { ...formRef.current.values };

  setFormValue(() => {
    delete currentFormState[`address_${removeID}`];
    delete currentFormState[`property_name_${removeID}`];
    delete currentFormState[`unit_${removeID}`];
    delete currentFormState[`type_${removeID}`];
    delete currentFormState[`countrycode_${removeID}`];
    delete currentFormState[`contact_number_${removeID}`];
    delete currentFormState[`email_${removeID}`];
    delete currentFormState[`tenancy_type_${removeID}`];
    delete currentFormState[`start_date_${removeID}`];
    delete currentFormState[`end_date_${removeID}`];

    return currentFormState;
  });

  setList((prevState) => {
    const prevFields = [...prevState];
    prevFields.splice(index, 1);
    return prevFields;
  });
};

export const isDataChanged = (newData, prevData, keyList) => {
  for (let i = 0; i < keyList.length; i++) {
    const property_name_old = prevData[i].property_name;
    const unit_old = prevData[i].unit;
    const type_old = prevData[i].type;
    const tenancy_type_old = prevData[i].tenancy_type;
    const address_old = prevData[i].address;
    const countrycode_old = prevData[i].countrycode;
    const contact_number_old = prevData[i].contact_number;
    const start_date_old = prevData[i].start_date;
    const end_date_old = prevData[i].end_date;
    const email_old = prevData[i].owner_email;

    if (newData[`property_name_${keyList[i]}`] !== property_name_old) {
      return true;
    }

    if (newData[`unit_${keyList[i]}`] !== unit_old) {
      return true;
    }

    if (newData[`type_${keyList[i]}`] !== type_old) {
      return true;
    }

    if (newData[`tenancy_type_${keyList[i]}`] !== tenancy_type_old) {
      return true;
    }

    if (newData[`address_${keyList[i]}`] !== address_old) {
      return true;
    }

    if (newData[`countrycode_${keyList[i]}`] !== countrycode_old) {
      return true;
    }

    if (newData[`contact_number_${keyList[i]}`] !== contact_number_old) {
      return true;
    }

    if (newData[`email_${keyList[i]}`] !== email_old) {
      return true;
    }

    if (!moment(newData[`start_date_${keyList[i]}`]).isSame(start_date_old)) {
      return true;
    }

    if (!moment(newData[`end_date_${keyList[i]}`]).isSame(end_date_old)) {
      return true;
    }
  }

  return false;
};
