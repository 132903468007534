/**
 *
 */

import React, { useCallback } from "react";

/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import MUI component starts */
/* Import MUI component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const CounterInput = (props) => {
  /* Props destructuring starts */
  const {
    num, // useState value
    setNum, // useState set function definition
    label,
    onNumChange = (num, event) => {},
    validation,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const validationMsg = useCallback(validation, [num]);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const changeHandler = (event) => {
    let value = event.target.value;
    if (value === "") {
      setNum(0);
      onNumChange(0);
      return;
    }
    if (Number(value) >= 0) {
      if (value[0] === "0") {
        setNum(value.slice(1));
        onNumChange(value.slice(1));
      } else {
        setNum(value);
        onNumChange(value);
      }
    }
  };

  const inc = () => {
    setNum(Number(num) + 1);
    onNumChange(Number(num) + 1);
  };

  const dec = () => {
    setNum((prevCount) => (prevCount > 1 ? prevCount - 1 : prevCount)); // It shouldn't go below 1
    onNumChange(Number(num) - 1);
    // if (num !== 0) {
    //   setNum(Number(num) - 1);
    // }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="counter-input-component-wrapper w_100 d_flex">
      <div className="form-input-wrapper">
        <label className="font_s font_grey">
          {label}
          {/* {required && <span className="font_m font_red"> *</span>} */}
        </label>
        <input
          type="number"
          className="bg_black font_xs"
          value={num}
          onChange={changeHandler}
        />
      </div>
      <button onClick={inc} className="d_block font_xl font_blue bg_black">
        +
      </button>
      <button onClick={dec} className="d_block font_xl font_blue bg_black">
        -
      </button>
      {validation && (
        <p className="validation font_red font_s fw_5">{validationMsg(num)}</p>
      )}
    </div>
  );
};
/* Component ends */

export default CounterInput;
