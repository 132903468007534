/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useNavigate } from "react-router-dom";
import { generateNavigation, generateNavigationTenant } from "../helper";
import { useDispatch, useSelector } from "react-redux";
import GLOBAL from "../../../../../setup/constants/global";
/* Import configuration ends */

/* Import redux slices component starts */
import { getIsTENANT } from "../../../../../setup/store/slices/authSlice";
import {
  deleteNotification,
  markNotificationSeen,
} from "../../../../../setup/store/slices/notificationSlice";
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../../../setup/store/slices/globalAlertSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import {
  RightArrowOutlineGrey,
  DeleteGrey,
  DeleteRed,
} from "../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../../../ui/button/IconButton/IconButton";
import moment from "moment";
/* Import local pages and component ends */

/* Component starts */
const Item = (props) => {
  /* Props destructuring starts */
  const { item, fetchNotifs } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isTenant = useSelector(getIsTENANT);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const handleNavigate = () => {
    if (!item?.seen) {
      handleSeen(item);
    }
    if (isTenant) {
      navigate(generateNavigationTenant(item));
    } else {
      navigate(generateNavigation(item));
    }
  };

  const handleDelete = async (n) => {
    // Creating or collecting payload data to be sent
    const payload = { id: n._id };
    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      const result = await dispatch(deleteNotification(payload));
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          fetchNotifs();
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  const handleSeen = async (n) => {
    // Creating or collecting payload data to be sent
    const payload = { id: n._id };
    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      const result = await dispatch(markNotificationSeen(payload));
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className={item?.seen ? "item" : "not-seen"}>
      <p className="font_m fw_5" onClick={handleNavigate}>
        {item?.title}
      </p>
      <div className="end">
        <p className="font_xs fw_3 font_grey">
          {moment(item?.createdAt).format(DATE_TIME_FORMAT)}
        </p>
        <img src={RightArrowOutlineGrey} alt="" className="icon" />

        {!item?.seen && <div className="red_dot bg_red"></div>}
        <IconButton onClick={() => handleDelete(item)}>
          <img src={DeleteGrey} className="icon" />
        </IconButton>
      </div>
    </div>
  );
};
/* Component ends */

export default Item;
