/**
 *
 */

import moment from "moment";
import React, { useState, useEffect } from "react";
import GLOBAL from "../../../../../setup/constants/global";

/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
/* Import local pages and component ends */

/* Component starts */
const RejectionStatus = (props) => {
  /* Props destructuring starts */
  const { item } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="rejection-statuses-wrapper">
      <h2 className="heading text-center font_red fw_6">
        Application Rejected
      </h2>
      {item?.tenant_application?.application?.application_details
        ?.rejection_reason && (
        <h3 class="reason font_l fw_5 text-center">
          <span class="font_red">Reason : </span>{" "}
          {
            item?.tenant_application?.application?.application_details
              ?.rejection_reason
          }
        </h3>
      )}
      {item?.tenant_application?.application?.application_details
        ?.rejected_by && (
        <h3 class="by font_l fw_5 text-center">
          <span class="font_red">Rejected By : </span>{" "}
          {
            item?.tenant_application?.application?.application_details
              ?.rejected_user_name
          }{" "}
          (
          {
            item?.tenant_application?.application?.application_details
              ?.rejected_by
          }
          )
        </h3>
      )}
      {item?.tenant_application?.application?.application_details
        ?.rejected_timeStamp && (
        <p class="by font_l fw_5 text-center">
          <span class="font_red">Rejected At : </span>{" "}
          {moment(
            item?.tenant_application?.application?.application_details
              ?.rejected_timeStamp
          ).format(DATE_TIME_FORMAT)}
        </p>
      )}
    </div>
  );
};
/* Component ends */

export default RejectionStatus;
