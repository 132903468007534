/**
 * This component is a Modal that is shown on success
 */

import React, { useRef, useState } from "react";

import { Formik } from "formik";
/* Import react bootstrap component starts */
import { Modal } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomTextInput from "../../../../ui/input/CustomTextInput/CustomTextInput";
import CustomFileInput from "../../../../ui/input/CustomFileInput/CustomFileInput";
import CustomButton from "../../../../ui/button/CustomButton/CustomButton";
import TextButton from "../../../../ui/button/TextButton/TextButton";
/* Import local pages and component ends */

import "./style.scss";
import {
  addFoundClaimedItem,
  addLostClaimedItem,
} from "../../../../../setup/store/slices/lostAndFoundSlice";
import { useDispatch, useSelector } from "react-redux";
import GLOBAL from "../../../../../setup/constants/global";
import ErrorModal from "../../../../ui/modal/ErrorModal/ErrorModal";
import SuccessModal from "../../../../ui/modal/SuccessModal/SuccessModal";
import CustomPhoneInput from "../../../../ui/input/CustomPhoneInput/CustomPhoneInput";
import { getUserTheme } from "../../../../../setup/store/slices/authSlice";

/* Component starts */
const ClaimedModal = (props) => {
  /* Props destructuring starts */
  const {
    showModal,
    size = "lg",
    onClose,
    onSuccess,
    id,
    itemId,
    type,
  } = props;
  /* Props destructuring ends */

  // useState
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [modalDesc, setModalDesc] = useState("");

  // useRef
  const customPhoneNum = useRef({ code: "", num: "" });
  const photo = useRef(null);
  const dispatch = useDispatch();

  const userTheme = useSelector(getUserTheme);
  // Initial Values
  const initialFormValues = {
    first_name: "",
    last_name: "",
    mobile_number: "",
    email: "",
    id_type: "",
    id_number: "",
    photo: "",
  };

  // Handle Form Validations
  const formValidation = (values) => {
    const error = {};

    if (values.first_name === "") {
      error.first_name = "First Name Is Required";
    }

    if (values.last_name === "") {
      error.last_name = "Last Name Is Required";
    }

    if (values.mobile_number === "") {
      error.mobile_number = "Mobile Number Is Required";
    }

    if (values.photo === "") {
      error.photo = "Please select an image";
    }

    return error;
  };

  // Handle SUbmit
  const onSubmit = async (values) => {
    const payload = {
      first_name: values.first_name,
      last_name: values.last_name,
      mobile_number: values.mobile_number,
      id_type: values.id_type,
      id_number: values.id_number,
      file: [photo.current],
    };

    if (type === "Found") {
      payload["found_id"] = id;
    } else {
      payload["lost_id"] = itemId;
    }

    // Backend response. Try, Catch
    try {
      let result;
      if (type === "Found") {
        result = await dispatch(addFoundClaimedItem(payload));
      } else {
        result = await dispatch(addLostClaimedItem(payload));
      }

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setModalDesc(result.payload);
          setShowErrorModal(true);
          break;

        case GLOBAL.FULFILLED:
          setModalDesc(result.payload.message);
          setShowSuccessModal(true);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  /* ************* Component rendering. JSX code ************* */
  return (
    <Modal
      size={size}
      centered
      show={showModal}
      className="claim-modal-wrapper"
    >
      <Modal.Body
        className={`${userTheme === "dark" ? "bg_darkTheme" : "bg_lightTheme"}`}
      >
        <div className="modal-header-custom">
          <p className="font_l fw_6 font_blue text-start">
            Claimer Information
          </p>
        </div>
        <div className="modal-body-custom">
          <Formik
            onSubmit={onSubmit}
            initialValues={initialFormValues}
            validate={formValidation}
            validateOnBlur={true}
            enableReinitialize={true}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                {/* Form Fields */}
                <div className="form-wrapper form-wrapper-flex">
                  <div className="form-group-full w_100">
                    <p className="fw_4 font_m font_grey">
                      {" "}
                      ID :{" "}
                      <span className="fw_4 font_blue font_m">{itemId}</span>
                    </p>
                  </div>
                  {/* Input for Claimed By First Name */}
                  <div className="form-group">
                    <CustomTextInput
                      name="first_name"
                      label="Enter First Name"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched.first_name}
                      errors={errors.first_name}
                      value={values.first_name}
                      required={true}
                    />
                  </div>

                  {/* Input for Claimed By Last Name */}
                  <div className="form-group">
                    <CustomTextInput
                      name="last_name"
                      label="Enter Last Name"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched.last_name}
                      errors={errors.last_name}
                      value={values.last_name}
                      required={true}
                    />
                  </div>

                  {/* Input for Mobile Number */}
                  <div
                    //className="form-group"
                    className={`form-group ${
                      (userTheme === "light" || userTheme === "automatic") &&
                      "otherTheme"
                    } `}
                  >
                    <CustomPhoneInput
                      name="mobile_number"
                      label={"Phone Number"}
                      customPhoneNum={customPhoneNum}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      value={values.mobile_number}
                      countryCodeEditable={true}
                      errors={errors.mobile_number}
                      touched={touched.mobile_number}
                    />
                  </div>

                  {/* Input for Email */}
                  <div className="form-group">
                    <CustomTextInput
                      name="email"
                      label="Enter Your Email"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched.email}
                      value={values.email}
                      required={false}
                    />
                  </div>

                  <hr />

                  <div className="form-group-full w_100">
                    <p className="fw_4 font_blue font_m">Claimer ID</p>
                  </div>

                  {/* Input for Claimed By ID */}
                  <div className="form-group">
                    <CustomTextInput
                      name="id_type"
                      label="Enter ID Type"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched.id_type}
                      errors={errors.id_type}
                      value={values.id_type}
                      required={true}
                    />
                  </div>

                  {/* Input for ID Number */}
                  <div className="form-group">
                    <CustomTextInput
                      name="id_number"
                      label="ID Number"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched.id_number}
                      errors={errors.id_number}
                      value={values.id_number}
                      required={true}
                    />
                  </div>

                  {/* Upload Image */}
                  <div className="form-group">
                    <CustomFileInput
                      name="photo"
                      label="Upload"
                      files={photo}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      errors={errors.photo}
                      touched={touched.photo}
                      setFieldValue={setFieldValue}
                      values={values.photo}
                      required={false}
                      formikState={true}
                    />
                  </div>
                </div>

                {/* Submit and Cancel */}
                <div className="button-wrapper text-center">
                  <>
                    <CustomButton type="submit" size="l">
                      Claim
                    </CustomButton>
                    <TextButton
                      type="button"
                      onClick={onClose}
                      className="font_m fw_6"
                    >
                      Cancel
                    </TextButton>
                  </>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </Modal.Body>

      {/* Success Modal */}
      <SuccessModal
        showModal={showSuccessModal}
        description={modalDesc}
        title="Successfully"
        onClose={() => {
          onSuccess();
          setShowSuccessModal(false);
        }}
      />

      {/* Error Modal */}
      <ErrorModal
        showModal={showErrorModal}
        description={modalDesc}
        title="Error"
        onClose={() => setShowErrorModal(false)}
      />
    </Modal>
  );
};
/* Component ends */

export default ClaimedModal;
