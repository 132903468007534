/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import SimpleLog from "./SimpleLog";
import URICP1Log from "./URICP1Log";
/* Import local pages and component ends */

/* Component starts */
const TimeLineLists = (props) => {
  /* Props destructuring starts */
  const {
    maintenance_id,
    maintenance,
    logs,
    mgmtModel,
    isT1,
    isT2,
    isT3,
    status,
    property,
    user,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const generateLogType = (item) => {
    const action = item?.action;
    const currentStatus = item?.current_status;
    const logType = item?.log_type;

    // UIRC Phase 1 Initial Report review
    if (
      action === "FM pending initial p1 report review" ||
      action === "Initial Inspection submitted by Owner" ||
      action === "UIRC initial assigned to PM" ||
      action === "Owner approved p1 report review"
    ) {
      return "uirc-p1-initial-report-review";
    }

    // UIRC Phase 1 Final Report review
    if (action === "FM pending final p1 report review") {
      return "uirc-p1-final-report-review";
    }

    // Quotaion
    if (action === "Quoatation assigned To PM") {
      return "quotation";
    }

    // Task
    if (
      action === "Technician Assigned" &&
      (logType === "URIC Phase 2" || logType.toLowerCase() === "additional")
    ) {
      return "task";
    }
    return "simple";
  };
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="timeline-list-wrapper">
      {/* Time Line List */}
      {logs?.map((item, index) => (
        <div className="log-wrapper" key={index}>
          {/* UIRC P1 Report Review Log */}
          {(generateLogType(item) === "uirc-p1-initial-report-review" ||
            generateLogType(item) === "uirc-p1-final-report-review") && (
            <URICP1Log
              isT1={isT1}
              status={status}
              maintenance={maintenance}
              item={item}
              takeAction={index === 0}
              maintenance_id={maintenance_id}
              property={property}
              user={user}
            />
          )}

          {/* Simple Log */}
          {generateLogType(item) === "simple" && (
            <SimpleLog
              isT1={isT1}
              status={status}
              maintenance={maintenance}
              item={item}
              takeAction={index === 0}
              maintenance_id={maintenance_id}
              property={property}
              user={user}
            />
          )}
        </div>
      ))}
    </div>
  );
};
/* Component ends */

export default TimeLineLists;
