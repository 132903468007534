/**
 *
 */

import React, { useState, useEffect } from "react";
import DetailCard from "./sub-component/DetailCard";

/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
/* Import local pages and component ends */

import "./style.scss";
import TimeLineListings from "./sub-component/TimeLineListings";

/* Component starts */
const SecurityComplaintDetail = (props) => {
  /* Props destructuring starts */
  const {
    complaint_id,
    complaint,
    type,
    mgmtModel,
    isT1,
    isT2,
    isT3,
    status,
    property,
    user,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="security-complaint-report-component-wrapper container_sm">
      <h1 className="text-center font_xxl mb-4">Security Complaint Report</h1>

      {/* Details */}
      <DetailCard
        type={type}
        isT1={isT1}
        isT2={isT2}
        isT3={isT3}
        status={status}
        mgmtModel={mgmtModel}
        complaint={complaint}
      />

      {/* Timeline */}
      <TimeLineListings
        isT1={isT1}
        isT2={isT2}
        isT3={isT3}
        status={status}
        mgmtModel={mgmtModel}
        complaint={complaint}
        complaint_id={complaint_id}
        logs={complaint?.logs}
        property={property}
        user={user}
      />
    </div>
  );
};
/* Component ends */

export default SecurityComplaintDetail;
