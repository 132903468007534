/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useDispatch, useSelector } from "react-redux";
import GLOBAL from "../../../../setup/constants/global";
import { taxNameRender } from "./helper";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  fetchLicenseAmount,
  getPayableAmount,
  fetchCalculatedLicenseAmount,
  getTaxCalculatedAmt,
  getTaxIncludedAmount,
} from "../../../../setup/store/slices/licenseSlice";
import {
  fetchSetting,
  getSetting,
} from "../../../../setup/store/slices/settingSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import MUI component starts */
/* Import MUI component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import CounterInput from "../../../ui/input/CounterInput/CounterInput";
import PaymentMethod from "../../Payment/PaymentMethod/PaymentMethod";
import TransactionStatus from "../../Payment/TransactionStatus/TransactionStatus";
/* Import local pages and component ends */

import "./style.scss";
import { getUserTheme } from "../../../../setup/store/slices/authSlice";

/* Component starts */
const PurchaseLicense = (props) => {
  /* Props destructuring starts */
  const {
    size = "s",
    defaultNumLicences = 1,
    afterTransaction = () => {},
    onCancel,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [perUnitProrated, setPerUnitProrated] = useState(1);
  const [numLicenseToPay, setNumLicenseToPay] = useState(defaultNumLicences);
  const [transactionStatus, setTransactionStatus] = useState({});
  const [showTransactionStatus, setShowTransactionStatus] = useState(false);
  const [loadSummary, setLoadSummary] = useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();

  // Redux Selector
  const payableAmount = useSelector(getPayableAmount);
  const taxIncludedAmount = useSelector(getTaxIncludedAmount);
  const settings = useSelector(getSetting);
  const taxCalculatedAmt = useSelector(getTaxCalculatedAmt);
  const userTheme = useSelector(getUserTheme);

  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // Validation for License counter
  const validationForCounter = (value) => {
    if (value === "0" || value === 0) {
      return "Should have atleast 1 License to purchase";
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  // Initial loading
  useEffect(() => {
    dispatch(fetchSetting());
  }, []);

  // On changing licence quantity
  useEffect(() => {
    setLoadSummary(true);
    const getTimeoutData = setTimeout(async () => {
      await dispatch(fetchLicenseAmount(numLicenseToPay));
      await dispatch(fetchCalculatedLicenseAmount(numLicenseToPay));
      setLoadSummary(false);
    }, 1000);

    return () => clearTimeout(getTimeoutData);
  }, [numLicenseToPay]);

  // Calculating the prorated amount
  useEffect(() => {
    let amountToPay = payableAmount / numLicenseToPay;
    setPerUnitProrated(
      amountToPay % 1 == 0 ? amountToPay : amountToPay.toFixed(2)
    );
  }, [payableAmount]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="purchaseLicenseWrapper w_100 p_relative">
      <h2 className="heading text-center font_xxl mb-5">Purchase License</h2>
      {!showTransactionStatus && (
        <div
          className={`${
            size === "l" ? "" : "form-wrapper form-wrapper-top bg_grey"
          }`}
        >
          {/* Counter */}
          <div className="counter w_100">
            <CounterInput
              num={numLicenseToPay}
              setNum={setNumLicenseToPay}
              label="Number of Licenses"
              validation={validationForCounter}
            />
          </div>

          {/* Payment Summary */}
          <div className="summary-wrapper w_100 p_relative">
            <div
              className={`summary p_relative w_100 ${
                size === "l" ? "bg_grey" : "bg_darkgrey"
              }`}
            >
              <p
                className={`${
                  size === "l" ? "font_l" : "font_m"
                } font_yellow fw_6`}
              >
                Payment Summary
              </p>
              <div className="d_flex details_amt">
                <p
                  className={`${
                    size === "l" ? "font_l" : "font_m"
                  } font_grey fw_6`}
                >
                  Total Number of Licenses
                </p>
                <p
                  className={`${
                    size === "l" ? "font_l" : "font_m"
                  } right font_grey fw_6`}
                >
                  {numLicenseToPay}
                </p>
              </div>
              <div className="d_flex details_amt">
                <p
                  className={`${
                    size === "l" ? "font_l" : "font_m"
                  } font_grey fw_6`}
                >
                  Additional Required Licenses (Per Unit){" "}
                  {parseInt(settings?.perUnitPrice) !==
                    parseInt(perUnitProrated) && (
                    <span className="font_xs font_yellow">(Prorated)</span>
                  )}{" "}
                  :
                </p>
                <p
                  className={`${
                    size === "l" ? "font_l" : "font_m"
                  } right font_grey fw_6`}
                >
                  ${perUnitProrated}
                </p>
              </div>
              {taxCalculatedAmt?.tax_breakdown?.length > 0 &&
                taxCalculatedAmt?.tax_breakdown.map((tb) => (
                  <div className="d_flex details_amt">
                    <p
                      className={`${
                        size === "l" ? "font_l" : "font_m"
                      } font_grey fw_6`}
                    >
                      {`${taxNameRender(tb?.tax_rate_details?.tax_type)} (${
                        tb.tax_rate_details.percentage_decimal
                      }%)`}
                    </p>
                    <p
                      className={`${
                        size === "l" ? "font_l" : "font_m"
                      } right font_grey fw_6`}
                    >
                      ${tb.amount}
                    </p>
                  </div>
                ))}

              <div className="d_flex details_amt">
                <p
                  className={`${
                    size === "l" ? "font_xl" : "font_l"
                  } font_blue fw_6`}
                >
                  Total Payable :
                </p>
                <p
                  className={`${
                    size === "l" ? "font_xl" : "font_l"
                  } right font_blue fw_6`}
                >
                  ${taxIncludedAmount?.toLocaleString("en-US")}
                </p>
              </div>
            </div>

            {/* On loading payment summary */}
            {loadSummary && (
              <div className={`load-summary ${size === "l" && "bg_darkgrey"}`}>
                <p className={`${size === "l" ? "font_l" : "font_m"} fw_6`}>
                  Getting payment details...
                </p>
              </div>
            )}
          </div>
        </div>
      )}

      {/* Payment method */}
      {!showTransactionStatus && (
        <PaymentMethod
          size={size}
          onCancel={onCancel}
          setTransactionStatus={setTransactionStatus}
          setShowTransactionStatus={setShowTransactionStatus}
          receiverStripeKey={settings?.stripePublicKey}
          isPaymentAllowed={parseInt(numLicenseToPay) > 0}
          payload={{ number_of_license: numLicenseToPay }}
          paymentType={GLOBAL.PAYMENT_TYPE.LICENSE}
        />
      )}

      {/* Transaction Status */}
      {showTransactionStatus && (
        <TransactionStatus
          onSuccessClose={afterTransaction}
          onFailedClose={() => {
            setShowTransactionStatus(false);
          }}
          status={transactionStatus}
          setShowTransactionStatus={setShowTransactionStatus}
          setNumLicenseToPay={setNumLicenseToPay}
          currency={taxCalculatedAmt?.currency}
        />
      )}
    </div>
  );
};
/* Component ends */

export default PurchaseLicense;
