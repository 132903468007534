export const initialValues = {
  title: "",
  description: "",
  companyName: "",
  fullName: "",
  mobile_number: "",
  email: "",
  company: "",
};

export const validations = (values, violationIncident) => {
  const errorFields = {};

  /* Validations for Subject Starts */
  if (values.title === "") {
    errorFields.title = "Subject is required";
  }

  /* Validations for Subject Ends */

  /* Validations for Description Starts */
  // if (values.description === "") {
  //   errorFields.description = "Description is required";
  // }

  /* Validations for Description Ends */

  /* Validations for Full Name Starts */
  if (violationIncident === "Others" && values.fullName === "") {
    errorFields.fullName = "Full Name is required";
  }

  /* Validations for Full Name Ends */

  /* Validations for Email Starts */
  if (violationIncident === "Others" && values.email === "") {
    errorFields.email = "Email is required";
  }

  /* Validations for Email Ends */

  return errorFields;
};
