/**
 *
 */

import React, { useState } from "react";

/* Import configuration starts */
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
import { signOutVisitorsPass } from "../../../setup/store/slices/visitorLogBook";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */

import GLOBAL from "../../../setup/constants/global";
import Report from "../local-component/Report";
import Header from "../local-component/Header";
import CustomButton from "../../../components/ui/button/CustomButton/CustomButton";
import TextButton from "../../../components/ui/button/TextButton/TextButton";
import CustomTextArea from "../../../components/ui/input/CustomTextArea/CustomTextArea";
import ErrorModal from "../../../components/ui/modal/ErrorModal/ErrorModal";
import SuccessModal from "../../../components/ui/modal/SuccessModal/SuccessModal";
import BreadCrumb from "../../../components/ui/other/BreadCrumb/BreadCrumb";
/* Import local pages and component ends */

// Styles
import "./style.scss";

/* Component starts */
const VisitorSignOut = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [modalDesc, setModalDesc] =
    useState(""); /* Component states and useRef declaration starts */

  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const initialFormValues = {
    notes: "",
  };
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  const onSubmit = async (values) => {
    const payload = {
      id: state?._id,
      notes: values?.notes,
    };

    console.log(payload);

    // Backend response. Try, Catch
    try {
      const result = await dispatch(signOutVisitorsPass(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setModalDesc(result.payload);
          setShowErrorModal(true);
          break;

        case GLOBAL.FULFILLED:
          setModalDesc(result.payload.message);
          setShowSuccessModal(true);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  const onCancel = () => {
    navigate(-1);
  };

  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */

  return (
    <>
    <BreadCrumb type="visitor_log_signOut_detail" />
    <div className="visitor-sign-out-wrapper">
      <div className="visitor-log-header">
        <Header />
      </div>

      <div className="report-wrapper container_sm">
        <div className="detail-box">
          <Report incident={state} />
        </div>

        <div className="detail-form-box">
          <div className="signout-form">
            <Formik
              onSubmit={onSubmit}
              initialValues={initialFormValues}
              validateOnBlur={true}
              enableReinitialize={true}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                // isValid,
                // dirty,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="form-wrapper">
                    {/* Creating new Sign Out Note */}
                    <>
                      {/* Text Area for Description */}
                      <div className="form-group form-group-full">
                        <CustomTextArea
                          name="notes"
                          label="Notes"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          value={values.notes}
                          touched={touched.notes}
                          error={errors.notes}
                          required={false}
                        />
                      </div>
                    </>
                  </div>

                  <div className="button-wrapper text-center">
                    <>
                      <CustomButton
                        type="submit"
                        // disable={!(dirty && isValid)}
                        size="l"
                      >
                        Sign Out
                      </CustomButton>
                      <TextButton
                        type="button"
                        onClick={onCancel}
                        className="font_m fw_6"
                      >
                        Cancel
                      </TextButton>
                    </>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>

      {/* Success Modal */}
      <SuccessModal
        showModal={showSuccessModal}
        description={modalDesc}
        title="Signed Out Successfully "
        onClose={() => {
          navigate(-1);
          setShowSuccessModal(false);
        }}
      />

      {/* Error Modal */}
      <ErrorModal
        showModal={showErrorModal}
        description={modalDesc}
        title="Error"
        onClose={() => setShowErrorModal(false)}
      />
    </div>
    </>
  );
};
/* Component Ends */
export default VisitorSignOut;
