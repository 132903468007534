export const initialValues = {
  title: "",
  description: "",
  severity: "",
};

export const validations = (values) => {
  const error = {};
  /* Validation for Title starts */
  if (values.title === "") {
    error.title = "Title is required";
  }
  /* Validation for Title ends */
  /* ******************************************************************************* */
  /* Validation for Title starts */
  if (values.severity === "") {
    error.severity = "Severity is required";
  }
  /* Validation for Title ends */

  /* Validation for Description starts */
  if (values.description !== "" && values.description.length >= 35) {
    error.description = "Not Exceed 35 characters";
  }
  /* Validation for Description starts */

  return error;
};
