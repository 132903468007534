/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import moment from "moment";
import { useNavigate } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Table } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import {
  EyeFilledBlue,
  ProfileFilledGrey,
} from "../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../../../ui/button/IconButton/IconButton";
import Pagination from "../../../../ui/other/Pagination/Pagination";
import GLOBAL from "../../../../../setup/constants/global";
/* Import local pages and component ends */

/* Component starts */
const PreviousTenants = (props) => {
  /* Props destructuring starts */
  const { list, totalCount, currentPage, setCurrentPage } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const handleReportView = (data) => {
    // navigate(`detail/${data.tenantId}?unit_id=${data.unitId}`, {});
    navigate(`detail/${data.tenantId}/unit/${data.unitId}`, {});
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      <div className="custom-table-wrapper prevoius-tenats-table">
        {list?.length > 0 && (
          <Table className="custom-table font_s" borderless responsive>
            {/* Table Heading */}
            <thead>
              <tr>
                <th>Tenant Name</th>
                <th>Unit No.</th>
                <th>Type Of Unit</th>
                <th>Contract Expiry Date</th>
                {/* <th>Subscription</th> */}
                <th className="text-center">Action</th>
              </tr>
            </thead>
            {/* Table Body */}
            <tbody>
              {list?.map((item) => (
                <tr key={item.unit_id}>
                  {/* Tenant Name */}
                  <td>{item?.tenant !== undefined ? item?.tenant : "-"}</td>

                  {/* Unit NUmber */}
                  <td>
                    {item?.unit_name !== undefined ? item?.unit_name : "-"}
                  </td>

                  {/* Unit Type */}
                  <td>
                    {item?.unit_type !== undefined ? item?.unit_type : "-"}
                  </td>

                  {/* Contract Expiry Date */}
                  <td>
                    {item?.contract_exp_date !== undefined
                      ? moment(item?.contract_exp_date).format(DATE_TIME_FORMAT)
                      : "-"}
                  </td>
                  {/* <td>{item?.contract_exp_date}</td> */}

                  {/* Subscription */}
                  {/* <td>{item?.sub}</td> */}

                  {/* Actions */}
                  <td className="text-center txt-nowrap">
                    {/* <IconButton
                      // onClick={() =>
                      //   navigate("/tenant/security/details", {
                      //     state: { item },
                      //   })
                      // }
                      className="action-button"
                    >
                      <img
                        src={ProfileFilledGrey}
                        className="h_100"
                        alt="View Details"
                      />
                    </IconButton> */}

                    <IconButton
                      onClick={() =>
                        handleReportView({
                          tenantId: item?.tenant_id,
                          unitId: item?.unit_id,
                        })
                      }
                      className="action-button"
                    >
                      <img
                        src={EyeFilledBlue}
                        className="h_100"
                        alt="View Details"
                      />
                    </IconButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}

        {list?.length === 0 && (
          <div className=" text-center">
            <h3 className=" font_m font_yellow fw_6">No Data</h3>
          </div>
        )}
      </div>

      {/* Pagination */}
      {list?.length > 0 && (
        <Pagination
          itemsPerPage="10"
          totalItems={totalCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
    </>
  );
};
/* Component ends */

export default PreviousTenants;
