/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useDispatch, useSelector } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  showSuccessAlert,
  showErrorAlert,
} from "../../../../../setup/store/slices/globalAlertSlice";
import { forceMoveOut } from "../../../../../setup/store/slices/moveOutSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Table } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import {
  EyeFilledBlue,
  DashboardMove,
  ExcalmatoryYellow,
} from "../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../../../ui/button/IconButton/IconButton";
import moment from "moment";
import GLOBAL from "../../../../../setup/constants/global";
import DetailViewModal from "./DetailViewModal/DetailViewModal";
import ConfirmationModal from "../../../../ui/modal/ConfirmationModal/ConfirmationModal";
import FileViewerModal from "../../../../ui/modal/FileViewerModal/FileViewerModal";
import AddDateModal from "../../../../ui/modal/AddDateModal/AddDateModal";
/* Import local pages and component ends */

/* Component starts */
const Contract = (props) => {
  /* Props destructuring starts */
  const { contractList, property } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [showContractDetailModal, setShowContractDetailModal] = useState(false);
  const [detail, setDetail] = useState({});
  const [selectedContract, setSelectedContract] = useState(null);
  const [showConfirmMoveOut, setShowConfirmMoveOut] = useState(false);
  const [showFile, setShowFile] = useState(false);
  const [file, setFile] = useState({});
  const [showSelectDateModal, setShowSelectDateModal] = useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const handleView = (item) => {
    // if (item) {
    setDetail(item);
    if (item) {
      setShowContractDetailModal(true);
    }
    // }
  };

  const onSelectMoveOutDate = async (move_out_date) => {
    setShowConfirmMoveOut(false);
    // Creating or collecting payload data to be sent
    const payload = {
      contract_id: selectedContract._id,
      move_out_date,
      tenantId: selectedContract?.tenant_id,
      unitId: selectedContract?.unit_id,
    };

    // Backend response. Try, Catch
    try {
      const result = {};
      // const result = await dispatch(forceMoveOut(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="custom-table-wrapper contract-table">
      {contractList?.length > 0 && (
        <Table className="custom-table font_s" borderless responsive>
          {/* Table Heading */}
          <thead>
            <tr>
              <th>Contract ID</th>
              <th>Unit No.</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Status</th>
              <th>Rent Amount </th>
              <th># of Installments</th>
              <th className="text-start">Action</th>
            </tr>
          </thead>
          {/* Table Body */}
          <tbody>
            {contractList?.map((item, index) => (
              <tr key={item?._id}>
                {/* ID */}

                <td>
                  {item?.request_id !== undefined ? item?.request_id : "-"}
                </td>

                <td>{item?.unit_name !== undefined ? item?.unit_name : "-"}</td>

                {/* Start Date */}
                <td>{moment(item?.start_date).format(DATE_FORMAT)}</td>

                {/*End Date */}
                <td>{moment(item?.end_date).format(DATE_FORMAT)}</td>

                {/* Status */}
                <td
                  className={` ${
                    item?.status === "active" ||
                    item?.status === "renewal_pending"
                      ? "font_green"
                      : item?.status === "expired"
                      ? "font_red"
                      : "font_yellow"
                  } `}
                >
                  {item?.status === "renewal_pending"
                    ? "Active"
                    : item?.status !== undefined
                    ? item?.status?.charAt(0)?.toUpperCase() +
                      item?.status?.slice(1)
                    : "-"}
                </td>

                {/* Total Rent */}
                <td>
                  {item?.total_rent?.toLocaleString("en-US")}{" "}
                  {property?.currency}
                </td>

                {/* Total Installments */}
                <td>{item?.no_of_installments}</td>

                {/* Actions */}
                <td className="text-start txt-nowrap">
                  <IconButton
                    onClick={() =>
                      // navigate("/property/lostAndFound/detail-found", {
                      //   state: { item },
                      // })
                      handleView(item)
                    }
                    className="action-button"
                  >
                    <img
                      src={EyeFilledBlue}
                      className="h_100"
                      alt="View Details"
                    />
                  </IconButton>
                  {(item.status === "active" ||
                    item.status === "renewal_pending") &&
                    // !item?.move_out_initiated &&
                    item?.move_out_status === "" &&
                    index === 0 && (
                      <IconButton
                        onClick={() => {
                          setSelectedContract(item);
                          setShowConfirmMoveOut(true);
                        }}
                        className="action-button"
                      >
                        <img
                          src={DashboardMove}
                          className="h_100"
                          alt="Eviction"
                        />
                      </IconButton>
                    )}

                  {item?.notarized_contract &&
                    !item?.notarized_contract_pdf &&
                    (item?.status === "renewal_pending" ||
                      item?.status === "active") && (
                      <IconButton
                        className="action-button"
                        // onClick={() => handleView(item)}
                      >
                        <img
                          src={ExcalmatoryYellow}
                          className="h_100"
                          alt="Notarized"
                        />
                      </IconButton>
                    )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      {contractList?.length === 0 && (
        <div className=" text-center">
          <h3 className=" font_m font_yellow fw_6">No Data</h3>
        </div>
      )}

      {/* Contract detail modal */}
      <DetailViewModal
        type="Contract"
        showModal={showContractDetailModal}
        property={property}
        detail={detail}
        onClose={() => {
          setShowContractDetailModal(false);
        }}
      />

      {/* Confirmation Modal */}
      <ConfirmationModal
        showModal={showConfirmMoveOut}
        description="Are you sure you want to start eviction for this tenant ?"
        title="Eviction"
        onClose={() => {
          setShowConfirmMoveOut(false);
        }}
        onConfirm={() => {
          setShowConfirmMoveOut(false);
          if (
            moment(new Date()).isAfter(new Date(selectedContract?.end_date))
          ) {
            onSelectMoveOutDate(new Date());
          } else {
            setShowSelectDateModal(true);
          }
        }}
      />

      <FileViewerModal
        show={showFile}
        file={file?.url}
        type={file?.type}
        onClose={() => {
          setShowFile(false);
        }}
      />

      <AddDateModal
        showModal={showSelectDateModal}
        onCancel={() => setShowSelectDateModal(false)}
        onSubmit={onSelectMoveOutDate}
        title="Eviction"
        descriptionHeader="Please keep in mind this is an irreversible action and cannot be revert back once triggered."
        descriptionHeaderClass="font_s font_yellow fw_5"
        maxDateValue={new Date(selectedContract?.end_date)}
      />
    </div>
  );
};
/* Component ends */

export default Contract;
