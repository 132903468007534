/**
 *
 */

import React, { useState } from "react";

/* Import configuration starts */
import moment from "moment";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import GLOBAL from "../../../setup/constants/global";
import FileViewerModal from "../../../components/ui/modal/FileViewerModal/FileViewerModal";
import Image from "../../../components/ui/other/Image/Image";
/* Import local pages and component ends */

/* Component starts */
const Report = (props) => {
  /* Props destructuring starts */
  const { incident } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [showFileViewer, setShowFileViewer] = useState(false);
  const [file, setFile] = useState({});
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */

  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  const getStatus = (status) => {
    let txt_status = status;
    let statusColor = "";
    switch (status) {
      case GLOBAL.SECURITY_STATUS.PENDING_SO_ASSIGNMENT:
      case GLOBAL.SECURITY_STATUS.PENDING_DECISION:
        statusColor = "font_yellow";
        txt_status = "Pending Decision";
        break;
      case GLOBAL.SECURITY_STATUS.ESCALATED:
        statusColor = "font_yellow";
        txt_status = "Escalated";
        break;
      case GLOBAL.SECURITY_STATUS.CLOSED:
        statusColor = "font_green";
        break;
      case GLOBAL.SECURITY_STATUS.SO_ASSIGNED:
        statusColor = "font_yellow";
        txt_status = "Security Officer Assigned";
        break;
      case GLOBAL.SECURITY_STATUS.RESOLVED:
        statusColor = "font_green";
        txt_status = "resolved";
        break;
    }
    return (
      <span className={`font_s fw_4 ${statusColor}`}>
        {txt_status?.toUpperCase()}
      </span>
    );
  };

  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="security-unit-detail-box">
      <h3 className="num fw_6 font_l font_grey">
        {" "}
        Ticket No.:{" "}
        <span className="fw_6 font_l font_blue">{incident?.ticket_id}</span>
      </h3>
      <hr />
      <p className="title font_m">
        Description : <br />
        <span className="font_m fw_6">{incident?.description}</span>
      </p>

      {/* <div className="last-update"> */}
      <p className="last-update font_grey font_s">
        Last Updated{" "}
        <span className="font_m fw_4">
          {moment(incident?.upadtedAt).format(DATE_TIME_FORMAT)}
        </span>
      </p>
      {/* </div> */}

      <hr />

      <div className="ticket-wrapper">
        <p>
          Status
          <span>{getStatus(incident?.status)}</span>
        </p>
      </div>

      <hr />

      <div className="ticket-wrapper">
        <p>
          Type
          <span>{incident?.security_complaint_id?.name}</span>
        </p>
      </div>
      <hr />

      {incident?.documents?.length > 0 && (
        <div className="image-wrapper">
          {incident?.documents.map((image, index) => (
            <div className="proof-img" key={index}>
              <Image
                key={index}
                className="media"
                imgClass="thumbnail"
                src={image?.url}
                videoThumbnail={image?.type2 === "video"}
                alt="Attatched Media"
                objectFit="cover"
                onClick={() => {
                  setFile(image);
                  setShowFileViewer(true);
                }}
              />
            </div>
          ))}
        </div>
      )}

      {/* Modal */}
      {/* File viewer modal */}
      <FileViewerModal
        show={showFileViewer}
        type={file.type}
        file={file.url}
        onClose={() => {
          setShowFileViewer(false);
          setFile({});
        }}
      />
    </div>
  );
};
/* Component Ends */
export default Report;
