/*
 * Slice to handle Annoucement Module
 */

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_URL } from "../../config/devKeys";
import { setLoading } from "./unpersistedSlice";
import axios from "axios";

const initialState = {
  annoucementsList: [],
  propertyAnnoucementsList: [],
  communityAnnoucementsList: [],
  usersAnnoucementsList: [],
  annoucement: null,
};

// ADD Annoucements -> Client - ( Home, Property_id, Community_id )
export const addAnnoucement = createAsyncThunk(
  "announcement/create",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));

      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        "Content-Type": "application/json",
      };
      const response = await fetch(
        API_URL + "/announcement/create",
        // "http://localhost:3000/announcement/create",
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(payload),
        }
      );
      const data = await response.json();
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));

      return rejectWithValue(error.message);
    }
  }
);

// FETCH ANNOUCEMENTS LIST - Client Side (HOME)
export const fetchAnnoucementsList = createAsyncThunk(
  "announcement_home/list",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(`${API_URL}/announcement/list`, {
        method: "POST",
        headers: headers,
      });

      const data = await response.json();

      dispatch(setLoading(false));

      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// FETCH ANNOUCEMENTS LIST - Client Side  (Property_ID)
export const fetchAnnoucementsListBasedProperty = createAsyncThunk(
  "announcement_property/list",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(`${API_URL}/announcement/list`, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          property_id: payload?.property_id,
          page: payload?.page,
        }),
      });

      const data = await response.json();
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// FETCH ANNOUCEMENTS LIST - Client Side  (Community_ID)
export const fetchAnnoucementsListBasedCommunity = createAsyncThunk(
  "announcement_community/list",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(API_URL + `/announcement/list`, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          community_id: payload?.community_id,
          page: payload?.page,
        }),
      });

      const data = await response.json();

      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// FETCH ANNOUCEMENT DATA BASED ON ID  - (ANNOUCEMENT_ID)
export const fetchAnnoucementById = createAsyncThunk(
  "announcement/get_annoucement_by_id",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(
        `${API_URL}/announcement/get_annoucement_by_id?id=${payload}`,
        {
          method: "GET",
          headers: headers,
        }
      );

      const data = await response.json();

      dispatch(setLoading(false));

      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// FETCH THE ANNOUCEMENT STATS BASED ON ID
export const fetchAnnouncementStatsById = createAsyncThunk(
  "announcement/get_stats",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.get(
        `${API_URL}/announcement/get_annoucement_statistics?id=` +
          payload.announcement_id,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      dispatch(setLoading(false));
      return data.data;
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// TENANT SIDE ANNOUCEMENTS
// FETCH ANNOUCEMENTS LIST - Tenant Side (Home)
export const fetchUsersAnnoucementsList = createAsyncThunk(
  "announcement/user_list",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(
        `${API_URL}/announcement/user_list?id=${payload?.property_id}`,

        // `http://localhost:3000/announcement/user_list?id=${payload?.property_id}`,
        {
          method: "GET",
          headers: headers,
        }
      );

      const data = await response.json();

      dispatch(setLoading(false));

      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// UPDATE IF ANNOUCEMENT OPENED By ID - Exclude { CLIENT } (ALL-TEAMS & TENANT)
export const updateOpenedAnnoucementByID = createAsyncThunk(
  "announcement/open_annoucement",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(
        `${API_URL}/announcement/open_annoucement?id=${payload}`,
        {
          method: "GET",
          headers: headers,
        }
      );

      const data = await response.json();

      dispatch(setLoading(false));

      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// DELETE ANNOUCEMENT DATA BASED ON ID  - (ANNOUCEMENT_ID)
export const deleteAnnoucement = createAsyncThunk(
  "announcement/delete",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.delete(
        API_URL + "/announcement/delete?id=" + payload._id,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user_token")}`,
          },
        }
      );
      if (data.success) {
        await dispatch(fetchAnnoucementsList());
        return data.data;
      } else {
        dispatch(setLoading(false));
        rejectWithValue(data.error);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const annoucementSlice = createSlice({
  name: "annoucement",
  initialState,
  extraReducers: {
    [fetchAnnoucementsList.rejected]: (state, action) => {
      state.annoucementsList = [];
    },
    [fetchAnnoucementsList.fulfilled]: (state, action) => {
      state.annoucementsList = action.payload;
    },
    [fetchAnnoucementsListBasedProperty.rejected]: (state, action) => {
      state.propertyAnnoucementsList = [];
    },
    [fetchAnnoucementsListBasedProperty.fulfilled]: (state, action) => {
      state.propertyAnnoucementsList = action.payload;
    },
    [fetchAnnoucementsListBasedCommunity.rejected]: (state, action) => {
      state.communityAnnoucementsList = [];
    },
    [fetchAnnoucementsListBasedCommunity.fulfilled]: (state, action) => {
      state.communityAnnoucementsList = action.payload;
    },
    [fetchUsersAnnoucementsList.rejected]: (state, action) => {
      state.usersAnnoucementsList = [];
    },
    [fetchUsersAnnoucementsList.fulfilled]: (state, action) => {
      state.usersAnnoucementsList = action.payload;
    },
    [fetchAnnoucementById.rejected]: (state, action) => {
      state.annoucement = null;
    },
    [fetchAnnoucementById.fulfilled]: (state, action) => {
      state.annoucement = action.payload;
    },
  },
});

// To Get The Annoucements List For Home
export const getAnnoucementsList = (state) =>
  state.annoucement.annoucementsList;

// To Get The Annoucements List For Property
export const getPropertyAnnoucementsList = (state) =>
  state.annoucement.propertyAnnoucementsList;

// To Get The Annoucements List For Community
export const getCommunityAnnoucementsList = (state) =>
  state.annoucement.communityAnnoucementsList;

// To Get The Annoucements List For Users
export const getUsersAnnoucementsList = (state) =>
  state.annoucement.usersAnnoucementsList;

// To Get The Annoucement Based On ID
export const getAnnoucementById = (state) => state.annoucement.annoucement;

export default annoucementSlice.reducer;
