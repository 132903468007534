/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  fetchTenantFoundItemList,
  fetchTenantLostItemList,
  getTennatFoundItemsList,
  getTennatLostItemsList,
} from "../../../setup/store/slices/lostAndFoundSlice";
import {
  fetchUnitDetailByID,
  getUnitDetail,
} from "../../../setup/store/slices/unitSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import MUI component starts */
/* Import MUI component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import Searchbar from "../../../components/ui/other/Searchbar/Searchbar";
import TenantLostTable from "../../../components/module/LostAndFound/TenantLostAndFoundModule/TenantLostTable/TenantLostTable";
import TabButton from "../../../components/ui/other/TabButton/TabButton";
import TenantFoundTable from "../../../components/module/LostAndFound/TenantLostAndFoundModule/TenantFoundTable/TenantFoundTable";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const TenantLostAndFound = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [currentKey, setCurrentKey] = useState("found");
  // const [table, setTable] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { lostAndFoundListTab, unitId } = useParams();

  console.log(lostAndFoundListTab, "TABBB");

  const navigate = useNavigate();
  const unit = useSelector(getUnitDetail);
  const foundItemsList = useSelector(getTennatFoundItemsList);
  const lostItemsList = useSelector(getTennatLostItemsList);

  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */

  const buttonList = [
    { key: "found", label: "Found" },
    { key: "lost", label: "Lost" },
  ];

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const onTabSelect = (key) => {
    // setCurrentKey(key);

    const pathnameArr = pathname.split("/");
    pathnameArr.pop();

    navigate(pathnameArr.join("/") + "/" + key);
  };

  /* Component function definition ends */

  /* Component useEffect starts */

  useEffect(() => {
    if (lostAndFoundListTab === "found") {
      dispatch(
        fetchTenantFoundItemList({
          property_id: unit?.property_id?._id,
          page: currentPage,
        })
      );
    } else {
      const payload = {
        unit_id: unit?._id,
        // tenant_id: unit?.tenant?._id,
        page: currentPage,
      };
      dispatch(fetchTenantLostItemList(payload));
    }
  }, [lostAndFoundListTab, currentPage]);

  useEffect(() => {
    if (lostAndFoundListTab) {
      setCurrentKey(lostAndFoundListTab);
    }
  }, [lostAndFoundListTab]);

  useEffect(() => {
    dispatch(fetchUnitDetailByID({ unit_id: unitId }));
  }, [unitId]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="found-wrapper">
      <div className="heading-wrapper">
        <div className="heading">
          <h2 className="font_xxl fw_5">Lost And Found</h2>
        </div>
        {currentKey === "lost" && (
          <div className="register-wrapper">
            <Link to="add" className="font_m fw_4 link-security">
              + Report Lost Item
            </Link>
          </div>
        )}
      </div>

      {/* <Searchbar /> */}
      {/* TABS BUTTON START   onSelect={onselect}*/}
      <TabButton
        buttonList={buttonList}
        currentKey={lostAndFoundListTab}
        onSelect={onTabSelect}
      />
      {/* TABS BUTTON END*/}

      {lostAndFoundListTab === "found" ? (
        <TenantFoundTable
          foundList={foundItemsList?.result}
          totalCount={foundItemsList?.totalCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      ) : (
        <TenantLostTable
          lostList={lostItemsList?.result}
          totalCount={lostItemsList?.totalCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
    </div>
  );
};
/* Component ends */

export default TenantLostAndFound;
