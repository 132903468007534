/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useDispatch } from "react-redux";
import GLOBAL from "../../../../../../setup/constants/global";
import { useNavigate } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  showSuccessAlert,
  showErrorAlert,
} from "../../../../../../setup/store/slices/globalAlertSlice";
import { updatePropertySettings } from "../../../../../../setup/store/slices/propertySlice";
import { uploadInitialAgreementTemplate } from "../../../../../../setup/store/slices/templateSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import {
  EditWhite,
  PlusBlue,
  EyeFilledBlue,
  EditGreen,
} from "../../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomButton from "../../../../../ui/button/CustomButton/CustomButton";
import IconButton from "../../../../../ui/button/IconButton/IconButton";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const InitialAgreementTemplate = (props) => {
  /* Props destructuring starts */
  const { user, settings, property_id } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [settingChanged, setSettingChanged] = useState(false);
  const [isAdded, setIsAdded] = useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const handleUpload = async (e) => {
    const document = e.target.files[0];
    // Creating or collecting payload data to be sent
    const payload = { property_id, document };
    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      const result = await dispatch(uploadInitialAgreementTemplate(payload));
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result?.payload || "",
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result?.payload?.message || "",
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    setIsAdded(settings.added_template_initial_agreement);
  }, [settings]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="tenant-id-count option template-row">
      {/* Label */}
      <div className="label">
        <p className="font_m fw_5">Initial Agreement</p>
      </div>

      {/* Setting */}
      <div className="setting">
        <div className="row1">
          {/* {!isAdded && ( */}
          <label>
            {!isAdded && (
              <img src={PlusBlue} className="icon v_middle" alt="" />
            )}

            <span className="font_blue font_m fw_6 v_middle">
              {!isAdded && "Add"}
              {isAdded && "Replace"}
            </span>

            <input
              type="file"
              name=""
              onChange={handleUpload}
              className="d-none"
              id=""
            />
          </label>
          {/* )} */}
          {isAdded && (
            <>
              <IconButton
                onClick={() => navigate("initial_agreement/template/edit")}
              >
                <img
                  src={EditGreen}
                  className="icon v_middle edit-template-icon"
                  alt=""
                />
              </IconButton>
            </>
          )}
        </div>

        <div className="row2"></div>
      </div>
    </div>
  );
};
/* Component ends */

export default InitialAgreementTemplate;
