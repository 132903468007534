/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import GLOBAL from "../../../setup/constants/global";
import moment from "moment";

import { useNavigate } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */

/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Table } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { EyeFilledBlue } from "../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import Pagination from "../../../components/ui/other/Pagination/Pagination";
import IconButton from "../../../components/ui/button/IconButton/IconButton";

/* Import local pages and component ends */

/* Component starts */
const UnitMaintenances = (props) => {
  /* Props destructuring starts */
  const {
    unitMaintenances,
    propertyId,
    communityId,
    totalCount,
    currentPage,
    setCurrentPage,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */

  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  const handleViewReport = async (item) => {
    if (communityId) {
      navigate(
        `/communities/${communityId}/properties/${propertyId}/maintenances/${item?.status}/${item?._id}`
      );
    } else {
      navigate(
        `/independent/properties/${propertyId}/maintenances/${item?.status}/${item?._id}`
      );
    }
  };

  // To Render Category
  const newCategoryRender = (item) => {
    const isInside = item?.location === "Inside My Unit";
    let category = "";

    category = isInside ? item?.category : item?.outercategory;

    return {
      category,
    };
  };

  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="unit-history-wrapper">
      {/* <h3 className="font_xl fw_6 text-center heading">Unit Maintenances</h3> */}

      <div>
        <div className="custom-table-wrapper unit-history-table">
          {unitMaintenances?.length > 0 && (
            <Table className="custom-table font_s" borderless responsive>
              {/* Table Heading */}
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Tenant Name</th>
                  <th>Location</th>
                  <th>Category</th>
                  <th>Ticket Creation Date</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              {/* Table Body */}
              <tbody>
                {unitMaintenances?.map((item, index) => (
                  <tr key={item._id}>
                    {/* ID */}
                    <td>{item?.request_id}</td>
                    {/* Tenant Name */}
                    <td>{item?.tenant !== null ? item?.tenant : "-"}</td>

                    {/* Location */}
                    <td>{item?.location}</td>

                    {/* Category */}
                    <td>{newCategoryRender(item).category}</td>

                    {/* Start Date */}
                    <td>{moment(item?.createdAt).format(DATE_TIME_FORMAT)}</td>

                    {/* Status */}
                    <td
                      className={
                        item?.status === "New"
                          ? "font_blue"
                          : item?.status === "Closed" || item?.status === 'Rejected'
                          ? "font_red"
                          : "font_yellow"
                      }
                    >
                      {item?.status?.charAt(0).toUpperCase() +
                        item?.status?.slice(1)}
                    </td>

                    {/* Actions */}

                    <td className="text-start txt-nowrap">
                      <IconButton
                        onClick={() => handleViewReport(item)}
                        className="action-button"
                      >
                        <img
                          src={EyeFilledBlue}
                          className="h_100"
                          alt="View Details"
                        />
                      </IconButton>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}

          {unitMaintenances?.length === 0 && (
            <div className=" text-center">
              <h3 className=" font_m font_yellow fw_6">No Data</h3>
            </div>
          )}
        </div>

        {unitMaintenances?.length > 0 && (
          <Pagination
            itemsPerPage="10"
            totalItems={totalCount}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        )}
      </div>
    </div>
  );
};
/* Component ends */

export default UnitMaintenances;
