/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useDispatch } from "react-redux";
import GLOBAL from "../../../../../setup/constants/global";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  showSuccessAlert,
  showErrorAlert,
} from "../../../../../setup/store/slices/globalAlertSlice";
import { updatePropertySettings } from "../../../../../setup/store/slices/propertySlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import {
  DocFilledWhite,
  DocumentGreen,
} from "../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomButton from "../../../../ui/button/CustomButton/CustomButton";
import IconButton from "../../../../ui/button/IconButton/IconButton";
import TextEditorModal from "../../../../ui/modal/TextEditorModal/TextEditorModal";
/* Import local pages and component ends */

/* Component starts */
const PropertyGroundRules = (props) => {
  /* Props destructuring starts */
  const { user, settings, property_id } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [settingChanged, setSettingChanged] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [descVal, setDescVal] = useState({ value: "" });
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  // On updating setting
  const onUpdate = async (v) => {
    // Creating or collecting payload data to be sent
    const payload = { ground_rules: v, property_id };
    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      const result = await dispatch(updatePropertySettings(payload));
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result?.payload || "",
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result?.payload?.message || "",
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    const value = {
      value: settings?.ground_rules ? settings.ground_rules : "",
    };
    setDescVal({ ...value });
  }, [settings]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="tenant-id-count option">
      {/* Label */}
      <div className="label">
        <p className="font_m fw_5">Property Ground Rules</p>
      </div>

      {/* Setting */}
      <div className="setting">
        <div className="row1">
          <IconButton onClick={() => setShowModal(true)}>
            <img src={DocumentGreen} className="icon" alt="" />
          </IconButton>
        </div>

        <div className="row2"></div>

        {/* Save Button */}
        {settingChanged && (
          <div className="save-btn-wrapper mt_2">
            <CustomButton onClick={onUpdate} className="save-btn" size="s">
              Save
            </CustomButton>
          </div>
        )}
      </div>

      <TextEditorModal
        descVal={descVal}
        setDescVal={setDescVal}
        showModal={showModal}
        onSuccess={onUpdate}
        title="Property Ground Rules"
        onClose={() => setShowModal(false)}
      />
    </div>
  );
};
/* Component ends */

export default PropertyGroundRules;
