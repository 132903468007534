/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  fetchPropertyInvoiceById,
  getPropertyInvoice,
  updtePropertyInvCheque,
} from "../../../../../../setup/store/slices/propertyInvoiceSlice";
import {
  showErrorAlert,
  showSuccessAlert,
} from "../../../../../../setup/store/slices/globalAlertSlice";
import {
  getIsACC,
  getIsOwner,
} from "../../../../../../setup/store/slices/authSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Table } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import {
  DocumentGreen,
  ExcalmatoryYellow,
  EyeFilledBlue,
  LocationPinFilledGrey,
} from "../../../../../../setup/constants/images";

/* Import image and SVG ends */

/* Import local pages and component starts */
import GLOBAL from "../../../../../../setup/constants/global";
import FileViewerModal from "../../../../../ui/modal/FileViewerModal/FileViewerModal";
import IconButton from "../../../../../ui/button/IconButton/IconButton";
import InvoiceDetailModal from "../InvoiceDetailModal/InvoiceDetailModal";
import ChecqueStatusChangeModal from "../../../ChecqueStatusChangeModal/ChecqueStatusChangeModal";
/* Import local pages and component ends */

/* Component starts */
const PropertyInvoiceDetail = (props) => {
  /* Props destructuring starts */
  const { invoiceID, currency, settings } = props;

  /* Props destructuring ends */

  /* Component states and useRef declaration starts */

  const [showContractDetailModal, setShowContractDetailModal] = useState(false);
  const [detail, setDetail] = useState({});

  const [showFileViewer, setShowFileViewer] = useState(false);
  const [file, setFile] = useState({});

  const [updateCheque, setUpdateCheque] = useState(false);
  const [selectedInv, setSelectedInv] = useState({});
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */

  const dispatch = useDispatch();
  const propertyInvoice = useSelector(getPropertyInvoice);

  const isAccountant = useSelector(getIsACC);
  const isOwner = useSelector(getIsOwner);

  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  const getStatus = (status) => {
    let txt_status = status;
    let statusColor = "";
    switch (status) {
      case "pending_clearance":
        statusColor = "font_yellow";
        txt_status = "Pending Clearance";
        break;
      case "bounced":
        statusColor = "font_yellow";
        txt_status = "Bounced";
        break;
      case "succeeded":
        statusColor = "font_green";
        txt_status = "Success";
        break;
      // case GLOBAL.SECURITY_STATUS.SO_ASSIGNED:
      //   statusColor = "font_yellow";
      //   txt_status = "Security Officer Assigned";
      //   break;
      // case GLOBAL.SECURITY_STATUS.RESOLVED:
      //   statusColor = "font_green";
      //   txt_status = "resolved";
      //   break;
    }
    return <span className={`font_s fw_4 ${statusColor}`}>{txt_status}</span>;
  };

  const handleView = (item) => {
    setDetail(item);
    setShowContractDetailModal(!showContractDetailModal);
  };

  const onChequeStatusChange = async (status, reason) => {
    // Creating or collecting payload data to be sent
    let payload = {};

    if (status?.selectedOption === "replace") {
      payload = {
        invoice_id: selectedInv?.invoice_id,
        transaction_id: selectedInv?._id,
        status: status?.selectedOption,
        cheque_reason: status?.note,
        cheque_number: status?.cheque_num,
        payment_amount: parseInt(status?.cheque_amt),
        cheque_date: status?.recpdate,
        bank_name: status?.bank_name,
        issuer: status?.issuer_name,
        invoice_doc: status?.receipt?.current,
        account_number: status?.account_number,
      };
    } else {
      payload = {
        invoice_id: selectedInv?.invoice_id,
        transaction_id: selectedInv?._id,
        status: status,
        cheque_reason: reason,
      };
    }

    // Backend response. Try, Catch
    try {
      const result = await dispatch(updtePropertyInvCheque(payload));
      await dispatch(fetchPropertyInvoiceById(invoiceID));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload.error.message,
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    if (invoiceID) {
      dispatch(fetchPropertyInvoiceById(invoiceID));
    }
  }, [invoiceID]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      <div className="property-detail-card ">
        <div className="d-flex justify-content-between">
          <p>
            Invoice#{" "}
            <span className="font_blue fw_6">
              {propertyInvoice?.invoice?.invoice_num}
            </span>
            {/* {(propertyInvoice?.invoice.status === "paid" ||
            propertyInvoice?.invoice?.status === "collected" ||
            propertyInvoice?.invoice?.status === "partially_settled") && (
            <p
              className="font_m fw_6 font_blue refund_text"
              onClick={() => setShowRefundModal(true)}
            >
              Refund
            </p>
          )} */}
          </p>

          <p className="font_m">
            Type :{" "}
            <span className="font_yellow fw_6">
              {propertyInvoice?.invoice?.type}
            </span>
          </p>
        </div>

        <hr />

        <div className="property-wrapper">
          <div className="property-data">
            <div>
              {/* <span className="font_m fw_6">{state?.property}</span> */}
              <span className="font_m fw_6">
                {propertyInvoice?.invoice?.property?.property_name}
              </span>
            </div>
          </div>

          <div className="property-data">
            <div>
              <img src={LocationPinFilledGrey} alt="Location-Pin" />
              <span className="font_s fw_4 font_grey">
                {propertyInvoice?.invoice?.property?.address_line1},{" "}
                {propertyInvoice?.invoice?.property?.city},
                {propertyInvoice?.property?.state},
                {propertyInvoice?.invoice?.property?.country}{" "}
                {propertyInvoice?.invoice?.property?.zipcode !== undefined
                  ? `- ${propertyInvoice?.invoice?.property?.zipcode}`
                  : ""}
              </span>
            </div>
          </div>
        </div>

        <hr />

        <div className="invoice-data-price">
          <p className="font_m fw_4 font_grey">
            Description <br />
            <span className="font_m fw_4">
              {propertyInvoice?.invoice?.description
                ? propertyInvoice?.invoice?.description
                : "-"}
            </span>
          </p>

          <p className="font_m fw_4 font_grey">
            Quantity <br />
            <span className="font_m fw_4">
              {propertyInvoice?.invoice?.quantity}
            </span>
          </p>

          <div>
            <p className="font_m fw_4 font_grey">
              Invoice Amount <br />
            </p>
            <p>
              <span className="font_s fw_4">
                Amount : {propertyInvoice?.invoice?.amount}
              </span>
              <br />
              <span className="font_s fw_4">
                VAT({settings?.collect_vat_value}):{" "}
                {propertyInvoice?.invoice?.vat_amount?.toFixed(2)}
              </span>
              <br />
              <span className="font_s fw_4">
                Tax({settings?.collect_tax_value}):{" "}
                {propertyInvoice?.invoice?.tax_amount?.toFixed(2)}
              </span>
              <hr />
              <span className="font_s fw_4">
                Total :{" "}
                <span className="font_m fw_6 font_blue">
                  {propertyInvoice?.invoice?.total !== undefined
                    ? `${propertyInvoice?.invoice?.total?.toLocaleString(
                        "en-US"
                      )}${" "}${currency}`
                    : "-"}
                </span>
              </span>
            </p>
          </div>
        </div>

        <hr />

        <div className="invoice-data">
          <p className="font_m fw_4 font_grey">
            Service Name
            <br />
            <span className="font_m fw_4">
              {propertyInvoice?.invoice?.service_name
                ? propertyInvoice?.invoice?.service_name
                : "-"}
            </span>
          </p>

          <p className="font_m fw_4 font_grey">
            Invoice Due Date <br />
            <span className="font_m fw_4">
              {moment(propertyInvoice?.invoice?.due_date).format(DATE_FORMAT)}
            </span>
          </p>

          <p className="font_m fw_4 font_grey">
            Payment Date <br />
            <span className="font_m fw_4">
              {propertyInvoice?.invoice?.updatedAt &&
              (propertyInvoice?.invoice?.status === "Paid" ||
                propertyInvoice?.invoice?.status === "Partial Paid")
                ? `${moment(propertyInvoice?.invoice?.updatedAt).format(
                    DATE_TIME_FORMAT
                  )}`
                : "-"}
            </span>
          </p>
        </div>

        <hr />

        <div className="status-wrapper">
          <p className="font_m fw_4">Status</p>
          <p
            className={`font_m fw_4
        ${
          propertyInvoice?.invoice?.status === "Paid"
            ? "font_green"
            : propertyInvoice?.invoice?.status === "Unpaid"
            ? "font_yellow"
            : "font_red"
        }
        `}
          >
            {propertyInvoice?.invoice?.status}
          </p>
        </div>

        <hr />

        <div className="doc-wrapper">
          <p className="font_m fw_4">Documents</p>

          {propertyInvoice?.invoice_docs && (
            <>
              {propertyInvoice?.invoice_docs?.map((doc) => (
                <div className="doc" key={doc?._id}>
                  <img
                    src={DocumentGreen}
                    onClick={() => {
                      setFile(doc?.document);
                      setShowFileViewer(true);
                    }}
                  />
                  {/* {" "}
                  Untitled 1 -{" "}
                  <span className="font_s fw_4 font_blue text-decoration-underline">
                    Download
                  </span> */}
                </div>
              ))}
            </>
          )}
        </div>

        <hr />

        <div className="payment-data">
          <p className="font_l fw_4">Payment Details</p>

          {propertyInvoice?.invoice?.invoice_transaction?.length > 0 ? (
            <div className="trans-table">
              <Table className="custom-table font_s" borderless responsive>
                {/* Table Heading */}
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Amount</th>
                    <th>Method</th>
                    <th>Date</th>
                    {/* <th>Received By</th> */}
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                {/* Table Body */}
                <tbody>
                  {propertyInvoice?.invoice?.invoice_transaction?.map(
                    (trans, idx) => (
                      <tr key={trans._id}>
                        {/* ID */}
                        <td>{idx + 1}</td>

                        {/* Payment Amount */}
                        <td>
                          {trans?.associated_invoices?.length > 0
                            ? propertyInvoice?.invoice?.amount?.toLocaleString(
                                "en-US"
                              )
                            : trans?.payment_amount?.toLocaleString("en-US") ||
                              trans?.cheque_amount?.toLocaleString("en-US") ||
                              trans?.total_amount?.toLocaleString("en-US")}{" "}
                          {currency}
                        </td>

                        {/* Payment Method */}
                        <td className="font_blue">
                          {trans?.payed_via?.charAt(0).toUpperCase() +
                            trans?.payed_via?.slice(1)}
                        </td>

                        {/* Date */}
                        <td>
                          {moment(trans?.createdAt).format(DATE_TIME_FORMAT)}
                        </td>

                        <td>{getStatus(trans?.status)}</td>

                        {/* Actions */}
                        <td>
                          <IconButton
                            className="action-button"
                            onClick={() =>
                              handleView(
                                trans,
                                propertyInvoice?.invoice?.tenant
                              )
                            }
                          >
                            <img src={EyeFilledBlue} className="h_100" />
                          </IconButton>

                          {((isOwner &&
                            propertyInvoice?.invoice?.property?.mgmt_model ===
                              0) ||
                            (isAccountant &&
                              propertyInvoice?.invoice?.property?.mgmt_model ===
                                2)) &&
                            propertyInvoice?.invoice?.status !== "paid" &&
                            trans?.status === "pending_clearance" && (
                              <IconButton
                                className="action-button"
                                onClick={() => {
                                  setSelectedInv(trans);
                                  setDetail(trans);
                                  setUpdateCheque(true);
                                }}
                              >
                                <img
                                  src={ExcalmatoryYellow}
                                  className="h_100"
                                />
                              </IconButton>
                            )}
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </Table>
            </div>
          ) : (
            <div className="pay-data text-center">
              <h3 className=" font_m font_yellow fw_6">No Payment Details</h3>
            </div>
          )}
        </div>

        <InvoiceDetailModal
          type="Contract"
          showModal={showContractDetailModal}
          detail={detail}
          onClose={() => {
            setShowContractDetailModal(false);
          }}
        />

        <ChecqueStatusChangeModal
          showModal={updateCheque}
          onClose={() => setUpdateCheque(false)}
          onSubmit={onChequeStatusChange}
          detail={detail}
        />

        {/* File viewer modal */}
        <FileViewerModal
          show={showFileViewer}
          type={file.type}
          file={file.url}
          onClose={() => {
            setShowFileViewer(false);
            setFile({});
          }}
        />
      </div>
    </>
  );
};
/* Component ends */

export default PropertyInvoiceDetail;
