/**
 *
 */

import React, { useState, useEffect, useRef } from "react";

/* Import configuration starts */
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  vehicleValidation,
  vehicleFormPrefill,
  setFormList,
  isDataChanged,
  isApproved,
} from "./helper";
import GLOBAL from "../../../../setup/constants/global";
/* Import configuration ends */

/* Import redux slices component starts */
import { showErrorAlert } from "../../../../setup/store/slices/globalAlertSlice";
import {
  clearMasterData,
  fetchVehicleMake,
  fetchVehicleModel,
} from "../../../../setup/store/slices/masterSlice";
import { updateTenantApplication } from "../../../../setup/store/slices/tenantSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomNumberInput from "../../../ui/input/CustomNumberInput/CustomNumberInput";
import TextButton from "../../../ui/button/TextButton/TextButton";
import CustomTextInput from "../../../ui/input/CustomTextInput/CustomTextInput";
import CustomSelectInput from "../../../ui/input/CustomSelectInput/CustomSelectInput";
import CustomFileInput from "../../../ui/input/CustomFileInput/CustomFileInput";
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
import SuccessModal from "../../../ui/modal/SuccessModal/SuccessModal";
import ErrorModal from "../../../ui/modal/ErrorModal/ErrorModal";
/* Import local pages and component ends */

/* Component starts */
const VehiclesForm = (props) => {
  /* Props destructuring starts */
  // states
  const {
    vehicleMakeList,
    vehicleModelList,
    data,
    applicationId,
    maxAllowed,
    fetchApplication = null,
    canCancelApplication = false,
    validateDataUpdated = true,
  } = props;

  // functions
  const {
    onNext,
    update,
    fetchVehicleMake,
    fetchVehicleModel,
    clearMasterData,
    onCancelStep = null,
    onCancelApplication,
    onUpdatingInfo = null,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [initialFormValues, setInitialFormValues] = useState({});
  const [vehicleModelData, setVehicleModelData] = useState([]);

  const [message, setMessage] = useState("");
  const [showErrModal, setShowErrModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [count, setCount] = useState("");
  const [formCountList, setFormCountList] = useState([]);
  const [counterChanged, setCounterChanged] = useState(false);

  // useRef
  const formRef = useRef();
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatchHook = useDispatch();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // On selecting vehicle make
  const onMakeSelected = async (value, i) => {
    try {
      const result = await fetchVehicleModel(value?._id);

      setVehicleModelData((prev) => {
        const arr = [...prev];
        arr[i] = result?.payload;
        return arr;
      });

      formRef.current.setFieldValue(`model_${i}`, "");
    } catch (error) {
      console.log(error);
    }
  };

  // On change count
  const handleCountChange = async (e) => {
    const value = e.target.value;
    const intValue = value !== "" ? parseInt(value) : 0;

    // Checking for maximum member allowed
    if (!isNaN(intValue) && intValue > maxAllowed) {
      setMessage(`Maximum vehicle allowed is ${maxAllowed}`);
      setShowErrModal(true);
      return;
    }
    setCount(value);
    setCounterChanged(true);
    setFormList(
      formCountList,
      setFormCountList,
      intValue,
      formRef,
      setInitialFormValues,
      vehicleModelData,
      setVehicleModelData
    );
  };

  // Getting or Processing action error note from PM
  const getActionError = (d) => {
    if (d?.actions?.length > 0) {
      let lastAction = d["actions"][d["actions"]?.length - 1];
      if (
        lastAction.status === GLOBAL.TENANT_APPLICATION_STATUS.DATA_REJECTED
      ) {
        return true;
      }
    }
    return false;
  };

  // Form validations
  const formValidation = (values) => {
    return vehicleValidation(values, formCountList);
  };

  // On updating vehicles
  const onSubmit = async (values) => {
    if (formCountList?.length === 0 && data?.length === 0) {
      onNext();
      return;
    }

    if (
      validateDataUpdated &&
      !counterChanged &&
      !isDataChanged(values, data, formCountList)
    ) {
      dispatchHook(
        showErrorAlert({
          title: "Error",
          description: "Please update the form",
        })
      );
      return;
    }

    // Creating or collecting payload data to be sent
    const payload = {
      applicationId,
      type: "vehicles",
      vehicles: [],
    };

    formCountList?.forEach((i, indx) => {
      if (!isApproved(values[`status_${i}`])) {
        const detail = {};
        detail.make = values[`make_${i}`];
        detail.model = values[`model_${i}`];
        detail.color = values[`color_${i}`];
        detail.plate_number = values[`plate_number_${i}`];
        detail.plate_issued_from = values[`plate_issued_from_${i}`];

        if (indx < data.length) {
          detail._id = data[indx]._id;
        }

        if (values[`image_${i}`] && values[`image_${i}`] instanceof File) {
          payload[`img_${indx}`] = values[`image_${i}`];
        }

        payload.vehicles.push(detail);
      }
    });
    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      const result = await update(payload);
      console.log(result);
      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setMessage(result.payload);
          setShowErrModal(true);
          break;
        case GLOBAL.FULFILLED:
          onUpdatingInfo && onUpdatingInfo();
          fetchApplication && fetchApplication();
          setMessage(result.payload.message);
          setShowSuccessModal(true);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  // Fetching necessary data on initial load
  useEffect(() => {
    fetchVehicleMake();
  }, []);

  // Setting selected vehicle make on load of vehicle make list data
  useEffect(() => {
    if (data?.length > 0 && vehicleMakeList?.length > 0) {
      setCount(parseInt(data?.length));
      vehicleFormPrefill(
        data,
        setInitialFormValues,
        setFormCountList,
        setVehicleModelData,
        vehicleMakeList,
        fetchVehicleModel
      );
    }
  }, [data, vehicleMakeList]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="vehicles container_sm">
      <h2 className="heading text-center font_xxl mb-5">Vehicles</h2>
      <Formik
        onSubmit={onSubmit}
        initialValues={initialFormValues}
        validate={formValidation}
        validateOnBlur={true}
        enableReinitialize={true}
        innerRef={formRef}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="form-wrapper">
              {/* Vehicle List Counter */}
              <div className="list-counter">
                <CustomNumberInput
                  label="Number of Vehicles"
                  handleChange={(e) => handleCountChange(e)}
                  value={count}
                  required={false}
                />
              </div>

              {/* Maximum vehicle allowed */}
              <p className="fw_5 font_s font_yellow max-allowed">
                Maximum vehicle allowed is {maxAllowed}
              </p>

              {/* If no vehicles added */}
              {formCountList.length === 0 && (
                <p className="font_m fw_5 text-center">No vehicles added !</p>
              )}

              {/* Vehicles form List */}
              {formCountList?.map((item, index) => (
                <div key={index} className="form-section-group">
                  {/* Member Count Title */}
                  <h3 className="title-bubble font_blue font_m fw_6 w_100">
                    Vehicle #{index + 1}
                    {isApproved(values[`status_${item}`]) && (
                      <p className="font_m fw_5 already-approved-warning">
                        <span className="font_yellow">
                          All fields are disabled, as this information is
                          already
                        </span>{" "}
                        <span className="font_green">Approved</span>
                      </p>
                    )}
                  </h3>

                  {/* Require action */}
                  {getActionError(data[index]) && (
                    <p className="w_100 font_red font_s fw_5">
                      {
                        data[index]?.actions[data[index]?.actions?.length - 1]
                          .note
                      }
                    </p>
                  )}

                  {/* Form Fields */}
                  {/* Input for Vehicle Make */}
                  <div className="form-group">
                    <CustomSelectInput
                      label="Vehicle Make"
                      name={`make_${item}`}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      customOnchange={(v) => onMakeSelected(v, index)}
                      options={vehicleMakeList}
                      value={values[`make_${item}`]}
                      errors={errors[`make_${item}`]}
                      touched={touched[`make_${item}`]}
                      valueExtractor={(d) => d?.name}
                      disabled={isApproved(values[`status_${item}`])}
                    />
                  </div>

                  {/* Input for Vehicle Model */}
                  <div className="form-group">
                    <CustomSelectInput
                      label="Vehicle Model"
                      name={`model_${item}`}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      options={vehicleModelData[index]}
                      value={values[`model_${item}`]}
                      errors={errors[`model_${item}`]}
                      touched={touched[`model_${item}`]}
                      valueExtractor={(d) => d?.name}
                      disabled={isApproved(values[`status_${item}`])}
                    />
                  </div>

                  {/* Input for Color */}
                  <div className="form-group">
                    <CustomTextInput
                      name={`color_${item}`}
                      label="Vehicle Color"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched[`color_${item}`]}
                      errors={errors[`color_${item}`]}
                      value={values[`color_${item}`]}
                      required={true}
                      readOnly={isApproved(values[`status_${item}`])}
                    />
                  </div>

                  {/* Input for Plate Number */}
                  <div className="form-group">
                    <CustomTextInput
                      name={`plate_number_${item}`}
                      label="Number Plate"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched[`plate_number_${item}`]}
                      errors={errors[`plate_number_${item}`]}
                      value={values[`plate_number_${item}`]}
                      required={true}
                      readOnly={isApproved(values[`status_${item}`])}
                    />
                  </div>

                  {/* Input for Plate Issued From */}
                  <div className="form-group">
                    <CustomTextInput
                      name={`plate_issued_from_${item}`}
                      label="Plate Issued From"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched[`plate_issued_from_${item}`]}
                      errors={errors[`plate_issued_from_${item}`]}
                      value={values[`plate_issued_from_${item}`]}
                      required={true}
                      readOnly={isApproved(values[`status_${item}`])}
                    />
                  </div>

                  {/* Input for Vehicle Photo */}
                  <div className="form-group">
                    <CustomFileInput
                      name={`image_${item}`}
                      label="Vehicle Image"
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      formikState={true}
                      touched={touched[`image_${item}`]}
                      errors={errors[`image_${item}`]}
                      value={values[`image_${item}`]}
                      required={true}
                      disabled={isApproved(values[`status_${item}`])}
                    />
                  </div>
                </div>
              ))}
            </div>

            <div className="button-wrapper text-center">
              <CustomButton type="submit" size="l">
                Next
              </CustomButton>

              {onCancelStep && (
                <TextButton onClick={onCancelStep} className="font_m fw_6">
                  Back
                </TextButton>
              )}
            </div>
          </form>
        )}
      </Formik>

      {canCancelApplication && (
        <div className="cancel-application-btn-wrapper mt-4">
          <CustomButton
            className="cancel-application-btn"
            variant="red"
            size="l"
            onClick={onCancelApplication}
          >
            Cancel Application
          </CustomButton>
        </div>

        // <div className="cancel-application-wrapper bg_grey">
        //   <p className="font_m font_yellow fw_5">
        //     I would like to cancel the onboarding application process. I am
        //     aware that after cancelling the application I won't be able to undo
        //     my action.
        //   </p>
        //   <div className="cancel-application-btn-wrapper">
        //     <CustomButton
        //       className="cancel-application-btn"
        //       variant="red"
        //       size="m"
        //       onClick={onCancelApplication}
        //     >
        //       Cancel Application
        //     </CustomButton>
        //   </div>
        // </div>
      )}

      {/* Modals */}
      {/* Success Modal */}
      <SuccessModal
        showModal={showSuccessModal}
        description={message}
        title="Success"
        onClose={() => onNext()}
      />

      {/* Error Modal */}
      <ErrorModal
        showModal={showErrModal}
        description={message}
        title="Error"
        onClose={() => setShowErrModal(false)}
      />
    </div>
  );
};
/* Component ends */

const mapStateToProps = (state) => {
  return {
    vehicleMakeList: state.master.vehicleMakeList,
    vehicleModelList: state.master.vehicleModelList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchVehicleMake: bindActionCreators(fetchVehicleMake, dispatch),
    fetchVehicleModel: bindActionCreators(fetchVehicleModel, dispatch),
    update: bindActionCreators(updateTenantApplication, dispatch),
    clearMasterData: bindActionCreators(clearMasterData, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VehiclesForm);
