/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  fetchInternalMaintenanceById,
  getInternalMaintenance,
} from "../../setup/store/slices/internalMaintenanceSlice";
import {
  fetchPropertyDetailByID,
  getPropertyDetail,
} from "../../setup/store/slices/propertySlice";
import { getUser } from "../../setup/store/slices/authSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import InternalMaintenanceDetails from "../../components/module/InternalMaintenance/InternalMaintenanceDetails/InternalMaintenanceDetails";
import BreadCrumb from "../../components/ui/other/BreadCrumb/BreadCrumb";
/* Import local pages and component ends */

/* Component starts */
const InternalMaintenanceDetailsPage = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const { propertyId, maintenanceId } = useParams();
  const dispatch = useDispatch();
  const maintenance = useSelector(getInternalMaintenance);
  const property = useSelector(getPropertyDetail);
  const user = useSelector(getUser);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const mgmtModel = maintenance?.property_id?.mgmt_model;
  const currency = maintenance?.property_id?.currency;
  const isT1 = mgmtModel === 0;
  const isT2 = mgmtModel === 1;
  const isT3 = mgmtModel === 2;
  const status = maintenance?.status;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const getMaintenanceById = async () => {
    await dispatch(
      fetchInternalMaintenanceById({ maintenance_id: maintenanceId })
    );
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    getMaintenanceById();
  }, []);

  useEffect(() => {
    dispatch(fetchPropertyDetailByID({ property_id: propertyId }));
  }, []);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="">
      <BreadCrumb type="inter-main-details" />
      <InternalMaintenanceDetails
        isT1={isT1}
        isT2={isT2}
        isT3={isT3}
        mgmtModel={mgmtModel}
        maintenance={maintenance}
        status={status}
        maintenance_id={maintenanceId}
        user={user}
        currency={currency}
        property={property}
      />
    </div>
  );
};
/* Component ends */

export default InternalMaintenanceDetailsPage;
