/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

import "./style.scss";

/* Import local pages and component starts */
import {
  StarYellowFilled,
  StarYellowOutline,
} from "../../../../setup/constants/images";
/* Import local pages and component ends */

/* Component starts */
const Rating = (props) => {
  /* Props destructuring starts */
  const { rating = 0, setRating, upto = 5 } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [renderCount, setRenderCount] = useState(null);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const onRate = (item) => {
    setRating(item);
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    const countArr = [];
    for (let i = 1; i <= upto; i++) {
      countArr.push(i);
    }
    setRenderCount(countArr);
  }, [upto]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="rating-ui-wrapper">
      {renderCount &&
        renderCount.map((item, index) => (
          <i className="star-icon" key={index} onClick={() => onRate(item)}>
            {item <= rating ? (
              <img src={StarYellowFilled} alt={`${item}`} />
            ) : (
              <img src={StarYellowOutline} alt={`O`} />
            )}
          </i>
        ))}
    </div>
  );
};
/* Component ends */

export default Rating;
