/**
 *
 */

import React, { useState, useEffect } from "react";
import {
  customDateFormat,
  customMoment,
  pathNamePopped,
} from "../../../../../setup/utils/global-helper";
import moment from "moment";
import { Table } from "react-bootstrap";
import CustomCurrencyInput from "../../../../ui/input/CustomCurrencyInput/CustomCurrencyInput";
import CustomDateInput from "../../../../ui/input/CustomDateInput/CustomDateInput";
import GLOBAL from "../../../../../setup/constants/global";
import { fetchPropertySettings } from "../../../../../setup/store/slices/propertySlice";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { showErrorAlert } from "../../../../../setup/store/slices/globalAlertSlice";
import { EyeFilledBlue } from "../../../../../setup/constants/images";
import IconButton from "../../../../ui/button/IconButton/IconButton";

/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
/* Import local pages and component ends */

/* Component starts */
const RentInstallmentDetail = (props) => {
  /* Props destructuring starts */
  const {
    data = [],
    canEdit,
    application_id,
    instalmentPlan,
    setShowSetInstallment,
    currency,
    canAttachCheque,
    onUpdateChequeCallback,
    index,
    depositData,
    canSend,
    getApplication,
    manuallyFetchPropertySettings = true,
    manuallyFetchPropertyData = true,
    property_settings = null,
    property_data = null,
    isRenew = false,
    isOld = false,
    property,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [settings, setSettings] = useState(null);
  const [thresholdAmt, setThresholdAmt] = useState(0);
  const [totalAmt, setTotalAmt] = useState({ errorAmt: false, amount: 0 });
  const [newInstallment, setNewInstallment] = useState([]);
  const [newDueDates, setNewDueDates] = useState([]);
  const [dataChanged, setDataChanged] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { propertyId } = useParams();
  const { pathname } = useLocation();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const deposit = instalmentPlan?.deposit;
  const depositAmt = instalmentPlan?.deposit_amount?.toLocaleString("en-US");
  const commission = instalmentPlan?.commission;
  const commissionAmt =
    instalmentPlan?.commission_amount?.toLocaleString("en-US");
  const duration = instalmentPlan?.duration;
  const noOfInstallments = instalmentPlan?.no_of_installments;
  const startDate = instalmentPlan?.start_date;
  const endDate = instalmentPlan?.end_date;
  const actionDate = instalmentPlan?.updatedAt;
  const totalRent = instalmentPlan?.total_rent;

  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const getTotal = (amt, collectVat, collectTax) => {
    let amount = amt;
    if (collectVat && settings?.collect_vat_enabled) {
      amount += (amt * settings?.collect_vat_value) / 100;
    }
    if (collectTax && settings?.collect_tax_enabled) {
      amount += (amt * settings?.collect_tax_value) / 100;
    }
    // return `${amount?.toLocaleString("en-US")}`;
    //?.toLocaleString('en-US')
    return `${amount?.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`;
  };

  // On fetching property settings
  const handleFetchPropertySettings = async () => {
    // Backend response. Try, Catch
    try {
      const result = await dispatch(
        fetchPropertySettings({ property_id: propertyId })
      );
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description:
                typeof result?.payload === "string" ? result?.payload : "",
            })
          );
          setSettings(null);
          break;

        case GLOBAL.FULFILLED:
          setSettings(result.payload);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  const handleView = (item) => {
    const newPathname = pathNamePopped(pathname, 5);
    console.log(newPathname, "[PATh]");
    navigate(`invoicing/Tenant/rent/${item?.tenant_billing_invoice}`, {
      state: { pathname: pathname },
    });
  };

  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    if (manuallyFetchPropertySettings) {
      handleFetchPropertySettings();
    }
  }, []);

  // Calculating/Adding all installments amount to get the total installment amount
  useEffect(() => {
    if (data.length > 0 && instalmentPlan) {
      const installments = [];
      const dates = [];
      const totalAmountValue = parseInt(
        data.reduce((acc, curr) => acc + curr.amount, 0)
      );

      data.forEach((item) => {
        installments.push(item.amount + "");
        dates.push(customDateFormat(item.due_date, property?.offset));
      });

      setThresholdAmt(totalRent);
      setTotalAmt((prevState) => ({
        errorAmt: totalRent !== totalAmountValue,
        amount: totalAmountValue,
      }));
      setDataChanged(totalRent !== totalAmountValue);
      setNewInstallment(installments);
      setNewDueDates(dates);
    }
  }, [data, instalmentPlan]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="section container_md installmentDetail">
      <h3 className="table-heading font_m">Rent Installment Details</h3>

      <div className="custom-table-wrapper">
        {/* Top Options */}
        <div className="top-options"></div>
        {/* More Details */}
        <div className="more-details-edit">
          <div className="more-details">
            <div className="more-data">
              {/* Total Rent */}
              <div className="c-row">
                <p className="font_xs fw_5 key font_grey">Total Rent</p>
                <p className="font_xs fw_5 value">
                  {totalRent?.toLocaleString("en-US")} ({currency})
                </p>
              </div>
              {/* Deposit Amount */}
              {deposit && (
                <div className="c-row">
                  <p className="font_xs fw_5 key font_grey">
                    Deposit ({currency})
                    {settings?.collect_vat_enabled &&
                      instalmentPlan?.collect_deposit_vat && (
                        <p>VAT({settings?.collect_vat_value}%)</p>
                      )}
                    {settings?.collect_tax_enabled &&
                      instalmentPlan?.collect_deposit_tax && (
                        <p>Tax({settings?.collect_tax_value}%)</p>
                      )}
                    {(settings?.collect_vat_enabled &&
                      instalmentPlan?.collect_deposit_vat) ||
                    (settings?.collect_tax_enabled &&
                      instalmentPlan?.collect_deposit_tax) ? (
                      <p>Total</p>
                    ) : (
                      ""
                    )}
                  </p>
                  <p className="font_xs fw_5 value">
                    {depositAmt}
                    {settings?.collect_vat_enabled &&
                      instalmentPlan?.collect_deposit_vat && (
                        <p>
                          {instalmentPlan?.collect_deposit_vat
                            ? (
                                (instalmentPlan?.deposit_amount *
                                  settings?.collect_vat_value) /
                                100
                              ).toFixed(2)
                            : 0}
                        </p>
                      )}
                    {settings?.collect_tax_enabled &&
                      instalmentPlan?.collect_deposit_tax && (
                        <p>
                          {instalmentPlan?.collect_deposit_tax
                            ? (
                                (instalmentPlan?.deposit_amount *
                                  settings?.collect_tax_value) /
                                100
                              ).toFixed(2)
                            : 0}
                        </p>
                      )}
                    {((settings?.collect_vat_enabled &&
                      instalmentPlan?.collect_deposit_vat) ||
                      (settings?.collect_tax_enabled &&
                        instalmentPlan?.collect_deposit_tax)) && (
                      <>
                        {getTotal(
                          instalmentPlan?.deposit_amount,
                          instalmentPlan?.collect_deposit_vat,
                          instalmentPlan?.collect_deposit_tax
                        )?.toLocaleString("en-US")}
                      </>
                    )}
                  </p>
                </div>
              )}
              {/* Commission Amount */}
              {commission && (
                <div className="c-row">
                  <p className="font_xs fw_5 key font_grey">
                    Commission ({currency})
                    {settings?.collect_vat_enabled &&
                      instalmentPlan?.collect_commission_vat && (
                        <p>VAT({settings?.collect_vat_value}%)</p>
                      )}
                    {settings?.collect_tax_enabled &&
                      instalmentPlan?.collect_commission_tax && (
                        <p>Tax({settings?.collect_tax_value}%)</p>
                      )}
                    {(settings?.collect_vat_enabled &&
                      instalmentPlan?.collect_commission_vat) ||
                    (settings?.collect_tax_enabled &&
                      instalmentPlan?.collect_commission_tax) ? (
                      <p>Total</p>
                    ) : (
                      ""
                    )}
                  </p>
                  <p className="font_xs fw_5 value">
                    {commissionAmt}{" "}
                    {settings?.collect_vat_enabled &&
                      instalmentPlan?.collect_commission_vat && (
                        <p>
                          {" "}
                          {instalmentPlan?.collect_commission_vat
                            ? (
                                (instalmentPlan?.commission_amount *
                                  settings?.collect_vat_value) /
                                100
                              ).toFixed(2)
                            : 0}{" "}
                        </p>
                      )}{" "}
                    {settings?.collect_tax_enabled &&
                      instalmentPlan?.collect_commission_tax && (
                        <p>
                          {instalmentPlan?.collect_commission_tax
                            ? (
                                (instalmentPlan?.commission_amount *
                                  settings?.collect_tax_value) /
                                100
                              ).toFixed(2)
                            : 0}
                        </p>
                      )}
                    {(settings?.collect_vat_enabled ||
                      settings?.collect_tax_enabled) && (
                      <>
                        {getTotal(
                          instalmentPlan?.commission_amount,
                          instalmentPlan?.collect_commission_vat,
                          instalmentPlan?.collect_commission_tax
                        )?.toLocaleString("en-US")}
                      </>
                    )}
                  </p>
                </div>
              )}
            </div>

            <div className="more-data-duration">
              {/* Duration */}
              <div className="c-row">
                <p className="font_xs fw_5 key font_grey">Duration</p>
                <p className="font_xs fw_5 value">{duration}</p>
              </div>
              {/* No. Of Installment */}
              <div className="c-row">
                <p className="font_xs fw_5 key font_grey">No. Of Installment</p>
                <p className="font_xs fw_5 value">{noOfInstallments}</p>
              </div>
            </div>

            <div className="more-data">
              {/* Start Date */}
              <div className="c-row">
                <p className="font_xs fw_5 key font_grey">Start Date</p>
                <p className="font_xs fw_5 value">
                  {customMoment(startDate, DATE_FORMAT, true, property?.offset)}
                </p>
              </div>
              {/* End Date */}
              <div className="c-row">
                <p className="font_xs fw_5 key font_grey">End Date</p>
                <p className="font_xs fw_5 value">
                  {customMoment(endDate, DATE_FORMAT, true, property?.offset)}
                </p>
              </div>

              <div className="c-row">
                {/* <p className="font_xs fw_5 key font_grey">Action Date</p>
                <p className="font_xs fw_5 value">
                  {moment(actionDate).format(DATE_TIME_FORMAT)}
                </p> */}
              </div>
            </div>

            <div className="more-data">
              {/* Action Date */}
              <div className="c-row">
                <p className="font_xs fw_5 key font_grey">Action Date</p>
                <p className="font_xs fw_5 value">
                  {moment(actionDate).format(DATE_TIME_FORMAT)}
                </p>
              </div>
            </div>

            {instalmentPlan?.cheque && (
              <p className="recieve-by-cheque-txt font_xs fw_6 font_yellow">
                Recieve payments by cheque
              </p>
            )}
          </div>
        </div>

        {depositData?.length > 0 && (
          <>
            <p className="py-2 font_l fw_5 font_blue">Deposit Breakdown</p>
            {Object?.keys(depositData[0])?.length !== 0 ? (
              <Table className="custom-table font_s" borderless responsive>
                {/* Table Heading */}
                <thead>
                  <tr>
                    <th>Description</th>
                    <th>Deposit Amount ({currency})</th>
                    <th>Due Date</th>
                  </tr>
                </thead>
                {/* Table Body */}
                <tbody>
                  {depositData?.map((item, index) => (
                    <tr key={index} className="install">
                      {/* Name */}
                      <td>{item?.description ? item?.description : "-"}</td>
                      {/* Amount */}
                      <td className="">
                        {item?.deposit_amount
                          ? parseInt(item?.deposit_amount)?.toLocaleString(
                              "en-US"
                            )
                          : "-"}
                      </td>
                      <td>
                        {instalmentPlan?.updatedAt &&
                        Object?.keys(depositData[0])?.length !== 0
                          ? customMoment(
                              instalmentPlan?.updatedAt,
                              DATE_FORMAT,
                              true,
                              property?.offset
                            )
                          : "-"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <div className=" text-center">
                <h3 className=" font_m font_yellow fw_6">No Deposits</h3>
              </div>
            )}
          </>
        )}

        <>
          <p className="py-2 font_blue fw_5 font_l">Rent Installments</p>
          <Table className="custom-table font_s" borderless responsive>
            {/* Table Heading */}
            <thead>
              <tr>
                <th>Name</th>
                <th>Amount ({currency})</th>
                <th>Due Date</th>
                <th>Action</th>
                {/* {(canEdit || canAttachCheque) && <th>Action</th>} */}
              </tr>
            </thead>
            {/* Table Body */}
            <tbody>
              {data?.map((item, index) => (
                <tr key={index} className="install">
                  {/* Name */}
                  <td>
                    {item?.name}

                    <>
                      {settings?.collect_vat_enabled &&
                        instalmentPlan?.collect_instalment_vat && (
                          <p>VAT({settings?.collect_vat_value}%)</p>
                        )}

                      {settings?.collect_tax_enabled &&
                        instalmentPlan?.collect_instalment_tax && (
                          <p>Tax({settings?.collect_tax_value}%)</p>
                        )}

                      {(settings?.collect_vat_enabled &&
                        instalmentPlan?.collect_instalment_vat) ||
                      (settings?.collect_tax_enabled &&
                        instalmentPlan?.collect_instalment_tax) ? (
                        <p>Total</p>
                      ) : (
                        ""
                      )}
                    </>
                  </td>
                  {/* Amount */}
                  <td className="amount-column-wrapper">
                    <div className="amount-wrapper">
                      {parseInt(newInstallment[index])?.toLocaleString("en-US")}

                      <>
                        {settings?.collect_vat_enabled &&
                          instalmentPlan?.collect_instalment_vat && (
                            <p>
                              {newInstallment[index]
                                ? (
                                    (parseFloat(newInstallment[index]) *
                                      settings?.collect_vat_value) /
                                    100
                                  )?.toLocaleString("en-US", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })
                                : // .toFixed(2)
                                  // .toLocaleString("en-US")
                                  0}
                            </p>
                          )}
                        {settings?.collect_tax_enabled &&
                          instalmentPlan?.collect_instalment_tax && (
                            <p>
                              {newInstallment[index]
                                ? (
                                    (parseFloat(newInstallment[index]) *
                                      settings?.collect_tax_value) /
                                    100
                                  )?.toLocaleString("en-US", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })
                                : // .toFixed(2)
                                  // .toLocaleString("en-US")
                                  0}
                            </p>
                          )}
                        {(settings?.collect_vat_enabled &&
                          instalmentPlan?.collect_instalment_vat) ||
                        (settings?.collect_tax_enabled &&
                          instalmentPlan?.collect_instalment_tax) ? (
                          <p>
                            {getTotal(
                              parseFloat(newInstallment[index]),
                              instalmentPlan?.collect_instalment_vat,
                              instalmentPlan?.collect_instalment_tax
                            )?.toLocaleString("en-US")}
                          </p>
                        ) : (
                          ""
                        )}
                      </>
                    </div>
                  </td>
                  {/* Due Date */}
                  <td className="date-column-wrapper">
                    <div className="datepick-wrapper">
                      {moment(newDueDates[index]).format(DATE_FORMAT)}
                    </div>
                  </td>

                  <td>
                    {item?.tenant_billing_invoice && (
                      <IconButton
                        onClick={() => handleView(item)}
                        className="action-button installment-plan-edit-btn"
                      >
                        <img
                          className="h_100"
                          src={EyeFilledBlue}
                          alt="Cancel"
                        />
                      </IconButton>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      </div>
    </div>
  );
};
/* Component ends */

export default RentInstallmentDetail;
