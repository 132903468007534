/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import T1SLA from "./T1SLA";
import T2SLA from "./T2SLA";
import T3SLA from "./T3SLA";
/* Import local pages and component ends */

/* Component starts */
const SLAs = (props) => {
  /* Props destructuring starts */
  const { user, settings, property } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="property-setting-module-wrapper container_md">
      {property?.mgmt_model === 0 && <T1SLA user={user} settings={settings} />}
      {property?.mgmt_model === 1 && <T2SLA user={user} settings={settings} />}
      {property?.mgmt_model === 2 && <T3SLA user={user} settings={settings} />}
    </div>
  );
};
/* Component ends */

export default SLAs;
