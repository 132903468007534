/**
 * This is component is used to protect the private route from accessing by
 * anauthorized users.
 *
 * This component is used to check if the user that gone to the route
 * or path is a Signed in or not. If the user is not Signed in then it will
 * redirect to the Sign in page.
 */

import React, { useState } from "react";

/* Import configuration starts */
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  fetchUser,
  getUser,
  getAccessToken,
} from "../../store/slices/authSlice";
import { useEffect } from "react";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import MUI component starts */
/* Import MUI component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
/* Import local pages and component ends */

/* Component starts */
const RequireAuth = () => {
  /* Props destructuring starts */
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [userLoaded, setUserLoaded] = useState(false);

  const location = useLocation();
  const dispatch = useDispatch();
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const user = useSelector(getUser);
  const accessToken = useSelector(getAccessToken);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const loggedInUserData =
    localStorage.getItem("user_data") &&
    JSON.parse(localStorage.getItem("user_data"));
  const loggedInUserRole = localStorage.getItem("user_role");
  const loggedInUserToken = localStorage.getItem("user_token");
  const userLoggedIn =
    loggedInUserData && loggedInUserRole && loggedInUserToken;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  /* Component function definition ends */

  /* Component useEffect starts */
  // useEffect(() => {
  //   (() => {
  //     dispatch(fetchUser()).then(() => {
  //       setUserLoaded(true);
  //     });
  //   })();
  // }, []);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  // if (userLoaded)
  return userLoggedIn ? (
    <Outlet />
  ) : (
    <Navigate to="/signin" state={{ from: location }} replace />
  );
};
/* Component ends */

export default RequireAuth;
