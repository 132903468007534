export const moveOutValues = {
  name_0: "",
  image_0: {},
  name_1: "",
  image_1: {},
};

export const prefill = (data, setInitialFormValues, docList, setDocList) => {
  const formValues = {};
  const docListArr = [];
  data.forEach((item, index) => {});
};

export const generateDocStatus = (status) => {
  switch (status) {
    case "Submition Pending":
      return { text: "Submition Pending", color: "font_yellow" };

    case "Approval Pending":
      return { text: "Approval Pending", color: "font_yellow" };

    case "Rejected":
      return { text: "Rejected", color: "font_red" };

    case "Approved":
      return { text: "Approved", color: "font_green" };
  }
};
