/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */

import {
  fetchTenantViolationsList,
  getTenantViolationsList,
} from "../../../setup/store/slices/violationSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import TenantViolationsTable from "../../../components/module/Voilations/VoilationsTables/TenantViolation/TenantViolationsTable";
/* Import local pages and component ends */

// styles sheet
import "./style.scss";

/* Component starts */
const TenantViolations = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [currentPage, setCurrentPage] = useState(1);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const { unitId } = useParams();
  const violationList = useSelector(getTenantViolationsList);
  /* Other hooks declaration ends */

  // Redux Selectors

  /* Component variable declaration / object destructure starts */

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const onloadViolationList = () => {
    dispatch(fetchTenantViolationsList({ unit_id: unitId, page: currentPage }));
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    onloadViolationList();
  }, [currentPage]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */

  return (
    <div className="violation-wrapper">
      <div className="heading">
        <h2 className="font_xxl fw_5">Violations</h2>
      </div>

      <TenantViolationsTable
        violationList={violationList?.result}
        totalCount={violationList?.totalCount}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
};
/* Component End */

export default TenantViolations;
