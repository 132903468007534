/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import moment from "moment";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import GLOBAL from "../../../../../setup/constants/global";
import CustomRadioInput from "../../../../ui/input/CustomRadioInput/CustomRadioInput";
/* Import local pages and component ends */

/* Component starts */
const Report = (props) => {
  /* Props destructuring starts */
  const { moveOut } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [keyStatus, setKeyStatus] = useState("recieved");
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const contractStartDate = moment(moveOut?.contract_start_date).format(
    DATE_FORMAT
  );
  const contractEndDate = moment(moveOut?.contract_end_date).format(
    DATE_FORMAT
  );
  const dateRequested = moment(moveOut?.date_requested).format(DATE_FORMAT);
  const moveoutDate = moment(moveOut?.move_out_date).format(DATE_FORMAT);
  const raisedBy = moveOut?.raised_by_type;
  const unitName = moveOut?.unit;
  const tenantName = moveOut?.tenant;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="moveOut-detail-box-wrapper">
      <div className="tenant-moveOut-detail">
        <div>
          <p className="font_s font_grey fw_4">
            Tenant Name
            <br />
            <span className="font_m fw_6">{tenantName}</span>
          </p>
        </div>
        <div>
          <p className="font_s font_grey fw_4">
            Date Requested
            <br />
            <span className="font_s fw_4">{dateRequested}</span>
          </p>
        </div>
        <div>
          <p className="font_s font_grey fw_4">
            Preferred Move Out
            <br />
            <span className="font_s fw_4">{moveoutDate}</span>
          </p>
        </div>
        <div>
          <p className="font_s font_grey fw_4">
            Unit
            <br />
            <span className="font_m fw_6 font_blue">{unitName}</span>
          </p>
        </div>
        <div>
          <p className="font_s font_grey fw_4">
            Raised By
            <br />
            <span className="font_s fw_4">{raisedBy}</span>
          </p>
        </div>
      </div>

      <hr />

      <div className="contract-date-wrapper">
        <div>
          <p className="font_s font_grey fw_4">
            Contract Start
            <br />
            <span className="font_s fw_4">{contractStartDate}</span>
          </p>
        </div>

        <div>
          <p className="font_s font_grey fw_4">
            Contract End
            <br />
            <span className="font_s fw_4">{contractEndDate}</span>
          </p>
        </div>
      </div>
    </div>
  );
};
/* Component ends */

export default Report;
