/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import moment from "moment";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Accordion, Table } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import {
  DocumentGreen,
  EyeFilledBlue,
} from "../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../../../ui/button/IconButton/IconButton";
import FileViewerModal from "../../../../ui/modal/FileViewerModal/FileViewerModal";

import HouseholdMembers from "../../NewTenantPMReview/sub-component/HouseholdMembers";
import ApplicationFee from "../../NewTenantPMReview/sub-component/ApplicationFee";

import RentDetail from "./RentDetail";
import {
  invoiceDetailsByType,
  keyStatusGenerate,
  tenancyType,
} from "../../NewTenantPMReview/helper";
import RentHistoryDetailModal from "../../NewTenantPMReview/RentHistoryDetailModal/RentHistoryDetailModal";
import GLOBAL from "../../../../../setup/constants/global";
/* Import local pages and component ends */

/* Component starts */
const Onboarding = (props) => {
  /* Props destructuring starts */
  const { onboardingList } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [showFileViewer, setShowFileViewer] = useState(false);
  const [file, setFile] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [detailData, setDetailData] = useState({});
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const onViewFile = (item) => {
    setFile(item);
    setShowFileViewer(true);
  };

  // To View Detail About the Rent Hisitory
  const handleViewReport = (item) => {
    setDetailData(item);
    setShowModal(!showModal);
  };

  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="onboarding-wrapper">
      {onboardingList?.map((item, index) => (
        <div className="my-4" key={index}>
          <Accordion>
            <Accordion.Item eventKey={index}>
              <Accordion.Header>
                <div
                  className="d-flex justify-content-between gap-4"
                  style={{ width: "25%" }}
                >
                  <p className=" font_m fw_4">
                    Application ID <br />
                    <span className=" font_m fw_4">
                      {" "}
                      {item?.application?.application_details?.request_id}
                    </span>
                  </p>

                  <p className=" font_m fw_4">
                    Date & Time <br />
                    <span className=" font_m fw_4">
                      {moment(
                        item?.application?.application_details?.createdAt
                      ).format(DATE_FORMAT)}
                    </span>
                  </p>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                {item?.application?.application_details?.status ===
                "invitation_canceled" ? (
                  <div className=" text-center">
                    <p className="font_m font_red fw_6">Invitation Cancelled</p>
                  </div>
                ) : (
                  <>
                    {/* ID Detail Table */}
                    <div className="idVerify-wrapper">
                      <p className="font_l">Id Details</p>

                      {/* ID Verfication Table */}
                      <div className="custom-table-wrapper">
                        {item?.application?.ids?.length > 0 && (
                          <Table
                            className="custom-table font_s"
                            borderless
                            responsive
                          >
                            {/* Table Heading */}
                            <thead>
                              <tr>
                                <th>Id Type</th>
                                <th>Id Number</th>
                                <th>Id Expiry Date</th>
                                <th>Image</th>
                              </tr>
                            </thead>
                            {/* Table Body */}
                            <tbody>
                              {item?.application?.ids?.map((item) => (
                                <tr key={item?._id}>
                                  {/* Id Type */}
                                  <td>{item?.type}</td>
                                  {/* Id Number */}

                                  <td>{item?.number}</td>
                                  {/* Id Expiry Date */}

                                  <td>
                                    {moment(item?.expiry_date).format(
                                      DATE_FORMAT
                                    )}
                                  </td>

                                  {/* ID Image */}
                                  <td className="text-start txt-nowrap">
                                    <IconButton>
                                      <img
                                        src={DocumentGreen}
                                        alt="View ID"
                                        onClick={() => {
                                          onViewFile(item?.image);
                                        }}
                                      />
                                    </IconButton>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        )}

                        {item?.application?.ids?.length === 0 && (
                          <div className=" text-center">
                            <h3 className=" font_m font_yellow fw_6">
                              No Data
                            </h3>
                          </div>
                        )}
                      </div>
                    </div>

                    {/* House Hold Members Detail */}
                    <div>
                      <HouseholdMembers
                        data={item?.application?.household_members}
                      />
                    </div>

                    {/* Vehicle Table */}
                    <div className="vehicle-wrapper">
                      <p className="font_l">Vehicles Details</p>

                      {/* ID Verfication Table */}
                      <div className="custom-table-wrapper">
                        {item?.application?.vehicles?.length > 0 && (
                          <Table
                            className="custom-table font_s"
                            borderless
                            responsive
                          >
                            {/* Table Heading */}
                            <thead>
                              <tr>
                                <th>Make</th>
                                <th>Model</th>
                                <th>Color</th>
                                <th>Plate Number</th>
                                <th>Place Issued From</th>
                                <th>Image</th>
                              </tr>
                            </thead>
                            {/* Table Body */}
                            <tbody>
                              {item?.application?.vehicles?.map((item) => (
                                <tr key={item?._id}>
                                  {/* Make */}
                                  <td>{item?.make}</td>
                                  {/* Model */}
                                  <td>{item?.model}</td>
                                  {/* Color */}
                                  <td>{item?.color}</td>
                                  {/* Plate Number */}
                                  <td>{item?.plate_number}</td>
                                  {/* Place Issued */}
                                  <td>{item?.plate_issued_from}</td>
                                  {/* ID Image */}
                                  <td className="text-start txt-nowrap">
                                    <IconButton>
                                      <img
                                        src={DocumentGreen}
                                        alt="View ID"
                                        onClick={() => {
                                          onViewFile(item?.image);
                                        }}
                                      />
                                    </IconButton>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        )}

                        {item?.application?.vehicles?.length === 0 && (
                          <div className=" text-center">
                            <h3 className=" font_m font_yellow fw_6">
                              No Data
                            </h3>
                          </div>
                        )}
                      </div>
                    </div>

                    {/* PET Table */}
                    <div className="pet-wrapper">
                      <p className="font_l">Pets Details</p>

                      {/* ID Verfication Table */}
                      <div className="custom-table-wrapper">
                        {item?.application?.pets?.length > 0 && (
                          <Table
                            className="custom-table font_s"
                            borderless
                            responsive
                          >
                            {/* Table Heading */}
                            <thead>
                              <tr>
                                <th>Pet Name</th>
                                <th>Type Of Pet</th>
                                <th>Date Of Birth</th>
                                <th>Image</th>
                              </tr>
                            </thead>
                            {/* Table Body */}
                            <tbody>
                              {item?.application?.pets?.map((item) => (
                                <tr key={item.id}>
                                  {/* Pet Name */}
                                  <td>{item?.name}</td>
                                  {/* Type Of Pet */}
                                  <td>{item?.type}</td>
                                  {/* Date Of Birth */}
                                  <td>
                                    {moment(item?.dob).format(DATE_FORMAT)}
                                  </td>

                                  {/* ID Image */}
                                  <td className="text-start txt-nowrap">
                                    <IconButton>
                                      <img
                                        src={DocumentGreen}
                                        alt="View ID"
                                        onClick={() => {
                                          onViewFile(item?.image);
                                        }}
                                      />
                                    </IconButton>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        )}

                        {item?.application?.pets?.length === 0 && (
                          <div className=" text-center">
                            <h3 className=" font_m font_yellow fw_6">
                              No Data
                            </h3>
                          </div>
                        )}
                      </div>
                    </div>

                    {/* Rental History Detail */}
                    {item?.sub_status !== "renewed" && (
                      <div className="rent-wrapper">
                        <p className="font_l">Rental History Details</p>
                        <div className="custom-table-wrapper">
                          {item?.application?.rental_history?.length > 0 && (
                            <Table
                              className="custom-table font_s"
                              borderless
                              responsive
                            >
                              {/* Table Heading */}
                              <thead>
                                <tr>
                                  <th>Property</th>
                                  <th>Tenancy Type</th>
                                  <th>From </th>
                                  <th>To</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              {/* Table Body */}
                              <tbody>
                                {item?.application?.rental_history?.map(
                                  (item, index) => (
                                    <tr key={index}>
                                      {/* Property Name */}
                                      <td>{item?.property_name}</td>
                                      {/* Tenancy Type */}
                                      <td>{tenancyType[item.tenancy_type]}</td>

                                      {/* From */}
                                      <td>
                                        {" "}
                                        {moment(item.start_date).format(
                                          DATE_FORMAT
                                        )}
                                      </td>
                                      {/* To */}
                                      <td>
                                        {" "}
                                        {moment(item.end_date).format(
                                          DATE_FORMAT
                                        )}
                                      </td>
                                      {/* Actions */}

                                      {/* {!approval && ( */}
                                      <th className="text-left txt-nowrap action">
                                        <IconButton
                                          onClick={() => handleViewReport(item)}
                                          className="action-button"
                                        >
                                          <img
                                            src={EyeFilledBlue}
                                            className="h_100"
                                            alt="View Details"
                                          />
                                        </IconButton>
                                      </th>
                                      {/* )} */}
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </Table>
                          )}

                          {item?.application?.rental_history?.length === 0 && (
                            <div className=" text-center">
                              <h3 className=" font_m font_yellow fw_6">
                                No Data
                              </h3>
                            </div>
                          )}
                        </div>
                      </div>
                    )}

                    {/* Utility Table */}
                    <div className="utility-wrapper">
                      <p className="font_l">Utility Details</p>

                      {/* ID Verfication Table */}
                      <div className="custom-table-wrapper">
                        {item?.contract?.utilities?.length > 0 && (
                          <Table
                            className="custom-table font_s"
                            borderless
                            responsive
                          >
                            {/* Table Heading */}
                            <thead>
                              <tr>
                                <th>Utility Name</th>
                                <th>Document</th>
                              </tr>
                            </thead>
                            {/* Table Body */}
                            <tbody>
                              {item?.contract?.utilities?.map((item) => (
                                <tr key={item?._id}>
                                  {/* Utility Name */}
                                  <td>{item?.name}</td>

                                  {/* ID Image */}
                                  <td className="text-start txt-nowrap">
                                    <IconButton>
                                      <img
                                        src={DocumentGreen}
                                        alt="View ID"
                                        onClick={() => {
                                          onViewFile(item?.image);
                                        }}
                                      />
                                    </IconButton>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        )}

                        {item?.contract?.utilities?.length === 0 && (
                          <div className=" text-center">
                            <h3 className=" font_m font_yellow fw_6">
                              No Data
                            </h3>
                          </div>
                        )}
                      </div>
                    </div>

                    {/* Application Fee Details */}
                    {item?.sub_status !== "renewed" && (
                      <div className="my-4">
                        <ApplicationFee
                          amount={
                            item?.application?.property_settings
                              ?.application_fee_amount
                          }
                          invoiceDetails={
                            invoiceDetailsByType(
                              item?.contract?.billing_invoices,
                              "application-fee"
                            ) !== null
                              ? invoiceDetailsByType(
                                  item?.contract?.billing_invoices,
                                  "application-fee"
                                )
                              : "NA"
                          }
                          fromExisiting={true}
                        />
                      </div>
                    )}

                    {/* Rental Details */}
                    <div className="my-4">
                      <RentDetail
                        instalments={item?.initial_agreement?.installments}
                        data={item?.initial_agreement?.installments}
                        instalmentPlan={
                          item?.initial_agreement?.installment_plan
                        }
                        application_id={
                          item?.application?.application_details?._id
                        }
                        currency={
                          item?.application?.application_details?.unit?.currency
                        }
                      />
                    </div>

                    {/* Initial Aggrement */}
                    <div className="initial-agreement-wrapper">
                      <div className="initial-agreement-heading">
                        <p className="font_l label">Initial Agreement</p>
                      </div>

                      {/* ID Verfication Table */}
                      <div className="custom-table-wrapper">
                        {item?.application?.application_details
                          ?.initial_agreement_pdf !== undefined && (
                          <Table
                            className="custom-table font_s"
                            borderless
                            responsive
                          >
                            {/* Table Heading */}
                            <thead>
                              <tr>
                                <th></th>
                                <th>Tenant Signature Date and Time</th>
                                <th>PM Signature Date and Time</th>
                                <th>File</th>
                                {/* <th className="text-start">Actions</th> */}
                              </tr>
                            </thead>
                            {/* Table Body */}
                            <tbody>
                              <tr>
                                <td>Initial Agreement</td>
                                <td>
                                  {item?.application?.application_details
                                    ?.first_party_initial_signature_timestamp !==
                                  undefined
                                    ? moment(
                                        item?.application?.application_details
                                          ?.first_party_initial_signature_timestamp
                                      ).format(DATE_TIME_FORMAT)
                                    : "-"}
                                </td>
                                <td>
                                  {item?.application?.application_details
                                    ?.second_party_initial_signature_timestamp !==
                                  undefined
                                    ? moment(
                                        item?.application?.application_details
                                          ?.second_party_initial_signature_timestamp
                                      ).format(DATE_TIME_FORMAT)
                                    : "-"}
                                </td>
                                {/* Document */}
                                <td className="text-start txt-nowrap ">
                                  <IconButton
                                    onClick={() =>
                                      onViewFile(
                                        item?.application?.application_details
                                          ?.initial_agreement_pdf
                                      )
                                    }
                                  >
                                    <img src={DocumentGreen} alt="View ID" />
                                  </IconButton>
                                </td>
                              </tr>
                              <tr>
                                {" "}
                                <td>Audit log</td>
                                <td>-</td>
                                <td>-</td>
                                {/* Document */}
                                <td className="text-start txt-nowrap ">
                                  <IconButton
                                    onClick={() =>
                                      onViewFile(
                                        item?.application?.application_details
                                          ?.initial_agreement_audit_logs
                                      )
                                    }
                                  >
                                    <img src={DocumentGreen} alt="View ID" />
                                  </IconButton>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        )}

                        {item?.application?.application_details
                          ?.initial_agreement_pdf === undefined && (
                          <div className=" text-center">
                            <h3 className=" font_m font_yellow fw_6">
                              No Data
                            </h3>
                          </div>
                        )}
                      </div>
                    </div>

                    {/* Final Aggrement */}
                    <div className="initial-agreement-wrapper">
                      <div className="initial-agreement-heading">
                        <p className="font_l label">Final Agreement</p>
                      </div>

                      {/* ID Verfication Table */}
                      <div className="custom-table-wrapper">
                        {item?.application?.application_details
                          ?.final_agreement_pdf !== undefined && (
                          <Table
                            className="custom-table font_s"
                            borderless
                            responsive
                          >
                            {/* Table Heading */}
                            <thead>
                              <tr>
                                <th></th>
                                <th>Tenant Signature Date and Time</th>
                                <th>PM Signature Date and Time</th>
                                <th>File</th>
                                {/* <th className="text-start">Actions</th> */}
                              </tr>
                            </thead>
                            {/* Table Body */}
                            <tbody>
                              <tr>
                                <td>Final Agreement</td>
                                <td>
                                  {item?.application?.application_details
                                    ?.first_party_final_signature_timestamp !==
                                  undefined
                                    ? moment(
                                        item?.application?.application_details
                                          ?.first_party_final_signature_timestamp
                                      ).format(DATE_TIME_FORMAT)
                                    : "-"}
                                </td>
                                <td>
                                  {item?.application?.application_details
                                    ?.second_party_final_signature_timestamp !==
                                  undefined
                                    ? moment(
                                        item?.application?.application_details
                                          ?.second_party_final_signature_timestamp
                                      ).format(DATE_TIME_FORMAT)
                                    : "-"}
                                </td>
                                {/* Document */}
                                <td className="text-start txt-nowrap ">
                                  <IconButton
                                    onClick={() =>
                                      onViewFile(
                                        item?.application?.application_details
                                          ?.final_agreement_pdf
                                      )
                                    }
                                  >
                                    <img src={DocumentGreen} alt="View ID" />
                                  </IconButton>
                                </td>
                              </tr>
                              <tr>
                                {" "}
                                <td>Audit log</td>
                                <td>-</td>
                                <td>-</td>
                                {/* Document */}
                                <td className="text-start txt-nowrap ">
                                  <IconButton
                                    onClick={() =>
                                      onViewFile(
                                        item?.application?.application_details
                                          ?.final_agreement_audit_logs
                                      )
                                    }
                                  >
                                    <img src={DocumentGreen} alt="View ID" />
                                  </IconButton>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        )}

                        {item?.application?.application_details
                          ?.final_agreement_pdf === undefined && (
                          <div className=" text-center">
                            <h3 className=" font_m font_yellow fw_6">
                              No Data
                            </h3>
                          </div>
                        )}
                      </div>
                    </div>

                    {/* Move In Details */}
                    {item?.sub_status !== "renewed" && (
                      <div className="utility-wrapper">
                        <p className="font_l">Move In</p>

                        {/* ID Verfication Table */}
                        <div className="custom-table-wrapper">
                          {item?.maintenance && (
                            <Table
                              className="custom-table font_s"
                              borderless
                              responsive
                            >
                              {/* Table Heading */}
                              <thead>
                                <tr>
                                  <th></th>
                                  <th>Date</th>
                                  <th>Status</th>
                                  {/* <th></th> */}
                                  <th>Action Date</th>
                                </tr>
                              </thead>
                              {/* Table Body */}
                              <tbody>
                                <tr>
                                  <td>Move In Date</td>
                                  <td>
                                    {item?.maintenance?.move_in_date && (
                                      <p>
                                        {moment(
                                          item?.maintenance?.move_in_date
                                        ).format(DATE_FORMAT)}
                                      </p>
                                    )}

                                    {!item?.maintenance?.move_in_date && (
                                      <p>Not Added</p>
                                    )}
                                  </td>
                                  <td>
                                    {!item?.maintenance?.status && (
                                      <p className="font_m">Not Assigned</p>
                                    )}
                                    {item?.maintenance?.status && (
                                      <p className="font_m">
                                        {item?.maintenance?.status}
                                      </p>
                                    )}
                                  </td>

                                  <td>
                                    {moment(
                                      item?.maintenance?.updatedAt
                                    ).format(DATE_TIME_FORMAT)}
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          )}

                          {item?.maintenance === null && (
                            <div className=" text-center">
                              <h3 className=" font_m font_yellow fw_6">
                                No Data
                              </h3>
                            </div>
                          )}
                        </div>
                      </div>
                    )}

                    {/* Keys Details */}
                    {item?.sub_status !== "renewed" && (
                      <div className="keys-wrapper">
                        <div className="keys-heading">
                          <p className="font_l label">Keys</p>
                          <p className="font_s time"></p>
                        </div>

                        {/* ID Verfication Table */}
                        <div className="custom-table-wrapper">
                          {item?.key?.key_details !== null && (
                            <Table
                              className="custom-table font_s"
                              borderless
                              responsive
                            >
                              {/* Table Heading */}
                              <thead>
                                <tr>
                                  <th></th>
                                  <th>Status</th>
                                  <th>Action Date</th>
                                </tr>
                              </thead>
                              {/* Table Body */}
                              <tbody>
                                <tr>
                                  {/* ID */}
                                  <td>Key Status</td>

                                  {/* Status */}
                                  <td>
                                    <p
                                      className={`${
                                        keyStatusGenerate(
                                          item?.key?.key_details?.status
                                        ).color
                                      }`}
                                    >
                                      {
                                        keyStatusGenerate(
                                          item?.key?.key_details?.status
                                        ).text
                                      }
                                    </p>
                                  </td>

                                  <td>
                                    {moment(
                                      item?.key?.key_details?.updatedAt
                                    ).format(DATE_TIME_FORMAT)}
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          )}

                          {item?.key?.key_details === null && (
                            <div className=" text-center">
                              <h3 className=" font_m font_yellow fw_6">
                                No Data
                              </h3>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </>
                )}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      ))}

      {/* Tenant Previous History Modal*/}
      <RentHistoryDetailModal
        showModal={showModal}
        detail={detailData}
        onClose={() => setShowModal(!showModal)}
      />

      {/* File viewer modal */}
      <FileViewerModal
        show={showFileViewer}
        type={file?.type}
        file={file?.url}
        onClose={() => {
          setShowFileViewer(false);
          setFile({});
        }}
      />
    </div>
  );
};
/* Component ends */

export default Onboarding;
