/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import CurrencyInput from "react-currency-input-field";
import getSymbolFromCurrency from "currency-symbol-map";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import MUI component starts */
/* Import MUI component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
/* Import local pages and component ends */

import "../style.scss";
import { useSelector } from "react-redux";
import { getUserTheme } from "../../../../../setup/store/slices/authSlice";

/* Component starts */
const V1 = (props) => {
  /* Props destructuring starts */
  const {
    name,
    label,
    handleChange,
    handleBlur = null,
    touched,
    errors,
    value,
    setFieldValue = null, // setFieldValue function definition of Formik
    setFieldTouched = null, // setFieldTouched function definition of Formik
    required = true,
    prefix = null,
    currency = null,
    formikField = true,
    setValue = null,
    onCustomChange = null,
    customOnchange = null,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [symbol, setSymbol] = useState("");
  const [defaultVal, setDefaultVal] = useState("");
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const userTheme = useSelector(getUserTheme);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    if (currency) {
      setSymbol(getSymbolFromCurrency(currency));
    }
  }, [currency]);

  // useEffect(() => {
  //   setValue(name, value);
  // }, [value]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="custom-currency-input-wrapper">
      <div className="form-input-wrapper">
        <label className="font_s font_grey">
          {label}
          {required && <span className="font_m font_red"> *</span>}
        </label>
        <CurrencyInput
          // defaultValue={value}
          value={value}
          onBlur={(e) => {
            handleBlur && handleBlur(e);
            setFieldTouched && setFieldTouched(name, true);
          }}
          //|| userTheme === "automatic"
          className={` ${
            userTheme === "light"
              ? "bg_lightThemeInput font_lightTheme"
              : "bg_black font_white"
          } font_xs`}
          name={name}
          decimalsLimit={2}
          prefix={prefix || symbol}
          allowNegativeValue={false}
          onValueChange={(currencyValue) => {
            setFieldValue &&
              setFieldValue(name, currencyValue ? currencyValue : "");
            setValue && setValue(currencyValue ? currencyValue : "");
            onCustomChange &&
              onCustomChange(currencyValue ? currencyValue : "");

            customOnchange &&
              customOnchange(currencyValue ? currencyValue : "");
          }}
          autoComplete="off"
        />
      </div>

      {touched && errors && <p className="error font_xxs font_red">{errors}</p>}
    </div>
  );
};
/* Component ends */

export default V1;
