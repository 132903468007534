/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import { getIsTENANT } from "../../setup/store/slices/authSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import ChangePassword from "../../components/module/Auth/ChangePassword/ChangePassword";
import BreadCrumb from "../../components/ui/other/BreadCrumb/BreadCrumb";

/* Import local pages and component ends */

/* Component starts */
const ChangePasswordPage = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const { state, pathname } = useLocation();
  const isTenant = useSelector(getIsTENANT);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const userName = state;
  const forgotPassword = pathname === "/password/forgot";
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      {!isTenant && <BreadCrumb type="settings_changePass_page" />}
      <div className="container_sm">
        <ChangePassword forgotPassword={forgotPassword} userName={userName} />
      </div>
    </>
  );
};
/* Component ends */

export default ChangePasswordPage;
