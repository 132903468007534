/**
 *
 */

import React, { useState, useEffect, useRef } from "react";

/* Import configuration starts */
import { Formik, FieldArray } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { initialValues, validations } from "./helper";
/* Import configuration ends */

/* Import redux slices component starts */

/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */

import CustomTextInput from "../../../../components/ui/input/CustomTextInput/CustomTextInput";
import CustomTextArea from "../../../../components/ui/input/CustomTextArea/CustomTextArea";
import CustomDateTimeInput from "../../../../components/ui/input/CustomDateTimeInput/CustomDateTimeInput";
import CustomDateInput from "../../../../components/ui/input/CustomDateInput/CustomDateInput";
import CustomButton from "../../../../components/ui/button/CustomButton/CustomButton";
import TextButton from "../../../../components/ui/button/TextButton/TextButton";
/* Import local pages and component ends */

// Styles
import "./style.scss";
import CustomRadioInput from "../../../ui/input/CustomRadioInput/CustomRadioInput";
import CustomSelectInput from "../../../ui/input/CustomSelectInput/CustomSelectInput";
import CustomFileInput from "../../../ui/input/CustomFileInput/CustomFileInput";
import CustomCheckInput from "../../../ui/input/CustomCheckInput/CustomCheckInput";

/* Component starts */
const AddTask = (props) => {
  /* Props destructuring starts */
  const { onSuccess, onCancel } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [initialFormValues, setInitialFormValues] = useState(initialValues);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [date, setDate] = useState(new Date());
  const [time, setTime] = useState("");

  const [monday, setMonday] = useState(false);
  const [tuesday, setTuesday] = useState(false);
  const [wednesday, setWednesday] = useState(false);
  const [thursday, setThursday] = useState(false);
  const [friday, setFriday] = useState(false);
  const [saturday, setSaturday] = useState(false);
  const [sunday, setSunday] = useState(false);

  const [member, setMember] = useState("Team");
  const [selectFrequency, setSelectFrequency] = useState();

  //   const [selectedWeekDay, setSelectedWeekDay] = useState();
  const [selectedMedian, setSelectedMedian] = useState();
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const uploadDoc = useRef(null);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */

  const frequencyList = [
    { id: 1, frequency: "Hourly" },
    { id: 2, frequency: "Daily" },
    { id: 3, frequency: "Weekly" },
    { id: 4, frequency: "Monthly" },
  ];

  const timeMedian = [
    { id: 1, name: "AM" },
    { id: 2, name: "PM" },
  ];

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  // Form validations
  const formValidation = (values) => {
    return validations(values);
  };

  const onSubmit = async (values) => {
    console.log(
      selectFrequency?.frequency,
      "V-ID",
      values,
      "[]",
      selectedMedian,
      "-=[=]",
      time,
      "1212",
      monday,
      "+1425+"
    );

    // Backend response. Try, Catch
    // try {
    //   const result = await dispatch(addSecurityIncident(payload));

    //   console.log(result, "ADD_SCF");

    //   // Handling success response
    //   switch (result.meta.requestStatus) {
    //     case GLOBAL.REJECTED:
    //       setModalDesc(result.payload);
    //       setShowErrorModal(true);
    //       break;

    //     case GLOBAL.FULFILLED:
    //       setModalDesc("Security Incident Created Successfully");
    //       setShowSuccessModal(true);
    //       break;
    //   }
    // } catch (error) {
    //   // Handling error response
    //   console.error(error.message);
    // }
  };

  /* Component function definition ends */

  /* Component useEffect starts */

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="add-task-module-wrapper">
      <h2 className="heading text-center font_xxl mb-5">
        Internal Routine Form
      </h2>

      <div className="add-new-form">
        <Formik
          onSubmit={onSubmit}
          initialValues={initialFormValues}
          validate={formValidation}
          validateOnBlur={true}
          enableReinitialize={true}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="form-wrapper">
                {/* Creating new Routine Task */}
                <>
                  {/* Radio select the Voilator */}
                  <div className="form-group form-group-full w_100">
                    <p className="font_m font_grey w_100">
                      Assign this request to team member or another party ?
                    </p>
                    <CustomRadioInput
                      label="Team Member"
                      onSelecting={() => {
                        setMember("Team");
                      }}
                      isSelected={member === "Team"}
                    />

                    <CustomRadioInput
                      label="Another Party"
                      onSelecting={() => {
                        setMember("Another");
                      }}
                      isSelected={member === "Another"}
                    />
                  </div>

                  {member === "Team" ? (
                    <>
                      <div className="form-group">
                        <CustomSelectInput
                          name="team-member"
                          label="Select Team Members"
                          //   handleBlur={handleBlur}
                          //   handleChange={handleChange}
                          //   valueExtractor={(item) =>
                          //     //item?.unit_number
                          //     `${item?.unit_name} - ${item?.tenant_name}`
                          //   }
                          //   setValue={setSelectedUnitNo}
                          //   options={tenantList}
                          //   value={selectedUnitNo}
                          formikState={false}
                          emptyOption={false}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="form-group">
                        <CustomSelectInput
                          name="manager"
                          label="Select Manager"
                          //   handleBlur={handleBlur}
                          //   handleChange={handleChange}
                          //   valueExtractor={(item) =>
                          //     //item?.unit_number
                          //     `${item?.unit_name} - ${item?.tenant_name}`
                          //   }
                          //   setValue={setSelectedUnitNo}
                          //   options={tenantList}
                          //   value={selectedUnitNo}
                          formikState={false}
                          emptyOption={false}
                        />
                      </div>
                    </>
                  )}

                  {/* Input for Subject */}
                  <div className="form-group">
                    <CustomTextInput
                      name="subject"
                      label="Enter Subject"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched.subject}
                      errors={errors.subject}
                      value={values.subject}
                    />
                  </div>

                  {/* Text Area for Description */}
                  <div className="form-group form-group-full">
                    <CustomTextArea
                      name="description"
                      label="Enter Description"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      value={values.description}
                      errors={errors.description}
                      touched={touched.description}
                      required={true}
                    />
                  </div>

                  {/* Input for Start Date */}
                  <div className="form-group">
                    <CustomDateTimeInput
                      label="Start Date & Time"
                      value={startDate}
                      minDate={true}
                      customOnchange={(date) => setStartDate(date)}
                    />
                  </div>

                  {/* Input for End Date */}
                  <div className="form-group">
                    <CustomDateTimeInput
                      label="End Date & Time"
                      value={endDate}
                      minDate={true}
                      customOnchange={(date) => setEndDate(date)}
                    />
                  </div>

                  <div className="form-group">
                    <CustomSelectInput
                      name="frequency"
                      label="Select Frequency"
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      valueExtractor={(item) => item?.frequency}
                      setValue={setSelectFrequency}
                      options={frequencyList}
                      value={selectFrequency}
                      formikState={false}
                      emptyOption={false}
                    />
                  </div>

                  {selectFrequency?.frequency === "Hourly" && (
                    <div className="form-group">
                      <CustomSelectInput
                        name="hours"
                        label="Choose Hours"
                        //   handleBlur={handleBlur}
                        //   handleChange={handleChange}
                        //   valueExtractor={(item) =>
                        //     //item?.unit_number
                        //     `${item?.unit_name} - ${item?.tenant_name}`
                        //   }
                        //   setValue={setSelectedUnitNo}
                        //   options={tenantList}
                        //   value={selectedUnitNo}
                        formikState={false}
                        emptyOption={false}
                      />
                    </div>
                  )}

                  {selectFrequency?.frequency === "Daily" && (
                    <div className="form-group daily-time">
                      <div className="time-input">
                        <label className="font_s fw_4 font_lightgrey px-3 pt-2">
                          Enter Everyday Time{" "}
                          <span className="font_xs font_red">*</span>
                        </label>
                        <input
                          type="time"
                          value={time}
                          onChange={(e) => setTime(e.target.value)}
                        />
                      </div>
                      <div style={{ flex: "1" }}>
                        <CustomSelectInput
                          name="frequency"
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          valueExtractor={(item) => item?.name}
                          setValue={setSelectedMedian}
                          options={timeMedian}
                          value={selectedMedian}
                          formikState={false}
                          emptyOption={false}
                          required={false}
                        />
                      </div>
                    </div>
                  )}

                  {selectFrequency?.frequency === "Weekly" && (
                    <>
                      <div className="form-group daily-time">
                        <div className="time-input">
                          <label className="font_s fw_4 font_lightgrey px-3 pt-3">
                            Enter Everyday Time{" "}
                            <span className="font_xs font_red">*</span>
                          </label>
                          <input
                            type="text"
                            value={time}
                            onChange={(e) => setTime(e.target.value)}
                          />
                        </div>
                        <div style={{ flex: "1" }}>
                          <CustomSelectInput
                            name="frequency"
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            valueExtractor={(item) => item?.name}
                            setValue={setSelectedMedian}
                            options={timeMedian}
                            value={selectedMedian}
                            formikState={false}
                            emptyOption={false}
                            required={false}
                          />
                        </div>
                      </div>
                      <div className="form-group form-group-full weekly-day">
                        <div>
                          <p className="font_s font_grey fw_4 mt-2 px-3">
                            Select Days
                          </p>
                        </div>
                        <div className="week-check-box">
                          <div className="check">
                            <CustomCheckInput
                              value={monday}
                              setValue={setMonday}
                              //   customOnchange={(monday) => setSelectedWeekDay(monday)}
                            />
                            <span className="font_s check-text mx-1">
                              Monday
                            </span>
                          </div>

                          <div className="check">
                            <CustomCheckInput
                              value={tuesday}
                              setValue={setTuesday}
                            />
                            <span className="font_s check-text mx-1">
                              Tuesday
                            </span>
                          </div>
                          <div className="check">
                            <CustomCheckInput
                              value={wednesday}
                              setValue={setWednesday}
                            />
                            <span className="font_s check-text mx-1">
                              Wednesday
                            </span>
                          </div>
                          <div className="check">
                            <CustomCheckInput
                              value={thursday}
                              setValue={setThursday}
                            />
                            <span className="font_s check-text mx-1">
                              Thursday
                            </span>
                          </div>
                          <div className="check">
                            <CustomCheckInput
                              value={friday}
                              setValue={setFriday}
                            />
                            <span className="font_s check-text mx-1">
                              Friday
                            </span>
                          </div>
                          <div className="check">
                            <CustomCheckInput
                              value={saturday}
                              setValue={setSaturday}
                            />
                            <span className="font_s check-text mx-1">
                              Saturday
                            </span>
                          </div>
                          <div className="check">
                            <CustomCheckInput
                              value={sunday}
                              setValue={setSunday}
                            />
                            <span className="font_s check-text mx-1">
                              Sunday
                            </span>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  {selectFrequency?.frequency === "Monthly" && (
                    <>
                      <div className="form-group">
                        <CustomDateInput
                          label="Enter Date"
                          value={date}
                          customOnchange={(date) => setDate(date)}
                        />
                      </div>
                      <div className="form-group daily-time">
                        <div className="time-input">
                          <label className="font_s fw_4 font_lightgrey px-3 pt-3">
                            Enter Everyday Time{" "}
                            <span className="font_xs font_red">*</span>
                          </label>
                          <input
                            name="time"
                            type="text"
                            value={time}
                            onChange={(e) => setTime(e.target.value)}
                          />
                        </div>
                        <div style={{ flex: "1" }}>
                          <CustomSelectInput
                            name="frequency"
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            valueExtractor={(item) => item?.name}
                            setValue={setSelectedMedian}
                            options={timeMedian}
                            value={selectedMedian}
                            formikState={false}
                            emptyOption={false}
                            required={false}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {member === "Team" && (
                    <div className="form-group">
                      <CustomFileInput
                        label="Upload Document"
                        name="uploadDoc"
                        files={uploadDoc}
                        // handleChange={handleChange}
                        // handleBlur={handleBlur}
                        // errors={errors.uploadDoc}
                        // touched={touched.uploadDoc}
                        // values={values.uploadDoc}
                        // required={true}
                        // formikState={false}
                      />
                    </div>
                  )}
                </>
              </div>

              <div className="button-wrapper text-center">
                <>
                  <CustomButton type="submit" size="l">
                    Create
                  </CustomButton>
                  <TextButton
                    type="button"
                    onClick={onCancel}
                    className="font_m fw_6"
                  >
                    Cancel
                  </TextButton>
                </>
              </div>
            </form>
          )}
        </Formik>
      </div>

      {/* Success Modal */}
      {/* <SuccessModal
        showModal={showSuccessModal}
        description={modalDesc}
        title="Successfully Created"
        onClose={() => {
          onSuccess();
          setShowSuccessModal(false);
        }}
      />

      {/* Error Modal 
      <ErrorModal
        showModal={showErrorModal}
        description={modalDesc}
        title="Error"
        onClose={() => setShowErrorModal(false)}
      /> */}
    </div>
  );
};
/* Component Ends */
export default AddTask;
