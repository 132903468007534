/**
 * @auther Abdul Ahad <aabdul@dgrnte.com>
 * Slice to send OTP
 */

import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axios from '../axios';
import {API_URL} from '../../config/devKeys';
import {setLoading} from './unpersistedSlice';

export const sendOtp = createAsyncThunk(
  'otp',
  async (payload, {rejectWithValue, getState, dispatch}) => {
    try {
      dispatch(setLoading(true));
      const {data} = await axios.post(API_URL + '/otp', payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        },
      });
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  },
);

const otpSlice = createSlice({
  name: 'otp',
  initialState: {},
});

export default otpSlice.reducer;
