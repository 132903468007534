/**
 *
 */

import React, { useRef, useState, useEffect } from "react";

/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import MUI component starts */
/* Import MUI component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
/* Import local pages and component ends */

import "./style.scss";
/* Component starts */
const CustomDropDown = (props) => {
  /* Props destructuring starts */
  const { triggerElement, children, className = "" } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // Lock body scroll
  const lockScroll = () => {
    document.body.style.overflow = "hidden";
  };

  // Unlock body scroll
  const unlockScroll = () => {
    document.body.style.overflow = "";
  };

  // Toggle show and hide dropdown menu
  const toggleDropdown = () => {
    if (isMenuVisible) {
      setIsMenuVisible(false);
      unlockScroll();
    } else {
      setIsMenuVisible(true);
      lockScroll();
    }
  };

  // Hide dropdown on clicking outside
  const hideOnClickOutside = () => {
    setIsMenuVisible(false);
    unlockScroll();
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className={`custom-dropdown ${className}`} onClick={toggleDropdown}>
      {triggerElement}
      {isMenuVisible && (
        <>
          <div className="menu">{children}</div>
          <div onClick={hideOnClickOutside} className="back-drop"></div>
        </>
      )}
    </div>
  );
};
/* Component ends */

export default CustomDropDown;
