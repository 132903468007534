/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import GLOBAL from "../../../setup/constants/global";
/* Import configuration ends */

/* Import redux slices component starts */
import { sendOtp } from "../../../setup/store/slices/otpSlice";
import { deleteProperty } from "../../../setup/store/slices/propertySlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { DeleteRed } from "../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../../components/ui/button/IconButton/IconButton";
import SuccessModal from "../../../components/ui/modal/SuccessModal/SuccessModal";
import ErrorModal from "../../../components/ui/modal/ErrorModal/ErrorModal";
import ConfirmationModal from "../../../components/ui/modal/ConfirmationModal/ConfirmationModal";
import ConfirmationOTPModal from "../../../components/ui/modal/ConfirmationOTPModal/ConfirmationOTPModal";
/* Import local pages and component ends */

/* Component starts */
const Header = (props) => {
  /* Props destructuring starts */
  const { property } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [otpId, setOtpId] = useState();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [modalDesc, setModalDesc] = useState("");
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { communityId } = useParams();
  // Redux Selector

  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // On deleting property
  const onDelete = () => {
    setModalDesc("Are you sure you want to delete this property ?");
    setShowConfirmationModal(true);
  };

  //On confirming delete property
  const confirmDelete = async () => {
    // Collecting or creating payload data to be sent
    const payload = {
      action: "delete_property",
      resource_name: property.property_name,
    };

    // Backend response. Try, catch
    try {
      const result = await dispatch(sendOtp(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setModalDesc(result.payload);
          setShowConfirmationModal(false);
          setShowErrorModal(true);
          break;

        case GLOBAL.FULFILLED:
          setModalDesc("Enter the code sent to your email");
          setOtpId(result.payload.otp._id);
          setShowConfirmationModal(false);
          setShowOtpModal(true);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // On verifying otp for delete
  const onVerify = async (otp) => {
    if (otp.length !== 6) return;

    // Collecting or creating payload data to be sent
    const payload = {
      id: property._id,
      otp: otp,
      otpId: otpId,
      is_part_community: property.is_part_community,
      community_id: communityId || "",
    };

    // Backend response. Try, catch
    try {
      const result = await dispatch(deleteProperty(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setModalDesc(result.payload);
          setShowOtpModal(false);
          setShowErrorModal(true);
          break;

        case GLOBAL.FULFILLED:
          setModalDesc("Property has been successfully deleted");
          setShowOtpModal(false);
          setShowSuccessModal(true);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="header">
      <div className=""></div>
      <IconButton onClick={onDelete} className="option-button">
        <img src={DeleteRed} className="w_100 d_block" alt="Delete Icon" />
        <span className="font_s d_block font_red fw_5">Delete</span>
      </IconButton>

      {/* Modals */}
      {/* Confirmation Modal */}
      <ConfirmationModal
        showModal={showConfirmationModal}
        title="Delete Property"
        description={modalDesc}
        onClose={() => setShowConfirmationModal(false)}
        onConfirm={confirmDelete}
      />

      {/* OTP Modal */}
      <ConfirmationOTPModal
        showModal={showOtpModal}
        onVerify={onVerify}
        title="Enter OTP"
        description={modalDesc}
        onCancel={() => setShowOtpModal(false)}
      />

      {/* Success Modal */}
      <SuccessModal
        showModal={showSuccessModal}
        title="Property Deleted"
        description={modalDesc}
        onClose={() => navigate("/")}
      />

      {/* Error Modal */}
      <ErrorModal
        showModal={showErrorModal}
        title="Error"
        description={modalDesc}
        onClose={() => setShowErrorModal(false)}
      />
    </div>
  );
};
/* Component ends */

export default Header;
