/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useDispatch } from "react-redux";
import GLOBAL from "../../../../../setup/constants/global";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  showSuccessAlert,
  showErrorAlert,
} from "../../../../../setup/store/slices/globalAlertSlice";
import { updatePropertySettings } from "../../../../../setup/store/slices/propertySlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomNumberInput from "../../../../ui/input/CustomNumberInput/CustomNumberInput";
import CustomCheckInput from "../../../../ui/input/CustomCheckInput/CustomCheckInput";
import CustomButton from "../../../../ui/button/CustomButton/CustomButton";
import CustomRadioInput from "../../../../ui/input/CustomRadioInput/CustomRadioInput";
/* Import local pages and component ends */

/* Component starts */
const SecurityModule = (props) => {
  /* Props destructuring starts */
  const { user, settings, property_id } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [settingsChanged, setSettingsChanged] = useState(false);
  const [enabled, setEnabled] = useState();
  const [taxValue, setTaxValue] = useState();
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  // On updating setting
  const onUpdate = async () => {
    // Creating or collecting payload data to be sent
    const payload = {
      can_tenant_complain_security_request: enabled,
      property_id,
    };
    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      const result = await dispatch(updatePropertySettings(payload));
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result?.payload || "",
            })
          );
          break;

        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success!",
              description: result?.payload?.message || "",
            })
          );
          setSettingsChanged(false);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    if (settings) {
      setEnabled(settings?.can_tenant_complain_security_request);
    }
  }, [settings]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="collect-tax option">
      {/* Label */}
      <div className="label">
        <p className="font_m fw_5">Security Module</p>
      </div>

      {/* Setting */}
      <div className="setting">
        <div className="row1">
          <div className="accept-fee-wrapper">
            <p className="font_s fw_5 font_grey accept-fee mb_1">
              Endable Security Module ?
            </p>
            <div className="radio-wrapper">
              <CustomRadioInput
                label="Yes"
                className="radio-input"
                isSelected={enabled}
                onSelecting={() => {
                  setSettingsChanged(true);
                  setEnabled(true);
                }}
              />
              <CustomRadioInput
                label="No"
                className="radio-input"
                isSelected={!enabled}
                onSelecting={() => {
                  setSettingsChanged(true);
                  setEnabled(false);
                  setTaxValue(0);
                }}
              />
            </div>
          </div>
        </div>

        {/* Save Button */}
        {settingsChanged && (
          <div className="save-btn-wrapper mt_2">
            <CustomButton onClick={onUpdate} className="save-btn" size="s">
              Save
            </CustomButton>
          </div>
        )}
      </div>
    </div>
  );
};
/* Component ends */

export default SecurityModule;
