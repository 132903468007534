export const generateStatus = (status) => {
  let text = status;
  let color = status;

  switch (status) {
    case "expired":
      text = "Expired";
      color = "font_red";
      break;

    case "active":
      text = "Active";
      color = "font_green";
      break;
  }

  return {
    text,
    color,
  };
};
