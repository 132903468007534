/**
 *
 */

import React, { useState, useEffect } from "react";
// import DetailCard from "./sub-component/DetailCard";

/* Import configuration starts */
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  fetchTenantInvoiceById,
  getTenantInvoice,
} from "../../../../../setup/store/slices/invoiceSlice";

import {
  fetchUnitDetailByID,
  getUnitDetail,
} from "../../../../../setup/store/slices/unitSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import TenantInvoiceDetail from "./local-component/TenantInvoiceDetail";
import InvoiceReceiptDetail from "./local-component/InvoiceReceiptDetail";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const TenantInvoicingDetails = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const { id, unitId } = useParams();
  const dispatch = useDispatch();
  const invoice = useSelector(getTenantInvoice);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const defualtUnpaidStatus = [
    "unpaid",
    "not_collected",
    "partially_collected",
    "partial paid",
    "void",
  ];
  const defaultPaidStatus = [
    "paid",
    "collected",
    "settled",
    "partially_settled",
  ];
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    dispatch(fetchTenantInvoiceById(id));
  }, []);

  useEffect(() => {
    dispatch(fetchUnitDetailByID({ unit_id: unitId }));
  }, []);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="tenant-invoicing-report-component-wrapper container_sm">
      {/* Tenant Invoice */}
      {defualtUnpaidStatus?.includes(invoice?.invoice?.status) && (
        <TenantInvoiceDetail />
      )}

      {/* Tenant Receipt */}
      {defaultPaidStatus?.includes(invoice?.invoice?.status) && (
        <InvoiceReceiptDetail />
      )}
    </div>
  );
};
/* Component ends */

export default TenantInvoicingDetails;
