export const getAmmenities = (unit) => {
  let ammenities = [];
  if (unit.balcony) {
    ammenities.push(`${unit.balcony} Balcony`);
  }
  if (unit.bathroom) {
    ammenities.push(`${unit.bathroom} Bathroom`);
  }
  if (unit.kitchen) {
    ammenities.push(`${unit.kitchen} Kitchen`);
  }
  if (unit.laundry) {
    ammenities.push(`${unit.laundry} Laundry`);
  }
  if (unit.living_room) {
    ammenities.push(`${unit.living_room} Living Room`);
  }
  if (unit.maids_room) {
    ammenities.push(`${unit.maids_room} Maid's Room`);
  }
  if (unit.offices) {
    ammenities.push(`${unit.offices} Office`);
  }
  if (unit.pantry) {
    ammenities.push(`${unit.pantry} Pantry`);
  }
  if (unit.showroom) {
    ammenities.push(`${unit.showroom} Showroom`);
  }
  if (unit.store) {
    ammenities.push(`${unit.store} Store`);
  }
  if (unit.terrace) {
    ammenities.push(`${unit.terrace} Terrace`);
  }
  if (unit.toilet) {
    ammenities.push(`${unit.toilet} Toilet`);
  }
  return ammenities.join(", ");
};

export const ACTION_REQ_SECTION = {
  basic_details: "Basic Details",
  commercial_details: "Commercial Details",
  ids: "ID Verification",
  household_members: "Household Members",
  disability: "Disability",
  vehicles: "Vehicles",
  pets: "Pets",
  rental_history: "Rental History",
  initial_agreement: "Initial Agreement",
  utilities: "Utilities",
  contract: "Contract",
  contract_document: "Contract Document",
  contract_signature: "Contract Signature",
  move_in_date: "Move In Date",
  key: "Keys",
};
