/**
 *
 */

import React, { useState, useEffect, useRef } from "react";

/* Import configuration starts */
import { Formik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import GLOBAL from "../../../../setup/constants/global";
import {
  fieldValue,
  validation,
  addMoreDoc,
  removeDoc,
  addMoreDesc,
  removeDesc,
  prefill,
} from "./helper";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  getUser,
  getUserTheme,
} from "../../../../setup/store/slices/authSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Modal } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { RandomProperty, XWhite } from "../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
import TextButton from "../../../ui/button/TextButton/TextButton";
import CustomFileInput from "../../../ui/input/CustomFileInput/CustomFileInput";
import IconButton from "../../../ui/button/IconButton/IconButton";
import CustomTextInput from "../../../ui/input/CustomTextInput/CustomTextInput";
import Image from "../../../ui/other/Image/Image";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const UtilityDocNameAddModal = (props) => {
  /* Props destructuring starts */
  const { showModal, size = "lg", onClose, getDocNames } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [formValue, setFormValue] = useState(fieldValue);
  const [desc, setDesc] = useState([]);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const formRef = useRef();
  const userTheme = useSelector(getUserTheme);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // On submitting form
  const onSubmit = async (values) => {
    onClose();
    const documentName = [];
    for (let i = 0; i < desc.length; i++) {
      documentName.push(values[`description_${i}`]);
    }
    getDocNames(documentName);
  };
  // Deleting document from Previous document
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <Modal
      size={size}
      centered
      show={showModal}
      className="utility-doc-genrate-modal-wrapper"
    >
      <Modal.Body
        className={`${userTheme === "dark" ? "bg_darkTheme" : "bg_lightTheme"}`}
      >
        <div className="modal-header-custom">
          <p className="font_l fw_6 font_grey text-center">Clearence Document</p>
        </div>
        <div className="modal-body-custom">
          <Formik
            onSubmit={onSubmit}
            initialValues={formValue}
            validate={(values) => validation(values, desc)}
            validateOnBlur={true}
            enableReinitialize={true}
            innerRef={formRef}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              setFieldTouched,
            }) => (
              <form onSubmit={handleSubmit}>
                {/* Form Fields */}
                <div className="form-wrapper form-wrapper-flex">
                  {/* Description and Estimate */}
                  <div className="w_100 description">
                    <h3 className="font_blue font_m fw_5">
                      Add Utility Document Name
                    </h3>

                    {desc.length === 0 && (
                      <h3 className="font_yellow font_m fw_5 text-center attachments-not-added">
                        No Utility Document Name added !
                      </h3>
                    )}
                    {desc.map((item, index) => (
                      <div key={index}>
                        <div className="form-group-full">
                          <CustomTextInput
                            label="Document Name"
                            name={`description_${item}`}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            value={values[`description_${item}`]}
                            touched={touched[`description_${item}`]}
                            errors={errors[`description_${item}`]}
                          />
                        </div>
                      </div>
                    ))}

                    {/* Add or Remove Field Action */}
                    <div className="action-btn w_100 text-end">
                      <TextButton
                        onClick={() =>
                          addMoreDesc(formRef, setFormValue, desc, setDesc)
                        }
                        className="fw_6 font_m font_blue v_middle"
                      >
                        + Add More
                      </TextButton>
                      {desc.length > 0 && (
                        <TextButton
                          onClick={() =>
                            removeDesc(formRef, setFormValue, desc, setDesc)
                          }
                          className="fw_6 font_m font_red v_middle"
                        >
                          Remove
                        </TextButton>
                      )}
                    </div>
                  </div>
                </div>

                {/* Submit and Cancel */}
                <div className="button-wrapper">
                  <>
                    <CustomButton
                      variant="red"
                      size="m"
                      onClick={onClose}
                      className="btn1"
                    >
                      Cancel
                    </CustomButton>
                    <CustomButton type="submit" size="m">
                      Submit
                    </CustomButton>
                  </>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </Modal.Body>
    </Modal>
  );
};
/* Component ends */

export default UtilityDocNameAddModal;
