/**
 *
 */

import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

/* Import configuration starts */
import { useDispatch, useSelector } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */

import {
  fetchVisitorLogsList,
  getVisitorLogsList,
} from "../../setup/store/slices/visitorLogBook";
import { getTenantsLists } from "../../setup/store/slices/securitySlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import Searchbar from "../../components/ui/other/Searchbar/Searchbar";
import VisitorLogTable from "../../components/module/VisitorLog/VisitorLogTable/VisitorLogTable";
import BreadCrumb from "../../components/ui/other/BreadCrumb/BreadCrumb";
/* Import local pages and component ends */

// styles sheet
import "./style.scss";

/* Component starts */
const VisitorLogBook = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [currentPage, setCurrentPage] = useState(1);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const { propertyId } = useParams();
  /* Other hooks declaration ends */

  // Redux Selectors
  const visitorLogsList = useSelector(getVisitorLogsList);
  const tenantList = useSelector(getTenantsLists);

  /* Component variable declaration / object destructure starts */

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    dispatch(
      fetchVisitorLogsList({ property_id: propertyId, page: currentPage })
    );
  }, [propertyId, currentPage]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */

  return (
    <>
      <BreadCrumb type="log_book" />
      <div className="visitor-wrapper">
        <div className="heading">
          <h2 className="font_xl fw_6">Visitors Log Book</h2>
          <Link to="add" className="font_m fw_6 d_block link">
            + Register Visitor
          </Link>
        </div>

        {/* <Searchbar /> */}

        <VisitorLogTable
          visitorList={visitorLogsList?.result}
          totalCount={visitorLogsList?.totalCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </>
  );
};
/* Component End */

export default VisitorLogBook;
