/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import {
  FaAngleRight,
  FaAngleLeft,
  FaAngleDoubleRight,
  FaAngleDoubleLeft,
} from "react-icons/fa";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const Pagination = (props) => {
  /* Props destructuring starts */
  const {
    itemsPerPage,
    totalItems,
    currentPage,
    setCurrentPage,
    type = null,
    paid,
    unPaid,
    overdue,
    released,
    adjusted,
    currency,
    totalAmount,
    voidAmount,
    depositOption = false,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  let totalPage = Math.ceil(totalItems / itemsPerPage);
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  // const handleChange = (e) => {
  //   setValue(e.target.value);
  // };

  // // console.log(value, "VAL");

  const handlePrev = () => {
    // console.log("prv");
    if (currentPage > 1) setCurrentPage((prev) => prev - 1);
  };

  const handleNext = () => {
    // console.log("nxt");
    if (totalPage > currentPage) setCurrentPage((prev) => prev + 1);
  };

  // console.log(pageCount);

  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="pagination-wrapper">
      <div className="total">
        <div className="data">
          <div className="font_s font_grey fw_4">Total:</div>
          <div className="font_s fw_4 px-1">{totalItems}</div>
        </div>

        {type !== null && (
          <>
            <div className="data">
              <div className="font_s font_grey fw_4">Paid:</div>
              <div className="font_s fw_4 px-1 font_green">
                {paid !== "0"
                  ? `${paid?.toLocaleString("en-US")}${" "}${currency}`
                  : `${paid}${" "}${currency}`}
              </div>
            </div>

            <div className="data">
              <div className="font_s font_grey fw_4 ">Unpaid:</div>
              <div className="font_s fw_4 px-1 font_red">
                {unPaid !== "0"
                  ? `${unPaid?.toLocaleString("en-US")}${" "}${currency}`
                  : `${unPaid}${" "}${currency}`}
              </div>
            </div>

            <div className="data">
              <div className="font_s font_grey fw_4 ">Void:</div>
              <div className="font_s fw_4 px-1 font_red">
                {voidAmount !== "0"
                  ? `${voidAmount?.toLocaleString("en-US")}${" "}${currency}`
                  : `${voidAmount}${" "}${currency}`}
              </div>
            </div>

            <div className="data">
              <div className="font_s font_grey fw_4 ">Total:</div>
              <div className="font_s fw_4 px-1 font_yellow">
                {totalAmount !== "0"
                  ? `${totalAmount?.toLocaleString("en-US")}${" "}${currency}`
                  : `${totalAmount}${" "}${currency}`}
              </div>
            </div>

            {depositOption && (
              <div className="data">
                <div className="font_s font_grey fw_4">Released:</div>
                <div className="font_s fw_4 px-1">
                  {/* {released?.toLocaleString("en-US")} {currency} */}
                  {released !== "0"
                    ? `${released?.toLocaleString("en-US")}${" "}${currency}`
                    : `${released}${" "}${currency}`}
                </div>
              </div>
            )}

            {depositOption && (
              <div className="data">
                <div className="font_s font_grey fw_4">Adjusted:</div>
                <div className="font_s fw_4 px-1">
                  {/* {adjusted?.toLocaleString("en-US")} {currency} */}
                  {adjusted !== "0"
                    ? `${adjusted?.toLocaleString("en-US")}${" "}${currency}`
                    : `${adjusted}${" "}${currency}`}
                </div>
              </div>
            )}
          </>
        )}
      </div>

      <div className="other-option">
        {/* <div className="drop-down-opt">
          <label htmlFor="number-dd" className="font_s font_grey fw_4 py-2">
            Items per page:
          </label>
          <select
            id="number-dd"
            name="number"
            className="select-opt"
            value={value}
            onChange={(e) => handleChange(e)}
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={75}>75</option>
            <option value={100}>100</option>
          </select>
        </div> */}
        <div className="font_s font_grey fw_4">
          {" "}
          {currentPage} of {totalPage}
        </div>
        <div className="option-btn">
          <span className="font_s font_grey fw_4">
            <FaAngleDoubleLeft
              size={20}
              className="icon"
              onClick={() => setCurrentPage(1)}
            />
          </span>
          <span className="font_s font_grey fw_4">
            <FaAngleLeft
              size={20}
              className={currentPage > 1 ? "icon" : "disable-btn"}
              onClick={() => handlePrev()}
            />
          </span>

          <span className="font_s font_grey fw_4">
            <FaAngleRight
              size={20}
              className={totalPage > currentPage ? "icon" : "disable-btn"}
              onClick={() => handleNext()}
            />
          </span>
          <span className="font_s font_grey fw_4">
            <FaAngleDoubleRight
              size={20}
              className="icon"
              onClick={() => setCurrentPage(totalPage)}
            />
          </span>
        </div>
      </div>
    </div>
  );
};
/* Component ends */

export default Pagination;
