import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  locale: "en",
  rtl: false
};

export const localeSlice = createSlice({
  name: "locale",
  initialState,
  reducers: {
    changeLocale: (state, action) => {
      state.locale = action.payload;
      state.rtl = action.payload === 'ar'
    },
  },
});

export const { changeLocale } = localeSlice.actions;

export const selectedLocale = (state) => state.locale.locale;
export const isLayoutRtl = (state) => state.locale.rtl;

export default localeSlice.reducer;
