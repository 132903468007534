export const initialValues = {
  short_desc: "",
  long_desc: "",
  location_found: "",
  found_by: "",
  photos: [null],
};

export const validations = (values) => {
  const error = {};
  /* Validation for Short Description starts */
  if (values.short_desc === "") {
    error.short_desc = "Short Description is required";
  }
  /* Validation for Short Description ends */
  /* ******************************************************************************* */

  /* Validation for Long Description starts */
  if (values.long_desc === "") {
    error.long_desc = "Long Description is required";
  }
  /* Validation for Long Description ends */
  /* ******************************************************************************* */

  /* Validation for Location Found starts */
  if (values.location_found === " ") {
    error.location_found = "Location Found is required";
  }
  /* Validation for Location Found ends */
  /* ******************************************************************************* */

  /* Validation for Found By starts */
  if (values.found_by === " ") {
    error.found_by = "Found By is required";
  }
  /* Validation for Found By ends */
  /* ******************************************************************************* */
};

// Field Prop option extractor
export const fieldPropExtractor = (option, index) => {
  if (option?.photos?.length > 0) return option?.photos[index];
  else return undefined;
};
