/*
 * Slice to handle MOVES DASHBOARD Module
 */

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_URL } from "../../config/devKeys";
import { setLoading } from "./unpersistedSlice";

const initialState = {
  movesList: [],
  propertyMovesList: [],
  communityMovesList: [],
  searchedMovesList: [],
  // annoucement: null,
};

// FETCH MOVES DASHBOARD LIST - Client Side (HOME)
export const fetchMovesList = createAsyncThunk(
  "dashboard_home/dashboard_moves_listData",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(
        `${API_URL}/dashboard/dashboard_moves_listData`,
        {
          method: "POST",
          headers: headers,
        }
      );

      const data = await response.json();

      dispatch(setLoading(false));

      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// FETCH MOVES DASHBOARD LIST - Client Side  (Property_ID)
export const fetchMovesListBasedProperty = createAsyncThunk(
  "dashboard_property/dashboard_moves_listData",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(
        `${API_URL}/dashboard/dashboard_moves_listData`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            property_id: payload?.property_id,
          }),
        }
      );

      const data = await response.json();
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// FETCH MOVES DASHBOARD LIST - Client Side  (Community_ID)
export const fetchMovesListBasedCommunity = createAsyncThunk(
  "dashboard_community/dashboard_moves_listData",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(
        API_URL + `/dashboard/dashboard_moves_listData`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            community_id: payload?.community_id,
          }),
        }
      );

      const data = await response.json();

      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// FETCH SEARCHED MOVES DASHBOARD LIST - Client Side (HOME)
export const fetchSearchedMovesList = createAsyncThunk(
  "dashboard_search/dashboard_moves_listData",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(
        `${API_URL}/dashboard/dashboard_moves_listData?searchKey=${payload.searchKey}&start_date=${payload.start_date}&end_date=${payload.end_date}`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            property_id: payload?.property_id,
            community_id: payload?.community_id,
          }),
        }
      );

      const data = await response.json();

      dispatch(setLoading(false));

      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// FETCH ANNOUCEMENT DATA BASED ON ID  - (ANNOUCEMENT_ID)
// export const fetchAnnoucementById = createAsyncThunk(
//   "announcement/get_annoucement_by_id",
//   async (payload, { rejectWithValue, getState, dispatch }) => {
//     try {
//       dispatch(setLoading(true));
//       const headers = {
//         Authorization: `Bearer ${localStorage.getItem("user_token")}`,
//         "Content-Type": "application/json",
//       };

//       const response = await fetch(
//         `${API_URL}/announcement/get_annoucement_by_id?id=${payload}`,
//         {
//           method: "GET",
//           headers: headers,
//         }
//       );

//       const data = await response.json();

//       dispatch(setLoading(false));

//       if (data.success) {
//         return data.data;
//       } else {
//         return rejectWithValue(data.error.message);
//       }
//     } catch (error) {
//       dispatch(setLoading(false));
//       return rejectWithValue(error?.response);
//     }
//   }
// );

export const movesDashboardSlice = createSlice({
  name: "moveDash",
  initialState,
  reducers: {
    clearSearchMoves: (state, action) => {
      state.movesList = [];
    },
  },
  extraReducers: {
    [fetchMovesList.rejected]: (state, action) => {
      state.movesList = [];
    },
    [fetchMovesList.fulfilled]: (state, action) => {
      state.movesList = action.payload;
    },
    [fetchMovesListBasedProperty.rejected]: (state, action) => {
      state.propertyMovesList = [];
    },
    [fetchMovesListBasedProperty.fulfilled]: (state, action) => {
      state.propertyMovesList = action.payload;
    },
    [fetchMovesListBasedCommunity.rejected]: (state, action) => {
      state.communityMovesList = [];
    },
    [fetchMovesListBasedCommunity.fulfilled]: (state, action) => {
      state.communityMovesList = action.payload;
    },
    [fetchSearchedMovesList.rejected]: (state, action) => {
      state.searchedMovesList = [];
    },
    [fetchSearchedMovesList.fulfilled]: (state, action) => {
      state.searchedMovesList = action.payload;
    },
  },
});

// To Get The MOVES List For Home
export const getMovesList = (state) => state.moveDash.movesList;

// To Get The MOVES List For Property
export const getPropertyMovesList = (state) => state.moveDash.propertyMovesList;

// To Get The MOVES List For Community
export const getCommunityMovesList = (state) =>
  state.moveDash.communityMovesList;

// To Get The SEARCH MOVES List For Community
export const getSeacrhedMovesList = (state) => state.moveDash.searchedMovesList;

export const { clearSearchMoves } = movesDashboardSlice.actions;

// // To Get The Annoucement Based On ID
// export const getAnnoucementById = (state) => state.annoucement.annoucement;

export default movesDashboardSlice.reducer;
