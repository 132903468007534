/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import GLOBAL from "../../../setup/constants/global";
import { formatCurrency } from "../../../setup/utils/global-helper";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import MUI component starts */
/* Import MUI component ends */

/* Import image and SVG starts */
import { DocFilledWhite, DocumentGreen } from "../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../../components/ui/button/IconButton/IconButton";
import FileViewerModal from "../../../components/ui/modal/FileViewerModal/FileViewerModal";
/* Import local pages and component ends */

/* Component starts */
const DetailAgeBox = (props) => {
  /* Props destructuring starts */
  const { unitHistory } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  // const [isResidential, setIsResidential] = useState(false);
  // const [showFileViwer, setShowFileViwer] = useState(false);
  // const [file, setFile] = useState({});
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  // useEffect(() => {
  //   if (unit?.property_type.length > 0) {
  //     setIsResidential(
  //       unit?.property_type[0]?.name === GLOBAL.PROPERTY_TYPE.RESIDENTIAL
  //     );
  //   }
  // }, [unit]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="unit-detail-box">
      <h3 className="unitAge-title fw_6 font_l text-center">Unit Status</h3>
      <div className="unitAge-details-content">
        {unitHistory && (
          <p className="font_m font_grey fw_5">
            Unit Age :{" "}
            <span className="font_yellow font_l fw_6">
              {`Unit is ${unitHistory?.current_status} since past ${unitHistory?.unit_age_in_days} day(s)`}{" "}
            </span>
          </p>
        )}
      </div>
    </div>
  );
};
/* Component ends */

export default DetailAgeBox;
