/**
 * This component is for custom text input field
 */

import React from "react";

/* Import configuration starts */
import { blockInvalidChar, blockInvalidCharNoDot } from "../helper";
/* Import configuration ends */

import "../style2.scss";
import { getUserTheme } from "../../../../../setup/store/slices/authSlice";
import { useSelector } from "react-redux";

/* Component starts */
const V2 = (props) => {
  /* Props destructuring starts */
  const {
    type = "text",
    label,
    handleChange = null,
    setValue = null,
    value,
    name,
    errors,
    handleBlur,
    touched,
    required = true,
    disableDot = true,
    inputClass,
  } = props;
  /* Props destructuring ends */

  const userTheme = useSelector(getUserTheme);

  /* Component function definition starts */
  const changeHandler = (e) => {
    const inputValue = e.target.value;

    if (disableDot && inputValue[inputValue.length - 1] === ".") return false;

    if (isNaN(Number(inputValue))) {
      return false;
    } else {
      if (
        inputValue[0] === "0" &&
        inputValue[1] !== undefined &&
        inputValue[1] !== "."
      ) {
        return false;
      } else {
        handleChange && handleChange(e);
        setValue && setValue(inputValue);
      }
    }
  };
  /* Component function definition ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="custom-form-control custom-num-input-2">
      <div className="form-input-wrapper">
        <label className="font_s font_grey">
          {label && <label className="font_s font_grey">{label}</label>}
        </label>
        <input
          onKeyDown={disableDot ? blockInvalidCharNoDot : blockInvalidChar}
          type={type}
          onBlur={handleBlur}
          onChange={changeHandler}
          value={value}
          name={name}
          // className={`bg_black font_xs ${inputClass}`}
          className={` font_xs ${
            userTheme === "light"
              ? // || userTheme === "automatic"
                "bg_lightThemeInput font_lightTheme"
              : "bg_black"
          } ${inputClass}`}
          autoComplete="off"
        />
      </div>

      {touched && errors && <p className="error font_xxs font_red">{errors}</p>}
    </div>
  );
};
/* Component ends */

export default V2;
