/**
 *
 */

import React from "react";

/* Import configuration starts */
import moment from "moment";
import { useNavigate } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Table } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import { EyeFilledBlue } from "../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import GLOBAL from "../../../../../setup/constants/global";
import IconButton from "../../../../ui/button/IconButton/IconButton";
import Pagination from "../../../../ui/other/Pagination/Pagination";
/* Import local pages and component ends */

// Styles
import "./style.scss";

/* Component starts */
const TenantSecurityTable = (props) => {
  /* Props destructuring starts */
  const { complainList, totalCount, currentPage, setCurrentPage } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */

  const navigate = useNavigate();

  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";
  const TIME_FORMAT = USER_DATA ? USER_DATA?.time_format : "";
  const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  // To View The Item Report
  const handleViewReport = async (id) => {
    navigate(`${id}`);
  };

  const getStatus = (status) => {
    let txt_status = status;
    let statusColor = "";
    switch (status) {
      case GLOBAL.SECURITY_STATUS.PENDING_SO_ASSIGNMENT:
      case GLOBAL.SECURITY_STATUS.PENDING_DECISION:
        statusColor = "font_yellow";
        txt_status = "Pending Decision";
        break;
      case GLOBAL.SECURITY_STATUS.ESCALATED:
        statusColor = "font_yellow";
        txt_status = "Escalated";
        break;
      case GLOBAL.SECURITY_STATUS.CLOSED:
        statusColor = "font_green";
        break;
      case GLOBAL.SECURITY_STATUS.SO_ASSIGNED:
        statusColor = "font_yellow";
        txt_status = "Security Officer Assigned";
        break;
      case GLOBAL.SECURITY_STATUS.RESOLVED:
        statusColor = "font_green";
        txt_status = "resolved";
        break;
    }
    return (
      <span className={`font_s fw_4 ${statusColor}`}>
        {txt_status.toUpperCase()}
      </span>
    );
  };

  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */

  return (
    <>
      <div className="custom-table-wrapper tenant-security-table">
        {complainList?.length > 0 && (
          <Table className="custom-table font_s" borderless responsive>
            {/* Table Heading */}
            <thead>
              <tr>
                <th>Complain ID</th>
                <th>Complain Type</th>
                <th>Requested Date</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            {/* Table Body */}
            <tbody>
              {complainList?.map((item) => (
                <tr key={item._id}>
                  {/* Complain ID */}
                  <td>{item?.ticket_id}</td>

                  {/* Complain Type */}
                  <td>{item?.security_complaint_id?.name}</td>

                  {/* Requested Date */}
                  <td>{moment(item?.createdAt).format(DATE_TIME_FORMAT)}</td>

                  {/* Status */}
                  <td>{getStatus(item?.status)}</td>

                  {/* Actions */}
                  <td className="text-start">
                    <IconButton
                      onClick={() => handleViewReport(item?._id)}
                      className="action-button"
                    >
                      <img
                        src={EyeFilledBlue}
                        className="h_100"
                        alt="View Details"
                      />
                    </IconButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}

        {complainList?.length === 0 && (
          <div className=" text-center">
            <h3 className=" font_m font_yellow fw_6">No Data</h3>
          </div>
        )}
      </div>

      {/* PAGINATION */}
      {complainList?.length > 0 && (
        <Pagination
          itemsPerPage="10"
          totalItems={totalCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
    </>
  );
};
/* Component Ends */
export default TenantSecurityTable;
