/**
 * Utility to display toast
 */

import { toast } from "react-toastify";

export const Toastify = (type = "success", message) => {
  switch (type) {
    case "error":
      toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: { backgroundColor: "#e74c3c", color: "white" },
      });
      break;

    case "info":
      toast.info(message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        // style: { backgroundColor: "#07bc0c", color: "white" },
      });
      break;

    default:
      toast.success(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: { backgroundColor: "#07bc0c", color: "white" },
      });
      break;
  }
};
