/**
 *
 */

import React, { useState, useEffect, useRef } from "react";

/* Import configuration starts */
import { Formik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import GLOBAL from "../../../../setup/constants/global";
import { useParams } from "react-router-dom";
import {
  fieldValue,
  validation,
  addMoreDesc,
  removeDesc,
  addMoreImg,
  removeImg,
} from "./helper";
import { formatCurrency } from "../../../../setup/utils/global-helper";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  getUser,
  getUserTheme,
} from "../../../../setup/store/slices/authSlice";
import {
  showSuccessAlert,
  showErrorAlert,
} from "../../../../setup/store/slices/globalAlertSlice";
import {
  mtFillInitialReport,
  mtFillFinalReport,
  fillT1InitCard,
  fillT1FinlCard,
} from "../../../../setup/store/slices/moveInSlice";
import { pmRespondTenantKeyRejection } from "../../../../setup/store/slices/tenantSlice";
import {
  mtFillInitialReport_moveout,
  ownerFillInitialReport_moveout,
} from "../../../../setup/store/slices/moveOutSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Modal } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import {
  RandomProperty,
  XGreen,
  XWhite,
} from "../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
import TextButton from "../../../ui/button/TextButton/TextButton";
import CustomFileInput from "../../../ui/input/CustomFileInput/CustomFileInput";
import IconButton from "../../../ui/button/IconButton/IconButton";
import CustomTextInput from "../../../ui/input/CustomTextInput/CustomTextInput";
import Image from "../../../ui/other/Image/Image";
import CustomNumberInput from "../../../ui/input/CustomNumberInput/CustomNumberInput";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const UIRCP1 = (props) => {
  /* Props destructuring starts */
  const {
    maintenance_id,
    showModal,
    size = "lg",
    onClose,
    maintenanceType,
    initialURIC,
    finalUIRC,
    reviewByFM,
    keyRejection,
    data = null,
    applicationID,
    maintenance,
    isT1,
    currency,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [formValue, setFormValue] = useState(fieldValue);
  const [docs, setDocs] = useState([]);
  const [desc, setDesc] = useState([]);
  const [prevDocs, setPrevDocs] = useState([]);
  const [error, setError] = useState({
    show: false,
    message: "",
  });
  const [descList, setDescList] = useState([]);
  const [imgList, setImgList] = useState([]);
  const [totalEstimation, setTotalEstimation] = useState(0);
  const [estimationArray, setEstimationArray] = useState([]);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const formRef = useRef();
  const { propertyId } = useParams();

  // Redux Selector
  const user = useSelector(getUser);
  const userTheme = useSelector(getUserTheme);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const assementCycle =
    initialURIC?.status === "Approved" ? "final" : "initial";
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // On assiging technician to the UIRC Phase 1 Initial
  const initialInspection = async ({ description, document }) => {
    onClose();
    // Creating or collecting payload data to be sent
    const payload = {
      maintenance_id,
      type: maintenanceType,
      description,
      document,
      readiness_card: initialURIC._id,
      property_id: propertyId,
    };

    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      let result;
      if (isT1) {
        result = await dispatch(fillT1InitCard(payload));
      } else {
        result = await dispatch(mtFillInitialReport(payload));
      }
      console.log("Response", result);
      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;
        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // On assiging technician to the UIRC Phase 1 Initial for moveout
  const initialInspection_moveout = async ({ description, document }) => {
    onClose();
    // Creating or collecting payload data to be sent
    const payload = {
      maintenance_id,
      type: maintenanceType,
      readiness_card: initialURIC._id,
      property_id: propertyId,
      description,
      document,
    };

    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      let result;
      if (isT1) {
        result = await dispatch(ownerFillInitialReport_moveout(payload));
      } else {
        result = await dispatch(mtFillInitialReport_moveout(payload));
      }
      console.log("Response", result);
      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;
        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // On assiging technician to the UIRC Phase 1 Final
  const finalInspection = async ({ description, document }) => {
    onClose();
    // Creating or collecting payload data to be sent
    const payload = {
      maintenance_id,
      type: maintenanceType,
      readiness_card: finalUIRC._id,
      property_id: propertyId,
      description,
      document,
    };

    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      let result;
      if (isT1) {
        result = await dispatch(fillT1FinlCard(payload));
      } else {
        result = await dispatch(mtFillFinalReport(payload));
      }
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;
        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // PM creating task due to key rejction
  const pmCreateTask = async ({ description, document }) => {
    onClose();
    // Creating or collecting payload data to be sent
    const payload = {
      maintenance_id,
      application_id: applicationID,
      type: maintenanceType,
      status: "Accepted",
      property_id: propertyId,
      description,
      document,
    };

    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      const result = await dispatch(pmRespondTenantKeyRejection(payload));
      console.log("Response", result);
      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description: result.payload,
            })
          );
          break;
        case GLOBAL.FULFILLED:
          dispatch(
            showSuccessAlert({
              title: "Success",
              description: result.payload.message,
            })
          );
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  // On submitting form
  const onSubmit = async (values) => {
    // setFormValue({});
    // setDesc([]);
    // setDocs([]);
    setError({ show: false, message: "" });
    if (descList.length === 0) {
      onClose();
      dispatch(
        showErrorAlert({
          title: "Error",
          description: "UIRC form should not be empty",
        })
      );
      return;
    }

    const description = [];
    const document = [];

    descList.forEach((descItem, descIndx) => {
      if (isT1) {
        description.push({
          description: values[`description_${descItem}`],
          estimate: values[`estimate_${descItem}`],
        });
      } else {
        description.push(values[`description_${descItem}`]);
      }

      imgList[descIndx].forEach((docItm, docIndex) => {
        const file = values[`document_${descItem}_${docItm}`];
        document.push({
          file,
          name: encodeURIComponent(`${descIndx}@file_name_${file.name}`),
        });
      });
    });

    console.log({ description, document }, "PAYLOAD");

    if (maintenanceType === "Move Out") {
      initialInspection_moveout({ description, document });
    } else if (keyRejection) {
      pmCreateTask({ description, document });
    } else if (reviewByFM) {
      if (assementCycle === "initial") {
        console.log({ description, document }, "PAYLOAD");
      } else {
        console.log({ description, document }, "PAYLOAD");
      }
    } else {
      if (assementCycle === "initial") {
        await initialInspection({ description, document });
      } else {
        await finalInspection({ description, document });
      }
    }
  };

  const calculateTotalEstimation = () => {
    let total = 0;
    estimationArray.forEach((item) => {
      total += item;
    });

    setTotalEstimation(total);
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    calculateTotalEstimation();
  }, [estimationArray]);
  /* Component useEffect ends */
  console.log("estimationArray", estimationArray);
  /* ************* Component rendering. JSX code ************* */
  return (
    <Modal
      size={size}
      centered
      show={showModal}
      className="uirc-p1-modal-wrapper"
    >
      <Modal.Body
        className={`${userTheme === "dark" ? "bg_darkTheme" : "bg_lightTheme"}`}
      >
        <div className="modal-header-custom">
          <p className="font_l fw_6 font_blue text-center">
            {/* Initial Unit Readiness Phase1 Form  */}
            {/* Unit Readiness Final Assessment {reviewByFM && "Review"} */}
            {maintenanceType === "Move Out" ? (
              "Unit Readiness Initial Assessment"
            ) : (
              <span className="font_blue">
                Unit Readiness{" "}
                {assementCycle === "initial" ? "Initial" : "Final"} Assessment
              </span>
            )}
          </p>
        </div>
        <div className="modal-body-custom">
          <Formik
            onSubmit={onSubmit}
            initialValues={formValue}
            validate={(values) => validation(values, descList, imgList, isT1)}
            validateOnBlur={true}
            enableReinitialize={true}
            innerRef={formRef}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              setFieldTouched,
            }) => (
              <form onSubmit={handleSubmit}>
                {/* Form Fields */}
                <div className="form-wrapper form-wrapper-flex">
                  {/* Description and Estimate */}
                  <div className="w_100 description">
                    {!reviewByFM && (
                      <h3 className="font_blue font_l fw_5">
                        Add Inspection Item
                      </h3>
                    )}
                    {descList.length === 0 && (
                      <h3 className="font_yellow font_m fw_5 text-center attachments-not-added">
                        No Inspection Item added !
                      </h3>
                    )}
                    {descList.map((item, index) => (
                      <div className="task_item_wrapper">
                        <p className="title-number font_m font_blue fw_5">
                          Item #{index + 1}
                        </p>
                        <div key={index} className="task_item">
                          <div className="form-group-full">
                            <CustomTextInput
                              label="Task Description"
                              name={`description_${item}`}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              value={values[`description_${item}`]}
                              touched={touched[`description_${item}`]}
                              errors={errors[`description_${item}`]}
                            />
                          </div>

                          <div className="img-list">
                            <h3 className="add-attachments font_blue font_m fw_5">
                              Attachments For Item #{index + 1}
                            </h3>
                            {imgList[index]?.length === 0 && (
                              <h3 className="no-img-added-txt text-center font_yellow font_m fw_6">
                                No Images Added !
                              </h3>
                            )}
                            {imgList[index]?.map((docItem, docIndex) => (
                              <div className="doc_item">
                                <div className="form-goup-flex">
                                  <CustomFileInput
                                    name={`document_${item}_${docItem}`}
                                    label="Attachment"
                                    setFieldTouched={setFieldTouched}
                                    setFieldValue={setFieldValue}
                                    value={
                                      values[`document_${item}_${docItem}`]
                                    }
                                    touched={
                                      touched[`document_${item}_${docItem}`]
                                    }
                                    errors={
                                      errors[`document_${item}_${docItem}`]
                                    }
                                    formikState={true}
                                    acceptable={true}
                                    acceptType="image/*,video/*"
                                  />
                                </div>

                                {imgList[index]?.length > 0 && (
                                  <TextButton
                                    onClick={() =>
                                      removeImg(
                                        formRef,
                                        setFormValue,
                                        imgList,
                                        setImgList,
                                        descList,
                                        index,
                                        docIndex
                                      )
                                    }
                                    className="fw_6 font_m font_red v_middle remove-img-btn"
                                  >
                                    Remove Attachment
                                  </TextButton>
                                )}
                              </div>
                            ))}
                            <div className="add-img-btn-wrapper text-end">
                              <TextButton
                                onClick={() =>
                                  addMoreImg(
                                    formRef,
                                    setFormValue,
                                    imgList[index],
                                    setImgList,
                                    descList[index],
                                    index
                                  )
                                }
                                className="fw_6 font_m font_blue v_middle add-img-btn"
                              >
                                + Add Attachments
                              </TextButton>
                            </div>
                          </div>

                          {/* {console.log(descList, "DESC LIST")} */}
                          {isT1 && (
                            <div className="form-group">
                              <CustomNumberInput
                                label={`Estimate (${currency})`}
                                name={`estimate_${item}`}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                value={values[`estimate_${item}`]}
                                touched={touched[`estimate_${item}`]}
                                errors={errors[`estimate_${item}`]}
                                customOnChange={(v) =>
                                  setEstimationArray((p) => {
                                    const d = [...p];
                                    d[index] = v !== "" ? parseInt(v) : 0;
                                    return d;
                                  })
                                }
                              />
                            </div>
                          )}
                        </div>
                        {descList.length > 0 && (
                          <TextButton
                            onClick={() => {
                              removeDesc(
                                formRef,
                                setFormValue,
                                descList,
                                setDescList,
                                imgList,
                                setImgList,
                                index
                              );
                              setEstimationArray((p) => {
                                const d = [...p];
                                d.splice(index, 1);
                                return d;
                              });
                            }}
                            className="fw_6 font_m font_red v_middle remove-btn"
                          >
                            Remove Item #{index + 1}
                          </TextButton>
                        )}
                      </div>
                    ))}

                    {/* Add or Remove Field Action */}
                    {!reviewByFM && (
                      <div className="action-btn w_100 text-end">
                        <TextButton
                          onClick={() => {
                            addMoreDesc(
                              formRef,
                              setFormValue,
                              descList,
                              setDescList,
                              setImgList
                            );
                            setEstimationArray((p) => [...p, 0]);
                          }}
                          className="fw_6 font_m font_blue v_middle"
                        >
                          + Add Item
                        </TextButton>
                      </div>
                    )}
                  </div>
                </div>

                <hr className="font_grey" />

                <div className="total-value">
                  <p className="font_m font_grey fw_6">
                    Total Estimation :{" "}
                    <span className="font_blue">
                      {/* {formatCurrency(totalEstimation)} */}
                      {totalEstimation?.toLocaleString("en-US")} {currency}
                    </span>
                  </p>
                  <p className="total-item font_m font_grey fw_6">
                    Total Inspection Item :{" "}
                    <span className="font_yellow">{descList?.length}</span>
                  </p>
                </div>
                {/* Submit and Cancel */}
                <div className="button-wrapper">
                  <>
                    <CustomButton
                      variant="red"
                      size="m"
                      onClick={() => {
                        setError({ show: false, message: "" });
                        onClose();
                      }}
                      className="btn1"
                    >
                      Cancel
                    </CustomButton>
                    <CustomButton type="submit" size="m">
                      Submit
                    </CustomButton>
                  </>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </Modal.Body>
    </Modal>
  );
};
/* Component ends */

export default UIRCP1;
