/**
 * This component is for custom text input field
 */

import React from "react";

import "../style2.scss";
import { getUserTheme } from "../../../../../setup/store/slices/authSlice";
import { useSelector } from "react-redux";

/* Component starts */
const V2 = (props) => {
  /* Props destructuring starts */
  const {
    type = "text",
    label,
    handleChange,
    value,
    setValue,
    name,
    errors,
    handleBlur,
    touched,
    required = true,
    disabled = false,
    inputClass,
  } = props;
  /* Props destructuring ends */

  const userTheme = useSelector(getUserTheme);

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="custom-form-control-2">
      <div className="form-input-wrapper">
        <label className="font_s font_grey">
          {/* {label} */}
          {/* {required && <span className="font_m font_red"> *</span>} */}
        </label>
        <input
          type={type}
          onBlur={handleBlur}
          onChange={(e) => {
            handleChange && handleChange(e);
            setValue && setValue(e.target.value);
          }}
          value={value}
          name={name}
          // className={`bg_black font_xs ${inputClass}`}
          className={` font_xs ${
            userTheme === "light" || userTheme === "automatic"
              ? // || userTheme === "automatic"
                "bg_lightThemeInput font_lightTheme"
              : "bg_black"
          } ${inputClass}`}
          disabled={disabled}
          autoComplete="off"
        />
      </div>

      {touched && errors && <p className="error font_xxs font_red">{errors}</p>}
    </div>
  );
};
/* Component ends */

export default V2;
