/*
 * Slice to handle SPARE PARTS DASHBOARD Module
 */

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_URL } from "../../config/devKeys";
import { setLoading } from "./unpersistedSlice";

const initialState = {
  sparePartsList: [],
  propertySparePartsList: [],
  communitySparePartsList: [],
  searchedSparePartsList: [],
  // annoucement: null,
};

// FETCH SPARE PARTS DASHBOARD LIST - Client Side (HOME)
export const fetchSparePartsList = createAsyncThunk(
  "dashboard_home/dashboard_spareParts_listData",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(
        `${API_URL}/dashboard/dashboard_spareParts_listData`,
        {
          method: "POST",
          headers: headers,
        }
      );

      const data = await response.json();

      dispatch(setLoading(false));

      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// FETCH SPARE PARTS DASHBOARD LIST - Client Side  (Property_ID)
export const fetchSparePartsListBasedProperty = createAsyncThunk(
  "dashboard_property/dashboard_spareParts_listData",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(
        `${API_URL}/dashboard/dashboard_spareParts_listData`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            property_id: payload?.property_id,
          }),
        }
      );

      const data = await response.json();
      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// FETCH SPARE PARTS DASHBOARD LIST - Client Side  (Community_ID)
export const fetchSparePartsListBasedCommunity = createAsyncThunk(
  "dashboard_community/dashboard_spareParts_listData",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(
        API_URL + `/dashboard/dashboard_spareParts_listData`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            community_id: payload?.community_id,
          }),
        }
      );

      const data = await response.json();

      dispatch(setLoading(false));
      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// FETCH SEARCHED SPARE PARTS DASHBOARD LIST - Client Side (HOME)
export const fetchSearchedSparePartsList = createAsyncThunk(
  "dashboard_search/dashboard_spareParts_listData",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        "Content-Type": "application/json",
      };

      const response = await fetch(
        `${API_URL}/dashboard/dashboard_spareParts_listData?searchKey=${payload.searchKey}&start_date=${payload.start_date}&end_date=${payload.end_date}`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            property_id: payload?.property_id,
            community_id: payload?.community_id,
          }),
        }
      );

      const data = await response.json();

      dispatch(setLoading(false));

      if (data.success) {
        return data.data;
      } else {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

// FETCH ANNOUCEMENT DATA BASED ON ID  - (ANNOUCEMENT_ID)
// export const fetchAnnoucementById = createAsyncThunk(
//   "announcement/get_annoucement_by_id",
//   async (payload, { rejectWithValue, getState, dispatch }) => {
//     try {
//       dispatch(setLoading(true));
//       const headers = {
//         Authorization: `Bearer ${localStorage.getItem("user_token")}`,
//         "Content-Type": "application/json",
//       };

//       const response = await fetch(
//         `${API_URL}/announcement/get_annoucement_by_id?id=${payload}`,
//         {
//           method: "GET",
//           headers: headers,
//         }
//       );

//       const data = await response.json();

//       dispatch(setLoading(false));

//       if (data.success) {
//         return data.data;
//       } else {
//         return rejectWithValue(data.error.message);
//       }
//     } catch (error) {
//       dispatch(setLoading(false));
//       return rejectWithValue(error?.response);
//     }
//   }
// );

export const sparePartsDashboardSlice = createSlice({
  name: "sparePartDash",
  initialState,
  reducers: {
    clearSearchSpareParts: (state, action) => {
      state.sparePartsList = [];
    },
  },
  extraReducers: {
    [fetchSparePartsList.rejected]: (state, action) => {
      state.sparePartsList = [];
    },
    [fetchSparePartsList.fulfilled]: (state, action) => {
      state.sparePartsList = action.payload;
    },
    [fetchSparePartsListBasedProperty.rejected]: (state, action) => {
      state.propertySparePartsList = [];
    },
    [fetchSparePartsListBasedProperty.fulfilled]: (state, action) => {
      state.propertySparePartsList = action.payload;
    },
    [fetchSparePartsListBasedCommunity.rejected]: (state, action) => {
      state.communitySparePartsList = [];
    },
    [fetchSparePartsListBasedCommunity.fulfilled]: (state, action) => {
      state.communitySparePartsList = action.payload;
    },
    [fetchSearchedSparePartsList.rejected]: (state, action) => {
      state.searchedSparePartsList = [];
    },
    [fetchSearchedSparePartsList.fulfilled]: (state, action) => {
      state.searchedSparePartsList = action.payload;
    },
  },
});

// To Get The SPARE PARTS List For Home
export const getSparePartsList = (state) => state.sparePartDash.sparePartsList;

// To Get The SPARE PARTS List For Property
export const getPropertySparePartsList = (state) =>
  state.sparePartDash.propertySparePartsList;

// To Get The SPARE PARTS List For Community
export const getCommunitySparePartsList = (state) =>
  state.sparePartDash.communitySparePartsList;

// To Get The SEARCH SPARE PARTS List For Community
export const getSeacrhedSparePartsList = (state) =>
  state.sparePartDash.searchedSparePartsList;

export const { clearSearchSpareParts } = sparePartsDashboardSlice.actions;

// // To Get The Annoucement Based On ID
// export const getAnnoucementById = (state) => state.annoucement.annoucement;

export default sparePartsDashboardSlice.reducer;
