/**
 *
 */

import React, { useState } from "react";

/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import CustomCheckInput from "../../../components/ui/input/CustomCheckInput/CustomCheckInput";
/* Import local pages and component ends */

/* Component starts */
const Report = (props) => {
  /* Props destructuring starts */
  const { incident } = props;
  console.log(incident, "[]=[]=[]");
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [terms, setTerms] = useState("");
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="facility-report-detail">
      <div className="facility-main-wrapper">
        <div className="facility-data">
          <p className="font_m fw_4">Gym</p>
          <p className="font_s fw_4 font_grey ">
            Subscription to this facility is{" "}
            <span className="font_s font_blue fw_4">Paid - $ 250/monthly</span>
          </p>
          <p className="font_xxs font_grey fw_4">
            There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered alteration in some form, by injected
            humour, or randomised words which don't look even slightly
            believable.
          </p>
        </div>
        <div className="facility-img">
          <img
            src="https://as2.ftcdn.net/v2/jpg/03/51/09/29/1000_F_351092935_pOu0iS396drNWuY3P35h2Nz0A1KpiJkN.jpg"
            alt="Facility-Picture"
          />
        </div>
      </div>

      <p className="font_s fw_4">Select Payment Options</p>
      <p className="font_xs font_grey fw_4">Current Credit Cards</p>

      <div>CARDS</div>

      <div className="terms">
        <CustomCheckInput value={terms} setValue={setTerms} />
        <p className="font_s check-text">
          I agree and accept{" "}
          <span className="font_s check-text font_blue text-decoration-underline">
            Terms And Conditions
          </span>{" "}
        </p>
      </div>
    </div>
  );
};
/* Component Ends */
export default Report;
