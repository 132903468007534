/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { Chart } from "react-google-charts";

import { useSelector } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import { getUserTheme } from "../../../../setup/store/slices/authSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
/* Import local pages and component ends */

/* Component starts */
const Type3DPieChart = (props) => {
  /* Props destructuring starts */
  const { data, label } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const userTheme = useSelector(getUserTheme);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */

  const options = {
    // title: label,
    is3D: true,
    titleTextStyle: {
      color:
        userTheme === "light" || userTheme === "automatic"
          ? "font_lightTheme"
          : "white",
      // "white",
    },
    pieSliceText: "value",
    // slices: {
    //   1: { offset: 0.2 },
    //   3: { offset: 0.3 },
    //   5: { offset: 0.4 },
    //   7: { offset: 0.5 },
    // },
    legend: {
      position: "right",
      textStyle: {
        color:
          userTheme === "light" || userTheme === "automatic"
            ? "font_lightTheme"
            : "white",
        fontSize: 14,
      },
    },
    tooltip: {
      showColorCode: "true",
      text: "value",
    },
    fontName: "Roboto",
    backgroundColor:
      userTheme === "light" || userTheme === "automatic"
        ? "#F1FFFF"
        : "#262626",
    fontSize: 18,
    colors: [
      "#1097FF",
      "#D9AF58",
      "#1AD3AA",
      "#FE5D6E",
      "#37BDB0",
      "#FFBB28",
      "#FF8042",
      "#ff0000",
      "#00ff00",
      "#0000ff",
      "#8884d8",
      "#ffc658",
      "#82ca9d",
      "#F3BC00",
      "#BC5090",
    ], // To show our own custom Colors
  };

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="">
      <Chart
        chartType="PieChart"
        data={data}
        options={options}
        width={"100%"}
        height={"400px"}
      />
    </div>
  );
};
/* Component ends */

export default Type3DPieChart;
