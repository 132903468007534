/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import GLOBAL from "../../../../setup/constants/global";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import { pathNamePopped } from "../../../../setup/utils/global-helper";
import PaymentMethod from "../../Payment/PaymentMethod/PaymentMethod";
import TransactionStatus from "../../Payment/TransactionStatus/TransactionStatus";
import CustomRadioInput from "../../../ui/input/CustomRadioInput/CustomRadioInput";
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const ApplicationFeePayment = (props) => {
  /* Props destructuring starts */
  const {
    application_id,
    data,
    onPaymentSuccess,
    acceptOnlinePayment,
    property_settings,
    canCancelApplication = false,
    onCancelApplication,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [transactionStatus, setTransactionStatus] = useState({});
  const [showTransactionStatus, setShowTransactionStatus] = useState(false);

  const [payOption, setPayOption] = useState("");
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  const { pathname } = useLocation();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const getVat = () => {
    return `${
      property_settings?.application_fee_collect_vat
        ? (
            (property_settings?.application_fee_amount *
              property_settings?.collect_vat_value) /
            100
          ).toFixed(2)
        : 0
    } ${data.currency}`;
  };

  const getTax = () => {
    return `${
      property_settings?.application_fee_collect_tax
        ? (
            (property_settings?.application_fee_amount *
              property_settings?.collect_tax_value) /
            100
          ).toFixed(2)
        : 0
    } ${data.currency}`;
  };

  const getTotal = () => {
    const vat = property_settings?.application_fee_collect_vat
      ? (property_settings?.application_fee_amount *
          property_settings?.collect_vat_value) /
        100
      : 0;
    const tax = property_settings?.application_fee_collect_tax
      ? (property_settings?.application_fee_amount *
          property_settings?.collect_tax_value) /
        100
      : 0;
    return `${(property_settings?.application_fee_amount + vat + tax).toFixed(
      2
    )} ${data.currency}`;
  };

  const handleHomePage = () => {
    const newPathname = pathNamePopped(pathname, 4);
    navigate(`${newPathname}/home`);
  };

  /* Component function definition ends */

  /* Component useEffect starts */ /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="application-fee-payment-module-wrapper container_sm">
      <h2 className="heading text-center font_xxl mb-5">Application Fee</h2>

      {/* Showing transaction status after payment */}
      {showTransactionStatus && (
        <TransactionStatus
          onSuccessClose={() => {
            onPaymentSuccess();
            setShowTransactionStatus(false);
          }}
          onFailedClose={() => {
            setShowTransactionStatus(false);
          }}
          status={transactionStatus}
          currency={data?.currency}
        />
      )}

      {/* Payment interface */}
      {!showTransactionStatus && (
        <>
          <div className="amt">
            <p className="font_m fw_5">
              Application Fee Amount :{" "}
              <span className="font_blue">
                {property_settings.application_fee_amount} {data?.currency}
              </span>
            </p>
            {property_settings?.collect_vat_enabled &&
              property_settings?.application_fee_collect_vat && (
                <p className="font_m fw_5">
                  VAT :{" "}
                  <span className="font_blue">
                    {getVat()} [{property_settings?.collect_vat_value}%]
                  </span>
                </p>
              )}
            {property_settings?.collect_tax_enabled &&
              property_settings?.application_fee_collect_tax && (
                <p className="font_m fw_5">
                  Tax :{" "}
                  <span className="font_blue">
                    {getTax()} [{property_settings?.collect_tax_value}%]
                  </span>
                </p>
              )}
            {((property_settings?.collect_vat_enabled &&
              property_settings?.application_fee_collect_vat) ||
              (property_settings?.collect_tax_enabled &&
                property_settings?.application_fee_collect_tax)) && (
              <p className="font_m fw_5">
                Total : <span className="font_blue">{getTotal()}</span>
              </p>
            )}
          </div>
          <div className="form-wrapper">
            <div className="options-wrapper">
              <CustomRadioInput
                label="Offline"
                onSelecting={() => {
                  setPayOption("offline");
                }}
                isSelected={payOption === "offline"}
              />
              {acceptOnlinePayment && (
                <CustomRadioInput
                  label="Online"
                  onSelecting={() => {
                    setPayOption("online");
                  }}
                  isSelected={payOption === "online"}
                  // disabled={!acceptOnlinePayment && true}
                />
              )}
            </div>

            {payOption === "offline" && (
              <>
                <CustomButton
                  variant="primary"
                  size="l"
                  onClick={
                    () => handleHomePage()
                    // navigate("tenant/home")
                  }
                >
                  Submit
                </CustomButton>
              </>
            )}
            {/* // data?.property_id?.mgmt_model === GLOBAL.MGMT_MODEL.T1 && (
              //   <h3 className="font_m fw_5 font_yellow">
              //     The Application Fee is due. Please visit the leasing office to
              //     make the payment.
              //   </h3>
              // )}

            // {payOption === "cash" &&
            //   data?.property_id?.mgmt_model === GLOBAL.MGMT_MODEL.T3 && (
            //     <h3 className="font_m fw_5 font_yellow">
            //       The Application Fee is due. Please visit the leasing office to
            //       make the payment.
            //     </h3>
            //   )} */}
          </div>
          {payOption === "online" && acceptOnlinePayment && (
            <>
              <h3 className="pay-online font_l fw_6 text-center">Pay Online</h3>
              <PaymentMethod
                paymentType={GLOBAL.PAYMENT_TYPE.TENANT_APPLICATION_FEE}
                receiverStripeKey={
                  property_settings.payment_processor.stripe_public_key
                }
                isPaymentAllowed={true}
                payload={{
                  application_id,
                  property_id: data.property_id._id,
                }}
                setTransactionStatus={setTransactionStatus}
                setShowTransactionStatus={setShowTransactionStatus}
              />
            </>
          )}
        </>
      )}

      {!showTransactionStatus && canCancelApplication && (
        <div className="cancel-application-btn-wrapper mt-4">
          <CustomButton
            className="cancel-application-btn"
            variant="red"
            size="l"
            onClick={onCancelApplication}
          >
            Cancel Application
          </CustomButton>
        </div>

        // <div className="cancel-application-wrapper bg_grey">
        //   <p className="font_m font_yellow fw_5">
        //     I would like to cancel the onboarding application process. I am
        //     aware that after cancelling the application I won't be able to undo
        //     my action.
        //   </p>
        //   <div className="cancel-application-btn-wrapper w_100">
        //     <CustomButton
        //       className="cancel-application-btn"
        //       variant="red"
        //       size="m"
        //       onClick={onCancelApplication}
        //     >
        //       Cancel Application
        //     </CustomButton>
        //   </div>
        // </div>
      )}

      {/* {console.log(data.payment_processor_details.stripe_public_key)} */}
      {/* {console.log(data.property_id._id)} */}
    </div>
  );
};
/* Component ends */

export default ApplicationFeePayment;
