/**
 *
 */

import React, { useRef, useEffect, useState } from "react";

/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import MUI component starts */
/* Import MUI component ends */

/* Import image and SVG starts */
import { UploadFilledWhite } from "../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
/* Import local pages and component ends */

import "./style.scss";
import { getUserTheme } from "../../../../setup/store/slices/authSlice";
import { useSelector } from "react-redux";

/* Component starts */
const CustomFileInput = (props) => {
  /* Props destructuring starts */
  const {
    value,
    values,
    handleChange,
    handleBlur,
    name,
    multiple = false,
    errors,
    label,
    required = true,
    touched,
    files = null, // useRef from parent component
    customOnChange = null,
    formikState = false,
    setFieldValue = null,
    setFieldTouched = null,
    readOnly = false,
    disabled = false,

    acceptable = false, // To Enable The Option Should Be ( True )
    acceptType, // To Accept the Type Of File
    customOnchange = null,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [fileName, setFileName] = useState("");
  const fileInput = useRef(null);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const userTheme = useSelector(getUserTheme);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const onFileChoose = async (e) => {
    const filesValue = e.target.files;
    const attachFiles = [];
    let allFilesName = "";
    for (let i = 0; i < filesValue.length; i++) {
      allFilesName += filesValue[i].name + "; ";
      attachFiles.push(filesValue[i]);
    }

    if (customOnChange) {
      customOnChange(multiple ? attachFiles : attachFiles[0]);
    }

    if (files !== null && files.hasOwnProperty("current")) {
      files.current = multiple ? attachFiles : attachFiles[0];
    }

    !setFieldValue && handleChange && handleChange(e);

    if (setFieldValue && formikState) {
      const extractFile = multiple ? attachFiles : attachFiles[0];
      setFieldValue(name, extractFile);
    } else if (formikState && !setFieldValue) {
      console.error("Please provide setFieldValue function");
    }

    setFileName(allFilesName);
  };

  // File select flag
  const selectedFlag = (files, fileName) => {
    if (files?.current && !fileName) {
      return "File Selected";
    } else if (fileName) {
      return fileName;
    } else if (customOnChange && files) {
      return "File Selected";
    } else {
      return "No File Chosen";
    }
  };

  // File select flag if formikState is true
  const selectedFlagFormik = (value, fileName) => {
    // if (value && !fileName) {
    //   return "File Selected";
    // } else if (value && fileName) {
    //   return fileName;
    // } else {
    //   return "No File Chosen";
    // }
    if (value) {
      if (value?.name) {
        return value?.name;
      } else {
        return "File Selected";
      }
    } else {
      return "No File Chosen";
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  // useEffect(() => {
  //   if (files) {
  //     if(files?.current && !files?.current?.name) {
  //       setFileName("File Selected")
  //     }
  //   }
  // }, [files]);

  useEffect(() => {
    // return () => {
    //   files.current = null;
    // };
  }, []);

  useEffect(() => {
    if (values === "") {
      setFileName("");
    }
  }, [values]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="custom-file-input-wrapper">
      <div
        className={`file-input-wrapper ${
          files?.current || (files && customOnChange) || value
            ? "filechosen"
            : ""
        }`}
      >
        <label className="font_s font_grey">
          {label}
          {required && <span className="font_m font_red"> *</span>}
        </label>

        {/* If file is capturing in useeRef instead of formik state */}
        {!formikState && (
          <input
            type="text"
            // className="bg_black font_xs filename"
            //|| userTheme === "automatic"
            className={` font_xs ${
              userTheme === "light" || userTheme === "automatic"
                ? "bg_lightThemeInput font_lightTheme filename"
                : "bg_black font_white filename"
            }`}
            value={selectedFlag(files, fileName)}
            onChange={(e) => {
              setFileName(e.target.value);
            }}
            onClick={() => {
              fileInput.current.click();
            }}
            onBlur={(e) => {
              setFieldTouched && setFieldTouched(name, true);
            }}
            readOnly
            accept={acceptable && acceptType}
          />
        )}

        {/* If file is capturing in formik state */}
        {formikState && (
          <input
            type="text"
            // className="bg_black font_xs filename" || userTheme === "automatic"
            className={` font_xs ${
              userTheme === "light" || userTheme === "automatic"
                ? "bg_lightThemeInput font_lightTheme filename"
                : "bg_black font_white filename"
            }`}
            value={selectedFlagFormik(value, fileName)}
            onChange={(e) => {
              setFileName(e.target.value);
            }}
            onClick={() => {
              !disabled && fileInput.current.click();
            }}
            onBlur={(e) => {
              setFieldTouched && setFieldTouched(name, true);
            }}
            readOnly
            disabled={disabled}
            accept={acceptable && acceptType}
          />
        )}

        <input
          ref={fileInput}
          name={name}
          type="file"
          value={""}
          onChange={onFileChoose}
          multiple={multiple}
          onBlur={handleBlur}
          className="file"
          disabled={disabled}
          accept={acceptable && acceptType}
        />

        <img src={UploadFilledWhite} className="upload" alt="Upload Icon" />
      </div>

      {touched && errors && <p className="error font_xxs font_red">{errors}</p>}
    </div>
  );
};
/* Component ends */

export default CustomFileInput;
