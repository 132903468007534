/*
 * Slice to handle FAQs Module
 */

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../axios";
import { API_URL } from "../../config/devKeys";
import { setLoading } from "./unpersistedSlice";
// import { PLATFORM } from "../../platformUtil";

const initialState = {
  faqs: [],
};

// FETCH Faqs List
export const fetchFAQsList = createAsyncThunk(
  "faq/list",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await axios.get(
        API_URL + "/faq/list"
        // {
        //   headers: {
        //     Authorization: `Bearer ${localStorage.getItem("user_token")}`,
        //   },
        // }
      );
      if (data.success) {
        dispatch(setLoading(false));
        return data.data;
      } else {
        rejectWithValue(data.error);
      }
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue(error?.response);
    }
  }
);

export const faqSlice = createSlice({
  name: "faqs",
  initialState,
  extraReducers: {
    [fetchFAQsList.rejected]: (state, action) => {
      state.faqs = [];
    },
    [fetchFAQsList.fulfilled]: (state, action) => {
      state.faqs = action.payload;
    },
  },
});

// To Get the Facility Types
export const getFAQsList = (state) => state.faqs.faqs;

export default faqSlice.reducer;
