/**
 *
 */

import React, { useState, useEffect } from "react";
/* Import configuration starts */
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  getMaintenance,
  fetchMaintenanceById,
} from "../../setup/store/slices/maintenanceSlice";
import { getUser } from "../../setup/store/slices/authSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import TenantMaintenanceDetail from "../../components/module/TenantMaintenance/TenantMaintenanceDetail/TenantMaintenanceDetail";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const TenantMaintenanceDetailPage = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const { maintenanceId, unitId } = useParams();
  const maintenance = useSelector(getMaintenance);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const type = maintenance?.type;
  const mgmtModel = maintenance?.property_id?.mgmt_model;
  const currency = maintenance?.property_id?.currency;
  const isT1 = mgmtModel === 0;
  const isT2 = mgmtModel === 1;
  const isT3 = mgmtModel === 2;
  const status = maintenance?.status;
  const user = useSelector(getUser);
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const getMaintenanceById = async () => {
    await dispatch(fetchMaintenanceById({ maintenance_id: maintenanceId }));
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    getMaintenanceById();
  }, []);
  /* Component useEffect ends */

  console.log(maintenance);
  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="tenant-maintenance-detail-page-wrapper">
      <h3>Maintenace Request Details</h3>

      <TenantMaintenanceDetail
        maintenance={maintenance}
        isT1={isT1}
        isT2={isT2}
        isT3={isT3}
        mgmtModel={mgmtModel}
        type={type}
        status={status}
        maintenance_id={maintenance?._id}
        user={user}
        currency={currency}
      />
    </div>
  );
};
/* Component ends */

export default TenantMaintenanceDetailPage;
