/**
 *
 */

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */

/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import Searchbar from "../../components/ui/other/Searchbar/Searchbar";
import TabButton from "../../components/ui/other/TabButton/TabButton";

import OpenTasks from "../../components/module/RoutineRequest/RoutineTaskTable/OpenTaskTable/OpenTasks";
import PendingTasks from "../../components/module/RoutineRequest/RoutineTaskTable/PendingTaskTable/PendingTasks";
import ClosedTasks from "../../components/module/RoutineRequest/RoutineTaskTable/ClosedTaskTable/ClosedTasks";
/* Import local pages and component ends */

// styles sheet
import "./style.scss";

const RoutineRequest = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [currentKey, setCurrentKey] = useState("open");

  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const buttonList = [
    { key: "open", label: "Open" },
    { key: "pending", label: "Pending" },
    { key: "closed", label: "Closed" },
  ];

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const onTabSelect = (key) => {
    setCurrentKey(key);
  };

  /* Component function definition ends */

  /* Component useEffect starts */
  //   useEffect(() => {
  //     dispatch(fetchFoundItemList());
  //     dispatch(fetchLostItemList());

  //     dispatch(fetchClaimedItemList());
  //   }, [dispatch]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  /* Component starts */
  return (
    <div className="routine-wrapper">
      <div className="routine-heading">
        <Link
          to="/property/routine-request/add"
          className="font_m fw_6 d_block link"
        >
          + Create Task
        </Link>

        <h2 className="font_xxl fw_6">Routine Requests</h2>
      </div>

      <Searchbar />

      {/* TAB BUTTONS */}
      <TabButton
        buttonList={buttonList}
        currentKey={currentKey}
        onSelect={onTabSelect}
      />

      {/* Display Table Data Based On The Current Tab */}

      {currentKey === "open" ? (
        <OpenTasks />
      ) : currentKey === "pending" ? (
        <PendingTasks />
      ) : (
        <ClosedTasks />
      )}
    </div>
  );
};
/* Component End */

export default RoutineRequest;
