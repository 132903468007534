import {
  validEmail,
  validateAtleastOneNumber,
  validateAtleastOneSpecialChar,
} from "../../../../setup/utils/validations";

import moment from "moment";

export const isApproved = (status) => {
  return status && status === "approved" ? true : false;
};

export const householdMemberValidation = (values, formCountList) => {
  const error = {};

  formCountList.forEach((i, indx) => {
    const first_name = `first_name_${i}`;
    const last_name = `last_name_${i}`;
    const gender = `gender_${i}`;
    const relation = `relation_${i}`;
    const nationality = `nationality_${i}`;
    const email = `email_${i}`;
    const mobile = `mobile_${i}`;
    const id_type = `id_type_${i}`;
    const id_number = `id_number_${i}`;
    const language = `language_${i}`;
    const id_image = `id_image_${i}`;

    if (values[first_name] === "") {
      error[first_name] = "First Name is required";
    }

    if (values[last_name] === "") {
      error[last_name] = "Last Name is required";
    }

    if (values[gender] === "") {
      error[gender] = "Gender is required";
    }

    if (values[relation] === "") {
      error[relation] = "Relation is required";
    }

    if (values[nationality] === "") {
      error[nationality] = "Nationality is required";
    }

    if (values[email] === "") {
      error[email] = "Email is required";
    }

    if (values[mobile] === "") {
      error[mobile] = "Mobile is required";
    }

    if (values[id_type] === "") {
      error[id_type] = "ID Type is required";
    }

    if (values[id_number] === "") {
      error[id_number] = "ID Number is required";
    }

    if (values[language] === "") {
      error[language] = "Language is required";
    }

    if (values[id_image] === null) {
      error[id_image] = "ID Photo is required";
    }
  });

  return error;
};

export const setFormList = (
  formCountList,
  setFormCountList,
  requiredFormCountNumber,
  formRef,
  setInitialFormValues,
  phoneNumList
) => {
  const currentFormState = { ...formRef.current.values };
  const currentFormCount = [...formCountList];

  if (requiredFormCountNumber > formCountList.length) {
    // Adding forms
    for (let i = 0; i < requiredFormCountNumber; i++) {
      if (i >= formCountList.length) {
        currentFormState[`first_name_${i}`] = "";
        currentFormState[`last_name_${i}`] = "";
        currentFormState[`dob_${i}`] = new Date();
        currentFormState[`gender_${i}`] = "";
        currentFormState[`relation_${i}`] = "";
        currentFormState[`nationality_${i}`] = "";
        currentFormState[`email_${i}`] = "";
        currentFormState[`mobile_${i}`] = "";
        currentFormState[`id_type_${i}`] = "";
        currentFormState[`id_number_${i}`] = "";
        currentFormState[`id_expiry_date_${i}`] = new Date();
        currentFormState[`language_${i}`] = "";
        currentFormState[`id_image_${i}`] = null;
        phoneNumList.current.push({
          code: "",
          num: "",
        });

        currentFormCount.push(i);
      }
    }
  } else if (requiredFormCountNumber < formCountList.length) {
    const lastIdx = formCountList.length - 1;

    for (let i = lastIdx; i >= requiredFormCountNumber; i--) {
      delete currentFormState[`first_name_${formCountList[i]}`];
      delete currentFormState[`last_name_${formCountList[i]}`];
      delete currentFormState[`dob_${formCountList[i]}`];
      delete currentFormState[`gender_${formCountList[i]}`];
      delete currentFormState[`relation_${formCountList[i]}`];
      delete currentFormState[`nationality_${formCountList[i]}`];
      delete currentFormState[`email_${formCountList[i]}`];
      delete currentFormState[`mobile_${formCountList[i]}`];
      delete currentFormState[`id_type_${formCountList[i]}`];
      delete currentFormState[`id_number_${formCountList[i]}`];
      delete currentFormState[`id_expiry_date_${formCountList[i]}`];
      delete currentFormState[`language_${formCountList[i]}`];
      delete currentFormState[`id_image_${formCountList[i]}`];

      phoneNumList.current.pop();
      currentFormCount.pop();
    }
  }

  setInitialFormValues(currentFormState);
  setFormCountList(currentFormCount);
};

// On load of household member details previous data
export const formPrefill = (
  data,
  setInitialFormValues,
  setFormCountList,
  phoneNumList
) => {
  const formValues = {};
  const currentFormCount = [];

  data.forEach((item, i) => {
    formValues[`first_name_${i}`] = item?.first_name;
    formValues[`last_name_${i}`] = item?.last_name;
    formValues[`dob_${i}`] = new Date(item?.dob);
    formValues[`gender_${i}`] = item?.gender;
    formValues[`relation_${i}`] = item?.relation;
    formValues[`nationality_${i}`] = item?.nationality;
    formValues[`email_${i}`] = item?.email;
    formValues[`mobile_${i}`] = `${item?.countrycode} ${item?.mobile}`;
    formValues[`id_type_${i}`] = item?.id_type;
    formValues[`id_number_${i}`] = item?.id_number;
    formValues[`id_expiry_date_${i}`] = new Date(item?.id_expiry_date);
    formValues[`language_${i}`] = item?.language;
    formValues[`id_image_${i}`] = item?.id_image;
    formValues[`status_${i}`] = item?.status;

    phoneNumList.current.push({
      code: "",
      num: "",
    });

    currentFormCount.push(i);
  });

  setInitialFormValues(formValues);
  setFormCountList(currentFormCount);
};

export const isDataChanged = (newData, prevData, keyList) => {
  for (let i = 0; i < keyList.length; i++) {
    const first_name_old = prevData[i].first_name;
    const last_name_old = prevData[i].last_name;
    const dob_old = prevData[i].dob;
    const gender_old = prevData[i].gender;
    const relation_old = prevData[i].relation;
    const nationality_old = prevData[i].nationality;
    const email_old = prevData[i].email;
    const mobile_old = `${prevData[i].countrycode} ${prevData[i].mobile}`;
    const id_type_old = prevData[i].id_type;
    const id_number_old = prevData[i].id_number;
    const id_expiry_date_old = prevData[i].id_expiry_date;
    const language_old = prevData[i].language;
    const id_image_old = prevData[i].id_image;

    if (newData[`first_name_${keyList[i]}`] !== first_name_old) {
      return true;
    }

    if (newData[`last_name_${keyList[i]}`] !== last_name_old) {
      return true;
    }

    if (!moment(newData[`dob_${keyList[i]}`]).isSame(dob_old)) {
      return true;
    }

    if (newData[`gender_${keyList[i]}`] !== gender_old) {
      return true;
    }

    if (newData[`relation_${keyList[i]}`] !== relation_old) {
      return true;
    }

    if (newData[`nationality_${keyList[i]}`] !== nationality_old) {
      return true;
    }

    if (newData[`email_${keyList[i]}`] !== email_old) {
      return true;
    }

    if (newData[`mobile_${keyList[i]}`] !== mobile_old) {
      return true;
    }

    if (newData[`id_type_${keyList[i]}`] !== id_type_old) {
      return true;
    }

    if (newData[`id_number_${keyList[i]}`] !== id_number_old) {
      return true;
    }

    if (
      !moment(newData[`id_expiry_date_${keyList[i]}`]).isSame(
        id_expiry_date_old
      )
    ) {
      return true;
    }

    if (newData[`language_${keyList[i]}`] !== language_old) {
      return true;
    }

    if (
      newData[`id_image_${keyList[i]}`] &&
      !newData[`id_image_${keyList[i]}`]?.url
    ) {
      return true;
    }
  }
  return false;
};

// export const setFormList = (
//   formCountList,
//   setFormCountList,
//   requiredFormCountNumber,
//   formRef,
//   setInitialFormValues,
//   phoneNumList
// ) => {
//   const currentFormState = { ...formRef.current.values };
//   const currentFormCount = [...formCountList];

//   if (requiredFormCountNumber > formCountList.length) {
//     // Adding forms
//     for (let i = 0; i < requiredFormCountNumber; i++) {
//       if (i >= formCountList.length) {
//         currentFormState[`first_name_${i}`] = "";

//         phoneNumList.current.push({
//           code: "",
//           num: "",
//         });

//         currentFormCount.push(i);
//       }
//     }
//   } else if (requiredFormCountNumber < formCountList.length) {
//     const lastIdx = formCountList.length - 1;

//     for (let i = lastIdx; i >= requiredFormCountNumber; i--) {
//       delete currentFormState[`first_name_${formCountList[i]}`];

//       phoneNumList.current.pop();
//       currentFormCount.pop();
//     }
//   }

//   setInitialFormValues(currentFormState);
//   setFormCountList(currentFormCount);
// };
