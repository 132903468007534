export const fieldValue = {};

// Validations
export const validation = (values, desc) => {
  const error = {};

  // Description and Estimation starts
  desc.forEach((itm, idx) => {
    const description = "description_" + itm;
    const estimate = "estimate_" + itm;
    // ********************** Creating Object Keys **********************
    if (values[description] === "") {
      error[description] = "Description is required";
    }

    if (values[estimate] === "") {
      error[estimate] = "Estimation is required";
    }
  });
  // Description and Estimation ends

  return error;
};

// Form prefill when reviewed by FM
export const prefill = (data, setFormValue, setDesc) => {
  const descriptionContainer = {};
  const formCount = [];

  data?.forEach((item, index) => {
    descriptionContainer[`description_${index}`] = item.description;
    descriptionContainer[`estimate_${index}`] = item.estimate;

    formCount.push(index);
  });

  setFormValue(descriptionContainer);
  setDesc(formCount);
};
