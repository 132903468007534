/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  fetchUserRoles,
  getUserRoles,
} from "../../../setup/store/slices/masterSlice";
import { fetchUser, getUser } from "../../../setup/store/slices/authSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import MUI component starts */
/* Import MUI component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
/* Import local pages and component ends */

/* Component starts */
const HeadingAndNavigation = () => {
  /* Props destructuring starts */
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [role, setRole] = useState();

  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const userRoles = useSelector(getUserRoles);
  const user = useSelector(getUser);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const isClient = localStorage.getItem("user_role") === "Client";
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    dispatch(fetchUserRoles());
    dispatch(fetchUser());
  }, []);

  // Setting role after all user roles loaded
  useEffect(() => {
    let userRole = userRoles?.filter((d) => d?._id === user?.role_id)[0]?.name;
    setRole(userRole);
  }, [userRoles]);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="heading-navigation-wrapper">
      <div className="heading-navigation">
        <h2 className="heading font_xxl right">Overview</h2>

        <div className="left">
          {/* <Link to="dashboard" className="font_m">
            Dashboards
          </Link> */}
          {isClient && (
            <>
              {/* <Link to="advertisments" className="font_m">
                +Advertisement
              </Link> */}
              <Link to="annoucements" className="font_m">
                +Announcement
              </Link>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
/* Component ends */

export default HeadingAndNavigation;
