/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import AnnoucementView from "./local-component/AnnoucementView";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const AnnoucementDetail = (props) => {
  /* Props destructuring starts */
  const { annoucement, announcementStats } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="annoucement-detail-wrapper container_sm">
      <h1 className="text-center font_xxl mb-4">Announcement</h1>

      {/* Details */}
      <AnnoucementView
        annoucement={annoucement}
        announcementStats={announcementStats}
      />
    </div>
  );
};
/* Component ends */

export default AnnoucementDetail;
