/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useNavigate } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import EditCommunity from "../../components/module/Community/EditCommunity/EditCommunity";
/* Import local pages and component ends */

import "./style.scss";
import BreadCrumb from "../../components/ui/other/BreadCrumb/BreadCrumb";

/* Component starts */
const EditCommunityPage = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      <BreadCrumb type="community_edit" />

      <div className="edit-community-page-wrapper">
        <div className="container_sm">
          <EditCommunity
            onSuccess={() => navigate(-1)}
            onCancel={() => navigate(-1)}
          />
        </div>
      </div>
    </>
  );
};
/* Component ends */

export default EditCommunityPage;
