/**
 * This component is for custom phone input field
 */

import React, { useEffect, useRef } from "react";

/* Import configuration starts */
import PhoneInput from "react-phone-input-2";
/* Import configuration ends */

import "./style.scss";
import { getUserTheme } from "../../../../setup/store/slices/authSlice";
import { useSelector } from "react-redux";

export default function CustomPhoneInput(props) {
  /* Props destructuring starts */
  const {
    disableDropdown = false,
    name,
    value,
    errors,
    placeholder,
    onCountryCodeChanged,
    defaultCountry = "us",
    countryCodeEditable = false,
    handleBlur = null,
    setFieldTouched = null,
    handleChange = null,
    touched,
    required = true,
    customPhoneNum = null, // useRef from parent component
    refValue = null,
    customOnChange = null,
    setFieldValue = null, // setFieldValue formik function
    readOnly = false,
    label = "Phone Number",
    countrycodeName = null,
    countrycodeValue = null,
    noDefaultSelectedCountry = false,
    values,
  } = props;
  /* Props destructuring ends */

  /* Other hooks declaration starts */
  const selectedCountryCode = useRef(defaultCountry);
  const userTheme = useSelector(getUserTheme);
  /* Other hooks declaration ends */

  /* Component function definition starts */
  const changeHandler = (value, country, e) => {
    let reqPhoneNumber;
    if (selectedCountryCode.current !== country.countryCode) {
      selectedCountryCode.current = country.countryCode;
      if (onCountryCodeChanged) {
        onCountryCodeChanged(country);
      }
    }

    reqPhoneNumber = value.slice(country.dialCode.length, value.length);

    if (customOnChange !== null) {
      handleChange && handleChange(e);
      customOnChange({
        code: `+${country.dialCode}`,
        num: `${reqPhoneNumber}`,
      });
    }

    if (customPhoneNum !== null) {
      customPhoneNum.current = {
        code: `+${country.dialCode}`,
        num: `${reqPhoneNumber}`,
      };
    }

    if (refValue !== null) {
      refValue.current = {
        code: `+${country.dialCode}`,
        num: `${reqPhoneNumber}`,
      };
    }

    if (setFieldValue !== null) {
      if (countrycodeName) {
        setFieldValue(countrycodeName, `+${country.dialCode}`);
        setFieldValue(name, reqPhoneNumber);
      } else {
        setFieldValue(name, `+${country.dialCode} ${reqPhoneNumber}`);
      }
    }

    handleChange && handleChange(e);
  };
  /* Component function definition ends */

  // useEffect(() => {
  //   if (countrycodeName) {
  //     setFieldValue(countrycodeName, `+1`);
  //   }
  // }, []);

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="custom-form-control">
      <div className="form-control-wrapper">
        <label className="phone-input-label font_s font_grey">
          {label}
          {required && <span className="font_m font_red"> *</span>}
        </label>
        <PhoneInput
          disableDropdown={readOnly ? true : disableDropdown}
          name={name}
          inputProps={{
            name: name,
            readOnly: readOnly,
          }}
          onChange={changeHandler}
          onBlur={(e) => {
            handleBlur && handleBlur(e);
            setFieldTouched && setFieldTouched(name, true);
          }}
          country={noDefaultSelectedCountry ? "us" : defaultCountry}
          specialLabel={""}
          copyNumbersOnly={true}
          placeholder={placeholder}
          countryCodeEditable={countryCodeEditable}
          value={
            countrycodeName && countrycodeValue !== null
              ? `${countrycodeValue} ${value}`
              : value
          }
          // className="font_xs"
          // || userTheme === "automatic"
          className={` font_xs
           ${
             userTheme === "light" || userTheme === "automatic"
               ? "bg_lightThemeInput font_lightTheme"
               : "bg_black font_white"
           }
          `}
        />
      </div>

      {touched && errors && <p className="error font_xxs font_red">{errors}</p>}
    </div>
  );
}
