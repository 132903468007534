/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Table } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import {
  EyeFilledBlue,
  ProfileFilledGrey,
} from "../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../../../ui/button/IconButton/IconButton";
/* Import local pages and component ends */

/* Component starts */
const Requests = (props) => {
  /* Props destructuring starts */
  const { list } = props;

  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */
  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="custom-table-wrapper request-table">
      {list?.length > 0 && (
        <Table className="custom-table font_s" borderless responsive>
          {/* Table Heading */}
          <thead>
            <tr>
              <th>Request No</th>
              <th>Unit No.</th>
              <th>Tenant Name</th>
              <th>Request Date & Time</th>
              <th>Status</th>
              <th className="text-center">Action</th>
            </tr>
          </thead>
          {/* Table Body */}
          <tbody>
            {list?.map((item) => (
              <tr key={item.unit_no}>
                {/* Request No */}
                <td>{item?.req_no}</td>

                {/* Unit NUmber */}
                <td>{item?.unit_no}</td>

                {/* Tenant Name */}
                <td>{item?.name}</td>

                {/* Request Date & Time */}
                <td>{item?.req_date}</td>

                {/* Status */}
                <td
                  className={
                    item?.status === "Approved"
                      ? "approve"
                      : item?.status === "Rejected"
                      ? "reject"
                      : item?.status.startsWith("Pending")
                      ? "pending"
                      : ""
                  }
                >
                  {item?.status}
                </td>

                {/* Actions */}
                <td className="text-center txt-nowrap">
                  {/* <IconButton
                    // onClick={() =>
                    //   navigate("/tenant/security/details", {
                    //     state: { item },
                    //   })
                    // }
                    className="action-button"
                  >
                    <img
                      src={ProfileFilledGrey}
                      className="h_100"
                      alt="View Details"
                    />
                  </IconButton> */}

                  <IconButton
                    // onClick={() =>
                    //   navigate("/tenant/security/details", {
                    //     state: { item },
                    //   })
                    // }
                    className="action-button"
                  >
                    <img
                      src={EyeFilledBlue}
                      className="h_100"
                      alt="View Details"
                    />
                  </IconButton>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      {list?.length === 0 && (
        <div className=" text-center">
          <h3 className=" font_m font_yellow fw_6">No Data</h3>
        </div>
      )}
    </div>
  );
};
/* Component ends */

export default Requests;
