export const missingModuleRender = (item, index) => {
  let seperator = " ";
  let renderedItem = "";

  switch (item) {
    case "management_model":
      renderedItem = "Management Model";
      break;

    case "license":
      renderedItem = "License";
      break;

    case "units":
      renderedItem = "Unit";
      break;

    case "team":
      renderedItem = "Team";
      break;

    case "property_setting":
      renderedItem = "Property Settings";
      break;

    default:
  }
  if (index !== 0) {
    seperator = ", ";
  }
  return seperator + renderedItem;
};
