/**
 *
 */

import React from "react";

import "./style.scss";
import { getUserTheme } from "../../../../setup/store/slices/authSlice";
import { useSelector } from "react-redux";

/* Component starts */
const TextButton = ({
  children,
  className = "",
  variant = "primary",
  type = "button",
  ...rest
}) => {
  /* Component variable declaration / object destructure starts */
  const settingColors =
    variant === "primary"
      ? "font_blue"
      : variant === "red"
      ? "font_red"
      : variant === "white"
      ? "font_white"
      : "";
  /* Component variable declaration / object destructure ends */

  const userTheme = useSelector(getUserTheme);

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <button
      className={`custom-text-btn ${className} ${settingColors} ${
        userTheme !== "dark" && "otherTheme"
      } `}
      type={type}
      {...rest}
    >
      {children}
    </button>
  );
};
/* Component ends */

export default TextButton;
