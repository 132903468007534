/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import moment from "moment";
import GLOBAL from "../../../../../setup/constants/global";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import { getLoggedinUserRole } from "../../../../../setup/store/slices/authSlice";
import {
  getIsACC,
  getIsOwner,
} from "../../../../../setup/store/slices/authSlice";
import { showWarningAlert } from "../../../../../setup/store/slices/globalAlertSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Table } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
import {
  EyeFilledBlue,
  ProfileFilledGrey,
} from "../../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import IconButton from "../../../../ui/button/IconButton/IconButton";
import Pagination from "../../../../ui/other/Pagination/Pagination";
import { pathNamePopped } from "../../../../../setup/utils/global-helper";
/* Import local pages and component ends */

/* Component starts */
const MoveOuts = (props) => {
  /* Props destructuring starts */
  const { list, totalCount, currentPage, setCurrentPage, mgmtModel } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // Redux Selector
  const isAcc = useSelector(getIsACC);
  const isOwner = useSelector(getIsOwner);
  const { pathname } = useLocation();
  const params = useParams();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const renderType = (item) => {
    switch (item.raised_by_type) {
      case GLOBAL.USER_ROLE.TENANT:
        return "Tenant Initiated";
        break;
      case GLOBAL.USER_ROLE.PM:
        return "Property Manager Initiated";
        break;
      case GLOBAL.USER_ROLE.CLIENT:
        return "Client Initiated";
        break;
      case GLOBAL.USER_ROLE.OWNER:
        return "Owner Initiated";
        break;
    }
  };

  const renderStatus = (item) => {
    let status = "";
    let statusColor = "";
    switch (item.status) {
      case "Pending":
        if (item?.sub_status === "Pending submittion") {
          status = "Pending Submission";
          statusColor = "font_yellow";
        } else {
          status = "Pending approval";
          statusColor = "font_yellow";
        }
        break;
      case "keys_handover_left":
        status = "Awaiting Keys";
        statusColor = "font_yellow";
        break;
      case "URIC Inspection Pending":
        status = "Pending Initial Inspection";
        statusColor = "font_yellow";
        break;
      case "Approved":
        status = "Closed";
        statusColor = "font_green";
        break;
      case "pending_clearance_approval":
        status = "Pending Clearance Certificate Approval";
        statusColor = "font_yellow";
        break;
      default:
        status = item.status;
        statusColor = "font_yellow";
    }

    return { status, statusColor };
  };

  const handleViewMoveoutDetails = (item) => {
    // if (isAcc) {
    //   if (
    //     item?.status === "Accountant clearance pending" ||
    //     item?.status === "Approved" ||
    //     item?.status === "pending_clearance_approval"
    //   ) {
    //     navigate(
    //       `${item?._id}/review/accountant?force=${
    //         item?.sub_status === "Force move out" ? 1 : 0
    //       }`,
    //       {
    //         state: { item },
    //       }
    //     );
    //   } else {
    //     dispatch(
    //       showWarningAlert({
    //         title: "Not Accessible",
    //         description:
    //           "Cannot access move out at the moment. Some initial verifications and documentations are still on process. You will get the access after the Moveout Inspection is successfully completed.",
    //       })
    //     );
    //   }
    // } else {
    //   navigate(`${item._id}`, {
    //     state: { item },
    //   });
    // }

    navigate(`${pathNamePopped(pathname, 6)}/tenants/move-out/${item?._id}`, {
      state: { pathname: pathname },
    });

    // navigate(`${item?._id}`, {
    //   state: { item },
    // });
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      <div className="custom-table-wrapper move-out-table">
        {list?.length > 0 && (
          <Table className="custom-table font_s" borderless responsive>
            {/* Table Heading */}
            <thead>
              <tr>
                <th>Tenant Name</th>
                <th>Unit No.</th>
                <th>Move Out Request Date</th>
                <th>Move Out Date</th>
                <th>Type</th>
                <th>Status</th>
                <th className="text-center">Action</th>
              </tr>
            </thead>
            {/* Table Body */}
            <tbody>
              {list?.map((item) => (
                <tr key={item.unit_no}>
                  {/* Tenant Name */}
                  <td>
                    {item?.tenant?.first_name} {item?.tenant?.last_name}
                  </td>

                  {/* Unit NUmber */}
                  <td>{item?.unit_card.unit_name}</td>

                  {/* Move Out Request Date */}
                  <td>{moment(item?.createdAt).format(DATE_FORMAT)}</td>

                  {/* Move Out Date e */}
                  <td>{moment(item?.move_out_date).format(DATE_FORMAT)}</td>

                  {/* Type */}
                  <td>{renderType(item)}</td>

                  {/* Status */}
                  <td className={`${renderStatus(item).statusColor}`}>
                    {renderStatus(item).status}
                  </td>

                  {/* Actions */}
                  <td className="text-center txt-nowrap">
                    {/* <IconButton
                      // onClick={() =>
                      //   navigate("/tenant/security/details", {
                      //     state: { item },
                      //   })
                      // }
                      className="action-button"
                    >
                      <img
                        src={ProfileFilledGrey}
                        className="h_100"
                        alt="View Details"
                      />
                    </IconButton> */}

                    <IconButton
                      onClick={() => handleViewMoveoutDetails(item)}
                      className="action-button"
                    >
                      <img
                        src={EyeFilledBlue}
                        className="h_100"
                        alt="View Details"
                      />
                    </IconButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}

        {list?.length === 0 && (
          <div className=" text-center">
            <h3 className=" font_m font_yellow fw_6">No Data</h3>
          </div>
        )}
      </div>

      {/* {list?.length > 0 && (
        <Pagination
          itemsPerPage="10"
          totalItems={totalCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )} */}
    </>
  );
};
/* Component ends */

export default MoveOuts;
