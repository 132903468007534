/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import GLOBAL from "../../setup/constants/global";
/* Import configuration ends */

/* Import redux slices component starts */
import { showErrorAlert } from "../../setup/store/slices/globalAlertSlice";
import {
  fetchPropertyDetailByID,
  getPropertyDetail,
} from "../../setup/store/slices/propertySlice";
import { fetchTenantApplicationByIdNew } from "../../setup/store/slices/tenantSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import TransitionedTenantInstalment from "../../components/module/Tenant/TransitionedTenantInstalment/TransitionedTenantInstalment";
import BreadCrumb from "../../components/ui/other/BreadCrumb/BreadCrumb";
/* Import local pages and component ends */

/* Component starts */
const TransitionedTenantInstalmentPage = (props) => {
  /* Props destructuring starts */
  const {} = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [application, setApplication] = useState();
  const [sequence, setSequence] = useState([]);
  const [sequenceNumber, setSequenceNumber] = useState(null);
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const dispatch = useDispatch();
  const { propertyId, applicationId } = useParams();
  const property = useSelector(getPropertyDetail);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  const initializeSequenceNumber = (data) => {
    let s = [];
    const isResidential = data?.is_unit_residential;
    const isCommercial = !data?.is_unit_residential;

    s.push("basic_details");
    if (isCommercial) {
      s.push("commercial_details");
    }
    if (data?.ids?.length > 0) {
      s.push("ids");
    }
    if (isResidential && data?.household_members?.length > 0) {
      s.push("household_members");
    }
    if (data?.disability) {
      s.push("disability");
    }
    if (data?.vehicles?.length > 0) {
      s.push("vehicles");
    }
    if (data?.pets?.length > 0) {
      s.push("pets");
    }
    if (data?.rental_history?.length > 0) {
      s.push("rental_history");
    }
    if (data?.application_fee) {
      s.push("application_fee");
    }
    s.push("installment_plan");
    s.push("initial_agreement");
    s.push("contract");
    if (data?.utilities?.length > 0) {
      s.push("utilities");
    }
    s.push("final_agreement");
    s.push("maintenance");
    s.push("keys");

    setSequenceNumber(s);
  };

  const getApplication = async () => {
    // Backend response. Try, Catch
    try {
      const result = await dispatch(
        fetchTenantApplicationByIdNew(applicationId)
      );
      console.log("Response", result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          dispatch(
            showErrorAlert({
              title: "Error!",
              description:
                typeof result?.payload === "string" ? result?.payload : "",
            })
          );
          setSequence([]);
          setApplication();
          break;

        case GLOBAL.FULFILLED:
          initializeSequenceNumber(result.payload.data);
          setSequence(result.payload.sequence);
          setApplication(result.payload.data);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    getApplication();
    dispatch(fetchPropertyDetailByID({ property_id: propertyId }));
  }, []);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="">
      <BreadCrumb type="transitioned_tenant_application_review" />

      {property && application && sequence.length > 0 && (
        <TransitionedTenantInstalment
          getApplication={getApplication}
          sequence={sequence}
          sequenceNumber={sequenceNumber}
          application={application}
          application_id={applicationId}
          property={property}
          currency={property?.currency}
          status={application}
        />
      )}
    </div>
  );
};
/* Component ends */

export default TransitionedTenantInstalmentPage;
