/**
 *
 */

import React from "react";

/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import MUI component starts */
/* Import MUI component ends */

/* Import image and SVG starts */
import {
  VisaFilledWhite,
  Visa,
  Amex,
  Discover,
  Union,
  JCB,
  Master,
} from "../../../../setup/constants/images";
/* Import image and SVG ends */

/* Import local pages and component starts */
import TextButton from "../../../ui/button/TextButton/TextButton";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const PaymentCard = (props) => {
  /* Props destructuring starts */
  const { selected, setSelected, cardInfo, size, onDelete } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const brandToLogo = {
    visa: Visa,
    mastercard: Master,
    amex: Amex,
    unionpay: Union,
    discover: Discover,
    jcb: JCB,
    // Add more card brands and their logos as needed
  };

  // Determine the logo to display based on the brand
  const logo = brandToLogo[cardInfo?.type] || null;

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  const selectThisCard = () => {
    setSelected(cardInfo);
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div
      className={`payment-card-wrapper-main ${
        selected?.id === cardInfo?.id ? "selected" : ""
      }`}
      onClick={selectThisCard}
    >
      <div className="top">
        {logo && <img src={logo} alt={`${cardInfo?.type} logo`} />}
        <span className="dot d_block">
          <span className="inner-dot d_block"></span>
        </span>
      </div>

      <p className={`num fw_5 ${size === "l" ? "font_l" : "font_m"}`}>
        XXXX XXXX XXXX {cardInfo?.last4}
      </p>

      <div className="info">
        <p className={`name fw_5 my-1 ${size === "l" ? "font_l" : "font_m"}`}>
          {cardInfo?.name}
        </p>

        <p className={`name fw_5 my-1 ${size === "l" ? "font_l" : "font_m"}`}>
          {cardInfo?.expiry_month}/{cardInfo?.expiry_year}
        </p>
      </div>

      <div className="bottom fw_5 text-end">
        <TextButton
          className={`fw_5 ${size === "l" ? "font_m" : "font_s"}`}
          variant="red"
          onClick={() => {
            onDelete(cardInfo);
          }}
        >
          Delete
        </TextButton>
      </div>
    </div>
  );
};
/* Component ends */

export default PaymentCard;
