/**
 *
 */

import React, { useState, useEffect, useRef } from "react";

/* Import configuration starts */
import { connect } from "react-redux";
import { Formik } from "formik";
import { bindActionCreators } from "redux";
import {
  basicDetailInitVal,
  basicDetailValidation,
  basicDetailFormPrefill,
} from "./helper";
import GLOBAL from "../../../../setup/constants/global";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  clearMasterData,
  fetchGenders,
  fetchLocales,
} from "../../../../setup/store/slices/masterSlice";
import { fetchCountries } from "../../../../setup/store/slices/countrySlice";
import { updateTenantApplication } from "../../../../setup/store/slices/tenantSlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import TextButton from "../../../ui/button/TextButton/TextButton";
import CustomDateInput from "../../../ui/input/CustomDateInput/CustomDateInput";
import CustomTextInput from "../../../ui/input/CustomTextInput/CustomTextInput";
import CustomSelectInput from "../../../ui/input/CustomSelectInput/CustomSelectInput";
import CustomPhoneInput from "../../../ui/input/CustomPhoneInput/CustomPhoneInput";
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
import SuccessModal from "../../../ui/modal/SuccessModal/SuccessModal";
import ErrorModal from "../../../ui/modal/ErrorModal/ErrorModal";
/* Import local pages and component ends */

/* Component starts */
const BasicDetailForm = (props) => {
  /* Props destructuring starts */
  // states
  const {
    countries,
    _availableLocales,
    genders,
    data,
    applicationId,
    canCancelApplication = false,
  } = props;

  // functions
  const {
    fetchLocales,
    fetchCountries,
    fetchGenders,
    update,
    onNext,
    clearMasterData,
    onCancelStep = null,
    fetchApplication = null,
    onUpdatingInfo = null,
    onCancelApplication,
  } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  const [initialFormValues, setInitialFormValues] =
    useState(basicDetailInitVal);
  const [dob, setDob] = useState(new Date());
  const [message, setMessage] = useState("");
  const [showErrModal, setShowErrModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  // useRef
  const customPhoneNum = useRef({ code: "", num: "" });
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  // Form validations
  const formValidation = (values) => {
    return basicDetailValidation(values, customPhoneNum);
  };

  //
  const is18Plus = (d) => {
    const birthDate = new Date(d);
    const todaysDate = new Date();
    let diff = (birthDate.getTime() - todaysDate.getTime()) / 1000;
    diff /= 60 * 60 * 24;
    const diffYear = Math.abs(Math.trunc(diff / 365.25));
    if (diffYear >= 18) {
      return true;
    }
    return false;
  };

  // On updating basic details
  const onSubmit = async (values) => {
    const { gender, nationality, language, profession, business_name } = values;

    if (!is18Plus(dob)) {
      setMessage("Tenant has to be minimum 18 years old");
      setShowErrModal(true);
      return;
    }

    // Creating or collecting payload data to be sent
    const payload = {
      applicationId,
      type: "basic_details",
      unitId: data.unit,
      basic_details: {
        dob: dob,
        gender,
        nationality,
        language,
        profession,
        business_name,
      },
    };
    console.log("Payload", payload);

    // Backend response. Try, Catch
    try {
      // const result = {};
      const result = await update(payload);

      console.log(result);

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setMessage(result.payload);
          setShowErrModal(true);
          break;

        case GLOBAL.FULFILLED:
          onUpdatingInfo && onUpdatingInfo();
          fetchApplication && fetchApplication();
          setMessage(result.payload.message);
          setShowSuccessModal(true);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };
  /* Component function definition ends */

  /* Component useEffect starts */
  // On initital load
  useEffect(() => {
    fetchLocales();
    fetchCountries();
    fetchGenders();
  }, []);

  // On load of basic details previous data
  useEffect(() => {
    if (data) {
      basicDetailFormPrefill(
        data,
        setInitialFormValues,
        setDob,
        customPhoneNum
      );
    }
  }, [data]);
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="basic-detail container_sm">
      <h2 className="heading text-center font_xxl mb-5">Basic Details</h2>

      <Formik
        onSubmit={onSubmit}
        initialValues={initialFormValues}
        validate={formValidation}
        validateOnBlur={true}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            {/* Form Fields */}
            <div className="form-wrapper form-wrapper-flex">
              {/* Input for First Name */}
              <div className="form-group">
                <CustomTextInput
                  name="first_name"
                  label="First Name"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  touched={touched.first_name}
                  errors={errors.first_name}
                  value={values.first_name}
                  readOnly={true}
                />
              </div>

              {/* Input for Last Name */}
              <div className="form-group">
                <CustomTextInput
                  name="last_name"
                  label="Last Name"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  touched={touched.last_name}
                  errors={errors.last_name}
                  value={values.last_name}
                  readOnly={true}
                />
              </div>

              {/* Input for Email */}
              <div className="form-group">
                <CustomTextInput
                  name="email"
                  label="Email"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  touched={touched.email}
                  errors={errors.email}
                  value={values.email}
                  readOnly={true}
                />
              </div>

              {/* Input for Phone */}
              <div className="form-group">
                <CustomPhoneInput
                  name="mobile"
                  label={"Phone Number"}
                  customPhoneNum={customPhoneNum}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values.mobile}
                  countryCodeEditable={true}
                  errors={errors.mobile}
                  touched={touched.mobile}
                  readOnly={true}
                />
              </div>

              {/* Input for Date of Birth */}
              <div className="form-group">
                <CustomDateInput
                  label="Date of Birth"
                  value={dob}
                  setValue={setDob}
                  maxDate={new Date()}
                  minDate={false}
                />
              </div>

              {/* Select for Gender */}
              <div className="form-group">
                <CustomSelectInput
                  name="gender"
                  value={values.gender}
                  touched={touched.gender}
                  errors={errors.gender}
                  label="Gender"
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  options={genders}
                  valueExtractor={(item) => item?.name}
                  formikState={true}
                  emptyOption={true}
                />
              </div>

              {/* Input for Profession */}
              <div className="form-group">
                <CustomTextInput
                  name="profession"
                  label="Profession"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  touched={touched.profession}
                  errors={errors.profession}
                  value={values.profession}
                  required={false}
                />
              </div>

              {/* Input for Company Name */}
              <div className="form-group">
                <CustomTextInput
                  name="business_name"
                  label="Company Name"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  touched={touched.business_name}
                  errors={errors.business_name}
                  value={values.business_name}
                  required={false}
                />
              </div>

              {/* Select for Nationality */}
              <div className="form-group">
                <CustomSelectInput
                  name="nationality"
                  value={values.nationality}
                  touched={touched.nationality}
                  errors={errors.nationality}
                  label="Nationality"
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  options={countries}
                  valueExtractor={(item) => item?.name}
                  formikState={true}
                  emptyOption={true}
                />
              </div>

              {/* Select for Language */}
              <div className="form-group">
                <CustomSelectInput
                  name="language"
                  value={values.language}
                  touched={touched.language}
                  errors={errors.language}
                  label="Language"
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  options={_availableLocales}
                  valueExtractor={(item) => item?.name}
                  formikState={true}
                  emptyOption={true}
                />
              </div>
            </div>

            {/* Submit and Cancel */}
            <div className="button-wrapper text-center">
              <CustomButton type="submit" size="l">
                Next
              </CustomButton>

              {onCancelStep && (
                <TextButton
                  type="button"
                  onClick={onCancelStep}
                  className="font_m fw_6"
                >
                  Back
                </TextButton>
              )}
            </div>
          </form>
        )}
      </Formik>

      {canCancelApplication && (
        <div className="cancel-application-btn-wrapper w_100 mt-4">
          <CustomButton
            className="cancel-application-btn"
            variant="red"
            size="l"
            onClick={onCancelApplication}
          >
            Cancel Application
          </CustomButton>
        </div>

        // <div className="cancel-application-wrapper bg_grey">

        //   <div className="cancel-application-btn-wrapper w_100">
        //     <CustomButton
        //       className="cancel-application-btn"
        //       variant="red"
        //       size="m"
        //       onClick={onCancelApplication}
        //     >
        //       Cancel Application
        //     </CustomButton>
        //   </div>
        // </div>
      )}

      {/* Modals */}
      {/* Success Modal */}
      <SuccessModal
        showModal={showSuccessModal}
        description={message}
        title="Success"
        onClose={() => onNext()}
      />

      {/* Error Modal */}
      <ErrorModal
        showModal={showErrModal}
        description={message}
        title="Error"
        onClose={() => setShowErrModal(false)}
      />
    </div>
  );
};
/* Component ends */

const mapStateToProps = (state) => {
  return {
    _availableLocales: state.master.locales,
    countries: state.country.countries,
    genders: state.master.genderTypes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchLocales: bindActionCreators(fetchLocales, dispatch),
    fetchCountries: bindActionCreators(fetchCountries, dispatch),
    fetchGenders: bindActionCreators(fetchGenders, dispatch),
    update: bindActionCreators(updateTenantApplication, dispatch),
    clearMasterData: bindActionCreators(clearMasterData, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BasicDetailForm);
