export const generateStatus = (item) => {
  let status;
  let subStatus;
  let subStatusVisible = false;
  let subStatusColor;

  if (item.status === "tenant_approval_pending") {
    status = "Tenant Approval Pending";
  } else {
    status = item.status;
  }

  switch (item.sub_status) {
    case "reschedule_maintenance":
      subStatusVisible = true;
      subStatus = "Reschedule Requested";
      subStatusColor = "font_red";
      break;

    case "review_pending":
      subStatusVisible = true;
      subStatus = "Review Pending";
      subStatusColor = "font_red";
      break;

    case "waiting_for_tenant_approval":
      subStatusVisible = true;
      subStatus = "Waiting for Approval";
      subStatusColor = "font_red";
      break;
  }

  return {
    status,
    subStatus,
    subStatusVisible,
    subStatusColor,
  };
};
