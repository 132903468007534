/**
 *
 */
import React from "react";

/* Import configuration starts */
import moment from "moment";
import { useNavigate } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
import { Table } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import GLOBAL from "../../../../setup/constants/global";
/* Import local pages and component ends */

// Styles
import "./style.scss";

/* Component starts */
const ActiveFacilities = (props) => {
  /* Props destructuring starts */
  const {} = props;

  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const navigate = useNavigate();
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";

  const activeFacilityList = [];

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */

  return (
    <div className="custom-table-wrapper active-facility-table-wrapper">
      {activeFacilityList?.length > 0 && (
        <Table className="custom-table font_s" borderless responsive>
          {/* Table Heading */}
          <thead>
            <tr>
              <th>Facility</th>
              <th>Subscription Expiry Date</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          {/* Table Body */}
          <tbody>
            {activeFacilityList?.map((item) => (
              <tr key={item.id}>
                {/* Facility */}
                <td>{item?.facility}</td>

                {/* Subscription Expiry Date */}
                <td>{moment(item?.expiry).format(DATE_FORMAT)}</td>

                {/* Guest Status */}
                <td
                  className={
                    item?.status === "Active"
                      ? "font_green"
                      : item?.status === "Pending"
                      ? "font_yellow"
                      : "font_red"
                  }
                >
                  {item?.status}
                </td>

                {/* Actions */}
                <td className="text-start txt-nowrap">
                  <button className="sub-btn">Subscribe</button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      {activeFacilityList?.length === 0 && (
        <div className=" text-center">
          <h3 className=" font_m font_yellow fw_6">No Data</h3>
        </div>
      )}
    </div>
  );
};
/* Component Ends */
export default ActiveFacilities;
