/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */

import { useLocation, useParams, useSearchParams } from "react-router-dom";
/* Import configuration ends */

/* Import redux slices component starts */

/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import CreateTenantInvoice from "./local-component/CreateTenantInvoice";
import CreatePropertyInvoice from "./local-component/CreatePropertyInvoice";
import CreatePartyInvoice from "./local-component/CreatePartyInvoice";
/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const CreateInvoice = (props) => {
  /* Props destructuring starts */
  const { onSuccess, onCancel } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */

  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const [searchParams, setSearchParams] = useSearchParams();

  const invoicingListTab = searchParams.get("type");

  const moveout = searchParams.get("moveout") === "1";
  const unitId = searchParams.get("unitId");
  const redirectedFrom = searchParams.get("from");

  const { tab, unitID } = useParams();
  const { state } = useLocation();

  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <>
      <div className="create-invoice-wrapper">
        {(tab === "Tenant" ||
          redirectedFrom === "tenant_detail" ||
          searchParams.get("type") === "Tenants") && (
          <CreateTenantInvoice
            type="Tenant"
            onSuccess={onSuccess}
            onCancel={onCancel}
            moveout={moveout}
            unitId={unitId || unitID}
            state={state}
          />
        )}

        {tab === "Property Invoice" && (
          <CreatePropertyInvoice
            type="Property"
            onSuccess={onSuccess}
            onCancel={onCancel}
          />
        )}

        {tab === "Parties Invoice" && (
          <CreatePartyInvoice
            type="Party"
            onSuccess={onSuccess}
            onCancel={onCancel}
          />
        )}
      </div>
    </>
  );
};
/* Component ends */

export default CreateInvoice;
