// INITIAL VALUES
export const isApproved = (status) => {
  return status && status === "approved" ? true : false;
};

// Form validations
export const validation = (values, formCountList) => {
  const error = {};

  formCountList.forEach((item, index) => {
    const utility_photo = `utility_photo_${item}`;

    if (values[utility_photo] === null) {
      error[utility_photo] = "Utility Document is required";
    }
  });

  return error;
};

export const prefill = (
  utilityRequiredData,
  data,
  setInitialFormValues,
  setFormCountList
) => {
  const formCount = [];
  const formFieldNames = {};

  if (data && data.length > 0) {
    utilityRequiredData?.forEach((item, index) => {
      formFieldNames[`utility_name_${index}`] = item;
      formFieldNames[`utility_photo_${index}`] =
        data[index] && data[index]?.image ? data[index]?.image : null;
      formFieldNames[`status_${index}`] =
        data[index] && data[index]?.status ? data[index]?.status : "";

      formCount.push(index);
    });
  } else {
    utilityRequiredData?.forEach((item, index) => {
      formFieldNames[`utility_name_${index}`] = item;
      formFieldNames[`utility_photo_${index}`] = null;
      formFieldNames[`status_${index}`] = "";

      formCount.push(index);
    });
  }

  setInitialFormValues(formFieldNames);
  setFormCountList(formCount);
};

export const isDataChanged = (newData, prevData, formCountList) => {
  for (let i = 0; i < formCountList.length; i++) {
    if (
      newData[`utility_photo_${formCountList[i]}`] &&
      !newData[`utility_photo_${formCountList[i]}`]?.url
    ) {
      return true;
    }
  }

  return false;
};
