/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import moment from "moment";
import GLOBAL from "../../../../../setup/constants/global";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
/* Import local pages and component ends */

/* Component starts */
const BasicDetails = (props) => {
  /* Props destructuring starts */
  const { details, index } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const USER_DATA = localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : null;
  const DATE_FORMAT = USER_DATA ? USER_DATA?.date_format : "";

  const firstName = details.first_name;
  const lastName = details.last_name;
  const email = details.email;
  const mobile = `${details.countrycode} ${details.mobile}`;
  const dob = moment(details.dob).format(DATE_FORMAT);
  const gender = details.gender;
  const profession = details.profession ? details.profession : "-";
  const businessName = details.business_name ? details.business_name : "-";
  const nationality = details.nationality;
  const language = details.language;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="basic-wrapper">
      <p className="font_l"> {index} - Basic Details</p>

      <div className="detail-box bg_grey">
        <div className="detail">
          <p className="font_s fw_3">
            First Name <br /> <span className="font_m fw_4">{firstName}</span>
          </p>
        </div>
        <div className="detail">
          <p className="font_s fw_3">
            Last Name <br /> <span className="font_m fw_4">{lastName}</span>
          </p>
        </div>
        <div className="detail">
          <p className="font_s fw_3">
            Email <br /> <span className="font_m fw_4">{email}</span>
          </p>
        </div>
        <div className="detail">
          <p className="font_s fw_3">
            Mobile Number <br /> <span className="font_m fw_4">{mobile}</span>
          </p>
        </div>
        <div className="detail">
          <p className="font_s fw_3">
            Date Of Birth <br /> <span className="font_m fw_4">{dob}</span>
          </p>
        </div>
        <div className="detail">
          <p className="font_s fw_3">
            Gender <br /> <span className="font_m fw_4">{gender}</span>
          </p>
        </div>
        <div className="detail">
          <p className="font_s fw_3">
            Profession <br /> <span className="font_m fw_4">{profession}</span>
          </p>
        </div>
        <div className="detail">
          <p className="font_s fw_3">
            Company Name <br />{" "}
            <span className="font_m fw_4">{businessName}</span>
          </p>
        </div>
        <div className="detail">
          <p className="font_s fw_3">
            Nationality <br />{" "}
            <span className="font_m fw_4">{nationality}</span>
          </p>
        </div>
        <div className="detail">
          <p className="font_s fw_3">
            Language <br /> <span className="font_m fw_4">{language}</span>
          </p>
        </div>
      </div>
    </div>
  );
};
/* Component ends */

export default BasicDetails;
