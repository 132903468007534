/**
 *
 */

import React, { useState, useEffect, useRef } from "react";

/* Import configuration starts */
import { Formik } from "formik";
import { initialValues, validations } from "./helper";
import { useLocation, useParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
/* Import configuration ends */

/* Import redux slices component starts */
import {
  addAnnoucement,
  fetchAnnoucementsList,
  fetchAnnoucementsListBasedCommunity,
  fetchAnnoucementsListBasedProperty,
} from "../../../../setup/store/slices/annoucementSlice";

import {
  fetchSeverityList,
  getSeverities,
} from "../../../../setup/store/slices/severitySlice";
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */

import GLOBAL from "../../../../setup/constants/global";
import CustomTextInput from "../../../ui/input/CustomTextInput/CustomTextInput";
import CustomDateInput from "../../../ui/input/CustomDateInput/CustomDateInput";
import CustomButton from "../../../ui/button/CustomButton/CustomButton";
import TextButton from "../../../ui/button/TextButton/TextButton";
import TextEditor from "../../../ui/other/TextEditor/TextEditor";
import CustomTextArea from "../../../ui/input/CustomTextArea/CustomTextArea";
import ErrorModal from "../../../ui/modal/ErrorModal/ErrorModal";
import SuccessModal from "../../../ui/modal/SuccessModal/SuccessModal";
import NewHTMLTextEditor from "../../../ui/other/NewHTMLTextEditor/NewHTMLTextEditor";

import CustomSelectInput from "../../../ui/input/CustomSelectInput/CustomSelectInput";
/* Import local pages and component ends */

// Styles
import "./style.scss";
import {
  fetchPropertyDetailByID,
  getPropertyDetail,
} from "../../../../setup/store/slices/propertySlice";

/* Component starts */

const AddAnnoucements = (props) => {
  /* Props destructuring starts */

  const { onSuccess, onCancel } = props;

  //onSuccess, onCancel
  /* Props destructuring ends */

  let initialFormValues = initialValues;

  /* Component states and useRef declaration starts */
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [descVal, setDescVal] = useState({ value: null });

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [modalDesc, setModalDesc] = useState("");

  const [isEmptyContent, setIsEmptyContent] = useState(false);
  const [isEditorFocused, setIsEditorFocused] = useState(false); // State to track editor focus
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  const { pathname } = useLocation();
  const { propertyId } = useParams();
  const param = useParams();
  const dispatch = useDispatch();
  const severityList = useSelector(getSeverities);
  const propertyDetail = useSelector(getPropertyDetail);
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */

  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */

  // Form Validations
  const formValidation = (values) => {
    return validations(values);
  };

  const onSubmit = async (values) => {
    const payload = {
      title: values?.title,
      description: values?.description,
      html_messege: descVal.value,
      start_date: startDate?.toString(),
      end_date: endDate?.toString(),
      severity: values?.severity,
    };

    if (pathname.includes("communities")) {
      payload["community_id"] = param?.communityId;
      payload["added_for"] = "community";
    } else if (pathname.includes("independent")) {
      payload["property_id"] = param?.propertyId;
      payload["added_for"] = "property";
    } else {
      payload["added_for"] = "all";
    }

    console.log(payload, "[PY-00]");

    // Backend response. Try, Catch
    try {
      const result = await dispatch(addAnnoucement(payload));

      // Handling success response
      switch (result.meta.requestStatus) {
        case GLOBAL.REJECTED:
          setModalDesc(result.payload);
          setShowErrorModal(true);
          break;

        case GLOBAL.FULFILLED:
          // if (pathname?.includes("communities")) {
          //   dispatch(
          //     fetchAnnoucementsListBasedCommunity({
          //       community_id: param?.communityId,
          //     })
          //   );
          // } else if (pathname?.includes("independent")) {
          //   dispatch(
          //     fetchAnnoucementsListBasedProperty({
          //       property_id: param?.propertyId,
          //     })
          //   );
          // } else {
          //   dispatch(fetchAnnoucementsList());
          // }
          setModalDesc(result.payload.message);
          setShowSuccessModal(true);
          break;
      }
    } catch (error) {
      // Handling error response
      console.error(error.message);
    }
  };

  /* Component function definition ends */

  /* Component useEffect starts */
  useEffect(() => {
    dispatch(fetchSeverityList());
    dispatch(fetchPropertyDetailByID(param?.propertyId));
  }, []);

  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */

  return (
    <div className="add-annoucement-module-wrapper">
      <h2 className="heading text-center font_xxl mb-5">Create Annoucement</h2>

      <div className="add-new-form">
        <Formik
          onSubmit={onSubmit}
          initialValues={initialFormValues}
          validate={formValidation}
          validateOnBlur={true}
          enableReinitialize={true}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="form-wrapper">
                {/* Creating Annoucements */}
                <>
                  {/* Input for Title */}
                  <div className="form-group">
                    <CustomTextInput
                      name="title"
                      label="Title"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched.title}
                      errors={errors.title}
                      value={values.title}
                      required={true}
                    />
                  </div>

                  {/* Severity Of Annoucement */}
                  <div className="form-group">
                    <CustomSelectInput
                      name="severity"
                      label="Severity"
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      valueExtractor={(item) => item?.severity}
                      optionValueExtractor={(item) => item?._id}
                      options={severityList}
                      touched={touched.severity}
                      errors={errors.severity}
                      value={values.severity}
                    />
                  </div>

                  {/* Text Area for Description */}
                  <div className="form-group form-group-full">
                    <CustomTextArea
                      name="description"
                      label="Description"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      value={values.description}
                      errors={errors.description}
                      touched={touched.description}
                      required={true}
                    />
                  </div>

                  {/* Text Editor */}
                  <div className="form-group form-group-full">
                    {/* <TextEditor
                      label="Add"
                      descVal={descVal}
                      setDescVal={setDescVal}
                    /> */}
                    <NewHTMLTextEditor
                      label="Draft the Announcment"
                      descVal={descVal}
                      setDescVal={setDescVal}
                      isEmptyContent={isEmptyContent}
                      setIsEmptyContent={setIsEmptyContent}
                      isEditorFocused={isEditorFocused}
                      setIsEditorFocused={setIsEditorFocused}
                    />

                    {(isEmptyContent && (
                      <p className="font_red fw_6 font_m">{`Editor content cannot be empty!`}</p>
                    )) ||
                      (isEditorFocused && (
                        <p className="font_red fw_6 font_m">{`Please finish editing before submitting!`}</p>
                      ))}
                  </div>

                  {/* Input for Start Date */}
                  <div className="form-group">
                    <CustomDateInput
                      name="start_date"
                      label="Start Date"
                      setFieldValue={setFieldValue}
                      value={startDate}
                      canSetOffset={true}
                      canSetTimezone={true}
                      offset={propertyDetail?.offset}
                      timeZone={propertyDetail?.time_zone}
                      customOnchange={(date) => {
                        setStartDate(date);
                        // setEndDate(date);
                        var day = new Date(date);
                        var nextDay = new Date(day);
                        nextDay.setDate(day.getDate() + 1);
                        setEndDate(nextDay);
                      }}
                      minDate={true}
                      minDateValue={new Date()}
                    />
                  </div>

                  {/* Input for End Date */}
                  <div className="form-group">
                    <CustomDateInput
                      name="end_date"
                      label="End Date"
                      setFieldValue={setFieldValue}
                      value={endDate}
                      canSetOffset={true}
                      canSetTimezone={true}
                      offset={propertyDetail?.offset}
                      timeZone={propertyDetail?.time_zone}
                      customOnchange={(date) => setEndDate(date)}
                      minDate={true}
                      minDateValue={(() => {
                        var day = new Date(startDate);
                        var nextDay = new Date(day);
                        nextDay.setDate(day.getDate() + 1);
                        return nextDay;
                      })()}
                    />
                  </div>
                </>
              </div>

              <div className="button-wrapper text-center">
                <>
                  <CustomButton type="submit" size="l">
                    Create
                  </CustomButton>
                  <TextButton
                    type="button"
                    onClick={onCancel}
                    className="font_m fw_6"
                  >
                    Cancel
                  </TextButton>
                </>
              </div>
            </form>
          )}
        </Formik>
      </div>

      {/* Success Modal */}
      <SuccessModal
        showModal={showSuccessModal}
        description={modalDesc}
        title="Successfully Created"
        onClose={() => {
          onSuccess();
          setShowSuccessModal(false);
        }}
      />

      {/* Error Modal */}
      <ErrorModal
        showModal={showErrorModal}
        description={modalDesc}
        title="Error"
        onClose={() => setShowErrorModal(false)}
      />
    </div>
  );
};
/* Component ends */

export default AddAnnoucements;
