/**
 * This component is for custom text input field
 */

import React from "react";

/* Import configuration starts */
import { blockInvalidChar, blockInvalidCharNoDot } from "./helper";
/* Import configuration ends */

import V1 from "./variants/V1";
import V2 from "./variants/V2";

import "./style.scss";

/* Component starts */
const CustomNumberInput = (props) => {
  /* Props destructuring starts */
  const {
    type = "text",
    label,
    handleChange,
    value,
    name,
    errors,
    handleBlur,
    touched,
    required = true,
    disableDot = true,
    variant = "1",
  } = props;
  /* Props destructuring ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* ************* Component rendering. JSX code ************* */
  if (variant === "1") return <V1 {...props} />;
  if (variant === "2") return <V2 {...props} />;
};
/* Component ends */

export default CustomNumberInput;
