/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
import { statusColorGenerate } from "../helper";
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
/* Import local pages and component ends */

/* Component starts */
const DetailSection3 = (props) => {
  /* Props destructuring starts */
  const { status } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="section pair-section section3 font_s fw_6">
      {/* Status */}
      <p className="left font_grey">Status</p>
      <p className={`value right ${statusColorGenerate(status)}`}>{status}</p>
    </div>
  );
};
/* Component ends */

export default DetailSection3;
