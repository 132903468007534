/**
 * This component is a Modal that is shown on success
 */

import React, { useRef } from "react";

import { Formik } from "formik";
/* Import react bootstrap component starts */
import { Modal } from "react-bootstrap";
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */

import CustomTextArea from "../../../ui/input/CustomTextArea/CustomTextArea";
import CustomFileInput from "../../../ui/input/CustomFileInput/CustomFileInput";
import CustomSelectInput from "../../../ui/input/CustomSelectInput/CustomSelectInput";
import CustomButton from "../../../ui/button/CustomButton/CustomButton";

/* Import local pages and component ends */

import "./style.scss";

/* Component starts */
const TenantNotificationModal = (props) => {
  /* Props destructuring starts */
  const { showModal, size = "lg", onclose } = props;
  /* Props destructuring ends */

  // UseRef
  const photo = useRef(null);

  // Initial Values
  const initialFormValues = {
    notification: "",
    type: "",
    photo: photo.current,
  };

  // Dummy Data
  const typesList = [
    {
      id: 1,
      name: "Manual",
    },
    {
      id: 2,
      name: "Semi-Automated",
    },
    {
      id: 3,
      name: "Automated",
    },
  ];

  // Handle Form Validations
  const formValidation = (values) => {
    const error = {};

    if (values.notification === "") {
      error.notification = "Notification Is Required";
    }

    if (values.photo === "") {
      error.photo = "Please select an image";
    }

    if (values.type === "") {
      error.type = "Please choose an option";
    }

    return error;
  };

  // Handle SUbmit
  const onSubmit = (values) => {
    console.log("Hi", values);
  };

  /* ************* Component rendering. JSX code ************* */
  return (
    <Modal
      size={size}
      centered
      show={showModal}
      className="tenant-notification-modal-wrapper"
    >
      <Modal.Body>
        <div className="modal-header-custom">
          <div className="icon-wrapper text-center">
            <img
              src="https://www.researchgate.net/publication/366943371/figure/fig2/AS:11431281112633472@1673502843018/AI-generated-profile-picture-made-with-the-app-Lensaai.png"
              alt="Profile Image"
            />
            {/* <p>TEST DEMO</p> */}
          </div>
          <h2 className="font_l fw_6 text-center font_blue">TEST DEMO</h2>
        </div>
        <div className="modal-body-custom">
          <Formik
            onSubmit={onSubmit}
            initialValues={initialFormValues}
            validate={formValidation}
            validateOnBlur={true}
            enableReinitialize={true}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                {/* Form Fields */}
                <div className="form-wrapper form-wrapper-flex">
                  {/* Text Area for Type Notification */}
                  <div className="form-group form-group-full">
                    <CustomTextArea
                      name="notification"
                      label="Type Notification"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      value={values.notification}
                      errors={errors.notification}
                      touched={touched.notification}
                      required={true}
                    />
                  </div>

                  {/* Upload Image */}
                  <div className="form-group">
                    <CustomFileInput
                      name="photo"
                      label="Upload"
                      files={photo}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      errors={errors.photo}
                      touched={touched.photo}
                      setFieldValue={false}
                      values={values.photo}
                      required={false}
                      formikState={true}
                    />
                  </div>

                  {/* Select for Type */}
                  <div className="form-group">
                    <CustomSelectInput
                      name="type"
                      value={values.type}
                      touched={touched.type}
                      errors={errors.type}
                      label="Type"
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      options={typesList}
                      valueExtractor={(item) => item?.name}
                      formikState={true}
                      required={false}
                      emptyOption={false}
                    />
                  </div>
                </div>

                {/* Submit and Cancel */}
                <div className="form-btn-wrapper">
                  <>
                    <CustomButton onClick={onclose} size="l" className="btn1">
                      Cancel
                    </CustomButton>
                    <CustomButton type="submit" size="l">
                      Send
                    </CustomButton>
                  </>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </Modal.Body>
    </Modal>
  );
};
/* Component ends */

export default TenantNotificationModal;
