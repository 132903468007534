/**
 *
 */

import React, { useState, useEffect } from "react";

/* Import configuration starts */
/* Import configuration ends */

/* Import redux slices component starts */
/* Import redux slices component ends */

/* Import react bootstrap component starts */
/* Import react bootstrap component ends */

/* Import image and SVG starts */
/* Import image and SVG ends */

/* Import local pages and component starts */
import TextButton from "../../../../ui/button/TextButton/TextButton";
/* Import local pages and component ends */

/* Component starts */
const DetailSection5 = (props) => {
  /* Props destructuring starts */
  const { isT1, maintenance } = props;
  /* Props destructuring ends */

  /* Component states and useRef declaration starts */
  /* Component states and useRef declaration ends */

  /* Other hooks declaration starts */
  /* Other hooks declaration ends */

  /* Component variable declaration / object destructure starts */
  const assigned =
    maintenance?.assigned?.first_name + " " + maintenance?.assigned?.last_name;
  const assignedRole = maintenance?.assigned_role?.name;
  /* Component variable declaration / object destructure ends */

  /* Component function definition starts */
  /* Component function definition ends */

  /* Component useEffect starts */
  /* Component useEffect ends */

  /* ************* Component rendering. JSX code ************* */
  return (
    <div className="section pair-section section5 font_s fw_6">
      {/* Ticket Assign */}
      <p className="left font_grey">Ticket Assign</p>
      {isT1 ? (
        // Owner name
        <div className="right assigned">
          <p className="fw_4">{assigned}</p>
          <p className="fw_4">{assignedRole}</p>
        </div>
      ) : (
        // Assigning Technician Action Button
        <div className="right assigned">
          <p className="fw_4">{assigned}</p>
          <p className="fw_4">{assignedRole}</p>
        </div>
        // <div className="right">
        //   <div className="assign text-end">
        //     <TextButton className="font_blue font_s fw_6">
        //       + Assign Technician
        //     </TextButton>
        //   </div>
        //   <div className="reassign text-end">
        //     <TextButton className="font_blue font_s fw_6">
        //       + Reassign Ticket
        //     </TextButton>
        //   </div>
        // </div>
      )}
    </div>
  );
};
/* Component ends */

export default DetailSection5;
