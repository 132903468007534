export const generateStatus = (status) => {
  switch (status) {
    case "approved":
      return "Approved";
      break;

    case "active":
    case "renewal_pending":
      return "Active";
      break;

    default:
      break;
  }
};

export const contractListStatus = (item) => {
  let color = "",
    status = "";

  if (item?.move_out_initiated) {
    return { status: "Move Out", color: "font_yellow" };
  }

  switch (item?.status) {
    case "expired":
      status = "Expired";
      color = "font_red";
      break;
    case "active":
    case "renewal_pending":
      status = "Active";
      color = "font_green";
      break;
    case "renewed":
      status = "Renewed";
      color = "font_yellow";
      break;

    default:
      break;
  }

  return {
    status,
    color,
  };
};

export const moveOutStatus = (item) => {
  let status = "";
  let color = "";

  switch (item.move_out_status) {
    case "move_out_initiated":
      status = "Initiated";
      break;

    case "waiting_clearance_cert":
      status = "Waiting for clearance certificate";
      break;

    case "keys_handover_left":
      status = "Handover keys";
      break;

    case "text_pending_clearance_docs":
      status = "Upload clearance document(s)";
      break;

    default:
      status = "";
  }

  return { status };
};

export const renewalStatus = (renewal) => {
  let status = {
    text: "",
    color: "",
  };
  switch (renewal?.status) {
    case "renewal_pending_approval":
    case "pending_tenant_approval":
      status.text = "Pending Approval";
      status.color = "font_yellow";
      break;

    case "pending_revision_approval":
      status.text = "Revision request is under review";
      status.color = "font_yellow";
      break;

    case "pending_contract_dispatch":
      status.text = "Pending Contract";
      status.color = "font_yellow";
      break;

    case "pending_tenant_signature":
      status.text = "Pending Contract Approval";
      status.color = "font_yellow";
      break;

    case "pending-contract-review":
      status.text = "Contract Under Review";
      status.color = "font_yellow";
      break;

    case "pending_tenant_info":
      if (renewal?.application_status === "pending-application-review") {
        status.text = "Pending Review";
      } else if (renewal?.application_status === "reservor-data-rejected") {
        status.text = "Application data rejected";
      } else {
        status.text = "Pending Information Update";
      }
      status.color = "font_yellow";
      break;

    default:
      status.text = renewal?.status;
  }
  return status;
};
